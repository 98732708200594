import { Component, OnInit } from "@angular/core";
import { NbcLoggerService } from '../../logger/nbc-logger.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';


@Component({
  selector: "nbc-modal-confirmation",
  templateUrl: "nbc-modal-confirmation.component.html"
})

export class NbcModalConfirmation implements OnInit {

  constructor(private log: NbcLoggerService,
    public bsModalRef: BsModalRef) {}

  public onAffirmation!: Subject<any>;
  public onRejection !: Subject<any>;

  modalTitle: string | undefined;
  modalMessage: string | undefined;
  confirmText: string | undefined;
  cancelText: string | undefined;
  hideConfirm: boolean | undefined;


  // Modal Confirm Action
  confirm() {
    this.log.trace("Modal Confirmation Confirmed");
    this.onAffirmation.next(true);
    this.bsModalRef.hide();
  };

  cancel() {
    this.log.trace("Modal Confirmation Cancelled");
    this.onRejection.next(false);
    this.bsModalRef.hide();
  };

  ngOnInit() {

    this.onAffirmation = new Subject();
    this.onRejection = new Subject();

    this.confirmText = this.confirmText;
    this.cancelText = this.cancelText;
    // Modal Title
    //this.modalTitle = <<Modal Title needs to be set>>;

    // Modal Body
   // this.modalMessage = <<body text which may have info, question to the end user>>;

    // Modal Confirm Button Text
   // this.confirmText = <<button text that you want to display>>

    // Modal Cancel Button Text
    //this.cancelText = <<cancel text that you would need>>

    // Hides the Confirm button when there is a use case where we only want to dismiss the modal
    //this.hideConfirm = <<>> !!hideConfirm;

  }

}
