<input [(ngModel)]="searchTerm"
       appInputRestriction="restrictChars"
       name="{{name}}"
       class="{{nbcClass}}"
       [typeahead]="airportCodeObservable$"
       [typeaheadAsync]="true"
       typeaheadOptionField="description"
       [typeaheadOptionsLimit]="50"
       [isAnimated]="true"
       [typeaheadWaitMs]="200" 
       size="3"
       [required]="isRequired"
       [disabled]="!isAuthorized"
       [attr.authenticate-user]="nbcAuthenticateUser"
       [attr.authenticate-command]="nbcAuthenticateCommand"
       (typeaheadOnSelect)="onValueSelected($event)">
