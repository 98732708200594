import { ChangeDetectorRef, Component, OnDestroy, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartType } from "chart.js";
import { debug } from "console";
import * as _ from "lodash";
import { forEach } from "lodash";
import * as moment from "moment";
//import { Label, MultiDataSet } from "ng2-charts";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import {
    EventBusService,
    EmitEvent,
} from "src/app/Core/services/event-bus.service";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { CrewItemService } from "src/app/Shared/Services/crew-item.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { SubSink } from "subsink";
import { NbcModalDirtyCheckComponent } from "../../Shared/components/nbc-dirty-check/nbc-modal-dirtycheck.component";
import { SharedDataService } from "../../Shared/sharedData.service";
import { PayTransactionComponent } from "./components/paytransations/model-pay-transaction.component";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
    selector: "payroll-timesheet",
    templateUrl: "./payroll-timesheet.component.html",
})
export class PayrollTimesheet implements OnInit, OnDestroy {
    constructor(
        private _sharedData: SharedDataService,
        private _payrollService: PayrollService,
        private _remotesQueryService: RemotesQueryService,
        private _employeeTypeService: EmployeeTypeService,
        private _eventService: EventService,
        private _modalService: BsModalService,
        private _crewItemService: CrewItemService,
        private _eventBus: EventBusService,
        private activatedRoutes: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private _router: Router,
        private ngxService: NgxUiLoaderService,
        private log: NbcLoggerService
    ) {
        this.cd.detach();
    }

  @HostListener('click', ['$event'])
  onParentDivClick(event: MouseEvent) {
    const elements = document.querySelectorAll<HTMLElement>(
      ".payroll-timesheet");
    if (elements && elements[0]) {      
      this.cd.detectChanges();
    }  
  }

    private subs = new SubSink();

    isFullScreenMode = false;
    componentDestroyed$: Subject<boolean> = new Subject();
    bsModalRef?: BsModalRef;
    bsDirtyCheckModalRef?: BsModalRef;
    testDate: Date = new Date();
    eventId = 0;
    internalModel: any | undefined;
    glAccountSearchTerm: string | undefined;
    defaultUpdatedValues: any = {
        employeeType: "",
        updatedTime: "",
        contractCode: "",
        ruleVariance: "",
        payTransactions: "",
        glAccount: "",
        indicators: "",
        defaultScheduledHours: "",
        contractRuleVariance: null,
        jobCode: null,
        rate: null,
        customRate: null,
        // Added variable for US 21236
        updatedStartTime: "",
        updatedEndTime: "",
        updatedMealStartTime: "",
        updatedMealEndTime: "",
    };
    chartColors: any[] = [
        "#f67500",
        "#1bb445",
        "#a63f9a",
        "#000000",
        "#FFD35B",
    ];
    doughnutchartColors: any[] = [
        {
            // first color
            backgroundColor: "#008D86",
        },
    ];
    defaultOrderBy = "";
    defaultReverseSort = false;
    authorName = window.preload.user.userName;
    submitted = false;
    //public doughnutChartLabels: Label[] = [];
    //public doughnutChartData: MultiDataSet = [];
    public doughnutChartLabels: any = [];
    public doughnutChartData: any = [];
    public doughnutChartType: ChartType = "doughnut";

    rateCodes: any[] = [];
    timesheetQuery: any = {};
    previousSettings: any = {
        pageSize: null,
        currentPage: null,
        currentDate: null,
    };
    model: any = null;
    event: any = {};
    timeCardDate = "";
    timesheetDates: any[] = [];
    employeeTypes: any[] = [];
    contractCodes: any[] = [];
    jobCodes: any[] = [];
    jobCodesGroups: any[] = [];
    chartData: any[] = [];
    employeeTypeFilters: any[] = [];
    filter: any = {
        showTravelOptions: false,
        active: false,
        employeeType: [],
    };
    isJobCodeBulkUpdate = false;
    glAccountObservable$: Observable<any[]> | undefined;
    activeTimesheetDate: any;
    payrolltimecarddate: any;
    dayCodes: any[] = [
        { id: "REG", name: "REG" },
        { id: "OFF", name: "OFF" },
    ];
    groupUpdate: any = {
        status: false,
        selectAll: false,
        count: 0,
        submitted: false,
        options: [
            {
                name: "Employee Type",
                value: "employeeType",
            },
            {
                name: "Time",
                value: "time",
            },
            // US-21236 -- comment Old code
            //{
            //  name: "Start",
            //  value: "timeIn"
            //},
            //{
            //  name: "End",
            //  value: "timeOut"
            //},
            //{
            //  name: "Meal Start",
            //  value: "lunchIn"
            //},
            //{
            //  name: "Meal End",
            //  value: "lunchOut"
            //},
            //end
            { name: "Contract Code", value: "contractCode" },
            { name: "GL Account", value: "glAccount" },
            { name: "Default Scheduled Hours", value: "defaultScheduledHours" },
            //commenting below optins as decision by SCORE. Feature will be availble with August release. Issue with JobCode as we need ratecode also with this change
            //SCOR-10862
            { name: "Job Code", value: "jobCode" },
            { name: "Rate", value: "rate" },
        ],
        updatedValues: _.cloneDeep(this.defaultUpdatedValues),
    };

    isSingleClick = true;
    selected = "";
    sort = {
        orderByField: this.defaultOrderBy,
        reverseSort: this.defaultReverseSort,
    };
    timesheets: any;
    invalidHBCodeJobCodeDataList: any;

    chartOptions: any = {
        responsive: false,
        cutoutPercentage: 75,
        segmentShowStroke: false,
        segmentStrokeColor: "#fff",
        segmentStrokeWidth: 0,
        percentageInnerCutout: 75,
        animationSteps: 50,
        animationEasing: "easeOutQuart",
        animateRotate: false,
        animateScale: false,
        showTooltips: false,
        maintainAspectRatio: false,
        cutout: "70%",
    };
    timecardDisplayDate: any | undefined;
    testtimecarddate = "2";
    expired = false;
    isApproveEnabled = false;
    isDirty = false;
    isValid = false;
    saveDisabled = true;
    pageSize = [10, 25, 50, 100];
    totalRecords = 0;
    setView: any = {
        isPageHeaderReset: true,
        isPageChange: false,
    };
    defaultContractCode: any = {
        defaultEmployeeType: null,
        defaultJobCode: null,
        defaultScheduledHours: null,
        id: 0,
        inTimeKeeper: false,
        indicators: null,
        name: null,
        ruleVariances: null,
        transactionTypes: null,
    };

    conflictJobCodeTimesheets: any[] = [];
    activityChartData: any[] = [];
    chartDataLength = 0;
    totalSubmittedTimesheets = 0;

    chartInit() {
        let key, value;
        this.activityChartData = [];
        for (key = 0; key < this.chartData.length; key++) {
            value = this.chartData[key];
            this.activityChartData.push({
                value: value.count,
                color: this.chartColors[key],
                highlight: this.chartColors[key],
                label: value.description,
            });
        }
    }

    fullScreenMode(val) {
        this.isFullScreenMode = !this.isFullScreenMode;
        this.onDemandChangeDetection();
    }

    calculateDuration(startDate, endDate, item) {
        if (
            _.isUndefined(startDate) ||
            _.isUndefined(endDate) ||
            _.isUndefined(item) ||
            startDate === null ||
            endDate === null
        ) {
            return "";
        }

        const dateDiff = moment(endDate).diff(moment(startDate), "minutes");
        const hours = Math.floor(dateDiff / 60);
        const minutes = dateDiff % 60;
        let duration = "";
        if (hours === 0) {
            duration = minutes + " min";
        } else if (hours > 0 && minutes > 0) {
            duration = hours + "h " + minutes + " min";
        } else if (hours > 0 && minutes === 0) {
            duration = hours + " h";
        }
        if (item) {
            item.duration = dateDiff / 60;
        }
        return duration;
    }

    updateDuration(items) {
        let item;
        if (items.length > 0) {
            for (let i = 0; i < items.length; i += 1) {
                item = items[i];

                if (item.timeIn && item.timeOut) {
                    items[i].calculatedDuration = this.calculateDuration(
                        item.timeIn,
                        item.timeOut,
                        item
                    );
                }
            }
        }
        return items;
    }

    updateWageSchedule(items) {
        let item;
        if (items.length > 0) {
            for (let i = 0; i < items.length; i += 1) {
                item = items[i];

                if (item.isTk) {
                    item.wageSchedule = this.getWageSchedule(
                        item.contractCode.name
                    );
                }

                if (item.rate) {
                    const num = item.rate;
                    item.rate = num.toFixed(3);
                    item.defaultRate = _.cloneDeep(item.rate);
                } else {
                    item.defaultRate = 0;
                }
            }
        }

        return items;
    }

    getWageSchedule(contractName) {
        const contractWageDefault = [
            { contractName: "NABET A DLY_DLY180 5X8", wageSchedule: "11" },
            { contractName: "NABET A DLY_DLY180_ 4X10", wageSchedule: "11" },
            { contractName: "RSN IATSE ITR", wageSchedule: "11" },
            { contractName: "RSN IBEW ETR", wageSchedule: "11" },
            { contractName: "IATSE GOLF", wageSchedule: "11" },
            { contractName: "DGA AD_SM_FRL", wageSchedule: "11" }, // SCOR-21795
            { contractName: "NABET D DAILY", wageSchedule: "11" },
        ];

        for (let i = 0; i < contractWageDefault.length; i++) {
            if (contractWageDefault[i].contractName === contractName) {
                return contractWageDefault[i].wageSchedule;
            }
        }
        return "";
    }

    errorStatusChecking(status) {
        const stat = status.toLowerCase();
        if (status) {
            if (stat.indexOf("error||") > -1) {
                return "error";
            } else if (stat.indexOf("validation failed") > -1) {
                return "validation-failed";
            }
        }
        return status;
    }

    getTimesheetByQuery(callback) {
        //this.log.trace("getTimesheetByQuery()");
        this.previousSettings = _.cloneDeep(this.timesheetQuery);

        this._payrollService
            .getPayrollTimesheets(this.timesheetQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    const updateValidation = this.getValidationAttributes();
                    _.forEach(
                        result.reference.timesheets.items,
                        (value, key) => {
                            value["isIntimeValid"] = true;
                            value["isOuttimeValid"] = true;
                            value["isLunchInTimeValid"] = true;
                            value["isLunchOutTimeValid"] = true;
                            value["isPayrollCommentValid"] = true;
                            value["isRateValid"] = true;
                            value["isglAccountValid"] = true;
                            value["isdefaultScheduleValid"] = true;
                            value["isIntimeRequired"] = true;
                            value["isOuttimeRequired"] = true;
                            value["isLunchInTimeRequired"] = true;
                            value["isLunchOutTimeRequired"] = true;
                            value["oldJobCode"] = value.jobCode;
                            this.validateTimes(value);
                            this.validateDefaultScheduleHours(value);
                            _.assign(value, updateValidation);
                            if (value.payrollProvider === "") {
                                result.reference.payrollProviderStatus = false;
                            }
                            value.id = key;
                            value.status = this.errorStatusChecking(
                                value.status
                            );
                        }
                    );
                    let items = result.reference.timesheets.items;
                    items = this.updateDuration(items);
                    items = this.updateWageSchedule(items);
                    items = this.updateSelection(items);
                    this.timeCardDate = result.reference.timecardDate;
                    this.internalModel = items;
                    this.totalRecords =
                        result.reference.timesheets.totalRecords;

                    this.timesheets = _.cloneDeep(items);
                    this.setView.isPageChange = false;
                    if (typeof callback === "function") {
                        callback();
                    }

                    this.groupUpdate.selectAll = false;
                    this.groupUpdate.status = false;
                    this.previousSettings.pageSize = _.cloneDeep(
                        this.timesheetQuery.pageSize
                    );
                    this.previousSettings.currentPage = _.cloneDeep(
                        this.timesheetQuery.currentPage
                    );
                    this.previousSettings.timecardDisplayDate = _.cloneDeep(
                        this.timecardDisplayDate
                    );
                    this.onDemandChangeDetection();                    
                    this.setFrozenHeader();
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to get timesheet information. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }
    onScroll($event) {
        // This is shell handler to get scroll on the payroll page. this helps to reset typeahead position
    }

    updateSelection(items) {
        let item;
        if (items.length > 0) {
            for (let i = 0; i < items.length; i += 1) {
                items[i].selected = false;
            }
        }
        return items;
    }

    setTimesheetDates(timesheets) {
        if (!timesheets) {
            return;
        }
        this.testtimecarddate = "3";
        let timesheet, i, addDate;
        for (i = 0; i < timesheets.length; i++) {
            timesheet = moment(timesheets[i].timeCardDate).startOf("day");
            if (timesheets[i].isTimesheetSubmitted) {
                addDate = {
                    date: new Date(timesheet),
                    displayDate: timesheet.format("MMM D, YYYY"),
                    dateString: timesheet.format("YYYY-MM-DD").toString(),
                };
                this.timesheetDates.push(addDate);
            }
        }
        if (!this.activeTimesheetDate) {
            this.timesheetQuery.timeCardDate = moment(
                timesheets[0].timeCardDate
            );
            this.activeTimesheetDate = moment(timesheets[0].timeCardDate);
        } else {
            this.activeTimesheetDate = moment(this.activeTimesheetDate);
        }
        this.timesheetQuery = this.getTimesheetQuery();
        this.timesheetQuery.eventId = this.eventId;
        this.timesheetQuery.timeCardDate = this.activeTimesheetDate.format();
        this.timecardDisplayDate = _.find(
            this.timesheetDates,
            (x) =>
                x["dateString"] ==
                this.activeTimesheetDate.format("YYYY-MM-DD").toString()
        );
        this.payrolltimecarddate = this.timecardDisplayDate.dateString;
    }

    updatetimecardDate() {
        this.timecardDisplayDate = _.find(
            this.timesheetDates,
            (x) => x["dateString"] == this.payrolltimecarddate
        );
        //this.timesheetQuery.pageSize = 25;
        this.timesheetQuery.isTravel = true;
        this.timesheetQuery.isNonTravel = true;
    }

    setEmpTypeFilters(isReset, employeetypes) {
        let i, j, emptype, filter;

        for (i = 0; i < this.employeeTypeFilters.length; i++) {
            filter = this.employeeTypeFilters[i];

            if (isReset) {
                filter.isSelected = false;
                filter.isDisabled = false;
            } else {
                filter.isSelected = false;
                filter.isDisabled = true;
            }
            for (j = 0; j < employeetypes.length; j++) {
                emptype = employeetypes[j];
                if (emptype.description === filter.employeeType.description) {
                    filter.isSelected = true;
                    filter.isDisabled = false;
                }
            }
        }
    }
    getTimesheetHeader() {
        ////this.log.trace("getTimesheetHeader()");
        this._payrollService
            .getPayrollTimesheetHeader(this.timesheetQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    const chartnumber: Array<number> = [];
                    for (
                        let i = 0;
                        i < result.reference.statusSummary.length;
                        i++
                    ) {
                        chartnumber.push(
                            result.reference.statusSummary[i].count
                        );
                        this.doughnutChartLabels[i] =
                            result.reference.statusSummary[i].description;
                    }
                    // this.doughnutChartData = [];
                    // this.doughnutChartData[0] = chartnumber;
                    this.doughnutChartData = {
                        labels: this.doughnutChartLabels,
                        datasets: [
                            {
                                data: chartnumber,
                                backgroundColor: this.chartColors,
                            },
                        ],
                    };
                    // this.doughnutchartColors[0]["chartColors"] =
                    //     this.chartColors;
                    // this.doughnutchartColors[0]["borderWidth"] = 0;
                    this.chartData = result.reference.statusSummary;
                    this.chartDataLength = result.reference.totalTimesheets;
                    this.totalSubmittedTimesheets =
                        result.reference.totalSubmittedTimesheets;
                    if (this.setView.isPageHeaderReset) {
                        this.employeeTypeFilters =
                            result.reference.employeeTypeFilters;
                        this.setView.isPageHeaderReset = false;
                        const emptypes: any[] = [];
                        if (this.filter.employeeType) {
                            for (
                                let j = 0;
                                j < this.employeeTypeFilters.length;
                                j++
                            ) {
                                for (
                                    let i = 0;
                                    i < this.timesheetQuery.employeeType.length;
                                    i++
                                ) {
                                    const indexOfObject =
                                        this.timesheetQuery.employeeType.findIndex(
                                            (object) => {
                                                return (
                                                    object.id ===
                                                    this.employeeTypeFilters[j]
                                                        .employeeType.id
                                                );
                                            }
                                        );

                                    const eindexOfObject = emptypes.findIndex(
                                        (object) => {
                                            return (
                                                object.id ===
                                                this.employeeTypeFilters[j]
                                                    .employeeType.id
                                            );
                                        }
                                    );

                                    if (
                                        indexOfObject !== -1 &&
                                        eindexOfObject === -1
                                    ) {
                                        emptypes.push(
                                            this.employeeTypeFilters[j]
                                                .employeeType
                                        );
                                    }
                                }
                            }
                            this.timesheetQuery.employeeType =
                                _.cloneDeep(emptypes);
                            this.filter.employeeType = _.cloneDeep(emptypes);
                            this.setEmpTypeFilters(
                                false,
                                this.filter.employeeType
                            );
                        }
                    }
                    this.chartInit();
                    this.onDemandChangeDetection();
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to get timesheet information. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    checkMisMatch(mismatch) {
        if (mismatch) {
            return "default-mismatch";
        } else {
            return "";
        }
    }

    setUpdatedTimesheets(updatedTimesheets, newItems) {
        let i,
            j,
            item,
            tempItem,
            recordsRemoved = 0;

        for (i = 0; i < updatedTimesheets.length; i++) {
            item = updatedTimesheets[i];
            tempItem = _.find(newItems, { mpId: item.mpId });

            if (tempItem) {
                for (j = 0; j < this.timesheets.length; j += 1) {
                    if (tempItem.mpId === this.timesheets[j].mpId) {
                        if (
                            (this.filter.active &&
                                this.filter.employeeType &&
                                tempItem.eventPosition.employeeType
                                    .description ===
                                    this.filter.employeeType.description) ||
                            !this.filter.active
                        ) {
                            this.timesheets[j].status = tempItem.status;
                            this.timesheets[j].displayStatus =
                                tempItem.displayStatus;
                            this.timesheets[j].timesheetErrors =
                                tempItem.timesheetErrors;
                            this.timesheets[j].jobCodeMismatch =
                                tempItem.jobCodeMismatch;
                            this.timesheets[j].employeeTypeMismatch =
                                tempItem.employeeTypeMismatch;
                            this.timesheets[j].changed = false;

                            this.internalModel[j].status = tempItem.status;
                            this.internalModel[j].displayStatus =
                                tempItem.displayStatus;
                            this.internalModel[j].timesheetErrors =
                                tempItem.timesheetErrors;
                            this.internalModel[j].jobCodeMismatch =
                                tempItem.jobCodeMismatch;
                            this.internalModel[j].employeeTypeMismatch =
                                tempItem.employeeTypeMismatch;
                            this.internalModel[j].changed = false;

                            //TODO:Angular Upgrade : Form Pristine Imp
                            // this.payrollTimesheetForm["activityTimeForm_" + tempItem.mpId].$setPristine();
                        } else {
                            if (
                                this.filter.active &&
                                this.filter.employeeType &&
                                tempItem.eventPosition.employeeType
                                    .description !==
                                    this.filter.employeeType.description
                            ) {
                                recordsRemoved += 1;
                            }
                        }
                    }
                }
            }
        }

        // If records get removed, we need to pull again to the appropriate page.
        if (recordsRemoved > 0 && this.timesheets.length !== recordsRemoved) {
            this.getTimesheetByQuery(null);
            // if all records are removed when filtered,
            //  we need grab latest again and restart the search
        } else if (recordsRemoved === this.timesheets.length) {
            this.setEmpTypeFilters(true, []);
            this.restartSearch(() => {
                this.setView.isPageChange = false;
            });
        }

        this.setView.isPageHeaderReset = true;
        this.getTimesheetHeader();
    }

    restartSearch(callback) {
        this.timesheetQuery.currentPage = 1;
        this.getTimesheetByQuery(function () {
            if (typeof callback === "function") {
                callback();
            }
        });
    }
    approveTimesheet() {
        let i, item;
        //this.log.trace("approveTimesheet");
        this.submitted = true;

        const timesheets = _.cloneDeep(this.timesheets);
        const approvedTimesheets: any[] = [];

        for (i = 0; i < timesheets.length; i++) {
            item = timesheets[i];
            if (item.selected) {
                item.status = "Approved";
                if (item.contractRuleVariance) {
                    item.ruleVariance = item?.contractRuleVariance?.description;
                } else {
                    item.ruleVariance = item?.contractRuleVariance?.description;
                }
                approvedTimesheets.push(item);
            }
        }
        const payrollTimesheet = {
            timesheets: {
                items: approvedTimesheets,
            },
            event: this.event,
            timeCardDate: this.timeCardDate,
        };

        this._payrollService
            .savePayrollTimesheet(payrollTimesheet)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    //this.log.trace("approveTimesheet got results: " + JSON.stringify(result));
                    this.setUpdatedTimesheets(
                        approvedTimesheets,
                        result.reference.timesheets.items
                    );
                    this.getTimesheetHeader();
                    this.saveDisabled = true;
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to approve timesheet at this time. Please try again later."
                    );
                },
            });
    }

    validateDuration(item) {
        if (item.timeOut && item.timeIn) {
            const durationDifference = moment(item.timeOut).diff(
                item.timeIn,
                "days"
            );
            if (durationDifference > 1) {
                return true;
            }
        }
        return false;
    }

    bulkUpdateEmployeeType() {
        let i, item;
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            if (item.selected) {
                if (this.groupUpdate.selected.name === "Employee Type") {
                    if (
                        this.groupUpdate.updatedValues[
                            this.groupUpdate.selected.value
                        ] !== ""
                    ) {
                        item.eventPosition.employeeType = _.cloneDeep(
                            this.groupUpdate.updatedValues[
                                this.groupUpdate.selected.value
                            ]
                        );
                        if (item.isTk) {
                            this.setDefaultContractAndJobCode(item);
                        }
                    }
                    else{     
                        this.groupUpdate.updatedValues = _.cloneDeep(
                            this.defaultUpdatedValues
                        );                  
                        item.eventPosition.employeeType = _.cloneDeep(
                    this.defaultUpdatedValues
                );
                    }
                } else {
                    item[this.groupUpdate.selected.value] = _.cloneDeep(
                        this.groupUpdate.updatedValues[
                            this.groupUpdate.selected.value
                        ]
                    );
                }
                this.setChanged(item, null, null);
            }
        }
    }

    setDefaultContractAndJobCode(timesheet) {
        if (timesheet.isTk) {
            let contractCode: any = null;
            let jobCode: any = null;
            let indicator: any = null;
            let ruleVar: any = null;
            let glAccount: any = null; //SCORE 21795
            let dayCode: any = null;

            if (
                timesheet.eventPosition.employeeType.description === "DH" ||
                timesheet.eventPosition.employeeType.description === "DHR"
            ) {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code1;
                contractCode = _.filter(this.contractCodes, (e) => {
                    return e.name === "NABET A DLY_DLY180 5X8";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "NTA";
                });
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].indicators &&
                    contractCode[0].indicators.length > 0 &&
                    timesheet.eventPosition.employeeType.description ===
                        "DHR" &&
                    timesheet.eventPosition.hasDOOTActivityOnly === false
                ) {
                    indicator = _.filter(
                        contractCode[0].indicators,
                        function (e) {
                            return (
                                e.description &&
                                e?.description?.toUpperCase() ===
                                    "REMOTE LOCATION:OUT OF TOWN"
                            );
                        }
                    );
                }

                if (
                    timesheet.eventPosition.hasDOOTActivityOnly === true &&
                    timesheet.eventPosition.hasTravelActivityOnly === false
                ) {
                    dayCode = "OFF";
                } else {
                    dayCode = "REG";
                }
            }
            if (
                timesheet.eventPosition.employeeType.description === "FL" ||
                timesheet.eventPosition.employeeType.description === "FLR"
            ) {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code1;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "SPORTS FREELANCE FED";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "UNE";
                });
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].indicators &&
                    contractCode[0].indicators.length > 0 &&
                    timesheet.eventPosition.employeeType.description ===
                        "FLR" &&
                    timesheet.eventPosition.hasDOOTActivityOnly === false
                ) {
                    indicator = _.filter(
                        contractCode[0].indicators,
                        function (e) {
                            return (
                                e.description &&
                                e?.description?.toUpperCase() ===
                                    "OS REMOTE LOCATION:OUT OF TOWN"
                            );
                        }
                    );
                }
                if (
                    timesheet.eventPosition.employeeType.description ===
                        "FLR" &&
                    timesheet.eventPosition.hasDOOTActivityOnly === true &&
                    timesheet.eventPosition.hasTravelActivityOnly === false
                ) {
                    dayCode = "OFF";
                } else {
                    dayCode = "REG";
                }
            }
            if (
                timesheet.eventPosition.employeeType.description === "PEP" ||
                timesheet.eventPosition.employeeType.description === "PEPTRVL"
            ) {
                //SCORE 21795

                glAccount = this._payrollService.constObj.glAccount_code2;

                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "SPORTS FREELANCE FED";
                }); //SCOR-19737
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "UNE";
                });
            }
            if (timesheet.eventPosition.employeeType.description === "PEPCA") {
                //SCORE 23669

                glAccount = this._payrollService.constObj.glAccount_code2;

                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "SPORTS FREELANCE CA";
                }); //SCOR-23669
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "UNE";
                });
            }
            if (
                timesheet.eventPosition.employeeType.description === "RSNIATSE"
            ) {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code3;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "RSN IATSE ITR";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "ITR";
                });
            }
            if (
                timesheet.eventPosition.employeeType.description ===
                    "GOLFPEP" ||
                timesheet.eventPosition.employeeType.description ===
                    "GOLFPEPTRVL" ||
                timesheet.eventPosition.employeeType.description ===
                    "GOLFPEPTVL"
            ) {
                //SCORE 21795

                if (
                    timesheet.eventPosition.employeeType.description ===
                        "GOLFPEP" ||
                    timesheet.eventPosition.employeeType.description ===
                        "GOLFPEPTVL"
                ) {
                    glAccount = this._payrollService.constObj.glAccount_code2;
                }
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "PEP GOLF FED";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "UNE";
                });
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].indicators &&
                    contractCode[0].indicators.length > 0 &&
                    timesheet.eventPosition.employeeType.description ===
                        "GOLFPEPTVL"
                ) {
                    indicator = _.filter(
                        contractCode[0].indicators,
                        function (e) {
                            return (
                                e.description &&
                                e?.description?.toUpperCase() ===
                                    "FREELANCE TRAVEL:FREELANCETRAVEL"
                            );
                        }
                    );
                }
            }
            if (timesheet.eventPosition.employeeType.description === "DGA") {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code2;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "DGA AD_SM_FRL";
                });

                //SCORE 21795
                if (
                    timesheet.eventPosition.position.description === "DIRECTOR"
                ) {
                    jobCode = _.filter(this.jobCodes, function (e) {
                        return e.code === "DT1";
                    });
                } else {
                    jobCode = _.filter(this.jobCodes, function (e) {
                        return e.code === "DT2";
                    });
                }
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].ruleVariances.length > 0
                ) {
                    if (
                        timesheet.eventPosition?.rateCode?.id
                            ?.toLowerCase()
                            .includes("12 hour")
                    ) {
                        //only if the rate code contains 12 hour , default the rule variance
                        ruleVar = _.filter(
                            contractCode[0]?.ruleVariances,
                            function (e) {
                                return (
                                    e.description === "DGA AD_SM_FRL_DAILY_FLAT"
                                );
                            }
                        );
                    }
                }
            }
            if (
                timesheet.eventPosition.employeeType.description ===
                    "GOLFIATSE" ||
                timesheet.eventPosition.employeeType.description === "GOLFIATVL"
            ) {
                //SCORE 21795
                if (
                    timesheet.eventPosition.department?.toUpper() == "PRODUCTION"
                ) {
                    glAccount = this._payrollService.constObj.glAccount_code2;
                } else {
                    glAccount = this._payrollService.constObj.glAccount_code1;
                }
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "IATSE GOLF";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "IRG";
                });

                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].indicators &&
                    contractCode[0].indicators.length > 0 &&
                    timesheet.eventPosition.employeeType.description ===
                        "GOLFIATVL" &&
                    timesheet.eventPosition.hasTravelActivityOnly === false
                ) {
                    indicator = _.filter(
                        contractCode[0].indicators,
                        function (e) {
                            return (
                                e.description &&
                                e?.description?.toUpperCase() ===
                                    "FREELANCE TRAVEL:FREELANCETRAVEL"
                            );
                        }
                    );
                }
            }
            if (timesheet.eventPosition.employeeType.description === "RSNPEP") {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code3;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "RSN IATSE ITR";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "UNE";
                });
            }
            if (
                timesheet.eventPosition.employeeType.description === "RSN IBEW"
            ) {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code3;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "RSN IBEW ETR";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "ETR";
                });
            }
            if (
                timesheet.eventPosition.employeeType.description === "MNIATSE"
            ) {
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "NBCSN LOCAL745 ITM";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "ITM";
                });
            }

            if (timesheet.eventPosition.employeeType.description === "DHA") {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code2;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "NABET A DLY_DLY180 5X8";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "NTA";
                });
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].ruleVariances.length > 0 &&
                    timesheet.contact.isNabet
                ) {
                    ruleVar = _.filter(
                        contractCode[0]?.ruleVariances,
                        function (e) {
                            return (
                                e.description === "NABET A DLY 5X8_NO_FRINGE"
                            );
                        }
                    );
                }
            }

            if (timesheet.eventPosition.employeeType.description === "DHD") {
                //SCORE 21795
                glAccount = this._payrollService.constObj.glAccount_code2;
                contractCode = _.filter(this.contractCodes, function (e) {
                    return e.name === "NABET D DAILY";
                });
                jobCode = _.filter(this.jobCodes, function (e) {
                    return e.code === "NTA";
                });
                if (
                    contractCode &&
                    contractCode.length > 0 &&
                    contractCode[0].ruleVariances.length > 0 &&
                    timesheet.contact.isNabet
                ) {
                    ruleVar = _.filter(
                        contractCode[0]?.ruleVariances,
                        function (e) {
                            return e.description === "NABET D DAILY_NO FRINGE";
                        }
                    );
                }
            }

            timesheet.jobCode = jobCode ? jobCode[0] : null;

            if (timesheet.jobCode === null) {
                timesheet.rate = null;
            }

            //SCORE 21795
            timesheet.glAccount = glAccount ? glAccount : null;

            timesheet.dayCode = dayCode ? dayCode : timesheet.dayCode;

            const newContractCode = contractCode
                ? contractCode[0]
                : this.defaultContractCode;

            if (timesheet.contractCode !== newContractCode) {
                timesheet.contractCode = newContractCode;
                this.clearData(timesheet);
                this.populateSchedHrs(timesheet);
                this.checkDefaultEmployeeType(timesheet);
                if (indicator) {
                    timesheet.indicators = indicator;
                } else {
                    timesheet.indicators = [];
                }
                if (ruleVar) {
                    timesheet.contractRuleVariance = ruleVar[0];
                }

                this.setChanged(timesheet, null, null);
                timesheet.wageSchedule = this.getWageSchedule(
                    timesheet.contractCode.name
                );
            } else {
                timesheet.contractCode = newContractCode;
                if (indicator) {
                    timesheet.indicators = indicator;
                } else {
                    timesheet.indicators = [];
                }
                if (ruleVar) {
                    timesheet.contractRuleVariance = ruleVar[0];
                }

                this.setChanged(timesheet, null, null);
            }

            this.validateRate(timesheet);
            this.onDemandChangeDetection();
        }
    }

    checkDefaultEmployeeType(item) {
        if (item.contractCode) {
            item.employeeTypeMismatch =
                item.contractCode.name !== item.defaultContractCodeName;
        } else {
            item.employeeTypeMismatch = true;
        }
    }
    checkDefaultJobCode(item) {
        item.jobCodeMismatch =
            item.contractCode.defaultJobCode &&
            item.contractCode.defaultJobCode !== item.jobCode.code &&
            item.jobCode.code !== null;
        if (this.invalidHBCodeJobCodeCombinationsCheck(item)) {
            this.conflictJobCodeTimesheets = [];
            item.itemBeforeSelection = item.oldJobCode;
            this.conflictJobCodeTimesheets.push(item);
            this.confirmJobCodeMismatchModal();
        } else {
            item.oldJobCode = item.jobCode;
            this.onDemandChangeDetection();
            this.setChanged(item, null, null);
        }
    }

    confirmJobCodeMismatchModal() {
        let confirmMessage =
            "One or more of the contacts selected would have an invalid HB Code and Job Code combination ( <b>";
        for (let i = 0; i < this.conflictJobCodeTimesheets.length; i++) {
            confirmMessage =
                confirmMessage +
                this.conflictJobCodeTimesheets[i].contact.firstName +
                " " +
                this.conflictJobCodeTimesheets[i].contact.lastName;
            if (i < this.conflictJobCodeTimesheets.length - 1) {
                confirmMessage = confirmMessage + ", ";
            }
        }
        confirmMessage =
            confirmMessage + " </b> ). Are you sure you want to proceed?";

        //this.log.trace("Opening modal");
        const initialState: any = {
            self: this,
            modalTitle: "Confirm Contact Type Change",
            modalMessage: this.isJobCodeBulkUpdate
                ? confirmMessage
                : "The HB Code and Job Code selection is not a valid combination. Are you sure you want to proceed?",
            confirmText: "Proceed",
            cancelText: "Cancel",
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onRejection.subscribe((result) => {
            if (this.groupUpdate && this.groupUpdate.selected) {
                this.resetGroupUpdate1(this.timesheets);
            } else {
                const timesheet = this.getListItemById(
                    this.conflictJobCodeTimesheets[0].mpId
                );
                timesheet.jobCode =
                    this.conflictJobCodeTimesheets[0].itemBeforeSelection;
                timesheet.itemBeforeSelection = "";
                timesheet.jobCodeMismatch = false;
            }
            this.isJobCodeBulkUpdate = false;

            this.onDemandChangeDetection();
        });
        this.bsModalRef.content.onAffirmation.subscribe((result) => {
            let i, item;
            for (i = 0; i < this.timesheets.length; i++) {
                item = this.timesheets[i];
                if (item.selected) {
                    if (this.groupUpdate.selected.name === "Job Code") {
                        item.oldJobCode = item.jobCode;
                        item.jobCode = _.cloneDeep(
                            this.groupUpdate.updatedValues[
                                this.groupUpdate.selected.value
                            ]
                        );
                        item.rateCode = _.cloneDeep(
                            this.groupUpdate.updatedValues.rate
                        );
                        item.rate = _.cloneDeep(
                            this.groupUpdate.updatedValues.customRate
                        );
                    } else if (this.groupUpdate.selected.name === "Rate") {
                        item.rateCode = "00";
                        item.rate = _.cloneDeep(
                            this.groupUpdate.updatedValues.customRate
                        );
                    } else {
                        item[this.groupUpdate.selected.value] = _.cloneDeep(
                            this.groupUpdate.updatedValues[
                                this.groupUpdate.selected.value
                            ]
                        );
                    }
                    this.setChanged(item);
                } else {
                    this.setChanged(item);
                }
            }
            this.groupUpdate.selected = "";
            this.groupUpdate.updatedValues = _.cloneDeep(
                this.defaultUpdatedValues
            );
            this.checkFormValidity();
            this.isJobCodeBulkUpdate = false;
        });
    }

    invalidHBCodeJobCodeCombinationsCheck(item) {
        const invalidHBCodeJobCodes = this.invalidHBCodeJobCodeDataList;
        if (
            this.groupUpdate &&
            this.groupUpdate.selected &&
            this.groupUpdate.selected.name === "Job Code"
        ) {
            for (let i = 0; i < invalidHBCodeJobCodes.length; i++) {
                if (
                    invalidHBCodeJobCodes[i].hbCode ===
                    item.eventPosition.employeeType.description
                ) {
                    for (
                        let j = 0;
                        j < invalidHBCodeJobCodes[i].jobCodes.length;
                        j++
                    ) {
                        if (
                            invalidHBCodeJobCodes[i].jobCodes[j]
                                .jobCodeValue ===
                                this.groupUpdate.updatedValues.jobCode.code &&
                            this.groupUpdate.updatedValues.jobCode.code !== null
                        ) {
                            const jobCode =
                                invalidHBCodeJobCodes[i].jobCodes[j];
                            return this.evaluateValidJobCodeCombination(
                                jobCode,
                                item
                            );
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < invalidHBCodeJobCodes.length; i++) {
                if (
                    invalidHBCodeJobCodes[i].hbCode ===
                    item.eventPosition.employeeType.description
                ) {
                    for (
                        let j = 0;
                        j < invalidHBCodeJobCodes[i].jobCodes.length;
                        j++
                    ) {
                        if (
                            invalidHBCodeJobCodes[i].jobCodes[j]
                                .jobCodeValue === item.jobCode.code
                        ) {
                            const jobCode =
                                invalidHBCodeJobCodes[i].jobCodes[j];
                            return this.evaluateValidJobCodeCombination(
                                jobCode,
                                item
                            );
                        }
                    }
                }
            }
        }
        return false;
    }

    evaluateValidJobCodeCombination(jobCode, item) {
        // var jobCode = invalidHBCodeJobCodes[i].jobCodes[j];

        if (!jobCode) {
            return true;
        }
        if (
            this.groupUpdate &&
            this.groupUpdate.selected &&
            this.groupUpdate.selected.name === "Job Code"
        ) {
            if (jobCode.rateCodes && this.groupUpdate.updatedValues.rate) {
                // if no ratecode has been configured for the HBType, then restriction will be applied to all rate codes
                if (!jobCode.rateCodes || jobCode.rateCodes.length === 0) {
                    return true;
                }

                // if ratecode is configured in above configuration, we may need to see against specific ratecode from config.
                if (
                    _.find(jobCode.rateCodes, (o) => {
                        return (
                            o.rateCodeValue ===
                            this.groupUpdate.updatedValues.rate
                        );
                    })
                ) {
                    return true;
                }
                return false;
            }
        } else {
            if (jobCode.rateCodes && item.rateCode) {
                // if no ratecode has been configured for the HBType, then restriction will be applied to all rate codes
                if (!jobCode.rateCodes || jobCode.rateCodes.length === 0) {
                    return true;
                }

                // if ratecode is configured in above configuration, we may need to see against specific ratecode from config.
                if (this.groupUpdate.selected.name === "Rate") {
                    //verify with "00" if selected bulkupdate is 00, because 00 will be set if custom rate is applied
                    if (
                        _.find(jobCode.rateCodes, function (o) {
                            return o.rateCodeValue === "00";
                        })
                    ) {
                        return true;
                    }
                } else {
                    if (
                        _.find(jobCode.rateCodes, (o) => {
                            return o.rateCodeValue === item.rateCode;
                        })
                    ) {
                        return true;
                    }
                }
                return false;
            }
        }
        return true;
    }

    bulkUpdateContractCode() {
        let origContractCode, item, i, x, j, duplicateIndicator;
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            if (item.selected) {
                origContractCode = _.cloneDeep(item.contractCode);

                item.contractCode = this.groupUpdate.updatedValues.contractCode;
                item.defaultScheduledHours =
                    this.groupUpdate.updatedValues.contractCode.defaultScheduledHours;
                if (item.contractCode.name !== origContractCode.name) {
                    item.indicators = [];
                }
                if (item.isTk) {
                    item.wageSchedule = this.getWageSchedule(
                        item.contractCode.name
                    );
                }
                if (this.groupUpdate.updatedValues.ruleVariance) {
                    item.contractRuleVariance = _.cloneDeep(
                        this.groupUpdate.updatedValues.ruleVariance
                    );
                }

                if (item?.payrollProvider?.toLowerCase() === "tk") {
                    if (item.contractCode.name !== origContractCode.name) {
                        item.payTransactions = _.cloneDeep(
                            this.groupUpdate.updatedValues.payTransactions
                        );
                    }
                    if (item.contractCode.name === origContractCode.name) {
                        for (
                            x = 0;
                            x <
                            this.groupUpdate.updatedValues.payTransactions
                                .length;
                            x++
                        ) {
                            item.payTransactions.push(
                                _.cloneDeep(
                                    this.groupUpdate.updatedValues
                                        .payTransactions[x]
                                )
                            );
                        }
                    }
                }

                if (this.groupUpdate.updatedValues.indicators.length > 0) {
                    if (
                        item.contractCode.name === origContractCode.name &&
                        item.indicators.length > 0
                    ) {
                        x = 0;
                        for (
                            x;
                            x <
                            this.groupUpdate.updatedValues.indicators.length;
                            x++
                        ) {
                            duplicateIndicator = false;
                            j = 0;
                            for (j; j < item.indicators.length; j++) {
                                if (
                                    item.indicators[j].name ===
                                    this.groupUpdate.updatedValues.indicators[x]
                                        .name
                                ) {
                                    duplicateIndicator = true;
                                    break;
                                }
                            }
                            if (!duplicateIndicator) {
                                item.indicators.push(
                                    _.cloneDeep(
                                        this.groupUpdate.updatedValues
                                            .indicators[x]
                                    )
                                );
                            }
                        }
                    } else {
                        item.indicators = _.cloneDeep(
                            this.groupUpdate.updatedValues.indicators
                        );
                    }
                }

                this.setChanged(item, null, null);
            }
        }
    }

    bulkUpdateDefaultScheduledHours() {
        let i, item;
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            if (item.selected) {
                item.defaultScheduledHours = _.cloneDeep(
                    this.groupUpdate.updatedValues.defaultScheduledHours
                );
                this.setChanged(item, null, null);
            }
        }
    }

    bulkUpdateRate() {
        let i, item;
        this.conflictJobCodeTimesheets = [];
        if (this.groupUpdate.updatedValues.customRate) {
            for (i = 0; i < this.timesheets.length; i++) {
                item = this.timesheets[i];
                if (item.isTk && item.selected) {
                    if (this.invalidHBCodeJobCodeCombinationsCheck(item)) {
                        this.conflictJobCodeTimesheets.push(item);
                        this.isJobCodeBulkUpdate = true;
                    } else {
                        item.rate = _.cloneDeep(
                            this.groupUpdate.updatedValues.customRate
                        );
                        item.rateCode = "00";
                        this.setChanged(item, null, null);
                    }
                }
            }
        }
        if (this.conflictJobCodeTimesheets.length > 0) {
            this.confirmJobCodeMismatchModal();
        }
    }

    bulkUpdateJobCode() {
        let i, item;
        this.conflictJobCodeTimesheets = [];
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            if (item.isTk && item.selected) {
                if (this.groupUpdate.selected.name === "Job Code") {
                    if (this.invalidHBCodeJobCodeCombinationsCheck(item)) {
                        this.conflictJobCodeTimesheets.push(item);
                        this.isJobCodeBulkUpdate = true;
                    } else {
                        if (
                            (this.groupUpdate.updatedValues.rate === "00" &&
                                this.groupUpdate.updatedValues.customRate) ||
                            this.groupUpdate.updatedValues.rate !== "00"
                        ) {
                            item.jobCode = _.cloneDeep(
                                this.groupUpdate.updatedValues[
                                    this.groupUpdate.selected.value
                                ]
                            );
                            item.oldJobCode = item.jobCode;
                            item.rateCode = _.cloneDeep(
                                this.groupUpdate.updatedValues.rate
                            );
                            item.rate = _.cloneDeep(
                                this.groupUpdate.updatedValues.customRate
                            );
                            this.setChanged(item, null, null);
                        }
                    }
                }
            }
        }
        if (this.conflictJobCodeTimesheets.length > 0) {
            this.confirmJobCodeMismatchModal();
        }
    }

    bulkUpdateOther() {
        let i, updatedValues, item;
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            /*As the below operation is for bulk update of clock in/out and meal in/out times excluding if rate card is flat day - SCOR-11149*/
            if (item.selected && item.billCodeUnitCodeTypeCode !== "Dy") {
                //***********Code start -- to Check StartTime and EndTime

                //Check if CI,CO,MI and MO is null. Update Null Value. -US 21236 Code

                if (
                    this.groupUpdate.updatedValues.updatedStartTime == "" &&
                    this.groupUpdate.updatedValues.updatedEndTime == "" &&
                    this.groupUpdate.updatedValues.updatedMealStartTime == "" &&
                    this.groupUpdate.updatedValues.updatedMealEndTime == ""
                ) {
                    //updatedValues = this.groupUpdate.updatedValues.updatedStartTime; //
                    //item[this.groupUpdate.selected.value] = _.cloneDeep(updatedValues);
                    item.timeIn =
                        this.groupUpdate.updatedValues.updatedStartTime;
                    item.timeOut =
                        this.groupUpdate.updatedValues.updatedEndTime;
                    item.lunchIn =
                        this.groupUpdate.updatedValues.updatedMealStartTime;
                    item.lunchOut =
                        this.groupUpdate.updatedValues.updatedMealEndTime;

                    this.validateTimes(item);

                    item.calculatedDuration = this.calculateDuration(
                        item.timeIn,
                        item.timeOut,
                        item
                    );
                    item.durationError = this.validateDuration(item);
                    this.setChanged(item, null, null);
                }

                // US 21236
                // Added validation if values are update in bluck update it will get replace else the same value will remain as it for selecSted record
                else if (
                    this.groupUpdate.updatedValues.updatedStartTime &&
                    this.groupUpdate.updatedValues.updatedStartTime !== ""
                ) {
                    item.timeIn =
                        this.groupUpdate.updatedValues.updatedStartTime;
                }
                if (
                    this.groupUpdate.updatedValues.updatedEndTime &&
                    this.groupUpdate.updatedValues.updatedEndTime !== ""
                ) {
                    item.timeOut =
                        this.groupUpdate.updatedValues.updatedEndTime;
                }
                if (
                    this.groupUpdate.updatedValues.updatedMealStartTime &&
                    this.groupUpdate.updatedValues.updatedMealStartTime != ""
                ) {
                    item.lunchIn =
                        this.groupUpdate.updatedValues.updatedMealStartTime;
                }
                if (
                    this.groupUpdate.updatedValues.updatedMealEndTime &&
                    this.groupUpdate.updatedValues.updatedMealEndTime != ""
                ) {
                    item.lunchOut =
                        this.groupUpdate.updatedValues.updatedMealEndTime;
                }

                this.validateTimes(item);

                item.calculatedDuration = this.calculateDuration(
                    item.timeIn,
                    item.timeOut,
                    item
                );
                item.durationError = this.validateDuration(item);
                this.setChanged(item, null, null);
                // }

                //*****************Code End
                // Old code

                //updatedValues = this.groupUpdate.updatedValues.updatedTime;
                //item[this.groupUpdate.selected.value] = _.cloneDeep(updatedValues);
                //this.validateTimes(item);
                //item.calculatedDuration = this.calculateDuration(item.timeIn, item.timeOut, item);
                //item.durationError = this.validateDuration(item);
                //this.setChanged(item, null, null);
            }
        }
    }

    toggleEmployeeTypeFilter(item, isReset, isdoubleClick = false) {
        if (item) {
            const indexOfObject = this.timesheetQuery.employeeType.findIndex(
                (object) => {
                    return object.id === item.employeeType.id;
                }
            );
            const filterobjectindex = this.filter.employeeType.findIndex(
                (object) => {
                    return object.id === item.employeeType.id;
                }
            );

            if (indexOfObject !== -1) {
                this.timesheetQuery.employeeType.splice(indexOfObject, 1);
                isdoubleClick = true;
            } else {
                this.timesheetQuery.employeeType.push(item.employeeType);
                isdoubleClick = false;
            }

            if (filterobjectindex !== -1) {
                this.filter.employeeType.splice(filterobjectindex, 1);
            } else {
                this.filter.employeeType.push(item.employeeType);
            }
            if (!isdoubleClick) {
                item.isSelected = true;
                item.isDisabled = false;
                this.filter.showTravelOptions = true;
                this.filter.active = true;

                if (this.internalModel) {
                    this.resetGroupUpdate1(this.timesheets);
                    this.selectedFormsValid();
                }
            } else {
                this.filter.active = false;
                this.groupUpdate.selectAll = false;
                this.groupUpdate.status = false;
            }
        } else {
            this.timesheetQuery.employeeType = [];
            this.filter.employeeType = [];
            this.filter.showTravelOptions = false;
            this.filter.active = false;
            this.groupUpdate.selectAll = false;
            this.groupUpdate.status = false;
        }
        this.setEmpTypeFilters(
            this.timesheetQuery.employeeType.length == 0,
            this.timesheetQuery.employeeType
        );
    }

    cancelTimesheetChanges() {
        this.getTimesheetByQuery(() => {
            this.resetGroupUpdate1(this.internalModel);
            for (let i = 0; i < this.timesheets.length; i++) {
                this.validateTimes(this.timesheets[i]);
            }
        });
        this.isApproveEnabled = false;
        this.onDemandChangeDetection();
    }
    contractCodeChanged() {
        this.groupUpdate.updatedValues.ruleVariance = "";
        this.groupUpdate.updatedValues.payTransactions = [];
        this.groupUpdate.updatedValues.indicators = [];
        this.onDemandChangeDetection();
    }

    rerouteTimecard() {
        const url =
            "Payroll/timesheet/" +
            this.eventId +
            "/" +
            this.timecardDisplayDate.dateString;
        this._router.navigate([url], {});
        this.getTimesheets();
    }

    getTimesheets() {
        this.timesheetQuery.sortBy = "SortOrder";
        this.sort.orderByField = "";
        this.sort.reverseSort = this.defaultReverseSort;
        this.timesheets = null;
        //TODO:Angular Upgrade : FOrm Pristine
        //this.payrollTimesheetForm.$setPristine();
        //this.timeCardDate = moment(this.timecardDisplayDate.dateString);
        this.timesheetQuery.timeCardDate = moment(
            this.timecardDisplayDate.dateString
        ).format();
        this.toggleEmployeeTypeFilter(null, true);
        //window.location.hash = "#" + moment(this.timecardDisplayDate.dateString).format("YYYY-MM-DD");
        this.setView.isPageHeaderReset = true;
        this.setView.isPageChange = false;
        this.internalModel = null;
        this.totalRecords = 0;
        this.timesheets = null;
        this.getTimesheetHeader();
    }
    getNewPage() {
        this.setView.isPageChange = true;
        this.getTimesheetByQuery(null);
    }

    setChanged(item, value?, checkBlank?) {
        let setDirty = true;

        // Only avoid the check if we want to for initial records
        if (checkBlank === true && !value) {
            setDirty = false;
        }

        if (setDirty) {
            this.selectedFormsValid();
            this.isDirty = true;
        }

        // we stil want to be able to save when the item is changed to anything
        item.changed = true;
    }

    getValidationAttributes() {
        return {
            validation: {
                isIntimeValid: true,
                isOuttimeValid: true,
                isLunchInTimeValid: true,
                isLunchOutTimeValid: true,
                isPayrollCommentValid: true,
                isRateValid: true,
                isglAccountValid: true,
                isglAccountRequired: true,
                isdefaultScheduleValid: true,
                isIntimeRequired: true,
                isOuttimeRequired: true,
                isLunchInTimeRequired: true,
                isLunchOutTimeRequired: true,
                isValid: true,
            },
        };
    }

    validateDefaultScheduleHours(item) {
        if (item.isTk) {
            if (
                item.defaultScheduledHours > 24 ||
                item.defaultScheduledHours < 0 ||
                item.defaultScheduledHours === null ||
                item.defaultScheduledHours === undefined
            ) {
                item["isdefaultScheduleValid"] = false;
            } else {
                item["isdefaultScheduleValid"] = true;
            }
        } else {
            item["isdefaultScheduleValid"] = true;
        }
    }

    selectedFormsValid() {
        let i, item, isPayrollProviderValid, isTimeValid;
        this.isApproveEnabled = false;
        if (!(this.timesheets && this.timesheets.length > 0)) {
            return;
        }
        this.saveDisabled = true;
        let isTBDRecordSelected = true;
        this.isValid = true;

        for (i = 0; i < this.timesheets.length; i += 1) {
            item = this.timesheets[i];
            if (item.selected) {
                this.validateTimes(item);
                this.saveDisabled = false;
                isTimeValid = false;
                if (
                    item.payTransactions.length === 0 &&
                    item.billCodeUnitCodeTypeCode !== "Dy"
                ) {
                    item["isIntimeRequired"] = true;
                    item["isOuttimeRequired"] = true;
                } else {
                    item["isIntimeRequired"] = false;
                    item["isOuttimeRequired"] = false;
                    item["isLunchInTimeRequired"] = false;
                    item["isLunchOutTimeRequired"] = false;
                }

                if (item.isTk) {
                    if (
                        item.glAccount != null &&
                        item.glAccount != undefined &&
                        item.glAccount != "" &&
                        item.glAccount.length === 8
                    ) {
                        item["isglAccountValid"] = true;
                    } else {
                        item["isglAccountValid"] = false;
                    }
                }

                if (item["isIntimeRequired"] && !item.timeIn) {
                    item["isIntimeValid"] = false;
                }

                if (item["isOuttimeRequired"] && !item.timeOut) {
                    item["isOuttimeValid"] = false;
                }

                // Lets not validate when there is nothing set
                if (
                    item.timeIn ||
                    item.timeOut ||
                    item.lunchIn ||
                    item.lunchOut
                ) {
                    if (
                        item["isIntimeValid"] &&
                        item["isOuttimeValid"] &&
                        item["isLunchInTimeValid"] &&
                        item["isLunchOutTimeValid"]
                    ) {
                        isTimeValid = true;
                    }
                } else {
                    isTimeValid = true;
                }

                isPayrollProviderValid = !(
                    _.isEmpty(item.payrollProvider) ||
                    item.payrollProvider.toLowerCase() === "tbd"
                );

                if (item.isTk) {
                    if (
                        ((item["isIntimeRequired"] &&
                            item["isIntimeValid"] &&
                            item.timeIn) ||
                            !item["isIntimeRequired"]) &&
                        ((item["isOuttimeRequired"] &&
                            item["isOuttimeValid"] &&
                            item.timeOut) ||
                            !item["isOuttimeRequired"]) &&
                        item["isglAccountValid"] &&
                        item["isdefaultScheduleValid"] &&
                        item["isRateValid"] &&
                        isTimeValid
                    ) {
                        item["isValid"] = true;
                    } else {
                        item["isValid"] = false;
                    }
                } else if (
                    ((item["isIntimeRequired"] &&
                        item["isIntimeValid"] &&
                        item.timeIn) ||
                        !item["isIntimeRequired"]) &&
                    ((item["isOuttimeRequired"] &&
                        item["isOuttimeValid"] &&
                        item.timeOut) ||
                        !item["isOuttimeRequired"]) &&
                    item["isRateValid"] &&
                    isTimeValid
                ) {
                    item["isValid"] = true;
                } else {
                    item["isValid"] = false;
                }

                if (this.isValid && !item["isValid"]) {
                    this.isValid = false;
                }

                if (!isPayrollProviderValid && isTBDRecordSelected) {
                    isTBDRecordSelected = false;
                }
            } else {
                if(item.billCodeUnitCodeTypeCode?.toUpperCase() !== "DY"){
                item["isIntimeRequired"] = true;
                item["isOuttimeRequired"] = true;}
            }
        }

        if (this.isValid) {
            this.isApproveEnabled = true;
        } else {
            this.isApproveEnabled = false;
        }

        // we need to record any instance which may have payroll provider as TBD. above logic is contradictory to 4206
        if (!isTBDRecordSelected) {
            this.isApproveEnabled = false;
        }
    }

    sortByColumn(column) {
        if (
            this.sort.orderByField === column &&
            this.sort.reverseSort !== this.defaultReverseSort
        ) {
            this.timesheetQuery.sortBy = "";
            this.sort.orderByField = "";
            this.sort.reverseSort = this.defaultReverseSort;
        } else if (this.sort.orderByField === column) {
            this.timesheetQuery.sortBy = "-" + column;
            this.sort.reverseSort = !this.sort.reverseSort;
        } else {
            this.timesheetQuery.sortBy = column;
            this.sort.orderByField = column;
            this.sort.reverseSort = this.defaultReverseSort;
        }

        this.timesheetQuery.currentPage = 1;
        this.getTimesheetByQuery(null);
    }

    FormsValid() {
        let i, item, isPayrollProviderValid, isAllvalid, isTimeValid;
        isAllvalid = true;
        if (!(this.timesheets && this.timesheets.length > 0)) {
            return false;
        }
        for (i = 0; i < this.timesheets.length; i += 1) {
            item = this.timesheets[i];
            isTimeValid = false;
            this.validateTimes(item);
            if (item.changed && item.selected) {
                if (
                    item.payTransactions.length === 0 &&
                    item.billCodeUnitCodeTypeCode !== "Dy"
                ) {
                    item["isIntimeRequired"] = true;
                    item["isOuttimeRequired"] = true;
                } else {
                    item["isIntimeRequired"] = false;
                    item["isOuttimeRequired"] = false;
                    item["isLunchInTimeRequired"] = false;
                    item["isLunchOutTimeRequired"] = false;
                }

                if (item["isIntimeRequired"] && !item.timeIn) {
                    item["isIntimeValid"] = false;
                }

                if (item["isOuttimeRequired"] && !item.timeOut) {
                    item["isOuttimeValid"] = false;
                }

                // Lets not validate when there is nothing set
                if (
                    item.timeIn ||
                    item.timeOut ||
                    item.lunchIn ||
                    item.lunchOut
                ) {
                    if (
                        item["isIntimeValid"] &&
                        item["isOuttimeValid"] &&
                        item["isLunchInTimeValid"] &&
                        item["isLunchOutTimeValid"]
                    ) {
                        isTimeValid = true;
                    }
                } else {
                    isTimeValid = true;
                }

                isPayrollProviderValid = !(
                    _.isEmpty(item.payrollProvider) ||
                    item.payrollProvider.toLowerCase() === "tbd"
                );

                if (item.isTk) {
                    if (
                        ((item["isIntimeRequired"] &&
                            item["isIntimeValid"] &&
                            item.timeIn) ||
                            !item["isIntimeRequired"]) &&
                        ((item["isOuttimeRequired"] &&
                            item["isOuttimeValid"] &&
                            item.timeOut) ||
                            !item["isOuttimeRequired"]) &&
                        item["isglAccountValid"] &&
                        item["isdefaultScheduleValid"] &&
                        item["isRateValid"] &&
                        isTimeValid
                    ) {
                        item["isValid"] = true;
                    } else {
                        item["isValid"] = false;
                    }
                } else if (
                    ((item["isIntimeRequired"] &&
                        item["isIntimeValid"] &&
                        item.timeIn) ||
                        !item["isIntimeRequired"]) &&
                    ((item["isOuttimeRequired"] &&
                        item["isOuttimeValid"] &&
                        item.timeOut) ||
                        !item["isOuttimeRequired"]) &&
                    item["isRateValid"] &&
                    isTimeValid
                ) {
                    item["isValid"] = true;
                } else {
                    item["isValid"] = false;
                }

                if (isAllvalid && !item["isValid"]) {
                    isAllvalid = false;
                }
            }
        }

        return isAllvalid;
    }

    CheckDirtySelected() {
        let i, item, isSelectedDirty;
        isSelectedDirty = false;
        if (!(this.timesheets && this.timesheets.length > 0)) {
            return false;
        }
        for (i = 0; i < this.timesheets.length; i += 1) {
          item = this.timesheets[i];          
            if (item.changed && item.selected) {
                if (!isSelectedDirty) isSelectedDirty = true;
            }
        }
        //refresh
        return isSelectedDirty;
    }

    setFrozenHeader() {
        setTimeout(
            () =>
                this._eventBus.emit(
                    new EmitEvent("groupUpdate", "fullScreenGroupUpdate", "")
                ),
            100
        );
    }

    groupUpdateSelect(item) {
        if (item.selected) {
            item.selected = false;
        } else {
            item.selected = true;
        }

        if (item.selected) {
            this.groupUpdate.count++;
        } else {
            this.groupUpdate.count--;
            this.groupUpdate.selectAll = false;
            this.groupUpdate.status = false;
        }
        if (this.groupUpdate.count === 0) {
            this.resetGroupUpdate1(this.timesheets);
            this.groupUpdate.selectAll = false;
        } else {
            this.groupUpdate.status = true;
        }
        if (this.groupUpdate.count === this.timesheets.length) {
            this.groupUpdate.selectAll = true;
        }
        this.onDemandChangeDetection();
        this.setFrozenHeader();
    }

    resetGroupUpdate() {
        this.resetGroupUpdate1(this.timesheets);
        this.onDemandChangeDetection();
        this.setFrozenHeader();
    }

    resetGroupUpdate1(model) {
        let i;
        this.groupUpdate.status = false;
        this.groupUpdate.submitted = false;
        this.groupUpdate.selectAll = false;
        this.groupUpdate.selected = "";
        this.glAccountSearchTerm = "";
        this.groupUpdate.count = 0;
        this.groupUpdate.updatedValues = _.cloneDeep(this.defaultUpdatedValues);

        if (model) {
            for (i = 0; i < model.length; i++) {
                model[i].selected = false;
            }
        }
    }

    selectAll() {
        let i, item;
        this.groupUpdate.status = !this.groupUpdate.selectAll;
        if (!this.groupUpdate.selectAll) {
            this.groupUpdate.count = this.timesheets.length;
        } else {
            this.resetGroupUpdate1(this.internalModel);
        }
        for (i = 0; i < this.timesheets.length; i++) {
            item = this.timesheets[i];
            item.selected = this.groupUpdate.status;
        }
        this.onDemandChangeDetection();
        this.setFrozenHeader();
    }
    clearData(item) {
        item.ruleVariance = {};
        item.contractRuleVariance = {};
        item.indicators = [];
        item.payTransactions = [];
    }
    populateSchedHrs(item) {
        item.defaultScheduledHours = item.contractCode
            ? item.contractCode.defaultScheduledHours
            : "";
        this.validateDefaultScheduleHours(item);
    }
    checkDuplicateTags($tag, item) {
        // This checks for duplicate tags -- we should use the new version of tags input in the future - AT
        let tag, index;
        if ($tag && item) {
            tag = _.filter(item.indicators, { id: $tag.id });
            if (tag && item.indicators.length > 0 && tag.length > 0) {
                if (tag.length > 1) {
                    index = item.indicators.indexOf($tag);
                    item.indicators.splice(index, 1);
                }
            }
        }
    }
    searchGlAccounts() {
        this.glAccountObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.glAccountSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._payrollService.searchGlAccounts(
                    encodeURIComponent(token)
                );
            })
        );
    }
    onGLAccountValueSelected($event) {
        const glAccount = $event.item;
        this.groupUpdate.updatedValues.glAccount = glAccount;
    }
    onSelectGLAccount(selected) {
        const timesheet = this.getListItemById(selected.mpId);
        const glAccount = selected.glAccount;
        timesheet.glAccount = glAccount;
        timesheet["isglAccountValid"] = timesheet.glAccount.length === 8;
        this.setChanged(timesheet);
        this.checkFormValidity();
    }

    onIndicatorSelected(selected, indicators) {
        const timesheet = this.getListItemById(selected.mpId);
        timesheet.indicators = indicators;
        this.setChanged(timesheet);
        this.checkFormValidity();
        this.onDemandChangeDetection();
    }

    onBulkIndicatorSelected(indicators) {
        this.groupUpdate.updatedValues.indicators = indicators;
        this.onDemandChangeDetection();
    }

    getListItemById(id: any) {
        return _.find(this.timesheets, (item) => item.mpId == id);
    }

    InitialiseEventHandlers() {
        this.subs.sink = this._eventBus.on("onSelectGLAccount", (data) =>
            this.onSelectGLAccount(data)
        );
    }

    checkFormValidity() {
        this.selectedFormsValid();
    }
    validateRate(item) {
        //Rate must be between 0 and 6000
        if (
            item.rate > 6000 ||
            (item.rate <= 0 && item.rateCode === "00") ||
            (item.rate == null && !item.isTk)
        ) {
            item["isRateValid"] = false;
        } else {
            item["isRateValid"] = true;
        }
        this.onDemandChangeDetection();
    }
    cancelRateAlert(item, event) {
        event.target.focus();
        //var item = item;
        if (item.defaultRate) {
            item.rate = item.defaultRate;
        } else {
            item.rate = "";
        }
    }

    onTimeSelected(event, item, index?, formControlName?) {
        if (formControlName) {
            item[formControlName] = event;
        }
        this.validateTimes(item);
    }

    incrementDate(dates) {
        let timeDifference;
        //date = moment(date).local();
        //date2 = moment(date2).local();

        if (
            dates.date &&
            dates.date2 &&
            moment(this.getDateString(dates.date)).diff(
                this.getDateString(dates.date2),
                "days"
            ) === 0
        ) {
            timeDifference =
                this.getMinFromTime(dates.date2) -
                this.getMinFromTime(dates.date);

            if (timeDifference < 0) {
                dates.date2 = moment(dates.date2)
                    .local()
                    .add(1, "days")
                    .toDate();
            }
        }
        return dates;
    }

    getDateString(date) {
        return moment(date).format("YYYY-MM-DD");
    }
    getMinFromTime(time) {
        const date = new Date(time);
        const minutes = date.getMinutes(),
            hours = date.getHours(),
            minutesInHour = 60;

        return hours * minutesInHour + minutes;
    }
    validateTimes(item) {
        // Lets reset the validity first
        item["isValid"] = true;
        item["isIntimeValid"] = true;
        item["isOuttimeValid"] = true;
        item["isLunchInTimeValid"] = true;
        item["isLunchOutTimeValid"] = true;

        if (
            item.payTransactions.length === 0 &&
            item.billCodeUnitCodeTypeCode !== "Dy"
        ) {
            item["isIntimeRequired"] = true;
            item["isOuttimeRequired"] = true;
            item["isLunchInTimeRequired"] = true;
            item["isLunchOutTimeRequired"] = true;
        } else {
            item["isIntimeRequired"] = false;
            item["isOuttimeRequired"] = false;
            item["isLunchInTimeRequired"] = false;
            item["isLunchOutTimeRequired"] = false;
        }

        // Lets not validate when there is nothing set
        if (!item.timeIn && !item.timeOut && !item.lunchIn && !item.lunchOut) {
            return;
        }

        // Time Out should not be before or equal to Time In for Time In
        if (item.timeOut <= item.timeIn) {
            item["isIntimeValid"] = false;
        }

        // Time Out should not be before or equal to Time In for Time Out
        if (item.timeIn >= item.timeOut) {
            item["isOuttimeValid"] = false;
        }

        // Time Out should be there if Time in is Entered
        if (item.timeIn && !item.timeOut) {
            item["isOuttimeValid"] = false;
        }

        // Time In should be there if Time Out is Entered
        if (!item.timeIn && item.timeOut) {
            item["isIntimeValid"] = false;
        }

        // Time In Cannot Equal Time Out
        if (
            item.timeIn &&
            item.timeOut &&
            item.timeIn.valueOf() === item.timeOut.valueOf()
        ) {
            item["isIntimeValid"] = false;
            item["isOuttimeValid"] = false;
        }

        // Start Time Cannot Be a different Day with String Compare
        if (
            moment(item.timeIn).format("MM/DD/YYYY") !==
            moment(this.timesheetQuery.timeCardDate).format("MM/DD/YYYY")
        ) {
            item["isIntimeValid"] = false;
        } else {
            item["isIntimeValid"] = true;
        }

        // Day cannot be greater than 48 hours
        if (moment(item.timeOut).diff(item.timeIn, "days") > 1) {
            item["isValid"] = false;
            item["isIntimeValid"] = false;
            item["isOuttimeValid"] = false;
        }

        // If we only have time in and time out and not lunch then we should stop validation
        if (item.timeIn && item.timeOut && !item.lunchIn && !item.lunchOut) {
            return;
        }

        // Lunch Validation
        if (item.lunchIn || item.lunchOut) {
            if (!item.lunchIn) {
                item["isLunchInTimeValid"] = false;
            }
            if (!item.lunchOut) {
                item["isLunchOutTimeValid"] = false;
            }
            if (item.lunchIn <= item.timeIn || item.lunchIn >= item.timeOut) {
                item["isLunchInTimeValid"] = false;
            }
            if (item.lunchOut <= item.timeIn || item.lunchOut >= item.timeOut) {
                item["isLunchOutTimeValid"] = false;
            }
            if (item.lunchIn >= item.lunchOut) {
                item["isLunchInTimeValid"] = false;
            }
            if (item.lunchOut <= item.lunchIn) {
                item["isLunchOutTimeValid"] = false;
            }
        }

        // Lunch In Cannot Equal Lunch Out
        if (
            item.lunchIn &&
            item.lunchOut &&
            item.lunchIn.valueOf() === item.lunchOut.valueOf()
        ) {
            item["isLunchInTimeValid"] = false;
            item["isLunchOutTimeValid"] = false;
        }

        // Lunch In Cannot Equal Time In
        if (
            item.lunchIn &&
            item.timeIn &&
            item.lunchIn.valueOf() === item.timeIn.valueOf()
        ) {
            item["isLunchInTimeValid"] = false;
            item["isLunchOutTimeValid"] = false;
        }

        // Time Out Cannot Equal Lunch Out
        if (
            item.lunchOut &&
            item.timeOut &&
            item.lunchOut.valueOf() === item.timeOut.valueOf()
        ) {
            item["isOuttimeValid"] = false;
            item["isLunchOutTimeValid"] = false;
        }
    }

    validateRateVal(item, event) {
        if (item.rate) {
            const num = parseFloat(item.rate);
            item.rate = num.toFixed(3);
        }
        if (item.rate > 250) {
            //this.log.trace("Opening modal");
            const initialState: any = {
                self: this,
                modalTitle: "High Hourly Rate",
                modalMessage:
                    "Hourly rate over $250.00 has been entered!  Please select “Proceed” if you wish to continue.",
                confirmText: "Proceed",
                cancelText: "Cancel",
            };
            const modalOptions = {
                animated: true,
                initialState: initialState,
                keyboard: false,
            };

            this.bsModalRef = this._modalService.show(
                NbcModalConfirmation,
                modalOptions
            );
            this.bsModalRef.content.onRejection.subscribe((result) => {
                this.cancelRateAlert(item, event);
                this.onDemandChangeDetection();
            });
        }
    }

    validatePayrollComment(item) {
        //Payroll Comment must not be over 6000
        if (item.payrollComment > 6000 || item.payrollComment < 0) {
            item["isPayrollCommentValid"] = false;
        } else {
            item["isPayrollCommentValid"] = true;
        }
        this.onDemandChangeDetection();
    }
    checkDuration(item) {
        if (item.timeOut && item.timeIn) {
            const durationDifference = moment(item.timeOut).diff(
                item.timeIn,
                "days"
            );
            if (durationDifference > 1) {
                item.durationError = true;
            } else {
                item.durationError = false;
            }
        }
    }
    setDuration(item) {
        item.calculatedDuration = this.calculateDuration(
            item.timeIn,
            item.timeOut,
            item
        );
        item.durationError = this.validateDuration(item);
    }

    setWageSchedule(item) {
        item.wageSchedule = this.getWageSchedule(item.contractCode.name);
    }
    openPayrollApprovalModal() {
        //this.log.trace("Opening modal");
        const initialState: any = {
            self: this,
            modalTitle: "Approve Activities?",
            modalMessage:
                "You are about to approve timesheet activities. Would you like to continue?",
            confirmText: "Yes, Approve",
            cancelText: "No, Cancel",
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result) => {
            this.approveTimesheet();
        });
    }
    openPayTransactionsModal(item, bulk) {
        let filteredIndex = -1;
        const initialState: any = {
            self: this,
            items: bulk ? this.groupUpdate.updatedValues : item,
            payTransactions: bulk
                ? this.groupUpdate.updatedValues.payTransactions
                : item.payTransactions,
        };

        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            PayTransactionComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            if (bulk) {
                this.groupUpdate.updatedValues.payTransactions = _.cloneDeep(
                    result.payTransactions
                );
            } else {
                if (filteredIndex > -1) {
                    this.timesheets[filteredIndex] = _.cloneDeep(result);
                    this.setChanged(this.timesheets[filteredIndex]);
                }
            }

            this.onDemandChangeDetection();
        });

        if (!bulk) {
            filteredIndex = this.timesheets.indexOf(item);
        }
    }
    onDemandChangeDetection() {
        setTimeout(() => this.cd.detectChanges(), 100);
    }
    saveTimesheet(callback) {
        //this.log.trace("saveTimesheet");
        let i, item;
        const changedTimesheets: any[] = [];
        this.submitted = true;
        const timesheets = _.cloneDeep(this.timesheets);
        for (i = 0; i < timesheets.length; i++) {
            item = timesheets[i];
            //SCOR-9986 Now we will save only selected timesheets
            if (item.changed || item.selected) {
                item.status = "Pending Approval";
                item.updatedBy = this.authorName;
                item.updatedDate = new Date();
                if (item.contractRuleVariance) {
                    item.ruleVariance = item?.contractRuleVariance?.description;
                } else {
                    item.ruleVariance = item?.contractRuleVariance?.description;
                }
                changedTimesheets.push(item);
            }
        }
        const payrollTimesheet = {
            timesheets: {
                items: changedTimesheets,
            },
            event: this.event,
            timeCardDate: this.timeCardDate,
        };
      this.onDemandChangeDetection();
      if (changedTimesheets.length === 0) {
        this.log.clearAlert();
          this.log.showInfo("No changes detected");
            return;
        }

        this._payrollService
            .savePayrollTimesheet(payrollTimesheet)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.setUpdatedTimesheets(
                        changedTimesheets,
                        result.reference.timesheets.items
                    );
                    this.getTimesheetHeader();
                this.saveDisabled = true;                
                    if (typeof callback === "function") {
                        callback();
                    }
                },
                error: (err) => {
                    if (err.message.length > 0) {
                        this.log.showError(err.error || err.message);
                    } else {
                        this.log.showError(
                            "Unable to save timesheet at this time. Please try again later."
                        );
                    }
                    this.log.error(err);
                },
            });
    }

    unsavedChangesModal(callback, disableSave) {
        //if (confirmText == null)
        //  confirmText = "Continue"
      //this.onDemandChangeDetection();

        const initialState: any = {
            self: this,
            modalTitle: "Unsaved Changes",
            modalMessage: "You have unsaved changes, Please select an option.",
            confirmText: "Discard Changes",
            cancelText: "Cancel",
            saveText: "Save Changes",
            disableSave: disableSave,
        };
        const modalOptions = {
            animated: true,
            ignoreBackdropClick: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsDirtyCheckModalRef = this._modalService.show(
            NbcModalDirtyCheckComponent,
            modalOptions
        );
        this.bsDirtyCheckModalRef.content.onAffirmation.subscribe(() => {
            if(this.groupUpdate?.selected?.name)
            {
                this.proceedGroupUpdate();
            }
            this.saveTimesheet(callback);
            this.resetGroupUpdate1(this.timesheets);
            callback();
        });
      this.bsDirtyCheckModalRef.content.onRejection.subscribe(() => {
        //this.onDemandChangeDetection();
            this.timesheetQuery.currentPage = _.cloneDeep(
                this.previousSettings.currentPage
            );
            this.timesheetQuery.pageSize = _.cloneDeep(
                this.previousSettings.pageSize
            );
            this.timecardDisplayDate = _.cloneDeep(
                this.previousSettings.timecardDisplayDate
            );
            this.payrolltimecarddate = this.timecardDisplayDate.dateString;
            this.onDemandChangeDetection();
        });
        this.bsDirtyCheckModalRef.content.onDisregard.subscribe(() => {
            this.isDirty = false;
            //this.restartSearch(null);
            callback();
            this.resetGroupUpdate1(this.timesheets);
        });
      
    }

    getInvalidHBJobCodeCombinations() {
        //this.log.trace("getInvalidHBJobCodeCombinations");
        this._payrollService
            .getInvalidHBJobCodeCombinations()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.invalidHBCodeJobCodeDataList = result.reference;
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to get invalid HBJobCode Combinations. Please try again later."
                    );
                },
            });
    }

    /**
     * Filter rate codes based on selected job code
     * @memberof score.olympics.controllers.payrollTimesheetController
     * @function filterRateCodes
     * @public
     */
    filterRateCodes() {
        this.rateCodes = _.flatten(
            this.jobCodes
                .filter((jobCode) => {
                    return (
                        jobCode.code ===
                        this.groupUpdate.updatedValues.jobCode.code
                    );
                })
                .map(function (jobCode) {
                    return jobCode.details;
                })
        );
        this.onDemandChangeDetection();
    }

    trackByMpId(index: number, item: any) {
        return item.mpId;
    }

    getJobCodes() {
        //this.log.trace("payroll -> getJobCodes called");
        this._payrollService
            .getJobCodes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.jobCodes = result.reference;
                    this.jobCodesGroups = _.clone(result.reference);
                },
                error: (err) => {
                    this.log.showError("failed to load jobcodes");
                },
            });
    }

    getContractCodes() {
        //this.log.trace("payroll -> contractcode called");
        this._payrollService
            .getContractCodes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.contractCodes = result.reference;
                },
                error: (err) => {
                    this.log.showError("failed to load jobcodes");
                },
            });
    }

    getEmployeeTypes() {
        //this.log.trace("payroll -> getemployeeTypes called");
        this._employeeTypeService
            .getAllEmployeeTypes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.employeeTypes = result.reference;
                    if (this.employeeTypes && this.employeeTypes.length > 0) {
                        this.defaultUpdatedValues.employeeType =
                            this.employeeTypes[0];
                    }
                },
                error: (err) => {
                    this.log.showError("Failed to load Employee Types");
                },
            });
    }

    getEvent() {
        //this.log.trace("payroll -> getEvent called");
        this._eventService
            .getEventInfoDetails(this.eventId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    //this.log.trace("Payroll data");
                    this.event = data.reference;
                },
                error: (err) => {
                    this.log.showError("failed to load Events");
                    this.log.error(err);
                },
            });
    }

    getTimecardDetailsByEventId() {
        this._payrollService
            .getPayrollTimecardDates(this.eventId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.setTimesheetDates(result.reference);
                    this.getTimesheetHeader();
                    this.onDemandChangeDetection();
                },
                error: (error) => {},
            });
    }

    /*  This function will call when double click on the Employee Type in Pay Roll screen */
    searchAllEmployeeFilter(item) {
        this.isSingleClick = false;

        this.toggleEmployeeTypeFilter(null, true);
        //TODO:Angular Upgrade : Ng form dirty
        this.checkDirtyForm(this.restartSearch, [true]);
    }
    searchEmployeeFilter(item, blnclick) {
        this.isSingleClick = blnclick;
        setTimeout(() => {
            this.toggleEmployeeTypeFilter(
                item,
                blnclick,
                this.timesheetQuery.employeeType.includes(item.employeeType)
            );
            this.restartSearch(null);
        }, 250);
    }

    hasUnsavedData() {
        let i, item;
        //usSpinnerService.spin("generalSpinner");
        const timesheets = _.cloneDeep(this.timesheets);
        for (i = 0; i < timesheets.length; i++) {
            item = timesheets[i];
            //SCOR-9986 Now we will save only selected timesheets
            if (item.changed && item.selected) {
                return true;
            }
        }
        return false;
    }

    searchPage(pageSize, method) {
        this.timesheetQuery.pageSize = pageSize;
        this.checkDirtyForm(method);
    }

    checkDirtyForm(method, params?) {
        if (this.CheckDirtySelected()) {
            this.unsavedChangesModal(() => {
                method.apply(this, params);
            }, !this.FormsValid());
        } else {
            this.previousSettings.currentPage = _.cloneDeep(
                this.timesheetQuery.currentPage
            );
            method.apply(this, params);
        }
    }

    getTimesheetQuery() {
        return this._remotesQueryService.getPayrollQuery();
    }

    groupUpdateBtn() {
        this.groupUpdate.submitted = true;
        if (
            this.groupUpdate.selected.name === "Rate" ||
            (this.groupUpdate.selected.name === "Job Code" &&
                this.groupUpdate.updatedValues.rate === "00")
        ) {
            if (this.groupUpdate.updatedValues.customRate <= 6000) {
                if (this.groupUpdate.updatedValues.customRate > 250) {
                    const num = parseFloat(
                        this.groupUpdate.updatedValues.customRate
                    );
                    this.groupUpdate.updatedValues.customRate = num.toFixed(3);
                    //this.log.trace("Opening modal");
                    const initialState: any = {
                        self: this,
                        modalTitle: "High Hourly Rate",
                        modalMessage:
                            "Hourly rate over $250.00 has been entered!  Please select “Proceed” if you wish to continue.",
                        confirmText: "Proceed",
                        cancelText: "Cancel",
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false,
                    };

                    this.bsModalRef = this._modalService.show(
                        NbcModalConfirmation,
                        modalOptions
                    );
                    this.bsModalRef.content.onAffirmation.subscribe(
                        (result) => {
                            this.proceedGroupUpdate();
                        }
                    );
                    this.bsModalRef.content.onRejection.subscribe((result) => {
                        this.resetGroupUpdate();
                    });
                } else {
                    this.proceedGroupUpdate();
                }
            }
        } else if (
            this.groupUpdate.selected.name === "Default Scheduled Hours"
        ) {
            if (this.groupUpdate.updatedValues.defaultScheduledHours <= 24) {
                this.proceedGroupUpdate();
            }
        } else if (this.groupUpdate.selected.name === "Contract Code") {
            if (
                this.groupUpdate.updatedValues.contractCode !== null &&
                this.groupUpdate.updatedValues.contractCode !== ""
            ) {
                this.proceedGroupUpdate();
            }
        }
        // Added for time check - US 21236
        else if (this.groupUpdate.selected.name === "Time") {
            this.proceedGroupUpdate();
        } else {
            this.proceedGroupUpdate();
        }
        this.onDemandChangeDetection();
    }

    savePayrollComment(item) {
        //this.log.trace("save payroll comment");
        if (item.payrollUserComments && item.payrollUserComments.length > 255) {
            item.payrollUserComments = item.payrollUserComments.substring(
                0,
                255
            );
        }
        const currentTimesheetDate = moment(
            this.timecardDisplayDate.date
        ).format("DD-MMM-YYYY");
        if (item.contractRuleVariance) {
            item.ruleVariance = item.contractRuleVariance.description;
        } else {
            item.ruleVariance = "";
        }
        this._payrollService
            .savePayrollComment(this.event.id, currentTimesheetDate, item)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    //this.log.trace("saveComment - got results: " + JSON.stringify(result));
                },
                error: (error) => {
                    this.log.error("got an error: " + JSON.stringify(error));
                    this.log.showError(
                        "Unable to save comments at this time. Please try again later."
                    );
                },
            });
    }

    proceedGroupUpdate() {
        //if (this.payrollBulkUpdateForm.$valid) {
        switch (this.groupUpdate.selected.name) {
            case "GL Account":
            case "Employee Type":
                this.bulkUpdateEmployeeType();
                break;
            case "Contract Code":
                this.bulkUpdateContractCode();
                this.groupUpdate.selected = "";
                this.groupUpdate.updatedValues = _.cloneDeep(
                    this.defaultUpdatedValues
                );
                this.checkFormValidity();
                break;
            case "Default Scheduled Hours":
                this.bulkUpdateDefaultScheduledHours();
                this.groupUpdate.selected = "";
                this.groupUpdate.updatedValues = _.cloneDeep(
                    this.defaultUpdatedValues
                );
                this.checkFormValidity();
                break;
            case "Job Code":
                this.bulkUpdateJobCode();
                this.checkFormValidity();
                break;
            case "Rate":
                this.bulkUpdateRate();
                this.checkFormValidity();
                break;
            default:
                this.bulkUpdateOther();
                this.groupUpdate.selected = "";
                this.groupUpdate.updatedValues = _.cloneDeep(
                    this.defaultUpdatedValues
                );
                this.checkFormValidity();
        }
        //}
    }

    public chartClicked({
        event,
        active,
    }: {
        event: MouseEvent;
        active: {}[];
    }): void {}

    public chartHovered({
        event,
        active,
    }: {
        event: MouseEvent;
        active: {}[];
    }): void {}

    ngOnInit() {
        this.ngxService.startBackground();
        this._sharedData.setCss("payroll");
        this.activatedRoutes.params.subscribe(
            (params) => (this.eventId = params["id"])
        );
        this.activatedRoutes.params.subscribe(
            (params) =>
                (this.activeTimesheetDate = params["timecarddate"].replace(
                    /#|\//g,
                    ""
                ))
        );

        this.getJobCodes();
        this.getEvent();
        this.getContractCodes();
        this.getEmployeeTypes();
        this.getTimecardDetailsByEventId();
        this.getInvalidHBJobCodeCombinations();
      this.searchGlAccounts();      

        this.ngxService.stopBackground();
        //this.getTimesheetByQuery(null);
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
