import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Observable, forkJoin, pipe } from "rxjs";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { DateService } from "src/app/Shared/helper/date.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import * as _ from "lodash";
//import { decimalDigest } from "@angular/compiler/src/i18n/digest";
import { ModalContactSapComponent } from "../sap/modal-contact-sap-result";
import { ModalContactIdmComponent } from "../idm/modal-contact-idm-result";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { ContactSharedDataService } from "../../details/contact-shared.service";

@Component({
    selector: "modal-contact-sap-result",
    templateUrl: "./modal-add-new-contact.html",
})
export class ModalAddNewContactComponent implements OnInit, OnDestroy {
    constructor(
        public bsModalRef: BsModalRef,
        private _contactService: ContactService,
        private _remoteQueryService: RemotesQueryService,
        private _dateService: DateService,
        private _modalService: BsModalService,
        private _router: Router,
        private log: NbcLoggerService
    ) {}
    private subs = new SubSink();

    //subject to transmit the results
    public onSync!: Subject<any>;
    isFirstFourDecrypted = false;
    isLastFourDecrypted = false;

    contactMatchModalActive = false;
    defaultModalTitle = "Add Contact";
    modalTitle = this.defaultModalTitle;
    modalDescription = "";
    contact: any = {};
    matchingContactQuery: any = {};
    emailDuplicate = false;
    submitted = false;
    dobMonthRequired = false;
    dobDayRequired = false;
    ssnRequired = true;
    isBack = true;
    isNext = true;
    ssoRequired = true;
    enableNext = false;
    isPhoneValid = false;
    contactEmailRegex = RemotesConstants.regex.email;
    nonVendorText =
        "Will be paid through timekeeper, has or will have an active SSO and Perner";
    vendorText =
        "Is not paid directly or is a 3rd party, Independent Contractor, International Freelancer or Loan Out Corp";
    invalidDOB = false;
    months!: any[];
    countries: any | undefined;
    dummy: any = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        ssnLastFour: "",
        dobMonth: "",
        dobDay: "",
        isNonVendor: false,
        isUSCitizen: false,
        sso: "",
    };

    matchedContact: any = {};
    sort = {
        lastName: "asc",
        firstName: "asc",
        email: "asc",
    };

    validateDob() {
        if (!this.dummy.dobMonth) {
            this.dummy.dobMonth = "";
        }
        if (!this.dummy.dobDay) {
            this.dummy.dobDay = "";
        }
        if (!this.dummy.sso) {
            this.dummy.sso = "";
        }
        if (
            this.dummy.dobMonth !== "" &&
            this.dummy.dobDay === "" &&
            !(this.dummy.dobMonth === "" && this.dummy.dobDay !== "")
        ) {
            this.invalidDOB = true;
        } else {
            this.invalidDOB = false;
        }
        this.dummy.isUSCitizen = this.dummy.isNonVendor ? true : false;
        this.dobMonthRequired =
            (this.dummy.isNonVendor && this.dummy.isUSCitizen) ||
            (this.dummy.isNonVendor &&
                this.dummy.isUSCitizen &&
                this.dummy.dobDay !== "") ||
            (this.dummy.dobDay !== "" && this.dummy.dobMonth === "");
        this.dobDayRequired =
            (this.dummy.isNonVendor && this.dummy.isUSCitizen) ||
            (this.dummy.isNonVendor &&
                this.dummy.isUSCitizen &&
                this.dummy.dobMonth !== "") ||
            (this.dummy.dobMonth !== "" && this.dummy.dobDay === "");
        if (
            !this.dummy.isNonVendor &&
            (this.dummy.dobDay !== "" || this.dummy.dobMonth !== "")
        ) {
            this.enableNext = true;
        }
        if (
            !this.dummy.isNonVendor &&
            (this.dummy.sso === undefined ||
                this.dummy.sso === "" ||
                this.dummy.lastFourSsn === undefined ||
                this.dummy.lastFourSsn === "" ||
                this.dummy.dobDay === "" ||
                this.dummy.dobMonth === "")
        ) {
            this.enableNext = true;
        }
        if (
            this.dummy.isNonVendor &&
            this.dummy.dobDay !== "" &&
            this.dummy.dobMonth !== "" &&
            this.dummy.lastFourSsn !== ""
        ) {
            this.enableNext = true;
        }
    }

    //create new contact : workflow to check duplication of
    // sso / email etc.
    searchContactForm(isValid) {
        this.submitted = true;
        if (isValid && this.isPhoneValid) {
            this.setSearchCriteria();
            this.contactMatchSearch();
        }
    }

    ///create new contact
    createContact() {
        this.contact = {
            id: 0,
            firstName: this.dummy.firstName,
            lastName: this.dummy.lastName,
            ssnLastFour: this.dummy.lastFourSsn,
            email: this.dummy.email,
            phone1: {
                type: 4, // Home
                phoneNumber: this.dummy.phone,
            },
            dobMonth: this.dummy.dobMonth,
            dobDay: this.dummy.dobDay,
            union: "Non union",
            isNonVendor: this.dummy.isNonVendor,
            isUsCitizen: this.dummy.isUSCitizen,
            sso: this.dummy.sso,
        };

        this.subs.sink = this._contactService
            .upsertContact(this.contact)
            .subscribe({
                next: (result) => {
                    this.contact = result.reference;
                    this.loadDecryptedValuesByDefault();
                    //this.onSync.next(this.contact);
                    //this.bsModalRef.hide();
                },
                error: (err) => {
                    if (err.status == 400) {
                        this.isNext = true;
                        this.log.showModalError(err.error || "Bad Request");
                    } else
                        this.log.showModalError(
                            "Unable to create the contact at this time. Please try again later."
                        );
                },
            });
        //contactService.createContact(this.contact).success(function (result) {
        //  log.trace("got results: " + JSON.stringify(result));
        //  this.contact = result.reference;
        //
        //  //if (openNewWindow) {
        //  //    $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=new#/profile/");
        //  //} else {
        //  //    $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=new#/profile/";
        //  //}
        //}).error(function (data, status) {
        //  log.error("got an error: " + JSON.stringify(data));
        //  if (status === 400) {
        //    $scope.$emit("showAlert", "danger", data.message || "Bad Request", "modalAlert");
        //  } else {
        //    $scope.$emit("showAlert", "danger", "Unable to create the contact at this time. Please try again later.", "modalAlert");
        //  }
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //});
    }

    contactMatchSearch() {
        this.subs.sink = this._contactService
            .search(this.matchingContactQuery)
            .subscribe({
                next: (result) => {
                    if (result.items && result.items.length > 0) {
                        this.contactMatchModalActive = true;
                        this.modalTitle = "Existing Contact Matches Found";
                        this.modalDescription =
                            "We have found contacts that match the contact information you entered. Please review the list below to confirm that your contact does not already exist.";
                        // this.matchedContact = result;
                        const decryptedResult: any[] = [];
                        _.forEach(result.items, (item, key) => {
                            item.ssnLastFour = this.dummy.lastFourSsn;
                            item.dobMonth = this.dummy.dobMonth;
                            item.dobDay = this.dummy.dobDay;
                            decryptedResult.push(item);
                        });
                        this.matchedContact.items = decryptedResult;
                        this.matchedContact.pageSize = result.pageSize;
                        this.matchedContact.currentPage = result.currentPage;
                        this.matchedContact.totalRecords = result.totalRecords;
                    } else if (
                        typeof this.matchingContactQuery.sso !== "undefined" &&
                        !isNaN(this.matchingContactQuery.sso)
                    ) {
                        if (
                            !this.dummy.isNonVendor &&
                            typeof this.dummy.sso !== "undefined" &&
                            !isNaN(this.dummy.sso)
                        ) {
                            this.matchingContactQuery.sso = this.dummy.sso;
                        }

                        if (this.matchingContactQuery.sso !== "") {
                            this.contactSSOMatchSearch();
                        } else {
                            this.contactEmailAddressMatchSearch();
                        }
                    } else {
                        this.contactEmailAddressMatchSearch();
                    }
                },
                error: (err) => this.log.error(err),
            });
        //$scope.disableSavefn();
        //contactService.search(this.matchingContactQuery).success(function (result) {
        //  log.trace("got results: " + JSON.stringify(result));
        //  $scope.$emit("hideAlert", "danger", "", "modalAlert");
        //  if (result.items && result.items.length > 0) {
        //    this.contactMatchModalActive = true;
        //    this.modalTitle = "Existing Contact Matches Found";
        //    this.modalDescription = "We have found contacts that match the contact information you entered. Please review the list below to confirm that your contact does not already exist.";
        //    // this.matchedContact = result;
        //    var decryptedResult = [];
        //    _.forEach(result.items, function (item, key) {
        //      decryptMatchingResult(item).done(function (res) {
        //        res.lastFourSsn = this.dummy.lastFourSsn;
        //        res.dobMonth = this.dummy.dobMonth;
        //        res.dobDay = this.dummy.dobDay;
        //        decryptedResult.push(res);
        //        this.matchedContact.items = decryptedResult;
        //      });
        //    });
        //    this.matchedContact.pageSize = result.pageSize;
        //    this.matchedContact.currentPage = result.currentPage;
        //    this.matchedContact.totalRecords = result.totalRecords;
        //    console.log(decryptedResult);

        //  } else if (typeof this.matchingContactQuery.sso !== "undefined" && !isNaN(this.matchingContactQuery.sso)) {
        //    if (!this.dummy.isNonVendor && typeof this.dummy.sso !== "undefined" && !isNaN(this.dummy.sso)) {
        //      this.matchingContactQuery.sso = this.dummy.sso;
        //    }

        //    if (this.matchingContactQuery.sso !== "") {
        //      this.contactSSOMatchSearch();
        //    }
        //    else {
        //      this.contactEmailAddressMatchSearch();
        //    }
        //  }
        //  else {
        //    this.contactEmailAddressMatchSearch();
        //  }
        //}).error(function (data) {
        //  log.error("got an error: " + JSON.stringify(data));
        //  $scope.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.", "modalAlert");
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //});
    }

    contactSSOMatchSearch() {
        const contactQuery = this._remoteQueryService.getContactQuery();
        contactQuery.sso = this.dummy.sso;
        this.subs.sink = this._contactService.search(contactQuery).subscribe({
            next: (result) => {
                if (result.items && result.items.length > 0) {
                    this.log.showModalError(
                        "There is an existing contact with this SSO. You cannot have more than one contact with the same SSO."
                    );
                } else {
                    this.contactEmailAddressMatchSearch();
                }
            },
            error: (err) => {
                this.log.error(err);
                this.log.showModalError(
                    "Unable to perform the search at this time. Please try again later."
                );
            },
        });
    }
    CheckValidation(isValid, isPristine) {
        if (
            isValid == "VALID" &&
            this.isPhoneValid &&
            isPristine &&
            !this.isBack
        ) {
            return false;
        } else {
            return true;
        }
    }
    contactEmailAddressMatchSearch() {
        this.log.closeModalError();
        const contactQuery = this._remoteQueryService.getContactQuery();
        contactQuery.emailaddress = this.dummy.email;
        contactQuery.currentPage = this.matchingContactQuery.currentPage;
        //SCOR-17900
        this.subs.sink = this._contactService
            .contactEmailAddressMatchSearch(contactQuery)
            .subscribe({
                next: (result) => {
                    if (result.items && result.items.length > 0) {
                        this.contactMatchModalActive = true;
                        this.modalTitle = "Existing Contact Matches Found";
                        this.modalDescription =
                            "A contact with this email address already exists.  You cannot create a contact with a duplicate email.  Please cancel this change.";

                        const decryptedResult: any[] = [];
                        _.forEach(result.items, (item, key) => {
                            decryptedResult.push(item);
                            this.emailDuplicate = true;
                            this.matchedContact.items = decryptedResult;
                            //let observable = this.decryptMatchingResult(item)
                            //  .subscribe(([ssn, firstFour]) => {
                            //    item.ssnLastFour = ssn.reference.ssnLastFour;
                            //    item.dobMonth = firstFour.reference.dobMonth;
                            //    item.dobDay = firstFour.reference.dobDay;

                            //  },
                            //    (err) => this.log.error(err)
                            //  );

                            this.matchedContact.pageSize = result.pageSize;
                            this.matchedContact.currentPage =
                                result.currentPage;
                            this.matchedContact.totalRecords =
                                result.totalRecords;
                            //decryptMatchingResult(item).done(function (res) {
                            //  decryptedResult.push(res);
                            //  this.matchedContact.items = decryptedResult;
                            //});
                        });
                    } else {
                        this.createContact();
                    }
                },
                error: (err) => {
                    this.log.error(err);
                    this.log.showModalError(
                        "Unable to perform the search at this time. Please try again later."
                    );
                },
            });
        //contactService.contactEmailAddressMatchSearch(contactQuery).success(function (result) {
        //  log.trace("got results: " + JSON.stringify(result));
        //  if (result.items && result.items.length > 0) {
        //    this.contactMatchModalActive = true;
        //    this.modalTitle = "Existing Contact Matches Found";
        //    this.modalDescription = "There is an existing contact with the same Email Address.  Creating this contact presents potential issues when Registering this contact for an Event.  Do you wish to continue? ";
        //    // this.matchedContact = result;

        //    var decryptedResult = [];
        //    _.forEach(result.items, function (item, key) {
        //      decryptMatchingResult(item).done(function (res) {
        //        decryptedResult.push(res);
        //        this.matchedContact.items = decryptedResult;
        //      });
        //    });
        //    this.matchedContact.pageSize = result.pageSize;
        //    this.matchedContact.currentPage = result.currentPage;
        //    this.matchedContact.totalRecords = result.totalRecords;
        //    console.log(decryptedResult);
        //  }
        //  else {
        //    this.createContact();
        //  }
        //}).error(function (data) {
        //  log.error("got an error: " + JSON.stringify(data));
        //  $scope.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.", "modalAlert");
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //});
    }

    decryptMatchingResult(contact): Observable<[any, any]> {
        const tasks$: Observable<any>[] = [];
        const ssnObervable$ = this._contactService.decrypt(
            "ssnLastFour",
            contact
        );
        const firstFourObervable$ = this._contactService.decrypt(
            "firstFour",
            contact
        );

        return forkJoin([ssnObervable$, firstFourObervable$]);
        //---------------------------------------
        //
        //forkJoin([
        //  ssnObervable$,
        //  firstFourObervable$
        //]).subscribe(([ssn, firstFour]) => {
        //  contact.ssnLastFour = ssn.reference.ssnLastFour;
        //  contact.dobMonth = firstFour.reference.dobMonth;
        //  contact.dobDay = firstFour.reference.dobDay;
        //},
        //  (err) => this.log.error(err)
        //);

        // ------------------------------
        //var ssnObervable = this._contactService.decrypt("ssnLastFour", contact)
        //  .subscribe({
        //    next: (result) => {
        //      contact.ssnLastFour = result.reference.ssnLastFour;
        //    }
        //  });

        //var firstFourObervable = this._contactService.decrypt("firstFour", contact)
        //  .subscribe({
        //    next: (result) => {
        //      contact.dobMonth = result.reference.dobMonth;
        //      contact.dobDay = result.reference.dobDay;
        //    }
        //  });
        // tasks$.push(ssnObervable);

        //promises.push(def);
        //promises.push(def1);

        // return $.when.apply(undefined, promises).promise();
    }

    loadDecryptedValuesByDefault() {
        this.decryptMatchingResult(this.contact).subscribe({
            next: ([ssn, firstfour]) => {
                this.contact.ssnLastFour = ssn.reference.ssnLastFour;
                this.contact.dobMonth = firstfour.reference.dobMonth;
                this.contact.dobDay = firstfour.reference.dobDay;
                this.isFirstFourDecrypted = this.isLastFourDecrypted = true;
                this.doContactVerification();
            },
        });
        //this.getDecryptedValue("firstFour", this.contact);
        //this.getDecryptedValue("ssnLastFour", this.contact);
    }

    doContactVerification() {
        if (this.isFirstFourDecrypted && this.isLastFourDecrypted) {
            this.verifyContact();
        }
    }
    //NOT NEEDED FOR NOW
    //getDecryptedValue(field, contact) {
    //  //contactService.decrypt(field, contact).success(function (result) {
    //  //  if (result.reference) {
    //  //    var data = result.reference;
    //  //    if (field) {
    //  //      switch (field) {
    //  //        case "ssnLastFour":
    //  //          this.contact.ssnLastFour = data.ssnLastFour;
    //  //          isLastFourDecrypted = true;
    //  //          break;
    //  //        case "firstFour":
    //  //          this.contact.dobMonth = data.dobMonth;
    //  //          this.contact.dobDay = data.dobDay;
    //  //          isFirstFourDecrypted = true;
    //  //          break;
    //  //      }
    //  //    }
    //  //  }
    //  //  setTimeout(doContactVerification(), 30);

    //  //}).error(function (data) {
    //  //  log.error("Contact Encryption got an error: " + JSON.stringify(data));
    //  //  $scope.$emit("showAlert", "danger", data.message);
    //  //}).finally(function () {
    //  //  log.trace("removing spinner");
    //  //  usSpinnerService.stop("updateSpinner");
    //  //});
    //};

    disableSavefn() {
        //  document.getElementById("btnCreateContact").disabled = true;
    }
    sortItem(type) {
        const sort = this.sort[type];

        if (sort === "asc") {
            this.matchingContactQuery.sortBy = "-" + type;
            this.sort[type] = "desc";
        } else {
            this.matchingContactQuery.sortBy = type;
            this.sort[type] = "asc";
        }
        this.matchingContactQuery.currentPage = 1;
        this.contactMatchSearch();
    }
    getCountries() {
        //addressService.getCountries().success(function (result) {
        //  log.trace("getCountries got " + JSON.stringify(result));
        //  this.countries = result.reference;
        //}).error(function (data) {
        //  log.error("getCountries got an error: " + JSON.stringify(data));
        //  $scope.$emit("showAlert", "danger", "Unable to load Countries at this time.", "modalAlert");
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //});
    }
    cancelAddContact() {
        //this.log.trace("cancelled modal");
        this.isNext = false;
        const btn = document.getElementById("btnCreateContact");
        if (btn) btn["disabled"] = false; //SCOR-11195
        this.isBack = false;
        if (this.contactMatchModalActive) {
            this.contactMatchModalActive = false;
            this.modalTitle = this.defaultModalTitle;
        } else {
            this.closeModal();
        }
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    getDaysFromMonth(month) {
        for (let i = 0; i < this.months.length; i++) {
            if (this.months[i].id === month) {
                return this.months[i].days;
            }
        }
    }
    getDialCodeFromCountry(code) {
        for (let i = 0; i < this.countries.length; i++) {
            if (this.countries[i].isoCode === code) {
                return this.countries[i].dialCode;
            }
        }
    }
    setSearchCriteria() {
        if (this.dummy.isNonVendor && this.dummy.isUSCitizen) {
            this.matchingContactQuery.lastFourSsn = this.dummy.lastFourSsn;
            this.matchingContactQuery.dobMonth = this.dummy.dobMonth;
            this.matchingContactQuery.dobDay = this.dummy.dobDay;
            this.matchingContactQuery.sso = this.dummy.sso;
            this.matchingContactQuery.lastName = this.dummy.lastName;
        } else {
            this.matchingContactQuery.lastName = this.dummy.lastName;
            this.matchingContactQuery.email = this.dummy.email;
        }
    }
    clearDobDay() {
        const month = this.contact.dobMonth;
        const day = this.contact.dobDay;
        // 2, 4, 6, 9, 11
        if (
            !this.contact.dobMonth ||
            (month === 2 && day > 30) ||
            (month === 4 && day > 31) ||
            (month === 6 && day > 31) ||
            (month === 9 && day > 31) ||
            (month === 11 && day > 31)
        ) {
            this.contact.dobDay = "";
            this.dummy.dobDay = "";
        }
    }

    //SCOR-10289 changes
    checkVendorValidation() {
        if (!this.dummy.dobMonth) {
            this.dummy.dobMonth = "";
        }
        if (!this.dummy.dobDay) {
            this.dummy.dobDay = "";
        }
        //if (this.dummy.lastFourSsn) {
        //  // this.dummy.lastFourSsn = document.getElementById("ssn").value;
        //  this.dummy.lastFourSsn = contactSearchForm.ssn.$viewValue;
        //}

        //if (this.dummy.sso) {
        //  //this.dummy.sso = document.getElementById("sso").value;
        //  this.dummy.sso = contactSearchForm.sso.$viewValue;
        //}

        setTimeout(() => {
            this.dummy.isUSCitizen = this.dummy.isNonVendor ? true : false;
            this.ssoRequired =
                this.dummy.isNonVendor &&
                (!this.dummy.lastFourSsn ||
                    this.dummy.dobDay == "" ||
                    this.dummy.dobMonth == "");
            this.ssnRequired =
                this.dummy.isNonVendor &&
                (!this.dummy.sso || this.dummy.sso.length !== 9);
            this.dobMonthRequired =
                (this.dummy.isNonVendor &&
                    (!this.dummy.sso || this.dummy.sso.length !== 9)) ||
                (this.dummy.dobDay !== "" && this.dummy.dobMonth === "");
            this.dobDayRequired =
                (this.dummy.isNonVendor &&
                    (!this.dummy.sso || this.dummy.sso.length !== 9)) ||
                (this.dummy.dobMonth !== "" && this.dummy.dobDay === "");
        }, 100);
    }

    verifyContact() {
        //this.contact = this.contact;
        this.log.trace("Contact Verification");
        this.subs.sink = this._contactService
            .getMatchingRecordsFromSAPIDM(this.contact)
            .subscribe({
                next: (data) => {
                    if (data.reference.contacts.length > 0) {
                        this.openVerificationModal(data.reference);
                    } else {
                        this.closeModal();
                        this._router.navigate(
                            [
                                "Contacts/Detail/" +
                                    this.contact.id +
                                    "/profileinfo",
                            ],
                            { queryParams: { mode: "newNoSync" } }
                        );
                    }
                },
                error: (err) => {},
            });

        //contactService.getMatchingRecordsFromSAPIDM($scope.contact).success(function (result) {
        //  log.trace("getMatchingRecordsFromSAPIDM got SAP/IDM resultset");
        //  $modalInstance.dismiss("cancel");
        //  $scope.hcmResultsSet = result.reference;
        //  $scope.hcmContact = $scope.contact;
        //  if ($scope.hcmResultsSet.contacts.length > 0) {

        //    this.isMatchingHCMRecordsFound = true;
        //    if ($scope.hcmResultsSet.dataSource !== "IDM") {
        //      $modal.open({
        //        templateUrl: "/scripts/app/areas/contacts/views/contacts/modalContactSAPResult.html",
        //        controller: "modalContactSAPController",
        //        backdrop: "static",
        //        keyboard: false,
        //        size: "1200px",
        //        resolve: {
        //          items: function () { return $scope; },
        //        }
        //      });
        //    }
        //    else {
        //      $modal.open({
        //        templateUrl: "/scripts/app/areas/contacts/views/contacts/modalContactIDMResult.html",
        //        controller: "modalContactIDMController",
        //        backdrop: "static",
        //        keyboard: false,
        //        size: "1200px",
        //        resolve: {
        //          items: function () { return $scope; },
        //        }
        //      });
        //    }
        //  }
        //  else {

        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSync#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSync#" + true + "/profile/";
        //    }
        //  }
        //}).error(function (data) {
        //  log.error("Verify contact got an error: " + JSON.stringify(data));
        //  $scope.$emit("showAlert", "danger", "Error Occured.", "modalAlert");
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //  $modalInstance.dismiss("cancel");
        //});
    }

    openVerificationModal(hcmResultsSet: any) {
        let bsSyncModalRef: BsModalRef;
        const initialState: any = {
            self: this,
            hcmResultsSet: hcmResultsSet,
            hcmContact: this.contact,
        };
        const modalOptions = {
          animated: true,
          backdrop: true,
          keyboard: false,
          class: "child-modal2",
          initialState: initialState,
          ignoreBackdropClick: true,
        };

        if (hcmResultsSet.contacts.length > 0) {
            if (hcmResultsSet.dataSource !== "IDM") {
                bsSyncModalRef = this._modalService.show(
                    ModalContactSapComponent,
                    modalOptions
                );
                bsSyncModalRef.content.onSync.subscribe((result) => {
                    this.log.trace("SAP sync initiated");
                    this.syncContact(result);
                });
                bsSyncModalRef.content.onCancelSync.subscribe((result) => {
                    this.log.trace("SAP sync cancelled");
                    this.onSyncCancelled(result);
                });
            } else {
                bsSyncModalRef = this._modalService.show(
                    ModalContactIdmComponent,
                    modalOptions
                );
                setTimeout(() => {
                  document.querySelectorAll(
                    ".child-modal2"
                  )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
                }, 200);
                bsSyncModalRef.content.onSync.subscribe((result) => {
                    this.log.trace("IDM sync initiated");
                    this.syncContact(result);
                });

                bsSyncModalRef.content.onCancelSync.subscribe((result) => {
                    this.log.trace("IDM sync cancelled");
                    this.onSyncCancelled(result);
                });
            }
        }
    }

    onSyncCancelled(result) {
        const url = "Contacts/Detail/" + result.id + "/profileinfo";
        this.closeModal();
        this._router.navigate([url], {
            queryParams: { mode: "newCancelSync" },
        });
    }

    //$rootScope.$on("onContactSync", function (contactToSync) {
    //  $scope.syncContact(contactToSync);;
    //});
    syncContact(contactToSync) {
        const url = "Contacts/Detail/" + contactToSync.id + "/profileinfo";
        this.subs.sink = this._contactService
            .verifyContact(contactToSync)
            .subscribe({
                next: (result) => {
                    if (result.reference === true) {
                        this.log.trace(
                            "Contact synced successfully -" +
                                contactToSync.firstName +
                                " " +
                                contactToSync.lastName
                        );
                        this.log.showModalSuccess(
                            "Contact synced successfully."
                        );
                        this.closeModal();
                        this._router.navigate([url], {
                            queryParams: { mode: "newSync" },
                        });
                    } else {
                        this.closeModal();
                        this._router.navigate([url], {
                            queryParams: { mode: "newNoSync" },
                        });
                    }
                },
                error: (err) => {
                    this.log.error(err);
                    if (
                        err.error ===
                        "There is an existing contact with the same birthday, last four digits of the social security number and last name.  You cannot create a duplicate contact.  Please cancel this request."
                    ) {
                        this.closeModal();
                        this._router.navigate([url], {
                            queryParams: { mode: "newNoSyncSSNError" },
                        });
                    } else if (
                        err.error ===
                        "There is an existing contact with this SSO. You cannot have more than one contact with the same SSO."
                    ) {
                        this.closeModal();
                        this._router.navigate([url], {
                            queryParams: { mode: "newNoSyncSSOError" },
                        });
                    } else {
                        this.closeModal();
                        this._router.navigate([url], {
                            queryParams: { mode: "newNoSyncError" },
                        });
                    }
                },
            });

        //contactService.verifyContact(contactToSync.targetScope.contactBeforeSync).success(function (result) {
        //  if (result.reference === true) {
        //    log.trace("Contact synced successfully -" + contactToSync.targetScope.contactBeforeSync.firstName + " " + contactToSync.targetScope.contactBeforeSync.lastName);
        //    $scope.$emit("showAlert", "success", "Contact synced successfully.");
        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newSync#/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newSync#/profile/";
        //    }
        //  }
        //  else {
        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSync#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSync#" + true + "/profile/";
        //    }
        //  }
        //}).error(function (data) {
        //  log.error("Verify contact got an error: " + JSON.stringify(data));
        //  if (data.message === "There is an exiting contact with the same birthday, last four digits of the social security number and last name.  You cannot create a duplicate contact.  Please cancel this request.") {
        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncSSNError#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncSSNError#" + true + "/profile/";
        //    }
        //  }
        //  else if (data.message === "There is an existing contact with this SSO. You cannot have more than one contact with the same SSO.") {
        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncSSOError#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncSSOError#" + true + "/profile/";
        //    }
        //  }
        //  else {
        //    if (openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncError#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newNoSyncError#" + true + "/profile/";
        //    }
        //  }

        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("modalSpinner");
        //});
    }
    //$rootScope.$on("onCancelContactSync", function (contactToSync) {
    //  if (openNewWindow) {
    //    $window.open("/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newCancelSync#" + true + "/profile/");
    //  } else {
    //    $window.location.href = "/Contacts/Contacts/contactdetail/" + this.contact.id + "/?mode=newCancelSync#" + true + "/profile/";
    //  }
    //});

    ngOnInit() {
        this.onSync = new Subject();
        this.dummy.isNonVendor = true;
        this.dummy.isUSCitizen = true;

        this.getCountries();
        this.matchingContactQuery = this._remoteQueryService.getContactQuery();
        this.months = this._dateService.getMonths();
        this.checkVendorValidation();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
