import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule, DatePipe } from "@angular/common";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { EventDashboardComponent } from "./dashboard/eventdashboard.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NbcFilledPositionsBarComponent } from "../Shared/components/NbcfilledPositions/nbcFilledPositionsBar.component";
import { SeasonListComponent } from "./dashboard/seasonlist.component";
import { EventListComponent } from "./dashboard/eventlist.component";
import { EventSummaryComponent } from "./summary/eventsummary.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgPipesModule } from "ngx-pipes";
import { CrewPageComponent } from "./crew/crew-page.component";
import { CrewGridComponent } from "./crew/crew-grid.component";
import { NbcSharedModule } from "../Shared/shared.module";
import { EventHeaderComponent } from "./shared/eventheader.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { modaleventlistview } from "./dashboard/eventviewlistview.component";
import { NbcBudgetBarComponent } from "../Shared/components/nbc-budget-bar/nbc-budget-bar.component";
import { EventLayoutComponent } from "./event-layout.component";
import { NbcBudgetCode } from "../Shared/components/nbc-budgetcode/nbc-budget.component";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { TimeSheetComponent } from "./timesheet/timesheet.component";
import { PositionRequestComponent } from "./crew/position-request/position-request.component";
import { webuiActivityPopoverComponent } from "./crew/component/popover-activity/webui-activity-popover.component";
import { modalUpsertPosition } from "./crew/component/upsert-position/modal-upsert-position";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ModalTierRatecardComponent } from "./crew/component/tier-ratecard/modal-tier-ratecard.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { BackupContactPopoverComponent } from "./crew/component/popover-backup/Backup-Contact-Popover.Component";
import { AirportCodeComponent } from "./crew/component/airport-code/airport-code.component";
import { ContactTypeaheadComponent } from "./crew/component/contact-typeahead/contact-typeahead.component";
import { ModalScheduleConflictComponent } from "./crew/component/schedule-conflict/modal-schedule-conflict.component";
import { CrewChartComponent } from "./crew/component/chart/crew-chart.component";
import { NgChartsModule } from "ng2-charts";
import { CopyPositionsComponent } from "./crew/component/copy-positions/copy-positions.component";
import { CrewContactSearchFlyoutComponent } from "./crew/component/crew-contact-search-flyout/crew-contact-search-flyout.component";
import { ModalEditPositionCommentsComponent } from "./crew/component/position-comments/modal-Edit-Position-Comments.component";
import { EventTimesheetReportExportComponent } from "./timesheet/component/nbc-export/event-timesheet-report-export.component";
import { ModalSubmitEventTimesheetComponent } from "./timesheet/component/modal-submit-event-timesheet/modal-submit-event-timesheet.component";
import { SsoStatusReportComponent } from "./crew/report/sso-status/sso-status.component";
import { CrewChangeExportComponent } from "./crew/report/crew-change-export/crew-change-export.component";
import { ExportModalCustomizeCrewWorkComponent } from "./crew/report/export-modal-customize-crew-work/export-modal-customize-crew-work.component";
import { CreateEventConponent } from "./manageEvent/createevent/create-event.component";
import { CreateSeasonOrEvent } from "./create-seasonorevent/create-season-event.component";
import { EditEventComponent } from "./manageEvent/editevent/edit-event.component";
import { CloneEventModalComponent } from "./clone-event/clone-event-modal.component";
import { EventRouterModule } from "./events-routing.module";
import { SeasonTilesComponent } from "./season/components/season-tiles/season-tiles.component";
import { SeasonDashboardComponent } from "./season/dashboard/dashboard.component";
import { EventSearchComponent } from "./season/components/event-search/event-search.component";
import { SeasonSetupComponent } from "./season/components/season-setup/season-setup.component";
import { CreateSeasonComponent } from "./season/components/create-season/create-season.component";
import { SeasonHeaderComponent } from "./season/components/season-header/season-header.component";
import { SeasonLayoutComponent } from "./season/season-layout.component";
import { EventModuleLayoutComponent } from "./event-module-layout.component";
import { NbcNoTeamSetupComponent } from "./season/components/season-setup/nbc-noTeam/nbc-no-team-setup.component";
import { NbcModalDirtyCheckComponent } from "../Shared/components/nbc-dirty-check/nbc-modal-dirtycheck.component";
import { ModalActivateTimesheetComponent } from "./timesheet/component/activate-timesheet/modal-activate-timesheet.component";
import { NgxSmoothDnDModule } from "ngx-smooth-dnd";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CloneEventInfoModalComponent } from "../Events/clone-event/clone-event-info-modal.component";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AsyncLoaderComponent } from "./crew/component/async-loader/async-loader.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NbcSharedModule,
        EventRouterModule.forChild(),
        RouterModule,
        TabsModule,
        BsDatepickerModule,
        TooltipModule,
        FormsModule,
        TypeaheadModule,
        NgPipesModule,
        BsDropdownModule,
        ButtonsModule,
        PopoverModule,
        NgxSliderModule,
        NgChartsModule,
        NgxSmoothDnDModule,
        DragDropModule,
        CollapseModule.forRoot(),
    ],
    exports: [EventHeaderComponent],
    declarations: [
        EventModuleLayoutComponent,
        EventDashboardComponent,
        EventListComponent,
        SeasonListComponent,
        NbcFilledPositionsBarComponent,
        EventHeaderComponent,
        EventSummaryComponent,
        modaleventlistview,
        CrewGridComponent,
        CrewPageComponent,
        NbcBudgetBarComponent,
        CreateEventConponent,
        CreateSeasonOrEvent,
        EventLayoutComponent,
        NbcBudgetCode,
        TimeSheetComponent,
        ModalSubmitEventTimesheetComponent,
        EditEventComponent,
        CloneEventModalComponent,
        PositionRequestComponent,
        webuiActivityPopoverComponent,
        modalUpsertPosition,
        ModalTierRatecardComponent,
        BackupContactPopoverComponent,
        AirportCodeComponent,
        ContactTypeaheadComponent,
        ModalScheduleConflictComponent,
        CrewChartComponent,
        CopyPositionsComponent,
        CrewContactSearchFlyoutComponent,
        ModalEditPositionCommentsComponent,
        EventTimesheetReportExportComponent,
        SsoStatusReportComponent,
        CrewChangeExportComponent,
        ExportModalCustomizeCrewWorkComponent,
        SeasonTilesComponent,
        SeasonDashboardComponent,
        SeasonHeaderComponent,
        EventSearchComponent,
        SeasonSetupComponent,
        CreateSeasonComponent,
        SeasonLayoutComponent,
        NbcNoTeamSetupComponent,
        NbcModalDirtyCheckComponent,
        ModalActivateTimesheetComponent,
        CloneEventInfoModalComponent,
        AsyncLoaderComponent,
    ],
    providers: [DatePipe],
})
export class EventModule {}
