import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-remote-production-memo-report",
    templateUrl: "./remote-production-memo-report.component.html",
})
export class RemoteProductionMemoReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}
    reportName;
    //  = modelValue;
    remoteProductionMemoReport;
    showEventsRowAll = true;
    showEventsEditRow = false;
    //$scope.showEventsSelectedRow = false;
    showEventsTagRow = false;
    eventId = 13;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    getCriteriaData;
    eventsList: any[] = [];
    // $scope.rateCard = [];
    selectedEvent: any[] = [];
    remoteProductionMemoReportInfo;
    alerts: any;
    showAlertMessage: any;
    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    onItemSelect($event) {
        this.selectedEvent = $event;
    }
    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    editRow(value) {
        switch (value) {
            case "EventsRow":
                {
                    this.showEventsRowAll = false;
                    this.showEventsEditRow = true;
                    this.showEventsTagRow = false;
                }
                break;
        }
    }
    confirmSelection(value) {
        if (value === "EventsRow") {
            this.showEventsEditRow = false;
            this.showSection(value);
        }
    }

    showSection(value) {
        switch (value) {
            case "EventsRow":
                {
                    if (
                        !_.isUndefined(this.selectedEvent) &&
                        this.selectedEvent.length > 0
                    ) {
                        this.showEventsRowAll = false;
                        this.showEventsEditRow = false;
                        this.showEventsTagRow = true;
                    } else {
                        this.showEventsRowAll = true;
                        this.showEventsTagRow = false;
                        this.showEventsEditRow = false;
                    }
                    break;
                }
                break;
        }
    }

    stateChanged(checked, value, row) {
        if (row === "EventsRow") {
            if (checked) {
                this.eventsList.push(value);
                this.remoteProductionMemoReportInfo.events.push(value);
                //document.getElementById("PayrateButton").disabled = false;
            }
            if (!checked) {
                this.eventsList.splice(this.eventsList.indexOf(value), 1);
                this.remoteProductionMemoReportInfo.events.splice(
                    this.remoteProductionMemoReportInfo.events.indexOf(value),
                    1
                );
                //if ($scope.eventsList.length === 0) {
                //    document.getElementById("PayrateButton").disabled = true;
                //}
            }
        }
    }

    removeTag(item, row, index) {
        if (row === "EventsRow") {
            this.eventsList.splice(this.eventsList.indexOf(item), 1);
            //$scope.payRateReportInfo.events.splice($scope.payRateReportInfo.events.indexOf(item), 1);
            this.selectedEvent.splice(this.selectedEvent.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("EventsRow");
            //if ($scope.eventsList.length === 0) {
            //    document.getElementById("PayrateButton").disabled = true;
            //}
        }
    }

    getremoteProductionMemoDetails() {
        //log.trace("exportReport");
        // usSpinnerService.spin("genericSpinner");

        this._reportService
            .getremoteProductionMemoDetails(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        //log.trace("exportReport got details" + JSON.stringify(result));

                        this.remoteProductionMemoReport = data;
                        this.getCriteriaData = data;
                    }
                },
                error: (data) => {
                    //log.error("get got an error: " + JSON.stringify(data));
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.alerts = [{ type: "danger", msg: "Error Occurred" }];
                },
            });
    }
    exportProductionMemoReport() {
        // usSpinnerService.spin("genericSpinner");

        const tempproductionMemoReportInfo: any = {};

        tempproductionMemoReportInfo.events = this.selectedEvent;
        // temppayRateReportInfo.events = $scope.payRateReportInfo.events;
        this.isDownload = true;
        this._reportService
            .exportProductionMemoReport(
                this.eventId,
                tempproductionMemoReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        // $scope.staffingReport = data;
                        this.remoteProductionMemoReport = this.getCriteriaData;
                        //reportService.exportStaffingReport($scope.eventId, $scope.temppayRateReportInfo,$scope.userName).success(function (result) {
                        const fileName = "SCORE-Production-Memo Updates_";
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.showAlertMessage = false;

                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                        //var blob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        //var fileURL = URL.createObjectURL(blob);
                        // .isSaved = data.reference;

                        //window.open(fileURL);
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    ngOnInit(): void {
        this.getremoteProductionMemoDetails();
        this.remoteProductionMemoReportInfo = {};

        //$scope.payRateReportInfo.events = null;
        this.remoteProductionMemoReportInfo.events = [];
    }
}
