import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CookieService } from "ngx-cookie-service";
import { HelpComponent } from "../Misc/help.component";
import { BaseUrlHelperService } from "../Shared/helper/baseUrlHelper.service";
import { LoginService } from "../Shared/Services/login.service";

@Component({
    selector: "rem-dashboard-identity",
    templateUrl: "./identity.component.html",
})
export class IdentityComponent implements OnInit {
    constructor(
        private _loginService: LoginService,
        private baseAPi: BaseUrlHelperService,
        private _modalService: BsModalService,
        private cookieService: CookieService
    ) {}

    user!: any;
    bsModalRef?: BsModalRef;

    openRemotesHelpModel(module: any) {
        // throw new Error("Functionality not implemented");
    }

    openHelpModel() {
        const initialState: any = {
            hideConfirm: false,
            fullName: this.user,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.bsModalRef = this._modalService.show(HelpComponent, modalOptions);
    }

    logout() {
        //this._loginService.logout()
        //  .subscribe({
        //    next: () => { "no action required"}
        //  });
        window.localStorage.clear();
        this.cookieService.deleteAll();
        window.location.href = window.location.origin + "/home/logout";
    }

    ngOnInit() {
        this.user = window ? window.preload.user : null;
        this.user.lastLogin = this.user.lastLogin
            ? moment.utc(this.user.lastLogin).local().format("llll")
            : "";
        this.user.lastLogin = this.user.lastLogin ? this.user.lastLogin : "";
    }
}
