import { DOCUMENT } from "@angular/common";
import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import * as _ from "lodash";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { SubSink } from "subsink";
import { OnboardingColorsService } from "../../Shared/Services/Onboarding/onboarding-colors.service";
import { RemotesQueryService } from "../../Shared/Services/remotesquery.service";

@Component({
    selector: "requests-grid",
    templateUrl: "./requests-grid.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/hr/hr.css",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class RequestsGridComponent implements OnInit, OnDestroy {
    constructor(
        private _sharedDataService: SharedDataService,
        private _onboardingColorsService: OnboardingColorsService,
        private _eventBusService: EventBusService,
        private _remotesQueryService: RemotesQueryService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    @ViewChild("stickyMenu") menuElement: ElementRef | undefined;
    sticky = false;
    elementPosition: any;

    i9VerifyDDl: any;

    private subs = new SubSink();

    selectAll = false;
    colors: any | undefined;
    gridData = {
        items: [],
        query: this._remotesQueryService.getHrOnboardingRequestTabQuery(),
    };

  buildGrid(dataSource: any) {
        this.selectAll = false;
        this.gridData = dataSource;
        this.gridData.items.forEach((item: any) => {
            _.assign(item, { checked: false });
            const i9verify = this.i9VerifyDDl.filter((obj) => {
                return obj.name === item.i9Verify;
            });
            item.i9Verify = i9verify[0];
            item.requestStatusColor = this.colors.requestStatus.get(
                item.requestStatus
            );
        });
    }

    //var userDefaults = _.filter(this.user.userDefaultRows, (role) => { return role.division.code === "OLY"; });

    getI9verifyMasterData(data: any) {
        this.i9VerifyDDl = data;
    }

    sortOnColumn(columnName: string): void {
        this._eventBusService.emit(
            new EmitEvent(
                "onboard-request:sortOnColumn",
                "changeSortColumn",
                columnName
            )
        );
    }

    updateDetails(item, columnName, value): void {
        const registrationData: any = {
            items: [item],
            columnName: columnName,
            value: value,
        };

      //registrationObjects.items.push(item);
        this._eventBusService.emit(
            new EmitEvent(
                "onboarding-request:updateDetails",
                "updateDetails",
                registrationData
            )
        );
    }

    cancel(regObject): void {
        this._eventBusService.emit(
            new EmitEvent(
                "onboarding-request:cancelReg",
                "cancelReg",
                regObject
            )
        );
    }
    checkedItem($event) {
        let position = 0;
        let count = 0;
        this.gridData.items.find((val: any) => {
            if (val.checked == false) {
                count++;
            } else if (val.checked == true) {
                position++;
            }
        });
        if (count > 1 || $event.target.checked == false) {
            this.selectAll = false;
        } else if (count == 0 || count == 1) {
            this.selectAll = true;
        }
        this.setSelectedIds();
        if (position > 1 || $event.target.checked == true) {
            this._sharedDataService.setRequestPosition(false);
        } else {
            this._sharedDataService.setRequestPosition(true);
        }
    }

    setSelectedIds() {
        setTimeout(() => {
            const selectedIds = _.filter(this.gridData.items, (o: any) => {
                return o.checked === true;
            });
            this._eventBusService.emit(
                new EmitEvent(
                    "Onboarding-Request:setSelectedIds",
                    "setSelectedIds",
                    selectedIds
                )
            );
        }, 300);
    }

    checkAllOptions() {
        if (this.gridData.items.every((val: any) => val.checked == true)) {
            this.gridData.items.forEach((val: any) => {
                val.checked = false;
            });
            this.selectAll = false;
        } else {
            this.gridData.items.forEach((val: any) => {
                val.checked = true;
            });
            this.selectAll = true;
        }
        this.setSelectedIds();
    }

    openOnboardingModal(registrationObject) {
        this._eventBusService.emit(
            new EmitEvent(
                "Onboarding-Request:openOnboardingModal",
                "openHrOnboardingPositionModal",
                registrationObject
            )
        );
    }

    openEditPositionRequestModal(registrationObject) {
        this._eventBusService.emit(
            new EmitEvent(
                "Onboarding-Request:openEditPositionRequestModal",
                "openEditPosReqModal",
                registrationObject
            )
        );
    }
    sendReminder(registrationObject) {
        const registration: any[] = [];
        registration.push(registrationObject);
        this._eventBusService.emit(
            new EmitEvent(
                "Onboarding-Request:SendReminderModal",
                "sendReminderModal",
                registration
            )
        );
    }

    associateColumnWidth() {
        const columns = this.menuElement?.nativeElement.children;

        if (columns) {
            const row = this.document.querySelector(".grid-row");
            for (let i = 0; i < columns.length; i++) {
                //document.querySelector('#wonderful div:nth-child(2) div.blue')
                const query = "div:nth-child(" + (i + 1) + ")";
                console.log(this.findChild(row, query));
                const childWidth = this.findChild(row, query)?.offsetWidth;
                // columns[i].setAttribute("style", "width:" + childWidth + "px;");
                columns[i].className += " fixed";
            }
        }

        //_.each(columns, (column: any) => {

        //  column.setAttribute("style", "min-width:" + width + ";width:" + width );
        //});
        //}
    }

    findChild(parent, query) {
        return parent.querySelectorAll(query)[0];
    }

    //@HostListener('window:scroll', ['$event'])
    //handleScroll() {
    //  const windowScroll = window.pageYOffset;
    //  if (windowScroll >= this.elementPosition) {
    //    this.sticky = true;
    //  } else {
    //    this.sticky = false;
    //  }
    //}

    //getCoords(elem) {
    //  let box = elem.getBoundingClientRect();
    //  return {
    //    top: box.top - 200,
    //    left: box.left - 200
    //  };
    //}

    //ngAfterViewInit() {
    //  this.elementPosition = this.menuElement?.nativeElement.offsetTop;
    //  const parent = document.getElementById("headerDiv");
    //  const cord = this.getCoords(parent);
    //  this.elementPosition = cord.top;
    //}

    initSubs(): void {
        this.subs.sink = this._eventBusService.on(
            "buildOnboardingRequestGrid",
            (dataSource) => this.buildGrid(dataSource)
        );
        this.subs.sink = this._eventBusService.on(
            "i9verifyMasterData",
            (data) => this.getI9verifyMasterData(data)
        );
        setTimeout(() => {
            document.addEventListener("scroll", function (e) {
                const statusEle = document.querySelector(".floatingStatus");
                const stickyHeader =
                    document.querySelectorAll(".hdr-grid-col");

                let headerDiv = document.querySelector("#headerDiv");
                const element = headerDiv as HTMLElement;

                // if (statusEle) {
                //     if (window.scrollY >= 240) {
                //         if (!statusEle.classList.contains("fixed"))
                //             statusEle.className += " fixed";
                //     } else {
                //         statusEle.classList.remove("fixed");
                //     }
                // }

                if (stickyHeader) {
                    if (window.scrollY >= 490) {
                        
                        element.style.marginTop = "-615px";
                        if (!headerDiv.classList.contains("fixed"))
                            headerDiv.className += " fixed";
                        // _.each(stickyHeader, (col) => {
                        //     const element = col as HTMLElement;
                        //     element.style.width = "3%";
                        //      //Uncomment and use the following line if you want to add a 'fixed' class
                           
                        // });
                    } else {
                        _.each(stickyHeader, (col) => {
                            col.classList.remove("fixed");
                        });
                        headerDiv.classList.remove("fixed");
                        element.style.marginTop = "0px";
                    }
                }
            });
        }, 2000);
    }

    async ngOnInit() {
        this.colors = this._onboardingColorsService.getColors();
        this.initSubs();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
