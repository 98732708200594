import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { takeUntil, switchMap } from "rxjs/operators";
import { Subject, Observable, Subscriber } from "rxjs";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { SharedDataService } from "../../Shared/sharedData.service";
import * as moment from "moment";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
    selector: "payroll-upcoming-event",
    templateUrl: "./upcomingEvents.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.css",
    //  "../../../assets/Content/app/areas/payroll/payroll.css"],
    //encapsulation: ViewEncapsulation.None
})
export class PayrollUpcomingEventsComponent implements OnInit, OnDestroy {
    constructor(
        private log: NbcLoggerService,
        private _eventTypeService: EventTypeService,
        private _networkService: NetworkService,
        private _contactService: ContactService,
        private _payrollService: PayrollService,
        private _sharedService: SharedDataService,
        private ngxService: NgxUiLoaderService,
        private _remotesQueryService: RemotesQueryService
    ) {}

    // Private Vars
    componentDestroyed$: Subject<boolean> = new Subject();
    payrollTimesheetSubmittedQuery: any = {};
    eventTypes: any[] | undefined;
    networks: any[] | undefined;
    dashboardHeader: any;
    model: any;
    bsInlineRangeValue!: any[];
    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm = "";

    filterOptions: any = {
        sortBy: [
            { name: "Most Recent", value: "recent" },
            { name: "Oldest", value: "-recent" },
            { name: "Event Name (A-Z)", value: "eventName" },
            { name: "Event Name (Z-A)", value: "-eventName" },
        ],
    };

    // Public Vars
    setDefaultQuery() {
        this.payrollTimesheetSubmittedQuery =
            this._remotesQueryService.getPayrollUpcomingTimesheetQuery();
        this.bsInlineRangeValue = [
            this.payrollTimesheetSubmittedQuery.startDate,
            this.payrollTimesheetSubmittedQuery.endDate,
        ];
    }

    //$scope.$watch("payrollTimesheetSubmittedQuery.event", function () {
    //  if ($scope.payrollTimesheetSubmittedQuery.event && $scope.payrollTimesheetSubmittedQuery.event.id) {
    //    $scope.payrollTimesheetSubmittedQuery.eventId = $scope.payrollTimesheetSubmittedQuery.event.id;
    //  }
    //});

    // Private Events

    getEventTypes() {
        this.log.debug("SubmittedTimesheets -> getEventTypes:fetching data");
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this.eventTypes = data.reference;
                    this.log.trace(
                        "SubmittedTimesheets -> event types received"
                    );
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    getNetworks() {
        this.log.debug("SubmittedTimesheets -> getNetworks:fetching data");
        this._networkService
            .getNetworks()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this.networks = data.reference;
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to load networks at this time. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    searchContactByName(term) {
        this._contactService
            .searchByName(encodeURIComponent(term))
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (res) => {
                    return res.data.reference;
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to retrieve payroll dashboard submitted info at this time. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    // Public Events
    resetSearch() {
        this.setDefaultQuery();
        this.contactSearchTerm = "";
        this.getUpcomingEvents();
    }

    getUpcomingEvents() {
        this.log.trace("getUpcomingEvents()");
        this.ngxService.startBackground();
        this.payrollTimesheetSubmittedQuery.startDate = moment(
            this.payrollTimesheetSubmittedQuery.startDate
        ).format();
        this.payrollTimesheetSubmittedQuery.endDate = moment(
            this.payrollTimesheetSubmittedQuery.endDate
        ).format();

        this._payrollService
            .getPayrollDashboardEvents(this.payrollTimesheetSubmittedQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.payrollTimesheetSubmittedQuery.currentPage =
                        result.upcomingEvents.currentPage;
                    this.dashboardHeader = result.upcomingEventsDashboardHeader;
                    this.model = result.upcomingEvents;
                    this.ngxService.stopBackground();
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to get upcoming events at this time. Please try again later."
                    );
                    this.log.error(err);
                    this.ngxService.stopBackground();
                },
            });
    }

    onContactSelect($contact) {
        this.log.trace("onContactSelect()");
        this.payrollTimesheetSubmittedQuery.contactId = $contact.item.id;
        this.payrollTimesheetSubmittedQuery.contact = $contact.item;
    }

    onToggleFilterDatepicker($event) {
        this.log.trace("Upcoming Events --> DateRange selected");
        this.payrollTimesheetSubmittedQuery.startDate = $event[0];
        this.payrollTimesheetSubmittedQuery.endDate = $event[1];
    }

    contactTypeahead() {
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._contactService.searchByName(
                    encodeURIComponent(token)
                );
            })
        );
    }

    changeTypeaheadLoading($event) {}

    ngOnInit() {
        this._sharedService.setCss("payroll");
        this.getNetworks();
        this.getEventTypes();
        this.setDefaultQuery();
        this.bsInlineRangeValue = [
            this.payrollTimesheetSubmittedQuery.startDate,
            this.payrollTimesheetSubmittedQuery.endDate,
        ];
        this.getUpcomingEvents();

        this.contactTypeahead();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
