<div>
  <div class="modal-header">
    <h3 class="modal-title">{{reportName}}</h3>
    <button type="button"
            class="btn-close"
            (click)="cancel()"
            aria-hidden="true">
    </button>
  </div>
  <div class="pt-3">
    <nbc-modal-alert></nbc-modal-alert>
  </div>
  <div class="modal-body">
    <div class="grid-table">

      <div class="grid-content-row"
           *ngIf="showDateRangeRowAll">
        <div class="grid-col report-name">
          <span style="color:red;">*</span>Date Range
        </div>
        <div class="grid-col">
          All
        </div>
        <div class="grid-col report-edit">
          <a>
            <i class="fa fa-pencil"
               (click)="editRow('DateRangeRow')"></i>
          </a>
        </div>
      </div>
      <div class="grid-content-row highlight"
           *ngIf="showDateRangeEditRow">
        <div class="grid-col report-name">
          <span style="color:red;">*</span>Date Range
        </div>
        <div class="grid-col input-info">
          <div class="input-group input-group-set max-input"
               dropdown
               is-open="dateRangeFilter"
               on-toggle="onToggleDateRangeDatepicker()">
            <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
            <input type="text"
                   placeholder="Select Date Range"
                   required
                   class="form-control"
                   bsDaterangepicker
                   readonly
                   [(ngModel)]="bsInlineRangeValue"
                   #drp="bsDaterangepicker"
                   (bsValueChange)="onToggleFilterDatepicker($event)"
                   [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

          </div>

        </div>

        <div class="grid-col report-edit">
          <a>
            <i class="fa fa-check"
               (click)="confirmSelection('DateRangeRow')"></i>
          </a>
        </div>
      </div>
      <div class="grid-content-row"
           *ngIf="showDateRangeTagRow">
        <div class="grid-col report-name">
          <span style="color:red;">*</span>Date Range
        </div>
        <div class="grid-col">
          <div class="col-md-6">
            <span class="filter-tag">
              {{payrollTimecardsReportInfo.dateRangeStartDate | date:'MM/dd/yyyy'}}-{{payrollTimecardsReportInfo.dateRangeEndDate | date:'MM/dd/yyyy'}} <a>
                <i class="fa fa-times-circle"
                   (click)="removeDateTag('DateRangeRow')"></i>
              </a>
            </span>
          </div>
        </div>
        <div class="grid-col report-edit">
          <a>
            <i class="fa fa-pencil"
               (click)="editRow('DateRangeRow')"></i>
          </a>
        </div>
      </div>

      <div class="grid-content-row"
           *ngIf="ShowTimecardTypeRowAll">
        <div class="grid-col report-name">
          Timecard Type
        </div>
        <div class="grid-col">
          All
        </div>
        <div class="grid-col report-edit">
          <a>
            <i class="fa fa-pencil"
               (click)="editRow('TimecardTypesRow')"></i>
          </a>
        </div>
      </div>
      <div class="grid-content-row highlight"
           *ngIf="ShowTimecardTypeEditRow">
        <div class="grid-col report-name">
          Timecard Type
        </div>
        <div class="grid-col">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div ng-class="{'has-error': submitted || requireRateCard}">
                  <nbc-multi-select [source]="timecardTypes"
                                    value="id"
                                    display="name"
                                    [(selectedValues)]="selectedTimecardType"
                                    [placeholder]="'Select'"
                                    (nbcOnOptionSelect)="onItemSelect($event)"></nbc-multi-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col report-edit">
          <a>
            <i class="fa fa-check"
               (click)="confirmSelection('TimecardTypesRow',timecardTypes)"></i>
          </a>
        </div>
      </div>
      <div class="grid-content-row"
           *ngIf="ShowTimecardTypeTagRow">
        <div class="grid-col report-name">
          Timecard Type
        </div>
        <div class="grid-col">
          <div class="row">
            <div class="col-md-6"
                 *ngFor="let types of selectedTimecardType">
              <span class="filter-tag">
                {{types.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(types,'TimecardTypesRow')"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('TimecardTypesRow')"></i>
            </a>
          </div>
        </div>

      
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">
      Cancel
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="exportPayrollTimecardsReport()"
            value="disable"
            [(disabled)]="isExport">
      Export
    </button>

  </div>
  <app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
</div>
