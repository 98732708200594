import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { ContactSharedDataService } from "../contact-shared.service";

@Component({
    selector: "contact-work-schedule-all",
    templateUrl: "./contact-work-schedule-all.component.html",
})
export class ContactWorkScheduleAllComponent implements OnInit, OnDestroy {
    constructor(
        private _contactService: ContactService,
        private _contactSharedData: ContactSharedDataService,
        private log: NbcLoggerService
    ) {}
    componentDestroyed$: Subject<boolean> = new Subject();
    workSchedule = [];
    contactId: any = 0;
    model: any = {};
    workScheduleQuery: any | undefined;
    getWorkScheduleQuery() {
        return {
            pageSize: 10,
            currentPage: 1,
            contactId: this.contactId,
            sortBy: "StartDate",
        };
    }

    getWorkSchedule() {
        this._contactService
            .getWorkScheduleAll(this.workScheduleQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.model = result;
                    this.workScheduleQuery.currentPage = result.currentPage;
                },
                error: (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load the events at this time. Please try again later."
                    );
                },
            });
    }

    paginate(pagination) {}

    ngOnInit() {
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contactId = contact.id;
        });
        this.workScheduleQuery = this.getWorkScheduleQuery();
        this.getWorkSchedule();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
