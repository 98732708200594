import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-help",
    templateUrl: "./help.component.html",
})
export class HelpComponent implements OnInit {
    constructor(
        private _modalService: BsModalService,
        private router: Router
    ) {}
    closeHelpModel() {
        this._modalService.hide();
       // this.router.navigateByUrl("Help/ContactUs");
    }
    ngOnInit(): void {}
}
