<div>
  <!-- Filters -->
  <div class="content-filters">
    <div class="row">
      <div class="col-md-12">
        <ul class="list-inline header-info-summary d-flex">
          <li class="timesheets-box">
            <div class="pull-left">
              <div class="info">
                <h2><i class="fa fa-list-alt"></i></h2>
              </div>
            </div>
            <div class="pull-left">
              <div class="info">
                <h3>{{dashboardHeader?.timesheetCount}}</h3>
                <div>Timesheets</div>
              </div>
            </div>
          </li>
          <li class="events-box">
            <div class="pull-left">
              <div class="info">
                <h2><i class="fa fa-trophy"></i></h2>
              </div>
            </div>
            <div class="pull-left">
              <div class="info">
                <h3>{{dashboardHeader?.eventsCount}}</h3>
                <div>Events</div>
              </div>
            </div>
          </li>
          <li class="activities-box">
            <div class="pull-left">
              <div class="info">
                <h2><i class="fa fa-check-square-o"></i></h2>
              </div>
            </div>
            <div class="pull-left">
              <div class="info">
                <h3>{{dashboardHeader?.activitiesCount}}</h3>
                <div>Unsubmitted Activities</div>
              </div>
            </div>
          </li>
          <div class="pull-right ms-auto">
            <a class="btn btn-primary" authenticate-user="payrolltimesheet.export" (click)="openSubmittedTimesheetsExportModal()">Export...</a>
          </div>
        </ul>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="input-group input-group-set dropdown" dropdown>
            <div class="input-group input-group-set" dropdown>
              <span class="input-group-text">
                <i class="fa fa-calendar"></i>
              </span>
              <span class="w-75">
                <input type="text"
                       placeholder="Daterangepicker"
                       class="form-control"
                       readonly
                       bsDaterangepicker
                       [bsValue]="bsInlineRangeValue"
                       #drp="bsDaterangepicker"
                       (bsValueChange)="onToggleFilterDatepicker($event)"
                       [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6" *ngIf="eventTypes">
        <div class="form-group">
          <nbc-multi-select id="AlleventType"
                            name="AlleventType"
                            [source]="eventTypes"
                            value="id"
                            display="name"
                            [(selectedValues)]="payrollTimesheetSubmittedQuery.eventTypeIds"
                            [placeholder]="'All Event Types'"
                            (nbcOnOptionSelect)="onEventSelect($event)"></nbc-multi-select>

        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6" *ngIf="networks">
        <div class="form-group">
          <nbc-multi-select id="AllNetworks"
                            name="AllNetworks"
                            [source]="networks"
                            value="id"
                            display="description"
                            [(selectedValues)]="payrollTimesheetSubmittedQuery.networkIds"
                            [placeholder]="'All Networks'"
                            (nbcOnOptionSelect)="onNetworkSelect($event)"></nbc-multi-select>

        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">
          <select id="Allstatus"
                  name="Allstatus"
                  class="form-select"
                  [(ngModel)]="payrollTimesheetSubmittedQuery.status">
            <option value="All">All Statuses</option>
            <option value="Pending">All Pending Approval</option>
          </select>
        </div>
      </div>
      <div class="col-lg-1 col-md-3 col-sm-6">
        <div class="form-group">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="payrollTimesheetSubmittedQuery.event"
                 placeholder="Search Event Name"
                 maxlength="100"
                 autocomplete="off" />
        </div>
      </div>
      <div class="col-lg-1 col-md-3 col-sm-6">
        <div class="form-group">
          <input [(ngModel)]="contactSearchTerm"
                 appInputRestriction="restrictChars"
                 name="contact"
                 [typeahead]="contactObservable$"
                 [typeaheadAsync]="true"
                 typeaheadOptionField="fullName"
                 [typeaheadOptionsLimit]="100"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 placeholder="Search Contact Name"
                 class="form-control"
                 [isAnimated]="true"
                 [typeaheadWaitMs]="200"
                 (typeaheadOnSelect)="onContactSelect($event)">
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="form-group">
          <button class="btn btn-secondary" (click)="getPayrollDashboardTimesheets(true)">Search</button>
          <button class="btn btn-default" (click)="resetSearch()">Reset</button>
        </div>
      </div>
    </div>
      </div>
      <!-- content area -->
      <div class="main-pane-content">
        <div class="row" *ngIf="payrollTimesheets && payrollTimesheets.items">
          <div class="view-options d-flex">
            <div class="col-md-6">
              <div class="form-inline">
                <div class="form-group">
                  Sort: &nbsp;
                </div>
                <div class="form-group">
                  <nbc-dropdown [(model)]="payrollTimesheetSubmittedQuery.sortBy"
                                (nbcOnOptionSelect)="getPayrollDashboardTimesheets()"
                                [source]="filterOptions.sortBy"
                                display="name"
                                value="value"
                                nbcSize="w-100"
                                placeholder="Select One"></nbc-dropdown>
                </div>
              </div>
            </div>
            <divv class="col-md-6">
              <div class="form-group text-end d-flex float-end">
                <label class="pagination-results">
                  <pagination-label pageSize="{{payrollTimesheets.pageSize}}"
                                    currentPage="{{payrollTimesheets.currentPage}}"
                                    totalRecords="{{payrollTimesheets.totalRecords}}"></pagination-label>
                </label>
                  &nbsp;
                  <nbc-pagination totalItems="{{payrollTimesheets.totalRecords}}"
                                  itemsPerPage="{{payrollTimesheets.pageSize}}"
                                  [(currentPage)]="payrollTimesheetSubmittedQuery.currentPage"
                                  (pageChanged)="getPayrollDashboardTimesheets(false);"></nbc-pagination>
              </div>
            </divv>
            <!--<div class="col-md-6">
          <div class="form-group text-right">
            <label class="pagination-results">
              <div pagination-label page-size="{{payrollTimesheets.pageSize}}" current-page="{{payrollTimesheets.currentPage}}" total-records="{{payrollTimesheets.totalRecords}}"></div>
            </label>
            <pagination template-url="/Scripts/app/views/directives/pagination.html" total-items="payrollTimesheets.totalRecords" [(ngModel)]="payrollTimesheetSubmittedQuery.currentPage" items-per-page="payrollTimesheetSubmittedQuery.pageSize" class="pagination-sm" boundary-links="true" rotate="false" (change)="getPayrollDashboardTimesheets(payrollTimesheetSubmittedQuery);" max-size="7"></pagination>
          </div>
        </div>-->
          </div>
        </div>
        <div class="row" *ngIf="payrollTimesheets && payrollTimesheets.items">
          <div class="col-md-12">
            <!-- List View -->
            <div class="view-list">
              <div class="row">
                <div class="timesheet-tile col-md-12"
                     *ngFor="let timesheet of payrollTimesheets.items">
                  <div class="timesheet-tile-aside">
                    <img src="/assets/image/eventType/{{timesheet.eventTypeLogoId}}" width="40" height="40" />
                  </div>
                  <div class="timesheet-tile-main">
                    <div class="timesheet-tile-dtls col-lg-3 col-md-3 col-sm-6">
                      <div class="timesheet-tile-title">
                        <a class="nbc-router-link btn-link"
                           routerLink="../timesheet/{{timesheet.eventId}}/{{timesheet.timecardDate | date : 'yyyy-MM-dd'}}"
                           title="{{timesheet.eventName}}"
                           target="_blank">
                          {{timesheet.eventName}}
                        </a>
                        <span *ngIf="timesheet.tier" class="heading-title" style="margin:0 10px 10px 20px;">T{{timesheet.tier}}</span>
                      </div>
                      <span class="timesheet-tile-code">{{timesheet.budgetCode}}</span>
                    </div>
                    <div class="timesheet-tile-count col-lg-1 col-md-2 col-sm-3">
                      {{timesheet.timesheetIndex}} of {{timesheet.timesheetCount}}
                    </div>
                    <div class="timesheet-tile-date col-lg-2 col-md-2 col-sm-3">
                      {{timesheet.timecardDate | date : 'EEE, MMM d, yyyy'}}
                    </div>
                    <div class="timesheet-tile-activities col-lg-3 col-md-2 col-sm-6">
                      <div class="progress">
                        <span *ngFor="let status of timesheet.statusSummary;let $index = index" class="progress-bar {{statusColors[$index]}}-bar"
                              [style.width]="(( status.count / timesheet.totalSubmittedRecords) * 100) + '%'"
                              data-toggle="tooltip"
                              placement="top"
                              tooltip="{{status.count}} {{status.description}}">
                        </span>
                      </div>
                      <label>{{timesheet.totalSubmittedRecords}} of {{timesheet.totalActivities}} Activities</label>
                    </div>
                    <div class="timesheet-tile-author col-lg-3 col-md-3 col-sm-6">
                      Last modified on {{timesheet.modifiedDate | date : 'M/d/yyyy'}}
                      by {{timesheet.modifiedBy}}
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div style="margin-left:1%;"
             *ngIf="!payrollTimesheets && !payrollTimesheets?.items">No Results.</div>
      </div>
    </div>
