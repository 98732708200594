import { Component, OnInit, OnDestroy } from "@angular/core";
import { DashboardService } from "../.././/Shared/Services/dashboard.service";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SharedDataService } from "../.././/Shared/sharedData.service";
import { NbcLoggerService } from "../.././/Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PermissionUtility } from "../../Core/services/permission-utility.service";

@Component({
    selector: "app-dashboard-validationfailedtimecards",
    templateUrl: "./dashboard-validationfailedtimecards.component.html",
})
export class DashboardvalidationfailedTimecardsComponent
    implements OnInit, OnDestroy
{
    constructor(
        private _dashboardService: DashboardService,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();

    validationfailedtimecards: any | undefined;
    allvalidationfailedtimecards: any | undefined;
    validationfailedtimecardsComplete: boolean | undefined;
    fullscreenMode = false;

  //defaultOrderBy: string = "";
  //defaultReverseSort: boolean = false;
  displayCount = 10;

  queryContainer: any = { "query": {} };

  //sort = {
  //  orderByField: this.defaultOrderBy,
  //  reverseSort: this.defaultReverseSort
  //};

  getValidationFailedListingQuery() {
    return {
      "sortByColumn": "dateWorked",
      "reverseSort": false
    };
  }


  sortByColumn(column) {
    this.log.trace("Sort By Column");
    if (this.queryContainer.query.sortByColumn === column) {
      this.queryContainer.query.reverseSort = !this.queryContainer.query.reverseSort;
    } else {
      this.queryContainer.query.sortByColumn = column;
      this.queryContainer.query.reverseSort = false;
    }
    this.getValidationFailedTimecards();
  };

    getValidationFailedTimecards() {
        this.log.trace("Logging from new logger");
        this.log.debug("GetPendingTimecards -> get:fetching data");

    this._dashboardService.GetTSValidationFailedTimecards(this.queryContainer.query)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.allvalidationfailedtimecards = data;
          this.log.debug("Getvalidationfailedtimecards ->  received");

        },
        err => {
          this.log.error(err);
          this.log.showError("Unable to load the dashboard at this time. Please try again later.");
        },
        () => {
          this.validationfailedtimecardsComplete = true;
        });
  };

  ngOnInit(): void {
    const user = window.preload.user;
    this.queryContainer.query = this.getValidationFailedListingQuery();
    this.getValidationFailedTimecards();
  }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
