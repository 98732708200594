import {
    Directive,
    ElementRef,
    OnInit,
    OnChanges,
    SimpleChanges,
    SimpleChange,
    Input,
    Injector,
    Optional,
    Inject,
    AfterViewInit,
} from "@angular/core";
import { NgControl, Validators } from "@angular/forms";
import * as _ from "lodash";
import { PermissionUtility } from "src/app/Core/services/permission-utility.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";

@Directive({
    selector: "[authenticate-user]",
})
export class NbcAuthorizationDirective
    implements OnInit, OnChanges, AfterViewInit
{
    constructor(
        el: ElementRef,
        @Optional() @Inject(NgControl) private ngControl: NgControl,
        //@Optional() private injector: Injector,
        private log: NbcLoggerService
    ) {
        this.element = el;
        this.utils = PermissionUtility.PermissionUtilities();
    }
    element!: ElementRef;
    command!: string;
    /*
     * isReadOnlyEvent event has been introduce to enable functionality to make UI read only for closed events.
     * This change will not impact any other status
     */
    isReadOnlyEvent: any =
        window.preload.olympicEvent &&
        window.preload.olympicEvent.status === "Closed"
            ? true
            : false;

    /*
     * READ ONLY EVENT Change
     * As permission utility is global modules, authorization changes are applicable to entire application. Read only feature is only for the respective events.
     * In such cases, to restrict this readonly feature to event level (other than proxies, contacts etc.), we are checking for url
     */
    isEventPage = window.location.pathname.indexOf("event") > -1 ? true : false;

    utils: any;
    user: any | undefined;
    @Input("authenticate-disabled") authenticateDisabled!: string;
    @Input("authenticate-department") authenticateDepartment: any = 0;
    @Input("authenticate-contact-id") authenticateContactId: any = 0;
    @Input("authenticate-hrowndepartment") authenticateHrowndepartment: any = 0;
    @Input("authenticate-owndepartment") authenticateOwndepartment: any = 0;
    @Input("authenticate-user") authenticateUser: any;
    @Input("authenticate-command") authenticateCommand: any;
    @Input("authenticate-required") authenticateRequired: any;
    @Input("authenticate-fast-track-class") authenticateFastTrackClass: any;
    @Input("authenticate-position-status-class")
    authenticatePositionStatusClass: any;
    @Input("position-status-disabled") positionStatusDisabled: any;
    @Input("authenticate-classes") authenticateClasses: any;
    @Input("fast-track-disabled") fastTrackDisabled: any;

    observingRequired = false;
    isElementAccessAllowed = false;
    commandList = {
        hide: {
            run: () => {
                this.element.nativeElement.classList.add("d-none");
            },
            undo: () => {
                this.element.nativeElement.classList.remove("d-none");
            },
        },
        disabledclass: {
            run: () => {
                this.element.nativeElement.classList.add("is-disabled");
            },
            undo: () => {
                this.element.nativeElement.classList.remove("is-disabled");
            },
        },
        display: {
            run: () => {
                this.element.nativeElement.classList.add("d-none");
            },
            undo: () => {
                this.element.nativeElement.classList.remove("d-none");
                // this.element.nativeElement.show();
            },
        },
        disabled: {
            run: () => {
                setTimeout(() => {
                    this.element.nativeElement.disabled = true;
                    this.element.nativeElement.setAttribute("disabled", true);
                    this.element.nativeElement.setAttribute(
                        "data-disable-source",
                        "nbc-auth-dir"
                    );
                }, 100);
            },
            undo: () => {
                setTimeout(() => {
                    this.element.nativeElement.disabled = false;
                    this.element.nativeElement.removeAttribute("disabled");
                    this.element.nativeElement.removeAttribute(
                        "data-disable-source"
                    );
                }, 100);
            },
        },
        disabledlabel: {
            run: () => {
                setTimeout(() => {
                    this.element.nativeElement.classList.add("disabled");
                }, 50);
            },
            undo: () => {
                setTimeout(() => {
                    this.element.nativeElement.classList.remove("disabled");
                }, 50);
            },
        },
        nbcselectdisabled: {
            run: () => {
                setTimeout(() => {
                    const ele =
                        this.element.nativeElement.querySelector("select");
                    if (ele) ele.disabled = true;
                }, 50);
            },
            undo: () => {
                setTimeout(() => {
                    const ele =
                        this.element.nativeElement.querySelector("select");
                    if (ele) ele.disabled = false;
                }, 50);
            },
        },

        nbctypeaheaddisabled: {
            run: () => {
                setTimeout(() => {
                    const ele =
                        this.element.nativeElement.querySelector("input");
                    if (ele) {
                        ele.disabled = true;
                        ele.setAttribute("disabled", true);
                    }
                }, 75);
            },
            undo: () => {
                setTimeout(() => {
                    const ele =
                        this.element.nativeElement.querySelector("input");
                    if (ele) {
                        ele.disabled = false;
                        ele.removeAttribute("disabled");
                    }
                }, 75);
            },
        },
        required: {
            run: () => {
                this.element.nativeElement.setAttribute("required", true);
                //this.element.nativeElement.attr("required");
            },
            undo: () => {
                this.element.nativeElement.setAttribute("required", false);
                //this.element.nativeElement.removeAttr("required");
            },
        },
        readonly: {
            run: () => {
                setTimeout(() => {
                    this.element.nativeElement.readOnly = true;
                }, 300);
            },
            undo: () => {
                setTimeout(() => {
                    this.element.nativeElement.readOnly = false;
                }, 300);
                // this.element.nativeElement.readOnly = false;
                //this.element.nativeElement.prop("readonly", false);
            },
        },
        invisible: {
            run: () => {
                this.element.nativeElement.css("visibility", "d-none");
            },
            undo: () => {
                this.element.nativeElement.css("visibility", "visible");
            },
        },
        removeclass: {
            run: () => {
                if (this.applyRemoveClassNameBasedOnTokens("run")) {
                    this.element.nativeElement.classList.removeClass(
                        this.authenticateClasses
                    );
                }
            },
            undo: () => {
                if (this.applyRemoveClassNameBasedOnTokens("undo")) {
                    this.element.nativeElement.classList.addClass(
                        this.authenticateClasses
                    );
                }
            },
        },
        removelink: {
            run: () => {
                this.element.nativeElement.setAttribute(
                    "nbc-href",
                    this.getElementAttributesValue("href")
                );
                //this.element.nativeElement.data(
                // 'href'',
                //  this.element.nativeElement.attr('href')
                //);
                if (!_.isNil(this.element.nativeElement.removeAttr)) {
                    this.element.nativeElement.removeAttr("href");
                }
            },
            undo: () => {
                this.element.nativeElement.setAttribute(
                    "href",
                    this.getElementAttributesValue("nbc-href")
                );

                //this.element.nativeElement.attr(
                //  'href',
                //  this.element.nativeElement.data('href')
                //);
            },
        },
        nbcdisabled: {
            run: () => {
                if (
                    this.element.nativeElement.classList.contains(
                        "dropdown-toggle"
                    )
                )
                    this.element.nativeElement.setAttribute("disabled", true);
            },
            undo: () => {
                if (
                    this.element.nativeElement.classList.contains(
                        "dropdown-toggle"
                    )
                )
                    this.element.nativeElement.setAttribute("disabled", false);
            },
        },
        tagsdisabled: {
            run: () => {
                if (this.element.nativeElement.classList.contains("input"))
                    this.element.nativeElement.setAttribute("disabled", true);

                setTimeout(() => {
                    if (
                        this.element.nativeElement.classList.contains(
                            "remove-button"
                        )
                    )
                        this.element.nativeElement.addClass("d-none");
                }, 100);
            },
            undo: () => {
                if (this.element.nativeElement.classList.contains("input"))
                    this.element.nativeElement.setAttribute("disabled", false);
                setTimeout(() => {
                    if (
                        this.element.nativeElement.classList.contains(
                            "remove-button"
                        )
                    )
                        this.element.nativeElement.removeClass("d-none");
                }, 300);
            },
        },
        unbindclickevent: {
            run: () => {
                setTimeout(() => {
                    this.element.nativeElement.style.setProperty(
                        "pointer-events",
                        "none"
                    );
                }, 200);
            },
            undo: () => {
                setTimeout(() => {
                    this.element.nativeElement.style.removeProperty(
                        "pointer-events"
                    );
                }, 200);
            },
        },
        disableanchor: {
            // Careful while using this feature - it will disable all links and remove all bindings.  Only use this if you want to disable an html link without jquery click handlers.
            run: () => {
                this.element.nativeElement.classList.add("prevent-click");
                this.element.nativeElement.onclick = (e) => {
                    if (e.preventDefault) {
                        e.preventDefault();
                    } else {
                        e.returnValue = false;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                };
                this.element.nativeElement.oncontextmenu = (e) => {
                    if (e.preventDefault) {
                        e.preventDefault();
                    } else {
                        e.returnValue = false;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                };
            },
            undo: () => {
                this.element.nativeElement.classList.remove("prevent-click");
                this.element.nativeElement.onclick = null;
                this.element.nativeElement.oncontextmenu = null;
            },
        },
    };

    /**
     * Apply Remove Class Name Based On Tokens
     * @function applyRemoveClassNameBasedOnTokens
     * @param {String} action action
     * @returns {Bool}
     * @private
     * @memberof score.directives.authenticateUser
     */
    applyRemoveClassNameBasedOnTokens(action) {
        this.log.trace("applyRemoveClassNameBasedOnTokens");
        let isClassNamesStr = true;
        const tokens = this.authenticateUser.split(",");
        const nativeEle = this.element.nativeElement;
        if (tokens.length) {
            tokens.forEach((arrEl: any) => {
                const tokenNames = arrEl.split(".");
                if (tokenNames.length) {
                    tokenNames.forEach((tokenName: any) => {
                        if (tokenName === "FastTrack") {
                            if (
                                nativeEle.classList.contains(
                                    this.authenticateFastTrackClass
                                ) ||
                                nativeEle.classList.contains(
                                    this.fastTrackDisabled
                                )
                            ) {
                                if (action === "run") {
                                    nativeEle.classList
                                        .removeClass(
                                            this.authenticateFastTrackClass
                                        )
                                        .addClass("fast-track-disabled");
                                } else {
                                    nativeEle.classList
                                        .addClass(
                                            this.authenticateFastTrackClass
                                        )
                                        .removeClass("fast-track-disabled");
                                }
                            }
                            isClassNamesStr = false;
                        } else if (tokenName === "PositionAttributes") {
                            if (
                                nativeEle.classList.contains(
                                    this.authenticatePositionStatusClass
                                ) ||
                                nativeEle.classList.contains(
                                    this.positionStatusDisabled
                                )
                            ) {
                                if (action === "run") {
                                    nativeEle.classList.contains
                                        .removeClass(
                                            this.authenticatePositionStatusClass
                                        )
                                        .addClass(this.positionStatusDisabled);
                                } else {
                                    nativeEle.classList.contains
                                        .addClass(
                                            this.authenticatePositionStatusClass
                                        )
                                        .removeClass(
                                            this.positionStatusDisabled
                                        );
                                }
                            }
                            isClassNamesStr = false;
                        }
                    });
                }
            });
        }
        return isClassNamesStr;
    }

    isAccessGranted() {
        this.log.trace("isAccessGranted");

        if (_.isEmpty(this.user)) {
            this.log.trace("the user is empty, exiting");
            return false;
        }

        if (this.user.isSuperUser) {
            if (location.href.indexOf("ptoken=show") >= 0) {
                this.log.trace("showing token tooltips");
                //element.prepend("<div style=\"position:absolute;background:yellow;padding:2px;z-index:5000;color:black\">" + attr.authenticateUser + "</div>");
            }

            this.log.trace("the user is a super user, exiting");
            return true;
        }
        if (this.authenticateUser == "ProxyNotification.Update") {
            const s = "";
        }

        return this.utils.hasAccess(
            this.user,
            _.cloneDeep(this.user.allFeatures),
            this.authenticateUser.split(","),
            this.authenticateDepartment,
            this.authenticateContactId,
            this.authenticateOwndepartment
        );
    }

    excludeDisabledElements() {
        const tagName = this.element.nativeElement.tagName;
        if (tagName.indexOf("SELECT") > -1) {
            const ele =
                this.element.nativeElement.tagName === "SELECT" ||
                this.element.nativeElement.querySelector("select");
            return !(ele && !ele.disabled);
        }

        //this reference comes from NBC-DIABLE-BUTTON.directive.ts
        //if the element (specifically button) is disabled and has it is done by disable-btn directive, we will proceed with authorization
        const disableSource = this.element.nativeElement.getAttribute(
            "data-disable-source"
        );

        const isElementDisabled = disableSource
            ? false
            : this.element.nativeElement.disabled;

        return !(this.element && !isElementDisabled);
    }
    isNbcSelectElementDisabled() {
        const ele = this.element.nativeElement.querySelector("select");
        //avoiding to send null
        return ele ? ele.disabled : false;
    }

    applyDisabledBusinessRules(change: SimpleChange) {
        this.log.trace("applyDisabledBusinessRules");
        setTimeout(() => {
            //if (this.element && (!!this.element.nativeElement.disabled
            //  || this.element.nativeElement.classList.contains("disabled")
            //  || this.isNbcSelectElementDisabled()))
            //  return;
            //const hasAccess = this.isAccessGranted();
            if (!this.isElementAccessAllowed) return;
            if (change.currentValue == "true" || change.currentValue === true) {
                if (this.authenticateCommand === "disabledclass") {
                    this.commandList.disabledclass.run();
                } else if (this.authenticateCommand === "nbcselectdisabled") {
                    this.commandList.nbcselectdisabled.run();
                } else if (this.authenticateCommand === "disabledlabel") {
                    this.commandList.disabledlabel.run();
                } else {
                    this.commandList.disabled.run();
                }
                this.element.nativeElement.setAttribute(
                    "data-disable-source",
                    "nbc-auth-dir"
                );
            } else {
                //In ideal condition,business rule will be applicable when user has access using token. If element is already disabled because
                // user do not have access, we no need to override it with business rule.
                if (this.authenticateCommand === "disabledclass") {
                    this.commandList.disabledclass.undo();
                } else if (this.authenticateCommand === "disabledlabel") {
                    this.commandList.disabledlabel.undo();
                } else if (this.authenticateCommand === "nbcselectdisabled") {
                    this.commandList.nbcselectdisabled.undo();
                } else this.commandList.disabled.undo();

                this.element.nativeElement.removeAttribute(
                    "data-disable-source"
                );
            }
        }, 150);
    }
    updateErrorStatus(control, error, val) {
        let errors = control?.errors || {};
        if (!val) {
            errors = _.omit(errors, error);
            if (_.isEmpty(errors)) errors = null;
        } else _.set(errors, error, val);
        control?.setErrors(errors);
        return control;
    }

    observeRequiredAttr(isRequired) {
        this.log.trace("observeRequiredAttr");

        const control = this.ngControl?.control;
        if (control) {
            if (isRequired) {
                this.element.nativeElement.required = true; // force truthy in case we are on non input element
                //  control.setValidators(Validators.required);
                if (!control.value)
                    this.updateErrorStatus(control, "required", true); //control.setErrors({ required: true });
            } else {
                //control.setValidators(Validators.nullValidator);
                //control.setErrors(null);
                this.element.nativeElement.required = false;
                this.updateErrorStatus(control, "required", false);
            }
            control?.updateValueAndValidity();
        }
        //const validity =
        //    !this.element.nativeElement.required ||
        //    !_.isEmpty(this.element.nativeElement.value)
        //        ? ""
        //        : this.element.nativeElement.validationMessage;

        //if (_.has(this.element.nativeElement, "setCustomValidity"))
        //    this.element.nativeElement.setCustomValidity(validity);
    }

    applyRequiredBusinessRules(change: SimpleChange) {
        this.log.trace("applyRequiredBusinessRules");
        setTimeout(() => {
            if (!this.isElementAccessAllowed) return;
            if (change.previousValue !== change.currentValue) {
                this.observeRequiredAttr(change.currentValue);
            }
            if (change.currentValue) {
                this.commandList.required.run();
            } else {
                this.commandList.required.undo();
            }
        }, 150);
    }

    authorize() {
        if (this.commandList[this.command]) {
            this.commandList[this.command].run();
        }
        if (!window.preload.olympicEvent && this.isEventPage)
            this.isReadOnlyEvent = true;

        /*********** SCOR-13949: READONLY related to block. ONLY FOR SUPERUSER**************/
        /* FOR OTHER ROLE USERS -- Functionality is in the PermissionUtilities.js
         * Logic used below: -- if any data update token provided, access for undo is denied.
         * Undo will make -- readonly to editable, disabled to enabled etc. We don't want it.
         * BUT,
         * we want d-none items to show i.e. covered with <<token>>.Retrieve. This is requried for Modal HTML also along with HTML elements.
         */

        if (this.isReadOnlyEvent && this.user.isSuperUser) {
            let isValidToken = true;
            let isDrualCase = false;
            const eleTokens = this.authenticateUser.toLowerCase();
            if (
                eleTokens.indexOf(".create") > -1 ||
                eleTokens.indexOf(".delete") > -1 ||
                eleTokens.indexOf(".update") > -1 ||
                eleTokens.indexOf(".import") > -1
            ) {
                isValidToken = false;
            }
            if (eleTokens.indexOf("drupalmodal.retrieve") > -1) {
                isDrualCase = true;
            }

            if ((this.command === "hide" && isValidToken) || isDrualCase) {
                this.commandList[this.command].undo();
                //Suraj - watch function will be enabled due to simpleChange. No action needed
                //this.applyDisabledBusinessRules();
                //this.applyRequiredBusinessRules();
            }
        }
        if (this.isAccessGranted()) {
            this.isElementAccessAllowed = true;
            if (this.commandList[this.command]) {
                this.commandList[this.command].undo();
            }
        }
    }

    getAuthorizationConfig() {
        //  const tokens = this.getElementAttributesValue("authenticate-user");
        //this.authenticateUser = !_.isEmpty(this.authenticateUser)
        //    ? this.authenticateUser.split(",")
        //    : null;
        this.command = this.authenticateCommand;
        this.command = !_.isEmpty(this.command) ? this.command : "hide";
        let departments = this.getElementAttributesValue(
            "authenticate-department"
        );
        departments =
            departments ||
            this.getElementAttributesValue(
                "ng-reflect-authenticate-department"
            );

        this.authenticateDepartment = departments
            ? _.parseInt(departments, 10)
            : null;

        let contactId = this.getElementAttributesValue(
            "authenticate-contact-id"
        );
        contactId =
            contactId ||
            this.getElementAttributesValue(
                "ng-reflect-authenticate-contact-id"
            );

        this.authenticateContactId = contactId
            ? _.parseInt(contactId, 10)
            : null;

        let hrOwnDept = this.getElementAttributesValue(
            "authenticate-hrowndepartment"
        );

        hrOwnDept =
            hrOwnDept ||
            this.getElementAttributesValue(
                "ng-reflect-authenticate-hrowndepartment"
            );
        this.authenticateOwndepartment = hrOwnDept
            ? _.parseInt(hrOwnDept, 10)
            : null;
    }

    getElementAttributesValue(attr) {
        const attrVal = this.element.nativeElement.getAttribute(attr);
        return attrVal ? attrVal.toLowerCase() : "";
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const change = changes[propName];
            switch (propName) {
                case "authenticateDisabled":
                    this.applyDisabledBusinessRules(change);
                    break;
                case "authenticateRequired":
                    this.applyRequiredBusinessRules(change);
                    break;
            }
        }
    }

    ngAfterViewInit() {
        this.user = window.preload.user;

        this.getAuthorizationConfig();

        if (!this.excludeDisabledElements())
            if (!_.isEmpty(this.authenticateUser)) this.authorize();
    }
    ngOnInit() {
        // this.log.trace(this.element);
    }
}
