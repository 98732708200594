import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service'; 
import { Observable, throwError } from 'rxjs';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CrewItemService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }
 

  getCrewItemByQuery(crewItemQuery: any): Observable<any> {
    this.log.trace("get crew item by query");
    return this.http.post<any>(this._apiroot.baseUrl + "events/positions", crewItemQuery).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTimecardDetailsByEventId(eventId:any): Observable<any> {
    this.log.trace("get GetTimecardDetailsByEventId");
    let url = "events/" + eventId + "/GetTimecardDetailsByEventId";
    return this.http.post<any>(this._apiroot.baseUrl + url, null).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getCrewItemHeader(eventId: any): Observable<any> {
    this.log.trace("getCrewItemHeader: " + eventId);
    return this.http.get(this._apiroot.baseUrl + "events/" + eventId + "/positions/summary").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }

  getConflictDates(eventId: any, contactid: any, positionno: any): Observable<any> {
    this.log.trace("getConflictDates: " + eventId);
    return this.http.get(this._apiroot.baseUrl + "events/" + eventId + "/position/getConflictDates/" + contactid + "/" + positionno).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }

  getCheckBulkConflict(eventId: any, positionDetails: any, scheduleDates: any): Observable<any> {
    this.log.trace("getCheckBulkConflict: " + eventId);
    return this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/position/getCheckBulkConflict", { positiondetails: positionDetails, scheduledates: scheduleDates}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }

  sendAdditionalLocations(eventId: any, updateAdditionalLocationsQuery: any): Observable<any> {
    this.log.trace("sendAdditionalLocations - eventId: " + eventId + " location: " + JSON.stringify(updateAdditionalLocationsQuery));
    return this.http.post<any>(this._apiroot.baseUrl + "events/" + eventId + "/sendAdditionalLocations", updateAdditionalLocationsQuery).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  checkCrewConflict(eventId: any, resourceCode: any, positionNumber: any, scheduleDate: any): Observable<any> {
    this.log.trace("getting scheduling conflict for crew");
    let query = "events/" + eventId + "/CheckConflicts?ResourceCode=" + resourceCode + "&PositionNumber=" + positionNumber;
    if (positionNumber)
      query = query + "&PositionNumber=" + positionNumber;

    if (scheduleDate)
      query = query + "&ScheduleDate=" + scheduleDate;

    return this.http.get(this._apiroot.baseUrl + query).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
    
  }
  setContactConfirmation(eventId: any, crewItem: any): Observable<any> {
    this.log.trace("confirmContact eventId: " + eventId + ", crewItem: " + crewItem);
    return this.http.post<any>(this._apiroot.baseUrl + "events/" + eventId + "/position/setContactConfirmation", crewItem).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getSingleCrewItem(eventId: any, crewItemId: any): Observable<any> {
    this.log.trace("getSingleCrewItem eventId: " + eventId + ", crewItemId: " + crewItemId);
    return this.http.get(this._apiroot.baseUrl + "events/" + eventId + "/position/getSingleCrewItem/" + crewItemId).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }

  updateSortOrderForCrewItems(eventId: any, crewItems: any): Observable<any> {
    this.log.trace("updateSortOrderForCrewItems: eventId - " + eventId + ", crewItems - " + crewItems);
    return this.http.post<any>(this._apiroot.baseUrl + "events/" + eventId + "/positions/sort", crewItems).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendAirTravelNotification(eventId: any, crewItems: any): Observable<any> {
    this.log.trace("sendAirTravelNotification - eventId: " + eventId + " crew: " + JSON.stringify(crewItems));
    return this.http.post<any>(this._apiroot.baseUrl + "events/" + eventId + "/sendAirTravelNotification", crewItems).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  importPositions(eventId: any, importfile: any): Observable<any> {
    const formData = new FormData();
    //const headers = { 'Content-Type': undefined };
    formData.append('importfile', importfile, importfile.name);    
    return this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/importpositions", formData, {
      responseType: "arraybuffer",
      headers: {
        "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"        
      },
      observe: "response"
    }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;

  };


}

