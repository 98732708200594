
<div>
  <h3>form validity = {{ngForm.valid}} -- {{ngForm.dirty}}</h3>
</div>
<table class="table table-striped">
  <thead>
  <th scope="col" style="300px;">Name</th>
  <th scope="col" style="300px;">validity</th>
  <th scope="col" style="300px;">value</th>
  <th scope="col" style="300px;">Errors</th>
  </thead>
  <tr *ngFor="let control of controls" style="border:1px solid gray;">

    <td class="col-md-2" style="padding: 5px;">{{ control.name }}</td>
    <td class="col-md-2"  [class.text-danger]="(!control.valid || null)">{{ control.valid }}</td>
    <td class="col-md-6"> <pre>{{ control.value | json }} </pre></td>
    <td> {{ control.errors | json }}</td>
  </tr>
</table>
