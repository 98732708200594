<div class="savePosition"> 
  <div class="modal-header ng-scope">
    <h4 class="modal-title ng-binding">Edit Rate</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
  </div>
  <div class="modal-body" *ngIf="positionListItem">
    <div class="row">
      <div class="col-sm-5">
        <input type="text"
               class="form-control form-control-ttl"
               [(ngModel)]="selectedTierrateCard.desc"
               name="selectedTierrateCard"
               style="height:70px;background-color:#eeeeee;text-align:center;"
               disabled
               ng-enter />
      </div>
      <div class="col-sm-2 modal-category-dept" style="font-weight:bold;">
        {{positionListItem?.eventPosition?.category | uppercase}}<br /> TIERED
      </div>

      <div class="col-sm-5">
        <label class="control-label" style="font-weight:bold;font-size:15px">Comments</label>
        <textarea appInputRestriction="restrictChars"
                  class="form-control"
                  textarea=""
                  maxlength="254"
                  name="tierRateCardComment"
                  [(ngModel)]="positionListItem.tierRateCardComment"
                  placeholder="leave a comment..."></textarea>
      </div>

    </div> 
    <div class="row">
      <div class="col-sm-8" style="margin:40px 10px 10px 20px">
        <ngx-slider [(value)]="sliderValue"
                    [options]="options"
                    (userChangeStart)="customRateChangedBySlider($event)"
                    (userChange)="customRateChangedBySlider($event)"
                    (userChangeEnd)="customRateChangedBySlider($event)">
        </ngx-slider>
      </div>
      <div class="col-sm-3" style="margin:20px 0 10px 20px;">
        <span class="float-start" style="font-size:30px;padding:5px;">$ </span>
        <input type="text"
               class="tiereRatecard-customrate float-start"
               placeholder="---.---"
               maxlength="7"
               name="customRate"
               style="font-size:30px;padding:5px;width:120px;border:0 none;border-bottom:1px solid black;text-align:center;"
               [(ngModel)]="positionListItem.customRate"
               (keydown)="customRateValidation($event)"
               (blur)="customRateChanged($event)" />
      </div>
    </div>
  </div>
  <div class="modal-footer text-end">
    <div class="row">
      <div class="col-sm-4 col-lg-12 float-end">
        <button type="button" class="btn" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSaveRate()">Save</button>
      </div>
    </div>
  </div>
</div>
