
import { Injectable } from '@angular/core'; 
import * as _ from 'lodash';
import { months } from 'moment';
import { NbcLoggerService } from '../logger/nbc-logger.service';

@Injectable({ providedIn: 'root' })
export class DateService {
  constructor(private log: NbcLoggerService) { }

  months: { id: number, name: string, shortName: string, days: any[] }[] = [
    {
      id: 1,
      name: "January",
      shortName: "Jan",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 2,
      name: "February",
      shortName: "Feb",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
    },
    {
      id: 3,
      name: "March",
      shortName: "Mar",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 4,
      name: "April",
      shortName: "Apr",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    },
    {
      id: 5,
      name: "May",
      shortName: "May",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 6,
      name: "June",
      shortName: "Jun",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    },
    {
      id: 7,
      name: "July",
      shortName: "Jul",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 8,
      name: "August",
      shortName: "Aug",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 9,
      name: "September",
      shortName: "Sep",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    },
    {
      id: 10,
      name: "October",
      shortName: "Oct",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    },
    {
      id: 11,
      name: "November",
      shortName: "Nov",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    },
    {
      id: 12,
      name: "December",
      shortName: "Dec",
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    }
  ];

  getMonths() {
    this.log.trace("getMonths");
    return this.months;
  };

  /**
 * Format input string to MMMd format e.g. Dec 31
 * @public
 * @memberOf score.services.dateService
 * @param {Object} dateString date string 
 * @returns {String}
 */

  formatDateMMMd(dateString) {
    if (_.isEmpty(dateString) && !_.isDate(dateString)) {
      return "";
    }
    var date = new Date(dateString),
      day = date.getDate(),
      monthIndex = date.getMonth();

    if (monthIndex > -1 && monthIndex < 12) {
      return this.months[monthIndex].shortName + " " + day;
    } else {
      return "";
    }
  };


  /**
 * Format input string to YYYY format e.g. 2001
 * @public
 * @memberOf score.services.dateService
 * @param {Object} dateString date string 
 * @returns {String}
 */
  formatDateYYYY(dateString) {
    if (_.isEmpty(dateString) && !_.isDate(dateString)) {
      return "";
    }
    var date = new Date(dateString),
      year = date.getFullYear();

    return year === 0 || year ? year : "";
  };


  /**
     * Format input string to MMMdYYYY format e.g. Dec 31 2015
     * @public
     * @memberOf score.services.dateService
     * @param {Object} dateString date string
     * @returns {String}
     */
  formatDateMMMdYYYY(dateString) {
    if (_.isEmpty(dateString) && !_.isDate(dateString)) {
      return "";
    }
    var date = new Date(dateString),
      day = date.getDate(),
      monthIndex = date.getMonth(),
      year = date.getFullYear();

    if (monthIndex > -1 && monthIndex < 12) {
      return months[monthIndex].shortName + " " + day + " " + year;
    } else {
      return "";
    }
  };


}
