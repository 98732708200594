import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TemplateDetailListComponent } from "./detail-list/template-detail-list.component";
import { TemplateListComponent } from "./list/template-list.component";



const routes: Routes = [
  {
    path: 'templates', component: TemplateListComponent,
    children: [
      {
        path: '', redirectTo: 'templateList', pathMatch: 'full'
      },
      {
        path: 'template-list', component: TemplateListComponent
      }
    ]
  },
  {
    path: 'templates/:id', component: TemplateDetailListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRouterModule {
  static forChild() {
    return {
      ngModule: TemplateRouterModule,
      providers: []
    };
  }
}
