import { Component, OnDestroy, OnInit } from "@angular/core";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { ContactSharedDataService } from "../contact-shared.service";
import * as moment from "moment";
import { OrderByPipe } from "ngx-pipes";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "contact-work-history",
    templateUrl: "./contact-work-history.component.html",
})
export class ContactWorkHistoryComponent implements OnInit, OnDestroy {
    constructor(
        private _contactService: ContactService,
        private _eventTypeService: EventTypeService,
        private _contactSharedData: ContactSharedDataService,
        private _orderByPipe: OrderByPipe,
        private log: NbcLoggerService
    ) {}

    contact: any | undefined;
    contactId = 0;
    eventTypes: any;
    limitHistory = 5;
    overflowPositionCount = 0;
    overflowEventCount = 0;
    componentDestroyed$: Subject<boolean> = new Subject();
    employeeType: any | undefined;
    mostRecent: any | undefined;
    summary: any;
    mostRecentOptions = [
        { value: 1, name: "1 year" },
        { value: 3, name: "3 years" },
        { value: 6, name: "6 years" },
        { value: 12, name: "12 years" },
    ];

    getWorkHistoryQuery() {
        const query = {
            id: 0,
            mostRecent: 0,
            pageSize: 10,
            currentPage: 1,
            contactId: 0,
            //positions: [],
            //skills: [],
            eventType: {
                id: 0,
            },
            startDate: "",
            endDate: "",
        };
        query.contactId = this.contactId;
        return query;
    }
    workHistoryQuery = this.getWorkHistoryQuery();
    model: any | undefined;
    formState = "";

    getEventTypes() {
        this.log.trace("contact-workschedule -> getEventTypes:fetching data");
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.eventTypes = data.reference;
                    this.eventTypes.push(this.getEventTypeFor("OLY"));
                    this.eventTypes.push(this.getEventTypeFor("REM"));
                    this.log.trace(
                        "contact-workschedule -> event types received"
                    );
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    getEventTypeFor(module) {
        if (module === "OLY") {
            return {
                effectAllowed: "",
                hasFlex: true,
                hasTeam: false,
                id: -1,
                imageId: 0,
                name: " All Olympic Events",
                tier: 0,
                type: "OLY",
            };
        } else {
            return {
                effectAllowed: "",
                hasFlex: true,
                hasTeam: false,
                id: -2,
                imageId: 0,
                name: " All Remotes Events",
                tier: 0,
                type: "REM",
            };
        }
    }

    search() {
        this.log.trace("contact-workschedule -> search");
        this._contactService
            .getWorkHistory(this.workHistoryQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.log.trace("contact-workschedule -> data received");
                    this.model = result.workHistories;

                    if (
                        this.workHistoryQuery.eventType.id === -1 ||
                        this.workHistoryQuery.eventType.id === -2
                    ) {
                        if (
                            result.workHistories !== null &&
                            result.workHistories.items !== null &&
                            result.workHistories.items.length > 0
                        ) {
                            let divCode = "";
                            if (this.workHistoryQuery.eventType.id === -1) {
                                divCode = "OLY";
                            } else if (
                                this.workHistoryQuery.eventType.id === -2
                            ) {
                                divCode = "REM";
                            }
                            const listItems = _.filter(
                                result.workHistories.items,
                                function (item) {
                                    return item.divisionCode === divCode;
                                }
                            );
                            this.model.items = [];
                            this.model.items = listItems;
                        }
                    }
                    this.summary = result;
                    this.workHistoryQuery.currentPage =
                        result.workHistories.currentPage;
                    this.formState = "showResults";
                },
                (err) => {
                    this.log.error(err);
                    //this.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.");
                },
                () => {
                    this.determineOverflowPositionCount();
                    this.determineOverflowEventTypeCount();
                }
            );
    }

    restartSearch() {
        this.log.trace("restartSearch");
        this.workHistoryQuery.contactId = this.contactId;
        this.workHistoryQuery.currentPage = 1;
        this.workHistoryQuery.startDate = moment().format();
        this.workHistoryQuery.endDate = moment().format();
        this.search();
    }

    determineOverflowPositionCount() {
        if (this.summary.positionSummaries.length > 5) {
            let countOfSpecificPositionsDisplayed = 0,
                positionCountTotal = 0,
                eventsTotalArray = this._orderByPipe.transform(
                    this.summary.positionSummaries,
                    ["-id"]
                ); //$filter("orderBy")(this.summary.positionSummaries, "-id");
            for (let i = 0; i < this.summary.positionSummaries.length; i++) {
                positionCountTotal += eventsTotalArray[i].id; // the id property is the count of times the contact has been crewed to a position
                if (i < 5) {
                    // only displaying the top 5 positions
                    countOfSpecificPositionsDisplayed += eventsTotalArray[i].id;
                }
            }
            this.overflowPositionCount =
                positionCountTotal - countOfSpecificPositionsDisplayed;
        }
    }

    determineOverflowEventTypeCount() {
        if (this.summary.eventTypeSummaries.length > 5) {
            let eventCountDifference = 0;
            const eventsTotalArray = this._orderByPipe.transform(
                this.summary.eventTypeSummaries,
                ["-count"]
            ); //$filter("orderBy")(this.summary.eventTypeSummaries, "-count");
            for (let i = 0; i < 5; i++) {
                eventCountDifference += eventsTotalArray[i].count;
            }

            this.overflowEventCount =
                this.summary.totalPastEvents - eventCountDifference;
        }
    }

    ngOnInit() {
        this.getEventTypes();
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contact = contact;
            this.contactId = contact.id;
            this.restartSearch();
        });
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
