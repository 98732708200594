<div class="box-dash border-dash tranparent" authenticate-user="dashboard.positionrequest.retrieve">
  <div class="box-dash-hdr-bar backgrounddash headerdiv">
    <h2 class="box-dash-hdr fontcolourdash"> <i class="fa fa-bars" aria-hidden="true" style="margin-left:-17px"></i> Position Request/Onboarding</h2>
  </div>
  <div class="box-dash-inner-dflt">
    <div class="row"><span class="moreDetails">For More Details Click the Icons Below</span></div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12" authenticate-user="dashboard.positionrequestinprogress.retrieve">
        <div (click)="onRequestProgress('',positionrequest?.inProgressCount)" style="cursor:pointer"
             class="box-dash clearfix" title="All open requests under your events">
          <div class="box-icon-dash float-start box-icon1"><i class="fa fa-tasks"></i></div>
          <div class="box-icon-desc">
            <!--<button class="box-dash-count-position" (click)="onRequestProgress()">Requests in Progress</button>-->
            <span class="box-dash-count-position">
              Requests in Progress
            </span>
            <div>
              <h2 style="text-align:center;margin-top: 20px;" *ngIf="positionrequest">{{positionrequest?.inProgressCount}}</h2>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12" authenticate-user="dashboard.positionrequestsubmitted.retrieve">
        <div class="box-dash clearfix" style="cursor:pointer" (click)="onRequestProgress('Registration Submitted',positionrequest.submittedCount)" title="You must complete Page 2 of the I9 Form">
          <div class="box-icon-dash float-start box-icon3">
            <i class="fa fa-file-o"></i>
          </div>
          <div class="box-icon-desc">
            <span class="box-dash-count-position">Registration Submitted</span>
            <div>
              <h2 style="text-align:center;margin-top: 20px;" *ngIf="positionrequest">{{positionrequest.submittedCount}}</h2>
            </div>
            <div class="alertbell" *ngIf="positionrequest && positionrequest.submittedCount > 0 && !submittedalert">
              <i class="fa fa-bell faa-ring animated faa-slow"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12" authenticate-user="dashboard.positionrequestrejected.retrieve">
        <div (click)="onRequestProgress('Rejected (Contact)',positionrequest.rejectedCount)" style="cursor:pointer" class="box-dash clearfix" title="You must follow up with the contact to re-submit their form">
          <div class="box-icon-dash float-start box-icon2">
            <i class="fa fa-thumbs-o-down"></i>
          </div>
          <div class="box-icon-desc">
            <span class="box-dash-count-position">Rejected(Contacts)</span>
            <div>
              <h2 style="text-align:center;margin-top: 20px;" *ngIf="positionrequest">{{positionrequest.rejectedCount}}</h2>
            </div>
            <div class="alertbell" *ngIf="positionrequest && positionrequest.rejectedCount > 0 && !rejectedalert">
              <i class="fa fa-bell faa-ring animated faa-slow"></i>
            </div>
          </div>

        </div>

        <!--</div>-->
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12" authenticate-user="dashboard.positionrequesthmrejected.retrieve">
        <div class="box-dash clearfix" style="cursor:pointer" (click)="onRequestProgress('Rejected (I9 Page 2)',positionrequest.rejectedHMCount)" title="You must correct & resubmit page 2">
          <div class="box-icon-dash float-start box-icon2">
            <i class="fa fa-thumbs-o-down"></i>
          </div>
          <div class="box-icon-desc">
            <span class="box-dash-count-position">Rejected (I-9 Page2)</span>
            <div>
              <h2 style="text-align:center;margin-top: 20px;" *ngIf="positionrequest">{{positionrequest.rejectedHMCount}}</h2>
            </div>
            <div class="alertbell" *ngIf="positionrequest && positionrequest.rejectedHMCount > 0 && !rejectedHMalert">
              <i class="fa fa-bell faa-ring animated faa-slow"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12" authenticate-user="dashboard.i9documentverified.retrieve">
        <div class="box-dash clearfix" style="cursor:pointer" (click)="i9DocumentVerify()" title="You must validate the contact's physical documents">
          <div class="box-icon-dash float-start box-icon4">
            <i class="fa fa-id-card"></i>
          </div>
          <div class="box-icon-desc">
            <span class="box-dash-count-position">I-9 Physical Documents</span>
            <div>
              <h2 style="text-align:center;margin-top: 20px;" *ngIf="i9document">{{i9document.verifyCount}}</h2>
            </div>
            <div class="alertbell" *ngIf="i9document && i9document.outOfComplianceCount > 0 && !i9verifyalert">
              <i class="fa fa-bell faa-ring animated faa-slow"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row">
      <div class="col-md-6">
        <div class="float-start">
          <button class="btn btn-primary btn-block buttonrs"
              (click)="onRequestProgress()"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i> Click for more Details</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="pull-right">
          <button class="btn btn-primary btn-block buttonri"
              (click)="onRequestProgress()"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i> Click for more Details</button>
        </div>
      </div>
    </div>-->

  </div>
</div>


<!--<p>position-request-onboarding works!</p>-->
