import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, Observable, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import { PositionService } from "src/app/Shared/Services/position.service";
import { SkillsService } from "src/app/Shared/Services/skills.service";
import { TemplateService } from "src/app/Shared/Services/template.service";

@Component({
    selector: "modal-position",
    templateUrl: "modal-position.component.html",
    //styleUrls: ["../../../../assets/Content/app/areas/admin/admin.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class ModalPositionComponent implements OnInit {
    constructor(
        private _positionService: PositionService,
        private _employeeTypeService: EmployeeTypeService,
        private _templateService: TemplateService,
        private _skillsService: SkillsService,
        private bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}

    modalDestroyed$: Subject<any> = new Subject();
    public onUpdate!: Subject<boolean>;
    positionObservable$: Observable<any[]> | undefined;
    positionSearchTerm: string | undefined;
    skillsPositionObservable$: Observable<any[]> | undefined;
    skillsPositionSearchTerm: string | undefined;

    skillSearchTerm = "";
    skillData: any[] = [];

    model: any = {
        rateCode: {},
    };
    isPositionAddInProgress = false;
    positionHasNoSkills = true;
    addPosition = true;
    submitLabel = "Add Position";
    numPositions: any = 1;
    submitted = false;
    employeeTypes: any = {};
    disableRateCode = true;
    employeeTypeSelected: any = {
        id: 0,
        description: "",
        typeName: "",
        rateCards: [],
    };
    rateCardList: any = {};
    positionItem: any | undefined;
    employeeTypeId: any | undefined;
    positionTemplateId: any | undefined;

    checkEditPosition() {
        this.log.trace("checkEditPosition()");
        if (!_.isUndefined(this.positionItem)) {
            this.model = _.cloneDeep(this.positionItem);
            this.positionSearchTerm = this.model.position?.description;
            this.addPosition = false;
            this.submitLabel = "Save Position";
            this.employeeTypeId = this.model.employeeType?.id;
            this.loadSkills();
        } else {
            this.numPositions = 1;
        }
    }
    removeTagSkills(name, item) {
        this.model.skills.splice(this.model.skills?.indexOf(event), 1);
    }
    onSeasonSelected($event) {
        if (this.model.skills.indexOf($event.item) == -1) {
            this.model.skills.push($event.item);
        }
        this.skillsPositionSearchTerm = "";
    }
    getEmployeeTypes() {
        this.log.trace("getEmployeeTypes()");

        this._employeeTypeService
            .getAllEmployeeTypes()
            .pipe(takeUntil(this.modalDestroyed$))
            .subscribe({
                next: (result) => {
                    this.employeeTypes = result.reference;
                    this.getRateCards();
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showModalError(
                        "Unable to obtain employee types at this time. Please try again later."
                    );
                },
            });
    }

    loadPositions() {
        //this.employeeTypeId = null;
        this.log.trace("loadPositions");
        this.positionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.positionSearchTerm);
            }
        ).pipe(
            takeUntil(this.modalDestroyed$),
            switchMap((token: string) => {
                return this._positionService.crewPositionAutoComplete(token);
            })
        );
    }

    onPositionSelect($event) {
        this.model.position = $event.item;
        this.model.skills = [];
        //this.loadSkillsByPosition();
        this.isPositionAddInProgress = false;
        this.skillData = [];
        this.loadSkills();
    }

    loadSkillsByPosition() {
        this.log.trace("loadSkillsByPosition()");
        if (!_.isUndefined(this.model.position)) {
            //skillsService.autoCompleteByPosition(this.model.position.id).success(function (res) {
            //  log.trace("loadSkillsByPosition() got results: " + JSON.stringify(res));
            //  defer.resolve(res.reference);
            //  if (res.reference.length === 0) {
            //    this.positionHasNoSkills = true;
            //  } else {
            //    this.positionHasNoSkills = false;
            //  }
            //}).error(function (data) {
            //  log.error("loadSkillsByPosition() got an error: " + JSON.stringify(data));
            //  defer.reject(data);
            //  this.$emit("showAlert", "danger", "Unable to load skills at this time. Please try again later.", "modalAlert");
            //});
        }
        //return defer.promise;
        this.skillsPositionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.skillsPositionSearchTerm);
            }
        ).pipe(
            takeUntil(this.modalDestroyed$),
            switchMap((token: string) => {
                return this._skillsService.autoCompleteByPosition(
                    this.model.position.id,
                    token
                );
            })
        );
    }

    setSkillEventTerm($event) {
        this.skillSearchTerm = $event;
    }
    onSkillSelected(skill) {
        this.log.trace(this.model.skills);
        if (_.isEmpty(this.model.skills)) {
            this.model.skills = [];
        }
        this.model.skills = skill;
        this.skillSearchTerm = "";
    }

    loadSkills() {
        const positionId = this.model.position.id;
        this._skillsService.autoCompleteByPosition(positionId, "").subscribe({
            next: (result: any) => {
                this.skillData = result || [];
            },
            error: (err) => {
                this.log.showError(
                    "Unable to load skills at this time. Please try again later."
                );
            },
        });
    }

    savePosition(isValid) {
        let multiplier;
        this.isPositionAddInProgress = true;
        this.log.trace("savePosition()");
        this.submitted = true;

        if (isValid) {
            const positions: any[] = [];
            let positionCount;
            if (this.addPosition && this.numPositions > 1) {
                multiplier = this.numPositions;
                for (let i = 0; i < multiplier; i++) {
                    const position = _.cloneDeep(this.model);
                    position.sortOrder = positionCount + i + 1;
                    positions.push(position);
                }
            } else {
                positions.push(this.model);
            }

            //this.numPositions = 0;
            this._templateService
                .updatePositions(this.positionTemplateId, positions)
                //.pipe(takeUntil(this.modalDestroyed$))
                .subscribe({
                    next: (result: any) => {
                        this.isPositionAddInProgress = false;
                        this.onUpdate.next(true);
                        this.closeModal();
                    },
                    error: (err) => {
                        this.isPositionAddInProgress = false;
                        this.log.error(
                            "savePosition() got an error: " +
                                JSON.stringify(err)
                        );
                        this.log.showModalError(
                            "Unable to update positions at this time. Please try again later."
                        );
                    },
                });
        }
    }

    getRateCards() {
        this.disableRateCode = false;
        if (
            this.model.employeeType !== null &&
            _.has(this.model, "employeeType")
        ) {
            if (
                this.model.employeeType.description &&
                this.model.employeeType.description.toLowerCase() === "vendor"
            ) {
                this.model.rateCode = "";
                this.disableRateCode = true;
            } else {
                this.rateCardList = this.model.employeeType.rateCards;
            }
            if (this.employeeTypeId != this.model.employeeType.id) {
                this.model.rateCode = "";
                this.employeeTypeId = this.model.employeeType.id;
            }
        } else if (this.model.employeeType === null) {
            this.rateCardList = {};
        }
    }

    selectEmployeeType(type) {
        this.employeeTypeSelected = type;
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    //init
    ngOnInit() {
        this.onUpdate = new Subject();
        this.positionTemplateId = this.positionTemplateId;
        this.addPosition = true;
        this.checkEditPosition();
        this.getEmployeeTypes();

        this.loadPositions();
        this.loadSkillsByPosition();
    }
}
