import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { JobTitleService } from "src/app/Shared/Services/job-title.service";
import { SubSink } from "subsink";
import { SharedDataService } from "../../Shared/sharedData.service";
import { ModalJobTitleComponent } from "./modal-job-title.component";

@Component({
    selector: "admin-job-title",
    templateUrl: "./job-title.component.html",
})
export class JobTitleComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private _jobTitleService: JobTitleService,
        private log: NbcLoggerService,
        public _modalService: BsModalService,
        private _sharedData: SharedDataService
    ) {}

    @ViewChild("stickyMenu") menuElement: ElementRef | undefined;
    sticky = false;
    elementPosition: any;

    bsModalRef?: BsModalRef;
    componentDestroyed$: Subject<boolean> = new Subject();
    private subs = new SubSink();

    countries: string[] = [];
    JobTitleObservable$: Observable<any[]> | undefined;
    queryContainer: any = { query: {} };
    totalRecords: any;
    jobCodeDetails: any;
    recordCount = 0;
    resultsFound = false;
    showAlertMessage = false;
    values:
        | {
              isAdd: any;
              groupId: any;
              maxNum: any;
          }
        | undefined;
    maxNum: any;
    ShowModal = false;
    groupId: any;
    isJobtileExists = false;
    disableSave = false;
    result: any;
    pageSizeList = [10, 25, 50, 100];
    onText = "Y";
    offText = "N";
    getJobCodeListingQuery(currentPage, pageSize, totalRecords) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 50,
            totalRecords: totalRecords || 100,
            sortByColumn: "groupName",
            reverseSort: false,
        };
    }

    updateStatus(jobInfo) {
        jobInfo.isDataUpdated = true;
        if (!jobInfo.active) jobInfo.active = "False";
        this.subs.sink = this._jobTitleService
            .saveJobTitleDetails(jobInfo)
            .subscribe(
                (result) => {
                    // this.log.trace("addJobCode - got results:" + JSON.stringify(result));
                    this.result = result.reference;
                    this.showAlertMessage = true;
                    this.log.showSuccess("Status updated successfully");
                    this.reset();
                },
                (err) => {
                    this.log.error("addJobCode - got an error: ");
                    this.showAlertMessage = true;
                },
                () => {
                    this.log.trace("addJobCode - removing spinner");
                }
            );
    }

    sortByColumn(column) {
        this.log.trace("Sort By Column");
        if (this.queryContainer.query.sortByColumn === column) {
            this.queryContainer.query.reverseSort =
                !this.queryContainer.query.reverseSort;
        } else {
            this.queryContainer.query.sortByColumn = column;
            this.queryContainer.query.reverseSort = false;
        }
        this.search();
    }

    searchPage(pagesize) {
        this.queryContainer.query.pageSize = pagesize;
        this.search();
    }

    search() {
        this.getAllJobTitleDetails();
    }

    searchName() {
        this.loadGrid();
    }

    loadGrid() {
        this.log.trace("loadGrid");
        this.queryContainer.query.currentPage = 1;
        this.recordCount = 0;

        this.subs.sink = this._jobTitleService
            .getAllJobTitleDetails(this.queryContainer.query)
            .subscribe({
                next: (data) => {
                    // this.log.trace("loadGrid got details" + JSON.stringify(data));
                    this.initializeData(data);
                    if (this.recordCount > 0) {
                        this.resultsFound = true;
                    } else {
                        this.resultsFound = false;
                    }
                },
                error(msg) {
                    //('Error Getting Location: ', msg);
                },
                complete: () => {},
            });
    }

    JobTitleTypeahead() {
        this.JobTitleObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.queryContainer.query.groupCode);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._jobTitleService.getJobTitle(token);
            })
        );
    }

    reset() {
        delete this.queryContainer.query.groupCode;
        this.queryContainer.query.sortByColumn = "groupName";
        this.queryContainer.query.currentPage = 1;
        this.getAllJobTitleDetails();
    }

    searchJobTitles(term) {
        return this._jobTitleService
            .getJobTitle(encodeURIComponent(term))
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                const vendorNames = result.data.reference;
                return vendorNames.slice(0, 20);
            });
    }

    initializeData(result) {
        _.forEach(result.reference.jobCodeInfo, (obj1) => {
            this.recordCount += 1;
        });
        this.totalRecords = result.reference.availableTotalPositions;
        this.queryContainer.query.totalRecords =
            result.reference.availableTotalPositions;
        this.jobCodeDetails = result.reference;
    }

    getAllJobTitleDetails() {
        this.log.trace("getAllJobTitleDetails");
        this.subs.sink = this._jobTitleService
            .getAllJobTitleDetails(this.queryContainer.query)
            .subscribe(
                (result) => {
                    this.initializeData(result);
                    if (this.recordCount > 0) {
                        this.resultsFound = true;
                    } else {
                        this.resultsFound = false;
                    }
                },
                (err) => {
                    this.log.error(err);
                    this.showAlertMessage = true;
                },
                () => {
                    this.log.debug("event types received");
                }
            );
    }

    openAddJobCodeModal(isAdd, groupId) {
        this.values = {
            isAdd: isAdd,
            groupId: groupId,
            maxNum: this.maxNum,
        };

        const initialState: any = {
            self: this,
            values: this.values,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalJobTitleComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.getAllJobTitleDetails();
        });
    }

    @HostListener("window:scroll", ["$event"])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        if (windowScroll >= this.elementPosition) {
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }

    getCoords(elem) {
        const box = elem.getBoundingClientRect();
        return {
            top: box.top - 190,
            left: box.left - 200,
        };
    }

    ngAfterViewInit() {
        this.elementPosition = this.menuElement?.nativeElement.offsetTop;
        const parent = document.getElementById("headerDiv");
        const cord = this.getCoords(parent);
        this.elementPosition = cord.top;
    }

    ngOnInit(): void {
        this.JobTitleTypeahead();
        this.queryContainer.query = this.getJobCodeListingQuery(
            null,
            null,
            null
        );
        this.getAllJobTitleDetails();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
