import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import * as moment from "moment";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { TimeZoneHelperService } from "src/app/Shared/helper/timezonehelper.service";
//import { UniquePipe } from "ngx-pipes";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
//import { Subject } from "rxjs";
//import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { ChartType } from "chart.js";
//import { MultiDataSet, Label, Colors } from "ng2-charts";
//import * as Chart from "chart.js";
import { SubSink } from "subsink";
//import { a } from "@fullcalendar/core/internal-common";

@Component({
    selector: "event-summary",
    templateUrl: "./eventsummary.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.None
})
export class EventSummaryComponent implements OnInit, OnDestroy {
    event: any = {};
    eventSummary: any = {};
    constructor(
        private _sharedData: SharedDataService,
        private _networkService: NetworkService,
        private _eventService: EventService,
        private _venueService: VenueService,
        private _timeZoneService: TimeZoneHelperService,
        private log: NbcLoggerService
    ) {}

    // Init Settings
    //moment.locale("en", {
    //  "calendar": {
    //    "lastDay": "[Yesterday at] LT",
    //    "sameDay": "[Today at] LT",
    //    "nextDay": "[Tomorrow at] LT",
    //    "lastWeek": "MMMM D",
    //    "nextWeek": "MMMM D",
    //    "sameElse": "MMMM D"
    //          }
    //});

    productionMemoActionUrl = "";
    private subs = new SubSink();
    hasLoaded = false;
    originalNotes: string | undefined;
    eventId: number = this.event.eventId;
    currentDate = moment();
    model: any | undefined;
    networks = {};
    seasons = {};
    recentActivities = [];
    oneDayEvent = false;
    favoriteEvent = false;
    mapLocations = [];
    map = {
        center: { latitude: 40.1451, longitude: -99.668 },
        zoom: 4,
        options: { scrollwheel: false },
        bounds: {},
    };
    markers = [];
    contactMarkers = [];
    countdown = undefined;
    //hasTeamNames = (!!this.model.homeTeam && !!this.model.awayTeam);
    eventVenueToolTipText: any | undefined;
    venueAddressToolTipText: any = "";
    venueDetails: any | undefined;
    venueState: any | undefined;
    budgetChart: any | undefined;
    laborCosts: ChartDetails | undefined;
    travelCosts: ChartDetails | undefined;
    public doughnutChartType: ChartType = "doughnut";

    eventDashboardSummary: any = {};
    edittingNotes: boolean | undefined;
    eventTimezone: any | undefined;
    currentUser: any = window.preload.user;
    broadcastAirDates: any[] | undefined;
    crewChangeList: any[] | undefined;

    options: any = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            data: [],
            display: false,
        },
    };

    getEventNetworks() {
        this.log.trace("EventSummary -> getEventNetworks called");
        this.subs.sink = this._networkService.getNetworks().subscribe(
            (data) => {
                this.log.trace(
                    "EventSummary -> getEventNetworks: data received"
                );
                this.networks = data.reference;
            },
            (err) => {
                this.log.error(err);
                //this.$emit("showAlert", "danger", "Unable to load event networks at this time. Please try again later.")
            },
            () => {
                this.log.trace("EventSummary -> removing spinner");
            }
        );
    }

    exportProductionMemoReportEvent = function (id: any, userName: any) {
        //alert(id);
        //alert(userName);
        //var createdDateTime = new Date();
        //userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
        //return $http.post("/api/events/" + id + "/ProductionMemo", { eventId: id, createdBy: userName, createdDate: createdDateTime.toDateString(), createdTime: createdDateTime.toTimeString() }, { responseType: "arraybuffer", headers: { "Content-type": "application/json", "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } });
    };

    eventBroadcastToolTip = function () {
        //this.log.trace("EventSummary -> Populate event broadcast tooltip ");
        //var timezone = timeZoneService.getTimeZones(this.event.venue.timeZoneId).abbr;
        //this.eventBroadcastToolTipText = [];
        //this.model.broadcastAirDates.forEach(function (item) {
        //  var airDate = $filter("date")(item.airDate, "EEE, MMM d, yyyy, ") + $filter("date")(item.startTime, "h:mma - ") + $filter("date")(item.endTime, "h:mma") + timezone;
        //  this.eventBroadcastToolTipText.push(airDate);
        //});
        //this.eventBroadcastToolTipText = this.eventBroadcastToolTipText.join("<br/>");
        return true;
    };
    eventVenueToolTip() {
        this.log.trace("EventSummary -> populate venue tooltip ");

        const arreventVenueToolTipText: any[] = [];
        this.venueAddressToolTipText = "";

        if (this.venueDetails.address) {
            this.venueAddressToolTipText = " - " + this.venueDetails.address;
        }
        if (this.venueDetails.state) {
            this.venueState = ", " + this.venueDetails.state;
        }
        this.eventVenueToolTipText = arreventVenueToolTipText.join("<br/>");
    }
    getEventSummary() {
        this.log.trace("EventSummary -> getEventSummary called");

        if (!this.eventSummary) {
            this.log.showError(
                "Unable to load event at this time. Please try again later."
            );
            return;
        }
        // Remove IE8 fixes.
        if (this.eventSummary) {
            this.eventSummary.budgetUnused =
                this.eventSummary.budgetTotal - this.eventSummary.budgetUsed;
            this.eventSummary.travelBudgetUnused =
                this.eventSummary.travelBudgetTotal -
                this.eventSummary.travelBudgetUsed;
            this.laborCosts = new ChartDetails();
            if (this.eventSummary.budgetTotal > 0) {
                if (
                    this.eventSummary.budgetUsed > this.eventSummary.budgetTotal
                ) {
                    const used =
                        this.eventSummary.budgetUsed -
                        this.eventSummary.budgetTotal;
                    const unused = this.eventSummary.budgetTotal - used;
                    this.laborCosts.data = [[used, unused]];
                } else
                    this.laborCosts.data = [
                        [
                            this.eventSummary.budgetUsed || 0,
                            this.eventSummary.budgetUnused || 0,
                        ],
                    ];
            } else this.laborCosts.data = [[0, 0]];

            this.laborCosts.color[0]["backgroundColor"] = [
                "#0db14b",
                "#d1d1d1",
            ];

            this.travelCosts = new ChartDetails();
            if (this.eventSummary.travelBudgetTotal > 0) {
                if (
                    this.eventSummary.travelBudgetUsed >
                    this.eventSummary.travelBudgetTotal
                ) {
                    const used =
                        this.eventSummary.travelBudgetUsed -
                        this.eventSummary.travelBudgetTotal;
                    const unused = this.eventSummary.travelBudgetTotal - used;
                    this.travelCosts.data = [[used, unused]];
                } else
                    this.travelCosts.data = [
                        [
                            this.eventSummary.travelBudgetUsed || 0,
                            this.eventSummary.travelBudgetUnused || 0,
                        ],
                    ];
            } else this.travelCosts.data = [[0, 0]];

            this.travelCosts.color[0]["backgroundColor"] = [
                "#0db14b",
                "#d1d1d1",
            ];

            this.budgetChart = {
                laborCosts: [
                    {
                        value: this.eventSummary.budgetUsed || 0,
                        color: "#0db14b",
                    },
                    {
                        value: this.eventSummary.budgetUnused || 0,
                        color: "#d1d1d1",
                    },
                ],
                travelCosts: [
                    {
                        value: this.eventSummary.travelBudgetUsed || 0,
                        color: "#0db14b",
                    },
                    {
                        value: this.eventSummary.travelBudgetUnused || 0,
                        color: "#d1d1d1",
                    },
                ],
            };

            const budgetPercent =
                ((this.eventSummary.budgetTotal -
                    this.eventSummary.budgetUsed) /
                    this.eventSummary.budgetTotal) *
                100;
            this.eventSummary.budgetTurnOver = Math.round(budgetPercent);

            const travelBudgetPercent =
                ((this.eventSummary.travelBudgetTotal -
                    this.eventSummary.travelBudgetUsed) /
                    this.eventSummary.travelBudgetTotal) *
                100;
            this.eventSummary.travelBudgetTurnOver =
                Math.round(travelBudgetPercent);

            this.laborCosts.positiveForecast = true;
            this.travelCosts.positiveForecast = true;

            if (this.eventSummary.budgetUsed > this.eventSummary.budgetTotal) {
                this.laborCosts.positiveForecast = false;
                this.laborCosts.color[0]["backgroundColor"][0] = "#e32020";
            } else {
                this.laborCosts.positiveForecast = true;
                this.laborCosts.color[0]["backgroundColor"][0] = "#0db14b";
            }

            if (
                this.eventSummary.travelBudgetUsed >
                this.eventSummary.travelBudgetTotal
            ) {
                this.travelCosts.positiveForecast = false;
                this.travelCosts.color[0]["backgroundColor"][0] = "#e32020";
            } else {
                this.travelCosts.positiveForecast = true;
                this.travelCosts.color[0]["backgroundColor"][0] = "#0db14b";
            }
        }
    }
    getEventDashboardSummary() {
        this.log.trace("EventSummary -> getEventDashboardSummary called");
        this.subs.sink = this._eventService
            .getEventDashboardSummary(this.model.id)
            .subscribe(
                (data) => {
                    this.log.trace(
                        "EventSummary -> getEventDashboardSummary: data received"
                    );
                    this.eventDashboardSummary = data.reference;
                },
                (err) => {
                    this.log.error(err);
                    //$scope.$emit("showAlert", "danger", "Unable to load Event Dashboard Summary information. Please try again later.");
                },
                () => {
                    this.log.trace("EventSummary -> removing spinner");
                }
            );
    }

    getEventCrewChangeSummary(noofdays: any) {
        this.log.trace("EventSummary -> getEventCrewChangeSummary called");
        this.subs.sink = this._eventService
            .getEventCrewChange(this.model.id, noofdays)
            .subscribe(
                (data) => {
                    this.log.trace(
                        "EventSummary -> getEventCrewChangeSummary: data received"
                    );
                    this.crewChangeList = data.reference;
                },
                (err) => {
                    this.log.error(err);
                    //$scope.$emit("showAlert", "danger", "Unable to load Event Dashboard Summary information. Please try again later.");
                },
                () => {
                    this.log.trace("EventSummary -> removing spinner");
                }
            );
    }

    saveVenue(venue: any) {
        this.log.trace("EventSummary -> getSeasonSummary called");
        // usSpinnerService.spin("searchSpinner");
        //_venueService.saveVenue(venue).success(function () {
        //  log.trace("successfully retrieved the season summary");
        //}).error(function (data) {
        //  log.error("got an error: " + JSON.stringify(data));
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("searchSpinner");
        //});
    }
    editNotes(state: any) {
        this.edittingNotes = state;
        if (state === true) {
            this.originalNotes = this.model.note;
        }
        if (state === false) {
            this.model.note = this.originalNotes;
        }
    }
    saveNotes() {
        this.subs.sink = this._eventService
            .saveNote(this.model.id, this.model.note)
            .subscribe({
                next: (result) => {
                    this.model.note = result.reference.note;
                    this.edittingNotes = false;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to save note at this time. Please try again later."
                    );
                },
            });
    }
    getRecentActivities() {
        this.log.trace("EventSummary -> getRecentActivities called");
        this.subs.sink = this._eventService
            .getRecentActivities(this.model.id)
            .subscribe(
                (data) => {
                    this.log.trace(
                        "EventSummary -> getRecentActivities: data received"
                    );
                    this.recentActivities =
                        data.reference && data.reference.length > 20
                            ? _.take(data.reference, 20)
                            : data.reference;
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load recent activities. Please try again later."
                    );
                },
                () => {
                    this.log.trace("EventSummary -> removing spinner");
                }
            );
    }
    humanizeDate(date: any) {
        if (date) return moment(new Date(date)).calendar();
        else return "";
    }
    getVenueById() {
        this.log.trace("EventSummary -> getVenueById called");
        this.subs.sink = this._venueService
            .getVenueById(this.event.venue.id)
            .subscribe(
                (data) => {
                    this.log.trace(
                        "EventSummary -> getVenueById: data received"
                    );
                    this.venueDetails = data.reference;
                    this.eventVenueToolTip();
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load venue Details. Please try again later."
                    );
                },
                () => {
                    this.log.trace("EventSummary -> removing spinner");
                }
            );
    }

    getEventData() {
        this.log.trace("EventSummary -> hasLoaded");

        this.log.trace(this.hasLoaded);
        this._sharedData.getEventInfo().subscribe((eventData) => {
            if (eventData.id > 0) {
                this.event = eventData;
                this.model = this.event;
                this.sortBroadcastDates();
                this.eventTimezone = this._timeZoneService.searchTimeZone(
                    this.event.venue.timeZoneId
                ).abbr;
                this.getVenueById();
                this.getEventDashboardSummary();
                // this.getRecentActivities();   SCOR-21759 commented call to recent activities

                this.hasLoaded = true;
                this.productionMemoActionUrl =
                    "EventExport/productionmemo/" +
                    this.event.id +
                    "/" +
                    this.currentUser.id;
            }
        });

        this._sharedData.getEventSummary().subscribe((summary) => {
            if (_.isPlainObject(summary)) {
                this.eventSummary = summary;
                this.getEventSummary();
            }
        });
        this.getEventNetworks();
    }
    sortBroadcastDates() {
        this.model?.broadcastAirDates?.sort((left: any, right: any) => {
            return moment.utc(left.airDate).diff(moment.utc(right.airDate));
        });
    }

    ngOnInit() {
        this._sharedData.setCurrentPage("Summary");
        // this.getEventNetworks();
        this.getEventData();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}

class ChartDetails {
    data: any[][];
    labels: any[];
    color: Array<any>;
    positiveForecast: boolean;

    constructor() {
        this.data = [];
        this.labels = [];
        this.color = [{}];
        this.positiveForecast = false;
    }
}
