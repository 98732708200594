import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { SubSink } from "subsink";

@Component({
    selector: "copy-positions",
    templateUrl: "./copy-positions.component.html",
})
export class CopyPositionsComponent implements OnInit {
    constructor(
        private _eventTypeService: EventTypeService,
        private _eventService: EventService,
        private _seasonService: SeasonService,
        private _remoteQueryService: RemotesQueryService,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}

    public onSync!: Subject<any>;
    seasonObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<any> = new Subject();
    private subs = new SubSink();
    seasonSearchTerm = "";

    eventId: any = -1;
    mainEvent: any | undefined;
    eventQuery: any = {};
    searched = false;
    positionsBeingCopied = false;
    modalTitle = "Copy From Another Event";
    eventTypes = [];
    gotoStep2 = false;
    gotoStep1 = true;
    checkCrewWorkSchedule = true;
    hasInactiveMasterData = false;
    inActiveJobTitles = "";

    event = {};
    selectedEvent: any = {};

    CopyEventPositionSettings = {
        sourceEventId: this.eventId,
        targetEventId: 0,
        copyContact: true,
        copyCrewWorkSchedule: false,
    };

    getEventTypes() {
        this.log.trace("getEventTypes");
        this.subs.sink = this._eventTypeService.getEventTypes({}).subscribe({
            next: (result) => {
                this.eventTypes = result.reference;
            },
            error: (err) => {
                this.log.error("got an error: " + JSON.stringify(err));
                this.log.showModalError(
                    "Unable to get event types at this time. Please try again later."
                );
            },
        });
    }

    hasOneSearchCriteria() {
        if (
            !this.eventQuery.eventTypeId &&
            (!this.eventQuery.eventName || this.eventQuery.eventName === "") &&
            (!this.eventQuery.seasonId || this.eventQuery.seasonId === 0)
        ) {
            return false;
        } else {
            return true;
        }
    }

    eventSearch($event: any) {
        if (!this.hasOneSearchCriteria()) {
            this.log.showModalError(
                "Please fill in at least one search criterion."
            );
            return;
        }
        if ($event) {
            this.eventQuery.currentPage = $event.page;
        }

        if (!this.eventQuery.season) {
            this.eventQuery.seasonId = 0;
        }

        this.eventQuery.crewStartDate = "";
        this.eventQuery.crewEndDate = "";

        this.eventQuery.sortBy = "-" + this.eventQuery.sortBy;
        this.eventQuery.isActive = true;
        this.eventQuery.isArchived = true;
        this.eventQuery.isCancelled = true;
        this.eventQuery.isInactive = true;
        this.eventQuery.isClosed = true;

        this.log.trace("eventSearch()");

        this.subs.sink = this._eventService.search(this.eventQuery).subscribe({
            next: (result) => {
                this.searched = true;
                this.event = result;
                this.eventQuery.currentPage = result.currentPage;
            },
            error: (err) => {
                this.log.error("got an error: " + JSON.stringify(err));
                this.log.showModalError(
                    "Unable to perform the search at this time. Please try again later."
                );
            },
        });
    }
    onEventTypeSelect() {
        this.eventQuery.season = { id: 0, name: "" };
    }
    //searchSeason (term) {
    //  if (this.eventQuery.eventTypeId !== "" && this.eventQuery.eventTypeId !== "All Event Types") {
    //    //not integrated on the backend yet so it will return 500.
    //    return seasonServices.searchByEventType(this.eventQuery.eventTypeId, term).then(function (res) {
    //      log.trace("response" + JSON.stringify(res));
    //      return res.data.reference;
    //    });
    //  } else {
    //    return seasonServices.searchByName(term).then(function (res) {
    //      log.trace("response: " + JSON.stringify(res));
    //      return res.data.reference;
    //    });
    //  }
    //};

    onBackClick() {
        this.gotoStep2 = false;
        this.gotoStep1 = true;
        this.selectedEvent = {};
    }

    eventSelected(evt: any, event: any) {
        evt.preventDefault();
        this.selectedEvent = event;
        this.subs.sink = this._eventService
            .checkInactiveMaster(this.selectedEvent.id)
            .subscribe({
                next: (result) => {
                    // this.log.trace("checkInactiveMaster got data" + JSON.stringify(result));
                    this.hasInactiveMasterData = result.reference.length > 0;
                    if (this.hasInactiveMasterData) {
                        const inActivePositions = result.reference.filter(
                            (jobtitle: any, i: any, arr: any) =>
                                arr.findIndex(
                                    (t: any) =>
                                        t.eventPosition.position.id ===
                                        jobtitle.eventPosition.position.id
                                ) === i
                        );

                        this.inActiveJobTitles = inActivePositions
                            .map(
                                (obj: any) =>
                                    obj.eventPosition.position.description
                            )
                            .join(", ");
                    }
                    this.gotoStep2 = true;
                    this.gotoStep1 = false;
                },
                error: (err) => {
                    this.hasInactiveMasterData = false;
                    this.log.error(
                        "checkInactiveMaster got an error: " +
                            JSON.stringify(err)
                    );
                },
            });
    }

    crewScheduleCriteria() {
        if (this.CopyEventPositionSettings.copyCrewWorkSchedule === true) {
            const targetDiffDays =
                this.mainEvent.crewWorkEndDate -
                this.mainEvent.crewWorkStartDate;
            const sourceDiffDays =
                this.selectedEvent.endDate - this.selectedEvent.startDate;
            if (targetDiffDays >= sourceDiffDays) {
                this.CopyEventPositionSettings.copyCrewWorkSchedule = true;
            } else {
                this.CopyEventPositionSettings.copyCrewWorkSchedule = false;
                this.checkCrewWorkSchedule =
                    this.CopyEventPositionSettings.copyCrewWorkSchedule;
            }
        }
    }

    copyPositions() {
        this.positionsBeingCopied = true;
        this.CopyEventPositionSettings.sourceEventId = this.selectedEvent.id;
        this._eventService;

        this.subs.sink = this._eventService
            .copyEventPositions(
                this.selectedEvent.id,
                this.mainEvent.id,
                this.CopyEventPositionSettings.copyContact,
                this.CopyEventPositionSettings.copyCrewWorkSchedule
            )
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    window.location.reload();
                    this.positionsBeingCopied = false;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    if (
                        err.error ===
                        "Crew Work Schedule cannot be copied as the event has less schedule days."
                    ) {
                        this.log.showModalError(
                            "Crew Work Schedule cannot be copied as the event has less schedule days."
                        );
                        this.positionsBeingCopied = false;
                    } else {
                        this.log.showModalError(
                            "Unable to copy positions from the selected event at this time. Please try again later."
                        );
                    }
                },
            });
    }

    seasonSelected(event: any) {
        const item = event.item;
        if (item) {
            // TODO: This is a work around for UI Bootstrap"s typeahead
            this.eventQuery.season = item;
            this.eventQuery.seasonId = item.id;
        } else {
            this.eventQuery.seasonId = 0;
        }
    }

    closeModal() {
        this.log.trace("closeModal");
        this.bsModalRef.hide();
    }

    getSeasons() {
        this.log.trace("get airports");
        this.seasonObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.seasonSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._seasonService.autocomplete(token);
            })
        );
    }

    //init
    ngOnInit() {
        this.onSync = new Subject();
        this.getEventTypes();
        this.getSeasons();
        this.eventQuery = this._remoteQueryService.getEventQuery();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
