import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service'; 
import { Observable, Observer, noop, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { NbcLoggerService } from '../logger/nbc-logger.service';
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }
 
  //@LiquidCache('getCountries')
  getCountries(): Observable<any> {
    return this.http.get<any>(this._apiroot.baseUrl + "countries");
  };

}
