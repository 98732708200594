import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable, throwError } from 'rxjs';
import { IEventQuery } from './remotesquery.service';
import { catchError, map } from 'rxjs/operators';
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
  providedIn: 'root'
})
export class WorkActivityService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }
 
  //@LiquidCache('getWorkActivties')
  getWorkActivties(): Observable<any> {
    this.log.trace("get getWorkActivties ");
    return this.http.get(this._apiroot.baseUrl + "workactivities").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }
}
