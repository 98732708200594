<header id="col-main-hdr">
  <nav *ngIf="isGetSeasonData && pageName!=='Season Setup'" class="col-main-menu navbar-right" role="navigation">
    <a routerLink="/Events/Season/{{season.id}}/setup"
       class="dropdown-icon"
       authenticate-user="event.update">
      <i class="fa fa-cog"></i>
    </a>
  </nav> 
  <div>
    <span class="event-type-logo" *ngIf="isGetSeasonData">
      <img src="/assets/image/eventType/{{season.eventType.imageId}}" width="35" height="35" />
    </span>
    <h1 *ngIf="isGetSeasonData"><a routerLink="/Events/Season/{{season.id}}">{{ season.name }}</a> <span><a id="season-header-edit" (click)="openEditSeasonModal()" authenticate-user="event.update"><i class="fa fa-pencil"></i></a></span></h1>
    <span class="hdr-event-dates">{{ season.startDate | date }} - {{season.endDate| date }}</span>
  </div>
</header>
<nav class="breadcrumb">
    <ul>
      <li home></li>
        <li>/</li>
        <li><a  routerLink="/Events/Seasonlist">Events</a></li>
        <li>/</li>
        <li *ngIf = "isGetSeasonData"><a  routerLink="/Events/Season/{{season.id}}">{{ season.name }}</a> </li>
        <li>/</li>
        <li>{{ pageName }}</li>
    </ul>
</nav>
