import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { SubSink } from "subsink";
import { NbcLoggerService } from "../../../../Shared/logger/nbc-logger.service";
import { PayrollService } from "../../../../Shared/Services/payroll.service";

@Component({
    selector: "activate-timesheet",
    templateUrl: "./modal-activate-timesheet.component.html",
})
export class ModalActivateTimesheetComponent {
    constructor(
        private _payrollService: PayrollService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    public onAffirmation!: Subject<any>;
    public onRejection!: Subject<any>;

    modalTitle: string | undefined;
    modalMessage: string | undefined;
    confirmText: string | undefined;
    cancelText: string | undefined;
    hideConfirm: boolean | undefined;
    isActivateTimesheetButtonEnabled = true;

    eventTimesheetQuery!: any;
    private subs = new SubSink();

    // Modal Confirm Action
    confirm() {
        this.isActivateTimesheetButtonEnabled = false;
        this.log.trace("Modal Confirmation Confirmed");
        this.activateTimesheet();
    }

    cancel() {
        this.log.trace("Modal Confirmation Cancelled");
        this.isActivateTimesheetButtonEnabled = true;
        this.onRejection.next(false);
        this.bsModalRef.hide();
    }

    activateTimesheet() {
        this.subs.sink = this._payrollService
            .initEventTimesheet(this.eventTimesheetQuery)
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.onAffirmation.next(true);
                    this.bsModalRef?.hide();
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    if (err.status === 400) {
                        this.log.showModalError(err.error || "Bad Request");
                    } else {
                        this.log.showModalError(
                            "Unable to activate the timesheet. Please try again later."
                        );
                    }
                    this.isActivateTimesheetButtonEnabled = true;
                },
                complete: () => {
                    this.isActivateTimesheetButtonEnabled = true;
                },
            });
    }

    ngOnInit() {
        this.onAffirmation = new Subject();
        this.onRejection = new Subject();

        this.confirmText = this.confirmText;
        this.cancelText = this.cancelText;
        this.eventTimesheetQuery = this.eventTimesheetQuery;
    }
}
