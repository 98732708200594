<div>
  <header id="col-main-hdr">
    <h1><i class="fa fa-money"></i> Payroll</h1>
  </header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li>
         <a routerLink="../../../" title="Payroll">Payroll</a> 
      </li>
      <li>/</li>
      <li><a routerLink="../../../upcomingEvents" title="Payroll">Upcoming Events</a></li>
      <li>/</li>
      <li>{{eventName}}</li>
    </ul>
  </nav>
  <div class="page-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="header-bar clearfix">
            <p class="heading-title pull-left">
              {{eventName}}
              <span *ngIf="!(crewStartDate === crewEndDate || crewStartDate === '')" class="heading-title-subtxt">
                {{crewStartDate| date:"EEE. MMM. d"}} - {{crewEndDate|date:"EEE. MMM. d, yyyy"}}
              </span>
              <span *ngIf="crewStartDate === crewEndDate || crewStartDate === ''" class="heading-title-subtxt">
                {{crewEndDate|date:"EEE. MMM. d, yyyy"}}
              </span>
              <span *ngIf="tier" class="heading-title" style="margin:0 10px 10px 20px;">
                T{{tier}}
              </span>
            </p>
          </div>
          <div class="content-filters">
            <div class="row">
              <div class="col-md-6 text-center" *ngIf="chartData?.totalContacts > 0">
                <div class="donut-chart-container">
                   <canvas baseChart
                          [data]="ChartLayoutData"
                          [legend]="false"
                          [labels]="chartLayoutLabel"
                          [colors]="chartLayoutColors"
                          [options]="options"
                          [type]="doughnutChartType" width="100" height="100"></canvas>
                  <!--<canvas tc-chartjs-doughnut chart-data="contactsPayrollChartData" chart-options="chartOptions" width="100" height="100"></canvas>-->
                  <div class="donut-chart-label">
                    <div class="donut-chart-label-txt">
                      <div class="numeral">
                        <strong>{{chartData.totalContacts}}</strong>
                      </div>
                      Position<span *ngIf="chartData.totalContacts != 1">s</span>
                    </div>
                  </div>
                </div>
                <div class="donut-chart-legend upcoming-event-chart">
                  <div class="donut-chart-legend-itms">
                    <div class="donut-chart-legend-itm donut-chart-{{index}}" *ngFor="let chartItem of chartData.payrollProviders;index as index;">
                      <span class="label">{{chartItem.count}}</span> {{chartItem.description}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pull-right col-md-6">
                <div class="col-md-12 col-sm-12  pull-right" *ngIf="chartData?.lastModifiedBy">
                  <small style="float:right">Last modified <span *ngIf="chartData?.lastModifiedDate">on <strong>{{chartData.lastModifiedDate}}</strong></span> by <strong>{{chartData.lastModifiedBy}}</strong></small>
                </div>
                <div style="clear:both"></div>
                <div class="col-md-12 col-sm-12  pull-right">
                  <div class="filter-boxes text-end pull-right clearfix">
                    <div *ngFor="let item of employeeTypeFilters;trackBy:index"
                         (click)="searchEmployeeFilter(item)"
                         class="filter-box"
                         [ngClass]="{ 'selected': item.isSelected, 'disabled': item.isDisabled }">
                      <span>{{item.count}}</span>
                      {{item.empType}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="model?.length === 0 && loaded">
              <div ng-include src="'/scripts/app/areas/events/views/event/crewSortFilters.html'" ng-controller="crewSortFiltersController" ng-init="crewItemStatusesHide=true"></div>
            </div>
            <div class="row" *ngIf="groupUpdate.status">
              <div class="col-md-12">
                <div class="form-inline form-sort-options">
                  <div class="form-group">
                    <label>Group Update:</label>
                  </div>
                  <div class="form-group">
                    <nbc-dropdown [source]="groupUpdate.options"
                                  [(model)]="groupUpdate.selected"
                                  display="name"
                                  value="name"
                                  placeholder="Select an Action"
                                  nbcSize="w-100"
                                  nbcRequired = true></nbc-dropdown>
                  </div>
                  <div class="form-group"
                       *ngIf="groupUpdate.selected && groupUpdate.selected.name !== 'Select an Action' && groupUpdate.selected.name !== ''"
                       [ngClass]="{'has-error' : groupUpdate.submitted && groupUpdate.updatedPayrollProvider === ''}">
                    <nbc-dropdown [source]="payrollProvidersList"
                                  [(model)]="groupUpdate.updatedPayrollProvider"
                                  display="description"
                                  value="description"
                                  placeholder=""
                                  nbcSize="w-100"
                                  nbcRequired = true></nbc-dropdown>
                  </div>
                  <div class="form-group">
                    <button type="button" class="btn btn-primary" (click)="groupUpdateBtn();" *ngIf="groupUpdate.selected && groupUpdate.selected.name !== 'Select an Action' && groupUpdate.selected.name !== ''">Update</button>
                    <button type="button" class="btn btn-default" (click)="resetGroupUpdate();">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content grid-content pad-0">
            <div class="row">
              <div class="col-md-12">
                <div class="hdr-grid hdr-grid-ttl-stacked" *ngIf="model?.length > 0">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="hdr-grid-ttl">
                        <div class="hdr-grid-ttl-checkbox">
                          <input authenticate-user="payrolltimesheet.update"
                                 authenticate-command="disabled"
                                 type="checkbox"
                                 [(ngModel)]="groupUpdate.selectAll"
                                 (click)="selectAll($event);" />
                        </div>
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('lastName');">
                            Contact
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'lastName'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('sso');">
                            SSO
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'sso'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('employmentStatus');">
                            Employee Status
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'employmentStatus'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-2 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('position.description');">
                            Position
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'position.description'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('positionCategory');">
                            Type
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'positionCategory'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-1 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('employeeType.description');">
                            HB
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'employeeType.description'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div>
                          <a href="javascript:void(0)" (click)="sortByColumn('payrollProvider');">
                            Payroll Provider
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'payrollProvider'">
                                <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row grid-row" *ngFor="let item of model | orderBy:  sort.reverseSort==false?'+'+sort.orderByField :'-'+sort.orderByField ">
                  <div class="col-sm-2 col-xs-12">
                    <div class="grid-itm grid-itm-checkbox"
                         [ngStyle]="{'background-color': payrollProviderColors[item.payrollProvider]}">
                      <input authenticate-user="payrolltimesheet.update"
                             authenticate-command="disabled"
                             type="checkbox"
                             [(ngModel)]="item.selected"
                             (click)="groupUpdateSelect($event)" />
                    </div>
                    <div class="grid-itm grid-itm-overflow grid-itm-contact btn-link" [ngStyle]="{'background-color':item.bgColor}">
                      <div>

                        <a routerLink="/Contacts/Detail/{{item.contactId}}" class="btn-link" target="_blank">
                          {{item.firstName}} {{item.lastName}}
                        </a>

                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-xs-4">
                    <div class="grid-itm grid-itm-overflow" style="float: none !important">
                      <div *ngIf="item.sso" class="d-flex">
                        <div class="col-sm-10">
                          {{item.sso}}
                        </div>
                        <div *ngIf =  "item?.businessSegment?.toLowerCase().indexOf('nbc sports') < 0 && item?.employmentStatus?.toLowerCase() == 'active' "
                             class="col-sm-2" >
                          <img src="/Content/app/images/icon-alert-triangle.png"
                               width="18"
                               height="18"
                               tooltip="Alert: This contact's SSO is not part of the NBC Sports"
                               container="body"
                               containerClass="tooltip"
                               placement="right" />
                        </div>
                      </div>
                      <div  *ngIf="item.payrollProvider === 'TK' && (!item.sso || item.sso === 0)"
                           placement="top"
                           tooltip="Payroll Provider ('TK') can only be selected with SSO."
                           class="payroll-provider-error">
                        Payroll Provider ("TK") can only be selected with SSO.
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1 col-xs-8">
                    <div class="grid-itm grid-itm-overflow">
                      <div>
                        {{item.employmentStatus}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-xs-8">
                    <div class="grid-itm grid-itm-overflow">
                      <div>
                        {{item.position.description}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1 col-xs-4">
                    <div class="grid-itm grid-itm-overflow">
                      <div>
                        {{item.positionCategory}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1 col-xs-4">
                    <div class="grid-itm grid-itm-overflow">
                      <div>
                        {{item?.employeeType?.description}}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-xs-4">
                    <div class="grid-itm grid-itm-dropdown grid-item-select payroll-provider" [ngClass]="{'has-error': item.payrollProvider === 'TK' && (!item.sso || item.sso === 0)}">


                      <select id="ddlProviders"
                              name="ddlProviders"
                              (change)="changeItem(item)"
                              class="form-select input-xs"
                              [(ngModel)]="item.payrollProvider"
                              authenticate-user="payrolltimesheet.update" authenticate-command="disabled">

                        <option *ngFor="let provider of payrollProvidersList"
                                [ngValue]="provider.description">
                          {{provider.description}}
                        </option>
                      </select>





                    </div>
                  </div>
                </div>

                <p *ngIf="model?.length === 0 && loaded">No results.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
