<div *ngIf="state=='failed'">
  <i class="fa fa-warning"
     style="float:right;color:red"></i>
</div>
<div *ngIf="state=='success'">
  <i class="fa fa-check-circle-o"
     style="float:right;color:green"></i>
</div>
<div *ngIf="state=='inprogress'">
  <i class="fa fa-spinner fa-pulse"
     style="float:right;color:orange"></i>
</div>
