
<div class="box-dash border-dash tranparent" >
  <div class="box-dash-hdr-bar backgrounddash headerdiv">
    <div class="float-end btn-fullscreen hidden-sm hidden-xs maximizesign" >
      <a title="Fullscreen" (click)="fullScreenmode()">
        <i style="color: white; font-size: 22px; margin-top: 5px; margin-left: 10px;" [ngClass]="!fullscreenMode ? 'fa-window-restore' : 'fa-window-maximize'" class="fa-regular"></i>

      </a>
    </div>
    <h2 class="box-dash-hdr fontcolourdash"><i class="fa fa-user" aria-hidden="true" style="margin-left:-17px"></i> Contact: Recently Viewed</h2>
  </div>
  <div class="box-dash-inner-dflt">
    <!--<div class="table-responsive">-->
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                Full Name
              </th>
              <th>Email</th>
              <th>Last Event Worked</th>
              <th>Date Viewed</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of recentlyViewed">
              <td><a class="btn-link" href="/Contacts/Detail/{{item.id}}" target="_blank">{{item.name}}</a></td>
              <!-- Added mail to link-->
              <td><a href="mailto:{{item.additionalData}}">{{item.additionalData}}</a></td>
              <td><a class="btn-link" href="/Events/Event/{{item.lastEventId}}/Summary" target="_blank">{{item.lastEventName}}</a></td>
              <!--<td>"27-sep-2022"</td>-->
              <td>{{item.dateViewed}}</td>
            </tr>
          </tbody>
        </table>
      </div>     
    </div>
  </div>
</div>
