import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { TemplateService } from "src/app/Shared/Services/template.service";
import { from, Subject } from "rxjs";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { takeUntil } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";
import * as _ from "lodash";

@Component({
    selector: "modal-add-template",
    templateUrl: "./modal-add-template.component.html",
    //styleUrls: ["../../../../assets/Content/app/areas/admin/admin.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class ModalAddTemplateComponent implements OnInit, OnDestroy {
    constructor(
        private _templateService: TemplateService,
        private _eventTypeService: EventTypeService,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}
    componentDestroy$: Subject<boolean> = new Subject();
    public onSync!: Subject<any>;
    isCloning = false;
    templateObj: any | undefined;
    authorName!: string;
    submitted = false;
    eventTypes: any[] = [];
    modalTitle = "Add New Template";
    modalSubmit = "Add Template";
    isRequestInProcess = false;

    //TODO: As of right now, the username is hardcoded, but it will need to save the actual username...once login functionality is added.
    model: any = {
        name: "",
        eventType: {},
        level: null,
        positions: [],
        author: this.authorName,
    };
    // Private Events
    getEventTypes() {
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroy$))
            .subscribe({
                next: (result: any) => {
                    this.eventTypes = result.reference;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                },
            });
    }

    checkForClone() {
        if (this.isCloning) {
            this.model = _.cloneDeep(this.templateObj);
            this.modalTitle = "Clone Template";
            this.modalSubmit = "Clone Template";
            this.model.name = "Clone of " + this.model.name;
        }
    }

    // Public Events
    addNewTemplate(isValid) {
        this.isRequestInProcess = true;
        this.submitted = true;
        if (
            !this.model.eventType ||
            !_.has(this.model.eventType, "id") ||
            this.model.eventType.id == 0
        ) {
            this.model.eventType.id = 0;
            this.isRequestInProcess = false;
            return false;
        }

        if (isValid) {
            if (this.isCloning) {
                this._templateService
                    .clonePosition(this.model.id, this.model)
                    .pipe(takeUntil(this.componentDestroy$))
                    .subscribe({
                        next: (result: any) => {
                            this.onSync.next(result.reference);
                            this.closeModal();
                        },
                        error: (err) => {
                            this.log.error(
                                "addNewTemplate(): clonePosition() got an error: " +
                                    JSON.stringify(err)
                            );
                            this.log.showModalError(
                                "Unable to clone your position at this time. Please try again later."
                            );
                        },
                    });
            } else {
                this._templateService.updateTemplate(this.model).subscribe({
                    next: (result: any) => {
                        // this.log.trace("addNewTemplate(): updateTemplate() got results: " + JSON.stringify(result));
                        this.onSync.next(result.reference);
                        this.closeModal();
                    },
                    error: (err) => {
                        this.isRequestInProcess = false;
                        this.log.error(
                            "addNewTemplate(): updateTemplate() got an error: " +
                                JSON.stringify(err)
                        );
                        this.log.showModalError(
                            "Unable to add a position template at this time. Please try again later."
                        );
                    },
                });
            }
        }
        return true;
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.templateObj = this.templateObj;
        this.getEventTypes();
        this.checkForClone();
    }
    ngOnDestroy() {
        this.componentDestroy$.next(true);
        this.componentDestroy$.complete();
    }
}
