<div class="hr-dashboard">
  <div class="onboarding-filter-div" authenticate-user="HR.Filter.Update"
       authenticate-command="hide">
    <div class="row d-flex">
      <div class="col-10 p-0">
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="positionStatus"
                        [source]="positionStatusList"
                        value="value"
                        display="name"
                        [(model)]="selectedRequestStatus"
                        placeHolderDisplay="Request Status"
                        placeHolderValue="0"
                        (change)="changeRequestStatus()">
            </nbc-select>
          </span>
        </div>
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="positionType"
                        [(model)]="selectedPositionType"
                        [source]="positionTypeList"
                        placeHolderDisplay="Position Type"
                        placeHolderValue="0"
                        display="name"
                        value="value"
                        (change)="changePositionType()">
            </nbc-select>
          </span>
        </div>
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="employeeStatus"
                        [(model)]="selectedEmployeeStatus"
                        [source]="employeeStatusList"
                        placeHolderDisplay="Employee Status"
                        placeHolderValue="0"
                        display="name"
                        value="value"
                        (change)="changeEmployeeStatus()">
            </nbc-select>
          </span>
        </div>
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="exportStatus"
                        [(model)]="selectedExportStatus"
                        [source]="exportStatusList"
                        placeHolderDisplay="Submitted On Mass OM"
                        placeHolderValue="0"
                        display="name"
                        value="value"
                        (change)="changeExportStatus()">
            </nbc-select>
          </span>
        </div>
        <button class="onboarding-export-button"
                [disabled]="exportEnable"
                (click)="export()"
                authenticate-user="HR.Request.Export"
                authenticate-command="hide">
          + Export Position Request
        </button>
        <button class="onboarding-button-bulkreminder"
                [disabled]="!reminderEnable"
                (click)="SendReminderBulk()"
                authenticate-user="HR.Request.Update"
                authenticate-command="hide">
          Send Reminders to Group
        </button>
      </div>
    </div>
    <div class="row">
      <div class="form-group onboarding col-md-6">
      <span authenticate-user="HR.Filter.Update"
            authenticate-command="hide" class="ps-0" style="max-width:200px;float:left;padding-right:10px;">
        <nbc-select name="searchCriteriaSelection"
                    [(model)]="selectSearchCriteria"
                    [source]="searchCriteria"
                    placeHolderDisplay="Select a Search Criteria"
                    placeHolderValue="0"
                    display="optionDescription"
                    value="optionValue"
                    (change)="changeSearchCriteria()">
        </nbc-select>&nbsp;
      </span>
      <span class="d-flex">
        <input class="form-control w-25 input-sm"
               name="contactSearchCriteria"
               [disabled]="searchTextboxEnable"
               placeholder="Enter the Name"
               [(ngModel)]="contactSearchTerm"
               [typeahead]="contactObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="fullName"
               [typeaheadOptionsLimit]="25"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeaheadWaitMs]="500"
               (typeaheadOnSelect)="onContactSearchTermSelect($event)"
               [isAnimated]="true"
               authenticate-user="HR.Filter.Update"
               authenticate-command="hide"
               *ngIf="selectSearchCriteria?.optionValue == '1' || selectSearchCriteria?.optionValue == '3' || selectSearchCriteria?.optionValue == '7'" />

        <input class="form-control w-25  input-sm"
               name="positionSearchCriteria"
               [disabled]="searchTextboxEnable"
               placeholder="Enter the Position"
               [(ngModel)]="positionSearchTerm"
               [typeahead]="positionObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="description"
               [typeaheadOptionsLimit]="25"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeaheadWaitMs]="500"
               (typeaheadOnSelect)="onPositionSearchTermSelect($event)"
               [isAnimated]="true"
               authenticate-user="HR.Filter.Update"
               authenticate-command="hide"
               *ngIf="selectSearchCriteria?.optionValue == '4'" />

          <input class=" form-control w-25  input-sm"
                 name="textSearchCriteria"
                 [disabled]="searchTextboxEnable || !selectSearchCriteria || selectSearchCriteria?.optionValue=='0'"
                 placeholder="Enter the Search Term"
                 [(ngModel)]="queryContainer.query.searchTerm.description"
                 [isAnimated]="true"
                 authenticate-user="HR.Filter.Update"
                 authenticate-command="hide"
                 *ngIf="!selectSearchCriteria || selectSearchCriteria?.optionValue=='0' || selectSearchCriteria?.optionValue == '2' || selectSearchCriteria?.optionValue == '5' || selectSearchCriteria?.optionValue == '6'" />

          <button class="onboarding-go-button"
                  [disabled]="goButtonEnable"
                  (click)="queryContainer.query.currentPage=1; search()"
                  authenticate-user="HR.Filter.Update"
                  authenticate-command="hide">
            Go
          </button>

          <button class="onboarding-reset-button"
                  (click)="restartSearch()"
                  authenticate-user="HR.Filter.Update"
                  authenticate-command="hide">
            Reset
          </button>
        </span>
      </div>
    </div>

    <!--<input authenticate-user="event.crew.update"
         [(ngModel)]="contactSearchTerm"
         appInputRestriction="restrictChars"
         name="contactSearch"
         [typeahead]="contactObservable$"
         [typeaheadAsync]="true"
         [typeaheadOptionsLimit]="10"
         placeholder="{{placeholder}}"
         class="form-control"
         [isAnimated]="true"
         typeaheadWaitMs="200"
         (blur)="onBlur()"
         (typeaheadOnSelect)="onContactSelected($event)">-->
  </div>
  <div class="page-content pad-10">
    <p *ngIf="gridData.items.length === 0">No Results</p>
    <div *ngIf="gridData.items.length > 0">
      <div class="row view-options">
        <div class="col-md-4">
          <div class="form-inline form-sort-options">
            <div class="form-group">
              <nbc-page-size [source]="pageSizeList" [model]="queryContainer.query.pageSize" (nbcValueChange)="searchPage($event)"></nbc-page-size>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group text-end d-flex float-end">
            <label class="pagination-results">
              <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                currentPage="{{queryContainer.query.currentPage}}"
                                totalRecords="{{queryContainer.query.totalRecords}}"></pagination-label>
            </label>
            <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                            itemsPerPage="{{queryContainer.query.pageSize}}"
                            [(currentPage)]="queryContainer.query.currentPage"
                            (pageChanged)="search();"></nbc-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content pad-10" >
    <div class="floatingStatus">
      <ul id="progress">
        <li class="PRS">Position Requests Submitted</li>
        <li class="RS">Registrations Submitted</li>
        <li class="RC">Registrations Completed</li>
        <li class="RA">Registrations Approved</li>
        <li class="OI">Onboardings Inprogress</li>
        <li class="COM">Requests Completed</li>
      </ul>
      <div class="RR">
        Registrations Rejected
      </div>
    </div>
    <requests-grid></requests-grid>
  </div>
</div>
<app-nbc-file-download></app-nbc-file-download>

