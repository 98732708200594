import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ModelPositionrequestComponent } from "../../Dashboard/position-request-onboarding/model-positionrequest/model-positionrequest.component";
import { DashboardService } from "../.././/Shared/Services/dashboard.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { stat } from "fs";
import { ModelI9verifyComponent } from "./model-i9verify/model-i9verify.component";

@Component({
    selector: "app-position-request-onboarding",
    templateUrl: "./position-request-onboarding.component.html",
    //styleUrls: ['./position-request-onboarding.component.less']
})
export class PositionRequestOnboardingComponent implements OnInit, OnDestroy {
    bsModalRef?: BsModalRef;
    constructor(
        private cookieService: CookieService,
        private _eventBus: EventBusService,
        private _modalService: BsModalService,
        private _dashboardService: DashboardService,
        private _router: Router,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    positionrequest: any | undefined;
    i9document: any | undefined;
    submittedalert = false;
    rejectedalert = false;
    rejectedHMalert = false;
    i9verifyalert = false;

    getPositionRequestData() {
        this._dashboardService
            .getPositionRequestSummary()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace("successfully retrieved dashboard data");
                    this.positionrequest = data;
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the position request summary at this time. Please try again later."
                        );
                }
            );
    }

    getI9DocumentVerifyData() {
        this._dashboardService
            .getI9DocumentVerify()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace("successfully retrieved dashboard data");
                    this.i9document = data;
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the I9 document Verify at this time. Please try again later."
                        );
                }
            );
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    i9DocumentVerify() {
        // this._eventBus.emit(new EmitEvent("positionrequestonboarding:onRequestProgress", "ModelPositionrequest",''));
        if (this.i9document.verifyCount > 0) {
            if (this.i9document.outOfComplianceCount > 0) {
                this.setAlertCookie("i9verifyalert");
            }
            const initialState: any = {
                self: this,
                positionrequest: this.i9document.i9DocumentVerifyList,
            };
            const modalOptions = {
                animated: true,
                backdrop: true,
                keyboard: false,
                ignoreBackdropClick: true,
                initialState: initialState,
                class: "dashboardModal",
            };

            this.bsModalRef = this._modalService.show(
                ModelI9verifyComponent,
                modalOptions
            );
            this.bsModalRef.content.onRejection.subscribe((result: any) => {
                this.getI9DocumentVerifyData();
            });
            this.getAlertCookie();
        }
    }

    onRequestProgress(status: any, count: any) {
        // this._eventBus.emit(new EmitEvent("positionrequestonboarding:onRequestProgress", "ModelPositionrequest",''));
        if (count > 0) {
            this.setAlertCookie(status);
            const initialState: any = {
                self: this,
                positionrequest: _.filter(
                    this.positionrequest.positionRequestList,
                    (matchingstatus) => {
                        return matchingstatus.onboardingStatus
                            .toLowerCase()
                            .includes(status.toLowerCase());
                    }
                ),
            };
            const modalOptions = {
                animated: true,
                backdrop: true,
                keyboard: false,
                initialState: initialState,
                class: "dashboardModal",
            };

            this.bsModalRef = this._modalService.show(
                ModelPositionrequestComponent,
                modalOptions
            );
            this.bsModalRef.content.onRejection.subscribe((result: any) => {
                this.getPositionRequestData();
            });
            this.getAlertCookie();
        }
    }

    setAlertCookie(status: any) {
        const midnight = new Date();
        midnight.setHours(23, 59, 59, 0);
        if (status == "Rejected (Contact)") {
            this.cookieService.set("Rejected (Contact)", "1", {
                path: "/",
                expires: midnight,
            });
        } else if (status == "Registration Submitted") {
            this.cookieService.set("Registration Submitted", "1", {
                path: "/",
                expires: midnight,
            });
        } else if (status == "Rejected (I9 Page 2)") {
            this.cookieService.set("Rejected (I9 Page 2)", "1", {
                path: "/",
                expires: midnight,
            });
        } else if (status == "i9verifyalert") {
            this.cookieService.set("i9verifyalert", "1", {
                path: "/",
                expires: midnight,
            });
        }
    }

    getAlertCookie() {
        if (this.cookieService.get("Rejected (Contact)")) {
            this.rejectedalert = true;
        }
        if (this.cookieService.get("Registration Submitted")) {
            this.submittedalert = true;
        }
        if (this.cookieService.get("Rejected (I9 Page 2)")) {
            this.rejectedHMalert = true;
        }
        if (this.cookieService.get("i9verifyalert")) {
            this.i9verifyalert = true;
        }
    }

    ngOnInit(): void {
        this.getPositionRequestData();
        this.getAlertCookie();
        this.getI9DocumentVerifyData();
    }
}
