import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateSeasonComponent } from "../create-season/create-season.component";
import { Router } from "@angular/router";
import { SharedDataService } from "../../../../Shared/sharedData.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-season-header",
    templateUrl: "./season-header.component.html",
    //styleUrls: [
    //  "../../../../../assets/Content/app/areas/events/events.less",],
    //encapsulation: ViewEncapsulation.Emulated
})
export class SeasonHeaderComponent implements OnInit, OnDestroy {
    constructor(
        private log: NbcLoggerService,
        private _seasonServices: SeasonService,
        private activateRoute: ActivatedRoute,
        private _modalService: BsModalService,
        private _sharedData: SharedDataService,
        private router: Router
    ) {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
    seasonId = window.preload.seasonId;
    season: any = {};
    isGetSeasonData = false;
    @Input() pageName: any;
    showGear = true;
    bsModalRef?: BsModalRef;
    private subs = new SubSink();
    getSeasonData() {
        //  this.activateRoute.queryParams.ge?
        this.log.trace("getSeasonData called");
        // usSpinnerService.spin("searchSpinner");
        this.subs.sink = this._seasonServices
            .getSeasonData(this.seasonId)
            .subscribe((result) => {
                // this.log.trace("getSeasonData successfully retrieved the season : " + JSON.stringify(result));
                this.season = result.reference;
                this._sharedData.setSeasonData(this.season);
                this.isGetSeasonData = true;
            });
    }

    openEditSeasonModal() {
        const initialState: any = {
            hideConfirm: false,
            // eventTypeId: id,
            season: this.season,
        };

      const modalOptions = {
            id:1,
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.bsModalRef = this._modalService.show(
            CreateSeasonComponent,
            modalOptions
        );
        this.bsModalRef.content.onSeasonSync.subscribe((seasonId) => {
            this.getSeasonData();
        });
    }

    ngOnInit(): void {
        this._sharedData.setEvenSection(true);
        this._sharedData.setCss("event");
        this.activateRoute.params.subscribe((params) => {
            this.seasonId = params["id"];
            this._sharedData.setCurrentSeasonId(this.seasonId);
            this.getSeasonData();
        });

        this._sharedData.getCurrentPage().subscribe((page) => {
            this.pageName = page;
        });
    }
    ngOnDestroy(): void {
        this._sharedData.setEvenSection(false);
        this.subs.unsubscribe();
    }
}
