import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(
    private log: NGXLogger,
    private http: HttpClient,
    private _apiroot: BaseUrlHelperService
  ) {}

  get(): Observable<any> {
    return this.http.get<any>(this._apiroot.baseUrl + 'Announcement/get').pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
