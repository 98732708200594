import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnInit,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    ChangeDetectorRef,
} from "@angular/core";
import * as _ from "lodash";
import { NbcBaseComponent } from "../../../Core/component/Base.Component";
import { formViewProvider } from "../../../Core/view-provider/form-view-provider";

@Component({
    selector: "nbc-select",
    templateUrl: "./nbc-select.component.html",
    viewProviders: [formViewProvider],
})
export class NbcSelectComponent
    extends NbcBaseComponent
    implements OnInit, OnChanges, AfterViewInit
{
    constructor(private cd: ChangeDetectorRef) {
        super();
    }
    @Input() source: any[] | undefined;
    @Input() model: any | undefined;
    @Input() display: any | undefined;
    @Input() nbcOrderBy: any | undefined;
    @Input() title: any | undefined;
    @Input() value: any | undefined;
    @Input() placeHolderDisplay: any | undefined;
    @Input() placeHolderValue: any | null;
    @Input() nbcDisabled: any | undefined;
    @Input() name: any;
    //@Input("authenticate-user") nbcAuthenticateUser: any | undefined;
    //@Input("authenticate-command") nbcAuthenticateCommand: any | undefined;
    //@Input("authenticate-department") nbcAuthenticateDepartment:
    //    | any
    //    | undefined;
    //@Input("authenticate-contact-id") nbcAuthenticateContactId: any | undefined;
    //@Input("authenticate-disabled") nbcAuthenticateDisabled: any | undefined;
    //@Input("authenticate-required") nbcAuthenticateRequired: any | undefined;
    @Input() nbcClass: any | undefined;
    @Input() nbcMismatch: any | undefined;
    @Input() customErrorClass: any | undefined;
    @Input() defaultSelectClass = "dropdown-toggle form-select ";
    @Input() nbcRequired = false;
    @Input() nbcAddBlank = false;
    @Input() isModelAnObject = true;
    @Input() attachOldValue = false;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcValueChange = new EventEmitter<any>();

    selected: any | undefined;
    internalSource: any[] | undefined;
    isAuthorized = true;
    oldValue: any;
    optionSelected(manuallySelected?) {
        if (_.isUndefined(this.internalSource)) {
            this.internalSource = _.cloneDeep(this.source);
        }

        if (!_.isUndefined(this.value)) {
            this.oldValue = manuallySelected ? this.model : this.oldValue;
            this.model = this.refineModelValue(this.selected);

            if (
                !this.model &&
                (this.selected === this.placeHolderValue ||
                    this.selected === this.placeHolderDisplay)
            ) {
                this.updatePreSelectedValue();
            }
            //this condition will occur when user selects the placeholder to reset the value.
            if (
                this.model &&
                (this.selected === this.placeHolderValue ||
                    this.selected === this.placeHolderDisplay)
            ) {
                this.model = null;
            }
        } else {
            this.model = this.selected;
        }
        if (manuallySelected) {
            let valueToEmit = null;
            if (this.model) {
                valueToEmit = this.isModelAnObject
                    ? this.model
                    : this.model[this.value] == this.placeHolderDisplay
                      ? null
                      : this.model[this.value];
            }
            //old value is available only when both required flags are set.i.e. model must be an object
            if (this.attachOldValue && this.isModelAnObject) {
                _.assign(valueToEmit, { oldValue: this.oldValue });
            }
            this.nbcValueChange.emit(valueToEmit);
            this.modelChange.emit(valueToEmit);
        }
    }

    onFocus() {
        this.focus.emit();
    }

    compareFn(c1: any): boolean {
        return true;
    }

    refineModelValue(selectedOptionValue) {
        if (_.isUndefined(this.internalSource)) {
            this.internalSource = _.cloneDeep(this.source);
        }
        return _.find(
            this.internalSource,
            (x) => x[this.value] == selectedOptionValue
        );
    }

    updatePreSelectedValue() {
        if (this.model && !_.isEmpty(this.model)) {
            this.selected = this.isModelAnObject
                ? this.model[this.value]
                : this.model;
            this.optionSelected();
        } else {
            this.selected = this.internalSource
                ? this.internalSource[0][this.value]
                : null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                this.model = current; // this.refineModelValue(current);
                this.updatePreSelectedValue();
            }
            if (propName === "nbcClass") {
                const current = changes["nbcClass"]["currentValue"];
                const prev = changes["nbcClass"]["previousValue"];
                this.nbcClass = current;
            }
            if (propName === "nbcMismatch") {
                const current = changes["nbcMismatch"]["currentValue"];
                const prev = changes["nbcMismatch"]["previousValue"];
                this.nbcMismatch = current;
            }
            if (propName === "customErrorClass") {
                const current = changes["customErrorClass"]["currentValue"];
                const prev = changes["customErrorClass"]["previousValue"];
                this.customErrorClass = current;
            }
            if (propName === "source") {
                const current = changes["source"]["currentValue"];
                const prev = changes["source"]["previousValue"];
                this.source = current;
                this.initialize();
                if (changes["model"]) {
                    const mcurrent = changes["model"]["currentValue"];
                    const mprev = changes["model"]["previousValue"];
                    this.model = mcurrent; // this.refineModelValue(mcurrent);
                    this.updatePreSelectedValue();
                }
            }

            if (propName === "authenticateRequired") {
                const current = changes["authenticateRequired"]["currentValue"];
                const prev = changes["authenticateRequired"]["previousValue"];
                this.authenticateRequired = current;
            }
        }
    }

    addPlaceholdertoSource() {
        if (
            this.internalSource &&
            this.placeHolderValue &&
            this.placeHolderDisplay
        ) {
            const existingPlaceholder = _.find(this.internalSource, (o) => {
                return o[this.value] == this.placeHolderValue;
            });
            if (!existingPlaceholder) {
                const clonedItem = _.create(this.internalSource[0]?.__proto__);
                if (this.value == this.display) {
                    //exceptional condition when we want display text as value. E.g. Company code {'id':null, desc:'ABC0'}
                    clonedItem[this.display] = this.placeHolderDisplay;
                } else {
                    //normal case where display and values are diferent. E.g.{id:5,desc:john}
                    clonedItem[this.value] = "";
                    clonedItem[this.display] = this.placeHolderDisplay;
                }
                this.internalSource?.unshift(clonedItem);
            }
        }
    }

    addBlank() {
        if (this.internalSource && this.nbcAddBlank) {
            const clonedItem = {};
            const existingBlankValue = _.find(this.internalSource, (o) => {
                return o[this.value] == this.placeHolderValue;
            });

            if (!existingBlankValue) {
                clonedItem[this.value] = this.placeHolderValue;
                clonedItem[this.display] = this.placeHolderDisplay;
                this.internalSource?.unshift(clonedItem);
            }
        }
    }

    sortBy(source) {
        if (
            typeof this.nbcOrderBy === "string" &&
            !_.isEmpty(this.nbcOrderBy)
        ) {
            source.sort((a, b) => {
                const itemA = a[this.nbcOrderBy]
                    ? a[this.nbcOrderBy].toLowerCase()
                    : "";
                const itemB = b[this.nbcOrderBy]
                    ? b[this.nbcOrderBy].toLowerCase()
                    : "";

                if (itemA < itemB) return -1; //sort string ascending return -1;
                if (itemA > itemB) return 1;
                return 0; //default return value (no sorting)
            });
        }
        return source;
    }

    initialize() {
        const originalSource = this.source || [];
        this.source = this.sortBy(originalSource);
        this.internalSource = _.cloneDeep(this.source);

        if (this.nbcAddBlank) this.addBlank();
        this.addPlaceholdertoSource();
        setTimeout(() => this.updatePreSelectedValue(), 100);
    }

    ngAfterViewInit() {
        this.initialize();
        setTimeout(() => {
            this.isAuthorized = this.authorize();
        }, 200);
        setTimeout(() => this.cd.detectChanges(), 200);
    }

    ngOnInit() {
        if (!this.name)
            this.name =
                "nbcselect_" +
                Date.now().toString(36) +
                Math.random().toString(36).substr(2);
    }
}
