<div>
  <header id="col-main-hdr">
    <h1> <i class="fa fa-th"></i> Templates</h1>
  </header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li>Templates</li>
    </ul>
  </nav>
  <div class="page-container">
    <div class="container-fluid">
      <span us-spinner spinner-key="searchSpinner"></span>
      <div nbc-alerts></div>
      <div class="row" authenticate-user="position.retrieve">
        <div class="col-md-12">
          <div class="header-bar clearfix">
            <p class="heading-title pull-left">Event Position Templates</p>
            <button type="button" class="btn btn-primary pull-right" (click)="openPositionTemplateModal()" authenticate-user="position.create">+ Add a Template</button>
          </div>
          <div class="content-filters">
            <div class="row">
              <div class="col-sm-6">
                <form class="form-inline" submit="searchTemplates()">
                  <div class="form-group">
                    <input class="form-control"
                           name="searchText"
                           appInputRestriction="restrictChars"
                           type="text"
                           [(ngModel)]="filter.searchText"
                           placeholder="Template Name"
                           maxlength="100" />
                    <!--<button type="submit" class="btn btn-secondary" (click)="searchTemplates()">Search</button>-->
                    <button type="button" class="btn btn-default" (click)="clearSearch()">Clear</button>
                  </div>
                </form>
              </div>
              <div class="col-sm-6">
                <div class="form-group form-inline pull-right">
                  <nbc-dropdown [source]="filter.templateTypes"
                                [(model)]="filter.isActive"
                                display="name"
                                name="isActive"
                                value="value"
                                nbcSize="w-100"
                                (nbcOnOptionSelect)="onFilterSelect()">
                  </nbc-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="row">
              <div class="col-md-12">

                <div class="table-responsive">
                  <table class="table table-sortable">
                    <thead>
                      <tr>
                        <th>
                          <div (click)="sortByColumn('name');" style="cursor: pointer;">
                            Template Name
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'name'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('createdOn');" style="cursor: pointer;">
                            Created on
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField === 'createdOn'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('editedOn');" style="cursor: pointer;">
                            Edited on
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'editedOn'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('author');" style="cursor: pointer;">
                            Author
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'author'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('eventType.name');" style="cursor: pointer;">
                            Event Type
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'eventType.name'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('positionsQuantity');" style="cursor: pointer;">
                            # of Pos.
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'positionsQuantity'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div (click)="sortByColumn('level');" style="cursor: pointer;">
                            Level
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'level'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="(templateList | filterBy:['name']:filter.searchText | filterBy:['isActive']:selectedFilter) as result">
                        <tr *ngFor="let template of result | orderBy: sort.orderFieldname" [ngClass]="{'template-row-inactive' : !template.isActive}">
                          <td><a class="btn-link" routerLink="{{template.id}}" authenticate-user="position.update">{{template.name}}</a></td>
                          <td>{{template.createdOn | date}}</td>
                          <td>{{template.editedOn | date}}</td>
                          <td>{{template.author}}</td>
                          <td>{{template.eventType?.name}}</td>
                          <td>{{template.positionsQuantity}}</td>
                          <td>{{template.level}}</td>
                          <td class="text-end">
                            <a class="btn btn-icon-clear" routerLink="{{template.id}}" authenticate-user="position.update"><i class="fa fa-pencil"></i></a>
                            <button type="button" class="btn btn-icon-clear" (click)="openPositionTemplateModal(template)" authenticate-user="position.create"><i class="fa fa-copy"></i></button>
                          </td>
                        </tr>
                        <tr *ngIf="result.length === 0">
                          <td colspan="8">No results</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
