import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";
import { EventListComponent } from "./dashboard/eventlist.component";
import { SeasonListComponent } from "./dashboard/seasonlist.component";
import { EventDashboardComponent } from "./dashboard/eventdashboard.component";
import { EventSummaryComponent } from "./summary/eventsummary.component";
import { CrewPageComponent } from "./crew/crew-page.component";
import { EventLayoutComponent } from "./event-layout.component";
import { TimeSheetComponent } from "./timesheet/timesheet.component";
import { CreateSeasonOrEvent } from "./create-seasonorevent/create-season-event.component";
import { CreateEventConponent } from "./manageEvent/createevent/create-event.component";
import { SeasonDashboardComponent } from "./season/dashboard/dashboard.component";
import { SeasonSetupComponent } from "./season/components/season-setup/season-setup.component";
import { SeasonLayoutComponent } from "./season/season-layout.component";
import { EventModuleLayoutComponent } from "./event-module-layout.component";

const routes: Routes = [
    {
        path: "",
        component: EventModuleLayoutComponent,
        children: [
            {
                path: "",
                component: EventDashboardComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "Eventlist",
                        pathMatch: "full",
                    },
                    {
                        path: "Eventlist",
                        component: EventListComponent,
                    },
                    {
                        path: "Seasonlist",
                        component: SeasonListComponent,
                    },
                ],
            },
            {
                path: "CreateSeasonOrEvent",
                component: CreateSeasonOrEvent,
            },
            {
                path: "createEvent/:id",
                component: CreateEventConponent,
            },
            {
                path: "Event/:id",
                component: EventLayoutComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "Summary",
                        pathMatch: "full",
                    },
                    {
                        path: "Summary",
                        component: EventSummaryComponent,
                    },
                    {
                        path: "Crew",
                        component: CrewPageComponent,
                    },
                    {
                        path: "Timesheet",
                        component: TimeSheetComponent,
                    },
                ],
            },
            {
                path: "Season/:id",
                component: SeasonLayoutComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "dashboard",
                        pathMatch: "full",
                    },
                    {
                        path: "dashboard",
                        component: SeasonDashboardComponent,
                    },
                    {
                        path: "setup",
                        component: SeasonSetupComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EventRouterModule {
    static forChild() {
        return {
            ngModule: EventRouterModule,
            providers: [],
        };
    }
}
