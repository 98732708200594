import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: "modal-contact-sap-result",
    templateUrl: "./modal-contact-sap-result.html",
})
export class ModalContactSapComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    //subject to transmit the results
    public onSync!: Subject<any>;
    public onCancelSync!: Subject<any>;

    canSyncContact = false;
    selectedContact: any | undefined;
    contacts: any[] = [];
    contactBeforeSync: any | undefined;
    items: any = {};
    hcmResultsSet: any = {};
    hcmContact: any = {};

    //Closing Modal
    closeModal() {
        this.onCancelSync.next(this.contactBeforeSync);
        this.bsModalRef.hide();
    }
    //Contact Radio button selection
    selectContact(selectedIndex) {
        this.canSyncContact = true;
        this.selectedContact = this.contacts[selectedIndex];
        for (let contact = 0; contact < this.contacts.length; contact++) {
            if (selectedIndex !== contact) {
                this.contacts[contact].isSelected = "false";
            }
        }
    }
    //Method to sync selected contact
    syncContact(selectedContact) {
        if (selectedContact) {
            this.canSyncContact = false;
            this.contactBeforeSync = this.hcmContact;
            this.contactBeforeSync.sapJobTitle = selectedContact.sapJobTitle;
            this.contactBeforeSync.hrManager = selectedContact.hrManager;
            this.contactBeforeSync.hrManagerFlag =
                selectedContact.hrManagerFlag;
            this.contactBeforeSync.superManager = selectedContact.superManager;
            this.contactBeforeSync.superManagerFlag =
                selectedContact.superManagerFlag;
            this.contactBeforeSync.directManager =
                selectedContact.directManager;
            this.contactBeforeSync.directManagerFlag =
                selectedContact.directManagerFlag;
            this.contactBeforeSync.workCity = selectedContact.workCity;
            this.contactBeforeSync.workState = selectedContact.workState;
            this.contactBeforeSync.workZip = selectedContact.workZip;
            this.contactBeforeSync.subBusSegment =
                selectedContact.subBusSegment;
            this.contactBeforeSync.perNer = selectedContact.perNer;
            this.contactBeforeSync.sso = selectedContact.sso;
            this.contactBeforeSync.firstName = selectedContact.firstName;
            this.contactBeforeSync.lastName = selectedContact.lastName;
            this.contactBeforeSync.ssnLastFour = selectedContact.ssnLastFour;
            this.contactBeforeSync.dobDay = selectedContact.dobDay;
            this.contactBeforeSync.dobMonth = selectedContact.dobMonth;
            this.contactBeforeSync.employeeStatus =
                selectedContact.employeeStatus;
            this.contactBeforeSync.employeeGroup =
                selectedContact.employeeGroup;
            this.contactBeforeSync.employeeSubGroup =
                selectedContact.employeeSubGroup;
            this.contactBeforeSync.unionAffiliation =
                selectedContact.unionAffiliation;
            this.contactBeforeSync.city = selectedContact.city;
            this.contactBeforeSync.province = selectedContact.state;
            this.contactBeforeSync.postalCode = selectedContact.postalCode;
            this.contactBeforeSync.country = selectedContact.countryCode;
            this.contactBeforeSync.payrollArea = selectedContact.payrollArea;
            this.contactBeforeSync.businessSegment =
                selectedContact.businessSegment;
            this.contactBeforeSync.lastUpdatedbySAP =
                selectedContact.lastUpdatedbySAP;
            this.contactBeforeSync.employmentStatus =
                selectedContact.employmentStatus;
            this.contactBeforeSync.companyCode = selectedContact.companyCode;

            this.onSync.next(this.contactBeforeSync);
            this.bsModalRef.hide();
            // $modalInstance.dismiss("cancel");

            // this.$emit("onContactSync", this.contactBeforeSync);
        } else {
            this.contactBeforeSync = this.hcmContact;
            this.onCancelSync.next(this.contactBeforeSync);
            this.bsModalRef.hide();
            //this.$emit("onCancelContactSync", this.contactBeforeSync);
        }
    }
    getMonth(monthNumber) {
        const month = new Date(Date.parse(monthNumber + "1, 2016")); //.format("MMM");
        return month;
    }
    ngOnInit() {
        this.onSync = new Subject();
        this.onCancelSync = new Subject();

        this.contacts = this.hcmResultsSet.contacts;
        this.hcmContact = this.hcmContact;
        this.contactBeforeSync = this.hcmContact;
        for (let contact = 0; contact < this.contacts.length; contact++) {
            this.contacts[contact].isSelected = "false";
            if (this.contacts[contact].lastUpdatedbySAP !== null) {
                this.contacts[contact].lastUpdatedbySAP = new Date(
                    this.contacts[contact].lastUpdatedbySAP
                );
            }
        }
    }
}
