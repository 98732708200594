import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { PermissionUtility } from "../Core/services/permission-utility.service";
import { SharedDataService } from "../Shared/sharedData.service";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {
    constructor(
        private _sharedDataService: SharedDataService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    tabs = [
        {
            heading: "Job Title",
            route: "job-title",
            active: true,
            permissionKey: "admin.jobtitle",
            hasAccess: false,
            authentication: "Admin.JobTitle.Retrieve",
        },
        {
            heading: "Venue",
            route: "venue",
            active: false,
            permissionKey: "admin.venu",
            hasAccess: false,
            authentication: "remotesvenuesetup.retrieve",
        },
        {
            heading: "Event Type",
            route: "event-type",
            active: false,
            permissionKey: "admin.eventType",
            hasAccess: false,
            authentication: "Admin.EventType.Retrieve",
        },
        {
            heading: "Network",
            route: "network",
            active: false,
            permissionKey: "admin.network",
            hasAccess: false,
            authentication: "Admin.Network.Retrieve",
        },
        {
            heading: "Network Group",
            route: "network-group",
            active: false,
            permissionKey: "admin.networkGroup",
            hasAccess: false,
            authentication: "Admin.NetworkGroup.Retrieve",
        },
        {
            heading: "Minimum Wage",
            route: "minimum-wage",
            active: false,
            permissionKey: "admin.minimumWage",
            hasAccess: false,
            authentication: "Admin.minimumWage.Retrieve",
        },
    ];
    _: any;

    activateSelectedTab() {
        const activatedRoute =
            this.route.snapshot?.firstChild?.routeConfig?.path;
        if (activatedRoute) {
            _.forEach(this.tabs, (rt) => {
                rt.active = false;
                if (activatedRoute?.toLowerCase() === rt.route.toLowerCase()) {
                    rt.active = true;
                }
            });

            //we need to check if we have tabs available (we remove them from list as per authrorization)
            if (this.tabs?.length > 0) {
                //if we don't have any specific which is active, we need to set the first to default and load
                if (_.findIndex(this.tabs, { active: true }) < 0) {
                    this.tabs[0].active = true;
                }
                this.router.navigate([this.tabs[0].route], {
                    relativeTo: this.route,
                });
            } else this.router.navigate(["/unauthorized"]);
        }
    }

    go(route: string) {
        _.forEach(this.tabs, function (tab, index) {
            tab.active = false;
            if (tab.route === route) {
                tab.active = true;
            }
        });
        this.router.navigate([route], { relativeTo: this.route });
    }

    authorizeTabAccess() {
        const access = PermissionUtility.PermissionUtilities();
        const user = window.preload.user;
        _.forEach(this.tabs, function (tab, index) {
            tab.hasAccess = access.hasAccess(
                user,
                user.allFeatures,
                tab.authentication.split(","),
                null,
                null,
                null
            );
        });
        _.remove(this.tabs, {
            hasAccess: false,
        });
    }

    ngOnInit(): void {
        this._sharedDataService.setCss("admin");
        this.authorizeTabAccess();
        this.activateSelectedTab();
    }
}
