import {
    OnInit,
    OnDestroy,
    Component,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
} from "@angular/core";
import { Subject, Observable, Subscriber, of } from "rxjs";
import { takeUntil, switchMap } from "rxjs/operators";
import * as _ from "lodash";
import * as moment from "moment";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { ControlContainer, NgForm } from "@angular/forms";

@Component({
    selector: "[nbc-time]",
    templateUrl: "./nbc-time.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class NbcTimeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() id: string | undefined;
    @Input() name!: string;
    @Input() type = "ampm";
    @Input() isStartTime = false;
    @Input() DefaultDate: string | undefined;
    @Input() otherDate!: any;
    @Input() index: any;
    @Input() model!: any;
    @Input() nbcClass: string | undefined;
    @Input() isValidTime = true;
    @Input() format = "yyyy-MM-dd HH:mm";
    @Input() placeholder = "end";
    @Input() nbcDisabled = false;
    @Input() nbcRequired = false;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcClassChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcBlur = new EventEmitter<any>();

    selectedTime!: string;
    timeslots: any[] | undefined;
    timeslotObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<boolean> = new Subject();
    showme = false;
    top = 0;
    left = 0;
    options: any = {
        pm_text: "PM",
        am_text: "AM",
    };

    timeParseFormat: any = {
        ampm: "h:mm A",
        "24hr": "HH:mm",
    };

    onTimeSelected(event: TypeaheadMatch) {
        this.createDate();
    }

    onManualEntry($event: any) {
        //this.sanitizeManualInput();
        if (_.isEmpty(this.selectedTime)) {
            this.modelChange.emit(null);
            setTimeout(() => this.nbcBlur.emit(this.id), 200);
        } else {
            //if (moment(this.selectedTime, "HHmm").isValid())
            this.selectedTime = this.hookReadMind();
            this.createDate();
            setTimeout(() => this.nbcBlur.emit(this.id), 300);
        }
    }

    OnBlur($event: any) {
        if (_.isEmpty($event.target.value)) {
            this.modelChange.emit(null);
            this.nbcBlur.emit(this.id);
        }
    }

    /*
     * Where our formatting actually happens.
     *
     * @param {String} val The value we're formatting
     */
    hookReadMind() {
        const am_pm = "";

        const val = this.selectedTime.toLowerCase();
        return this._createStringFromFormat(this.getTimeObject(val, am_pm));
    }

    /**
     * Format what we've got into an english readable format.
     * So turn '1030' into '10:30 am' etc.
     *
     * @param {String} original_val The original value to format
     * @param {String} am_pm Whether or not it's 'am' or 'pm'
     */
    getTimeObject(original_val: any, am_pm: any) {
        const t = this.parseTime(original_val, "g:i:A").split(":");
        const h = t[0];
        const m = t[1];
        let new_num;

        if (!h && !m) {
            new_num = this.options.empty;
        } else {
            new_num = {
                h: h,
                m: m,
                sep: ":",
                postfix: " " + (am_pm ? am_pm : t[2]),
            };
        }
        return new_num;
    }

    parseTime(time: any, formatToUse: any) {
        let hour, minute;
        let format = formatToUse || "H:i:s",
            pm = time.match(/p/i) !== null,
            num = time.replace(/[^0-9]/g, "");

        // Parse for hour and minute
        switch (num.length) {
            case 4:
                hour = parseInt(num.charAt(0) + num.charAt(1), 10);
                minute = parseInt(num.charAt(2) + num.charAt(3), 10);
                break;
            case 3:
                hour = parseInt(num.charAt(0), 10);
                minute = parseInt(num.charAt(1) + num.charAt(2), 10);
                break;
            case 2:
            case 1:
                hour = parseInt(num.charAt(0) + (num.charAt(1) || ""), 10);
                minute = 0;
                break;
            default:
                return "";
        }

        if (hour == 12 && pm === false) {
            hour = 0;
        } else if (pm === true && hour > 0 && hour < 12) {
            hour += 12;
        }

        // Keep within range
        if (hour <= 0) {
            hour = 0;
        }

        if (hour >= 24 && ("" + hour + "").length == 2) {
            const parts = ("" + hour + "").split("");
            hour = parseInt(parts[0], 10);
            minute = parseInt(parts[1], 10);
            if (minute < 6) {
                minute = minute + "0";
            }
        }

        if (minute < 0 || minute > 59) {
            minute = 0;
        }

        if (hour >= 13 && hour <= 23) {
            pm = true;
        }

        return (
            format
                // 12 hour without leading 0
                .replace(/g/g, hour === 0 ? "12" : "g")
                .replace(/g/g, hour > 12 ? hour - 12 : hour)
                //// 12 hour with leading 0
                //.replace(/h/g, hour.toString().length > 1 ? (hour > 12 ? hour - 12 : hour) : '0' + (hour > 12 ? hour - 12 : hour))
                //// 24 hour with leading 0
                //.replace(/H/g, hour.toString().length > 1 ? hour : '0' + hour)
                // minutes with leading zero
                .replace(
                    /i/g,
                    minute.toString().length > 1 ? minute : "0" + minute
                )
                // simulate seconds
                .replace(/s/g, "00")
                // lowercase am/pm
                .replace(/A/g, pm ? this.options.pm_text : this.options.am_text)
        );
    }

    _createStringFromFormat(obj: any) {
        let combined = "" + obj.h + "" + obj.sep + ("" + obj.m + "");

        if (obj.postfix) {
            combined += obj.postfix;
        }

        return combined;
    }

    //sanitizeManualInput() {
    //  const numb = this.selectedTime.match(/\d/g);
    //  if (numb) {
    //    this.selectedTime = numb.join("");
    //    if (this.selectedTime.length <= 2) {
    //      if (_.parseInt(this.selectedTime) > 24) {
    //        this.selectedTime = "0" + this.selectedTime.toString() + "0";
    //      }
    //    }
    //    else if (this.selectedTime.length === 3) {
    //      this.selectedTime = "0" + this.selectedTime.toString();
    //      this.selectedTime = this.covertMins(this.selectedTime);
    //    }
    //    else if (this.selectedTime.length === 4) {
    //      if (!moment(this.selectedTime, 'hh:mm A').isValid()) {
    //        this.selectedTime = "0" + this.selectedTime.toString();
    //        this.selectedTime = this.selectedTime.slice(0, 3) + "0";
    //        this.selectedTime = this.covertMins(this.selectedTime);
    //      }
    //    }
    //    else if (this.selectedTime.length > 4)
    //      this.selectedTime = "";

    //  }
    //  else
    //    this.selectedTime = "";
    //}
    //covertMins(input) {
    //  if (input && input.length === 4) {
    //    const min = input.substring(2, 4);
    //    if (_.parseInt(min) < 60)
    //      return input;
    //    else
    //      return (input.substring(0, 3));
    //  }
    //  return input;
    //}

    createDate() {
        let date;
        if (!_.isEmpty(this.DefaultDate)) {
            date = moment.parseZone(this.DefaultDate).format("YYYY-MM-DD");
        } else {
            date = moment(this.model).isValid()
                ? moment.parseZone(this.model).format("YYYY-MM-DD")
                : moment.parseZone(this.DefaultDate).format("YYYY-MM-DD");
        }
        const newDate = moment(date);
        const time = moment(this.selectedTime, "h:mm A");

        newDate.set({
            hour: time.get("hour"),
            minute: time.get("minute"),
            second: time.get("second"),
        });

        this.model = newDate.format();

        if (moment(this.model).isValid()) {
            // this.model = this.incrementDate(this.model);
            const x = moment.parseZone(this.model).local().format();
            this.modelChange.emit(x);
            this.nbcBlur.emit(x);
        } else {
            this.modelChange.emit("");
            this.selectedTime = "";
        }
    }

    incrementDate(date: any) {
        let timeDifference;

        if (!_.isDate(date)) {
            return date;
        }
        if (
            date &&
            this.otherDate &&
            moment
                .parseZone(this.getDateString(date))
                .diff(this.getDateString(this.otherDate), "days") === 0
        ) {
            timeDifference =
                this.calculateMinutes(date) -
                this.calculateMinutes(this.otherDate);

            if (timeDifference < 0) {
                this.otherDate = moment
                    .parseZone(this.otherDate)
                    .add(1, "days")
                    .format();
            }
        }

        return date;
    }

    getDateString(date: any) {
        return moment.parseZone(date).format("YYYY-MM-DD");
    }
    calculateMinutes(time: any) {
        const date = new Date(time);
        const minutes = date.getMinutes(),
            hours = date.getHours(),
            minutesInHour = 60;

        return hours * minutesInHour + minutes;
    }

    generateTimeslots() {
        const x = 15; //minutes interval
        const times: any = []; // time array
        let tt = 0; // start time
        const ap = ["AM", "PM"]; // AM-PM

        //loop to increment the time and push results in array
        for (let i = 0; tt < 24 * 60; i++) {
            const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
            const mm = tt % 60; // getting minutes of the hour in 0-55 format
            times[i] =
                ("0" + (hh % 12)).slice(-2) +
                ":" +
                ("0" + mm).slice(-2) +
                " " +
                ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
            tt = tt + x;
        }
        this.timeslots = times;
    }

    getTimeStops() {
        const startTime = moment("00:00", "HH:mm");
        const endTime = moment("23:59", "HH:mm");

        //let d = moment("06-11-2022 00:00", "DD-MM-YYYY HH:mm") -- DayLight Savings Testing.
        //let startTime = moment(d, 'HH:mm');

        if (endTime.isBefore(startTime)) {
            endTime.add(1, "day");
        }

        const timeStops: any = [];

        while (startTime <= endTime) {
            let timeComponent = moment(startTime).format(
                this.timeParseFormat[this.type]
            );
            if (timeComponent.split(":")[0].length < 2)
                timeComponent = "0" + timeComponent;
            timeStops.push(timeComponent);
            startTime.add(15, "minutes");
        }
        this.timeslots = _.uniq(timeStops);
    }

    getTimeslots() {
        this.timeslotObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.selectedTime);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                if (this.selectedTime.length == 1)
                    token = "0" + this.selectedTime;
                const arr = _.filter(this.timeslots, (x) => {
                    return _.startsWith(x, token);
                });
                return of(arr);
            })
        );
    }

    /**
     * method provides element position on the screen
     *  * this helps to retain absolute positioning of the popover
     * @param event
     */
    getPosition(event: any) {
        this.showme = true;
        let offsetLeft = 0;
        let offsetTop = 0;
        let el = event.srcElement;

        while (el) {
            offsetLeft += el.layerX;
            offsetTop += el.layerY;
            el = el.parentElement;
        }
        this.top = offsetTop;
        this.left = offsetLeft;
    }

    /**
     * Initiating function
     * @param $event
     */
    show($event: any) {
        this.getPosition($event);
    }

    changeTypeaheadLoading($e: any) {}

    selectFocusedText() {
        const name = this.name?.toString();
        if (name) {
            //name and id are same
            (document.getElementById(name) as HTMLInputElement).select();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            switch (propName) {
                case "model":
                    this.getTimeStops();
                    this.getTimeslots();
                    this.selectedTime = this.model
                        ? moment
                              .parseZone(this.model)
                              .format(this.timeParseFormat[this.type])
                        : "";
                    break;
                case "isValidTime":
                    this.isValidTime = changes[propName]["currentValue"];
                    break;
                case "DefaultDate":
                    this.DefaultDate = changes[propName]["currentValue"];
                    break;
                case "nbcRequired":
                    this.nbcRequired = changes[propName]["currentValue"];
                    break;
            }
        }
    }

    ngOnInit() {
        this.getTimeStops();
        this.getTimeslots();
        this.selectedTime = this.model
            ? moment
                  .parseZone(this.model)
                  .format(this.timeParseFormat[this.type])
            : "";
        this.nbcClass = this.nbcDisabled ? "form-control" : null;
    }
    emitCurrentData() {}
    ngOnDestroy() {}
}
