<div class="modal-header">
  <h4 class="modal-title">Pay Transactions</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div *ngFor="let transaction of payTransactions;index as index;">
    <form name="payTransactionsForm">
      <div class="col-md-12 form-group-wrapper form-group d-flex" [ngClass]="{'has-error':(transaction.transactionType === null || transaction.transactionType.id === 0 || transaction.hrUnit === null || transaction.rate === '' || transaction.hrUnit === undefined || transaction.rate === undefined ) && submitted}">
        <div class="col-md-3 ps-3  pe-3">
          <nbc-select [(model)]="transaction.transactionType"
                      [source]="items.contractCode.transactionTypes"
                      [display]="'description'"
                      [value]="'id'"
                      placeHolderDisplay="TRANSACTION TYPE"
                      placeHolderValue="0"
                      nbcAuthenticateDisabled="(items.contractCode.transactionTypes.length == null || items.contractCode.transactionTypes.length == 0)"
                      authenticate-user="payrolltimesheet.update"
                      authenticate-command="nbcselectdisabled"
                      (change)="changeTransactionType(transaction)">
          </nbc-select>
        </div>
        <div class="col-md-2 ps-3  pe-3">
          <input name="update" authenticate-required="true" type="number"
                 appInputRestriction="numbersonly"
                 class="form-control" authenticate-disabled="checkStatus(item)" [(ngModel)]="transaction.hrUnit" numbers-only placeholder="Hr/Unit" authenticate-user="payrolltimesheet.update" authenticate-command="disabled" [ngClass]="{'has-error': (transaction.hrUnit === null || transaction.hrUnit === undefined) && submitted}" />
        </div>
        <div class="col-md-2 ps-3  pe-3">
          <input name="rate" authenticate-required="true" type="text"
                 class="form-control" [(ngModel)]="transaction.rate" placeholder="Rate"
                 authenticate-user="payrolltimesheet.update" authenticate-command="disabled"
                 [ngClass]="{'has-error':(transaction.rate === '' || transaction.rate === undefined) && submitted}" />
        </div>
        <div class="col-md-3 ps-3  pe-3">        
          <textarea name="transactionComment" type="text"
                    [(ngModel)]="transaction.transactionComment"
                     authenticate-user="payrolltimesheet.update" authenticate-command="disabled" 
                    maxlength="50"
                    style="width: 150px; height: 34px;"
                    placeholder="Comments"
                    appInputRestriction="noSpecialChars"
                    class="form-control form-control-sm"></textarea>
        </div>
        <div class="col-md-2 ps-3  pe-3">
          <a (click)="$event.preventDefault();removeTransaction(index)" class="btn btn-icon-clear"> <i class="fa fa-times-circle"></i> </a>
        </div>
      </div>
    </form>
  </div>
  <a class="btn-link" (click)="$event.preventDefault();addTransaction()"><i class="fa fa-plus-square"></i> Add another Transaction</a>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-default" (click)="cancel()">Cancel</button>
  <button class="btn btn-primary" (click)="approveTimesheet()">Add Entry</button>
</div>
