import { HttpClient } from "@angular/common/http";

declare global {
    interface Window {
        preload: any;
    }
}
window.preload = window.preload || {};
export function UiSettingsFactory(http: HttpClient) {
    return () => {
        const url = window.location.origin + "/api/uisettings";
        return http
            .get(url)
            .toPromise()
            .then((resp: any) => {
                window.preload.uisettings = resp["reference"];
            });
    };
}
