
<form #savePositionForm="ngForm">
  <div class="modal-header">

    
      <div class="col-sm-8"
           [ngClass]="{'has-error':positionName.invalid && submitted}">

        <input [(ngModel)]="positionSearchTerm"
               name="positionName"
               class="form-control form-control-ttl"
               [typeahead]="positionObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="description"
               [typeaheadOptionsLimit]="10"
               placeholder="Position Name"
               [isAnimated]="true"
               [typeaheadWaitMs]="200"
               required
               #positionName="ngModel"
               (typeaheadOnSelect)="onPositionSelect($event)">

        <span *ngIf="positionName.invalid && submitted" class="control-label-error" style="color: red;">Enter the position name</span>
      </div>
      
        <span class="modal-category-dept ms-3">
          {{this.model?.position?.category}}<br />{{this.model.position?.department}}
        </span>      
        <button type="button"
                class="btn-close"
                aria-hidden="true"
                (click)="closeModal()">
        </button>      
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group"> 
          <label class="control-label">Skill Set</label>
          <div *ngIf="skillData?.length>0">
            <nbc-tags [(searchTerm)]="skillSearchTerm"
                      [sourceData]="skillData"
                      [asyncLoad]="false"
                      [tagDisplay]="'description'"
                      [tagId]="model.skills"
                      [tagName]="model.skills"
                      [tags]="model.skills"
                      [placeholder]="'Add Skill'"
                      (tagChange)="setSkillEventTerm($event)"
                      [nbcMinLength]="0"
                      (nbcOnOptionSelect)="onSkillSelected($event)"></nbc-tags>
          </div>

          <div *ngIf="skillData?.length==0">
            <input tagsinput type="text" class="form-control" placeholder="Add Skill" disabled />
          </div>
        </div>
        <div class="form-group"
             *ngIf="employeeTypes && employeeTypes.length >0">
          <label class="form-control-label">Employee Type</label>
          <nbc-dropdown [(model)]="model.employeeType"
                        [source]="employeeTypes"
                        display="description"
                        placeholder="Select One"
                        add-blank="true"
                        disabled=""
                        nbcSize="w-100"
                        (nbcOnOptionSelect)="getRateCards()">
          </nbc-dropdown>
        </div>
        <div class="form-group"
             *ngIf="rateCardList && rateCardList.length >0">
          <label class="form-control-label">Rate Card</label>
          <nbc-dropdown [(model)]="model.rateCode"
                        [source]="rateCardList"
                        display="id"
                        orderby="id"
                        nbcSize="w-100"
                        add-blank="false">
          </nbc-dropdown>
        </div>
        <div class="form-group" [hidden]="!addPosition">
          <label class="control-label">Number of Positions</label>
          <input class="form-control form-control-xs"
                 style="border:1px solid gray;text-align: left;"
                 [(ngModel)]="numPositions"
                 type="text"
                 maxlength="2"
                 name="numPositions"
                 appInputRestriction="numberOnly" />
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer text-right">
    <button class="btn btn-default" (click)="closeModal()" type="button">Cancel</button>
    <button class="btn btn-primary" [disabled]="isPositionAddInProgress || numPositions<=0" (click)="savePosition(savePositionForm.valid)" type="submit">{{submitLabel}}</button>
  </div>
</form>
