import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class eventTierService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    getEventTierSetup(
        broadcastingNetworkId: any,
        eventTypeId: any
    ): Observable<any> {
        this.log.trace("tierratecard: get event tier setup");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecard/eventtiersetup/" +
                    broadcastingNetworkId +
                    "/" +
                    eventTypeId
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
    getEventTierSetupByNetworkGroup(
        networkGroupId: any,
        eventTypeId: any
    ): Observable<any> {
        this.log.trace("tierratecard: get event tier setup");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecard/eventtiersetupByGroupId/" +
                    networkGroupId +
                    "/" +
                    eventTypeId
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
