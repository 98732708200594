<div class="main-pane-content" *ngIf="model">
  <div class="row">
    <!--SCOR-9750 tab hidden per requirement-->
    <div class="col-md-12">

      <div class="text-end">
        <div class="form-inline form-sort-options">
          <div class="form-group justify-content-end">
            <nbc-dropdown [source]="eventTypes | orderBy: 'name'" [(model)]="workHistoryQuery.eventType.id"
                          display="name" value="id"
                          nbcSize="w-100"
                          (nbcOnOptionSelect)="restartSearch()"
                          placeholder="All Event Types"></nbc-dropdown>



            <nbc-dropdown [source]="mostRecentOptions" [(model)]="workHistoryQuery.mostRecent"
                          display="name" value="value"
                          nbcSize="w-100"
                          (nbcOnOptionSelect)="restartSearch()"
                          placeholder="Most Recent"></nbc-dropdown>


          </div>
        </div>
      </div>
      <div class="form-group form-group-wrapper" authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve, Contact_Profile_Work_History.Retrieve">
        <div class="work-history-events-total">
          <span>{{summary?.totalPastEvents}}</span>
          Past Events
        </div>
        <div class="work-history-events">
          <div class="work-history-event-row" *ngIf="summary?.eventTypeSummaries">
            <span class="work-history-event" *ngFor="let item of summary.eventTypeSummaries | orderBy: '-count' | slice: 0:limitHistory">
              <span class="work-history-event-count">{{item.count}}</span>{{item.name}}
            </span>
            <span *ngIf="summary.eventTypeSummaries.length > 5" class="work-history-event">
              <span class="work-history-event-count">{{overflowEventCount}}</span>Other Event Types
            </span>
          </div>

          <div class="work-history-event-row" *ngIf="summary?.positionSummaries">
            <span class="work-history-event-position" *ngFor="let item of summary.positionSummaries | orderBy: '-id' |  slice: 0: limitHistory">
              <span class="work-history-event-position-count">{{item.id}}</span>{{item.description}}
            </span>
            <span *ngIf="summary.positionSummaries.length > 5" class="work-history-event-position">
              <span class="work-history-event-position-count">{{overflowPositionCount}}</span> Other Position Types
            </span>
          </div>

        </div>
        <div class="clear"></div>
      </div>
      <div class="view-options">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group pagination-container d-flex" *ngIf="model">
              <label class="pagination-results ms-auto">
                <span *ngIf="(model.pageSize * model.currentPage) < model.totalRecords"> {{(model.pageSize < model.totalRecords) ? model.currentPage * model.pageSize : model.totalRecords}} of {{model.totalRecords}} Past Events</span>
                <span *ngIf="(model.pageSize * model.currentPage) > model.totalRecords">{{model.totalRecords}} of {{model.totalRecords}} Past Events</span>
                <!-- <pagination-label pageSize="{{model?.pageSize}}"
                currentPage="{{model?.currentPage}}}"
                totalRecords="{{model?.totalRecords}}"></pagination-label> -->
              </label>

              <!--pagination-->
              <nbc-pagination totalItems="{{model.totalRecords}}"
                              itemsPerPage="{{model.pageSize}}"
                              [(currentPage)]="workHistoryQuery.currentPage"
                              (pageChanged)="search()"></nbc-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="row-divider"></div>
      <div class="work-history-itm" *ngFor="let workHistory of model?.items " [hidden]="formState!='showResults'">
        <div class="row">
          <div class="col-md-4">
            <div class="work-history-game-dtl position-relative" authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve, Contact_Profile_Work_History.Retrieve">
              <img *ngIf="workHistory.divisionCode === 'REM'" src="/assets/image/eventType/{{workHistory.eventType?.imageId}}" class="work-history-game-logo" />
              <div class="d-flex opacity-0 work-history-game-logo" *ngIf="workHistory.divisionCode !== 'REM'">
                <img src="" class="work-history-game-logo" onerror="workHistory.divisionCode !== 'REM'" />
              </div>              
              <div class="small" style="position:absolute; bottom:-5px; left:5px" *ngIf="workHistory.tieredRateCardNum"><strong>T{{workHistory.tieredRateCardNum}}</strong></div>
              <h5>
                <a *ngIf="workHistory.divisionCode === 'REM'" class="btn-link fw-bold" href="{{summary.remotesUrl}}/Events/Event/{{workHistory.id}}/Summary" target="_blank">{{workHistory.eventName}}</a>
                <a *ngIf="workHistory.divisionCode === 'OLY'" class="btn-link fw-bold" href="/olympics/event/{{workHistory.olyEventId}}/summary" target="_blank">{{workHistory.eventName}}</a>
                <a *ngIf="workHistory.season.id != 0"                   
                   class="btn-link fw-bold"
                   href="/Events/Season/{{workHistory.season.id}}/dashboard"
                   placement="top"
                   tooltip="{{workHistory.season.description}}"
                   containerClass="tooltip"
                   class="label label-default btn-icon">
                  S
                </a>               
              </h5>
              <p>
                <span>{{workHistory.position.description}}</span>
                <!-- adjustment until we get skills implemented -->
                <!--<span ng-repeat="position in workHistory.position"><span *ngIf="$index != 0"> - </span>{{position}}</span>-->
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="detail-itms detail-itms-sm" authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve, Contact_Profile_Work_History.Retrieve">
              <span class="detail-itm">
                <span class="detail-lbl"><em>Producer:</em></span>
                <span class="detail-txt">{{workHistory.producer?.description}}</span>
              </span>
              <span class="detail-itm">
                <span class="detail-lbl"><em>Director:</em></span>
                <span class="detail-txt">{{workHistory.director?.description}}</span>
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="detail-itms detail-itms-sm">
              <span class="detail-itm" authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve, Contact_Profile_Work_History.Retrieve">
                <span class="detail-lbl"><em>Employee Type:</em></span>
                <ng-container *ngIf="(workHistory.eventType || (workHistory.divisionCode === 'OLY'))">
                  <span class="detail-txt">{{workHistory.employeeType?.description}}</span>
                </ng-container>
              </span>
              <span authenticate-user="ContactWorkHistory.Rate.Retrieve,ContactWorkHistory.RateCode.Retrieve" class="detail-itm">
                <span *ngIf="!workHistory.tieredPositionReference" class="detail-lbl"><em>Rate Code:</em></span>
                <span *ngIf="workHistory.tieredPositionReference" class="detail-lbl"><em>Tiered Rate Card:</em></span>
                <span *ngIf="workHistory.employeeType?.description?.toLowerCase() !== 'vendor'" class="detail-txt">
                  <ng-container *ngIf="(workHistory.eventType || (workHistory.divisionCode === 'OLY'))">
                    <span *ngIf="!workHistory.tieredPositionReference" class="detail-txt" authenticate-user="contact.rate.retrieve,ContactWorkHistory.RateCode.Retrieve">{{workHistory.rateCode}} </span>
                    <span *ngIf="workHistory.tieredPositionReference" class="detail-txt" authenticate-user="contact.rate.retrieve,ContactWorkHistory.RateCode.Retrieve">{{workHistory.billingCodeDescription}} </span>
                  </ng-container>
                  <span *ngIf="workHistory.employeeType?.description?.toLowerCase() === 'vendor'" class="detail-txt">N/A</span>
                </span>
                <span authenticate-user="ContactWorkHistory.Rate.Retrieve,ContactWorkHistory.RateCode.Retrieve"
                      *ngIf="workHistory.tieredPositionReference || (workHistory.rateCode == '00' || workHistory.rateCode == 'FLAT DAY')"
                      class="detail-lbl"><em>&nbsp;&nbsp;Rate:</em></span>
                <ng-container *ngIf="(workHistory.eventType || (workHistory.divisionCode === 'OLY'))">
                  <span authenticate-user="ContactWorkHistory.Rate.Retrieve,ContactWorkHistory.RateCode.Retrieve"
                        *ngIf="workHistory.tieredPositionReference || (workHistory.rateCode == '00' || workHistory.rateCode == 'FLAT DAY')"
                        class="detail-txt">{{workHistory.billingRate| number : '1.2-2'}}</span>
                </ng-container>



              </span>
            </div>
          </div>
          <div class="col-md-2 text-end">
            <p authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve">
              <span>{{workHistory.eventStartDate | date:"EEE, MMM d yyyy"}}</span>
              <span *ngIf="(workHistory.eventEndDate|date:'MMM d yyyy') != (workHistory.eventStartDate|date:'MMM d yyyy')">
                to<br />
                {{workHistory.eventEndDate | date:"EEE, MMM d yyyy"}}
              </span>
            </p>
          </div>
        </div>
        <div class="row-divider"></div>
      </div>
    </div>
  </div>
</div>
