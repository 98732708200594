<div>

  <div class="page-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="header-bar clearfix">
            <p class="heading-title pull-left">
              {{ season?.name }}
              <span class="txt-notransform">
                Season Set Up
              </span>
            </p>
          </div>
          <div cdkDropListGroup class="page-content season-setup-page-container">
            <nbc-alert></nbc-alert>

            <div *ngIf="hasTeams">
              <div class="container">
                <div class="row ms-5"
                     *ngIf="hasTeams"
                     authenticate-user="event.update">
                  <div class="hdr-grid-dates-paging col-md-1"
                       *ngIf="teams?.length>10">
                    <button class="btn btn-xs btn-icon team-button"
                            (click)="showPrevLogos()"
                            [disabled]="logoStartDisplay <= 0">
                      <i class="fa fa-chevron-left"></i>
                    </button>
                  </div>
                  <div class="col-md-7">
                    <div class="row hdr-grid-dates " style="cursor: move;" *ngIf="teams?.length>0">
                      <div *ngFor="let team of teams; let $index=index" cdkDropList [cdkDropListData]="teams"
                           class="draggable-teams p-0"
                           [hidden]="($index < logoStartDisplay || $index > logoEndDisplay)">

                        <div tooltip="{{team.name}}" placement="bottom">
                          <span>
                            <img cdkDrag [cdkDragData]="team" class="draggable-image-size"
                                 src="/assets/image/team/{{team.imageId}}" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="hdr-grid-dates-paging col-md-1"
                       *ngIf="teams?.length>10">
                    <button class="btn btn-xs btn-icon team-button"
                            (click)="showNextLogos()"
                            [disabled]="(logoEndDisplay + 1) >= teams.length">
                      <i class="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="hdr-tab mt-4"><span>Events</span></h3>
            <div class="season-events-slotted pad-0"
                 [ngClass]="{'no-teams':!hasTeams}">
              <div class="row season-event"
                   *ngFor="let event of seasonEventsList; let $index=index;">
                <div class="col-sm-8">
                  <div class="teams"
                       *ngIf="hasTeams">
                    <ul class="list-inline d-flex">
                      <li>
                        <span placement="bottom"
                              tooltip="{{event.awayTeam.name}}">
                          <img src="/assets/image/team/{{event.awayTeam.imageId}}" />
                        </span>
                      </li>
                      <li>
                        &#64;
                      </li>
                      <li>
                        <span placement="bottom"
                              tooltip="{{event.homeTeam.name}}">
                          <img src="/assets/image/team/{{event.homeTeam.imageId}}" />
                        </span>
                      </li>
                    </ul>

                  </div>
                  <div class="event-details">
                    <div>
                      <h4>
                        <a class="btn-link" routerLink="/Events/Event/{{event.id}}">{{event.eventName}}</a>
                        <span *ngIf="event.tier"
                              class="pull-right">T{{event.tier}}</span>
                      </h4>
                    </div>
                    <h4></h4>
                    <span class="event-venue">{{event.venue.name}}</span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="event-actions">
                    <a class="btn btn-default"
                       (click)="openEditEventModal(event)"
                       authenticate-user="event.update,financial.budgetforecast.update"
                       authenticate-command="disabled">
                      <i class="fa fa-pencil"></i>
                    </a>
                    <a class="btn btn-default"
                       (click)="openDeleteEventModal(event)"
                       authenticate-user="event.delete"
                       authenticate-command="disabled">
                      <i class="fa fa-times"></i>
                    </a>
                    <a class="btn btn-primary" routerLink="/Events/Event/{{event.id}}"><i class="fa fa-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="newSeasonEvents?.length>0"
                 class="season-events-new"
                 authenticate-user="event.update">
              <div class="row season-event"
                   *ngFor="let add of newSeasonEvents; let $index = index">
                <div class="d-flex p-0">
                  <div *ngIf="hasTeams"
                       class="event-team">
                    <div class="form-inline form-group form-group-wrapper clearfix d-flex">
                      <div class="img-thumbnail"
                           [cdkDropListData]="list2"
                           #temp{{$index}}="cdkDropList"
                           cdkDropList
                           (cdkDropListDropped)="assignAwayTeamDnd($event,$index)"
                           id="awayTeam_{{$index}}">
                        <img *ngIf="newSeasonEvents[$index]?.awayTeam"
                             src="/assets/image/team/{{newSeasonEvents[$index].awayTeam?.imageId||0}}" />
                      </div>

                      <nbc-teams nbcName="awayTeam_{{$index}}"
                                 [eventTypeId]="season.eventType.id"
                                 [(model)]="newSeasonEvents[$index].awayTeam"
                                 (searchTermChange)="checkAwayTeamData(event,$index)"
                                 placeholder="Away Team"></nbc-teams>
                    </div>
                  </div>
                  <div *ngIf="hasTeams"
                       class="event-team-at">@</div>
                  <div *ngIf="hasTeams"
                       class="event-team">
                    <div class="form-inline form-group form-group-wrapper clearfix d-flex">
                      <div class="img-thumbnail"
                           [cdkDropListData]="list2"
                           #temp{{$index}}="cdkDropList"
                           cdkDropList
                           (cdkDropListDropped)="assignHomeTeamDnd($event,$index)"
                           id="homeTeam_{{$index}}">
                        <img *ngIf="newSeasonEvents[$index]?.homeTeam"
                             src="/assets/image/team/{{newSeasonEvents[$index].homeTeam?.imageId||0}}" />
                      </div>

                      <nbc-teams nbcName="HomeTeam_{{$index}}"
                                 [eventTypeId]="season.eventType.id"
                                 [(model)]="newSeasonEvents[$index].homeTeam"
                                 (searchTermChange)="checkHomeTeamData(event,$index)"
                                 placeholder="Home Team"></nbc-teams>
                      <button class="btn btn-default pull-right"
                              *ngIf="newSeasonEvents.length > 1 && $index > 0"
                              type="button"
                              (click)="removeEventAdd($index)">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="!hasTeams"
                       class="event-details w-50">
                    <nbc-noteam-setup [dataModel]="add">

                    </nbc-noteam-setup>
                    <!--<div class="form-inline">
            <div class="form-group form-group-wrapper">
              <input type="text"
                     appInputRestriction="restrictChars"
                     name="eventName"
                     class="form-control"
                     placeholder="Event Name"
                     [(ngModel)]="add.eventName"
                     maxlength="100" />
            </div>
            <div class="form-group form-group-wrapper">
              <input name="venue"
                     [(ngModel)]="venueSearchTerm"
                     appInputRestriction="restrictChars"
                     [typeahead]="searchVenue$"
                     typeaheadOptionField="name"
                     [typeaheadMinLength]="2"
                     [typeaheadOptionsLimit]="50"
                     placeholder="Select Venue"
                     class="form-control"
                     [isAnimated]="true"
                     typeaheadWaitMs="200"
                     (typeaheadOnSelect)="onVenueSelect($event,$index)">
            </div>

          </div>-->
                  </div>
                  <div *ngIf="hasTeams && (add.homeTeam && add.awayTeam && hasTeams) && (add.homeTeam.id === add.awayTeam.id)"
                       class="event-details has-error"
                       style="margin: 15px auto">
                    <span class="control-label control-label-error">
                      Home Team and Away Team are the same team. Please enter different teams.
                    </span>
                  </div>
                  <div *ngIf="(add.homeTeam && add.awayTeam && hasTeams) || (add.eventName && add.venue && !hasTeams)"
                       class="event-actions w-100 text-end">
                    <button class="btn btn-default"
                            type="button"
                            (click)="cancelAdd($index)">
                      Cancel
                    </button>
                    <button class="btn btn-primary"
                            type="submit"
                            (click)="openEditEventModal(add, $index);"
                            [disabled]="hasTeams && add.homeTeam.id === add.awayTeam.id">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class="btn-link"
               (click)="addBlankEvent($event)"
               authenticate-user="event.create">+ Add Another Event</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div ng-drag-clone="">
  <div *ngIf="clonedData">
    <img src="/assets/image/team/{{clonedData.imageId}}" />
  </div>
</div>
<ng-template #customItemTemplate let-model="item"
             let-index="index">
  <span>
    <img src="/assets/image/team/{{model.imageId}}"
         width="16" /><span style="padding-left:10px;">{{model.name}}</span>
  </span>
</ng-template>
