import { Component, OnInit, OnDestroy } from "@angular/core";
import { DashboardService } from "../.././/Shared/Services/dashboard.service";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SharedDataService } from "../.././/Shared/sharedData.service";
import { NbcLoggerService } from "../.././/Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PermissionUtility } from "../../Core/services/permission-utility.service";

@Component({
    selector: "app-dashboard-event-recentlyviewed",
    templateUrl: "./dashboard-event-recentlyviewed.component.html",
})
export class DashboardEventRecentlyviewedComponent
    implements OnInit, OnDestroy
{
    constructor(
        private _dashboardService: DashboardService,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();

    recentlyViewed: any | undefined;
    allrecentlyViewed: any | undefined;
    recentlyViewedComplete: boolean | undefined;
    fullscreenMode = false;

    defaultOrderBy = "";
    defaultReverseSort = false;
    displayCount = 10;

    sort = {
        orderByField: this.defaultOrderBy,
        reverseSort: this.defaultReverseSort,
    };

    getRecentlyViewed() {
        this.log.trace("Logging from new logger");
        this.log.debug("RecentlyViewed -> get:fetching data");

        //this.recentlyViewed.sortBy = "SortOrder";
        //getPayrollQuery(): any {
        //  return {
        //    eventId: 0,
        //    timeCardDate: new Date(),
        //    pageSize: 25,
        //    sortBy: "SortOrder",
        //  }
        this.sort.orderByField = "";
        this.sort.reverseSort = this.defaultReverseSort;

        this._dashboardService
            .getRecentlyViewedData("event")
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.allrecentlyViewed = data;
                    this.recentlyViewed = data
                        ? _.take(data, this.displayCount)
                        : null;
                    this.log.debug("RecentlyViewed ->  received");
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load the dashboard at this time. Please try again later."
                    );
                },
                () => {
                    this.recentlyViewedComplete = true;
                }
            );
    }

    fullScreenmode() {
        const contact = document.getElementById("contactrecentlyviewed");
        const event = document.getElementById("eventrecentlyviewed");
        const createevent = document.getElementById("CreateEvent");
        const positionrequest = document.getElementById("positionrequest");
        const myevents = document.getElementById("eventdashboard");
        const toprow = document.getElementById("toprow");

        if (event != null) {
            if (!this.fullscreenMode) {
                event.classList.add("col-lg-12");
                event.classList.remove("col-lg-6");
                this.recentlyViewed = this.allrecentlyViewed;
                this.fullscreenMode = true;
            } else {
                event.classList.remove("col-lg-12");
                event.classList.add("col-lg-6");
                this.fullscreenMode = false;
                this.recentlyViewed = this.allrecentlyViewed
                    ? _.take(this.allrecentlyViewed, this.displayCount)
                    : null;
            }
        }
        if (toprow != null) {
            if (this.fullscreenMode) {
                toprow.classList.add("dashboardhide");
            } else {
                toprow.classList.remove("dashboardhide");
            }
        }
        if (contact != null) {
            if (this.fullscreenMode) {
                contact.classList.add("dashboardhide");
            } else {
                contact.classList.remove("dashboardhide");
            }
        }
        if (createevent != null) {
            if (this.fullscreenMode) {
                createevent.classList.add("dashboardhide");
            } else {
                createevent.classList.remove("dashboardhide");
            }
        }
        if (positionrequest != null) {
            if (this.fullscreenMode) {
                positionrequest.classList.add("dashboardhide");
            } else {
                positionrequest.classList.remove("dashboardhide");
            }
        }
        if (myevents != null) {
            if (this.fullscreenMode) {
                myevents.classList.add("dashboardhide");
            } else {
                myevents.classList.remove("dashboardhide");
            }
        }
    }

    ngOnInit(): void {
        const user = window.preload.user;

        if (user.isSuperUser) {
            this.displayCount = 10;
        } else {
            const access = PermissionUtility.PermissionUtilities();
            const hasAccess =
                access.hasAccess(
                    user,
                    user.allFeatures,
                    ["dashboard.positionrequest.retrieve"],
                    null,
                    null,
                    null
                ) ||
                access.hasAccess(
                    user,
                    user.allFeatures,
                    ["dashboard.myevents.retrieve"],
                    null,
                    null,
                    null
                );
            this.displayCount = hasAccess ? 10 : 25;
        }
        this.getRecentlyViewed();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
