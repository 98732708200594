import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-pay-rate-report",
    templateUrl: "./pay-rate-report.component.html",
})
export class PayRateReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}
    reportName;
    //  = modelValue;
    payRateReport: any;
    isSaved: any;
    getCriteriaData: any;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    payRateReportInfo: any;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    showEventsRowAll = true;
    showEventsEditRow = false;
    //$scope.showEventsSelectedRow = false;
    showEventsTagRow = false;
    eventId = 13;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    eventsList: any[] = [];
    // $scope.rateCard = [];
    selectedEvent: any[] = [];
    alerts: any;
    showAlertMessage: any;
    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    onItemSelect($event) {
        this.selectedEvent = $event;
    }
    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }

    //openTravelInDatepicker
    openDateRangeDatepicker(event) {
        // log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
        //document.getElementById("PayrateButton").disabled = false;
    }

    editRow(value) {
        switch (value) {
            case "EventsRow":
                {
                    this.showEventsRowAll = false;
                    this.showEventsEditRow = true;
                    this.showEventsTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }

    confirmSelection(value) {
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
        }
        if (value === "TravelOutDateRow") {
            this.showSection(value);
        }

        if (value === "EventsRow") {
            this.showEventsEditRow = false;
            this.showSection(value);
        }
    }

    showSection(value) {
        switch (value) {
            case "DateRangeRow":
                {
                    if (
                        this.payRateReportInfo.dateRangeStartDate !== null &&
                        this.payRateReportInfo.dateRangeEndDate !== null
                    ) {
                        this.showDateRangeRowAll = false;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = true;
                    } else {
                        this.showDateRangeRowAll = true;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = false;
                    }
                    break;
                }
                break;

            case "EventsRow":
                {
                    if (
                        !_.isUndefined(this.selectedEvent) &&
                        this.selectedEvent.length > 0
                    ) {
                        this.showEventsRowAll = false;
                        this.showEventsEditRow = false;
                        this.showEventsTagRow = true;
                    } else {
                        this.showEventsRowAll = true;
                        this.showEventsTagRow = false;
                        this.showEventsEditRow = false;
                    }
                    break;
                }
                break;
        }
    }

    stateChanged(checked, value, row) {
        if (row === "EventsRow") {
            if (checked) {
                this.eventsList.push(value);
                this.payRateReportInfo.events.push(value);
            }
            if (!checked) {
                this.eventsList.splice(this.eventsList.indexOf(value), 1);
                this.payRateReportInfo.events.splice(
                    this.payRateReportInfo.events.indexOf(value),
                    1
                );
            }
        }
    }

    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.payRateReportInfo.dateRangeStartDate = null;
            this.payRateReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
        }
    }

    removeTag(item, row, index) {
        if (row === "EventsRow") {
            this.eventsList.splice(this.eventsList.indexOf(item), 1);
            this.selectedEvent.splice(this.selectedEvent.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("EventsRow");
        }
    }
    onToggleFilterDatepicker($event) {
        this.payRateReportInfo.dateRangeStartDate = $event[0];
        this.payRateReportInfo.dateRangeEndDate = $event[1];
    }
    getPayRateDetails() {
        this._reportService.getPayRateDetails(this.eventId).subscribe({
            next: (data) => {
                if (data == null) {
                    this.showAlertMessage = true;
                    this.log.showModalError(
                        "There are no data points to export for this report at the moment."
                    );
                } else {
                    this.payRateReport = data;
                    this.getCriteriaData = data;
                }
            },
            error: (data) => {
                this.showAlertMessage = true;
                this.log.showModalError("Error Occurred");
            },
        });
    }

    exportPayRateReport() {
        const temppayRateReportInfo: any = {};
        temppayRateReportInfo.dateRangeStartDate = this.payRateReportInfo
            .dateRangeStartDate
            ? moment(this.payRateReportInfo.dateRangeStartDate).format(
                  "MM/DD/yyyy"
              )
            : "";
        temppayRateReportInfo.dateRangeEndDate = this.payRateReportInfo
            .dateRangeStartDate
            ? moment(this.payRateReportInfo.dateRangeEndDate).format(
                  "MM/DD/yyyy"
              )
            : "";

        this.isDownload = true;

        temppayRateReportInfo.events = this.selectedEvent;
        this._reportService
            .exportPayRateReport(
                this.eventId,
                temppayRateReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (!data) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.payRateReport = this.getCriteriaData;
                        this.showAlertMessage = false;
                        const d = moment(new Date()).format("MM-DD-yyyy");

                        this.child.fileName = "SCORE-Pay-Rate Updates_" + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                        this.isSaved = data.reference;
                    }
                },
                error: (data) => {
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    ngOnInit(): void {
        this.getPayRateDetails();
        this.payRateReportInfo = {};
        //$scope.payRateReportInfo.travelInStartDate = null;
        this.payRateReportInfo.dateRangeStartDate = null;

        //$scope.payRateReportInfo.travelInEndDate = null;
        this.payRateReportInfo.dateRangeEndDate = null;
        //$scope.payRateReportInfo.events = null;
        this.payRateReportInfo.events = [];
    }
}
