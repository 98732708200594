import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { TimeZoneHelperService } from "./Shared/helper/timezonehelper.service";
import { DOCUMENT } from "@angular/common";
import { SharedDataService } from "./Shared/sharedData.service";
import { NavigationEnd, Router } from "@angular/router";
import { forEach } from "lodash";
import { CssMapService } from "./Shared/helper/css-mapper.service";
import * as _ from "lodash";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    title = "here";

    constructor(
        private cookieService: CookieService,
        private http: HttpClient,
        private tzHelper: TimeZoneHelperService,
        private _sharedData: SharedDataService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {}
    scoreUrl = "";

    setCookies() {
        this.cookieService.set(
            "Z_Client_Offset",
            this.tzHelper.getTimezoneOffset(),
            { path: "/" }
        );
        this.cookieService.set(
            "Z_Client_TimeZone",
            this.tzHelper.getTimezone(),
            { path: "/" }
        );
        this.cookieService.set(
            "Z_Client_TimeZone",
            this.tzHelper.getTimezone(),
            { path: "/", domain: "score.nbcuni.com" }
        );
    }

    loadStyle(css: any[]) {
        const className = ".component-css";
        const head = this.document.getElementsByTagName("head")[0];
        setTimeout(() => {
            this.document
                .querySelectorAll(className)
                .forEach((e) => e.remove());
        }, 200);

        setTimeout(() => {
            if (css) {
                for (let i = 0; i < css.length; i++) {
                    const style = this.document.createElement("link");
                    style.className = "component-css";
                    style.rel = "stylesheet";
                    style.href = `${css[i]}`;

                    head.appendChild(style);
                }
            }
        }, 600);
    }

    preloadCache() {
        const cookieName = "preload-cache";
        const url = window.location.origin + "/api/preloadcache";
        if (!this.cookieService.get(cookieName)) {
            return this.http
                .get(url)
                .toPromise()
                .then((resp) => {
                    this.cookieService.set(cookieName, "complete", {
                        path: "/",
                    });
                });
        }
        return null;
    }

    ngOnInit() {
        this.setCookies();
        this._sharedData.getCss().subscribe((module: any) => {
            const cssmap = CssMapService.get(module);
            const arrCss = cssmap ? _.cloneDeep(cssmap.css) : [];
            this.loadStyle(arrCss);
        });

        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                //No Action
            }
        });

        this.preloadCache();
        this.scoreUrl = window?.preload?.uisettings?.pickADoorUrl;
    }
}
