import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { SubSink } from "subsink";
import { ModalNetworkComponent } from "./modal-network";

@Component({
    selector: "app-network",
    templateUrl: "./network.component.html",
})
export class NetworkComponent implements OnInit, OnDestroy {
    constructor(
        private _networkService: NetworkService,
        private log: NbcLoggerService,
        public _modalService: BsModalService
    ) {}

    bsModalRef?: BsModalRef;
    totalRecords: any;
    showAlertMessage: boolean | undefined;
    isAdd: boolean | undefined;

    network: any;
    allNetworks: any;
    private subs = new SubSink();
    ShowModal = false;
    networks: any;
    resultsFound = false;
    queryContainer: any = { query: {} };
    pageSizeList = [10, 25, 50, 100];

    openUpsertModal(network) {
        const networkObj = {
            description: "",
            isNetworkGroupTiered: true,
            isActive: true,
        };

        const networkEdit = network;

        const initialState: any = {
            self: this,
            network: network == null ? networkObj : _.cloneDeep(networkEdit),
            allNetworks: this.networks,
            modalTitle: network ? "Edit Network" : "Add New Network",
            isAdd: network ? false : true,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalNetworkComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.getAllAdminNetowks();
        });
    }

    searchPage(pagesize) {
        this.queryContainer.query.pageSize = pagesize;
        this.search();
    }

    search() {
        this.getAllAdminNetowks();
    }

    getAllAdminNetowks() {
        this.subs.sink = this._networkService.getAllNetworks().subscribe(
            (result) => {
                const pageSize = parseInt(this.queryContainer.query.pageSize);
                this.totalRecords = result.reference.recordCount;
                // this.log.trace("getAllNetworks got data" + JSON.stringify(result));
                const begin =
                  (this.queryContainer.query.currentPage - 1) * pageSize;
                const end = begin + pageSize;
                this.queryContainer.query.totalRecords =
                    result.reference.length;
                this.networks = result.reference.slice(begin, end);
                this.resultsFound = true;
            },
            (err) => {
                this.log.error(
                    "getAllNetworks got an error: " + JSON.stringify(err)
                );
            },
            () => {}
        );
    }

    getNetworkTypeQuery(currentPage, pageSize, totalRecords) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 100,
            sortByColumn: "name",
            reverseSort: false,
            eventType: "",
            exactTerm: false,
        };
    }

    ngOnInit(): void {
        this.queryContainer.query = this.getNetworkTypeQuery(null, null, null);
        this.getAllAdminNetowks();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
