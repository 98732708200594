<div>
  <div class="content-filters">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="form-group">
          <div class="input-group input-group-set dropdown"
               dropdown>
            <span class="input-group-text"
                  dropdown-toggle><i class="fa fa-calendar"></i></span>
            <input type="text"
                   name="arrivalDate"
                   class="form-control dropdown-toggle"
                   bsDaterangepicker
                   readonly
                   [bsValue]="bsInlineRangeValue"
                   (bsValueChange)="onToggleFilterDatepicker($event)"
                   [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="d-flex">
            <div class="dropdown input-group-text border-0 p-0" dropdown>
              <button id="button-animated" dropdownToggle
                      type="button" class="bg-transparent form-control"
                      aria-controls="dropdown-animated">
                <span [hidden]="!showVenue">Venue</span>
                <span [hidden]="showVenue">Location</span>
                <i style="padding-left: 5px;" class="fa fa-sort"></i>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdownVenue">
                <li role="presentation" [ngClass]="{active:showVenue}">
                  <a role="menuitem" class="dropdown-item" tabindex="-1" (click)="hideLocation()">Venue</a>
                </li>
                <li role="presentation" [ngClass]="{active:!showVenue}">
                  <a role="menuitem" tabindex="-1" class="dropdown-item" (click)="showLocation()">Location</a>
                </li>
              </ul>
            </div>
            <div class="animate-switch-container w-100" [ngSwitch]="showVenue">
              <div class="animate-switch" *ngSwitchCase="true">
                <input name="venue"
                       [(ngModel)]="searchVenueTerm"
                       appInputRestriction="restrictChars"
                       [typeahead]="searchVenueResponse$"
                       typeaheadOptionField="displayText"
                       [typeaheadAsync]="true"
                       autocomplete="off"
                       (blur)="checkSearchVenueDetails($event)"
                       [typeaheadOptionsLimit]="30"
                       placeholder="Select Venue"
                       class="form-control"
                       [isAnimated]="true"
                       [typeaheadWaitMs]="200"
                       (typeaheadOnSelect)="onVenueSelect($event)">
              </div>
              <div class="animate-switch" *ngSwitchCase="false">
                <input name="cityName"
                       appInputRestriction="restrictChars"
                       type="text"
                       class="form-control"
                       placeholder="City Name"
                       [(ngModel)]="eventQuery.city"
                       maxlength="100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="d-flex">
            <div class="dropdown input-group-text border-0 p-0" dropdown>
              <button id="button-animated"
                      dropdownToggle
                      type="button"
                      class="bg-transparent form-control"
                      aria-controls="dropdown-animated">
                <span *ngIf="showTeam">Team</span>
                <span *ngIf="!showTeam">Event</span>
                <i style="padding-left: 5px;" class="fa fa-sort"></i>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdownTeam">
                <li role="presentation" [ngClass]="{active:showTeam}">
                  <a role="menuitem" tabindex="-1" class="dropdown-item" (click)="hideEvent()">Team</a>
                </li>
                <li role="presentation" [ngClass]="{active:!showTeam}">
                  <a role="menuitem" tabindex="-1" class="dropdown-item" (click)="showEvent()">Event</a>
                </li>
              </ul>
            </div>
            <div class="animate-switch-container w-100" [ngSwitch]="showTeam">
              <div class="animate-switch" *ngSwitchCase="true">
                <input name="venue"
                       [(ngModel)]="eventQueryTeamTerm"
                       appInputRestriction="restrictChars"
                       [typeahead]="searchTeamResponse$"
                       typeaheadOptionField="name"
                       [typeaheadAsync]="true"
                       [typeaheadOptionsLimit]="30"
                       [typeaheadItemTemplate]="customItemTemplate"
                       placeholder="Team Name"
                       (blur)="checkSearchTeamDetails($event)"
                       class="form-control"
                       [isAnimated]="true"
                       autocomplete="off"
                       [typeaheadWaitMs]="200"
                       (typeaheadOnSelect)="onTeamSelected($event)">

              </div>
              <div class="animate-switch" *ngSwitchCase="false">
                <input type="text"
                       appInputRestriction="restrictChars"
                       class="form-control"
                       placeholder="Event Name"
                       [(ngModel)]="eventQuery.eventName"
                       maxlength="100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="form-group">
          <button type="submit"
                  class="btn btn-secondary"
                  (click)="eventSearch()">
            Search
          </button>
          <button type="button"
                  class="btn btn-default"
                  (click)="resetEventSearch()">
            Reset
          </button>
          <a (click)="showFilter = !showFilter"
             class="pull-right lnk-show-hide">
            {{ showFilter ? 'Hide' : 'Show' }} Filters <i class="fa"
                                                          [ngClass]="{'fa-sort-asc': showFilter, 'fa-sort-desc': !showFilter}"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- Filters -->
    <div class="row"
         *ngIf="showFilter">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">
          <nbc-dropdown *ngIf="networks?.length>0"
                        [source]="networks | orderBy: 'description'"
                        name="network"
                        [(model)]="eventQuery.networkId"
                        display="description"
                        value="id"
                        nbcSize="w-100"
                        (nbcOnOptionSelect)="eventSearch()"
                        placeholder="All Networks">
          </nbc-dropdown>
        </div>

      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="mb-3 d-flex">
          <label class="form-group-label" style="padding-right:5px;">Level</label>
          <span class="d-flex">
            <button type="button" name="isLevel1" (click)="eventSearch()" class="btn btn-white" style="margin:0 !important; outline: none !important;" [(ngModel)]="eventQuery.isLevel1" btnCheckbox tabindex="0" role="button">1</button>
            <button type="button" name="isLevel2" (click)="eventSearch()" class="btn btn-white" style="margin:0 !important; outline: none !important" [(ngModel)]="eventQuery.isLevel2" btnCheckbox tabindex="0" role="button">2</button>
            <button type="button" name="isLevel3" (click)="eventSearch()" class="btn btn-white" style="margin:0 !important; outline: none !important" [(ngModel)]="eventQuery.isLevel3" btnCheckbox tabindex="0" role="button">3</button>
          </span>
        </div>
      </div>



      <div class="col-lg-1 col-md-4 col-sm-6"
           *ngIf="!season">
      </div>
      <div class="col-lg-2 col-md-4  col-sm-6">
        <div class="form-group">
          <div class="input-group input-group-set"
               dropdown>
            <!-- <span class="input-group-addon dropdown"><i class="fa fa-filter"></i></span> -->
            <select name="ddlBroadcastType"
                    id="ddlBroadcastType"
                    (change)="filterByBroadcastType()"
                    [(ngModel)]="eventQuery.broadcastType">
              <option value="">All Broadcast Types</option>
              <option value="Live">Live</option>
              <option value="Taped">Taped</option>
              <option value="Outside Package">Outside Package</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="d-flex input-group-set">
            <label class="input-group-addon">Status</label>
            <div class="dropdown w-100"
                 dropdown
                 [insideClick]="true"
                 *ngIf="eventStatusList?.length>0">
              <button class="text-start form-control"
                      dropdownToggle
                      type="button"
                      id="dropdownStatus"
                      aria-controls="dropdown-Status">
                {{ eventTypeFilter.Status }}
              </button>
              <ul id="dropdown-Status"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="dropdownStatus">
                <li role="menuitem"
                    *ngFor="let status of eventStatusList;let $index =index;">
                  <label class="checkbox">
                    <input type="checkbox"
                           name="status"
                           [(checked)]="status.checked"
                           (change)="onSelect(eventTypeFilter,$index)" />
                    {{status.description}}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 d-flex ms-3">
        <div class="col-lg-2 col-md-4 col-sm-6 pe-4">
          <div class="form-group" *ngIf="timeSheetStatuses">
            <nbc-dropdown [source]="timeSheetStatuses"
                          name="timeSheetStatus"
                          [(model)]="eventQuery.timesheetStatus"
                          display="name"
                          value="id"
                          nbcSize="w-100"
                          (nbcOnOptionSelect)="eventSearch()"
                          placeholder="All Timesheets">
            </nbc-dropdown>
          </div>
        </div>
        <div class="col-lg-2 col-md-4  col-sm-6 ps-2">
          <div class="form-group">
            <div class="d-flex input-group-set manager-drop-down">
              <label class=" input-group-addon">
                Manager
              </label>
              <div class="w-100">
                <nbc-dropdown *ngIf="managers?.all?.values"
                              [source]="managers.all.values  | orderBy: 'description'"
                              name="manager"
                              [(model)]="eventQuery.managerId"
                              display="description"
                              value="id"
                              nbcSize="w-100"
                              (nbcOnOptionSelect)="eventSearch()"
                              placeholder="Any">
                </nbc-dropdown>
              </div>
            </div>
        </div>
          </div>
        <div class="col-lg-1 col-md-4 col-sm-6 ps-2">
          <div class="form-group">
            <div class="checkbox float-end">
              <label>
                <input type="checkbox"
                       [(ngModel)]="eventQuery.isMyEvent"
                       (change)="eventSearch()"> My events only
              </label>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
  <div class="main-pane-content">
    <div class="row"
         *ngIf="model.totalRecords > 0">
      <div class="view-options d-flex">
        <div class="col-md-5">
          <div class="form-inline form-sort-options">
            <div class="form-group">
              <label class="form-group-label">Sort:</label>
              <nbc-dropdown [source]="sortList"
                            name="sortLists"
                            [(model)]="eventQuery.sortBy"
                            display="name"
                            value="predicate"
                            nbcSize="w-100"
                            (nbcOnOptionSelect)="eventSearch()">
              </nbc-dropdown>
            </div>
            <div class="form-group">
              <label class="form-group-label">View:</label>
              <div class="btn-group">
                <button type="button"
                        class="btn btn-white"
                        [(ngModel)]="viewFormat"
                        btnRadio="grid"
                        (click)="showGrid = true">
                  <i class="fa fa-th-large"></i>
                </button>
                <button type="button"
                        class="btn btn-white"
                        [(ngModel)]="viewFormat"
                        btnRadio="list"
                        (click)="showGrid = false">
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="form-group d-flex float-end">
            <label class="pagination-results">
              <pagination-label pageSize="{{model.pageSize}}"
                                currentPage="{{model.currentPage}}}"
                                totalRecords="{{model.totalRecords}}"></pagination-label>

            </label>
            <nbc-pagination totalItems="{{model.totalRecords}}"
                            itemsPerPage="{{model.pageSize}}"
                            [(currentPage)]="eventQuery.currentPage"
                            (pageChanged)="eventSearch()"></nbc-pagination>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12"
           *ngIf="model.totalRecords===0">
        <p>There are no results matching the search criteria.</p>
      </div>
      <div class="col-md-12"
           *ngIf="!hideResults">
        <!-- GridView -->
        <div class="view-grid"
             *ngIf="showGrid"
             style="min-height:250px;">
          <div class="row">
            <div class="event-tile col-lg-3 col-md-6 col-sm-6 col-xs-12"
                 *ngFor="let event of model.items">
              <div class="event-tile-main">
                <div class="event-tile-dtls event-tile-status-planning">
                  <span class="event-tile-date"
                        *ngIf="(event.startDate !== '')">
                    {{event.startDate|date:"EEE. MMM. d"}} - {{event.endDate|date:"EEE. MMM. d, yyyy"}}
                    <span class="col-md-offset-3">
                      <label class="tier-position"
                             *ngIf="event.tier > 0">T{{event.tier}}</label>
                    </span>
                  </span>
                  <span *ngIf="event.startDate === '' "
                        class="event-tile-date">
                    {{event.endDate|date:"EEE.MMM. d, yyyy"}}
                    <span class="col-md-offset-3">
                      <label class="tier-position"
                             *ngIf="event.tier > 0">T{{event.tier}}</label>
                    </span>
                  </span>

                  <span class="event-tile-title">
                    <a routerLink="/Events/Event/{{event.id}}">
                      <truncate-text truncateString="{{event.name}}"
                                     truncateLimit="56">
                      </truncate-text>
                    </a>
                  </span>
                  <span *ngIf="event.venue.name || 'No Venue'"
                        class="event-tile-location">
                    <truncate-text truncateString="{{event.venue.name}}"
                                   truncateLimit="25">
                    </truncate-text>
                  </span>
                  <a (click)="saveFavorite(event)"
                     class="favoriteEvent hdr-action-icon pull-right"
                     [(ngModel)]="event.isFavorite"
                     ngDefaultControl
                     name="IsFavouriteGrid{{event.venue.name}}"
                     authenticate-user="favoriteevent.update,favoriteevent.retrieve">
                    <i class="fa"
                       [ngClass]="{'fa-star': event.isFavorite, 'fa-star-o': !event.isFavorite}"></i>
                  </a>
                </div>
                <div class="event-tile-sub-dtls">
                  <a routerLink="/Events/Season/{{event.seasonId}}"
                     title="{{event.seasonName}}"
                     class="event-tile-season"
                     *ngIf="event.seasonId > 0">
                    <span class="label label-default">S</span>
                  </a>
                  <span class="event-tile-network">
                    <i class="event-tile-network-icon"></i>
                    <span *ngFor="let network of event.networks ">
                      {{network}}<span [hidden]="$last">
                      </span>
                    </span>
                  </span>
                  <!--<div class="event-tile-weather-cloud pull-right" *ngIf="calculateDates(event.startDate, event.endDate)">
                      <i class="fa fa-cloud"></i> <span>32&deg;</span>
                  </div>-->

                </div>
                <div class="event-tile-positions">

                  <nbc-filled-positions-bar totalPositions="{{event.totalPositions}}"
                                            pendingPositions="{{event.pendingPositions}}"
                                            confirmedPositions="{{event.confirmedPositions}}"
                                            openPositions="{{event.totalPositions-(event.pendingPositions + event.confirmedPositions)}}">

                  </nbc-filled-positions-bar>
                  <label>{{event.totalPositions}} Positions</label>
                </div>
              </div>
              <div class="event-tile-aside">
                <nbc-event-countdown-label [nbcLabelPrependText]="In "
                                           [nbcStartDate]="event.startDate"
                                           [nbcEndDate]="event.endDate"></nbc-event-countdown-label>
                <img src="/assets/image/team/{{event?.awayTeam?.imageId}}"
                     width="40"
                     height="40"
                     class="event-tile-game-logo"
                     [hidden]="!event?.awayTeam?.imageId" />
                <img src="/assets/image/team/{{event?.homeTeam?.imageId}}"
                     width="40"
                     height="40"
                     class="event-tile-game-logo"
                     [hidden]="!event?.homeTeam?.imageId" />
                <img src="/assets/image/eventType/{{event?.eventType?.imageId}}"
                     width="40"
                     height="40"
                     class="event-tile-game-logo"
                     [hidden]="!event?.homeTeam?.imageId || event.awayTeam.imageId || !event.eventType.imageId" />

                <img class="event-tile-submissions"
                     *ngIf="(event.eventTcSubmitStatus == 'Fully Submitted' && event.totalPositions > 0)"
                     src="/Content/app/images/green_check.png"
                     width="28"
                     height="28"
                     title="{{event.eventTcSubmitStatus}}" />
                <img class="event-tile-submissions"
                     *ngIf="event.eventTcSubmitStatus == 'Not Submitted'"
                     src="/Content/app/images/red_check.png"
                     width="28"
                     height="28"
                     title="{{event.eventTcSubmitStatus}}" />
                <img class="event-tile-submissions"
                     *ngIf="event.eventTcSubmitStatus == 'Partially Submitted'"
                     src="/Content/app/images/orange_check.png"
                     width="28"
                     height="28"
                     title="{{event.eventTcSubmitStatus}}" />
                <div *ngIf="event.eventLocation" class="event-location-banner">
                  <span>
                    {{event.eventLocation}}
                  </span>
                </div>
                <!--hidden because of tier rate card new data is not available on this document-->
                <a [hidden]="true"
                   authenticate-user="event.create"
                   class="event-tile-copy"
                   (click)="openCloneEventModal(event)">
                  <i [hidden]="true"
                     class="fa fa-copy"></i>
                </a>
                <!-- <a authenticate-user="event.create" class="event-tile-copy" (click)="openCloneEventModal(event)"><i class="fa fa-copy"></i></a>-->
              </div>
            </div>
          </div>
        </div>

        <!-- ListView -->
        <div class="view-list"
             *ngIf="!showGrid"> 
          <ng-container *ngIf="model?.items.length>0">
            <div class="row"
                 *ngFor="let event of model.items">
              <div class="event-tile col-md-12">
                <div class="event-tile-aside">
                  <nbc-event-countdown-label [nbcLabelPrependText]="In "
                                             [nbcStartDate]="event.startDate"
                                             [nbcEndDate]="event.endDate"></nbc-event-countdown-label>
                  <img src="/assets/image/team/{{event.awayTeam.imageId}}"
                       width="40"
                       height="40"
                       class="event-tile-game-logo"
                       *ngIf="event?.awayTeam?.imageId" />
                  <span *ngIf="event.homeTeam?.imageId || event.awayTeam?.imageId">@</span>
                  <img *ngIf="event.homeTeam?.imageId"
                       src="/assets/image/team/{{event.homeTeam.imageId}}"
                       width="40"
                       height="40"
                       class="event-tile-game-logo" />
                  <img src="/assets/image/eventType/{{event.eventType.imageId}}"
                       width="40"
                       height="40"
                       class="event-tile-game-logo"
                       *ngIf="!event?.homeTeam?.imageId || !event.awayTeam?.imageId || event.eventType?.imageId" />
                  <div *ngIf="event.eventLocation" class="event-location-banner">
                    <span>
                      {{event.eventLocation}}
                    </span>
                  </div>

                </div>
                <div class="event-tile-main">
                  <div class="event-tile-dtls event-tile-status-archived col-lg-3 col-md-6 col-sm-6">
                    <a (click)="saveFavorite(event)"
                       class="favoriteEvent hdr-action-icon pull-right"
                       [(ngModel)]="event.isFavorite"
                       ngDefaultControl
                       authenticate-user="favoriteevent.update,favoriteevent.retrieve">
                      <i class="fa"
                         [ngClass]="{'fa-star': event.isFavorite, 'fa-star-o': !event.isFavorite}"></i>
                    </a>
                    <span class="event-tile-date"
                          *ngIf="event.startDate !== ''">
                      {{event.startDate|date:"EEE. MMM. d"}} - {{event.endDate|date:"EEE. MMM. d, yyyy"}}
                      <span class="col-md-offset-2">
                        <label class="tier-position"
                               *ngIf="event.tier && event.tier > 0">T{{event.tier}}</label>
                      </span>
                    </span>
                    <span class="event-tile-date"
                          *ngIf="event.startDate === '' ">
                      {{event.endDate|date:"EEE.MMM. d, yyyy"}}
                      <span class="col-md-offset-2">
                        <label class="tier-position"
                               *ngIf="event.tier && event.tier > 0">T{{event.tier}}</label>
                      </span>
                    </span>

                    <span class="event-tile-title">
                      <a routerLink="/Events/Event/{{event.id}}">{{event.name}}</a>
                    </span>
                    <span class="event-tile-location">{{event.venue.name || 'No Venue'}}</span>
                    <a routerLink="/Events/Season/{{event.seasonId}}"
                       title="{{event.seasonName}}"
                       class="event-tile-season pull-right"
                       *ngIf="event.seasonId > 0">
                      <span class="label label-default">S</span>
                    </a>
                  </div>
                  <div class="event-tile-sub-dtls col-lg-3 col-md-6 col-sm-6">
                    <span class="event-tile-network">
                      <i class="event-tile-network-icon"></i>
                      <span *ngFor="let network of event.networks">
                        {{network}}<span [hidden]="$last">
                        </span>
                      </span>
                    </span>
                    <!--<div class="event-tile-weather-cloud-list-wrapper pull-right">
                        <div class="event-tile-weather-cloud-list" *ngIf="calculateDates(event.startDate, event.endDate)">
                            <i class="fa fa-cloud"></i><span>32&deg;</span>
                        </div>
                    </div>-->
                  </div>
                  <div class="event-tile-positions col-lg-3 col-md-6 col-sm-6">
                    <nbc-filled-positions-bar totalPositions="{{event.totalPositions}}"
                                              pendingPositions="{{event.pendingPositions}}"
                                              confirmedPositions="{{event.confirmedPositions}}"
                                              openPositions="{{event.totalPositions-(event.pendingPositions + event.confirmedPositions)}}">

                    </nbc-filled-positions-bar>
                    <label>{{event.totalPositions}} Positions</label>

                    <img class="event-tile-submissions"
                         *ngIf="event.eventTcSubmitStatus == 'Fully Submitted' && event.totalPositions > 0"
                         src="/Content/app/images/green_check.png"
                         width="28"
                         height="28"
                         title="{{event.eventTcSubmitStatus}}" />
                    <img class="event-tile-submissions"
                         *ngIf="event.eventTcSubmitStatus == 'Not Submitted'"
                         src="/Content/app/images/red_check.png"
                         width="28"
                         height="28"
                         title="{{event.eventTcSubmitStatus}}" />
                    <img class="event-tile-submissions"
                         *ngIf="event.eventTcSubmitStatus == 'Partially Submitted'"
                         src="/Content/app/images/orange_check.png"
                         width="28"
                         height="28"
                         title="{{event.eventTcSubmitStatus}}" />
                  </div>
                  <div class="event-tile-budget col-lg-3 col-md-6 col-sm-6"
                       authenticate-user="financial.budgetforecast.retrieve">
                  </div>
                  <!--hidden because of tier rate card new data is not available on this document-->
                  <a [hidden]="true"
                     authenticate-user="event.create"
                     class="event-tile-copy"
                     (click)="openCloneEventModal(event)">
                    <i [hidden]="true"
                       class="fa fa-copy"></i>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
<ng-template #customItemTemplate
             let-model="item"
             let-index="index">
  <span>
    <img src="/assets/image/team/{{model.imageId}}"
         width="16" /><span style="padding-left:10px;">{{model.name}}</span>
  </span>
</ng-template>
