import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import { SubSink } from "subsink";

@Component({
    selector: "modal-network-group",
    templateUrl: "./modal-network-group.component.html",
    providers: [EventBusService],
})
export class ModalNetworkGroupComponent implements OnInit, OnDestroy {
    constructor(
        private _networkGroupService: networkGroupService,
        private eventBus: EventBusService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    //subject to transmit the results
    public onSync!: Subject<any>;
    allNetworkGroupSetup: any;
    tiers: any;
    showAlertMessage = false;
    disableSave = false;
    changeTracker: any = {
        networks: [],
        numberOfTiers: "",
        tierChangeAction: "",
    };

    networkToReset: any;
    private subs = new SubSink();
    isAdd = false;
    networkGroup: any;
    networkGroupToEdit: any;
    baseCopyOfConfig: any;
    networks: any;
    isNetworkGroupInUse: any;
    modalTitle = "";
    formValidity: any = {
        groupName: false,
        isNetworkGroupTiered: false,
        networkPanel: false,
        numberOfTiers: false,
    };
    onText = "Y";
    offText = "N";
    groupAssociatedNetworks: any = [];
    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }

    getTiers() {
        //hardcoding as we won't have more than 4 tiers :: Chris Brown 03132018
        this.tiers = [1, 2, 3, 4];
    }
    getNetworks() {
        this.subs.sink = this._networkGroupService.getAllNetworks().subscribe(
            (result) => {
                this.networks = result.reference;
                if (!this.isAdd) {
                    this.identifyOwnNetworks();
                }
                this.disablePreAssociatedNeworks();
            },
            (err) => {
                this.log.error("getAllNetworks got an error: ");
            },
            () => {
                this.log.trace("removing spinner");
            }
        );
    }
    disablePreAssociatedNeworks() {
        _.forEach(this.networks, (network) => {
            _.findIndex(this.groupAssociatedNetworks, (o: any) => {
                if (o.groupId !== this.networkGroup.id) {
                    if (_.indexOf(o.neworks, network.id) >= 0) {
                        network.isDisabled = true;
                    }
                }
            });
        });
    }

    identifyOwnNetworks() {
        _.forEach(this.networks, (network) => {
            _.findIndex(this.networkGroup.networks, (o: any) => {
                if (network.id === o.id) {
                    network.isSelected = true;
                }
                network.isDisabled = false;
            });
        });
    }

    associateNetworks() {
        let selectedNetworks = _.map(this.networks, function (o) {
            if (o.isSelected) {
                return o;
            }
        });
        selectedNetworks = _.without(selectedNetworks, undefined);
        return selectedNetworks;
    }

    validate() {
        let msg;
        this.formValidity = new Object();

        if (_.isEmpty(_.trim(this.networkGroup.name))) {
            this.formValidity.groupName = true;
            msg = "All Highlighted Fields are Required!";
        } else {
            const isAlreadyPresent = _.findIndex(
                this.allNetworkGroupSetup,
                (o: any) => {
                    return (
                        o.name.toLowerCase() ===
                        _.trim(this.networkGroup.name.toLowerCase())
                    );
                }
            );
            if (isAlreadyPresent > -1 && this.isAdd) {
                msg = "Cannot have duplicate Network Group Names!";
            }
            let j = 0;
            let duplicateIndicator = 0;
            for (j; j < this.allNetworkGroupSetup.length; j++) {
                if (
                    this.allNetworkGroupSetup[j].name.toLowerCase() ==
                    _.trim(this.networkGroup.name.toLowerCase())
                ) {
                    duplicateIndicator++;
                }
            }
            if (duplicateIndicator > 1) {
                msg = "Cannot have duplicate Network Group Names!";
            }
        }

        if (this.networkGroup.isNetworkGroupTiered) {
            if (
                !(
                    this.networkGroup.networks &&
                    this.networkGroup.networks.length > 0
                )
            ) {
                this.formValidity.networkPanel = true;
                msg = "All Highlighted Fields are Required!";
            }
            if (
                !(
                    this.networkGroup.numberOfTiers &&
                    this.networkGroup.numberOfTiers > 0
                )
            ) {
                this.formValidity.numberOfTiers = true;
                msg = "All Highlighted Fields are Required!";
            }
        }

        ///SCOR-13425
        if (!this.networkGroup.isNetworkGroupTiered) {
            if (
                !(
                    this.networkGroup.networks &&
                    this.networkGroup.networks.length > 0
                )
            ) {
                this.formValidity.networkPanel = true;
                msg = "All Highlighted Fields are Required!";
            }
        }

        if (msg) {
            this.log.showModalError(msg);
            this.showAlertMessage = true;
            return false;
        }

        return true;
    }

    upsertNetworkGroup() {
        this.networkGroup.networks = this.associateNetworks();
        if (!this.validate()) {
            return;
        }

        if (!_.isEmpty(this.changeTracker)) {
            this.networkGroup.changeTracker = this.changeTracker;
        }
        this.disableSave = true;

        this.subs.sink = this._networkGroupService
            .upsert(this.networkGroup)
            .subscribe(
                (result) => {
                    // this.log.showSuccess();
                    this.onSync.next("Configuration updated successfully");
                    this.closeModal();
                },
                (err) => {
                    this.log.showError("Unable to upsert network Group");
                    this.log.error("get got an error: " + JSON.stringify(err));
                },
                () => {
                    this.disableSave = false;
                }
            );
    }

    onNetworkStateChanged($event, network) {
        network.isSelected = $event.target.checked;
        this.eventBus.emit(
            new EmitEvent(
                "modal-network-group.component",
                "TierRatecardNetworkChanged",
                { networkGroup: this.networkGroup, network: network }
            )
        );
    }

    onNetworkStateChangeConfirmed = (network) => {
        const pristinObj = !_.isUndefined(this.baseCopyOfConfig)
            ? _.find(this.baseCopyOfConfig.networks, {id: network.id})
            : null;
        const isNetworkExists = _.find(
            this.changeTracker.networks,
            {
                id: network.id
            }
        );

        // when the network is part of the group and is deselected and again selected
        if (
            (pristinObj && network.isSelected) ||
            (!pristinObj && !network.isSelected && isNetworkExists)
        ) {
            _.remove(this.changeTracker.networks, function (n: any) {
                return n.id === network.id;
            });
        }
        // netowrk is not part of the group and newly selected from the free pool
        // OR network is part of the group and deselected
        else if (
            (!pristinObj && network.isSelected) ||
            (pristinObj && !network.isSelected)
        ) {
            if (!isNetworkExists) {
                this.changeTracker.networks.push(network);
            }
        }
    };

    // user cancels the flow after seeing the impact
    onNetworkStateChangeCancelled(network) {
        const pristinObj = !_.isUndefined(this.baseCopyOfConfig)
            ? _.find(this.baseCopyOfConfig.networks, {
                id: network.id
                })
            : null;
        this.networkToReset = _.find(this.networks, {
            id: network.id
        });
        if (pristinObj) {
            this.networkToReset.isSelected = true;
        } else {
            this.networkToReset.isSelected = false;
        }
    }

    //NO NEED TO IMPLEMENT. THiS IS NOT VALID CASE
    //onTierFlagChanged() {
    //  this.eventBus.emit(new EmitEvent("modal-network-group.component",
    //    Events.TierFlagChangedImpact, { networkGroup: this.networkGroup, network: network }));

    //}

    onTierCountChanged() {
        this.eventBus.emit(
            new EmitEvent(
                "modal-network-group.component",
                "TierChangedImpact",
                {
                    networkGroup: this.networkGroup,
                    baseCopyOfConfig: this.baseCopyOfConfig,
                }
            )
        );
    }

    onTierCountChangeConfirmed(action) {
        if (
            !_.isUndefined(this.baseCopyOfConfig) &&
            this.baseCopyOfConfig.numberOfTiers &&
            this.baseCopyOfConfig.numberOfTiers > 0 &&
            this.networkGroup.numberOfTiers !==
                this.baseCopyOfConfig.numberOfTiers
        ) {
            this.changeTracker.numberOfTiers =
                this.baseCopyOfConfig.numberOfTiers;
            this.changeTracker.tierChangeAction = action;
        }
    }

    //Reset the Number of Tier if event is tiered
    onSetNumberOfTiers(data) {
        this.networkGroup.numberOfTiers = data.numberOfTiers;
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.getNetworks();
        this.getTiers();
        this.baseCopyOfConfig = _.cloneDeep(this.networkGroupToEdit);
        this.networkGroup = _.cloneDeep(this.networkGroupToEdit);
        this.isNetworkGroupInUse = this.isNetworkGroupInUse;

        if (this.isAdd) {
            this.networkGroup.isNetworkGroupTiered = false;
        }

        this.subs.sink = this.eventBus.on(
            "onNetworkStateChangeConfirmed",
            (data) => {
                this.onNetworkStateChangeConfirmed(data.network);
            }
        );

        this.subs.sink = this.eventBus.on(
            "onNetworkStateChangeCancelled",
            (data) => {
                this.onNetworkStateChangeCancelled(data.changedNetwork);
            }
        );

        this.subs.sink = this.eventBus.on("onTieredCountRevert", (data) => {
            this.onSetNumberOfTiers(data);
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
