import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { ReportService } from "src/app/Shared/Services/report.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-sso-status",
    templateUrl: "./sso-status.component.html",
    styleUrls: ["../../../../../assets/Content/app/RemotesReport.css"],
    encapsulation: ViewEncapsulation.Emulated,
})
export class SsoStatusReportComponent implements OnInit {
    constructor(
        private _reportService: ReportService,
        private bsModalRef: BsModalRef
    ) {}
    @ViewChild(NbcFileDownloadComponent) child;
    eventId: any;
    departmentList: any[] = [];
    isDownload = false;
    showDepartmentRowAll = true;
    showDepartmentEditRow = false;
    showDepartmentSelectedRow = false;
    events: any;
    departmentSelected = {};
    SSOStatusReportInputInfo: any = {};
    CrewChangeReportInputInfo: any;
    fileName: any;
    fileType: any;
    data: any;
    SSOStatusReport: any;
    disp = false;
    user = window.preload.user;
    userName = this.user.firstName + " " + this.user.lastName;
    showAlertMessage = false;
    showDepartmentRow: any;
    showDepartmentTagRow: any;
    private subs = new SubSink();

    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }

    checkDepartmentValue() {
        if (this.SSOStatusReportInputInfo.department !== null) {
            this.showDepartmentRowAll = false;
        } else {
            this.showDepartmentRowAll = true;
        }
    }
    editRow(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentRow = true;
        }
    }

    confirmSelection(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentRow = false;
            this.checkDepartmentValue();
        }
    }

    stateChanged(checked: any, value: any, row: any) {
        if (row === "DepartmentRow") {
            if (checked) {
                this.departmentList.push(value);
                this.CrewChangeReportInputInfo.department.push(value);
            }
            if (!checked) {
                this.departmentList.splice(
                    this.departmentList.indexOf(value),
                    1
                );
                this.CrewChangeReportInputInfo.department.splice(
                    this.CrewChangeReportInputInfo.department.indexOf(value),
                    1
                );
            }
        }
    }

    removeTag(item: any, row: any) {
        if (row === "DepartmentRow") {
            this.departmentList.splice(this.departmentList.indexOf(item), 1);
            this.CrewChangeReportInputInfo.department.splice(
                this.CrewChangeReportInputInfo.department.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("DepartmentRow");
        }
    }

    showSection(value: any) {
        if (value === "DepartmentRow") {
            if (
                !_.isUndefined(this.departmentList) &&
                this.departmentList.length > 0
            ) {
                this.showDepartmentRowAll = false;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = true;
            } else {
                this.showDepartmentRowAll = true;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = false;
            }
        }
    }
    getSSOStatusReportDetails() {
        // log.trace("exportReport");
        // usSpinnerService.spin("genericSpinner");
        this.subs.sink = this._reportService
            .getRemotesCrewSSOStatusLookupDetails(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.showAlertMessage = true;
                        // this.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.SSOStatusReport = data.department;
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                }, // this.alerts = [{ type: "danger", msg: "Error Occurred" }];
            });
    }
    loadEvents(term: any) {
        // usSpinnerService.spin("genericSpinner");
        this.showAlertMessage = false;
        // log.trace("loadEvents");
        // var defer = $q.defer();

        this.subs.sink = this._reportService
            .autoCompleteByOnlyEvent(term, this.events)
            .subscribe({
                next: (data) => {
                    // defer.resolve(data.events);
                },
                error: (data) => {
                    // defer.reject(data);
                },
            });

        // return defer.promise;
    }

    closeAlert() {
        // $scope.alerts = [];
        this.showAlertMessage = false;
    }
    ngOnInit(): void {
        this.getSSOStatusReportDetails();
        this.SSOStatusReportInputInfo = {};
        this.SSOStatusReportInputInfo.department = null;
    }

    exportReport() {
        // usSpinnerService.spin("genericSpinner");
        const tempSSOStatusReportInfo: any = {};

        const temp_var: any[] = [];
        if (this.SSOStatusReportInputInfo.department !== null) {
            temp_var.push(this.SSOStatusReportInputInfo.department);
        }
        tempSSOStatusReportInfo["department"] = temp_var;

        this.subs.sink = this._reportService
            .exportSSOStatusReport(
                this.eventId,
                tempSSOStatusReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.showAlertMessage = true;
                        // $scope.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.child.fileName =
                            "Event-" + this.eventId + "-SSO Status";
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    // $scope.alerts = [{ type: "danger", msg: "Error Occurred" }];
                },
            });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
