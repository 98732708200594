import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactService } from "src/app/Shared/Services/contact.service";
import * as _ from "lodash";
import * as moment from "moment";
import { ContactSharedDataService } from "./contact-shared.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import { takeUntil, switchMap } from "rxjs/operators";
import { Subject, Subscriber, Observable } from "rxjs";
import { PositionService } from "src/app/Shared/Services/position.service";
import { SkillsService } from "src/app/Shared/Services/skills.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { PermissionUtility } from "src/app/Core/services/permission-utility.service";
import { SharedDataService } from "../../Shared/sharedData.service";
import { ModalEmergencyContactComponent } from "../component/emergencyContacts/modal-emergency-contact.component";

@Component({
    templateUrl: "./contact-details.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.css",
    //  "../../../assets/Content/app/areas/contacts/contacts.css"],
    //encapsulation: ViewEncapsulation.None
})
export class ContactDetailsComponent implements OnInit {
    constructor(
        private _contactService: ContactService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _contactSharedData: ContactSharedDataService,
        private _employeeTypeService: EmployeeTypeService,
        private _positionService: PositionService,
        private _skillService: SkillsService,
        private _modalService: BsModalService,
        private _dataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    bsModalRef?: BsModalRef;
    showSyncMessage: any | undefined;
    employeeTypes: {} | undefined;
    rateCardList: any[] | undefined;
    submitted = false;
    mode = "";
    disableCancel = false;
    unionOptions: any;
    contactCopy: any;
    preload = {
        mode: "",
    };
    countries: any;

    editModeHRVerifiedSection = false;

    componentDestroyed$: Subject<boolean> = new Subject();
    contactId: number | undefined;
    contact: any | undefined;
    noEditMode = false;
    editMode = false;
    eventTypes: any[] = [];
    customProp: any;
    skillObservable$: Observable<any[]> | undefined;
    positionObservable$: Observable<any[]> | undefined;

    positionSearchTerm = "";
    skillSearchTerm = "";
    skillData: any[] = [];

    tabs = [
        {
            heading: "Profile Info",
            route: "profileinfo",
            active: true,
            hasAccess: false,
            authentication:
                "contact.retrieve,contact.hrmaster.retrieve,contact_profile_profile_info.retrieve",
        },
        {
            heading: "Work History",
            route: "workhistory",
            active: false,
            hasAccess: false,
            authentication:
                "contact.workhistory.retrieve,contact_profile_work_history.retrieve",
        },
        {
            heading: "Work Schedule",
            route: "workschedule",
            active: false,
            hasAccess: false,
            authentication:
                "contact.workschedule.retrieve,contact.workschedule.create,contact_profile_work_schedule.retrieve",
        },
        {
            heading: "Work Schedule ALL",
            route: "workscheduleall",
            active: false,
            hasAccess: false,
            authentication:
                "contact.workschedule.retrieve,contact.workschedule.create,contact_profile_work_schedule.retrieve",
        },
        {
            heading: "Talent Permission",
            route: "permissionTalent",
            active: false,
            hasAccess: false,
            authentication:
                "contact.permissiontalent.retrieve,contact.permissiontalent.create,contact_profile_permission_talent.retrieve",
        },
        {
            heading: "Remotes Permission",
            route: "permissionRemotes",
            active: false,
            hasAccess: false,
            authentication:
                "contact.permissionremotes.retrieve,contact.permissionremotes.create,contact_profile_permission_remotes.retrieve",
        },
        {
            heading: "Olympics Permission",
            route: "permissionOlympics",
            active: false,
            hasAccess: false,
            authentication:
                "contact.permissionolympics.retrieve,contact.permissionolympics.create,contact_profile_permission_olympics.retrieve",
        },
    ];

    setEditMode(callFromComponent?: boolean) {
        this.log.trace("setEditMode");
        this.editMode = true;

        this.contactCopy = _.cloneDeep(this.contact);
        if (!callFromComponent) {
            setTimeout(() => {
                this._contactSharedData.isContactEditModeEnabled(true);
            }, 100);
        }
        // this.getRateCards();
        //this.setDialCode(1);
    }

    setReadMode() {
        this.log.trace("setReadMode");
        this.contact = this.contactCopy;

        if (!this.editModeHRVerifiedSection) {
            this.contactCopy = {};
        }
        this.editMode = false;
    }

    getContact() {
        //var defer = $q.defer();
        this.log.trace("retrieve contact");

        this._contactService
            .getContact(this.contactId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace("contactSearch -> search received");
                    this.contact = data.reference;
                    // this.$broadcast("onContactDetailLoaded");
                    this.contactCopy = _.cloneDeep(this.contact);
                    if (
                        this.preload.mode === "newNoSync" &&
                        this.showSyncMessage
                    ) {
                        this.log.showError(
                            "No results found. This contact will be added with only the details you have entered.  Incorrect or dummy data will prevent the contact from syncing with SAP."
                        );
                    }
                    if (
                        this.preload.mode === "newNoSyncError" &&
                        this.showSyncMessage
                    ) {
                        this.log.showError(
                            "Unable to sync contact at this time."
                        );
                    }
                    if (
                        this.preload.mode === "newCancelSync" &&
                        this.showSyncMessage
                    ) {
                        this.log.showError(
                            "This contact will be added with only the details you have entered.  Incorrect or dummy data will prevent the contact from syncing with SAP."
                        );
                    }
                    if (
                        this.preload.mode === "newSync" &&
                        this.showSyncMessage
                    ) {
                        this.log.showSuccess("Contact synced successfully.");
                    }
                    if (
                        this.preload.mode === "newNoSyncSSNError" &&
                        this.showSyncMessage
                    ) {
                        this.log.showError(
                            "There is an existing contact with the same birthday, last four digits of the social security number and last name.  You cannot create a duplicate contact.  Please cancel this request."
                        );
                    }
                    if (
                        this.preload.mode === "newNoSyncSSOError" &&
                        this.showSyncMessage
                    ) {
                        this.log.showError(
                            "There is an existing contact with this SSO. You cannot have more than one contact with the same SSO."
                        );
                    }
                    this.showSyncMessage = false;
                    this.setDialCode(1);
                    this.setDialCode(2);
                    this.setDialCode(3);

                    this.getRateCards();
                    this.setDefaultContactCustomColor();

                    this._contactSharedData.setContact(this.contact);

                    if (this.mode === "edit" || this.mode === "new") {
                        this.setEditMode(false);
                    }
                    if (this.mode === "new") {
                        this.disableCancel = true;
                    }
                },
                (err) => this.log.error(err),
                () => {}
            );
    }
    setPositionEventTerm($event) {
        this.positionSearchTerm = $event;
    }
    getEmployeeTypes() {
        this.log.trace("contact Details -> getEmployeeType:fetching data");
        this._employeeTypeService
            .getAllEmployeeTypes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.employeeTypes = data.reference;
                    this.log.trace("contact Details -> EmployeeType received");
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    onEmployeeTypeChange(event: any) {
        this.contact.employeeType = event;
        this.contact.rateCode = {};
        this.getRateCards();
        this.checkRateCode();
    }

    getRateCards() {
        if (
            this.contact &&
            this.contact.employeeType &&
            this.contact.employeeType !== "" &&
            this.contact.employeeType.description.toLowerCase() !== "vendor"
        ) {
            this.rateCardList = this.contact.employeeType.rateCards;
        } else if (
            this.contact &&
            this.contact.employeeType &&
            (this.contact.employeeType === "" ||
                this.contact.employeeType.description.toLowerCase() ===
                    "vendor")
        ) {
            this.contact.rateCode.id = undefined;
            this.contact.rateCode.description = "";
            this.rateCardList = [];
        }
    }

    checkRateCode() {
        this.log.trace("chceking Rate Code");
        if (this.contact.employeeType.description === "Select One") {
            this.contact.rateCode.id = "";
            this.contact.rateCode.description = "";
        } else if (
            this.contact.employeeType.description.toLowerCase() === "vendor"
        ) {
            this.contact.rateCode.id = "";
            this.contact.rateCode.description = "N/A";
        }
    }

    loadContactDetails(showSyncMsg) {
        this.log.trace("loadContactDetails");
        // Vars
        this.showSyncMessage = showSyncMsg;
        this.editMode = false;
        this.employeeTypes = {};
        this.rateCardList = [];
        this.submitted = false;
        if (this.preload.mode) {
            if (
                this.preload.mode === "newSync" ||
                this.preload.mode === "newNoSync" ||
                this.preload.mode === "newNoSyncError" ||
                this.preload.mode === "newCancelSync" ||
                this.preload.mode === "newNoSyncSSNError" ||
                this.preload.mode === "newNoSyncSSOError"
            ) {
                this.mode = "new" || "";
            } else {
                this.mode = this.preload.mode;
            }
        } else {
            this.mode = "";
        }
        this.disableCancel = false;
        this.unionOptions = [
            {
                name: "Union",
            },
            {
                name: "Non union",
            },
        ];
        this.contact = {};
        this.contactCopy = {};
        // Initialize
        this.contact = this.getContact();
        this.getEmployeeTypes();
        //moved under get contact as we are having sync issues.
        //if (this.mode === "edit" || this.mode === "new") {
        //  this.setEditMode(false);
        //}
        //if (this.mode === "new") {
        //  this.disableCancel = true;
        //}
    }

    setDialCode(seqnumber) {
        if (this.contact) {
            switch (seqnumber) {
                case 1:
                    this.contact.phone1.dialCode = this.getCountryDialCode(
                        this.contact.phone1.countryCode
                    );
                    break;
                case 2:
                    this.contact.phone2.dialCode = this.getCountryDialCode(
                        this.contact.phone2.countryCode
                    );
                    break;
                case 3:
                    this.contact.phone3.dialCode = this.getCountryDialCode(
                        this.contact.phone3.countryCode
                    );
                    break;
            }
        }
    }

    getCountryDialCode(IsoCode) {
        let dialcode;
        if (this.countries) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].isoCode === IsoCode) {
                    dialcode = this.countries[i].dialCode;
                }
            }
        }

        return dialcode;
    }

    setDefaultContactCustomColor() {
        if (this.contact && this.contact.contactVerified) {
            this.contact.contactVerified.tooltip = this.contact.contactVerified
                .fieldBool
                ? "Contact Information Accurate"
                : "Contact Information May Not Be Accurate";
            if (this.contact.contactVerified.updatedBy != "") {
                this.contact.contactVerified.modifiedDate = moment
                    .utc(this.contact.contactVerified.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.contactVerified.tooltip =
                    this.contact.contactVerified.tooltip +
                    (this.contact.contactVerified.updatedBy
                        ? "\nVerified By: " +
                          this.contact.contactVerified.updatedBy +
                          " Verified On: " +
                          this.contact.contactVerified.modifiedDate
                        : "");
            }
            this.contact.contactVerified.color = this.contact.contactVerified
                .fieldBool
                ? "Green"
                : "Red";
        }
        if (this.contact && this.contact.covidCommunicationSent) {
            this.contact.covidCommunicationSent.tooltip = this.contact
                .covidCommunicationSent.fieldBool
                ? "Notification Email Sent"
                : "Notification Email Not Sent";
            if (this.contact.covidCommunicationSent.updatedBy != "") {
                this.contact.covidCommunicationSent.modifiedDate = moment
                    .utc(this.contact.covidCommunicationSent.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.covidCommunicationSent.tooltip =
                    this.contact.covidCommunicationSent.tooltip +
                    (this.contact.covidCommunicationSent.updatedBy
                        ? "\nSent By: " +
                          this.contact.covidCommunicationSent.updatedBy +
                          " Sent On: " +
                          this.contact.covidCommunicationSent.modifiedDate
                        : "");
            }
            this.contact.covidCommunicationSent.color = this.contact
                .covidCommunicationSent.fieldBool
                ? "Green"
                : "Red";
        }
        if (this.contact && this.contact.covidReplyRecd) {
            this.contact.covidReplyRecd.tooltip = this.contact.covidReplyRecd
                .fieldBool
                ? "Notification Email Acknowledged"
                : "Notification Email Not Acknowledged";
            if (this.contact.covidReplyRecd.updatedBy != "") {
                this.contact.covidReplyRecd.modifiedDate = moment
                    .utc(this.contact.covidReplyRecd.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.covidReplyRecd.tooltip =
                    this.contact.covidReplyRecd.tooltip +
                    (this.contact.covidReplyRecd.updatedBy
                        ? "\nAcknowledged By: " +
                          this.contact.covidReplyRecd.updatedBy +
                          " Acknowledged On: " +
                          this.contact.covidReplyRecd.modifiedDate
                        : "");
            }
            this.contact.covidReplyRecd.color = this.contact.covidReplyRecd
                .fieldBool
                ? "Green"
                : "Red";
        }
    }

    go(route: string) {
        _.forEach(this.tabs, function (tab, index) {
            tab.active = false;
            if (tab.route === route) {
                tab.active = true;
            }
        });

        this._router.navigate([route], { relativeTo: this._activatedRoute });
    }

    activateSelectedTab() {
        const activatedRoute =
            this._activatedRoute.snapshot?.firstChild?.routeConfig?.path;
        if (activatedRoute) {
            _.forEach(this.tabs, (rt) => {
                rt.active = false;
                if (activatedRoute?.toLowerCase() === rt.route.toLowerCase()) {
                    rt.active = true;
                }
            });
        }
    }
    setSkillEventTerm($event) {
        this.skillSearchTerm = $event;
        this.loadSkills();
    }
    positionTypeahead() {
        this.positionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.positionSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._positionService.autocomplete(token);
            })
        );
    }
    //skillsTypeahead() {
    //  this.skillObservable$ = new Observable((observer: Subscriber<string>) => {
    //    // Runs on every search
    //    observer.next(this.skillSearchTerm);
    //  }).pipe(takeUntil(this.componentDestroyed$),
    //    switchMap((token: string) => {

    //      return this._skillService.autocomplete(token)
    //    })
    //  );
    //}

    onPositionSelected(position) {
        this.log.trace(this.contact.positions);
        if (_.isEmpty(this.contact.positions)) {
            this.contact.positions = [];
        }

        this.contact.positions = position;
        this.positionSearchTerm = "";
    }

    loadSkills() {
        this._skillService.autocomplete(this.skillSearchTerm).subscribe({
            next: (result: any) => {
                this.skillData = result || [];
            },
            error: (err) => {
                this.log.showError(
                    "Unable to load skills at this time. Please try again later."
                );
            },
        });
    }

    onSkillSelected(skill) {
        this.log.trace(this.contact.skills);
        if (_.isEmpty(this.contact.skills)) {
            this.contact.skills = [];
        }

        this.contact.skills = skill;
        // this.contact.skills = (skill);

        this.skillSearchTerm = "";
    }
    removeTag(property, item) {
        this.log.trace(this.contact[property]);

        const list = _.filter(this.contact[property], function (tag: any) {
            return tag.id !== item.id;
        });
        this.contact[property] = list;
    }
    changeTypeaheadLoading($event) {}
    contactVerified() {
        this.log.trace("confirm position");
        const title = "Contact Information Accuracy",
            msg = !this.contact.contactVerified.fieldBool
                ? "Please confirm contact's information is accurate."
                : "Are you sure contact's information is no longer accurate?";
        this.toggleCustomFieldValue("contactVerified");
        this.customProp = "contactVerified";
        // showValidationErrorModal(title, msg, true, CustomFieldUpdate, [$scope.contact.contactVerified, $scope.contact.id], "Confirm", "Cancel", toggleCustomFieldValue, ["contactVerified"]);
        this.showConfirmationModal(
            title,
            msg,
            true,
            "Confirm",
            this.customFieldUpdate,
            [this.contact.contactVerified, this.contact.id],
            "Cancel",
            this.toggleCustomFieldValue,
            ["contactVerified"]
        );
    }

    covidCommunicationSent() {
        this.log.trace("confirm position");

        const title = "E-mail Communication Sent",
            msg = !this.contact.covidCommunicationSent.fieldBool
                ? "Please confirm notification e-mail was sent to the contact."
                : "Are you sure notification e-mail was not sent to the contact?";
        this.toggleCustomFieldValue("covidCommunicationSent");
        this.customProp = "covidCommunicationSent";
        this.showConfirmationModal(
            title,
            msg,
            true,
            "Confirm",
            this.customFieldUpdate,
            [this.contact.covidCommunicationSent, this.contact.id],
            "Cancel",
            this.toggleCustomFieldValue,
            ["covidCommunicationSent"]
        );
    }

    covidReplyRecd() {
        this.log.trace("confirm position");

        const title = "Communication Sent Acknowledgement",
            msg = !this.contact.covidReplyRecd.fieldBool
                ? "Please confirm contact acknowledgement of notification e-mail."
                : "Are you sure contact did not acknowledge the notification e-mail?";
        this.toggleCustomFieldValue("covidReplyRecd");
        this.customProp = "covidReplyRecd";

        this.showConfirmationModal(
            title,
            msg,
            true,
            "Confirm",
            this.customFieldUpdate,
            [this.contact.covidReplyRecd, this.contact.id],
            "Cancel",
            this.toggleCustomFieldValue,
            ["covidReplyRecd"]
        );
    }

    toggleCustomFieldValue(customprop) {
        this.contact[customprop].fieldValue =
            this.contact[customprop].fieldValue === "Y" ? "N" : "Y";
        this.contact[customprop].fieldBool =
            !this.contact[customprop].fieldBool;

        if (this.contact[customprop].updatedBy != "") {
            this.contact[customprop].modifiedDate = moment
                .utc(this.contact[customprop].updatedDate)
                .format("MM/DD/YYYY");
        }
        this.contact[customprop].color = this.contact[customprop].fieldBool
            ? "Green"
            : "Red";

        this.contactCopy[customprop].fieldValue =
            this.contactCopy[customprop].fieldValue === "Y" ? "N" : "Y";
        this.contactCopy[customprop].fieldBool =
            !this.contactCopy[customprop].fieldBool;
        if (this.contactCopy[customprop].updatedBy != "") {
            this.contactCopy[customprop].modifiedDate = moment
                .utc(this.contactCopy[customprop].updatedDate)
                .format("MM/DD/YYYY");
        }
        this.contactCopy[customprop].color = this.contactCopy[customprop]
            .fieldBool
            ? "Green"
            : "Red";
    }

    customFieldUpdate = (customfield, contactid): void => {};

    showConfirmationModal(
        title,
        msg,
        canUpdate,
        confirmText,
        confirmCallback,
        confirmArgs,
        cancelText,
        cancelCallback,
        cancelArgs
    ) {
        const initialState: any = {
            modalTitle: title,
            modalMessage: msg,
            confirmText: confirmText,
            cancelText: cancelText,
            hideConfirm: false,
        };
        const modalOptions = {
            animated: true,
          initialState: initialState,
          ignoreBackdropClick: true,
          keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result) => {
            this.log.trace("confirmation");
            this._contactService
                .updateCustomField(
                    this.contactId,
                    this.contact[this.customProp]
                )
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (confirmResult) =>
                        this.log.trace(
                            "got results: " + JSON.stringify(confirmResult)
                        ),
                    error: (err) =>
                        this.log.error("got error: " + JSON.stringify(err)),
                });
            // confirmCallback.apply(this, confirmArgs);
        });
        this.bsModalRef.content.onRejection.subscribe((result) => {
            this.log.trace("onRejection");

            this.toggleCustomFieldValue(this.customProp);
            // cancelCallback.apply(this, cancelArgs);
        });
    }
    execute(fnDetails: any) {
        if (!_.isEmpty(fnDetails.methodName)) {
            switch (fnDetails.methodName) {
                case "loadContactDetails":
                    this.mode = fnDetails.mode;
                    this.preload.mode = this.mode;
                    this.loadContactDetails(false);
                    break;
            }
        }
    }

    authorizeTabAccess() {
        const access = PermissionUtility.PermissionUtilities();
        const user = window.preload.user;
        _.forEach(this.tabs, function (tab, index) {
            tab.hasAccess = access.hasAccess(
                user,
                user.allFeatures,
                tab.authentication.split(","),
                null,
                null,
                null
            );
        });
    }

    ngOnInit() {
        this._dataService.setCss("contact");

        this._activatedRoute.params.subscribe((params) => {
            this.contactId = params["id"];
        });

        this._activatedRoute.queryParams.subscribe((qparams) => {
            this.preload.mode = qparams["mode"];
        });

        this._contactSharedData
            .getFnCall()
            .subscribe((fnDetails) => this.execute(fnDetails));

        this._contactSharedData.contactEditEnabled.subscribe((message) => {
            if (message) {
                this.setEditMode(true);
            } else {
                this.setReadMode();
            }
        });
        this._contactSharedData.contactEditUpdated.subscribe((res) => {
            this._contactSharedData.getContact().subscribe((res) => {
                this.contact = res;
                this.loadSkills();
            });
        });
        this._contactSharedData.HrVerficationEditEnabled.subscribe(
            (message) => {
                if (message) {
                    this.editModeHRVerifiedSection = message;
                }
            }
        );

        this.authorizeTabAccess();

        this.positionTypeahead();

        this.activateSelectedTab();

        this.loadContactDetails(true);
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
