import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-event-countdown-label",
    templateUrl: "./nbcEventCountdownLabel.component.html",
})
export class NbcEventCountdownLabelComponent implements OnInit {
    constructor(private log: NbcLoggerService) {
        this.isValid = false;
    }

    @Input() nbcStartDate: Date | undefined;
    @Input() nbcEndDate: Date | undefined;
    @Input() nbcLabelPrependText = "";
    @Input() nbcLabelAppendText = "";

    isValid: boolean;
    hide: boolean | undefined;
    timeLabel: string | undefined;

    updateTextAfterDateChange() {
        this.log.trace("loading eventCountdownLabel :" + this.nbcStartDate);
        const eventnbcStartDate = moment.parseZone(this.nbcStartDate);
        const eventnbcEndDate = moment.parseZone(this.nbcEndDate);
        const todaysDate = moment().startOf("day");
        const daysBeforeEvent = todaysDate.diff(
            eventnbcStartDate,
            "days",
            true
        );
        const nbcLabelPrependText = this.nbcLabelPrependText
            ? this.nbcLabelPrependText + " "
            : "";
        const nbcLabelAppendText = this.nbcLabelAppendText
            ? " " + this.nbcLabelAppendText
            : "";
        const weeks = Math.abs(Math.round(daysBeforeEvent / 7));
        const difference = moment
            .parseZone(eventnbcEndDate)
            .diff(eventnbcStartDate, "days");

        if (eventnbcStartDate.isValid() === true) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }

        this.hide = false;
        this.timeLabel =
            nbcLabelPrependText + Math.ceil(Math.abs(daysBeforeEvent));
        if (difference === 0 && daysBeforeEvent >= 0 && daysBeforeEvent <= 1) {
            this.timeLabel = "TODAY";
        } else if (todaysDate > eventnbcEndDate) {
            this.timeLabel = "PAST";
        } else if (
            todaysDate >= eventnbcStartDate &&
            todaysDate <= eventnbcEndDate
        ) {
            this.timeLabel = "CURRENT";
        } else if (Math.abs(daysBeforeEvent) >= 14) {
            this.timeLabel =
                nbcLabelPrependText + weeks + " WEEKS" + nbcLabelAppendText;
        } else if (Math.ceil(Math.abs(daysBeforeEvent)) === 1) {
            this.timeLabel = this.timeLabel + " DAY" + nbcLabelAppendText;
        } else {
            this.timeLabel = this.timeLabel + " DAYS" + nbcLabelAppendText;
        }
        if (weeks > 3) {
            this.hide = true;
        }
    }

    ngOnInit() {
        this.updateTextAfterDateChange();
    }
}
