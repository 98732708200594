import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NetworkService } from "src/app/Shared/Services/network.service";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { takeUntil } from "rxjs/operators";
import { tierConfigChangeImpactService } from "src/app/Shared/Services/tier-config-change-Impact.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { cloneDeep } from "lodash";

@Component({
    selector: "modal-network",
    templateUrl: "./modal-Impacted-Events.component.html",
})
export class modalImpactedEventsList implements OnInit {
    constructor(
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef,
        public _tierConfigChangeImpactService: tierConfigChangeImpactService
    ) {}

    //subject to transmit the results
    public onSync!: Subject<any>;
    componentDestroyed$: Subject<boolean> = new Subject();
    modalTitle: any;
    message: any;
    impactedEvents: any | undefined;
    eventList: any[] | undefined;
    queryData: any;
    closeModal() {
        this.onSync.next(false);
        this.bsModalRef.hide();
    }

    proceed() {
        this.onSync.next(true);
        this.bsModalRef.hide();
    }
    getImpactedFutureEvents(showNoRecordMessage?) {
        this._tierConfigChangeImpactService
            .getImpactedFutureEvents(this.queryData)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.impactedEvents = result;
                    this.eventList = this.impactedEvents.items;
                },
                (err) => {
                    this.log.showError(
                        "unable to find change impact. Please try later."
                    );
                }
            );
    }
    ngOnInit() {
        this.onSync = new Subject();
        this.getImpactedFutureEvents();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
