import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
    providedIn: "root",
})
export class PayrollService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}
    _timesheetQuery = {
        eventId: 0,
        timeCardDate: new Date(),
        pageSize: 10,
        sortBy: null,
        currentPage: 1,
        departments: "",
        positionType: {
            id: 0,
            description: "",
        },
        employeeType: null,
        contactType: 0,
        allContacts: {
            id: 0,
            description: "",
        },
        isTravel: true,
        isNonTravel: true,
        positionCategory: "",
    };

    //SCORE 21795
    constObj = {
        /// </summary>
        glAccount_code1: "50601000",

        /// <summary>
        /// glAccount_code2
        /// </summary>
        glAccount_code2: "50624000",

        /// <summary>
        /// glAccount_code3
        /// </summary>
        glAccount_code3: "57031527",
    };

    getPayrollQuery() {
        return this._timesheetQuery;
    }
    getPayrollDashboardTimesheets(query: any): Observable<any> {
        this.log.trace("get payroll dashboard timesheets summary and items: ");
        return this.http.post(
            this._apiroot.baseUrl + "payroll/timesheets/submitted",
            query
        );
    }
    initEventTimesheet(eventTimesheetQuery) {
        this.log.trace("Save event timesheet");
        return this.http.post(
            this._apiroot.baseUrl + "payroll/activateTimesheet",
            eventTimesheetQuery
        );
    }

    getPayrollDashboardEvents(
        payrollTimesheetSummaryQuery: any
    ): Observable<any> {
        this.log.trace("get payroll dashboard upcoming events");
        return this.http.post(
            this._apiroot.baseUrl + "dashboard/payrollupcomingEvents",
            payrollTimesheetSummaryQuery
        );
    }

    getPayrollUpcomingEventSummary(eventId): Observable<any> {
        this.log.trace("get payroll upcoming event summary data");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "payroll/upcoming/" +
                    eventId +
                    "/summary"
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getPayrollUpcomingEventHeader(eventId): Observable<any> {
        this.log.trace("get payroll upcoming event summary data");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "payroll/upcoming/" +
                    eventId +
                    "/header"
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getPayrollTimecardDates(eventId): Observable<any> {
        this.log.trace("get payroll upcoming event summary data");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/GetPayrollTimecardDates",
                ""
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    savePayrollProviderItems(eventId, payrollProviderItems): Observable<any> {
        this.log.debug("save payroll provider items");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "payroll/upcoming/" +
                    eventId +
                    "/payrollprovider",
                payrollProviderItems
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getPayrollTimesheets(timesheetQuery: any): Observable<any> {
        this.log.trace("get payroll timesheets");
        return this.http
            .post(this._apiroot.baseUrl + "payroll/timesheets", timesheetQuery)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getPayrollTimesheetHeader(timesheetQuery: any): Observable<any> {
        this.log.trace("get payroll timesheets");
        return this.http
            .post(
                this._apiroot.baseUrl + "payroll/timesheets/header",
                timesheetQuery
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    exportTimesheet(apiURL, providers): Observable<any> {
        this.log.trace("export Timesheet");
        return this.http
            .post(this._apiroot.baseUrl + apiURL, providers, {
                responseType: "arraybuffer",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
            })
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    savePayrollTimesheet(payrollTimesheet: any): Observable<any> {
        this.log.trace("save payroll timesheet");
        return this.http
            .post(
                this._apiroot.baseUrl + "payroll/savetimesheets",
                payrollTimesheet
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    searchGlAccounts(term): Observable<any> {
        this.log.trace("calling getGlAccounts:" + JSON.stringify(term));
        return this.http
            .get(this._apiroot.baseUrl + "payroll/glaccounts?term=" + term)
            .pipe(
                map((data: any) => (data && data.reference) || []),
                catchError((err) => {
                    return [];
                })
            );
    }

    //@LiquidCache('InvalidHBJobCodeCombinations')
    getInvalidHBJobCodeCombinations(): Observable<any> {
        this.log.trace("get list of invalid HBJobCode Combinations");
        return this.http
            .get(this._apiroot.baseUrl + "payroll/invalidHBJobCodeCombinations")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    //@LiquidCache('getJobCodes')
    getJobCodes(): Observable<any> {
        this.log.trace("get list of job codes");
        return this.http.get(this._apiroot.baseUrl + "payroll/jobcodes").pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    //@LiquidCache('getContractCodes')
    getContractCodes(): Observable<any> {
        this.log.trace("get list of contract codes");
        return this.http
            .get(this._apiroot.baseUrl + "payroll/contractCodes")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
    getEventTimesheet(eventTimesheetQuery): Observable<any> {
        this.log.trace("get list of event timesheet");
        return this.http
            .post(
                this._apiroot.baseUrl + "event/timesheets",
                eventTimesheetQuery
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
    getEventTimesheetDates(
        eventId,
        crewStartDate,
        crewEndDate
    ): Observable<any> {
        this.log.trace("get list of EventTimesheetDates");
        const eventQuery = {
            crewStartDate: crewStartDate,
            crewEndDate: crewEndDate,
        };
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "event/" +
                    eventId +
                    "/geteventtimesheettabs",
                eventQuery
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    savePayrollComment(eventId, timeCardDate, eventTimesheet): Observable<any> {
        this.log.trace("saving payroll comment");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "event/" +
                    eventId +
                    "/" +
                    timeCardDate +
                    "/payroll/comment",
                eventTimesheet
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventTimesheet(eventTimesheet): Observable<any> {
        return this.http
            .post(
                this._apiroot.baseUrl + "event/saveeventtimesheets",
                eventTimesheet
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
