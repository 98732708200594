import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "modal-contact-idm-result",
    templateUrl: "./modal-contact-idm-result.html",
})
export class ModalContactIdmComponent implements OnInit {
    constructor(public _modalRef: BsModalRef) {}
    //subject to transmit the results
    public onSync!: Subject<any>;
    public onCancelSync!: Subject<any>;

    contacts: any[] = [];
    selectedContact: any | undefined;
    hcmResultsSet: any = {};
    hcmContact: any = {};
    contactBeforeSync: any | undefined;

    closeModal() {
        this.onCancelSync.next(this.contactBeforeSync);
        this._modalRef.hide();
    }
    //Contact Radio button selection
    selectContact(selectedIndex) {
        this.selectedContact = this.contacts[selectedIndex];
        for (let contact = 0; contact < this.contacts.length; contact++) {
            if (selectedIndex !== contact) {
                this.contacts[contact].isSelected = "false";
            }
        }
    }
    //Method to sync selected contact
    syncContact(selectedContact) {
        if (this.selectedContact) {
            this.contactBeforeSync.sso = selectedContact.sso;

            this.onSync.next(this.contactBeforeSync);
            this._modalRef.hide();
        } else {
            this.onCancelSync.next(this.contactBeforeSync);
            this._modalRef.hide();
        }
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.onCancelSync = new Subject();
        this.contactBeforeSync = this.hcmContact;
        this.contacts = this.hcmResultsSet.contacts;
        for (let contact = 0; contact < this.contacts.length; contact++) {
            this.contacts[contact].isSelected = "false";
        }
    }
}
