import { Component, Input, OnInit } from '@angular/core'; 
import { NbcLoggerService } from '../logger/nbc-logger.service';

@Component({
  selector: "truncate-text",
  template: "<span placement='top' tooltip={{originalString}}>{{truncatedName}}</span>"
})

export class EventNameTruncateComponent implements OnInit {
  originalString: string | undefined;
  constructor(private log: NbcLoggerService) { }

  @Input() truncateString:string | undefined;
  @Input() truncateLimit:number | undefined;
  truncatedName:string | undefined;
  truncateText() {
    this.log.debug("Entered truncateText");
    var originalString = this.truncateString || "";
    var truncateString = this.truncateString || "";
    var truncateLimit = this.truncateLimit || 0;
    if (truncateString.length > truncateLimit) {
      this.log.debug("inside the condition");
        truncateString = truncateString.slice(0, truncateLimit) + ("...");
    }
    this.truncatedName = truncateString;
    this.originalString = originalString;
    this.log.debug("originalString: " + this.originalString);
    this.log.debug("originalString: " + this.truncatedName);
}
  ngOnInit() {
    this.log.debug("starting to truncate");
    this.truncateText();
  }
}
