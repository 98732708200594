<input [(ngModel)]="selectedTime"
       name="{{name}}"
       type="datetime"
       autocomplete="false"
       id="{{name}}"
       [typeahead]="timeslotObservable$"
       [typeaheadOptionsLimit]="8"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       placeholder="{{placeholder}}"
       [disabled]="nbcDisabled"
       [attr.required]="nbcRequired"
       (typeaheadOnBlur)="onManualEntry($event)"
       (typeaheadOnSelect)="onTimeSelected($event)"
       (blur)="OnBlur($event)"
       [typeaheadWaitMs]="200"
       class="{{nbcClass}}"
       style="height:100%;width:100%;"
       (focus)="selectFocusedText();">
