import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';

@Injectable({
  providedIn: 'root'
})
export class EventTypeService {
  constructor(
    private log: NGXLogger,
    private http: HttpClient,
    private _apiroot: BaseUrlHelperService
  ) {}

  /*
        * Generic method to return the collection of the tier network setup
        */

  searchByEventTypes(term): Observable<any> {
    this.log.trace('Get evevnt names for event: ' + term);
    return this.http
      .get(this._apiroot.baseUrl + 'eventTypes/searchByEventTypes/' + term)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getAllJobTitleDetails(eventTypeQuery): Observable<any> {
    eventTypeQuery = !eventTypeQuery ? {} : eventTypeQuery;
    this.log.trace('get list of event types');
    return this.http
      .post(this._apiroot.baseUrl + 'eventTypesWithQuery', eventTypeQuery)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
