<div class="container" style="background-color:#FFFFFF">

</div>
<div class="main-pane-content">
  <div class="row">
    <div class="col-md-12">
      <div class="text-right">
        <div class="form-group">
          <!--<button authenticate-user="contact.workschedule.create,ContactWorkSchedule.AddActivity.Create"
                  class="btn btn-primary"
                  (click)="openAddActivityModal()">
            &#043; Add Activity
          </button>-->
        </div>
      </div>
    </div>
  </div>

  <!-- calendar  -->
  <span (click)="getPreviousMonth()"><i class="fa fa-caret-left fa-lg cursor-pointer"></i>&nbsp;&nbsp;</span>
  <span (click)="getToday()"><strong class="cursor-pointer">Today</strong></span>
  <span (click)="getNextMonth()">&nbsp;&nbsp;<i class="fa fa-caret-right fa-lg cursor-pointer"></i></span>
  <full-calendar class="work-schl-cal" [options]="calendarOptions" #calendar></full-calendar>

  <div class="form-box form-box-sm" *ngFor="let activity of workSchedule | orderBy: 'startDate' ">
    <div class="row" authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.retrieve">

      <div class="btn-link col-md-4">
        <div class="work-schl-game"
             [hidden]="!activity.contactWorkHistory.eventName"
             authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.EventName.retrieve">
          <img *ngIf="activity.contactWorkHistory.divisionCode === 'REM'"
               src="/assets/image/season/{{activity.eventTypeImageId}}" class="work-schl-game-logo" />
          <!--<a href="/Events/Event/Summary/{{activity.contactWorkHistory.id}}">{{activity.contactWorkHistory.eventName}}</a>-->

          <a *ngIf="activity.contactWorkHistory.divisionCode === 'REM'" class="btn-link"
             class="nbc-router-link calendar-event-link" routerLink="/Events/Event/{{activity.contactWorkHistory.id}}" target="_blank">
            {{activity.contactWorkHistory.eventName}}
          </a>
          <a *ngIf="activity.contactWorkHistory.divisionCode === 'OLY'" class="btn-link"
             href="../../olympics/event/{{activity.contactWorkHistory.olyEventId}}/summary" target="_blank">
            {{activity.contactWorkHistory.eventName}}
          </a>
        </div>
        <span [hidden]="!!activity.contactWorkHistory.name">
          {{activity.title}}
        </span>
      </div>
      <div class="col-md-4" authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.EventDate.retrieve">
        <div class="work-schl-date">
          {{activity.startDate | date:"MMM dd  "}} - {{activity.endDate | date:"MMM dd  "}}

        </div>
      </div>
      <div class="col-md-4">
        <div authenticate-user="ContactWorkSchedule.Venue.Retrieve, contact.workschedule.retrieve">
          {{activity.contactWorkHistory?.venue?.name || activity?.location}}
        </div>
        <span *ngIf="activity.contactWorkHistory.venue"
              authenticate-user="ContactWorkSchedule.CityState.Retrieve, contact.workschedule.retrieve"
              class="work-schl-location">
          {{activity.contactWorkHistory.venue.city}},{{activity.contactWorkHistory.venue.state}}
        </span>
      </div>
    </div>
  </div>
</div>
