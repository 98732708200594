<div class="row">
  <div class="col-md-5" *ngIf="showCountry">
    <nbc-select *ngIf="countries"
                [source]="countries"
                [(model)]="country"
                display="name"
                value="isoCode"
                (modelChange)="setDialCode()"
                placeHolderDisplay="Select Country"
                [placeHolderValue]="-1"
                [nbcRequired]="false"
                defaultSelectClass="form-select text-truncate"
                [customErrorClass]="countryCode === '' && isSubmitted && nbcRequired === true ? 'border-red': ''"
                authenticate-user="contact.update,contact.hrmaster.update,ContactInformation.Update"
                authenticate-command="nbcselectdisabled">
    </nbc-select>
  </div>
  <div class="col-md-7">
    <div class="input-group" [ngClass]="{'has-error': !isValid && isSubmitted}">
      <label class="input-group-text m-0">{{dialCode}}</label>
      <input type="text"
             name="viewPhone1"
             class="form-control"
             maxlength="20"
             [(ngModel)]="phoneNumber"
             phoneFormatOnly
             [required]="nbcRequired"
             placeholder="Phone Number"
             (blur)="onBlur()"
             authenticate-user="contact.update,contact.hrmaster.update,ContactInformation.Update"
             authenticate-command="disabled">
    </div>
  </div>

  <span class="control-label control-label-error"
        *ngIf="!isPhoneNumberValid && phoneNumber!== ''">
    Phone number is Invalid
  </span>
  <span class="control-label control-label-error"
        *ngIf="phoneNumber === '' && isSubmitted && nbcRequired === true">
    Phone number is Required
  </span>
</div>
