<div class="main-pane-content">
  <!--Sumamry Graphic start-->
  <div class="form-group form-group-wrapper"
       authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve, Contact_Profile_Work_History.Retrieve">
    <div class="work-history-events-total">
      <span>{{model.totalRecords}}</span>
      Current and Future Events
    </div>
    <div class="clear"></div>
  </div>
  <!--Sumamry Graphic end-->
  <!--Pagination start-->
  <div class="view-options">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group pagination-container d-flex justify-content-end">
          <label class="pagination-results ms-auto" *ngIf="model && model?.items?.length!==0">
            <span *ngIf="model.pageSize * model.currentPage <= model.totalRecords">
              {{(model.pageSize < model.totalRecords) ? model.currentPage * model.pageSize : model.totalRecords}} of {{model.totalRecords}} Current and Future Events
            </span>
            <span *ngIf="model.pageSize * model.currentPage > model.totalRecords">
              {{model.totalRecords}} of {{model.totalRecords}} Current and Future Events
            </span>
          </label>
          <nbc-pagination totalItems="{{model.totalRecords}}"
                          itemsPerPage="{{model.pageSize}}"
                          [(currentPage)]="workScheduleQuery.currentPage"
                          (pageChanged)="getWorkSchedule()">
          </nbc-pagination>
        </div>
      </div>
    </div>
  </div>
  <!--Pagination Ends-->
  <div class="row-divider"></div>
  <!--Displaying events-->
  <ng-container *ngIf="model?.items">
    <div class="form-box form-box-sm" *ngFor="let activity of model.items">
      <div class="row" authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.retrieve">

        <div class="btn-link col-md-6">
          <div *ngIf="!!activity.contactWorkHistory.eventName"
               class="work-schl-game"
               authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.EventName.retrieve">
            <img *ngIf="activity.contactWorkHistory.divisionCode === 'REM'" src="/assets/image/season/{{activity.eventTypeImageId}}" class="work-schl-game-logo" />
            <a *ngIf="activity.contactWorkHistory.divisionCode === 'REM'"
               class="nbc-router-link btn-link"
               routerLink="/Events/Event/{{activity.contactWorkHistory.id}}"
               target="_blank">
              {{activity.contactWorkHistory.eventName}}
            </a>
            <a *ngIf="activity.contactWorkHistory.divisionCode === 'OLY'"
               href="/olympics/event/{{activity.contactWorkHistory.olyEventId}}/summary">
              {{activity.contactWorkHistory.eventName}}
            </a>
          </div>
          <span [hidden]="!!activity.contactWorkHistory.name">
            {{activity.title}}
          </span>
        </div>
        <div class="col-md-2" authenticate-user="contact.workschedule.retrieve,ContactWorkSchedule.EventDate.retrieve">
          <div class="work-schl-date">
            {{activity.startDate | date:"MMM dd  "}} - {{activity.endDate | date:"MMM dd  "}}

          </div>
        </div>
        <div class="col-md-2">
          <div authenticate-user="ContactWorkSchedule.Venue.Retrieve, contact.workschedule.retrieve">
            {{activity.contactWorkHistory?.venue?.name || activity?.location}}
          </div>
          <span *ngIf="activity.contactWorkHistory.venue"
                authenticate-user="ContactWorkSchedule.CityState.Retrieve, contact.workschedule.retrieve"
                class="work-schl-location">
            {{activity.contactWorkHistory?.venue?.city}},{{activity.contactWorkHistory?.venue?.state}}
          </span>
        </div>

        <div class="col-md-2">
          <div authenticate-user="contact.workschedule.retrieve">{{activity.technicalManager}} </div>
        </div>

      </div>
    </div>
  </ng-container>
</div>
