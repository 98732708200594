import { OnChanges } from "@angular/core";
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { EventService } from "../../Services/event.service";
import { SharedDataService } from "../../sharedData.service";

@Component({
    selector: "app-nbc-tab-display",
    templateUrl: "./nbc-tab-display.component.html",
    //styleUrls: ["../../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.None,
})
export class NbcTabDisplayComponent implements OnInit, OnChanges {
    constructor(private eventService: SharedDataService) {}
    pageNumber: any;
    @Output() newItemEventData = new EventEmitter<any>();
    @Input() tabSource: any[] = [];
    @Input() tabDefault: any = {};
    @Input() tabHeading = "date";
    @Input() tabLength: any = 7;
    @Input() isTabPanelEnabled = true;

    @Output() isTabPanelEnabledChange: EventEmitter<any> =
        new EventEmitter<any>();
    lastPageNumber: any;

    displayTabs: any[] | undefined;
    goBackEnabled = false;
    goForwardEnabled = false;
    currentTabIndex!: any;
    currentTab!: any;
    showTabs = false;

    tabClick(item: any) {
        this.currentTabIndex = _.findIndex(this.tabSource, { date: item.date });
        this.currentTab = item;
        this.newItemEventData.emit(item);
    }

    filterTabDisplay() {
        _.forEach(this.tabSource, function (value, key) {
            value.active = false;
        });
    }

    nextTabSet() {
        this.pageNumber++;

        this.filterTabDisplay();
        this.getDisplayTabs();
    }
    prevTabSet() {
        this.pageNumber--;

        this.filterTabDisplay();
        this.getDisplayTabs();
    }

    getDisplayTabs() {
        if (this.tabSource && this.tabSource.length > this.tabLength) {
            const displayTabsSource = _.cloneDeep(this.tabSource);

            let start = this.pageNumber * this.tabLength;
            let end =
                start + this.tabLength > this.tabSource.length
                    ? this.tabSource.length
                    : start + this.tabLength;
            if (end - start < this.tabLength) {
                start = this.tabSource.length - this.tabLength;
                end = start + this.tabLength;
            }
            this.displayTabs = [];
            this.displayTabs = displayTabsSource.slice(start, end);
            this.goForwardEnabled = true;
        } else {
            this.displayTabs = _.cloneDeep(this.tabSource);
        }
        if (this.displayTabs && this.displayTabs.length > 0) {
            this.tabDefault = this.currentTabIndex
                ? _.find(this.displayTabs, { date: this.currentTab.date })
                : this.pageNumber === 0
                  ? this.displayTabs[0]
                  : null;
            if (this.tabDefault) {
                this.tabDefault.active = true;
                this.tabClick(this.tabDefault);
            }
        }
        setTimeout(() => {
            this.showTabs = true;
        }, 200);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "tabSource") {
                this.InitTabs();
            }
            if (propName === "isTabPanelEnabled") {
                const current = changes["isTabPanelEnabled"]["currentValue"];
                _.each(this.displayTabs, (tab) => {
                    tab.disabled = !current;
                });
            }
        }
    }
    InitTabs() {
        this.showTabs = false;
        this.tabDefault = this.currentTab
            ? _.find(this.tabSource, { date: this.currentTab.date })
            : this.tabSource[0];
        //  this.tablength = "7"
        this.tabHeading = "date";

        if (this.tabSource) {
            if (this.tabDefault) {
                _.forEach(this.tabSource, (value, key: any) => {
                    if (
                        this.tabDefault[this.tabHeading].toString() ===
                        value[this.tabHeading].toString()
                    ) {
                        this.pageNumber = Math.floor(key / this.tabLength);
                    }
                });
            }

            this.lastPageNumber = Math.floor(
                this.tabSource.length / this.tabLength
            );
            this.filterTabDisplay();
            this.getDisplayTabs();
        }
    }

    ngOnInit(): void {
        // this.InitTabs();
    }
}
