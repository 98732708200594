
<header id="col-main-hdr">
  <h1><i class="fa fa-money"></i> Payroll</h1>
</header>
<nav class="breadcrumb">
  <ul>
    <li home></li>
    <li>/</li>
    <li>Payroll</li>
  </ul>
</nav> 
<div class="page-container">
  <nbc-alert></nbc-alert>
  <div class="container-fluid"> 
    <tabset class="content-tabs contact-tabs-lg"  #tabset (contextmenu)="associateUrl()">
      <tab *ngFor="let t of tabs"
           [heading]="t.heading"
           (selectTab)="go(t.route)"
           (contextmenu)="showMessage($event)"
           [active]="t.active">
      </tab>
    </tabset>
    <router-outlet></router-outlet>
  </div>

</div>


