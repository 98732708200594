<div>
  <header id="col-main-hdr">
    <h1>New {{eventType.name}} Event</h1>
  </header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li>
        <a routerLink="/Events/CreateSeasonOrEvent" title="Create a Season or Event">Create a Season or Event</a>
      </li>
      <li>/</li>
      <li>New {{eventType.name}} Event</li>
    </ul>
  </nav>
  <div class="page-container">
    <div class="container-fluid">
      <div class="page-content">
        <form name="createEventForm"
              #createEventForm="ngForm"
              novalidate>
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-12">
                  <nbc-alert></nbc-alert>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box">
                    <h4>Event Info</h4>
                    <div class="row">
                      <div class="col-md-8 col-sm-8"
                           authenticate-user="event.update">
                        <div class="form-inline text-left form-group">
                          <div class="form-group">
                            <label style="padding: 0px;" class="checkbox-inline">
                              <input type="radio"
                                     name="isLive"
                                     class="inline"
                                     [(ngModel)]="model.isTaped"
                                     [value]="false" required /> Live Broadcast
                            </label>
                          </div>

                          <div class="form-group">
                            <label class="checkbox-inline">
                              <input type="radio"
                                     name="isTaped"
                                     class="inline"
                                     [(ngModel)]="model.isTaped"
                                     [value]="true" required /> Taped
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-sm-12"
                           authenticate-user="event.update">
                        <div class="form-inline text-left form-group">
                          <div class="form-group">
                            <label style="cursor:pointer;">
                              Mobile Timekeeping: <input name="isMobileTimekeepingChecked" style="cursor:pointer;"
                                                         class="checkbox"
                                                         type="checkbox"
                                                         [(ngModel)]="model.isMobileTimekeepingChecked">
                            </label>
                            <div *ngIf="model.isMobileTimekeepingChecked" style="padding-left:15px;"
                           class="form-group">
                              <nbc-dropdown *ngIf="model.isMobileTimekeepingChecked"
                                            [source]="TimekeepingReminders"
                                            name="ddlTimekeepingReminders"
                                            [(model)]="model.timekeepingReminder"
                                            display="desc"
                                            value="id"
                                            nbcSize="w-100"
                                            placeholder="Select Timekeeping Reminder">
                              </nbc-dropdown>
                            </div>
                          </div>
                          
                        </div>
                        
                        
                      </div>
                     
                        
                    </div>
                    <div class="row"
                         *ngIf="eventType.hasTeam"
                         [ngClass]="{'has-error': submitted && ((model.homeTeam === model.awayTeam) || (eventType.hasTeam && (model.awayTeamId === '' || model.homeTeamId === '')))}">
                      <div class="col-md-6 col-sm-6 position-relative">
                        <div class="form-group form-group-wrapper">
                          <ng-template #customItemTemplate
                                       let-model="item"
                                       let-index="index">
                            <span>
                              <img src="/assets/image/team/{{model.imageId}}"
                                   width="16" />
                              <span style="padding-left:10px;">{{model.name}}</span>
                            </span>
                          </ng-template>

                          <input [(ngModel)]="awayTeamSearchTerm"
                                 #awayTeam="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="awayTeam"
                                 placeholder="Away Team"
                                 required
                                 class="form-control"
                                 [isAnimated]="true"
                                 [typeaheadWaitMs]="200"
                                 [typeahead]="AwayTeamObservable$"
                                 [typeaheadItemTemplate]="customItemTemplate"
                                 [typeaheadAsync]="true"
                                 typeaheadOptionField="name"
                                 [typeaheadOptionsLimit]="50"
                                 (typeaheadOnSelect)="onAwayTeamSelect($event)">
                          <span *ngIf="homeTeam?.dirty && awayTeam?.dirty && isEqualTeam(model) && model.awayTeam && model.homeTeam"
                                class="control-label control-label-error">Home Team and Away Team cannot be the same.</span>
                          <span *ngIf="submitted && eventType.hasTeam && !model.awayTeam"
                                class="control-label control-label-error">Away Team cannot be empty.</span>
                        </div>

                        <div class="form-control-compare form-control-at">&#64;</div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group form-group-wrapper">
                          <input [(ngModel)]="homeTeamSearchTerm"
                                 #homeTeam="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="homeTeam"
                                 autocomplete="off"
                                 placeholder="Home Team"
                                 required
                                 [typeaheadItemTemplate]="customItemTemplate"
                                 class="form-control"
                                 [isAnimated]="true"
                                 [typeaheadWaitMs]="200"
                                 [typeahead]=" HomeTeamObservable$"
                                 [typeaheadAsync]="true"
                                 typeaheadOptionField="name"
                                 [typeaheadOptionsLimit]="50"
                                 (typeaheadOnSelect)="onHomeTeamSelect($event)">
                          <span *ngIf="submitted && eventType.hasTeam && !model.homeTeam"
                                class="control-label control-label-error">Home Team cannot be empty.</span>
                        </div>
                      </div>
                      </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group"
                             [ngClass]="{'has-error':eventName?.invalid && submitted}">

                          <input type="text"
                                 #eventName="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="eventName"
                                 class="form-control"
                                 required
                                 placeholder="Event Name"
                                 [(ngModel)]="model.eventName"
                                 maxlength="100"
                                 autocomplete="off"/>

                          <span *ngIf="eventName?.invalid && submitted"
                                class="control-label control-label-error">Please enter an Event Name.</span>
                        </div>

                        <div class="form-group"
                             [ngClass]="{'has-error':budgetCode?.invalid && submitted}">
                          <div class="input-group">
                            <input type="text"
                                   name="budgetCode"
                                   #budgetCode="ngModel"
                                   class="form-control"
                                   authenticate-required="true"
                                   (click)="openSearchModal()"
                                   readonly="readonly"
                                   required
                                   placeholder="Budget / Show Code"
                                   [(ngModel)]="model.budgetCode"
                                   maxlength="250"
                                   authenticate-user="event.wbsecode.create"
                                   authenticate-command="disabled">
                            <span class="input-group-btn">
                              <button class="btn btn-white"
                                      type="button"
                                      (click)="openSearchModal()"
                                      authenticate-user="event.wbsecode.create"
                                      authenticate-command="disabled">
                                <i class="fa fa-search"></i>
                              </button>
                            </span>
                          </div>

                          <span *ngIf="model.budgetCode=='' && submitted"
                                class="control-label control-label-error">Please select a Budget Code.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group"
                             [ngClass]="{'has-error': model.partOfSeason === true && model.seasonId === ''  && submitted}">
                          <label>
                            <input name="chkPartOfSeason"
                                   type="checkbox"
                                   [(ngModel)]="model.partOfSeason"
                                   (change)="setSeasonId();checkOutOfSeasonRangeBroadcastDates();" />
                            This event is part of a season.
                          </label>
                        </div>
                        <div *ngIf="model.partOfSeason"
                             class="form-group"
                             [ngClass]="{'has-error': submitted && model.partOfSeason === true && (!model.seasonId || model.seasonId === 'Select Season' || model.seasonId === '' || model.seasonId === 0 )}">
                            <nbc-select [source]="seasons"
                                        name="ddlseasonId"
                                        [(model)]="model.seasonId"
                                        display="name"
                                        value="id"
                                        [nbcRequired]="true"
                                        placeHolderValue="-1"
                                        placeHolderDisplay="Select Season"
                                        (modelChange)="onPartSeasonChange($event)"
                                        [nbcDisabled]="seasons?.length === 0"></nbc-select>
                          <span class="control-label control-label-error"
                                *ngIf="submitted && model.partOfSeason === true && (!model.seasonId || model.seasonId === 'Select Season' || model.seasonId === '' || model.seasonId === 0 )">Please select a season.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                          <label>
                            Facility
                          </label>
                          <input type="text"
                                 #facility="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="facility"
                                 class="form-control"
                                 placeholder="Facility"
                                 [(ngModel)]="model.facility"
                                 maxlength="150"
                                 autocomplete="off" />
                        </div>
                      </div>
                      </div>
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="form-group"
                             [ngClass]="{'has-error':!isEventLocationValid && submitted}">
                          <label>
                            Event Location
                          </label>
                          <nbc-dropdown [source]="EventLocations"
                                        name="eventLocation"
                                        [(model)]="model.eventLocation"
                                        (nbcOnOptionSelect)="getEventLocationChange();"
                                        display="desc"
                                        [nbcRequired]="true"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select Event Location">
                          </nbc-dropdown>
                          <span *ngIf="!isEventLocationValid && submitted"
                                class="control-label control-label-error">Please select a Event Location.</span>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box">
                    <h4>Venue</h4>
                    <div class="form-group"
                         [ngClass]="{'has-error': venue?.invalid && submitted}">
                      <input [(ngModel)]="venueSearchQueryTypeahead"
                             #venue="ngModel"
                             name="venue"
                             autocomplete="off"
                             placeholder="Select Venue"
                             required
                             class="form-control"
                             [isAnimated]="true"
                             [typeaheadWaitMs]="200"
                             [typeahead]=" VenueNameObservable$"
                             [typeaheadAsync]="true"
                             typeaheadOptionField="displayText"
                             [typeaheadOptionsLimit]="50"
                             [typeaheadMinLength]="1"
                             (typeaheadOnSelect)="onVenueSelect($event)">
                      <span *ngIf="venue?.invalid && submitted"
                            class="control-label control-label-error">Please select a venue.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box">
                    <h4>Crew Location</h4>
                    <span>Defaults locations Onsite, @Home, Stamford will be automatically added.</span>
                    <br />
                    <div class="form-group"
                         *ngFor="let loc of model.additionalLocations;let locationindex=index">
                      <!--[ngClass]="{'has-error':eventUpdate.venue.$error && submitted}"-->
                      <div class="row">
                        <span class="col-md-6">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="locations{{locationindex}}"
                                 class="form-control"
                                 [(ngModel)]="loc.locationName"
                                 maxlength="25"
                                 autocomplete="off"
                                 authenticate-required="true" />
                        </span>
                        <span class="col-md-2" style="line-height:30px;">
                          <span class="blueCloseCircle" (click)="removeLocation(locationindex)">
                            <i class="fa fa-times-circle"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <a (click)="addLocation()"
                         class="btn-link cursor-pointer"><i class="fa fa-plus-square"></i> Add another location</a>
                      <span *ngIf="blankLocation && submitted"
                            class="control-label control-label-error">Locations must not be blank or any of the following locations Onsite, @Home, Stamford.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div *ngIf="eventType.hasFlex"
                     class="col-md-6 col-sm-6">
                  <div class="form-box">
                    <h4>Flex</h4>
                    <div class="row">
                      <div class="col-sm-12 d-flex">
                        <label class="btn btn-white"
                               ngDefaultControl
                               name="flexY"
                               [(ngModel)]="model.flex"
                               style="margin:0 !important;"
                               btnRadio="Y"
                               tabindex="0"
                               uncheckable>Flex</label>

                        <label class="btn btn-white"
                               ngDefaultControl
                               name="flexN"
                               [(ngModel)]="model.flex"
                               btnRadio="N"
                               tabindex="0"
                               style="margin:0 !important;"
                               uncheckable>No Flex</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div [ngClass]="eventType.hasFlex ? 'col-sm-6 col-md-6' : 'col-md-12 col-sm-6'">
                  <div class="form-box">
                    <h4>Level</h4>
                    <div class="row">
                      <div class="col-sm-12 d-flex">
                        <button type="button"
                                name="templateLevel"
                                class="btn btn-white"
                                style="margin:0 !important;"
                                [(ngModel)]="model.level"
                                [btnRadio]="1"
                                tabindex="0"
                                role="button"
                                uncheckable>
                          1
                        </button>
                        <button type="button"
                                name="templateLevel"
                                class="btn btn-white"
                                style="margin:0 !important;"
                                [(ngModel)]="model.level"
                                [btnRadio]="2"
                                tabindex="0"
                                role="button"
                                uncheckable>
                          2
                        </button>
                        <button type="button"
                                name="templateLevel"
                                class="btn btn-white"
                                style="margin:0 !important;"
                                [(ngModel)]="model.level"
                                [btnRadio]="3"
                                tabindex="0"
                                role="button"
                                uncheckable>
                          3
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box"
                       id="event-broadcast-date">
                    <div class="row">
                      <div class="col-md-12 d-flex">
                        <div class="col-md-6">
                          <b>Event Date / Time / Network</b>
                        </div>
                        <div class="col-md-6">
                          <b class="float-start"
                             [hidden]="isInitialLoad">
                            <span *ngIf="currentTierStatus">Tier {{currentTierStatus}}</span>
                            <span *ngIf="!currentTierStatus">Non-Tiered</span>
                          </b>
                        </div>
                      </div>
                    </div>

                    <form #broadcastDateForm="ngForm"
                          name="broadcastDateForm">
                      <div class="form-inline form-group-wrapper form-group"
                           [ngClass]="{'has-error':broadcastDateForm?.invalid && submitted ||  (model.broadcastAirDates[index].startTime > model.broadcastAirDates[index].endTime) && submitted}"
                           *ngFor="let broadcastDate of model.broadcastAirDates; let index = index; let first =first">
                        <div class="form-group">
                          <div class="input-group input-group-set d-flex">
                            <span class="input-group-text">
                              <i class="fa fa-calendar"></i>
                            </span>
                            <input type="text"
                                   placeholder="Date"
                                   class="form-control"
                                   #airDate="ngModel"
                                   name="airDate{{index}}"
                                   ngModel
                                   readonly
                                   bsDatepicker
                                   required
                                   [minDate]="calendar_broadcast_minDate"
                                   [bsValue]="bsInlineDateValue"
                                   #drp="bsDatepicker"
                                   (bsValueChange)="SetModelValueAirDate(index,$event);broadcastDateFactory(index,model.broadcastAirDates[index], model.crewWorkStartDate, model.crewWorkEndDate);getMinAirDate();checkOutOfSeasonRange(index);findPrimaryBroadcastingDate();"
                                   (click)="onDateChangeStarted();"
                                   (onHidden)="onCalenderhidden('onHidden')"
                                   [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}"
                                   autocomplete="off" />
                          </div>
                        </div>
                        <div class="form-group">
                          <span class="form-control-txt">at</span>
                        </div>
                        <div class="form-group">
                          <div class="input-group input-group-time"
                               [ngClass]="{'has-error':broadcastDateForm?.invalid && submitted || model.broadcastAirDates[index].startTime > model.broadcastAirDates[index].endTime && submitted}">
                            <nbc-date-time id="starttime{{index}}"
                                           name="starttime{{index}}"
                                           type="hours"
                                           format="24hr"
                                           placeholder="Start Time"
                                           [defaultDate]="defaultDate"
                                           (modelChange)="checkOutOfSeasonRange(index); checkBroadcastRange(model.broadcastAirDates[index]);"
                                           [(model)]="model.broadcastAirDates[index].startTime"
                                           [nbcRequired]="true"
                                           [nbcDatesNavigationEnabled]="false">
                            </nbc-date-time>
                          </div>
                        </div>
                        <div class="form-group">
                          <span>-</span>
                        </div>
                        <div class="form-group">
                          <div class="input-group input-group-time"
                               [ngClass]="{'has-error':broadcastDateForm?.$invalid && submitted || model.broadcastAirDates[index].startTime > model.broadcastAirDates[index].endTime && submitted}">
                            <nbc-date-time id="endtime{{index}}"
                                           name="endtime{{index}}"
                                           type="hours"
                                           format="24hr"
                                           placeholder="End Time"
                                           (modelChange)="checkOutOfSeasonRange(index);checkBroadcastRange(model.broadcastAirDates[index]);"
                                           defaultDate="{{defaultDate}}"
                                           [(model)]="model.broadcastAirDates[index].endTime"
                                           [nbcRequired]="true"
                                           [nbcDatesNavigationEnabled]="false">
                            </nbc-date-time>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-group">
                            <label class="form-control-txt">
                              <span>
                                Local Time {{eventTimeZone ? '(' + eventTimeZone +')' : ''}} on
                              </span>
                            </label>
                          </div>
                          <div class="form-group" *ngIf="networks?.length>0">
                            <nbc-dropdown [source]="getNetworks(index+1)"
                                          (nbcOnOptionSelect)="getNetworkGroupSetup($event,model.broadcastAirDates[index].networkId,index+1,oldValue);findPrimaryBroadcastingDate();"
                                          name="networkId{{index}}"
                                          [(model)]="model.broadcastAirDates[index].networkId"
                                          display="description"
                                          value="id"
                                          nbcSize="w-100"
                                          placeholder="Network"
                                          [nbcRequired]="true">
                            </nbc-dropdown>
                          </div>
                        </div>
                        <div class="form-group"
                             *ngIf="false">
                          <div class="form-control-txt">
                            <a (click)="openCompassSearchModal()"
                               class="cursor-pointer"> search in COMPASS</a>
                          </div>
                        </div>
                        <div class="form-group float-end"
                             *ngIf="index!==(PRIMARY_DATE_INDEX) && !broadCastdateBeingChanged">
                          <div class="btn btn-icon-clear"
                               (click)="removeBroadcastDate(index);findPrimaryBroadcastingDate();">
                            <i class="fa fa-times-circle"></i>
                          </div>
                        </div>

                        <span *ngIf="model.broadcastAirDates[index].airDate=='' && submitted"
                              class="control-label control-label-error">
                          Please select an Date and network.
                        </span>
                        <span *ngIf="model.broadcastAirDates[index].networkId=='' && submitted"
                              class="control-label control-label-error">
                          Please select a Network.
                        </span>
                        <span class="control-label control-label-error"
                              *ngIf="model.broadcastAirDates[index].outOfSeasonRange === true">
                          The selected date is outside the season date range.
                        </span>
                        <span class="control-label control-label-error"
                              *ngIf="model.broadcastAirDates[index].startTime > model.broadcastAirDates[index].endTime && submitted ">
                          The selected broadcast start time cannot be later than the selected end time.
                        </span>
                      </div>
                    </form>
                    <div>
                      <a (click)="addBroadcastDate()"
                         class="cursor-pointer btn-link">
                        <i class="fa fa-plus-square"></i> Add another date
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box">
                    <h4>Crew Work Schedule</h4>
                    <div class="form-group"
                         [ngClass]="{'has-error':(crewWorkSchedule?.errors?.required && submitted) || (!isBroadcastDateSet && submitDetectBroadcastDateSet) || (difference > 39 && submitted)}">
                      <div style="width:50%"
                           class="input-group input-group-set dropdown"
                           dropdown
                           (click)="setMinMaxBroadcastDate()">
                        <span class="input-group-text"
                              dropdown-toggle><i class="fa fa-calendar"></i></span>

                        <input type="text"
                               [disabled]="model.broadcastAirDates[0].airDate === ''"
                               name="crewWorkSchedule"
                               #crewWorkSchedule
                               ngModel
                               placeholder="Select Date Range"
                               class="form-control"
                               readonly
                               required
                               bsDaterangepicker
                               [bsValue]="bsInlineRangeValue"
                               #drp="bsDaterangepicker"
                               (bsValueChange)="openDatepicker($event,createEventForm);validateDateRange()"
                               [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}" />
                      </div>

                      <span *ngIf="( createEventForm?.controls?.crewWorkSchedule?.value.length==0 && submitted)"
                            class="control-label control-label-error">
                        Please select a Crew Schedule date range.
                      </span>
                      <span *ngIf="!isBroadcastDateSet && submitDetectBroadcastDateSet === true"
                            class="control-label control-label-error">
                        Please add an event date.
                      </span>
                      <span *ngIf="difference > 39 && submitted"
                            class="control-label control-label-error">
                        Crew Work Schedule cannot exceed 40 days.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box"
                       id="event-managers">
                    <h4>Event Managers</h4>
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Homebase PM</label>
                          <nbc-dropdown [source]="eventManagers.homeBase.values"
                                        name="ddlHomeBasePM"
                                        [(model)]="model.homeBaseManagerId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select HB PM">
                          </nbc-dropdown>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Man Power</label>
                          <nbc-dropdown [source]="eventManagers.manPower.values"
                                        name="ddlmanPowerManagerId"
                                        [(model)]="model.manPowerManagerId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select Man Power">
                          </nbc-dropdown>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Technical Manager</label>
                          <nbc-dropdown [source]="eventManagers.technical.values"
                                        name="ddltechnicalManagerId"
                                        [(model)]="model.technicalManagerId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select TM">
                          </nbc-dropdown>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Onsite Production Manager</label>
                          <nbc-dropdown [source]="eventManagers.onSite.values"
                                        name="ddlonsiteProductionManagerId"
                                        [(model)]="model.onsiteProductionManagerId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select OPM">
                          </nbc-dropdown>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Tech Manager</label>
                          <nbc-dropdown [source]="eventManagers.technical.values"
                                        name="ddltechnicalManagerId2"
                                        [(model)]="model.technicalManagerId2"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select TM">
                          </nbc-dropdown>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                          <label>Onsite Prod Manager</label>
                          <nbc-dropdown [source]="eventManagers.onSite.values"
                                        name="ddlonsiteProductionManagerId2"
                                        [(model)]="model.onsiteProductionManagerId2"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="Select OPM">
                          </nbc-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row"
                   authenticate-user="financial.budgetforecast.retrieve">
                <div class="col-md-12">
                  <div class="form-box"
                       authenticate-user="event.update">
                    <h4>Financials <span class="label-note">(Optional)</span></h4>
                    <div class="row">
                      <div class="col-sm-6 col-xs-12">
                        <div class="row">
                          <div class="col-md-12">
                            <label>Average Airfare</label>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-text">Talent $</div>
                                    <input type="text"
                                           #laborBudget="ngModel"
                                           name="talentAirFare"
                                           class="form-control"
                                           placeholder="Talent Air Fare"
                                           [(ngModel)]="model.financials.talentAirFare"
                                           authenticate-user="financial.budgetforecast.update"
                                           authenticate-command="disabled"
                                           nbc-decimal
                                           decimals="3"
                                           maxlength="12"
                                           autocomplete="off" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-text">Production $</div>
                                    <input type="text"
                                           #laborBudget="ngModel"
                                           name="productionAirFare"
                                           class="form-control"
                                           placeholder="Production Air Fare"
                                           [(ngModel)]="model.financials.productionAirFare"
                                           authenticate-user="financial.budgetforecast.update"
                                           authenticate-command="disabled"
                                           nbc-decimal
                                           decimals="3"
                                           maxlength="12"
                                           autocomplete="off" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-text">Engineers $</div>
                                    <input type="text"
                                           #laborBudget="ngModel"
                                           name="engineersAirFare"
                                           class="form-control"
                                           placeholder="Engineers Air Fare"
                                           [(ngModel)]="model.financials.engineersAirFare"
                                           authenticate-user="financial.budgetforecast.update"
                                           authenticate-command="disabled"
                                           nbc-decimal
                                           decimals="3"
                                           maxlength="12"
                                           autocomplete="off" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <label>Average Hotel Nightly Cost</label>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-text">Production $</div>
                                    <input type="text"
                                           #laborBudget="ngModel"
                                           name="productionHotelFare"
                                           class="form-control"
                                           placeholder="Production Hotel Fare"
                                           [(ngModel)]="model.financials.productionHotelFare"
                                           authenticate-user="financial.budgetforecast.update"
                                           authenticate-command="disabled"
                                           nbc-decimal
                                           decimals="3"
                                           maxlength="12"
                                           autocomplete="off" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-text">Engineers $</div>
                                    <input type="text"
                                           #laborBudget="ngModel"
                                           name="engineersHotelFare"
                                           class="form-control"
                                           placeholder="Engineers Hotel Fare"
                                           [(ngModel)]="model.financials.engineersHotelFare"
                                           authenticate-user="financial.budgetforecast.update"
                                           authenticate-command="disabled"
                                           nbc-decimal
                                           decimals="3"
                                           maxlength="12"
                                           autocomplete="off" />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>Hours Budgeted / Day</label>
                        <div class="row" *ngFor="let budgetHours of model.financials.budgetedHours | orderBy:'broadCastDate'">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">{{budgetHours.broadCastDate  | date:"EEE MM/dd/yyyy"}}</div>
                                <input type="text"
                                       #laborBudget="ngModel"
                                       appInputRestriction="numberOnly"
                                       name="budgetedhours"
                                       class="form-control"
                                       placeholder="Budgeted Hours"
                                       [(ngModel)]="budgetHours.hours"
                                       maxlength="2"
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       numbers-only
                                      autocomplete="off" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" *ngIf="eventPositionTemplates?.length>0">
                  <div class="form-box"
                       id="event-position-template">
                    <h4>Event Position Template <span class="label-note">(Optional)</span></h4>
                    <nbc-select [source]="eventPositionTemplates"
                                [(model)]="model.eventPositionTemplate"
                                display="description"
                                value="id"
                                name="ddlTemplates"
                                placeHolderValue="-1"
                                authenticate-user="event.update"
                                authenticate-command="nbcselectdisabled"
                                placeHolderDisplay="Select Event Position Template"></nbc-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-box"
                       id="notes">
                    <h4>Notes <span class="label-note">(Optional)</span></h4>
                    <textarea class="form-control"
                              name="note"
                              [(ngModel)]="model.note"
                              rows="3"
                              appInputRestriction="restrictChars"
                              maxlength="250"></textarea>
                  </div>
                </div>
              </div>
              <button type="submit"
                      class="btn btn-primary"
                      id="btnCreateEvent"
                      (click)="createEvent(createEventForm.valid,createEventForm) "
                      authenticate-user="event.create">
                Create Event
              </button>
              <a routerLink="/Events/Eventlist"
                 class="btn btn-default">Cancel</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
