import { OnInit, Component, OnDestroy } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "pay-transation-component",
    templateUrl: "./model-pay-transaction.component.html",
    //styles: ["tr {  transform: scale(1) !important;} td { position: absolute !important;top:0 !important;right:0 !important;}"]

    //styleUrls: [
    //  "../../../../../assets/Content/app/areas/payroll/payroll.less"],
})
export class PayTransactionComponent implements OnInit, OnDestroy {
    constructor(
        private activatedRoutes: ActivatedRoute,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    public onSync!: Subject<any>;
    public onCancel!: Subject<any>;
    items: any = undefined;
    payTransactions: any[] = [];
    submitted = false;
    transactionShell: any = {
        hrUnit: null,
        rate: "",
        transactionType: null,
    };
    payrollDefaultsData: any[] = [
        {
            employeeType: "GOLFIATSE",
            defaultContract: "IATSE GOLF",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 8,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFIATVL",
            defaultContract: "IATSE GOLF",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 8,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFIATVL",
            defaultContract: "IATSE GOLF",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 8,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 48 },
                { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 6 },
            ],
        },
        {
            employeeType: "GOLFPEPTVL",
            defaultContract: "PEP GOLF FED",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 8,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 52 },
               /* { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 6 },*/
            ],
        },
        {
            employeeType: "GOLFPEPTVL",
            defaultContract: "PEP GOLF FED",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEPTVL",
            defaultContract: "PEP GOLF CA",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 8,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 52 },
                /*{ description: "PERDIEMT", hrUnit: 1, id: 180, rate: 6 },*/
            ],
        },
        {
            employeeType: "GOLFPEPTVL",
            defaultContract: "PEP GOLF CA",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "PEP",
            defaultContract: "SPORTS FREELANCE FED",
            checkTravel: false,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "PEP",
            defaultContract: "SPORTS FREELANCE CA",
            checkTravel: false,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "PEPTRVL",
            defaultContract: "SPORTS FREELANCE FED",
            checkTravel: false,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "PEPTRVL",
            defaultContract: "SPORTS FREELANCE CA",
            checkTravel: false,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF FED",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: true,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF CA",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: true,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "TRAVEL", hrUnit: 8, id: 134, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF FED",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: true,
            hasFlat: true,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF CA",
            checkTravel: true,
            hasTravelActivity: true,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: true,
            hasFlat: true,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF FED",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "GOLFPEP",
            defaultContract: "PEP GOLF CA",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATDAILY", hrUnit: 10, id: 8693, rate: 0 },
            ],
        },
        {
            employeeType: "DGA",
            defaultContract: "DGA AD_SM_FRL",
            checkTravel: false,
            hasTravelActivity: false,
            hasDOOTActivity: false,
            checkDOOT: false,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "DAILY",
            transactions: [
                { description: "FLATPAY-G", hrUnit: 10, id: 2195, rate: 0 },
            ],
        },
        {
            employeeType: "DH",
            defaultContract: "NABET A DLY_DLY180 5X8",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: true,
            checkDOOT: true,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "DOOT", hrUnit: 8, id: 108, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 47 },
                { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 12 },
            ],
        },
        {
            employeeType: "DHA",
            defaultContract: "NABET A DLY_DLY180 5X8",
            checkTravel: true,
            hasTravelActivity: false,
            checkDOOT: true,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "DOOT", hrUnit: 8, id: 108, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 47 },
                { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 12 },
            ],
        },
        {
            employeeType: "DHR",
            defaultContract: "NABET A DLY_DLY180 5X8",
            checkTravel: true,
            hasTravelActivity: false,
            checkDOOT: true,
            hasDOOTActivity: true,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "DOOT", hrUnit: 8, id: 108, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 47 },
                { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 12 },
            ],
        },
        {
            employeeType: "FLR",
            defaultContract: "SPORTS FREELANCE FED",
            checkTravel: true,
            hasTravelActivity: false,
            hasDOOTActivity: true,
            checkDOOT: true,
            checkFlat: false,
            hasFlat: false,
            hrUnit: 10,
            hrUnitSource: "DEFAULT",
            rate: "HOURLY",
            transactions: [
                { description: "DOOT", hrUnit: 8, id: 108, rate: 0 },
                { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 61 },
               /* { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 12 },*/
            ],
        },
        {
          employeeType: "FLR",
          defaultContract: "SPORTS FREELANCE CA",
          checkTravel: true,
          hasTravelActivity: false,
          hasDOOTActivity: true,
          checkDOOT: true,
          checkFlat: false,
          hasFlat: false,
          hrUnit: 10,
          hrUnitSource: "DEFAULT",
          rate: "HOURLY",
          transactions: [
            { description: "DOOT", hrUnit: 8, id: 108, rate: 0 },
            { description: "PERDIEMN", hrUnit: 1, id: 179, rate: 61 },
            /* { description: "PERDIEMT", hrUnit: 1, id: 180, rate: 12 },*/
          ],
        },
    ];

    checkTransaction() {
        if (this.payTransactions.length === 0) {
            this.getPayTransaction_new();
        }
    }

    reorderTransactions() {
        if (this.items.contractCode.transactionTypes) {
            const flat = _.find(
                this.items.contractCode.transactionTypes,
                function (x) {
                    return x.description === "FLATDAILY";
                }
            );
            if (flat) {
                const exceptFlat = _.filter(
                    this.items.contractCode.transactionTypes,
                    function (x) {
                        return x.description !== "FLATDAILY";
                    }
                );
                exceptFlat.unshift(flat);
                this.items.contractCode.transactionTypes = exceptFlat;
            }
        }
    }

    getPayTransaction_new() {
        let newPayTransaction = _.clone(this.transactionShell);
        if (this.items.eventPosition && this.payTransactions.length === 0) {
            const employeeType =
                this.items.eventPosition.employeeType.description;
            const hasTravelActivity = this.items.eventPosition
                .hasTravelActivityOnly
                ? true
                : false;

            const hasDOOTActivity = this.items.eventPosition.hasDOOTActivityOnly
                ? true
                : false;

            const hasFlatRate = this.items.flatRate ? true : false;

            let config: any | null;
            for (let i = 0; i < this.payrollDefaultsData.length; i++) {
                const conf = this.payrollDefaultsData[i];

                const travelValid =
                    conf.checkTravel === false ||
                    conf.hasTravelActivity === hasTravelActivity;
                const dootValid =
                    conf.checkDOOT === false ||
                    (conf.checkDOOT === hasDOOTActivity &&
                        conf.checkDOOT === true);
                const flatValid =
                    conf.checkFlat === false ||
                    (conf.hasFlat === hasFlatRate && conf.checkFlat === true);
                if (
                    conf.defaultContract.toUpperCase() ===
                    this.items.contractCode.name.toUpperCase()
                ) {
                    if (
                        conf.employeeType.toUpperCase() ===
                            employeeType.toUpperCase() &&
                        (conf.checkTravel === false ||
                            conf.hasTravelActivity === hasTravelActivity) &&
                        (conf.checkDOOT === false ||
                            (conf.checkDOOT === hasDOOTActivity &&
                                conf.checkDOOT === true)) &&
                        (conf.checkFlat === false ||
                            (conf.hasFlat === hasFlatRate &&
                                conf.checkFlat === true))
                    ) {
                        config = conf;
                    } else {
                        if (
                            conf.employeeType.toUpperCase() ===
                                employeeType.toUpperCase() &&
                            travelValid &&
                            (conf.checkDOOT === false ||
                                (conf.checkDOOT === hasDOOTActivity &&
                                    conf.checkDOOT === true)) &&
                            (conf.checkFlat === false ||
                                (conf.hasFlat === hasFlatRate &&
                                    conf.checkFlat === true))
                        ) {
                            config = conf;
                        } else {
                            if (
                                conf.employeeType.toUpperCase() ===
                                    employeeType.toUpperCase() &&
                                travelValid &&
                                dootValid &&
                                (conf.checkFlat === false ||
                                    (conf.hasFlat === hasFlatRate &&
                                        conf.checkFlat === true))
                            ) {
                                config = conf;
                            }
                        }
                    }
                }
            }

            //var config = _.filter(payrollDefaultsData, function (conf) {
            //    return conf.employeeType.toUpperCase() === employeeType.toUpperCase() && conf.hasTravelActivity === hasTravelActivity;
            //});

            if (config) {
                for (let i = 0; i < config.transactions.length; i++) {
                    newPayTransaction = _.clone(this.transactionShell);
                    const transaction = config.transactions[i];
                    const isMatchingTransaction = _.find(
                        this.items.contractCode.transactionTypes,
                        function (o) {
                            return (
                                o &&
                                o.id == transaction.id &&
                                o.description == transaction.description
                            );
                        }
                    );

                    if (config && isMatchingTransaction) {
                        // config = JSON.parse(config);
                        newPayTransaction.hrUnit =
                            config.hrUnitSource === "DURATION"
                                ? this.items.duration
                                : transaction.hrUnit;
                        if (transaction.rate === 0) {
                            if (config.rate === "DAILY") {
                                if (this.items.flatRate) {
                                    const payRate =
                                        this.items.flatRate /
                                        newPayTransaction.hrUnit;
                                    newPayTransaction.rate = parseFloat(
                                        payRate.toFixed(3)
                                    );
                                } else newPayTransaction.rate = null;
                            } else {
                                newPayTransaction.rate = this.items.rate;
                            }
                        } else {
                            newPayTransaction.rate = transaction.rate;
                        }

                        newPayTransaction.transactionType = transaction;
                    }

                    this.payTransactions.push(newPayTransaction);
                }
            } else {
                //default transaction setup
                this.payTransactions.push(newPayTransaction);
            }
        } else {
            //default transaction setup
            this.payTransactions.push(newPayTransaction);
        }
    }

    changeTransactionType(transaction) {
        transaction.hrUnit = null;
        transaction.rate = "";
    }

    addTransaction() {
        this.getPayTransaction_new();
    }

    removeTransaction(index) {
        this.payTransactions.splice(index, 1);
    }

    approveTimesheet() {
        this.submitted = true;
        if (this.validateTransactions() === false) {
            return;
        }
        this.items.payTransactions = this.payTransactions;
        this.onSync.next(this.items);
        this.closeModal();
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    cancel() {
        this.log.trace("cancelled modal");
        this.onCancel.next(undefined);
        this.closeModal();
    }

    validateTransactions() {
        let i;
        for (i = 0; i < this.payTransactions.length; i++) {
            if (
                this.payTransactions[i].hrUnit === null ||
                this.payTransactions[i].rate === "" ||
                this.payTransactions[i].transactionType.id === 0 ||
                this.payTransactions[i].rate == undefined ||
                this.payTransactions[i].hrUnit == undefined
            ) {
                return false;
            }
        }
        return true;
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.onCancel = new Subject();
        this.items = _.cloneDeep(this.items);
        this.payTransactions = _.cloneDeep(this.payTransactions);
        if (this.payTransactions.length === 0) {
            this.addTransaction();
        }
        this.reorderTransactions();
        this.checkTransaction();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
