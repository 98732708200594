
<header id="col-main-hdr" class="hdr-event-dash" *ngIf="event">
  <nav class="col-main-menu navbar-right" role="navigation">
    <div dropdown>
      <a authenticate-user="event.update,financial.budgetforecast.update,event.wbsecode.update"
         (click)="openEditEventModal()"
         class="hdr-action-icon">
        <i class="fa fa-pencil"></i>
      </a>
      <a authenticate-user="event.create"
         (click)="openCloneEventModal(event)"
         class="hdr-action-icon">
        <i class="fa fa-copy"></i>
      </a>
      <a authenticate-user="favoriteevent.update, favoriteevent.retrieve"
         (click)="saveFavorite(event)"
         class="favoriteEvent hdr-action-icon"
         [ngModel]="event.isFavorite"
         ngDefaultControl>
        <i class="fa" [ngClass]="{'fa-star': event.isFavorite, 'fa-star-o': !event.isFavorite}">
        </i>
      </a>
      <a id="button-season"
         class="dropdown-icon"
         dropdownToggle
         data-toggle="dropdown"
         aria-controls="dropdown-season"
         *ngIf="!!event.seasonId">
        <i class="fa fa-bars"></i>
      </a>
      <div id="dropdown-season" class="dropdown-menu dropdown-menu-end" *dropdownMenu role="menu" aria-labelledby="button-season">
        <div class="dropdown-top">
          <a class="btn-link" routerLink="/Events/Season/{{event.seasonId}}">Season Dashboard</a>
        </div>
        
        <ul *ngIf="season?.eventType?.hasTeam" class="dropdown-season-list">
          <li *ngFor="let event of seasonEventsList">
            <a class="btn-link" href="/Events/Event/{{event.id}}">
              <img width="45"
                   height="45"
                   src="/assets/image/team/{{event.awayTeam?.imageId}}"
                   title="{{event.awayTeam?.name}}"
                   alt="{{event.awayTeam?.name}}">
              <span class="dropdown-season-sep">&#64;</span>
              <img width="45"
                   height="45"
                   src="/assets/image/team/{{event.homeTeam?.imageId}}"
                   title="{{event.homeTeam?.name}}"
                   alt="{{event.homeTeam?.name}}" />
            </a>
          </li>
          <li [hidden]="(seasonEventsList).length">No results</li>
        </ul>
        <ul *ngIf="season && !season.eventType.hasTeam" class="dropdown-season-list">
          <li *ngFor="let event of seasonEventsList">
            <a class="btn-link" href="/Events/Event/{{event.id}}">
              <truncate-text [truncateString]="event.name"
                             [truncateLimit]="26">
              </truncate-text>
            </a>
          </li>
          <li [hidden]="(seasonEventsList).length">No results</li>
        </ul>
        <div authenticate-user="event.update,financial.budgetforecast.update"
             class="dropdown-btm">
          <a class="btn-link" routerLink="/Events/Season/{{event.seasonId}}/setup">Update My Season</a>
        </div>
      </div>
    </div>
  </nav>

  <span class="event-type-logo" aria-hidden="true">
    <img src="/assets/image/eventType/{{event.eventType?.imageId || 0}}"
         width="35"
         height="35"
         title="" />
  </span>
  <h1>
    <a *ngIf="season" routerLink="/Events/Season/{{season.id}}">
      {{season.name ? season.name + ':' : ''}}
    </a>
    <span class="hdr-teams">
      <span [hidden]="!hasTeamNames">
        <span class="hdr-team-logo">
          <img width="25"
               height="25"
               class="hdr-game-logo"
               src="/assets/image/team/{{event.awayTeam?.imageId || 0}}" />
          {{event.awayTeam?.name}}
        </span>
        &#64;
        <span class="hdr-team-logo">
          <img width="25"
               height="25"
               src="/assets/image/team/{{event.homeTeam?.imageId || 0}}">
          {{event.homeTeam?.name}}
        </span>
      </span>
      <span [hidden]="hasTeamNames" class="event-name">
        <span>{{event.eventName}}</span>
      </span>
      <span class="event-name" [hidden]="!event.isTaped">
        <span>(TAPED)</span>
      </span>
    </span>
  </h1>

  <span>
    &nbsp;&nbsp;&nbsp;&nbsp;<span class="label label-default"
                                  style="font-size:14px"
                                  *ngIf="(event?.tier > 0)">
      T{{event.tier}}
    </span>
  </span>

  <span class="hdr-event-dates"
        *ngIf="filteredBroadcastDates?.length > 0">
    <span>{{filteredBroadcastDates[0]?.airDate | date:"EEE, MMM d"}}</span>
    <span *ngIf="(filteredBroadcastDates[0]?.airDate | date:'EEE, MMM d') != (filteredBroadcastDates[filteredBroadcastDates?.length - 1]?.airDate | date:'EEE, MMM d')"
          style="padding-right:10px;">- {{filteredBroadcastDates[filteredBroadcastDates?.length - 1]?.airDate | date:"EEE, MMM d"}}</span>
    <span class="info-tip"
          toggle="tooltip"
          trigger="mouseenter"
          placement="bottom"
          (mouseover)="eventBroadcastToolTip()"
          [tooltip]="popToolTipTemplate">
    </span>
    <ng-template #popToolTipTemplate><div class="text-nowrap" [innerHtml]="eventBroadcastToolTipText"></div></ng-template>
  </span>
</header>
<!--<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Pricing</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown link
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>-->
<nav class="navbar navbar-expand navbar-secondary p-0"
     role="navigation"
     [hidden]="!event.id"
     id="navbar-main-secondary">
  <div class="navbar-header">
    <button type="button"
            class="navbar-toggler"
            ng-init="isCollapsed = true"
            (click)="isCollapsed = !isCollapsed">
      <span class="sr-only">Toggle navigation</span>
      <i class="fa fa-bars"></i>
    </button>
  </div>
  <div class="navbar-collapse" [ngClass]="{collapse: isCollapsed}">
    <ul class="nav navbar-nav">
      <li [ngClass]="{active: pageName === 'Summary'}">
        <!--<a href="/Events/Event/Summary/{{event.id}}">Summary</a>-->
        <a class="d-block" [ngClass]="{active: pageName === 'Summary'}" href="/Events/Event/{{event.id}}">
          Summary
        </a>
      </li>
      <li authenticate-user="event.crew.retrieve" [ngClass]="{active: pageName === 'Crew'}">
        <a class="d-block" routerLink="Crew" routerLinkActive="active">
          Crew
        </a>
      </li>
      <li authenticate-user="eventtimesheet.retrieve" [ngClass]="{active: pageName === 'Timesheet'}">
        <a class="d-block" routerLink="Timesheet" routerLinkActive="active">
          Timesheet
        </a>
      </li>
    </ul>
  </div>
</nav>
<nav class="breadcrumb">
  <ul>
    <!--<li><a href="/" title="Home">Home</a></li>-->
    <li home></li>
    <li>/</li>
    <li>
      <a routerLink="/Events">Events</a>
    </li>
    <li>/</li>
    <li [hidden]="!event.id">
      <a routerLink="/Events/Event/{{event.id}}">{{event.eventName}}</a>
    </li>
    <li *ngIf="pageName">/</li>
    <li *ngIf="pageName">{{pageName}}</li>
  </ul>
</nav>
