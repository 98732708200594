import {
    Component,
    OnInit,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from "@angular/core";

import * as _ from "lodash";
import * as moment from "moment";
import { format } from "path";
import { ControlContainer, NgForm } from "@angular/forms";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ChangeDetectorRef } from "@angular/core";

@Component({
    selector: "nbc-date-time",
    templateUrl: "./nbc-datetime.component.html",
    styles: [
        ".header{background-color:#5cb85c;color:#FFF;} .header-text{padding:10px !important;text-align:center;}  .active{background-color:#5cb85c;color:#fff;border-radius:50% !important} ",
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    host: {
        "(document:click)": "onClick($event)",
    },
})
export class NbcDateTimeComponent implements OnInit, OnChanges {
    constructor(
        el: ElementRef,
        private log: NbcLoggerService,
        private cd: ChangeDetectorRef
    ) {
        this.ele = el;
    }

    @Input() id: string | undefined;
    @Input() name!: string;
    @Input() type: string | undefined;
    @Input() defaultDate!: Date;
    @Input() model!: Date | undefined;
    @Input() format = "yyyy-MM-dd HH:mm";
    @Input() dateDisplayFormat = "ampm";
    @Input() isValidTime = true;
    @Input() placeholder!: string;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() nbcDisabled = false;
    @Input() nbcRequired = false;
    @Input() nbcDatesNavigationEnabled = true;
    @Input() nbcTimeNavigationEnabled = true;

    @Output() nbcClassChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcBlur = new EventEmitter<any>();

    ele: ElementRef;
    oldDate!: Date;

    onClick(event: any) {
        const x = this.ele.nativeElement;
        const classes = event.target.getAttribute("class");
        if (!_.includes(classes, this.name)) {
            this.clearview();
            this.close();
            this.cd.detectChanges();
        }
    }

    getName() {
        return this.name;
    }

    showMins = false;
    navDisabled = false;

    displayModel = "";
    minutes: any[] | undefined;
    hours: any[] | undefined;
    step = 1;
    now!: Date;
    date!: Date;
    headerDate!: Date;
    militaryFormatPattern: any = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    civilianFormatPattern: any = /^[01]?\d:[0-5]\d\s?[ap]m?$/i;
    timeFormatPattern: any = {
        ampm: this.civilianFormatPattern,
        "24hr": this.militaryFormatPattern,
    };
    timeParseFormat: any = {
        ampm: "h:mm A",
        "24hr": "HH:mm",
    };
    views: any[] = ["year", "month", "date", "hours", "minutes"];
    view: any | undefined;
    showme = false;
    left = 0;
    top = 0;

    next(d: any) {
        const date = this.date;
        d = d || 1;
        switch (this.view) {
            case "year":
            /*falls through*/
            case "month":
                date.setFullYear(date.getFullYear() + d);
                break;
            case "date":
                date.setDate(1);
                date.setMonth(date.getMonth() + d);
                break;
            case "hours":
                date.setDate(date.getDate() + d);
                break;
            case "minutes":
                if (date.getHours() + d >= 24 || date.getHours() + d < 0) {
                } else {
                    date.setHours(date.getHours() + d);
                }
                break;
        }
        const nextView = this.views[this.views.indexOf(this.view) - 1];
        if (nextView) {
            this.view = nextView;
        }
        this.setDate(date);
        this.updateDisplayModel();
    }
    prev(d: any) {
        return this.next(-d || -1);
    }

    close() {
        if (this.showme) this.showme = false;
    }

    /**
     * this clearing method will help to close the popover
     */
    clearview() {
        this.view = null;
    }

    /**
     * get list of mins popover after hour is selected
     * @param date
     * @param step
     * @returns
     */
    getVisibleMinutes(date: any, step: any) {
        date = new Date(date || new Date());
        date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours()
        );
        this.minutes = [];
        const stop = date.getTime() + 60 * 60 * 1000;
        while (date.getTime() < stop) {
            this.minutes.push(date);
            date = new Date(date.getTime() + step * 60 * 1000);
        }
        return this.minutes;
    }

    /**
     * get list of visible hours on the hour popover
     * @param date
     * @returns
     */
    getVisibleHours(date: any) {
        date = new Date(date || new Date());
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.hours = [];
        for (let i = 0; i < 25; i++) {
            if (
                _.isUndefined(this.hours[this.hours.length - 1]) ||
                this.hours[this.hours.length - 1].getHours() !== date.getHours()
            ) {
                this.hours.push(date);
                //console.log(date);
            }
            date = new Date(date.getTime() + 60 * 60 * 1000);
            if (date.getHours() === 0) break;
        }
        return this.hours;
    }
    isNow(date: any) {
        let is = true;
        const now = this.now;

        switch (this.view) {
            case "minutes":
                //console.log(date.getMinutes() + " " + now.getMinutes());
                is = is && date.getMinutes() === now.getMinutes();
                is = is && date.getHours() === now.getHours();
                is = is && date.getDate() === now.getDate();
                is = is && date.getMonth() === now.getMonth();
                is = is && date.getFullYear() === now.getFullYear();
                break;
            case "hours":
                is = is && date.getHours() === now.getHours();
                is = is && date.getDate() === now.getDate();
                is = is && date.getMonth() === now.getMonth();
                is = is && date.getFullYear() === now.getFullYear();
                break;
            case "date":
                is = is && date.getDate() === now.getDate();
                is = is && date.getMonth() === now.getMonth();
                is = is && date.getFullYear() === now.getFullYear();
                break;
            case "month":
                is = is && date.getMonth() === now.getMonth();
                is = is && date.getFullYear() === now.getFullYear();
                break;
            case "year":
                is = is && date.getFullYear() === now.getFullYear();
                break;
        }
        return is;
    }
    cancel(date: any) {
        this.model = undefined;
        this.displayModel = "";
        this.modelChange.emit(undefined);
    }

    isSameYear(date: any) {
        return this.model && this.oldDate.getFullYear() === date.getFullYear();
    }
    isSameMonth(date: any) {
        return (
            this.isSameYear(date) && this.oldDate.getMonth() === date.getMonth()
        );
    }
    isSameDay(date: any) {
        return (
            this.isSameMonth(date) && this.oldDate.getDate() === date.getDate()
        );
    }
    isSameHour(date: any) {
        return (
            this.isSameDay(date) && this.oldDate.getHours() === date.getHours()
        );
    }
    isSameMinutes(date: any) {
        return (
            this.isSameHour(date) &&
            this.oldDate.getMinutes() === date.getMinutes()
        );
    }

    /**
     * core function to update model
     * @param date
     */
    setDate(date: any) {
        //if (attrs.disabled) {
        //  return;
        //}
        this.date = date;
        this.headerDate = this.date;
        // change next view
        const nextView = this.views[this.views.indexOf(this.view) + 1];
        if (!nextView || this.model) {
            this.model = new Date(date || this.model);
            //var view = partial ? 'minutes' : this.view;
            //noinspection FallThroughInSwitchStatementJS
            switch (this.view) {
                case "minutes":
                    this.model.setMinutes(date.getMinutes());
                    this.model.setHours(date.getHours());
                    this.model.setDate(date.getDate());
                    this.model.setMonth(date.getMonth());
                    this.model.setFullYear(date.getFullYear());
                    break;
                case "hours":
                    this.model.setHours(date.getHours());
                    this.model.setDate(date.getDate());
                    this.model.setMonth(date.getMonth());
                    this.model.setFullYear(date.getFullYear());
                    break;
                case "date":
                    this.model.setDate(date.getDate());
                    this.model.setMonth(date.getMonth());
                    this.model.setFullYear(date.getFullYear());
                    break;
                case "month":
                    this.model.setMonth(date.getMonth());
                    this.model.setFullYear(date.getFullYear());
                    break;
                case "year":
                    this.model.setFullYear(date.getFullYear());
                    break;
            }
        }
        this.getVisibleHours(this.model || date);
        this.getVisibleMinutes(this.model || date, 1);
        if (nextView) {
            this.setView(nextView);
        } else {
            const x = moment.utc(this.model).local().format();
            this.modelChange.emit(x);
            this.updateDisplayModel();
            this.clearview();
            this.close();
        }
        this.cd.detectChanges();
    }

    updateDisplayModel() {
        this.displayModel = moment
            .utc(this.model)
            .local()
            .format(this.timeParseFormat[this.dateDisplayFormat]);
    }

    /**
     * sets the view which lets user select hour or mins
     * @param nextView
     */
    setView(nextView: any) {
        if (this.views.indexOf(nextView) !== -1) {
            this.view = nextView;
        }
    }

    /**
     * time to show on time selection popover with formatter
     * @param hr
     * @returns
     */
    getTime(hr: any) {
        return moment(hr).format(this.timeParseFormat[this.format]);
    }

    /**
     * show hours in the hour popover
     * @param min
     * @returns
     */
    getHour(min: any) {
        return moment(min).format(this.timeParseFormat[this.format]);
    }

    /**
     * show hours in the hour popover
     * @param min
     * @returns
     */
    getHeaderDate() {
        return moment(this.headerDate).format("MMMM D, y");
    }

    /**
     * method provides element position on the screen
     *  * this helps to retain absolute positioning of the popover
     * @param event
     */
    getPosition(event: any) {
        this.showme = true;
        const offsetTop = 0;
        const offsetLeft = 0;
        const el = event.srcElement;
        const cord = this.getCoords(el);
        //while (el) {
        //  offsetLeft += el.layerX;
        //  offsetTop += el.layerY;
        //  el = el.parentElement;
        //}
        this.top = cord.top;
        this.left = cord.left;
    }

    getCoords(elem: any) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top,
            left: box.left,
            //top: box.top + window.pageYOffset,
            //right: box.right + window.pageXOffset,
            //bottom: box.bottom + window.pageYOffset,
            //left: box.left + window.pageXOffset
        };
    }
    /**
     * Initiating function
     * @param $event
     */
    show($event: any) {
        setTimeout(() => {
            this.clearview();
            this.close();

            if (this.view == null) this.view = this.type;
            this.getPosition($event);
            this.cd.detectChanges();
        }, 100);
    }

    initialize() {
        if (
            moment(this.model).isValid() ||
            moment(this.defaultDate).isValid()
        ) {
            if (
                this.model &&
                !_.isUndefined(this.model) &&
                moment(this.model).isValid()
            ) {
                this.model = new Date(this.model);
                this.date = new Date(this.model);
            } else {
                this.date = new Date(this.defaultDate);
            }
            this.headerDate = this.date;
            this.view = this.type;
            this.now = new Date();
            this.getVisibleHours(this.date);
            if (this.model) {
                this.oldDate = this.model;
                this.updateDisplayModel();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            this.log.trace("** property changed is :" + propName);
            if (_.has(changes["model"], "currentValue")) {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                if (current) {
                    this.initialize();
                } else {
                    this.model = undefined;
                    this.displayModel = "";
                }
            }
        }
    }

    ngOnInit() {
        this.initialize();
    }
}
