import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { EventBusService } from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ChartType } from "chart.js";
//import { MultiDataSet, Label, Colors } from 'ng2-charts';
import { OnDestroy } from "@angular/core";
import { SubSink } from "subsink";

@Component({
    selector: "crew-chart",
    templateUrl: "./crew-chart.component.html",
    //styleUrls: [
    //  "../../../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class CrewChartComponent implements OnInit, OnDestroy {
    constructor(
        private _eventBus: EventBusService,
        private log: NbcLoggerService
    ) {}

    //public chartLayoutLabel!: Label[];
    //public ChartLayoutData!: MultiDataSet;
    public chartLayoutColors: Array<any> = [{}];
    public doughnutChartType: ChartType = "doughnut";
    options: any = {
        responsive: false,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            data: [],
            display: false,
        },
        cutout: "70%",
    };

    private subs = new SubSink();

    budgetColor: any | undefined;
    chartData: any = {};

    positionChart: ChartDetails | undefined;
    tierpositionsChartData: ChartDetails | undefined;
    unionChartData: ChartDetails | undefined;
    budgetChartData: ChartDetails | undefined;

    positionChartData: any;
    tierpositionsChartDetails: any;
    unionChartDataDetails: any;

    // Private Methods
    chartInit(data: any) {
        this.chartData = data;
        const budgetPercent =
            ((this.chartData.budgetBaseLine - this.chartData.budgetForecast) /
                this.chartData.budgetBaseLine) *
            100;

        this.chartData.budgetTurnOver = Math.round(budgetPercent);
        this.chartData.percentUnion =
            this.chartData.positionsUnion === 0
                ? 0
                : Math.ceil(
                      (this.chartData.positionsUnion /
                          this.chartData.positionsTotal) *
                          100
                  );
        if (
            this.chartData.budgetTurnOver >= 0 &&
            this.chartData.budgetTurnOver <= 100
        ) {
            this.chartData.positiveBudget = true;
            this.budgetColor = "#0DB14B";
        } else {
            this.chartData.positiveBudget = false;
            this.budgetColor = "#ff0000";
        }

        this.positionChart = new ChartDetails();
        this.positionChart.data = [
            [
                this.chartData.positionsOpen,
                this.chartData.positionsAssigned,
                this.chartData.positionsConfirmed,
            ],
        ];
        this.positionChart.color[0]["backgroundColor"] = [
            "#D1D1D1",
            "rgb(252, 183, 17)",
            "#0DB14B",
        ];

        this.positionChartData = {
            labels: this.positionChart.labels,
            datasets: [
                {
                    data: [
                        this.chartData.positionsOpen,
                        this.chartData.positionsAssigned,
                        this.chartData.positionsConfirmed,
                    ],
                    backgroundColor: [
                        "#D1D1D1",
                        "rgb(252, 183, 17)",
                        "#0DB14B",
                    ],
                },
            ],
        };

        this.tierpositionsChartData = new ChartDetails();
        this.tierpositionsChartData.data = [
            [
                this.chartData.tierPositionsSummary.percentile_25,
                this.chartData.tierPositionsSummary.percentile_50,
                this.chartData.tierPositionsSummary.percentile_75,
                this.chartData.tierPositionsSummary.percentile_100,
                this.chartData.tierPositionsSummary.overPaid,
            ],
        ];
        this.tierpositionsChartData.color[0]["backgroundColor"] = [
            "#5cb85c",
            "#d1d1d1",
            "#f8e71c",
            "#ffac00",
            "#ff0400",
        ];

        this.tierpositionsChartDetails = {
            labels: [],
            datasets: [
                {
                    data: [
                        this.chartData.tierPositionsSummary.percentile_25,
                        this.chartData.tierPositionsSummary.percentile_50,
                        this.chartData.tierPositionsSummary.percentile_75,
                        this.chartData.tierPositionsSummary.percentile_100,
                        this.chartData.tierPositionsSummary.overPaid,
                    ],
                    backgroundColor: [
                        "#5cb85c",
                        "#d1d1d1",
                        "#f8e71c",
                        "#ffac00",
                        "#ff0400",
                    ],
                },
            ],
        };
        if (
            this.chartData.positiveBudget &&
            this.chartData.budgetTurnOver !== 0
        ) {
            this.budgetChartData = new ChartDetails();
            const budgetdata =
                this.chartData.budgetBaseLine - this.chartData.budgetForecast;
            this.budgetChartData.data = [
                [budgetdata, this.chartData.budgetForecast],
            ];
            this.budgetChartData.color[0]["backgroundColor"] = [
                "#D1D1D1",
                this.budgetColor,
            ];
        } else {
            this.budgetChartData = new ChartDetails();
            this.budgetChartData.data = [[this.chartData.budgetForecast]];
            this.budgetChartData.color[0]["backgroundColor"] = [
                this.budgetColor,
            ];
        }
        if (this.chartData.percentUnion !== 0) {
            this.unionChartData = new ChartDetails();
            const unionVal =
                this.chartData.positionsTotal -
                this.chartData.positionsNonUnion -
                this.chartData.positionsUnion;
            this.unionChartData.data = [
                [
                    unionVal,
                    this.chartData.positionsNonUnion,
                    this.chartData.positionsUnion,
                ],
            ];
            this.unionChartData.color[0]["backgroundColor"] = [
                "#D1D1D1",
                "rgb(0, 137, 209)",
                "rgb(14, 86, 139)",
            ];

            this.unionChartDataDetails = {
                labels: [],
                datasets: [
                    {
                        data: [
                            unionVal,
                            this.chartData.positionsNonUnion,
                            this.chartData.positionsUnion,
                        ],
                        backgroundColor: [
                            "#D1D1D1",
                            "rgb(0, 137, 209)",
                            "rgb(14, 86, 139)",
                        ],
                    },
                ],
            };
        } else if (this.chartData.positionsNonUnion !== 0) {
            this.unionChartData = new ChartDetails();
            const unionValue =
                this.chartData.positionsTotal -
                this.chartData.positionsNonUnion -
                this.chartData.positionsUnion;
            this.unionChartData.data = [
                [unionValue, this.chartData.positionsNonUnion],
            ];
            this.unionChartData.color[0]["backgroundColor"] = [
                "#D1D1D1",
                "rgb(0, 137, 209)",
            ];
            this.unionChartDataDetails = {
                labels: [],
                datasets: [
                    {
                        data: [unionValue, this.chartData.positionsNonUnion],
                        backgroundColor: ["#D1D1D1", "rgb(0, 137, 209)"],
                    },
                ],
            };
        } else {
            this.unionChartData = new ChartDetails();
            this.unionChartData.data = [[100]];
            this.unionChartData.color[0]["backgroundColor"] = ["#D1D1D1"];
            this.unionChartDataDetails = {
                labels: [],
                datasets: [
                    {
                        data: [100],
                        backgroundColor: ["#D1D1D1", "#D1D1D1"],
                    },
                ],
            };
        }
    }

    subscribeEvents() {
        this.subs.sink = this._eventBus.on("chartInit", (data: any) => {
            this.chartInit(data);
        });
    }
    ngOnInit() {
        this.subscribeEvents();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}

class ChartDetails {
    data: any;
    labels: any[];
    color: Array<any>;

    constructor() {
        this.data = [];
        this.labels = [];
        this.color = [{}];
    }
}
