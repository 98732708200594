//import { ThrowStmt } from "@angular/compiler"; // Not Required
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { UniquePipe } from "ngx-pipes";
import { Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { eventManagerService } from "src/app/Shared/Services/eventmanager.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { TeamService } from "src/app/Shared/Services/team.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";
import { SharedDataService } from "../../../../Shared/sharedData.service";

@Component({
    selector: "app-event-search",
    templateUrl: "./event-search.component.html",
    styles: [
        `
            .show {
                display: table-cell !important;
            }
        `,
    ],
    providers: [UniquePipe],
})
export class EventSearchComponent implements OnInit, OnDestroy {
    constructor(
        private _eventTypeService: EventTypeService,
        private _eventServices: EventService,
        private _globalService: GlobalService,
        private _eventManagerService: eventManagerService,
        private _remotesQueryService: RemotesQueryService,
        private _venueService: VenueService,
        private _teamService: TeamService,
        private _sharedData: SharedDataService,
        private log: NbcLoggerService
    ) {}

    seasonId = -1;
    currentDate = moment();
    eventQuery: any = {};
    defaultEventQuery = {};
    eventManagers = {
        all: { id: 11, values: [] },
        homeBase: { id: 12, values: [] },
        technical: { id: 13, values: [] },
        manPower: { id: 14, values: [] },
        onSite: { id: 15, values: [] },
    };
    defaultEventResultsHaveLoaded = false;
    eventStatusList: any[] = [];
    bsInlineRangeValue: any;
    searchTeamResponse$: Observable<any[]> | undefined;
    searchVenueResponse$: Observable<any[]> | undefined;
    private subs = new SubSink();
    searchVenueTerm = "";
    model: any = {
        items: [],
        broadcastDates: [
            {
                airDate: "",
                startTime: "",
                endTime: "",
            },
        ],
    };
    venue = "";
    eventTypes = [];
    networks = {};
    showFilter = true;
    sortList = [
        { name: "Earliest", predicate: "StartDate" },
        { name: "Latest", predicate: "-StartDate" },
        { name: "Event Name A-Z", predicate: "Name" },
        { name: "Event Name Z-A", predicate: "-Name" },
        { name: "Level", predicate: "Level" },
    ];
    showGrid = true;
    viewFormat = "grid";
    showVenue = true;
    hideStatusList = false;
    eventQueryTeamTerm: any;
    showTeam = true;
    eventTypeFilter: any = {
        Status: "3 Selected",
        Count: 3,
        Max: 7,
    };

    tiers = [
        { name: "Tier 1", id: 1 },
        { name: "Tier 2", id: 2 },
        { name: "Tier 3", id: 3 },
        { name: "Tier 4", id: 4 },
    ];

    timeSheetStatuses = [
        { name: "Not Submitted", id: "Not Submitted" },
        { name: "Partially Submitted", id: "Partially Submitted" },
        { name: "Fully Submitted", id: "Fully Submitted" },
    ];
    // managers = this.eventManagers;
    managers: any;
    hideResults = false;

    //getEventTypes() {
    //  // usSpinnerService.spin("searchSpinner");
    //  this._eventTypeService.getEventTypes({}).subscribe({
    //    next: (result) => {
    //      this.log.trace("got results: " + JSON.stringify(result));
    //      this.eventTypes = result.reference;
    //    }, error: (data) => {
    //      this.log.error("got an error: " + JSON.stringify(data));
    //      // $scope.$emit("showAlert", "danger", "Unable to load event types at this time. Please try again later.");
    //    }
    //  })
    //}
    onToggleFilterDatepicker($event) {
        this.eventQuery.crewEndDate = $event[1];
        this.eventQuery.crewStartDate = $event[0];
    }
    getEventStatusList() {
        // usSpinnerService.spin("searchSpinner");
        this.subs.sink = this._eventServices.getEventStatusList().subscribe({
            next: (result) => {
                this.eventStatusList = result.reference;

                _.forEach(this.eventStatusList, (item) => {
                    if (
                        item.description.toLowerCase() === "planning" ||
                        item.description.toLowerCase() === "executing" ||
                        item.description.toLowerCase() === "closing"
                    ) {
                        item.checked = true;
                    }
                });
            },
            error: (data) => {
                this.log.error("got an error: " + JSON.stringify(data));
                // $scope.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.");
            },
        });
    }
    getEventNetworks() {
        // usSpinnerService.spin("updateSpinner");
        this.subs.sink = this._globalService.getEventNetworks().subscribe({
            next: (result) => {
                // this.log.trace("got results: " + JSON.stringify(result));
                this.networks = result.reference;
            },
            error: (data) => {
                this.log.error("got an error: " + JSON.stringify(data));
                // $scope.$emit("showAlert", "danger", "Unable to load event networks at this time. Please try again later.");
            },
        });
    }

    getEventManagers() {
        this.log.trace("getEventManagers called");
        // usSpinnerService.spin("saveSpinner");
        this._eventManagerService
            .eventManager([
                this.eventManagers.homeBase.id,
                this.eventManagers.technical.id,
                this.eventManagers.manPower.id,
                this.eventManagers.onSite.id,
            ])
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.managers.all.values = result.reference;
                },
                error: (result) => {
                    this.log.error("got an error: " + JSON.stringify(result));
                    // $scope.$emit("showAlert", "danger", "Unable to load the event managers");
                },
            });
    }
    searchTeam(eventType?, term?) {
        this.log.trace("searchTeam " + eventType);
        // return this._teamService.fullSearch(encodeURIComponent(term));
        this.searchTeamResponse$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.eventQueryTeamTerm);
            }
        ).pipe(
            switchMap((query) => {
                // using github public api to get users by name
                return this._teamService.fullSearch(encodeURIComponent(query));
            })
        );
    }
    onTeamSelected($event, awayTeam?, index?) {
        this.eventQuery.team = $event.item;
    }
    eventSearch() {
        this.log.trace("eventSearch()");
        if (this.eventQuery.crewStartDate && this.eventQuery.crewEndDate) {
            if (this.eventQuery.networkId == 0) {
                this.eventQuery.networkId = "";
            }
            if (this.eventQuery.managerId == 0) {
                this.eventQuery.managerId = "";
            }
            if (this.eventQuery.timesheetStatus == 0) {
                this.eventQuery.timesheetStatus = "";
            }

            this.eventQuery.seasonId = +this.eventQuery.seasonId;

            const clonedQuery = _.cloneDeep(this.eventQuery);
            clonedQuery.crewEndDate = moment(
                this.eventQuery.crewEndDate
            ).format();
            clonedQuery.crewStartDate = moment(
                this.eventQuery.crewStartDate
            ).format();

            (this.subs.sink = this._eventServices
                .search(clonedQuery)
                .subscribe({
                    next: (result) => {
                        // this.log.trace("got results: " + JSON.stringify(result));
                        this.model = result;
                        this.model.broadcastType =
                            this.eventQuery.broadcastType;
                        this.model.timesheetStatus =
                            this.eventQuery.timesheetStatus;
                        this.eventQuery.currentPage = result.currentPage;
                        this.defaultEventResultsHaveLoaded = true;

                        // $scope.$emit("onUpdateEventSearchResults", $scope.model);
                    },
                    error: (data) => {
                        this.log.error("got an error: " + JSON.stringify(data));
                        // $scope.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.");
                    },
                })),
                () => {
                    //Finalize
                };
        }
    }
    checkTeam() {
        if (this.eventQuery.team === undefined) {
            this.hideResults = true;
        } else {
            this.hideResults = false;
        }
    }
    filterByBroadcastType() {
        // this.eventQuery.broadcastType = this.model.broadcastType;
        this.eventSearch();
    }

    restartSearch() {
        this.log.trace("restartSearch");
        this.eventQuery.currentPage = 1;
        this.checkTeam();
        this.eventSearch();
    }
    resetEventSearch() {
        this.log.trace("resetSearch()");
        this.setEventQuery();
        this.eventQuery.broadcastType = "";
        this.eventQueryTeamTerm = "";
        this.searchVenueTerm = "";
        this.showVenue = true;
        this.bsInlineRangeValue = [
            this.eventQuery.crewStartDate,
            this.eventQuery.crewEndDate,
        ];
        this.eventTypeFilter.Status = "3 Selected";
        this.eventStatusList.forEach((key) => {
            if (
                key.description.toLowerCase() === "planning" ||
                key.description.toLowerCase() === "executing" ||
                key.description.toLowerCase() === "closing"
            ) {
                key.checked = true;
            } else {
                key.checked = false;
            }
        });
        this.hideEvent();
        this.restartSearch();
    }
    searchVenue() {
        this.searchVenueResponse$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.searchVenueTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._venueService.search(encodeURIComponent(query));
            })
        );
    }
    checkSearchTeamDetails($event) {
        this.eventQueryTeamTerm = $event.target.value;
        if (!this.eventQueryTeamTerm) {
            this.eventQuery.team = "";
        }
    }
    checkSearchVenueDetails($event) {
        this.searchVenueTerm = $event.target.value;
        if (!this.searchVenueTerm) {
            this.eventQuery.venue = "";
        }
    }
    onVenueSelect($item) {
        this.log.trace("onVenueSelect()");
        this.eventQuery.venue = $item.item;
    }
    showLocation() {
        this.showVenue = false;
        this.venue = "";
        this.eventQuery.venue = undefined;
    }
    hideLocation() {
        this.showVenue = true;
        this.eventQuery.city = "";
    }
    showEvent() {
        this.showTeam = false;
        this.eventQuery.teamName = "";
    }
    hideEvent() {
        this.showTeam = true;
        this.eventQuery.eventName = "";
    }

    openCloneEventModal(event) {
        this.log.trace("Opening clone event modal");

        // var broadcastingNetworks = _.without(_.pluck(event.broadcastDates, "network.description"), undefined);
        // console.log(broadcastingNetworks);

        // var modalInstance = $modal.open({
        //   templateUrl: "/scripts/app/areas/events/views/event/modalClone.html",
        //   controller: "ModalCloneController",
        //   backdrop: "static",
        //   keyboard: false,
        //   resolve: {
        //     event: function () {
        //       return {
        //         eventId: event.id,
        //         eventName: event.name,
        //         sourceTier: event.tier,
        //         broadcastingNetworks: broadcastingNetworks,
        //         eventTypeId: event.eventType.id
        //       };
        //     },
        //     tierConfig: function () {
        //       return event.eventTierSetup;
        //     }
        //   }
        // // });

        // modalInstance.result.then(function (clone) {
        //   log.trace("clone event modal dismissed with: " + clone);
        //   usSpinnerService.spin("searchSpinner");

        //   eventServices.cloneEvent(clone).success(function (data) {
        //     log.trace("clone event modal successfully saved data: " + JSON.stringify(data));
        //     $window.location.href = preload.siteRoot + "events/event/summary/" + data.reference.id;
        //   }).error(function (data) {
        //     log.error("clone event modal got an error cloning the event: " + JSON.stringify(data));
        //     $scope.$emit("showAlert", "danger", "Unable to clone the event at this time. Please try again later.");
        //   }).finally(function () {
        //     usSpinnerService.stop("searchSpinner");
        //   });
        // }, function () {
        //   log.trace("clone event modal dismissed without value");
        // });
    }
    openDatepicker($event) {
        // this.dateRangePickerFactory($event, this.eventQuery.crewStartDate, this.eventQuery.crewEndDate);
    }
    saveFavorite(event) {
        const favoriteStatus = event.isFavorite,
            favoriteQuery = {
                eventId: event.id,
                eventName: event.name,
                key: event.isFavoriteKey,
                isFavorite: !favoriteStatus,
            },
            index = this.model.items.indexOf(event);
        let eventFavoritePromise;
        if (!favoriteStatus) {
            this.subs.sink = this._eventServices
                .favorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        ("Unable to favorite/unfavorite event at this time. Please try again later.");
                    },
                    () => {
                        this.log.debug("favorite successfully added");
                    }
                );
        } else {
            this.subs.sink = this._eventServices
                .unfavorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        // this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        this.log.showError(
                            "Unable to favorite/unfavorite event at this time. Please try again later."
                        );
                    },

                    () => {
                        this.log.debug("unfavorite successfully added");
                    }
                );
        }
    }
    calculateDates(startDate, endDate) {
        // return this.weatherCheckFactory(currentDate, startDate, endDate);
    }
    setDefaultEventQuery() {
        this.eventQuery = cloneDeep(this.defaultEventQuery);
        this.eventQuery.seasonId = this.seasonId;
    }
    setEventQuery() {
        this.log.trace("setEventQuery");
        this.eventQuery = this._remotesQueryService.getEventQuery();
        this.eventQuery.seasonId = this.seasonId;
        // $scope.$emit("onLoadEventQuery", this.eventQuery);
    }
    showList() {
        this.hideStatusList = false;
    }
    onSelect(eventTypeFilter, $index) {
        eventTypeFilter.Count = 0;
        const item = this.eventStatusList[$index];
        item.checked = !item.checked;
        this.eventStatusList.splice($index, 1, item);

        this.eventStatusList.forEach((val) => {
            if (val.checked === true) {
                eventTypeFilter.Count += 1;
            }
            this.eventQuery["is" + val.description] = val.checked || false;
        });
        if (eventTypeFilter.Count > 0) {
            eventTypeFilter.Status = eventTypeFilter.Count + " Selected";
            if (eventTypeFilter.Count === this.eventStatusList.length) {
                if (eventTypeFilter.Count === this.eventStatusList.length) {
                    eventTypeFilter.Status = "All Selected";
                }
            }
        } else {
            eventTypeFilter.Status = "None Selected";
        }

        //making sure that angular change detection is complete
        setTimeout(() => this.eventSearch(), 200);
    }
    ngOnInit() {
        this._sharedData.setCurrentPage("Season Overview");

        this._sharedData.getCurrentSeasonId().subscribe((seasonId) => {
            this.seasonId = seasonId;
        });

        this.managers = this.eventManagers;
        this.searchTeam();
        this.searchVenue();
        this.getEventNetworks();
        this.getEventStatusList();
        this.getEventManagers();
        this.setEventQuery();
        this.eventSearch();

        //this._activatedRoute.params
        //  .subscribe(params => {
        //    console.log(params);
        //    debugger;
        //    this.seasonId = params["id"]
        //    this.managers = this.eventManagers;
        //    this.searchTeam();
        //    this.searchVenue();
        //    this.getEventNetworks();
        //    //this.getEventTypes();
        //    this.getEventStatusList();
        //    this.getEventManagers();
        //    this.setEventQuery();

        //  });

        this.bsInlineRangeValue = [
            this.eventQuery.crewStartDate,
            this.eventQuery.crewEndDate,
        ];
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
