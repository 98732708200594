import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
//import { LiquidCache } from 'ngx-liquid-cache';
/*import { ISelect } from 'src/app/Event/dashboard/eventlist.component'*/ @Injectable(
    {
        providedIn: "root",
    }
)
export class GlobalService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    //@LiquidCache('getEventNetworks')
    getEventNetworks(): Observable<any> {
        this.log.trace("calling getEventNetworks");
        return this.http.get(this._apiroot.baseUrl + "networks").pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getAirportCodes(term: any): Observable<any> {
        this.log.trace("calling getAirportCodes:" + JSON.stringify(term));
        return this.http
            .get<any>(
                this._apiroot.baseUrl +
                    "global/airportCodes?term=" +
                    encodeURIComponent(term)
            )
            .pipe(map((data: any) => (data && data.reference) || []));
    }

    //@LiquidCache('getPayrollProviders')
    getPayrollProviders(): Observable<any> {
        this.log.trace("calling getPayrollProviders");
        return this.http
            .get<any>(this._apiroot.baseUrl + "global/payrollProviders")
            .pipe(map((data: any) => (data && data.reference) || []));
    }
}
