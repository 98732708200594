import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-season-tiles",
    templateUrl: "./season-tiles.component.html",
})
export class SeasonTilesComponent implements OnInit, OnDestroy {
    constructor(
        private _log: NbcLoggerService,
        private _eventTypeService: EventTypeService,
        private _seasonService: SeasonService,
        private _venueService: VenueService
    ) {}
    eventTypes = [];
    seasonQuery = this.getSeasonQuery();
    venue = "";
    seasons = [];
    model: any;
    showGrid = true;
    viewFormat = "'grid'";
    sortList = [
        { name: "Earliest", predicate: "StartDate" },
        { name: "Latest", predicate: "-StartDate" },
        { name: "Season Name A-Z", predicate: "Name" },
        { name: "Season Name Z-A", predicate: "-Name" },
    ];
    showFilter = true;
    private subs = new SubSink();
    hideFilters() {}
    getEventTypes() {
        // usSpinnerService.spin("searchSpinner");
        this.subs.sink = this._eventTypeService.getEventTypes({}).subscribe({
            next: (result) => {
                // this._log.trace("got results: " + JSON.stringify(result));
                this.eventTypes = result.reference;
            },
            error: (data) => {
                this._log.error("got an error: " + JSON.stringify(data));
                this._log.showError(
                    "Unable to load event types at this time. Please try again later."
                );
            },
        });
    }
    showListView() {
        this.showGrid = false;
    }
    getSeasonQuery() {
        const userRoles = window.preload.userRoleNames;
        return {
            pageSize: 10,
            currentPage: 1,
            startYear: moment().format("YYYY"),
            endYear: moment().format("YYYY"),
            city: "",
            state: "",
            venue: {},
            location: "",
            seasonName: "",
            eventType: "",
            isMyEvent: false,
            sortBy: "StartDate",
            userRoles: userRoles,
        };
    }
    seasonSearch() {
        this._log.trace("seasonSearch()");
        this.seasonQuery.userRoles = window.preload.user.userRoleNames;
        if (this.seasonQuery.startYear && this.seasonQuery.endYear) {
            // usSpinnerService.spin("searchSpinner");
            this.subs.sink = this._seasonService
                .search(this.seasonQuery)
                .subscribe({
                    next: (result: any) => {
                        // this._log.trace("got results: " + JSON.stringify(result));
                        this.model = result;
                        this.seasonQuery.currentPage = result.currentPage;
                        // usSpinnerService.stop("searchSpinner");
                    },
                    error: (data) => {
                        this._log.error(
                            "got an error: " + JSON.stringify(data)
                        );
                        // $scope.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.");
                        // usSpinnerService.stop("searchSpinner");
                    },
                });
        }
    }
    restartSearch() {
        this._log.trace("restartSearch");
        this.seasonQuery.currentPage = 1;
        this.seasonSearch();
    }

    resetSeasonSearch() {
        this._log.trace("resetSeasonSearch()");
        this.seasonQuery = cloneDeep(this.getSeasonQuery());
        this.venue = "";
        this.restartSearch();
    }

    searchVenue(term: any) {
        this._log.trace("searchVenue: " + term);
        return (this.subs.sink = this._venueService
            .search(encodeURIComponent(term))
            .subscribe((res) => {
                // this._log.trace("response: " + JSON.stringify(res));
                return res.data.reference;
            }));
    }
    onVenueSelect($item: any, $model: any, $label: any) {
        this._log.trace("onVenueSelect()");
        this.seasonQuery.venue = $item;
    }
    showGridView() {
        this.showGrid = true;
    }
    ngOnInit(): void {
        this.getEventTypes();
        this.seasonSearch();
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
