<div class="nbc-tag-container">
  <div class="payroll-Indicator tag-container">
    <div id="id_{{name}}" *ngFor="let tag of tags"
         [ngClass]="!isAuthorized?'disabled':''"
         class="tag">
      <span *ngIf="tag?.description"
            class="tag-text">{{tag.description}}</span>
      <i *ngIf="isAuthorized"
         class="tag-remove btn-close"
         (click)="removeTag('positions',tag);"></i>
    </div>
    
    <input name={{name}}
            #input 
           [typeahead]="searchTermObservable$"
           [(ngModel)]="searchTerm"
           typeaheadOptionField={{tagDisplay}}
           [typeaheadMinLength]="0"
           placeholder={{placeholder}}
           class="form-control disabled"
           [isAnimated]="true"
           [typeaheadWaitMs]="200"
           style="width:100px;"
           [disabled]="!isAuthorized"
           (typeaheadOnSelect)="onEventSelected($event,input )">
  </div>
</div>

