import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { forkJoin, Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { TeamService } from "src/app/Shared/Services/team.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { SubSink } from "subsink";
import { EditEventComponent } from "../../../manageEvent/editevent/edit-event.component";
import { DeleteEventModalComponent } from "../delete-event-modal/delete-event-modal.component";

@Component({
    selector: "app-season-setup",
    templateUrl: "./season-setup.component.html",
})
export class SeasonSetupComponent implements OnInit, OnDestroy {
    constructor(
        private log: NbcLoggerService,
        private _teamService: TeamService,
        private _eventServices: EventService,
        private _sharedData: SharedDataService,
        private _modalService: BsModalService,
        private activateRoute: ActivatedRoute
    ) {}
    private subs = new SubSink();
    list2: any[] = [];
    seasonId = window.preload.seasonId || 0;
    newSeasonEvents: any = [{}];
    hasTeams = true;
    // searchVenue$?: Observable<any[]> | undefined;
    searchAwayTeam$: Observable<any[]> | undefined;
    searchHomeTeam$: Observable<any[]> | undefined;
    itemsPerSlide = 10;
    homeTeamTerm = "";
    //venueSearchTerm = "";
    venue: any;
    testing: any[] = [];
    add: any;
    singleSlideOffset = false;
    noWrap = false;
    logoStartDisplay = 0;
    logoEndDisplay = 9;
    awayTeamDrag: any = [];
    homeTeamDrag: any = [];
    event: any;
    pageName = "Season Setup";
    modalOpen: any;

    networks = [];
    awayTeamTerm = "";
    logosTeams: { imageId: string; text?: string }[] = [];
    bsModalRef?: BsModalRef;
    bsDeleteModalRef?: BsModalRef;
    season: any = {};
    selectedEventSummary: any;
    teams: any;
    seasonEventsList: any;
    selectEventData: any = {};
    clonedData;

    getSeasonTeams() {
        this.log.trace("getSeasonTeams called");
        if (this.season.eventType && this.season.eventType.hasTeam === true) {
            this.hasTeams = true;
            this.subs.sink = this._teamService
                .search(this.season.eventType.id, "")
                .subscribe({
                    next: (result) => {
                        this.log.trace("successfully retrieved the season");
                        this.teams = result;
                        this.logosTeams = _.cloneDeep(this.teams);
                    },
                    error: (data) => {
                        this.log.error("got an error: " + JSON.stringify(data));
                        this.log.showError(
                            "Unable to load teams at this time. Please try again later."
                        );
                    },
                });
        } else {
            this.hasTeams = false;
        }
    }

    getSeasonEvents() {
        this.subs.sink = this._eventServices
            .searchBySeasonId(this.seasonId)
            .subscribe({
                next: (data) => {
                    this.log.trace("send back list of events for season");
                    this.seasonEventsList = data.reference;
                },
                error: (data) => {
                    this.log.error("got an error: " + JSON.stringify(data));
                },
            });
    }

    // Public Functions
    showNextLogos() {
        this.logoStartDisplay += 10;
        this.logoEndDisplay += 10;
    }

    showPrevLogos() {
        this.logoStartDisplay -= 10;
        this.logoEndDisplay -= 10;
    }

    searchAwayTeam() {
        this.searchAwayTeam$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.awayTeamTerm);
            }
        ).pipe(
            switchMap((query) => {
                this.awayTeamDrag = [];
                // using github public api to get users by name
                return this._teamService.search(
                    this.season.eventType.id,
                    encodeURIComponent(query)
                );
            })
        );
    }

    searchHomeTeam() {
        this.searchHomeTeam$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.homeTeamTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._teamService.search(
                    this.season.eventType.id,
                    encodeURIComponent(query)
                );
            })
        );
    }

    successDropAway(team, index) {
        this.log.trace("dropping away team");
        this.newSeasonEvents[index].awayTeam = team;
    }

    successDropHome(team, index) {
        this.log.trace("dropping home team");
        this.newSeasonEvents[index].homeTeam = team;
    }

    removeEventAdd($index) {
        this.newSeasonEvents.splice($index, 1);
    }

    addBlankEvent(e) {
        e.preventDefault();
        this.newSeasonEvents.push({});
    }
    check(index, logoEndDisplay, logoStartDisplay) {
        console.log(index, logoEndDisplay, logoStartDisplay);
    }
    cancelAdd(index) {
        this.newSeasonEvents[index] = [];
        this.newSeasonEvents[index].eventName = undefined;
        this.newSeasonEvents[index].venue = undefined;
    }
    openModal(cancelIndex?, index?) {
        if (
            !_.isObject(this.selectEventData) ||
            !_.isObject(this.selectedEventSummary)
        ) {
            return false;
        }

        this.selectEventData["openedFromSeasonSetupPage"] = true;
        if (!this.selectEventData["isTaped"]) {
            this.selectEventData["isTaped"] = false;
        }

        this.log.trace("EventHeader -> Opening modal");

        const initialState: any = {
            self: this,
            eventData: this.selectEventData,
            networks: this.networks,
            seasons: [],
            eventSummary: this.selectedEventSummary,
            isSeasonSetup: true,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
            class: "modal-lg",
        };

        this.bsModalRef = this._modalService.show(
            EditEventComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.modalOpen = false;
            if (cancelIndex >= 0) {
                this.cancelAdd(cancelIndex);
            }
            if (index > -1) {
                this.seasonEventsList[index] = result.event;
            } else {
                this.seasonEventsList.push(result.event);
            }
            window.location.reload();
        });
        return;
    }

    checkAwayTeamData(event, $index) {
        if (!event) {
            this.newSeasonEvents[$index].awayTeam = undefined;
        }
    }

    checkHomeTeamData(event, $index) {
        if (!event) {
            this.newSeasonEvents[$index].homeTeam = undefined;
        }
    }

    addEvent(index) {
        this.log.trace("addEvent");
        const event: any = {
            seasonId: this.seasonId,
            eventTypeId: this.season.eventType.id,
            eventType: this.season.eventType,
            isMobileTimekeepingChecked: true,
        };
        if (this.hasTeams) {
            event.eventName =
                this.newSeasonEvents[index].awayTeam.name +
                " @ " +
                this.newSeasonEvents[index].homeTeam.name;
            event.homeTeam = this.newSeasonEvents[index].homeTeam;
            event.awayTeam = this.newSeasonEvents[index].awayTeam;
            event.venue = this.newSeasonEvents[index].homeTeam.venue;
        } else {
            event.eventName = this.newSeasonEvents[index].eventName;
            event.venue = this.newSeasonEvents[index].venue;
        }

        this.subs.sink = this._eventServices.save(event).subscribe({
            next: (data) => {
                this.seasonEventsList.push(data.reference);
                this.cancelAdd(index);
            },
            error: () => {
                this.log.error("got an error");
                this.log.showError(
                    "Unable to add event at this time. Please try again later."
                );
            },
        });
    }

    getEventSummary(event?: any) {
        this.selectedEventSummary = null;
        // event.id =4
        this.subs.sink = this._eventServices
            .getEventSummary(event.id)
            .subscribe({
                next: (result) => {
                    this.log.trace("successfully retrieved the event summary");
                    this.selectedEventSummary = result.reference;
                    this.openModal();
                },
                error: (data) => {
                    this.log.error("got an error: " + JSON.stringify(data));
                    this.log.showError(
                        "Unable to load event at this time. Please try again later."
                    );
                },
            });
    }

    getEventData(event?: any) {
        this.selectEventData = null;
        this.subs.sink = this._eventServices.getEventInfo(event.id).subscribe({
            next: (result) => {
                this.log.trace("successfully retrieved the event");
                this.selectEventData = result.reference;
                this.openModal();
            },
            error: (data) => {
                this.log.error("got an error: " + JSON.stringify(data));
            },
        });
    }

    openEditEventModal(event, cancelIndex) {
        this.log.trace("Opening modal");
        const index = this.seasonEventsList.indexOf(event);
        this.modalOpen = true;

        if (event.id) {
            forkJoin(
                this._eventServices.getEventSummary(event.id),
                this._eventServices.getEventInfo(event.id)
            ).subscribe(([summaryResponse, eventInfoResponse]) => {
                this.selectedEventSummary = summaryResponse.reference;
                this.selectEventData = eventInfoResponse.reference;
                this.openModal(cancelIndex, index);
            });
        } else {
            event.statusCode = {};

            if (this.hasTeams) {
                event.seasonId = this.seasonId;
                event.eventTypeId = this.season.seasonType;
                event.eventType = this.season.eventType;
                event.eventName =
                    event.eventName ||
                    event.awayTeam.name + " @ " + event.homeTeam.name;
                event.venue = event.homeTeam.venue;
            } else {
                event.eventTypeId = this.season.seasonType;
                event.eventType = this.season.eventType;
                event.seasonId = this.seasonId;
            }
            event.isMobileTimekeepingChecked = true;
            event.facility = "";
            event.eventLocation = "";
            event.timekeepingReminder = "";
            event.eventTierSetup = {
                networkGroupId: null,
                eventExecutionStatus: "None",
            };
            event.additionalLocations = []; // SCOR-19041
            event.financials = {
                talentAirFare: null,
                productionAirFare: null,
                engineersAirFare: null,
                productionHotelFare: null,
                engineersHotelFare: null,
                budgetedHours: [],
            };
            this.selectEventData = event;
            this.selectedEventSummary = {};
            this.openModal(cancelIndex, index);
        }
    }

    eraseAfter() {
        this.newSeasonEvents.awayTeam = undefined;
        this.newSeasonEvents.homeTeam = undefined;
        this.newSeasonEvents.eventName = undefined;
        this.newSeasonEvents.venue = undefined;
    }

    openDeleteEventModal(event) {
        const index = this.seasonEventsList.indexOf(event);
        this.log.trace("Opening modal");
        this.modalOpen = true;
        const initialState: any = {};
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsDeleteModalRef = this._modalService.show(
            DeleteEventModalComponent,
            modalOptions
        );
        this.bsDeleteModalRef.content.onSync.subscribe((result) => {
            if (result) {
                this.deleteSeason(event, index);
            }
        });
    }

    deleteSeason(event?, index?) {
        this.subs.sink = this._eventServices
            .removeEventFromSeason(event.id)
            .subscribe({
                next: (res) => {
                    if (index > -1) {
                        this.seasonEventsList.splice(index, 1);
                    }
                },
                error: (data) => {
                    this.log.error("got an error" + data);
                    this.log.showError(
                        "Unable to delete event at this time. Please try again later."
                    );
                },
            });
    }

    assignAwayTeamDnd(event: CdkDragDrop<string[]>, index) {
        console.log(event);
        this.newSeasonEvents[index].awayTeam = event.item.data;
    }

    assignHomeTeamDnd(event: CdkDragDrop<string[]>, index) {
        console.log(event);
        this.newSeasonEvents[index].homeTeam = event.item.data;
    }

    ngOnInit(): void {
        this._sharedData.setCurrentPage("Season Setup");
        this.subs.sink = this._sharedData
            .getCurrentSeasonId()
            .subscribe((seasonId) => {
                this.seasonId = seasonId;
                this.getSeasonEvents();
            });

        this.subs.sink = this._sharedData
            .getSeasonData()
            .subscribe((season) => {
                this.season = season;
                this.getSeasonTeams();
            });
        this.searchHomeTeam();
        this.searchAwayTeam();
        this.searchAwayTeam();
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
class Teams {
    constructor(public team: string) {}
}
