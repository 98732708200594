import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { CrewItemService } from "src/app/Shared/Services/crew-item.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import * as _ from "lodash";
import { forkJoin, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
//import { error } from "@angular/compiler/src/util";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { DepartmentService } from "src/app/Shared/Services/department.service";
import { WorkActivityService } from "src/app/Shared/Services/work-activity.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import * as moment from "moment";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { PositionRequestComponent } from "./position-request/position-request.component";
import { modalUpsertPosition } from "./component/upsert-position/modal-upsert-position";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { ModalScheduleConflictComponent } from "./component/schedule-conflict/modal-schedule-conflict.component";
import { crewItemConflictFactory } from "./factory/crew-item-conflict-factory";
import { ModalTierRatecardComponent } from "./component/tier-ratecard/modal-tier-ratecard.component";
import { CopyPositionsComponent } from "./component/copy-positions/copy-positions.component";
//import { CrewContactSearchFlyoutComponent } from "./component/crew-contact-search-flyout/crew-contact-search-flyout.component";
import { ModalEditPositionCommentsComponent } from "./component/position-comments/modal-Edit-Position-Comments.component";
import { SsoStatusReportComponent } from "./report/sso-status/sso-status.component";
import { ReportService } from "../../Shared/Services/report.service";
import { NbcFileDownloadComponent } from "../../Shared/components/nbc-file-download/nbc-file-download.component";
import { ExportModalCustomizeCrewWorkComponent } from "./report/export-modal-customize-crew-work/export-modal-customize-crew-work.component";
import { CrewChangeExportComponent } from "./report/crew-change-export/crew-change-export.component";
import { SubSink } from "subsink";
import { rest } from "lodash";

@Component({
    selector: "crew-page",
    templateUrl: "./crew-page.component.html",
})
export class CrewPageComponent implements OnInit, OnDestroy {
    constructor(
        private _sharedData: SharedDataService,

        private _modalService: BsModalService,
        private _crewItemService: CrewItemService,
        private _remotesQueryService: RemotesQueryService,
        private _employeeTypeService: EmployeeTypeService,
        private _eventService: EventService,
        private _departmentService: DepartmentService,
        private _workActivityService: WorkActivityService,
        private _contactService: ContactService,
        private _eventBus: EventBusService,
        private _reportService: ReportService,
        private modalService: BsModalService,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    private subs = new SubSink();
    @ViewChild(NbcFileDownloadComponent) child;

    isFullScreenMode = false;
    isCrewHeaderBeingLoaded = false;
    bsModalRef?: BsModalRef;
    bsScheduleRef?: BsModalRef;
    isSummaryExpanded = false;
    isPageInit = false;

    fileData: any;
    previewUrl: any = "";
    fileUploadProgress: string | undefined;
    uploadedFilePath: string | undefined;
    validExcelFileTypes = [".xlsx"];
    //tierDataDeferred = $q.defer(),

    /**
     * Event data
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @type {Object}
     */
    eventData: any = {};

    /**
     * Event id
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @type {Int}
     */
    eventId: any | undefined;

    /**
     * Item conflict
     * @private
     * @type {Object}
     * @memberOf score.controllers.EventCrewingController
     */
    itemConflict: any;

    /**
     * Selected position list items
     * @private
     * @type {Object[]}
     * @memberOf score.controllers.EventCrewingController
     */
    selectedPositionListItems: any;

    /**
     * Employee Types
     * @type {Array}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    employeeTypes: any[] = [];

    clonedEmployeeTypes: any[] = [];

    /**
     * Display Bulk Update
     * @type {Boolean}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    displayBulkUpdate = false;

    /**
     * Crew Item Query
     * @type {Object}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    crewItemQuery: any = {};

    /**
     * Loaded record flag
     * @type {Boolean}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    loaded = false;

    /**
     * Schedule Dates
     * @type {Array}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    scheduleDates: any = [];

    /**
     * Task Items
     * @type {Array}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    taskItems: any[] = [];

    broadcastingDates: any[] = [];

    //all tiered position w.r.t. Event Tier
    tieredPositions: any[] = [];

    groupUpdateTierPositions: any[] = [];

    /*Determining if event is tiered*/
    isTieredEvent = false;
    searchFlyoutPage = false;
    //this.exportEmergencyContactList = [];
    tierRateCardComment = null;
    bulkUpdateOnlyAtlSelected = false;

    bulkUpdateOnlyBtlSelected = false;

    //SCOR-19041
    additionalLocations!: any[];
    additionalLocationFilter: any[] = [];
    updateAdditionalLocationsQuery: any = {
        eventId: 0,
        crewPositionIds: "",
        additionalLocationId: 0,
    };
    bulkAdditionalLocationId = 0;
    bulkAddLocationSelect!: any;

    tieredEvntSortBy: any = "";

    chartData: any | undefined;

    departments: any[] = [];

    positionListItems: any[] = [];

    crewItemStatuses: any[] | undefined;
    bulkEditFlight = false;
    updateSubmit = false;
    bulkEditCategory: any = {
        value: "-1",
    };
    selectedEmployeeType: any = {};
    selectedRateCard: any = null;
    bulkEditFlightLocal = "local";
    bulkEditFlightDestination = "";
    bulkEditFlightDepature = "";
    bulkEditFlightArrival = "";
    selectedUnionDesignation = "";
    customRateCard: any = null;

    tempInBoundArrAirportCode = null;
    tempOutBoundArrAirportCode = null;
    tempInBoundDepAirportCode = null;
    tempOutBoundDepAirportCode = null;
    toggleFlag = true;

    filterDateItem: any = {};

    //TODO:AngularUpgrade Crew

    venueAirportCode: any | undefined;
    totalRecords: number | undefined;
    eventSummary: any = {};
    positionTypes: any[] = [];

    //global variables for windows
    childwindows: any = [];
    newWindow = null;

    crewItemByQuery$!: Observable<any>;
    employeeTypes$!: Observable<any>;

    /**
     * Group Update Options
     * @type {Boolean}
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    groupUpdate: any = {
        options: [
            {
                value: "Employee Type",
                description: "Employee Type / Rate Code",
            },
            { value: "Work Schedule", description: "Work Schedule" },
            { value: "Unassign Contact", description: "Unassign Contact" },
            { value: "Delete Position", description: "Delete Position" },
            {
                value: "Location",
                description: "Location",
            },
        ],
    };

    TierRateOptions: any[] = [
        { value: "Employee Type", description: "Employee Type / Rate Code" },
        { value: "Work Schedule", description: "Work Schedule" },
        { value: "Unassign Contact", description: "Unassign Contact" },
        { value: "Delete Position", description: "Delete Position" },
        { value: "Tier Rate Card", description: "Tier Rate Card" },
        { value: "Location", description: "Location" },
    ];

    /**
     * Sort By
     * @memberof score.controllers.EventCrewingController
     * @public
     * @type {Object[]}
     */

    sortOptions: any[] = [];

    sortBy: any[] = [
        { label: "Contacts A-Z", sortBy: "contact" },
        { label: "Contacts Z-A", sortBy: "-contact" },
        { label: "Positions A-Z", sortBy: "position" },
        { label: "Positions Z-A", sortBy: "-position" },
    ];

    tieredEventSortBy: any[] = [
        { label: "Contacts A-Z", sortBy: "contact" },
        { label: "Contacts Z-A", sortBy: "-contact" },
        { label: "Positions A-Z", sortBy: "position" },
        { label: "Positions Z-A", sortBy: "-position" },
        { label: "Tier Rate Card", sortBy: "ratecode" },
    ];

    positionRequestOptions: any[] = [
        { value: "Y", description: "In-Progress" },
        { value: "N", description: "Awaiting Request" },
    ];

    pageSize: any[] = [10, 25, 50, 100, 250];

    addPositionRequest() {
        const positionRequest = {
            value: "Position Request",
            description: "Position Request",
        };
        if (this.eventData.canPositionRequest === true) {
            this.groupUpdate.options.push(positionRequest);
            this.TierRateOptions.push(positionRequest);
        }
    }

    /**
     * Get Crew Item Query
     * @private
     * @memberOf score.controllers.EventCrewingController
     */

    getCrewItemQuery() {
        this.log.trace("CrewPage -> getCrewItemQuery");
        this.crewItemQuery = this._remotesQueryService.getCrewItemQuery();
        this._sharedData
            .getCurrentEventId()
            .subscribe(
                (eventNumber) => (this.crewItemQuery.eventId = eventNumber)
            );
    }

    /*
     * we need different sort options for tier rate card. Selecting based on the type of an event
     */
    getSortOptions() {
        if (this.eventData && !_.isEmpty(this.eventData.tier)) {
            this.sortOptions = this.tieredEvntSortBy;
        } else {
            this.sortOptions = this.sortBy;
        }
    }

    /**
     * Get Crew Item Header
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires loadCharts
     */
    getCrewItemHeader() {
        if (!this.isSummaryExpanded && !this.isPageInit) return;
        this.log.trace("getCrewItemHeader()");
        this.isCrewHeaderBeingLoaded = true;
        this.subs.sink = this._crewItemService
            .getCrewItemHeader(this.eventId)
            .subscribe({
                next: (result) => {
                    // this.log.trace("getCrewItemHeader got results: " + JSON.stringify(result));
                    this.chartData = result.reference;
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:getCrewItemHeader",
                            "chartInit",
                            this.chartData
                        )
                    );
                    this.isCrewHeaderBeingLoaded = false;
                },
                error: (err) => {
                    this.log.error(
                        "getCrewItemHeader got an error: " + JSON.stringify(err)
                    );
                    this.log.showError(
                        "Unable to get Crew Header at this time. Please try again later."
                    );
                    this.isCrewHeaderBeingLoaded = false;
                },
            });
        this.isPageInit = false;
    }

    /**
     * Get Departments
     * @memberof score.controllers.crewSortFiltersController
     * @private
     */
    getDepartments() {
        this.log.trace("getDepartments");

        this.subs.sink = this._departmentService.getDepartments().subscribe({
            next: (result) => {
                this.departments = result.reference;
            },
            error: (err) => {
                this.log.error("got an error: " + JSON.stringify(err));
                this.log.showError(
                    "Unable to get departments. Please try again later."
                );
            },
        });
    }

    /**
     * Bulk Update Event Positions
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @param {Object[]} positionListItem crew position list item
     * @param {Function} successCallbackFn success callback function
     */
    bulkUpdateEventPositions(positionListItems: any, successCallbackFn: any) {
        this.log.trace("bulkUpdateEventPositions");
        this.subs.sink = this._eventService
            .bulkUpdateEventPositions(this.eventId, positionListItems)
            .subscribe({
                next: (result) => {
                    if (typeof successCallbackFn === "function") {
                        successCallbackFn();
                    }
                },
                error: (err) => {
                    this.log.error("got an error");
                    this.log.showError(
                        "Unable to save data at this time. Please try again later."
                    );
                    this.getCrewItemByQuery(true, false);
                },
            });
    }

    /**
     * Get Crew Item Statuses
     * @memberof score.controllers.crewSortFiltersController
     * @private
     */
    getCrewItemStatuses() {
        this.log.trace("getCrewItemStatuses");
        this.crewItemStatuses = this._remotesQueryService.getCrewItemStatuses();
    }

    /**
   * Get Employee Types
   * @private 
   * @memberOf score.controllers.EventCrewingController
   
   */
    getEmployeeTypes() {
        if (!this.employeeTypes$) this.getEmployeeTypes$();
        this.employeeTypes$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result: any) => {
                    this.employeeTypeResponseHandler(result);
                },
                error: (err) => {
                    this.log.error(
                        "Error while fetching employee types in crew page " +
                            JSON.stringify(err)
                    );
                    this.log.showError("Failed to load Employee Types");
                },
            });
    }
    getEmployeeTypes$() {
        this.employeeTypes$ = this._employeeTypeService.getAllEmployeeTypes();
        return this.employeeTypes$;
    }
    employeeTypeResponseHandler(result: any) {
        this.employeeTypes = result.reference;
        this.clonedEmployeeTypes = _.cloneDeep(result.reference);
    }

    /**
     * Get Work Activities
     * @private
     * @memberOf score.controllers.EventCrewingController
     */
    getWorkActivities() {
        this.subs.sink = this._workActivityService
            .getWorkActivties()
            .subscribe({
                next: (result: any) => {
                    this.taskItems = result.reference;
                    const additionalWorkActivityProps = {
                        isChecked: false,
                        disabled: false,
                    };
                    this.taskItems.forEach(function (item) {
                        _.merge(item, additionalWorkActivityProps);
                    });
                },
                error: (err) => {
                    this.log.error(
                        "Error while fetching getWorkActivities" +
                            JSON.stringify(err)
                    );
                    this.log.showError("Failed to load getWorkActivities");
                },
            });
    }

    /**
     * Check Air Notification Travel Activities
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @param {Object[]} positionListItems crew position list item
     */
    checkAirNotificationTravelActivites(positionListItems: any) {
        const iterator = (positionListItem: any) => {
            let index = 0;
            const workScheduleDatesLength =
                    positionListItem.eventPosition.workScheduleDates.length,
                forEachCallback = (workActivity: any) => {
                    if (workActivity.code === "TVL") {
                        positionListItem.eventPosition.hasTravelIn = true;
                    }
                    if (workActivity.code === "RET") {
                        positionListItem.eventPosition.hasTravelOut = true;
                    }
                };
            for (index; index < workScheduleDatesLength; index++) {
                _.forEach(
                    positionListItem.eventPosition.workScheduleDates[index]
                        .plannedWorkActivities,
                    forEachCallback
                );
            }
        };
        if (positionListItems.length) {
            let topLevelIndex = 0;
            const positionsLength = positionListItems.length;
            for (
                topLevelIndex;
                topLevelIndex < positionsLength;
                topLevelIndex++
            ) {
                positionListItems[topLevelIndex].eventPosition.hasTravelIn =
                    false;
                positionListItems[topLevelIndex].eventPosition.hasTravelOut =
                    false;
                iterator(positionListItems[topLevelIndex]);
            }
        } else {
            positionListItems.eventPosition.hasTravelIn = false;
            positionListItems.eventPosition.hasTravelOut = false;
            iterator(positionListItems);
        }
    }

    /**
     * Bulk Update In Bound Travel
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkUpdateInBoundTravel() {
        let index = 0;
        const selectedPositionListItemsLength =
            this.selectedPositionListItems.length;
        for (index; index < selectedPositionListItemsLength; index++) {
            if (this.bulkEditFlight) {
                this.bulkEditFlightArrival =
                    this.bulkEditFlightArrival === undefined
                        ? ""
                        : this.bulkEditFlightArrival;
                this.bulkEditFlightDepature =
                    this.bulkEditFlightDepature === undefined
                        ? ""
                        : this.bulkEditFlightDepature;

                this.selectedPositionListItems[
                    index
                ].eventPosition.isInBoundTraveling = true;
                this.selectedPositionListItems[
                    index
                ].eventPosition.inBoundArrAirportCode =
                    this.bulkEditFlightArrival;
                this.selectedPositionListItems[
                    index
                ].eventPosition.inBoundDepAirportCode =
                    this.bulkEditFlightDepature;
            } else if (this.bulkEditFlightLocal) {
                this.selectedPositionListItems[
                    index
                ].eventPosition.isInBoundTraveling = false;
                this.selectedPositionListItems[
                    index
                ].eventPosition.inBoundLocalTravel = this.bulkEditFlightLocal;
            }
        }

        this.bulkUpdateEventPositions(this.selectedPositionListItems, () => {
            this.getCrewItemHeader();
            // this.getCrewItemByQuery(false,false);
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:bulkUpdateInBoundTravel",
                    "bulkUpdateGridRows",
                    this.selectedPositionListItems
                )
            );
        });
        return true;
    }

    /**
     * Bulk Update Out Bound Travel
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkUpdateOutBoundTravel() {
        let index = 0;
        const selectedPositionListItemsLength =
            this.selectedPositionListItems.length;
        for (index; index < selectedPositionListItemsLength; index++) {
            if (this.bulkEditFlight) {
                this.bulkEditFlightArrival =
                    this.bulkEditFlightArrival === undefined
                        ? ""
                        : this.bulkEditFlightArrival;
                this.bulkEditFlightDepature =
                    this.bulkEditFlightDepature === undefined
                        ? ""
                        : this.bulkEditFlightDepature;
                this.selectedPositionListItems[
                    index
                ].eventPosition.isOutBoundTraveling = true;
                this.selectedPositionListItems[
                    index
                ].eventPosition.outBoundArrAirportCode =
                    this.bulkEditFlightArrival;
                this.selectedPositionListItems[
                    index
                ].eventPosition.outBoundDepAirportCode =
                    this.bulkEditFlightDepature;
            } else if (this.bulkEditFlightLocal) {
                this.selectedPositionListItems[
                    index
                ].eventPosition.isOutBoundTraveling = false;
                this.selectedPositionListItems[
                    index
                ].eventPosition.outBoundLocalTravel = this.bulkEditFlightLocal;
            }
        }

        this.bulkUpdateEventPositions(this.selectedPositionListItems, () => {
            this.getCrewItemHeader();
            //this.getCrewItemByQuery$();
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:bulkUpdateInBoundTravel",
                    "bulkUpdateGridRows",
                    this.selectedPositionListItems
                )
            );
        });
        return true;
    }

    //SCOR-19041
    bulkUpdateAdditionalLocation() {
        //if (!this.bulkAddLocationSelect)
        //  return;
        this.bulkAdditionalLocationId = this.bulkAddLocationSelect
            ? this.bulkAddLocationSelect.locationId
            : -1;
        const cIds: any[] = [];
        let item: any; //TODO : Revist the item
        for (item of this.selectedPositionListItems) {
            cIds.push(item.id);
        }

        this.updateAdditionalLocationsQuery.eventId = item.eventId;
        this.updateAdditionalLocationsQuery.crewPositionIds = cIds.join(",");
        this.updateAdditionalLocationsQuery.additionalLocationId =
            this.bulkAdditionalLocationId;

        this.subs.sink = this._crewItemService
            .sendAdditionalLocations(
                item.eventId,
                this.updateAdditionalLocationsQuery
            )
            .subscribe({
                next: (result: any) => {
                    if (
                        !this.bulkAdditionalLocationId ||
                        this.bulkAdditionalLocationId <= 0
                    ) {
                        for (const listItem of this.selectedPositionListItems) {
                            listItem.additionalLocationId = null;
                            listItem.additionalLocationName = "";
                        }
                    } else {
                        for (const listItem of this.selectedPositionListItems) {
                            listItem.additionalLocationId =
                                this.bulkAdditionalLocationId;
                            listItem.additionalLocation = _.find(
                                this.additionalLocations,
                                {
                                    locationId: this.bulkAdditionalLocationId,
                                }
                            );
                            listItem.additionalLocationName =
                                listItem.additionalLocation.locationName;
                        }
                    }
                    this.bulkUpdateEventPositions(
                        _.clone(this.selectedPositionListItems),
                        () => {
                            this.getCrewItemHeader();
                            this._eventBus.emit(
                                new EmitEvent(
                                    "crew-page:sendAirTravelNotification",
                                    "bulkUpdateGridRows",
                                    this.selectedPositionListItems
                                )
                            );
                        }
                    );
                    this.bulkAdditionalLocationId = 0;
                    this.bulkAddLocationSelect = {};
                },
                error: (err) => {
                    this.log.error("Error  " + JSON.stringify(err));
                },
            });
    }

    /**
     * Bulk Update Employee Type
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkUpdateEmployeeType() {
        let updateResult; //for min wage - to retain bulk options instead of hiding the options when min wage critiera is not satisfied

        let index = 0;
        const selectedPositionListItemsLength =
            this.selectedPositionListItems.length;
        const title = "Minimum Wage",
            message = "The entered rate is less than the minimum wage.";
        //to highlight the border in yellow for min wage
        for (index; index < selectedPositionListItemsLength; index++) {
            this.selectedPositionListItems[index].highlight = true;
        }

        let validRate = true;
        if (!_.isEmpty(this.selectedRateCard)) {
            if (
                this.selectedRateCard.id != "FLAT DAY" &&
                this.selectedRateCard.billingRate != null &&
                this.selectedRateCard.billingRate < this.eventData.minimumWage
            ) {
                validRate = false;
                this.displayBulkUpdate = true;
                this.bulkEditCategory.value = "Employee Type";

                this.openTimesheetWarningModal(title, message);
                updateResult = false;
            } else if (
                this.selectedRateCard.id != "FLAT DAY" &&
                this.customRateCard != null &&
                this.customRateCard < this.eventData.minimumWage
            ) {
                validRate = false;
                this.displayBulkUpdate = true;
                this.bulkEditCategory.value = "Employee Type";

                this.openTimesheetWarningModal(title, message);
                updateResult = false;
            } else if (
                this.selectedRateCard.id === "00" &&
                this.customRateCard > 250
            ) {
                validRate = false;
                this.log.showError(
                    "Hourly rates of over $250.00 must be entered by Payroll Team, please enter $1.00 as the rate and notify payroll of what correct rate should be."
                );
                updateResult = true;
            }
        }

        if (validRate) {
            updateResult = true;
            index = 0; //resetting to 0 as this same index is being used for min wage validation.
            for (index; index < selectedPositionListItemsLength; index++) {
                this.selectedPositionListItems[index].highlight = false;

                if (
                    this.isTieredEvent &&
                    this.selectedPositionListItems[index].eventPosition
                        .category === "BTL"
                ) {
                    this.selectedPositionListItems[index].customRate =
                        undefined;
                    this.selectedPositionListItems[index].customRateExeedsBy =
                        undefined;
                }

                if (_.isEmpty(this.selectedEmployeeType)) {
                    this.selectedPositionListItems[
                        index
                    ].eventPosition.employeeType = null;
                    //if employee type is emplty, rate code needs to be empty.
                    this.selectedPositionListItems[
                        index
                    ].eventPosition.rateCode = null;
                } else {
                    this.selectedPositionListItems[
                        index
                    ].eventPosition.employeeType = this.selectedEmployeeType;

                    if (
                        this.selectedEmployeeType.description.toLowerCase() ===
                        "vendor"
                    ) {
                        this.selectedPositionListItems[
                            index
                        ].eventPosition.rateCode = null;
                    } else {
                        if (!_.isEmpty(this.selectedRateCard)) {
                            this.selectedPositionListItems[
                                index
                            ].eventPosition.rateCode = this.selectedRateCard;
                            if (
                                this.selectedRateCard.id === "00" ||
                                this.selectedRateCard.id === "FLAT DAY"
                            ) {
                                this.selectedPositionListItems[
                                    index
                                ].customRate = this.customRateCard;
                            }
                        } else {
                            this.selectedPositionListItems[
                                index
                            ].eventPosition.rateCode = null;
                        }
                    }
                }
            }

            const selectedItems = _.clone(this.selectedPositionListItems);
            this.bulkUpdateEventPositions(selectedItems, () => {
                this.getCrewItemHeader();
                this._eventBus.emit(
                    new EmitEvent(
                        "crew-page:bulkUpdateEmployeeType",
                        "bulkUpdateGridRows",
                        selectedItems
                    )
                );
            });
        }

        return updateResult;
    }

    tierRateCardSelect() {
        this.customRateCard = null;
    }

    bulkUpdateTieredPositions() {
        this.bulkupdate_TierRateAmount(
            this.selectedPositionListItems,
            this.selectedRateCard
        );
        if (this.customRateCard) {
            this.customRateCard = null;
        }
    }

    bulkupdate_TierRateAmount(
        selectedPositionListItems: any,
        selectedRateCard: any
    ) {
        if (!selectedPositionListItems || selectedPositionListItems.length == 0)
            return;

        if (!_.isEmpty(selectedPositionListItems)) {
            for (
                let index = 0;
                index < selectedPositionListItems.length;
                index++
            ) {
                if (
                    _.isEmpty(
                        selectedPositionListItems[index].eventPosition
                            .employeeType
                    )
                ) {
                    const msg =
                        "Employee Types must be selected before a Tiered Rate Card can be entered on a position";
                    this.showValidationErrorModal(
                        "Tier Rate Card",
                        msg,
                        true,
                        this.cancelBulkUpdate,
                        null,
                        "Proceed",
                        null,
                        this.cancelBulkUpdate,
                        null
                    );
                    return;
                }
            }
        }

        const rateCode = {
            description: selectedRateCard.desc,
            id: selectedRateCard.billing_code,
            name: selectedRateCard.desc,
        };

        for (let index = 0; index < selectedPositionListItems.length; index++) {
            if (
                !_.isEmpty(this.selectedRateCard) &&
                this.selectedRateCard.id != null
            ) {
                selectedPositionListItems[index].posTierId =
                    this.selectedRateCard.positionTiers[0].currentRates.posTierId;
                selectedPositionListItems[index].eventPosition.posTierId =
                    this.selectedRateCard.positionTiers[0].currentRates.posTierId;
                selectedPositionListItems[index].eventPosition.rateCode =
                    rateCode;
                selectedPositionListItems[index].customRate =
                    this.customRateCard;
                selectedPositionListItems[index].tierRateCardComment =
                    this.tierRateCardComment;
                if (
                    selectedRateCard.position.description !== null &&
                    selectedRateCard.position.description !== ""
                ) {
                    selectedPositionListItems[
                        index
                    ].eventPosition.jobTitleDetail =
                        selectedPositionListItems[
                            index
                        ].eventPosition.position.description;
                    selectedPositionListItems[index].eventPosition.position =
                        selectedRateCard.position;
                } else {
                    selectedPositionListItems[
                        index
                    ].eventPosition.jobTitleDetail = "";
                }
            } else {
                selectedPositionListItems[index].eventPosition.rateCode = null;
                selectedPositionListItems[index].customRate = null;
                selectedPositionListItems[index].customRateExeedsBy = null;
                selectedPositionListItems[index].posTierId = null;
                selectedPositionListItems[index].eventPosition.posTierId = null;
                selectedPositionListItems[index].tierRateCardComment = null;
                selectedPositionListItems[index].eventPosition.position = null;
                if (
                    selectedPositionListItems[
                        index
                    ].eventPosition.existingRateCard.startsWith("TR_")
                ) {
                    selectedPositionListItems[
                        index
                    ].eventPosition.jobTitleDetail = "";
                }
            }
        }

        const selectedItems = _.clone(this.selectedPositionListItems);
        this.bulkUpdateEventPositions(selectedItems, () => {
            this.getCrewItemHeader();
            // this.getCrewItemByQuery();
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:bulkUpdateWorkSchedule",
                    "getRelatedCrewPositions",
                    selectedItems
                )
            );
            // this._eventBus.emit(new EmitEvent("crew-page:bulkupdate_TierRateAmount", "bulkUpdateGridRows", this.selectedPositionListItems));
        });
    }

    bulkUpdateOnboardingPositionRequests(selectedPositionListItems: any) {
        if (!selectedPositionListItems || selectedPositionListItems.length == 0)
            return;
        const positionRequests: any[] = [];
        const primaryContacts: any[] = [];
        const unConfirmedPositionStatusCount = 0;
        const groupMessage = "";
        if (selectedPositionListItems) {
            for (let i = 0; i < selectedPositionListItems.length; i++) {
                if (selectedPositionListItems[i].primaryContact) {
                    primaryContacts.push(
                        selectedPositionListItems[i].primaryContact
                    );
                }
            }
        }
        const flags: any[] = [],
            distinctContacts: any[] = [];
        const l: number = primaryContacts.length;
        let i: number;
        for (i = 0; i < l; i++) {
            if (flags[primaryContacts[i].id]) continue;
            flags[primaryContacts[i].id] = true;
            distinctContacts.push(primaryContacts[i].id);
        }

        if (!_.isEmpty(selectedPositionListItems)) {
            for (
                let index = 0;
                index < selectedPositionListItems.length;
                index++
            ) {
                let msg = "";
                if (
                    selectedPositionListItems[index].primaryContact &&
                    selectedPositionListItems[index].primaryContact
                        .isNonVendor &&
                    !selectedPositionListItems[index].primaryContact
                        .isHrVerified &&
                    selectedPositionListItems[index].eventPosition &&
                    selectedPositionListItems[index].eventPosition.status.id ==
                        "C" &&
                    distinctContacts.length == primaryContacts.length &&
                    selectedPositionListItems[index]
                        .isOnboardingPendingContact == "N" &&
                    selectedPositionListItems[index].eventPosition.position
                        .groupType.description !== "Talent"
                ) {
                    if (
                        selectedPositionListItems[index].primaryContact
                            .activeRegistrationStatus == "N" &&
                        selectedPositionListItems[index].primaryContact.email !=
                            ""
                    ) {
                        positionRequests.push(selectedPositionListItems[index]);
                    } else {
                        msg =
                            "One or more of the selected positions have an existing Request in Progess or they are missing an Email Address.  This request will be cancelled";
                        this.showValidationErrorModal(
                            "Error While Bulk Update Position Request",
                            msg,
                            true,
                            this.cancelBulkUpdate,
                            null,
                            "OK",
                            null,
                            this.cancelBulkUpdate,
                            null
                        );
                        return;
                    }
                } else if (distinctContacts.length !== primaryContacts.length) {
                    if (unConfirmedPositionStatusCount > 0) {
                        const isAlreadyPrinted = _.includes(
                            groupMessage,
                            "One or more of the selected positions have an Unconfirmed position."
                        );
                        if (!isAlreadyPrinted)
                            msg =
                                "One or more of the selected positions have an Unconfirmed position.  This request will be cancelled";
                        this.showValidationErrorModal(
                            "Error While Bulk Update Position Request",
                            msg,
                            true,
                            this.cancelBulkUpdate,
                            null,
                            "OK",
                            null,
                            this.cancelBulkUpdate,
                            null
                        );
                        return;
                    } else {
                        msg =
                            "Same Contact is assigned to two or more position. We cannot continue with duplicate contact";
                        if (groupMessage !== "") {
                            msg =
                                groupMessage +
                                "  This request will be cancelled";
                        }
                        this.showValidationErrorModal(
                            "Error While Bulk Update Position Request",
                            msg,
                            true,
                            this.cancelBulkUpdate,
                            null,
                            "OK",
                            null,
                            this.cancelBulkUpdate,
                            null
                        );
                        return;
                    }
                } else {
                    const isAlreadyPrinted = _.includes(
                        groupMessage,
                        "One or more of the selected positions do not meet the required criteria."
                    );
                    if (
                        selectedPositionListItems[index].primaryContact
                            ?.activeRegistrationStatus == "Y" ||
                        selectedPositionListItems[index].primaryContact
                            ?.email == "" ||
                        selectedPositionListItems[index].primaryContact
                            ?.email == null ||
                        selectedPositionListItems[index].primaryContact
                            ?.email == undefined
                    ) {
                        msg =
                            "One or more of the selected positions have an existing Request in Progess or they are missing an Email Address.  This request will be cancelled";
                        this.showValidationErrorModal(
                            "Error While Bulk Update Position Request",
                            msg,
                            true,
                            this.cancelBulkUpdate,
                            null,
                            "OK",
                            null,
                            this.cancelBulkUpdate,
                            null
                        );
                        return;
                    }
                    if (
                        selectedPositionListItems[index].eventPosition.position
                            .groupType.description === "Talent"
                    ) {
                        msg =
                            "Talent positions cannot be requested. Please unselect the position(s) and request again.";
                        this.showValidationErrorModal(
                            "Error While Bulk Update Position Request",
                            msg,
                            true,
                            this.cancelBulkUpdate,
                            null,
                            "OK",
                            null,
                            this.cancelBulkUpdate,
                            null
                        );
                        return;
                    }
                    if (!isAlreadyPrinted)
                        msg =
                            "One or more of the selected positions do not meet the required criteria.";
                    if (groupMessage !== "") {
                        if (unConfirmedPositionStatusCount > 0) {
                            msg +=
                                groupMessage +
                                "  This request will be cancelled";
                        } else {
                            msg =
                                groupMessage +
                                "  This request will be cancelled";
                        }
                    } else {
                        msg += "  This request will be cancelled";
                    }
                    this.showValidationErrorModal(
                        "Error While Bulk Update Position Request",
                        msg,
                        true,
                        this.cancelBulkUpdate,
                        null,
                        "OK",
                        null,
                        this.cancelBulkUpdate,
                        null
                    );
                    return;
                }
            }
            if (selectedPositionListItems.length == positionRequests.length) {
                this.openEditPositionRequestModal(positionRequests);
            }
        }
    }

    /**
     * Bulk Update Work Schedule
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkUpdateWorkSchedule() {
        let index = 0,
            i = 0,
            j;
        const selectedPositionListItemsLength =
                this.selectedPositionListItems.length,
            forEachCallback = (element: any) => {
                element.isPlanned = true;
            };

        let duplicateContact = false;
        const contactlist: any[] = [];
        const positionlist: any[] = [];
        const scheduledatelist: any[] = [];

        for (i; i < selectedPositionListItemsLength; i++) {
            if (
                this.selectedPositionListItems[i].primaryContact &&
                this.selectedPositionListItems[i].primaryContact.id
            ) {
                if (
                    contactlist.includes(
                        this.selectedPositionListItems[i].primaryContact.id
                    )
                ) {
                    duplicateContact = true;
                } else {
                    contactlist.push(
                        this.selectedPositionListItems[i].primaryContact.id
                    );
                }
                positionlist.push(
                    this.selectedPositionListItems[i].id +
                        "-" +
                        this.selectedPositionListItems[i].primaryContact.id
                );
            }
        }

        const schDates = this.scheduleDates;
        let schDateIndexs = 0;
        const schDateLengths = schDates.length;

        for (schDateIndexs; schDateIndexs < schDateLengths; schDateIndexs++) {
            if (schDates[schDateIndexs].isChecked) {
                scheduledatelist.push(
                    moment(schDates[schDateIndexs].date).format("yyyy-MM-DD")
                );
            }
        }

        const selectedTasks: any[] = _.filter(this.taskItems, {
            isChecked: true,
        });

        if (duplicateContact && selectedTasks.length > 0) {
            this.log.showError(
                "Overlapping activity cannot exist in the selected Positions for the same contact"
            );
        } else {
            this.subs.sink = this._crewItemService
                .getCheckBulkConflict(
                    this.eventId,
                    positionlist,
                    scheduledatelist
                )
                .subscribe({
                    next: (result: any) => {
                        this.itemConflict = result.reference;
                        if (this.itemConflict && selectedTasks.length > 0) {
                            this.log.showError(
                                "Overlapping activity cannot exist in the selected Positions for the same contact"
                            );
                        } else {
                            for (
                                index;
                                index < selectedPositionListItemsLength;
                                index++
                            ) {
                                let workScheduleIndex = 0;
                                const workScheduleDateLength =
                                    this.selectedPositionListItems[index]
                                        .eventPosition.workScheduleDates.length;

                                for (
                                    workScheduleIndex;
                                    workScheduleIndex < workScheduleDateLength;
                                    workScheduleIndex++
                                ) {
                                    const workScheduleDate =
                                        this.selectedPositionListItems[index]
                                            .eventPosition.workScheduleDates[
                                            workScheduleIndex
                                        ];
                                    let workActivityIndex = 0;
                                    const workActivitiesLength =
                                        workScheduleDate.workActivities.length;

                                    workScheduleDate.updateEventActivityOnly =
                                        true;

                                    //if the date difference is 0 then it is the same date

                                    const schDate = this.scheduleDates;
                                    let schDateIndex = 0;
                                    const schDateLength = schDate.length;

                                    for (
                                        schDateIndex;
                                        schDateIndex < schDateLength;
                                        schDateIndex++
                                    ) {
                                        if (schDate[schDateIndex].isChecked) {
                                            if (
                                                !moment(
                                                    schDate[schDateIndex].date
                                                ).diff(
                                                    moment(
                                                        workScheduleDate.date
                                                    )
                                                )
                                            ) {
                                                workScheduleDate.plannedWorkActivities =
                                                    [];
                                                workScheduleDate.plannedWorkActivities =
                                                    selectedTasks;
                                                workScheduleDate.plannedWorkActivities.forEach(
                                                    forEachCallback
                                                );

                                                for (
                                                    workActivityIndex;
                                                    workActivityIndex <
                                                    workActivitiesLength;
                                                    workActivityIndex++
                                                ) {
                                                    const workActivity =
                                                        workScheduleDate
                                                            .workActivities[
                                                            workActivityIndex
                                                        ];
                                                    workActivity.isPlanned =
                                                        false;

                                                    for (
                                                        j = 0;
                                                        j <
                                                        workScheduleDate
                                                            .plannedWorkActivities
                                                            .length;
                                                        j += 1
                                                    ) {
                                                        if (
                                                            workScheduleDate
                                                                .plannedWorkActivities[
                                                                j
                                                            ].id ===
                                                            workActivity.id
                                                        ) {
                                                            workActivity.isPlanned =
                                                                true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            const selectedItems = _.clone(
                                this.selectedPositionListItems
                            );

                            this.bulkUpdateEventPositions(selectedItems, () => {
                                this.getCrewItemHeader();
                                //this.getCrewItemByQuery(true, false);
                                this._eventBus.emit(
                                    new EmitEvent(
                                        "crew-page:bulkUpdateWorkSchedule",
                                        "getRelatedCrewPositions",
                                        selectedItems
                                    )
                                );
                            });
                        }
                    },
                    error: (err) => {
                        this.log.error("got an error: " + JSON.stringify(err));
                    },
                });
        }
    }

    /**
     * Open Timesheet Warning Modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @param {String} title modal title
     * @param {String} message moadl message
     */
    openTimesheetWarningModal(title: any, message: any) {
        this.log.trace("openTimesheetWarningModal");

        title = title || "Editing Activities";
        message =
            message ||
            "WARNING: Editing activities for this date will not be reflected in payroll.";

        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: null,
            cancelText: "Close",
            hideConfirm: true,
        };

        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            NbcModalConfirmation,
            modalOptions
        );

        //$modal.open({
        //  keyboard: false,
        //  backdrop: "static",
        //  templateUrl: "/scripts/app/areas/events/views/event/confirmActionModal.html",
        //  controller: "modalCrewTimesheetWarningController",
        //  controllerAs: "confirmActionCtr",
        //  resolve: {
        //    title: function () {
        //      return title;
        //    },
        //    message: function () {
        //      return message;
        //    }
        //  }
        //});
    }

    /**
     * Open Timesheet Warning Modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @param {String} title modal title
     * @param {String} message moadl message
     */
    openCustonFieldConfirmModal(title: any, message: any) {
        this.log.trace("openCustonFieldConfirmModal");
        //$modal.open({
        //  keyboard: false,
        //  backdrop: "static",
        //  templateUrl: "/scripts/app/areas/events/views/event/confirmActionModal.html",
        //  controller: "modalCrewTimesheetWarningController",
        //  controllerAs: "confirmActionCtr",
        //  resolve: {
        //    title: function () {
        //      return title;
        //    },
        //    message: function () {
        //      return message;
        //    }
        //  }
        //});
    }

    /**
     * Check Crew Conflict
     * @param {String} contactType contact type primary/secondary/alternate
     * @param {Object[]} positionListItem crew position list item
     * @param {Object} contactObj contact object
     * @param {Object} initialObj initial object
     * @param {Boolean} hideAssignBtn hide assign btn for schedule conflict modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     */
    checkCrewConflict(
        contactType: any,
        positionListItem: any,
        contactObj: any,
        initialObj: any,
        hideAssignBtn?: any,
        notOpenScheduleConflictModal?: any,
        isDisplayConflict?: any,
        isUpdateBackupContact?: any,
        scheduleDate?: any
    ) {
        if (!(contactObj && contactObj.resourceCode)) {
            return;
        }

        this.subs.sink = this._crewItemService
            .checkCrewConflict(
                this.eventId,
                contactObj.resourceCode,
                positionListItem.id,
                scheduleDate
            )
            .subscribe({
                next: (result: any) => {
                    this.itemConflict = result.reference;
                    if (this.itemConflict && this.itemConflict.totalConflicts) {
                        if (!notOpenScheduleConflictModal) {
                            this.openScheduleConflictModal(
                                contactType,
                                this.itemConflict,
                                isDisplayConflict || false,
                                initialObj,
                                hideAssignBtn,
                                positionListItem
                            );
                        }
                        if (!_.isEmpty(contactObj)) {
                            positionListItem.eventPosition.isConflictExists =
                                true;
                        }
                    } else {
                        if (!_.isEmpty(contactObj)) {
                            positionListItem.eventPosition.isConflictExists =
                                false;
                        }
                    }

                    if (isUpdateBackupContact !== undefined) {
                        if (isUpdateBackupContact) {
                            this.GetContactCustomDetails(positionListItem);
                        } else {
                            this.GetContactCustomDetails(positionListItem);
                        }
                    } else {
                        this.GetContactCustomDetails(positionListItem);
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                },
            });
    }

    /**
     * Confirm Contact
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    confirmContact(positionListItem: any) {
        this.log.trace("confirm position");

        let canConfirmContact = true;
        let sameEventConflict = false;

        const eventPosition = positionListItem.eventPosition;
        const selectedEmployeeType =
            positionListItem.eventPosition.employeeType;

        const EmployeeTypeNotRequiresRateCode = [
            "BUR",
            "CHI",
            "NY",
            "STAMFORD",
            "STAFF",
            "VENDOR",
        ];
        const PositionNotRequiresRateCode = ["TALENT", "TALENT ASSISTANT"];

        if (
            positionListItem.additionalLocation === null ||
            positionListItem.additionalLocation?.locationName === "" ||
            positionListItem.additionalLocation?.locationName === undefined ||
            positionListItem.additionalLocation?.locationName === null
        ) {
            canConfirmContact = false;
        }

        //checking if position is allowed to have rate code. SCORE wont process TALENT timesheets and Business requirested not to mandate rate code for AP
        if (
            _.indexOf(
                PositionNotRequiresRateCode,
                eventPosition.position.groupType.description.toUpperCase()
            ) < 0
        ) {
            //employee type is required for all positions except TALENT and TALENT ASST. as rate code depends on employee type
            if (!selectedEmployeeType) {
                canConfirmContact = false;
            }

            //checking if employee type needs rate to confirm or not
            if (
                selectedEmployeeType &&
                _.indexOf(
                    EmployeeTypeNotRequiresRateCode,
                    selectedEmployeeType?.description?.toUpperCase()
                ) < 0
            ) {
                if (!positionListItem.eventPosition.rateCode) {
                    canConfirmContact = false;
                } else {
                    // if the rate code is selected, we need to check for custom rates / flat day as special case
                    if (
                        positionListItem.eventPosition.rateCode.id.toLowerCase() ===
                            "00" ||
                        positionListItem.eventPosition.rateCode.id.toLowerCase() ===
                            "flat day" ||
                        (this.isTieredEvent &&
                            positionListItem.eventPosition.category === "BTL")
                    ) {
                        if (!positionListItem.customRate) {
                            canConfirmContact = false;
                        }
                    }
                }
            }
        }

        this.subs.sink = this._crewItemService
            .checkCrewConflict(
                this.eventId,
                positionListItem.primaryContact.id,
                positionListItem.id,
                null
            )
            .subscribe({
                next: (result: any) => {
                    this.itemConflict = result.reference;
                    if (
                        this.itemConflict &&
                        this.itemConflict.sameEventConflict === 1
                    ) {
                        canConfirmContact = false;
                        sameEventConflict = true;
                    }

                    if (canConfirmContact) {
                        const title = "Confirming a Contact",
                            message =
                                "WARNING: Confirming the contact will not be reflected in payroll.";

                        positionListItem.eventPosition.status = {
                            id: "C",
                            description: "Confirmed",
                        };

                        if (positionListItem.isTimeSheetSubmitted) {
                            this.openTimesheetWarningModal(title, message);
                        }

                        this.subs.sink = this._crewItemService
                            .setContactConfirmation(
                                this.eventId,
                                positionListItem
                            )
                            .subscribe({
                                next: (result: any) => {
                                    this._eventBus.emit(
                                        new EmitEvent(
                                            "crew-page:confirmContact",
                                            "getRelatedCrewPositions",
                                            [positionListItem]
                                        )
                                    );
                                    this.getCrewItemHeader();
                                },
                                error: (err) => {
                                    this.log.error(
                                        "got an error:" + JSON.stringify(err)
                                    );
                                    this.log.showError(
                                        "Unable to confirm the contact at this time. Please try again later."
                                    );
                                },
                            });
                    } else {
                        if (sameEventConflict)
                            this.log.showError(
                                "Overlapping activity cannot exist for " +
                                    this.itemConflict.schedulingConflicts[0]
                                        .resourceName +
                                    " for Position Numbers (" +
                                    this.itemConflict
                                        .sameEventConflictPositionNumbers +
                                    ") on " +
                                    this.itemConflict.sameEventConflictDates
                            );
                        else {
                            this.log.clearAlert();
                            this.log.showError(
                                "Employee Type / Rate Code / Location is missing."
                            );
                        }
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                },
            });
    }

    getSingleCrewItem(eventId: any, positionListItem: any) {
        this.subs.sink = this._crewItemService
            .getSingleCrewItem(this.eventId, positionListItem.id)
            .subscribe({
                next: (result: any) => {
                    //if (result.reference.primaryContact) {
                    // // positionListItem.primaryContact.isOnBoarding = result.reference.primaryContact.isOnBoarding;
                    //  positionListItem.airTravelNotificationSent = result.reference?.airTravelNotificationSent;
                    //  positionListItem.primaryContact.canShowHrIcon = result.reference.primaryContact?.canShowHrIcon;

                    //  positionListItem.primaryContact.isNonVendor = result.reference.primaryContact?.isNonVendor;
                    //  positionListItem.primaryContact.isHrVerified = result.reference.primaryContact?.isHrVerified;
                    //  positionListItem.primaryContact.crewRegistrationNo = result.reference.primaryContact?.crewRegistrationNo;
                    //}
                    //positionListItem.confirmDate = result.reference?.confirmDate;

                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-grid:getSingleCrewItem",
                            "updateGridRow",
                            result.reference
                        )
                    );
                },
                error: (err) => {
                    this.log.error("got error " + JSON.stringify(err));
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-grid:getSingleCrewItem",
                            "updateGridRow",
                            positionListItem
                        )
                    );
                },
            });
    }

    /**
     * contact Verified
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    contactVerified(positionListItem: any) {
        this.log.trace("confirm position");

        const title = "Contact Information Accuracy",
            msg = !positionListItem.primaryContact.contactVerified.fieldBool
                ? "Please confirm contact's information is accurate."
                : "Are you sure contact's information is no longer accurate?";
        this.toggleCustomFieldValue(positionListItem, "contactVerified");
        this.showValidationErrorModal(
            title,
            msg,
            true,
            this.CustomFieldUpdate.bind(this),
            [
                positionListItem,
                positionListItem.primaryContact.contactVerified,
                positionListItem.primaryContact.id,
            ],
            "Confirm",
            "Cancel",
            this.toggleCustomFieldValue,
            [positionListItem, "contactVerified"]
        );
    }

    /**
     * covid Communication Sent
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    covidCommunicationSent(positionListItem: any) {
        this.log.trace("confirm position");

        const title = "E-mail Communication Sent",
            msg = !positionListItem.primaryContact.covidCommunicationSent
                .fieldBool
                ? "Please confirm notification e-mail was sent to the contact."
                : "Are you sure notification e-mail was not sent to the contact?";
        this.toggleCustomFieldValue(positionListItem, "covidCommunicationSent");
        this.showValidationErrorModal(
            title,
            msg,
            true,
            this.CustomFieldUpdate.bind(this),
            [
                positionListItem,
                positionListItem.primaryContact.covidCommunicationSent,
                positionListItem.primaryContact.id,
            ],
            "Confirm",
            "Cancel",
            this.toggleCustomFieldValue,
            [positionListItem, "covidCommunicationSent"]
        );
    }

    /**
     * Covid Reply Recd
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    covidReplyRecd(positionListItem: any) {
        this.log.trace("confirm position");

        const title = "Communication Sent Acknowledgement",
            msg = !positionListItem.primaryContact.covidReplyRecd.fieldBool
                ? "Please confirm contact acknowledgement of notification e-mail."
                : "Are you sure contact did not acknowledge the notification e-mail?";
        this.toggleCustomFieldValue(positionListItem, "covidReplyRecd");
        this.showValidationErrorModal(
            title,
            msg,
            true,
            this.CustomFieldUpdate.bind(this),
            [
                positionListItem,
                positionListItem.primaryContact.covidReplyRecd,
                positionListItem.primaryContact.id,
            ],
            "Confirm",
            "Cancel",
            this.toggleCustomFieldValue,
            [positionListItem, "covidReplyRecd"]
        );
    }

    /**
     * i9 Docs Verified
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    i9DocsVerified(positionListItem: any) {
        this.log.trace("confirm position");

        const title = "Contact I-9 Documents",
            msg = !positionListItem.primaryContact.i9DocsVerified.fieldBool
                ? "Are you sure contact's documents match their uploaded I-9 Documents?"
                : "Contact's I-9 Documents not verified?";
        this.toggleCustomFieldValue(positionListItem, "i9DocsVerified");
        this.showValidationErrorModal(
            title,
            msg,
            true,
            this.CustomFieldUpdate.bind(this),
            [
                positionListItem,
                positionListItem.primaryContact.i9DocsVerified,
                positionListItem.primaryContact.id,
            ],
            "Confirm",
            "Cancel",
            this.toggleCustomFieldValue,
            [positionListItem, "i9DocsVerified"]
        );
    }

    CustomFieldUpdate(positionListItem: any, customfield: any, contactid: any) {
        this.subs.sink = this._contactService
            .updateCustomField(contactid, customfield)
            .subscribe({
                next: (confirmResult: any) => {
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:CustomFieldUpdate",
                            "updateGridRow",
                            positionListItem
                        )
                    );
                },
                error: (err) => {
                    this.log.error("got error: " + JSON.stringify(err));
                },
            });
    }

    toggleCustomFieldValue(position: any, customprop: any) {
        position.primaryContact[customprop].fieldValue =
            position.primaryContact[customprop].fieldValue === "Y" ? "N" : "Y";
        position.primaryContact[customprop].fieldBool =
            !position.primaryContact[customprop].fieldBool;
        if (position.primaryContact[customprop].updatedBy != "") {
            position.primaryContact[customprop].modifiedDate = moment
                .utc(position.primaryContact[customprop].updatedDate)
                .format("MM/DD/YYYY");
        }
        position.primaryContact[customprop].color = position.primaryContact[
            customprop
        ].fieldBool
            ? "Green"
            : "Red";
    }

    /**
     * Save Event Position Travel
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    saveEventPositionTravel(positionListItem: any) {
        this.log.trace("saveEventPositionTravel");
        this.subs.sink = this._eventService
            .saveEventPositionTravel(this.eventId, [positionListItem])
            .subscribe({
                next: (result: any) => {
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-grid:saveEventPositionTravel",
                            "updateGridRow",
                            positionListItem
                        )
                    );
                },
                error: (err) => {
                    this.log.error(
                        "saveCrew got an error: " + JSON.stringify(err)
                    );
                    this.log.showError("Failed to load ");
                    this.getCrewItemByQuery();
                },
            });
    }

    /**
     * Save Event Position Rate Code
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    saveEventPositionRateCode(positionListItem: any) {
        this.log.trace("saveEventPositionRateCode");

        this.subs.sink = this._eventService
            .saveEventPositionRateCode(this.eventId, [positionListItem])
            .subscribe({
                next: (result: any) => {
                    this.getCrewItemHeader();

                    if (this.isCrewDataGridFilterActive()) {
                        this.getCrewItemByQuery();
                    } else {
                        //if ratecode is other than flat or custom AND it not NON-TIER-BTL position, we are refreshing row to keep latest data on the grid. Otherwise, we will refresh from custom rate save call

                        if (
                            positionListItem.eventPosition.rateCode?.id
                                ?.toString()
                                .toLowerCase() === "flat day" ||
                            (positionListItem.eventPosition.rateCode?.id
                                ?.toString()
                                .toLowerCase() === "00" &&
                                (!_.has(positionListItem, "customRate") ||
                                    _.isEmpty(positionListItem.customRate))) ||
                            (positionListItem.isTieredEvent &&
                                positionListItem.eventPosition.category ===
                                    "BTL" &&
                                (!_.has(positionListItem, "customRate") ||
                                    _.isEmpty(positionListItem.customRate)))
                        ) {
                            //no update required.
                            const s = "";
                        } else {
                            this._eventBus.emit(
                                new EmitEvent(
                                    "crew-grid:  saveEventPositionRateCode(positionListItem)",
                                    "updateGridRow",
                                    result.reference
                                )
                            );
                        }
                    }

                    positionListItem.asyncDataSave.rateCodeState = "success";
                    this.reportAsyncStatus(positionListItem, "rateCode");

                    // this._eventBus.emit(new EmitEvent("crew-grid:getSingleCrewItem", "updateGridRow", result.reference));

                    // this.getSingleCrewItem(this.eventId, positionListItem);
                    //this._eventBus.emit(new EmitEvent("crew-grid:  saveEventPositionRateCode(positionListItem)", "updateGridRow", positionListItem));
                },
                error: (err) => {
                    this.log.error(
                        "saveCrew got an error: " + JSON.stringify(err)
                    );
                    this.log.showError("Unable to save rate code.");
                    this.getCrewItemByQuery();

                    positionListItem.asyncDataSave.rateCodeState = "error";
                    this.reportAsyncStatus(positionListItem, "rateCode");
                },
            });
        /*  }*/
    }

    // Save Position Appproved Date..
    savePositionApprovedDate(positionListItem: any) {
        this.subs.sink = this._eventService
            .savePositionApprovedDate(this.eventId, [positionListItem])
            .subscribe({
                next: (result: any) => {
                    this.getCrewItemHeader();
                },
                error: (err) => {
                    this.log.error(
                        "saveCrew got an error: " + JSON.stringify(err)
                    );
                    this.log.showError(
                        "Unable to save Position Approved Date."
                    );
                },
            });
    }

    /**
     * Save Event Position Employee Type
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    saveEventPositionEmployeeType(positionListItem: any) {
        this.log.trace("saveEventPositionEmployeeType");
        positionListItem.eventPosition.rateCode = null;
        //this._eventBus.emit(new EmitEvent("crew-grid:saveEventPositionEmployeeType", "updateGridRow", positionListItem));
        this.subs.sink = this._eventService
            .saveEventPositionEmployeeType(this.eventId, [positionListItem])
            .subscribe({
                next: (result: any) => {
                    if (!positionListItem.eventPosition.employeeType) {
                        positionListItem.eventPosition.rateCode = null;
                        positionListItem.customRate = null;
                    }
                    this.getCrewItemHeader();

                    if (this.isCrewDataGridFilterActive()) {
                        this.getCrewItemByQuery();
                    } else {
                        positionListItem.asyncDataSave.employeeTypeState =
                            "success";
                        this.reportAsyncStatus(
                            positionListItem,
                            "employeeType"
                        );
                    }
                    //this._eventBus.emit(new EmitEvent("crew-grid:saveEventPositionEmployeeType", "updateGridRow", positionListItem));
                },
                error: (err) => {
                    this.log.error(
                        "saveCrew got an error: " + JSON.stringify(err)
                    );
                    this.log.showError("Unable to save employee type.");

                    positionListItem.asyncDataSave.employeeTypeState = "error";
                    this.reportAsyncStatus(positionListItem, "employeeType");
                },
            });
    }

    saveEventPositionAdditionalLocation(positionListItem: any) {
        this.updateAdditionalLocationsQuery.eventId = this.eventId;
        this.updateAdditionalLocationsQuery.crewPositionIds =
            positionListItem.id;
        if (
            positionListItem.additionalLocation == null ||
            positionListItem.additionalLocation === ""
        ) {
            this.updateAdditionalLocationsQuery.additionalLocationId = 0;
        } else {
            this.updateAdditionalLocationsQuery.additionalLocationId =
                positionListItem.additionalLocation.locationId;
        }

        this.subs.sink = this._crewItemService
            .sendAdditionalLocations(
                this.eventId,
                this.updateAdditionalLocationsQuery
            )
            .subscribe({
                next: (result: any) => {
                    positionListItem.asyncDataSave.additionalLocationState =
                        "success";
                    this.reportAsyncStatus(
                        positionListItem,
                        "additionallocation"
                    );
                },
                error: (err) => {
                    this.log.error("Error  " + JSON.stringify(err));
                    positionListItem.asyncDataSave.additionalLocationState =
                        "error";
                    this.reportAsyncStatus(
                        positionListItem,
                        "additionallocation"
                    );
                },
            });
    }

    /**
     * Open Delete Position Modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires removeGridRow
     * @param {Object[]} positionListItem crew position list item
     */
    openDeletePositionModal(positionListItem: any, isBulkUpdate = false) {
        this.log.trace("openDeletePositionModal");

        const actionMessage = isBulkUpdate
            ? "Are you sure you want to delete all the selected positions?"
            : "Are you sure you want to delete this position?";

        const initialState: any = {
            modalTitle: "Confirm Delete Position",
            modalMessage: actionMessage,
            confirmText: "Delete",
            cancelText: "Cancel",
            hideConfirm: false,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("confirmation");
            this.deleteEventPosition(
                this.eventId,
                isBulkUpdate,
                positionListItem
            );
        });
        this.bsModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
        });
    }

    deleteEventPosition(
        eventId: any,
        isBulkUpdate: any,
        positionListItem: any
    ) {
        this.subs.sink = this._eventService
            .deleteEventPosition(
                eventId,
                isBulkUpdate ? positionListItem : [positionListItem]
            )
            .subscribe({
                next: () => {
                    this.getCrewItemByQuery();
                    this.getCrewItemHeader();
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to delete a position at this time. Please try again later."
                    );
                    this.getCrewItemByQuery();
                },
            });
    }

    /**
     * Open Remove Crew Modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list item
     */
    openRemoveCrewModal(positionListItem: any, isBulkUpdate = false) {
        this.log.trace("openRemoveCrewModal");

        const actionMessage = isBulkUpdate
            ? "Are you sure you want to unassign all the selected positions?"
            : "Are you sure you want to remove " +
              positionListItem.primaryContact.firstName +
              " " +
              positionListItem.primaryContact.lastName +
              "?";

        const initialState: any = {
            modalTitle: isBulkUpdate
                ? "Unassign Contact"
                : "Remove Crew Member from Position",
            modalMessage: actionMessage,
            confirmText: isBulkUpdate ? "Unassign" : "Remove",
            cancelText: "Cancel",
            hideConfirm: false,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("Modal dismissed with successful removal");
            this.updateCrewPosition(positionListItem, isBulkUpdate);
        });
        this.bsModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
        });
    }

    updateCrewPosition(positionListItem: any, isBulkUpdate: any) {
        if (isBulkUpdate) {
            for (let count = 0; count < positionListItem.length; count++) {
                positionListItem[count].eventPosition.status = {
                    id: "O",
                    description: "Open",
                };
                positionListItem[count].eventPosition.employeeType = "";
                positionListItem[count].eventPosition.rateCode = {};
                positionListItem[count].eventPosition.airportCode = "";

                positionListItem[count].eventPosition.IsInBoundTraveling =
                    false;
                positionListItem[count].eventPosition.IsOutBoundTraveling =
                    false;
                positionListItem[count].eventPosition.inBoundArrAirportCode =
                    null;
                positionListItem[count].eventPosition.inBoundDepAirportCode =
                    null;
                positionListItem[count].eventPosition.isInBoundTraveling = null;
                positionListItem[count].eventPosition.isOutBoundTraveling =
                    null;
                positionListItem[count].eventPosition.outBoundLocalTravel =
                    "local";

                positionListItem[count].eventPosition.localTravel = "local";
                positionListItem[count].customRate = "";
                positionListItem[count].eventPosition.isConflictExists = false;
                positionListItem[count].primaryContact = null;
            }
        } else {
            positionListItem.eventPosition.status = {
                id: "O",
                description: "Open",
            };
            positionListItem.eventPosition.employeeType = "";
            positionListItem.eventPosition.rateCode = {};
            positionListItem.eventPosition.airportCode = "";

            positionListItem.eventPosition.IsInBoundTraveling = false;
            positionListItem.eventPosition.IsOutBoundTraveling = false;
            positionListItem.eventPosition.inBoundArrAirportCode = null;
            positionListItem.eventPosition.inBoundDepAirportCode = null;
            positionListItem.eventPosition.isInBoundTraveling = null;
            positionListItem.eventPosition.isOutBoundTraveling = null;
            positionListItem.eventPosition.outBoundLocalTravel = "local";

            positionListItem.eventPosition.localTravel = "local";
            positionListItem.customRate = "";
            positionListItem.eventPosition.isConflictExists = false;
            positionListItem.primaryContact = null;
            positionListItem.tierRateCardComment = null;
        }

        this.subs.sink = this._eventService
            .updateEventPositionContact(
                this.eventId,
                isBulkUpdate ? positionListItem : [positionListItem]
            )
            .subscribe({
                next: (result) => {
                    // Remove additional location

                    this.updateAdditionalLocationsQuery.eventId = this.eventId;
                    this.updateAdditionalLocationsQuery.additionalLocationId = 0;
                    if (isBulkUpdate) {
                        const cIds: any[] = [];
                        for (const item of positionListItem) {
                            cIds.push(item.id);
                        }
                        this.updateAdditionalLocationsQuery.crewPositionIds =
                            cIds.join(",");
                    } else {
                        this.updateAdditionalLocationsQuery.crewPositionIds =
                            positionListItem.id;
                    }

                    this.subs.sink = this._crewItemService
                        .sendAdditionalLocations(
                            this.eventId,
                            this.updateAdditionalLocationsQuery
                        )
                        .subscribe({
                            next: (res) => {
                                //no action required.
                                this.getCrewItemHeader();
                                if (isBulkUpdate) this.getCrewItemByQuery();
                                else this.getCrewItemByQuery();
                                //though updating single item is right way, for schedule conflict within an event, it create an issue
                                //e.g. SCOR-20693
                                //this.getSingleCrewItem(this.eventId, positionListItem);
                            },
                        });

                    //this.getCrewItemHeader();
                    //this.getCrewItemByQuery();
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to confirm the contact at this time. Please try again later."
                    );
                },
            });
    }

    /**
     * Open Unconfirm Modal
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list item
     */
    openUnconfirmModal(positionListItem: any) {
        this.log.trace("openUnconfirmModal");
        const actionMessage =
            "<p>Are you sure you want to unconfirm this contact?</p><p>Travel arrangements may have already been reserved for this contact.</p>";

        const initialState: any = {
            modalTitle: "Unconfirm this Contact",
            modalMessage: actionMessage,
            confirmText: "Unconfirm",
            cancelText: "Cancel",
            hideConfirm: false,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("Modal dismissed with successful unconfirm");
            positionListItem.eventPosition.status = {
                id: "R",
                description: "Requested",
            };
            this.subs.sink = this._crewItemService
                .setContactConfirmation(this.eventId, positionListItem)
                .subscribe({
                    next: (result) => {
                        this.getCrewItemHeader();

                        this._eventBus.emit(
                            new EmitEvent(
                                "crew-page:openUnconfirmModal",
                                "getRelatedCrewPositions",
                                [positionListItem]
                            )
                        );
                        //this.getCrewItemByQuery();
                    },
                    error: (err) => {
                        this.log.showError(
                            "Unable to unconfirm the position at this time. Please click Unconfirm to try again."
                        );
                    },
                });
        });
    }

    /**
     * Cancel Bulk Update
     * @private
     * @memberOf score.controllers.EventCrewingController
     */
    cancelBulkUpdate() {
        this.updateSubmit = false;
        this.bulkEditCategory = { value: "-1" };
        this.selectedEmployeeType = "";
        this.selectedRateCard = "";
        this.bulkEditFlight = false;
        this.bulkEditFlightLocal = "local";
        this.bulkEditFlightDestination = "";
        this.bulkEditFlightDepature = "";
        this.bulkEditFlightArrival = "";
        this.selectedUnionDesignation = "";
        this.customRateCard = null;
        if (this.filterDateItem) {
            this.filterDateItem.item = "";
        }
        this.taskItems.forEach(function (taskItem) {
            taskItem.isChecked = false;
            taskItem.disabled = false;
            taskItem.isPlanned = false;
        });
    }

    /**
     * Save Work Schedule Day
     * @param {Object[]} positionListItem crew position list item
     * @param {Int} day
     * @param {Object} initialPositionListItem initial crew position list item
     * @fires updateGridRow
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    saveWorkScheduleDay(
        positionListItem: any,
        day: any,
        initialPositionListItem: any
    ) {
        this.log.trace("saveWorkScheduleDay");

        const workScheduleDate =
            positionListItem.eventPosition.workScheduleDates[day];
        let title = "",
            message = "";

        if (workScheduleDate.isTimeSheetSubmitted) {
            if (!workScheduleDate.plannedActivityCount) {
                title = "Adding Activities";
                message =
                    "WARNING: Adding activities for this date will not be reflected in payroll.";
            }
            this.openTimesheetWarningModal(title, message);
        }

        const scheduleDate = moment(workScheduleDate.date).format("yyyy-MM-DD");
        this.checkCrewConflict(
            "",
            positionListItem,
            positionListItem.primaryContact,
            initialPositionListItem,
            null,
            null,
            null,
            null,
            scheduleDate
        );
        this.subs.sink = this._eventService
            .saveEventPositionWorkSchedule(this.eventId, [positionListItem])
            .subscribe({
                next: (result: any) => {
                    this.getCrewItemHeader();
                    this.checkAirNotificationTravelActivites(positionListItem);
                    if (workScheduleDate.plannedActivityCount) {
                        if (
                            positionListItem.primaryContact &&
                            !crewItemConflictFactory.checkCrewItemConflict(
                                positionListItem
                            )
                        ) {
                            //this.checkCrewConflict("", positionListItem, positionListItem.primaryContact, initialPositionListItem);
                            crewItemConflictFactory.addCrewItemConflictChecked(
                                positionListItem
                            );
                        }
                    } else {
                        //only do this if there are no more planned work activities
                        if (
                            typeof _.find(
                                _.map(
                                    positionListItem.eventPosition
                                        .workScheduleDates,
                                    "plannedWorkActivities"
                                ),
                                _.size
                            ) === "undefined"
                        ) {
                            crewItemConflictFactory.removeCrewItemConflictChecked(
                                positionListItem
                            );
                        }
                    }

                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:saveEventPositionWorkSchedule",
                            "updateGridRow",
                            positionListItem
                        )
                    );
                },
                error: (err) => {
                    this.log.error("Error " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to save the work schedule at this time. Please try again later."
                    );
                    this.getCrewItemByQuery();
                },
            });
    }

    /**
     * Update Sort Order For Crew Items
     * @param {Object[]} positionListItems crew position list items
     * @param {Boolean} redrawGrid indicates if we want to redraw grid or not
     * @fires updateSortOrderForCrewItemsSuccessful
     * @memberOf score.controllers.EventCrewingController
     * @private
     */

    updateSortOrderForCrewItems(
        positionListItems: any,
        redrawGrid: any,
        refreshGrid: any
    ) {
        this.log.trace("updateSortOrderForCrewItems");
        this.subs.sink = this._crewItemService
            .updateSortOrderForCrewItems(this.eventId, positionListItems)
            .subscribe({
                next: (result: any) => {
                    if (refreshGrid) {
                        this.getCrewItemByQuery();
                    } else {
                        this._eventBus.emit(
                            new EmitEvent(
                                "crew-page:updateSortOrderForCrewItems",
                                "updateSortOrderForCrewItemsSuccessful",
                                redrawGrid
                            )
                        );
                    }
                },
                error: (err) => {
                    this.log.trace("sort order was not updated successfully");
                    this.log.showError(
                        "Unable to save a new sort order at this time. Please try again later."
                    );
                },
            });
    }

    /**
     * Toggle Travel Button
     * @param {Object[]} positionListItem crew position list item
     * @fires updateGridRow
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    toggleTravelButton(positionListItem: any) {
        this.log.trace("activate flight button");
        positionListItem.eventPosition.isTraveling =
            !positionListItem.eventPosition.isTraveling;

        if (positionListItem.eventPosition.isTraveling) {
            if (
                !positionListItem.eventPosition.airportCode &&
                positionListItem.primaryContact
            ) {
                positionListItem.eventPosition.airportCode = _.cloneDeep(
                    positionListItem.primaryContact.airportCode
                );
            }

            if (positionListItem.eventPosition.airportCode) {
                this.saveEventPositionTravel(positionListItem);
            } else {
                this._eventBus.emit(
                    new EmitEvent(
                        "crew-page:toggleTravelButton",
                        "updateGridRow",
                        positionListItem
                    )
                );
            }
        } else {
            //fire the save if isTraveling is false. set local as default for localTravel
            positionListItem.eventPosition.localTravel = "local";
            this.saveEventPositionTravel(positionListItem);
        }
    }

    /**
     * Toggle in Bound Travel Button
     * @param {Object[]} positionListItem crew position list item
     * @fires updateGridRow
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    toggleInBoundTravelButton(positionListItem: any) {
        this.log.trace("activate flight button");

        //After page load if both inbound & outbound are inactive
        if (
            !positionListItem.eventPosition.toggleInFlag &&
            !positionListItem.eventPosition.isInBoundTraveling &&
            !positionListItem.eventPosition.isOutBoundTraveling
        ) {
            if (this.venueAirportCode) {
                positionListItem.eventPosition.inBoundArrAirportCode =
                    _.cloneDeep(this.venueAirportCode);
                positionListItem.eventPosition.outBoundDepAirportCode =
                    _.cloneDeep(this.venueAirportCode);
            }
            if (positionListItem.primaryContact) {
                positionListItem.eventPosition.inBoundDepAirportCode =
                    _.cloneDeep(positionListItem.primaryContact.airportCode);
                positionListItem.eventPosition.outBoundArrAirportCode =
                    _.cloneDeep(positionListItem.primaryContact.airportCode);
            }
            positionListItem.eventPosition.toggleOutFlag = true;
            positionListItem.eventPosition.isOutBoundTraveling =
                !positionListItem.eventPosition.isOutBoundTraveling;
        } //After page load only if inbound in inactive
        else if (
            !positionListItem.eventPosition.toggleInFlag &&
            !positionListItem.eventPosition.isInBoundTraveling &&
            positionListItem.eventPosition.isOutBoundTraveling
        ) {
            if (
                positionListItem.eventPosition.tempInBoundArrAirportCode &&
                positionListItem.eventPosition.tempInBoundArrAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundArrAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundArrAirportCode
                    );
            } else if (this.venueAirportCode) {
                positionListItem.eventPosition.inBoundArrAirportCode =
                    _.cloneDeep(this.venueAirportCode);
            }

            if (
                positionListItem.eventPosition.tempInBoundDepAirportCode &&
                positionListItem.eventPosition.tempInBoundDepAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundDepAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundDepAirportCode
                    );
            } else if (positionListItem.primaryContact) {
                positionListItem.eventPosition.inBoundDepAirportCode =
                    _.cloneDeep(positionListItem.primaryContact.airportCode);
            }
        } else if (
            positionListItem.eventPosition.toggleInFlag &&
            !positionListItem.eventPosition.isInBoundTraveling &&
            !positionListItem.eventPosition.isOutBoundTraveling
        ) {
            //once toggle action is manuplated
            if (
                positionListItem.eventPosition.tempInBoundArrAirportCode &&
                positionListItem.eventPosition.tempInBoundArrAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundArrAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundArrAirportCode
                    );
            }
            if (
                positionListItem.eventPosition.tempInBoundDepAirportCode &&
                positionListItem.eventPosition.tempInBoundDepAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundDepAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundDepAirportCode
                    );
            }
            if (
                positionListItem.eventPosition.tempOutBoundArrAirportCode &&
                positionListItem.eventPosition.tempOutBoundArrAirportCode !=
                    "" &&
                positionListItem.eventPosition.toggleOutFlag
            ) {
                positionListItem.eventPosition.outBoundArrAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition
                            .tempOutBoundArrAirportCode
                    );
            } else if (
                positionListItem.primaryContact &&
                !positionListItem.eventPosition.toggleOutFlag
            ) {
                positionListItem.eventPosition.outBoundArrAirportCode =
                    _.cloneDeep(positionListItem.primaryContact.airportCode);
            }

            if (
                positionListItem.eventPosition.tempOutBoundDepAirportCode &&
                positionListItem.eventPosition.tempOutBoundDepAirportCode !=
                    "" &&
                positionListItem.eventPosition.toggleOutFlag
            ) {
                positionListItem.eventPosition.outBoundDepAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition
                            .tempOutBoundDepAirportCode
                    );
            } else if (
                this.venueAirportCode &&
                !positionListItem.eventPosition.toggleOutFlag
            ) {
                positionListItem.eventPosition.outBoundDepAirportCode =
                    _.cloneDeep(this.venueAirportCode);
            }
            positionListItem.eventPosition.isOutBoundTraveling =
                !positionListItem.eventPosition.isOutBoundTraveling;
            positionListItem.eventPosition.toggleOutFlag = true;
        } else if (
            positionListItem.eventPosition.toggleInFlag &&
            !positionListItem.eventPosition.isInBoundTraveling
        ) {
            //once toggle action is manuplated
            if (
                positionListItem.eventPosition.tempInBoundArrAirportCode &&
                positionListItem.eventPosition.tempInBoundArrAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundArrAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundArrAirportCode
                    );
            }
            if (
                positionListItem.eventPosition.tempInBoundDepAirportCode &&
                positionListItem.eventPosition.tempInBoundDepAirportCode != ""
            ) {
                positionListItem.eventPosition.inBoundDepAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition.tempInBoundDepAirportCode
                    );
            }
        }

        if (positionListItem.eventPosition.inBoundArrAirportCode)
            positionListItem.eventPosition.tempInBoundArrAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.inBoundArrAirportCode
                );

        if (positionListItem.eventPosition.inBoundDepAirportCode)
            positionListItem.eventPosition.tempInBoundDepAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.inBoundDepAirportCode
                );

        if (positionListItem.eventPosition.outBoundArrAirportCode)
            positionListItem.eventPosition.tempOutBoundArrAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.outBoundArrAirportCode
                );

        if (positionListItem.eventPosition.outBoundDepAirportCode)
            positionListItem.eventPosition.tempOutBoundDepAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.outBoundDepAirportCode
                );

        positionListItem.eventPosition.toggleInFlag = true;

        if (positionListItem.eventPosition.isInBoundTraveling) {
            //fire the save if isTraveling is false. set local as default for localTravel
            positionListItem.eventPosition.inBoundLocalTravel = "local";
            positionListItem.eventPosition.isInBoundTraveling =
                !positionListItem.eventPosition.isInBoundTraveling;
            positionListItem.eventPosition.inBoundArrAirportCode = "";
            positionListItem.eventPosition.inBoundDepAirportCode = "";
            this.saveEventPositionTravel(positionListItem);
        } else {
            positionListItem.eventPosition.isInBoundTraveling =
                !positionListItem.eventPosition.isInBoundTraveling;

            if (
                positionListItem.eventPosition.isInBoundTraveling ||
                positionListItem.eventPosition.isOutBoundTraveling
            ) {
                this.saveEventPositionTravel(positionListItem);
            } else {
                this._eventBus.emit(
                    new EmitEvent(
                        "crew-page:toggleInBoundTravelButton",
                        "updateGridRow",
                        positionListItem
                    )
                );
            }
        }
    }

    /**
     * Toggle in Bound Travel Button
     * @param {Object[]} positionListItem crew position list item
     * @fires updateGridRow
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    toggleOutBoundTravelButton(positionListItem: any) {
        this.log.trace("activate flight button");
        //After page load if outbound travel is inactive
        if (
            !positionListItem.eventPosition.toggleOutFlag &&
            !positionListItem.eventPosition.isOutBoundTraveling
        ) {
            if (this.venueAirportCode) {
                positionListItem.eventPosition.outBoundDepAirportCode =
                    _.cloneDeep(this.venueAirportCode);
            }
            if (positionListItem.primaryContact) {
                positionListItem.eventPosition.outBoundArrAirportCode =
                    _.cloneDeep(positionListItem.primaryContact.airportCode);
            }
        } else if (
            positionListItem.eventPosition.toggleOutFlag &&
            !positionListItem.eventPosition.isOutBoundTraveling
        ) {
            //once toggle action is manuplated
            if (
                positionListItem.eventPosition.tempOutBoundArrAirportCode &&
                positionListItem.eventPosition.tempOutBoundArrAirportCode != ""
            ) {
                positionListItem.eventPosition.outBoundArrAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition
                            .tempOutBoundArrAirportCode
                    );
            }
            if (
                positionListItem.eventPosition.tempOutBoundDepAirportCode &&
                positionListItem.eventPosition.tempOutBoundDepAirportCode != ""
            ) {
                positionListItem.eventPosition.outBoundDepAirportCode =
                    _.cloneDeep(
                        positionListItem.eventPosition
                            .tempOutBoundDepAirportCode
                    );
            }
        }

        if (positionListItem.eventPosition.outBoundArrAirportCode)
            positionListItem.eventPosition.tempOutBoundArrAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.outBoundArrAirportCode
                );

        if (positionListItem.eventPosition.outBoundDepAirportCode)
            positionListItem.eventPosition.tempOutBoundDepAirportCode =
                _.cloneDeep(
                    positionListItem.eventPosition.outBoundDepAirportCode
                );

        positionListItem.eventPosition.toggleOutFlag = true;

        if (positionListItem.eventPosition.isOutBoundTraveling) {
            //fire the save if isTraveling is false. set local as default for localTravel
            positionListItem.eventPosition.outBoundLocalTravel = "local";
            positionListItem.eventPosition.isOutBoundTraveling =
                !positionListItem.eventPosition.isOutBoundTraveling;
            positionListItem.eventPosition.outBoundArrAirportCode = "";
            positionListItem.eventPosition.outBoundDepAirportCode = "";
            this.saveEventPositionTravel(positionListItem);
        } else {
            positionListItem.eventPosition.isOutBoundTraveling =
                !positionListItem.eventPosition.isOutBoundTraveling;

            if (positionListItem.eventPosition.isOutBoundTraveling) {
                this.saveEventPositionTravel(positionListItem);
            }
        }
        positionListItem.eventPosition.toggleOutFlag = true;
    }

    /**
     * Set Travel Type
     * @param {Object[]} positionListItem crew position list item
     * @param {String} travelType travel type (local or no-local)
     * @param {bool} travelType travel type (true or false)
     * @fires updateGridRow
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    setTravelType(positionListItem: any, travelType: any, isInBound: any) {
        this.log.trace("setTravelType");
        positionListItem.eventPosition.localTravel = travelType;
        if (isInBound) {
            positionListItem.eventPosition.inBoundLocalTravel = travelType;
        } else {
            positionListItem.eventPosition.outBoundLocalTravel = travelType;
        }
        this.saveEventPositionTravel(positionListItem);
    }

    /**
     * Manipulate Contact
     * @param {Object[]} positionListItems crew position list item
     * @param {Boolean} requireCheckConflicts require check conflicts
     * @param {Object} initialContactBackUpInfo initial backup contact info
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     */
    manipulateContact(
        positionListItems: any,
        requireCheckConflicts: any,
        initialContactBackUpInfo: any
    ) {
        this.log.trace("manipulateContact");
        this.subs.sink = this._eventService
            .updateEventPositionContact(this.eventId, positionListItems)
            .subscribe({
                next: (result: any) => {
                    //the requireCheckConflicts is true only when we are working with primary contact
                    if (requireCheckConflicts) {
                        this.checkCrewConflict(
                            "primary",
                            positionListItems[0],
                            positionListItems[0].primaryContact,
                            initialContactBackUpInfo
                        );
                    } else {
                        //this.getCrewItemByQuery();
                        this._eventBus.emit(
                            new EmitEvent(
                                "crew-page:manipulateContact",
                                "updateGridRow",
                                positionListItems[0]
                            )
                        );
                        this.getCrewItemHeader();
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to assign a contact at this time. Please try again later."
                    );
                },
            });
    }

    getCrewPositionBackup(positionListItem: any) {
        this.subs.sink = this._crewItemService
            .getSingleCrewItem(this.eventId, positionListItem.id)
            .subscribe({
                next: (result: any) => {
                    positionListItem.crewPositionBackupItems =
                        result.reference.crewPositionBackupItems;
                    positionListItem.crewPositionBackupCount =
                        result.reference.crewPositionBackupCount;
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:getCrewPositionBackup",
                            "updateGridRow",
                            result.reference
                        )
                    );
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to retrive a backup contact at this time. Please try again later."
                    );
                },
            });
    }

    createPrimaryContact(
        positionListItem: any,
        crewPositionBackupContact: any,
        alternateContactId: any
    ) {
        if (crewPositionBackupContact.backupContact !== null) {
            positionListItem.primaryContact =
                crewPositionBackupContact.backupContact;
        } else {
            positionListItem.primaryContact = {
                resourceCode: alternateContactId,
                firstName: crewPositionBackupContact.firstName,
                lastName:
                    crewPositionBackupContact.lastName !== null
                        ? crewPositionBackupContact.lastName
                        : "",
                contactMiddleName: "",
                flaggedByHR:
                    crewPositionBackupContact.flaggedByHr !== null
                        ? crewPositionBackupContact.flaggedByHr === "N"
                            ? false
                            : true
                        : null,
                employeeType: "",
                rateCode: null,
                sso: null,
                city: "",
                email: "",
                country: "",
                state: "",
                airportCode: "",
                phone1: null,
                perNer: null,
                fullName:
                    crewPositionBackupContact.firstName +
                    "" +
                    (crewPositionBackupContact.lastName !== null
                        ? crewPositionBackupContact.lastName
                        : ""),
                id: alternateContactId,
                requestId: null,
                onboardingStatus: null,
                isOnBoarding: null,
                isOnboardingPendingContact: null,
                isHrVerified: null,
                middleName: "",
                contactNameNote: "",
                canDelete: true,
                contactVerified: {
                    fieldValue: "N",
                    fieldBool: false,
                    updatedBy: "",
                    updatedDate: null,
                    color: "Red",
                    fieldCode: "Contact Verified",
                },
                covidCommunicationSent: {
                    fieldValue: "N",
                    fieldBool: false,
                    updatedBy: "",
                    updatedDate: null,
                    color: "Red",
                    fieldCode: "Covid Communication Sent",
                },
                covidReplyRecd: {
                    fieldValue: "N",
                    fieldBool: false,
                    updatedBy: "",
                    updatedDate: null,
                    color: "Red",
                    fieldCode: "Covid Reply Recd",
                },
            };
        }
    }

    deleteBackupContact(
        eventId: any,
        positionListItem: any,
        removedContact: any,
        contactType: any,
        serviceMethodName: any,
        requireCheckConflicts: any,
        backupContactAction: any,
        contactId: any
    ) {
        const crewPositionBackupContact = removedContact;
        crewPositionBackupContact.backupAction = "Delete";
        const positionItems: any[] = [];
        positionItems.push(positionListItem);

        this.subs.sink = this._eventService
            .deleteBackupContact(positionListItem.eventId, positionItems)
            .subscribe({
                next: (result: any) => {
                    const index = _.findIndex(
                        positionListItem.crewPositionBackupItems,
                        function (o: any) {
                            return o.contactCode == contactId;
                        }
                    );

                    positionListItem.crewPositionBackupItems.splice(index, 1);
                    //.splice($index, 1);
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to delete a backup contact at this time. Please try again later."
                    );
                },
            });
    }

    /**
     * Assign/Unassign contact
     * @param {Object[]} positionListItem crew position list item
     * @param {Object} contactObj contact object
     * @param {String} contactType contact type primary/secondary/alternate
     * @param {String} serviceMethodName service method name addUpdatePositions/updateEventPositionContact
     * @param {Boolean} requireCheckConflicts require check conflicts
     * @fires updateCrewPosition
     * @memberOf score.controllers.EventCrewingController
     * @private
     */
    assignUnassignContact(
        positionListItem: any,
        contactObj: any,
        contactType: any,
        serviceMethodName,
        requireCheckConflicts: any,
        backupContactAction?: any,
        contactId?: any
    ) {
        const serviceActionMethodName =
                serviceMethodName || "addUpdatePositions",
            initialContactBackUpInfo: any = {};
        let fullGridRefeshRequired = false;
        let tempPrimaryContact: any;
        let isNewBackupContact = false,
            isUpdateBackupContact = false;

        // we need to have initial state of the object in case if there are conflicts and user do not want to proceed with
        // contact assignment.
        initialContactBackUpInfo.listItem =
            positionListItem && positionListItem.length
                ? _.cloneDeep(positionListItem[0])
                : _.cloneDeep(positionListItem);

        ////get rate value associated with Ratecard : MinWage Story
        //if (contactObj.length !== undefined) {
        //  var rateCard = _.filter(contactObj.employeeType.rateCards, (rateCard) => { return rateCard.id == contactObj.rateCode.id })
        //  var rateValue;
        //  if (rateCard.length > 0) {
        //    rateValue = rateCard[0].billingRate;
        //  }
        //}

        if (contactId !== null && contactId !== undefined) {
            initialContactBackUpInfo.contactCode = contactId;
        }
        initialContactBackUpInfo.contactObj = {};
        initialContactBackUpInfo.contactType = "";
        if (backupContactAction !== null && backupContactAction !== undefined) {
            if (
                backupContactAction === "New" ||
                (backupContactAction === "Cancel" &&
                    positionListItem.primaryContact === null &&
                    contactId !== undefined)
            ) {
                let backupCount = positionListItem.crewPositionBackupCount;
                if (backupCount === 0) {
                    positionListItem.crewPositionBackupItems = [];
                    positionListItem.crewPositionBackupCount = 1;
                }
                backupCount = backupCount + 1;
                if (
                    backupContactAction === "Cancel" &&
                    positionListItem.primaryContact === null
                ) {
                    contactObj.id = contactId;
                }
                positionListItem.crewPositionBackupItems.push({
                    BackupNo: -1,
                    PositionNo: positionListItem.id,
                    DisplaySequence: backupCount,
                    ContactCode: "" + contactObj.id + "",
                    ContactName: contactObj.fullName,
                    CustomCb1: "Y",
                    CustomCb2: "Y",
                    BackupAction: "New",
                });
                isNewBackupContact = true;
            } else if (backupContactAction === "Update") {
                initialContactBackUpInfo.contactCode = contactId;
                const primaryContactId = positionListItem.primaryContactId;
                const crewPositionBackupContact = _.find(
                    positionListItem.crewPositionBackupItems,
                    { contactCode: contactId }
                );
                let empObj: any = null;
                let rateObj: any = null;
                if (!_.isEmpty(crewPositionBackupContact.employeeType)) {
                    empObj = _.find(this.employeeTypes, {
                        description: crewPositionBackupContact.employeeType,
                    });

                    rateObj = _.find(empObj.rateCards, {
                        id: crewPositionBackupContact.rateCard,
                    });
                }

                const alternateContact =
                    crewPositionBackupContact.backupContact;
                const alternateContactId =
                    crewPositionBackupContact.contactCode;
                positionListItem.primaryContactId = alternateContactId;
                if (this.isTieredEvent) {
                    // we dont update BTL position info
                    if (positionListItem.eventPosition.category === "ATL") {
                        if (
                            alternateContact &&
                            alternateContact.employeeType !== null
                        ) {
                            positionListItem.eventPosition.employeeType =
                                alternateContact.employeeType;
                            positionListItem.eventPosition.rateCode =
                                alternateContact.rateCode;
                        } else {
                            if (
                                crewPositionBackupContact?.employeeType &&
                                empObj != null
                            ) {
                                if (
                                    positionListItem.eventPosition.employeeType
                                ) {
                                    positionListItem.eventPosition.employeeType =
                                        empObj;
                                } else {
                                    positionListItem.eventPosition.employeeType =
                                        empObj;
                                }
                            }
                            if (
                                crewPositionBackupContact?.rateCode ||
                                crewPositionBackupContact.rateCard
                            ) {
                                if (positionListItem.eventPosition.rateCode) {
                                    positionListItem.eventPosition.rateCode =
                                        rateObj;
                                } else {
                                    positionListItem.eventPosition.rateCode =
                                        rateObj;
                                }
                            }
                        }
                    }
                } else {
                    /* NORMAL WORKFLOW : overwrite contact rate and employee types to the position*/
                    // if contact has default employeeType and rateCode set .we want to set these values to event position
                    if (
                        alternateContact !== null &&
                        alternateContact.employeeType !== null
                    ) {
                        positionListItem.eventPosition.employeeType =
                            alternateContact.employeeType;
                        positionListItem.eventPosition.rateCode =
                            alternateContact.rateCode;
                    } else {
                        if (
                            crewPositionBackupContact.employeeType &&
                            empObj != null
                        ) {
                            if (positionListItem.eventPosition.employeeType) {
                                positionListItem.eventPosition.employeeType =
                                    empObj;
                            } else {
                                positionListItem.eventPosition.employeeType =
                                    empObj;
                            }
                        }
                        if (
                            crewPositionBackupContact.rateCode ||
                            crewPositionBackupContact.rateCard
                        ) {
                            if (positionListItem.eventPosition.rateCode) {
                                positionListItem.eventPosition.rateCode =
                                    rateObj;
                            } else {
                                positionListItem.eventPosition.rateCode =
                                    rateObj;
                            }
                        }
                    }
                }
                if (positionListItem.primaryContact) {
                    crewPositionBackupContact.contactCode = primaryContactId;
                    crewPositionBackupContact.backupContact =
                        positionListItem.primaryContact;
                    crewPositionBackupContact.firstName =
                        positionListItem.primaryContact.firstName;
                    crewPositionBackupContact.lastName =
                        positionListItem.primaryContact.lastName;
                    //positionListItem.primaryContact.resourceCode = alternateContactId;
                    if (crewPositionBackupContact.backupContact) {
                        if (
                            crewPositionBackupContact.backupContact
                                .contactNameNote === null
                        ) {
                            crewPositionBackupContact.backupContact.contactNameNote =
                                "";
                        }
                    }
                    positionListItem.primaryContact = alternateContact;
                    isUpdateBackupContact = true;
                    crewPositionBackupContact.backupAction = "Update";
                    if (positionListItem.primaryContact) {
                        if (
                            positionListItem.primaryContact.contactNameNote ===
                            null
                        ) {
                            positionListItem.primaryContact.contactNameNote =
                                "";
                        }
                        if (positionListItem.primaryContact.lastName === null) {
                            positionListItem.primaryContact.lastName = "";
                        }
                        if (positionListItem.primaryContact.email === null) {
                            positionListItem.primaryContact.email = "";
                        }
                        if (
                            positionListItem.primaryContact.middleName === null
                        ) {
                            positionListItem.primaryContact.middleName = "";
                        }
                        if (
                            positionListItem.primaryContact
                                .contactMiddleName === null
                        ) {
                            positionListItem.primaryContact.contactMiddleName =
                                "";
                        }
                    } else {
                        const previousBackupContact = _.find(
                            initialContactBackUpInfo.listItem
                                .crewPositionBackupItems,
                            { contactCode: contactId }
                        );
                        this.createPrimaryContact(
                            positionListItem,
                            previousBackupContact,
                            alternateContactId
                        );
                    }
                } else {
                    this.createPrimaryContact(
                        positionListItem,
                        crewPositionBackupContact,
                        alternateContactId
                    );
                    this.deleteBackupContact(
                        null,
                        positionListItem,
                        contactObj,
                        contactType,
                        null,
                        false,
                        "Delete",
                        alternateContactId
                    );
                }

                const positionItems: any = [];
                positionItems.push(positionListItem);
            } else if (backupContactAction === "Cancel") {
                if (contactId !== null && contactId !== undefined) {
                    const crewPositionBackupContact = _.find(
                        positionListItem.crewPositionBackupItems,
                        { contactCode: contactId }
                    );
                    if (positionListItem.primaryContact !== null) {
                        crewPositionBackupContact.backupAction = "Update";
                    }
                }
                const positionItems: any = [];
                positionItems.push(positionListItem);
            }
        }

        if (serviceActionMethodName === "addUpdatePositions") {
            // if we want to unassign contact we expect contactObj to be empty
            // and we want set status of a position to Open
            if (
                (_.isEmpty(contactObj) &&
                    contactType &&
                    contactType === "primary") ||
                (positionListItem.primaryContact === null &&
                    backupContactAction !== undefined)
            ) {
                positionListItem.eventPosition.status = {
                    description: "Open",
                    id: "O",
                };

                this.updateAdditionalLocationsQuery.eventId =
                    positionListItem.eventId;
                this.updateAdditionalLocationsQuery.crewPositionIds =
                    positionListItem.id;
                this.updateAdditionalLocationsQuery.additionalLocationId = 0;

                this.subs.sink = this._crewItemService
                    .sendAdditionalLocations(
                        positionListItem.eventId,
                        this.updateAdditionalLocationsQuery
                    )
                    .subscribe({
                        next: (result: any) => {},
                    });

                fullGridRefeshRequired = true;

                // if we want to assign contact to a position which has status open then we want to change the status to Requested
            } else if (contactType === "primary") {
                positionListItem.eventPosition.status = {
                    description: "Requested",
                    id: "R",
                };

                if (this.isTieredEvent) {
                    // we dont update BTL position info
                    if (
                        positionListItem.eventPosition.category === "ATL" &&
                        contactObj.employeeType != null
                    ) {
                        positionListItem.eventPosition.employeeType =
                            contactObj.employeeType;
                        positionListItem.eventPosition.rateCode =
                            contactObj.rateCode;
                        // positionListItem.eventPosition.configuredRate = rateValue;
                    }
                } else {
                    if (contactObj.employeeType != null) {
                        /* NORMAL WORKFLOW : overwrite contact rate and employee types to the position*/
                        // if contact has default employeeType and rateCode set .we want to set these values to event position
                        positionListItem.eventPosition.employeeType =
                            contactObj.employeeType;
                        positionListItem.eventPosition.rateCode =
                            contactObj.rateCode;
                    }
                    //positionListItem.eventPosition.configuredRate = rateValue;
                }
            }
        } else {
            // we are using updateEventPositionContact service method and it requires array of positionListItems as a parameter.
            if (
                positionListItem &&
                positionListItem.primaryContact &&
                positionListItem.eventPosition?.status?.id !== "C"
            ) {
                positionListItem.eventPosition.status = {
                    description: "Requested",
                    id: "R",
                };
            } else if (
                positionListItem &&
                !positionListItem.primaryContact &&
                positionListItem.eventPosition?.status?.id !== "O"
            ) {
                // If Primary Contact got removed change position to open status
                positionListItem.eventPosition.status = {
                    description: "Open",
                    id: "O",
                };
            }

            positionListItem = [positionListItem];
        }

        this.subs.sink = this._eventService[serviceActionMethodName](
            this.eventId,
            positionListItem,
            contactObj,
            contactType
        ).subscribe({
            next: (result: any) => {
                // if we are working with addUpdatePositions event service method
                // that is default service method used for every assign/unassign contact call.
                // Current service call is not used when we swap contacts
                if (serviceActionMethodName === "addUpdatePositions") {
                    //check conflict
                    // only when we have contactObj if it is empty that is contact unassign action
                    if (_.isEmpty(contactObj)) {
                        positionListItem[contactType + "Contact"] = null;
                        if (fullGridRefeshRequired) {
                            this.getCrewItemByQuery();
                        } else {
                            this._eventBus.emit(
                                new EmitEvent(
                                    "crew-page:_eventService",
                                    "updateGridRow",
                                    positionListItem
                                )
                            );
                        }
                    } else {
                        positionListItem[contactType + "Contact"] = contactObj;
                        if (contactType === "primary") {
                            this.checkCrewConflict(
                                contactType,
                                positionListItem,
                                contactObj,
                                initialContactBackUpInfo
                            );
                        } else {
                            this._eventBus.emit(
                                new EmitEvent(
                                    "crew-page:_eventService",
                                    "updateGridRow",
                                    positionListItem
                                )
                            );
                        }
                    }
                } else {
                    // if updateEventPositionContact service call

                    // we want to check conflict only if a contact primary position is going to take a new contact, otherwise
                    // if the contact is old one or it is revert action (when an user cancel contact assignment from the conflict modal)
                    // then we don't want to check conflict because it has been checked before for that contact.
                    if (requireCheckConflicts) {
                        if (positionListItem[0].primaryContact) {
                            // we are swapping a backup contact with a primary contact then we are using updateEventPositionContact service method.
                            // updateEventPositionContact requires to have array of positionListItem(s) as a parameter in our case it is going to be an array of one element
                            // so we can safely use positionListItem[0]
                            this.checkCrewConflict(
                                "primary",
                                positionListItem[0],
                                positionListItem[0].primaryContact,
                                initialContactBackUpInfo,
                                null,
                                null,
                                null,
                                isUpdateBackupContact
                            );
                        } else {
                            this.getCrewItemByQuery();
                        }
                    } else {
                        this.getCrewItemByQuery();
                    }
                }
                if (isNewBackupContact) {
                    //this.getCrewItemByQuery();
                    this.getCrewPositionBackup(positionListItem);
                }

                this.getCrewItemHeader();
            },
            error: (err: any) => {
                this.log.error("got an error: " + JSON.stringify(err));
                this.log.showError(
                    "Unable to assign/unassign the contact at this time. Please try again later."
                );
            },
        });
    }

    GetContactCustomDetails(positionListItem: any) {
        this.subs.sink = this._contactService
            .getContact(positionListItem.primaryContact.id)
            .subscribe({
                next: (result: any) => {
                    const contact = result.reference;
                    positionListItem.primaryContact.contactVerified =
                        contact.contactVerified;
                    positionListItem.primaryContact.covidCommunicationSent =
                        contact.covidCommunicationSent;
                    positionListItem.primaryContact.covidReplyRecd =
                        contact.covidReplyRecd;
                    if (
                        positionListItem.primaryContact &&
                        positionListItem.primaryContact.contactVerified
                    ) {
                        if (
                            positionListItem.primaryContact.contactVerified
                                .updatedBy != ""
                        ) {
                            positionListItem.primaryContact.contactVerified.modifiedDate =
                                moment
                                    .utc(
                                        positionListItem.primaryContact
                                            .contactVerified.updatedDate
                                    )
                                    .format("MM/DD/YYYY");
                        }
                        positionListItem.primaryContact.contactVerified.color =
                            positionListItem.primaryContact.contactVerified
                                .fieldBool
                                ? "Green"
                                : "Red";
                    }

                    if (
                        positionListItem.primaryContact &&
                        positionListItem.primaryContact.covidCommunicationSent
                    ) {
                        if (
                            positionListItem.primaryContact
                                .covidCommunicationSent.updatedBy != ""
                        ) {
                            positionListItem.primaryContact.covidCommunicationSent.modifiedDate =
                                moment
                                    .utc(
                                        positionListItem.primaryContact
                                            .covidCommunicationSent.updatedDate
                                    )
                                    .format("MM/DD/YYYY");
                        }
                        positionListItem.primaryContact.covidCommunicationSent.color =
                            positionListItem.primaryContact
                                .covidCommunicationSent.fieldBool
                                ? "Green"
                                : "Red";
                    }

                    if (
                        positionListItem.primaryContact &&
                        positionListItem.primaryContact.covidReplyRecd
                    ) {
                        if (
                            positionListItem.primaryContact.covidReplyRecd
                                .updatedBy != ""
                        ) {
                            positionListItem.primaryContact.covidReplyRecd.modifiedDate =
                                moment
                                    .utc(
                                        positionListItem.primaryContact
                                            .covidReplyRecd.updatedDate
                                    )
                                    .format("MM/DD/YYYY");
                        }
                        positionListItem.primaryContact.covidReplyRecd.color =
                            positionListItem.primaryContact.covidReplyRecd
                                .fieldBool
                                ? "Green"
                                : "Red";
                    }
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:GetContactCustomDetails",
                            "updateGridRow",
                            positionListItem
                        )
                    );
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                },
            });
    }
    /**
     * Leave Comment Modal
     * @param {Object[]} positionListItem crew position list items
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     */
    leaveCommentsModal(positionListItem: any) {
        const initialState: any = {
            positionListItem: positionListItem,
            eventId: this.eventId,
            comments: positionListItem.eventPosition.comments,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            ModalEditPositionCommentsComponent,
            modalOptions
        );
        // To disable clicable in popover while opening Modal Popup
        setTimeout(() => {
            const popoverElement = document.querySelector(
                "popover-container"
            ) as HTMLElement;
            if (popoverElement) {
                popoverElement.style.zIndex = "1";
            }
        }, 100);
        this.bsModalRef.content.onSync.subscribe((result: any) => {
            this.log.trace("Modal dismissed with successful removal");
            positionListItem.eventPosition.comments = result;
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:leaveCommentsModal",
                    "updateGridRow",
                    positionListItem
                )
            );
        });
    }

    /**
     * Send Air Travel Notification
     * @param {Object[]} positionListItem crew position list items
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    sendAirTravelNotification(positionListItems: any) {
        this.log.trace("sendAirTravelNotification");

        this.subs.sink = this._crewItemService
            .sendAirTravelNotification(this.eventId, positionListItems)
            .subscribe({
                next: (result: any) => {
                    let index = 0;
                    const positionListItemsLength = positionListItems.length;
                    for (index; index < positionListItemsLength; index++) {
                        const listItem = positionListItems[index];
                        if (
                            //listItem.eventPosition.airportCode &&
                            (listItem.eventPosition.isInBoundTraveling ||
                                listItem.eventPosition.isOutBoundTraveling) &&
                            (listItem.eventPosition.hasTravelIn ||
                                listItem.eventPosition.hasTravelOut) &&
                            listItem.eventPosition.status.description.toLowerCase() ===
                                "confirmed"
                        ) {
                            listItem.airTravelNotificationSent = true;
                        }
                    }
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:sendAirTravelNotification",
                            "bulkUpdateGridRows",
                            this.positionListItems
                        )
                    );
                },
                error: (err) => {
                    this.log.error(
                        "sendAirTravelNotification - got an error: " +
                            JSON.stringify(err.error)
                    );
                    this.log.showError(
                        "Unable to Send Air Travel Notification at this time. Please try again later."
                    );
                },
            });
    }

    /**
     * Bulk Update Positions
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    bulkUpdatePositions() {
        let isUpdateSuccess: any = true;
        this.updateSubmit = true;

        if (this.bulkEditCategory?.value === "Employee Type") {
            isUpdateSuccess = this.bulkUpdateEmployeeType();
            // this.bulkUpdateEmployeeType();
        } else if (this.bulkEditCategory?.value === "Work Schedule") {
            this.bulkUpdateWorkSchedule();
        } else if (this.bulkEditCategory?.value === "Travel Notification") {
            this.sendAirTravelNotification(this.selectedPositionListItems);
        } else if (this.bulkEditCategory?.value === "Unassign Contact") {
            this.bulkUnassignPositions(this.selectedPositionListItems);
        } else if (this.bulkEditCategory?.value === "Delete Position") {
            this.bulkDeletePositions(this.selectedPositionListItems);
        } else if (this.bulkEditCategory?.value === "Inbound Travel") {
            isUpdateSuccess = this.bulkUpdateInBoundTravel();
        } else if (this.bulkEditCategory?.value === "Outbound Travel") {
            isUpdateSuccess = this.bulkUpdateOutBoundTravel();
        } else if (this.bulkEditCategory?.value === "Tier Rate Card") {
            this.bulkUpdateTieredPositions();
        } else if (this.bulkEditCategory?.value === "Position Request") {
            this.bulkUpdateOnboardingPositionRequests(
                this.selectedPositionListItems
            );
        } else if (this.bulkEditCategory?.value === "Location") {
            this.bulkUpdateAdditionalLocation();
        }
        if (
            this.bulkEditCategory?.value !== "Unassign Contact" &&
            this.bulkEditCategory?.value !== "Delete Position" &&
            isUpdateSuccess
        ) {
            this.cancelBulkUpdate();
        }
    }
    /**
     * Clear fields on selection of in bound and out bound items from group date fropdown
     * @public
     * @memberOf score.controllers.EventCrewingController
     */
    clearTravelFields(bulkCategory: any) {
        this.updateSubmit = false;
        this.bulkEditFlight = false;
        this.bulkEditFlightLocal = "local";
        this.bulkEditFlightDestination = "";
        this.bulkEditFlightDepature = "";
        this.bulkEditFlightArrival = "";
        if (bulkCategory.value === "Location") {
            this.bulkAddLocationSelect = _.find(this.additionalLocations, {
                locationName: "@Home",
            });
            return true;
        }
        return true;
    }

    onGroupUpdateItemSelected(bulkCategory: any) {
        if (!bulkCategory) {
            return false;
        }

        if (bulkCategory.value == "Tier Rate Card") {
            this.validateTieredRateCardRulesForGroupUpdate();
            return true;
        }

        if (bulkCategory.value === "Location") {
            this.bulkAddLocationSelect = _.find(this.additionalLocations, {
                locationName: "@Home",
            });
            return true;
        }
        return true;
    }

    validateTieredRateCardRulesForGroupUpdate() {
        if (!this.bulkUpdateOnlyBtlSelected) {
            const msg =
                "Group updates for Tiered Rate Cards can only be performed on BTL, please select BTL positions to update.";
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.cancelBulkUpdate,
                null,
                "Proceed",
                null,
                this.cancelBulkUpdate,
                null
            );
            return;
        }

        //not applicable to Vendors
        const selectedVendorEmployeeTypes = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return (
                    selection.eventPosition.employeeType &&
                    selection.eventPosition.employeeType.description.toLowerCase() ===
                        "vendor"
                );
            }
        );

        if (
            selectedVendorEmployeeTypes &&
            selectedVendorEmployeeTypes.length > 0
        ) {
            const msg =
                "Your selection contains Vendor Employee Type positions, which cannot have Tiered Rate Cards.  Please select only Non-Vendor Employee types and continue.";
            this.showValidationErrorModal(
                "Tiered Rate Card",
                msg,
                true,
                this.cancelBulkUpdate,
                null,
                "Proceed",
                null,
                this.cancelBulkUpdate,
                null
            );
            return;
        }

        //not having employee types
        const employeeTypesNotavailable = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return !selection.eventPosition.employeeType;
            }
        );

        if (employeeTypesNotavailable && employeeTypesNotavailable.length > 0) {
            const msg =
                "Employee Types must be selected before a Tiered Rate Card can be entered on a position.";
            this.showValidationErrorModal(
                "Tiered Rate Card",
                msg,
                true,
                this.cancelBulkUpdate,
                null,
                "Proceed",
                null,
                this.cancelBulkUpdate,
                null
            );
            return;
        }
    }

    /**
     * Bulk Update - Un assign positions
     * @param {Object[]} positionListItem crew position list items
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkUnassignPositions(selectedPositionListItems: any) {
        let canUnassignContact = true;
        const positionsList: any = [];
        let selectedOpenPosition = 0;
        for (let count = 0; count < selectedPositionListItems.length; count++) {
            if (
                selectedPositionListItems[
                    count
                ].eventPosition.status.description.toLowerCase() === "open"
            ) {
                selectedOpenPosition++;
            }
            if (
                selectedPositionListItems[count].eventPosition.status
                    .description === "Confirmed"
            ) {
                canUnassignContact = false;
            }
            if (
                selectedPositionListItems[count].eventPosition.status
                    .description === "Requested"
            ) {
                positionsList.push(this.selectedPositionListItems[count]);
            }
        }
        if (selectedOpenPosition !== selectedPositionListItems.length) {
            if (canUnassignContact) {
                this.openRemoveCrewModal(positionsList, true);
                this.cancelBulkUpdate();
            } else {
                this.log.showError(
                    "One or more of the positions you selected are confirmed. You may not group unassign confirmed positions. Please un-confirm those positions and try again."
                );
            }
        }
    }
    /**
     * Bulk Update - Delete positions
     * @param {Object[]} positionListItem crew position list items
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires bulkUpdateGridRows
     */
    bulkDeletePositions(selectedPositionListItems: any) {
        let canDeletePosition = true;
        for (let count = 0; count < selectedPositionListItems.length; count++) {
            if (
                selectedPositionListItems[count].primaryContact ||
                selectedPositionListItems[count].crewPositionBackupCount > 0
            ) {
                canDeletePosition = false;
            }
        }
        if (canDeletePosition) {
            this.openDeletePositionModal(selectedPositionListItems, true);
            this.cancelBulkUpdate();
        } else {
            this.log.showError(
                "One or more of the positions you selected are assigned. You may not delete assigned positions. Please un-assign those positions and try again."
            );
        }
    }

    /**
     * Check Task Count
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @returns {Boolean}
     */
    checkTaskCount() {
        this.log.trace("check task count");

        const checkedItems = this.taskItems.filter(function (item) {
            if (item.isChecked === true) {
                return true;
            }
            return false;
        });

        if (checkedItems.length === 4) {
            this.taskItems.forEach(function (item) {
                if (item.isChecked === false) {
                    item.disabled = true;
                } else {
                    item.disabled = false;
                }
            });
        } else {
            this.taskItems.forEach(function (item) {
                item.disabled = false;
            });
        }
    }

    onEmpTypeChange() {
        if (this.crewItemQuery.employeeTypeId) {
            this.crewItemQuery.employeeTypeId =
                this.crewItemQuery.employeeTypeId.id == -1
                    ? null
                    : this.crewItemQuery.employeeTypeId.id;
        }
        this.getCrewItemByQuery$();
        this.getCrewItemByQuery();
    }

    applyCrewItemFilter() {
        this.crewItemQuery.currentPage = 1;
        this.getCrewItemByQuery();
    }

    searchPage(pageSize) {
        this.crewItemQuery.pageSize = pageSize;
        this.getCrewItemByQuery();
    }

    /**
     * Get Crew Item By Query
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires onUpdateCrewPositionTypesFilter
     * @fires buildGrid
     * @param {Boolean} isWorkActivityBulkUpdate is work activity bulk update
     * @param {Boolean} disableGridSort to disable grid sort
     */
    getCrewItemByQuery(isWorkActivityBulkUpdate?: any, disableGridSort?: any) {
        if (this.crewItemQuery?.locationNo?.locationId) {
            this.crewItemQuery.locationNo =
                this.crewItemQuery?.locationNo?.locationId;
        }
        if (
            this.crewItemQuery?.status?.id === -1 ||
            this.crewItemQuery?.status?.id === ""
        ) {
            this.crewItemQuery.status = null;
        }
        this.log.trace("getCrewItemByQuery()");
        if (_.isUndefined(this.crewItemByQuery$)) this.getCrewItemByQuery$();

        this.crewItemByQuery$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result: any) => {
                    this.CrewItemByQueryResponseHandler(
                        result,
                        isWorkActivityBulkUpdate,
                        disableGridSort
                    );
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to get Crew Information. Please try again later."
                    );
                },
            });
    }

    getCrewItemByQuery$() {
        this.crewItemByQuery$ = new Observable<any>();
        this.crewItemByQuery$ = this._crewItemService.getCrewItemByQuery(
            this.crewItemQuery
        );
        return this.crewItemByQuery$;
    }

    getCrewPositionsByIds(crewPositionIds: any) {
        let selectiveCrewPos$ = new Observable<any>();
        const query = _.cloneDeep(this.crewItemQuery);
        let isSelectiveRefreshEnabled = true;

        //Suraj -- the existing crew grid data uses filters. So, if the any filter is active, we cannot proceed with the
        // approach to update only affected row. In certain scanarios, such as confirming/unconforming, pos. request
        // the approach will fail to refresh correct data on the screen. To avoid that, we fallback on grid refresh.
        if (this.isCrewDataGridFilterActive()) {
            isSelectiveRefreshEnabled = false;
        } else query.crewPositionNo = crewPositionIds.join(",");

        if (isSelectiveRefreshEnabled) {
            selectiveCrewPos$ = this._crewItemService.getCrewItemByQuery(query);
            selectiveCrewPos$
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result: any) => {
                        this._eventBus.emit(
                            new EmitEvent(
                                "crew-grid:getCrewPositionsByIds",
                                "bulkUpdateGridRows",
                                result.crewItems.items
                            )
                        );
                    },
                    error: (err) => {
                        this.log.error("got an error: " + JSON.stringify(err));
                        this.log.showError(
                            "Unable to get Crew Information. Please try again later."
                        );
                    },
                });
        } else this.getCrewItemByQuery();
    }

    CrewItemByQueryResponseHandler(
        result: any,
        isWorkActivityBulkUpdate?: any,
        disableGridSort?: any
    ) {
        this.positionListItems = result.crewItems.items;

        this.totalRecords = result.crewItems.totalRecords;
        this.venueAirportCode = null;

        //SCOR-19041
        if (!this.additionalLocations) {
            this.additionalLocations = result.additionalLocations;
            this.additionalLocationFilter = _.cloneDeep(
                this.additionalLocations
            );
            this.additionalLocationFilter.push({
                locationId: -1,
                eventId: result.eventId,
                locationName: "No Location",
                isDeleted: "N",
            });
        }
        if (this.eventSummary && this.eventSummary.venue) {
            this.venueAirportCode = this.eventSummary.venue.venueAirportCode;
        }

        if (!this.positionTypes || this.positionTypes?.length === 0) {
            this.positionTypes = result.eventPositionTypes;
        }

        if (
            this.positionListItems &&
            this.positionListItems.length &&
            this.employeeTypes &&
            this.employeeTypes.length &&
            this.eventData
        ) {
            const data = {
                positionListItems: this.positionListItems,
                employeeTypes: this.clonedEmployeeTypes,
                eventData: this.eventData,
                crewItemQuery: this.crewItemQuery,
                disableGridSort: disableGridSort,
                tieredPositions: this.tieredPositions,
                additionalLocations: this.additionalLocations,
                selectedPositionsBulkUpdate: isWorkActivityBulkUpdate
                    ? this.selectedPositionListItems
                    : [],
            };
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:CrewItemByQueryResponseHandler",
                    "buildGrid",
                    data
                )
            );

            //if (isWorkActivityBulkUpdate) {
            //  this._eventBus.emit(new EmitEvent("crew-page:CrewItemByQueryResponseHandler", "setCheckState", this.selectedPositionListItems));
            // }
        } else {
            const data = {
                positionListItems: [],
                employeeTypes: this.clonedEmployeeTypes,
                eventData: this.eventData,
                crewItemQuery: this.crewItemQuery,
                disableGridSort: disableGridSort,
                tieredPositions: this.tieredPositions,
                selectedPositionsBulkUpdate: [],
            };
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:CrewItemByQueryResponseHandler",
                    "destroyGrid",
                    data
                )
            );
        }

        if (!isWorkActivityBulkUpdate) {
            this.displayBulkUpdate = false;
            this.cancelBulkUpdate();
        }
    }

    isCrewDataGridFilterActive() {
        if (
            (!this.crewItemQuery.department ||
                this.crewItemQuery.department == 0) &&
            (!this.crewItemQuery.employeeTypeId ||
                this.crewItemQuery.employeeTypeId == 0) &&
            (!this.crewItemQuery.positionRequest ||
                this.crewItemQuery.positionRequest == 0) &&
            (!this.crewItemQuery.positionTypeId ||
                this.crewItemQuery.positionTypeId == 0) &&
            _.isEmpty(this.crewItemQuery.status) &&
            _.isEmpty(this.crewItemQuery.sortBy)
        )
            return false;
        else return true;
    }

    /**
     * Open Position Modal
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list item
     */
    openPositionModal(positionListItem: any) {
        this.log.trace("Opening modal");

        const initialState: any = {
            self: this,
            positionListItem: positionListItem,
            scheduleDates: this.scheduleDates,
            taskItems: this.taskItems,
            eventId: this.eventId,
            positionCount: this.positionListItems.length,
            broadcastingDates: this.broadcastingDates,
            tieredPositions: this.tieredPositions,
            eventTierSetup: this.eventData.eventTierSetup,
            existingTier: this.eventData.tier,
            emps: this.clonedEmployeeTypes,
            minimumWage: this.eventData.minimumWage,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            modalUpsertPosition,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((updatedPosition: any) => {
            // this.log.trace("Modal dismissed with: " + updatedPosition);
            if (!_.isEmpty(positionListItem) && !_.isEmpty(updatedPosition)) {
                //this.getSingleCrewItem(this.eventId, positionListItem);
                this.getCrewPositionsByIds([positionListItem.id]);
            } else {
                this.getCrewItemByQuery();
            }
            this.getCrewItemHeader();
        });
    }
    //opening popup window
    openHrOnboardingModal(positionListItem: any) {
        if (
            positionListItem.primaryContact &&
            positionListItem.primaryContact.requestId !== null
        ) {
            const positionRequestNo = positionListItem.primaryContact.requestId; //hrOnboardingItem.requestNumber;
            const crewRegNo =
                positionListItem.primaryContact.crewRegistrationNo; //hrOnboardingItem.crewRegistrationNo;
            const drupalUrl = window.preload.uisettings.drupalHMModal;
            const onboardingStatus =
                positionListItem.isOnboardingPendingContact;

            // Checking position request and onboardingstatus
            if (positionRequestNo == "" && onboardingStatus != "N") {
                //SCOR -20260
                const title = "Message",
                    message =
                        "Contact onboarding is already in progress, please open the modal from <a target='_blank' href='Events/Event/" +
                        positionListItem.registration_Event_No +
                        "/Crew'> " +
                        positionListItem.registration_Event_Name +
                        "</a>";
                this.openTimesheetWarningModal(title, message);
            } else {
                const url =
                    drupalUrl +
                    "%26field_position_request_number%3D" +
                    positionRequestNo +
                    "%26field_status_crew_reg_number%3D" +
                    crewRegNo;
                const settings =
                    "top=125,left=280,width=800,height=775,location=0,titlebar=0";

                const newWindow = window.open(url, crewRegNo, settings);
                for (let i = 0; i < this.childwindows.length; i++) {
                    if (
                        this.childwindows[i] &&
                        this.childwindows[i] == newWindow &&
                        this.childwindows[i].opened
                    ) {
                        this.childwindows.splice(i, 1);
                        this.childwindows[i].close();
                    }
                }
                this.childwindows[this.childwindows.length] = newWindow;
                if (newWindow) {
                    newWindow.focus();
                }
            }
            return false;
        } else {
            this.log.showError("The Position is not Requested.");
            return false;
        }
    }

    /**
     * Open Position Request Modal
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list item
     */
    openEditPositionRequestModal(positionListItem: any) {
        this.log.trace("Opening modal");
        let positionRequests: any = [];
        if (positionListItem.length == undefined) {
            if (positionListItem.primaryContact.email == "") {
                this.log.trace(
                    "Contact is missing an email address to place position request"
                );
                let missingEmailErrorMsg = "";
                missingEmailErrorMsg =
                    "The selected position has an existing Request in Progess or it is missing an Email Address. This request will be cancelled";
                this.showValidationErrorModal(
                    "Error while placing Position Request",
                    missingEmailErrorMsg,
                    true,
                    null,
                    null,
                    "OK",
                    null,
                    null,
                    null
                );
                return;
            } else if (
                positionListItem.primaryContact.activeRegistrationStatus != "Y"
            ) {
                positionRequests.push(positionListItem);
            } else {
                this.log.showError(
                    "The Position has an existing Request in Progess."
                );
                return;
            }
        } else {
            positionRequests = positionListItem;
        }
        this.openPositionModel(positionRequests);
        /*    }*/
    }

    openCopyAnotherEventModal() {
        this.log.trace("Opening modal");
        const initialState: any = {
            mainEvent: this.eventData,
            mainEventAgendaList: [],
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            CopyPositionsComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result: any) => {
            this.log.trace("confirmation");
        });
    }

    /**
     * SCOR-10948
     * Open Export Modal
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires customize modal for report
     * @param {Object[]} positionListItem crew position list item
     */
    openExportModal() {
        this.log.trace("Opening export modal");
        const initialState: any = {
            modelValue: "Export Report",
            eventId: this.eventId,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            CrewChangeExportComponent,
            modalOptions
        );
    }

    /**
     * SCOR-19189
     * Open SSO Status Export Modal
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires customize modal for report
     * @param {Object[]} positionListItem crew position list item
     */
    openSSOStatusExportModal() {
        this.log.trace("Opening export modal");
        const initialState: any = {
            modelValue: "Export Report",
            eventId: this.eventId,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            SsoStatusReportComponent,
            modalOptions
        );
    }

    exportEmergencyContact() {
        this.subs.sink = this._reportService
            .exportEmergencyContact(this.eventId)
            .subscribe((data) => {
                const fileName = "SCORE-Remotes Emergency Contact Export_";
                this.openInExcel(data, fileName);
            });
    }

    openInExcel(data: any, fileName: any) {
        const d = moment(new Date()).format("MM-DD-yyyy");
        //var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        this.child.fileName = fileName + d;
        this.child.fileType = "excel";
        this.child.data = data;
        this.child.download();
    }

    //SCOR-12432
    openWorkScheduleCustomizeModal(reportType: any) {
        this.log.trace("Opening export modal");

        const initialState: any = {
            reportType: reportType,
            eventId: this.eventId,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            ExportModalCustomizeCrewWorkComponent,
            modalOptions
        );
    }

    /**
     * Open Schedule Conflict Modal
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @param {String} contactType contact type primary/secondary/alternate
     * @param {Object[]} itemConflict crew position list item
     * @param {Boolean} isCheckingConflictDetail is checking conflicts detail
     * @param {Object} initialObj initial object
     * @param {Boolean} hideAssignBtn hide assign btn inside conflict modal
     */
    openScheduleConflictModal(
        contactType: any,
        itemConflict: any,
        isCheckingConflictDetail: any,
        initialObj: any,
        hideAssignBtn: any,
        positionListItem: any
    ) {
        this.log.trace("Opening modal");

        const initialState: any = {
            itemConflict: itemConflict,
            checkingConflictDetail: isCheckingConflictDetail,
            isWorkScheduleConflict: contactType, // contactType is empty string if we are adding/editing work schedule
            hideAssignBtn: hideAssignBtn,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsScheduleRef = this.modalService.show(
            ModalScheduleConflictComponent,
            modalOptions
        );
        this.bsScheduleRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("Modal dismissed with: Assign Clicked");
            //Conflict confirmed, assign the contact anyway.
            //$scope.$emit("UpdateAllGridRow");
            //this.getCrewItemByQuery(false, false);
            //this.getSingleCrewItem(this.eventId, positionListItem);
            this._eventBus.emit(
                new EmitEvent(
                    "crew-page:bulkUpdateWorkSchedule",
                    "getRelatedCrewPositions",
                    [positionListItem]
                )
            );
            //  this._eventBus.emit(new EmitEvent("crew-page:openScheduleConflictModal", "updateGridRow", positionListItem));
        });
        this.bsScheduleRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("Modal dismissed with: No, Close");
            if (contactType) {
                if (!_.isEmpty(initialObj)) {
                    this.assignUnassignContact(
                        initialObj.listItem,
                        initialObj.primaryContact,
                        initialObj.contactType,
                        "updateEventPositionContact",
                        false,
                        "Cancel",
                        initialObj.contactCode
                    );
                } else {
                    this.assignUnassignContact(
                        positionListItem,
                        {},
                        contactType,
                        null,
                        false
                    );
                }
            } else {
                if (!_.isEmpty(initialObj)) {
                    this.bulkUpdateEventPositions([initialObj], () => {
                        if (
                            !crewItemConflictFactory.checkCrewItemConflict(
                                initialObj
                            )
                        ) {
                            crewItemConflictFactory.addCrewItemConflictChecked(
                                initialObj
                            );
                        } else {
                            crewItemConflictFactory.removeCrewItemConflictChecked(
                                initialObj
                            );
                        }
                        this._eventBus.emit(
                            new EmitEvent(
                                "crew-page:openScheduleConflictModal",
                                "updateGridRow",
                                initialObj
                            )
                        );
                    });
                }
            }
        });

        //var scheduleConflictModalInstance = $modal.open({
        //  templateUrl: "/scripts/app/areas/events/views/event/modalCrewScheduleConflict.html",
        //  controller: "modalCrewScheduleConflictController",
        //  controllerAs: "modalConflictCtr",
        //  keyboard: false,
        //  backdrop: "static",
        //  resolve: {
        //    itemConflict: function () {
        //      return itemConflict;
        //    },
        //    isCheckingConflictDetail: function () {
        //      return isCheckingConflictDetail;
        //    },
        //    isWorkScheduleConflict: function () {
        //      return contactType; // contactType is empty string if we are adding/editing work schedule
        //    },
        //    hideAssignBtn: function () {
        //      return hideAssignBtn;
        //    }
        //  }
        //});

        //scheduleConflictModalInstance.result.then(function () {
        //  log.trace("Modal dismissed with: Assign Clicked");
        //  //Conflict confirmed, assign the contact anyway.
        //  //this.getCrewItemByQuery();
        //  $scope.$emit("UpdateAllGridRow");
        //}, function () {
        //  log.trace("Modal dismissed with: No, Close");
        //  if (contactType) {
        //    if (!_.isEmpty(initialObj)) {
        //      //initialObj.listItem.primaryContact = null;
        //      assignUnassignContact(initialObj.listItem, initialObj.contactObj, initialObj.contactType, "updateEventPositionContact", false, "Cancel", initialObj.contactCode);
        //    } else {
        //      assignUnassignContact(positionListItem, {}, contactType);
        //    }
        //    //working with contact work schedule
        //  } else {
        //    if (!_.isEmpty(initialObj)) {
        //      bulkUpdateEventPositions([initialObj], function () {
        //        if (!conflictFactory.checkCrewItemConflict(initialObj)) {
        //          conflictFactory.addCrewItemConflictChecked(initialObj);
        //        } else {
        //          conflictFactory.removeCrewItemConflictChecked(initialObj);
        //        }
        //        $scope.$emit("updateGridRow", initialObj);
        //      });
        //    }
        //  }
        //});
    }

    /**
     * Show Contact Search Flyout
     * @public
     * @memberOf score.controllers.EventCrewingController
     * @fires showFlyout
     */
    showContactSearchFlyout() {
        //TODO:Angular Upgrade : Crew
        // this.$broadcast("showFlyout");
        this._sharedData.getCrewContactSearchFlyout(true);
        this.searchFlyoutPage = true;
        // const modalOptions = {

        // }
        // this.bsModalRef = this._modalService.show(CrewContactSearchFlyoutComponent, modalOptions);
    }

    /**
     * Get Airport Codes
     * @param {String} term search term
     * @memberOf score.controllers.EventCrewingController
     * @public
     * @returns {Promise}
     */
    getAirportCodes(term: any) {
        this.log.trace("getAirportCodes term: " + term);
        //return globalService.getAirportCodes(encodeURIComponent(term)).then(function (res) {
        //  log.trace("getAirportCodes response: " + JSON.stringify(res));
        //  return res.data.reference;
        //});
    }

    /********************************* Tiered Rate card******************************************************************/

    getTieredRateCardForPostion(
        networkGroupId: any,
        tierId: any,
        isEventClosed: any,
        eventId: any
    ) {
        this.log.trace("getTieredRateCardForPostion()");
        if (tierId && tierId > 0) {
            this.subs.sink = this._eventService
                .getTieredRateCardForPostion(
                    networkGroupId,
                    tierId,
                    isEventClosed,
                    eventId
                )
                .subscribe({
                    next: (result) => {
                        this.tieredPositions = result.reference;
                        this.groupUpdateTierPositions = _.cloneDeep(
                            this.tieredPositions
                        );
                    },
                    error: (err) => {
                        this.log.error(
                            "Error while fetching tier positions " +
                                JSON.stringify(err)
                        );
                        this.log.showError("Failed to load Tier Positions");
                    },
                });
        }
    }

    openEditTierRatecardModal(positionListItem: any, allTieredPositions: any) {
        this.log.trace("openEditTierRatecardModal");

        const selected_billing_code =
            positionListItem.eventPosition.rateCode.billing_code;
        const tierrateCard = _.find(allTieredPositions, {
            billing_code: selected_billing_code,
        });

        const initialState: any = {
            position: positionListItem,
            tierrateCard: tierrateCard,
            eventId: this.eventId,
            minimumWage: this.eventData.minimumWage,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            ModalTierRatecardComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((positionListItem: any) => {
            positionListItem.eventPosition.rateCode =
                positionListItem.eventPosition.rateCode;
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:getSingleCrewItem",
                    "updateGridRow",
                    positionListItem
                )
            );
            //below line is to show inprogress icon. This is required as above line of updateGridRow will rerender the grid row. We need to show selected rate.
            positionListItem.asyncDataSave.rateCodeState = "inprogress";
            this.reportAsyncStatus(positionListItem, "rateCode");
            this.saveEventPositionRateCode(positionListItem);
        });
    }

    openTierRatecardModal() {
        this.log.trace("openEditTierRatecardModal");
        const tierrateCard = this.selectedRateCard;
        const sourceModule = "groupUpdate";
        const positionListItem = {
            position: _.isEmpty(this.selectedPositionListItems)
                ? null
                : this.selectedPositionListItems[0],
            customRate: this.customRateCard ? this.customRateCard : null,
            sourceModuleName: sourceModule,
            tierrateCard: tierrateCard,
            eventId: this.eventId,
            minimumWage: this.eventData.minimumWage,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: positionListItem,
        };

        this.bsModalRef = this.modalService.show(
            ModalTierRatecardComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((positionListItem: any) => {
            this.customRateCard = positionListItem.customRate;
            this.tierRateCardComment = positionListItem.tierRateCardComment;
        });
    }

    /*
     * Generic function to show the modal. All parameters are customizable per need
     */
    showValidationErrorModal(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any,
        hideConfirm?: any,
        showBackdrop?: any
    ) {
        this.log.trace("showValidationErrorModal");
        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText || null,
            hideConfirm: hideConfirm == null ? false : hideConfirm,
        };
        const modalOptions = {
            animated: true,
            keyboard: false,
            backdrop: showBackdrop == null ? true : showBackdrop,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this.modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("confirmation");
            callback.apply(this, args);
        });
        this.bsModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
            cancelCallback.apply(this, cancelargs);
        });
    }

    closeAlert() {
        //this.showAlertMessage = false;
        //this.alerts = [{ type: "danger", msg: "" }];
    }

    /********************************************************************************************************************/

    openBulkUpdateMenu(positionListItems: any) {
        this.displayBulkUpdate = true;

        this.selectedPositionListItems = positionListItems;

        const selectedATLPosition = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return selection.eventPosition.category === "ATL";
            }
        );

        this.bulkUpdateOnlyAtlSelected =
            positionListItems &&
            positionListItems.length == selectedATLPosition.length
                ? true
                : false;

        const selectedBTLPosition = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return selection.eventPosition.category === "BTL";
            }
        );

        this.bulkUpdateOnlyBtlSelected =
            positionListItems &&
            positionListItems.length == selectedBTLPosition.length
                ? true
                : false;

        if (this.bulkEditCategory?.value == "Tier Rate Card") {
            this.validateTieredRateCardRulesForGroupUpdate();
        }
    }

    selectAllRecords(positionListItems: any, showBulkUpdateOptions = false) {
        this.displayBulkUpdate = showBulkUpdateOptions;

        this.selectedPositionListItems = positionListItems;

        const selectedATLPosition = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return (
                    selection.eventPosition &&
                    selection.eventPosition.category === "ATL"
                );
            }
        );

        this.bulkUpdateOnlyAtlSelected =
            positionListItems &&
            positionListItems.length == selectedATLPosition.length
                ? true
                : false;

        const selectedBTLPosition = _.filter(
            this.selectedPositionListItems,
            function (selection) {
                return (
                    selection.eventPosition &&
                    selection.eventPosition.category === "BTL"
                );
            }
        );
        this.bulkUpdateOnlyBtlSelected =
            positionListItems &&
            positionListItems.length == selectedBTLPosition.length
                ? true
                : false;
    }

    onEventLoaded(action: any) {
        this.log.trace("onEventLoaded was called");
        this.isTieredEvent =
            !_.isEmpty(this.eventData) && this.eventData.tier > 0
                ? true
                : false;

        if (this.isTieredEvent) {
            const useOldRates =
                this.eventData.eventExecutionStatus.toUpperCase() ===
                    "CLOSED" ||
                this.eventData.eventExecutionStatus.toUpperCase() ===
                    "INPROGRESS"
                    ? true
                    : false;

            this.getTieredRateCardForPostion(
                this.eventData.networkGroupId,
                this.eventData.tier,
                useOldRates,
                this.eventData.id
            );
        }
        if (action === "editEventClosed") {
            this.getCrewItemByQuery();
            this.getCrewItemHeader();
        }
    }

    closeBulkUpdateMenu() {
        this.displayBulkUpdate = false;
        this.selectedPositionListItems = null;
        this.cancelBulkUpdate();
        this._eventBus.emit(
            new EmitEvent(
                "crew-page:closeBulkUpdateMenu",
                "OnBulkUpdateClosed",
                null
            )
        );
    }

    sortByColumn(column: any) {
        this.log.trace("Sort By Column");
        if (this.crewItemQuery.sortBy === column) {
            this.crewItemQuery.reverseSort = !this.crewItemQuery.reverseSort;
        } else {
            this.crewItemQuery.sortBy = column;
            this.crewItemQuery.reverseSort = false;
        }
        this.getCrewItemByQuery();
    }

    showCustomRateValidationMsg(listItem: any) {
        const msg =
            "Hourly rates of over $250.00 must be entered by Payroll Team, please enter $1.00 as the rate and notify payroll of what correct rate should be.";
        this.showValidationErrorModal(
            "Maximum Hourly Rate Exceeded",
            msg,
            true,
            null,
            null,
            null,
            "Close",
            this.reValidateCustomRate.bind(this),
            [listItem],
            true
        );
    }

    reValidateCustomRate(listItem: any) {
        this._eventBus.emit(
            new EmitEvent(
                "crew-page:reValidateCustomRate",
                "setCustomRateFocus",
                listItem
            )
        );
    }

    //$scope.$on("manipulateContact", function (event, positionListItems, requireCheckConflicts, initialContactBackUpInfo) {
    //  manipulateContact(positionListItems, requireCheckConflicts, initialContactBackUpInfo);
    //});

    //$scope.$on("toggleTravelButton", function (event, positionListItem) {
    //  toggleTravelButton(positionListItem);
    //});

    //$scope.$on("openExportModal", function (event, positionListItem) {
    //  this.openExportModal();
    //});

    //$rootScope.$on("sortByColumn", function (event, sortableColumnName) {
    //  $scope.sortByColumn(sortableColumnName);
    //});

    openPositionModel(positionListItems: any) {
        const initialState: any = {
            self: this,
            positionListItem: positionListItems,
            scheduleDates: this.scheduleDates,
            taskItems: this.taskItems,
            positionCount: positionListItems.length,
            broadcastingDates: this.broadcastingDates,
            employeeTypes: this.employeeTypes,
            eventData: this.eventData,
            crewItemQuery: this.crewItemQuery,
            tieredPositions: this.tieredPositions,
            //eventTierSetup: this.eventTierSetup,
            existingTier: this.eventData.tier,
            eventId: this.eventId,
            minimumWage: this.eventData.minimumWage,
        };
        const modalOptions = {
            animated: true,
            backdorp: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.bsModalRef = this._modalService.show(
            PositionRequestComponent,
            modalOptions
        );
        this.bsModalRef.content.onPositionRequestSync.subscribe(
            (result: any) => {
                // console.log('results', result);
                // this.getCrewItemByQuery(false, false);

                if (this.isCrewDataGridFilterActive()) {
                    this.getCrewItemByQuery();
                } else {
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-page:bulkUpdateWorkSchedule",
                            "getRelatedCrewPositions",
                            positionListItems
                        )
                    );
                }
                //this._eventBus.emit(new EmitEvent("crew-page:openPositionModel", "bulkUpdateGridRows", positionListItems));
            }
        );
    }

    private reportAsyncStatus(positionListItem: any, asyncCallType: any) {
        const data = { listItem: positionListItem, operation: asyncCallType };
        this._eventBus.emit(
            new EmitEvent(
                "crew-page:reportAsyncStatus",
                "reportAsyncStatus",
                data
            )
        );
    }

    subscribeEventHandlers() {
        this.subs.sink = this._eventBus.on(
            "workScheduleDaysInitialized",
            (data: any) => {
                this.scheduleDates = data.scheduleDates;
            }
        );

        this.subs.sink = this._eventBus.on(
            "setAirBroadcastingDates",
            (broadcastingDates: any) => {
                this.broadcastingDates = broadcastingDates;
            }
        );

        this.subs.sink = this._eventBus.on(
            "openEditPositionRequestModal",
            (positionListItem: any) => {
                this.openEditPositionRequestModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "openPositionModal",
            (positionListItem: any) => {
                this.openPositionModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "openDeletePositionModal",
            (positionListItem: any) => {
                this.openDeletePositionModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "openRemoveCrewModal",
            (positionListItem: any) => {
                this.openRemoveCrewModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "confirmContact",
            (positionListItem: any) => {
                this.confirmContact(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "openUnconfirmModal",
            (positionListItem: any) => {
                this.openUnconfirmModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "covidCommunicationSent",
            (positionListItem: any) => {
                this.covidCommunicationSent(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "contactVerified",
            (positionListItem: any) => {
                this.contactVerified(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "covidReplyRecd",
            (positionListItem: any) => {
                this.covidReplyRecd(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "i9DocsVerified",
            (positionListItem: any) => {
                this.i9DocsVerified(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on("selectAllRecords", (data: any) => {
            this.selectAllRecords(data.listItems, data.showBulkUpdate);
        });

        this.subs.sink = this._eventBus.on(
            "openBulkUpdateMenu",
            (selectedItems: any) => {
                this.openBulkUpdateMenu(selectedItems);
            }
        );

        this.subs.sink = this._eventBus.on(
            "assignBackupContact",
            (data: any) => {
                this.assignUnassignContact(
                    data.listItem,
                    data.backup,
                    data.contactType,
                    data.serviceMethodName,
                    true,
                    data.action,
                    data.backup.id
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "assignBackuptoPrimary",
            (data: any) => {
                this.assignUnassignContact(
                    data.listItem,
                    {},
                    "alternate",
                    data.serviceMethodName,
                    true,
                    data.backupContactAction,
                    data.backup.contactCode
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "removeBackupContact",
            (data: any) => {
                this.deleteBackupContact(
                    this.eventId,
                    data.listItem,
                    data.backup,
                    data.contactType,
                    data.serviceMethodName,
                    false,
                    data.action,
                    data.backup.contactCode
                );
            }
        );

        this.subs.sink = this._eventBus.on("setTravelType", (data: any) => {
            this.setTravelType(data.listItem, data.travelType, data.isInBound);
        });

        this.subs.sink = this._eventBus.on(
            "toggleOutBoundTravelButton",
            (listItem: any) => {
                this.toggleOutBoundTravelButton(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "toggleInBoundTravelButton",
            (listItem: any) => {
                this.toggleInBoundTravelButton(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "saveEventPositionTravel",
            (listItem: any) => {
                this.saveEventPositionTravel(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "savePositionApprovedDate",
            (listItem: any) => {
                this.savePositionApprovedDate(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "updateSortOrderForCrewItems",
            (data: any) => {
                this.updateSortOrderForCrewItems(
                    data.listItems,
                    data.redrawGrid,
                    data.refreshGrid
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "sendAirTravelNotification",
            (listItem: any) => {
                this.sendAirTravelNotification([listItem]);
            }
        );

        this.subs.sink = this._eventBus.on(
            "closeBulkUpdateMenu",
            (param: any) => {
                this.closeBulkUpdateMenu();
            }
        );

        this.subs.sink = this._eventBus.on("assignContact", (param: any) => {
            this.assignUnassignContact(
                param.listItem,
                param.contact,
                param.contactType,
                null,
                true
            );
        });

        this.subs.sink = this._eventBus.on(
            "openEditTierRatecardModal",
            (param: any) => {
                this.openEditTierRatecardModal(
                    param.listItem,
                    param.allTieredPositions
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "saveEventPositionEmployeeType",
            (listItem: any) => {
                this.saveEventPositionEmployeeType(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "saveEventPositionAdditionalLocation",
            (listItem: any) => {
                this.saveEventPositionAdditionalLocation(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "saveEventPositionRateCode",
            (listItem: any) => {
                this.saveEventPositionRateCode(listItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "getCrewItemHeader",
            (param: any) => {
                this.getCrewItemHeader();
            }
        );

        this.subs.sink = this._eventBus.on(
            "saveWorkScheduleDay",
            (data: any) => {
                this.saveWorkScheduleDay(
                    data.listItem,
                    data.day,
                    data.initialListItem
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "showCustomRateValidationMsg",
            (listItem: any) => {
                this.showCustomRateValidationMsg(listItem);
            }
        );

        this.subs.sink = this._eventBus.on("leaveComment", (listItem: any) => {
            this.leaveCommentsModal(listItem);
        });

        this.subs.sink = this._eventBus.on(
            "openHrOnboardingModal",
            (positionListItem: any) => {
                this.openHrOnboardingModal(positionListItem);
            }
        );

        this.subs.sink = this._eventBus.on(
            "openConflictModal",
            (positionListItem: any) => {
                this.checkCrewConflict(
                    "",
                    positionListItem,
                    positionListItem.primaryContact,
                    null,
                    true,
                    null,
                    true
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "invalidContactAlert",
            (data: any) => {
                this.log.showError(
                    "Do not proceed in crewing this contact; please contact Human Resources."
                );
            }
        );

        this.subs.sink = this._eventBus.on(
            "getCrewPositionsByIds",
            (data: any) => {
                this.getCrewPositionsByIds(data);
            }
        );
    }

    fileupload() {
        const fileupload = document.getElementById("file-upload");
        fileupload?.click();
    }

    importPositions(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        const extension = "." + this.fileData.name.split(".").pop();
        if (!_.includes(this.validExcelFileTypes, extension)) {
            this.log.showError(
                "The file being imported does not meet the correct excel format, please choose a file in .xlsx format."
            );
            return;
        }
        this.subs.sink = this._crewItemService
            .importPositions(this.eventId, this.fileData)
            .subscribe(
                (result) => {
                    //this.log.showSuccess("Positions are imported successfully.");
                    const fileName = "Positions Import_";
                    const success = result.headers
                        .get("content-disposition")
                        .split("filename=")[1][1];
                    if (success == "1") {
                        this.log.showError(
                            "Positions were not imported, please review the excel for additional details."
                        );
                    } else if (success == "2") {
                        this.log.showError(
                            "Positions were partially imported, please review the excel for additional details."
                        );
                    } else {
                        this.log.showSuccess(
                            "All Positions successfully imported."
                        );
                    }
                    this.openInExcel(result.body, fileName);
                    this.getCrewItemByQuery();
                    this.getCrewItemHeader();
                },
                (err) => {
                    this.log.showError("Error - Position import failed");
                }
            );
    }

    fullScreenMode(val: any) {
        this.isFullScreenMode = !this.isFullScreenMode;

        setTimeout(() => {
            if (this.isFullScreenMode) {
                const gridTableElement = document.querySelectorAll(
                    ".grid-table-container"
                );
                const tableContainerRow = gridTableElement[0] as HTMLElement;
                tableContainerRow.style.cssText +=
                    "max-height: 50px !important;";
                tableContainerRow.style.cssText +=
                    "min-height: 50px !important;";
            }
        }, 50);

        this._eventBus.emit(
            new EmitEvent(
                "crew-page:fullScreenMode",
                "fullScreenMode",
                this.isFullScreenMode
            )
        );
    }

    /**
     * Initialize
     * @memberOf score.controllers.EventCrewingController
     * @public
     * @fires buildGrid
     */

    ngOnInit() {
        this.isPageInit = true;
        this._sharedData.getEventSummary().subscribe((summary) => {
            this.eventSummary = summary;
        });
        this._sharedData.getEventInfo().subscribe((event) => {
            this.eventData = event;
            if (this.eventData && !_.isEmpty(this.eventData)) {
                this.eventId = this.eventData.id;
                this.onEventLoaded("");
                this.getCrewItemQuery();
                this.getCrewItemHeader();
                this.getWorkActivities();
                this.getDepartments();
                this.getCrewItemStatuses();

                this.getCrewItemByQuery$();
                this.getEmployeeTypes$();

                forkJoin(this.crewItemByQuery$, this.employeeTypes$).subscribe(
                    ([crewItemResponse, employeeTypeResponse]) => {
                        this.CrewItemByQueryResponseHandler(crewItemResponse);
                        this.employeeTypeResponseHandler(employeeTypeResponse);
                        setTimeout(() => {
                            this.addPositionRequest();
                        }, 100);
                        const param = {
                            positionListItems: this.positionListItems,
                            employeeTypes: this.clonedEmployeeTypes,
                            eventData: this.eventData,
                            crewItemQuery: this.crewItemQuery,
                            disableGridSort: false,
                            tieredPositions: this.tieredPositions,
                            selectedPositionsBulkUpdate: [],
                            minimumWage: this.eventData.minimumWage,
                            additionalLocations: this.additionalLocations,
                        };
                        this.loaded = true;
                        this._eventBus.emit(
                            new EmitEvent("crew-page:init", "buildGrid", param)
                        );
                    }
                );
            }
        });

        this._sharedData.getEventAirBroadcastDates().subscribe((dates) => {
            this.broadcastingDates = dates;
        });

        this._sharedData.setCurrentPage("Crew");

        this.tempInBoundArrAirportCode = null;
        this.tempOutBoundArrAirportCode = null;
        this.tempInBoundDepAirportCode = null;
        this.tempOutBoundDepAirportCode = null;
        this.toggleFlag = true;
        this.subscribeEventHandlers();
        //this.loadInitialValues();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
