import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PermissionService } from "src/app/Shared/Services/permission.service";
import * as _ from "lodash";
import uniqBy from "lodash.uniqBy";
import { ContactSharedDataService } from "../../contact-shared.service";
import { subscribeOn, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "Talent-permission",
    templateUrl: "./talent-permissions.component.html",
})
export class TalentPermissionComponent implements OnInit, OnDestroy {
    constructor(
        private _contactSharedData: ContactSharedDataService,
        private _permissionService: PermissionService,
        private log: NbcLoggerService
    ) {}
    dropdownList!: any[];
    selectedItems!: any[];
    roles: any | undefined;
    selectedrole: any | undefined;
    businessunits: any | undefined;
    selectedbusinessunit: any | undefined;
    departments: any | undefined;
    selecteddepartment: any | undefined;
    user: any | undefined;
    contact: any | undefined;
    permissions: any | undefined;
    allroles: any[] | undefined;
    resultsFound = false;
    componentDestroyed$: Subject<boolean> = new Subject();

    moduleName = "Talent permissions";

    getRoleSetup() {
        this.log.trace("get Talent Roles");
        this._permissionService
            .getTalentRoleSetup()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("get Talent RoleSetup got results: " + result);

                    if (result) {
                        this.allroles = result["reference"];
                        this.businessunits = uniqBy(this.allroles, (v) =>
                            [v.buId, v.buName].join()
                        );
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + err?.error?.message);
                    this.log.showError(
                        "Unable to load the Roles at this time. Please try again later."
                    );
                },
            });
    }

    getUserPermissions() {
        this.log.trace("getWorkSchedule");
        if (this.contact.sso != "") {
            this._permissionService
                .getTalentUserPermissions(this.contact.sso)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result) => {
                        // this.log.trace("getRoleSetup got results: " + JSON.stringify(result));

                        if (result) {
                            this.permissions = result["reference"];
                        }

                        if (this.permissions.length > 0) {
                            this.resultsFound = true;
                        } else {
                            this.resultsFound = false;
                        }
                    },
                    error: (err) => {
                        this.log.error("got an error: " + err?.error?.message);
                        this.log.showError(
                            "Unable to load the Permissions at this time. Please try again later."
                        );
                    },
                });
        }
    }

    ClearSelection() {
        this.businessunits = uniqBy(this.allroles, (v) =>
            [v.buId, v.buName].join()
        );
        this.selecteddepartment = null;
        this.selectedrole = null;
    }

    addRoles() {
        this.log.trace("getWorkSchedule");
        if (this.contact.sso != "") {
            const contact = {
                contactId: this.contact.id,
                sso: this.contact.sso,
                firstName: this.contact.firstName,
                lastName: this.contact.lastName,
                emailAddress: this.contact.email,
                createdBy: "206477201", //TODO:AngularUpgrade - User login window.preload.user.id,
                roles: [] as any,
            };

            const roleSelected = _.find(this.allroles, (x) => {
                return (
                    x.roleId == this.selectedrole &&
                    x.buId == this.selectedbusinessunit &&
                    x.deptId == this.selecteddepartment
                );
            });
            contact.roles.push(roleSelected);
            this._permissionService
                .SaveUserPermissions(this.contact.sso, contact)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result) => {
                        this.getUserPermissions();
                        // this.log.trace("getRoleSetup got results: " + JSON.stringify(result));
                        this.ClearSelection();
                    },
                    error: (err) => {
                        this.log.error("got an error: " + err?.error?.message);
                        this.log.showError(
                            "Unable to Save the Permissions at this time. Please try again later."
                        );
                    },
                });
        }
    }

    checkRole() {
        return this.selectedrole === null || this.selectedrole === undefined;
    }

    getDepartment() {
        if (this.selectedbusinessunit !== null) {
            const alldepartment = _.filter(this.allroles, (x) => {
                return x.buId == this.selectedbusinessunit;
            });
            this.departments = uniqBy(alldepartment, (v) =>
                [v.deptId, v.deptName].join()
            );
        } else {
            this.departments = null;
        }
        this.getRoles();
    }

    getRoles() {
        if (
            this.selecteddepartment !== null &&
            this.selecteddepartment !== undefined &&
            this.selectedbusinessunit !== null &&
            this.selectedbusinessunit !== undefined
        ) {
            const Roles = _.filter(this.allroles, (x) => {
                return (
                    x.buId == this.selectedbusinessunit &&
                    x.deptId == this.selecteddepartment
                );
            });
            this.roles = uniqBy(Roles, (v) => [v.roleId, v.roleName].join());
            this.selectedrole = null;
        } else {
            this.roles = null;
        }
    }

    ngOnInit() {
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contact = contact;
            this.getUserPermissions();
        });

        this.getRoleSetup();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
