import { Component, OnInit } from "@angular/core";
import { nextTick } from "process";
import { AnnouncementService } from "../Shared/Services/announcement.service";

@Component({
    selector: "global-announcement",
    templateUrl: "./announcement.component.html",
    styleUrls: ["./announcement.component.css"],
})
export class AnnouncementComponent implements OnInit {
    constructor(private announcementService: AnnouncementService) {}
    annoucement: any = {
        active: false,
        message: "",
    };

    get() {
        this.announcementService.get().subscribe({
            next: (result) => {
                this.annoucement = result;
            },
            error: (err) => {
                //no action
            },
        });
    }

    ngOnInit() {
        this.get();
    }
}
