import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, Observer, noop, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class VenueService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    autocomplete(searchterm: any): Observable<any> {
        this.log.trace("searching for :" + searchterm);
        // using github public api to get users by name
        return this.http
            .get<any>(this._apiroot.baseUrl + "venue/Search?term=" + searchterm)
            .pipe(
                map((data: any) => (data && data.reference) || []),
                tap(
                    () => noop,

                    (err) => {
                        this.log.error(
                            (err && err.message) || "Something goes wrong"
                        );
                        // in case of http error
                        //this.errorMessage = err && err.message || 'Something goes wrong';
                    }
                )
            );
    }

    fullSearch(term: string): Observable<any> {
        this.log.trace("searching: " + term);
        return this.http
            .get(this._apiroot.baseUrl + "teams/Search?term=" + term)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    search(term: string): Observable<any> {
        this.log.trace("searching: " + term);
        return this.http
            .get(this._apiroot.baseUrl + "venue/Search?term=" + term)
            .pipe(map((data: any) => (data && data.reference) || []));
    }

    getVenueById(id: any): Observable<any> {
        this.log.trace("getting venue details for " + id);
        return this.http.get(this._apiroot.baseUrl + "venue/" + id, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    saveVenueDetails(venue: any): Observable<any> {
        this.log.trace("saving credit show " + JSON.stringify(venue));
        return this.http
            .post(this._apiroot.baseUrl + "venues/saveVenueDetails", venue)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getAirportCodes(term: any): Observable<any> {
        this.log.trace("calling getAirportCodes:" + JSON.stringify(term));
        return this.http
            .get(this._apiroot.baseUrl + "global/airportCodes?term=" + term)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getAllVenues(venueFilterQuery: any): Observable<any> {
        this.log.trace("retrieving all venue");
        const venueSetupQuery = JSON.stringify(venueFilterQuery);
        return this.http
            .post(
                this._apiroot.baseUrl + "venues/getAllVenues",
                venueSetupQuery
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getAllVenuesCity(venueFilterQuery: any): Observable<any> {
        this.log.trace("retrieving all venue");
        const venueSetupQuery = JSON.stringify(venueFilterQuery);
        return this.http
            .post(
              this._apiroot.baseUrl + "venues/getAllVenuesCitySearch",
                venueSetupQuery
            )
            .pipe(
                map((data: any) => data.reference.items),
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getCountries(): Observable<any> {
        this.log.trace("getCountries");
        return this.http.get(this._apiroot.baseUrl + "countries").pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getVenueDetails(venueid: any): Observable<any> {
        this.log.trace("retrieving all venue");
        const venue = JSON.stringify({ venue: venueid });
        return this.http
            .post(this._apiroot.baseUrl + "venues/getVenueDetails", venue)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getAllTimeZones(): Observable<any> {
        this.log.trace("getCountries");
        return this.http.get(this._apiroot.baseUrl + "timezones").pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    getImpactedEvents(venueImpactedQuery: any): Observable<any> {
        this.log.trace("Remotes: get all Impacted Events by venue change");
        return this.http
            .post<any>(
                this._apiroot.baseUrl + "venue/changeImpact/getImpactedEvents",
                venueImpactedQuery
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
    saveVenue(venue: any): Observable<any> {
        this.log.trace("saving venue: " + venue);
        return this.http.post(this._apiroot.baseUrl + "venue", venue).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
