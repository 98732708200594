<div>
  <div class="modal-header">
    <h3 class="modal-title">{{reportName}}</h3>
    <button type="button"
            class="btn-close"
            (click)="cancel()"
            aria-hidden="true">
    </button>
  </div>
  <div *ngIf="showAlertMessage">
    <div class="pt-3">
      <nbc-modal-alert></nbc-modal-alert>
  </div>
  </div>
    <!--<span us-spinner spinner-key="genericSpinner" spinner-start-active="true"></span>-->
    <div class="modal-body">
      <div class="grid-table">

        <div class="grid-content-row"
             *ngIf="showDateRangeRowAll">
          <div class="grid-col report-name">
            <span style="color:red;">*</span> Date Range
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('DateRangeRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showDateRangeEditRow">
          <div class="grid-col report-name">
            <span style="color:red;">*</span> Date Range
          </div>
          <div class="grid-col input-info">
            <div class="input-group input-group-set max-input"
                 dropdown
                 is-open="dateRangeFilter"
                 on-toggle="onToggleDateRangeDatepicker()">
              <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
              <input type="text"
                     placeholder="Select Date Range"
                     class="form-control"
                     bsDaterangepicker
                     readonly
                     [(ngModel)]="bsInlineRangeValue"
                     #drp="bsDaterangepicker"
                     (bsValueChange)="onToggleFilterDatepicker($event)"
                     [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('DateRangeRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showDateRangeTagRow">
          <div class="grid-col report-name">
            Date Range
          </div>
          <div class="grid-col">
            <div class="col-md-6">
              <span class="filter-tag">
                {{ onboardingReportInfo.dateRangeStartDate | date:'MM/dd/yyyy'}}-{{onboardingReportInfo.dateRangeEndDate | date:'MM/dd/yyyy'}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeDateTag('DateRangeRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('DateRangeRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row"
             *ngIf="showRequestStatusRowAll">
          <div class="grid-col report-name">
            Request Status
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('RequestStatusRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showRequestStatusEditRow">
          <div class="grid-col report-name">
            Request Status
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <!-- <div nbc-multi-select-dropdown=""
              events="optionsChange"
               options="onboardingReport"
                selected-model="selectedRequestStatus"
                 translation-texts="name"
                  extra-settings="requestatusselectionsettings"
                   checkboxes="true" required style="width:220px">
                 </div> -->
                    <nbc-multi-select [source]="onboardingReport"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedRequestStatus"
                                      [placeholder]="'Select'"
                                      (nbcOnOptionSelect)="onItemSelectReport($event)">
                    </nbc-multi-select>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('RequestStatusRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showRequestStatusTagRow">
          <div class="grid-col report-name">
            Request Status
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6"
                   *ngFor="let requeststatus of selectedRequestStatus">
                <span class="filter-tag">
                  {{requeststatus.name}} <a>
                    <i class="fa fa-times-circle"
                       (click)="removeTag(requeststatus,'RequestStatusRow')"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
            <div class="grid-col report-edit">
              <a>
                <i class="fa fa-pencil"
                   (click)="editRow('RequestStatusRow')"></i>
              </a>
            </div>
          </div>

        <div class="grid-content-row" *ngIf="showProdMgrRowAll">
          <div class="grid-col report-name">
            Production Manager
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('ProdMgrRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight" *ngIf="showProdMgrEditRow">
          <div class="grid-col report-name">
            Production Manager
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="onboardingProductionManagers"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedProdMgr"
                                      [placeholder]="'Select'"
                                      (nbcOnOptionSelect)="onItemSelectProductionManagers($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('ProdMgrRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row" *ngIf="showProdMgrTagRow">
          <div class="grid-col report-name">
            Production Manager
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6"
                   *ngFor="let prodMgr of selectedProdMgr">
                <span class="filter-tag">
                  {{prodMgr.name}} <a>
                    <i class="fa fa-times-circle"
                       (click)="removeTag(prodMgr,'ProdMgrRow')"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
            <div class="grid-col report-edit">
              <a>
                <i class="fa fa-pencil"
                   (click)="editRow('ProdMgrRow')"></i>
              </a>
            </div>
          </div>


        <div class="grid-content-row"
             *ngIf="showHgrMgrRowAll">
          <div class="grid-col report-name">
            Hiring Manager
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('HgrMgrRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showHgrMgrEditRow">
          <div class="grid-col report-name">
            Hiring Manager
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <!-- <div nbc-multi-select-dropdown=""
              events="optionsChange"
               options="onboardingHiringManagers"
                selected-model="selectedHgrMgr"
                 translation-texts="name"
                  extra-settings="hgrmgrselectionsettings"
                   checkboxes="true" required style="width:220px"></div> -->


                    <nbc-multi-select [source]="onboardingHiringManagers"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedHgrMgr"
                                      [placeholder]="'Select'"
                                      (nbcOnOptionSelect)="onItemSelectHiringManagers($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('HgrMgrRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showHgrMgrTagRow">
          <div class="grid-col report-name">
            Hiring Manager
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6"
                   *ngFor="let hgrMgr of selectedHgrMgr">
                <span class="filter-tag">
                  {{hgrMgr.name}} <a>
                    <i class="fa fa-times-circle"
                       (click)="removeTag(hgrMgr,'HgrMgrRow')"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
            <div class="grid-col report-edit">
              <a>
                <i class="fa fa-pencil"
                   (click)="editRow('HgrMgrRow')"></i>
              </a>
            </div>
          </div>



      </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button [disabled]="isExport"
                type="button"
                class="btn btn-primary"
                (click)="exportRequestStatusReport()"
                value="disable">Export</button>

    </div>
</div>
<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
