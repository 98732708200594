<div cdkDropListGroup>
  <div>
    <nbc-alert></nbc-alert>    
    <div class="page-container event-crew">
      <div class="container-fluid">
        <div class="row"
             id="crew-hdr-fixed">
          <div class="col-md-12">
            <div class="header-bar clearfix">
              <div class="row">
                <div class="col-md-6">
                  <p class="heading-title float-start">
                    Crew
                    <div [class.d-none]="!isFullScreenMode ||null" style="width:80%;padding-left:50px;">
                      <nbc-alert></nbc-alert>
                    </div>
                </div>
                <div class="col-md-6 text-end">
                  <div class="float-end btn-fullscreen hidden-sm hidden-xs" maximize #maximize="maximize" (click)="fullScreenMode($event);"><span (click)="maximize.maximize();"></span></div>

                  <div class="float-end"
                       authenticate-user="reports.crewworkschedule.export">
                    <div class="btn-group dropdown"
                         dropdown>
                      <button type="button"
                              id="button-export"
                              dropdownToggle
                              aria-controls="dropdown-export"
                              class="btn dropdown-toggle btn-primary btn-fullscreen"
                              style="background-color: #ff8d23;">
                        Export Schedule <span class="caret"></span>
                      </button>
                      <ul *dropdownMenu class="dropdown-menu"
                          id="dropdown-export"
                          aria-labelledby="button-export"
                          role="menu">

                        <li role="menuitem" class="dropdown-item">
                          <a (click)="openWorkScheduleCustomizeModal('Excel')">Export to Excel</a>
                        </li>

                        <li role="menuitem" class="dropdown-item">
                          <a (click)="openWorkScheduleCustomizeModal('PDF')">Export to PDF</a>
                        </li>
                      </ul>
                    </div>

                  </div>

                  <div class="float-end">
                    <div class="btn-group dropdown"
                         dropdown>
                      <button type="button"
                              id="button-export"
                              aria-controls="dropdown-export"
                              class="btn dropdown-toggle btn-primary btn-fullscreen"
                              style="background-color: #ff8d23;" dropdownToggle>
                        Reports <span class="caret"></span>
                      </button>
                      <ul *dropdownMenu class="dropdown-menu"
                          id="dropdown-export"
                          aria-labelledby="button-export"
                          role="menu">

                        <li role="menuitem"
                            class="dropdown-item"
                            authenticate-user="event.crewworkschedule.export">
                          <a (click)="openExportModal()">Export Change Report</a>
                        </li>

                        <li role="menuitem"
                            class="dropdown-item"
                            authenticate-user="event.EmergencyContact.Export">
                          <a (click)="exportEmergencyContact()">Export Emergency Contact Report</a>
                        </li>

                        <li role="menuitem"
                            class="dropdown-item"
                            authenticate-user="event.ssostatusreport.export">
                          <a (click)="openSSOStatusExportModal()">Export Crew SSO Status Report</a>
                        </li>
                      </ul>
                    </div>
                  </div>




                  <div class="float-end" authenticate-user="event.crew.create">
                    <ng-template #popTemplate style="width:250px !important">
                      <div authenticate-user="event.crew.create" class="ns-popover-tooltip clearfix">
                        <div class="popover-content">
                          <ul class="add-agenda-options" style="font-size:14px !important;">
                            <li>
                              <a class="btn-link" (click)="openPositionModal({})">Create New Position</a>
                            </li>
                            <li>
                              <a class="btn-link" (click)="openCopyAnotherEventModal()">Copy from another event</a>
                            </li>
                            <li>
                              <a (click)="fileupload()" class="fileUpload btn-link" for="file-upload">Import Positions</a>
                              <input id="file-upload" type="file" style="display:none" accept=".xlsx" (change)="importPositions($event)" name="importfile" />
                            </li>
                          </ul>
                        </div>
                      </div>

                    </ng-template>
                    <button type="button" class="btn btn-primary"
                            [popover]="popTemplate"
                            placement="bottom"
                            [outsideClick]="true"
                            containerClass="customClass">
                      + Add position
                    </button>

                  </div>
                  <div class="float-end">
                    <button class="btn btn-primary btn-summary"
                            style="margin:0 15px;"
                            (click)="isSummaryExpanded=!isSummaryExpanded; getCrewItemHeader()">
                      <i class="fa fa-bar-chart" style="font-size:18px;color:white"></i>
                    </button>
                  </div>
                </div>
                <!-- filters -->
                <div class="content-filters" [ngStyle]="{'width':(isSummaryExpanded && !isFullScreenMode) ?'100%':'80%'}">
                  <div class="row p-0">
                    <div class="float-end" authenticate-user="event.crew.create">
                      <a class="btn btn-icon btn-contact-search m-1" style="position:absolute;width:54px;height:65px;"
                         [ngStyle]="{'left': (isFullScreenMode) ?'96%':'95%', 'top': !isFullScreenMode ? '':'45%'}"
                         (click)="showContactSearchFlyout()">
                        <span class="fa-stack fa-lg">
                          <i class="fa fa-male fa-stack-2x" style="font-size:1.75em"></i>
                          <i class="fa fa-search fa-flip-horizontal fa-stack-2x" style="font-size:0.75em;top:34px;left:0px;"></i>
                        </span>
                      </a>
                    </div>

                  </div>
                  <div [hidden]="!isSummaryExpanded || isFullScreenMode"
                       class="row p-3"
                       [ngClass]="isCrewHeaderBeingLoaded?'blur-content':''">
                    <crew-chart></crew-chart>
                    <!--<div ng-include
      src="'/scripts/app/areas/events/views/event/crewCharts.html'"
      ng-controller="crewChartController as crewChartCtr"></div>-->
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-inline form-sort-options">
                        <div class="form-group" *ngIf="!isTieredEvent">
                          <nbc-dropdown name="sortBy"
                                        [source]="sortBy"
                                        [(model)]="crewItemQuery.sortBy"
                                        display="label"
                                        value="sortBy"
                                        nbcSize="w-100"
                                        placeholder="Sort By"
                                        (nbcOnOptionSelect)="getCrewItemByQuery()">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group" *ngIf="isTieredEvent">
                          <nbc-dropdown name="tierSortBy"
                                        [source]="tieredEventSortBy"
                                        [(model)]="crewItemQuery.sortBy"
                                        display="label"
                                        value="sortBy"
                                        nbcSize="w-100"
                                        placeholder="Sort By"
                                        (nbcOnOptionSelect)="getCrewItemByQuery()">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group">
                          <label class="form-group-label"><i class="fa fa-filter"></i></label>
                        </div>
                        <div class="form-group">
                          <nbc-dropdown name="positionRequestOption"
                                        [source]="positionRequestOptions"
                                        [(model)]="crewItemQuery.positionRequest"
                                        display="description"
                                        value="value"
                                        nbcSize="w-100"
                                        placeholder="All Position Requests"
                                        (nbcOnOptionSelect)="applyCrewItemFilter()">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group" *ngIf="crewItemStatuses && crewItemStatuses.length !== 0">
                          <nbc-select name="crewItemStatuse"
                                      [source]="crewItemStatuses"
                                      [(model)]="crewItemQuery.status"
                                      display="description"
                                      value="id"
                                      placeHolderDisplay="All Statuses"
                                      placeHolderValue="-1"
                                      (change)="applyCrewItemFilter()">
                          </nbc-select>
                        </div>
                        <div class="form-group" *ngIf="departments && departments.length>0">
                          <nbc-dropdown name="department"
                                        [source]="departments"
                                        [(model)]="crewItemQuery.department"
                                        display="description"
                                        value="description"
                                        nbcSize="w-100"
                                        placeholder="All Departments"
                                        (nbcOnOptionSelect)="applyCrewItemFilter()">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group" *ngIf="employeeTypes && employeeTypes.length > 0">
                          <nbc-dropdown name="employeeType"
                                        [source]="employeeTypes"
                                        [(model)]="crewItemQuery.employeeTypeId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="All Employee Types"
                                        (nbcOnOptionSelect)="applyCrewItemFilter()">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group" *ngIf="positionTypes && positionTypes.length > 0">
                          <nbc-dropdown name="positionType"
                                        [source]="positionTypes"
                                        [(model)]="crewItemQuery.positionTypeId"
                                        display="description"
                                        value="id"
                                        nbcSize="w-100"
                                        placeholder="All Position Types"
                                        (nbcOnOptionSelect)="applyCrewItemFilter(false)">
                          </nbc-dropdown>
                        </div>
                        <div class="form-group" *ngIf="additionalLocations && additionalLocations.length > 0">
                          <nbc-dropdown name="locationFilter"
                                        [source]="additionalLocationFilter"
                                        [(model)]="crewItemQuery.locationNo"
                                        value="locationId"
                                        display="locationName"
                                        nbcSize="w-100"
                                        placeholder="All Locations"
                                        (nbcOnOptionSelect)="applyCrewItemFilter(false)">
                          </nbc-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- BEGIN GROUP UPDATE OPTIONS -->
                  <div class="row row-bulkupdate"
                       *ngIf="displayBulkUpdate"
                       authenticate-user="event.crew.update">
                    <div class="col-md-12">
                      <div class="d-flex" [ngClass]="bulkEditFlight?'form-inline form-sort-options grp-update-airport':'form-inline form-sort-options'">
                        <div class="form-group d-flex" *ngIf="!isTieredEvent">
                          <label>Group Update: </label>
                          <nbc-select *ngIf="displayBulkUpdate"
                                      [source]="groupUpdate.options"
                                      [(model)]="bulkEditCategory"
                                      display="description"
                                      value="value"
                                      (change)="clearTravelFields(bulkEditCategory)"
                                      placeHolderDisplay="Select an Action"
                                      placeHolderValue="-1">
                          </nbc-select>
                        </div>
                        <div class="form-group d-flex" *ngIf="isTieredEvent">
                          <label>Group Update: </label>
                          <nbc-select [source]="TierRateOptions"
                                      [(model)]="bulkEditCategory"
                                      display="description"
                                      value="value"
                                      (change)="clearTravelFields(bulkEditCategory);onGroupUpdateItemSelected(bulkEditCategory);"
                                      placeHolderDisplay="Select an Action"
                                      placeHolderValue="-1">
                          </nbc-select>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Employee Type'">
                          <label class="d-flex">
                            Employee Type:
                            <nbc-select [source]="employeeTypes"
                                        [(model)]="selectedEmployeeType"
                                        display="description"
                                        value="id"
                                        placeHolderDisplay="Select Employee Type"
                                        placeHolderValue="-1"
                                        (nbcValueChange)="selectedRateCard=''">
                            </nbc-select>
                          </label>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Employee Type'">
                          <label>
                            <nbc-select *ngIf="(!isTieredEvent || bulkUpdateOnlyAtlSelected) && selectedEmployeeType?.rateCards?.length && selectedEmployeeType?.description?.toLowerCase() !== 'vendor'"
                                        [source]="selectedEmployeeType.rateCards"
                                        [(model)]="selectedRateCard"
                                        display="name"
                                        value="id"
                                        [nbcDisabled]="!selectedEmployeeType.rateCards.length"
                                        placeHolderDisplay="Select Rate Card"
                                        placeHolderValue="-1">
                            </nbc-select>
                          </label>
                        </div>
                        <div class="form-group mt-2" *ngIf="bulkEditCategory?.value === 'Employee Type' && !isTieredEvent  && (!selectedEmployeeType?.rateCards || selectedEmployeeType?.description?.toLowerCase() === 'vendor')">
                          Rate Code: <span>N/A</span>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Employee Type' && isTieredEvent && selectedEmployeeType?.description?.toLowerCase() === 'vendor'">
                          <label>
                            Rate Code:
                            <span *ngIf="!selectedEmployeeType?.rateCards || selectedEmployeeType.description?.toLowerCase() === 'vendor'">N/A</span>
                          </label>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Employee Type' &&
                                                    (selectedRateCard?.id === '00') &&
                                                    selectedEmployeeType?.description?.toLowerCase() !== 'vendor'">
                          <div class="input-group d-flex">
                            <div class="input-group-text">$</div>
                            <input type="text"
                                   decimals="3"
                                   [(ngModel)]="customRateCard"
                                   maxlength="6"
                                   class="form-control"
                                   nbc-decimal />
                          </div>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Employee Type' &&
                                                    (selectedRateCard?.id === 'FLAT DAY') &&
                                                    selectedEmployeeType?.description?.toLowerCase() !== 'vendor'">
                          <div class="input-group d-flex">
                            <div class="input-group-text">$</div>
                            <input type="text"
                                   [(ngModel)]="customRateCard"
                                   maxlength="8"
                                   nbc-decimal
                                   decimals="3"
                                   class="form-control" />
                          </div>
                        </div>

                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Location'">
                          <div class="locations">
                            <label class="d-flex">
                              Crew Location:
                              <nbc-select [source]="additionalLocations"
                                          [(model)]="bulkAddLocationSelect"
                                          display="locationName"
                                          value="locationId">
                              </nbc-select>
                            </label>
                          </div>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Inbound Travel' || bulkEditCategory?.value === 'Outbound Travel'">
                          <div class="travel"
                               *ngIf="bulkEditFlight">
                            <div class="flight" style="margin-top: 30px;">
                              <button (click)="bulkEditFlight = false"></button>

                              <table style="display:inline">
                                <tr>
                                  <td>
                                    <airport-code name="flightDepart"
                                                  nbcClass="form-control"
                                                  [isBulkUpdate]="true"
                                                  [(model)]="bulkEditFlightDepature">
                                    </airport-code>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label class="overview-airport-type">Depart</label>
                                  </td>
                                </tr>
                              </table>


                              <table style="display:inline">
                                <tr>
                                  <td>
                                    <airport-code name="flightArrive"
                                                  [isBulkUpdate]="true"
                                                  nbcClass="form-control"
                                                  [(model)]="bulkEditFlightArrival">
                                    </airport-code>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label class="overview-airport-type">Arrive</label>
                                  </td>
                                </tr>
                              </table>

                            </div>
                          </div>
                          <div class="travel"
                               *ngIf="!bulkEditFlight">
                            <div class="no-flight"
                                 [ngClass]="{'has-error' : (bulkEditFlightLocal === '' || !bulkEditFlightLocal ) && updateSubmit}">
                              <button (click)="bulkEditFlight = true"></button>
                              <select [(ngModel)]="bulkEditFlightLocal" class="form-control">
                                <option value="local">local</option>
                                <option value="non-air">non-air</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Work Schedule'">
                          <div class="work-schedule dropdown"
                               dropdown
                               is-open="status.isopen">
                            <button type="button"
                                    id="button-dates"
                                    class="form-control"
                                    dropdownToggle
                                    aria-controls="dropdown-dates"
                                    [disabled]="disabled">
                              Select dates <i class="fa fa fa-sort"></i>
                            </button>
                            <ul id="dropdown-dates" *dropdownMenu class="dropdown-menu work-schedule-dropdown-menu"
                                role="menu"
                                aria-labelledby="button-dates"
                                (click)="$event.stopPropagation()" style="overflow:auto;height:400px;width:70px">
                              <li *ngFor="let item of scheduleDates">
                                <label class="checkbox inline">
                                  <input type="checkbox"
                                         [(ngModel)]="item.isChecked"
                                         [disabled]="item.disabled" />
                                  {{item.date | date:'EEE, MMM d'}}
                                </label>
                              </li>
                            </ul>
                          </div>

                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Work Schedule'">
                          <div class="work-schedule dropdown"
                               dropdown
                               is-open="status.isopen">
                            <button type="button"
                                    id="button-tasks"
                                    class="form-control"
                                    dropdownToggle
                                    aria-controls="dropdown-tasks"
                                    [disabled]="disabled">
                              Select tasks <i class="fa fa fa-sort"></i>
                            </button>
                            <ul id="dropdown-tasks" *dropdownMenu class="dropdown-menu work-schedule-dropdown-menu"
                                role="menu"
                                aria-labelledby="button-tasks"
                                (click)="$event.stopPropagation()">
                              <li *ngFor="let task of taskItems">
                                <label class="checkbox inline">
                                  <input type="checkbox"
                                         value="{{task.code}}"
                                         (change)="checkTaskCount()"
                                         [(ngModel)]="task.isChecked"
                                         [disabled]="task.disabled" />
                                  {{task.code}} - {{task.description}}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <span *ngIf="bulkEditCategory?.value === 'Travel'">
                          <div class="form-group has-error"
                               *ngIf="updateSubmit && ((bulkEditFlight && bulkEditFlightDestination === '') || (!bulkEditFlight && (bulkEditFlightLocal === '' || !bulkEditFlightLocal )))">
                            <span class="control-label">Select Travel Destination</span>
                          </div>
                        </span>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Tier Rate Card'">
                          <label class="d-flex">
                            Rate Card:

                            <nbc-select *ngIf="tieredPositions && tieredPositions.length>0"
                                        [source]="groupUpdateTierPositions"
                                        [(model)]="selectedRateCard"
                                        display="desc"
                                        value="id"
                                        (change)="tierRateCardSelect()"
                                        placeHolderDisplay="Select Rate Card"
                                        placeHolderValue="null">
                            </nbc-select>
                          </label>
                        </div>
                        <div class="form-group"
                             *ngIf="bulkEditCategory?.value === 'Tier Rate Card' && selectedRateCard?.id!=null">
                          <div class="input-group d-flex">
                            <div class="input-group-text">$</div>
                            <input type="text"
                                   nbc-decimal
                                   decimals="3"
                                   [(ngModel)]="customRateCard"
                                   maxlength="7"
                                   (click)="openTierRatecardModal()"
                                   class="form-control"
                                   readonly />
                          </div>
                        </div>
                        <div class="form-group">
                          <button class="btn btn-primary"
                                  *ngIf="bulkEditCategory?.value!='-1'"
                                  (click)="bulkUpdatePositions()">
                            Update
                          </button>
                          <button class="btn btn-default" (click)="closeBulkUpdateMenu()">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END GROUP UPDATE OPTIONS -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-content grid-content pad-0 activity-overflow">
          <p *ngIf="loaded && positionListItems?.length === 0">No results.</p>
          <div class="row bottom-padding-15 row-pagination top"
               *ngIf="loaded && positionListItems?.length>0">
            <div class="view-options d-flex p-0">
              <div class="col-md-5">
                <div class="form-inline form-sort-options">
                  <div class="form-group">
                    <nbc-page-size [source]='pageSize' [model]="crewItemQuery.pageSize" (nbcValueChange)="searchPage($event)"></nbc-page-size>
                  </div>
                </div>
              </div>

              <div class="col-lg-2 col-md-2 text-center text-center-lg text-center-md">
                Minimum Wage : {{eventData.minimumWage == 0 ?  "Not Available": "$"+eventData.minimumWage}}
              </div>

              <div class="col-lg-5 col-md-5 text-center">
                <div *ngIf="positionListItems && positionListItems.length>0">
                  <div class="mb-3 pagination-container d-flex pe-3">
                    <label class="pagination-results pagination-result-pos ms-auto">
                      <pagination-label pageSize="{{crewItemQuery.pageSize}}"
                                        currentPage="{{crewItemQuery.currentPage}}"
                                        totalRecords="{{totalRecords}}">
                      </pagination-label>
                    </label>
                    <nbc-pagination totalItems="{{totalRecords}}"
                                    itemsPerPage="{{crewItemQuery.pageSize}}"
                                    [(currentPage)]="crewItemQuery.currentPage"
                                    (pageChanged)="getCrewItemByQuery()">
                    </nbc-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <crew-grid></crew-grid>
          <div class="row bottom-padding-15 row-pagination bottom" *ngIf="loaded && positionListItems?.length>0">
            <div class="view-options d-flex">
              <div class="col-lg-7 col-md-7 text-left form-inline">
              </div>
              <div class="col-lg-5 col-md-5 text-center">
                <div *ngIf="positionListItems && positionListItems.length>0">
                  <div class="mb-3 pagination-container d-flex">
                    <label class="pagination-results pagination-result-pos ms-auto">
                      <pagination-label pageSize="{{crewItemQuery.pageSize}}"
                                        currentPage="{{crewItemQuery.currentPage}}"
                                        totalRecords="{{totalRecords}}">
                      </pagination-label>
                    </label>
                    <nbc-pagination totalItems="{{totalRecords}}"
                                    itemsPerPage="{{crewItemQuery.pageSize}}"
                                    [(currentPage)]="crewItemQuery.currentPage"
                                    (pageChanged)="getCrewItemByQuery()">
                    </nbc-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="searchFlyoutPage">
    <app-crew-contact-search-flyout [isFullScreenMode]="isFullScreenMode"></app-crew-contact-search-flyout>
  </div>
</div>
<app-nbc-file-download></app-nbc-file-download>

<svg id="svg-filter">
  <filter id="svg-blur">
    <feGaussianBlur in="SourceGraphic" stdDeviation="4"></feGaussianBlur>
  </filter>
</svg>
