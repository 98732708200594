<div class="modal-header">
  <h4 class="modal-title">Schedule Conflict</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div *ngIf="itemConflict.sameEventConflict != 1">
    {{itemConflict.schedulingConflicts[0].resourceName}} is already scheduled for the following:
    <br />
    <br />
    <div *ngFor="let item of itemConflict.schedulingConflicts">
      <div *ngIf="!!item.event">
        <p>
          <strong *ngIf="!item.olyEventId">{{item.event.eventName}}</strong>
          <strong *ngIf="item.olyEventId">{{item.olyEventName}}</strong>
          <br>{{item.conflictStart | date: "mediumDate"}}<span *ngIf="(item.conflictStart | date: 'mediumDate') == (item.conflictEnd  | date: 'mediumDate')"> - {{item.conflictEnd | date: "mediumDate"}}</span>
          <br><span *ngIf="item.event.venue">{{item.event.venue.name}}</span>
          <span *ngIf="item.olyEventId">{{item.event.eventName}}</span>
          <br>
          <strong>
            As {{item.eventPositionName}}
          </strong>
          <br>
        </p>
      </div>
      <div *ngIf="(!item.event)">
        <p class="text-capitalize">
          <strong>{{item.conflictType}}</strong>
          <br>{{item.conflictStart | date: "mediumDate"}}<span *ngIf="(item.conflictStart | date: 'mediumDate') == (item.conflictEnd  | date: 'mediumDate')"> - {{item.conflictEnd | date: "mediumDate"}}</span>
        </p>
      </div>
    </div>
    <span *ngIf="checkingConflictDetail && !hideAssignBtn">
      <br><br>
      {{ !isWorkScheduleConflict ? "Proceed with adding this activity?" : "Proceed with assigning this member anyway?" }}
    </span>
  </div>
  <div *ngIf="itemConflict.sameEventConflict === 1">
    Overlapping activity cannot exist for <strong>{{itemConflict.schedulingConflicts[0].resourceName}}</strong> for Position Numbers ({{itemConflict.sameEventConflictPositionNumbers}}) on {{itemConflict.sameEventConflictDates}}.
</div>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-primary" (click)="assign()" *ngIf="!checkingConflictDetail && !hideAssignBtn && itemConflict.sameEventConflict != 1">{{ !isWorkScheduleConflict ? "Yes" : "Yes, Assign" }}</button>
    <button class="btn btn-default" (click)="cancel()">{{cancelText}}</button>
  </div>
