<div class="results-container" *ngIf="contacts?.items?.length === 0">
  <div class="empty-results">
    <h5>There are no records for whom you are searching.</h5>
  </div>
</div>
<ul class="results-container" *ngIf="contacts?.items?.length > 0">
  <li *ngFor="let contact of contacts.items" class="clearfix">
    <div class="container-fluid d-flex">
      <div class="col-lg-9 col-med-4 contact-summary">
        <img src="/assets/image/contact/{{contact.imageId}}" />
        <div>
          <h5 authenticate-user="contact.retrieve,Name.Retrieve">
            <a class="nbc-router-link btn-link" routerLink="Detail/{{contact.id}}" target="_blank">{{contact.firstName}} {{contact.middleName}} {{contact.lastName}}</a>
          </h5>

          <ul class="positions list-inline d-flex" authenticate-user="contact.hrmaster.retrieve,ContactPositionSkills.Retrieve">
            <li *ngFor="let position of contact.positions | unique:'description'">{{position.description}}</li>
          </ul>
          <div>
            <span [hidden]="contact.country == null" authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">
              <span class="flag-icon flag-icon-{{ contact.country | lowercase }}" style="margin-right:5px;"></span>
              <span class="location country-{{contact.country|lowercase}}">{{contact.city}}, {{contact.province}}</span>
              <span class="pipe">|</span>
            </span>
            <span authenticate-user="contact.workhistory.retrieve,ContactWorkHistory.Retrieve">{{contact.pastEvents}} Past Events</span>
            <span authenticate-user="contact.retrieve,Union.Retrieve">
              <span class="pipe">|</span>
              {{contact.union}}
            </span>
            <span authenticate-user="contact.retrieve,EmployeeType.Retrieve">
              <span class="pipe">|</span>
              {{contact.employeeType?.description}}
            </span>
            <span class="pull-right profile-comment" *ngIf="contact.flaggedByHR">
              <strong>This contact cannot be crewed to an event. Please contact HR if further information is required.</strong>
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-med-4 contact-info">

        <p authenticate-user="contact.retrieve,ContactInformation.Email.Retrieve">{{contact.email}}</p>
        <span authenticate-user="contact.retrieve,ContactInformation.Retrieve">
          <input [(ngModel)]="contact.phone1.phoneNumber"
                 disabled
                 *ngIf="contact?.phone1?.phoneNumber!=''"
                 class="phone-number" />
        </span>

      </div>
    </div>
  </li>
</ul>
