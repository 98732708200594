import { Component, OnDestroy, OnInit } from "@angular/core";
import { AddressService } from "src/app/Shared/Services/address.service";
import { DateService } from "src/app/Shared/helper/date.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import * as _ from "lodash";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { ContactSharedDataService } from "../contact-shared.service";
import { Subject, Observable, Subscriber } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { takeUntil, switchMap } from "rxjs/operators";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SubSink } from "subsink";
import { ModalContactSapComponent } from "../../component/sap/modal-contact-sap-result";
import { ModalContactIdmComponent } from "../../component/idm/modal-contact-idm-result";
import { ContactDetailsComponent } from "../contact-details.component";
import * as moment from "moment";
import { ModalContactTypeChangeComponent } from "../../component/contactType/modal-contact-type-change";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
//import { error } from "@angular/compiler/src/util";
import { ModalContactCommentComponent } from "../../component/contactComments/modal-contact-comments";
import { NgForm } from "@angular/forms";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { ModalEmergencyContactComponent } from "../../component/emergencyContacts/modal-emergency-contact.component";

@Component({
    selector: "contact-profileInfo",
    templateUrl: "./contact-profile.component.html",
})
export class ContactProfileComponent implements OnInit, OnDestroy {
    constructor(
        private _addressService: AddressService,
        private _dateService: DateService,
        private _employeeTypeService: EmployeeTypeService,
        private _contactService: ContactService,
        private _contactSharedData: ContactSharedDataService,
        private _globalService: GlobalService,
        private _modalService: BsModalService,
        private _remotesQueryService: RemotesQueryService,
        private log: NbcLoggerService
    ) {
        this.profileInfoUpdate = {};
    }

    bsModalRef?: BsModalRef;
    subs = new SubSink();
    submitted = false;
    countries: any | undefined;
    contact: any | undefined;
    contactCopy: any | undefined;
    emailRegex = RemotesConstants.regex.email;
    ssoRegex = RemotesConstants.regex.sso;
    lastFourRegex = RemotesConstants.regex.lastFour;

    componentDestroyed$: Subject<boolean> = new Subject();
    airportSearchTerm = "";
    airportCodeObservable: Observable<any[]> | undefined;

    states: any[] | undefined;
    months!: any[];
    hrEmployeeTypes: any | undefined;
    hrCompleted = false;
    hasContactInfoChanges = "false";
    disableSave = false;
    isPhoneRequired = true;

    showDobRestrictedField = true;
    showSsnRestrictedField = true;
    ssnRequired = true;
    ssoRequired = true;
    dobMonthRequired = false;
    dobDayRequired = false;

    isPrimaryPhoneValid = false;
    isSecondaryPhoneValid = false;

    hrStatus = {
        positionRequests: false,
        sapPositions: false,
        hrPaperwork: false,
        systemsEntries: false,
    };

    phoneTypes = [
        {
            id: 4,
            description: "Home",
        },
        {
            id: 3,
            description: "Mobile",
        },
        {
            id: 1,
            description: "Work",
        },
    ];

    nonVendorTemplate =
        "Will be paid through timekeeper, has or will have an active SSO and Perner";
    vendorTemplate =
        "Is not paid directly or is a 3rd party, Independent Contractor, International Freelancer or Loan Out Corp";

    HRStatus: any | undefined;
    rateCardList: any[] | undefined;
    editModeHRVerifiedSection = false;
    editMode = false;

    isNotHRSU = false;
    isSuperUser = true;
    profileInfoUpdate: any;
    selectedCountry: any;

    getCountries() {
        this.log.trace("ContactProfile -> get countries:fetching data");
        this.subs.sink = this._addressService
            .getCountries()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.countries = data.reference;
                    this.selectedCountry = _.filter(this.countries, {
                        isoCode: this.contact.country,
                    })[0];
                    setTimeout(
                        () => this.setStates(this.contact.country, false),
                        100
                    );
                    this.log.trace("ContactProfile -> countries received");
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    setStates(event, change) {
        if (event && change) {
            this.selectedCountry = event;
            this.contact.country = event?.isoCode;
        }
        if (this.contact && this.contact.country === "US") {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].isoCode === this.contact.country) {
                    this.states = this.countries[i].states;
                }
            }
        } else {
            this.states = [];
        }
        if (change) {
            this.contact.province = "";
        }
    }

    getMonths() {
        this.months = this._dateService.getMonths();
    }

    getHrEmployeeTypes() {
        this.log.trace("ContactProfile -> get HR employee Type:fetching data");
        this._employeeTypeService
            .getHrEmployeeTypes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.hrEmployeeTypes = data.reference;
                    this.log.trace(
                        "ContactProfile -> HR employee Type received"
                    );
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    checkHRStatus() {
        if (_.isUndefined(this.contact)) {
            return -1;
        }
        if (
            ((!this.contact.perNer ||
                (this.contact.perNer &&
                    this.contact.employmentStatus !== null &&
                    this.contact.employmentStatus.toLowerCase() !== "active" &&
                    this.contact.employmentStatus.toLowerCase() !==
                        "inactive")) &&
                !this.contact.isOnBoarding &&
                this.contact.isUsCitizen &&
                this.contact.isNonVendor &&
                !this.hrCompleted) ||
            (!this.contact.isOnBoarding &&
                !this.hrCompleted &&
                !this.contact.perNer &&
                this.contact.isUsCitizen &&
                this.contact.isNonVendor)
        ) {
            //no perner and onBording in progress and HR not completed
            return 1;
        } else if (
            (!this.contact.isNonVendor ||
                (this.contact.isNonVendor && !this.contact.isUsCitizen)) &&
            (!this.contact.perNer ||
                (this.contact.perNer &&
                    this.contact.employmentStatus !== null &&
                    this.contact.employmentStatus.toLowerCase() !== "active" &&
                    this.contact.employmentStatus.toLowerCase() !== "inactive"))
        ) {
            //for a vendor contact
            return 4;
        }

        if (
            this.contact.isNonVendor &&
            !this.hrCompleted &&
            ((this.contact.employmentStatus !== null &&
                this.contact.employmentStatus.toLowerCase() !== "active" &&
                this.contact.employmentStatus.toLowerCase() !== "inactive" &&
                !!this.contact.perNer) ||
                !this.contact.perNer)
        ) {
            //For non-Vendor with perner and whose HR onboarding process not completed
            return 2;
        } else if (
            (this.contact.perNer && this.contact.isNonVendor) ||
            (this.hrCompleted && this.contact.isNonVendor)
        ) {
            // Contact Has a Per Ner, is a US Citizen, and is a non-vendor
            // OR
            // Contact has all of the HR checkboxes checked, is US Citizen, and is a non-vendor
            return 3;
        } else if (
            this.contact.perNer &&
            this.contact.employmentStatus !== null &&
            (this.contact.employmentStatus.toLowerCase() === "active" ||
                this.contact.employmentStatus.toLowerCase() === "inactive") &&
            this.contact.isNonVendor &&
            !this.contact.isUsCitizen
        ) {
            // Contact Has a Per Ner, is not a US Citizen, and is a non-vendor
            return 3;
        }

        return -1;
    }

    checkHRStatusCompleted() {
        if (
            this.checkPositionRequests() &&
            this.checkSAPPositions() &&
            this.checkHRPaperwork() &&
            this.checkSystemEntries()
        ) {
            this.hrCompleted = true;
        }
    }

    checkPositionRequests() {
        let item,
            completed = true;
        if (this.contact) {
            for (const request in this.contact.positionRequest) {
                item = this.contact.positionRequest[request].value;
                if (item === false) {
                    completed = false;
                    break;
                }
            }

            if (
                completed === true &&
                this.contact &&
                this.contact.employeeTypeHr &&
                this.contact.positionRequest &&
                this.contact.employeeTypeHr !== "Select One"
            ) {
                this.hrStatus.positionRequests = true;
                return true;
            }
        }
        return false;
    }
    checkSAPPositions() {
        let item,
            completed = true;
        if (this.contact) {
            for (const request in this.contact.sapPositions) {
                item = this.contact.sapPositions[request].value;
                if (item === false) {
                    completed = false;
                    break;
                }
            }
            if (
                completed === true &&
                this.contact &&
                this.contact.sapPositions
            ) {
                this.hrStatus.sapPositions = true;
                return true;
            }
        }
        return false;
    }
    checkHRPaperwork() {
        let item,
            completed = true;
        if (this.contact) {
            for (const request in this.contact.hrPaperwork) {
                item = this.contact.hrPaperwork[request].value;
                if (item === false) {
                    completed = false;
                    break;
                }
            }
            if (
                completed === true &&
                this.contact &&
                this.contact.hrPaperwork
            ) {
                this.hrStatus.hrPaperwork = true;
                return true;
            }
        }
        return false;
    }
    checkSystemEntries() {
        let item,
            completed = true;
        if (
            this.contact &&
            this.contact.systemEntries &&
            this.contact.systemEntries.hiredIntoSap &&
            !this.contact.systemEntries.hiredIntoSap.value
        ) {
            completed = false;
        }

        if (completed === true && this.contact && this.contact.systemEntries) {
            this.hrStatus.systemsEntries = true;
            return true;
        }
        return false;
    }

    authendicateSSO() {
        this.isNotHRSU = true;
        const user = window.preload.user;
        this.isSuperUser = user ? user.isSuperUser : false;
        user.userRoles.forEach((role) => {
            if (
                role.description === "Olympic_HR_SU" ||
                role.description === "HR_SU"
            ) {
                this.isNotHRSU = false;
            }
        });
    }

    getDisplayCountry(code) {
        if (typeof this.countries === "undefined") {
            return "";
        }
        for (let i = 0; i < this.countries.length; i++) {
            if (this.countries[i].isoCode === code) {
                return this.countries[i].name;
            }
        }
    }

    getMonthName(month) {
        if (this.months) {
            for (let i = 0; i < this.months.length; i++) {
                if (this.months[i].id === month) {
                    return this.months[i].name;
                }
            }
        }
    }

    getDisplayPhonetype(type) {
        let phoneType = "";
        for (let i = 0; i < this.phoneTypes.length; i++) {
            if (this.phoneTypes[i].id === type) {
                phoneType = this.phoneTypes[i].description;
            }
        }
        return phoneType;
    }

    setEditMode(callFromComponent?: boolean) {
        this.log.trace("setEditMode");
        this.editMode = true;
        this.contactCopy = _.cloneDeep(this.contact);
        this.getRateCards();
        this.checkVendorValidation();
        if (!callFromComponent)
            this._contactSharedData.isContactEditModeEnabled(true);
    }

    setReadMode() {
        this.log.trace("setReadMode");
        this.contact = this.contactCopy;
        if (!this.editModeHRVerifiedSection) {
            this.contactCopy = {};
        }
        this.editMode = false;
        this._contactSharedData.isContactEditModeEnabled(false);
    }

    resetSubSection() {
        this.HRStatus = this.checkHRStatus();
    }

    setDialCode(seqnumber) {
        if (this.contact) {
            switch (seqnumber) {
                case 1:
                    this.contact.phone1.dialCode = this.getCountryDialCode(
                        this.contact.phone1.countryCode
                    );
                    break;
                case 2:
                    this.contact.phone2.dialCode = this.getCountryDialCode(
                        this.contact.phone2.countryCode
                    );
                    break;
                case 3:
                    this.contact.phone3.dialCode = this.getCountryDialCode(
                        this.contact.phone3.countryCode
                    );
                    break;
            }
        }
    }

    getCountryDialCode(IsoCode) {
        let dialcode;
        if (this.countries) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].isoCode === IsoCode) {
                    dialcode = this.countries[i].dialCode;
                }
            }
        }
        return dialcode;
    }

    getRateCards() {
        if (
            this.contact &&
            this.contact.employeeType &&
            this.contact.employeeType !== "" &&
            this.contact.employeeType.description.toLowerCase() !== "vendor"
        ) {
            this.rateCardList = this.contact.employeeType.rateCards;
        } else if (
            this.contact &&
            this.contact.employeeType &&
            (this.contact.employeeType === "" ||
                this.contact.employeeType.description.toLowerCase() ===
                    "vendor")
        ) {
            this.contact.rateCode.id = undefined;
            this.rateCardList = [];
        }
    }

    setEditModeHRVerifiedSection() {
        this.log.trace("setEditModeHRVerifiedSection");
        this.editModeHRVerifiedSection = true;
        this.contactCopy = _.cloneDeep(this.contact);
        this._contactSharedData.isHrVerficationEditModeEnabled(true);
    }

    verifyContact() {
        this.log.trace("Contact Verification");
        if (this.contact.sso == "0") {
            this.contact.sso = null;
        }
        this._contactService
            .getMatchingRecordsFromSAPIDM(this.contact)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.openVerificationModal(data.reference);
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(err.error);
                },
                () => {}
            );

        //log.trace("Contact Verification");
        //usSpinnerService.spin("updateSpinner");
        //contactService.getMatchingRecordsFromSAPIDM(this.contact).success(function (result) {
        //  log.trace("getMatchingRecordsFromSAPIDM got SAP/IDM resultset");
        //  this.hcmResultsSet = result.reference;
        //  this.hcmContact = this.contact;
        //  if (this.hcmResultsSet.contacts.length > 0) {
        //    if (this.hcmResultsSet.dataSource !== "IDM") {
        //      $modal.open({
        //        templateUrl: "/scripts/app/areas/contacts/views/contacts/modalContactSAPResult.html",
        //        controller: "modalContactSAPController",
        //        backdrop: "static",
        //        keyboard: false,
        //        resolve: {
        //          items: function () { return this; },
        //        }
        //      });
        //    }
        //    else {
        //      $modal.open({
        //        templateUrl: "/scripts/app/areas/contacts/views/contacts/modalContactIDMResult.html",
        //        controller: "modalContactIDMController",
        //        backdrop: "static",
        //        keyboard: false,
        //        resolve: {
        //          items: function () { return this; },
        //        }
        //      });
        //    }

        //  }
        //  else {
        //    log.trace("No results found in SAP/IDM" - +this.contact.firstName + " " + this.contact.lastName);
        //    this.$emit("showAlert", "danger", "No results found.  This contact will not be updated.");
        //  }
        //}).error(function (data) {
        //  log.error("Verify contact got an error: " + JSON.stringify(data));
        //  this.$emit("showAlert", "danger", "Error Occured..");

        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("updateSpinner");
        //});
    }

    openVerificationModal(hcmResultsSet: any) {
        const initialState: any = {
            self: this,
            hcmResultsSet: hcmResultsSet,
            hcmContact: this.contact,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        if (hcmResultsSet.contacts.length > 0) {
            if (hcmResultsSet.dataSource !== "IDM") {
                this.bsModalRef = this._modalService.show(
                    ModalContactSapComponent,
                    modalOptions
                );
                this.bsModalRef.content.onSync.subscribe((result) => {
                    this.syncContact(result);
                });
                this.bsModalRef.content.closeBtnName = "Close";
            } else {
                this.bsModalRef = this._modalService.show(
                    ModalContactIdmComponent,
                    modalOptions
                );
                this.bsModalRef.content.onSync.subscribe((result) => {
                    this.syncContact(result);
                });
                this.bsModalRef.content.closeBtnName = "Close";
            }
        } else {
            this.log.trace(
                "No results found in SAP/IDM " +
                    this.contact.firstName +
                    " " +
                    this.contact.lastName
            );
            this.log.showError(
                "No results found.  This contact will not be updated."
            );
        }
    }

    emergencyContact() {
        this.log.trace("Contact Emergency contact");
        if (this.contact.sso == "0") {
            this.contact.sso = null;
        }
        this._contactService
            .getEmergencyContact(this.contact)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.openEmergencyContactModal(data.reference);
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(err.error);
                },
                () => {}
            );
    }

    openEmergencyContactModal(emergencyContacts: any) {
        const initialState: any = {
            self: this,
            emergencyContacts: emergencyContacts,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        if (emergencyContacts.length > 0) {
            this.bsModalRef = this._modalService.show(
                ModalEmergencyContactComponent,
                modalOptions
            );
        } else {
            this.log.trace(
                "No Emergency contact found for this contact " +
                    this.contact.firstName +
                    " " +
                    this.contact.lastName
            );
            this.log.showError("No Emergency contact found for this contact.");
        }
    }

    syncContact(contactToSync) {
        //this.contactEmailAddressMatchSearch();
        this._contactService
            .syncContact(contactToSync)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this._contactSharedData.setFnCall({
                        methodName: "loadContactDetails",
                        mode: "edit",
                    });
                    this.log.showSuccess("Contact synced successfully.");
                },
                error: (e) => {
                    this.log.error("Contact sync got an error: " + e.message);
                    this.log.showError(e.error);
                },
            });

        //contactService.verifyContact(contactToSync.targetScope.contactBeforeSync).success(function (result) {
        //  if (result.reference) {
        //    this.loadContactDetails(false);
        //    log.trace("Contact synced successfully -" + contactToSync.targetScope.contactBeforeSync.firstName + " " + contactToSync.targetScope.contactBeforeSync.lastName);
        //    this.$emit("showAlert", "success", "Contact synced successfully.");

        //    if (typeof openNewWindow !== "undefined" && openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + self.contact.id + "/?mode=newSync#/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + self.contact.id + "/?mode=newSync#/profile/";
        //    }
        //  }
        //  else {
        //    if (typeof openNewWindow !== "undefined" && openNewWindow) {
        //      $window.open("/Contacts/Contacts/contactdetail/" + self.contact.id + "/?mode=newNoSync#" + true + "/profile/");
        //    } else {
        //      $window.location.href = "/Contacts/Contacts/contactdetail/" + self.contact.id + "/?mode=newNoSync#" + true + "/profile/";
        //    }
        //  }

        //}).error(function (data) {
        //  log.error("Contact sync got an error: " + JSON.stringify(data));
        //  this.$emit("showAlert", "danger", data.message);
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("updateSpinner");
        //});
    }

    disableSync() {
        if (this.profileInfoUpdate.dirty) {
            return true;
        } else {
            return false;
        }
    }

    contactEmailAddressMatchSearch() {
        const contactQuery = this._remotesQueryService.getContactQuery();
        contactQuery.emailaddress = this.contact.email;

        this._contactService
            .contactEmailAddressMatchSearch(contactQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    if (result.items && result.items.length > 0) {
                        const contactExist = _.find(result.items, (item) => {
                            return item.id !== this.contact.id;
                        });
                        if (contactExist) {
                            this.log.showError(
                                "A contact with this email address already exists.  You cannot create a contact with a duplicate email.  Please cancel this change."
                            );
                        } else {
                            this.saveContact(true);
                            this.profileInfoUpdate.dirty = false;
                        }
                    } else {
                        this.saveContact(true);
                        this.profileInfoUpdate.dirty = false;
                    }
                },
                error: (e) => {
                    this.log.error(e);
                    this.log.showError(
                        "Unable to perform the search at this time. Please try again later."
                    );
                },
            });
    }

    getDialCodeFromCountry(code) {
        if (typeof this.countries === "undefined") {
            return "";
        }

        for (let i = 0; i < this.countries.length; i++) {
            if (this.countries[i].isoCode === code) {
                return this.countries[i].dialCode;
            }
        }
    }

    airportCodeTypeahead() {
        if (
            _.isEmpty(this.airportSearchTerm) && this.contact?.airportCode
                ? this.contact?.airportCode
                : ""
        )
            this.airportSearchTerm = this.contact.airportCode;

        this.airportCodeObservable = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.airportSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._globalService.getAirportCodes(token);
            })
        );
    }

    onAirportSelected(airport) {
        this.log.trace(airport);
        this.contact.airportCode = airport?.item.code;
    }

    confrimVendorChangeModal() {
        this.log.trace("Opening modal");
        const initialState: any = {
            self: this,
            contact: this.contact,
            HRStatus: this.HRStatus,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };
        this.bsModalRef = this._modalService.show(
            ModalContactTypeChangeComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.contact = result;
            this.checkVendorValidation();
        });
    }

    getDaysFromMonth(month) {
        for (let i = 0; i < this.months.length; i++) {
            if (this.months[i].id === month) {
                return this.months[i].days;
            }
        }
    }

    clearDobDay() {
        const month = this.contact.dobMonth;
        const day = this.contact.dobDay;

        if (
            !this.contact.dobMonth ||
            (month === 2 && day > 30) ||
            (month === 4 && day > 31) ||
            (month === 6 && day > 31) ||
            (month === 9 && day > 31) ||
            (month === 11 && day > 31)
        ) {
            this.contact.dobDay = "";
        }
    }

    validateDob() {
        if (!this.contact.dobMonth) {
            this.contact.dobMonth = "";
        }
        if (!this.contact.dobDay) {
            this.contact.dobDay = "";
        }
        this.dobMonthRequired =
            (this.contact.isNonVendor && !this.contact.sso) ||
            (this.contact.dobDay !== "" && this.contact.dobMonth === "");
        this.dobDayRequired =
            (this.contact.isNonVendor && !this.contact.sso) ||
            (this.contact.dobMonth !== "" && this.contact.dobDay === "");
    }

    change() {
        this._contactSharedData.setContact(this.contact);
    }

    checkVendorValidation() {
        setTimeout(() => {
            this.ssoRequired =
                this.contact.isNonVendor && !this.contact.ssnLastFour;
            this.ssnRequired = this.contact.isNonVendor && !this.contact.sso;
            this.dobMonthRequired =
                (this.contact.isNonVendor && !this.contact.sso) ||
                (this.contact.dobDay !== "" && this.contact.dobMonth === "");
            this.dobDayRequired =
                (this.contact.isNonVendor && !this.contact.sso) ||
                (this.contact.dobMonth !== "" && this.contact.dobDay === "");
        }, 100);
    }

    flagContact(flag) {
        if (flag) {
            this.contact.flaggedByHR = true;
            // this.hrMessage = "";
            this._contactSharedData.setContact(this.contact);
            this._contactSharedData.isContactUpdated(true);
        } else {
            this.contact.flaggedByHR = false;
            // this.hrMessage = "";
            this._contactSharedData.setContact(this.contact);
            this._contactSharedData.isContactUpdated(true);
        }
    }

    commentContact(contact) {
        const initialState: any = {
            self: this,
            contact: this.contact,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalContactCommentComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.contact = result.reference;
            this.contactCopy = this.contact;
            this.loadDecryptedValuesByDefault();
        });
    }

    saveProfileInfo(form, isValid) {
        this.submitted = true;
        if (isValid && this.isPrimaryPhoneValid && this.isSecondaryPhoneValid) {
            this.contact.email = this.contact.email.toLowerCase();
            this.contactEmailAddressMatchSearch();

            // this.contactInfo.dirty = false;
            // this.disableCancel = false;
        }
    }

    saveContact(setView) {
        this.log.trace(this.contact);
        this.contact.country =
            this.contact.country === 0 ? "" : this.contact.country;
        this._contactService
            .upsertContact(this.contact)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.contact = result.reference;

                    this.setDialCode(1);
                    this.setDialCode(2);
                    this.setDialCode(3);
                    this.contactCopy = _.cloneDeep(result.reference);
                    if (_.isUndefined(setView) || setView === true) {
                        this.setReadMode();
                        this.editModeHRVerifiedSection = false;
                    }
                    this.loadDecryptedValuesByDefault();
                    this.setDefaultContactCustomColor();
                    this._contactSharedData.setContact(this.contact);
                    this._contactSharedData.isContactUpdated(true);
                },
                (err) => {
                    this.log.error(err);
                    if (err.status == 400) {
                        this.log.showError(err.error);
                    } else {
                        this.log.showError(
                            "Unable to perform the save at this time. Please try again later."
                        );
                    }
                },
                () => {}
            );
    }

    loadDecryptedValuesByDefault() {
        this.log.trace("Entered loadDecryptedValuesByDefault ");

        if (_.has(this.contact, "success")) {
            setTimeout(this.loadDecryptedValuesByDefault, 50);
        } else {
            this.log.trace(this.contact);

            //TODO:AngularUpgrade - User Permission to decrypt data is pending
            //var utils = new SCORE.PermissionUtilities(),
            //  user = window.preload.user,
            //  allFeaturesClone = _.cloneDeep(user.allFeatures);
            const doBtokens = ["contact.hrmaster.retrieve", "DOB.Retrieve"];

            //if (utils.hasAccess(user, allFeaturesClone, doBtokens, null, null)) {
            if (true)
                setTimeout(() => {
                    this.getDecryptedValue("firstFour", this.contact);
                }, 50);
        }

        const ssnToken = ["contact.hrmaster.retrieve", "SSN.Retrieve"];
        //if (utils.hasAccess(user, allFeaturesClone, ssnToken, null, null)) {
        if (true) {
            setTimeout(() => {
                this.getDecryptedValue("ssnLastFour", this.contact);
            }, 50);
        }
    }

    getDecryptedValue(field, contact) {
        this._contactService
            .decrypt(field, contact)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    if (result.reference) {
                        const data = result.reference;
                        if (field) {
                            switch (field) {
                                case "ssnLastFour":
                                    this.contact.ssnLastFour = data.ssnLastFour;
                                    if (!this.contactCopy)
                                        this.contactCopy = _.cloneDeep(
                                            this.contact
                                        );
                                    this.contactCopy.ssnLastFour =
                                        data.ssnLastFour;
                                    this.showSsnRestrictedField = false;
                                    break;
                                case "firstFour":
                                    this.contact.dobMonth = data.dobMonth;
                                    this.contact.dobDay = data.dobDay;
                                    if (!this.contactCopy)
                                        this.contactCopy = _.cloneDeep(
                                            this.contact
                                        );
                                    this.contactCopy.dobMonth = data.dobMonth;
                                    this.contactCopy.dobDay = data.dobDay;
                                    this.showDobRestrictedField = false;
                                    break;
                            }
                        }
                    }
                },
                (err) => {
                  this.log.showError(err.error);
                    this.log.error(
                        "Contact Encryption got an error: " +
                            JSON.stringify(err)
                    );
                }
            );
    }
    setDefaultContactCustomColor() {
        if (this.contact && this.contact.contactVerified) {
            this.contact.contactVerified.tooltip = this.contact.contactVerified
                .fieldBool
                ? "Contact Information Accurate"
                : "Contact Information May Not Be Accurate";
            if (this.contact.contactVerified.updatedBy != "") {
                this.contact.contactVerified.modifiedDate = moment
                    .utc(this.contact.contactVerified.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.contactVerified.tooltip =
                    this.contact.contactVerified.tooltip +
                    (this.contact.contactVerified.updatedBy
                        ? "\nVerified By: " +
                          this.contact.contactVerified.updatedBy +
                          " Verified On: " +
                          this.contact.contactVerified.modifiedDate
                        : "");
            }
            this.contact.contactVerified.color = this.contact.contactVerified
                .fieldBool
                ? "Green"
                : "Red";
        }
        if (this.contact && this.contact.covidCommunicationSent) {
            this.contact.covidCommunicationSent.tooltip = this.contact
                .covidCommunicationSent.fieldBool
                ? "Notification Email Sent"
                : "Notification Email Not Sent";
            if (this.contact.covidCommunicationSent.updatedBy != "") {
                this.contact.covidCommunicationSent.modifiedDate = moment
                    .utc(this.contact.covidCommunicationSent.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.covidCommunicationSent.tooltip =
                    this.contact.covidCommunicationSent.tooltip +
                    (this.contact.covidCommunicationSent.updatedBy
                        ? "\nSent By: " +
                          this.contact.covidCommunicationSent.updatedBy +
                          " Sent On: " +
                          this.contact.covidCommunicationSent.modifiedDate
                        : "");
            }
            this.contact.covidCommunicationSent.color = this.contact
                .covidCommunicationSent.fieldBool
                ? "Green"
                : "Red";
        }
        if (this.contact && this.contact.covidReplyRecd) {
            this.contact.covidReplyRecd.tooltip = this.contact.covidReplyRecd
                .fieldBool
                ? "Notification Email Acknowledged"
                : "Notification Email Not Acknowledged";
            if (this.contact.covidReplyRecd.updatedBy != "") {
                this.contact.covidReplyRecd.modifiedDate = moment
                    .utc(this.contact.covidReplyRecd.updatedDate)
                    .format("MM/DD/YYYY");
                this.contact.covidReplyRecd.tooltip =
                    this.contact.covidReplyRecd.tooltip +
                    (this.contact.covidReplyRecd.updatedBy
                        ? "\nAcknowledged By: " +
                          this.contact.covidReplyRecd.updatedBy +
                          " Acknowledged On: " +
                          this.contact.covidReplyRecd.modifiedDate
                        : "");
            }
            this.contact.covidReplyRecd.color = this.contact.covidReplyRecd
                .fieldBool
                ? "Green"
                : "Red";
        }
    }

    saveHRVerifiedSection(contactInfo: NgForm) {
        if (contactInfo.valid) {
            this.saveContact(true);
            this.disableSync();
            this.profileInfoUpdate.dirty = false;
            this.contact.dirty = false;
        }
    }

    setReadModeHRVerifiedSection() {
        this.log.trace("setReadModeHRVerifiedSection");
        this.contact = this.contactCopy;
        if (!this.editMode) {
            this.contactCopy = {};
        }
        this.editModeHRVerifiedSection = false;
    }

    ngOnInit() {
        this._contactSharedData
            .getContact()
            .subscribe((contact) => (this.contact = contact));

        this._contactSharedData.contactEditEnabled.subscribe((message) => {
            if (message) {
                // this.log.trace("**** Message Received: " + message);
                this.setEditMode(true);
                // this.setEditModeHRVerifiedSection();
            }
        });

        this.getCountries();

        this.getMonths();
        this.getHrEmployeeTypes();
        this.checkHRStatusCompleted();
        this.hasContactInfoChanges = "false";
        this.HRStatus = this.checkHRStatus();
        this.authendicateSSO();

        this.airportCodeTypeahead();

        this.loadDecryptedValuesByDefault();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
