import { ChangeDetectorRef } from "@angular/core";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { NbcBaseComponent } from "../../../Core/component/Base.Component";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-dropdown",
    templateUrl: "./nbc-dropdown.component.html",
    host: {
        "(document:click)": "onClick($event)",
    },
})
export class NbcDropDownComponent
    extends NbcBaseComponent
    implements OnInit, OnChanges
{
    constructor(
        private _elref: ElementRef,
        private log: NbcLoggerService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    @Input() source: any[] | undefined;
    @Input() model: any | undefined;
    @Input() display: any | undefined;
    @Input() value: any | undefined;
    //@Input() nbcOnOptionSelect: any | undefined;
    @Input() placeholder: any | undefined;
    @Input() nbcClasses: any | undefined;
    @Input() nbcAddBlank: any | undefined;
    @Input() nbcSize: any | undefined;
    @Input() nbcDisabled: any | undefined;
    @Input() nbcDisableEmptyList = true;
    @Input() nbcAuthenticateUser: any | undefined;
    @Input() nbcAuthenticateCommand: any | undefined;
    @Input() nbcRequired = false;
    @Input() attachOldValue = true;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcOnOptionSelect = new EventEmitter<any>();

    internalSource: any[] | undefined;

    name: string | undefined;
    isOpen: boolean | undefined;
    reg: any = /{(.*?)}/g;
    arrayFixed = false;
    oldValue: any;

    defaultDisplayProperty = "display";
    initialType: any;
    isDisplayTemplate: any;
    isAuthorized = true;

    dropDownController() {
        const reg = /{(.*?)}/g;
        const arrayFixed = false;
        //Use string.match to allow for the global regex to reset
    }

    closeDropdown() {
        this.log.trace("closeDropdown");
        this.isOpen = false;
        this.doChangeDetection();
    }

    onFocusOut() {
        if (this.isOpen) this.isOpen = !this.isOpen;
    }

    onClick(event: any) {
        if (!this._elref.nativeElement.contains(event.target))
            if (this.isOpen)
                // or some similar check
                this.isOpen = !this.isOpen;

        this.doChangeDetection();
    }

    fixArray(objArray: any, displayProperty: any, template: any) {
        for (let i = 0, j = objArray.length; i < j; i++) {
            if (objArray[i] && !objArray[i][displayProperty]) {
                let newString = template,
                    match;
                while ((match = this.reg.exec(template))) {
                    newString = newString.replace(
                        match[0],
                        eval.call(match[1], objArray[i])
                    );
                }
                objArray[i][displayProperty] = newString;
            }
        }
        this.log.trace(objArray);
        return objArray;
    }

    onTypeSelect(event: any) {
        this.log.trace("onTypeSelect");
        const doChange = this.model !== event[this.value];
        this.oldValue = this.model ? this.model : null;
        this.model = this.value ? event[this.value] : event;
        if (!this.model || this.model == "" || this.model == this.placeholder)
            this.model = null;
        this.initialType = this.selectInitialType(this.model);
        const ref = this;
        if (event && this.attachOldValue) {
            _.assign(event, { oldValue: this.oldValue });
        }
        this.nbcOnOptionSelect.emit(event);
        doChange &&
            setTimeout(() => {
                // ref.nbcOnOptionSelect(event);
                ref.isOpen = false;
                this.doChangeDetection();
                ref.log.trace("from timeout");
            }, 10);

        // Check a CLear
        if (this.model === 0 || this.model === " ") {
            this.model = this.placeholder ? this.placeholder : "";
        }
    }

    selectInitialType(id: any) {
        this.log.trace("selectInitialType");
        if (this.internalSource) {
            //if display isn't defined in the internalSource, add it
            if (this.isDisplayTemplate) {
                let i = 0,
                    displayProperty = this.defaultDisplayProperty;
                const template = this.display;
                if (!this.arrayFixed) {
                    while (
                        !this.arrayFixed &&
                        !!this.internalSource[0][displayProperty]
                    ) {
                        displayProperty = this.defaultDisplayProperty + i++;
                    }
                    this.fixArray(
                        this.internalSource,
                        displayProperty,
                        template
                    );

                    this.arrayFixed = true;
                }
                this.display = displayProperty;
                //If the array changes size, we need to re-fix the array
            }

            // Adds a Blank
            const placeholderText: any = {
                id: 0,
            };

            if (
                this.internalSource.length > 0 &&
                this.internalSource[0][this.display] !== this.placeholder
            ) {
                placeholderText.id = 0;
                placeholderText[this.display] = this.placeholder;

                if (this.placeholder && !this.nbcAddBlank) {
                    this.internalSource.unshift(placeholderText);
                }
                if (this.nbcAddBlank && this.internalSource[0] !== "") {
                    this.internalSource.unshift("");
                }
                //return this.placeholder; this was breaking the initial selection, removing fixes the issue
            }

            if (
                (id === 0 || id === "" || id === null || !id) &&
                (this.placeholder || this.nbcAddBlank)
            ) {
                this.model = 0;
                this.modelChange.emit(this.model);
                return this.placeholder;
            }

            if (this.value) {
                for (let i = 0; i < this.internalSource.length; i++) {
                    if (this.internalSource[i][this.value] === id) {
                        this.model = this.internalSource[i][this.value];
                        this.modelChange.emit(this.model);
                        return this.internalSource[i][this.display];
                    }
                }
            } else if (this.internalSource.length > 0) {
                for (let i = 0; i < this.internalSource.length; i++) {
                    if (
                        id &&
                        this.internalSource[i][this.display] ===
                            id[this.display]
                    ) {
                        //S P-- changed to pass an object instead of value
                        this.model = this.internalSource[i];
                        this.modelChange.emit(this.model);
                        return this.internalSource[i][this.display];
                    }
                }
            } else {
                if (this.model && this.model.description) {
                    return this.model.description;
                } else {
                    return this.placeholder;
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            this.log.trace("** property changed is :" + propName);
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                if (
                    (current === 0 || current === "" || current === null) &&
                    prev !== 0 &&
                    prev !== "" &&
                    prev !== null
                ) {
                    this.model = null;
                    this.initialize();
                    this.initialType = this.selectInitialType(this.model);
                } else {
                    this.model = current;
                    this.initialize();
                    this.initialType = this.selectInitialType(current);
                }
            } else if (propName === "source") {
                this.source = changes["source"]["currentValue"];
                this.ngOnInit();
            }
        }
    }

    doChangeDetection() {
        setTimeout(() => this.cd.detectChanges(), 30);
    }

    initialize() {
        this.isDisplayTemplate = this.display && !!this.display.match(this.reg);
    }

    ngOnInit() {
        if (!this.name)
            this.name =
                Date.now().toString(36) + Math.random().toString(36).substr(2);
        this.nbcDisabled = false;
        this.internalSource = _.cloneDeep(this.source);
        this.initialize();
        this.initialType = this.selectInitialType(this.model);
        this.isAuthorized = this.authorize(this.nbcAuthenticateUser);
    }
}
