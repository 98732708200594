<div *ngIf="gridData?.items?.length > 0" style="overflow-x: auto; overflow-y: hidden;">
  <div class="grid-table">
    <div class="hdr-grid-row grid-row sticky-header"
         id="headerDiv">
      <div class="hdr-grid-col text-center chk sticky-header grid-col-37">
        <input type="checkbox"
               id="select-all-records"
               authenticate-user="HR.Request.Export"
               authenticate-command="disabled"
               [(ngModel)]="selectAll"
               (change)="checkAllOptions()" />
      </div>

      <div class="hdr-grid-col exp text-center sticky-header grid-col-85">
        Submitted on Mass OM
      </div>

      <div class="hdr-grid-col psd text-center sticky-header grid-col-60"
           [ngClass]="gridData.query.sortByColumn === 'positionStartDate'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="positionStartDate"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('positionStartDate')">
          Position<br /> Start Date
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'positionStartDate'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <!--<div class="hdr-grid-col req sticky-header"
       [ngClass]="gridData.query.sortByColumn === 'requestNumber'?'hdr-grid-col-sorted':''">
    <a href="javascript:void(0)"
       data-sort-column-name="requestNumber"
       class="sortable-column text-nowrap"
       (click)="sortOnColumn('requestNumber')">
      Request #
      <span class="th-sort">
        <span *ngIf="gridData.query.sortByColumn === 'requestNumber'">
          <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
          <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
        </span>
      </span>
    </a>
  </div>-->

      <div class="hdr-grid-col en text-center sticky-header grid-col-125"
           [ngClass]="gridData.query.sortByColumn === 'eventName'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="eventName"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('eventName')">
          Event Name
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'eventName'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col dr text-center sticky-header grid-col-60"
           [ngClass]="gridData.query.sortByColumn === 'dateRequested'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="dateRequested"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('dateRequested')">
          Date <br />Requested
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'dateRequested'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col hm text-center sticky-header grid-col-110"
           [ngClass]="gridData.query.sortByColumn === 'hiringManager'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="hiringManager"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('hiringManager')">
          Hiring Manager
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'hiringManager'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col pm text-center sticky-header grid-col-125"
           [ngClass]="gridData.query.sortByColumn === 'productionManager'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="productionManager"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('productionManager')">
          Production Manager
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'productionManager'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col con text-center sticky-header grid-col-125"
           [ngClass]="gridData.query.sortByColumn === 'contact'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="contact"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('contact')">
          Contact
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'contact'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col pt text-center sticky-header grid-col-85"
           [ngClass]="gridData.query.sortByColumn === 'positionTypeRequest'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="positionTypeRequest"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('positionTypeRequest')">
          Position Type
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'positionTypeRequest'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col pn text-center sticky-header grid-col-80"
           [ngClass]="gridData.query.sortByColumn === 'positionName'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="positionName"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('positionName')">
          Position Name
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'positionName'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col sso text-center sticky-header grid-col-80"
           [ngClass]="gridData.query.sortByColumn === 'sso'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="sso"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('sso')">
          SSO
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'sso'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col es text-center sticky-header grid-col-105"
           [ngClass]="gridData.query.sortByColumn === 'employeeStatus'?'hdr-grid-col-sorted':''">
        <a href="javascript:void(0)"
           data-sort-column-name="employeeStatus"
           class="sortable-column text-nowrap"
           (click)="sortOnColumn('employeeStatus')">
          Employee Status
          <span class="th-sort">
            <span *ngIf="gridData.query.sortByColumn === 'employeeStatus'">
              <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
              <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
            </span>
          </span>
        </a>
      </div>

      <div class="hdr-grid-col empty sticky-header grid-col-20">
        &nbsp;
      </div>

      <div class="hdr-grid-col empty1 sticky-header grid-col-20">
        &nbsp;
      </div>

      <div class="hdr-grid-col text-center hdr-grid-col-hlight sendreminder sticky-header grid-col-120">
        Reminder
      </div>

      <div class="hdr-grid-col text-center hdr-grid-col-hlight i9review sticky-header grid-col-170">
        I9 Verify
      </div>

      <div class="hdr-grid-col text-center hdr-grid-col-hlight sap sticky-header grid-col-127">
        SAP Position #
      </div>

      <!--<div class="hdr-grid-col text-center hdr-grid-col-hlight doc sticky-header">
        Documents
      </div>-->

      <div class="hdr-grid-col text-center hdr-grid-col-hlight taf sticky-header grid-col-50">
        TAF Submitted
      </div>

      <div class="hdr-grid-col text-center hdr-grid-col-hlight can sticky-header grid-col-90">
        Cancel Request
      </div>
    </div>

    <!-- Grid Body -->

    <div *ngFor="let listItem of gridData.items; let index=index;" class="grid-row"
         id="row-{{listItem?.requestNumber}}"
         ng-attr-data-id="{{listItem?.requestNumber}}">
      <!--<div class="row grid-animate grid-row">-->
      <div class="grid-col grid-col text-center grid-col-35 {{listItem?.requestStatusColor}}"
           for="checkbox-{{listItem?.requestNumber}}">
        <div class="tooltips">
          <input type="checkbox"
                 class="selection"
                 id="checkbox-{{listItem?.requestNumber}}"
                 [(ngModel)]="listItem.checked"
                 (click)="checkedItem($event)"
                 authenticate-user="HR.Request.Export"
                 authenticate-command="disabled" />
          <span class="tooltiptext" *ngIf="(listItem.lastUpdatedby!='' && listItem.lastUpdatedDate!='')">
            Last updated on {{listItem.lastUpdatedDate}} by {{listItem.lastUpdatedby}}
          </span>
        </div>
      </div>
      <div class="grid-col text-center grid-col-80">
        <span class="grid-dtl-text" *ngIf="listItem.hasPreviouslyExported">
          <i class="fa fa-check-circle" style="color:green;font-size: 25px;"></i>
        </span>
      </div>

      <div class="grid-col text-center grid-col-60">
        <span class="grid-dtl-text">
          {{listItem.positionStartDate}}
        </span>
      </div>

      <!--<div class="grid-col text-right grid-col-50">
    <span class="grid-dtl-text">
      {{listItem.requestNumber}}
    </span>
  </div>-->

      <div class="grid-col grid-col-120">
        <span class="grid-dtl-text">
          <a routerLink="/Events/Event/{{listItem.eventId}}/Crew"
             target="_blank"
             class="btn-link"
             authenticate-user="HR.Request.Retrieve"
             authenticate-command="disableanchor">
            {{listItem.eventName}}
          </a>
        </span>
      </div>

      <div class="grid-col text-center grid-col-60">
        <div class="tooltips">
          <span class="grid-dtl-text">
            {{listItem.requestedDate}}
          </span>
          <span class="tooltiptext text" *ngIf="listItem.requestNumber!=''">Request # {{listItem.requestNumber}}</span>
        </div>
      </div>

      <div class="grid-col grid-col-100">
        <span class="grid-dtl-text">
          {{listItem.hiringManager}}
        </span>
      </div>

      <div class="grid-col grid-col-120">
        <span class="grid-dtl-text">
          {{listItem.productionManager}}
        </span>
      </div>

      <div class="grid-col grid-col-120">
        <i class="fa fa-circle-exclamation contact-icon"
           alt="alternative text"
           title="Contact has additional registrations in progress."
           *ngIf="(listItem.hasOlypicRegistrationConflict)"></i>

        <a routerLink="/Contacts/Detail/{{listItem.contactId}}"
           target="_blank"
           class="btn-link"
           authenticate-user="HR.Request.Retrieve"
           authenticate-command="disableanchor">
          {{listItem.contactName}}
        </a>
      </div>

      <div class="grid-col grid-col-80">
        <span class="grid-dtl-text" *ngIf="listItem.positionTypeRequest != null">
          {{listItem.positionTypeRequest}}
        </span>
      </div>
      <div class="grid-col grid-col-80">
        <span class="grid-dtl-text">
          {{listItem.positionName}}
        </span>
      </div>

      <div class="grid-col text-center grid-col-60">
        <span class="grid-dtl-text" *ngIf="listItem.sso != 0">
          {{listItem.sso}}
        </span>
      </div>

      <div class="grid-col grid-col-100">
        <span class="grid-dtl-text">
          {{listItem.employeeStatus}}
        </span>
      </div>

      <div class="open-position-modal-el grid-col text-center grid-col-lnk"
           style="background-color:#e6b800"
           data-request-no="{{listItem.requestNumber}}"
           data-crew-registration-no="{{listItem.crewRegistrationNo}}"
           authenticate-user="HR.Request.Update"
           authenticate-command="unbindclickevent"
           (click)="openOnboardingModal(listItem)">
        <i class="fa fa-ellipsis-h"></i>
      </div>

      <a href="javascript:void(0)"
         (click)="openEditPositionRequestModal(listItem)" class="open-position-modal-el grid-col text-center grid-col-lnk"
         style="background-color:#800080"
         data-request-no="{{listItem.requestNumber}}"
         data-crew-registration-no="{{listItem.crewRegistrationNo}}"
         authenticate-user="HR.Request.Update"
         authenticate-command="unbindclickevent">
        <i class="fa fa-ellipsis-h"></i>
      </a>

      <div class="grid-col grid-col-hlight text-center grid-col-110">
        <button class="onboarding-button-reminder reminder-reg"
                data-request-no="{{listItem.requestNumber}}"
                data-crew-registration-no="{{listItem.crewRegistrationNo}}"
                authenticate-user="HR.Request.Update"
                authenticate-command="hide"
                *ngIf="listItem.requestStatus !== '6' && listItem.requestStatus !== '5'"
                (click)="sendReminder(listItem)">
          Send Reminder
        </button>
        <br />
        <span class="text" *ngIf="listItem.reminderSentUser!='' && listItem.reminderSentUser!='' && listItem.reminderSent">Last Sent on {{listItem.reminderSentDate}} <br /> By {{listItem.reminderSentUser}}</span>
      </div>

      <div class="grid-col grid-col-hlight text-center grid-col-165">
        <div class="tooltips" *ngIf="i9VerifyDDl">
          <nbc-select name="I9Verify_ + {{listItem.requestNumber}}"
                      [(model)]="listItem.i9Verify"
                      authenticate-user="HR.Request.Update"
                      authenticate-command="nbcselectdisabled"
                      [source]="i9VerifyDDl"
                      display="name"
                      value="name"
                      placeHolderDisplay="Select Reason"
                      placeHolderValue="Select Reason"
                      (change)="updateDetails(listItem, 'I9Verify', listItem?.i9Verify?.name)">
          </nbc-select>
        </div>
      </div>

      <div class="grid-col grid-col-hlight text-center grid-col-90">
        <div class="tooltips">
          <input type="text" style="width:110px"
                 maxlength="25"
                 class="update-reg"
                 data-request-no="{{listItem.requestNumber}}"
                 data-crew-registration-no="{{listItem.crewRegistrationNo}}"
                 data-column="SAP Position"
                 [value]="listItem.sapNo!=null ? listItem.sapNo : ''"
                 [(ngModel)]="listItem.sapNo"
                 authenticate-user="HR.Request.Update"
                 authenticate-command="disabled"
                 (change)="updateDetails(listItem, 'SAP Position', listItem.sapNo)" />
          <span class="tooltiptext text" *ngIf="listItem.sapNoLastUpdatedBy!='' && listItem.sapNoLastUpdatedDate!=''">Last updated on {{listItem.sapNoLastUpdatedDate}} by {{listItem.sapNoLastUpdatedBy}}</span>
        </div>
      </div>

      <!--<div class="grid-col grid-col-hlight grid-col-165">
    <div class="tooltips">
      <select class="form-control input-xs update-reg grid-col-165"
              data-request-no="{{listItem.requestNumber}}"
              data-crew-registration-no="{{listItem.crewRegistrationNo}}"
              data-column="Documents"
              authenticate-user="HR.Request.Update"
              authenticate-command="disabled"
              [disabled]="listItem.requestStatus!=5 && listItem.requestStatus!=6 && listItem.requestStatus!=7"
              authenticate-disabled="{{(listItem.requestStatus!=5 && listItem.requestStatus!=6 && listItem.requestStatus!=7)}}"
              (change)="updateDetails(listItem, 'Documents', listItem.documentType)"
              [(ngModel)]="listItem.documentType">
        <option value="null">Documents</option>
        <option [selected]="listItem.documentType=='Direct Deposit'">Direct Deposit</option>
        <option [selected]="listItem.documentType=='W-4'">W-4</option>
        <option [selected]="listItem.documentType=='Both'">Both</option>
        <option [selected]="listItem.documentType=='NA'">N/A</option>
      </select>
      <span class="tooltiptext text" *ngIf="listItem.documentTypeLastUpdatedBy!=''">Last updated on {{listItem.documentTypeLastUpdatedDate}} by {{listItem.documentTypeLastUpdatedBy}}</span>
    </div>
  </div>-->

      <div class="grid-col text-center grid-col-50"
           [ngClass]="listItem.tafSubmitted==true?'chkchecked':''">
        <div class="tooltips">
          <input type="checkbox"
                 [checked]="listItem.tafSubmitted==true"
                 [disabled]="listItem.requestStatus!=5 && listItem.requestStatus!=6 && listItem.requestStatus!=7"
                 authenticate-disabled="{{(listItem.requestStatus!=5 && listItem.requestStatus!=6 && listItem.requestStatus!=7)}}"
                 [(ngModel)]="listItem.tafSubmitted"
                 class="update-reg"
                 data-request-no="{{listItem.requestNumber}}"
                 data-crew-registration-no="{{listItem.crewRegistrationNo}}"
                 data-column="TAF Submitted"
                 authenticate-user="HR.Request.Update"
                 authenticate-command="disabled"
                 (change)="updateDetails(listItem, 'TAF Submitted', listItem.tafSubmitted)" />
          <span class="tooltiptext" *ngIf="listItem.tafLastUpdatedBy!='' && listItem.tafLastUpdatedDate!=''">Last updated on {{listItem.tafLastUpdatedDate}} by {{listItem.tafLastUpdatedBy}}</span>
        </div>
      </div>
      <!--authenticate-user="HR.Request.Update{{?listItem.requestStatus=='1'}},PM.Request.Cancel{{?}}"-->

      <div class="grid-col grid-col-hlight text-center grid-col-90">
        <button class="onboarding-button cancel-reg"
                data-request-no="{{listItem.requestNumber}}"
                data-crew-registration-no="{{listItem.crewRegistrationNo}}"
                authenticate-user="HR.Request.Update"
                authenticate-command="hide"
                (click)="cancel(listItem)">
          Cancel <i class="fa fa-close"></i>
        </button>
      </div>
      <!--</div>-->

    </div>

  </div>

</div>
