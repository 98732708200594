import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
//import { DateRange } from '@angular/material/datepicker';
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcBudgetCode } from "src/app/Shared/components/nbc-budgetcode/nbc-budget.component";
import { TimeZoneHelperService } from "src/app/Shared/helper/timezonehelper.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { eventManagerService } from "src/app/Shared/Services/eventmanager.service";
import { eventTierService } from "src/app/Shared/Services/eventtier.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import { positionTemplateService } from "src/app/Shared/Services/positiontemplateservice";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { TeamService } from "src/app/Shared/Services/team.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";
import { SharedDataService } from "../../../Shared/sharedData.service";
@Component({
    selector: "app-craete-event",
    templateUrl: "./create-event.component.html",
})
export class CreateEventConponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _positionTemplateService: positionTemplateService,
        private _EventTypeService: EventTypeService,
        private _SeasonService: SeasonService,
        private _globalService: GlobalService,
        private _networkGroupService: networkGroupService,
        private _eventTierService: eventTierService,
        private _timeZoneService: TimeZoneHelperService,
        private _activatedRoute: ActivatedRoute,
        private _eventManagerService: eventManagerService,
        private _EventService: EventService,
        private _VenueService: VenueService,
        private _TeamService: TeamService,
        public _modalService: BsModalService,
        private _sharedData: SharedDataService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    eventManagers = {
        all: { id: 11, values: [] },
        homeBase: { id: 12, values: [] },
        technical: { id: 13, values: [] },
        manPower: { id: 14, values: [] },
        onSite: { id: 15, values: [] },
    };
    bsModalRef?: BsModalRef;
    minDate: Date = new Date();
    bsInlineTapeDate!: any[];
    bsInlineValue!: any[];
    bsInlineRangeValue!: any[];
    venueResult: any;
    venueSearchQueryTypeahead = "";
    componentDestroyed$: Subject<boolean> = new Subject();
    private subs = new SubSink();
    // disableSave: boolean | undefined;
    difference = 0;
    eventType: any = {};
    eventPositionTemplates = [];
    eventsWithNoTeam = [
        "PGA Tour",
        "LPGA",
        "Motocross",
        "Nascar",
        "Dew Tour",
        "Dew Tour Winter",
    ];
    hideTeamFields = false;
    isBroadcastDateSet = false;
    submitDetectBroadcastDateSet = false;
    selectedSeason: any = {};
    broadcastTimesInvalid = false;
    networks = {};
    ToggleEventClass = false;

    seasons = {};
    submitted = false;
    eventTimeZone: any;
    isValid: boolean | undefined;
    isEventLocationValid = true;
    airDateSeasonWarnings = [];
    networkEventTypeTier = {};
    VenueName: string[] = [];
    BroadcastTypes: any[] = [
        { id: "Live", desc: "Live" },
        { id: "Taped", desc: "Taped" },
        { id: "Outside Package", desc: "Outside Package" },
    ];

    EventLocations: any[] = [
        { id: "Features", desc: "Features" },
        { id: "Hybrid", desc: "Hybrid" },
        { id: "Onsite", desc: "Onsite" },
        { id: "Stamford", desc: "Stamford" },
    ];

    TimekeepingReminders: any[] = [
        { id: "10:00 AM", desc: "10:00 AM" },
        { id: "12:00 PM", desc: "12:00 PM" },
        { id: "02:00 PM", desc: "02:00 PM" },
        { id: "04:00 PM", desc: "04:00 PM" },
        { id: "06:00 PM", desc: "06:00 PM" },
        { id: "08:00 PM", desc: "08:00 PM" },
        { id: "10:00 PM", desc: "10:00 PM" },
    ];

    //tier
    tierNetworks = {};
    tierConfig: any = null;
    nonTieredNetworks: any = [];
    allTierNetworkSetups = [];
    allNonTieredNetworks: any = [];
    currentTierStatus = null;
    isInitialLoad = true;
    VenueNameObservable$: Observable<any[]> | undefined;
    AwayTeamObservable$: Observable<any[]> | undefined;
    HomeTeamObservable$: Observable<any[]> | undefined;

    awayTeamSearchTerm = "";
    homeTeamSearchTerm = "";

    sortedBroadcastDates: any[] = [];
    calendar_broadcast_minDate: Date = new Date();
    calendar_broadcast_maxDate!: Date;
    // the backend need an object like this. im only getting the tier back . this page needs to be updated.
    model: any = {
        eventTypeId: "",
        statusCode: {
            id: 0,
            description: "",
        },
        homeTeamId: "",
        awayTeamId: "",
        eventName: "",
        budgetCode: "",
        seasonId: 0,
        venueId: 0,
        flex: "N",
        level: 1,
        tier: "",
        broadcastDates: [],
        broadcastAirDates: [],
        minBroadcastDate: "",
        maxBroadcastDate: "",
        //minAirDate: undefined,

        crewWorkStartDate: undefined,
        crewWorkEndDate: undefined,
        eventPositionTemplate: {
            id: 0,
            description: "",
        },
        homeBaseManagerId: 0,
        technicalManagerId2: 0,
        onsiteProductionManagerId2: 0,
        manPowerManagerId: 0,
        technicalManagerId: 0,
        onsiteProductionManagerId: 0,
        note: "",
        eventTierSetup: {
            networkGroupId: null,
        },
        isMobileTimekeepingChecked: true,
        additionalLocations: [],
        isTaped: false,
        financials: {
            talentAirFare: null,
            productionAirFare: null,
            engineersAirFare: null,
            productionHotelFare: null,
            engineersHotelFare: null,
            budgetedHours: [],
        },
        facility: "",
        eventLocation: "",
        timekeepingReminder: "",
    };
    broadcastCount = -1;
    maxLocationLength = 25;
    blankLocation = false;

    minAirDate = "";
    PRIMARY_DATE_INDEX: any = 0;
    broadCastdateBeingChanged = false;
    defaultDate: Date = new Date();
    _clonedBroadcastAirDates: any[] = [];

    retrieveEventPositionTemplates() {
        this.log.trace("retrieve event position templates");

        this.subs.sink = this._positionTemplateService
            .getTemplateByEventTypeId(this.eventType.id)
            .subscribe(
                (result) => {
                    // this.log.trace("response: " + JSON.stringify(result));

                    this.eventPositionTemplates = result.reference;
                },
                (err) => {
                    this.log.showError("got an error: ");
                },
                () => {}
            );
    }
    getEventType() {
        this.subs.sink = this._EventTypeService
            .getEventTypesById(this.model.eventTypeId)
            .subscribe(
                (result) => {
                    // this.log.trace("got a result: ");
                    this.eventType = result.reference;
                    this.retrieveEventPositionTemplates();
                },
                (err) => {
                    this.log.error("got an error: ");
                },
                () => {}
            );
    }

    HomeTeamTypeahead() {
        this.HomeTeamObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.homeTeamSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._TeamService.search(
                    this.eventType.id || 0,
                    encodeURIComponent(token)
                );
            })
        );
    }

    AwayTeamTypeahead() {
        this.AwayTeamObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.awayTeamSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._TeamService.search(
                    this.eventType.id || 0,
                    encodeURIComponent(token)
                );
            })
        );
    }

    VenueNameTypeahead() {
        this.VenueNameObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.venueSearchQueryTypeahead);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._VenueService.autocomplete(token);
            })
        );
    }

    /*
     * method gets tier setup info for the event type and network id
     */
    getNetworkGroupSetup(
        $event: any,
        networkId: any,
        index: any,
        oldNetworkId: any
    ) {
        let changedDateindex = 0;
        oldNetworkId = oldNetworkId || $event.oldValue;
        changedDateindex = parseInt(index) - 1;

        if (!_.has(this.model.broadcastAirDates[changedDateindex], "network")) {
            this.model.broadcastAirDates[changedDateindex].network =
                this.getNetwork(networkId);
        }

        if (
            networkId > 0 &&
            this.PRIMARY_DATE_INDEX != null &&
            changedDateindex !== this.PRIMARY_DATE_INDEX
        ) {
            if (this.tierConfig) {
                //with groups
                if (
                    this.tierConfig.networkGroupId != null &&
                    this.tierConfig.networkGroupId > 0
                ) {
                    //tiere enabled on Groups
                    if (this.tierConfig.isNetworkGroupTiered) {
                        if (
                            _.findIndex(
                                this.tierConfig.tierGroupNetworks,
                                {
                                    id: networkId
                                }
                            ) < 0
                        ) {
                            let arrParticipatingNetworks: any;
                            arrParticipatingNetworks = _.map(
                                this.tierConfig.tierGroupNetworks,
                                "description"
                            );
                            arrParticipatingNetworks =
                                arrParticipatingNetworks.join("; ");
                            const msg =
                                "You must select networks from current Network Group: " +
                                arrParticipatingNetworks;
                            //this.showValidationModal("Tiered Rate Card", msg, null, "OK");
                            this.showValidationModal(
                                "Tiered Rate Card",
                                msg,
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.cancelTransactionWorkflow.bind(this),
                                [changedDateindex, oldNetworkId]
                            );

                            return;
                        }
                    }

                    //flag is disabled i.e. group is available but not tiered yet
                    if (!this.tierConfig.isNetworkGroupTiered) {
                        const combinedNonTieredNetworks =
                            this.nonTieredNetworks.concat(
                                this.tierConfig.tierGroupNetworks
                            );
                        if (
                            _.findIndex(
                                combinedNonTieredNetworks,
                                {
                                    id: networkId
                                }
                            ) < 0
                        ) {
                            const arrNonTieredNetworks = _.map(
                                this.nonTieredNetworks,
                                "description"
                            ).concat(
                                _.map(
                                    this.tierConfig.tierGroupNetworks,
                                    "description"
                                )
                            );

                            const arrParticipatingNetworks =
                                arrNonTieredNetworks.join("; ");
                            const msg =
                                "You must select networks from current Network Group: " +
                                arrParticipatingNetworks;
                            // this.showValidationModal("Tiered Rate Card", msg, null, "OK");
                            this.showValidationModal(
                                "Tiered Rate Card",
                                msg,
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.cancelTransactionWorkflow.bind(this),
                                [changedDateindex, oldNetworkId]
                            );

                            return;
                        }
                    }
                } else {
                    //if the primary is from free list i.e. not part of any groups
                    if (
                        _.findIndex(
                            this.allNonTieredNetworks,
                            {
                                id: networkId
                            }
                        ) < 0
                    ) {
                        let arrParticipatingNetworks: any;
                        arrParticipatingNetworks = _.map(
                            this.allNonTieredNetworks,
                            "description"
                        );
                        arrParticipatingNetworks =
                            arrParticipatingNetworks.join("; ");
                        const msg =
                            "You must select networks from current Network Group: " +
                            arrParticipatingNetworks;
                        //this.showValidationModal("Tiered Rate Card", msg, null, "OK");
                        this.showValidationModal(
                            "Tiered Rate Card",
                            msg,
                            false,
                            null,
                            null,
                            null,
                            "OK",
                            this.cancelTransactionWorkflow.bind(this),
                            [changedDateindex, oldNetworkId]
                        );

                        return;
                    }
                }
            }

            //everything is ok for secondary networks.So, we have to recalculate primary date index. This may be because of network "NBC"
            if (this.model.broadcastAirDates[changedDateindex]) {
                this.model.broadcastAirDates[changedDateindex].networkId =
                    networkId;
                this.model.broadcastAirDates[changedDateindex].network =
                    this.getNetwork(networkId);
                this.findPrimaryBroadcastingDate();
            }
            return;
        }

        if (!networkId || networkId <= 0) {
            if (index - 1 == this.PRIMARY_DATE_INDEX) {
                this.tierConfig = null;
            }
            //recalculating when the primary when we select "Network" option from the dropdown.
            this.model.broadcastAirDates[changedDateindex].network = null;
            this.findPrimaryBroadcastingDate();
            // BA need to call out the ality. Lets wait till they get back. 04262018 - ~SP
            return;
        }

        //Step 1: get the tier information for the selected network

        this.subs.sink = this._eventTierService
            .getEventTierSetup(networkId, this.model.eventTypeId)
            .subscribe(
                (result) => {
                    this.validateTierConfigResponse(
                        result.reference,
                        changedDateindex,
                        oldNetworkId
                    );
                },
                (err) => {
                    this.log.error("got an error: ");
                },
                () => {}
            );
    }

    resetNetworkSelection(index: any) {
        //resetting broadcasting network
        this.model.broadcastAirDates[index].networkId = "";
        this.model.broadcastAirDates[index].network = null;
    }

    getAllNonTieredNetworksFromGroups() {
        let nonTierEnabledGroups: any;
        nonTierEnabledGroups = _.filter(this.allTierNetworkSetups, {
            isNetworkGroupTiered: false,
        });
        this.allNonTieredNetworks = [];

        if (!_.isEmpty(nonTierEnabledGroups)) {
            for (let i = 0; i < nonTierEnabledGroups.length; i++) {
                this.allNonTieredNetworks = this.allNonTieredNetworks.concat(
                    nonTierEnabledGroups[i].networks
                );
            }

            // we need to add free networks to the networks from above list
            this.allNonTieredNetworks = this.allNonTieredNetworks.concat(
                this.nonTieredNetworks
            );
            this.allNonTieredNetworks = _.filter(
                this.allNonTieredNetworks,
                "id"
            );
        }
    }

    getAllTierNetworkSetup() {
        this.subs.sink = this._networkGroupService.allsetups().subscribe(
            (result) => {
                // this.log.trace("getAllNetworks got data" + JSON.stringify(result));
                this.allTierNetworkSetups = result.reference;
            },
            (err) => {
                this.log.showError("getAllNetworks got an error: ");
            },
            () => {
                this.getAllNonTieredNetworksFromGroups();
            }
        );
    }

    //conditional network selection
    getNetworks(index: any) {
        return this.networks;
    }

    getNetwork(id: any) {
        return _.find(this.networks, (n: any) => {
            return n.id === id;
        });
    }

    getSeasons() {
        this.subs.sink = this._SeasonService
            .searchByEventType(this.model.eventTypeId, "")
            .subscribe(
                (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.seasons = result.reference;
                },
                (err) => {
                    this.log.showError(" got an error: ");
                },
                () => {}
            );
    }
    isEqualTeam(model: any) {
        return _.isEqual(model.homeTeam, model.awayTeam);
    }
    getEventNetworks() {
        this.subs.sink = this._globalService.getEventNetworks().subscribe(
            (result) => {
                // this.log.trace("got results: " + JSON.stringify(result));
                this.networks = result.reference;
            },
            (err) => {
                this.log.showError(" got an error: ");
            },
            () => {}
        );
    }

    getNonTieredNetworks() {
        this.subs.sink = this._networkGroupService
            .getNonTieredNetworks()
            .subscribe(
                (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.nonTieredNetworks = result.reference;
                },
                (err) => {
                    this.log.showError(" got an error: ");
                },
                () => {}
            );
    }

    getSelectedSeason() {
        this.subs.sink = this._SeasonService
            .getSeasonData(this.model.seasonId)
            .subscribe(
                (result) => {
                    this.log.trace("Successfully selected a season");
                    this.selectedSeason = result.reference;
                    this.checkOutOfSeasonRangeBroadcastDates();
                },
                (err) => {
                    this.log.showError(" got an error: ");
                },
                () => {}
            );
    }

    addLocation() {
        let activeRows = 0;

        for (const loc in this.model.additionalLocations) {
            if (
                this.model.additionalLocations[loc].isDeleted == undefined ||
                this.model.additionalLocations[loc].isDeleted != "Y"
            ) {
                activeRows++;
            }
        }
        if (this.model.additionalLocations !== undefined) {
            this.model.additionalLocations.push({
                locationId: 0,
                locationName: "",
                isDeleted: "N",
            });
        }
    }

    removeLocation(loc: any) {
        this.model.additionalLocations.splice(loc, 1);
    }

    broadcastDateFactory(
        index: any,
        broadcastItem1: any,
        crewStartDate: any,
        crewEndDate: any
    ) {
        let updateCrewWorkRange = false;
        const broadcastItem = this.model.broadcastAirDates[index];
        if (broadcastItem && broadcastItem.airDate !== "") {
            if (broadcastItem.airDate) {
                if (broadcastItem.startTime) {
                    const startTimeHours = moment(
                            broadcastItem.startTime
                        ).hours(),
                        startTimeMinutes = moment(
                            broadcastItem.startTime
                        ).minutes(),
                        startTimeSeconds = moment(
                            broadcastItem.startTime
                        ).seconds();

                    broadcastItem.startTime = moment(broadcastItem.airDate)
                        .hours(startTimeHours)
                        .minutes(startTimeMinutes)
                        .seconds(startTimeSeconds)
                        .toDate();
                    broadcastItem.startTime = moment(broadcastItem.startTime)
                        .format()
                        .toString();
                } else {
                    broadcastItem.startTime = moment(broadcastItem.airDate)
                        .add(7, "hours")
                        .toDate();
                    broadcastItem.startTime = moment(broadcastItem.startTime)
                        .format()
                        .toString();
                }
                if (broadcastItem.endTime) {
                    const endTimeHours = moment(broadcastItem.endTime).hours(),
                        endTimeMinutes = moment(
                            broadcastItem.endTime
                        ).minutes(),
                        endTimeSeconds = moment(
                            broadcastItem.endTime
                        ).seconds();

                    broadcastItem.endTime = moment(broadcastItem.airDate)
                        .hours(endTimeHours)
                        .minutes(endTimeMinutes)
                        .seconds(endTimeSeconds)
                        .toDate();
                    broadcastItem.endTime = moment(broadcastItem.endTime)
                        .format()
                        .toString();
                } else {
                    broadcastItem.endTime = moment(broadcastItem.airDate)
                        .add(15, "hours")
                        .toDate();
                    broadcastItem.endTime = moment(broadcastItem.endTime)
                        .format()
                        .toString();
                }

                broadcastItem.airDate = moment(broadcastItem.startTime)
                    .startOf("day")
                    .format();
                this.model.broadcastAirDates.splice(index, 1, broadcastItem);
                this._clonedBroadcastAirDates.splice(index, 1, broadcastItem);
            }
            const sortedBroadcastDates = this.getSortedBroadcastDates();
            const totalLength = sortedBroadcastDates.length;
            if (crewStartDate) {
                // if (broadcastItem.airDate < crewStartDate) {
                if (
                    moment(crewStartDate).isSameOrAfter(
                        moment(sortedBroadcastDates[0].airDate),
                        "day"
                    )
                ) {
                    updateCrewWorkRange = true;
                    crewStartDate = moment(sortedBroadcastDates[0].airDate);
                }
            } else {
                updateCrewWorkRange = false;
            }
            if (crewEndDate) {
                const x = new Date(
                    sortedBroadcastDates[totalLength - 1].airDate
                );
                if (
                    !moment(crewEndDate).isSameOrAfter(
                        new Date(sortedBroadcastDates[totalLength - 1].airDate),
                        "day"
                    )
                ) {
                    updateCrewWorkRange = true;
                    crewEndDate = moment(
                        sortedBroadcastDates[totalLength - 1].airDate
                    );
                }
            } else {
                updateCrewWorkRange = false;
            }

            if (updateCrewWorkRange) {
                this.bsInlineRangeValue = [
                    new Date(crewStartDate),
                    new Date(crewEndDate),
                ];
            }
        }
    }

    addBroadcastDate() {
        this.broadcastCount++;
        if (!this.model.broadcastDates) {
            this.model.broadcastDates = [];
        }
        this.model.broadcastAirDates.push(this.getBroadcastDate());

        // this.broadcastDateFactory(this.model.broadcastAirDates[this.broadcastCount], this.model.crewWorkStartDate, this.model.crewWorkEndDate);
        this.detectBroadcastDateSet();
    }

    checkEventType() {
        if (!_.isUndefined(this.eventType)) {
            if (this.eventType.hasTeam !== true) {
                this.hideTeamFields = true;
            }
        }
    }

    getEventLocationChange() {
        if (
            this.model.eventLocation === undefined ||
            this.model.eventLocation === null ||
            this.model.eventLocation === "" ||
            this.model.eventLocation === 0
        ) {
            this.isEventLocationValid = false;
        } else {
            this.isEventLocationValid = true;
        }
    }

    onPartSeasonChange(partSeason) {
        this.model.seasonId = partSeason.id;
    }

    createEvent(isValid: any, createEventForm: any) {
        if (
            this.model.eventLocation === undefined ||
            this.model.eventLocation === null ||
            this.model.eventLocation === "" ||
            this.model.eventLocation === 0
        ) {
            this.isEventLocationValid = false;
        }
        //Validate that the venue is selected for event creations.
        if (
            createEventForm.controls.venue.value === "" ||
            createEventForm.controls.venue.value === undefined
        ) {
            createEventForm.controls.venue.setErrors({ required: false });
            this.submitted = true;
            this.scrollToTop();
            return false;
        }

        this.log.trace("createEvent called");
        this.checkBroadcastTimes();
        this.submitted = true;
        if (
            !this.model.broadcastAirDates[0].startTime ||
            !this.model.broadcastAirDates[0].endTime ||
            !this.model.broadcastAirDates[0].airDate ||
            this.model.broadcastAirDates[0].networkId == 0
        ) {
            isValid = false;
        }

        if (this.model?.tapeDate) {
            isValid = this.checkTapeDate(this.model.tapeDate);
        }

        //Validate that the crew range is not greater than 30 days
        this.validateDateRange();
        if (this.difference > 39) {
            return;
        }
        if (
            this.model.partOfSeason &&
            (!this.model.seasonId ||
                this.model.seasonId === "Select Season" ||
                this.model.seasonId === "" ||
                this.model.seasonId === 0)
        ) {
            this.isValid = false;
            this.scrollToTop();
            return false;
        }

        if (!this.isEventLocationValid) {
            this.isValid = false;
            this.log.showError("Please select Event Location");
            this.scrollToTop();
            return false;
        }

        //Validate if "part of season" is selected
        if (this.model.partOfSeason && !_.isNumber(this.model.seasonId)) {
            //validation message on DOM
            this.scrollToTop();
            return false;
        }

        //Validate that it is a team sport with different team names
        if (
            this.eventType.hasTeam === true &&
            this.model.homeTeamId !== "" &&
            this.model.awayTeamId !== "" &&
            this.model.awayTeamId === this.model.homeTeamId
        ) {
            this.log.trace(
                "Team Sports cannot have the same team for home and away"
            );
            this.scrollToTop();
            return false;
        }
        //Validate that the Broadcast Start Times are not greater than the end times
        if (this.broadcastTimesInvalid === true) {
            this.scrollToTop();
            return;
        }

        if (
            this.model.budgetCode === undefined ||
            this.model.budgetCode === null
        ) {
            this.isValid = false;
            this.scrollToTop();
            return false;
        }

        // associating an event tier
        this.model.tier = this.tierConfig ? this.tierConfig.tierId : 0;

        this.blankLocation = false;

        for (const loc of this.model.additionalLocations) {
            if (
                !loc.locationName ||
                loc.locationName === "" ||
                loc.locationName.toLowerCase() === "onsite" ||
                loc.locationName.toLowerCase() === "@home" ||
                loc.locationName.toLowerCase() === "stamford"
            ) {
                this.isValid = false;
                this.blankLocation = true;
                this.scrollToTop();
                return false;
            }
        }
        if (!this.validateNetworks()) {
            this.isValid = false;
            this.scrollToTop();
            return false;
        }

        if (isValid) {
            // this.disableSavefn();
            this.subs.sink = this._EventService
                .createEvent(this.model)
                .subscribe(
                    (event) => {
                        if (
                            !_.isEmpty(event) &&
                            _.has(
                                event.reference.sportsEventCallStatus,
                                event
                            ) &&
                            !event.reference.sportsEventCallStatus
                        ) {
                            //this.showValidationModal("Sports Event Website", "Unable to published the event in Sports Event Website.", null, "OK");
                            this.showValidationModal(
                                "Sports Event Website",
                                "Unable to published the event in Sports Event Website.",
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.redirectSummary.bind(this),
                                [event.reference]
                            );
                        } else {
                            this.redirectSummary(event.reference);
                        }
                    },
                    (err) => {
                        this.log.showError("got an error: ");
                    },
                    () => {}
                );
        } else {
            this.log.showError("Form is invalid.");
            this.scrollToTop();
        }
        return;
    }

    scrollToTop() {
        document.documentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
    validateNetworks() {
        let isValid = true;
        if (this.model.broadcastAirDates) {
            _.each(this.model.broadcastAirDates, (airDate: any) => {
                if (!airDate.networkId) isValid = false;
            });
        }
        return isValid;
    }

    checkTapeDate(tapeDate: any) {
        if (tapeDate > this.minAirDate) {
            return false;
        } else {
            return true;
        }
    }

    redirectSummary(event: any) {
        window.location.href = "/Events/Event/" + event.id;
    }
    //this.enableSavefn =  () {
    //    $("#btnCreateEvent").removeClass("btn btn-default").addClass("btn btn-primary");
    //    this.disableSave = false;
    //};
    disableSavefn() {
        // this.disableSave = true;
        // $("#btnCreateEvent").removeClass("btn btn-primary").addClass("btn btn-default");
    }

    getBroadcastDate() {
        return {
            airDate: "",
            startTime: "",
            endTime: "",
            networkId: "",
            outOfSeasonRange: false,
        };
    }

    onAwayTeamSelect(item: any) {
        this.model.awayTeamId = item.item.id;
        this.model.awayTeam = item.item;
        this.setEventTitle();
    }
    onHomeTeamSelect(item: any) {
        this.model.homeTeamId = item.item.id;
        this.model.homeTeam = item.item;
        if (item.item.venue) {
            this.model.venue = item.item.venue;
            this.venueSearchQueryTypeahead = item.item.venue.displayText;
        }
        this.setEventTitle();
        this.setEventVenue();
    }
    setEventVenue() {
        this.model.venue = this.model.homeTeam.venue;
        if (this.model.homeTeam.venue) {
            this.eventTimeZone = this._timeZoneService.searchTimeZone(
                this.model.homeTeam.venue.timeZoneId
            ).abbr;
        }
    }
    onVenueSelect(item: any) {
        this.model.venue = item.item;
        this.model.venueId = item.item.id;
        this.eventTimeZone = this._timeZoneService.searchTimeZone(
            item.item.timeZoneId
        ).abbr;
    }
    removeBroadcastDate(index: any) {
        this.model.broadcastAirDates.splice(index, 1);
        this.broadcastCount--;
        if (this.model.broadcastAirDates.length === 0) {
            this.addBroadcastDate();
            this.broadcastCount = -1;
        }
    }

    detectBroadcastDateSet() {
        if (
            this.model.broadcastAirDates[0].airDate === "" ||
            this.model.broadcastAirDates[0].airDate == undefined
        ) {
            this.isBroadcastDateSet = false;
        } else {
            this.isBroadcastDateSet = true;
        }
    }
    setMinMaxBroadcastDate() {
        this.submitDetectBroadcastDateSet = true;
        this.detectBroadcastDateSet();
        const dateRange = this.getSortedBroadcastDates();

        this.model.minBroadcastDate = moment(dateRange[0]).add(24, "hours");
        this.model.maxBroadcastDate = moment(
            dateRange[dateRange.length - 1]
        ).subtract(24, "hours");
    }

    getSortedBroadcastDates() {
        //var dateRange: any = [];
        //_.each(this.model.broadcastAirDates, (value, key) => {
        //  var date = value.airDate ? value.airDate.getTime() : "";
        //  dateRange.push(date);
        //});
        //dateRange.sort();
        //return dateRange;
        const broadcastDateCopy = _.clone(this.model.broadcastAirDates);

        this.sortedBroadcastDates = broadcastDateCopy.sort(function (
            left: any,
            right: any
        ) {
            return moment.utc(left.airDate).diff(moment.utc(right.airDate));
        });
        return this.sortedBroadcastDates;
    }

    getMinAirDate() {
        for (let i = 0; i < this.model.broadcastAirDates.length; i++) {
            if (i == 0) {
                this.minAirDate = this.model.broadcastAirDates[0].airDate;
            } else if (this.model.broadcastAirDates.length > 1) {
                if (this.minAirDate > this.model.broadcastAirDates[i].airDate) {
                    this.minAirDate = this.model.broadcastAirDates[i].airDate;
                }
            } else {
                this.minAirDate = this.model.broadcastAirDates[i].airDate;
            }
        }
    }

    checkBroadcastTimes() {
        for (let i = 0; i < this.model.broadcastAirDates.length; i++) {
            if (
                this.model.broadcastAirDates[i].startTime >
                this.model.broadcastAirDates[i].endTime
            ) {
                this.broadcastTimesInvalid = true;
            }
        }
    }
    checkBroadcastRange(broadcast: any) {
        //providing time to object syncup
        setTimeout(() => {
            if (broadcast.endTime < broadcast.startTime) {
                this.broadcastTimesInvalid = true;
            } else {
                this.broadcastTimesInvalid = false;
            }
        }, 300);
    }

    cntr = 0;
    openDatepicker($event: any, myForm: any) {
        if (this.cntr == 0 && $event.length == 2) {
            this.getSortedBroadcastDates();
            const minBroadcastDate = moment(
                _.first(this.sortedBroadcastDates).airDate
            );
            //let maxBroadcastDate = moment(_.last(this.sortedBroadcastDates).airDate);
            const startDate = moment($event[0]).format("MM/DD/YYYY");
            const endDate = moment($event[1]).format("MM/DD/YYYY");

            const validminDate = minBroadcastDate.isBetween(
                startDate,
                endDate,
                "days",
                "[]"
            );
            //let validmaxDate = maxBroadcastDate.isBetween(startDate, endDate, "days", '[]');
            this.calendar_broadcast_minDate = new Date();

            if (validminDate) {
                this.log.trace("Create Events --> DateRange selected");
                this.model.crewWorkStartDate = moment(startDate)
                    .format()
                    .toString();
                this.model.crewWorkEndDate = moment(endDate)
                    .format()
                    .toString();

                //this.calendar_broadcast_maxDate = new Date(endDate);
            } else {
                this.bsInlineRangeValue = [];
                this.cntr = +1;
            }
        } else {
            this.cntr = 0;
        }
        this.addBroadcastDateforBudget();
    }

    dateRangePickerFactory($event: any, startDate: any, endDate: any) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    openSearchModal() {
        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(NbcBudgetCode, modalOptions);
        this.bsModalRef.content.onSync.subscribe((result: any) => {
            this.model.budgetCode = result;
        });
    }

    penCompassSearchModal(size: any) {
        this.log.trace("Opening modal");
        // var modalInstance = $modal.open({
        //     templateUrl: "/scripts/app/areas/events/views/event/compassModal.html",
        //     controller: "modalCompass",
        //     backdrop: "static",
        //     keyboard: false,
        //     size: size
        // });

        // modalInstance.result.then( (broadcastDate) {
        //     log.trace("Modal dismissed with: " + broadcastDate);
        //     console.log(broadcastDate);
        //     var index = this.model.broadcastAirDates.length - 1;
        //     this.model.broadcastAirDates[index] = broadcastDate;
        //     this.onBroadcastDateChange(this.model.broadcastAirDates[index], broadcastDate.startTime, broadcastDate.endTime);
        // },  () {
        //     log.trace("modal dismissed without value");
        // });
    }

    getEventManagers() {
        _.forEach(this.eventManagers, (managers) => {
            this.subs.sink = this._eventManagerService
                .eventManager([managers.id])
                .subscribe(
                    (result) => {
                        // this.log.trace("got results: " + JSON.stringify(result));
                        managers.values = result.reference;
                    },
                    (err) => {
                        this.log.error("got an error: ");
                    },
                    () => {}
                );
        });
    }

    setEventTitle() {
        if (this.model.awayTeam && this.model.homeTeam) {
            const awayTeam = this.model.awayTeam,
                homeTeam = this.model.homeTeam;
            this.model.eventName = awayTeam.name + " @ " + homeTeam.name;
        }
    }

    setSeasonId() {
        if (!this.model.partOfSeason) {
            this.model.seasonId = null;
        }
    }

    checkOutOfSeasonRangeBroadcastDates() {
        _.forEach(this.model.broadcastAirDates, (value, index) => {
            this.checkOutOfSeasonRange(index);
        });
    }

    checkOutOfSeasonRange($index: any) {
        if (
            this.model.broadcastAirDates &&
            this.model.broadcastAirDates[$index].airDate &&
            this.model.partOfSeason === true &&
            !!this.model.seasonId &&
            this.selectedSeason.startDate &&
            this.selectedSeason.endDate &&
            (this.selectedSeason.startDate.valueOf() >
                this.model.broadcastAirDates[$index].airDate.valueOf() ||
                this.selectedSeason.endDate.valueOf() <
                    this.model.broadcastAirDates[$index].airDate.valueOf())
        ) {
            this.model.broadcastAirDates[$index].outOfSeasonRange = true;
        } else {
            this.model.broadcastAirDates[$index].outOfSeasonRange = false;
        }
    }

    addBroadcastDateforBudget() {
        this.model.crewWorkStartDate;
        this.model.crewWorkEndDate;
        const currentDate = new Date(this.model.crewWorkStartDate);
        const endDate = new Date(this.model.crewWorkEndDate);
        const budgethours: any = [];

        while (currentDate <= endDate) {
            const budgethour = this.model.financials.budgetedHours.find(
                (obj: any) =>
                    moment(obj.broadCastDate).format("MM/DD/YYYY") ==
                    moment(currentDate).format("MM/DD/YYYY")
            );
            if (!budgethour) {
                const newDate = {
                    broadCastDate: _.cloneDeep(
                        moment(currentDate).format("YYYY-MM-DDT00:00:00")
                    ),
                    hours: null,
                };
                budgethours.push(newDate);
            } else {
                budgethours.push(_.cloneDeep(budgethour));
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        this.model.financials.budgetedHours = _.cloneDeep(budgethours);
    }

    validateDateRange() {
        const startCrewDate = this.model.crewWorkStartDate;
        const endCrewDate = this.model.crewWorkEndDate;

        this.difference = moment(endCrewDate).diff(startCrewDate, "days");
    }

    clearBroadcastType() {
        if (!this.model.isSportsEventWebsitePublished) {
            this.model.broadcastType = null;
            this.model.tapeDate = null;
        }
    }

    /**************************** START: TIERED RATECARD CODE ***************************************/

    validateTierConfigResponse(
        newTierConfig: any,
        changedDateindex: any,
        oldNetworkId: any
    ) {
        //probably will get called on first attempt
        if (!this.tierConfig) {
            this.isInitialLoad = false;
            this.tierConfig = newTierConfig;
            this.currentTierStatus = _.cloneDeep(newTierConfig.tierId);
            this.tierNetworks = this.tierConfig.tierGroupNetworks;
            this.model.tier = this.tierConfig.tierId;
            this.model.networkGroupId = this.tierConfig.networkGroupId;
            this.model.broadcastAirDates[changedDateindex].network =
                this.getNetwork(
                    this.model.broadcastAirDates[changedDateindex].networkId
                );
        } else {
            // all cases will begin if we have the data from earlier transaction

            if (this.tierConfig && newTierConfig) {
                //if new and old tier are same
                if (
                    this.tierConfig.networkGroupId !==
                        newTierConfig.networkGroupId &&
                    this.tierConfig.tierId === 0 &&
                    newTierConfig.tierId === 0
                ) {
                    this.tierConfig = newTierConfig;
                    this.tierNetworks = newTierConfig.tierGroupNetworks;
                    this.model.networkGroupId = this.tierConfig.networkGroupId;
                    this.model.eventTierSetup.tierId =
                        this.model.eventTierSetup.tierId;
                }

                //case 1: Broadcast Network changes it to a different network which is designated to a different Tierd Grouping,  System must display message,
                if (
                    this.tierConfig.isTierEnabled &&
                    newTierConfig.isTierEnabled &&
                    this.tierConfig.networkGroupId !=
                        newTierConfig.networkGroupId
                ) {
                    const msg =
                        "You are changing Event to another Tiered Grouping, do  you wish to proceed?";
                    // this.showValidationModal("Tiered Rate Card", msg, "Proceed", "Cancel");
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        newTierConfig,
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );

                    return;
                }

                // case 2: Network changes to a different network which is designated to a Non-Tiered Event,  System must display message,
                //"You are changing from a Tiered to a Non-Tiered Event, do  you wish to proceed?"
                else if (
                    this.tierConfig.isTierEnabled &&
                    !newTierConfig.isTierEnabled
                ) {
                    const msg =
                        "You are changing from a Tiered to a Non-Tiered Event, do  you wish to proceed?";
                    //this.showValidationModal("Tiered Rate Card", msg, "Proceed", "Cancel");
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        newTierConfig,
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );

                    return;
                }

                // case 3:  Broadcast Network, and changes to a different network which is designated to Tierd Grouping,  System must display message,
                //"You are changing from a Non-Tiered to a Tiered Event, do  you wish to proceed?"
                else if (
                    !this.tierConfig.isTierEnabled &&
                    newTierConfig.isTierEnabled
                ) {
                    const msg =
                        "You are changing from a Non-Tiered to a Tiered Event, do  you wish to proceed?";
                    //this.showValidationModal("Tiered Rate Card", msg, "Proceed", "Cancel");
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        newTierConfig,
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );

                    return;
                }
                if (
                    !this.tierConfig.isTierEnabled &&
                    !newTierConfig.isTierEnabled &&
                    this.tierConfig.networkGroupId !=
                        newTierConfig.networkGroupId
                ) {
                    this.tierConfigChangeWorkflow(newTierConfig);
                    return;
                } else if (
                    (!this.tierConfig.isTierEnabled &&
                        !newTierConfig.isTierEnabled) ||
                    (this.tierConfig.isTierEnabled &&
                        newTierConfig.isTierEnabled)
                ) {
                    this.tierConfigChangeWorkflowForNonTier(newTierConfig);
                    return;
                }
            }
        }
    }

    tierConfigChangeWorkflow(newTierConfig: any) {
        //clearing broadcasting networks
        for (let i = 0; i < this.model.broadcastAirDates.length; i++) {
            //must maintain first date
            if (i === this.PRIMARY_DATE_INDEX) {
                this.model.broadcastAirDates[i].network = this.getNetwork(
                    this.model.broadcastAirDates[i].networkId
                );
                continue;
            }

            this.model.broadcastAirDates[i].networkId = "";
            this.model.broadcastAirDates[i].network = null;
        }
        this.currentTierStatus = _.cloneDeep(newTierConfig.tierId);

        //update local store with latest config
        this.tierConfig = newTierConfig;
        this.tierNetworks = newTierConfig.tierGroupNetworks;
        this.model.tier = this.tierConfig.tierId;
        this.model.networkGroupId = this.tierConfig.networkGroupId;
        this.findPrimaryBroadcastingDate();
    }

    tierConfigChangeWorkflowForNonTier(newTierConfig: any) {
        //update local store with latest config
        for (let i = 0; i < this.model.broadcastAirDates.length; i++) {
            //must maintain first date
            if (i === this.PRIMARY_DATE_INDEX) {
                this.model.broadcastAirDates[i].network = this.getNetwork(
                    this.model.broadcastAirDates[i].networkId
                );
                continue;
            }
        }
        this.currentTierStatus = _.cloneDeep(newTierConfig.tierId);
        this.tierConfig = newTierConfig;
        this.tierNetworks = newTierConfig.tierGroupNetworks;
        this.model.tier = this.tierConfig.tierId;
        this.model.networkGroupId = this.tierConfig.networkGroupId;
        this.findPrimaryBroadcastingDate();
    }

    cancelTransactionWorkflow(changedDateindex: any, oldNetworkId: any) {
        //reset the network value
        if (this.model.broadcastAirDates[changedDateindex]) {
            this.model.broadcastAirDates[changedDateindex].networkId =
                oldNetworkId;
            this.model.broadcastAirDates[changedDateindex].network =
                this.getNetwork(oldNetworkId);
            this.findPrimaryBroadcastingDate();
        }
    }

    /*
     * Generic  to show the modal. All parameters are customizable per need
     */
    showValidationModal(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any
    ) {
        this.log.trace("showValidationErrorModal");

        //if (confirmText == null)
        //  confirmText = "Continue"

        const initialState: any = {
            self: this,
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText || "Continue",
            cancelText: !canUpdate ? "Close" : cancelText || undefined,
            hideConfirm: !canUpdate,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe(() => {
            callback(args);
            // this.findPrimaryBroadcastingDate();
        });
        this.bsModalRef.content.onRejection.subscribe(() => {
            cancelCallback(...cancelargs);
        });
    }

    onDateChangeStarted() {
        this.broadCastdateBeingChanged = true;
    }
    onCalenderhidden(str: any) {
        this.broadCastdateBeingChanged = false;
    }
    SetModelValueAirDate(index: any, $event: any) {
        const defaultStartTime = "07:00 AM";
        const defaultEndTime = "03:00 PM";

        if (!_.isUndefined($event)) {
            this.model.broadcastAirDates[index].airDate = moment(
                moment($event).format("MM/DD/YYYY")
            )
                .format()
                .toString();
            if (!this._clonedBroadcastAirDates)
                this._clonedBroadcastAirDates = [];
            this._clonedBroadcastAirDates.splice(
                index,
                1,
                this.model.broadcastAirDates[index]
            );
            //let date = moment($event).format('MM/DD/YYYY');
            //if (!this.model.broadcastAirDates[index].startTime) {
            //  this.model.broadcastAirDates[index].startTime = moment(date + ' ' + defaultStartTime).utc().local().toDate();
            //  this.model.broadcastAirDates[index].airDate = moment(date + ' ' + defaultStartTime).utc().local().toDate();
            //}
            //if (!this.model.broadcastAirDates[index].endTime) {
            //  this.model.broadcastAirDates[index].endTime = $event = moment(date + ' ' + defaultEndTime).utc().local().toDate();
            //}
            //this.defaultDate = $event
        }

        const dates = this.getSortedBroadcastDates();

        //if (!_.isEmpty(dates)) {
        //  //this.sortedBroadcastDates = _.sortBy(this.model.broadcastAirDates, (dateObj) => {
        //  //  return new Date(dateObj.airDate);
        //  //  return moment.utc(left.timeStamp).diff(moment.utc(right.timeStamp))
        //  //});
        //  this.sortedBroadcastDates = _.cloneDeep(this.model.broadcastAirDates);
        //  this.sortedBroadcastDates .sort(function (left, right) {
        //    return moment.utc(left.airDate).diff(moment.utc(right.airDate))
        //  });
        //}
        return true;
    }

    SetModelValueTapDate($event: any) {
        this.model.tapeDate = $event;
    }

    findPrimaryBroadcastingDate() {
        this.PRIMARY_DATE_INDEX = null;
        this.broadCastdateBeingChanged = false;
        let foundNbcNetwork = false;
        const dates = _.cloneDeep(this.model.broadcastAirDates);

        let sortedDates: any[] = [];
        if (!_.isEmpty(dates)) {
            //sortedDates = _.sortBy(this.model.broadcastAirDates, (dateObj) => {
            //  return new Date(dateObj.airDate);
            //});
            sortedDates = this.getSortedBroadcastDates();
        }

        const firstNBCBroadcastingIndex = _.findIndex(
            sortedDates,
            (airdate) => {
                if (airdate.network) {
                    return airdate.network.description === "NBC";
                }
                return false;
            }
        );
        if (firstNBCBroadcastingIndex >= 0) {
            foundNbcNetwork = true;
        }

        if (firstNBCBroadcastingIndex >= 0) {
            this.PRIMARY_DATE_INDEX = _.findIndex(
                this.model.broadcastAirDates,
                {
                    airDate: sortedDates[firstNBCBroadcastingIndex].airDate,
                }
            );
        }
        if (
            (!this.PRIMARY_DATE_INDEX || this.PRIMARY_DATE_INDEX < 0) &&
            !foundNbcNetwork
        ) {
            let isPrimaryFound = false;
            let i = 0;
            while (!isPrimaryFound && i < sortedDates.length) {
                if (
                    _.has(sortedDates[i], "network") &&
                    !_.isEmpty(sortedDates[i].network)
                ) {
                    //need to find the corrosponding date from broadcast array

                    if (_.has(sortedDates[i], "airDate")) {
                        this.PRIMARY_DATE_INDEX = _.findIndex(
                            this.model.broadcastAirDates,
                            {
                                airDate: sortedDates[i]["airDate"],
                            }
                        );
                    } else {
                        this.PRIMARY_DATE_INDEX = _.findIndex(
                            this.model.broadcastAirDates,
                            {
                                "network.description":
                                    sortedDates[i].network.description,
                            }
                        );
                    }
                    // this.PRIMARY_DATE_INDEX = i;
                    isPrimaryFound = true;
                }
                i = i + 1;
            }
        }
        if (
            this.PRIMARY_DATE_INDEX == null &&
            !_.isEmpty(this.model.broadcastAirDates) &&
            this.model.broadcastAirDates.length > 0
        ) {
            this.PRIMARY_DATE_INDEX = _.findIndex(
                this.model.broadcastAirDates,
                (dateObj: any) => dateObj.airDate === sortedDates[0].airDate
            );
        }
        //this.PRIMARY_DATE_INDEX = this.PRIMARY_DATE_INDEX + 1;
    }

    ngOnInit(): void {
        this._activatedRoute.params.subscribe((params) => {
            this.model.eventTypeId = params["id"];
        });
        this._sharedData.setCss("event");
        this.defaultDate = new Date();
        this.getEventType();
        this.getSeasons();
        this.getEventNetworks();
        this.getEventManagers();
        this.checkEventType();
        this.addBroadcastDate();
        this.getNonTieredNetworks();
        this.getAllTierNetworkSetup();
        this.VenueNameTypeahead();
        this.AwayTeamTypeahead();
        this.HomeTeamTypeahead();

        // this fix bug ui of datapicker when scrool page or change size of window and datapicker is load, dont touch
        this.document
            ?.querySelector("modal-container")
            ?.addEventListener("scroll", function () {});
        window.addEventListener("resize", () => {});
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
