import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, Observable, tap } from "rxjs";
import { TimeZoneHelperService } from "./timezonehelper.service";
import { HttpMonitorService } from "src/app/Core/services/http-monitor.service";

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {
    constructor(private tzHelper: TimeZoneHelperService,
        private loadingService: HttpMonitorService
    ) {}

    private totalRequests = 0;
    private excludePatterns: any = [
                /api\/ping$/,
                /workSchedule/,
                /positions\/summary/,
                /positions\/employeeType/,
                /positions\/rateCode/    
            ];
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.body instanceof FormData) {
        } else {
            if (request.body) this.removeEmpty(request.body);
            const headers = new HttpHeaders({
                Z_Client_TimeZone: this.tzHelper.getTimezone(),
                Z_Client_Offset: this.tzHelper.getTimezoneOffset(),
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache",
            });

            request = request.clone({
                headers: headers,
            });
        }
        if (this.excludeUrlProcessing(request.url)) {
            return next.handle(request);
        }
        const started = Date.now();
        this.totalRequests++;
        
        this.loadingService.setLoading(true);

        this.loadingService.setShowLoader(true);
        this.loadingService.setShowLoader(true);
        
        return next.handle(request).pipe(
            tap((event) => {
                if (event.type === 4) {
                    // HttpResponse
                    const elapsed = Date.now() - started;
                    this.loadingService.logTime(request.url, elapsed);
                }
            }),
            finalize(() => {
                this.totalRequests--;

                if (this.totalRequests === 0) {
                    this.loadingService.setLoading(false);
                    this.loadingService.setShowLoader(false);
                }
            })
        );
    }

    removeEmpty = (obj: any) => {
        Object.keys(obj).forEach((k) => {
            if (typeof obj[k] === "boolean") return obj;
            (obj[k] &&
                typeof obj[k] === "object" &&
                this.removeEmpty(obj[k])) ||
                (!obj[k] && obj[k] !== undefined && delete obj[k]);
        });
        return obj;
    };
    excludeUrlProcessing(url) {
        const urlMatches = this.excludePatterns.some((pattern) =>
            pattern.test(url)
        );
        return urlMatches || false;
    }
}
