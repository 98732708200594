import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";

@Injectable({
    providedIn: "root",
})
export class networkGroupService {
    constructor(
        private log: NGXLogger,
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService
    ) {}

    /*
     * Generic method to return the collection of the tier network setup
     */
    allsetups(): Observable<any> {
        this.log.trace("tierratecard: get allsetup");
        return this.http
            .get(this._apiroot.baseUrl + "tierratecard/networkgroup/allsetups")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    get(networkGroupId: any): Observable<any> {
        this.log.trace("tierratecard: get allsetup");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/setup/" +
                    networkGroupId
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getNonTieredNetworks(): Observable<any> {
        this.log.trace("non-associated network");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/unassociatednetworks"
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getAllNetworks(): Observable<any> {
        this.log.trace("get a distinct list of networks");
        return this.http
            .get(this._apiroot.baseUrl + "admin/network/allnetworks")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    tieredNetworkGroups(): Observable<any> {
        this.log.trace("tierratecard: get all networkGroups");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/tieredNetworkGroups"
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    //needtocheck
    // + networkGroupId + "/networkPublishInfo"

    getPublishInfo(networkGroupId: any): Observable<any> {
        this.log.trace("tierratecard: get allsetup");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/" +
                    networkGroupId +
                    "/networkPublishInfo",
                null
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    manageEffectiveDate(
        networkGroupId: any,
        publishInfo: any
    ): Observable<any> {
        this.log.trace("tierratecard: manage effective date");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/manageeffectivedate/" +
                    networkGroupId,
                publishInfo
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    publishNetworkGroup(
        networkGroupId: any,
        publishInfo: any
    ): Observable<any> {
        this.log.trace("tierratecard: publish network group");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/publish/" +
                    networkGroupId,
                publishInfo
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    upsert(networkGroup: any): Observable<any> {
        this.log.trace("networkGroupService: upsert");
        return this.http
            .post(
                this._apiroot.baseUrl + "tierratecard/networkgroup/upsert",
                networkGroup
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    associateEventTypeWithTier(
        networkGroupId: any,
        tierId: any,
        draggedElement: any
    ): Observable<any> {
        this.log.trace("associate event type with tier");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "tierratecard/networkgroup/" +
                    networkGroupId +
                    "/" +
                    tierId +
                    "/associateEventTypeWithTier",
                draggedElement
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
