import { Component, OnInit, OnDestroy } from "@angular/core";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { FormGroup, FormControl, FormArray, NgForm } from "@angular/forms";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import { Observable, Subscriber, observable, Subject } from "rxjs";
import { mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { PositionService } from "src/app/Shared/Services/position.service";
import { AddressService } from "src/app/Shared/Services/address.service";
import * as _ from "lodash";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { SkillsService } from "src/app/Shared/Services/skills.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalAddNewContactComponent } from "../component/addContact/modal-add-new-contact";
import { Router } from "@angular/router";

@Component({
    selector: "contact-search",
    templateUrl: "./contactsearch.component.html",
})
export class ContactSearchComponent implements OnInit, OnDestroy {
    constructor(
        private _eventTypeService: EventTypeService,
        private _employeeTypeService: EmployeeTypeService,
        private _venueService: VenueService,
        private _positionService: PositionService,
        private _positionService1: PositionService,
        private _addressService: AddressService,
        private _contactService: ContactService,
        private _seasonService: SeasonService,
        private _eventService: EventService,
        private _skillService: SkillsService,
        private _modalService: BsModalService,
        private _router: Router,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();

    bsModalRef?: BsModalRef;
    // Vars
    showVenue = true;
    hasSearchResults = false;
    emailRegex = RemotesConstants.regex.email;

    venueObserver: Observable<any[]> | undefined;
    positionObserver$: Observable<any[]> | undefined;
    crewedPositionObserver$: Observable<any[]> | undefined;
    countryObservable: Observable<any[]> | undefined;
    seasonObservable: Observable<any[]> | undefined;
    eventObservable: Observable<any[]> | undefined;
    skillObservable$: Observable<any[]> | undefined;

    status: any = {
        open: false,
    };

    eventPositions: any | undefined;

    accordionInfo: any = {
        workHistory: {
            isOpen: false,
        },
        personal: {
            isOpen: false,
        },
    };

    radii: any = [
        {
            id: "1",
            description: "1 mile",
        },
        {
            id: "5",
            description: "5 miles",
        },
        {
            id: "10",
            description: "10 miles",
        },
        {
            id: "25",
            description: "25 miles",
        },
        {
            id: "50",
            description: "50 miles",
        },
        {
            id: "100",
            description: "100 miles",
        },
        {
            id: "200",
            description: "200 miles",
        },
        {
            id: "250",
            description: "250 miles",
        },
    ];

    searchLocation = "";

    locations = [
        {
            id: "1",
            description: "Venue",
        },
        {
            id: "2",
            description: "Location",
        },
    ];
    pageSizeList: any[] = [10, 25, 50, 100];
    eventTypes = [];
    employeeTypes = [];
    unions = [
        {
            id: "1",
            description: "Union",
        },
        {
            id: "2",
            description: "Non Union",
        },
        {
            id: "3",
            description: "Any",
        },
    ];
    statuses = [
        {
            id: "1",
            description: "Active",
        },
        {
            id: "2",
            description: "Inactive",
        },
        {
            id: "3",
            description: "Any",
        },
    ];
    sortList = [
        { name: "Last Name A-Z", predicate: "LastName" },
        { name: "Last Name Z-A", predicate: "-LastName" },
        { name: "Location", predicate: "City" },
    ];
    sort = "lastName";
    sidebarSearch = false;
    states: any[] | undefined;
    countries = {};
    contactQuery: any;
    contacts: any;
    modalOpen = false;

    accordionStatus = false;

    positionSearchTerm: any | undefined;
    crewPositionSearchTerm: any | undefined;
    venueSearchTerm: any | undefined;
    seasonSearchTerm: any | undefined;
    eventSearchTerm: any | undefined;
    skillSearchTerm: any | undefined;
    // Public Methods

    getContactQuery() {
        return {
            currentPage: 1,
            pageSize: 10,
            firstName: "",
            middleName: "",
            lastName: "",
            positions: [],
            crewedPositions: [],
            skills: [],
            limit: 500,
            // isActive: new triStateAnswerFactory.TriStateAnswer("Active", "Inactive", true, false),
            eventType: {
                id: 0,
                name: "",
            },
            employeeType: {
                id: 0,
                description: "",
            },
            seasonId: {
                id: 0,
                description: "",
            },
            season: {
                id: 0,
                name: "",
            },
            eventId: {
                id: 0,
                description: "",
            },
            event: {
                id: 0,
                description: "",
            },
            radius: null,
            venue: null,
            email: "",
            phone: "",
            sso: "",
            perNer: "",
            union: {
                id: "3",
                description: "",
            },
            distance: "1",
            location: "1",
            countryCode: "",
            city: "",
            state: "",
            searchLocation: "",
            dob: "",
            ssnLastFour: "",
            workedWith: 0,
            sortBy: "LastName",
        };
    }
    validatePhoneEmail() {
        //if (!dummy.phone && !dummy.email) {
        //  invalidatePhoneOrEmail = true;
        //} else {
        //  invalidatePhoneOrEmail = false;
        //}
    }

    contactFilter(item) {
        //if (!contactSearch.email) {
        //  contactSearch.email = "";
        //}
        //if (!contactSearch.phone) {
        //  contactSearch.phone = "";
        //}
        //if ((angular.isDefined(item.lastName) && angular.lowercase(item.lastName) === angular.lowercase(contactSearch.lastName)) ||
        //  (angular.isDefined(angular.lowercase(item.email) === angular.lowercase(contactSearch.email))) ||
        //  item.phone.replace(/[^\w\s]/gi, "") === contactSearch.phone.replace(/[^\w\s]/gi, "")) {
        //  return true;
        //}
        //return false;
    }
    setPositionEventTerm($event) {
        this.positionSearchTerm = $event;
    }
    seSkillEventTerm($event) {
        this.skillSearchTerm = $event;
    }
    setCrewPositionEventTerm($event) {
        this.crewPositionSearchTerm = $event;
    }
    isOneFieldEntered() {
        if (
            this.contactQuery.firstName === "" &&
            this.contactQuery.lastName === "" &&
            !_.isObject(this.contactQuery.livesNearVenue) &&
            this.contactQuery.city === "" &&
            this.contactQuery.positions.length === 0 &&
            this.contactQuery.crewedPositions.length === 0 &&
            this.contactQuery.skills.length === 0 &&
            (_.isUndefined(this.contactQuery.seasonId.id) ||
                this.contactQuery.seasonId.id === 0) &&
            (this.contactQuery.event === "" ||
                this.contactQuery.event.description === "") &&
            !_.isObject(this.contactQuery.venue) &&
            this.contactQuery.email === "" &&
            this.contactQuery.phone === "" &&
            this.contactQuery.union.id === "3" &&
            this.contactQuery.sso === "" &&
            this.contactQuery.perNer === "" &&
            this.contactQuery.countryCode === "" &&
            this.contactQuery.city === "" &&
            this.contactQuery.state === "" &&
            (!this.contactQuery.eventType ||
                this.contactQuery.eventType.id === 0) &&
            (!this.contactQuery.season || this.contactQuery.season.id === 0) &&
            ((this.contactQuery.searchLocation === "" &&
                (!this.contactQuery.radius ||
                    this.contactQuery.radius?.id === 0)) ||
                (this.contactQuery.searchLocation === "" &&
                    (this.contactQuery.radius ||
                        this.contactQuery.radius?.id > 0))) &&
            this.contactQuery.searchLocation === "" &&
            (!this.contactQuery.employeeType ||
                this.contactQuery.employeeType.id === 0)
        ) {
            return false;
        }

        return true;
    }

    //Fix for typeahead not clearing out the eventID Object.
    checkEvent() {
        if (this.contactQuery.event === "") {
            this.contactQuery.eventId = {};
        }
    }

    paginate1($event) {
        this.log.trace(this.contactQuery.sortBy);
        this.log.trace("paginate1");
        setTimeout(() => this.search(), 300);
    }

    paginate($event) {
        this.log.trace("paginate");
        this.log.trace($event);
        this.search();
    }

    submit(contactSearchForm: NgForm, contactQuery: any) {
        this.log.trace("submit");
        //this.search();
    }

    searchPage(pageSize) {
        this.contactQuery.pageSize = pageSize;
        this.search();
    }

    search() {
        this.log.trace("search");
        this.checkSeason();
        this.checkEvent();
        //contactSearchForm.submitted = true;
        const workHistoryOpened = this.accordionInfo.workHistory.isOpen;
        const personalOpened = this.accordionInfo.personal.isOpen;
        // if (contactSearchForm.valid && this.isOneFieldEntered()) {

        if (this.isOneFieldEntered()) {
            this.log.trace("further more");

            this.log.trace("contactSearch -> search");
            this._contactService
                .search(this.contactQuery)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (data) => {
                        this.log.trace("contactSearch -> search received");
                        this.contacts = data;
                        //this.contactQuery.currentPage = data.currentPage;
                    },
                    error: (msg) => {
                        this.log.showError("Error Getting Location: " + msg);
                    },
                    complete: () => {
                        this.hasSearchResults = true;
                    },
                });
        } else {
            if (!this.isOneFieldEntered()) {
                this.log.showError(
                    "Please fill in at least one search criterion."
                );
            }
        }
    }

    isWorkHistoryOpenChange() {
        this.accordionInfo.workHistory.isOpen =
            !this.accordionInfo.workHistory.isOpen;
        return this.accordionInfo.workHistory.isOpen;
    }
    isWorkPersonalInfoOpenChange() {
        this.accordionInfo.personal.isOpen =
            !this.accordionInfo.personal.isOpen;
        return this.accordionInfo.personal.isOpen;
    }
    cancel() {
        this.log.trace("cancel");
        location.reload();
        ////contactSearchForm.submitted = false;
        //this.hasSearchResults = false;
        ////$emit("hideAlert");
        //this.contactQuery = this.getContactQuery();
        //this.positionSearchTerm = "";
        //this.venueSearchTerm = "";
        //this.crewPositionSearchTerm = "";
        //location.reload();

        //this.accordionInfo = {
        //  workHistory: {
        //    isOpen: false
        //  },
        //  personal: {
        //    isOpen: false
        //  }
        //};
    }
    restartSearch() {
        this.log.trace("restartSearch");
        this.contactQuery.currentPage = 1;
        //   this.search();
    }
    loadEventPositions(term) {
        //var defer = $q.defer();
        //positionService.autoComplete(term).success(function (data) {
        //  defer.resolve(data.reference);
        //}).error(function (data) {
        //  defer.reject(data);
        //});
        //return defer.promise;
    }

    loadSkills(term) {
        this.log.trace("loadSkills");
        //var defer = $q.defer();

        //skillsService.autoComplete(term).success(function (data) {
        //  defer.resolve(data.reference);
        //}).error(function (data) {
        //  defer.reject(data);
        //});
        //return defer.promise;
    }
    searchSeason(term) {
        //return seasonServices.searchByName(term).then(function (res) {
        //  this.log.trace("response: " + JSON.stringify(res));
        //  return res.data.reference;
        // });
    }
    onSeasonSelect(season) {
        //contactQuery.event = null;
        //contactQuery.seasonId.id = season.id;
        //contactQuery.seasonId.description = season.name;
    }

    onEventSelect(event) {
        //contactQuery.eventId.id = event.id;
        //contactQuery.eventId.description = event.description;
    }

    checkSeason() {
        if (this.contactQuery.season === "") {
            this.contactQuery.seasonId.id = 0;
            this.contactQuery.seasonId.description = "";
        }
    }
    searchEvent(seasonId, term) {
        //return eventServices.filterEventsBySeasonAndName(seasonId, term).then(function (res) {
        //  this.log.trace("response: " + JSON.stringify(res));
        //  return res.data.reference;
        //});
    }
    searchVenue(term) {
        //return venueService.search(encodeURIComponent(term)).then(function (res) {
        //  this.log.trace("response: " + JSON.stringify(res));
        //  return res.data.reference;
        //});
    }

    openAddContactModal(contact) {
        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
        };

        this.bsModalRef = this._modalService.show(
            ModalAddNewContactComponent,
            modalOptions
        );
        //this.bsModalRef.content.onSync.subscribe(contact => {
        //  let url = "Contacts/Contacts/Detail/" + contact.id;
        //  //let url = "Contacts/Contacts/Detail/" + contact.id;

        //  this._router.navigate([url], { queryParams: { mode: "new"} });

        //})
        //this.log.trace("Opening modal");
        //modalOpen = true;

        //$modal.open({
        //  templateUrl: "/scripts/app/areas/contacts/views/contacts/modalAddContact.html",
        //  controller: "modalAddContactController",
        //  controllerAs: "modalAddContactCtr",
        //  backdrop: "static",
        //  keyboard: false,
        //  resolve: {
        //    contact: function () { return contact; },
        //    openNewWindow: function () { return false; }

        //  }
        //}).result.then(function () {
        //  modalOpen = false;
        //  $emit("hideAlert");
        //}, function () {
        //  modalOpen = false;
        //  $emit("hideAlert");
        //});
    }

    locationType = function (showVenue) {
        //if (showVenue) {
        //  showVenue = true;
        //} else {
        //  showVenue = false;
        //}
    };

    getDialCodeFromCountry = function (code) {
        //for (var i = 0; i < countries.length; i++) {
        //  if (countries[i].code === code) {
        //    return countries[i].dial_code;
        //  }
        //}
    };

    getEventTypes() {
        this.log.trace("contactSearch -> getEventTypes:fetching data");
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.eventTypes = data.reference;
                    this.log.trace("contactSearch -> event types received");
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    onEventTypeSelected(evtType) {
        this.log.trace(evtType);
        this.contactQuery.eventType = evtType;
    }

    getEmployeeTypes() {
        this.log.trace("contactSearch -> get Employee Type:fetching data");
        this._employeeTypeService
            .getAllEmployeeTypes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.employeeTypes = data.reference;
                    this.log.trace("contactSearch -> employee types received");
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    getCountries() {
        this.log.trace("contactSearch -> get countries:fetching data");
        this._addressService
            .getCountries()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.countries = data.reference;
                    this.log.trace("contactSearch -> countries received");
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    onEmployeeTypeSelected(empType) {
        this.contactQuery.employeeType = empType;
    }

    setCountryCode($item) {
        this.contactQuery.countryCode = $item.isoCode;
        //if ($item.isoCode === "US") {
        //    states = $item.states;
        //} else {
        //    states = [];
        //}
    }

    // Events
    //$watch("contactQuery.seasonId", function() {
    //  contactQuery.seasonId.id = contactQuery.season.id;
    //});

    //$watch("contactQuery.eventId", function() {
    //  contactQuery.eventId.id = contactQuery.event.id;
    //});

    // Init

    venueTypeahead() {
        this.venueObserver = new Observable((observer: Subscriber<string>) => {
            // Runs on every search
            observer.next(this.venueSearchTerm);
            observer.complete();
        }).pipe(
            switchMap((token: string) => {
                takeUntil(this.componentDestroyed$);
                return this._venueService.autocomplete(
                    encodeURIComponent(token)
                );
            })
        );
    }

    positionTypeahead() {
        this.positionObserver$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.positionSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._positionService.autocomplete(token);
            })
        );
    }

    crewedpositionTypeahead() {
        this.crewedPositionObserver$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.crewPositionSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._positionService1.autocomplete(token);
            })
        );
    }

    seasonTypeahead() {
        this.seasonObservable = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.seasonSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._seasonService.autocomplete(token);
            })
        );
    }

    eventsTypeahead() {
        this.eventObservable = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.eventSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._eventService.autocompleteUsingSeason(
                    this.contactQuery.seasonId.id,
                    token
                );
            })
        );
    }

    skillsTypeahead() {
        this.skillObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.skillSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._skillService.autocomplete(token);
            })
        );
    }

    onVenueSelected(venue) {
        this.log.trace(venue);
        this.contactQuery.venue = venue;
    }

    onPositionSelected(position) {
        this.log.trace(this.contactQuery.positions);
        if (_.isEmpty(this.contactQuery.positions)) {
            this.contactQuery.positions = [];
        }

        this.contactQuery.positions = position;
        this.positionSearchTerm = "";
    }

    onCrewedPositionSelected(position) {
        this.log.trace(this.contactQuery.crewedPositions);
        if (_.isEmpty(this.contactQuery.crewedPositions)) {
            this.contactQuery.crewedPositions = [];
        }

        this.contactQuery.crewedPositions = position;
        this.crewPositionSearchTerm = "";
    }

    onCountrySelected(country) {
        this.contactQuery.countryCode = country.item.isoCode;
    }

    onSeasonSelected(season) {
        this.log.trace(season);
        this.contactQuery.season = season.item;
        this.contactQuery.seasonId.id = season.item.id;
    }

    onEventSelected(event) {
        this.log.trace(event);
        this.contactQuery.event = event.item;
        this.contactQuery.eventId.id = event.item.id;
    }

    onSkillSelected(skill) {
        this.log.trace(this.contactQuery.skills);
        if (_.isEmpty(this.contactQuery.skills)) {
            this.contactQuery.skills = [];
        }

        this.contactQuery.skills = skill;
        this.skillSearchTerm = "";
    }

    onRadiusSelected(radius) {
        //   this.contactQuery.radius = radius;
    }

    removeTag(property, item) {
        this.log.trace(this.contactQuery[property]);

        const list = _.filter(this.contactQuery[property], function (tag: any) {
            return tag.id !== item.id;
        });
        this.contactQuery[property] = list;
    }

    changeTypeaheadLoading($event) {}

    ngOnInit() {
        this.getEventTypes();
        this.getEmployeeTypes();
        this.contactQuery = this.getContactQuery();
        // contactQuery.limit = 500;
        this.getCountries();

        //observable
        this.venueTypeahead();
        this.positionTypeahead();
        this.crewedpositionTypeahead();
        this.seasonTypeahead();
        this.eventsTypeahead();
        this.skillsTypeahead();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
