import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { TemplateService } from "src/app/Shared/Services/template.service";
import { from, Subject } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalAddTemplateComponent } from "../components/addtemplate/modal-add-template.component";
import { takeUntil } from "rxjs/operators";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { BaseUrlHelperService } from "../../Shared/helper/baseUrlHelper.service";
import { SharedDataService } from "../../Shared/sharedData.service";

@Component({
    selector: "template-list",
    templateUrl: "./template-list.component.html",
    //styleUrls: ["../../../assets/Content/app/areas/admin/admin.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class TemplateListComponent implements OnInit, OnDestroy {
    constructor(
        private _sharedData: SharedDataService,
        private _templateService: TemplateService,
        private _eventTypeService: EventTypeService,
        private _baseUrl: BaseUrlHelperService,
        public _modalService: BsModalService,
        private log: NbcLoggerService
    ) {}

    bsModalRef?: BsModalRef;
    componentDestroyed$: Subject<boolean> = new Subject();
    isCloneInProgress = false;
    editMode = false;
    reorderButtonInactive = true;
    sortProcessing = false;
    selectedFilter: any | null;
    filter: any = {
        isActive: "all",
        searchText: "",
        search: {},
        templateTypes: [
            { name: "All Templates", order: "0", value: "all" },
            { name: "Active", order: "1", value: "active" },
            { name: "Inactive", order: "2", value: "inactive" },
        ],
    };
    defaultOrderBy = "name";
    defaultReverseSort = false;
    sort: any = {
        orderByField: this.defaultOrderBy,
        reverseSort: this.defaultReverseSort,
        orderFieldname: this.defaultOrderBy,
    };
    templateList: any;
    eventTypes: any[] = [];

    onFilterSelect() {
        if (this.filter.isActive == "active") {
            this.selectedFilter = true;
        } else if (this.filter.isActive == "inactive") {
            this.selectedFilter = false;
        } else {
            this.selectedFilter = null;
        }
    }
    getTemplates() {
        this._templateService
            .getTemplates("", "")
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.templateList = result["reference"];
                },
                error: (err) => {
                    this.log.error("got an error: " + err?.error?.message);
                    this.log.showError(
                        "Unable to load templates at this time. Please try again later."
                    );
                },
            });
    }

    searchTemplates() {
        this.filter.search = { name: this.filter.searchText };
    }

    clearSearch() {
        this.filter.searchText = "";
        this.filter.search = {};
    }

    sortByColumn(column) {
        if (this.sort.orderByField === column) {
            if (this.sort.reverseSort === true) {
                this.sort.orderByField = this.defaultOrderBy;
                this.sort.reverseSort = this.defaultReverseSort;
            } else {
                this.sort.reverseSort = true;
            }
        } else {
            this.sort.orderByField = column;
            this.sort.reverseSort = false;
        }

        this.sort.orderFieldname = this.sort.reverseSort
            ? "-" + this.sort.orderByField
            : this.sort.orderByField;
        return;
    }

    openPositionTemplateModal(templateObj?) {
        const initialState: any = {
            self: this,
            templateObj: templateObj || {},
            eventTypes: this.eventTypes,
            authorName: window.preload.user.userName,
            isCloning: templateObj ? true : false,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false
        };

        this.bsModalRef = this._modalService.show(
            ModalAddTemplateComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            if (result) {
                window.location.href =
                    this._baseUrl.basePath + "templates/" + result.id;
            }
            //this.getTemplates();
        });
    }

    getEventTypes() {
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result: any) => {
                    this.eventTypes = result.reference;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                },
            });
    }

    ngOnInit() {
        this._sharedData.setCss("template");
        this.getTemplates();
        this.filter.isActive = "active";
        this.selectedFilter = true;
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
