import { NgModule } from "@angular/core";
import { NbcSharedModule } from "../Shared/shared.module";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { TabsModule } from "ngx-bootstrap/tabs";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

import { ModalModule } from "ngx-bootstrap/modal";

import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "../Core/core.module";

import { TierRateCardRouterModule } from "./tierratecard-routing.module";
import { TierRateCardComponent } from "./Component/tierratecard.component";
import { tierSetupWizardController } from "../Shared/Services/tierSetupWizardController.service";
import { tierPositionWizardTemplateComponent } from "./Component/tierPositionWizardTemplate.component";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ModalUpsertTierRateCardComponent } from "./Component/modal-upsert-tier-rate-card/modal-upsert-tier-rate-card.component";
import { DragulaModule } from "ng2-dragula";
import { TierRatecardImpactAnalysisComponent } from "./tier-ratecard-impact-analysis.component";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        NbcSharedModule,
        RouterModule,
        TabsModule.forRoot(),
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        JwBootstrapSwitchNg2Module,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        HttpClientModule,
        CoreModule,
        DragulaModule.forRoot(),
        TierRateCardRouterModule.forChild(),
    ],
    exports: [],
    declarations: [
        TierRateCardComponent,
        tierPositionWizardTemplateComponent,
        ModalUpsertTierRateCardComponent,
        TierRatecardImpactAnalysisComponent,
    ],
    providers: [tierSetupWizardController],
})
export class TierRateCardModule {}
