<div>
    <div class="modal-header">
        <h3 class="modal-title">Crew Work Schedule Report</h3>
        <button type="button" class="btn-close"
                (click)="cancel()" aria-hidden="true">
        </button>
        <div *ngIf="showAlertMessage">
            <alert *ngFor="let alert of alerts"
                   type="{{alert.type}}"
                   close="closeAlert()">{{alert.msg}}</alert>
        </div>
    </div> 
    <div class="modal-body">
        <div class="grid-table">
            <div class="grid-content-row"
                 *ngIf="showDepartmentRowAll">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DepartmentRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showDepartmentEditRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col">
                    <div class="row">
                        <div class="col-md-4"
                             *ngFor="let department of airStatusReport.department">
                            <input type="checkbox"
                                   [(ngModel)]="department.value"
                                   [checked]="department.value"
                                   (change)="stateChanged(department.value,department,'DepartmentRow')">
                            {{department.name}}
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DepartmentRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showDepartmentTagRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col">
                    <div class="col-md-4"
                         *ngFor="let department of airStatusReportInfo.department">
                        <span class="filter-tag"
                              *ngIf="department.value">{{department.name}} <a><i class="fa fa-times-circle"
                                   (click)="removeTag(department,'DepartmentRow')"></i></a></span>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DepartmentRow')"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                (click)="exportReport()">Export</button>
    </div>
</div>
<app-nbc-file-download></app-nbc-file-download>
