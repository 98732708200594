
<div>
  <div>
    <span us-spinner spinner-key="modalSpinner"></span>
    <div class="modal-header">
      <h5 class="modal-title ng-binding">IDM Results</h5>
      <button type="button" class="btn-close" (click)="closeModal()"> </button>
    </div>
  </div>
  <div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped" style="margin-bottom:0">
              <thead class="dark-header">
                <tr>
                  <th style="width:10px"></th>
                  <th class="col-md-2">First Name</th>
                  <th class="col-md-2">Last Name</th>
                  <th class="col-md-2">Email (Primary)</th>
                  <th class="col-md-1">SSO</th>
                  <th class="col-md-1">Account Status</th>
                  <th class="col-md-1">Type</th>
                  <th class="col-md-2">Business Segment</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of contacts;let optIndex=index;">
                  <td style="width:10px">
                    <input type="radio" [(ngModel)]="contact.isSelected"
                    id="rbSelectedContact{{optIndex}}"
                    name="rbSelectedContact{{optIndex}}"
                    (change)="selectContact(optIndex)" />
                  </td>
                  <td class="col-md-2">{{contact.firstName}} </td>
                  <td class="col-md-2">{{contact.lastName}} </td>
                  <td class="col-md-2">{{contact.email}} </td>
                  <td class="col-md-1">{{contact.sso}} </td>
                  <td class="col-md-1">{{contact.accountStatus}} </td>
                  <td class="col-md-1">{{contact.type}} </td>
                  <td class="col-md-2">{{contact.businessSegment}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer text-right" style="top:unset">
    <button class="btn btn-default" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" id="btnCreateContact" (click)="syncContact(selectedContact)">Next</button>
  </div>
</div>
