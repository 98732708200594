import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { Router } from "@angular/router";
import { positionDetails } from "../Model/positionDetails.model";
import * as _ from "lodash";
import { OnboardingColorsService } from "../../../Shared/Services/Onboarding/onboarding-colors.service";
import { Subject } from "rxjs/internal/Subject";

@Component({
    selector: "app-model-positionrequest",
    templateUrl: "./model-positionrequest.component.html",
})
export class ModelPositionrequestComponent implements OnInit {
    //bsModalRef?: BsModalRef;
    constructor(
        public bsModalRef: BsModalRef,
        private _modalService: BsModalService,
        private _onboardingColorsService: OnboardingColorsService,
        private _router: Router,
        private log: NbcLoggerService
    ) {}

    defaultOrderBy = "";
    defaultReverseSort = false;
    public onRejection!: Subject<any>;

    //sort = {
    //  orderByField: this.defaultOrderBy,
    //  reverseSort: this.defaultReverseSort
    //};

    orderHeader = "";
    isAscOrder = true;

    //global variables for windows
    childwindows: any = [];
    newWindow = null;
    positionrequest: any[] = [];
    searchTerm = "";
    colors: any | undefined;

    ngOnInit(): void {
        this.positionDetails = _.cloneDeep(this.positionrequest);
        this.colors = this._onboardingColorsService.getColors();
        this.positionDetails.forEach((item: any) => {
            item.requestStatusColor = this.colors.requestStatus.get(
                item.onboardingStatusNo.toString()
            );
        });
        this.onRejection = new Subject();
    }

    positionDetails: any[] = [];

    sort(headerName: string) {
        // this.isAscOrder = !this.isAscOrder;
        this.orderHeader = headerName;
    }

    //sortByColumn(column) {
    //  //debugger;
    //  //this.sort.orderByField = "name";
    //  if (this.sort.orderByField === column && this.sort.reverseSort !== this.defaultReverseSort) {
    //    this.positionDetails.sortBy = "";
    //    this.sort.orderByField = "";
    //    this.sort.reverseSort = this.defaultReverseSort;
    //  } else if (this.sort.orderByField === column) {
    //    this.recentlyViewed.sortBy = "-" + column;
    //    this.sort.reverseSort = !this.sort.reverseSort;
    //  } else {
    //    this.recentlyViewed.sortBy = column;
    //    this.sort.orderByField = column;
    //    this.sort.reverseSort = this.defaultReverseSort;
    //  }
    //  this.getRecentlyViewed();
    //}

    closeModal() {
        this.onRejection.next(false);
        this.bsModalRef.hide();
    }

    //opening popup window
    openDrupalOnboardingModal(positionListItem: any) {
        if (positionListItem.eventName && positionListItem.requestNo !== null) {
            //console.log(positionListItem);
            const positionRequestNo = positionListItem.requestNo; //hrOnboardingItem.requestNumber;
            const crewRegNo = positionListItem.crewRegistrationNo; //hrOnboardingItem.crewRegistrationNo;
            const drupalUrl = window.preload.uisettings.drupalHMModal;
            // const onboardingStatus = positionListItem.positionRequest;

            // Checking position request and onboardingstatus

            const url =
                drupalUrl +
                "%26field_position_request_number%3D" +
                positionRequestNo +
                "%26field_status_crew_reg_number%3D" +
                crewRegNo;
            const settings =
                "top=125,left=280,width=800,height=775,location=0,titlebar=0";

            const newWindow = window.open(url, crewRegNo, settings);
            console.log(this.childwindows);
            for (let i = 0; i < this.childwindows.length; i++) {
                if (
                    this.childwindows[i] &&
                    this.childwindows[i] == newWindow &&
                    this.childwindows[i].opened
                ) {
                    this.childwindows.splice(i, 1);
                    this.childwindows[i].close();
                }
            }
            this.childwindows[this.childwindows.length] = newWindow;
            if (newWindow) {
                newWindow.focus();
            }

            return false;
        } else {
            this.log.showError("The Position is not Requested.");
            return false;
        }
    }
}
