import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class ContactSharedDataService {
    constructor(private log: NGXLogger) {}
    private contact = new BehaviorSubject<any>([]);
    private editMode = new BehaviorSubject<string>("");

    //public isEditModeEnabled!: Observable<boolean>;
    private isContactEditEnabled: Subject<boolean> = new Subject<boolean>();
    public readonly contactEditEnabled: Observable<boolean> =
        this.isContactEditEnabled.asObservable();

    private isContactDetailUpdated: Subject<boolean> = new Subject<boolean>();
    public readonly contactEditUpdated: Observable<boolean> =
        this.isContactDetailUpdated.asObservable();

    private isHrVerficationEditEnabled: Subject<boolean> =
        new Subject<boolean>();
    public readonly HrVerficationEditEnabled: Observable<boolean> =
        this.isHrVerficationEditEnabled.asObservable();

    public fnNameReceived!: Observable<any>;
    private fnName: Subject<any> = new Subject<any>();

    setContact(updatedContact: any) {
        this.log.trace(
            "ContactSharedDataService - contact details received: " +
                updatedContact.contacId
        );
        this.contact = new BehaviorSubject<any>([]);
        this.contact.next(updatedContact);
    }

    getContact(): Observable<any> {
        return this.contact.asObservable();
    }

    setEditMode(isEditMode: string) {
        this.editMode = new BehaviorSubject<string>("");
        this.log.trace("ContactSharedDataService -setEditMode: " + isEditMode);
        this.editMode.next(isEditMode);
    }

    getEditMode(): Observable<string> {
        return this.editMode.asObservable();
    }

    setFnCall(callDetails: any) {
        this.fnNameReceived = this.fnName.asObservable();
        //this.fnName = new BehaviorSubject<string>(methodName);
        this.fnName.next(callDetails);
    }

    getFnCall(): Observable<any> {
        return this.fnName.asObservable();
    }

    isContactEditModeEnabled(isEnabled: boolean) {
        this.log.trace("isContactEditModeEnabled -*******: " + isEnabled);
        this.isContactEditEnabled.next(isEnabled);
    }

    isHrVerficationEditModeEnabled(isEnabled: boolean) {
        this.isHrVerficationEditEnabled.next(isEnabled);
    }
    isContactUpdated(isUpdated: boolean) {
        this.isContactDetailUpdated.next(isUpdated);
    }
}
