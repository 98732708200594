import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: "modal-emergency-contact",
    templateUrl: "./modal-emergency-contact.component.html",
})
export class ModalEmergencyContactComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    //subject to transmit the results
    public onSync!: Subject<any>;
    public onCancelSync!: Subject<any>;

    contacts: any[] = [];
    items: any = {};
    emergencyContacts: any = {};

    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.onCancelSync = new Subject();

        this.contacts = this.emergencyContacts;
    }
}
