import { Component, OnInit, Input } from '@angular/core';
import { NbcLoggerService } from 'src/app/Shared/logger/nbc-logger.service';
 

@Component({
  selector: 'contact-list',
  templateUrl: './contactlist.component.html'
})

export class ContactListComponent implements OnInit {
  constructor(private log: NbcLoggerService) { }


  @Input() contacts: any | undefined;


  ngOnInit() {
    this.log.trace(this.contacts);
  }
}
