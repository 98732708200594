import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
//import { LiquidCache } from 'ngx-liquid-cache';
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class MinimumWageService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    //@LiquidCache('getMinimumWage')
    getWage(): Observable<any> {
        this.log.debug("fetching wage");
        return this.http.get(this._apiroot.baseUrl + "wage", {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    /*  @LiquidCache('getAllWages')*/
    getAllWages(): Observable<any> {
        this.log.trace("get all wages");
        return this.http
            .get(this._apiroot.baseUrl + "admin/minimumwage/allwages")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    upsertWage(minimumwage): Observable<any> {
        this.log.trace("update wage");
        return this.http
            .post(
                this._apiroot.baseUrl + "admin/minimumwage/upsertwage",
                minimumwage
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
