<ng-template #nbcIdleTemplate>
  <div class="modal-header">
    <h5 class="modal-title">
      Your session is about to expire due to inactivity.
      Any unsaved data will be lost in {{logoutCountdown}} seconds
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        Do you wish to extend your session?
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="reset()">Extend</button>
    <button class="btn btn-primary" (click)="closeSession()">Log Off</button>
  </div>

</ng-template>
