import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ContactService } from "src/app/Shared/Services/contact.service";

@Component({
    selector: "[backup-contact]",
    templateUrl: "backup-contact-popover.component.html",
    //styleUrls: [
    //  "../../../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.None,
})
export class BackupContactPopoverComponent implements OnInit, OnDestroy {
    constructor(
        private _contactService: ContactService,
        private _eventBus: EventBusService,
        private log: NbcLoggerService
    ) {}
    @Input() listItemId!: any;
    @Input() BackupItems!: any[];
    @Input() eventPosition!: any;
    @Input() showPopover!: boolean;

    @Output() BackupItemsChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() showPopoverChange: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    componentDestroyed$: Subject<boolean> = new Subject();
    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm: string | undefined;

    contactTypeahed() {
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._contactService.searchByName(token);
            })
        );
    }

    onContactSelected($event) {
        const contact = $event.item;
        //this.BackupItems.push(contact);
        this.contactSearchTerm = "";
        const data = {
            id: this.listItemId,
            contact: contact,
            action: "New",
        };
        this._eventBus.emit(
            new EmitEvent(
                "backupPopOver:contactSelected",
                "onBackupItemChanged",
                data
            )
        );
        this.close();
    }

    onBackupRemoved($event, $index) {
        const removedObj = this.BackupItems[$index];
        //  this.BackupItems.splice($index, 1);
        const data = {
            id: this.listItemId,
            contact: removedObj,
            action: "Delete",
        };
        this._eventBus.emit(
            new EmitEvent(
                "backupPopOver:contactSelected",
                "onBackupItemChanged",
                data
            )
        );

        $event.stopPropagation();
    }

    onAssignPrimaryBackupContact($event, $index) {
        if (this.eventPosition?.status?.description !== "Confirmed") {
            const confirmedObj = this.BackupItems[$index];
            confirmedObj.backupAction = "Delete";
            const data = {
                id: this.listItemId,
                contact: confirmedObj,
                action: "Update",
            };
            this._eventBus.emit(
                new EmitEvent(
                    "backupPopOver:contactSelected",
                    "onAssignPrimaryBackupContact",
                    data
                )
            );
        }
        $event.stopPropagation();
    }

    close() {
        document.getElementsByTagName("body")[0].click();
    }

    ngOnInit() {
        this.contactTypeahed();
    }

    ngOnDestroy() {
        this.showPopoverChange.emit(false);
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
