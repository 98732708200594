<div class="modal-header">
  <h4 class="modal-title">Contact Comment</h4>
  <button type="button" class="btn-close" (click)="cancel()"> </button>
</div>
<div class="modal-body">
  <nbc-alert></nbc-alert>
  <div class="row">
    <div class="col-md-12">
      <textarea maxlength="50"
                [(ngModel)]="comments"
                placeholder="Leave a comment" class="form-control"
                appInputRestriction="restrictChars"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-primary" (click)="saveComment()">Save Comment</button>
  <button class="btn btn-default" (click)="cancel()">Cancel</button>
</div>
