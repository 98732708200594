import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-nbc-file-download",
    templateUrl: "./nbc-file-download.component.html",
})
export class NbcFileDownloadComponent implements OnInit {
    constructor() {}

    data!: string;
    fileType!: string;
    fileName!: any[];
    fileExtension!: string;
    mimetype!: string;

    ngOnInit(): void {}

    download() {
        switch (this.fileType?.toLocaleLowerCase()) {
            case "excel":
                this.fileExtension = ".xlsx";
                this.mimetype =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;

            case "text":
                this.fileExtension = ".txt";
                this.mimetype = "application/octet-stream";
                break;

            case "pdf":
                this.fileExtension = ".pdf";
                this.mimetype = "application/pdf";
                break;
        }
        const blob = new Blob([this.data], {
            type: this.mimetype,
        });
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.target = "_blank";
        a.download = this.fileName + this.fileExtension;
        document.body.appendChild(a);
        a.click();
    }
}
