import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-thank-you",
    templateUrl: "./thank-you.component.html",
})
export class ThankYouComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
