<div>
  <div class="modal-header">
    <h4 class="modal-title ng-binding">{{modalTitle}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div [innerHtml]="modalMessage"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-end">
    <div class="row">
      <div class="col-sm-12 p-0">
        <span [hidden]="!cancelText || cancelText.length<=0"><button type="button" class="btn btn-default"  (click)="cancel()">{{cancelText}}</button></span>
        <span [hidden]="hideConfirm"><button type="button" class="btn btn-primary" (click)="confirm()">{{confirmText}}</button></span>
      </div>
    </div>
  </div>
</div>
