<div  *ngIf="gridData?.items?.length > 0" class="grid-table">
  <div class="hdr-grid-row">
    <div class="hdr-grid-col text-center" style="width:5px;">
    </div>
    <div class="hdr-grid-col psd sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'positionStartDate'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="positionStartDate"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('positionStartDate')">
        Position Start Date
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'positionStartDate'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == true"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == false"></i>
          </span>
        </span>
      </a>
    </div>

    <!--<div class="hdr-grid-col req sticky-header"
       [ngClass]="gridData.query.sortByColumn === 'requestNumber'?'hdr-grid-col-sorted':''">
    <a href="javascript:void(0)"
       data-sort-column-name="requestNumber"
       class="sortable-column text-nowrap"
       (click)="sortOnColumn('requestNumber')">
      Request #
      <span class="th-sort">
        <span *ngIf="gridData.query.sortByColumn === 'requestNumber'">
          <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
          <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
        </span>
      </span>
    </a>
  </div>-->

    <div class="hdr-grid-col en sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'eventName'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="eventName"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('eventName')">
        Event Name
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'eventName'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col dr sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'dateRequested'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="dateRequested"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('dateRequested')">
        Date Requested
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'dateRequested'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col hm sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'hiringManager'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="hiringManager"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('hiringManager')">
        Hiring Manager
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'hiringManager'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col pm sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'productionManager'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="productionManager"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('productionManager')">
        Production Manager
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'productionManager'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col con sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'contact'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="contact"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('contact')">
        Contact
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'contact'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col pt sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'positionTypeRequest'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="positionTypeRequest"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('positionTypeRequest')">
        Position Type
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'positionTypeRequest'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col pn sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'positionName'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="positionName"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('positionName')">
        Position Name
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'positionName'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col sso sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'sso'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="sso"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('sso')">
        SSO
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'sso'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col es sticky-header"
         [ngClass]="gridData.query.sortByColumn === 'employeeStatus'?'hdr-grid-col-sorted':''">
      <a href="javascript:void(0)"
         data-sort-column-name="employeeStatus"
         class="sortable-column text-nowrap"
         (click)="sortOnColumn('employeeStatus')">
        Employee Status
        <span class="th-sort">
          <span *ngIf="gridData.query.sortByColumn === 'employeeStatus'">
            <i class="fa fa-caret-up" *ngIf="gridData.query.reverseSort == false"></i>
            <i class="fa fa-caret-down" *ngIf="gridData.query.reverseSort == true"></i>
          </span>
        </span>
      </a>
    </div>

    <div class="hdr-grid-col">
      &nbsp;
    </div>

    <div class="hdr-grid-col text-center i9review sticky-header">
      I9 Verify
    </div>

    <div class="hdr-grid-col text-center sap sticky-header">
      SAP Position #
    </div>

    <div class="hdr-grid-col text-center doc sticky-header">
      Documents
    </div>

    <div class="hdr-grid-col text-center taf sticky-header">
      TAF Submitted
    </div>

    <div class="hdr-grid-col text-center can sticky-header">
      LAST ACTION
    </div>
  </div>

  <!-- Grid Body -->

  <div *ngFor="let listItem of gridData.items; let index=index;" class="grid-row" id="row-{{listItem.requestNumber}}"
       ng-attr-data-id="{{listItem.requestNumber}}">
    <!--<div class="row grid-animate grid-row">-->
    <div class="grid-col grid-col text-center grid-col-15 {{listItem.requestStatusColor}}"
         for="checkbox-{{listItem.requestNumber}}">
      <div class="tooltips">
        <input type="text" class="onboarding-tooltip-input" />
        <span class="tooltiptext" *ngIf="(listItem.lastUpdatedby!='' && listItem.lastUpdatedDate!='')">
          Last updated on {{listItem.lastUpdatedDate}} by {{listItem.lastUpdatedby}}
        </span>
      </div>
    </div>

    <div class="grid-col text-center grid-col-80">
      <span class="grid-dtl-text">
        {{listItem.positionStartDate}}
      </span>
    </div>

    <!--<div class="grid-col text-center grid-col-80">
    <span class="grid-dtl-text">
      {{listItem.requestNumber}}
    </span>
  </div>-->

    <div class="grid-col text-center grid-col-80">
      <span class="grid-dtl-text">
        <a routerLink="/Events/Event/{{listItem.eventId}}/Crew"
           target="_blank"
           class="btn-link"
           authenticate-user="HR.Request.Retrieve"
           authenticate-command="disableanchor">
          {{listItem.eventName}}
        </a>
      </span>
    </div>

    <div class="grid-col text-center grid-col-80">
      <div class="tooltips">
        <span class="grid-dtl-text">
          {{listItem.requestedDate}}
        </span>
        <span class="tooltiptext text" *ngIf="listItem.requestNumber!=''">Request # {{listItem.requestNumber}}</span>
      </div>
    </div>

    <div class="grid-col grid-col-80">
      <span class="grid-dtl-text">
        {{listItem.hiringManager}}
      </span>
    </div>

    <div class="grid-col grid-col-100">
      <span class="grid-dtl-text">
        {{listItem.productionManager}}
      </span>
    </div>

    <div class="grid-col grid-col-80">
      <span class="grid-dtl-text">
        {{listItem.contactName}}
      </span>
    </div>

    <div class="grid-col grid-col-80">
      <span class="grid-dtl-text" *ngIf="listItem.positionTypeRequest != null">
        {{listItem.positionTypeRequest}}
      </span>
    </div>

    <div class="grid-col text-center grid-col-80">
      <span class="grid-dtl-text">
        {{listItem.positionName}}
      </span>
    </div>

    <div class="grid-col text-center grid-col-80">
      <span class="grid-dtl-text" *ngIf="listItem.sso != 0">
        {{listItem.sso}}
      </span>
    </div>

    <div class="grid-col grid-col-80">
      <span class="grid-dtl-text">
        {{listItem.employeeStatus}}
      </span>
    </div>

    <div class="open-position-modal-el grid-col text-center grid-col-lnk"
         style="background-color:#e6b800"
         data-request-no="{{listItem.requestNumber}}"
         data-crew-registration-no="{{listItem.crewRegistrationNo}}"
         authenticate-user="HR.Request.Update"
         authenticate-command="unbindclickevent"
         (click)="openHrOnboardingPositionModal(listItem)">
      <i class="fa fa-ellipsis-h"></i>
    </div>

    <div class="grid-col grid-col-hlight grid-col-165">
      <nbc-select name="I9Verify_ + {{listItem.requestNumber}}"
                  [(model)]="listItem.i9Verify"
                  authenticate-user="HR.Request.Update"
                  authenticate-command="nbcselectdisabled"
                  [source]="i9VerifyDDl"
                  display="name"
                  value="name"
                  placeHolderDisplay="Select Reason"
                  placeHolderValue="Select Reason"
                  (change)="updateDetails(listItem, 'I9Verify', listItem?.i9Verify?.name)">
      </nbc-select>
    </div>

    <div class="grid-col grid-col-hlight text-center grid-col-80">
      <input type="text"
             [value]="listItem.sapNo != null? listItem.sapNo : '' "
             disabled />
    </div>

    <div class="grid-col grid-col-hlight grid-col-165">
      <select class="form-control input-xs grid-col-165" disabled>
        <option hidden="hidden" value="">Documents</option>
        <option [selected]="listItem.documentType=='Direct Deposit'">Direct Deposit</option>
        <option [selected]="listItem.documentType=='W-4'">W-4</option>
        <option [selected]="listItem.documentType=='Both'">Both</option>
        <option [selected]="listItem.documentType=='NA'">N/A</option>
      </select>
    </div>

    <div class="grid-col text-center grid-col-35">
      <input type="checkbox"
             class="chkdisabled"
             [checked]="listItem.tafSubmitted==true"
             disabled />
    </div>

    <div class="grid-col grid-col-100 grid-col-hlight">
      <span class="grid-dtl-text">
        {{listItem.lastAction}}
      </span>
    </div>
  </div>
</div>
