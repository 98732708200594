import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class OnboardingColorsService {
    requestStatus: { [key: string]: any };

    constructor() {
        this.requestStatus = this.getColors().requestStatus;
    }
    getColorFunc(label: string, property: string) {
        //log.trace("getColorFunc");
      if (label && this.requestStatus[label.toLowerCase()]) {
            if (property) {
                return this.requestStatus[label.toLowerCase()][property];
            }
            return this.requestStatus[label.toLowerCase()].color;
        }
        return "";
    }

    getColors() {
        return {
            requestStatus: {
                "1": { color: "light-gold", hex: "#FFD700" },
                "2": { color: "lightning-yellow", hex: "#FFA500" },
                "3": { color: "sky-blue", hex: "#87CEFA" },
                "5": { color: "light-blue", hex: "#1E90FF" },
                "6": { color: "vivid-violet", hex: "#800080" },
                "7": { color: "grass-green", hex: "#90EE90" },
                "4": { color: "red", hex: "#DC143C" },
                "8": { color: "grey", hex: "#808080" },
                get: this.getColorFunc.bind(this),
            },
        };
    }
}
