<div>
    <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
        <button type="button"
        class="btn-close"
        (click)="cancel()"></button>
    </div>
    <nbc-alert></nbc-alert>
    <form role="form"
          name="upsertTierRateCard"
          novalidate>
        <nbc-modal-alert></nbc-modal-alert>
        <div class="modal-body">
            <div class="row">

                <div class="col-md-12">
                    <div class="col-md-10">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="description"
                                           class="control-label">Rate Card Name</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text"
                                    ngDefaultControl
                                           name="description"
                                           [ngModelOptions]="{standalone: true}"
                                           class="form-control"
                                           placeholder="Enter Rate Card Name"
                                           id="description"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="rateCard.desc"
                                           maxlength="40"
                                           required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="category"
                                           class="control-label">Rate Card Category</label>
                                </div>
                                <div class="col-md-6">
                                    <select>
                                        <option [(ngModel)]="rateCard.positionCategory"
                                                *ngFor="let option of positionCategories"
                                                ngDefaultControl
                                                disabled="!option.selectable"
                                                [selected]="option.isSelected"
                                                [ngModelOptions]="{standalone: true}"
                                                value="{{option.id}}">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-10">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="description"
                                           class="control-label">Job Title</label>
                                </div>
                                <div class="col-md-6">
                                           <input type="text"
                                           class="form-control"
                                           [(ngModel)]="rateCaredpostionTerm"
                                           typeaheadOptionField='description'
                                           maxlength="36"
                                           appInputRestriction="restrictChars"
                                           [ngModelOptions]="{standalone: true}"
                                           [typeaheadAsync]="true"
                                           [typeahead]="loadEventPositions$"
                                           [typeaheadItemTemplate]="customItemTemplate"
                                           ngDefaultControl
                                           [typeaheadMinLength]="2"
                                           [typeaheadWaitMs]=50
                                           placeholder="Position Name"
                                           autocomplete="off"
                                           placeholder='Position Name'
                                           (typeaheadOnSelect)="onPositionSelected($event)"> 
                                    <span>New mapping of Job title will only impact new positions and not existing
                                        positions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-default"
                    (click)="cancel()">Cancel</button>
            <button type="button"
                    *ngIf="isAdd"
                    class="btn "
                    [ngClass] = "isEnable ? 'btn-primary' : 'btn-default'"
                    
                   
                    (click)="disableSave || addUpdateRateCard()">Save</button>
                      <button type="button"
                    *ngIf="!isAdd"
                    class="btn "
                    [ngClass] = "{'btn-primary': isEnable, 'btn-default': !isEnable}"
                    (click)="disableSave || addUpdateRateCard()">Update</button>
        </div>
    </form> 
</div>
<ng-template #customItemTemplate let-model="item"
             let-index="index"><span style="padding-left:10px;">{{model.description}}</span></ng-template>
