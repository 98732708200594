<div style="width:170px;">
  <div class="pad-0 activities-box">
    <div class="popover-opt-list"> 
      <label *ngFor="let workActivity of clonedActivities; let index=index"
             class="popover-opt-itm"
             (click)="onActivityClick(workActivity)">        
        <span class="popover-opt-list-lbl ng-binding" for="{{workActivity.id}}">
          <input type="checkbox"
                 authenticate-user="event.crew.update,event.crewactivity.update"
                 authenticate-command="disabled"
                 data-work-activity-index="{{index}}"
                 value="{{workActivity.id}}"
                 [(ngModel)]="workActivity.isPlanned"
                 id="{{workActivity.id}}"
                 (click)="saveUpsertPositionActivity()"
                 class="popover-action-max-3-checked"
                 [authenticate-disabled]="(selectedActivities>=maxAllowedActivity && workActivity.isPlanned==false) || !isAuthorized" />          
          {{workActivity.code}}
        </span>
        <span class="popover-opt-list-desc ng-binding" for="{{workActivity.id}}">{{workActivity.description}}</span>
      </label>

    </div>
  </div>
  <div class="popover-footer" *ngIf="showSaveCancelBtn">
    <button class="btn btn-default btn-sm webui-popover-cancel-btn"
            authenticate-user="event.crew.update,event.crewactivity.update"
            authenticate-command="disabled" (click)="cancel($event);">
      Cancel
    </button>
    <button class="btn btn-primary btn-sm webui-popover-save-btn"
            authenticate-user="event.crew.update,event.crewactivity.update"
            authenticate-command="disabled"
            (click)="saveActivities()">
      Save
    </button>
  </div>
</div>
