<div class="payroll-timesheet">
  <header id="col-main-hdr">
    <h1><i class="fa fa-money"></i> Payroll</h1>
  </header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li><a href="/Remotes/Payroll" title="Payroll">Payroll</a></li>
      <li>/</li>
      <li>Timesheet</li>
    </ul>
  </nav>
  <nbc-alert></nbc-alert>
  <div class="page-container" authenticate-user="payrolltimesheet.retrieve">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="frozen-header">
            <div class="header-bar clearfix">
              <p class="heading-title float-start">Timesheet</p>
              <div class="float-end" *ngIf="timesheets && timesheets.length > 0" authenticate-user="payrolltimesheet.update">
                <div>
                  <button type="button" name="btnApprove" class="btn btn-success" [disabled]="!isApproveEnabled || !groupUpdate.status" (click)="openPayrollApprovalModal()">Approve</button>
                  <button type="button" name="btnSave" class="btn btn-primary" [disabled]="!isDirty || !isValid || saveDisabled" (click)="saveTimesheet(null)">Save</button>
                  <button type="button" class="btn btn-default" (click)="checkDirtyForm(cancelTimesheetChanges)">Cancel</button>
                  <div class="float-end btn-fullscreen hidden-sm hidden-xs" (click)="fullScreenMode($event);" payroll-fullscreen>
                    <a class="btn btn-default"><i class="fa"></i></a>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-filters">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <a class="btn-link" href="/Remotes/Payroll/upcomingevents/setup/{{event.id}}" target="_blank">{{event.eventName}}</a><span *ngIf="event?.eventTierSetup?.tierId" style="padding-left:20px;font-weight:bold"> T{{event.eventTierSetup.tierId?event.eventTierSetup.tierId:''}}</span>
                  <div class="form-group" style="max-width: 300px">
                    <!--<nbc-select *ngIf="timesheetDates && timecardDisplayDate"
                                [source]="timesheetDates"
                                [(model)]="timecardDisplayDate"
                                [value]="'dateString'"
                                [display]="'displayDate'"
                                (change)="checkDirtyForm(getTimesheets);"
                                modelObject="true">
                    </nbc-select>-->
                    <nbc-dropdown *ngIf="timesheetDates && timecardDisplayDate"
                                  name="timecarddate"
                                  [(model)]="payrolltimecarddate"
                                  [source]="timesheetDates"
                                  display="displayDate" value="dateString"
                                  nbcSize="w-100"
                                  (modelChange)="updatetimecardDate();"
                                  (nbcOnOptionSelect) ="checkDirtyForm(rerouteTimecard);"></nbc-dropdown>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 text-center">
                  <div class="donut-chart-container">
                    <canvas baseChart width="100px" height="100px"
                            [options]="chartOptions"
                            [data]="doughnutChartData"
                            [labels]="doughnutChartLabels"
                            [type]="doughnutChartType"
                            [legend]=false></canvas>
                    <div class="donut-chart-label">
                      <div class="donut-chart-label-txt">
                        <div class="numeral">
                          <strong>{{totalSubmittedTimesheets}} OF {{chartDataLength}}</strong>
                        </div>
                        Activit<span *ngIf="chartDataLength == 1">y</span><span *ngIf="chartDataLength != 1">ies</span>
                      </div>
                    </div>
                  </div>
                  <div class="donut-chart-legend">
                    <div class="donut-chart-legend-itms clearfix d-flex flex-wrap" style="width:350px">
                      <div class="donut-chart-legend-itm donut-chart-status-{{$index}} col-md-6" style="color:{{chartItem.color}}" *ngFor="let chartItem of activityChartData; let $index=$index">
                        <span class="label" style="background-color:{{chartItem.color}}">{{chartItem.value}}</span> {{chartItem.label}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="filter-boxes text-end float-end clearfix">
                    <div *ngFor="let item of employeeTypeFilters; let $index= $index"
                         (click)="searchEmployeeFilter(item,true)"
                         (dblclick)="searchAllEmployeeFilter(item)"
                         class="filter-box"
                         [ngClass]="{ 'selected': item.isSelected, 'disabled': item.isDisabled }">
                      <span *ngIf="item.employeeType.description">{{item.count}}</span>
                      {{item.employeeType.description}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" authenticate-user="payrolltimesheet.update" *ngIf="groupUpdate.status">
                <form role="form" name="payrollBulkUpdateForm" id="payrollBulkUpdateForm" novalidate>
                  <div class="col-md-12">
                    <div class="form-inline form-sort-options">
                      <div class="form-group">
                        <label>Group Update:</label>
                      </div>
                      <div class="form-group">
                        <nbc-select *ngIf="groupUpdate"
                                    [(model)]="groupUpdate.selected"
                                    [source]="groupUpdate.options"
                                    [display]="'name'"
                                    [value]="'name'"
                                    placeHolderDisplay="Select an Action"
                                    placeHolderValue="Select an Action"
                                    (change)="groupUpdate.submitted = false;this.onDemandChangeDetection()"
                                    [nbcRequired]="true">
                        </nbc-select>
                      </div>
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Employee Type'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && !groupUpdate.updatedValues.employeeType}">

                        <nbc-select *ngIf="employeeTypes"
                                    [(model)]="groupUpdate.updatedValues.employeeType"
                                    [source]="employeeTypes"
                                    [display]="'description'"
                                    [value]="'description'"
                                    [nbcRequired]="true">
                        </nbc-select>
                      </div>

                      <!--Old Code commented for US-21236 -->
                      <!--<div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name !== 'Select' && (groupUpdate.selected.name == 'Start' || groupUpdate.selected.name == 'End' || groupUpdate.selected.name == 'Meal Start' || groupUpdate.selected.name == 'Meal End')"
                           [ngClass]="{'has-error' : groupUpdate.submitted && !groupUpdate.updatedValues.updatedTime}">
                        <nbc-date-time id="groupUpdateTime"
                                       type="hours"
                                       format="24hr"
                                       dateDisplayFormat="ampm"
                                       placeholder="{{groupUpdate.selected.name + ' Time'}}"
                                       name="groupUpdateTime"
                                       defaultDate="{{timeCardDate}}"
                                       [(model)]="groupUpdate.updatedValues.updatedTime"></nbc-date-time>
                      </div>-->
                      <!--Code done by US 21236-->
                      <div class="form-group"
                           *ngIf="groupUpdate?.selected?.name === 'Time'">

                        <!--<form name="eventTsGroupForm" #eventTsGroupForm="ngForm" novalidate>-->
                        <div style="padding:4px;">
                          <div class="row timesheet-list" style="width:400px">
                            <div class="col-md-2" style="min-width: 93px;">
                              <nbc-date-time id="updatedStartTime"
                                             type="hours"
                                             format="24hr"
                                             dateDisplayFormat="ampm"
                                             placeholder="Start"
                                             (modelChange)="onDemandChangeDetection();"
                                             name="updatedStartTime"
                                             defaultDate="{{timeCardDate}}"
                                             [(model)]="groupUpdate.updatedValues.updatedStartTime"></nbc-date-time>

                            </div>
                            <div class="col-md-2" style="min-width: 93px;">
                              <nbc-date-time id="updatedEndTime"
                                             type="hours"
                                             format="24hr"
                                             dateDisplayFormat="ampm"
                                             placeholder="End"
                                             (modelChange)="onDemandChangeDetection();"
                                             name="updatedEndTime"
                                             defaultDate="{{timeCardDate}}"
                                             [(model)]="groupUpdate.updatedValues.updatedEndTime"></nbc-date-time>
                            </div>
                            <div class="col-md-2" style="min-width: 93px;">
                              <nbc-date-time id="updatedMealStartTime"
                                             type="hours"
                                             format="24hr"
                                             dateDisplayFormat="ampm"
                                             placeholder="Meal Start"
                                             (modelChange)="onDemandChangeDetection();"
                                             name="updatedMealStartTime"
                                             defaultDate="{{timeCardDate}}"
                                             [(model)]="groupUpdate.updatedValues.updatedMealStartTime"></nbc-date-time>
                            </div>
                            <div class="col-md-2" style="min-width: 93px;">
                              <nbc-date-time id="updatedMealEndTime"
                                             type="hours"
                                             format="24hr"
                                             dateDisplayFormat="ampm"
                                             placeholder="Meal End"
                                             (modelChange)="onDemandChangeDetection();"
                                             name="updatedMealEndTime"
                                             defaultDate="{{timeCardDate}}"
                                             [(model)]="groupUpdate.updatedValues.updatedMealEndTime"></nbc-date-time>

                            </div>
                          </div>
                        </div>
                        <!--</form>-->
                      </div>


                      <!--Code End-->
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Contract Code'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && (groupUpdate.updatedValues.contractCode == null || groupUpdate.updatedValues.contractCode === '')}">
                        <nbc-select *ngIf="groupUpdate"
                                    [nbcRequired]="true"
                                    [(model)]="groupUpdate.updatedValues.contractCode"
                                    [source]="contractCodes"
                                    [display]="'name'"
                                    [value]="'name'"
                                    [nbcAddBlank]="true"
                                    authenticate-user="payrolltimesheet.update"
                                    name="ContractCodeSelect"
                                    (change)="contractCodeChanged()">
                        </nbc-select>
                      </div>
                      <div class="form-group" *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Contract Code'">
                        <nbc-select *ngIf="groupUpdate && groupUpdate.updatedValues.contractCode  !== null && groupUpdate.updatedValues.contractCode !== '' && groupUpdate.updatedValues.contractCode.ruleVariances"
                                    [(model)]="groupUpdate.updatedValues.ruleVariance"
                                    [source]="groupUpdate.updatedValues.contractCode.ruleVariances"
                                    [display]="'description'"
                                    [nbcAddBlank]="true"
                                    [value]="'description'"
                                    name="RulesvarienceSelect">
                        </nbc-select>
                      </div>
                      <div class="form-group" *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Contract Code'">
                        <button class="btn btn-default"
                                (click)="openPayTransactionsModal(groupUpdate, true)"
                                *ngIf="groupUpdate.updatedValues.contractCode !== null && groupUpdate.updatedValues.contractCode !== ''">
                          Add Transaction
                        </button>
                        <span *ngIf="groupUpdate.updatedValues.payTransactions.length > 0">
                          {{groupUpdate.updatedValues.payTransactions.length}} transaction(s)
                        </span>
                      </div>
                      <div class="form-group group-update-ngtags" *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Contract Code' && groupUpdate.updatedValues.contractCode && groupUpdate.updatedValues.contractCode != '' && groupUpdate.updatedValues.contractCode.indicators">
                        <payroll-indicators [(searchTerm)]="indicatorSearchTerm"
                                            [(indicatorList)]="groupUpdate.updatedValues.contractCode.indicators"
                                            [tagDisplay]="'description'"
                                            [tagId]="displayId"
                                            [(tagName)]="groupUpdate.updatedValues.indicators"
                                            [(tags)]="groupUpdate.updatedValues.indicators"
                                            [placeholder]="'Add Indicator'"
                                            [nbcAuthenticateUser]="'payrolltimesheet.update'"
                                            (nbcOnOptionSelect)="onBulkIndicatorSelected($event)"></payroll-indicators>
                      </div>
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'GL Account'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && !groupUpdate.updatedValues.glAccount}">
                        <input [(ngModel)]="glAccountSearchTerm"
                               appInputRestriction="numbersonly"
                               name="glAccountGU"
                               placeholder="GL Account"
                               [typeahead]="glAccountObservable$"
                               [typeaheadAsync]="true"
                               [typeaheadOptionsLimit]="10"
                               [isAnimated]="true"
                               [typeaheadWaitMs]="200"
                               typeaheadMinLength="5"
                               [required]="true"
                               class="form-control"
                               (typeaheadOnSelect)="onGLAccountValueSelected($event)">
                      </div>
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Default Scheduled Hours'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && groupUpdate.updatedValues.defaultScheduledHours > 24 }">
                        <input type="number"
                               [required]="true"
                               class="form-control form-control-sm input-number bulk-scheduled-hours"
                               placeholder="Default Scheduled Hours"
                               max="24" min="1"
                               name="defaultScheduledHours"
                               appInputRestriction="restrictChars"
                               nbc-decimal
                               decimals="2"
                               pattern="/^((?:[1-9]|1[0-9]|2[0-3])(?:\.[0-9]{1,2})?|24)$/"
                               [(ngModel)]="groupUpdate.updatedValues.defaultScheduledHours"
                               maxlength="5"
                               autocomplete="off" />
                      </div>

                      <!--For Job Code selection starts here-->
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Job Code'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && !groupUpdate.updatedValues.jobCode}">
                        <nbc-select *ngIf="groupUpdate"
                                    [(model)]="groupUpdate.updatedValues.jobCode"
                                    [source]="jobCodesGroups"
                                    [display]="'code'"
                                    [value]="'code'"
                                    name="jobCodeSelect"
                                    placeHolderDisplay="Select Job Code"
                                    placeHolderValue="Select Job Code"
                                    (change)="filterRateCodes();"
                                    [nbcRequired]="true"
                                    class="bulk-contract-code">
                        </nbc-select>
                      </div>
                      <div class="form-group"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Job Code'"
                           [ngClass]="{'has-error' : groupUpdate.submitted && !groupUpdate.updatedValues.rate}">
                        <nbc-dropdown *ngIf="groupUpdate.updatedValues && rateCodes"
                                      placeholder="Select Rate card"
                                      [(model)]="groupUpdate.updatedValues.rate"
                                      [source]="rateCodes"
                                      display="rateCode" value="rateCode"
                                      name="rateCodeSelect"
                                      [nbcRequired]="true"
                                      nbcSize="w-100"
                                      class="bulk-contract-code"></nbc-dropdown>
                      </div>
                      <div class="form-group"
                           [ngClass]="{'has-error': (6000 < groupUpdate.updatedValues.customRate || (groupUpdate.submitted && !groupUpdate.updatedValues.customRate))}"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Job Code' && groupUpdate.updatedValues.rate === '00'">
                        <input type="text"
                               [required]="true"
                               name="customRateEntry"
                               nbc-decimal
                               decimals="4"
                               autocomplete="off"
                               [(ngModel)]="groupUpdate.updatedValues.customRate"
                               placeholder="Enter Custom Rate"
                               class="form-control form-control-sm" />
                      </div>
                      <!--Job Code selection ends here-->
                      <!--Custom Rate starts here-->
                      <div class="form-group"
                           [ngClass]="{'has-error': (6000 < groupUpdate.updatedValues.customRate || (groupUpdate.submitted && !groupUpdate.updatedValues.customRate))}"
                           *ngIf="groupUpdate.selected && groupUpdate.selected.name === 'Rate'">
                        <input type="text"
                               required
                               name="customRateEntry"
                               nbc-decimal
                               decimals="4"
                               autocomplete="off"
                               [(ngModel)]="groupUpdate.updatedValues.customRate"
                               placeholder="Enter Custom Rate"
                               class="form-control form-control-sm" />
                      </div>
                      <!--Custom Rate Ends here-->
                      <div class="form-group">
                        <button type="button" class="btn btn-primary" (click)="groupUpdateBtn()" *ngIf="groupUpdate.selected && groupUpdate.selected.name !== 'Select an Action'">
                          Update
                        </button>
                        <button type="button" class="btn btn-default" (click)="resetGroupUpdate();">Cancel</button>
                      </div>                    
                    </div>
                  </div>
                </form>
              </div>
              <div class="row" *ngIf="filter.showTravelOptions">
                <div class="col-md-12">
                  <div class="form-group form-inline filter-checkboxes">
                    <label class="text-uppercase">Filter:</label>
                    <label><input class="checkbox" type="checkbox" name="travel" [(ngModel)]="timesheetQuery.isTravel" (change)="checkDirtyForm(restartSearch)" /> Travel</label>
                    <label><input class="checkbox" type="checkbox" name="nonTravel" [(ngModel)]="timesheetQuery.isNonTravel" (change)="checkDirtyForm(restartSearch)" /> Non-Travel</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="unfrozen-pane">
            <div class="page-content pad-0 clear-outer-spacing">
              <div class="view-options-wrapper">

                <ng-container *ngIf="isFullScreenMode">
                  <!--unfortunate duplication due to time constraint-->
                  <div class="view-options" *ngIf="timesheets && timesheets.length > 0">
                    <div class="row">
                      <div class="col-lg-5 col-md-5 text-left form-inline">
                         <div class="form-group">
                           <nbc-page-size [source]='pageSize' [model]="timesheetQuery.pageSize" (nbcValueChange)="searchPage($event, restartSearch)"></nbc-page-size>
                         </div>
                      </div>
                      <div class="col-md-7">
                        <div class="form-group text-end float-end d-flex">
                          <label class="pagination-results ms-auto">
                          <pagination-label pageSize="{{timesheetQuery.pageSize}}"
                                            currentPage="{{timesheetQuery.currentPage}}"
                                            totalRecords="{{totalRecords}}"></pagination-label>
                          </label>
                          <nbc-pagination totalItems="{{totalRecords}}"
                                          itemsPerPage="{{timesheetQuery.pageSize}}"
                                          [(currentPage)]="timesheetQuery.currentPage"
                                          (pageChanged)="checkDirtyForm(getNewPage)"></nbc-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!isFullScreenMode">
                  <div class="view-options" *ngIf="timesheets && timesheets.length > 0">
                    <div class="row">
                      <div class="col-lg-5 col-md-5 text-left form-inline">
                        <div class="form-group">
                          <nbc-page-size [source]='pageSize' [model]="timesheetQuery.pageSize" (nbcValueChange)="searchPage($event,restartSearch)"></nbc-page-size>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div class="form-group text-end float-end d-flex">
                          <label class="pagination-results ms-auto">
                          <pagination-label pageSize="{{timesheetQuery.pageSize}}"
                                            currentPage="{{timesheetQuery.currentPage}}"
                                            totalRecords="{{totalRecords}}"></pagination-label>
                          </label>
                          <nbc-pagination totalItems="{{totalRecords}}"
                                          itemsPerPage="{{timesheetQuery.pageSize}}"
                                          [(currentPage)]="timesheetQuery.currentPage"
                                          (pageChanged)="checkDirtyForm(getNewPage)"></nbc-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>
              <div class="row">
                <div class="col-md-12" style="min-height: 550px">
                  <form name="payrollTimesheetForm" novalidate [hidden]="!timesheets || timesheets.length == 0">
                    <div class="grid-table-container" (scroll)="onScroll($event)">
                      <div class="grid-table">

                        <ng-container *ngIf="isFullScreenMode">
                          <div class="hdr-grid-row frozen-col-header" *ngIf="timesheets && timesheets.length > 0">
                            <div class="hdr-nonscrollable">
                              <div class="hdr-grid-col grid-col-select hdr-frozen">
                                <input type="checkbox" ignore-dirty name="groupSelectAll" [(ngModel)]="groupUpdate.selectAll" authenticate-disabled="expired" (click)="selectAll($event); selectedFormsValid()" authenticate-user="payrolltimesheet.update" authenticate-command="disabled" />
                              </div>
                              <div class="hdr-grid-col grid-col-contact hdr-frozen">
                                <a (click)="sortByColumn('LASTNAME');" class="text-nowrap">
                                  Contact
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'LASTNAME'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div class="hdr-grid-col grid-col-sso hdr-frozen">
                                <a (click)="sortByColumn('sso');" class="text-nowrap">
                                  SSO
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'sso'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div class="hdr-grid-col grid-col-position hdr-frozen">
                                <a (click)="sortByColumn('POSITIONS');" class="text-nowrap">
                                  Position
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'POSITIONS'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div class="hdr-scrollable">
                              <div class="hdr-grid-col text-nowrap grid-col-hb hdr-scroll">
                                <a (click)="sortByColumn('EMPLOYEETYPE');">
                                  HB
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'EMPLOYEETYPE'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div class="hdr-grid-col grid-col-day hdr-scroll">
                                <a (click)="sortByColumn('Day');" class="text-nowrap">
                                  Day
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'Day'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                          
                              <div class="hdr-grid-col text-nowrap grid-col-duration hdr-scroll">
                                <a (click)="sortByColumn('duration');">
                                  Dur.
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'duration'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div class="hdr-grid-col grid-col-times hdr-scroll">
                                Activities<br />
                                (CI - MB - ME - CO)
                              </div>
                              <div class="hdr-grid-col grid-col-comments hdr-scroll">
                                Event Timesheet Comments
                              </div>
                              <div class="hdr-grid-col grid-col-scheduled-hrs hdr-scroll">
                                Sched Hrs
                              </div>
                              <div class="hdr-grid-col grid-col-contract-code hdr-scroll">
                                Contract Code
                              </div>
                              <div class="hdr-grid-col grid-col-contract-rule hdr-scroll">
                                Contract Rule Var
                              </div>
                              <div class="hdr-grid-col grid-col-indicator hdr-scroll">
                                Indicator
                              </div>
                              <div class="hdr-grid-col grid-col-pay-transactions hdr-scroll">
                                Pay Transactions
                              </div>
                              <div class="hdr-grid-col grid-col-dayCode hdr-scroll">
                                Day Code
                              </div>
                              <div class="hdr-grid-col grid-col-wageSchedule hdr-scroll">
                                Wage Schedule
                              </div>
                              <div class="hdr-grid-col grid-col-flatday hdr-scroll">
                                Flat/Day
                              </div>
                              <div class="hdr-grid-col grid-col-job-code hdr-scroll">
                                Job Code
                              </div>
                              <div class="hdr-grid-col grid-col-rate hdr-scroll">
                                <a (click)="sortByColumn('Rate');">
                                  Rate
                                  <span class="th-sort">
                                    <span *ngIf="sort.orderByField === 'Rate'">
                                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div class="hdr-grid-col grid-col-costing hdr-scroll">
                                COSTING
                              </div>
                              <div class="hdr-grid-col grid-col-gl-account hdr-scroll">
                                GL Account
                              </div>
                              <div class="hdr-grid-col grid-col-payrollerComments hdr-scroll">
                                Comments
                              </div>

                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!isFullScreenMode">                          
                          <div class="hdr-grid-row frozen-col-header" *ngIf="timesheets && timesheets.length > 0">
                            <div class="hdr-grid-col grid-col-select hdr-frozen">
                              <input type="checkbox" ignore-dirty name="groupSelectAll" [(ngModel)]="groupUpdate.selectAll" authenticate-disabled="expired" (click)="selectAll($event); selectedFormsValid()" authenticate-user="payrolltimesheet.update" authenticate-command="disabled" />
                            </div>
                            <div class="hdr-grid-col grid-col-contact hdr-frozen">
                              <a (click)="sortByColumn('LASTNAME');" class="text-nowrap">
                                Contact
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'LASTNAME'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col grid-col-sso hdr-frozen">
                              <a (click)="sortByColumn('sso');" class="text-nowrap">
                                SSO
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'sso'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col grid-col-position hdr-frozen">
                              <a (click)="sortByColumn('POSITIONS');" class="text-nowrap">
                                Position
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'POSITIONS'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col text-nowrap grid-col-hb hdr-scroll">
                              <a (click)="sortByColumn('EMPLOYEETYPE');">
                                HB
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'EMPLOYEETYPE'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>

                            <div class="hdr-grid-col text-nowrap grid-col-hb hdr-scroll">
                              <a (click)="sortByColumn('Day');">
                                Day
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'Day'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col text-nowrap grid-col-duration hdr-scroll">
                              <a (click)="sortByColumn('duration');">
                                Dur.
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'duration'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col grid-col-times hdr-scroll">
                              Activities<br />
                              (CI - MB - ME - CO)
                            </div>
                            <div class="hdr-grid-col grid-col-comments hdr-scroll">
                              Event Timesheet Comments
                            </div>
                            <div class="hdr-grid-col grid-col-scheduled-hrs hdr-scroll">
                              Sched Hrs
                            </div>
                            <div class="hdr-grid-col grid-col-contract-code hdr-scroll">
                              Contract Code
                            </div>
                            <div class="hdr-grid-col grid-col-contract-rule hdr-scroll">
                              Contract Rule Var
                            </div>
                            <div class="hdr-grid-col grid-col-indicator hdr-scroll">
                              Indicator
                            </div>
                            <div class="hdr-grid-col grid-col-pay-transactions hdr-scroll">
                              Pay Transactions
                            </div>
                            <div class="hdr-grid-col grid-col-dayCode hdr-scroll">
                              Day Code
                            </div>
                            <div class="hdr-grid-col grid-col-wageSchedule hdr-scroll">
                              Wage Schedule
                            </div>
                            <div class="hdr-grid-col grid-col-flatday hdr-scroll">
                              Flat/Day
                            </div>
                            <div class="hdr-grid-col grid-col-job-code hdr-scroll">
                              Job Code
                            </div>
                            <div class="hdr-grid-col grid-col-rate hdr-scroll">
                              <a (click)="sortByColumn('Rate');">
                                Rate
                                <span class="th-sort">
                                  <span *ngIf="sort.orderByField === 'Rate'">
                                    <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                                  </span>
                                </span>
                              </a>
                            </div>
                            <div class="hdr-grid-col grid-col-costing hdr-scroll">
                              COSTING
                            </div>
                            <div class="hdr-grid-col grid-col-gl-account hdr-scroll">
                              GL Account
                            </div>
                            <div class="hdr-grid-col grid-col-payrollerComments hdr-scroll">
                              Comments
                            </div>
                          </div>
                        </ng-container>

                        <div class="grid-row"
                             *ngFor="let item of timesheets; trackBy:trackByMpId;let mpId=trackByMpId"
                             ng-form="activityTimeForm_+ item.mpId">
                          <div class="grid-col grid-col-select {{ item.displayStatus }}">
                            <div class="grid-itm grid-itm-checkbox text-center">
                              <input type="checkbox" name="select_{{item.mpId}}"
                                     [(ngModel)]="item.selected"
                                     (click)="groupUpdateSelect(item);selectedFormsValid();"
                                     authenticate-disabled="expired"
                                     authenticate-user="payrolltimesheet.update" authenticate-command="disabled" />
                            </div>
                          </div>
                          <div *ngIf="item.contact.isNabet || item.contact.hasCompanyCode" class="grid-col grid-col-contact" style="background-color: rgb({{item.contact.payrollContactColor}})">
                            <div class="grid-itm">
                              <i class="fa fa-comment"
                                 *ngIf="item.timesheetErrors && item.timesheetErrors.length > 0"
                                 [popover]="popRejectionTemplate"
                                 (click)="onDemandChangeDetection();"
                                 popoverContext="item"
                                 placement="top right"
                                 [outsideClick]="true">
                                <ng-template class="payroll-rejection-popover" #popRejectionTemplate>
                                  <div *ngIf="item.timesheetErrors && item.timesheetErrors.length > 0">
                                    <div class=" arrow"></div>
                                    <div class="popover-content">
                                      <ul class="list-unstyled">
                                        <li *ngFor="let timesheetError of item.timesheetErrors;index as index;">{{timesheetError.errorCode}} - {{timesheetError.errorDescription}} {{timesheetError.errorDetail}}<span *ngIf="!$last"><br /><br /></span></li>
                                      </ul>
                                    </div>
                                  </div>
                                </ng-template>
                              </i>
                              <span class="payrollconflict">
                                <span *ngIf="item.hasconflict == true" class="fa-stack">
                                  <i class="fa fa-circle fa-stack-2x"></i>
                                  <i class="fa fa-calendar fa-stack-1x fa-inverse cursor-pointer"></i>
                                </span>
                              </span>
                              <a class="btn-link" href="/Remotes/Contacts/Detail/{{item.contact.id}}" title="{{item.contact.firstName  + ' ' + item.contact.lastName}}, SSO:{{item.contact.sso}}" target="_blank">{{item.contact.lastName}}<span *ngIf="item.contact.lastName && item.contact.firstName">, </span>{{item.contact.firstName}}</a>
                            </div>
                          </div>
                          <div *ngIf="!item.contact.isNabet && !item.contact.hasCompanyCode" class="grid-col grid-col-contact">
                            <div class="grid-itm">
                              <i class="fa fa-comment"
                                 *ngIf="item.timesheetErrors && item.timesheetErrors.length > 0"
                                 [popover]="popRejectionTemplate"
                                 (click)="onDemandChangeDetection();"
                                 popoverContext="item"
                                 placement="top right"
                                 [outsideClick]="true">
                                <ng-template class="payroll-rejection-popover" #popRejectionTemplate>
                                  <div *ngIf="item.timesheetErrors && item.timesheetErrors.length > 0">
                                    <div class=" arrow"></div>
                                    <div class="popover-content">
                                      <ul class="list-unstyled">
                                        <li *ngFor="let timesheetError of item.timesheetErrors;index as index;">{{timesheetError.errorCode}} - {{timesheetError.errorDescription}} {{timesheetError.errorDetail}}<span *ngIf="!$last"><br /><br /></span></li>
                                      </ul>
                                    </div>
                                  </div>
                                </ng-template>
                              </i>
                              <span class="payrollconflict">
                                <span *ngIf="item.hasconflict == true" class="fa-stack">
                                  <i class="fa fa-circle fa-stack-2x"></i>
                                  <i class="fa fa-calendar fa-stack-1x fa-inverse cursor-pointer"></i>
                                </span>
                              </span>
                              <a class="btn-link" href="/Remotes/Contacts/Detail/{{item.contact.id}}" title="{{item.contact.firstName  + ' ' + item.contact.lastName}}, SSO:{{item.contact.sso}}" target="_blank">{{item.contact.lastName}}<span *ngIf="item.contact.lastName && item.contact.firstName">, </span>{{item.contact.firstName}}</a>
                            </div>
                          </div>
                          <div class="grid-col grid-col-sso">
                            <div class="grid-itm">
                              {{item.contact.sso}}
                            </div>
                          </div>
                          <div class="grid-col grid-col-position">
                            <div class="grid-itm">
                              <truncate-text truncateString="{{item.eventPosition.position.description}}" truncateLimit="15"></truncate-text>
                            </div>
                          </div>
                          <div class="grid-col grid-col-hb">
                            <div class="grid-itm">
                              <nbc-select [source]="employeeTypes"
                                          name="employeeTypes_{{item.mpId}}"
                                          [value]="'id'"
                                          [display]="'description'"
                                          [(model)]="item.eventPosition.employeeType"
                                          authenticate-user="payrolltimesheet.update"
                                          (change)="setDefaultContractAndJobCode(item); setChanged(item);"
                                          authenticate-command="nbcselectdisabled">
                              </nbc-select>
                            </div>
                          </div>
                          <div class="grid-col grid-col-day">
                            <div class="grid-itm text-center">
                              <span>{{item.activityString}}</span>
                            </div>
                          </div>
                          <div class="grid-col grid-col-duration">
                            <div class="grid-itm text-center">
                              <span [ngClass]="{'duration-error': item.durationError}"> {{ item.calculatedDuration }}</span>
                            </div>
                          </div>
                          <div class="grid-col grid-col-times">
                            <div class="grid-itm">
                              <div class="form-inline">
                                <div style="position:relative" class="form-group-time"
                                     [ngClass]="{'has-error':!item.isIntimeValid}">

                                  <nbc-date-time id="{{item.mpId}}"
                                                 type="hours"
                                                 format="24hr"
                                                 [nbcDisabled]="item.billCodeUnitCodeTypeCode === 'Dy'"
                                                 dateDisplayFormat="ampm"
                                                 placeholder="CI"
                                                 (modelChange)="onTimeSelected($event,item,mpId,'timeIn');checkDuration(item);setDuration(item);setChanged(item);onDemandChangeDetection();"
                                                 name="timeIn_{{item.mpId}}"
                                                 defaultDate="{{timeCardDate}}"
                                                 [(model)]="item.timeIn"
                                                 [nbcRequired]="item.isIntimeRequired || item.timeOut || item.lunchIn || item.lunchOut">
                                  </nbc-date-time>

                                </div>
                                <div style="position:relative" class="form-group-time"
                                     [ngClass]="{'has-error':!item.isLunchInTimeValid}">
                                  <nbc-date-time id="{{item.mpId}}"
                                                 type="hours"
                                                 format="24hr"
                                                 dateDisplayFormat="ampm"
                                                 placeholder="MB"
                                                 (modelChange)="onTimeSelected($event,item,mpId,'lunchIn');checkDuration(item);setDuration(item);setChanged(item);"
                                                 name="mealbegin_{{item.mpId}}"
                                                 defaultDate="{{timeCardDate}}"
                                                 [nbcDisabled]="item.billCodeUnitCodeTypeCode === 'Dy'"
                                                 [(model)]="item.lunchIn"
                                                 [nbcRequired]="item.isLunchInTimeRequired || item.lunchOut"></nbc-date-time>
                                </div>
                                <div style="position:relative" class="form-group-time"
                                     [ngClass]="{'has-error':!item.isLunchOutTimeValid}">
                                  <nbc-date-time id="{{item.mpId}}"
                                                 type="hours"
                                                 format="24hr"
                                                 dateDisplayFormat="ampm"
                                                 placeholder="ME"
                                                 (modelChange)="onTimeSelected($event,item,mpId,'lunchOut');checkDuration(item);setDuration(item);setChanged(item);"
                                                 name="mealend_{{item.mpId}}"
                                                 defaultDate="{{timeCardDate}}"
                                                 [(model)]="item.lunchOut"
                                                 [nbcDisabled]="item.billCodeUnitCodeTypeCode === 'Dy'"
                                                 [nbcRequired]="item.isLunchOutTimeRequired || item.lunchIn"></nbc-date-time>
                                </div>
                                <div style="position:relative" class="form-group-time"
                                     [ngClass]="{'has-error':!item.isOuttimeValid}">
                                  <nbc-date-time id="{{item.mpId}}"
                                                 type="hours"
                                                 format="24hr"
                                                 dateDisplayFormat="ampm"
                                                 placeholder="CO"
                                                 (modelChange)="onTimeSelected($event,item,mpId,'timeOut');checkDuration(item);setDuration(item);setChanged(item);onDemandChangeDetection();"
                                                 name="timeout_{{item.mpId}}"
                                                 defaultDate="{{timeCardDate}}"
                                                 [(model)]="item.timeOut"
                                                 [nbcDisabled]="item.billCodeUnitCodeTypeCode === 'Dy'"
                                                 [nbcRequired]="item.isOuttimeRequired || item.timeIn || item.lunchIn || item.lunchOut"></nbc-date-time>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="grid-col grid-col-comments">

                            <textarea class="grid-itm" rows="2" style="height:36px;overflow-x:hidden;text-align:left;white-space:normal;resize:none;padding-top:0;" disabled>
                                                            {{item.productionManagerComment}}
                                                        </textarea>
                          </div>
                          <div class="grid-col grid-col-scheduled-hrs">
                            <div class="grid-itm">
                              <div [ngClass]="{'has-error': !item.isdefaultScheduleValid}">
                                <input type="number"
                                       maxlength="5"
                                       max="24"
                                       min="1"
                                       nbc-decimal
                                       decimals="2"
                                       *ngIf="item.isTk"
                                       name="defaultScheduledHours_{{item.mpId}}"
                                       class="form-control form-control-xs input-number scheduled-hours"
                                       [(ngModel)]="item.defaultScheduledHours"
                                       authenticate-user="payrolltimesheet.update"
                                       [required]="item.isTk"
                                       (change)="validateDefaultScheduleHours(item); setChanged(item);"
                                       authenticate-disabled="expired"
                                       authenticate-command="disabled"
                                       authenticate-required="item.isTk" />
                              </div>
                            </div>
                          </div>
                          <div class="grid-col grid-col-contract-code">
                            <div class="grid-itm">
                              <nbc-select [(model)]="item.contractCode"
                                          *ngIf="item.isTk"
                                          name="contractCodes_{{item.mpId}}"
                                          [source]="contractCodes"
                                          [value]="'id'"
                                          [display]="'name'"
                                          nbcAuthenticateDisabled="contractCodes.length === 0 || expired"
                                          authenticate-user="payrolltimesheet.update"
                                          authenticate-command="nbcselectdisabled"
                                          [nbcMismatch]="checkMisMatch(item.employeeTypeMismatch)"
                                          (change)="clearData(item);populateSchedHrs(item);checkDefaultEmployeeType(item);setWageSchedule(item);setChanged(item);onDemandChangeDetection();">

                              </nbc-select>
                            </div>
                          </div>
                          <div class="grid-col grid-col-contract-rule">
                            <div class="grid-itm">
                              <nbc-select *ngIf="item.isTk && item.contractCode?.ruleVariances"
                                          name="ruleVariance_{{item.mpId}}"
                                          [(model)]="item.contractRuleVariance"
                                          [source]="item.contractCode.ruleVariances"
                                          [value]="'description'"
                                          [display]="'description'"
                                          nbcAuthenticateDisabled="(item.contractCode.ruleVariances == null || item.contractCode.ruleVariances.length === 0) || expired"
                                          authenticate-user="payrolltimesheet.update"
                                          authenticate-command="nbcselectdisabled"
                                          [nbcAddBlank]="true"
                                          (change)="selectedFormsValid();setChanged(item);">

                              </nbc-select>
                            </div>
                          </div>
                          <div class="grid-col grid-col-indicator">
                            <div class="grid-itm">
                              <payroll-indicators *ngIf="item.isTk && item.contractCode?.indicators"
                                                  [(searchTerm)]="positionSearchTerm"
                                                  [(indicatorList)]="item.contractCode.indicators"
                                                  [tagDisplay]="'description'"
                                                  [tagId]="displayId"
                                                  [(tagName)]="item.indicators"
                                                  [(tags)]="item.indicators"
                                                  [placeholder]="'Add Indicator'"
                                                  (tagChange)="setChanged(item)"
                                                  (nbcOnOptionSelect)="onIndicatorSelected(item,$event)"
                                                  authenticate-user="payrolltimesheet.update" authenticate-command="tagsdisabled"></payroll-indicators>
                            </div>
                          </div>
                          <div class="grid-col grid-col-pay-transactions">
                            <div class="grid-itm">
                              <div *ngIf="item.isTk" class="form-inline" ng-form="payTransactionForm" novalidate>
                                <button class="btn btn-default"
                                        (click)="openPayTransactionsModal(item, false)"
                                        authenticate-user="payrolltimesheet.update"
                                        authenticate-command="disabled"
                                        *ngIf="item.payTransactions.length === 0">
                                  Add Transaction
                                </button>
                                <span *ngIf="item.payTransactions.length === 1"> <span>{{item.payTransactions[0].transactionType.description}} /{{item.payTransactions[0].hrUnit}} / {{item.payTransactions[0].rate}} </span> <span><i class="fa fa-pencil float-end cursor-pointer" (click)="openPayTransactionsModal(item, false)"></i></span></span>
                                <span *ngIf="item.payTransactions.length >= 2">
                                  <a (click)="onDemandChangeDetection();"
                                     [popover]="popPayTransationTemplate"
                                     popoverTitle="Pay Transactions"
                                     popoverContext="item"
                                     placement="top right"
                                     [outsideClick]="true"
                                     class="btn-link">
                                    {{item.payTransactions.length}} Transactions
                                    <ng-template #popPayTransationTemplate>
                                      <div *ngIf="item.payTransactions.length >= 2">
                                        <div class="modal-body" *ngFor="let transaction of item.payTransactions;index as index;">
                                          {{transaction.transactionType.description}} / {{transaction.hrUnit}} / {{transaction.rate}}
                                        </div>
                                      </div>
                                    </ng-template>
                                  </a>
                                  <span>
                                    <i class="fa fa-pencil float-end cursor-pointer" (click)="openPayTransactionsModal(item, false)"></i>
                                  </span>
                                </span>
                              </div>
                              <span [ngClass]="{'has-error':!item.isPayrollCommentValid }">
                                <input class="form-control"
                                       name="payrollComment_{{item.mpId}}"
                                       maxlength="25"
                                       [(ngModel)]="item.payrollComment"
                                       (change)="validatePayrollComment(item);setChanged(item);"
                                       placeholder="Comment"
                                       *ngIf="!item.isTk"
                                       authenticate-disabled="expired"
                                       authenticate-user="payrolltimesheet.update" authenticate-command="disabled" />
                                <!--[(model)]-options="{'debounce': { default: 500 }}"-->
                              </span>
                            </div>
                          </div>
                          <div class="grid-col grid-col-dayCode">
                            <div class="grid-itm" *ngIf="item.isTk">
                              <nbc-dropdown [source]="dayCodes"
                                            name="dayCode_{{item.mpId}}"
                                            value="id"
                                            display="id"
                                            [(model)]="item.dayCode"
                                            nbcAuthenticateUser="payrolltimesheet.update"
                                            nbcSize="w-100"
                                            (modelChange)="setChanged(item);">
                              </nbc-dropdown>
                            </div>
                          </div>
                          <div class="grid-col grid-col-wageSchedule">
                            <div class="grid-itm text-center" *ngIf="item.isTk">
                              {{item.wageSchedule}}
                            </div>
                            <div class="grid-itm text-center" *ngIf="!item.isTk">

                            </div>
                          </div>
                          <div class="grid-col grid-col-flatday">
                            <div class="grid-itm text-center"
                                 *ngIf="item.billCodeUnitCodeTypeCode === 'Dy'">
                              {{item.flatRate}}
                            </div>
                            <div class="grid-itm text-center"
                                 *ngIf="item.billCodeUnitCodeTypeCode !== 'Dy'">

                            </div>
                          </div>
                          <div class="grid-col grid-col-job-code">
                            <div class="grid-itm" style="min-width: 150px">
                              <div class="form-inline">
                                <div *ngIf="item.isTk || (item.eventPosition.employeeType === 'VENDOR')">
                                  <nbc-select style="width: 40%" *ngIf="jobCodes" [(model)]="item.jobCode"
                                              [source]="jobCodes"
                                              name="jobCodes_{{item.mpId}}"
                                              [defaultSelectClass]="form-select-sm"
                                              [value]="'code'"
                                              [display]="'code'"
                                              nbcAuthenticateDisabled="(jobCodes.length === 0) || expired"
                                              authenticate-user="payrolltimesheet.update"
                                              authenticate-command="nbcselectdisabled"
                                              [nbcMismatch]="checkMisMatch(item.jobCodeMismatch)"
                                              (change)="item.rateCode = '00';checkDefaultJobCode(item);onDemandChangeDetection();"></nbc-select>

                                  <nbc-dropdown class="payrollJobCode" *ngIf="item.jobCode && item.jobCode.details"
                                                name="rateCoded_{{item.mpId}}"
                                                [(model)]="item.rateCode"
                                                [source]="item.jobCode.details"
                                                display="rateCode" value="rateCode"
                                                nbcAuthenticateDisabled="(item.jobCode.details.length === 0) || expired"
                                                nbcAuthenticateUser="payrolltimesheet.update"
                                                nbcAuthenticateCommand="disabled"
                                                [nbcMismatch]="checkMisMatch(item.jobCodeMismatch)"
                                                (change)="checkDefaultJobCode(item);setChanged(item);"
                                                (init)="oldValue=0"
                                                nbcSize="w-100"
                                                (focus)="oldValue=item.jobCode"></nbc-dropdown>
                                </div>
                                <span *ngIf="(item.eventPosition.employeeType === 'VENDOR')">N/A</span>
                              </div>
                            </div>
                          </div>
                          <div class="grid-col grid-col-rate">
                            <div class="grid-itm">
                              <div *ngIf="(item.jobCode != null && item.rateCode == '00') || !item.isTk" [ngClass]="{'has-error': !item.isRateValid}">
                                <input type="number"
                                       name="rate_{{item.mpId}}"
                                       class="form-control form-control-sm input-number"
                                       [(ngModel)]="item.rate"
                                       (change)=" validateRate(item); setChanged(item);"
                                       placeholder="Rate"
                                       (blur)="validateRateVal(item,$event);"
                                       authenticate-disabled="expired"
                                       authenticate-user="payrolltimesheet.update"
                                       authenticate-command="disabled"
                                       max="6000"
                                       min="0"
                                       nbc-decimal
                                       decimals="4" />
                              </div>
                            </div>
                          </div>
                          <div class="grid-col grid-col-costing">
                            <div class="grid-itm">
                              {{item.wbse}}
                            </div>
                          </div>
                          <div class="grid-col grid-col-gl-account">
                            <div class="grid-itm">
                              <div [ngClass]="{'has-error': !item.isglAccountValid}">
                                <glaccount [hidden]="!item.isTk"
                                           placeHolder="GL Account"
                                           nbcAuthenticateUser="payrolltimesheet.update"
                                           nbcAuthenticateCommand="disabled"
                                           [isRequired]="item.isTk && item.selected"
                                           nbcAuthenticateDisabled="expired"
                                           name="glAccountGU_{{item.mpId}}"
                                           mpId="{{item.mpId}}"
                                           nbcClass="form-control form-control-sm"
                                           (glAccountChange)="onSelectGLAccount(item)"
                                           [(model)]="item.glAccount">
                                </glaccount>
                              </div>
                            </div>
                          </div>
                          <div class="grid-col grid-col-payrollerComments">
                            <textarea [(ngModel)]="item.payrollUserComments"
                                      maxlength="255"
                                      name="payrollcomments_{{item.mpId}}"
                                      (blur)="savePayrollComment(item);"
                                      style="width: 150px; height: 34px;"
                                      placeholder="Leave a comment"
                                      appInputRestriction="restrictChars"
                                      class="form-control form-control-sm"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <p *ngIf="timesheets && timesheets.length === 0">No results.</p>
                  <div *ngIf="(!timesheets || timesheets.length === 0) && !setView.isPageChange && (!filter.employeeType || filter.employeeType.length === 0)" class="alert alert-info" type="info">Please choose an employee type filter from above.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
