<div>
  <div class="modal-header">
    <h4 class="modal-title ng-binding">{{modalTitle || "Unsaved Changes"}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div>
          <p>{{modalMessage || "You have unsaved changes.  Please select an option."}}</p>
          <p *ngIf="disableSave">Unable to save due to invalid form data.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-end">
    <div class="row">
      <div class="col-sm-12 p-0">
        <button type="button" class="btn btn-default" (click)="cancel()">{{cancelText || "Cancel" }}</button>
        <button type="button" class="btn btn-default" (click)="discard()">{{confirmText || "Discard Changes" }}</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="disableSave">{{saveText || "Save Changes" }}</button>
      </div>
    </div>
  </div>
</div>
