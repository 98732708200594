import { Component, OnInit, OnDestroy } from "@angular/core";
import { DashboardService } from "../Shared/Services/dashboard.service";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SharedDataService } from "../Shared/sharedData.service";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "dashboard-recently-viewed",
    templateUrl: "./dashboard-recentlyviewed.component.html",
})
export class DashboardRecentlyViewed implements OnInit, OnDestroy {
    constructor(
        private _dashboardService: DashboardService,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();

    recentlyViewed: any | undefined;
    recentlyViewedComplete: boolean | undefined;

    getRecentlyViewed() {
        this.log.trace("Logging from new logger");
        this.log.debug("RecentlyViewed -> get:fetching data");
        this._dashboardService
            .getRecentlyViewed()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.recentlyViewed = data.reference
                        ? _.take(data.reference, 10)
                        : null;
                    this.log.debug("RecentlyViewed ->  received");
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load the dashboard at this time. Please try again later."
                    );
                },
                () => {
                    this.recentlyViewedComplete = true;
                }
            );
    }
    goto(eventId: number) {
        this.log.debug("goto" + eventId);
        this.router.navigate(["Event", eventId]);
    }

    ngOnInit(): void {
        this.getRecentlyViewed();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
