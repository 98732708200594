import { Injectable } from "@angular/core";
import {
    parsePhoneNumberFromString,
    isValidNumber,
    CountryCode,
    isSupportedCountry,
} from "libphonenumber-js";

@Injectable({
    providedIn: "root",
})
export class PhoneNumberService {
    parsePhoneNumber(number: string, countryCodestr?: string) {
        try {
            let countryCodeobj: CountryCode = "US";
            if (
                countryCodestr !== null &&
                countryCodestr !== "" &&
                countryCodestr !== undefined
            ) {
                countryCodeobj = countryCodestr as CountryCode;
            }

            const phoneNumber = parsePhoneNumberFromString(
                number,
                countryCodeobj
            );
            return phoneNumber ? phoneNumber : null;
        } catch {
            return null;
        }
    }

    isValidNumber(number: string, countryCode?: string) {
        return isValidNumber(number, countryCode as CountryCode);
    }
}
