<span *ngIf="internalSource" class="{{nbcClass}}">
  <select class="{{defaultSelectClass}} {{nbcMismatch}} {{customErrorClass}}" [(ngModel)]="selected"
          name="{{name}}"
          [required]="(nbcRequired && !nbcDisabled) || null"
          [disabled]="nbcDisabled || !isAuthorized"
          [attr.authenticate-user]="authenticateUser"
          [attr.authenticate-command]="nbcselectdisabled"
          [attr.authenticate-disabled]="authenticateDisabled"
          [attr.authenticate-department]="authenticateDepartment"
          [attr.authenticate-required]="authenticateRequired"
          [attr.authenticate-contact-id]="authenticateContactId"
          (change)="optionSelected(true)"
          (focus)="onFocus()">
    <!--<option *ngIf="placeHolderDisplay" [ngValue]="placeHolderValue">{{placeHolderDisplay}}</option>-->
    <option *ngFor="let opt of internalSource"
            [ngValue]="opt[value]">
      {{opt[display]}}
    </option>
  </select>
</span>
