import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedDataService } from "../../Shared/sharedData.service";

@Component({
    selector: "event-dashboard",
    templateUrl: "./eventdashboard.component.html",
})
export class EventDashboardComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _sharedData: SharedDataService
    ) {}
    _: any;

    buttonText!: string | "+ Create an Event";
    ShowEventButton = false;
    tabs = [
        {
            heading: "SEASONS",
            route: "Seasonlist",
            active: false,
        },
        {
            heading: "EVENTS",
            route: "Eventlist",
            active: true,
        },
    ];

    go(route: string) {
        this.ShowEventButton = false;
        if (
            route.toLowerCase() == "eventlist" ||
            route.toLowerCase() == "seasonlist"
        ) {
            this.ShowEventButton = true;
            this.buttonText =
                route.toLowerCase() == "seasonlist"
                    ? "+ Create a Season"
                    : "+ Create an Event";
        }
        _.forEach(this.tabs, function (tab, index) {
            tab.active = false;
            if (tab.route === route) {
                tab.active = true;
            }
        });
        this.router.navigate([route], { relativeTo: this.route });
    }

    ngOnInit() {
        this.buttonText = "+ Create an Event";
        this._sharedData.setEvenSection(false);
        this._sharedData.setCss("eventsdashboard");
    }
}
