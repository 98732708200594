import { Component, Directive, ElementRef, Renderer2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
@Component({
  selector: "[maximize]",
  exportAs: "maximize", // <-- Make not of this here,
  template: "<a class=\"btn btn-default\" title=\"Fullscreen\" (click)=\"toggle()\"><i class=\"fa\" [ngClass]=\"fullscreenMode ? 'fa-compress' : 'fa-expand'\"></i></a>",
//  styles: [".fullscreen { width: 100vw; height: 100vh; position: fixed; top: 0; left: 0;}"]
})
export class NbcFullScreenDirective {
  private isMaximizedSubject = new BehaviorSubject(false);
  fullscreenMode = false;
  isMaximized$ = this.isMaximizedSubject.pipe();
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  toggle() {    
    this.fullscreenMode ? this.minimize() : this.maximize();
  }
  maximize() {    
    if (this.el) {
      this.fullscreenMode = true;;
      this.isMaximizedSubject.next(true);
      this.renderer.addClass(document.body, "fullscreen");

     // this.renderer.addClass(this.el.nativeElement.b, "fullscreen");
    }
  }
  minimize() {    
    if (this.el) {
      this.fullscreenMode = false;
      this.isMaximizedSubject.next(false);
      this.renderer.removeClass(document.body, "fullscreen");
      //this.renderer.removeClass(this.el.nativeElement, "fullscreen");
    }
  }
}
