<div class="main-pane-content">
  <div class="container-fluid">
    <span us-spinner spinner-key="searchSpinner"></span>
    <div class="row">
    </div>
    <div class="row">
      <div class="col-md-12 mainheader">
        <div class="header-bar clearfix">
          <div class="row">
            <div class="col-md-3">
              <select name="role"
                      id="role"
                      class="form-select"
                      [(ngModel)]="selectedrole"
                      style="height:35px;">
                <option [ngValue]="id">Select Role</option>
                <option *ngFor="let role of roles" [ngValue]='role'>{{role.roleDesc}}</option>
              </select>
            </div>
            <div class="col-md-9">
              <button type="button"
                      [disabled]="checkRole()"
                      authenticate-user="contact.permissionremotes.create"
                      class="btn btn-primary pull-right"
                      (click)="addRoles()">
                + ADD ROLE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form role="form"
          permissionTalentForm="ngForm"
          authenticate-user="contact.permissionremotes.retrieve"
          novalidate>
      <div class="tab-page-content">
        <div class="row" *ngIf="resultsFound">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="dark-header">
                  <tr>
                    <th style="cursor:default;" class="col-md-1 default-header">Role No</th>
                    <th style=" cursor:default;" class="col-md-1 default-header">Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let permission of permissions">
                    <td class="col-md-4" style="-ms-word-break: break-all; word-break: break-all;">{{permission.roleNo.roleNo}}</td>
                    <td class="col-md-4" style="-ms-word-break: break-all; word-break: break-all;">{{permission.roleNo.roleDesc}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p *ngIf="!resultsFound">No Permission.</p>
      </div>
    </form>
  </div>

</div>
