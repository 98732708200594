import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { ReportService } from "src/app/Shared/Services/report.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SubSink } from "subsink";

@Component({
    selector: "app-crew-change-export",
    templateUrl: "./crew-change-export.component.html",
    styleUrls: ["../../../../../assets/Content/app/RemotesReport.css"],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CrewChangeExportComponent implements OnInit {
    constructor(
        private _reportService: ReportService,
        private bsModalRef: BsModalRef
    ) {}
    @ViewChild(NbcFileDownloadComponent) child;

    eventId: any;
    departmentList: any[] = [];
    employeeTypeList: any[] = [];
    changeElementList: any[] = [];
    positionNameList: any[] = [];
    venue = "";
    isExport = true;
    showAlertMessage = false;
    venueList = [];
    showPositionNameTagRow = false;
    showDepartmentRow = false;
    showDepartmentRowAll = true;
    showDepartmentEditRow = false;
    showDepartmentSelectedRow = false;

    showEmployeeTypeRowAll = true;
    showEmployeeTypeEditRow = false;
    showEmployeeTypeSelectedRow = false;

    showPositionNameRowAll = true;
    showPositionNameEditRow = false;
    showPositionNameSelectedRow = false;

    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;

    showChangeElementRowAll = true;
    showChangeElementEditedRow = false;
    showChangeElementTagRow = false;

    departmentSelected = {};
    employeeTypeSelected = {};
    positionNameSelected = {};
    showEmployeeTypeTagRow: any;
    disp = false;
    events: any;
    user = window.preload.user;
    userName = this.user.firstName + " " + this.user.lastName;
    CrewChangeReportInputInfo: any;
    showEmployeeTypeRow: any;
    CrewChangeReport: any;
    showPositionNameRow: any;
    showDepartmentTagRow: any;
    private subs = new SubSink();

    cancel() {
        this.bsModalRef.hide();
        // $modalInstance.close();
    }

    openDateRangeDatepicker(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    checkDepartmentValue() {
        if (this.CrewChangeReportInputInfo.department !== null) {
            this.showDepartmentRowAll = false;
        } else {
            this.showDepartmentRowAll = true;
        }
    }

    checkEmployeeTypeValue() {
        if (this.CrewChangeReportInputInfo.employeeType !== null) {
            this.showEmployeeTypeRowAll = false;
        } else {
            this.showEmployeeTypeRowAll = true;
        }
    }

    checkPositionNameValue() {
        if (this.CrewChangeReportInputInfo.positionName !== null) {
            this.showPositionNameRowAll = false;
        } else {
            this.showPositionNameRowAll = true;
        }
    }

    editRow(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentRow = true;
        }
        if (value === "DateRangeRow") {
            this.showDateRangeRowAll = false;
            this.showDateRangeEditRow = true;
            this.showDateRangeTagRow = false;
        }
        if (value === "EmployeeTypeRow") {
            this.showEmployeeTypeRow = true;
        }
        if (value === "PositionNameRow") {
            this.showPositionNameRow = true;
        }
        if (value === "ChangeElementRow") {
            this.showChangeElementRowAll = false;
            this.showChangeElementEditedRow = true;
            this.showChangeElementTagRow = false;
        }
    }

    confirmSelection(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentRow = false;
            this.checkDepartmentValue();
        }
        if (value === "EmployeeTypeRow") {
            this.showEmployeeTypeRow = false;
            this.checkEmployeeTypeValue();
        }
        if (value === "PositionNameRow") {
            this.showPositionNameRow = false;
            this.checkPositionNameValue();
        }
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
        }
        if (value === "ChangeElementRow") {
            this.showChangeElementEditedRow = false;
            this.showSection(value);
        }
    }

    removeDateTag(value: any) {
        if (value === "DateRangeRow") {
            this.CrewChangeReportInputInfo.startDate = null;
            this.CrewChangeReportInputInfo.endDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (
                this.CrewChangeReportInputInfo.startDate === null &&
                this.CrewChangeReportInputInfo.endDate === null
            ) {
                // document.getElementById("crewChangeBtnReportExport").disabled = true;
                this.isExport = true;
            }
        }
    }

    stateChanged(checked: any, value: any, row: any) {
        if (row === "DepartmentRow") {
            if (checked) {
                this.departmentList.push(value);
                this.CrewChangeReportInputInfo.department.push(value);
            }
            if (!checked) {
                this.departmentList.splice(
                    this.departmentList.indexOf(value),
                    1
                );
                this.CrewChangeReportInputInfo.department.splice(
                    this.CrewChangeReportInputInfo.department.indexOf(value),
                    1
                );
            }
        }

        if (row === "EmployeeTypeRow") {
            if (checked) {
                this.employeeTypeList.push(value);
                this.CrewChangeReportInputInfo.employeeType.push(value);
            }
            if (!checked) {
                this.employeeTypeList.splice(
                    this.employeeTypeList.indexOf(value),
                    1
                );
                this.CrewChangeReportInputInfo.employeeType.splice(
                    this.CrewChangeReportInputInfo.employeeType.indexOf(value),
                    1
                );
            }
        }

        if (row === "PositionNameRow") {
            if (checked) {
                this.positionNameList.push(value);
                this.CrewChangeReportInputInfo.positionName.push(value);
            }
            if (!checked) {
                this.positionNameList.splice(
                    this.positionNameList.indexOf(value),
                    1
                );
                this.CrewChangeReportInputInfo.positionName.splice(
                    this.CrewChangeReportInputInfo.positionName.indexOf(value),
                    1
                );
            }
        }

        if (row === "ChangeElementRow") {
            if (checked) {
                this.changeElementList.push(value);
                this.CrewChangeReportInputInfo.changeElement.push(value);
            }
            if (!checked) {
                //Remove value from the object(CrewChangeReportInputInfo) based on the index.
                this.changeElementList.splice(
                    this.changeElementList.indexOf(value),
                    1
                );
                this.CrewChangeReportInputInfo.changeElement.splice(
                    this.CrewChangeReportInputInfo.changeElement.indexOf(value),
                    1
                );
            }
        }
    }

    removeTag(item: any, row: any) {
        if (row === "DepartmentRow") {
            this.departmentList.splice(this.departmentList.indexOf(item), 1);
            this.CrewChangeReportInputInfo.department.splice(
                this.CrewChangeReportInputInfo.department.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("DepartmentRow");
        }

        if (row === "EmployeeTypeRow") {
            this.employeeTypeList.splice(
                this.employeeTypeList.indexOf(item),
                1
            );
            this.CrewChangeReportInputInfo.employeeType.splice(
                this.CrewChangeReportInputInfo.employeeType.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("EmployeeTypeRow");
        }

        if (row === "PositionNameRow") {
            this.positionNameList.splice(
                this.positionNameList.indexOf(item),
                1
            );
            this.CrewChangeReportInputInfo.positionName.splice(
                this.CrewChangeReportInputInfo.positionName.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("PositionNameRow");
        }

        if (row === "ChangeElementRow") {
            this.changeElementList.splice(
                this.changeElementList.indexOf(item),
                1
            );
            this.CrewChangeReportInputInfo.changeElement.splice(
                this.CrewChangeReportInputInfo.changeElement.indexOf(item),
                1
            );

            item.value = !item.value;
            this.showSection("ChangeElementRow");
        }
    }
    showSection(value: any) {
        if (value === "DepartmentRow") {
            if (
                !_.isUndefined(this.departmentList) &&
                this.departmentList.length > 0
            ) {
                this.showDepartmentRowAll = false;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = true;
            } else {
                this.showDepartmentRowAll = true;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = false;
            }
        }

        if (value === "EmployeeTypeRow") {
            if (
                !_.isUndefined(this.employeeTypeList) &&
                this.employeeTypeList.length > 0
            ) {
                this.showEmployeeTypeRowAll = false;
                this.showEmployeeTypeEditRow = false;
                this.showEmployeeTypeTagRow = true;
            } else {
                this.showEmployeeTypeRowAll = true;
                this.showEmployeeTypeEditRow = false;
                this.showEmployeeTypeTagRow = false;
            }
        }

        if (value === "PositionNameRow") {
            if (
                !_.isUndefined(this.positionNameList) &&
                this.positionNameList.length > 0
            ) {
                this.showPositionNameRowAll = false;
                this.showPositionNameEditRow = false;
                this.showPositionNameTagRow = true;
            } else {
                this.showPositionNameRowAll = true;
                this.showPositionNameEditRow = false;
                this.showPositionNameTagRow = false;
            }
        }

        if (value === "DateRangeRow") {
            if (
                this.CrewChangeReportInputInfo.startDate !== null &&
                this.CrewChangeReportInputInfo.endDate !== null
            ) {
                this.showDateRangeRowAll = false;
                this.showDateRangeEditRow = false;
                this.showDateRangeTagRow = true;
                // document.getElementById("crewChangeBtnReportExport").disabled = false;
                this.isExport = false;
            } else {
                this.showDateRangeRowAll = true;
                this.showDateRangeEditRow = false;
                this.showDateRangeTagRow = false;
                if (
                    this.CrewChangeReportInputInfo.dateRangeStartDate ===
                        null &&
                    this.CrewChangeReportInputInfo.dateRangeEndDate === null
                ) {
                    // document.getElementById("crewChangeBtnReportExport").disabled = true;
                    this.isExport = false;
                }
            }
        }

        if (value === "ChangeElementRow") {
            if (
                !_.isUndefined(this.changeElementList) &&
                this.changeElementList.length > 0
            ) {
                this.showChangeElementRowAll = false;
                this.showChangeElementEditedRow = false;
                this.showChangeElementTagRow = true;
            } else {
                this.showChangeElementRowAll = true;
                this.showChangeElementEditedRow = false;
                this.showChangeElementTagRow = false;
            }
        }
    }

    loadEvents(term: any) {
        // usSpinnerService.spin("genericSpinner");
        this.showAlertMessage = false;
        // log.trace("loadEvents");
        // var defer = $q.defer();

        this.subs.sink = this._reportService
            .autoCompleteByOnlyEvent(term, this.events)
            .subscribe({
                next: (data) => {
                    // defer.resolve(data.events);
                },
                error: (data) => {
                    // defer.reject(data);
                },
            });

        // return defer.promise;
    }

    getCrewChangeReportDetails() {
        // log.trace("exportReport");
        // usSpinnerService.spin("genericSpinner");
        this.subs.sink = this._reportService
            .getRemotesCrewChangeLookupDetails(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.showAlertMessage = true;
                        // this.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.CrewChangeReport = data;
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                }, // $scope.alerts = [{ type: "danger", msg: "Error Occurred" }];
            });
    }
    closeAlert() {
        // $scope.alerts = [];
        this.showAlertMessage = false;
    }

    onToggleFilterDatepicker(event: any) {
        this.CrewChangeReportInputInfo.startDate = event[0];
        this.CrewChangeReportInputInfo.endDate = event[1];
    }
    exportReport() {
        // usSpinnerService.spin("genericSpinner");
        const tempCrewChangeReportInfo: any = {};

        const temp_var: any[] = [];
        if (this.CrewChangeReportInputInfo.department !== null) {
            temp_var.push(this.CrewChangeReportInputInfo.department);
        }
        tempCrewChangeReportInfo["department"] = temp_var;

        const temp_varEmp: any[] = [];
        if (this.CrewChangeReportInputInfo.employeeType !== null) {
            temp_varEmp.push(this.CrewChangeReportInputInfo.employeeType);
        }
        tempCrewChangeReportInfo.employeeType = temp_varEmp;

        const temp_varPos: any[] = [];
        if (this.CrewChangeReportInputInfo.positionName !== null) {
            temp_varPos.push(this.CrewChangeReportInputInfo.positionName);
        }
        tempCrewChangeReportInfo.positionName = temp_varPos;

        tempCrewChangeReportInfo.startDate = moment(
            this.CrewChangeReportInputInfo.startDate
        ).format("MM/DD/yyyy");
        tempCrewChangeReportInfo.endDate = moment(
            this.CrewChangeReportInputInfo.endDate
        ).format("MM/DD/yyyy");
        tempCrewChangeReportInfo.changeElement =
            this.CrewChangeReportInputInfo.changeElement;

        this.subs.sink = this._reportService
            .exportCrewChangeReport(
                this.eventId,
                tempCrewChangeReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.showAlertMessage = true;
                        // $scope.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.child.fileName =
                            "Event-" + this.eventId + "-Crew Change";
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    // $scope.alerts = [{ type: "danger", msg: "Error Occurred" }];
                },
            });
    }

    ngOnInit(): void {
        this.getCrewChangeReportDetails();
        this.CrewChangeReportInputInfo = {};
        this.CrewChangeReportInputInfo.startDate = null;
        this.CrewChangeReportInputInfo.endDate = null;
        this.CrewChangeReportInputInfo.department = null;
        this.CrewChangeReportInputInfo.positionName = null;
        this.CrewChangeReportInputInfo.employeeType = null;
        this.CrewChangeReportInputInfo.changeElement = [];
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
