<div #backup id="" class="grid-backup-edit" style="width:280px !important;">
  <div class="tab-header clearfix">
    <span class="pull-left">Backup Contact</span>
    <span class="pull-right">
      <button type="button"
              aria-hidden="true"
              class="btn-close"
              (click)="close()">
      </button>
    </span>
  </div>
 
  <div *ngIf="BackupItems && BackupItems.length < 10" class="grid-contact-edit">
    <div class="tab-body clearfix">
      <span class="image-placeholder"></span>
      <input authenticate-user="event.crew.update"
             [(ngModel)]="contactSearchTerm"
             appInputRestriction="restrictChars"
             name="contactSearch"
             [typeahead]="contactObservable$"
             [typeaheadAsync]="true"
             [typeaheadOptionsLimit]="30"
             [typeaheadMinLength]="3"
             [typeaheadItemTemplate]="customItemTemplate"
             placeholder="Contact Name"
             class="form-control"
             [isAnimated]="true"
             [typeaheadWaitMs]="200"
             (typeaheadOnSelect)="onContactSelected($event)">
    </div>
  </div>


  <div *ngFor="let cpb of BackupItems;let $index=index;"
       class="grid-contact-read">
    <div class="tab-body clearfix" *ngIf="cpb.firstName">
      <div class="pull-right"
           authenticate-user="event.crew.update">
        <button class="btn btn-xs btn-danger webui-delete-backup-contact"
                data-id="{{listItemId}}-{{cpb.contactCode}}"
                (click)="onBackupRemoved($event,$index);">
          <i class=" fa fa-times-circle"></i>
        </button>
      </div>
      <div></div>
      <div class="pull-right"
           authenticate-user="event.crew.update">
        <button class="btn btn-xs btn-primary"
                (click)="onAssignPrimaryBackupContact($event,$index)"
                [disabled]="eventPosition?.status?.description === 'Confirmed'"
                 data-id="{{listItemId}}-{{cpb.contactCode}}">
          <i class=" fa fa-thumbs-up" ></i>
        </button>
      </div>
      <img class="alternate-contact img-contact"
           data-id="{{cpb.contactCode}}"
           src="/assets/image/contact/0"
           wid width="35"
           height="35" />
      <span class="changed-by-text" style="cursor:pointer">
        <div class="tooltip right" role="tooltip">
          <div class="tooltip-arrow"></div>
          <div class="tooltip-inner">
            {{cpb.firstName}} {{cpb.lastName}}
            <br> Email: {{cpb.backupContact?.email}}
            sso:{{(cpb.backupContact?.sso) && cpb.backupContact?.sso > 0 ? cpb.backupContact?.sso : ''}}
          </div>
        </div>
        <strong>{{cpb.firstName}}&nbsp;{{cpb.lastName}}</strong>
      </span>
    </div>
  </div>

</div>


<ng-template #customItemTemplate let-model="item" let-index="index">
  <!--<h5>This is: {{model | json}} Index: {{ index }}</h5>-->
  
    <strong>
      {{model.firstName ? model.firstName :model.code }}  {{model.contactMiddleName? model.contactMiddleName :""}} {{model.lastName? model.lastName :""}}
    </strong>

    <font size="2px" *ngIf="model.flaggedByHR" >
      <div class= "fa-stack fa-1x">
        <i class="fa fa-circle fa-stack-2x" style="color: red;"></i >
        <i class="fa fa-warning fa-stack-1x" style="color: yellow;"></i >
      </div >
    </font>
    <br/>
    <font size="2px">
      <small>
        <strong>Email:</strong> {{model.email ? model.email :'' }}
        <strong>SSO:</strong> {{model.sso ? model.sso :'' }}
        <strong>City:</strong>  {{model.city ? model.city:'' }}
        <strong>State:</strong> {{model.state ? model.state:'' }}
      </small>
    </font>
    
</ng-template>
