<div *ngIf="isGridAvailable && modifiedListItems?.length>0 && !isFullScreenMode"
     class="grid-table-container grid-table-crew-container" #topScroll (scroll)="syncMainScroll()"
     style="min-height: 30px; max-height:15px; overflow-x:auto; overflow-y:hidden;border:0px">
  <div #topchild>
    &nbsp;
  </div>
</div>
<div class="grid-table-container grid-table-crew-container" #scrollableDiv (scroll)="onScroll($event)" style="min-height: 640px">
  <div id=grid class="grid-table" *ngIf="isGridAvailable && modifiedListItems?.length>0">
    <ng-container>
      <div class="hdr-grid-row frozen-col-header" *ngIf="isFullScreenMode">
        <div class="hdr-grid-col grid-col-crew-select hdr-frozen">
          <input type="checkbox"
                 id="select-all-records"
                 name="headerchkbx"
                 [(checked)]="this.gridHeaderConfig.selectAllSelected"
                 authenticate-user="event.crew.update"
                 (click)="selectAllRecords()" />
        </div>
        <div class="hdr-grid-col grid-col-crew-contact hdr-frozen">
          Primary Contact
        </div>
        <div class="hdr-grid-col grid-col-crew-position hdr-frozen">
          Position
        </div>
        <div class="hdr-grid-col grid-col-crew-emptype hdr-frozen">
          Emp. Type / Location
        </div>
        <div class="hdr-grid-col grid-col-crew-rate hdr-frozen">
          Rate Code
        </div>
        <div class="hdr-grid-col grid-col-crew-comments hdr-frozen">
        </div>
        <div class="hdr-grid-scrollable" [ngStyle]="{'margin-left.px':marginLeft}">
          <div *ngFor="let scheduleDate of gridHeaderConfig.scheduleDates; index as scheduleDateIndex;"
               class="hdr-grid-col child text-nowrap hdr-scroll hdr-activity" style="min-width: 100px; max-width: 100px;"
               [ngClass]="show">
            {{moment(scheduleDate.date).format('ddd')}}<br />
            {{moment(scheduleDate.date).format('MMM D')}}
          </div>
        </div>

        <div class="hdr-grid-col hdr-scroll" [ngClass]="isFullScreenMode ? 'd-none' : ''"></div>
      </div>
      <div class="hdr-grid-row" [ngClass]="isFullScreenMode ? 'grid_visibility_none' : ''">
        <div class="hdr-grid-col grid-col-crew-select hdr-frozen">
          <input type="checkbox"
                 id="select-all-records"
                 name="headerchkbx"
                 [(checked)]="this.gridHeaderConfig.selectAllSelected"
                 authenticate-user="event.crew.update"
                 (click)="selectAllRecords()" />
        </div>
        <div class="hdr-grid-col grid-col-crew-contact hdr-frozen">
          Primary Contact
        </div>
        <div class="hdr-grid-col grid-col-crew-position hdr-frozen">
          Position
        </div>
        <div class="hdr-grid-col grid-col-crew-emptype hdr-frozen">
          Emp. Type / Location
        </div>
        <div class="hdr-grid-col grid-col-crew-rate hdr-frozen">
          Rate Code
        </div>
        <div class="hdr-grid-col grid-col-crew-comments hdr-frozen">
        </div>
        <div class="hdr-grid-scrollable" style="z-index: 0 !important;">
          <div *ngFor="let scheduleDate of gridHeaderConfig.scheduleDates; index as scheduleDateIndex;"
               class="hdr-grid-col child text-nowrap hdr-scroll hdr-activity" style="min-width: 100px; max-width: 100px;"
               [ngClass]="show">
            {{moment(scheduleDate.date).format('ddd')}}<br />
            {{moment(scheduleDate.date).format('MMM D')}}
          </div>
        </div>

        <div class="hdr-grid-col hdr-scroll">
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="isGridAvailable">
      <smooth-dnd-container [dragClass]="'opacity-ghost'"
                            [dropClass]="'opacity-ghost-drop'"
                            [dragHandleSelector]="'.ui-sortable-handle'"
                            (drop)="onRowDrop($event)"
                            #dragDropContainer
                            style="position: absolute; left: 0px;"
                            class="border-end overflow-auto">
        <smooth-dnd-draggable *ngFor="let listItem of modifiedListItems; let index=index; trackBy: trackItem"
                              class="grid-rows" id="row-{{listItem.id}}"
                              ng-attr-data-id="{{listItem.id}}" id="row-{{listItem.id}}" ng-attr-data-id="{{listItem.id}}">
          <div class="grid-animate grid-row">
            <div class="grid-col grid-col-crew-select">
              <div class="grid-itm grid-itm-checkbox" [ngClass]="listItem.eventPosition?.status?.description?.toLowerCase() ">
                <span class="handle dragable ui-sortable-handle"
                      authenticate-user="event.crew.update"
                      authenticate-command="hide">
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                  <i class="fa fa-square"></i>
                </span>
                <input type="checkbox"
                       class="crewCheckbox checkbox"
                       ng-attr-data-id="{{listItem.id}}"
                       name="checkbox_{{listItem.id}}"
                       [(ngModel)]="listItem.selected"
                       (change)="selectPosition(listItem.id)"
                       authenticate-user="event.crew.update"
                       authenticate-command="disabled" />
              </div>

            </div>
            <div class="grid-col grid-col-crew-contact" [ngClass]="listItem.hasContactFocus ? 'contactSearch' : ''">
              <div class="grid-itm grid-itm-order border-bottom">
                <input type="number"
                       class="input-number update-sort-el"
                       numbersOnly
                       value="{{listItem.sortOrder}}"
                       ng-attr-data-id="{{listItem.id}}"
                       data-original-sort="{{listItem.sortOrder}}"
                       authenticate-user="event.crew.update"
                       authenticate-command="readonly"
                       (blur)="onBlurSort($event,listItem.id,listItem.sortOrder)" />
              </div>
              <div class="grid-itm-contact-img">
                <span class="info-tip1 changed-by-text">
                  <div class="drag-glow" *ngIf="listItem?.eventPosition?.status?.description !== 'Confirmed'"
                       [cdkDropListData]="list2"
                       #temp{{listItem.id}}="cdkDropList"
                       cdkDropList id="list-{{listItem.id}}"
                       (cdkDropListDropped)="assignContactDnD($event,listItem.id)" style="width:35px;height:35px;">
                    <img *ngIf="listItem?.primaryContact" class="drag-glow droppable draggable primary-contact"
                         data-contact-type="primary"
                         src="/assets/image/contact/{{listItem.primaryContact.imageId}}"
                         width=" 35"
                         height="35" />
                  </div>

                  <img *ngIf="listItem?.eventPosition?.status?.description === 'Confirmed'" class="drag-glow"
                       ng-attr-data-id="{{listItem.id}}"
                       data-contact-type="primary"
                       src="/assets/image/contact/{{listItem?.primaryContact?.imageId}}"
                       width="35"
                       height="35" />

                  <span [ngClass]="{'icon-nohr': listItem?.rules?.showNoHrClass,'icon-conflict':listItem?.rules?.isConflictExists}">
                    <span *ngIf="listItem?.rules?.showNoHrClass" class="fa-stack ">
                      <i [style.color]="listItem?.rules?.canPositionRequest?'green':''" class="fa fa-circle fa-stack-2x"></i>
                      <i class="fa fa-user fa-stack-1x fa-inverse"></i>
                    </span>

                    <span *ngIf="listItem?.rules?.isConflictExists" class="fa-stack">
                      <i class="fa fa-circle fa-stack-2x"></i>
                      <i class="fa fa-calendar fa-stack-1x fa-inverse cursor-pointer"
                         (click)="openConflictModal(listItem.id)"
                         authenticate-user="event.crew.update"
                         authenticate-command="unbindclickevent"></i>
                    </span>
                    <div *ngIf="listItem?.rules?.showOnboardingStatus" class="tooltip right ms-4" role="tooltip">
                      <div class="tooltip-arrow"></div>
                      <div class="tooltip-inner">
                        {{listItem.onboardingStatus}}
                      </div>
                    </div>
                  </span>
                </span>
              </div>
              <div class="grid-itm grid-itm-contact">

                <div *ngIf="listItem.primaryContact" class="grid-contact-read">
                  <div class="grid-itm-crew-contact-ttl">
                    <div>
                      <div [dynamicHtmlTooltip]="listItem?.contactAddressTitle">
                        <a class="btn-link" routerLink="/Contacts/Detail/{{listItem.primaryContact?.id}}" target="_blank" style="white-space: nowrap">
                          {{listItem.primaryContact?.firstName}}&nbsp;{{(listItem.primaryContact?.hasOwnProperty('middleName')) ? listItem.primaryContact?.middleName : listItem.primaryContact?.contactMiddleName}}&nbsp;{{listItem.primaryContact?.lastName}}
                        </a>
                      </div>
                    </div>
                    <div>
                      <div *ngIf="listItem?.eventPosition?.status?.description === 'Confirmed'" class="form-group" style="position:unset;float:left;margin: 0;padding:5px;">
                        <div class='input-group date activity-datetimepicker' authenticate-user="true"
                             authenticate-command="disabled"
                             authenticate-user="event.crew.update"
                             authenticate-required="true"
                             style="position:unset;">
                          <input type="text"
                                 class="form-control form-control-md"
                                 style="margin:0;padding:0;width: 63px;height: 25px;font-size: 11px;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                 bsDatepicker
                                 [container]="'activity-datetimepicker'"
                                 readonly
                                 [bsValue]="listItem.confirmDate"
                                 [maxDate]="maxDatePositionRequestedDate"
                                 (bsValueChange)="resetCalenderCurrentDate($event,listItem.id);"
                                 [bsConfig]="{ isAnimated: true,dateInputFormat: 'MM/DD/YYYY' }">

                          <span class="input-group-text" style="font-size:13px;padding:4px 8px;">
                            <span class="fa fa-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div style="float:left;float: right;padding: 4px 2px 0 0px;" *ngIf="listItem?.canI9DocVerify"
                           (click)="onI9DocsVerified(listItem.id)"
                           authenticate-user="event.crew.update,contact.acknowledgement.retrieve,contact.acknowledgements.update"
                           authenticate-command="unbindclickevent">
                        <span class="fa-stack" authenticate-user="contact.acknowledgement.retrieve" authenticate-command="hide" style="text-align: center; border: 1px solid #ddd; width: 2em;">
                          <i class="fa fa-id-card cursor-pointer i9-docs-verified"
                             [style.color]="listItem.primaryContact?.i9DocsVerified?.color"
                             style="font-size:15px"
                             ng-attr-data-id="{{listItem.id}}"
                             [title]="listItem.primaryContact?.i9DocsVerified?.fieldBool? 'I-9 Documents Physically Viewed. ' + ((listItem.primaryContact?.i9DocsVerified?.updatedBy) ? 'Verified By: ' + listItem.primaryContact?.i9DocsVerified?.updatedBy + ' Verified On: ' + listItem.primaryContact?.i9DocsVerified?.modifiedDate : '' )  : 'I-9 Documents Not Physically Viewed' "
                             authenticate-command="unbindclickevent">
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>


                <div *ngIf="!listItem.primaryContact" class="grid-contact-edit">
                  <div class="form-inline">
                    <span class="droppable" ng-attr-data-id="{{listItem.id}}" data-contact-type="primary">
                    </span>
                    <nbc-contact-auto listItemId="{{listItem.id}}"
                                      placeholder="Contact Name"
                                      [(hasFocus)]="listItem.hasContactFocus"
                                      eventHandler="onContactSelect">
                    </nbc-contact-auto>
                  </div>
                </div>


                <div *ngIf="listItem?.eventPosition?.status?.description === 'Confirmed' && listItem.primaryContact && !listItem?.isTimeSheetSubmitted"
                     class="grid-itm-crew-contact-actions">
                  <div>
                    <button authenticate-user="event.crew.update"
                            type="button"
                            ng-attr-data-id="{{listItem.id}}"
                            class="btn btn-sm btn-primary unconfirm-contact p-1 ms-3"
                            (click)="onUnconfirmContact(listItem.id)">
                      <i class="fa fa-thumbs-down"></i>
                    </button>
                  </div>
                  <div>
                    <button authenticate-user="event.crew.update"
                            type="button"
                            class="btn btn-sm btn-default p-1 ms-3">
                      <i class="fa fa-times-circle"></i>
                    </button>
                  </div>
                </div>


                <div *ngIf="listItem?.eventPosition?.status?.description !== 'Confirmed' && listItem?.primaryContact" class="grid-itm-crew-contact-actions">
                  <div>
                    <button authenticate-user="event.crew.update"
                            type="button"
                            ng-attr-data-id="{{listItem.id}}"
                            class="btn btn-sm btn-success confirm-contact p-1 ms-3"
                            (click)="onConfirmContact(listItem.id)">
                      <i class=" fa fa-thumbs-up"></i>
                    </button>
                  </div>

                  <div *ngIf="(!listItem?.isTimeSheetSubmitted || (listItem.primaryContact?.canDelete && listItem?.isTimeSheetSubmitted))">
                    <button authenticate-user="event.crew.update"
                            type="button"
                            ng-attr-data-id="{{listItem.id}}"
                            class="btn btn-sm btn-danger delete-contact p-1 ms-3"
                            (click)="onDeleteContact(listItem.id)">
                      <i class=" fa fa-times-circle"></i>
                    </button>
                  </div>

                </div>
              </div>

            </div>
            <div class="grid-col grid-col-crew-position grid-group-title" [ngClass]="listItem.hasTooltipFocus ? 'tooltipShow' : ''">
              <div class="grid-itm grid-itm-title crew-screen">
                <div class="vertical-align-middle">
                  <strong class="text-uppercase">

                    <span *ngIf="listItem.eventPosition.jobTitleDetail">{{listItem.eventPosition?.jobTitleDetail}}</span>


                    <span *ngIf="!listItem.eventPosition.jobTitleDetail">{{listItem.eventPosition?.position?.description}}</span>

                  </strong>
                  <div class="text-wrapper d-flex">
                    {{listItem.eventPosition?.category }}

                    <span *ngIf="listItem.eventPosition.department">
                      | <span *ngIf="listItem.eventPosition?.department === 'Production'">Prod</span>
                      <span *ngIf="listItem.eventPosition?.department === 'Engineering'">Eng</span>
                      <span *ngIf="listItem.eventPosition?.department === 'Miscellanous'">Misc</span>
                    </span>


                    <div *ngIf="( listItem.eventPosition?.skills?.length>0 && listItem.eventPosition.skills[0]?.description) || listItem.eventPosition.notes"
                         class="changed-by-text control-inline">
                      <div class="info-tooltip"
                           [tooltip]="infoTooltip"
                           container="body"
                           placement="bottom">i</div>
                      <ng-template #infoTooltip>
                        <div class="bottom text-nowrap" role="tooltip">
                          <div>

                            <span *ngIf="listItem.eventPosition?.skills?.length>0">
                              Skills:

                              <span *ngFor="let skill of listItem.eventPosition.skills; let skillIndex=index;">

                                <span *ngIf="skillIndex>0">,</span>

                                {{skill.description}}
                              </span>

                              <br />
                            </span>


                            <span *ngIf="listItem.eventPosition?.notes">
                              Notes: <br>
                              <span> {{listItem.eventPosition.notes}}</span>
                            </span>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                  </div>
                </div>
              </div>

              <div>
                <div class="grid-itm-title-actions">
                  <button authenticate-user="event.crew.update"
                          type="button"
                          ng-attr-data-id="{{listItem.id}}"
                          (click)="onEditPosition($event,listItem.id)"
                          class="btn btn-sm btn-info edit-position p-1 text-white d-flex ms-3">
                    <i class="fa-solid fa-pencil"></i>
                  </button>
                </div>

                <div *ngIf="listItem?.primaryContact && listItem.primaryContact?.isNonVendor && !listItem.primaryContact?.isHrVerified && (listItem?.eventPosition?.status?.description | lowercase) === 'confirmed' && listItem?.eventPosition?.position?.groupType?.description !== 'Talent' && listItem?.canPositionRequest"
                     style="position:relative">
                  <button style="background-image: url('../../../../../Content/images/Submit.png');width:17px;height:17px;position: absolute;right: 15px;top: 30px;"
                          authenticate-user="event.crew.update"
                          type="button"
                          ng-attr-data-id="{{listItem.id}}"
                          class="btn btn-sm position-request p-1 ms-3"
                          (click)="onEditPositionRequestModal(listItem.id)"
                          [disabled]="listItem.isOnboardingPendingContact !== 'N'"></button>
                </div>



                <div *ngIf="!listItem?.primaryContact && listItem?.crewPositionBackupCount === 0" class="grid-itm-title-actions" style="position:relative">
                  <span authenticate-user="event.crew.delete">
                    <button type="button"
                            authenticate-user="event.crew.delete"
                            authenticate-command="disabled"
                            ng-attr-data-id="{{listItem.id}}"
                            class="btn btn-sm btn-danger delete-position p-1 d-flex ms-3"
                            style="position:absolute;right: 1px;top: 25px;"
                            (click)="onDeletePosition(listItem.id)">
                      <i class="fa fa-times-circle"></i>
                    </button>
                  </span>
                </div>

                <div style="display: inline-block;width: 2px;position: absolute;">
                  <div class=" grid-itm grid-itm-comments open-position-modal cursor-pointer p-0"
                       style="padding-top:0px;width: 17px;background-color:#e6b800!important;display: inline-table;"
                       ng-attr-data-id="{{listItem.id}}"
                       authenticate-user="event.regmodal.retrieve"
                       authenticate-command="unbindclickevent"
                       (click)="openHrOnboardingModal(listItem.id);">
                    <div class="grid-col text-center">
                      <i class="fa fa-ellipsis-h cursor-pointer"></i>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="grid-col grid-col-crew-emptype">
              <async-loader [state]="listItem.asyncDataSave.employeeTypeState"
                            [errorText]="">
              </async-loader>
              <async-loader [state]="listItem.asyncDataSave.additionalLocationState"
                            [errorText]="">
              </async-loader>
              <div class="grid-itm grid-itm-type" [ngClass]="{'employeeTypeMissing':(!listItem.eventPosition?.employeeType || listItem.eventPosition?.employeeType?.description == '') && listItem.eventPosition?.status?.id.toLowerCase() == 'c'}">
                <nbc-select [source]="allEmployeeTypes"
                            [(model)]="listItem.eventPosition.employeeType"
                            display="description"
                            value="id"
                            defaultSelectClass="dropdown-toggle form-select border"
                            authenticate-user="event.crew.update"
                            authenticate-command="nbcselectdisabled"
                            nbcClass="employee-type"
                            [nbcAddBlank]="true"
                            (change)="selectEmployeeType(listItem.id);">
                </nbc-select>
                <br />
                <nbc-select [source]="additionalLocations"
                            [(model)]="listItem.additionalLocation"
                            display="locationName"
                            value="locationId"
                            defaultSelectClass="dropdown-toggle form-select border"
                            authenticate-user="event.crew.update"
                            authenticate-command="nbcselectdisabled"
                            placeHolderDisplay="Select Location"
                            placeHolderValue="0"
                            [(nbcMismatch)]="listItem.additionalLocationClass"
                            nbcClass="location"
                            [nbcAddBlank]="true"
                            (change)="selectAdditionalLocation(listItem.id);">
                </nbc-select>
              </div>
            </div>
            <div class="grid-col grid-col-crew-rate">
              <async-loader [state]="listItem.asyncDataSave.rateCodeState"
                            [errorText]="">
              </async-loader>
              <!--Normal WOrkflow-->
              <div *ngIf="((!listItem?.isTieredEvent && listItem.eventPosition.category==='BTL')||listItem.eventPosition.category==='ATL')"
                   [ngClass]="{'custom-rate': (listItem.eventPosition.rateCode && (listItem.eventPosition.rateCode.id === '00'|| listItem.eventPosition.rateCode.id === 'FLAT DAY')) , 'minimum-wage-warning' : ((listItem.eventPosition.rateCode) && (listItem.customRate > 0 ) && (listItem.eventPosition.rateCode.id !== 'FLAT DAY') && (listItem.customRate < minimumWage)) || listItem.highlight == true || ((listItem.eventPosition.rateCode) && (listItem.eventPosition.rateCode.id !== 'FLAT DAY') && (listItem.eventPosition.rateCode.billingRate < minimumWage)) || ((listItem.billingRateForMinWageHighlight !== null ) && listItem.billingRateForMinWageHighlight < minimumWage),'rateMissing':listItem.eventPosition?.status?.id?.toLowerCase() == 'c' && ((listItem.eventPosition?.employeeType?.description != 'NY' && listItem.eventPosition?.employeeType?.description != 'Staff' && listItem.eventPosition?.employeeType?.description != 'Stamford' && listItem.eventPosition?.employeeType?.description != 'Vendor') && ( !listItem.eventPosition?.rateCode || ((listItem.eventPosition?.rateCode?.id === '00'|| listItem.eventPosition?.rateCode?.id === 'FLAT DAY') && (!listItem.customRate || listItem.customRate <=0))))}"
                   class="grid-itm grid-itm-rate">
                <span authenticate-user="financial.rates.retrieve" authenticate-command="hide">
                  <nbc-select *ngIf="listItem.eventPosition?.employeeType?.id && listItem.eventPosition?.employeeType?.description?.toLowerCase() !== 'vendor'"
                              [source]="listItem.eventPosition.employeeType.rateCards"
                              [model]="listItem.eventPosition.rateCode"
                              display="name"
                              value="id"
                              [nbcAddBlank]="true"
                              defaultSelectClass="dropdown-toggle form-select border"
                              authenticate-user="event.crew.update"
                              authenticate-command="nbcselectdisabled"
                              nbcClass="rate-code"
                              ng-attr-data-id="{{listItem.id}}"
                              (modelChange)="selectRateCode($event,listItem.id)">
                  </nbc-select>


                  <ul *ngIf="(listItem.eventPosition?.rateCode?.id === '00' || listItem.eventPosition?.rateCode?.id === 'FLAT DAY')"
                      class="list-inline custom-rate-field d-flex">
                    <li>$</li>
                    <li>
                      <input *ngIf="listItem.eventPosition?.rateCode?.id === '00'"
                             type="text"
                             class="custom-rate-field"
                             [(ngModel)]="listItem.customRate"
                             placeholder="---.--"
                             maxlength="7"
                             id="rate{{listItem.id}}"
                             ng-attr-data-id="{{listItem.id}}"
                             authenticate-user="event.crew.update"
                             authenticate-command="disabled"
                             (blur)="setCustomRate(listItem.id)"
                             (keydown)="validateCustomRate($event,listItem.id)" />

                      <input *ngIf="listItem.eventPosition?.rateCode?.id === 'FLAT DAY'"
                             type="text"
                             class="custom-flat-rate-field"
                             [(ngModel)]="listItem.customRate"
                             placeholder="----.---"
                             maxlength="8"
                             style="font-size:10px !important;"
                             ng-attr-data-id="{{listItem.id}}"
                             (keydown)="validateCustomFlatRate($event,listItem.id)"
                             (blur)="setCustomFlatRate(listItem.id)"
                             authenticate-user="event.crew.update"
                             authenticate-command="disabled" />
                    </li>
                  </ul>
                </span>
                <span *ngIf="listItem.eventPosition?.employeeType?.description?.toLowerCase() === 'vendor'"
                      class="disabled">N/A</span>
              </div>
              <!--Tiered Workflow-->
              <div *ngIf="((listItem?.isTieredEvent && listItem.eventPosition?.category==='BTL'))"
                   [ngClass]="{'custom-rate':listItem?.eventPosition?.rateCode && (listItem.eventPosition?.category==='BTL'),'exceed-tier-rate':(listItem?.customRateExeedsBy && listItem?.customRateExeedsBy>0), 'tier-minimum-wage-warning' : (listItem?.customRate && listItem?.customRate < minimumWage),'rateMissing':listItem.eventPosition?.status?.id?.toLowerCase() == 'c' && ((listItem.eventPosition?.employeeType?.description != 'NY' && listItem.eventPosition?.employeeType?.description != 'Staff' && listItem.eventPosition?.employeeType?.description != 'Stamford' && listItem.eventPosition?.employeeType?.description != 'Vendor') && (!listItem.customRate || listItem.customRate <=0))}"
                   class="grid-itm grid-itm-rate-tieredrate">
                <span authenticate-user="financial.rates.retrieve" authenticate-command="hide">


                  <nbc-select *ngIf="listItem.eventPosition?.employeeType?.description?.toLowerCase() !== 'vendor'"
                              [source]="allTieredPositions"
                              [model]="listItem.eventPosition.rateCode"
                              display="desc"
                              value="billing_code"
                              [nbcAddBlank]="true"
                              authenticate-user="event.crew.update"
                              authenticate-command="nbcselectdisabled"
                              nbcClass="rate-code"
                              (modelChange)="selectRateCode($event,listItem.id)">
                  </nbc-select>


                  <ul *ngIf="listItem.eventPosition?.rateCode && (listItem.eventPosition?.category==='BTL')" class="list-inline custom-rate d-flex p-0">
                    <li>$</li>
                    <li>

                      <input *ngIf="(listItem.eventPosition?.category==='BTL')"
                             type="text"
                             nbc-demical
                             decimals="3"
                             [ngClass]="{'tier-high-rate':(listItem?.customRateExeedsBy>0)}"
                             [(ngModel)]="listItem.customRate"
                             placeholder="---.---"
                             maxlength="7"
                             id="rate{{listItem.id}}"
                             (click)="onTierrateTextboxClick(listItem.id)"
                             ng-attr-data-id="{{listItem.id}}"
                             authenticate-user="event.crew.update"
                             authenticate-command="disabled" readonly="readonly" />

                      <i ng-attr-data-id="{{listItem.id}}"
                         authenticate-user="event.crew.update"
                         authenticate-command="hide"
                         (click)="onTierrateTextboxClick(listItem.id)"
                         class="fa-solid fa-pencil modify-tierrate"></i>

                    </li>
                  </ul>
                </span>
                <span *ngIf="listItem.eventPosition?.employeeType?.description?.toLowerCase() === 'vendor'"
                      class="disabled">N/A</span>
              </div>
            </div>
            <div class="grid-col grid-col-crew-comments" style="width:25px;">
              <div class="grid-itm grid-itm-crew-comments" style="padding-top:unset;width:25px">
                <div class="position-notes">
                  <div *ngIf="listItem.eventPosition?.comments">
                    <div class="popover-btn-wrapper">
                      <button type="button"
                              [popover]="workScheduleCommentTemplate" container="body" boundariesElement="viewport" placement="right" [outsideClick]="true"
                              ng-attr-data-id="{{listItem.id}}"
                              class="btn btn-no-style edit-comment">
                        <i class="fa fa-comment cursor-pointer"></i>
                      </button>
                    </div>
                    <ng-template #workScheduleCommentTemplate>
                      <h3 class="popover-header">
                        Work Schedule Comment &nbsp;
                        <i class="fa-solid fa-pencil float-end cursor-pointer webui-popover-edit-comment-btn"
                           authenticate-user="event.crew.update"
                           authenticate-command="hide"
                           (click)="leaveComment(listItem.id)"></i>
                      </h3>
                      <div class="popover-body p-2" style="word-wrap:break-word;width:250px;">
                        {{listItem.eventPosition.comments}}
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="!listItem.eventPosition?.comments">
                    <button type="button"
                            class="btn btn-no-style comment-btn"
                            ng-attr-data-id="{{listItem.id}}"
                            (click)="leaveComment(listItem.id)"
                            authenticate-user="event.crew.update"
                            authenticate-command="disabled">
                      <i class="fa fa-comment-o cursor-pointer"></i>
                    </button>
                  </div>
                  <div *ngIf="(listItem?.isTieredEvent && listItem?.tierRateCardComment)">
                    <div class="popover-btn-wrapper">
                      <button type="button" class="btn btn-no-style tierratecomment-btn"
                              title="Tier Rate Card Comment"
                              [popover]="tierRateCardCommentTemplate" container="body" boundariesElement="viewport" placement="right" [outsideClick]="true"
                              authenticate-user="event.tierRateComment.retrieve" authenticate-command="disabled">
                        <i class="fa fa-envelope-square cursor-pointer"></i>
                      </button>
                    </div>
                    <ng-template #tierRateCardCommentTemplate>
                      <h3 class="popover-header">
                        Tier Rate Card Comment &nbsp;
                      </h3>
                      <div class="popover-body p-2" style="word-wrap:break-word;width:250px;">
                        {{listItem.tierRateCardComment}}
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-col-scrollable" [ngStyle]="{'margin-left.px':marginLeft}">
              <div *ngFor="let workScheduleDate of listItem.eventPosition.workScheduleDates;let workworkScheduleDateIndex=index;"
                   class="grid-col child {{workScheduleDate.className}}"
                   style="min-width:100px;max-width:100px;"
                   [ngClass]="show"
                   (click)="setWorkSchedulePopover(workScheduleDate,listItem.id,workworkScheduleDateIndex)"
                   id="{{workScheduleDate?.id}}">
                <div [popover]="popTemplate" class="grid-item" [adaptivePosition]="true" container="grid-rows" boundariesElement="viewport" [outsideClick]="true" [delay]="100">
                  <!--Suraj: Upgrade ang 15: commenting as it is causing an issue with workactivity popover positioning. Not able to identity the usability.-->
                  <!--<div authenticate-user="event.crew.update">
                  <span class="popover-btn-clear"></span>
                </div>-->
                  <div class="activity-webui-popover grid-itm grid-itm-date relative"
                       data-day="{{workScheduleDateIndex}}"
                       data-id="{{listItem.id}}">


                    <span *ngIf="workScheduleDate.plannedWorkActivities && workScheduleDate.plannedWorkActivities?.length>0">
                      <div class="hdr-grid-date-inline">
                        {{workScheduleDate.displayDay}}
                        <br />
                        {{workScheduleDate.displayDate}}
                      </div>

                      <span *ngFor="let plannedActivity of workScheduleDate.plannedWorkActivities; let plannedActivityIndex=index"
                            class="crew-activity activity-1 activity-{{plannedActivity.code}}">
                        <span>{{plannedActivity.code}}</span>

                      </span>
                    </span>

                  </div>
                </div>
              </div>
            </div>

            <div class="grid-col">

            </div>
          </div>
        </smooth-dnd-draggable>
      </smooth-dnd-container>
    </ng-container>
  </div>   
  </div>

  <ng-template #popTemplate>
    <div work-activity *ngIf="workSchedulePopover?.showPopover"
         [(toggle)]="workSchedulePopover.showPopover"
         maxAllowedActivity="4"
         id="{{workSchedulePopover.id}}"
         day="{{workSchedulePopover.index}}"
         (workActivitiesChange)="saveWorkScheduleDay($event)"
         parentId="{{workSchedulePopover.parentId}}"
         showSaveCancelBtn="{{true}}"
         [workActivities]="workSchedulePopover.workActivities"></div>
  </ng-template>


  <ng-template #asyncFailedTooltipTemplate>
    <div>This operation encountered an error</div>
  </ng-template>
