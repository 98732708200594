import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class tierSetupWizardController {

  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }  


 
    getNetworksetup(networkId): Observable<any> {
        this.log.trace("tierSetupWizardController: get tier");
        return this.http.get(this._apiroot.baseUrl + "tierratecard/wizard/networksetup/"+ networkId).pipe(
          catchError((err) => {
            return throwError(err);
          })
        );;
      };
    


}
