import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { SubSink } from "subsink";
import { OnboardingService } from "src/app/Shared/Services/onboarding.service";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { NgForm } from "@angular/forms";
import { debug } from "console";

@Component({
    selector: "editPositionRequest-onb",
    templateUrl: "./editPositionRequest.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/hr/hr.css",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class EditPositionRequestComponent implements OnInit, OnDestroy {
    hrOnboardingItem: any;
    crewRegNo: any;
    contactId: any;
    eventId: any;
    positionRequestInfo: any;
    isRequiredValidation = true;
    submitted: any;
    date: any;
    positionStartDate: any;
    productionManager: any;
    reportsTo: any;
    contacts: any;
    modelValue: any;
    result: any;

    public onSync!: Subject<any>;
    frmEditPositionRequest: any;
    disableSubmitPositionRequestButtonOnClick = false;

    posType: any;
    positionType: any = {
        id: "0",
        description: "",
    };

    compCode: any;
    companyCode: any = {
        id: "0",
        description: "",
    };

    flsa: any;

    companyCodeDDl = {
        options: [
            { id: 1, description: "Sports L0VA" },
            { id: 2, description: "Olympics L076" },
            { id: 3, description: "Olympic Channel L0VF" },
        ],
    };

    positionTypeDDl = {
        options: [
            { id: 1, description: "PEP Casual" },
            { id: 2, description: "Daily Hire NABET" },
            { id: 3, description: "Daily Hire DGA" },
            { id: 4, description: "PEP IATSE" },
        ],
    };

    //to hold the values before update

    beforeEditPosStartDate: any;
    beforeEditCompCode: any;
    beforeEditPosType: any;

    isDirtyPositionStartDate = false;
    isDirtyPositionType = false;
    isDirtyCompanyCode = false;

    isFormDirty = true;
    crewWorkSchedule_startDate: any;
    crewWorkSchedule_EndDate: any;

    showAlertMessage = false;

    constructor(
        public bsModalRef: BsModalRef,
        private _onboardingService: OnboardingService,
        private log: NbcLoggerService
    ) {
        // this.getPositionRequestInfo(this.crewRegNo, this.contactId, this.eventId, this.hrOnboardingItem);
    }

    /*  public onSync!: Subject<any>;*/
    /*  showAlertMessage: boolean = false;*/
    modalTitle = "";
    isPositionStartDateInvalid = false;
    //isAdd: boolean = false;
    private subs = new SubSink();
    //submitted: boolean = false;

    //Closing Modal
    closeModal() {
        /*    console.log(this.bsModalRef);*/
        this.bsModalRef.hide();
    }

    getPositionRequestInfo(
        crewRegistationNo,
        contactId,
        eventId,
        hrOnboardingItem
    ) {
        this.log.trace("Get single position request info for updating request");
        this.subs.sink = this._onboardingService
            .getPositionRequestInfo(
                crewRegistationNo,
                contactId,
                eventId,
                hrOnboardingItem
            )
            .subscribe(
                (result) => {
                    // this.log.trace("getAllNetworks got data" + JSON.stringify(result));

                    this.positionRequestInfo = result.reference;

                    this.log.trace(
                        "Get single position request result " +
                            JSON.stringify(result)
                    );

                    /*        console.log(this.positionRequestInfo);*/
                    this.positionStartDate = new Date(
                        this.positionRequestInfo.position.positionStartDate
                    );
                    this.companyCode =
                        this.positionRequestInfo.position.companyCode;
                    this.posType =
                        this.positionRequestInfo.position.positionType;
                    this.flsa = this.positionRequestInfo.contacts[0].flsa;
                    this.productionManager =
                        this.positionRequestInfo.event.onsiteProductionManager?.description;
                    this.reportsTo =
                        this.positionRequestInfo.event.reportsToManager?.description;
                    this.contacts = this.positionRequestInfo.contacts;
                    this.crewWorkSchedule_EndDate =
                        this.positionRequestInfo.event.crewWorkEndDate;
                    this.crewWorkSchedule_startDate =
                        this.positionRequestInfo.event.crewWorkStartDate;

                    this.beforeEditPosStartDate = this.positionStartDate;
                    this.beforeEditCompCode = this.companyCode;
                    this.beforeEditPosType = this.posType;

                    //populate selected value of positionType DD
                    _.forEach(this.positionTypeDDl.options, (pType) => {
                        if (pType.description == this.posType) {
                            this.positionType = {
                                id: pType.id,
                                description: pType.description,
                            };
                        }
                    });

                    //populate selected value of companyCode DD
                    _.forEach(this.companyCodeDDl.options, (cCode) => {
                        if (cCode.description == this.companyCode) {
                            this.companyCode = {
                                id: cCode.id,
                                description: cCode.description,
                            };
                        }
                    });
                },
                (err) => {
                    //this.log.error('getAllNetworks got an error: ' + JSON.stringify(err));
                },
                () => {}
            );
    }

    PositionStartDateValidation() {
        this.log.trace(
            "Check if position start date is between CWS start and end dates"
        );
        this.isPositionStartDateInvalid = false;

        const alteredStartDate = new Date(
            this.positionStartDate
        ).toDateString();
        //console.log(alteredStartDate);

        if (
            new Date(alteredStartDate) <
                new Date(this.crewWorkSchedule_startDate) ||
            new Date(alteredStartDate) > new Date(this.crewWorkSchedule_EndDate)
        ) {
            this.isPositionStartDateInvalid = true;
        }
        return this.isPositionStartDateInvalid;
    }

    savePositionRequest(frmEditPositionRequest: NgForm) {
        this.isFormDirty = this.validateCustomControls();
        this.PositionStartDateValidation();

        if (!this.isFormDirty) {
            this.log.trace("No data updated in the modal .");
            this.log.showModalError("There are no changes saved.");
            this.showAlertMessage = true;
        }

        if (
            frmEditPositionRequest.valid &&
            this.isFormDirty &&
            !this.isPositionStartDateInvalid
        ) {
            this.disableSubmitPositionRequestButtonOnClick = true;
            this.save();
        } else {
            this.submitted = false;
            this.isFormDirty = false;
        }

        //if (frmEditPositionRequest.valid && this.validateCustomControls() && this.isPositionStartDateInvalid === false) {
        //  this.disableSubmitPositionRequestButtonOnClick = true;
        //  this.save();
        //}
    }

    validateCustomControls() {
        if (this.beforeEditCompCode != this.companyCode.description) {
            this.isDirtyCompanyCode = true;
        }

        if (this.beforeEditPosStartDate != this.positionStartDate) {
            this.isDirtyPositionStartDate = true;
        }

        if (this.beforeEditPosType != this.positionType.description) {
            this.isDirtyPositionType = true;
        }

        return (
            this.isDirtyCompanyCode ||
            this.isDirtyPositionStartDate ||
            this.isDirtyPositionType
        );

        //return (this.productionManager
        //  && this.reportsTo
        //  && this.companyCode
        //  && this.positionType
        //  && this.flsaStatus)
    }

    save() {
        this.log.trace("save updated Position Request");
        this.positionRequestInfo.position.companyCode =
            this.companyCode.description;
        this.positionRequestInfo.position.positionType =
            this.positionType.description;
        this.positionRequestInfo.position.positionStartDate =
            this.positionStartDate.toDateString();
        const savePosRequest = this.positionRequestInfo;
        this.subs.sink = this._onboardingService
            .updateOnboardingPositionRequest(
                this.eventId,
                this.positionRequestInfo
            )
            .subscribe(
                (result) => {
                    this.result = result.reference;
                    // this.submitted = true;
                    //  this.onSync.next(result);
                    this.log.showSuccess(
                        "The Position Request has been successfully updated"
                    );
                    this.onSync.next(
                        "The Position Request has been successfully updated."
                    );
                    this.isFormDirty = false;
                    this.bsModalRef.hide();
                },
                (err) => {
                    console.log(err.error);
                    this.log.trace(
                        "The position request was not updated " + err.error
                    );
                    this.log.showModalError(
                        "The position request was not updated."
                    );
                    this.showAlertMessage = true;
                    //          this.log.showModalError('Cannot have duplicate Network Names!');
                    ///*          this.showAlertMessage = true;*/
                    //this.submitted = false;
                }
            );
    }

    cancel() {
        this.log.trace("cancel edit position request modal");
        this.isRequiredValidation = false;
        this.bsModalRef.hide();
        this.submitted = false;
        this.isFormDirty = false;

        //this.traverseRequest("Cancel");
        //if (this.currentRequest < this.positionRequestsLength) {
        //  this.loadPositionsToUi(this.positionRequests);
        //  this.isRequiredValidation = false;
        //}
        //if (this.currentRequest >= this.positionRequestsLength) {
        //  this.onPositionRequestSync.next(false);
        //  this._bsModalRef.hide();
        //}
        //this.clearDropDowns();
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.hrOnboardingItem = this.hrOnboardingItem;
        this.crewRegNo = this.crewRegNo;
        this.contactId = this.contactId;
        this.eventId = this.eventId;
        //console.log(this.eventId);
        //console.log(this.contactId);
        //console.log("in init of edit component");
        //console.log(this.crewRegNo);
        this.getPositionRequestInfo(
            this.crewRegNo,
            this.contactId,
            this.eventId,
            this.hrOnboardingItem
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
