import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { NbcBaseComponent } from "../../../../Core/component/Base.Component";

@Component({
    selector: "glaccount",
    templateUrl: "./glaccount.component.html",
})
export class GlAccountComponent
    extends NbcBaseComponent
    implements OnInit, OnChanges
{
    constructor(
        private _payrollService: PayrollService,
        private _eventBus: EventBusService,
        private log: NbcLoggerService
    ) {
        super();
    }

    @Input() model: any | undefined;
    @Input() name: string | undefined;
    @Input() mpId: string | undefined;
    @Input() nbcClass: string | undefined;
    @Input() isRequired = false;
    @Input() placeHolder: any | undefined;
    @Input() nbcAuthenticateUser: any | undefined;
    @Input() nbcAuthenticateCommand: any | undefined;
    @Input() nbcAuthenticateDisabled: any | undefined;
    @Input() nbcAuthenticateRequired: any | undefined;
    @Input() isBulkUpdate = false;

    @Output() modelChange = new EventEmitter<any>();
    @Output() glAccountChange: EventEmitter<any> = new EventEmitter<any>();

    isAuthorized = true;
    glAccountObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<any> = new Subject();

    searchTerm = "";

    getGLAccounts() {
        this.log.trace("get GL Account");
        this.glAccountObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.searchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._payrollService.searchGlAccounts(
                    encodeURIComponent(token)
                );
            })
        );
    }

    EmitModelData() {
        this.modelChange.emit(this.searchTerm);

        const data = {
            mpId: this.mpId,
            glAccount: this.searchTerm,
        };
        this.glAccountChange.emit(data);
    }

    onValueSelected($event) {
        const glAccount = $event.item;
        this.modelChange.emit(glAccount);

        const data = {
            mpId: this.mpId,
            glAccount: glAccount,
        };
        this.glAccountChange.emit(data);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                this.model = current;
                this.searchTerm = this.model;
            }
        }
    }

    ngOnInit() {
        this.searchTerm = this.model;
        this.getGLAccounts();

        this.isAuthorized = this.authorize(this.nbcAuthenticateUser);
    }
}
