<input [(ngModel)]="searchTerm"
       appInputRestriction="numbersonly"
       name="{{name}}"
       class="{{nbcClass}}"
       [typeahead]="glAccountObservable$"
       [typeaheadAsync]="true"
       [typeaheadOptionsLimit]="10"
       [isAnimated]="true"
       [typeaheadWaitMs]="200"
       typeaheadMinLength ="5"
       (ngModelChange)="EmitModelData()"
       [required]="isRequired"
       placeholder="{{placeHolder}}"
       [disabled]="!isAuthorized"
       authenticate-user="nbcAuthenticateUser"
       authenticate-command="nbcAuthenticateCommand"
       authenticate-required="nbcAuthenticateRequired"
       authenticate-disabled="nbcAuthenticateDisabled"
       (typeaheadOnSelect)="onValueSelected($event)">
