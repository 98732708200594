<div>
  <form role="form"
        name="jobTitleSetupForm"
        novalidate>

    <div class="content-filters">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label"
                   style="display:inline;">Job Title</label>

            <input [(ngModel)]="queryContainer.query.groupCode"
                   style="width:50%;display:inline;margin-left:5%;"
                   id="groupCode"
                   name="groupCode"
                   autocomplete="off"
                   class="form-control"
                   [isAnimated]="true"
                   [typeaheadWaitMs]="200"
                   [typeaheadMinLength]="2"
                   [typeahead]="JobTitleObservable$"
                   [typeaheadAsync]="true"
                   typeaheadOptionField="groupName"
                   [typeaheadOptionsLimit]="20">



          </div>
        </div>
        <div class="col-sm-3">
          <div class="filter-actions">
            <button class="btn btn-secondary"
                    [disabled]="queryContainer.query.groupCode == null || queryContainer.query.groupCode=='' "
                    (click)="searchName();"
                    tabindex="8">
              Search
            </button>
            <button class="btn btn-default"
                    (click)="reset();"
                    tabindex="9">
              Reset
            </button>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="tab-action-btns form-group">
            <a href="javascript:void(0)"
               class="btn btn-primary"
               (click)="openAddJobCodeModal(true,null)">+ADD NEW JOB TITLE</a>
          </div>

        </div>
      </div>
    </div>
    <div class="tab-page-content">
      <div class="row view-options">
        <div class="col-md-3">
          <div class="form-inline form-sort-options">
            <div class="form-group"
                 style="margin-top:1.8%;">
              <nbc-page-size [source]='pageSizeList'
                             [model]="queryContainer.query.pageSize"
                             (nbcValueChange)="searchPage($event)"></nbc-page-size>            

            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group text-right d-flex"
               style="margin-top:1.2%;">
            <label class="pagination-results ms-auto">
              <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                currentPage="{{queryContainer.query.currentPage}}}"
                                totalRecords="{{queryContainer.query.totalRecords}}"></pagination-label>
            </label>

            <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                            itemsPerPage="{{queryContainer.query.pageSize}}"
                            [(currentPage)]="queryContainer.query.currentPage"
                            (pageChanged)="search()"></nbc-pagination>

          </div>
        </div>
      </div>
      <div class="row"
           NgIf="resultsFound">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="dark-header"
                     id="headerDiv"
                     #stickyMenu
                     [class.stickyTableHeader]="sticky">
                <tr>
                  <th class="col-md-2">
                    <a href="javascript:void(0)"
                       (click)="sortByColumn('groupCode');">
                      JOB TITLE CODE
                      <span class="th-sort">
                        <span *ngIf="queryContainer.query.sortByColumn === 'groupCode'">
                          <i *ngIf="!queryContainer.query.reverseSort"
                             class="fa fa-caret-up"></i>
                          <i *ngIf="queryContainer.query.reverseSort"
                             class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </a>
                  </th>
                  <th class="col-md-2">
                    <a href="javascript:void(0)"
                       (click)="sortByColumn('groupName');">
                      JOB TITLE
                      <span class="th-sort">
                        <span *ngIf="queryContainer.query.sortByColumn === 'groupName'">
                          <i *ngIf="!queryContainer.query.reverseSort"
                             class="fa fa-caret-up"></i>
                          <i *ngIf="queryContainer.query.reverseSort"
                             class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </a>
                  </th>
                  <th class="col-md-2">
                    <a href="javascript:void(0)"
                       (click)="sortByColumn('department');">
                      DEPARTMENT
                      <span class="th-sort">
                        <span *ngIf="queryContainer.query.sortByColumn === 'department'">
                          <i *ngIf="!queryContainer.query.reverseSort"
                             class="fa fa-caret-up"></i>
                          <i *ngIf="queryContainer.query.reverseSort"
                             class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </a>
                  </th>
                  <th class="col-md-2">
                    <a href="javascript:void(0)"
                       (click)="sortByColumn('groupType');">
                      groupType
                      <span class="th-sort">
                        <span *ngIf="queryContainer.query.sortByColumn === 'groupType'">
                          <i *ngIf="!queryContainer.query.reverseSort"
                             class="fa fa-caret-up"></i>
                          <i *ngIf="queryContainer.query.reverseSort"
                             class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </a>
                  </th>
                  <th class="col-md-2">
                    <a href="javascript:void(0)"
                       (click)="sortByColumn('flsastaus');">
                      FLSA Status
                      <span class="th-sort">
                        <span *ngIf="queryContainer.query.sortByColumn === 'flsastaus'">
                          <i *ngIf="!queryContainer.query.reverseSort"
                             class="fa fa-caret-up"></i>
                          <i *ngIf="queryContainer.query.reverseSort"
                             class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </a>
                  </th>

                  <th style="cursor:default;"
                      class="col-md-2">STATUS</th>
                  <th style="cursor:default;"
                      class="col-md-2"></th>
                  <th style="cursor:default;"
                      class="col-md-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let jobInfo of jobCodeDetails?.jobCodeInfo">
                  <td class="col-md-2">{{jobInfo.groupCode}}</td>
                  <td class="col-md-2">{{jobInfo.groupName}}</td>
                  <td class="col-md-2">{{jobInfo.department.description}}</td>
                  <td class="col-md-2">{{jobInfo.groupType}}</td>
                  <td class="col-md-2">{{jobInfo.flsaStatus}}</td>
                  <td class="col-md-2">

                    <bSwitch name="{{jobInfo.groupCode}}"
                             [switch-on-text]="onText"
                             [switch-off-text]="offText"
                             [(ngModel)]="jobInfo.active"
                             [switch-on-color]="'success'"
                             [switch-off-color]="'danger'"
                             [switch-handle-width]="30"
                             [switch-size]="'mini'"
                             (click)="updateStatus(jobInfo)">
                    </bSwitch>


                  </td>
                  <td class="col-md-2 text-right">
                    <span class="edit-row">
                      <a href="javascript:void(0)"
                         class="btn btn-primary"
                         (click)="openAddJobCodeModal(false,jobInfo.groupCode)"><i class="fa-solid fa-pencil fa-lg"></i></a>
                    </span>
                  </td>
                  <td class="col-md-1"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left:1%;"
         *ngIf="!resultsFound">No Results.</div>
  </form>
</div>
