<!--<div class="dropdown nbc-dropdown {{nbcClasses}}"
     id="div_id_{{name}}"
     (click)="isOpen=!isOpen" [ngClass]="isOpen?'open':''"
     dropdown>
  <button class="dropdown-toggle form-control {{nbcSize}}"
          type="button"
          dropdown-toggle *ngIf="nbcDisabled"
          name="dis_{{name}}"
          [disabled]="nbcDisabled || !isAuthorized">
    <span>
      {{ initialType }}
      <i class="fa fa-sort"></i>
    </span>
  </button>
  <button *ngIf="!nbcDisabled"
          name="enable_{{name}}"
          class="form-control {{nbcSize}}"
          data-bs-toggle="dropdown"
          [ngStyle]="{'border-right':nbcRequired?'3px solid #ffc800':''}"
          authenticate-user="{{nbcAuthenticateUser}}"
          authenticate-command="{{nbcAuthenticateCommand}}"
          [disabled]="((!internalSource || internalSource.length<1)&& nbcDisableEmptyList)|| !isAuthorized"
          type="button" dropdown-toggle>
    <span>
      {{ initialType }}
      <i class="fa fa-sort"></i>
    </span>
  </button>
  <ul class="dropdown-menu" role="menu" id="menu_{{name}}">
    <ng-container *ngIf="isAuthorized">
      <li *ngFor="let event of internalSource" name="{{event[display]}}"
          (click)="closeDropdown();
        onTypeSelect(event);"
          role="presentation">
        <a role="menuitem" tabindex="-1">{{event[display]}}</a>
      </li>
    </ng-container>
  </ul>
</div>-->

<div class="dropdown nbc-dropdown {{nbcClasses}}"
     id="div_id_{{name}}" dropdown>
  <button *ngIf="nbcDisabled" dropdownToggle
          type="button" class="text-start form-control {{nbcSize}}"
          name="dis_{{name}}"
          [disabled]="nbcDisabled || !isAuthorized">
    <span>
      {{ initialType }}
      <i class="fa fa-sort float-end ps-1 mt-1"></i>
    </span>
  </button>
  <button *ngIf="!nbcDisabled" dropdownToggle
          name="enable_{{name}}"
          type="button" class="text-start form-control {{nbcSize}}"
          [ngStyle]="{'border-right':nbcRequired?'3px solid #ffc800':''}"
          authenticate-user="{{nbcAuthenticateUser}}"
          authenticate-command="{{nbcAuthenticateCommand}}"
          [disabled]="((!internalSource || internalSource.length<1)&& nbcDisableEmptyList)|| !isAuthorized"
          aria-controls="dropdown-basic">
    {{ initialType }}
    <span class="fa fa-sort float-end ps-1 mt-1"></span>
  </button>
  <ul *dropdownMenu class="dropdown-menu" id="menu_{{name}}"
      role="menu" aria-labelledby="button-basic">
    <ng-container *ngIf="isAuthorized">
      <li *ngFor="let event of internalSource" name="{{event[display]}}"
          (click)="closeDropdown();onTypeSelect(event);"
          role="presentation">
        <a class="dropdown-item" role="menuitem" tabindex="-1">{{event[display]}}</a>
      </li>
    </ng-container>
  </ul>
</div>
