import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InputRestrictionDirective } from "./directives/inputRestriction.directive";
import { NbcAlerts } from "./components/nbcAlert/nbc-alert.component";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NbcPageSizeComponent } from "./components/pagination/page-size/page-size.component";
import { NbcPaginationComponent } from "./components/pagination/pagination/pagination.component";
import { NbcPaginationLabelComponent } from "./components/pagination/label/pagination-label.component";
import { PaginationModule, PaginationConfig } from "ngx-bootstrap/pagination";
import { NbcDropDownComponent } from "./components/nbcdropdown/nbc-dropdown.component";
import { OnlynumberDirective } from "./directives/nbc-number-only.directive";
import { NbcModalAlerts } from "./components/nbcAlert/nbc-modal-alert.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NbcMultiSelectDropdown } from "./components/nbcMultiSelect/nbc-multi-select-dropdown.component";
import { NbcEventCountdownLabelComponent } from "./components/nbcEventCountDown/nbcEventCountdownLabel.component";
import { EventNameTruncateComponent } from "./directives/eventNameTruncate.directive";
import { NbcSelectComponent } from "./components/nbcSelect/nbc-select.component";
import { NbcDateTimeComponent } from "./components/nbc-date-time/datetime/nbc-datetime.component";
import { NbcTimeComponent } from "./components/nbc-date-time/time/nbc-time.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NbcTabDisplayComponent } from "./components/nbc-tab-display/nbc-tab-display.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NbcDecimalDirective } from "./directives/nbc-decimal/nbc-decimal.directivet";
import { PhoneFormatDirective } from "./directives/nbc-phone-format.directive";
import { NbcFocusDirective } from "./directives/nbc-focus/nbc-focus.directive";
import { NbcFileDownloadComponent } from "./components/nbc-file-download/nbc-file-download.component";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgPipesModule } from "ngx-pipes";
import { NbcFullScreenDirective } from "./directives/nbc-fullscreen/nbc-fullscreen.directive";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NbcTagsComponent } from "./nbc-tags/nbc-tags.component";
import { NbcAuthorizationDirective } from "./directives/nbc-authorization.directive";
import { NbcTypeaheadComponent } from "./components/nbc-typeahead/nbc-typeahead.component";
import { NbcTeamsComponent } from "./components/nbc-teams/nbc-teams.component";
import { NbcHomeLinkComponent } from "./components/nbc-home-link/nbc-home-link.component";
import { NbcDynamicHtmlTooltipDirective } from "./directives/nbc-dynamic-htmlToolTip.directive";
import { NbcRightClickOpenDirective } from "./directives/nbc-right-click-open.directive";
import { NbcFormControlsComponent } from "./components/form-display/nbc-form-controls.component";
import { NbcAutoCompleteOffDirective } from "./directives/nbc-autocomplete-off.directive";
import { NbcLoaderComponent } from "./components/nbc-loader/nbc-loader.component";

@NgModule({
    imports: [
        CommonModule,
        AlertModule.forRoot(),
        FormsModule,
        BsDropdownModule,
        TabsModule.forRoot(),
        PaginationModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        TypeaheadModule.forRoot(),
        TooltipModule.forRoot(),
        LoggerModule.forRoot({
            serverLoggingUrl: "/api/logs",
            level: NgxLoggerLevel.ERROR,
            serverLogLevel: NgxLoggerLevel.OFF,
            colorScheme: [
                "yellow",
                "#F37021",
                "gray",
                "gray",
                "red",
                "red",
                "red",
            ],
        }),
    ],
    exports: [
        InputRestrictionDirective,
        NbcAlerts,
        NbcModalAlerts,
        NbcPageSizeComponent,
        NbcPaginationComponent,
        NbcPaginationLabelComponent,
        NbcDropDownComponent,
        OnlynumberDirective,
        NbcMultiSelectDropdown,
        NbcEventCountdownLabelComponent,
        EventNameTruncateComponent,
        NbcSelectComponent,
        NbcDateTimeComponent,
        NbcTimeComponent,
        NbcTabDisplayComponent,
        NbcDecimalDirective,
        PhoneFormatDirective,
        NbcFocusDirective,
        NbcFileDownloadComponent,
        NgPipesModule,
        NbcFullScreenDirective,
        NbcTagsComponent,
        NbcAuthorizationDirective,
        NbcTypeaheadComponent,
        NbcTeamsComponent,
        NbcHomeLinkComponent,
        NbcDynamicHtmlTooltipDirective,
        NbcRightClickOpenDirective,
        NbcFormControlsComponent,
        NbcAutoCompleteOffDirective,
        NbcLoaderComponent
    ],
    declarations: [
        InputRestrictionDirective,
        NbcAlerts,
        NbcModalAlerts,
        NbcPageSizeComponent,
        NbcPaginationComponent,
        NbcPaginationLabelComponent,
        NbcDropDownComponent,
        OnlynumberDirective,
        NbcMultiSelectDropdown,
        NbcEventCountdownLabelComponent,
        EventNameTruncateComponent,
        NbcSelectComponent,
        NbcDateTimeComponent,
        NbcTimeComponent,
        NbcTabDisplayComponent,
        NbcDecimalDirective,
        PhoneFormatDirective,
        NbcFocusDirective,
        NbcFileDownloadComponent,
        NbcFullScreenDirective,
        NbcTagsComponent,
        NbcAuthorizationDirective,
        NbcTypeaheadComponent,
        NbcTeamsComponent,
        NbcHomeLinkComponent,
        NbcDynamicHtmlTooltipDirective,
        NbcRightClickOpenDirective,
        NbcFormControlsComponent,
        NbcAutoCompleteOffDirective,
        NbcLoaderComponent
    ],
    providers: [],
})
export class NbcSharedModule {
    static forRoot() {
        return {
            ngModule: NbcSharedModule,
            providers: [],
        };
    }
}
