import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PermissionService } from "src/app/Shared/Services/permission.service";
import { ContactSharedDataService } from "../../contact-shared.service";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "Remotes-permission",
    templateUrl: "./remotes-permissions.component.html",
})
export class RemotesPermissionComponent implements OnInit, OnDestroy {
    constructor(
        private _contactSharedData: ContactSharedDataService,
        private _permissionService: PermissionService,
        private log: NbcLoggerService
    ) {}
    componentDestroyed$: Subject<boolean> = new Subject();
    roles: any | undefined;
    selectedrole: any | undefined;
    user: any | undefined;
    contact: any | undefined;
    id: any;
    permissions: any | undefined;
    resultsFound = false;

    getRoles() {
        this.log.trace("getWorkSchedule");

        this._permissionService
            .getRoles()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("getRoleSetup got results: " + result);
                    if (result && result.reference) {
                        this.roles = result.reference;
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + err?.error?.message);
                    this.log.showError(
                        "Unable to load the events at this time. Please try again later."
                    );
                },
            });
    }
    getUserPermissions() {
        this.log.trace("getWorkSchedule");
        if (this.contact.sso != "") {
            this._permissionService
                .getRemOlyUserPermission(this.contact.id)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result) => {
                        // this.log.trace("getRoleSetup got results: " + JSON.stringify(result));
                        if (result) {
                            this.user = result["reference"];
                            const userDefaults = _.filter(
                                this.user.userDefaultRows,
                                (role) => {
                                    return role.division.code === "REM";
                                }
                            );
                            this.permissions = _.filter(
                                this.user.userRoleRows,
                                (role) => {
                                    return (
                                        role.userDefaultNo ===
                                        userDefaults[0].userDefaultRowNo
                                    );
                                }
                            );
                            this.resultsFound = true;
                        } else {
                            this.resultsFound = false;
                        }
                    },
                    error: (err) => {
                        this.log.error("got an error: " + err?.error?.message);
                        this.log.showError(
                            "Unable to load the permission at this time. Please try again later."
                        );
                    },
                });
        }
    }

    ClearSelection() {
        this.selectedrole = null;
    }

    checkRole() {
        return this.selectedrole === null || this.selectedrole === undefined;
    }

    addRoles() {
        this.log.trace("getWorkSchedule");

        const userDefaults = _.filter(
            this.user.userDefaultRows,
            function (role) {
                return role.division.code === "REM";
            }
        );
        if (this.contact.sso != "") {
            if (this.user.listId === 0) {
                this.user.userId = this.contact.sso;
                this.user.firstName = this.contact.firstName;
                this.user.lastName = this.contact.lastName;
                this.user.listId = this.contact.id;
                const match = _.find(this.user.userDefaultRows, (role) => {
                    return role.division.code == "REM";
                });
                if (match) {
                    match.checkBox1 = "Y";
                }
            }
            const role = {
                roleCombos: null,
                roleNo: this.selectedrole,
                userDefaultNo: userDefaults[0].userDefaultRowNo,
                userRoleNo: 0,
            };
            const matchrole = _.filter(this.user.userRoleRows, (role) => {
                return (
                    role.userDefaultNo === userDefaults[0].userDefaultRowNo &&
                    role.roleNo.roleNo === this.selectedrole.roleNo
                );
            });
            if (matchrole && matchrole.length > 0) {
                this.log.showError(
                    "Role already existing. Please select different role."
                );
                this.getUserPermissions();
            } else {
                this.user.userRoleRows.push(role);

                this._permissionService
                    .SaveREMOLYUserPermissions(this.user)
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe({
                        next: (result) => {
                            // this.log.trace("getRoleSetup got results: " + JSON.stringify(result));
                            this.ClearSelection();
                        },
                        error: (err) => {
                            this.log.error(
                                "got an error: " + err?.error?.message
                            );
                            this.log.showError(
                                "Unable to Save role at this time. Please try again later."
                            );
                        },
                        complete: () => {
                            this.getUserPermissions();
                        },
                    });
            }
        }
    }

    ngOnInit() {
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contact = contact;
            this.getUserPermissions();
        });

        this.getRoles();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
