import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import * as _ from "lodash";
import { EventService } from "src/app/Shared/Services/event.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import * as moment from "moment";
import "moment-timezone";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalUpdatePayrollProviderComponent } from "./modal-update-payroll-provider.component";
import { ActivatedRoute } from "@angular/router";
import { ChartType } from "chart.js";
//import { MultiDataSet, Label, Colors } from "ng2-charts";
import { SharedDataService } from "../../../Shared/sharedData.service";

import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
    selector: "upcoming-event-setup",
    templateUrl: "./upcoming-event-setup.html",
    //styleUrls: [
    // "../../../../assets/Content/app/areas/payroll/payroll.css"]
})
export class UpcomingEventSetup implements OnInit, OnDestroy {
    constructor(
        private log: NbcLoggerService,
        private _EventService: EventService,
        private _globalService: GlobalService,
        private _PayrollService: PayrollService,
        public _modalService: BsModalService,
        private _dataService: SharedDataService,
        private ngxService: NgxUiLoaderService,
        private _activatedRoute: ActivatedRoute
    ) {}

    index(index, item) {
        return item;
    }

    bsModalRef?: BsModalRef;
    eventId: any;
    crewStartDate: any;
    crewEndDate: any;
    tier: any;
    payrollProvidersList: any;
    eventName: any;
    model: any;
    clonedModel: any;
    chartData: any;
    loaded: boolean | undefined;
    contactsPayrollChartData: any;
    payrollProviderColors: string[] = [];
    sort = {
        orderByField: "",
        reverseSort: false,
    };

    chartColors = [
        "#ff8d23",
        "#890f89",
        "#008D86",
        "#00AA4F",
        "#808080",
        "#0066D7",
        "#D1D1D1",
    ];
    defaultOrderBy = "";
    defaultReverseSort = false;
    SetStrictFilterOprion = false;
    groupUpdate = {
        status: false,
        selectAll: false,
        count: 0,
        submitted: false,
        options: [
            {
                name: "Payroll Provider",
                value: "payrollProvider",
            },
        ],
        updatedPayrollProvider: "",
        selected: "",
    };
    auditLogTimezone = "America/New_York";
    employeeTypeFilters: any = [];
    employeeTypeToFilter: any = [];
    payrollProvidersInvalid: boolean | undefined;

    componentDestroyed$: Subject<boolean> = new Subject();

    //public chartLayoutLabel!: Label[];
    //public ChartLayoutData!: MultiDataSet;
    public chartLayoutLabel = [];
    public ChartLayoutData = {};
    public chartLayoutColors: Array<any> = [{}];
    public doughnutChartType: ChartType = "doughnut";
    options: any = {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 75,
          legend: {
            display: false,
          },
          cutout: '75%',
      };

    searchEmployeeFilter(item) {
        this.SetStrictFilterOprion = true;
        if (item.empType) {
            if (this.employeeTypeToFilter.includes(item.empType)) {
                const filterobjectindex = this.employeeTypeToFilter.findIndex(
                    (object) => {
                        return object === item.empType;
                    }
                );
                if (filterobjectindex !== -1) {
                    this.employeeTypeToFilter.splice(filterobjectindex, 1);
                }
            } else {
                this.employeeTypeToFilter.push(item.empType);
            }
        }

        this.GetDataToDisplay();
        for (let i = 0; i < this.employeeTypeFilters.length; i++) {
            const filter = this.employeeTypeFilters[i];

            filter.isSelected = false;
            filter.isDisabled = true;
            if (this.employeeTypeToFilter.includes(filter.empType)) {
                filter.isSelected = true;
                filter.isDisabled = false;
            }
        }
    }

    GetDataToDisplay() {
        if (this.employeeTypeToFilter.length === 0)
            this.model = _.cloneDeep(this.clonedModel);
        else {
            this.model = _.filter(this.clonedModel, (o) => {
                return this.employeeTypeToFilter.includes(
                    o.employeeType?.description
                );
            });
        }
    }

    searchAllEmployeeFilter(item) {
        this.employeeTypeToFilter = [];
        this.SetStrictFilterOprion = false;
        for (let i = 0; i < this.employeeTypeFilters.length; i++) {
            const filter = this.employeeTypeFilters[i];

            filter.isSelected = false;
            filter.isDisabled = false;
        }
    }

    groupUpdateBtn() {
        this.groupUpdate.submitted = true;
        if (this.groupUpdate.updatedPayrollProvider !== "") {
            this.bulkUpdatePositions();
        }
    }

    updateToTBTK(selected) {
        this.log.trace("updateToTBTK");

        const initialState: any = {
            self: this,
            selected: selected,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(
            ModalUpdatePayrollProviderComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.saveChanges(selected);
            this.resetGroupUpdate();
        });
    }

    bulkUpdatePositions() {
        this.log.trace("bulk update positions");
        let toUpdate: any = [],
            i,
            contact,
            missingSso = 0;

        for (i = 0; i < this.model.length; i += 1) {
            contact = this.model[i];
            if (contact.selected) {
                toUpdate.push(contact);

                if (
                    !contact.contactId ||
                    (contact.contactId && (!contact.sso || contact.sso === 0))
                ) {
                    missingSso += 1;
                }
            }
        }
        if (
            this.groupUpdate?.updatedPayrollProvider?.toLowerCase() === "tk" &&
            missingSso !== 0
        ) {
            this.updateToTBTK(toUpdate);
        } else {
            this.updateProviders(toUpdate);
            this.resetGroupUpdate();
        }
    }

    updateProviders(contacts) {
        let i, contact;

        for (i = 0; i < contacts.length; i += 1) {
            contact = contacts[i];
            contact.payrollProvider = this.groupUpdate.updatedPayrollProvider;
        }
        this.saveChanges(contacts);
    }

    selectAll(event) {
        this.groupUpdate.selectAll = event.target.checked;
        this.groupUpdate.status = event.target.checked;
        if (event.target.checked) {
            this.groupUpdate.count = this.model.length;
        } else {
            this.resetGroupUpdate();
        }
        _.forEach(this.model, (value) => {
            if (this.filterFunction(value)) {
                value.selected = event.target.checked;
            }
        });
    }

    sortByColumn(column) {
        if (this.sort.orderByField === column && this.sort.reverseSort) {
            this.sort.orderByField = this.defaultOrderBy;
            this.sort.reverseSort = this.defaultReverseSort;
        } else if (
            this.sort.orderByField === column &&
            !this.sort.reverseSort
        ) {
            this.sort.reverseSort = true;
        } else {
            this.sort.orderByField = column;
            this.sort.reverseSort = false;
        }
    }

    groupUpdateSelect(event) {
        if (event.target.checked) {
            this.groupUpdate.count++;
        } else {
            this.groupUpdate.count--;
        }
        if (this.groupUpdate.count === this.model.length) {
            this.groupUpdate.selectAll = true;
        } else {
            this.groupUpdate.selectAll = false;
        }
        if (this.groupUpdate.count === 0) {
            this.resetGroupUpdate();
        } else {
            this.groupUpdate.status = true;
        }
    }

    resetGroupUpdate() {
        let i;
        this.groupUpdate.status = false;
        this.groupUpdate.submitted = false;
        this.groupUpdate.selectAll = false;
        this.groupUpdate.selected = "";
        this.groupUpdate.updatedPayrollProvider = "TBD";
        this.groupUpdate.count = 0;

        for (i = 0; i < this.model.length; i += 1) {
            this.model[i].selected = false;
        }
    }

    changeItem(item) {
        this.validatePayrollProvider([item]);
        if (this.payrollProvidersInvalid) {
            return;
        }
        this.saveChanges([item]);
    }

    saveChanges(toUpdate) {
        this.log.trace("saveChanges");
        let i, item, items;
        items = toUpdate || this.model;
        this._PayrollService
            .savePayrollProviderItems(this.eventId, items)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.getPayrollProviderItems(items);
                },
                (err) => {
                    this.log.error("got an error: ");
                    this.getPayrollProviderItems(items);
                }
            );
    }

    getChartData() {
        this._PayrollService
            .getPayrollUpcomingEventHeader(this.eventId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.chartData = result.reference;
                    this.chartInit();
                },
                (err) => {
                    this.log.showError("got an error: ");
                }
            );
    }

    validatePayrollProvider(items) {
        this.log.trace("validatePayrollProvider");
        let i, item;

        this.payrollProvidersInvalid = false;

        for (i = 0; i < items.length; i += 1) {
            item = items[i];
            const tkAssignedToOpenPosition =
                item.payrollProvider === "TK" &&
                (!item.contactId || !item.sso || item.sso === 0);
            if (tkAssignedToOpenPosition) {
                this.payrollProvidersInvalid = true;
                break;
            }
        }
    }

    filterFunction(ele) {
        if (
            _.isEmpty(this.employeeTypeToFilter) ||
            this.employeeTypeToFilter.length === 0
        )
            return true;

        return ele.employeeType &&
            this.employeeTypeToFilter.includes(ele.employeeType.description)
            ? true
            : false;
    }

    getEmployeeTypeList(selecteditems) {
        if (!this.model) return;
        this.employeeTypeFilters = [];
        const empTypeFilter = {
            empType: "",
            count: 0,
            isSelected: false,
            isDisabled: false,
        };
        const distinctEmpTypes = _.uniq(
            _.map(this.model, "employeeType.description")
        );

        _.forEach(distinctEmpTypes, (empType) => {
            if (!_.isEmpty(empType)) {
                const filterItem = _.clone(empTypeFilter);
                filterItem.empType = empType;
                if (
                    this.employeeTypeToFilter &&
                    //this.employeeTypeToFilter === empType &&
                    (selecteditems?.filter( x => x.employeeType?.description === empType).length > 0)
                ){
                    filterItem.isSelected = true;
                }else{
                    filterItem.isSelected = false; 
                }

                filterItem.count = _.filter(this.model, function (o) {
                    if (
                        o.employeeType &&
                        o.employeeType.description === empType
                    )
                        return o;
                }).length;
                this.employeeTypeFilters.push(filterItem);
            }
        });
    }

    chartInit() {
        let unassignedContacts = 0,
            i,
            value;
        this.contactsPayrollChartData = [];

        //resolving TZ issue to show EST date. parseZone will prevent offset to get converted to client timezone.
        //SCOR-5228 Remotes: Payroll Provider – Last Modified On Date: The “Last Modified On” date is displaying one day less than the actual modified date
        if (this.chartData) {
            this.chartData.lastModifiedDate = moment
                .tz(this.chartData.lastModifiedDate, this.auditLogTimezone)
                .format("M/D/YYYY");
        }
        const layoutData: any[] = [];
        this.chartLayoutLabel = [];
        this.chartLayoutColors = [];
        this.ChartLayoutData = {};
        for (i = 0; i < this.chartData.payrollProviders.length; i += 1) {
            value = this.chartData.payrollProviders[i];
            layoutData.push(value.count);
            this.chartLayoutLabel.push(value.description);
            this.contactsPayrollChartData.push({
                value: value.count,
                color: this.chartColors[i],
                highlight: this.chartColors[i],
                label: value.description,
            });
            this.chartLayoutColors.push(this.chartColors[i]);
            this.payrollProviderColors[value.description] = this.chartColors[i];

            unassignedContacts += value.count;
        }
        // this.ChartLayoutData.push(layoutData);
        this.ChartLayoutData = {
            labels:this.chartLayoutLabel,
            datasets: [{
                data: layoutData,
                backgroundColor: this.chartLayoutColors
            }]
        }
        //this.chartLayoutColors[0]["backgroundColor"] = this.chartColors;
        this.contactsPayrollChartData.push({
            value: this.chartData.totalContacts - unassignedContacts,
            color: "#D1D1D1",
            highlight: "#D1D1D1",
            label: "All Contacts",
        });
    }

    getPayrollProviderItems(items?) {
        this.log.trace("getPayrollProviderItems()");
        this.ngxService.startBackground();
        this._PayrollService
            .getPayrollUpcomingEventSummary(this.eventId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.eventName = result.reference.eventName;
                    this.model =
                        result.reference.payrollProviderListItems.items;
                    _.forEach(this.model, function (value) {
                        if (value.isNabetContact) {
                            value.bgColor = "rgb(246,233,125)";
                        } else if (value.hasCompanyCode) {
                            value.bgColor = "rgb(202, 250, 232)";
                        } else {
                            value.bgColor = "";
                        }
                    });
                    this.clonedModel = _.cloneDeep(this.model);
                    this.getEmployeeTypeList(items);
                    this.GetDataToDisplay();
                    this.chartData = result.reference;
                    this.chartInit();
                    this.ngxService.stopBackground();
                    this.loaded = true;
                },
                (err) => {
                    this.log.showError("got an error: ");
                    this.ngxService.stopBackground();
                }
            );
    }

    getPayrollProviderList() {
        this.log.trace("getPayrollProviders()");
        this._globalService
            .getPayrollProviders()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.payrollProvidersList = result;
                },
                (err) => {
                    this.log.showError("got an error: ");
                }
            );
    }

    getEventInfo() {
        this.log.trace("getEventInfo()");
        this._EventService
            .getEventInfo(this.eventId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.crewStartDate = result.reference.crewWorkStartDate;
                    this.crewEndDate = result.reference.crewWorkEndDate;
                    this.tier = result.reference.tier;
                },
                (err) => {
                    this.log.showError(
                        "Unable to get event info at this time. Please try again later."
                    );
                }
            );
    }

    ngOnInit() {
        this._dataService.setCss("payroll");
        this._activatedRoute.params.subscribe(
            (params) => (this.eventId = params["id"])
        );

        // this.eventId=5987
        this.getEventInfo();
        this.getPayrollProviderList();
        this.getPayrollProviderItems();
    }

    ngOnDestroy(): void {
        // throw new Error("Method not implemented.");
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
