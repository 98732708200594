<div class="copy-another-event-modal">
  <div class="modal-header ng-scope">
    <h4 class="modal-title ng-binding">{{modalTitle}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <nbc-modal-alert></nbc-modal-alert>
    <div>

      <div class="row" *ngIf="!gotoStep2">
        <div class="col-sm-12">
          <p>Search and select the event you want to copy the positions from:</p>
          <form>
            <div class="form-group">
              <label class="control-label">Event Type:</label>
              <div *ngIf="eventTypes && eventTypes.length>0">
                <nbc-dropdown name="eventTypeid" 
                            [source]="eventTypes| orderBy :['name']"
                            [(model)]="eventQuery.eventTypeId"
                            display="name"
                            value="id" 
                            nbcSize="w-100"
                            placeholder="All Event Types" 
                            (nbcOnOptionSelect)="onEventTypeSelect()">
                </nbc-dropdown> 
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">Season:</label>
              <div>
                <input [(ngModel)]="seasonSearchTerm"
                       name="contactQuery.season"
                       appInputRestriction="restrictChars"
                       [typeahead]="seasonObservable$"
                       [typeaheadAsync]="true"
                       typeaheadOptionField="name"
                       [typeaheadOptionsLimit]="50"                     
                       placeholder="Season"
                       class="form-control"
                       [isAnimated]="true"
                       [typeaheadWaitMs]="200"
                       (typeaheadOnSelect)="seasonSelected($event)"> 
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">Event Name:</label>
              <div>
                <input type="text"
                       appInputRestriction="restrictChars"
                       class="form-control"
                       name="eventName"
                       placeholder="Event Name"
                       [(ngModel)]="eventQuery.eventName"
                       maxlength="50" />
              </div>
            </div>
            <div class="form-group">
              <div class="text-end">
                <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>&nbsp;
                <button type="button" class="btn btn-primary" [disabled]="positionsBeingCopied" (click)="eventSearch()">Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row" *ngIf="searched">
        <div class="col-sm-12" *ngIf="gotoStep1">
          <div class="table-responsive">
            <div class="form-group view-options text-end">
              <div class="mb-3 pagination-container d-flex">
                  <label class="pagination-results pagination-result-pos ms-auto">
                    <pagination-label pageSize="{{event.pageSize}}"
                                      currentPage="{{event.currentPage}}}"
                                      totalRecords="{{event.totalRecords}}">
                    </pagination-label>
                  </label>
                  <nbc-pagination totalItems="{{event.totalRecords}}"
                                  itemsPerPage="{{event.pageSize}}"
                                  [(currentPage)]="eventQuery.currentPage"
                                  (pageChanged)="eventSearch($event)">
                  </nbc-pagination>
                </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Event Type</th>
                  <th>Season</th>
                  <th>Event</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of event.items">
                  <td>{{event.eventType.name}}</td>
                  <td>{{event.seasonName}}</td>
                  <td><a href="/Events/Event/{{event.id}}/Crew" class="btn-link" target="_blank" (click)="eventSelected($event, event)">{{event.name}}</a></td>
                  <td>{{event.lastAirDate | date: 'MMM d yyyy' }}<span *ngIf="event.lastAirDate"> <br />*Air Date</span></td>
                </tr>
                <tr *ngIf="event.items.length == 0">
                  <td colspan="4">No results</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="positionsBeingCopied">
          <div class="table-responsive">
            <div class="form-group view-options text-center">
              <table class="table">
                <tr>
                  <td colspan="4">Event Positions are being copied.</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-12">
          <div *ngIf="gotoStep2">
            <div class="table-responsive">
              <div class="view-options">
                <table class="table">
                  <tr class="row">
                    <td class="col-md-12">
                      <div *ngIf="hasInactiveMasterData" style="color:red;">NOTE: Following Job Titles are inactive and will not copy: {{inActiveJobTitles}} </div>
                      <div class="form-box pt-4 ps-4">
                        <div style="margin-top:10px;" class="d-flex">
                          <span class="col-md-3"><strong>Event:</strong></span>
                          <span> <a  class="btn-link" routerLink="/Events/Event/{{selectedEvent.id}}/Crew" target="_blank"> {{selectedEvent.name}}</a></span>
                        </div>
                        <div style="margin-top:10px;" class="d-flex">
                          <span class="col-md-3"><strong>Last Air Date:</strong></span><span>{{selectedEvent.lastAirDate | date: 'MMM d yyyy' }}</span>
                        </div>
                        <div style="margin-top:10px;" class="d-flex">
                          <span class="col-md-3"><strong>Total Positions:</strong></span><span>{{selectedEvent.totalPositions}} (open positions:{{selectedEvent.openPositions}})</span>
                        </div>
                        <div style="margin-top:10px;" class="d-flex">
                          <span class="col-md-3"><strong>Copy Contacts:</strong></span><span><label class="checkbox m-0"><input type="checkbox" style="margin-left:0;" [(ngModel)]="CopyEventPositionSettings.copyContact" /> </label></span>
                        </div>  
                        <div style="margin-top:10px;" class="d-flex">
                          <span class="col-md-3"><strong>Copy Crew Work Schedule:</strong></span><span><label class="checkbox"><input type="checkbox" style="margin-left:0;" [(ngModel)]="CopyEventPositionSettings.copyCrewWorkSchedule" /> </label></span>
                        </div>
                      </div>

                    </td>
                  </tr>
                  <tr class="row">
                    <td class="col-md-12" style="border:0;">
                      <div class="form-group">
                        <div class="text-end">
                          <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
                          <button type="button" class="btn btn-default" (click)="onBackClick();">Back</button>
                          <button type="button" class="btn btn-primary" [disabled]="positionsBeingCopied" (click)="copyPositions()">Copy</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
