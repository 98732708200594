import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BaseUrlHelperService } from "src/app/Shared/helper/baseUrlHelper.service";
import { ContactUsService } from "src/app/Shared/Services/contact-us.service";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { SubSink } from "subsink";
import { NgForm } from "@angular/forms";
import { SharedDataService } from "../../../Shared/sharedData.service";
@Component({
    selector: "app-contact-us",
    templateUrl: "./contact-us.component.html",
})
export class ContactUsComponent implements OnInit {

  constructor(
    private contactUsService: ContactUsService,
    private _sharedDataService: SharedDataService,
    private _apiroot: BaseUrlHelperService,
    private router: Router
  ) { }

    isDisabled;
  showAlertMessage;  
  uploadUrl: any;
  documentName: any = [];
  isFileSizeError: any = false;
  
  error: any = false;
  submitted: any = false;
  spin = false;
  FileTimeStamp = Math.floor(Date.now() / 1000);
  apiUrl: any;

  contactEmailRegex = RemotesConstants.regex.email;
    userInfo = window.preload.user;
    model:any = {
        fullName: "",
        firstName: "",
        lastName: "",
        message: "",
        subject: "",
        ssoid: "",
        email: "",
        contactExt: "",
        contactNo: "",
        filePath: "",
        userId: "",
        fileTimeStamp: "",
    };
    alerts;
    fileData;
    previewUrl;
  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild("formContactus") formContactus!: NgForm
   


 


  loadContactusData() {
    this.subs.sink = this.contactUsService.loadContactusData(this.userInfo.id, 0)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.model = data;
            this.getFullName();
            this.isDisabled = true;
          } else {
            this.model = {};
            this.isDisabled = false;
          }
        }, error: (err) => {
          // this.alertService.showModalError(err.error);
          // this.enableSavefn();
          // this.isJobtileExists = true;
        }
      });
  }


  private subs = new SubSink();
    captchaSubmitted = false;    

    getFullName() {
        this.model.fullName = this.model.firstName + " " + this.model.lastName;
    }

    uploadText = "Attach screenshots or documents";
  
    uploaded(fileResponse) {
        const file = <File>fileResponse.target.files[0];
        const testData: FormData = new FormData();
        testData.append("file", file, file.name);
        this.contactUsService
            .postFile(testData, this.FileTimeStamp)
            .subscribe((res) => {});

        // if (uploadedResponse.status === "true") {

        //     $scope.model.filePath = uploadedResponse.relativeUrl;
        //     //   $scope.documentName = uploadedResponse.fileName;
        //     $scope.model.FileTimeStamp = uploadedResponse.FileTimeStamp;

        //     var fileCount = parseInt(uploadedResponse.count, 10);
        //     if (fileCount > 0) {

        //         for (var i = 0; i < fileCount; i++) {
        //             var filename = "fileName" + i;
        //             $scope.documentName.push({ "file": uploadedResponse[filename] });
        //         }
        //     }

        // }
        // else {
        //     if (uploadedResponse.fileSizeError) {
        //         $scope.response_Error = uploadedResponse.fileSizeError;
        //         $scope.isFileSizeError = true;
        //         $scope.showAlertMessage = true;
        //         $scope.alerts = [{ type: "danger", msg: uploadedResponse.fileSizeError }];

        //     }
        // }
    }

    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    //$scope.onUpload = function (files) {
    //    console.log(files);
    //    console.log("Uploaded Filed");
    //};
    // $scope.response_Error = ""; // list of errors from file upload section
    //errorFile(response) {
    //    // $scope.response_Error = response;
    //    // if (!angular.isObject(response)) {
    //    //     $scope.isFileSizeError = true;
    //    // }
    //}

    //------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------
    //Download file

    downloadFiles(documentDownload) {
        // $window.open(apiRoot + "FAQ/" + documentDownload + "/downloaddocument"); //right now hardcoded
    }

    //Post data to the database and navigate to next page.
    submitContactUsDetails() {
        //const request = data.form.value;
        // if ($scope.formContactus.$valid === false) {
        //     $scope.submitted = true;
        //     return false;
      // }

      console.log("form data submitted");
      if (this.formContactus.invalid) {
        this.submitted = true;
        return false;
      }

        //if (data.form.status == "VALID") {
          this.contactUsService
            .sendContactusData(this.model)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                    (response) => {
                        if (response === "Success") {
                            this.router.navigateByUrl("Help/Thanks");
                        } else {
                            // this.error = true;
                        }
                    },
                    (error) => {}
                );
        //}
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      this.spin = true; // Start spinner

      // Extract the original file name
      const originalFileName = file.name;

      // Simulate or generate a relative URL from the file name
      const relativeUrl = originalFileName;

      // Simulate file upload (replace this with your actual upload logic)
      const formData = new FormData();
      // formData.append('file', file);
      formData.append('file', file, file.name);

      const fileTimestamp = file.lastModified;

      // Simulate an API call to upload the file
      setTimeout(() => {
        this.simulateFileUpload(formData, originalFileName, relativeUrl).then((fileResponse: any) => {
          this.handleUploadResponse(fileResponse);
          this.uploadDocument(fileTimestamp, formData);
          this.spin = false; // Stop spinner
        });
      }, 2000); // Simulating a delay for upload
    }
  }

  uploadDocument(fileTimestamp, formData) {
    this.subs.sink = this.contactUsService.postFile(fileTimestamp, formData)
      .subscribe({
        next: (data: any) => {
          console.log("upload attachment " + data);
          this.model.filePath = data.relativeUrl;
        }, error: (err) => {
        }
      });
  }

  simulateFileUpload(formData: FormData, originalFileName: string, relativeUrl: string): Promise<any> {
    return new Promise((resolve) => {
      // Simulate a successful response from the backend
      const response = {
        data: JSON.stringify({
          status: "true",
          relativeUrl: relativeUrl, // Use the dynamically generated relative URL
          FileTimeStamp: Date.now(),
          count: 1,
          fileName0: originalFileName // Include the original file name in the response
        })
      };
      resolve(response);
    });
  }

  handleUploadResponse(fileResponse: any): void {
    const uploadedResponse = JSON.parse(fileResponse.data);
    console.log(uploadedResponse); // Log to verify the response structure

    if (uploadedResponse.status === "true") {
      this.model.filePath = uploadedResponse.relativeUrl;  // Check if this value exists
      this.model.fileTimeStamp = uploadedResponse.FileTimeStamp;  // Check if this value exists

      const fileCount = parseInt(uploadedResponse.count, 10);
      if (fileCount > 0) {
        for (let i = 0; i < fileCount; i++) {
          const filenameKey = "fileName" + i;
          this.documentName.push({ file: uploadedResponse[filenameKey] });


        }
      }
    } else {
      // Handle file size or upload errors
      if (uploadedResponse.fileSizeError) {
        this.handleError(uploadedResponse.fileSizeError);
      }
    }
  }

  handleError(errorMsg: string): void {
    this.response_Error = errorMsg;
    this.isFileSizeError = true;
    this.showAlertMessage = true;
    this.alerts = [{ type: "danger", msg: errorMsg }];
  }


  response_Error = ""; // list of errors from file upload section
  errorFile(response) {
    this.response_Error = response;
    if (!this.isObject(response)) {
      this.isFileSizeError = true;

    }
  };

  isObject(response: any): boolean {
    return response !== null && typeof response === 'object';
  };


    // File delete functionality start
  //deleteFileUpload(file, index, filePath, FileTimeStamp) {
  //  const relativeUrl = `${this.userInfo.id}\\Content\\EmailAttachment\\${FileTimeStamp}\\${file}`;
  //  var fileToDelete = filePath + "\\" + file;
  //  var response = { "responseMessage": relativeUrl };

  //  this.subs.sink = this.contactUsService.deleteUploadedDoc(response)
  //    .subscribe({
  //      next: (data: any) => {
  //        if (data.responseMessage === "Success") {
  //          //$window.location.href = '/Home/ThankYou';
  //          this.documentName.splice(index, 1);
  //        }
  //        else {
  //          this.error = true;
  //        }
  //      }, error: (err) => {
  //        // this.alertService.showModalError(err.error);
  //        // this.enableSavefn();
  //        // this.isJobtileExists = true;
  //      }
  //    });
  //};
  

  ngOnInit(): void {
    this._sharedDataService.setCss("contactUs");

    //Interaction with services for CRUD operations.
    //Get data from services on page load.
    this.apiUrl = this._apiroot.baseUrl + this.FileTimeStamp + "/UploadAttachment";
    this.uploadUrl = this.apiUrl;
    this.loadContactusData();
  }

    // File delete functionality End
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
