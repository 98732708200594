<header id="col-main-hdr"><h1>Events</h1></header>
<nav class="breadcrumb">
  <ul>   
    <li home></li>
    <li>/</li>
    <li>Events</li>
  </ul>
</nav> 
<div class="page-container">
  <span us-spinner spinner-key="searchSpinner"></span>
  <div class="container-fluid">
    <div authenticate-user="event.create" *ngIf="ShowEventButton"
         class="tab-action-btns">
      <a routerLink="../Events/CreateSeasonOrEvent" class="btn btn-primary">{{buttonText}}</a>
    </div>
    <tabset class="content-tabs contact-tabs-lg">
      <tab *ngFor="let t of tabs"
           (selectTab)="go(t.route)"
           [active]="t.active">
        <ng-template tabHeading>
          <span nbcRightClickOpen="Remotes/Events/{{t.route}}">{{t.heading}}</span>
        </ng-template>
      </tab>      
    </tabset>
    <router-outlet></router-outlet>
  </div>

</div>

