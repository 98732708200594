import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from "@angular/core";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";

@Component({
    selector: "contact-work-sch-activity-modal",
    templateUrl: "./contact-work-schedule-activity.component.html",
})
export class WorkScheduleNewActity {}
