import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-dirty-check",
    templateUrl: "./nbc-modal-dirtycheck.component.html",
})
export class NbcModalDirtyCheckComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}

    public onAffirmation!: Subject<any>;
    public onRejection!: Subject<any>;
    public onDisregard!: Subject<any>;

    modalTitle: string | undefined;
    modalMessage: string | undefined;
    confirmText: string | undefined;
    cancelText: string | undefined;
    hideConfirm: boolean | undefined;
    saveText: boolean | undefined;
    disableSave: boolean | undefined;

    discard() {
        this.log.trace("Modal Dirty Check Confirmed");
        this.onDisregard.next({});
        this.bsModalRef.hide();
    }

    cancel() {
        this.log.trace("Modal Dirty Check Cancelled");
        this.onRejection.next(null);
        this.bsModalRef.hide();
    }

    save() {
        this.log.trace("Modal Dirty Check Save");
        this.onAffirmation.next({ save: true });
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.onAffirmation = new Subject();
        this.onRejection = new Subject();
        this.onDisregard = new Subject();

        this.modalTitle = this.modalTitle;
        this.modalMessage = this.modalMessage;
        this.confirmText = this.confirmText;
        this.cancelText = this.cancelText;
        this.saveText = this.saveText;
        this.disableSave = this.disableSave;
    }
}
