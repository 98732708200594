import { CookieService } from 'ngx-cookie-service';
import { TimeZoneHelperService } from '../../Shared/helper/timezonehelper.service';

declare global {
  interface Window { preload: any; }
}
window.preload = window.preload || {};

export function RemotesCookieFactory(cookieService: CookieService, tzHelper: TimeZoneHelperService) {
  return () => {
    return new Promise<void>((resolve, reject) => {
      cookieService.set('Z_Client_Offset', tzHelper.getTimezoneOffset(), { path: "/" });
      cookieService.set('Z_Client_TimeZone', tzHelper.getTimezone(),
        { path: "/" });
      cookieService.set('Z_Client_TimeZone', tzHelper.getTimezone(),
        { path: "/", domain: "score.nbcuni.com" });
      resolve();
    })
  };
}
