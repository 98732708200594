<div>
  <!-- Filters -->


  <div class="content-filters" *ngIf="dashboardHeader">
    <div class="row">
      <div class="col-md-12">
        <ul class="pull-left list-inline header-info-summary">
          <li class="events-box">
            <div class="pull-left">
              <div class="info">
                <h2><i class="fa fa-trophy"></i></h2>
              </div>
            </div>
            <div class="pull-left">
              <div class="info">
                <h3>{{dashboardHeader.totalUpcomingEvents}}</h3>
                <div>Upcoming Events</div>
              </div>
            </div>
          </li>
          <li></li>
        </ul>
        <ul class="pull-left list-unstyled">
          <li class="total-contacts-block text-right">
            <div class="pull-left">
              <div class="info text-end">
                <h3>{{dashboardHeader.totalContacts}}</h3>
                Total<br />
                Positions
              </div>
            </div>
            <div class="pull-left">
              <div class="info">
                <i class="fa fa-male"></i>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
    <!-- Search filters -->
    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">
          <div class="input-group input-group-set dropdown" dropdown>
            <div class="input-group input-group-set" dropdown>
              <span class="input-group-text">
                <i class="fa fa-calendar"></i>
              </span>
              <span class="w-75">
                <input type="text"
                       readonly
                       placeholder="Daterangepicker"
                       class="form-control"
                       bsDaterangepicker
                       [bsValue]="bsInlineRangeValue"
                       #drp="bsDaterangepicker"
                       (bsValueChange)="onToggleFilterDatepicker($event)"
                       [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">
              </span>
            </div>
          </div>
      </div>
        </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">

          <select id="AllEventTypes"
          name="AllEventTypes"
          class="form-select"
          [(ngModel)]="payrollTimesheetSubmittedQuery.eventTypeId"
          >
    <option [value]="-1">All Event Types</option>
    <option *ngFor="let eventTypes of eventTypes | orderBy :['name']"
            [ngValue]="eventTypes.id">{{eventTypes.name}}</option>
  </select>

        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="form-group">

          <select id="AllNetworks"
          name="AllNetworks"
          class="form-select"
          [(ngModel)]="payrollTimesheetSubmittedQuery.networkId"
          >
    <option [value]="-1">All Networks</option>
    <option *ngFor="let networks of networks | orderBy :['description']"
            [ngValue]="networks.id">{{networks.description}}</option>
  </select>

        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="form-group">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="payrollTimesheetSubmittedQuery.event"
                 placeholder="Search Event Name"
                 maxlength="100"
                 autocomplete="off" />
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="form-group">
         
          <input [(ngModel)]="contactSearchTerm"
                 appInputRestriction="restrictChars"
                 name="contact"
                 [typeahead]="contactObservable$"
                 [typeaheadAsync]="true"
                 typeaheadOptionField="fullName"
                 [typeaheadOptionsLimit]="10"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 placeholder="Search Contact Name"
                 class="form-control"
                 [isAnimated]="true"
                 [typeaheadWaitMs]="200"
                 (typeaheadOnSelect)="onContactSelect($event)">
        </div>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-4">
        <div class="form-group">
          <button class="btn btn-secondary" (click)="getUpcomingEvents()">Search</button>
          <button class="btn btn-default" (click)="resetSearch()">Reset</button>
        </div>
      </div>
    </div>
  </div>
  <!--/div>-->
  <!-- content area -->
  <div class="main-pane-content"  >
    <div class="row" *ngIf="model && model.items.length>0">
      <div class="row view-options">
        <!--<div class="row">-->
          <div class="col-md-4">
            <div class="form-inline form-sort-options">
              <div class="form-group">
                Sort:
              </div>
              <div class="form-group">
                <nbc-dropdown [(model)]="payrollTimesheetSubmittedQuery.sortBy"
                              (nbcOnOptionSelect)="getUpcomingEvents()"
                              [source]="filterOptions.sortBy"
                              display="name"
                              value="value"
                              nbcSize="w-100"
                              placeholder="Select One"></nbc-dropdown>

              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group text-end d-flex float-end">
              <label class="pagination-results">
                <pagination-label pageSize="{{model.pageSize}}"
                                  currentPage="{{model.currentPage}}"
                                  totalRecords="{{model.totalRecords}}"></pagination-label>
              </label>
                <nbc-pagination totalItems="{{model.totalRecords}}"
                                itemsPerPage="{{payrollTimesheetSubmittedQuery.pageSize}}"
                                [(currentPage)]="payrollTimesheetSubmittedQuery.currentPage"
                                (pageChanged)="getUpcomingEvents();"></nbc-pagination>
            
            </div>
          </div>
        <!--</div>-->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <!-- List View -->
        <div class="view-grid" *ngIf="model && model?.items?.length>0">
          <div class="row">
            <div class="event-tile col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let event of model.items">
              <div class="event-tile-main">
                <div class="event-tile-dtls">
                  <span class="event-tile-date">
                    <span>{{event.startDate| date:"EEE. MMM d"}} - </span>{{event.endDate|date: "EEE. MMM d, yyyy"}}
                  </span>
                  <div class="btn-link event-tile-title">
                    <span>
                      <a class="btn-link" href="/Remotes/Payroll/upcomingevents/setup/{{event.id}}">{{event.title}}</a>
                    </span>
                    <span class="pull-right" *ngIf="event.tier" style="font-weight:bold;margin:0 10px 10px 10px;">T{{event.tier}}</span>
                  </div>
                </div>
                <div class="event-tile-positions">
                  <label><strong>{{event.totalPositions}}</strong> Positions</label>
                  <div class="text-right">
                    <a href="/Events/Season/{{event.seasonId}}" title="{{event.seasonName}}" class="event-tile-season" *ngIf="event.seasonId > 0">
                      <span class="label label-default">S</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="event-tile-aside">
                <nbc-event-countdown-label nbcStartDate="{{event.startDate}}"
                                           nbcEndDate="{{event.endDate}}"
                                           nbcLabelPrependText="In "></nbc-event-countdown-label>
                <img src="/assets/image/team/{{event.homeTeam.imageId}}" width="40" height="40" class="event-tile-game-logo" *ngIf="event?.homeTeam?.imageId" />
                <img src="/assets/image/team/{{event.awayTeam.imageId}}" width="40" height="40" class="event-tile-game-logo" *ngIf="event?.awayTeam?.imageId" />
                <img src="/assets/image/eventType/{{event?.eventTypeImageId}}" width="40" height="40" class="event-tile-game-logo"
                     *ngIf="!event?.homeTeam?.imageId && !event?.awayTeam?.imageId"
                     [hidden]="event?.homeTeam?.imageId || event?.awayTeam?.imageId || event?.eventTypeImageId == 0" />
              </div>
            </div>

         
        </div>
      </div>
   
  </div>
  
</div>
<div style="margin-left:1%;"
 *ngIf="!model?.items.length">No Results.</div>
     </div>

 </div>
 
 
