import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { Subject, Subscriber, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NbcLoggerService } from 'src/app/Shared/logger/nbc-logger.service';

@Component({
  selector: 'modal-update-payroll-provider.component',
  templateUrl: './modal-update-payroll-provider.component.html'
  //styleUrls: [
  //  "../../../../assets/Content/app/areas/payroll/payroll.css"],
  //encapsulation: ViewEncapsulation.Emulated
})
export class ModalUpdatePayrollProviderComponent implements OnInit {
  constructor(private log: NbcLoggerService, public bsModalRef: BsModalRef) {}

  //subject to transmit the results
  public onSync!: Subject<any>;
  componentDestroyed$: Subject<boolean> = new Subject();
  selected: any;

  //Closing Modal
  closeModal() {
    this.bsModalRef.hide();
  }

  confirm() {
    var i, item;
    for (i = 0; i < this.selected.length; i += 1) {
      item = this.selected[i];

      if (item.contactId === 0 || (!item.sso || item.sso === 0)) {
        item.payrollProvider = 'TBTK';
      } else {
        item.payrollProvider = 'TK';
      }
    }

    this.onSync.next(this.selected);
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.onSync = new Subject();
  }
}
