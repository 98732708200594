import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SharedDataService } from "../Shared/sharedData.service";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
})
export class ReportsComponent implements OnInit {
    constructor(private _sharedService: SharedDataService) {}

    ngOnInit(): void {
        this._sharedService.setCss("report");
    }
}
