import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpMonitorService } from "src/app/Core/services/http-monitor.service";

@Component({
    selector: "nbc-loader",
    templateUrl: "nbc-loader.component.html",
    styleUrls: ["nbc-loader.component.css"],
})
export class NbcLoaderComponent implements OnInit, OnDestroy {
    @Input()
    showLoader: any = null;

    isLoading = false;
    loader$ = new Subscription();

  constructor(private loadingService: HttpMonitorService) {
    this.loader$ = this.loadingService.canShowLoader$.subscribe((v) => {
            this.isLoading = this.showLoader || v;
        });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.loader$.unsubscribe();
    }
}
