import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class tierConfigChangeImpactService {

  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }  


    getImpactedFutureEvents(searchObj): Observable<any> {
    this.log.trace("getImpactedFutureEvents " + JSON.stringify(searchObj));
    return this.http.post(this._apiroot.baseUrl + "tierratecard/changeImpact/getImpactedFutureEvents/", searchObj).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };



}
