import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

export class RemotesConstants {
    public static eventManagers: any = {
        all: { id: 11, values: [] },
        homeBase: { id: 12, values: [] },
        technical: { id: 13, values: [] },
        manPower: { id: 14, values: [] },
        onSite: { id: 15, values: [] },
    };

    public static eventConstants: any = {
        scheduleLength: 40,
        visibleWorkScheduleDateRange: "range-0-7",
    };

    public static regex: any = {
        email: /^[_A-Za-z0-9-']+(\.[_A-Za-z0-9-']+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,6})$/,
        dateRangePickerDefault: "/^((?!Select Date Range).)*$/",
        sso: "[0-9]{9}",
        lastFour: "[0-9]{4}",
    };
}
