
 
<div >
    <div class="page-container-tbl">
        <div class="container-fluid-tbl">
            <div class="row-tbl">
                <div class="col-md-8 box-dash col-tbl-middle">
                    <div class="container-fluid-tbl">
                        <div class="row-tbl">
                            <div class="col-md-8 box-dash-map-md">
                                <div class="angular-google-map-container">
                                    <img src="Content/images/Map_USA.jpg" class="w-100" />
                                </div>
                            </div>
                            <div class="col-md-4 col-tbl-middle">
                                <ul class="box-dash-event-list">
                                    <li><strong>{{seasonSummary.totalEvents}}</strong>Total Events</li>
                                    <li><strong>{{seasonSummary.totalExecuting}}</strong>Executed</li>
                                    <li><strong>{{seasonSummary.totalPlanning}}</strong>Planning</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="page-container">
        <div class="container-fluid">
            <app-event-search></app-event-search>
        </div>
    </div>
</div>
 
