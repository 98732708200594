<div>
  <div class="modal-header">
    <h3 class="modal-title">{{reportName}}</h3>
    <button type="button"
            class="btn-close"
            (click)="cancel()"
            aria-hidden="true">
    </button>
  </div> 
    <div class="pt-3">
        <nbc-modal-alert></nbc-modal-alert>
    </div>
    <div class="modal-body">
        <div class="grid-table">

            <div class="grid-content-row"
                 *ngIf="showDateRangeRowAll">
                <div class="grid-col report-name">
                    <span style="color:red;">*</span> Date Range
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showDateRangeEditRow">
                <div class="grid-col report-name">
                    <span style="color:red;">*</span> Date Range
                </div>
                <div class="grid-col input-info">
                  <div class="input-group input-group-set max-input"
                       dropdown
                       is-open="dateRangeFilter"
                       on-toggle="onToggleDateRangeDatepicker()">
                    <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
                    <input type="text"
                           name="arrivalDate"
                           class="form-control"
                           bsDaterangepicker
                           readonly
                           [(ngModel)]="bsInlineRangeValue"
                           #drp="bsDaterangepicker"
                           placeholder="Select Date Range"
                           (bsValueChange)="onToggleFilterDatepicker($event)"
                           [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

                  </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DateRangeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showDateRangeTagRow">
                <div class="grid-col report-name">
                    Date Range
                </div>
                <div class="grid-col">
                    <div class="col-md-4">
                        <span class="filter-tag">{{contactInformationReportInfo.dateRangeStartDate |
                            date:'MM/dd/yyyy'}}-{{contactInformationReportInfo.dateRangeEndDate | date:'MM/dd/yyyy'}}
                            <a><i class="fa fa-times-circle"
                                   (click)="removeDateTag('DateRangeRow')"></i></a></span>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>

            <div class="grid-content-row"
                 *ngIf="ShowEventsTypeRowAll">
                <div class="grid-col report-name">
                    Event Type
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="ShowEventsTypeEditRow">
                <div class="grid-col report-name">
                    Event Type
                </div>
                <div class="grid-col">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div
                                     ng-class="{'has-error':addSalaryCodeForm.rateCard.$invalid && submitted || requireRateCard}">
                                    <nbc-multi-select [source]="contactInformationReport.eventType"
                                                      value="id"
                                                      display="name"
                                                      [(selectedValues)]="selectedEvent"
                                                      [placeholder]="'Select'"
                                                      (nbcOnOptionSelect)="onItemSelect($event)"></nbc-multi-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="ShowEventsTypeTagRow">
                <div class="grid-col report-name">
                    Event Type
                </div>
                <div class="grid-col">
                  <div class="row">
                    <div class="col-md-4"
                         *ngFor="let events of selectedEvent">
                      <span class="filter-tag">
                        {{events.name}} <a>
                          <i class="fa fa-times-circle"
                             (click)="removeTag(events,'EventsRow')"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                  <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                         (click)="editRow('EventsRow')"></i></a>
                  </div>
                </div>

            <div class="grid-content-row">
              <div class="grid-col report-name">
                Event Name
              </div>
              <!-- <div class="grid-col">
      <div class="nbc-tag-container">
          <div class="tag-container">
              <div *ngFor="let event of events"
                   class="tag">
                  <span class="tag-text">{{event.name}}</span>
                  <i class="tag-remove glyphicon glyphicon-remove"
                     (click)="removeTagEvent('positions',event);"></i>
              </div>
              <input appInputRestriction="restrictChars"
                     name="name"
                     [typeahead]="suggestions$"
                     [(ngModel)]="addEventTerm"
                     [typeaheadAsync]="true"
                     typeaheadOptionField="name"
                     [typeaheadOptionsLimit]="10"
                     class="form-control"
                     [isAnimated]="true"
                     typeaheadWaitMs="200"
                     (typeaheadOnSelect)="onPositionSelected($event)">
          </div>
      </div>
  </div> -->
            <div class="grid-col">
              <nbc-tags [(searchTerm)]="addEventTerm"
                        [searchTermObservable$]="suggestions$"
                        [tagDisplay]="'name'"
                        [tagId]="'name'"
                        [tagName]="'name'"
                        (tagChange)="setSearchEventTerm($event)"
                        placeholder="Add Event"
                        [tags]="events"
                        (nbcOnOptionSelect)="onEventSelected($event)"></nbc-tags>
            </div>
                <div class="grid-col report-edit">
                    <button class="btn btn-default"
                            (click)="clear()">Clear</button>
                </div>
            </div>

            </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                (click)="exportContactInformationReport()"
                value="disable"
                [disabled]="isExport">Export</button>

    </div>
</div>
<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
