<form>
    <div class="modal-header">
        <h4 class="modal-title">Confirm Delete Event</h4>
        <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
    </div>
    <div class="modal-body modal-agenda-body">
        <p>Are you sure you want to delete this event?</p>
    </div>
    <div class="modal-footer text-end">
        <button class="btn btn-default" type="button" (click)="closeModal()">Cancel</button>
        <button class="btn btn-primary" type="button" (click)="deleteEvent()">Delete</button>
    </div>
</form>
