
  <div class="modal-header" style=" height: 60px; background-color: orange; ">
    <h4 class="modal-title ng-binding" style=" width: 50%; float: left;"><i class="fa fa-bars" aria-hidden="true"></i> My Position Request Details</h4>
    <input type="text" class="form-control" placeholder="Type to search" style="width: 20%; padding-left: 20px;height:31px;margin-top:2px;float:left" [(ngModel)]="searchTerm" />
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div class="box-dash-inner-dflt">
      <!--<div class="table-responsive">-->
      <div class="row scroll_event">
        <div class="col-md-12">
          <!--Grid Header-->
          <table class="table">
            <thead>
              <tr>
                <th [appSort]="positionDetails" style="cursor:pointer;" data-order="desc" data-name="eventName">Event</th>
                <th [appSort]="positionDetails" style="cursor:pointer;" data-order="desc" data-name="contactName">Contact</th>
                <th></th>
                <th [appSort]="positionDetails" style="cursor:pointer;" data-order="desc" data-name="onboardingStatus">Request Status</th>
                <th [appSort]="positionDetails" style="cursor:pointer;" data-order="desc" data-name="positionStartDate">Position Start Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of positionDetails | positionFilter:searchTerm">
                <td>
                  <a class="btn-link" href="/Events/Event/{{item.eventId}}/Crew" target="_blank">{{item.eventName}}</a>
                </td>
                <td><a class="btn-link" href="/Contacts/Detail/{{item.contactId}}" target="_blank">{{item.contactName}}</a></td>
                <td class=" grid-itm grid-itm-comments open-position-modal cursor-pointer"
                    style="width: 17px;background-color:#e6b800" (click)="openDrupalOnboardingModal(item);">
                  <i class="fa fa-ellipsis-h cursor-pointer"></i>
                </td>
                <td class="dashboardOnboardingStatus {{item?.requestStatusColor}}">{{item.onboardingStatus}}</td>
                <td>{{item.positionStartDate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


