import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable } from 'rxjs';
/*import { ISelect } from 'src/app/Event/dashboard/eventlist.component'*/;

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) {
  }

  getRoles(): Observable<any> {
    this.log.trace("Get Roles");
    return this.http.get(this._apiroot.baseUrl + "uam/remoly/getroles/");
  };


  getRoleCombos(roleno): Observable<any> {
    this.log.trace("Get Role Combos");
    return this.http.get(this._apiroot.baseUrl + "uam/remoly/getrolecombos/" + roleno);
  };

  SaveREMOLYUserPermissions(permission){
    this.log.trace("saving Permission: " + permission);
    return this.http.post(this._apiroot.baseUrl + "uam/remoly/savepermissions/", permission);
  };

  getRemOlyUserPermission(id) {
    this.log.trace("Get Roles");
    return this.http.get(this._apiroot.baseUrl + "uam/remoly/" + id + "/getpermissions/");
  };

  getTalentUserPermissions(sso) {
    this.log.trace("Get Talent user permissions : " + sso);
    return this.http.get(this._apiroot.baseUrl  + "uam/talent/" + sso + "/getpermissions");
  };

  getTalentRoleSetup() {
      this.log.trace("Get Talent Role Setup");
      return this.http.get(this._apiroot.baseUrl + "uam/talent/getrolesetup/");
  };

  SaveUserPermissions(sso, permission) {
    this.log.trace("saving Permission: " + permission);
    return this.http.post(this._apiroot.baseUrl + "uam/talent/" + sso + "/savepermissions", permission);
  };

}
