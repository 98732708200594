import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OnboardingComponent } from "./onboarding.component";
import { OnboardingHistoryComponent } from "./history/onboarding-history.component";
import { OnboardingRequestsComponent } from "./requests/onboarding-requests.component";

const routes: Routes = [
    //{ path: 'Payroll/upcomingevents/setup/:id', component: UpcomingEventSetup },
    //{ path: 'Payroll/timesheet/:id/:timecarddate', component: PayrollTimesheet },
    {
        path: "",
        component: OnboardingComponent,
        children: [
            {
                path: "",
                redirectTo: "requests",
                pathMatch: "full",
            },
            {
                path: "requests",
                component: OnboardingRequestsComponent,
            },
            {
                path: "history",
                component: OnboardingHistoryComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OnboardingRouterModule {
    static forChild() {
        return {
            ngModule: OnboardingRouterModule,
            providers: [],
        };
    }
}
