import { Component, Input } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
    selector: "nbc-form-controls",
    templateUrl: "./nbc-form-controls.component.html",
})
export class NbcFormControlsComponent {
    @Input() ngForm: NgForm;

    get controls() {
        return Object.keys(this.ngForm.controls).map((key) => ({
            name: key,
            valid: this.ngForm.controls[key].valid,
          value: this.ngForm.controls[key].value,
          errors: this.ngForm.controls[key].errors
        }));
    }
}
