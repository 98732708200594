import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { EventBusService } from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";
import { RemotesConstants } from "../../Shared/helper/constants.service";
import { ModalVenueImpactList } from "./component/modal-venue-impact-list";

@Component({
    selector: "modal-venue",
    templateUrl: "./modal-venue.component.html",
})
export class ModalVenueComponent implements OnInit, OnDestroy {
    constructor(
        private _venueService: VenueService,
        private _remoteQueryService: RemotesQueryService,
        public _modalService: BsModalService,
        private eventBus: EventBusService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {
        this.query = _remoteQueryService.getVenueChangeImpactQuery();
    }
    private subs = new SubSink();

    emailRegex = RemotesConstants.regex.email;
    //subject to transmit the results
    public onSync!: Subject<any>;
    public imapctResponse!: Subject<any>;
    bsimpactModalRef?: BsModalRef;
    isChanged: any;
    venue: any = {};
    timeZones = [];
    countries: any;
    states: any;
    isValid: any;
    values: any = {};
    showAlertMessage = false;
    submitted = false;
    result: any;
    queryContainer: any = { query: {} };
    modelValue: any;
    isAdd = false;
    message = "";
    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }
    query: any;
    impactedEvents: any;

    getTimeZones() {
        this.log.trace("getTimeZones");
        this.subs.sink = this._venueService.getAllTimeZones().subscribe(
            (result) => {
                this.log.trace(
                    "getTimeZones success:" + JSON.stringify(result)
                );

                this.timeZones = result.reference;
            },
            (err) => {
                this.log.showError("Error retrieving time zones");
            },
            () => {
                this.log.trace("close spinner");
            }
        );
    }

    validateFormAndSave(venue, form) {
        this.isValid = true;
        if (venue.name && venue.name.trim().length > 0) {
            this.isValid = true;
            form.controls.venueName.setErrors(null);
        } else {
            form.controls.venueName.setErrors({ required: false });
            this.isValid = false;
        }

        if (venue.streetAddress && venue.streetAddress.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.streetAddress.setErrors(null);
        } else {
            form.controls.streetAddress.setErrors({ required: false });
            this.isValid = false;
        }

        if (venue.city && venue.city.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.city.setErrors(null);
        } else {
            form.controls.city.setErrors({ required: false });
            this.isValid = false;
        }

        if (venue.longitude && venue.longitude.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.longitude.setErrors(null);
        } else {
            form.controls.longitude.setErrors({ required: false });
            this.isValid = false;
        }

        if (venue.latitude && venue.latitude.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.latitude.setErrors(null);
        } else {
            form.controls.latitude.setErrors({ required: false });
            this.isValid = false;
        }

        if (
            (venue.jobDescription && venue.jobDescription.trim().length > 0) ||
            (venue.officeNumber && venue.officeNumber.trim().length > 0) ||
            (venue.mobileNumber && venue.mobileNumber.trim().length > 0) ||
            (venue.contactEmail && venue.contactEmail.trim().length > 0)
        ) {
            if (
                venue.firstName &&
                venue.firstName.trim().length > 0 &&
                venue.lastName &&
                venue.lastName.trim().length > 0
            ) {
                if (this.isValid) {
                    this.isValid = true;
                }
                form.controls.firstName.setErrors(null);
            } else {
                form.controls.firstName.setErrors({ required: false });
                this.isValid = false;
            }
        }

        if (venue.countryCode && venue.countryCode.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.country.setErrors(null);
        } else {
            form.controls.country.setErrors({ required: false });
            this.isValid = false;
        }
        if (venue.countryCode === "US") {
            if (venue.state && venue.state.trim().length > 0) {
                if (this.isValid) {
                    this.isValid = true;
                }
                form.controls.state.setErrors(null);
            } else {
                form.controls.state.setErrors({ required: false });
                this.isValid = false;
            }
        }

        if (venue.timeZone && venue.timeZone.trim().length > 0) {
            if (this.isValid) {
                this.isValid = true;
            }
            form.controls.timeZone.setErrors(null);
        } else {
            form.controls.timeZone.setErrors({ required: false });
            this.isValid = false;
        }

        if (
            form.controls.website.invalid ||
            form.controls.contactEmail.invalid
        ) {
            this.isValid = false;
        }
    }

    saveVenueDetails(venue, form) {
        this.showAlertMessage = false;
        this.log.trace("saveVenueDetails");

        this.submitted = true;
        if (venue.countryCode === "US") {
            venue.state = "";
            _.forEach(this.states, function (obj1) {
                if (obj1.code === venue.stateCode) {
                    venue.state = obj1.name;
                }
            });
        }
        this.validateFormAndSave(venue, form);

        if (this.isValid && form.valid) {
            this.isFormChanged(form); //ToDo
            venue.isChanged = this.isChanged;
            if (venue.isChanged) {
                this.VenueChanged(venue);
            } else {
                this.saveVenueOnProceed(venue);
            }
        }
    }

    isFormChanged(form) {
        this.isChanged = false;
        const controls = form.controls;
        if (
            controls.venueName.pristine &&
            controls.latitude.pristine &&
            controls.longitude.pristine &&
            controls.state.pristine &&
            controls.city.pristine &&
            controls.streetAddress.pristine &&
            controls.timeZone.pristine &&
            controls.country.pristine &&
            controls.postalCode.pristine
        )
            this.isChanged = false;
        else this.isChanged = true;
    }

    VenueChanged(venue) {
        this.query.venueId = venue.id;
        this.message =
            "This Venue Changes will impact all events listed below:";
        this.getImpactedEvents(venue);
    }

    getImpactedEvents(venue) {
        this.subs.sink = this._venueService
            .getImpactedEvents(this.query)
            .subscribe({
                next: (result) => {
                    this.impactedEvents = result;
                    if (
                        this.impactedEvents &&
                        this.impactedEvents.totalRecords > 0
                    ) {
                        this.openImpactedEventListModal();
                    } else {
                        this.venuechangesProceedClicked(venue);
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "unable to find impacted Event. Please try later."
                    );
                },
            });
    }

    openImpactedEventListModal() {
        const initialState: any = {
            self: this,
            impactedEvents: this.impactedEvents,
            message: this.message,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
        };

        this.bsimpactModalRef = this._modalService.show(
            ModalVenueImpactList,
            modalOptions
        );
        this.bsimpactModalRef.content.imapctResponse.subscribe((result) => {
            if (result) {
                this.saveVenueOnProceed(this.venue);
            }
        });
    }

    venuechangesProceedClicked(venue) {
        this.saveVenueOnProceed(venue);
    }
    cancelvenueAction() {}

    checkVenueExistAndSave(venue) {
        let isVenueExists = false;
        const checkVenueQuery = {
            query: this._remoteQueryService.getVenueSetupQuery(
                null,
                null,
                null
            ),
        };
        checkVenueQuery.query.currentPage = 1;
        checkVenueQuery.query.pageSize = 25;
        checkVenueQuery.query.venue = venue.name.trim();
        checkVenueQuery.query.city = venue.city.trim();
        checkVenueQuery.query.exactTerm = true;

        this.subs.sink = this._venueService
            .getAllVenues(checkVenueQuery.query)
            .subscribe(
                (result) => {
                    const venueResult = result.reference.items;
                    _.forEach(venueResult, function (obj1) {
                        // this.recordCount += 1;
                        if (
                            obj1.name.toLowerCase() ===
                                venue.name.trim().toLowerCase() &&
                            obj1.city.toLowerCase() ===
                                venue.city.trim().toLowerCase() &&
                            venue.id !== obj1.id
                        ) {
                            isVenueExists = true;
                        }
                    });
                    if (!isVenueExists) {
                        this.saveVenue(venue);
                    } else {
                        this.log.showModalError(
                            "Venue already exists for this City"
                        );
                        this.showAlertMessage = true;
                    }
                },
                (err) => {
                    this.log.showModalError(err.error);
                }
            );
        return isVenueExists;
    }

    saveVenue(venue) {
        this.subs.sink = this._venueService.saveVenueDetails(venue).subscribe(
            (res) => {
                this.log.trace(
                    "saveVendorDetails - got results:" + JSON.stringify(res)
                );
                this.result = res.reference;
                if (!this.isAdd) {
                }
                this.log.showSuccess("Venue saved Successfully.");
                this.onSync.next(res);
                this.bsModalRef.hide();
            },
            (err) => {
                this.log.showError(
                    "Unable to save Venue details now. Please try again later."
                );
            },
            () => {
                this.submitted = false;
            }
        );
    }

    saveVenueOnProceed(venue) {
        if (this.isValid) {
            this.checkVenueExistAndSave(venue);
        }
    }

    setStates(isoCode) {
        this.venue.countryCode = isoCode;
        if (this.venue) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].isoCode === this.venue.countryCode) {
                    this.states = this.countries[i].states;
                }
            }
        } else {
            this.states = [];
        }
    }

    onMyCustomEvent() {
        const newValue = this.venue.zip.replace(/[^0-9]*/g, "");
        this.venue.zip = newValue;
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.imapctResponse = new Subject();
        this.modelValue = this.values;
        this.isAdd = this.values.isAdd;
        this.countries = this.values.countries;
        if (this.values.isAdd) {
            this.venue = {
                id: -1,
                name: "",
                associateListID: "-2",
                firstName: "",
                lastName: "",
                jobDescription: "",
                officeNumber: "",
                mobileNumber: "",
                contactEmail: "",
                VenuePhoneNumber: "",
                countryCode: "",
                secondaryAirportCode: "",
                timeZone: "",
            };
        } else {
            this.venue = this.values.venueDetail;
        }
        if (this.countries) {
            this.setStates(this.venue.countryCode);
        }
        this.getTimeZones();
    }
    ngOnDestroy() {
        this.subs.sink?.unsubscribe();
    }
}
