import { Component, OnInit } from "@angular/core"; 

@Component({
  selector: '[home]',
  templateUrl: './nbc-home-link.component.html'
})

export class NbcHomeLinkComponent implements OnInit {
  constructor() { }
  scoreUrl = "";
 

  ngOnInit() { 
    this.scoreUrl = window?.preload?.uisettings?.pickADoorUrl;
  }
}
