<!-- Modal content-->
<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">How Can We Assist You?</h4>
        <button type="button"
        class="btn-close"
        (click)="closeHelpModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-md-1 offset-md-3"><i class="fa fa-phone fa-2x"></i></div>
            <div class="col-md-6">
                <address>
                    Call Technical Support 24/7<br>
                    1-855-NBCU-TECH / 1-855-622-8832
                </address>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-1 offset-md-3"><i class="fa fa-envelope fa-2x"></i></div>
            <div class="col-md-6">
                <address>
                    Not a Fan of the Phone<br>
                      <a href="Help/ContactUs" class="btn-link" (click)="closeHelpModel()">Send Us an Email </a>
                    <!--<a>Send Us an Email</a>-->
                </address>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-1 offset-md-3"><i class="fa fa-file-text fa-2x"></i></div>
            <div class="col-md-6">
                <address>
                    Self-Service<br>
                    <!--<a href="" ng-click="closeHelpModel()">Help Docs and FAQs</a>-->
                    <a href="https://nbcu.service-now.com/kb_view_customer.do?sysparm_article=KB0012818"
                    class="btn-link"
                       target="_blank">Help Docs and FAQs</a>
                    <!--Help Docs and FAQs-->
                </address>
            </div>
        </div>
    </div>

</div>
