<div>
    <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
        <button type="button"
                class="btn-close"
                (click)="closeModal()"></button>
    </div>

    <form role="form"
          name="upsertEventTypeForm"
          #detailsForm="ngForm"
          novalidate>
        <span us-spinner
              spinner-key="modalSpinner"></span>
              <nbc-modal-alert></nbc-modal-alert>
        <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="container">
                  <div class="col-md-12">
                    <div class="container">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label for="groupCode"
                                 *ngIf="eventType.id > 0"
                                 class="control-label">Event Id</label><br />
                          <span
                                *ngIf="eventType.id > 0">{{eventType.id}}</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="col-md-12">
                    <div class="container">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label for="description"
                                 class="control-label">Event Type Name</label>
                          <div [ngClass]="{'has-error': eventType.name.$invalid && submitted}">
                            <input type="text"
                                   #name="ngModel"
                                   name="name"
                                   maxlength="40"
                                   class="form-control"
                                   [(ngModel)]="eventType.name"
                                   required>


                            <span class=" control-label control-label-error"
                                  *ngIf="name.invalid && submitted ">
                              Event type name is
                              required.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="container">
                  <div class="col-md-12">
                    <div class="container">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label class="control-label">Has Team ?</label>
                          <div style="height:35px">




                            <bSwitch [switch-on-text]="onText"
                                     [switch-off-text]="offText"
                                     [switch-on-color]="'success'"
                                     [switch-off-color]="'danger'"
                                     [switch-handle-width]="30"
                                     [switch-size]="'mini'"
                                     [(ngModel)]="eventType.hasTeam"
                                     name="hasteam">
                            </bSwitch>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container">
                  <div class="col-md-12">
                    <div class="container">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label class="control-label">Flex ?</label>
                          <div style="height:35px">
                            <bSwitch

                                     [switch-on-text]="onText"
                                     [switch-off-text]="offText"
                                     [switch-on-color]="'success'"
                                     [switch-off-color]="'danger'"
                                     [switch-handle-width]="30"
                                     [switch-size]="'mini'"
                                     [(ngModel)]="eventType.hasFlex" name="hasflex">
                            </bSwitch>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>

              </div>
                <div class="col-md-6">
                  <div class="col-xs-12 col-sm-6 ps-3 pe-3"
                       id="fileUpload">

                    <img [src]="previewUrl" height="130" *ngIf="previewUrl" class="olympic-event-image img-responsive w-100" />




                    <img class="olympic-event-image img-responsive" *ngIf="previewUrl ==''"
                         src="/assets/image/EventType/{{eventType.imageId||0}}" class="olympic-event-image img-responsive w-100" />


                    <label for="file-upload" class="fileUpload btn btn-default btn-block" style="">
                      Upload Image
                    </label>
                    <input id="file-upload" type="file" (change)="fileProgress($event)" name="image" />
                  </div>

                </div>
              </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-default"
                    (click)="closeModal()">Cancel</button>
            <button type="button"
                    *ngIf="isAdd"
                    class="btn btn-primary"
                    id="btnSaveEventType"
                    [disabled]='isDisabled'
                    (click)="disableSave || upsertEventType()">Save</button>
            <button type="button"
                    *ngIf="!isAdd"
                    class="btn btn-primary"
                    id="btnSaveEventType"
                    [disabled]='isDisabled'
                   (click)="disableSave || upsertEventType()">Update</button>
        </div>
    </form>
</div>
