import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { PositionService } from "src/app/Shared/Services/position.service";
import { CrewItemService } from "src/app/Shared/Services/crew-item.service";
import { SkillsService } from "src/app/Shared/Services/skills.service";
import { SubSink } from "subsink";
import { ModalTierRatecardComponent } from "../tier-ratecard/modal-tier-ratecard.component";
import { BaseComponent } from "@fullcalendar/core/internal";

@Component({
    selector: "modal-upsert-position",
    templateUrl: "./modal-upsert-position.html",
})
export class modalUpsertPosition implements OnInit {
    constructor(
        public modalRef: BsModalRef,
        private _positionService: PositionService,
        private _crewItemService: CrewItemService,
        private _eventService: EventService,
        private _subModalService: BsModalService,
        private _skillService: SkillsService,
        private log: NbcLoggerService
    ) {}
    public onSync!: Subject<any>;
    modalDestroyed$: Subject<any> = new Subject();

    // skillObservable$: Observable<any[]> | undefined;
    skillSearchTerm = "";
    skillData: any[] = [];
    positionObservable$: Observable<any[]> | undefined;
    positionSearchTerm: string | undefined;

    bsConfModalRef?: BsModalRef;
    bsEditTierRate?: BsModalRef;
    workSchedulePopover!: any;
    eventId!: number;
    private subs = new SubSink();
    minimumWage: any;

    model: any = {
        eventPosition: {
            employeeType: {
                id: "",
            },
            skills: [],
            activities: [],
            posTierId: null,
            status: {
                id: "O",
                description: "Open",
            },
        },
    };
    localStore: any = undefined;

    employeeTypes: any[] = [];
    emps: any[] = [];

    scheduleDates!: any[];

    conflictScheduleDates!: any[];

    positionHasNoSkills = true;

    numPositions = 1;

    crewStartDisplay = 0;

    crewEndDisplay = 6;

    resultLimit = 10;

    disableRateCode = false;

    rateCodes: any[] = [];

    defaultWorkActivity = "AIR";

    isSaveDisabled = false;

    broadcastingDates!: any[];

    tieredPositions!: any[];

    //this is just a formatting of tierPositions. Earlier code has different format
    tierRateCards: any = [];

    existingTier!: any;

    activityCount = 0;
    hideActions = false;
    customRate: any = "";

    /*
     * object will provide configs for the event at this day
     */
    eventTierSetup!: any;

    positionListItem: any = {};

    customRateExeedsBy!: any;

    disablePosition = false;

    disableJobTitle = false;
    taskItems: any; 
    /**
     * Union Options
     * @type {Object[]}
     * @public
     * @memberof score.controllers.modalCrewPositionController
     */
    unionOptions: any[] = [
        { id: 0, description: "", value: null },
        { id: 1, description: "Union", value: true },
        { id: 2, description: "Non Union", value: false },
    ];

    tierRateCardComment = "";

    /**
     * Work Activities
     * @type {Array}
     * @public
     * @memberof score.controllers.modalCrewPositionController
     */
    workActivities!: any[];

    /**
     * Work Schedule Date
     * @param {item} item
     * @param {Int} id
     * @private
     * @memberof score.controllers.modalCrewPositionController
     */
    WorkScheduleDate(item: any, id: any) {
        const displayDay = moment(item.date).format("ddd");
        const displayDate = moment(item.date).format("MMM D");
        let workActivities = _.cloneDeep(this.workActivities);

        let isAirDate = false;
        _.forEach(this.broadcastingDates, (d) => {
            const diff = moment(d.airDate)
                .startOf("day")
                .diff(moment(item.date).startOf("day"), "days");
            if (diff === 0) {
                isAirDate = true;
            }
        });
        if (isAirDate) {
            workActivities = this.setDefaultWorkActivity(workActivities);
            isAirDate = false;
        }

        _.assign(item, {
            displayDay: displayDay,
            displayDate: displayDate,
            workActivities: workActivities,
        });
        return item;
    }

    /**
     *   SCOR-10821
     */
    setDefaultWorkActivity(workActivities: any) {
        if (!workActivities) return;

        _.forEach(workActivities, (activity) => {
            if (activity?.code?.toUpperCase() === "AIR") {
                activity.isPlanned = true;
                activity.isChecked = true;
            }
        });
        return workActivities;
    }

    initWorkScheduleDates() {
        if (!this.positionListItem.id) {
            this.model.eventPosition.workScheduleDates = [];
            this.scheduleDates.forEach((item) => {
                const workschedule = this.WorkScheduleDate(item, 0);
                this.model.eventPosition.workScheduleDates.push(workschedule);
            });
        }
    }
    setSkillEventTerm($event: any) {
        this.skillSearchTerm = $event;
    }
    onSkillSelected(skill: any) {
        this.log.trace(this.model.eventPosition.skills);
        if (_.isEmpty(this.model.eventPosition.skills)) {
            this.model.eventPosition.skills = [];
        }
        this.model.eventPosition.skills = skill;
        this.skillSearchTerm = "";
    }
    showNextDates() {
        this.crewStartDisplay += 7;
        this.crewEndDisplay += 7;
    }

    /**
     * Show Prev Dates
     * @memberof score.controllers.modalCrewPositionController
     * @public
     */
    showPrevDates() {
        this.crewStartDisplay -= 7;
        this.crewEndDisplay -= 7;
    }

    /**
     * Get Rate Cards
     * @public
     * @param {Int} employeeTypeId employee type id
     * @memberof score.controllers.modalCrewPositionController
     */
    getRateCards(employeeTypeId: any) {
        if (employeeTypeId) {
            for (let i = 0; i < this.employeeTypes.length; i++) {
                if (this.employeeTypes[i].id === employeeTypeId) {
                    if (
                        this.employeeTypes[i]?.description?.toLowerCase() ===
                        "vendor"
                    ) {
                        this.disableRateCode = true;
                        this.model.eventPosition.rateCode = null;
                        this.rateCodes = [];
                    } else {
                        this.rateCodes = this.employeeTypes[i].rateCards;
                        this.disableRateCode = false;
                    }
                }
            }
        } else {
            this.disableRateCode = true;
            this.model.eventPosition.rateCode = null;
            this.rateCodes = [];
        }
    }

    getConflictDates() {
        this.log.trace("getConflictDates()");
        if (this.positionListItem.primaryContact) {
            this.subs.sink = this._crewItemService
                .getConflictDates(
                    this.eventId,
                    this.positionListItem.primaryContact.id,
                    this.positionListItem.id
                )
                .subscribe({
                    next: (result) => {
                        // this.log.trace("getCrewItemHeader got results: " + JSON.stringify(result));
                        this.conflictScheduleDates = result.reference;
                    },
                    error: (err) => {
                        this.log.error(
                            "getConflictDates got an error: " +
                                JSON.stringify(err)
                        );
                        this.log.showError(
                            "Unable to get Conflict Dates at this time. Please try again later."
                        );
                    },
                });
        }
    }

    /**
     * Save Work Schedule Day
     * @param {Int} positionId position id
     * @param {Object[]} workScheduleDate work schedule date
     * @public
     * @membero
     */
    saveWorkScheduleDay(workScheduleDate: any) {
        this.log.trace("saveWorkScheduleDay()");
        const newWorkScheduleDate = _.cloneDeep(workScheduleDate);

        const plannedWorkActivities: any[] = _.filter(
            workScheduleDate.workActivities,
            (o) => {
                return o.isPlanned == true;
            }
        );
        this.model.eventPosition.workScheduleDates[
            newWorkScheduleDate.day
        ].workActivities = newWorkScheduleDate.workActivities;
        this.model.eventPosition.workScheduleDates[
            newWorkScheduleDate.day
        ].plannedWorkActivities = plannedWorkActivities;

        newWorkScheduleDate.workActivities.forEach((item: any) => {
            if (item.isChecked) {
                item.isPlanned = true;
            } else {
                item.isPlanned = false;
            }
        });

        //if (!_.isEmpty(workScheduleDate.parentId)) {
        //  this._positionService.saveWorkScheduleDay(this.eventId, workScheduleDate.parentId, [newWorkScheduleDate])
        //    .pipe(takeUntil(this.modalDestroyed$))
        //    .subscribe(
        //      {
        //        next: (result) => {
        //          this.model = result.reference;
        //        },
        //        error: (err) => {
        //          this.log.error("got an error: " + JSON.stringify(err));
        //          this.log.showModalError("Unable to save the work schedule at this time. Please try again later.")
        //        }
        //      }
        //    );
        //}
    }

    /**
     * Set Activity Count
     * @param {Int} initialCount initial count
     * @param {Object[]} workActivities work activities
     * @public
     */
    setActivityCount(initialCount: any, workActivities: any) {
        this.activityCount = initialCount;
        this.hideActions = true;

        workActivities.forEach(function (item: any) {
            // reset disabled and checked
            item.isDisabled = false;
            item.isChecked = false;

            // set planned items to checked
            if (item.isPlanned) {
                item.isChecked = true;
            }
            // disable if 3 items are checked
            if (!item.isPlanned && initialCount === 4) {
                item.isDisabled = true;
            }
        });
    }

    /**
     * Update Activity Count
     * @param {Object} activityItem activity item
     * @param {Object[]} workScheduleDate work schedule date
     * @public
     */
    updateActivityCount(activityItem: any, workScheduleDate: any) {
        let plannedWorkActivityIndex = 0;
        if (!workScheduleDate.hasOwnProperty("plannedWorkActivities")) {
            workScheduleDate.plannedWorkActivities = [];
        }
        if (activityItem.isChecked) {
            this.activityCount++;
            activityItem.isPlanned = true;
            workScheduleDate.plannedWorkActivities.push(activityItem);
        } else {
            this.activityCount--;
            activityItem.isPlanned = false;
            plannedWorkActivityIndex = _.findIndex(
                workScheduleDate.plannedWorkActivities,
                { id: activityItem.id }
            );
            workScheduleDate.plannedWorkActivities.splice(
                plannedWorkActivityIndex,
                1
            );
        }

        workScheduleDate.workActivities.forEach((item: any) => {
            if (!item.isChecked && this.activityCount === 4) {
                item.isDisabled = true;
            } else {
                item.isDisabled = false;
            }
        });
    }

    /**
     * Select Default Employee Type
     * @public
     */
    selectDefaultEmployeeType() {
        this.log.trace("selectDefaultEmployeeType");
        let i;
        for (i = 0; i < this.employeeTypes.length; i++) {
            if (this.model.eventPosition.employeeType) {
                if (
                    this.employeeTypes[i].id ===
                    this.model.eventPosition.employeeType.id
                ) {
                    this.model.eventPosition.employeeType =
                        this.employeeTypes[i];
                    break;
                }
            }
        }
    }

    /**
     * Load Event Positions
     * @param {Object} query query
     * @public
     * @memberof score.controllers.modalCrewPositionController
     * @returns {Promise}
     */
    loadPositions() {
        this.log.trace("loadPositions");
        this.positionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.positionSearchTerm);
            }
        ).pipe(
            takeUntil(this.modalDestroyed$),
            switchMap((token: string) => {
                return this._positionService.crewPositionAutoComplete(token);
            })
        );
    }

    /**
     * On Position Select
     * @memberof score.controllers.modalCrewPositionController
     * @param {Object} item item
     * @public
     */
    onPositionSelect(selectedPos: any) {
        const item = selectedPos.item;
        this.model.eventPosition.department = item.department;
        this.model.eventPosition.category = item.category;
        this.model.eventPosition.position = item;
        this.model.eventPosition.skills = [];

        if (this.applyTierRateRules()) {
            this.model.customRate = null;
            this.customRate = null;
            this.model.eventPosition.rateCode = null;
            if (
                !_.isEmpty(this.localStore) &&
                this.localStore.eventPosition.category !==
                    this.model.eventPosition.category
            ) {
                this.model.customRate = null;
                this.model.eventPosition.rateCode = null;

                // If Position is in Confirmed Status, Position must change to "assigned" Status
                if (this.localStore.eventPosition.status.id !== "O") {
                    this.model.eventPosition.status.id = "R";
                    this.model.eventPosition.status.description = "Requested";
                } else {
                    //if open status, we need to maintain it
                    this.model.eventPosition.status.id = "O";
                    this.model.eventPosition.status.description = "Open";
                }
            }
        } else {
            //condition written because we are not interested to disturb existing code. This will execute if the event is tiered and user selects position with the category ATL
            // we need to clear the BTL rate
            if (this.existingTier && this.existingTier > 0) {
                if (
                    this.model.eventPosition.category === "ATL" &&
                    !_.isEmpty(this.localStore) &&
                    this.localStore.eventPosition.category !==
                        this.model.eventPosition.category
                ) {
                    this.model.customRate = null;
                    this.customRate = null;
                    this.model.eventPosition.rateCode = null;

                    //SCOR-14171
                    // If Position is in Confirmed Status, Position must change to "assigned" Status
                    if (this.localStore.eventPosition.status.id !== "O") {
                        this.model.eventPosition.status.id = "R";
                        this.model.eventPosition.status.description =
                            "Requested";
                    } else {
                        //if open status, we need to maintain it
                        this.model.eventPosition.status.id = "O";
                        this.model.eventPosition.status.description = "Open";
                    }
                }
            }
        }
        this.skillData = [];
        this.loadSkills();
    }

    loadSkills() {
        const positionId = this.model.eventPosition.position.id;
        this._skillService.autoCompleteByPosition(positionId, "").subscribe({
            next: (result: any) => {
                this.skillData = result || [];
            },
            error: (err) => {
                this.log.showError(
                    "Unable to load skills at this time. Please try again later."
                );
            },
        });
    }

    /**
     * Load SKills By Position
     * @memberof score.controllers.modalCrewPositionController
     * @public
     * @returns {Promise}
     */
    //loadSkillsByPosition() {
    //  this.log.trace("loadSkillsByPosition");
    //  this.skillObservable$ = new Observable((observer: Subscriber<string>) => {
    //    // Runs on every search
    //    observer.next(this.skillSearchTerm);
    //  }).pipe(takeUntil(this.modalDestroyed$),
    //    switchMap((token: string) => {

    //      return this._skillService.autoCompleteByPosition(this.model?.eventPosition?.position?.id,token)
    //    })
    //  );

    //  //if (typeof this.model.eventPosition.position !== "undefined") {
    //  //  skillsService.autoCompleteByPosition(this.model.eventPosition.position.id).success(function (res) {
    //  //    log.trace("got results: " + JSON.stringify(res));
    //  //    defer.resolve(res.reference);

    //  //    if (res.reference.length === 0) {
    //  //      this.positionHasNoSkills = true;
    //  //    } else {
    //  //      this.positionHasNoSkills = false;
    //  //    }
    //  //  }).error(function (data) {
    //  //    log.error("got an error: " + JSON.stringify(data));
    //  //    defer.reject(data);
    //  //    this.$emit("showAlert", "danger", "Unable to load skills at this time. Please try again later.", "modalAlert");
    //  //  });
    //  //}

    //  //return defer.promise;
    //};

    /**
     * Save Position Data
     * @memberof score.controllers.modalCrewPositionController
     * @param {Boolean} isValid is valid flag
     * @public
     */
    savePositionData(isValid: any) {
        this.log.trace("savePosition()");
        this.isSaveDisabled = true;
        let overlapactivity = false;
        console.log(this.minimumWage);
        this.model.tierRateCardComment = this.tierRateCardComment;
        let selectedRateCode = "";
        if (this.model.eventPosition) {
            if (
                this.model.eventPosition.rateCode &&
                this.model.eventPosition.rateCode != null
            ) {
                selectedRateCode = this.model.eventPosition.rateCode.id;
            }
        }

        //the billing rate is not available when the rate card is changed..hence querying the ratecards to derive the rate
        let rateValue;
        if (selectedRateCode != "") {
            const rateCard = _.filter(
                this.model.eventPosition.employeeType.rateCards,
                (rateCard) => {
                    return rateCard.id == selectedRateCode;
                }
            );

            if (rateCard.length > 0) {
                rateValue = rateCard[0].billingRate;
            }
        } else {
            rateValue = null;
        }

        ////tiered
        //if (this.model.customRate && (this.model.customRate != null || this.model.customRate != "") && (this.model.eventPosition.rateCode.id != "FLAT DAY") && (this.model.customRate < this.minimumWage)) {
        //  this.log.showModalError("The rate does not match the minimum wage requirement.");
        //  this.isSaveDisabled = false;
        //}
        //else
        if (
            this.customRate &&
            (this.customRate != null || this.customRate != "") &&
            this.model.eventPosition.rateCode.id != "FLAT DAY" &&
            this.customRate < this.minimumWage
        ) {
            //this.log.showModalError("The rate does not match the minimum wage requirement.");
            this.showConfirmMsg(
                "Minimum Wage",
                "The entered rate is less than the minimum wage.",
                false,
                null,
                null,
                null,
                "Close",
                null,
                []
            );
            this.isSaveDisabled = false;
        } else if (
            this.model.eventPosition.rateCode &&
            this.model.eventPosition.rateCode.billingRate != null &&
            this.model.eventPosition.rateCode.id != "FLAT DAY" &&
            this.model.eventPosition.rateCode.billingRate < this.minimumWage
        ) {
            this.showConfirmMsg(
                "Minimum Wage",
                "The entered rate is less than the minimum wage.",
                false,
                null,
                null,
                null,
                "Close",
                null,
                []
            );

            this.isSaveDisabled = false;
        } else if (
            this.model.eventPosition.rateCode &&
            this.model.eventPosition.rateCode.id != "FLAT DAY" &&
            rateValue != null &&
            rateValue < this.minimumWage
        ) {
            this.showConfirmMsg(
                "Minimum Wage",
                "The entered rate is less than the minimum wage.",
                false,
                null,
                null,
                null,
                "Close",
                null,
                []
            );
            this.isSaveDisabled = false;
        } else {
            if (
                isValid &&
                this.model.eventPosition.position &&
                this.model.eventPosition.position.id
            ) {
                this.model.customRate = this.customRate;
                if (
                    (!_.isEmpty(this.model.eventPosition.employeeType) &&
                        this.model.eventPosition.employeeType?.description?.toLowerCase() ===
                            "vendor") ||
                    _.isUndefined(this.model.customRate) ||
                    _.isEmpty(this.model.customRate)
                ) {
                    this.model.customRateExeedsBy = null;
                }
                if (this.positionListItem.id) {
                    for (
                        let j = 0;
                        j < this.model.eventPosition.workScheduleDates.length;
                        j++
                    ) {
                        this.model.eventPosition.workScheduleDates[
                            j
                        ].updateEventActivityOnly = true;
                        if (
                            this.model.eventPosition.workScheduleDates[j]
                                .plannedWorkActivities.length > 0 &&
                            this.model.primaryContact &&
                            this.model.primaryContact.id
                        ) {
                            //console.log(moment(this.model.eventPosition.workScheduleDates[j].date).format('yyyy-MM-DD'));
                            if (
                                this.conflictScheduleDates.includes(
                                    moment(
                                        this.model.eventPosition
                                            .workScheduleDates[j].date
                                    ).format("yyyy-MM-DD")
                                )
                            ) {
                                overlapactivity = true;
                            }
                        }
                    }
                    if (overlapactivity) {
                        this.isSaveDisabled = false;
                        this.log.showModalError(
                            "Overlapping activity exist in the Positions for the same contact"
                        );
                    } else {
                        this.subs.sink = this._eventService
                            .bulkUpdateEventPositions(this.eventId, [
                                this.model,
                            ])
                            .subscribe({
                                next: (result) => {
                                    this.onSync.next(this.model);
                                    this.modalRef.hide();
                                    this.isSaveDisabled = false;
                                },
                                error: (err) => {
                                    this.isSaveDisabled = false;
                                    this.log.showModalError(
                                        "Unable to add a position at this time. Please try again later."
                                    );
                                },
                            });
                    }
                } else {
                    const crewItem = {
                            id: 0,
                            sortOrder: 0,
                            originalSortOrder: 0,
                            primaryContactId: 0,
                            primaryContact: {},
                            secondaryContactId: 0,
                            secondaryContact: {},
                            alternateContactId: 0,
                            alternateContact: {},
                            eventPosition: this.model.eventPosition,
                            payrollProvider: "",
                            customRate: this.customRate,
                            tierRateCardComment: this.tierRateCardComment,
                        },
                        multiplier = this.numPositions,
                        positions: any = [];
                    let i;

                    if (this.numPositions > 1) {
                        for (i = 0; i < multiplier; i++) {
                            positions.push(crewItem);
                        }
                    } else {
                        positions.push(crewItem);
                    }

                    this.subs.sink = this._eventService
                        .addEventPosition(this.eventId, positions)
                        .subscribe({
                            next: (data) => {
                                this.onSync.next(data);
                                this.modalRef.hide();
                                this.isSaveDisabled = false;
                            },
                            error: (err) => {
                                this.log.showModalError(
                                    "Unable to add a position at this time. Please try again later."
                                );
                                this.isSaveDisabled = false;
                                this.log.error(err);
                            },
                        });
                }
            } else {
                this.log.showModalError("Position is invalid.");
                this.isSaveDisabled = false;
            }
        }
    }

    /**
     * Cancel
     * @memberof score.controllers.modalCrewPositionController
     * @public
     */
    cancel() {
        this.modalRef.hide();
        this.log.trace("canceled modal");
    }

    /**
     * Check Rate Card
     * @public
     */
    checkRateCard() {
        if (
            (this.model.eventPosition.rateCard &&
                this.model.eventPosition.rateCode.id === "00") ||
            this.applyTierRateRules()
        ) {
            this.customRate = undefined;
        }
        // $(".custom-flat-rate-field").focus();
    }

    associateToPosition() {
        if (this.model) {
            if (!_.isEmpty(this.model.eventPosition.rateCode)) {
                const rateCard = _.find(
                    this.tieredPositions,
                    {
                        billing_code:this.model.eventPosition.rateCode.id
                    }
                );
                if (!_.isEmpty(rateCard)) {
                    //XXX Add check for !null position and description !== ""
                    if (
                        rateCard.position &&
                        rateCard.position.description !== ""
                    ) {
                        this.model.eventPosition.jobTitleDetail =
                            this.model.eventPosition.position.description;
                        this.model.eventPosition.position = rateCard.position;
                        this.model.eventPosition.posTierId =
                            rateCard.positionTiers[0].currentRates.posTierId;
                        //updating display text
                        this.positionSearchTerm = rateCard.position.description;
                        this.loadSkills();
                        this.model.eventPosition.department =
                            rateCard.position.department;
                        this.model.eventPosition.category =
                            rateCard.position.category;

                        this.customRate = null;
                        this.customRateExeedsBy = null;

                        this.disablePosition = true;
                    } else {
                        this.disablePosition = false;
                        this.model.eventPosition.jobTitleDetail = "";
                    }

                    this.model.eventPosition.existingRateCard =
                        this.model.eventPosition.rateCode.id;
                    this.model.eventPosition.posTierId =
                        rateCard.positionTiers[0].currentRates.posTierId;
                }
            } else {
                this.model.eventPosition.rateCode = null;
                this.customRate = null;
                this.customRateExeedsBy = null;
                this.model.eventPosition.position = null;
                this.model.eventPosition.department = null;
                this.model.eventPosition.category = null;
                //updating display text
                this.positionSearchTerm = "";
                this.disablePosition = false;
                if (this.model.eventPosition.existingRateCard) {
                    if (
                        this.model.eventPosition.existingRateCard.startsWith(
                            "TR_"
                        )
                    ) {
                        this.model.eventPosition.jobTitleDetail = "";
                        this.model.eventPosition.existingRateCard = "";
                    }
                }
            }
        } else {
            this.model.eventPosition.posTierId = null;
        }
    }

    createRateListForMP() {
        if (!this.tieredPositions) return;

        this.tieredPositions.forEach((item) => {
            this.tierRateCards.push({
                id: item.billing_code,
                billing_code: item.billing_code,
                description: item.desc,
                name: null,
            });
        });
    }

    /*
     * If tier is enabled on an EVENT + It is a BTL category for newly added/ being edited position.
     */
    applyTierRateRules() {
        if (this.existingTier && this.existingTier > 0) {
            if (this.model.eventPosition.category == "BTL") {
                return true;
            }
            return false;
        }
        return false;
    }

    openEditTierRatecardModal() {
        this.log.trace("openEditTierRatecardModal");
        const selected_billing_code =
            this.model.eventPosition.rateCode.billing_code;
        const tierrateCard = _.find(this.tieredPositions, function (o) {
            return o.billing_code === selected_billing_code;
        });

        this.model.eventPosition.customRate = this.customRate;
        this.model.eventPosition.tierRateCardComment = this.tierRateCardComment;

        const initialState: any = {
            position: !this.positionListItem.id
                ? this.model
                : this.positionListItem,
            tierrateCard: tierrateCard,
            eventId: this.eventId,
            minimumWage: this.minimumWage,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsEditTierRate = this._subModalService.show(
            ModalTierRatecardComponent,
            modalOptions
        );
        this.bsEditTierRate.content.onSync.subscribe(
            (positionListItem: any) => {
                this.customRate = positionListItem.customRate;
                this.tierRateCardComment = positionListItem.tierRateCardComment;
            }
        );
    }

    tierEmployeeTypeChanged() {
        this.model.eventPosition.rateCode = null;
        this.customRate = null;
        if (
            _.isEmpty(this.model.eventPosition.employeeType) ||
            this.model.eventPosition.employeeType?.description?.toLowerCase() ===
                "vendor"
        ) {
            this.disableRateCode = true;
        } else {
            this.disableRateCode = false;
        }
    }

    customRateChanged() {
        const newValue = this.customRate;
        this.customRate = newValue;
        //e.preventDefault();
        this.customRateUserConfirmationWorkFlow(newValue);
    }

    customRateUserConfirmationWorkFlow(newValue: any) {
        if (!newValue) return;

        if (newValue > 250 && this.model.eventPosition.rateCode.id === "00") {
            this.showConfirmMsg(
                "Maximum Hourly Rate Exceeded",
                "Hourly rates of over $250.00 must be entered by Payroll Team, please enter $1.00 as the rate and notify payroll of what correct rate should be.",
                false,
                null,
                null,
                null,
                "Close",
                this.clearExeededCustomRate,
                []
            );
        }
    }
    showConfirmMsg(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any
    ) {
        this.log.trace("showValidationErrorModal");
        let modalInstance;

        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText,
            hideConfirm: true,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsConfModalRef = this._subModalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsConfModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("confirmation");
            // confirmCallback.apply(this, confirmArgs);
        });
        this.bsConfModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
            cancelCallback.apply(this, cancelargs);
        });
    }

    clearExeededCustomRate() {
        this.customRate = null;
    }

    setWorkSchedulePopover(schedule: any, listItemId: any, index: any) {
        this.workSchedulePopover = schedule;
        this.workSchedulePopover.showPopover = true;
        const ind = {
            index: index,
            parentId: listItemId,
        };
        _.assign(this.workSchedulePopover, ind);
        schedule.showPopover = true;
    }

    ngOnInit() {
        this.onSync = new Subject();

        this.employeeTypes = this.emps;
        this.eventId = this.eventId;
        this.broadcastingDates = _.cloneDeep(this.broadcastingDates);
        this.workActivities = _.cloneDeep(this["taskItems"]);

        if (!this.positionListItem.id) {
            this.positionListItem = this.model;
            this.model.eventPosition.workScheduleDates = _.cloneDeep(
                this.scheduleDates
            );
        } else {
            this.model = _.cloneDeep(this.positionListItem);
            this.localStore = _.cloneDeep(this.positionListItem);
            this.model.eventPosition.position =
                this.positionListItem.eventPosition.position;
            this.customRate = this.positionListItem.customRate;
            this.positionSearchTerm =
                this.model.eventPosition.position.description;
            if (this.model.eventPosition.employeeType)
                this.getRateCards(this.model.eventPosition.employeeType.id);
            this.getConflictDates();
            this.loadSkills();
            // this.associateToPosition();
        }

        this.initWorkScheduleDates();
        this.loadPositions();

        this.selectDefaultEmployeeType();
        // this.loadSkillsByPosition();
        this.createRateListForMP();
        this.disableJobTitle = false;

        this.disablePosition = false;

        this.disablePosition = false;

        if (
            this.model.eventPosition.rateCode &&
            this.model.eventPosition.rateCode !== null &&
            this.model.eventPosition.rateCode?.billing_code?.startsWith("TR_")
        ) {
            const rateCard = _.find(
                this.tieredPositions,
                {
                    billing_code: this.model.eventPosition.rateCode.billing_code
                }
            );

            if (!_.isEmpty(rateCard)) {
                //XXX Add check for !null position and description !== ""
                if (rateCard.position && rateCard.position.description !== "") {
                    this.disablePosition = true;
                }
            }
        }
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
