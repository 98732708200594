import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { SubSink } from "subsink";

@Component({
    selector: "modal-event-type",
    templateUrl: "./modal-event-type.component.html",
    styles: ["input[type='file'] { display: none }"],
})
export class ModalEventTypeComponent implements OnInit, OnDestroy {
    constructor(
        private _EventTypeService: EventTypeService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    //subject to transmit the results
    public onSync!: Subject<any>;
    private subs = new SubSink();
    isAdd = false;
    eventType: any;
    eventTypes: any = { id: 0, name: "", hasTeam: false, hasFlex: false };
    modalTitle = "";
    submitted = false;
    src = "/assets/image/EventType/{{eventType.imageId||0}}";
    validImgFileTypes = [
        ".bmp",
        ".gif",
        ".jpg",
        ".jpeg",
        ".ico",
        ".png",
        ".tif",
    ];
    maxFileSizeToUploadInKB = 50;
    isDisabled = false;
    disableSave = false;
    onText = "Y";
    offText = "N";

    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }

    fileData: any;
    previewUrl: any = "";
    fileUploadProgress: string | undefined;
    uploadedFilePath: string | undefined;

    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        this.preview();
    }

    preview() {
        // Show preview
        const mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.previewUrl = reader.result;
        };
    }

    onSubmit() {
        const formData = new FormData();
        formData.append("file", this.fileData);
    }

    disableSavefn() {
        this.disableSave = true;
        // $("#btnSaveEventType").removeClass("btn btn-primary").addClass("btn btn-default");
    }
    upsertEventType() {
        this.log.trace("saveEventType");

        if (!this.validate() || this.isDisabled) {
            return;
        }
        this.disableSavefn();

        this.subs.sink = this._EventTypeService
            .upsert(this.eventType, this.fileData)
            .subscribe(
                (result) => {
                    this.onSync.next("Event Added");
                    this.bsModalRef.hide();
                },
                (err) => {
                    this.log.showModalError("Error Creating Event Type");
                }
            );
    }

    validate() {
        let msg;

        if (_.isEmpty(_.trim(this.eventType.name))) {
            msg = "Event Type is required";
        } else {
            const existingEventType = _.findIndex(this.eventTypes, (o: any) => {
                return (
                    o.name.toLowerCase() ===
                    _.trim(this.eventType.name.toLowerCase())
                );
            });

            const arr: any = [];

            //here you add another number
            if (this.fileData != undefined) arr.push(this.fileData);

            if (
                _.isEmpty(arr) &&
                (!this.eventType.imageId || this.eventType.imageId === 0)
            ) {
                msg = "Logo image is required";
            }

            if (!_.isEmpty(arr)) {
                const newValue = arr;
                const imgSize = _.round(newValue[0].size / 1024);
                const extension = "." + newValue[0].name.split(".").pop();

                if (imgSize > this.maxFileSizeToUploadInKB) {
                    msg = "Images with max. size 50KB can be uploaded";
                }
                if (!_.includes(this.validImgFileTypes, extension)) {
                    msg =
                        "Event type graphic can be uploaded in .bmp, .gif, .jpg, .jpeg, .ico, .tif, .gif format";
                }
            }

            if (existingEventType > -1 && this.isAdd) {
                msg = "Cannot have duplicate Event Type!";
            }
            let j = 0;
            let duplicateIndicator = 0;
            for (j; j < this.eventTypes.length; j++) {
                if (
                    this.eventTypes[j].name.toLowerCase() ==
                        _.trim(this.eventType.name.toLowerCase()) &&
                    this.eventType.id !== this.eventTypes[j].id
                ) {
                    duplicateIndicator++;
                }
            }
            if (duplicateIndicator > 1) {
                msg = "Cannot have duplicate Event Type!";
            }
        }

        if (msg) {
            this.log.showModalError(msg);
            return false;
        }

        return true;
    }

    ngOnInit() {
        this.onSync = new Subject();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
