import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { JobTitleService } from "src/app/Shared/Services/job-title.service";
import { SubSink } from "subsink";

@Component({
    selector: "modal-job-title",
    templateUrl: "./modal-job-title.component.html",
})
export class ModalJobTitleComponent implements OnInit, OnDestroy {
    constructor(
        private _jobTitleService: JobTitleService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    isJobtileExists = false;
    public onSync!: Subject<any>;
    private subs = new SubSink();
    groupTypes: any;
    modelValue: any = {};
    values:
        | {
              isAdd: any;
              groupId: any;
              maxNum: any;
          }
        | undefined;
    disableSave = false;
    result: any;
    showAlertMessage = false;
    submitted = false;
    onText = "Y";
    offText = "N";
    departments = [
        {
            value: 1,
            label: "Production",
        },
        {
            value: 2,
            label: "Engineering",
        },
    ];
    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }
    flsaStatuses = [
        {
            value: "Hourly (Non-exempt)",
            label: "Hourly (Non-exempt)",
        },
        {
            value: "Daily (Exempt)",
            label: "Daily (Exempt)",
        },
        {
            value: "Not Applicable",
            label: "Not Applicable",
        },
    ];

    validate() {
        let isValidInfo = true;
        if (!this.modelValue.jobTitleDetails.groupCode) {
            return false;
        }
        if (
            _.isEmpty(
                this.modelValue.jobTitleDetails.groupName ||
                    this.modelValue.jobTitleDetails.groupName == null
            )
        ) {
            isValidInfo = false;
        }
        this.modelValue.isValidDept = true;
        this.modelValue.isValidGroupType = true;
        this.modelValue.isFlsaStatusValid = true;

        if (this.modelValue.jobTitleDetails.department.id) {
            if (
                _.isEmpty(
                    this.modelValue.jobTitleDetails.department.id.toString()
                ) ||
                this.modelValue.jobTitleDetails.department.id <= 0
            ) {
                this.modelValue.isValidDept = false;
                isValidInfo = false;
            }
        } else {
            this.modelValue.isValidDept = false;
            isValidInfo = false;
        }

        if (this.modelValue.jobTitleDetails.groupTypeNo) {
            if (
                _.isEmpty(
                    this.modelValue.jobTitleDetails.groupTypeNo.toString()
                ) ||
                this.modelValue.jobTitleDetails.groupTypeNo <= 0
            ) {
                this.modelValue.isValidGroupType = false;
                isValidInfo = false;
            }
        } else {
            this.modelValue.isValidGroupType = false;
            isValidInfo = false;
        }

        //null need to be check as it is for loading the default value
        if (
            !this.modelValue.jobTitleDetails.flsaStatus ||
            this.modelValue.jobTitleDetails.flsaStatus === "null" ||
            _.isEmpty(this.modelValue.jobTitleDetails.flsaStatus)
        ) {
            this.modelValue.isFlsaStatusValid = false;
            isValidInfo = false;
        }
        return isValidInfo;
    }

    saveJobCode(jobTitleDetails) {
        if (!this.modelValue.isAdd) {
            jobTitleDetails.isDataUpdated = true;
        }
        this.log.trace("addJobCode");
        this.disableSavefn();
        jobTitleDetails.groupName = _.trim(jobTitleDetails.groupName);
        this.subs.sink = this._jobTitleService
            .saveJobTitleDetails(jobTitleDetails)
            .subscribe(
                (result) => {
                    this.result = result.reference;
                    this.modelValue.isGroupCodeAvailable = false;
                    this.showAlertMessage = true;

                    this.log.showSuccess("Job Title Saved Successfully");

                    this.onSync.next(result);
                    this.bsModalRef.hide();
                },
                (err) => {
                    this.log.showModalError(err.error);
                    this.showAlertMessage = true;
                    this.enableSavefn();
                },
                () => {
                    this.log.trace("addJobCode - removing spinner");
                    this.enableSavefn();
                }
            );
    }

    enableSavefn() {
        this.disableSave = false;
    }
    disableSavefn() {
        this.disableSave = true;
    }

    getGroupTypes() {
        this.log.trace("getGroupTypes");
        this.subs.sink = this._jobTitleService.getgroupTypes().subscribe(
            (result) => {
                this.groupTypes = result.reference;
            },
            (err) => {
                this.log.error(
                    "getGroupTypes got an error: " + JSON.stringify(err)
                );
            },
            () => {
                this.log.trace("removing spinner");
            }
        );
    }

    JobTitleOnChange() {
        this.submitted = false;
        this.isJobtileExists = false;
    }

    getNewGroupCodeForJobTitle() {
        this.subs.sink = this._jobTitleService
            .GetNewGroupCodeForJobTitle()
            .subscribe(
                (result) => {
                    this.modelValue.jobTitleDetails = result.reference;
                    this.modelValue.isGroupCodeAvailable = true;
                    this.modelValue.jobTitleDetails.groupTypeNo = 0;
                },
                (err) => {
                    this.log.error("get got an error: ");
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    getJobCode() {
        this.log.trace("getJobCode");
        this.subs.sink = this._jobTitleService
            .getJobTitleDetails(this.values?.groupId)
            .subscribe(
                (result) => {
                    this.modelValue.isGroupCodeAvailable = true;
                    this.modelValue.jobTitleDetails = result.reference;
                },
                (err) => {
                    this.log.error("get got an error: ");
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    addJobCode() {
        this.submitted = true;
        if (this.validate()) {
            if (!this.modelValue.jobTitleDetails.active)
                this.modelValue.jobTitleDetails.active = "False";
            this.saveJobCode(this.modelValue.jobTitleDetails);
        }
    }

    GetDummyData() {
        return {
            groupName: "",
            department: { id: "" },
            groupTypeNo: "",
        };
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.getGroupTypes();

        this.modelValue.isAdd = this.values?.isAdd;
        this.modelValue.jobTitleDetails = this.GetDummyData();

        if (this.values?.isAdd) {
            this.getNewGroupCodeForJobTitle();
        } else {
            this.getJobCode();
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
