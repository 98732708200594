import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { NbcBaseComponent } from "../../Core/component/Base.Component";

@Component({
    selector: "nbc-tags",
    templateUrl: "./nbc-tags.component.html",
})
export class NbcTagsComponent
    extends NbcBaseComponent
    implements OnInit, OnChanges
{
    @Input() searchTerm!: any | undefined;
    @Input() searchTermObservable$: Observable<any[]> = new Observable();
    @Input() sourceData!: any[];
    @Input() asyncLoad = true;
    @Input() tagDisplay!: any;
    @Input() tagEventlist!: any;
    @Input() placeholder!: any;
    @Input() tagId!: any;
    @Input() tags!: any;
    @Input() tagName!: any;
    @Input() nbcMinLength = 2;
    @Input() nbcAuthenticateUser: any | undefined;
    @Input() nbcAuthenticateCommand: any | undefined;

  @Output() nbcOnOptionSelect = new EventEmitter<any>();
  @Output() tagChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() tagOnRemove : EventEmitter<any> = new EventEmitter<any>();

    eventTerm: any;
    tagSource: any[] = [];
    isAuthorized = true;
    clonedSource: any[] = [];

  onEventSelected($event) {
    if (this.tags.indexOf($event.item) == -1) {
      this.tags.push($event.item);
      this.updateSource();
    }
    this.searchTerm = '';
    this.nbcOnOptionSelect.emit(this.tags)
  }
  removeTag(name, event) {
    this.tags.splice(this.tags.indexOf(event), 1);
    this.updateSource();
    this.tagOnRemove.emit(this.tags);
  }
  updateSource() {
    this.sourceData = _.cloneDeep(this.clonedSource);
    _.forEach(this.tags, (item: any) => {
      _.each(this.clonedSource, (source: any) => {
        if (_.parseInt(source.id) === _.parseInt(item.id)) {
          _.remove(this.sourceData, { id: _.parseInt(item.id) });
        }
      });
    });
  }

    EmitModelData() {
        this.tagChange.emit(this.searchTerm);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "sourceData") {
                this.sourceData = changes["sourceData"]["currentValue"];
                this.clonedSource = _.cloneDeep(this.sourceData);
                this.updateSource();
            }
        }
    }

    ngOnInit(): void {
        if (!this.tags || this.tags?.length === 0) {
            this.tags = [];
        }
        if (this.asyncLoad) {
            this.searchTermObservable$.subscribe((val: any) => {});
        }
        this.isAuthorized = this.authorize(this.nbcAuthenticateUser);
    }
}
