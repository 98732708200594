<div>
  <div class="modal-header">
    <h3 class="modal-title">{{reportName}}</h3>
    <button type="button"
            class="btn-close"
            (click)="cancel()"
            aria-hidden="true">
    </button>
  </div>
  <div *ngIf="showAlertMessage">
    <div class="pt-3">
      <nbc-modal-alert></nbc-modal-alert>
  </div>
  </div>
  <div class="modal-body">
    <div class="grid-table">
      <div class="grid-content-row">
        <div class="grid-col report-name">
          <label><strong>Season</strong></label>
        </div>
        <!-- <div class="form-group">
    <div class="nbc-tag-container">
      <div class="tag-container">
        <div *ngFor="let item of seasons" class="tag">
          <span class="tag-text">{{item.name}}</span>
          <i class="tag-remove glyphicon glyphicon-remove" (click)="removeTagSeason('positions',item);"></i>
        </div>
        <input appInputRestriction="restrictChars"
               name="seasons"
               id="seasons"
               [typeahead]="seasonObserver"
               [typeaheadAsync]="true"
               typeaheadOptionField="name"
               [typeaheadOptionsLimit]="10"
               placeholder="Add Season"
               class="form-control"
               [(ngModel)]="seasonTerm"
               [isAnimated]="true"
               typeaheadWaitMs="200"
               (typeaheadOnSelect)="onSeasonSelected($event)">
      </div>
    </div>
  </div> -->
      <div class="grid-col">
        <nbc-tags [(searchTerm)]="seasonTerm"
                  [searchTermObservable$]="seasonObserver$"
                  [tagDisplay]="'name'"
                  [tagId]="'seasons1'"
                  [tagName]="'seasons1'"
                  (tagChange)="setSearchSeasonTerm($event)"
                  [placeholder]="'Add Season'"
                  [tags]="seasons"
                  (nbcOnOptionSelect)="emitSeason($event)"></nbc-tags> 

      </div>

        <div class="grid-col report-edit">
          <button class="btn btn-default"
                  (click)="clearSeason()">
            Clear
          </button>
        </div>
      </div>

      <div class="grid-content-row">
        <div class="grid-col report-name">
          <label><strong>Event Name</strong></label>
        </div>
      <div class="grid-col">
        <nbc-tags [(searchTerm)]="eventTerm"
                  [searchTermObservable$]="loadObserver$"
                  [tagDisplay]="'name'"
                  [tagId]="'event'"
                  [tagName]="'event'"
                  (tagChange)="setSearchTerm($event)"
                  [placeholder]="'Add Event'"
                  [tags]="events"
                  (nbcOnOptionSelect)="emitEvent($event)"></nbc-tags>

      </div>
          <div class="grid-col report-edit">
            <button class="btn btn-default"
                    (click)="clearEvent()">
              Clear
            </button>
          </div>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">
      Cancel
    </button>
     <button type="button"
            class="btn btn-primary"
            (click)="exportRemoteStaffingSummaryReport();">
      Export
    </button>
  </div>
  <app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
