import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbcLoggerService } from 'src/app/Shared/logger/nbc-logger.service';
import { AddressService } from 'src/app/Shared/Services/address.service';
import { eventManagerService } from 'src/app/Shared/Services/eventmanager.service';
import * as _ from 'lodash';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { OnboardingService } from 'src/app/Shared/Services/onboarding.service';
import { ActivatedRoute } from '@angular/router';
//import { analyzeAndValidateNgModules } from '@angular/compiler'; // Not Required
import { EmitEvent, EventBusService } from 'src/app/Core/services/event-bus.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-position-request',
  templateUrl: './position-request.component.html'
})
export class PositionRequestComponent implements OnInit, OnDestroy {



  constructor(private _addressService: AddressService,
    private _eventManagerService: eventManagerService,
    private _onboardingService: OnboardingService,
    public _bsModalRef: BsModalRef,
    private activatedRoutes: ActivatedRoute,
    private _eventBus: EventBusService,
    public options: ModalOptions,
    private log: NbcLoggerService) {

  }

  public onPositionRequestSync!: Subject<any>;
  date: any;
  positionListItem: any[] | undefined;
  frmPositionRequest: any;
  reportsToManager: any;
  NoOfPositions: any;
  isSaveDisabled = false;
  isRequiredValidation = true;
  isPositionStartDateInvalid = false;
  positionStartDate: any;
  broadcastingDates: any
  tieredPosition: any;
  tpayRate: any;
  countries: any;
  tierRateCards: any[] = [];
  productionManagers:any[] = [];
  reportsToList:any[] = [];
  eventTierSetup: any[] = [];
  eventDetails: any;
  submitted: any;
  positionRequestsLength: any;
  positionDetails: any;
  contactDetails = [];
  companyCode: any;
  postionType: any
  countryCode = "US";
  tieredPositions: any;
  tieredRateComment = "";
  isTieredEvent: boolean = false;
  isTieredEventBtn = "";
  scheduleDates: any;
  isRateAboveScale = false;
  disableSubmitPositionRequestButtonOnClick = false;
  private subs = new SubSink();
  states: any;
  positionTypeDDl = {
    options: [
      { id: 1, description: "PEP Casual" },
      { id: 2, description: "Daily Hire NABET" },
      { id: 3, description: "Daily Hire DGA" },
      { id: 4, description: "PEP IATSE" }]
  };
  companyCodeDDl = {
    options: [
      { id: 1, description: "Sports L0VA" },
      { id: 2, description: "Olympics L076" },
      { id: 3, description: "Olympic Channel L0VF" }
      //,{ id: 4, description: "Golf L0GG" }
    ]
  };
  eventId: any;
  disableFlsaStatus = true;
  flsaStatus: any;
  flsaStatusDDl = {
    options: [
      { id: "Hourly (Non-exempt)", description: "Hourly (Non-exempt)" },
      { id: "Daily (Exempt)", description: "Daily (Exempt)" }]
  };
  model: any;
  localStore: any;
  positionRequests: any | undefined;
  currentRequest = 0;
  hiringManger = null;
  rateAboveScale = 'false';
  nonExemptPositions = ["Sr. A1",
    "A1",
    "Sr. A2",
    "A2",
    "Hard Camera",
    "Handheld Camera",
    "Robotic Camera",
    "Lead EVS",
    "EVS Operator",
    "Sr. Video",
    "Video",
    "Lighting Director",
    "Lighting Assistant",
    "Sr. Utility",
    "Utility",
    "Fiber Tech",
    "Chyron/Duet Operator",
    "Technical Director",
    "Teleprompter",
    "Parab",
    "Jib",
    "Coordinator",
    "Driver",
    "Graphics PA",
    "Production Assistant/Production Associate",
    "Runner",
    "Scorer",
    "Spotter",
    "Stage Manager",
    "Talent Assistant",
    "Gaffer",
    "Grip",
    "Hair & Make-Up",
    "Orange Sleeves",
    "Tape Operator"
  ];

  exemptPositions = ["Assistant Director",
    "Director",
    "Producer",
    "Production Manager",
    "Research",
    "Security",
    "Tech Manager",
    "Associate Producer",
    "Bug Operator"];

  sortedExemptPositions: any[] = [];
  sortedNonExemptPositions: any[] = [];
  positionRequestsResult: any;

  /**
 * Close Modal
 * @public
 */

  savePositionRequest(frmPositionRequest: NgForm) {
    this.submitted = true;
    if (frmPositionRequest.valid && this.validateCustomControls() && this.isPositionStartDateInvalid === false) {
      this.disableSubmitPositionRequestButtonOnClick = true;
      this.save();      
    }
    
  }
  validateCustomControls() {
    return (this.eventDetails.onsiteProductionManager
      && this.eventDetails.reportsToManager
      && this.companyCode
      && this.postionType
      && this.flsaStatus)
  }

  onPositionStartDateChange() {
    this.isPositionStartDateInvalid = true;
    if (this.positionRequests) {
      let StartDate = new Date(this.positionRequests[0].event.crewWorkStartDate).setHours(0, 0, 0, 0);
      let EndDate = new Date(this.positionRequests[0].event.crewWorkEndDate).setHours(0, 0, 0, 0);
      let positionstartDate = this.positionStartDate.setHours(0, 0, 0, 0);


      if (positionstartDate >= StartDate && positionstartDate <= EndDate) {
        this.isPositionStartDateInvalid = false;
      }
    }
  }
  onDateChangeStarte() {

  }
  getOnboardingPositionRequests() {
    this.log.trace("get OnboardingPositionRequests");
    this.subs.sink = this._onboardingService.getOnboardingPositionRequests(this.eventId, this.positionListItem)
    .subscribe(
      {
        next: (result) => {
          this.positionRequests = result.reference;
          this.positionRequestsLength = this.positionRequests.length;
          this.loadPositionsToUi(this.positionRequests);

          this._eventBus.emit(new EmitEvent("position-request:getOnboardingPositionRequests","getCrewItemHeader",null));
        },
        error: (err) => {
          this.log.showModalError("Unable to Position Request");
        }
      });

  }
 
  loadPositionsToUi(positionRequests:any) {
    this.eventDetails = positionRequests[this.currentRequest].event;
    for (let i = 0; i < this.eventDetails.broadcastDates.length; i++) {
      this.eventDetails.broadcastDates[i].airDate = new Date(this.eventDetails.broadcastDates[i].airDate)
      //this.eventDetails.broadcastDates[i].endTime = (this.eventDetails.broadcastDates[i].endTimeStore)
      //this.eventDetails.broadcastDates[i].startTime = (this.eventDetails.broadcastDates[i].startTime)
    }
    this.positionDetails = positionRequests[this.currentRequest].position;
    this.contactDetails = positionRequests[this.currentRequest].contacts;

    this.NoOfPositions = this.contactDetails.length;
    if (this.positionDetails.customRateExeedsBy !== null) {
      this.rateAboveScale = 'true';
    }
    else {
      this.rateAboveScale = 'false';
    }
    if (this.eventDetails.tier !== null && this.eventDetails.tier > 0) {
      this.isTieredEvent = true;
      this.isTieredEventBtn = "true";
    }
    else {
      this.isTieredEvent = false;
      this.isTieredEventBtn = "false";
    }
    if (this.positionDetails.payRate !== null) {
      this.tpayRate = this.positionDetails.payRate;
    }
    if (this.isTieredEvent) {
      this.tieredPosition = this.positionDetails.rateCode?.description === "" ? this.positionDetails.rateCode?.name : this.positionDetails.rateCode?.description;
    }
    if (this.eventDetails) {
      this.positionStartDate = new Date(this.eventDetails.crewWorkStartDate);
      this.hiringManger = this.eventDetails.manPowerManager.description;
    }
    if (this.positionDetails) {
      this.isRateAboveScale = this.positionDetails.customRateExeedsBy === null ? false : true;
    }
    //Commenting exising logic for story SCOR-21141. Getting confirmation from Mike
    //var isExcempt = _.includes(this.sortedExemptPositions, this.positionDetails.description);
    //var isNonExcempt = _.includes(this.sortedNonExemptPositions, this.positionDetails.description);
    //if (isExcempt) {
    //  this.flsaStatus = { id: 2, description: "Daily (Exempt)" };
    //}
    //else if (isNonExcempt) {
    //  this.flsaStatus = { id: 1, description: "Hourly (Non-exempt)" };
    //}
    //else {
    //  this.flsaStatus = null;
    //}

    //SCOR-21141
    if (this.contactDetails) {
      this.contactDetails.forEach((contact: any) => {
        var state: any;
        state = _.find(this.states, 
          {
            name: contact.state
          });
        contact.state = _.isNil(contact.state) ? contact.state : state ? state.code : contact.state;
        if (contact.flsa.toLowerCase() === "not applicable") {
          // this.flsaStatus = { id: "null", description: "Select FLSA" };
          this.disableFlsaStatus = false;
        }
        else {
          this.flsaStatus = { id: contact.flsa, description: contact.flsa };
        };
      });
    }


    //if (_.isEmpty(this.contactDetails) || this.contactDetails[0].flsa.toLowerCase() === "not applicable") {
    //  this.flsaStatus = { id: "null", description: "Select FLSA" };
    //  this.disableFlsaStatus = false;
    //}
    //else
    //  this.flsaStatus = { id: this.contactDetails[0].flsa, description: this.contactDetails[0].flsa};


    //eventDetails.onsiteProductionManager = [];
    //eventDetails.onsiteProductionManager.id = "112";
  };

  cancel() {
    this.log.trace("canceled modal");
    this.traverseRequest("Cancel");
    if (this.currentRequest < this.positionRequestsLength) {
      this.loadPositionsToUi(this.positionRequests);
      this.isRequiredValidation = false;
    }
    if (this.currentRequest >= this.positionRequestsLength) {
      this.onPositionRequestSync.next(false);
      this._bsModalRef.hide();    
    }
    this.clearDropDowns();
  };
  modalCancel() {
    this.log.trace("canceled total modal");
    this.onPositionRequestSync.next(false);
    this._bsModalRef.hide();
  };

  save() {
    this.log.trace("save Position Request");
    // usSpinnerService.spin("modalSpinner");
    var posRequest = this.positionRequests[this.currentRequest];
    posRequest.position.companyCode = this.companyCode.description;
    posRequest.position.positionType = this.postionType.description;
    posRequest.position.hiringManager = this.hiringManger;
    //posRequest.position.positionStartDate = this.positionStartDate;
    posRequest.position.positionStartDate = moment(this.positionStartDate).startOf("day").format("YYYY-MM-DDT00:00:00[Z]");
    posRequest.position.customRateExeedsBy = this.positionDetails.customRateExeedsBy;
    if (!posRequest.position.rateCode?.id?.includes("TR_")) {
      posRequest.position.rateCode.description = null;
    }
    if (posRequest.contacts) {
      for (let i = 0; i < posRequest.contacts.length; i++) {
        posRequest.contacts[i].flsa = this.flsaStatus.description;
      }
    } 
    //      //return;
    this.subs.sink = this._onboardingService.saveOnboardingPositionRequests(this.eventId, posRequest)
      .subscribe(
      {
        next: (result) => {
          this.positionRequestsResult = result.reference;
          if (this.currentRequest >= this.positionRequestsLength - 1) {
            // $modalInstance.close(this.positionRequestsResult);
            this.onPositionRequestSync.next(true);
            this._bsModalRef.hide();
          }
          if (this.positionRequestsResult === false) {
            this.log.showModalError( "This action cannot be completed as a Position Request already exists for this contact.");
          }
          this._eventBus.emit(new EmitEvent("position-request:save", "getCrewItemHeader", null));

          if (this.currentRequest >= this.positionRequestsLength) {
            this.onPositionRequestSync.next(true);
            this._bsModalRef.hide();
          }
          this.clearDropDowns();
          this.submitted = false;
          this.disableSubmitPositionRequestButtonOnClick = false;
          this.traverseRequest("Save");
          if (this.currentRequest < this.positionRequestsLength) {
            this.loadPositionsToUi(this.positionRequests);
          }
        },
        error: (err) => {
          this.log.error("got an error: " + JSON.stringify(err));
          this.log.showModalError("Unable to Position Request.")
        }
      });
  }

  clearDropDowns() {
    this.companyCode = { id: 1, description: "Sports L0VA" };
    this.postionType = { id: 1, description: "PEP Casual" };
    if (this.positionDetails) {
      var isExcempt = _.includes((this.sortedExemptPositions.map(item => item.toUpperCase())), this.positionDetails.description?.toUpperCase());
      var isNonExcempt = _.includes((this.sortedNonExemptPositions.map(item => item.toUpperCase())), this.positionDetails.description?.toUpperCase());
      if (isExcempt) {
        this.flsaStatus = { id: "Daily (Exempt)", description: "Daily (Exempt)" };
      }
      else if (isNonExcempt) {
        this.flsaStatus = { id: "Hourly (Non-exempt)", description: "Hourly (Non-exempt)" };
      }
      else {
        this.flsaStatus = null;
      }
    }
  };

  traverseRequest(requested:any) {
    if (requested === "Load") {
      this.currentRequest = 0;
    }
    else if (requested === "Cancel") {
      this.currentRequest++;
    }
    else if (requested === "Save") {
      this.currentRequest++;
    }
  };

  getCountries() {
    if (!this.countries) {
      this.subs.sink = this._addressService.getCountries()
      .subscribe(
        {
          next: (result) => {
            this.countries = result.reference;
            this.setStates();           
          },
          error: (err) => {
            this.log.showModalError("Unable to load Countries at this time.");
          }
        });
    }
    else {
      this.setStates();
    }
  };

  setStates() {
    if (this.countries) {
      for (var i = 0; i < this.countries.length; i++) {
        if (this.countries[i].isoCode === "US") {
          this.states = this.countries[i].states;
        }
      }
    } else {
      this.states = [];
    }

  };
  getEventManagers() {
    this.log.trace("getEventManagers called");
    //profile 15 - stands for onsite PM
    this.subs.sink = this._eventManagerService.eventManager(15)
    .subscribe(
      {
        next: (result) => {
          this.productionManagers = result.reference;
        },
        error: (err) => {
          this.log.showModalError("Unable to load the event managers.");
        }
      });
  };
  getReportsToManager() {
    this.log.trace("getReportsToManager called");
    //profile 20 - stands for Reports To Manager?
    var mgrTypes = [15, 20];
    this.subs.sink = this._eventManagerService.eventManager(mgrTypes)
    .subscribe(

      {
        next: (result) => {
          this.reportsToManager = result.reference;
        },
        error: (err) => {
          this.log.showModalError("Unable to load the event managers.");
        }
      });
  };

  ngOnInit() {
    for (let i = 0; i < this.exemptPositions.length; i++) {
      this.sortedExemptPositions.push(this.exemptPositions[i]?.toUpperCase());
    }
    for (let i = 0; i < this.nonExemptPositions.length; i++) {
      this.sortedNonExemptPositions.push(this.nonExemptPositions[i]?.toUpperCase());
    }


    this.scheduleDates = this.scheduleDates;
    this.broadcastingDates = this.broadcastingDates ? _.map(this.broadcastingDates, "airDate") : [];
    this.tieredPositions = this.tieredPositions;

    this.eventId = this.eventId;
    this.localStore = _.cloneDeep(this.positionListItem);
    this.model = _.cloneDeep(this.positionListItem);
    this.getCountries();
    this.getOnboardingPositionRequests();
    this.getEventManagers();
    this.getReportsToManager();
    this.clearDropDowns();

    this.onPositionRequestSync = new Subject();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}



