import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { EventTypeService } from "../.././Shared/Services/eventType.service";
import { DashboardService } from "../.././Shared/Services/dashboard.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { NbcLoggerService } from "../.././/Shared/logger/nbc-logger.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { PermissionUtility } from "../../Core/services/permission-utility.service";

@Component({
    selector: "app-my-event-dashboard",
    templateUrl: "./my-event-dashboard.component.html",
})
export class MyEventDashboardComponent implements OnInit, OnDestroy {
    constructor(
        private _eventTypeService: EventTypeService,
        private _dashboardService: DashboardService,
        private router: Router,
        private log: NbcLoggerService,
        private renderer: Renderer2
    ) {}

    model: any | undefined;
    upcomingEvents: any | undefined;
    favoriteEvents: any | undefined;
    allupcomingEvents: any | undefined;
    allfavoriteEvents: any | undefined;
    eventTypes: any | undefined;
    oneAtATime = true;
    isFirstOpen = true;
    customClass = "customClass";
    status = false;
    fullscreenMode = false;
    isShowMyUpcoming = true;
    isShowFavourite = false;

    //*********New Code***********
    accordionInfo: any = {
        upComing: {
            isOpen: true,
        },
        favotites: {
            isOpen: false,
        },
    };

    isupComingInfoOpenChange() {
        this.accordionInfo.upComing.isOpen =
            !this.accordionInfo.upComing.isOpen;
        const box = document.getElementById("box");
        console.log(box, "t0");
        if (box != null) {
            box.classList.add("test");
        }
        this.accordionInfo.favotites.isOpen = false;
        return this.accordionInfo.upComing.isOpen;
    }

    isfavotitesInfoOpenChange() {
        this.accordionInfo.favotites.isOpen =
            !this.accordionInfo.favotites.isOpen;
        this.accordionInfo.upComing.isOpen = false;
        return this.accordionInfo.favotites.isOpen;
    }

    componentDestroyed$: Subject<boolean> = new Subject();

    myEventsComplete: boolean | undefined;

    showMyUpcomingOrFavourite(isUpcomingOrFavourite: any) {
        if (isUpcomingOrFavourite == "upcoming") {
            this.isShowMyUpcoming = true;
            this.isShowFavourite = false;
        } else {
            this.isShowMyUpcoming = false;
            this.isShowFavourite = true;
        }
    }

    getUserEvents() {
        this.log.trace("getUserEvents called");
        this._dashboardService
            .getEventListData("Upcoming")
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace("successfully retrieved dashboard data");
                    this.allupcomingEvents = data;
                    this.upcomingEvents = this.allupcomingEvents
                        ? _.take(this.allupcomingEvents, 4)
                        : null;
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the event types at this time. Please try again later."
                        );
                },
                () => {
                    this.myEventsComplete = true;
                }
            );

        this._dashboardService
            .getEventListData("Favorite")
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace("successfully retrieved dashboard data");
                    this.allfavoriteEvents = data;
                    this.favoriteEvents = this.allfavoriteEvents
                        ? _.take(this.allfavoriteEvents, 4)
                        : null;
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the event types at this time. Please try again later."
                        );
                },
                () => {
                    this.myEventsComplete = true;
                }
            );
    }

    goto(eventId: number) {
        this.log.debug("goto" + eventId);
        this.router.navigate(["Events/Event", eventId]);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    fullScreenmode() {
        const contact = document.getElementById("contactrecentlyviewed");
        const event = document.getElementById("eventrecentlyviewed");
        const createevent = document.getElementById("CreateEvent");
        const positionrequest = document.getElementById("positionrequest");
        const myevents = document.getElementById("eventdashboard");
        const bottomrow = document.getElementById("bottomrow");

        if (myevents != null) {
            if (!this.fullscreenMode) {
                //myevents.classList.add('col-lg-12');
                this.upcomingEvents = this.allupcomingEvents;
                this.favoriteEvents = this.allfavoriteEvents;
                this.fullscreenMode = true;
            } else {
                //myevents.classList.remove('col-lg-12');
                this.favoriteEvents = this.allfavoriteEvents
                    ? _.take(this.allfavoriteEvents, 4)
                    : null;
                this.upcomingEvents = this.allupcomingEvents
                    ? _.take(this.allupcomingEvents, 4)
                    : null;
                this.fullscreenMode = false;
            }
        }
        if (bottomrow != null) {
            if (this.fullscreenMode) {
                bottomrow.classList.add("dashboardhide");
            } else {
                bottomrow.classList.remove("dashboardhide");
            }
        }
        if (event != null) {
            if (this.fullscreenMode) {
                event.classList.add("dashboardhide");
            } else {
                event.classList.remove("dashboardhide");
            }
        }
        if (createevent != null) {
            if (this.fullscreenMode) {
                createevent.classList.add("dashboardhide");
            } else {
                createevent.classList.remove("dashboardhide");
            }
        }
        if (positionrequest != null) {
            if (this.fullscreenMode) {
                positionrequest.classList.add("dashboardhide");
            } else {
                positionrequest.classList.remove("dashboardhide");
            }
        }
        if (contact != null) {
            if (this.fullscreenMode) {
                contact.classList.add("dashboardhide");
            } else {
                contact.classList.remove("dashboardhide");
            }
        }
    }
    ngOnInit() {
        //this.getEventTypes();
        this.getUserEvents();
        this.myEventsComplete = true;
        this.isShowMyUpcoming = true;
        this.isShowFavourite = false;
    }

    elem = document.documentElement;
    fullscreen() {
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        }
    }

    closefullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}
