import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable } from 'rxjs';
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
  providedIn: 'root'
})
export class EmployeeTypeService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log:NbcLoggerService) { }

  //@LiquidCache('getAllEmployeeTypes')
  getAllEmployeeTypes(): Observable<any> {
    this.log.trace("fetching employeeTypes");
    return this.http.get(this._apiroot.baseUrl + "employeeTypes")
  };

  //@LiquidCache('getHrEmployeeTypes')
  getHrEmployeeTypes(): Observable<any> {
    this.log.trace("fetching employeeTypes");
    return this.http.get(this._apiroot.baseUrl + "hremployeeTypes")
  };

}

