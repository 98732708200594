import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable, Observer, noop, of, throwError } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log:NbcLoggerService) { }
 

  autocomplete(searchterm:any): Observable<any> {
    this.log.trace("2searching for :" + searchterm);
    // using github public api to get users by name
    return this.http.get<any>(
      this._apiroot.baseUrl + "positions?term=" + encodeURIComponent(searchterm))
      .pipe(
        map((data: any) => data && data.reference || []),
        catchError((err) => {
          return []
        })
    );
  }

  searchPositions(term:any): Observable<any> {
      this.log.trace("calling search positions for: " + term);
    return this.http.get(this._apiroot.baseUrl + "searchpositions?term=" + encodeURIComponent(term))
      .pipe(
        map((data: any) => data && data.reference || []),
        catchError((err) => {
          return []
        })
      );
  };

  crewPositionAutoComplete(searchterm:any): Observable<any> {
    this.log.trace("crewPositionAutoComplete for :" + searchterm);
    // using github public api to get users by name
    return this.http.get<any>(
      this._apiroot.baseUrl + "crewPositionTypes?term=" + encodeURIComponent(searchterm))
      .pipe(
        map((data: any) => data && data.reference || [])
      );
  }

  saveWorkScheduleDay(eventId:any, positionId:any, workScheduleTasks:any): Observable<any> {
    this.log.trace("saving workschedule tasks for eventId " + eventId + "and positionId " + positionId);
    return this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/positions" + "/workschedule", workScheduleTasks)
    .pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }; 

}
