import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { Router } from "@angular/router";
import { positionDetails } from "../Model/positionDetails.model";
import * as _ from "lodash";
import { OnboardingColorsService } from "../../../Shared/Services/Onboarding/onboarding-colors.service";
import { Subject } from "rxjs/internal/Subject";
import { NbcModalConfirmation } from "../../../Shared/components/confirmation/nbc-modal-confirmation.component";
import { ContactService } from "../../../Shared/Services/contact.service";
import {
    EmitEvent,
    EventBusService,
} from "../../../Core/services/event-bus.service";
import * as moment from "moment";
import { SubSink } from "subsink";

@Component({
    selector: "app-model-i9verify",
    templateUrl: "./model-i9verify.component.html",
})
export class ModelI9verifyComponent implements OnInit {
    //bsModalRef?: BsModalRef;
    constructor(
        public bsModalRef: BsModalRef,
        private bsSubModalRef: BsModalRef,
        private _modalService: BsModalService,
        private _contactService: ContactService,
        private _eventBus: EventBusService,
        private _onboardingColorsService: OnboardingColorsService,
        private _router: Router,
        private log: NbcLoggerService
    ) {}

    private subs = new SubSink();
    defaultOrderBy = "";
    defaultReverseSort = false;
    public onRejection!: Subject<any>;

    //sort = {
    //  orderByField: this.defaultOrderBy,
    //  reverseSort: this.defaultReverseSort
    //};

    orderHeader = "";
    isAscOrder = true;

    //global variables for windows
    childwindows:any = [];
    newWindow = null;
    positionrequest: any[] = [];
    searchTerm = "";
    colors: any | undefined;

    ngOnInit(): void {
        this.positionDetails = _.cloneDeep(this.positionrequest);
        this.colors = this._onboardingColorsService.getColors();
        this.positionDetails.forEach((item: any) => {
            item.requestStatusColor = this.colors.requestStatus.get(
                item.onboardingStatusNo.toString()
            );
        });
        this.onRejection = new Subject();
    }

    positionDetails: any[] = [];

    sort(headerName: string) {
        // this.isAscOrder = !this.isAscOrder;
        this.orderHeader = headerName;
    }

    closeModal() {
        this.onRejection.next(false);
        this.bsModalRef.hide();
    }

    /*
     * Generic function to show the modal. All parameters are customizable per need
     */
    showValidationErrorModal(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any,
        hideConfirm?: any,
        showBackdrop?: any
    ) {
        this.log.trace("showValidationErrorModal");
        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText || null,
            hideConfirm: hideConfirm == null ? false : hideConfirm,
        };
        const modalOptions = {
            animated: true,
            backdrop: showBackdrop == null ? true : showBackdrop,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsSubModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsSubModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("confirmation");
            callback.apply(this, args);
        });
        this.bsSubModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
            cancelCallback.apply(this, cancelargs);
        });
    }

    /**
     * i9 Docs Verified
     * @private
     * @memberOf score.controllers.EventCrewingController
     * @fires updateGridRow
     * @param {Object[]} positionListItem crew position list Item
     */
    i9DocsVerified(positionListItem: any) {
        this.log.trace("confirm position");

        const title = "Contact I-9 Documents",
            msg = !positionListItem.i9DocsVerified.fieldBool
                ? "Are you sure contact's documents match their uploaded I-9 Documents?"
                : "Contact's I-9 Documents not verified?";
        this.toggleCustomFieldValue(positionListItem);
        this.showValidationErrorModal(
            title,
            msg,
            true,
            this.CustomFieldUpdate.bind(this),
            [
                positionListItem,
                positionListItem.i9DocsVerified,
                positionListItem.contactId,
            ],
            "Confirm",
            "Cancel",
            this.toggleCustomFieldValue,
            [positionListItem]
        );
    }

    CustomFieldUpdate(positionListItem: any, customfield: any, contactid: any) {
        this.subs.sink = this._contactService
            .updateCustomField(contactid, customfield)
            .subscribe({
                next: (confirmResult: any) => {
                    const index = this.positionDetails.findIndex(
                        (contact) => contact.contactId === contactid
                    );
                    if (index !== -1) this.positionDetails.splice(index, 1);
                },
                error: (err) => {
                    this.log.error("got error: " + JSON.stringify(err));
                },
            });
    }

    //opening popup window
    openDrupalOnboardingModal(positionListItem: any) {
        if (positionListItem.eventName && positionListItem.requestNo !== null) {
            //console.log(positionListItem);
            const positionRequestNo = positionListItem.requestNo; //hrOnboardingItem.requestNumber;
            const crewRegNo = positionListItem.crewRegistrationNo; //hrOnboardingItem.crewRegistrationNo;
            const drupalUrl = window.preload.uisettings.drupalHMModal;
            // const onboardingStatus = positionListItem.positionRequest;

            // Checking position request and onboardingstatus

            const url =
                drupalUrl +
                "%26field_position_request_number%3D" +
                positionRequestNo +
                "%26field_status_crew_reg_number%3D" +
                crewRegNo;
            const settings =
                "top=125,left=280,width=800,height=775,location=0,titlebar=0";

            const newWindow = window.open(url, crewRegNo, settings);
            console.log(this.childwindows);
            for (let i = 0; i < this.childwindows.length; i++) {
                if (
                    this.childwindows[i] &&
                    this.childwindows[i] == newWindow &&
                    this.childwindows[i].opened
                ) {
                    this.childwindows.splice(i, 1);
                    this.childwindows[i].close();
                }
            }
            this.childwindows[this.childwindows.length] = newWindow;
            if (newWindow) {
                newWindow.focus();
            }

            return false;
        } else {
            this.log.showError("The Position is not Requested.");
            return false;
        }
    }

    toggleCustomFieldValue(position: any) {
        position.i9DocsVerified.fieldValue =
            position.i9DocsVerified.fieldValue === "Y" ? "N" : "Y";
        position.i9DocsVerified.fieldBool = !position.i9DocsVerified.fieldBool;
        if (position.i9DocsVerified.updatedBy != "") {
            position.i9DocsVerified.modifiedDate = moment
                .utc(position.i9DocsVerified.updatedDate)
                .format("MM/DD/YYYY");
        }
        position.i9DocsVerified.color = position.i9DocsVerified.fieldBool
            ? "Green"
            : "Red";
    }
}
