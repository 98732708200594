import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { ReportService } from "src/app/Shared/Services/report.service";
import { ContactInformationReportComponent } from "../contact-information-report/contact-information-report.component";
import { CrewRatesReportComponent } from "../crew-rates-report/crew-rates-report.component";
import { FlagPayRateReportComponent } from "../flag-pay-rate-report/flag-pay-rate-report.component";
import { OnboardingReportComponent } from "../onboarding-report/onboarding-report.component";
import { PayRateReportComponent } from "../pay-rate-report/pay-rate-report.component";
import { RemoteProductionMemoReportComponent } from "../remote-production-memo-report/remote-production-memo-report.component";
import { RemoteStaffingSummaryReportComponent } from "../remote-staffing-summary-report/remote-staffing-summary-report.component";
import { SapDiscrepancyReportComponent } from "../sap-discrepancy-report/sap-discrepancy-report.component";
import { EventLocationReportComponent } from "../event-location-report/event-location-report.component";
import { PayrollTimecardsReportComponent } from "../payroll-timecards-report/payroll-timecards-report.component";

@Component({
    selector: "app-accordion",
    templateUrl: "./accordion.component.html",
})
export class AccordionComponent implements OnInit {
    constructor(
        private _modalService: BsModalService,
        private _reportService: ReportService
    ) {}

    ngOnInit(): void {
        this.checkCount();
        _.remove(this.reports[0].items, {
            hasReportAccess: false,
        });
    }
    showAlertMessage: any;
    bsModalRef?: BsModalRef;
    eventId = window.preload.eventId;
    isDownload = false;
    @ViewChild(NbcFileDownloadComponent) child;
    reportsfinance: any = [
        {
            title: "Finance Reports",
            needPermission: true,
            isAccordionOpen: false,
            count: 1,
            items: [],
        },
    ];
    reports: any = [
        {
            title: "General Reports",
            needPermission: true,
            isAccordionOpen: false,
            count: 8,
            items: [
                {
                    name: "Pay-Rate Updates by Event",
                    key: "Pay-RateUpdatesEventReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Flag Pay-Rate Change After Event Timesheet Activation",
                    key: "FlagPay-RateChangeReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Production Memo",
                    key: "Production-MemoReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Remotes Staffing Summary",
                    key: "remote-StaffingSummaryReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Onboarding Report",
                    key: "OnboardingReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Contact Information Report",
                    key: "ContactInformationReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "SAP Discrepancy Report",
                    key: "SAPDiscrepancyRemotesReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "SSO Only",
                    key: "SSOOnly.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Crew Summary Report",
                    key: "remote-CrewRatesReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Flagged by HR Report",
                    key: "remote-FlaggedbyHRReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Event Location",
                    key: "remote-EventLocationReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
                {
                    name: "Payroll Timecards",
                    key: "remote-PayrollTimecardsReport.Export",
                    isExport: "true",
                    isNotCustomize: "false",
                    hasReportAccess: true,
                },
            ],
        },
    ];

    checkCount() {
        _.forEach(this.reports, (report) => {
            report.count = 0;
            _.forEach(report.items, (eachreport) => {
                if (this.hasAccess(eachreport.key) || eachreport.key === "") {
                    report.count++;
                } else {
                    eachreport.hasReportAccess = false;
                }
            });
        });
    }

    hasAccess(args) {
        let user = window.preload.user,
            tokens,
            token,
            i;
        let returnVal = false;
        if (user.isSuperUser) {
            returnVal = true;
        } else {
            if (user.allFeatures[args.toLowerCase()] !== undefined) {
                returnVal = user.allFeatures[args.toLowerCase()];
            } else {
                returnVal = false;
            }
        }
        return returnVal;
    }

    customizeReportModal(reportName) {
        switch (reportName) {
            case "Pay-Rate Updates by Event":
                {
                    const initialState: any = {
                        controller: "payRateController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };
                    this.bsModalRef = this._modalService.show(
                        PayRateReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Production Memo":
                {
                    const initialState: any = {
                        controller: "remoteProductionMemoController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        RemoteProductionMemoReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Remotes Staffing Summary":
                {
                    const initialState: any = {
                        controller: "remoteStaffingSummaryController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        RemoteStaffingSummaryReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Flag Pay-Rate Change After Event Timesheet Activation":
                {
                    const initialState: any = {
                        controller: "flagPayRateController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        FlagPayRateReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Crew Summary Report":
                {
                    const initialState: any = {
                        controller: "crewRatesController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        CrewRatesReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Contact Information Report":
                {
                    const initialState: any = {
                        controller: "contactInformationReportController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        ContactInformationReportComponent,
                        modalOptions
                    );
                }
                break;
            case "SAP Discrepancy Report":
                {
                    const initialState: any = {
                        controller: "sapDiscrepancyReportController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        SapDiscrepancyReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Onboarding Report":
                {
                    const initialState: any = {
                        controller: "onboardingReportController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        OnboardingReportComponent,
                        modalOptions
                    );
                }
                break;
            case "SSO Only":
                {
                    this.isDownload = true;
                    this._reportService.exportSSOOnlyReport().subscribe({
                        next: (data) => {
                            if (data.status === 204) {
                                this.showAlertMessage = true;
                                // $scope.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                                //log.error("get got an error: ");
                            } else {
                                const fileName = "SCORE-SSO_";
                                this.showAlertMessage = false;

                                this.openInText(data, fileName);
                            }
                        },
                        error: (data) => {
                            //
                            this.showAlertMessage = true;
                            // $scope.alerts = [{ type: "danger", msg: "Error Occurred" }];
                            //log.error("get got an error: ");
                        },
                    });
                }
                break;
            case "Flagged by HR Report":
                {
                    this.isDownload = true;
                    this._reportService.exportFlaggedbyHRReport().subscribe({
                        next: (data) => {
                            if (data.status === 204) {
                                this.showAlertMessage = true;
                            } else {
                                const fileName = "FlaggedbyHRReport_";
                                this.showAlertMessage = false;

                                this.openInExcel(data, fileName);
                            }
                        },
                        error: (data) => {
                            this.showAlertMessage = true;
                        },
                    });
                }
                break;
            case "Event Location":
                {
                    const initialState: any = {
                        controller: "eventLocationReportController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        EventLocationReportComponent,
                        modalOptions
                    );
                }
                break;
            case "Payroll Timecards":
                {
                    const initialState: any = {
                        controller: "payrollTimecardsReportController",
                        hideConfirm: false,
                        reportName: reportName,
                    };
                    const modalOptions = {
                        animated: true,
                        initialState: initialState,
                        keyboard: false
                    };

                    this.bsModalRef = this._modalService.show(
                        PayrollTimecardsReportComponent,
                        modalOptions
                    );
                }
                break;
        }
    }

    openInText(data, fileName, appendDate?, dateFormat?) {
        // var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const d = moment(new Date()).format("MM-DD-yyyy");
        this.showAlertMessage = false;
        // this.openInExcel(data, fileName);

        this.child.fileName = fileName + d;
        this.child.fileType = "text";
        this.child.data = data;
        this.child.download();
    }

    openInExcel(data, fileName?, appendDate?, dateFormat?) {
        const d = moment(new Date()).format("MM-DD-yyyy");
        this.child.fileName = fileName + d;
        this.child.fileType = "excel";
        this.child.data = data;
        this.child.download();
    }

    // Page Name
}
