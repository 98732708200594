import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-onboarding-report",
    templateUrl: "./onboarding-report.component.html",
})
export class OnboardingReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}
    reportName;
    //  = modelValue;
    onboardingHiringManagers: any;
    onboardingProductionManagers: any;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    isExport = true;

    showRequestStatusRowAll = true;
    showHgrMgrRowAll = true;
    showProdMgrRowAll = true;
    showRequestStatusEditRow = false;
    showHgrMgrEditRow = false;
    showProdMgrEditRow = false;
    //$scope.showrequestStatusSelectedRow = false;
    showRequestStatusTagRow = false;
    showHgrMgrTagRow = false;
    showProdMgrTagRow = false;
    user = window.preload.user;
    requestatusselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    hgrmgrselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    prodmgrselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    requestStatusList: any[] = [];
    hgrMgrList: any[] = [];
    prodMgrList: any[] = [];
    onboardingReport = [
        { name: "Position Requests Submitted", id: 1 },
        { name: "Registrations Submitted", id: 2 },
        { name: "Registrations Complete", id: 3 },
        { name: "Registrations Rejected", id: 4 },
        { name: "Registrations Approved", id: 5 },
        { name: "Onboardings In-Progress", id: 6 },
        { name: "Requests Complete", id: 7 },
        { name: "Cancelled", id: 8 },
    ];
    // $scope.rateCard = [];
    selectedRequestStatus: any[] = [];
    onboardingReportInfo: any;
    selectedHgrMgr: any[] = [];
    selectedProdMgr: any[] = [];
    alerts;
    eventId = 13;
    showAlertMessage: any;
    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    onToggleFilterDatepicker($event) {
        this.onboardingReportInfo.dateRangeStartDate = $event[0];
        this.onboardingReportInfo.dateRangeEndDate = $event[1];
    }
    onItemSelectReport($event) {
        this.selectedRequestStatus = $event;
    }
    onItemSelectHiringManagers($event) {
        this.selectedHgrMgr = $event;
    }

    onItemSelectProductionManagers($event) {
        this.selectedProdMgr = $event;
    }

    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }

    //openTravelInDatepicker
    openDateRangeDatepicker(event) {
        // log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
        //document.getElementById("PayrateButton").disabled = false;
    }

    editRow(value) {
        switch (value) {
            case "RequestStatusRow":
                {
                    this.showRequestStatusRowAll = false;
                    this.showRequestStatusEditRow = true;
                    this.showRequestStatusTagRow = false;
                }
                break;
            case "HgrMgrRow":
                {
                    this.showHgrMgrRowAll = false;
                    this.showHgrMgrEditRow = true;
                    this.showHgrMgrTagRow = false;
                }
                break;
            case "ProdMgrRow":
                {
                    this.showProdMgrRowAll = false;
                    this.showProdMgrEditRow = true;
                    this.showProdMgrTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }

    confirmSelection(value) {
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
            // document.getElementById("orBtnReportExport").disabled = false;
        }
        if (value === "TravelOutDateRow") {
            // this.showTravelOutDateEditRow = false;
            this.showSection(value);
            // document.getElementById("orBtnReportExport").disabled = false;
        }

        if (value === "RequestStatusRow") {
            this.showRequestStatusEditRow = false;
            this.showSection(value);
        }

        if (value === "ProdMgrRow") {
            this.showProdMgrEditRow = false;
            this.showSection(value);
        }

        if (value === "HgrMgrRow") {
            this.showHgrMgrEditRow = false;
            this.showSection(value);
        }
        if (this.onboardingReportInfo?.dateRangeStartDate) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }
    }
    showSection(value) {
        switch (value) {
            case "DateRangeRow":
                {
                    if (
                        this.onboardingReportInfo.dateRangeStartDate !== null &&
                        this.onboardingReportInfo.dateRangeEndDate !== null &&
                        this.onboardingReportInfo.dateRangeStartDate !==
                            undefined &&
                        this.onboardingReportInfo.dateRangeEndDate !== undefined
                    ) {
                        this.showDateRangeRowAll = false;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = true;
                        // document.getElementById("orBtnReportExport").disabled = false;
                    } else {
                        this.showDateRangeRowAll = true;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = false;
                        // document.getElementById("orBtnReportExport").disabled = true;
                    }
                    break;
                }
                break;

            case "RequestStatusRow":
                {
                    if (
                        !_.isUndefined(this.selectedRequestStatus) &&
                        this.selectedRequestStatus.length > 0
                    ) {
                        this.showRequestStatusRowAll = false;
                        this.showRequestStatusEditRow = false;
                        this.showRequestStatusTagRow = true;
                    } else {
                        this.showRequestStatusRowAll = true;
                        this.showRequestStatusTagRow = false;
                        this.showRequestStatusEditRow = false;
                    }
                    break;
                }
                break;

            case "ProdMgrRow":
                {
                    if (
                        !_.isUndefined(this.selectedProdMgr) &&
                        this.selectedProdMgr.length > 0
                    ) {
                        this.showProdMgrRowAll = false;
                        this.showProdMgrEditRow = false;
                        this.showProdMgrTagRow = true;
                    } else {
                        this.showProdMgrRowAll = true;
                        this.showProdMgrTagRow = false;
                        this.showProdMgrEditRow = false;
                    }
                    break;
                }
                break;
            case "HgrMgrRow":
                {
                    if (
                        !_.isUndefined(this.selectedHgrMgr) &&
                        this.selectedHgrMgr.length > 0
                    ) {
                        this.showHgrMgrRowAll = false;
                        this.showHgrMgrEditRow = false;
                        this.showHgrMgrTagRow = true;
                    } else {
                        this.showHgrMgrRowAll = true;
                        this.showHgrMgrTagRow = false;
                        this.showHgrMgrEditRow = false;
                    }
                    break;
                }
                break;
        }
    }

    stateChanged(checked, value, row) {
        if (row === "RequestStatusRow") {
            if (checked) {
                this.requestStatusList.push(value);
                this.onboardingReportInfo.push(value);
                //document.getElementById("PayrateButton").disabled = false;
            }
            if (!checked) {
                this.requestStatusList.splice(
                    this.requestStatusList.indexOf(value),
                    1
                );
                this.onboardingReportInfo.splice(
                    this.onboardingReportInfo.indexOf(value),
                    1
                );
                //if ($scope.requestStatusList.length === 0) {
                //    document.getElementById("PayrateButton").disabled = true;
                //}
            }
        }

        if (row === "ProdMgrRow") {
            if (checked) {
                this.prodMgrList.push(value);
                this.onboardingReportInfo.push(value);
            }
            if (!checked) {
                this.prodMgrList.splice(this.prodMgrList.indexOf(value), 1);
                this.onboardingReportInfo.splice(
                    this.onboardingReportInfo.indexOf(value),
                    1
                );
            }
        }

        if (row === "HgrMgrRow") {
            if (checked) {
                this.hgrMgrList.push(value);
                this.onboardingReportInfo.push(value);
            }
            if (!checked) {
                this.hgrMgrList.splice(this.hgrMgrList.indexOf(value), 1);
                this.onboardingReportInfo.splice(
                    this.onboardingReportInfo.indexOf(value),
                    1
                );
            }
        }
    }

    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.onboardingReportInfo.dateRangeStartDate = null;
            this.onboardingReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (
                this.onboardingReportInfo.dateRangeStartDate === null &&
                this.onboardingReportInfo.dateRangeEndDate === null
            ) {
                // document.getElementById("orBtnReportExport").disabled = true;
            }
        }
        if (this.onboardingReportInfo?.dateRangeStartDate) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }
    }

    removeTag(item, row, index) {
        if (row === "RequestStatusRow") {
            this.requestStatusList.splice(
                this.requestStatusList.indexOf(item),
                1
            );
            //$scope.onboardingReportInfo.requestStatus.splice($scope.onboardingReportInfo.requestStatus.indexOf(item), 1);
            this.selectedRequestStatus.splice(
                this.selectedRequestStatus.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("RequestStatusRow");
        }

        if (row === "ProdMgrRow") {
            this.prodMgrList.splice(this.prodMgrList.indexOf(item), 1);
            this.selectedProdMgr.splice(this.selectedProdMgr.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("ProdMgrRow");
        }

        if (row === "HgrMgrRow") {
            this.hgrMgrList.splice(this.hgrMgrList.indexOf(item), 1);
            this.selectedHgrMgr.splice(this.selectedHgrMgr.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("HgrMgrRow");
        }
    }

    getOnboardingProductionManagers() {
        this._reportService
            .getOnboardingProductionManagers(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.onboardingProductionManagers = data;
                    }
                },
                error: (data) => {
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    getOnboardingHiringManagers() {
        this._reportService
            .getOnboardingHiringManagers(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.onboardingHiringManagers = data;
                    }
                },
                error: (data) => {
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }
    exportRequestStatusReport() {
        const temponboardingReportInfo: any = {};
        temponboardingReportInfo.dateRangeStartDate = moment(
            this.onboardingReportInfo.dateRangeStartDate
        ).format("MM/DD/yyyy");
        temponboardingReportInfo.dateRangeEndDate = moment(
            this.onboardingReportInfo.dateRangeEndDate
        ).format("MM/DD/yyyy");

        temponboardingReportInfo.requestStatus = this.selectedRequestStatus;
        temponboardingReportInfo.productionManager = this.selectedProdMgr;
        temponboardingReportInfo.hiringManager = this.selectedHgrMgr;
        this.isDownload = true;
        this._reportService
            .exportRequestStatusReport(temponboardingReportInfo, this.userName)
            .subscribe({
                next: (data) => {
                    if (!data) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        const fileName = "SCORE-Onboarding Report_";
                        const d = moment(new Date()).format("MM-DD-yyyy");

                        this.showAlertMessage = false;

                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    ngOnInit(): void {
        this.onboardingReportInfo = {};
        this.onboardingReportInfo.dateRangeStartDate = null;

        this.onboardingReportInfo.dateRangeEndDate = null;
        this.onboardingReportInfo = [];
        this.getOnboardingHiringManagers();
        this.getOnboardingProductionManagers();
    }
}
