import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { cloneDeep } from "lodash";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcBudgetCode } from "src/app/Shared/components/nbc-budgetcode/nbc-budget.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { BudgetService } from "src/app/Shared/Services/budget.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-create-season",
    templateUrl: "./create-season.component.html",
})
export class CreateSeasonComponent implements OnInit {
    constructor(
        public _bsModalService: BsModalService,
        public budgetService: BudgetService,
        public _modalService: BsModalService,
        private seasonServices: SeasonService,
        private datePipe: DatePipe,
        private log: NbcLoggerService
    ) {}

    public onSeasonSync!: Subject<any>;
    bsModalRef?: BsModalRef;

    eventTypeId: any;
    disableSave = true;
    difference: any;
    showResultsContents: any;
    chargeCodeType: any;
    chargeCodeTypes: any;
    model: any;
    season: any;
    submitted: any;
    companyCode: any;
    private subs = new SubSink();

    cancel() {
        this._modalService.hide();
    }

    onToggleFilterDatepicker($event: any, createSeasonForm) {
      if (_.isUndefined($event)) {
        this.disableSave = true;
      } else {
        this.model.startDate = this.datePipe.transform($event[0], "yyyy-MM-dd");
        this.model.endDate = this.datePipe.transform($event[1], "yyyy-MM-dd");
        this.disableSavefn(createSeasonForm);
      }
    }
    openDateRangeDatepicker(event: any) {
        this.log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
    }

    createSeason(createSeasonForm: any) {
        this.submitted = true;
        this.validateDateRange();
        if (this.difference > 39) {
            return;
        }
        if (createSeasonForm.valid && this.model.budgetCode != undefined) {
            this.subs.sink = this.seasonServices
                .addCreateSeasonData(this.model)
                .subscribe(
                    (result: any) => {
                        this.log.trace("successfully saved season data");
                        this.cancel();
                        setTimeout(() => {
                            this.onSeasonSync.next(result.reference?.id);
                        }, 2000);
                    },
                    () => {
                        this.log.trace("Failed saving addCreateSeasonData");
                        this.enableSavefn();
                    }
                );
        }
    }

    validateDateRange() {
        const startCrewDate = this.model.startDate;
        const endCrewDate = this.model.endDate;

        this.difference = moment(endCrewDate).diff(startCrewDate, "days");
    }

    enableSavefn() {
        this.disableSave = false;
    }

    disableSavefn(createSeasonForm) {
      if (createSeasonForm.valid) {
          this.disableSave = false;
        } else {
          this.disableSave = true;
        }
    }

    changeHandler(createSeasonForm) {
      this.disableSavefn(createSeasonForm);
    }

    showBudgetContent(createSeasonForm) {
        this.showResultsContents = false;
        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            id:2,
            animated: true,
            backdrop: true,
            keyboard: false,
            class: "child-modal2",
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.bsModalRef = this._modalService.show(NbcBudgetCode, modalOptions);
        setTimeout(() => {
          document.querySelectorAll(
            ".child-modal2"
          )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
        }, 200);
        this.bsModalRef.content.onSync.subscribe((result: any) => {
          this.model.budgetCode = result;
          setTimeout(() => {
            this.disableSavefn(createSeasonForm);
          }, 50);
        });
    }

    ngOnInit(): void {
        this.onSeasonSync = new Subject();
        this.model = cloneDeep(this.season);

        if (!this.model.seasonType) {
            this.model.seasonType = this.eventTypeId;
        }

        if (this.model.id) {
            this.model.dateRange = [
                moment(this.model.startDate).toDate(),
                moment(this.model.endDate).toDate(),
            ];
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
