import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
//import { BrowserModule } from '@angular/platform-browser';
//import { RouterModule } from '@angular/router';
import { FormsModule } from "@angular/forms";
import { PayrollUpcomingEventsComponent } from "./upcomingEvents/upcomingEvents.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PayrollComponent } from "./payroll.component";
import { SubmittedTimesheetsComponent } from "./submittedTimesheets/submittedTimesheets.component";
import { ContactService } from "../Shared/Services/contact.service";
import { PayrollService } from "../Shared/Services/payroll.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NbcSharedModule } from "../Shared/shared.module";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ModalModule } from "ngx-bootstrap/modal";
import { ModalSubmittedTimesheetExport } from "./submittedTimesheets/components/export/modal-submitted-timesheets-exports.component";
import { UpcomingEventSetup } from "./upcomingEvents/setup/upcoming-event-setup";
import { NgPipesModule } from "ngx-pipes";
import { PayrollTimesheet } from "./timesheet/payroll-timesheet.component";
import { NgChartsModule } from "ng2-charts";
import { PayrollRouterModule } from "./payroll-routing.module";
import { PayTransactionComponent } from "./timesheet/components/paytransations/model-pay-transaction.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { GlAccountComponent } from "./timesheet/components/glaccount/glaccount.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PayrollLayout } from "./payroll-layout.component";
import { NetworkService } from "../Shared/Services/network.service";
import { EventTypeService } from "../Shared/Services/event-type.service";
import { PayrollIndicatorsComponent } from "./timesheet/components/indicators/indicators.component";
import { PayrollFullScreenComponent } from "./timesheet/components/payroll-fullscreen/payroll-fullscreen.component";

@NgModule({
    imports: [
        //BrowserModule,
        CommonModule,
        //RouterModule,
        FormsModule,
        TabsModule,
        NbcSharedModule,
        NgPipesModule,
        TypeaheadModule,
        BsDatepickerModule,
        ModalModule,
        NgChartsModule,
        PayrollRouterModule.forChild(),
        PopoverModule,
        TooltipModule,
    ],
    exports: [],
    declarations: [
        PayrollComponent,
        SubmittedTimesheetsComponent,
        PayrollUpcomingEventsComponent,
        ModalSubmittedTimesheetExport,
        PayTransactionComponent,
        GlAccountComponent,
        PayrollIndicatorsComponent,
        UpcomingEventSetup,
        PayrollTimesheet,
        PayrollLayout,
        PayrollFullScreenComponent,
    ],
    providers: [
        PayrollService,
        ContactService,
        NetworkService,
        EventTypeService,
    ],
})
export class PayrollModule {}
