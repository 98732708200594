import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PositionService } from "src/app/Shared/Services/position.service";
import { tierPositionRateCard } from "src/app/Shared/Services/tierPositionRateCard.service";

@Component({
    selector: "app-modal-upsert-tier-rate-card",
    templateUrl: "./modal-upsert-tier-rate-card.component.html",
})
export class ModalUpsertTierRateCardComponent implements OnInit {
    constructor(
        private _tierPositionRateCardService: tierPositionRateCard,
        private _log: NbcLoggerService,
        private _positionService: PositionService,
        private bsModalRef: BsModalRef
    ) {}
    onSync: Subject<any> = new Subject<string>();
    disableSave;
    rateCaredpostionTerm: any;
    loadEventPositions$: Observable<any> | undefined;
    isEnable = true;
    showAlertMessage = false;
    isAdd: any;
    modalTitle: any;
    rateCard: any;
    existingRateCards: any;
    networkGroupId: any;
    positionCategories: any = [];
    isDisabled: any;
    onPositionSelected(event) {
        this.rateCard.position = event.item;
    }
    getPositionCategories() {
        this.positionCategories = [
            { id: "BTL", name: "BTL", selectable: true, isSelected: true },
            { id: "ATL", name: "ATL", selectable: false, isSelected: false },
        ];
    }
    addUpdateRateCard() {
        if (!this.validate() || this.isDisabled) {
            return false;
        }
        this.disableSavefn();
        const requestResult = {
            statusCode: -1,
        };

        //  usSpinnerService.spin("modalSpinner");

        //creating tier position
        const obj = {
            billing_code: this.isAdd ? "" : this.rateCard.billing_code,
            desc: this.rateCard.desc,
            positionCategory: this.rateCard.positionCategory,
            id: this.isAdd ? -1 : this.rateCard.id,
            isAccordionOpen: false,
            position: this.rateCard.position,
        };

        this._tierPositionRateCardService
            .upsertTierPosition(this.networkGroupId, obj)
            .subscribe({
                next: (result) => {
                    requestResult.statusCode = 1;
                    this.enableSavefn();
                    this.onSync.next(requestResult);
                    this.bsModalRef.hide();
                },
                error: (data) => {
                    this._log.error("got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this._log.showError(
                        "Unable to save tier position. Please try again later."
                    );
                },
            }),
            () => {
                this._log.trace("removing spinner");
            };
        return;
    }
    typeaheadOnSelect($event) {}
    disableSavefn() {
        this.disableSave = true;
        this.isEnable = false;
        // $("#btnAddRateCard").removeClass("btn btn-primary").addClass("btn btn-default");
    }
    enableSavefn() {
        this.isEnable = true;
        // $("#btnAddRateCard").removeClass("btn btn-default").addClass("btn btn-primary");
        this.isDisabled = false;
    }
    validate() {
        let msg;
        if (_.isEmpty(this.rateCard.desc)) {
            msg = "Please provide tier rate card name";
        }
        // SCOR-19574 removed below
        //if (_.isEmpty($scope.rateCard.position)) {
        //    msg = "Please provide tier rate card Job title";
        //}
        if (!_.isEmpty(this.existingRateCards)) {
            _.find(this.existingRateCards, (existingCard) => {
                //text matches but ids dont
                if (
                    !_.isEmpty(this.rateCard.desc) &&
                    existingCard.desc.toLowerCase() ===
                        this.rateCard.desc.toLowerCase() &&
                    existingCard.id !== this.rateCard.id
                ) {
                    msg = "Duplicate RateCard Name is Not Allowed";
                }
            });
        }

        if (msg) {
            this.showAlertMessage = true;
            this._log.showModalError(msg);
            return false;
        }

        return true;
    }
    /**
     * Load Event Positions
     * @param {Object} query query
     * @public
     * @memberof score.controllers.modalCrewPositionController
     * @returns {Promise}
     */
    loadEventPositions(query?) {
        this._log.trace("loadEventPositions");

        //return positionService.crewPositionAutoComplete(query).then(function (res) {

        // this._positionService.searchPositions(query).subscribe( (res)=> {
        //     this._log.trace("response: " + JSON.stringify(res));
        //     return res.data.reference.slice(0, 100);
        // });

        this.loadEventPositions$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.rateCaredpostionTerm);
            }
        ).pipe(
            switchMap((query: string) => {
                return this._positionService.searchPositions(query);
            })
        );
    }
    cancel() {
        this.bsModalRef.hide();
    }

    closeAlert() {
        this._log.trace("close alert");
        // .alerts = [];
        this.showAlertMessage = false;
        this.bsModalRef.hide();
    }
    check(rateCard) {}
    ngOnInit(): void {
        this.isAdd = this.rateCard ? false : true;
        this.modalTitle = this.rateCard
            ? "Edit Tier Rate Card"
            : "Add New Tier Rate Card";
        if (!this.rateCard) {
            this.rateCard = {
                desc: "",
                positionCategory: "BTL",
            };
        } else {
            this.rateCaredpostionTerm = this.rateCard.position.description;
        }
        this.getPositionCategories();
        this.loadEventPositions();
    }
}
