import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { SubSink } from "subsink";
import { ModalEventTypeComponent } from "./modal-event-type.component";

@Component({
    selector: "app-event-type",
    templateUrl: "./event-type.component.html",
})
export class EventTypeComponent implements OnInit, OnDestroy {
    constructor(
        private _EventTypeService: EventTypeService,
        private log: NbcLoggerService,
        public _modalService: BsModalService
    ) {}

    private subs = new SubSink();
    searchTypeAhead: any;
    bsModalRef?: BsModalRef;
    queryContainer: any = { query: {} };
    searchQuery = "";
    resultsFound = false;
    eventTypes: any = { id: 0, name: "", hasTeam: false, hasFlex: false };
    ShowModal = false;
    EventSource: string[] = [];
    showAlertMessage = false;
    isSaveDisabled = false;
    isAdd = false;
    isDisabled = false;
    validImgFileTypes = [
        ".bmp",
        ".gif",
        ".jpg",
        ".jpeg",
        ".ico",
        ".png",
        ".tif",
    ];
    maxFileSizeToUploadInKB = 50;
    eventType: any;
    modalTitle = "";
    files: any;
    pageSizeList = [10, 25, 50, 100];

    reset() {
        this.searchQuery = "";
        this.getEventTypes();
    }

    sortByColumn(column) {
        this.log.trace("Sort By Column");
        if (this.queryContainer.query.sortByColumn === column) {
            this.queryContainer.query.reverseSort =
                !this.queryContainer.query.reverseSort;
            this.queryContainer.query.sortByColumn = "-" + column;
        } else {
            this.queryContainer.query.sortByColumn = column;
            this.queryContainer.query.reverseSort = false;
        }
        this.search();
    }

    typeaheadOnSelect(term: any): void {
        this.subs.sink = this._EventTypeService
            .searchByEventTypes(encodeURIComponent(term.target.value.trim()))
            .subscribe((result) => {
                var result = result.reference.map(
                    (result) => result.description
                );
                this.EventSource = [];
                const data = Array.prototype.push.apply(
                    this.EventSource,
                    result
                );
            });
    }

    searchPage(pagesize) {
        this.queryContainer.query.pageSize = pagesize;
        this.search();
    }

    search() {
        this.getEventTypes();
    }

    getEventTypes() {
        this.subs.sink = this._EventTypeService
            .getAllEventTypes(this.queryContainer.query)
            .subscribe(
                (result) => {
                    const begin =
                        (this.queryContainer.query.currentPage - 1) *
                        this.queryContainer.query.pageSize;
                    const end = begin + this.queryContainer.query.pageSize;
                    this.queryContainer.query.totalRecords =
                        result.reference.length;
                    this.eventTypes = result.reference.slice(begin, end);
                    this.resultsFound = true;
                },
                (err) => {
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                }
            );
    }

    sort(val) {
        if (_.isEmpty(this.searchQuery)) return;

        this.subs.sink = this._EventTypeService
            .getAllEventTypes(this.queryContainer.query)
            .subscribe(
                (result) => {
                    this.queryContainer.query.totalRecords =
                        result.reference.length;
                    this.eventTypes = result.reference;
                    const begin =
                        (this.queryContainer.query.currentPage - 1) *
                        this.queryContainer.query.pageSize;
                    const end = begin + this.queryContainer.query.pageSize;
                    const searchText = this.searchQuery.toLowerCase();

                    if (!_.isEmpty(searchText)) {
                        const filtered: any = [];
                        _.forEach(this.eventTypes, function (item) {
                            if (item.name.toLowerCase() === searchText) {
                                filtered.push(item);
                            }
                        });

                        this.eventTypes = filtered.splice(begin, end);
                    } else {
                        this.eventTypes = result.reference.slice(begin, end);
                    }
                    if (val !== "entriesPerPage") {
                        this.queryContainer.query.totalRecords =
                            this.eventTypes.length;
                    }
                },
                (err) => {
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                }
            );
    }

    searchTypeHead(val: string) {
        this.sort(val);
    }

    openUpsertModal(eventType = null) {
        this.eventType = !eventType
            ? { id: 0, name: "", hasTeam: false, hasFlex: false }
            : eventType;

        const initialState: any = {
            self: this,
            eventType: _.cloneDeep(this.eventType),
            eventTypes: this.eventTypes,
            isAdd: this.eventType.id ? false : true,
            modalTitle:
                this.eventType.id > 0
                    ? "Edit Event Type"
                    : "Add New Event Type",
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalEventTypeComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.log.showSuccess(result);
            this.getEventTypes();
        });
    }

    getEventTypeQuery(currentPage, pageSize, totalRecords) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 100,
            sortByColumn: "name",
            reverseSort: false,
            eventType: "",
            exactTerm: false,
        };
    }

    cancel() {
        this.ShowModal = false;
    }

    ngOnInit(): void {
        this.searchTypeAhead = _.noop;
        this.queryContainer.query = this.getEventTypeQuery(null, null, null);
        this.queryContainer.query.sortByColumn = "name";
        this.queryContainer.query.reverseSort = false;
        this.getEventTypes();
        this.eventType = _.cloneDeep(this.eventType);
        this.eventTypes = _.cloneDeep(this.eventTypes);
        this.searchQuery = "";
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
