<div class="add-contact-modal">
  <div class="modal-header ng-scope">
    <h4 class="modal-title ng-binding">{{modalTitle}}</h4>
    <button type="button" class="btn-close" (click)="closeModal()"> </button>
  </div>
  <nbc-modal-alert *ngIf="isNext"></nbc-modal-alert>
  <div *ngIf="!contactMatchModalActive">
    <form role="form" #contactSearchForm="ngForm" novalidate>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div nbc-alerts alerts-key="modalAlert"></div>
            <div class="form-inline">
              <div class="form-group">
                <p class="form-control-static">This contact is: Vendor/Non-Vendor?</p>
              </div>
              <div class="form-group">
                <label class="checkbox-inline">
                  <input type="radio"
                         name="isNonVendor"
                         class="inline"
                         [(ngModel)]="dummy.isNonVendor"
                         (click)="checkVendorValidation(contactSearchForm)"
                         [value]="true"
                         tooltip="{{nonVendorText}}"
                         container="body"
                         containerClass="tooltip"
                         placement="top"
                         required>
                  Non-Vendor
                </label>

                <label class="checkbox-inline">
                  <input type="radio"
                         name="isNonVendor"
                         class="inline"
                         [(ngModel)]="dummy.isNonVendor"
                         (click)="checkVendorValidation(contactSearchForm)"
                         [value]="false"
                         tooltip="{{vendorText}}"
                         container="body"
                         containerClass="tooltip"
                         placement="top"
                         required> Vendor
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p>&nbsp;</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': (!dummy.firstName || dummy.firstName.length === 0) && submitted}">
              <label>First Name</label>
              <input type="text" name="firstName"
                     [(ngModel)]="dummy.firstName"
                     class="form-control"
                     maxlength="20"
                     id="firstName"
                     (click)="checkVendorValidation()"
                     required
                     appInputRestriction="restrictChars"
                     placeholder="First Name"
                     [authenticate-required]="true"
                     authenticate-user="contact.create,Name.Create"
                     authenticate-command="disabled" />
              <span class="text-danger" *ngIf="(!dummy.firstName || dummy.firstName.length === 0) && submitted">Enter First Name.</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': (dummy.lastName.invalid || dummy.lastName.length === 0 ) && submitted}">
              <label>Last Name</label>
              <input type="text"
                     [(ngModel)]="dummy.lastName"
                     name="lastName"
                     class="form-control"
                     (click)="checkVendorValidation()"
                     required
                     appInputRestriction="restrictChars"
                     maxlength="20"
                     id="lastName"
                     placeholder="Last Name"
                     [authenticate-required]="true"
                     authenticate-user="contact.create,Name.Create"
                     authenticate-command="disabled" />
              <span class="text-danger" *ngIf="(dummy.lastName.invalid || dummy.lastName.length === 0 ) && submitted">Enter Last Name.</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': dummy.email.invalid && dummy.email.pristine }">
              <label>Email</label>
              <input type="text"
                     authenticate-user="contact.create,ContactInformation.Email.Create"
                     authenticate-command="disabled"
                     class="form-control"
                     name="email"
                     [(ngModel)]="dummy.email"
                     maxlength="50"
                     id="email"
                     placeholder="Email Address"
                     required
                     [pattern]="contactEmailRegex"
                     authenticate-required="dummy.isUSCitizen === false || dummy.isNonVendor === false || (dummy.isUSCitizen === true && dummy.isNonVendor === true)" />
              <span class="text-danger" *ngIf="contactSearchForm.form.controls['email']?.errors?.required && submitted">Email is required.</span>
              <span class="control-label control-label-error" *ngIf="contactSearchForm.form.controls['email']?.errors?.pattern">Enter a valid email.</span>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': !isPhoneValid && submitted}">
              <label>Phone</label>
              <nbc-phone-number name="phone"
                                nbcRequired="dummy.isUSCitizen === false || dummy.isNonVendor === false || (dummy.isUSCitizen === true && dummy.isNonVendor === true)"
                                [(isValid)]="isPhoneValid" isSubmitted="submitted"
                                [(model)]="dummy.phone">
              </nbc-phone-number>
              <span class="control-label control-label-error" *ngIf="!isPhoneValid  && submitted">Enter a valid phone number.</span>
            </div>
          </div>
        </div>
        <br />
        <div class="static-text" *ngIf="dummy.isNonVendor === true">Enter either DOB and SSN or SSO</div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{'has-error': (dummy.dobMonth.invalid || dummy.dobDay.invalid) && submitted}">
              <label>Birth Date</label>
              <div class="form-inline">
                <select name="dobMonth"
                        class="form-select-sm bg-white"
                        [(ngModel)]="dummy.dobMonth"
                        [attr.required]="dobMonthRequired || null"
                        #fieldDobMonth="ngModel"
                        [ngClass]="{'ng-valid-not-required': !dobMonthRequired }"
                        (change)="clearDobDay(); validateDob();"
                        authenticate-user="contact.create,DOB.Create"
                        authenticate-command="disabled">
                  <option [ngValue]="''">Select</option>
                  <option *ngFor="let month of months" [ngValue]="month.id">{{month.name}}</option>
                </select>
                <select name="dobDay"
                        class="form-select-sm bg-white"
                        [(ngModel)]="dummy.dobDay"
                        #fielddobDay="ngModel"
                        (click)="checkVendorValidation()"
                        [authenticate-required]="dobDayRequired"
                        [required]="dobDayRequired===true"
                        [ngClass]="{'ng-valid-not-required': !dobDayRequired }"
                        authenticate-user="contact.create,DOB.Create"
                        authenticate-command="disabled">
                  <option [ngValue]="''">--Select--</option>
                  <option *ngFor="let value of getDaysFromMonth(dummy.dobMonth)" [ngValue]="value">{{value}}</option>
                </select>
              </div>
              <span class="text-danger" *ngIf="(((dummy.dobMonth?.invalid || dummy.dobDay?.invalid) && submitted));">Enter a valid birth month and birth date.</span>
            </div>
          </div>
          <div class="col-md-6">
            <!--[ngClass]="{'has-error': dummy.ssn.invalid && dummy.isNonVendor !==true && submitted}"-->
            <div class="form-group">
              <label>Last 4 digit Social Security Number</label>
              <div class="form-inline">
                <input name="ssn"
                       #fieldSsn="ngModel"
                       id="ssn"
                       type="text"
                       appInputRestriction="numberOnly"
                       class="form-control form-control-sm"
                       [(ngModel)]="dummy.lastFourSsn"
                       maxlength="4"
                       minlength="4"
                       (keyup)="checkVendorValidation()"
                       numbers-only
                       [required]="ssnRequired==true"
                       [ngClass]="{'ng-valid-not-required': !(dummy.isUSCitizen === true && dummy.isNonVendor === true) }"
                       authenticate-user="contact.create,SSN.Create"
                       authenticate-command="disabled" />
              </div>
              <span class="control-label control-label-error" *ngIf="dummy.lastFourSsn?.invalid  && dummy.isNonVendor !==true && submitted">Please enter a valid last four SSN.</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" [ngClass]="{'has-error': dummy.sso.invalid && dummy.isNonVendor !==true && submitted}">
              <label>SSO</label>
              <div class="form-inline">
                <input name="sso"
                       type="text"
                       id="sso"
                       appInputRestriction="numberOnly"
                       class="form-control form-control-md"
                       [(ngModel)]="dummy.sso"
                       maxlength="9"
                       minlength="9"
                       [required]="ssoRequired==true"
                       (keyup)="checkVendorValidation()"
                       numbers-only
                       [ngClass]="{'ng-valid-not-required': !(dummy.isUSCitizen === true && dummy.isNonVendor === true) }"
                       authenticate-user="contact.create,SSN.Create"
                       authenticate-command="disabled" />
              </div>
              <span class="control-label control-label-error" *ngIf="ssoRequired && contactSearchForm.form.controls['sso']?.errors?.required && dummy.isNonVendor !==true">Please enter a valid SSO.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-right">
        <button class="btn btn-default" type="button" (click)="cancelAddContact()">Cancel</button>
        <button class="btn btn-primary"
                type="submit"
                id="btnCreateContact"
                [disabled]="(contactSearchForm.status==='INVALID' || contactSearchForm.pristine===true) && CheckValidation(contactSearchForm.status,contactSearchForm.pristine)"
                (click)="checkVendorValidation();searchContactForm(contactSearchForm.valid)">
          Next
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="contactMatchModalActive">
    <div class="modal-body">
      <div nbc-alerts alerts-key="modalAlert"></div>
      <p>{{modalDescription}}</p>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <pagination-label pageSize="{{matchedContact.pageSize}}"
                              currentPage="{{matchedContact.currentPage}}"
                              totalRecords="{{matchedContact.totalRecords}}"></pagination-label>
          </div>
          <div class="col-md-9 d-flex justify-content-end">
            <nbc-pagination totalItems="{{matchedContact.totalRecords}}"
                            itemsPerPage="{{matchedContact.pageSize}}"
                            [maxSize]="5"
                            [(currentPage)]="matchingContactQuery.currentPage"
                            (pageChanged)="contactMatchSearch($event)"></nbc-pagination>
          </div>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th class="cursor-pointer" (click)="sortItem('LastName')"><strong>Last Name</strong></th>
            <th class="cursor-pointer" (click)="sortItem('FirstName')"><strong>First Name</strong></th>
            <th class="cursor-pointer" (click)="sortItem('Email')"><strong>Email</strong></th>
            <th><strong style="padding-left: 77px;">Phone</strong></th>
            <th><strong>DOB</strong></th>
            <th><strong>SSN</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of matchedContact.items">
            <td>{{contact.lastName}}</td>
            <td><a class="btn-link" routerLink="Contacts/Detail/{{contact.id}}" target="_blank">{{contact.firstName}}</a></td>
            <td>{{contact.email}}</td>
            <td><input [(ngModel)]="contact.phone1.phoneNumber" disabled *ngIf="contact.phone1?.phoneNumber" class="phone-number" /></td>
            <td>{{ months[contact.dobMonth-1]?.shortName }} {{contact?.dobDay}}</td>
            <td>
              <span *ngIf="contact.ssnLastFour">{{ "***-**-" + contact.ssnLastFour}}</span>
              <span *ngIf="!contact.ssnLastFour"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer text-right">
      <button class="btn btn-primary" *ngIf="!emailDuplicate" (click)="createContact()">Proceed to Create Contact</button>
      <button class="btn btn-primary" (click)="cancelAddContact()">Back</button>
      <button class="btn btn-default" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>
