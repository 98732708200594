import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { UpcomingEventsComponent } from "./upcomingevents.components";
//import { AngularMaterialModule } from '../Shared/material/angularmaterial.module'; //Not Required
import { CommonModule } from "@angular/common";
//import { NgxSelectModule } from 'ngx-select-ex'; //Not Required
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
//import { SelectDropDownModule } from 'ngx-select-dropdown' //Not Required
import { MyEventsComponent } from "./myevents.component";
import { TelephonePipe } from "../Shared/pipes/telephone.pipe";
import { TabsModule } from "ngx-bootstrap/tabs";
import { DashboardService } from "../Shared/Services/dashboard.service";
import { DashboardRecentlyViewed } from "./dashboard-recentlyviewed.component";
import { NgPipesModule } from "ngx-pipes";
import { FormsModule } from "@angular/forms";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { NbcSharedModule } from "../Shared/shared.module";
import { DashboardRouterModule } from "./dashboard-routing.module";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { PositionRequestOnboardingComponent } from "./position-request-onboarding/position-request-onboarding.component";
import { MyEventDashboardComponent } from "./my-event-dashboard/my-event-dashboard.component";
import { DashboardEventRecentlyviewedComponent } from "./dashboard-event-recentlyviewed/dashboard-event-recentlyviewed.component";
import { DashboardContactRecentlyviewedComponent } from "./dashboard-contact-recentlyviewed/dashboard-contact-recentlyviewed.component";
import { ModelPositionrequestComponent } from "./position-request-onboarding/model-positionrequest/model-positionrequest.component";
import { ModalModule, BsModalService } from "ngx-bootstrap/modal";
import { PositionFilterPipe } from "././position-request-onboarding/position-filter.pipe";
import { OnboardingColorsService } from "../Shared/Services/Onboarding/onboarding-colors.service";
import { SortDirective } from "../Shared/directives/sort.directive";
import { ModelI9verifyComponent } from "./position-request-onboarding/model-i9verify/model-i9verify.component";
import { DashboardPendingTimecardsComponent } from "./dashboard-pendingtimecards/dashboard-pendingtimecards.component";
import { DashboardvalidationfailedTimecardsComponent } from "./dashboard-validationfailedtimecards/dashboard-validationfailedtimecards.component";
//import { NbcFullscreendivDirective } from '../Shared/directives/nbc-fullscreendiv.directive'; //Not Required

@NgModule({
    imports: [
        //AngularMaterialModule,
        CommonModule,
        // NgxSelectModule,
        BsDatepickerModule.forRoot(),
        //SelectDropDownModule,
        TabsModule.forRoot(),
        NgPipesModule,
        FormsModule,
        RouterModule,
        NbcSharedModule,
        DashboardRouterModule.forChild(),
        AccordionModule,
        ModalModule,
    ],
    exports: [],
    declarations: [
        DashboardComponent,
        UpcomingEventsComponent,
        MyEventsComponent,
        DashboardRecentlyViewed,
        TelephonePipe,
        PositionRequestOnboardingComponent,
        MyEventDashboardComponent,
        DashboardEventRecentlyviewedComponent,
        DashboardContactRecentlyviewedComponent,
        ModelPositionrequestComponent,
        ModelI9verifyComponent,
        DashboardPendingTimecardsComponent,
        DashboardvalidationfailedTimecardsComponent,
        PositionFilterPipe,
        SortDirective,
    ],
    providers: [
        DashboardService,
        NbcLoggerService,
        BsModalService,
        OnboardingColorsService,
    ],
})
export class DashboardModule {}
