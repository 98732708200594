import { Component, OnInit, OnDestroy } from "@angular/core";
import { OnboardingService } from "../../Shared/Services/Onboarding/onboarding.service";

import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { Subject, Observable, Subscriber } from "rxjs";
import { takeUntil, switchMap, mergeMap } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
    EmitEvent,
    EventBusService,
} from "../../Core/services/event-bus.service";
import { OnboardingColorsService } from "../../Shared/Services/Onboarding/onboarding-colors.service";
import { ContactService } from "../../Shared/Services/contact.service";
import { PositionService } from "../../Shared/Services/position.service";
import { cloneDeep } from "lodash";
import { SharedDataService } from "../../Shared/sharedData.service";
import { SubSink } from "subsink";
import { NgxUiLoaderService, SPINNER } from "ngx-ui-loader";

@Component({
    selector: "onboarding-history",
    templateUrl: "./onboarding-history.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.less",
    //  "../../../assets/Content/app/areas/hr/hr.less",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"]
})
export class OnboardingHistoryComponent implements OnInit, OnDestroy {
    constructor(
        private _onboardingService: OnboardingService,
        private _modalService: BsModalService,
        private _remotesQueryService: RemotesQueryService,
        private _eventBusService: EventBusService,
        private _onboardingColorsService: OnboardingColorsService,
        private _contactService: ContactService,
        private _positionService: PositionService,
        private _sharedService: SharedDataService,
        private log: NbcLoggerService,
        private ngxService: NgxUiLoaderService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    //global variables for windows
    childwindows = [];
    newWindow = null;
    positionStatusList: any[] = [
        { name: "Request Completed", value: 7 },
        { name: "Cancelled", value: 8 },
    ];

    positionTypeList: any = [
        { name: "PEP Casual", value: "PEP Casual" },
        { name: "Daily Hire NABET", value: "Daily Hire NABET" },
        { name: "Daily Hire DGA", value: "Daily Hire DGA" },
        { name: "PEP IATSE", value: "PEP IATSE" },
    ];

    employeeStatusList: any = [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
        { name: "Processing", value: "Processing" },
        { name: "Withdrawn", value: "Withdrawn" },
    ];

    searchCriteria: any = [
        { optionValue: "1", optionDescription: "Contact Name" },
        { optionValue: "2", optionDescription: "SSO" },
        { optionValue: "3", optionDescription: "Hiring Manager" },
        { optionValue: "7", optionDescription: "Production Manager" },
        { optionValue: "4", optionDescription: "Position Name" },
        { optionValue: "5", optionDescription: "Event Name" },
        { optionValue: "6", optionDescription: "Event Id" },
    ];

    pageSizeList: any[] = [10, 25, 50, 100];

    private subs = new SubSink();
    spinnerType = SPINNER.rectangleBounce;
    isFirstTimehHistoryPageLoad = false;

    goButtonEnable: any = true;
    searchTextboxEnable: any = true;
    colors: any | undefined;
    selectedIds: any[] | undefined;

    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm: string | undefined;
    hasContactSelected = false;

    positionObservable$: Observable<any[]> | undefined;
    positionSearchTerm = "";
    hasPositionSelected = false;

    selectedRequestStatus: any = "";
    selectedPositionType: any = "";
    selectedEmployeeStatus: any = "";
    selectSearchCriteria: any = {
        optionValue: "0",
        optionDescription: "",
    };

    gridData = {
        items: [],
        query: this._remotesQueryService.getHrOnboardingHistoryTabQuery(),
    };

    queryContainer: any | undefined;

    contactTypeahed() {
        this.hasContactSelected = false;
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                this.queryContainer.query.searchTerm.description = token; //contact name fuzzy search
                return this._contactService.searchByName(token);
            })
        );
    }

    onContactSearchTermSelect($searchTerm): void {
        this.queryContainer.query.searchTerm.description =
            $searchTerm.item.fullName;
        this.queryContainer.query.searchTerm.name = $searchTerm.id;
    }

    positionTypeahead() {
        this.positionObservable$ = new Observable((observer) => {
            // Runs on every search
            observer.next(this.positionSearchTerm);
        }).pipe(mergeMap((token) => this.getPositionsAsObservable(token)));
    }

    getPositionsAsObservable(searchterm) {
        return this._positionService.autocomplete(searchterm);
    }

    onPositionSearchTermSelect($searchTerm): void {
        this.queryContainer.query.searchTerm.description =
            $searchTerm.item.description;
        this.queryContainer.query.searchTerm.name = $searchTerm.id;
    }

    searchPage(pageSize) {
        this.queryContainer.query.pageSize = pageSize;
        this.search();
    }

    search(): void {
        if (this.queryContainer.query.searchTerm.description != " ") {
            this.log.trace("OnboardingHistory -> Search() called");
            const onboardingQuery = cloneDeep(this.queryContainer);
            this._onboardingService
                .historySearch(onboardingQuery.query)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                    (data) => {
                        this.log.trace(
                            "OnboardingHistory -> Search(): Data received"
                        );
                        this.queryContainer.query.totalRecords =
                            data.reference.totalRecords;
                        this.gridData.items = data.reference.items;
                        this.gridData.query = this.queryContainer.query;  
                        setTimeout(() => {
                            this._eventBusService.emit(
                                new EmitEvent(
                                    "onboard-history:search",
                                    "buildOnboardingHistoryGrid",
                                    this.gridData
                                )
                            );
                        }, 300);
                    },
                    (err) => {
                        this.log.error(err);
                        this.stopLoaderAfterSearchResponseInHistoryTab();
                    },
                    () => {
                        this.log.trace("OnboardingHistory -> removing spinner");
                        this.stopLoaderAfterSearchResponseInHistoryTab();
                    }
                );
        }
    }


  getI9VerifyMasterData() {
    this._onboardingService.getI9verifyMasterData()
      .subscribe(
        data => {
          this._eventBusService.emit(new EmitEvent("i9verifyMasterData", "i9verifyMasterData", data.reference));
        },
        err => {
          this.log.error(err);
        },
        () => {
          this.log.trace("Onboarding MasterData -> removing spinner");
        });
  }

  stopLoaderAfterSearchResponseInHistoryTab(): void {
    if (this.isFirstTimehHistoryPageLoad) {
      this.ngxService.stopBackground();
      this.isFirstTimehHistoryPageLoad = false;
    }
  };


    changeRequestStatus(): void {
        if (this.selectedRequestStatus?.value) {
            this.queryContainer.query.requestStatusId =
                this.selectedRequestStatus.value;
        } else {
            this.queryContainer.query.requestStatusId = null;
        }
        this.search();
    }

    changePositionType(): void {
        if (this.selectedPositionType?.value) {
            this.queryContainer.query.positionType =
                this.selectedPositionType.value;
        } else {
            this.queryContainer.query.positionType = null;
        }
        this.search();
    }

    changeEmployeeStatus(): void {
        if (this.selectedEmployeeStatus?.value) {
            this.queryContainer.query.employeeStatus =
                this.selectedEmployeeStatus.value;
        } else {
            this.queryContainer.query.employeeStatus = null;
        }
        this.search();
    }

    changeSearchCriteria(): void {
        setTimeout(() => {
            if (
                this.selectSearchCriteria?.optionValue &&
                this.selectSearchCriteria?.optionValue != 0
            ) {
                this.queryContainer.query.searchTerm.id =
                    this.selectSearchCriteria.optionValue;
                this.enableSearchButton();
            } else {
                this.queryContainer.query.description = "";
                this.disableSearchButton();
            }
        }, 300);
    }

    disableSearchButton(): void {
        this.goButtonEnable = true;
        this.searchTextboxEnable = true;
        this.queryContainer.query.searchTerm.description = "";
    }

    enableSearchButton(): void {
        this.goButtonEnable = false;
        this.searchTextboxEnable = false;
        this.queryContainer.query.searchTerm.description = "";
    }

    onSearchTermSelect(searchTerm): void {
        this.queryContainer.query.searchTerm.description =
            searchTerm.description;
        this.queryContainer.query.searchTerm.name = searchTerm.id;
    }

    sortColumn(column) {
        if (this.queryContainer.query.sortByColumn === column) {
            this.queryContainer.query.reverseSort =
                !this.queryContainer.query.reverseSort;
        } else {
            this.queryContainer.query.sortByColumn = column;
            this.queryContainer.query.reverseSort = false;
        }
        this.search();
    }

    restartSearch(): void {
        this.queryContainer = {
            query: this._remotesQueryService.getHrOnboardingRequestTabQuery(),
        };
        this.selectedRequestStatus = "";
        this.search();
        this.goButtonEnable = true;
        this.searchTextboxEnable = true;
        this.contactSearchTerm = "";
        this.positionSearchTerm = "";
        this.selectSearchCriteria = "";
        this.selectedPositionType = "";
        this.selectedEmployeeStatus = "";
    }

    changeTypeaheadLoading($event) {}

    openHrOnboardingPositionModal(hrOnboardingItem) {
        const positionRequestNo = hrOnboardingItem.requestNumber;
        const crewRegNo = hrOnboardingItem.crewRegistrationNo;
        const drupalUrl = window.preload.uisettings.drupalModal;

        const url =
            drupalUrl +
            "%26field_position_request_number%3D" +
            positionRequestNo +
            "%26field_status_crew_reg_number%3D" +
            crewRegNo;
        const settings =
            "top=125,left=280,width=800,height=775,location=0,titlebar=0";

        const newWindow = window.open(url, crewRegNo, settings);

        for (let i = 0; i < this.childwindows.length; i++) {
            if (
                this.childwindows[i] &&
                this.childwindows[i] == newWindow &&
                this.childwindows[i].opened
            ) {
                this.childwindows.splice(i, 1);
                this.childwindows[i].close();
            }
        }
        this.childwindows[this.childwindows.length] = newWindow;
        if (newWindow) {
            newWindow.focus();
        }
        return false;
    }

    initSubs(): void {
        this.subs.sink = this._eventBusService.on(
            "changeSortColumn",
            (dataSource) => this.sortColumn(dataSource)
        );
        this.subs.sink = this._eventBusService.on(
            "openHrOnboardingPositionModal",
            (dataSource) => this.openHrOnboardingPositionModal(dataSource)
        );
    }

  ngOnInit(): void {
    this.isFirstTimehHistoryPageLoad = true;
    this.ngxService.startBackground();
    this.getI9VerifyMasterData();
     this.initSubs();
    this.queryContainer = {
      query: this._remotesQueryService.getHrOnboardingHistoryTabQuery()
    };
    this.colors = this._onboardingColorsService.getColors();
    this.contactTypeahed();
    this.positionTypeahead();
    this.search();

  }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
