<div>
    <div class="modal-header">
       <h3 class="modal-title">{{reportName}}</h3>
       <button type="button"
                class="btn-close"
                (click)="cancel()"
                aria-hidden="true"> </button>
    </div>
    <!--<span us-spinner spinner-key="genericSpinner" spinner-start-active="true"></span>-->
    <div *ngIf="showAlertMessage">
        <div class="pt-3">
            <nbc-modal-alert></nbc-modal-alert>
        </div>
      </div>
    <div class="modal-body">
        <div class="grid-table">
            <div class="grid-content-row"
                 *ngIf="showEventsRowAll">
                <div class="grid-col report-name">
                    Events
                </div>
                <!--<div class="grid-col">
                    All
                </div>-->
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showEventsEditRow">
                <div class="grid-col report-name">
                    Events
                </div>
                <div class="grid-col">
                    <div class="row">
                        <!--<div class="col-md-4" ng-repeat="events in payRateReport.events">
                            <input type="checkbox" ng-model="events.value" ng-checked="events.value" ng-change="stateChanged(events.value,events,'EventsRow')"> {{events.name}}
                        </div>-->
                        <div class="col-md-8">
                            <div class="form-group">
                                <div>
                                    <nbc-multi-select [source]="remoteProductionMemoReport.events"
                                                      value="id"
                                                      display="name"
                                                      [(selectedValues)]="selectedEvent"
                                                      [placeholder]="'Select'"
                                                      (nbcOnOptionSelect)="onItemSelect($event)"></nbc-multi-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showEventsTagRow">
                <div class="grid-col report-name">
                    Events
                </div>
                <div class="grid-col">
                  <div class="row">
                    <div class="col-md-4"
                         *ngFor="let events of selectedEvent">
                      <span class="filter-tag">
                        {{events.name}} <a>
                          <i class="fa fa-times-circle"
                             (click)="removeTag(events,'EventsRow')"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                  <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                         (click)="editRow('EventsRow')"></i></a>
                  </div>
                </div>



        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                (click)="exportProductionMemoReport()">Export</button>

    </div>
</div>
<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
