<header id="col-main-hdr">
  <h1>Admin</h1>
</header>
<div class="olympics-setup">
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li>Admin</li>
    </ul>
  </nav>

  <div class="page-container">

    <nbc-alert></nbc-alert>

    <div class="container-fluid">
      <tabset class="content-tabs contact-tabs-sm">

        <tab *ngFor="let t of tabs | filterBy:['hasAccess']:true"             
             (selectTab)="go(t.route)"
             [active]="t.active">
          <ng-template tabHeading>
            <span nbcRightClickOpen="Remotes/admin/{{t.route}}">{{t.heading}}</span>
          </ng-template>
        </tab>
      </tabset>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
