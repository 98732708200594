import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "app-clone-info-event-modal",
    templateUrl: "./clone-event-info-modal.component.html",
})
export class CloneEventInfoModalComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    statusCounter = 10;
    public onSync!: Subject<any>;

    data: any = {
        isOkToRedirect: false,
    };

    Decreament() {
        const handler = setInterval(() => {
            this.statusCounter = --this.statusCounter;
            if (this.statusCounter == 0) {
                clearInterval(handler);
                this.closeMsg();
            }
        }, 2000);
    }

    closeMsg() {
        this.data.isOkToRedirect = true;
        this.onSync.next(this.data);
        this.bsModalRef.hide();
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.Decreament();
    }

    ngOnDestroy() {
        this.onSync.unsubscribe();
    }
}
