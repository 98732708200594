<div style="overflow:hidden" class="hcmModal">
  <div>
    <div class="modal-header">
      <h5 class="modal-title ng-binding">SAP Results</h5><button type="button" class="btn-close" (click)="closeModal()"> </button>
    </div>
  </div>
  <div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped" style="margin-bottom:0">
              <thead class="dark-header">
                <tr>
                  <th style="width:10px"></th>
                  <th class="col-md-1">First Name</th>
                  <th class="col-md-1">Last Name</th>
                  <th class="col-md-1">SSO</th>
                  <th class="col-md-1">SAP Status</th>
                  <th class="col-md-2">SSN (last four)</th>
                  <th class="col-md-1">DOB (first four)</th>
                  <th class="col-md-1">City</th>
                  <th class="col-md-1">State</th>
                  <th class="col-md-1">Country Code</th>
                  <th class="col-md-2">Last Updated by SAP</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of contacts; let optIndex = index">

                  <td style="width:10px">
                    <input type="radio"
                           [(ngModel)]="contact.isSelected"
                           name="rbSelectedContact"
                           (change)="selectContact(optIndex)" />
                  </td>
                  <td class="col-md-1">{{contact.firstName}} </td>
                  <td class="col-md-1">{{contact.lastName}} </td>
                  <td class="col-md-1">{{contact.sso}} </td>
                  <td class="col-md-1">{{contact.employmentStatus}} </td>
                  <td class="col-md-2">{{contact.ssnLastFour}} </td>
                  <td class="col-md-1">{{contact.dobMonthDate}} </td>
                  <td class="col-md-1">{{contact.city}} </td>
                  <td class="col-md-1">{{contact.state}} </td>
                  <td class="col-md-1">{{contact.countryCode}} </td>
                  <td class="col-md-2"><span *ngIf="contact.lastUpdatedbySAP != 'Invalid Date'">{{contact.lastUpdatedbySAP | date : "MM/dd/yyyy"}} </span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer text-right" style="top:unset">
    <button class="btn btn-default" type="button"
            (click)="closeModal()">
      Cancel
    </button>
    <button class="btn btn-primary" *ngIf="selectedContact"
            type="submit"
            (click)="syncContact(selectedContact)">
      Next
    </button>
  </div>
</div>

