<div style="overflow:hidden" class="hcmModal">
  <div>
    <div class="modal-header">
      <h5 class="modal-title ng-binding">Emergency Contact Information</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>

    </div>
  </div>
  <div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped" style="margin-bottom:0">
              <thead class="dark-header">
                <tr>
                  <th class="col-md-1">SOURCE</th>
                  <th class="col-md-2">NAME</th>
                  <th class="col-md-2">RELATIONSHIP</th>
                  <th class="col-md-2">EMAIL</th>
                  <th class="col-md-2">PRIMARY PHONE</th>
                  <th class="col-md-1">ALTERNATE PHONE</th>
                  <th class="col-md-2">LAST UPDATED ON</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of contacts; let optIndex = index">
                  <td class="col-md-1">{{contact.source}} </td>
                  <td class="col-md-2">{{contact.name}} </td>
                  <td class="col-md-2">{{contact.relationShip}} </td>
                  <td class="col-md-2">{{contact.email}} </td>
                  <td class="col-md-2">{{contact.primaryPhoneNumber}} </td>
                  <td class="col-md-1">{{contact.alternatePhoneNumber}} </td>
                  <td class="col-md-2">{{contact.lastUpdatedOn}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer text-right" style="top:unset">
    <button class="btn btn-default" type="button"
            (click)="closeModal()">
      Close
    </button>
  </div>
</div>

