import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { EventTypeComponent } from "./event-type/event-type.component";
import { JobTitleComponent } from "./job-title/job-title.component";
import { MinimumWageComponent } from "./minimum-wage/minimum-wage.component";
import { NetworkGroupComponent } from "./network-group/network-group.component";
import { NetworkComponent } from "./network/network.component";
import { VenueComponent } from "./venue/venue.component";

const routes: Routes = [
    {
        path: "",
        component: AdminComponent,
        children: [
            {
                path: "",
                redirectTo: "job-title",
                pathMatch: "full",
            },
            {
                path: "job-title",
                component: JobTitleComponent,
            },
            {
                path: "venue",
                component: VenueComponent,
            },
            {
                path: "event-type",
                component: EventTypeComponent,
            },
            {
                path: "network",
                component: NetworkComponent,
            },
            {
                path: "network-group",
                component: NetworkGroupComponent,
            },
            {
                path: "minimum-wage",
                component: MinimumWageComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRouterModule {
    static forChild() {
        return {
            ngModule: AdminRouterModule,
            providers: [],
        };
    }
}
