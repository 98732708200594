import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "async-loader",
    templateUrl: "./async-loader.component.html",
})
export class AsyncLoaderComponent implements OnChanges {
    @Input() id: string | undefined;
    @Input() state: string | undefined;
    @Input() errorText: string | undefined;

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "state") {
                const current = changes["state"]["currentValue"];
                const prev = changes["state"]["previousValue"];
                this.state = current;

                if (this.state === "success") {
                    setTimeout(() => {
                        this.state = "";
                    }, 1000);
                }
            }

            if (propName === "errorText") {
                this.errorText = changes["errorText"]["currentValue"];
            }
        }
    }
}
