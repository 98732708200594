import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
 import { NbcLoggerService } from 'src/app/Shared/logger/nbc-logger.service';
import { EmitEvent, EventBusService } from 'src/app/Core/services/event-bus.service';
import { OnboardingColorsService } from '../../Shared/Services/Onboarding/onboarding-colors.service';
import { RemotesQueryService } from '../../Shared/Services/remotesquery.service';
import { SubSink } from 'subsink';

@Component({
    selector: "history-grid",
    templateUrl: "./history-grid.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.less",
    //  "../../../assets/Content/app/areas/hr/hr.less",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.None
})
export class HistoryGridComponent implements OnInit, OnDestroy {
    constructor(
        private _onboardingColorsService: OnboardingColorsService,
        private _eventBusService: EventBusService,
        private _remotesQueryService: RemotesQueryService
    ) {}

    //@Input() dataSource: any[] | undefined;

    colors: any | undefined;
    gridData = {
        items: [],
        query: this._remotesQueryService.getHrOnboardingHistoryTabQuery(),
    };

  i9VerifyDDl: any;

  private subs = new SubSink();
  //gridData: any | undefined;

  buildGrid(dataSource: any) {
    this.gridData = dataSource;
    this.gridData.items.forEach((item: any) => {
      const i9verify = this.i9VerifyDDl?.filter((obj) => {
        return obj.name === item.i9Verify;
      });
      item.i9Verify = i9verify[0];
      item.requestStatusColor = this.colors.requestStatus.get(item.requestStatus);
    });
  }

  getI9verifyMasterData(data: any) {
    this.i9VerifyDDl = data;
  }

  updateDetails(item, columnName, value): void {

    var registrationObjects: any = {
      items: [item],
      columnName: columnName,
      value: value
    };
    //registrationObjects.items.push(item);
    this._eventBusService.emit(new EmitEvent("onboarding-history:updateI9Review", "updateI9Review", registrationObjects));
  };

  sortOnColumn(columnName: string): void {
    this._eventBusService.emit(new EmitEvent("onboard-history:sortOnColumn", "changeSortColumn", columnName));
  };

    openHrOnboardingPositionModal(listItem) {
        this._eventBusService.emit(
            new EmitEvent(
                "onboard-history:openOnboardingModal",
                "openHrOnboardingPositionModal",
                listItem
            )
        );
    }

  InitSubs(): void {

    this.subs.sink = this._eventBusService.on(
      "i9verifyMasterData",
      (data) => this.getI9verifyMasterData(data)
    );

    this.subs.sink = this._eventBusService.on("buildOnboardingHistoryGrid", (dataSource) => this.buildGrid(dataSource));
    

  }

    async ngOnInit() {
        this.colors = this._onboardingColorsService.getColors();
        this.InitSubs();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
