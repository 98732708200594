import { Component, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { SubSink } from "subsink";
import { EventService } from "../../Shared/Services/event.service";

@Component({
    selector: "modal-event-listview",
    templateUrl: "./eventviewlistview.component.html",
})
export class modaleventlistview implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _EventService: EventService
    ) {}

    @Input() showGrid: string | undefined;
    @Input() model: any;
    private subs = new SubSink();

    saveFavorite(event: any) {
        const favoriteStatus = event.isFavorite,
            favoriteQuery = {
                eventId: event.id,
                eventName: event.name,
                key: event.isFavoriteKey,
                isFavorite: !favoriteStatus,
            };
        let eventFavoritePromise: any;
        const index = this.model.items.indexOf(event);
        if (!favoriteStatus) {
            this.subs.sink = this._EventService
                .favorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        ("Unable to favorite/unfavorite event at this time. Please try again later.");
                    },
                    () => {
                        this.log.debug("favorite successfully added");
                    }
                );
        } else {
            this.subs.sink = this._EventService
                .unfavorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        // this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        this.log.showError(
                            "Unable to favorite/unfavorite event at this time. Please try again later."
                        );
                    },

                    () => {
                        this.log.debug("unfavorite successfully added");
                    }
                );
        }
    }

    ngOnInit() {
        this.showGrid;
        this.model;
    }
}
