import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
//import {
//    CalendarOptions,
//    EventClickArg,
//    EventApi,
//    DateSelectArg,
//    FullCalendarComponent,
//} from "@fullcalendar/angular";
import { FullCalendarComponent } from "@fullcalendar/angular";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import { reduce, takeUntil } from "rxjs/operators";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { calendar } from "ngx-bootstrap/chronos/moment/calendar";
import { ContactSharedDataService } from "../contact-shared.service";
import * as _ from "lodash";
import * as moment from "moment";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { WorkScheduleNewActity } from "./activity/contact-work-schedule-activity.component";
import { Subject } from "rxjs";

@Component({
    selector: "contact-work-schedule",
    templateUrl: "./contact-work-schedule.component.html",
})
export class ContactWorkScheduleComponent implements OnInit, OnDestroy {
    calendarVisible: boolean | undefined;
    constructor(
        private _contactService: ContactService,
        private _contactSharedData: ContactSharedDataService,
        private _modalService: BsModalService,
        private log: NbcLoggerService
    ) {
        this.events = [];
    }
    @ViewChild("calendar") calendarComponent!: FullCalendarComponent;
    calendarOptions: any = {
        plugins: [interactionPlugin, dayGridPlugin],
        initialView: "dayGridMonth",
    };
    componentDestroyed$: Subject<boolean> = new Subject();
    contactId = 0;
    //calendarOptions: CalendarOptions | undefined;
    workSchedule: any | undefined;
    eventSources = [];
    events: {
        title: any;
        start: any;
        end: any;
        id: any;
        backgroundColor: any;
        workScheduleItem: any;
    }[];
    calendarButtonClicked = false;
    calendarInitialized = true;

    //onDateClick(res: { dateStr: string }) {
    //  alert('Clicked on date : ' + res.dateStr)
    //}

    onEventWorkScheduleClicked(info: any) {
        if (_.isEmpty(info.event.id)) {
            alert("Event: " + info.event.id);
            alert(
                "Coordinates: " + info.jsEvent.pageX + "," + info.jsEvent.pageY
            );
            alert("View: " + info.view.type);
        }
    }

    populateEvent(item) {
        let eventName = "",
            eventId = null,
            eventColor = "#fddbca";
        if (item.contactWorkHistory && item.contactWorkHistory.eventName) {
            eventName = item.contactWorkHistory.eventName;
        }
        if (item.eventId) {
            eventId = item.eventId;
            eventColor = "#0a9fd1";
        }
        let endDate =
            moment(item.endDate).format("YYYY-MM-DD") ||
            moment(item.end).format("YYYY-MM-DD");
        if (endDate) {
            endDate = moment(endDate).add("day", 1).format();
        }
        const itemData = {
            title: item.title || eventName,
            start:
                moment(item.startDate).format("YYYY-MM-DD") ||
                moment(item.start).format("YYYY-MM-DD"),
            end: endDate,
            id: eventId,
            backgroundColor: eventColor,
            workScheduleItem: item.workScheduleItem || item,
            allDay: true,
        };

        this.events.push(itemData);
        this.getConfigs();
    }

    getWorkSchedule(firstDay, lastDay) {
        this.log.trace("contact-workschedule -> getEventTypes:fetching data");
        this._contactService
            .getWorkSchedule(this.contactId, firstDay, lastDay)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    if (data) {
                        this.workSchedule = data["reference"];
                        _.forEach(this.workSchedule, (item) =>
                            this.populateEvent(item)
                        );

                        this.log.trace(
                            "contact-workschedule -> event types received"
                        );
                    }
                },
                (err) => this.log.error(err),
                () => {}
            );
    }

    openAddActivityModal() {
        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
        };
        this._modalService.show(WorkScheduleNewActity, modalOptions);
    }

    //openAddActivityModal(item, index) {
    //this.log.trace("Opening modal");
    //if (index) {
    //  this.activityEditMode = true;
    //}
    //var modalInstance = $modal.open({
    //  templateUrl: "/scripts/app/areas/contacts/views/contacts/modalAddContactWorkScheduleActivity.html",
    //  controller: "modalAddContactWorkScheduleActivity",
    //  backdrop: "static",
    //  keyboard: false,
    //  resolve: {
    //    contact: function () {
    //      return this.contact;
    //    },
    //    activityItem: function () {
    //      return item;
    //    }
    //  }
    //});

    //modalInstance.result.then(function (activity) {
    //  this.log.trace("Modal dismissed with: " + activity);
    //  var eventsList = angular.copy(this.events);
    //  this.calendar.fullCalendar("removeEventSource", this.events);

    //  if (activity === null) {
    //    eventsList.splice(index, 1);
    //    this.workSchedule.splice(index, 1);
    //    this.events = [];
    //    for (var i = 0; i < eventsList.length; i += 1) {
    //      populateEvent(eventsList[i], i);
    //    }
    //  } else {
    //    if (typeof index !== "undefined") {
    //      populateEvent(activity, index);
    //      this.workSchedule[index] = activity;
    //    } else {
    //      populateEvent(activity, this.workSchedule.length);
    //      this.workSchedule.push(activity);
    //    }
    //  }
    //  this.calendar.fullCalendar("addEventSource", this.events);
    //}, function () {
    //  this.log.trace("modal dismissed without value");
    //});

    //};

    getNextMonth() {
        this.events = [];
        const calendarApi = this.getCalendarApi();
        calendarApi.next();
        const dateRange = this.getMonthScheduleDates();
        this.getWorkSchedule(dateRange.startDate, dateRange.endDate);
    }

    getPreviousMonth() {
        this.events = [];
        const calendarApi = this.getCalendarApi();
        calendarApi.prev();

        const dateRange = this.getMonthScheduleDates();
        this.getWorkSchedule(dateRange.startDate, dateRange.endDate);
    }

    getToday() {
        this.events = [];
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.today();

        const dateRange = this.getMonthScheduleDates();
        this.getWorkSchedule(dateRange.startDate, dateRange.endDate);
    }

    getMonthScheduleDates() {
        const calendarApi = this.getCalendarApi();
        const startDate = moment(calendarApi.view.activeStart).format(
            "YYYY-MM-DD"
        );
        const endDate = moment(calendarApi.view.activeEnd).format("YYYY-MM-DD");
        return {
            startDate: startDate,
            endDate: endDate,
        };
    }

    getCalendarApi() {
        return this.calendarComponent.getApi();
    }

    getConfigs() {
        this.calendarOptions = {
            headerToolbar: {
                start: "", // will normally be on the left. if RTL, will be on the right
                center: "",
                end: "title", // will normally be on the right. if RTL, will be on the left
            },
            height: 600,
            initialView: "dayGridMonth",
            events: this.events, // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: false,
            selectable: false,
            selectMirror: true,
            dayMaxEvents: true,
            eventClick: this.onEventWorkScheduleClicked,
            nextDayThreshold: "00:00:00",
            //select: this.handleDateSelect.bind(this),
            //eventClick: this.handleEventClick.bind(this),
            //eventsSet: this.handleEvents.bind(this),
        };
    }
    ngOnInit() {
        if (_.isUndefined(this.events)) this.events = [];
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contactId = contact.id;
            setTimeout(() => {
                this.getToday();
            }, 300);
        });

        setTimeout(() => {
            this.getConfigs();
        }, 100);
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
