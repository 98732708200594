<div class="col-md-2 text-center float-start" *ngIf="chartData?.positionsTotal > 0">
  <div class="donut-chart-container">
    <canvas baseChart
            [data]=" positionChartData"
            [labels]="positionChart.labels"
            [colors]="positionChart.color"
            [options]="options"
            [type]="doughnutChartType"
            width="110"
            height="110"></canvas>
    <div class="donut-chart-label">
      <div class="donut-chart-label-txt">
        <div class="numeral">
          <strong>{{chartData.positionsTotal}}</strong>
        </div>
        Positions
      </div>
    </div>
  </div>
  <div class="donut-chart-legend align-text-bottom mb-1">
    <div class="donut-chart-legend-itms">
      <div class="donut-chart-legend-itm">
        <span class="label label-success">{{chartData.positionsConfirmed}}</span> Confirmed
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-warning">{{chartData.positionsAssigned}}</span> Assigned
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-default">{{chartData.positionsOpen}}</span> Open
      </div>
    </div>
  </div>


</div>

<div class="col-md-2 text-center float-start" *ngIf="chartData?.tierPositionsSummary?.isTieredEvent">
  <div class="donut-chart-container">
    <canvas baseChart
            [data]=" tierpositionsChartDetails"
            [labels]="tierpositionsChartData.labels"
            [colors]="tierpositionsChartData.color"
            [options]="options"
            [type]="doughnutChartType"
            width="110"
            height="110"></canvas>
    <div class="donut-chart-label">
      <div class="donut-chart-label-txt">
        <div class="numeral">
          <strong>{{chartData.tierPositionsSummary?.totalTierPositions}}</strong>
        </div>
        Positions
      </div>
    </div>
  </div>
  <div class="donut-chart-legend align-text-bottom mb-1">
    <div class="donut-chart-legend-itms">
      <div class="donut-chart-legend-itm">
        <span class="label label-success">{{chartData.tierPositionsSummary?.percentile_25}}</span> 25th Percentile
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-default">{{chartData.tierPositionsSummary?.percentile_50}}</span> 50th Percentile
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-percentile_75">{{chartData.tierPositionsSummary?.percentile_75}}</span> 75th Percentile
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-percentile_100">{{chartData.tierPositionsSummary?.percentile_100}}</span> 100th Percentile
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-overPaid">{{chartData.tierPositionsSummary?.overPaid}}</span> Over
      </div>
    </div>
  </div>
</div>
<div class="col-md-2 text-center float-start" *ngIf="chartData?.positionsTotal > 0">
  <div class="donut-chart-container">
    <canvas baseChart
            [data]="unionChartDataDetails"
            [labels]="unionChartData.labels"
            [colors]="unionChartData.color"
            [options]="options"
            [type]="doughnutChartType"
            width="110"
            height="110"></canvas>
    <div class="donut-chart-label">
      <div class="donut-chart-label-txt">
        <div class="percent">
          {{chartData.percentUnion}}%
        </div>
        Union
      </div>
    </div>
  </div>
  <div class="donut-chart-legend align-text-bottom mb-1">
    <div class="donut-chart-legend-itms">
      <div class="donut-chart-legend-itm">
        <span class="label label-darkblue">{{chartData.positionsUnion}}</span> Union
      </div>
      <div class="donut-chart-legend-itm">
        <span class="label label-primary">{{chartData.positionsNonUnion}}</span> Non-Union
      </div>
    </div>
  </div>
</div>


