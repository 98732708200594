<div class="modal-header">
  <h4 class="modal-title">Work Schedule Comment</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <nbc-modal-alert></nbc-modal-alert>
       <textarea maxlength="255" [(ngModel)]="comments"
                placeholder="Leave a comment"
                class="form-control"
                name="positionCOmment"
                appInputRestriction="restrictChars" ></textarea>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-primary" (click)="saveComment()">Save Comment</button>
  <button class="btn btn-default" (click)="cancel()">Cancel</button>
</div>
