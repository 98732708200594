import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: "input",
})
export class NbcAutoCompleteOffDirective implements OnInit {
  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.setAttribute("autocomplete", "off");
  }
}
