import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { TemplateService } from "src/app/Shared/Services/template.service";
import { ActivatedRoute, Router } from "@angular/router";
import { from, Subject, Subscription } from "rxjs";
import { _getEventTarget } from "@angular/cdk/platform";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { takeUntil } from "rxjs/operators";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { DragulaService } from "ng2-dragula";
import { ModalPositionComponent } from "../components/attachposition/modal-position.component";
import { SharedDataService } from "src/app/Shared/sharedData.service";

@Component({
    selector: "template-detail-list",
    templateUrl: "./template-detail-list.component.html",
    //styleUrls: ["../../../assets/Content/app/areas/admin/admin.css",
    //  "../../../assets/Content/app/Custom/dragula-dnd.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class TemplateDetailListComponent implements OnInit, OnDestroy {
    constructor(
        private _templateService: TemplateService,
        private _eventTypeService: EventTypeService,
        private _modalService: BsModalService,
        private dragulaService: DragulaService,
        private activatedRoute: ActivatedRoute,
        private log: NbcLoggerService,
        private _sharedData: SharedDataService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    bsModalRef?: BsModalRef;
    subs = new Subscription();

    positionTemplateId: any = 0;
    //orderBy = $filter("orderBy"),
    defaultOrderBy = "sortOrder";
    defaultReverseSort = false;

    isCloneInProgress = false;
    submitted = false;

    showGrid = true;

    filter: any = {
        searchText: "",
        search: {},
    };
    sort: any = {
        orderByField: this.defaultOrderBy,
        reverseSort: this.defaultReverseSort,
        orderFieldname: this.defaultOrderBy,
    };
    template: any = {};
    templateCopy: any = {};
    eventTypes: any[] = [];
    editMode: any = false;
    breadcrumb: any = {
        templateName: "",
    };
    reorderButtonInactive = true;
    sortProcessing = false;

    // Private Methods/Functions
    getTemplate() {
        this._templateService
            .getTemplateById(this.positionTemplateId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result: any) => {
                    this.template = result.reference;
                    this.breadcrumb.templateName = _.cloneDeep(
                        result.reference.name
                    );
                    for (let i = 0; i < this.template.positions.length; i++) {
                        this.template.positions[i].originalSortOrder =
                            this.template.positions[i].sortOrder;
                    }
                },
                error: (data) => {
                    this.log.error("got an error: " + JSON.stringify(data));
                    this.log.showError(
                        "Unable to load template at this time. Please try again later."
                    );
                },
            });
    }
    getEventTypes() {
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result: any) => {
                    this.eventTypes = result.reference;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                },
            });
    }
    reorderPositionItem(positions) {
        this.log.trace("reorderPositionItem");
        const reorderedPositionList = positions;
        const sortOrderReversed =
            this.sort.orderByField === "sortOrder" &&
            this.sort.reverseSort === true;
        let rowIndex = sortOrderReversed
            ? reorderedPositionList.positions.length
            : 1;

        if (reorderedPositionList.positions.length > 0) {
            if (!sortOrderReversed) {
                for (
                    let i = 0;
                    i < reorderedPositionList.positions.length;
                    i += 1
                ) {
                    reorderedPositionList.positions[i].sortOrder = rowIndex;
                    reorderedPositionList.positions[i].originalSortOrder =
                        rowIndex;
                    rowIndex += 1;
                }
            } else {
                for (
                    let j = reorderedPositionList.positions.length - 1;
                    j >= 0;
                    j -= 1
                ) {
                    reorderedPositionList.positions[j].sortOrder = rowIndex;
                    reorderedPositionList.positions[j].originalSortOrder =
                        rowIndex;
                    rowIndex -= 1;
                }
            }
        }

        return reorderedPositionList;
    }
    fixHelper(e, ui) {
        ui.children().each(function () {
            //$(this).width($(this).width());
        });
        return ui;
    }

    // Public Methods
    searchPositions() {
        this.filter.search = this.filter.searchText;
    }

    clearSearch() {
        this.filter.searchText = "";
        this.filter.search = "";
    }

    sortByColumn(column) {
        if (this.sort.orderByField === column) {
            if (this.sort.reverseSort === true) {
                this.sort.orderByField = "sortOrder";
                this.sort.reverseSort = this.defaultReverseSort;
                this.sortProcessing = false;
            } else {
                this.sort.reverseSort = true;
                this.sortProcessing = true;
            }
        } else {
            this.sort.orderByField = column;
            this.sortProcessing = true;
        }
        this.sort.orderFieldname = this.sort.reverseSort
            ? "-" + this.sort.orderByField
            : this.sort.orderByField;
    }

    setEditMode() {
        this.log.trace("setEditMode");
        this.editMode = true;
        this.templateCopy = _.cloneDeep(this.template);
        delete this.templateCopy.positions;
    }

    setReadMode() {
        this.log.trace("setReadMode");
        _.extend(this.template, this.templateCopy);
        this.templateCopy = {};
        this.editMode = false;
    }

    saveTemplate(isValid) {
        this.isCloneInProgress = true;
        this.log.trace("saveTemplate");
        this.submitted = true;
        if (
            this.template.eventType.id == "" ||
            this.template.eventType.id == 0
        ) {
            this.isCloneInProgress = false;
            return false;
        }
        if (isValid) {
            this.template.editedOn = new Date();
            this._templateService
                .updateTemplate(this.template)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result: any) => {
                        // this.log.trace("got results: " + JSON.stringify(result));
                        this.setReadMode();
                        this.template = result.reference;

                        for (
                            let i = 0;
                            i < this.template.positions.length;
                            i++
                        ) {
                            this.template.positions[i].originalSortOrder =
                                this.template.positions[i].sortOrder;
                        }
                        this.breadcrumb.templateName = _.cloneDeep(
                            result.reference.name
                        );
                    },
                    error: (err) => {
                        this.isCloneInProgress = false;
                        this.log.error("got an error: " + JSON.stringify(err));
                        this.log.showError(
                            "Unable to perform the save at this time. Please try again later."
                        );
                    },
                    complete: () => {
                        this.isCloneInProgress = false;
                    },
                });
        }
        return true;
    }

    sortableOptions = {
        axis: "y",
        cursor: "move",
        helper: this.fixHelper,
        handle: ".position-template-handle",
        items: "tr:not(.unsortable)",
        stop: () => {
            this.log.trace("drag and drop");
            this.log.showInfo("Please Save Order.");
            this.reorderButtonInactive = false;
            this.template = this.reorderPositionItem(this.template);
            // this.template.positions = _.orderBy(this.template.positions, "sortOrder", false);
            this.template.positions.sort(this.dynamicSort("sortOrder", false));
        },
    };

    sortOrderNumEntered(positionItem, actionTaken) {
        this.log.trace("sortOrder number entered");

        const numEntered = parseInt(positionItem.sortOrder, 10),
            originalSortOrder = parseInt(positionItem.originalSortOrder, 10),
            reverse = this.sort.reverseSort,
            orderByField =
                this.sort.orderByField !== ""
                    ? this.sort.orderByField
                    : "sortOrder";

        if (numEntered !== originalSortOrder) {
            this.showGrid = false;
            this.reorderButtonInactive = false;
            this.log.showInfo("Please Save Order.");
        }
        //sortOrder value needs to be an integer
        positionItem.sortOrder = parseInt(positionItem.sortOrder, 10);

        this.template.positions.forEach(function (key, index) {
            if (actionTaken !== "itemDeleted") {
                if (
                    originalSortOrder > numEntered &&
                    key.sortOrder >= numEntered &&
                    key.id !== positionItem.id
                ) {
                    key.sortOrder = key.sortOrder + 1;
                } else if (
                    originalSortOrder < numEntered &&
                    key.sortOrder <= numEntered &&
                    key.id !== positionItem.id
                ) {
                    key.sortOrder = key.sortOrder - 1;
                } else if (
                    key.sortOrder === numEntered &&
                    key.id === positionItem.id
                ) {
                    key.sortOrder = numEntered;
                }
            } else {
                key.sortOrder = index + 1;
            }
        });

        //reorder based on the value entered.
        //this.template.positions = _.orderBy(this.template.positions, ["sortOrder"], false);
        this.template.positions.sort(this.dynamicSort("sortOrder", false));

        //change sort order value based on index value.
        this.template = this.reorderPositionItem(this.template);

        //reorder based on the index values.
        //this.template.positions = _.orderBy(this.template.positions, orderByField, reverse);
        this.template.positions.sort(this.dynamicSort(orderByField, reverse));

        setTimeout(() => (this.showGrid = true), 400);
    }

    savePositionsOrder() {
        this.reorderButtonInactive = true;
        this.saveTemplate(true);
        this.log.clearAlert();
    }

    openDeletePositionModal(positionItem) {
        this.log.trace("Opening modal");
        const initialState: any = {
            self: this,
            modalTitle: "Confirm Delete Position",
            modalMessage: "Are you sure you want to delete this position?",
            confirmText: "Delete",
            cancelText: "Cancel",
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result) => {
            this.deletePosition(positionItem);
        });

        //var modalInstance = $modal.open({
        //  templateUrl: "/scripts/app/areas/admin/views/templates/modalDeletePosition.html",
        //  controller: "modalTemplateDeletePositionController",
        //  backdrop: "static",
        //  keyboard: false,
        //  resolve: {
        //    positionTemplateId: function () {
        //      return positionTemplateId;
        //    },
        //    positionItem: function () {
        //      return positionItem;
        //    }
        //  }
        //});

        //modalInstance.result.then(function () {
        //  log.trace("Modal dismissed with: " + JSON.stringify(positionItem));
        //  var index = this.template.positions.indexOf(positionItem);
        //  this.template.positions.splice(index, 1);
        //  this.sortOrderNumEntered(positionItem, "itemDeleted");
        //  this.savePositionsOrder(true);
        //}, function () {
        //  log.trace("modal dismissed without value");
        //});
    }

    deletePosition(positionItem) {
        this.log.trace("deletePosition modal");
        this._templateService
            .deletePosition(this.positionTemplateId, positionItem.id)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    const index = this.template.positions.indexOf(positionItem);
                    this.template.positions.splice(index, 1);
                    this.sortOrderNumEntered(positionItem, "itemDeleted");
                    this.savePositionsOrder();
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showError(
                        "Unable to delete a position at this time. Please try again later."
                    );
                },
            });
    }

    openPositionModal(positionItem?) {
        this.log.trace("Opening modal");
        if (!this.reorderButtonInactive) {
            this.savePositionsOrder();
        }

        const initialState: any = {
            self: this,
            positionTemplateId: this.positionTemplateId,
            positionItem: positionItem,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false
        };

        this.bsModalRef = this._modalService.show(
            ModalPositionComponent,
            modalOptions
        );
        this.bsModalRef.content.onUpdate.subscribe((result) => {
            this.getTemplate();
        });
        //var modalInstance = $modal.open({
        //  templateUrl: "/scripts/app/areas/admin/views/templates/modalPosition.html",
        //  controller: "modalPositionController",
        //  backdrop: "static",
        //  keyboard: false,
        //  size: size,
        //  resolve: {
        //    positionItem: function () {
        //      return positionItem;
        //    },
        //    positionTemplateId: function () {
        //      return positionTemplateId;
        //    },
        //    positionCount: function () {
        //      return this.template.positions.length;
        //    }

        //  }
        //});

        //modalInstance.result.then(function (positionTemplateData) {
        //  this.template = positionTemplateData;
        //  this.savePositionsOrder();

        //  log.trace("Modal dismissed with: " + positionTemplateData);

        //}, function () {
        //  log.trace("modal dismissed without value");
        //});
    }

    dynamicSort(property, isReverse) {
        let sortOrder = 1;
        if (isReverse) {
            sortOrder = -1;
            //property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            const result =
                a[property] < b[property]
                    ? -1
                    : a[property] > b[property]
                      ? 1
                      : 0;
            return result * sortOrder;
        };
    }

    initializeDragDrop() {
        this.dragulaService.createGroup("position", {
            moves: (el, container, handle, sibling) => {
                const classlist = handle?.classList;
                if (classlist?.contains("dragable")) return true;
                else return false;
            },
        });
        this.subs.add(
            this.dragulaService
                .dropModel("position")
                .subscribe(({ sourceModel, targetModel, item }) => {
                    this.log.trace("drag and drop");
                    this.log.showInfo("Please Save Order.");
                    this.reorderButtonInactive = false;
                    this.template.positions = targetModel;
                    this.template = this.reorderPositionItem(this.template);
                    // this.template.positions = _.orderBy(this.template.positions, "sortOrder", false);
                    this.template.positions.sort(
                        this.dynamicSort("sortOrder", false)
                    );
                })
        );
    }

    // Init
    ngOnInit() {
        this._sharedData.setCss("template");
        this.activatedRoute.params.subscribe(
            (params) => (this.positionTemplateId = params["id"])
        );

        this.getEventTypes();
        this.getTemplate();
        this.initializeDragDrop();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
