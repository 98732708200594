<div style="height:40px;" class="nbc-tab-display">
  <div style="background-color: white;float:left;width:3%;height:39px;text-align:left">
    <button style="width: 100%; height: 40px; border: 0;"
            (click)="prevTabSet()"
            [disabled]="pageNumber === 0 || !isTabPanelEnabled"
            [ngStyle]="{'cursor':( pageNumber === 0)?'not-allowed':'pointer'}"
            [ngClass]="{'disabled' : pageNumber === 0}">
      <i class="fa fa-arrow-left">

      </i>
    </button>
  </div> 
  <tabset *ngIf="showTabs" [justified]="true" class="nav-tabs-justified" style="float: left; width: 94%">

    <tab *ngFor="let t of displayTabs" (selectTab)="tabClick(t)" [active]="t.active" [disabled]="t.disabled">
      <ng-template tabHeading>
        <i *ngIf="t?.status?.toLowerCase() === 'submitted'"
           class="fa fa-check-circle"
           style="color: #88d150; font-size: 1.5em; padding-right: 0.5em">
        </i>
        <i *ngIf="t?.status?.toLowerCase() === 'partially submitted'"
           class="fa fa-check-circle"
           style="color: #ff8d23; font-size: 1.5em; padding-right: 0.5em">
        </i>{{ t.date | date: 'EEE, MMM d' }}
      </ng-template>
    </tab>
  </tabset>

  <div class="next-btn"
       style="background-color: white; float: right; width: 3%;height:39px;">
    <button style="width:100%;height:40px;border:0;"
            (click)="nextTabSet()"
            [disabled]="pageNumber === lastPageNumber || !isTabPanelEnabled"
            [ngStyle]="{'cursor':( pageNumber === lastPageNumber)?'not-allowed':'pointer'}"
            [ngClass]="{'disabled' : pageNumber === lastPageNumber}">
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>




