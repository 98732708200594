<div class="modal-header">
  <h4 class="modal-title">Send Reminder</h4>
  <button type="button"
  class="btn-close"
  aria-hidden="true"
  (click)="closeModal()">
</button>
</div>

<div class="modal-body">
  <!--*ngIf="eventDetails && (!positionDetails || !positionDetails.payRate)">
  Pay Rate should be available to request the position for Onboarding-->
  <!--<span style="color:red"
        *ngIf="!isFormDirty && !submitted">
    There are no changes saved.
  </span>

  <span style="color:green"
        *ngIf="submitted">
    The Position Request has been successfully updated.
  </span>-->
  <nbc-alert></nbc-alert>
  <nbc-modal-alert></nbc-modal-alert>
  <form #frmsendReminder="ngForm">
    <p>
      By clicking 'Send' below you will trigger an Email notification to <strong> {{text}} </strong>
    </p>
    <div class="row">
      <div class="col-md-12">
        <b>Comments</b>
        <div class="form-group">
          <textarea name="comments"
                    [(ngModel)]="comments"
                    class="form-control req-modal"
                    placeholder="You are required to enter a comment.  This will appear in the email."
                    maxlength="255"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer text-start col-md-12">
  <div class="col-md-6">
    <button type="submit"
            class="btn btn-primary"
            [disabled]="comments.length==0"
            (click)="sendReminder()">
      Send
    </button>
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
