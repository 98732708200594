import {ChangeDetectorRef, Component, OnInit, TemplateRef,
  ViewChild, } from "@angular/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { LoginService } from "../../../Shared/Services/login.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SubSink } from "subsink";

@Component({
    selector: "nbc-idle",
    templateUrl: "./nbc-idle.component.html",
})
export class NbcIdleComponent implements OnInit {
    name = "Angular";
    idleState = "Not started.";
    timedOut = false;
    lastPing?: Date;
  logoutCountdown!: string;
  subs = new SubSink();
  
  @ViewChild("nbcIdleTemplate", { static: true })
  nbcIdleTemplate: TemplateRef<any>;
    constructor(
        private idle: Idle,
        private keepalive: Keepalive,
      private pingService: LoginService,
      private modalService: BsModalService
    ) {
        //total Idle time in seconds
        const TOTAL_IDLE_TIME = 45 * 60;

        //total timeout warning in seconds
        const TOTAL_TIMOUT_TIME = 15 * 60;

        //keep alive message frequency in seconds
        const KEEP_ALIVE_FREQUENCY = 3 * 60;

        // sets an idle timeout of 45 mins,
        idle.setIdle(TOTAL_IDLE_TIME);

        // sets a timeout period of 15 mins.
        idle.setTimeout(TOTAL_TIMOUT_TIME);

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        //idle.onIdleEnd.subscribe(() => this.closeSession());
        this.subs.sink = idle.onTimeout.subscribe(() => {
            this.idleState = "Timed out!";
            this.timedOut = true;
            this.closeSession();
        });
        this.subs.sink = idle.onIdleStart.subscribe(() => this.showWarningModal());
        this.subs.sink = idle.onTimeoutWarning.subscribe((countdown) => {
            if (countdown > 0) {
                const min = Math.floor(countdown / 60);
                const seconds = this.padLeft(countdown - min * 60, 2, null);
                this.logoutCountdown = min + ":" + seconds;
            }
        });

        // sets the ping interval to KEEP_ALIVE_FREQUENCY seconds
        keepalive.interval(KEEP_ALIVE_FREQUENCY);

        this.subs.sink = keepalive.onPing.subscribe(() => {
            this.lastPing = new Date();
            this.pingService.ping().subscribe();
        });

        this.reset();
    }

    padLeft(nr: any, n: any, str: any) {
        return new Array(n - String(nr).length + 1).join(str || "0") + nr;
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;

    const openModals = document.getElementsByTagName("modal-container");
    if (openModals?.length > 1) {
     
      const ele =
        document.querySelectorAll(".idle-modal")[0]?.parentElement;
      ele?.remove();
    } else {
      const ele =
        document.querySelectorAll(".idle-modal")[0]?.parentElement;
      document.body.removeAttribute("style");
      document.body.removeAttribute("class");
      ele?.remove();
    }   
    
  }

    closeSession() {
        // window.location.href = window.location.origin + "/home/logout";
        this.pingService.logout();
    }

  modalRef: BsModalRef;

    showWarningModal() {
        const modal = document.querySelector(".sessionModal");
        if (modal) {
            modal.classList.remove("d-none");
      }


      const modalOptions = {
        animated: true,
        class: "idle-modal",
        backdrop: false,
        keyboard: false,
        ignoreBackdropClick: true,
      };

      setTimeout(() => {
        document.querySelectorAll(
          ".idle-modal"
        )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
      }, 100);

      this.modalRef = this.modalService.show(
        this.nbcIdleTemplate,
        modalOptions
      );

    }

    hideWarningModal() {
        const modal = document.querySelector(".sessionModal");
        if (modal) {
            modal.classList.add("d-none");
        }
        this.modalService._hideBackdrop();
        this.modalService._hideModal();
    }

    ngOnInit(): void {
        // right when the component initializes, start reset state and start watching
        this.reset();
    }
}
