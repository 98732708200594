import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
//import { LiquidCache } from "ngx-liquid-cache";
/*import { ISelect } from 'src/app/Event/dashboard/eventlist.component'*/ @Injectable(
    {
        providedIn: "root",
    }
)
export class EventTypeService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    //   upsert  (eventType, logo): Observable<any> { {
    //     this.log.trace("upsert the event type");
    //     return Upload.upload({
    //         file: (!logo || logo.length < 1) ? null : logo[0],
    //         url: this._apiroot.baseUrl + "eventTypes/upsert",
    //         method: "POST",
    //         sendFieldsAs: "json-blob",
    //         fields: { eventType: JSON.stringify(eventType) },
    //         fileFormDataName: "uploadFile"
    //     });

    // };

    // this.upsert = function (eventType, logo) {
    //   log.trace("upsert the event type");
    //   return Upload.upload({
    //       file: (!logo || logo.length < 1) ? null : logo[0],
    //       url: apiRoot + "eventTypes/upsert",
    //       method: "POST",
    //       sendFieldsAs: "json-blob",
    //       fields: { eventType: JSON.stringify(eventType) },
    //       fileFormDataName: "uploadFile"
    //   });

    // };

    upsert(eventType: any, logo: any): Observable<any> {
        const formData = new FormData();
        formData.append("eventType", JSON.stringify(eventType));
        formData.append("file", logo);

        {
            JSON.stringify(eventType);
        }
        this.log.trace("Save Event Type");
        return this.http
            .post(this._apiroot.baseUrl + "eventTypes/upsert", formData)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getEventTypesById(id: any): Observable<any> {
        this.log.trace("get an event type by id");
        return this.http.get(this._apiroot.baseUrl + "eventTypes/" + id).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    //@LiquidCache("getEventTypes")
    getEventTypes(eventTypeQuery: any): Observable<any> {
        this.log.debug(
            "url : " + this._apiroot.baseUrl + "eventTypesWithQuery"
        );
        return this.http
            .post<any>(
                this._apiroot.baseUrl + "eventTypesWithQuery",
                eventTypeQuery,
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                    }),
                }
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    //@LiquidCache('getEventTypes')
    // - Created new function to use in Admin screen without Cache
    getAllEventTypes(eventTypeQuery: any): Observable<any> {
        this.log.debug(
            "url : " + this._apiroot.baseUrl + "eventTypesWithQuery"
        );
        return this.http
            .post<any>(
                this._apiroot.baseUrl + "eventTypesWithQuery",
                eventTypeQuery,
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                    }),
                }
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    searchByEventTypes(term: any): Observable<any> {
        this.log.trace("Get evevnt names for event: " + term);
        return this.http
            .get(
                this._apiroot.baseUrl + "eventTypes/searchByEventTypes/" + term
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
