<div class="tab-content ng-scope">
  <div class="page-container"
       style="padding-left:0;padding-right:0;">
    <div class="container-fluid">

      <div class="row"
           authenticate-user="remotesvenuesetup.retrieve">
        <div class="col-md-12 mainheader">
          <div class="header-bar clearfix">
            <p class="heading-title pull-left"></p>

            <button type="button"
                    class="btn btn-primary pull-right"
                    (click)="openAddVenueModal(true,'')"
                    authenticate-user="remotesvenue.create">
              + Add Venue
            </button>
          </div>
          <div class="content-filters">
            <div class="row">
              <div class="col-sm-6 venuegrid">
                <form class="form-inline">
                  <div class="form-group">
                    <input name="venueSearch"
                           [(ngModel)]="venuesearchTerm"
                           [typeahead]="venueObservable$"
                           [typeaheadAsync]="true"
                           [typeaheadOptionsLimit]="10"
                           typeaheadOptionField="name"
                           class="form-control"
                           [isAnimated]="true"
                           [typeaheadWaitMs]="200"
                           placeholder="Venue Name"
                           (typeaheadOnSelect)="onVenueSelected($event)">

                    <input id="citysearch"
                           name="citysearch"
                           class="form-control"
                           maxlength="60"
                           [(ngModel)]="citysearchTerm"
                           placeholder="City"
                           [typeaheadAsync]="true"
                           [typeaheadOptionsLimit]="10"
                           typeaheadOptionField="city"
                           [typeahead]="cityObservable$"
                           [isAnimated]="true"
                           [typeaheadWaitMs]="200"
                           (typeaheadOnSelect)="onCitySelected($event)">
                    <button type="submit"
                            class="btn btn-secondary"
                            (click)="customSearch()">
                      Search
                    </button>
                    <button type="button"
                            class="btn btn-default"
                            (click)="clearSearch()">
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-page-content">
        <div class="row view-options"
             *ngIf="resultsFound">
          <div class="col-md-4">
            <div class="form-inline form-sort-options">
              <div class="form-group venuepaging">
                 <nbc-page-size [source]='pageSizeList'
                             [model]="queryContainer.query.pageSize"
                             (nbcValueChange)="searchPage($event)"></nbc-page-size>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group venuestat d-flex">
              <label class="pagination-results ms-auto">
                <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                  currentPage="{{queryContainer.query.currentPage}}}"
                                  totalRecords="{{queryContainer.query.totalRecords}}"></pagination-label>

              </label>

              <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                              itemsPerPage="{{queryContainer.query.pageSize}}"
                              [(currentPage)]="queryContainer.query.currentPage"
                              (pageChanged)="search()"></nbc-pagination>

            </div>
          </div>
        </div>
        <div class="row"
             *ngIf="resultsFound">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="dark-header">
                  <tr>
                    <th class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('name');$event.preventDefault();">
                        Venue Name

                        <span class="th-sort">

                          <i class="fa fa-sort" *ngIf="queryContainer.query.sortByColumn !== 'name'"></i>
                          <span *ngIf="queryContainer.query.sortByColumn === 'name'">
                            <i *ngIf="!queryContainer.query.reverseSort" class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort" class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>

                    <th style=" cursor:default;"
                        class="col-md-3 default-header">STREET ADDRESS</th>

                    <th class="col-md-2"
                        style="padding-left: 5px;">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('city');$event.preventDefault();">
                        City
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="queryContainer.query.sortByColumn != 'city'"></i>
                          <span *ngIf="queryContainer.query.sortByColumn == 'city'">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>
                    <th class="col-md-1">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('state');$event.preventDefault();">
                        State
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="queryContainer.query.sortByColumn !== 'state'"></i>
                          <span *ngIf="queryContainer.query.sortByColumn === 'state'">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>

                    <th style="cursor:default;"
                        class="col-md-1 default-header">POSTAL CODE</th>
                    <th class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('country');$event.preventDefault();">
                        Country
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="queryContainer.query.sortByColumn !== 'country'"></i>
                          <span *ngIf="queryContainer.query.sortByColumn === 'country'">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>                    
                    <th class="col-md-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let venue of venueSetupDetails">
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;"
                        [hidden]="isSrcFromPositionModal">
                      {{venue.name}}
                    </td>
                    <td class="col-md-3 venueaddress"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{venue.streetAddress}}
                    </td>
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{venue.city}}
                    </td>
                    <td class="col-md-1"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{venue.state}}
                    </td>
                    <td class="col-md-1"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{venue.zip}}
                    </td>
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{venue.country}}
                    </td>                    
                    <td class="col-md-1 text-end">
                      <span class="edit-row">
                        <a href="javascript:void(0)"
                           class="btn btn-primary"
                           (click)="openAddVenueModal(false,venue)"
                           authenticate-user="remotesvenue.edit"
                           authenticate-command="disabled"><i class="fa-solid fa-pencil fa-lg"></i></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p *ngIf="!resultsFound">No Results.</p>
      </div>
    </div>
  </div>
</div>
