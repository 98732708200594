import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../Core/services/auth-guard.service";
import { DashboardComponent } from "./dashboard.component";

const routes: Routes = [
    { path: "dashboard2", component: DashboardComponent },
    { path: "", redirectTo: "/dashboard2", pathMatch: "full" },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRouterModule {
    static forChild() {
        return {
            ngModule: DashboardRouterModule,
            providers: [],
        };
    }
}
