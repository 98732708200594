import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";

@Component({
    selector: "nbc-page-size",
    templateUrl: "./page-size.component.html",
})
export class NbcPageSizeComponent implements OnInit, OnChanges {
    constructor() {}

    @Input() model: any | undefined;
    @Input() source: any[] | undefined;

    @Output() nbcValueChange = new EventEmitter<any>();
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    selectedSize: any;
    internalSource: any[] | undefined;

    optionSelected(e) {
        this.nbcValueChange.emit(e);
        this.model = _.find(
            this.internalSource,
            (x) => x === this.selectedSize
        );
        this.modelChange.emit(this.model);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                this.selectedSize = changes[propName]["currentValue"];
            }
        }
    }

    ngOnInit(): void {
        this.internalSource = this.source;
        this.selectedSize = this.model;
    }
}
