<div>
  <div class="modal-header">
    <h3 class="modal-title">{{modalTitle}}</h3>
    <button type="button"
            class="btn-close"
            (click)="closeModal()">
    </button>
  </div>
  <nbc-modal-alert></nbc-modal-alert>
  <form role="form"
        name="upsertNetworkGroupForm"
        novalidate>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="description"
                       class="control-label">Network Group</label>
                <div [ngClass]="{'admin-control-error': formValidity.groupName}">
                  <input type="text"
                         class="form-control"
                         placeholder=""
                         id="description"
                         name="description"
                         [(ngModel)]="networkGroup.name"
                         maxlength="40"
                         required>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group"
                   style="height:35px">
                <label class="control-label">TIERED?</label>
                <span *ngIf="isNetworkGroupInUse">
                  <bSwitch style="margin-left: 2%;"
                           [switch-on-text]="onText"
                           [switch-off-text]="offText"
                           [switch-on-color]="'success'"
                           [switch-off-color]="'danger'"
                           [switch-handle-width]="30"
                           [switch-size]="'mini'"
                           disabled="disabled"
                           [(ngModel)]="networkGroup.isNetworkGroupTiered"
                           name="tierinuse">
                  </bSwitch>

                </span>
                <span *ngIf="!isNetworkGroupInUse">
                  <bSwitch style="margin-left: 2%;"
                           [switch-on-text]="onText"
                           [switch-off-text]="offText"
                           [switch-on-color]="'success'"
                           [switch-off-color]="'danger'"
                           [switch-handle-width]="30"
                           [switch-size]="'mini'"
                           [(ngModel)]="networkGroup.isNetworkGroupTiered"
                           name="tiernotinuse">
                  </bSwitch>

                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12"
                 [ngClass]="{'admin-control-error': formValidity.networkPanel}">
              <div>
                <label class="control-label">Networks</label>
              </div>
              <div class="container form-group">
                <div class="row">
                  <div class="col-md-3"
                       *ngFor="let network of networks">
                    <label>

                      <input type="checkbox"
                             name={{network.id}}
                             [disabled]="network.isDisabled"
                             [(ngModel)]="network.isSelected"
                             (click)="onNetworkStateChanged($event,network)">
                      {{network.description}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
          </div>
          <div class="row"
               style="padding-top:10px;">
            <div class="col-md-8"
                 *ngIf="networkGroup.isNetworkGroupTiered">
              <span class="container">
                <label class="control-label">
                  Number of
                  Tiers
                </label>
              </span>
              <span class="form-group">
                <div>
                  <select name="nooftiers"
                          (change)="onTierCountChanged()"
                          class="form-control"
                          [(ngModel)]="networkGroup.numberOfTiers"
                          [ngClass]="{'admin-control-error': formValidity.numberOfTiers}">
                    <option [value]="0">Select Tiers</option>
                    <option *ngFor="let option of tiers"
                            [value]="option">
                      {{option}}
                    </option>
                  </select>

                </div>
              </span>
            </div>

            <div class="col-md-8"
                 *ngIf="!networkGroup.isNetworkGroupTiered">
              <span class="container">
                <label class="control-label">
                  Number of
                  Tiers
                </label>
              </span>
              <span class="form-group">
                N/A
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-default"
              (click)="closeModal()">
        Cancel
      </button>
      <button type="button"
              *ngIf="isAdd"
              class="btn btn-primary"
              id="btnSaveNetworkGroup"
              [disabled]="disableSave"
              (click)="disableSave || upsertNetworkGroup()">
        Save
      </button>
      <button type="button"
              *ngIf="!isAdd"
              class="btn btn-primary"
              id="btnSaveNetworkGroup"
              [disabled]="disableSave"
              (click)="disableSave || upsertNetworkGroup()">
        Update
      </button>
    </div>
  </form>
  <div></div>
</div>
<network-group-impact-analysis></network-group-impact-analysis>
