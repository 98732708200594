import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { ReportService } from "src/app/Shared/Services/report.service";
import { SubSink } from "subsink";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
    selector: "app-export-modal-customize-crew-work",
    templateUrl: "./export-modal-customize-crew-work.component.html",
    styleUrls: ["../../../../../assets/Content/app/RemotesReport.css"],
    encapsulation: ViewEncapsulation.Emulated,
})
export class ExportModalCustomizeCrewWorkComponent implements OnInit {
    constructor(
        private _reportService: ReportService,
        private bsModalRef: BsModalRef,
        private ngxService: NgxUiLoaderService
    ) {}
    eventId: any;
    departmentList: any[] = [];
    reportType: any;
    airStatusReportInfo: any;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    fileName: any;
    fileType: any;
    data: any;
    showDepartmentRowAll = true;
    showDepartmentEditRow = false;
    showDepartmentTagRow = false;
    showAlertMessage: any;
    airStatusReport: any;
    disp = false;
    user = window.preload.user;
    userName = this.user.firstName + " " + this.user.lastName;
    private subs = new SubSink();

    cancel() {
        this.bsModalRef.hide();
    }

    editRow(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentRowAll = false;
            this.showDepartmentEditRow = true;
            this.showDepartmentTagRow = false;
        }
    }

    confirmSelection(value: any) {
        if (value === "DepartmentRow") {
            this.showDepartmentEditRow = false;
            this.showSection(value);
        }
    }

    stateChanged(checked: any, value: any, row: any) {
        if (row === "DepartmentRow") {
            if (checked) {
                this.departmentList.push(value);
                this.airStatusReportInfo.department.push(value);
            }
            if (!checked) {
                this.departmentList.splice(
                    this.departmentList.indexOf(value),
                    1
                );
                this.airStatusReportInfo.department.splice(
                    this.airStatusReportInfo.department.indexOf(value),
                    1
                );
            }
        }
    }

    removeTag(item: any, row: any) {
        if (row === "DepartmentRow") {
            this.departmentList.splice(this.departmentList.indexOf(item), 1);
            this.airStatusReportInfo.department.splice(
                this.airStatusReportInfo.department.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("DepartmentRow");
        }
    }
    showSection(value: any) {
        if (value === "DepartmentRow") {
            if (
                !_.isUndefined(this.departmentList) &&
                this.departmentList.length > 0
            ) {
                this.showDepartmentRowAll = false;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = true;
            } else {
                this.showDepartmentRowAll = true;
                this.showDepartmentEditRow = false;
                this.showDepartmentTagRow = false;
            }
        }
    }
    airStatusDetails() {
        // log.trace("exportReport");
        // usSpinnerService.spin("genericSpinner");
        this.subs.sink = this._reportService
            .getDepartmentDetails(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data.status === 204) {
                        this.showAlertMessage = true;
                        // this.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.airStatusReport = data;
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    // this.alerts = [{ type: "danger", msg: "Error Occurred" }];
                },
            });
    }
    exportReport() {
        // usSpinnerService.spin("genericSpinner");

        this.ngxService.startBackground();
        const tempAirStatusReportInfo: any = {};
        tempAirStatusReportInfo["department"] =
            this.airStatusReportInfo.department;

        this.subs.sink = this._reportService
            .exportCrewWorkScheduleReport(
                this.eventId,
                tempAirStatusReportInfo,
                this.userName,
                this.reportType
            )
            .subscribe({
                next: (data) => {
                    if (data["status"] === 204) {
                        this.showAlertMessage = true;
                        // $scope.alerts = [{ type: "danger", msg: "There are no data points to export for this report at the moment." }];
                    } else {
                        this.isDownload = true;
                        this.child.fileName =
                            "Event-" + this.eventId + "-Crew Work Schedule";
                        this.child.fileType = this.reportType;
                        this.child.data = data;
                        this.child.download();
                        this.ngxService.stopBackground();
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.ngxService.stopBackground();
                    // $scope.alerts = [{ type: "danger", msg: "Error Occurred" }];
                },
            });
    }
    closeAlert() {
        this.showAlertMessage = false;
    }
    ngOnInit(): void {
        this.reportType = this.reportType;
        this.airStatusDetails();
        this.airStatusReportInfo = {};
        this.airStatusReportInfo.department = [];
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
