import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { noop, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private log: NGXLogger,
    private http: HttpClient,
    private _apiroot: BaseUrlHelperService,) { }
 
  logout(): Observable<any> {
    this.log.trace("logout() ");
    return this.http.post(this._apiroot.baseUrl + "home/logout", null);
  }

  ping(): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "ping");
  }
}
