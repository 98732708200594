<header id="col-main-hdr"><h1>Tier Rate Card</h1></header>
<div class="olympics-setup">
  <nav class="breadcrumb">
    <ul>
      <li><a href="/Remotes/tierratecard" title="Tier Rate Card" class="btn-link">Tier Rate Card</a></li>
    </ul>
  </nav>

  <div class="page-container">

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 wizard-setup">
          <div class="row">

            <div class="col-md-12">

              <div class="Row" id="wizard_step1" *ngIf="wizard_step1">
                <div class="col-md-12 ps-3 pe-3">
                  <div class="col-md-12 wizard-layout ">
                    <div class="Row d-flex">
                      <div class="col-md-12 ps-3 pe-3">
                        <div class=" pull-left">
                          <div *ngIf="networkGroups?.length>0" class="dropdown"
                               style="width:160px;"
                               authenticate-user="TierRateCard.Update" authenticate-command="disabled">
                            <nbc-dropdown [source]="networkGroups"
                                          placeholder="Select Group"
                                          [(model)]="query.networkGroupId"
                                          display="description"
                                          value="id"
                                          nbcSize="w-100"
                                          (nbcOnOptionSelect)="onNetworkChange()"></nbc-dropdown>
                          </div>
                        </div>
                        <div class="pull-right" *ngIf="groupConfig">
                          <span *ngIf="groupConfig?.numberOfTiers?true:false"
                                 class="wizard-networkGroup-details">{{groupConfig.numberOfTiers}} Tiers</span>
                        </div>
                      </div>
                    </div>
                    <div class="Row">
                      <div class="col-md-12 ps-3 pe-3">
                        <span class="wizard-networkgroup-header">{{participatingNetworks}}</span>
                      </div>
                    </div>
                    <div class="Row">

                      <div class="simpleDemo row">
                        <div class="col-md-12 testBorder ">
                          <div>

                          </div>
                          <div class="row">
                            <div *ngFor="let list of groupConfig?.eventTiers"
                                 class="col-md-2"
                                 [ngClass]="{'d-none': $index >=6}">
                              <h3 *ngIf="list.tier!=null" class="wizard-tier-title" style="margin-top:20px;margin-bottom:10px;"> {{list?.tier?.desc}}</h3>
                              <h3 *ngIf="(list.tier===null)" class="wizard-tier-title" style="text-align:center;margin-top:20px;margin-bottom:10px;">Event Type</h3>
                              <div class="wizard-tier-lane">
                                <ul dragula="eventTypes"
                                    class="{{list?.tier?.desc}}"
                                    name="{{list?.tier?.desc}}"
                                    [(dragulaModel)]="list.eventTypes"
                                    id="{{list?.tier?.id}}"
                                    dnd-list="list.eventTypes">
                                  <li *ngFor="let item of list.eventTypes"
                                      [ngClass]="{'selected': models?.selected === item}">
                                    <span class="event-type1">
                                      <img class="img-thumbnail"
                                           title="{{item.name}}"
                                           src="/assets/image/{{item.type}}/{{item.imageId || 0}}" />
                                    </span>
                                  </li>
                                </ul>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Row" id="wizard_step2">
                <div class="col-md-12" *ngIf="groupConfig">
                  <tierPositionWizardTemplateComponent
                                                       [isTabVisible]="visibleStep2"
                                                       [networkConfig]="groupData">
                  </tierPositionWizardTemplateComponent>
                 </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="wizard-actionItems">
                    <input type="button" id="wizard_nextStep" class="btn btn-primary ng-binding pull-right" *ngIf="wizard_nextStepShow" value="Next" (click)="nextStep()" />

                    <input type="button" id="wizard_previousStep" class="btn btn-primary ng-binding pull-right" *ngIf="wizard_previousStepShow" value="Back" (click)="previousStep()" />
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<tier-ratecard-impact-analysis></tier-ratecard-impact-analysis>
