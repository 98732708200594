<div class="modal-header">

  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
</div>
<form #positionTemplateForm="ngForm">
  <div class="modal-body">
    <nbc-alert></nbc-alert>
    <div class="form-horizontal container">
      <div class="form-group row"
           [ngClass]="{'has-error':templateName.invalid && submitted}">
        <label for="templateName"
               class="col-md-3 control-label">Template Name</label>
        <div class="col-md-9 p-0">
          <input type="text"
                 id="templateName"
                 name="templateName"
                 appInputRestriction="restrictChars"
                 class="form-control"
                 [(ngModel)]="model.name"
                 #templateName="ngModel"
                 maxlength="100" required />
          <span *ngIf="templateName.invalid && submitted" class="control-label control-label-error">Please enter a template name.</span>
        </div>
      </div>
      <div class="form-group row"
           [ngClass]="{'has-error':model.eventType.id==0 && submitted}" *ngIf="eventTypes && eventTypes.length>0">
        <label for="eventType" class="col-md-3 control-label">Event Type</label>
        <div class="col-md-9 p-0">
          <nbc-dropdown id="eventType"
                        name="eventType"
                        [(model)]="model.eventType.id"
                        [source]="eventTypes  | orderBy :['name']"
                        display="name"
                        value="id"
                        nbcSize="w-100"
                        placeholder="Select Event Type"
                        [nbcRequired]="true">
          </nbc-dropdown>
          <span *ngIf="model.eventType.id==0 && submitted" class="control-label control-label-error">Please select an event type.</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 control-label">Level</label>
        <div class="col-md-9 p-0">
          <div class="btn-group">
            <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;" [ngClass]="model.level==1?'active':''" [(ngModel)]="model.level" [btnRadio]="1" tabindex="0" role="button" uncheckable>1</button>
            <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;" [ngClass]="model.level==2?'active':''" [(ngModel)]="model.level" [btnRadio]="2" tabindex="0" role="button" uncheckable>2</button>
            <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;" [ngClass]="model.level==3?'active':''" [(ngModel)]="model.level" [btnRadio]="3" tabindex="0" role="button" uncheckable>3</button>
          </div>

        </div>
      </div>      
        <div class="form-group">          
          <div class="col-md-9 offset-md-3 p-0">
            <button type="submit"
                    class="btn btn-primary"
                    [disabled]="!positionTemplateForm.form.valid || isRequestInProcess"
                    (click)="addNewTemplate(positionTemplateForm.valid)">
              {{modalSubmit}}
            </button>
            <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
          </div>
        </div>
      </div>
  </div>
</form>
