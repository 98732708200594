import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";


@Component({
  selector: "schedule-conflict",
  templateUrl: "./modal-schedule-conflict.component.html"
})
export class ModalScheduleConflictComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private log: NbcLoggerService) { }

  public onAffirmation!: Subject<any>;
  public onRejection !: Subject<any>;

  itemConflict: any | undefined;

  checkingConflictDetail: boolean = false;


  isWorkScheduleConflict: boolean = false;

  cancelText ="Close"


  hideAssignBtn: boolean = false;

  assign() {
    this.log.trace("contact assigned");
    this.onAffirmation.next(true);
    this.bsModalRef.hide();
  };


  cancel() {
    this.log.trace("cancelled modal");
    this.onRejection.next(false);
    this.bsModalRef.hide();
  };

  ngOnInit() {
    this.onAffirmation = new Subject();
    this.onRejection = new Subject();

    this.itemConflict = this.itemConflict;
    this.checkingConflictDetail = this.checkingConflictDetail;
    this.isWorkScheduleConflict = this.isWorkScheduleConflict;
    this.hideAssignBtn = this.hideAssignBtn;

    this.cancelText = this.checkingConflictDetail ? "Close" : "No, Cancel" ;
  }
}


