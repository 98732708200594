<div class="modal-header ng-scope">
  <h4 *ngIf="isAdd"
      class="modal-title">
    Add New Venue
  </h4>
  <h4 *ngIf="!isAdd"
      class="modal-title">
    Edit Venue
  </h4>
  <button type="button"
          class="btn-close"
          (click)="closeModal()">
  </button>
</div>
<form role="form"
      name="addVenueForm"
      #addVenueForm="ngForm">
  <div class="modal-body">

    <nbc-modal-alert></nbc-modal-alert>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Venue Name<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="form-group">

          <input #venueName="ngModel"
                 name="venueName"
                 type="text"
                 required
                 maxlength="60"
                 class="form-control"
                 [(ngModel)]="venue.name">
          <span class=" control-label control-label-error"
                *ngIf="venueName.invalid && submitted">Please enter venue name.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Street Address<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-10">
        <div class="form-group">
          <textarea #streetAddress="ngModel"
                    required
                    style="resize:none;width: 400px;"
                    name="streetAddress"
                    [(ngModel)]="venue.streetAddress"
                    cols="50"
                    rows="3"
                    maxlength="120"
                    appInputRestriction="restrictChars"></textarea>
          <span class=" control-label control-label-error"
                *ngIf="streetAddress.invalid && submitted">Please enter street address.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">City<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 #city="ngModel"
                 name="city"
                 required
                 maxlength="40"
                 class="form-control"
                 [(ngModel)]="venue.city"
                 authenticate-command="disabled">
          <span class=" control-label control-label-error"
                *ngIf="city.invalid && submitted">Please enter city name.</span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Postal Code</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">

          <input name="postalCode"
                 type="text"
                 id="postalCode"
                 appInputRestriction="noSpecialChars"
                 class="form-control"
                 [(ngModel)]="venue.zip"
                 maxlength="10" />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Country<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">


          <select #country="ngModel"
                  name="country"
                  class="form-select"
                  [(ngModel)]="venue.countryCode"
                  required
                  (change)='setStates(country.value)'>
            <option value="">Select Country</option>
            <option *ngFor="let country of countries"
                    [ngValue]="country.isoCode">
              {{country.name}}
            </option>
          </select>



          <!-- <nbc-dropdown  #venueName="ngModel" [source]="countries" [(model)]="venue.countryCode" name="country"
                                  display="name" value="isoCode"
                                  (nbcOnOptionSelect)="setStates($event)"
                                  placeholder="Select Country">
                    </nbc-dropdown> -->





          <span class=" control-label control-label-error"
                *ngIf="country.invalid && submitted">Please select a country.</span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">State</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div *ngIf="venue.countryCode === 'US'">
            <select #state="ngModel"
                    name="state"
                    class="form-select" required
                    [(ngModel)]="venue.stateCode">
              <option value="">--Select--</option>
              <option *ngFor="let state of states"
                      [ngValue]="state.code">
                {{state.name}}
              </option>
            </select>
            <span class=" control-label control-label-error"
                  *ngIf="state.invalid && submitted">Please select a state.</span>
          </div>
          <input #state="ngModel"
                 name="state"
                 type="text"
                 appInputRestriction="restrictChars"
                 class="form-control"
                 [(ngModel)]="venue.state"
                 maxlength="50"
                 *ngIf="venue.countryCode != 'US'">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Venue Phone Number</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 (UpdateNumberModelValue)="onMyCustomEvent()"
                 phoneFormatOnly
                 name="venuePhoneNumber"
                 maxlength="50"
                 class="form-control"
                 [(ngModel)]="venue.venuePhoneNumber">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Website</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input #website="ngModel"
                 name="website"
                 maxlength="50"
                 class="form-control"
                 [(ngModel)]="venue.website"
                 placeholder="www.google.com">
          <span class=" control-label control-label-error"
                *ngIf="website.invalid && submitted">Not a valid website address.</span>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Longitude<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 #longitude="ngModel"
                 name="longitude"
                 maxlength="20"
                 class="form-control"
                 [(ngModel)]="venue.longitude"
                 authenticate-command="disabled"
                 required>
          <span class=" control-label control-label-error"
                *ngIf="longitude.invalid && submitted">Please enter longitude.</span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Latitude<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 #latitude="ngModel"
                 name="latitude"
                 maxlength="20"
                 class="form-control"
                 [(ngModel)]="venue.latitude"
                 authenticate-command="disabled"
                 required>
          <span class=" control-label control-label-error"
                *ngIf="latitude.invalid && submitted">Please enter latitude.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Time zone<label class="lblreq">*</label></label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">



          <select #timeZone="ngModel"
                  class="form-select"
                  name="timeZone"
                  [(ngModel)]="venue.timeZone">
            <option value="">Select a Time Zone</option>
            <option *ngFor="let timeZoneId of timeZones"
                    [ngValue]="timeZoneId">
              {{timeZoneId}}
            </option>
          </select>



          <span class=" control-label control-label-error"
                *ngIf="timeZone.invalid && submitted">Please select a timezone.</span>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Note</label></div>
        </div>
      </div>
      <div class="col-md-10">
        <div class="form-group">
          <textarea class="form-control"
                    style="resize:none;"
                    res
                    name="noteField"
                    [(ngModel)]="venue.noteField"
                    rows="3"
                    maxlength="256"
                    appInputRestriction="restrictChars"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <div class="form-group">
          <div><label class="control-label"><b>Associate Information</b></label></div>
          <span class=" control-label control-label-error"
                *ngIf="firstName.invalid && submitted">
            Enter FirstName and LastName to Save Associate
            information
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">First Name</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 #firstName="ngModel"
                 name="firstName"
                 maxlength="30"
                 appInputRestriction="restrictChars"
                 class="form-control"
                 [(ngModel)]="venue.firstName">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Last Name</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 #lastName="ngModel"
                 name="lastName"
                 maxlength="30"
                 appInputRestriction="restrictChars"
                 class="form-control"
                 [(ngModel)]="venue.lastName">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Job Description</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 name="jobDescription"
                 maxlength="30"
                 class="form-control"
                 appInputRestriction="restrictChars"
                 [(ngModel)]="venue.jobDescription">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Contact Email</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div class="form-group">

            <input type="email"
                   class="form-control"
                   pattern="{{emailRegex}}"
                   id="contactEmail"
                   name="contactEmail"
                   [(ngModel)]="venue.contactEmail"
                   #emailref="ngModel">
            <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
              <div [hidden]="!emailref.errors?.pattern">
                <span class="control-label control-label-error">Not a valid email Id.</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Office Number</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 (UpdateNumberModelValue)="onMyCustomEvent()"
                 #officeNumber="ngModel"
                 name="officeNumber"
                 phoneFormatOnly
                 maxlength="17"
                 class="form-control"
                 [(ngModel)]="venue.officeNumber">
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div><label class="control-label">Mobile Number</label></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input type="text"
                 (UpdateNumberModelValue)="onMyCustomEvent()"
                 #MobileNumber="ngModel"
                 name="MobileNumber"
                 phoneFormatOnly
                 maxlength="17"
                 class="form-control"
                 [(ngModel)]="venue.mobileNumber">
        </div>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-default"
            (click)="closeModal()">
      Cancel
    </button>
    <button type="button"
            *ngIf="isAdd"
            class="btn btn-primary"
            id="btnSaveVenue"
            authenticate-command="disabled"
            authenticate-user="remotesvenue.create"
            (click)="saveVenueDetails(venue,addVenueForm)">
      Save
    </button>
    <button type="button"
            *ngIf="!isAdd"
            class="btn btn-primary"
            id="btnUpdateVenue"
            authenticate-command="disabled"
            authenticate-user="remotesvenue.edit"
            (click)="saveVenueDetails(venue,addVenueForm)">
      Update
    </button>
  </div>
  <!--<nbc-form-controls [ngForm]="addVenueForm"></nbc-form-controls>-->
</form>
