<div>
  <form role="form" name="minimumWageForm" novalidate>
    <div class="content-filters">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label"
                   style="display:inline;">State</label>

            <input type="text"
                   class="form-control"
                   style="width:50%;display:inline;margin-left:5%;"
                   [(ngModel)]="searchQuery"
                   id="name"
                   name="name"
                   maxlength="36"
                   [typeahead]="EventSource"
                   [typeaheadMinLength]="2"
                   [typeaheadWaitMs]=200
                   (keyup)="typeaheadOnSelect($event)"
                   autocomplete="off"
                   placeholder=''>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="filter-actions">
            <button class="btn btn-secondary"
                    [disabled]="searchQuery== null || searchQuery=='' "
                    (click)="searchTypeHead(searchQuery);"
                    tabindex="8">
              Search
            </button>
            <button class="btn btn-default"
                    (click)="reset();"
                    tabindex="9">
              Reset
            </button>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="tab-action-btns form-group">
            <a href="javascript:void(0)"
               class="btn btn-primary"
               (click)="openUpserMinimumWageModal(null)">+ADD NEW WAGE</a>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-page-content">
      <div style="padding-top:10px;"
           class="row view-options"
           *ngIf="resultsFound">
        <div class="col-md-4">
          <div class="form-inline form-sort-options">
            <div class="form-group pagination-results">
              <nbc-page-size [source]='pageSizeList'
                             [model]="queryContainer.query.pageSize"
                             (nbcValueChange)="searchPage($event)"></nbc-page-size>

            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group text-right venuestat d-flex">
            <label class="pagination-results ms-auto">
              <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                currentPage="{{queryContainer.query.currentPage}}}"
                                totalRecords="{{queryContainer.query.totalRecords}}">
              </pagination-label>
            </label>
            <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                            itemsPerPage="{{queryContainer.query.pageSize}}"
                            [(currentPage)]="queryContainer.query.currentPage"
                            (pageChanged)="search()"></nbc-pagination>
          </div>
        </div>
      </div>
      <div class="row"
           NgIf="resultsFound">
        <div class="col-md-12">
          <div>
            <table class="table table-striped">
              <thead class="dark-header"
                     id="headerDiv"
                     #stickyMenu>
                <!--[class.stickyTableHeader]="sticky"-->

                <tr>
                  <th style="cursor:default;"
                      class="col-md-2">STATE</th>
                  <th style="cursor:default;"
                      class="col-md-2">CITY</th>
                  <th style="cursor:default;"
                      class="col-md-2">START DATE</th>
                  <th style="cursor:default;"
                      class="col-md-1">END DATE</th>
                  <th style="cursor:default;"
                      class="col-md-2">MINIMUM WAGE</th>
                  <th style="cursor:default;"
                      class="col-md-2">STATUS</th>
                  <th style="cursor:default;"
                      class="col-md-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let wage of allWages">
                  <td class="col-md-2">{{wage.state}}</td>
                  <td class="col-md-2">{{wage.city}}</td>
                  <td class="col-md-2">{{wage.startDate | date:"MMM d, yyyy"}}</td>
                  <td class="col-md-2">{{wage.endDate | date:"MMM d, yyyy"}}</td>
                  <td class="col-md-2">{{wage.wage}}</td>
                  <td class="col-md-2">
                    <bSwitch name="{{wage.active}}"
                             [switch-on-text]="onText"
                             [switch-off-text]="offText"
                             [(ngModel)]="wage.active"
                             [switch-on-color]="'success'"
                             [switch-off-color]="'danger'"
                             [switch-handle-width]="30"
                             [switch-size]="'mini'"
                             (click)="updateStatus(wage)">
                    </bSwitch>
                  </td>
                  <td class="col-md-2 text-right">
                    <span class="edit-row">
                      <a href="javascript:void(0)"
                         class="btn btn-primary"
                         (click)="openUpserMinimumWageModal(wage)"><i class="fa-solid fa-pencil fa-lg"></i></a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-left:1%;"
         *ngIf="!resultsFound">No Results.</div>

  </form>
</div>
