import { Component, OnInit, OnDestroy } from "@angular/core";
import { EventTypeService } from "../Shared/Services/eventType.service";
import { DashboardService } from "../Shared/Services/dashboard.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "rem-myevents",
    templateUrl: "./myevents.component.html",
})
export class MyEventsComponent implements OnInit, OnDestroy {
    constructor(
        private _eventTypeService: EventTypeService,
        private _dashboardService: DashboardService,
        private router: Router,
        private log: NbcLoggerService
    ) {}

    model: any | undefined;

    componentDestroyed$: Subject<boolean> = new Subject();

    myEventsComplete: boolean | undefined;

    goto(eventId: number) {
        this.log.debug("goto" + eventId);
        this.router.navigate(["Events/Event", eventId]);
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
