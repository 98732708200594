import { Component, OnInit, OnDestroy } from "@angular/core";
import { DashboardService } from "../.././/Shared/Services/dashboard.service";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { SharedDataService } from "../.././/Shared/sharedData.service";
import { NbcLoggerService } from "../.././/Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PermissionUtility } from "../../Core/services/permission-utility.service";

@Component({
    selector: "app-dashboard-pendingtimecards",
    templateUrl: "./dashboard-pendingtimecards.component.html",
})
export class DashboardPendingTimecardsComponent implements OnInit, OnDestroy {
    constructor(
        private _dashboardService: DashboardService,
        private router: Router,
        private _sharedDataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();

    pendingTimecards: any | undefined;
    allpendingTimecards: any | undefined;
    pendingTimecardsComplete: boolean | undefined;
    fullscreenMode = false;

    defaultOrderBy = "";
    defaultReverseSort = false;
    displayCount = 10;

  queryContainer: any = { "query": {} };

  sort = {
    orderByField: this.defaultOrderBy,
    reverseSort: this.defaultReverseSort
  };

  getValidationPendingListingQuery() {
    return {
      "sortByColumn": "dateWorked",
      "reverseSort": false
    };
  }

  sortByColumn(column) {
    this.log.trace("Sort By Column");
    if (this.queryContainer.query.sortByColumn === column) {
      this.queryContainer.query.reverseSort = !this.queryContainer.query.reverseSort;
    } else {
      this.queryContainer.query.sortByColumn = column;
      this.queryContainer.query.reverseSort = false;
    }
    this.getPendingTimecards();
  };

  getPendingTimecards() {

    this.log.trace("Logging from new logger");
    this.log.debug("GetPendingTimecards -> get:fetching data");

        //this.recentlyViewed.sortBy = "SortOrder";
        //getPayrollQuery(): any {
        //  return {
        //    eventId: 0,
        //    timeCardDate: new Date(),
        //    pageSize: 25,
        //    sortBy: "SortOrder",
        //  }
        this.sort.orderByField = "";
        this.sort.reverseSort = this.defaultReverseSort;

        this._dashboardService
          .GetPendingTimecards(this.queryContainer.query)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.allpendingTimecards = data;
                    this.log.debug("GetPendingTimecards ->  received");
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load the dashboard at this time. Please try again later."
                    );
                },
                () => {
                    this.pendingTimecardsComplete = true;
                }
            );
    }

    hideSection(section: any) {
        const sectionrow = document.getElementById(section);

        if (sectionrow != null) {
            sectionrow.classList.add("dashboardhide");
        }
    }

    ngOnInit(): void {
        const user = window.preload.user;

        const access = PermissionUtility.PermissionUtilities();
        const hasAccess = access.hasAccess(
            user,
            user.allFeatures,
            ["dashboard.pendingtimecards.retrieve"],
            null,
            null,
            null
        );

        if (!hasAccess) this.hideSection("timecardrow");
        else if (!user.isSuperUser) this.hideSection("bottomrow");

        this.queryContainer.query = this.getValidationPendingListingQuery();
        this.getPendingTimecards();
  }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
