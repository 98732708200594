import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { catchError } from "rxjs/operators";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";

@Injectable({
    providedIn: "root",
})
export class positionTemplateService {
    constructor(
        private log: NGXLogger,
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService
    ) {}

    /*
     * Generic method to return the collection of the tier network setup
     */

    getTemplateByEventTypeId(eventTypeId: any): Observable<any> {
        this.log.trace(
            "retrieving position position template by event type id " +
                eventTypeId
        );
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "positionTemplate/" +
                    eventTypeId +
                    "/active"
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
