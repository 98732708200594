import { Component, OnDestroy, OnInit } from "@angular/core";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { pipe, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { debug } from "console";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { tierSetupWizardController } from "src/app/Shared/Services/tierSetupWizardController.service";
import { SharedDataService } from "../../Shared/sharedData.service";
import { DragulaService } from "ng2-dragula";
import {
    EmitEvent,
    EventBusService,
} from "../../Core/services/event-bus.service";
import { SubSink } from "subsink";
@Component({
    selector: "app-tierratecard",
    templateUrl: "./tierratecard.component.html",
    styleUrls: ["./tierratecard.component.css"],
})
export class TierRateCardComponent implements OnInit, OnDestroy {
    constructor(
        private _sharedDataService: SharedDataService,
        private _networkGroupService: networkGroupService,
        private _EventTypeService: EventTypeService,
        private _tierSetupWizardController: tierSetupWizardController,
        public _modalService: BsModalService,
        private dragulaService: DragulaService,
        private _eventBus: EventBusService,
        private log: NbcLoggerService
    ) {}

    private sub = new SubSink();

    componentDestroyed$: Subject<boolean> = new Subject();
    bsModalRef?: BsModalRef;
    subs = new Subscription();
    isChectDrag = false;
    eventTypes: any = [];
    remaining: any = [];
    groupConfig!: any;
    networkConfig!: any;
    participatingNetworks: any = "";
    wizard_nextStepShow = false;
    wizard_previousStepShow = false;
    wizard_step1 = true;
    wizard_step2 = false;
    visibleStep2 = false;
    groupData: any;
    query: any = {
        networkGroup: "",
        networkGroupId: 0,
    };

    networkGroups: any = [];

    getNetworkgroupConfig(networkId) {
        this._tierSetupWizardController
            .getNetworksetup(networkId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.groupConfig = result.reference;
                    this.groupConfig.eventTiers.unshift({
                        tier: null,
                        eventTypes: this.eventTypes,
                        effectAllowed: "none",
                    });

                    const arrParticipatingNetworks = _.map(
                        this.groupConfig.networks,
                        "description"
                    );
                    this.participatingNetworks = _.forEach(
                        arrParticipatingNetworks,
                        (n, key) => {
                            if (!_.isEmpty(this.participatingNetworks)) {
                                this.participatingNetworks =
                                    this.participatingNetworks + " , " + n;
                            } else {
                                this.participatingNetworks = n;
                            }
                        }
                    );

                    this.participatingNetworks = this.participatingNetworks
                        ? this.participatingNetworks.join(", ")
                        : "";

                    // this.$emit("networkGroupSelected", this.groupConfig);
                    this.filterEventTypes();

                    this.wizard_nextStepShow = true;
                    this.wizard_previousStepShow = false;
                },
                (err) => {
                    this.log.error(
                        "Unable to get network groups. Please try again later. "
                    );
                    this.log.showError(
                        "Unable to get network groups. Please try again later. "
                    );
                }
            );
    }

    /*
     * removing pre-associated event types from drag lane
     */
    filterEventTypes() {
        const associatedEventTypes: any = [];

        if (!this.groupConfig) return;

        // step 1 :find the event types already associated to any event tier
        _.map(this.groupConfig.eventTiers, function (eventTier) {
            _.map(eventTier.eventTypes, function (eventType) {
                if (
                    !_.includes(associatedEventTypes, eventType.id) &&
                    eventTier.tier
                ) {
                    associatedEventTypes.push(eventType.id);
                }
            });
        });

        // step 2 : remove the associated event types
        _.remove(this.eventTypes, function (eventType: any) {
            return _.includes(associatedEventTypes, eventType.id);
        });
    }

    getAllNetworks() {
        this._networkGroupService
            .tieredNetworkGroups()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.log.trace("got results: ");
                    this.networkGroups = result.reference;
                },
                (err) => {
                    this.log.error("getAllNetworks got an error: ");
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    //TODO

    //   $scope.$on("networkGroupSelected", function (event, networkConfig) {
    //     $scope.networkConfig = networkConfig;

    //     if (!$scope.networkConfig) {
    //         $("#wizard_nextStep").hide();
    //         $("#wizard_previousStep").hide();
    //     }
    //     else {
    //         $("#wizard_nextStep").show();
    //         $("#wizard_previousStep").hide();
    //     }

    // });

    nextStep() {
        this.wizard_step1 = false;
        this.wizard_step2 = true;
        this.wizard_nextStepShow = false;
        this.wizard_previousStepShow = true;
        this.visibleStep2 = true;
        this.groupData = _.cloneDeep(this.groupConfig);
        //instead of earlier code, passing the data to child control (next step) as a property of child control
    }

    previousStep() {
        this.wizard_step1 = true;
        this.wizard_step2 = false;
        this.wizard_nextStepShow = true;
        this.wizard_previousStepShow = false;
        this.networkConfig = null;
        this.visibleStep2 = false;
        this.groupData = null;
        this.onNetworkChange();
    }

    getNetworkConfig(networkGroupId) {
        this._networkGroupService
            .get(networkGroupId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.networkConfig = result.reference;
                    // $scope.$broadcast("networkConfiguration", $scope.networkConfig);  //TODO
                },
                (err) => {
                    this.log.error("Unable to save rate: ");
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    // Private Functions
    getEventTypes() {
        this._EventTypeService
            .getEventTypes("{}")
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));

                    this.eventTypes = result.reference;

                    if (this.eventTypes) {
                        this.eventTypes.sort(this.compare);
                    }

                    if (this.groupConfig && this.groupConfig.eventTiers) {
                        if (this.eventTypes) {
                            this.eventTypes = _.sortBy(this.eventTypes, [
                                function (o) {
                                    return o.name;
                                },
                            ]);
                        }
                        this.groupConfig.eventTiers.unshift({
                            tier: null,
                            eventTypes: this.eventTypes,
                            "effectAllowed ": "none",
                        });
                    }
                },
                (err) => {
                    this.log.error(
                        "Unable to load event types at this time. Please try again later.: "
                    );
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    //sort functionality
    compare(a, b) {
        if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase())
            return -1;
        if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase())
            return 1;
        return 0;
    }

    //function to update in the database.
    AssociateTier(networkGroupId, tierId, draggedElement) {
        this._networkGroupService
            .associateEventTypeWithTier(networkGroupId, tierId, draggedElement)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                },
                error: (err) => {
                    this.log.error(
                        "unable to associate the event type to the tier. Please try later "
                    );
                },
            });
    }

    // this.$watch('this.groupConfig.EventTypes', function (model) {
    //     this.modelAsJson = angular.toJson(model, true);
    //     console.log(this.modelAsJson);
    // }, true);

    // onDrop  (srcList, srcIndex, targetList, targetIndex) {

    //     //validate if the drag and drop has different destination
    //     var isDestinationPristine = this.isEqualObject(srcList, targetList);
    //     if (isDestinationPristine) {
    //         //no action needed as no change in both source and destination.
    //         return;
    //     }

    //     // var callback = this.EventTypeAssociatedToGroup();
    //     // $rootScope.$emit("onEventTypeAssociatedToGroup", srcList, srcIndex, targetList, targetIndex,this.groupConfig, callback);

    // };

    EventTypeAssociatedToGroup(sourceEle, targetEle, draggedElement) {
        // //validate if the drag and drop has different destination
        if (sourceEle.name === targetEle.name) return;

        if (draggedElement) {
            this.AssociateTier(
                this.query.networkGroupId,
                targetEle.id,
                draggedElement
            );
        }
        // By returning true from dnd-drop we signalize we already inserted the item.
        return true;
    }

    onEventTypeChangeCancelled(params) {
        //  this.getNetworkConfig(this.query.networkGroupId)
        const index = params?.targetModel?.indexOf(params?.item);
        params?.targetModel?.splice(index, 1);
        params?.sourceModel?.push(params.item);
    }

    ///utility to compare if lists are same or different.
    isEqualObject(objOne, objTwo) {
        return !!_([objOne]).filter(objTwo).size();
    }

    calldirective() {
        // $rootScope.$emit("initializeDropDownNetowkGroup", []);
    }

    onNetworkChange() {
        this.resetVariables();
        if (this.query.networkGroupId) {
            this.getEventTypes();
            this.getNetworkgroupConfig(this.query.networkGroupId);
        }

        if (!this.networkConfig) {
            this.wizard_nextStepShow = false;
            this.wizard_previousStepShow = false;
        } else {
            this.wizard_nextStepShow = true;
            this.wizard_previousStepShow = false;
        }
    }

    resetVariables() {
        this.groupConfig = null;
        this.participatingNetworks = "";
        //this.$emit("networkGroupSelected", null);  //TODO
    }
    initializeDragDrop() {
        this.dragulaService.createGroup("eventTypes", {
            revertOnSpill: true,
            accepts: function (el, target, source, sibling) {
                const targetEle: any = target;
                const sourceEle: any = source;

                //if target id is empty i.e. moving to parent bucket. Restrict it

                if (
                    _.isEmpty(targetEle.name) ||
                    sourceEle?.name === targetEle?.name
                )
                    return false;

                return true;
            },
        });

        this.subs.add(
            this.dragulaService
                .dropModel("eventTypes")
                .subscribe(
                    ({ target, source, sourceModel, targetModel, item }) => {
                        this.log.trace("drag and drop");
                        const params = {
                            source: source,
                            target: target,
                            sourceModel: sourceModel,
                            targetModel: targetModel,
                            item: item,
                            networkGroup: this.groupConfig,
                        };
                        this._eventBus.emit(
                            new EmitEvent(
                                "tierratecard-ondrop",
                                "onEventTypeAssociatedToGroup",
                                params
                            )
                        );
                    }
                )
        );
    }

    ngOnInit(): void {
        this._sharedDataService.setCss("tierratecard");
        this.sub.sink = this._eventBus.on(
            "onEventTypeProceedClicked",
            (params: any) => {
                this.EventTypeAssociatedToGroup(
                    params.source,
                    params.target,
                    params.item
                );
            }
        );
        this.sub.sink = this._eventBus.on(
            "onEventTypeChangeCancelled",
            (params: any) => {
                this.onEventTypeChangeCancelled(params);
            }
        );

        this.getEventTypes();
        this.getAllNetworks();
        this.initializeDragDrop();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
        this.sub.unsubscribe();
    }
}
