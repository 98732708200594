import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import "moment-timezone";
import { EventService } from "src/app/Shared/Services/event.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { EventTimesheetReportExportComponent } from "./component/nbc-export/event-timesheet-report-export.component";
import { ModalSubmitEventTimesheetComponent } from "./component/modal-submit-event-timesheet/modal-submit-event-timesheet.component";
import { SubSink } from "subsink";
import { NgForm } from "@angular/forms";
import { NbcModalDirtyCheckComponent } from "../../Shared/components/nbc-dirty-check/nbc-modal-dirtycheck.component";
import { ModalActivateTimesheetComponent } from "./component/activate-timesheet/modal-activate-timesheet.component";

@Component({
    selector: "app-time-sheet",
    templateUrl: "./timesheet.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSheetComponent
    implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
    constructor(
        private _payrollService: PayrollService,
        private _modalService: BsModalService,
        private _sharedDataService: SharedDataService,
        private _eventService: EventService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private log: NbcLoggerService
    ) {}
    //  @ViewChild('eventTimesheetForm', { static: true })  form!: NgForm;
    @ViewChildren("eventTimesheetForm")
    public forms!: QueryList<NgForm>;
    private eventTimesheetForm!: NgForm;

    isTabPanelEnabled = true;
    changeDetectionInterval: any;
    batchStatus = "";
    hasNewTabSelected = false;
    currentUser: any = window.preload.user;
    timesheetDates: any;
    bsModalRef1?: BsModalRef;
    bsActivateTSModalRef?: BsModalRef;
    bsSubmitTSModalRef?: BsModalRef;
    bsDirtyCheckModalRef?: BsModalRef;
    bsReportExportModalRef?: BsModalRef;
    filterApplied: any;
    date = new Date();
    eventId = window.preload.eventId;
    authorName =
        window.preload.user.firstName + " " + window.preload.user.lastName;
    isTimesheetSubmittable: any;
    // this flag is provided to avoid providing meal end when mean begin is not provided. MP throws an exception.
    isInvlidateLunchTimeProvided = false;
    isInvlidateTimeProvided = false;
    isTimesheetValidatedForSubmission = false;
    // activeTimesheetDate = new Date().toString()
    activeTimesheetDate: any = "";
    //moment(this.selectedDate).toString() === "Invalid Date" ? "" : moment(this.selectedDate);
    eventTimesheet: any;
    private subs = new SubSink();
    filterOptions = {
        sortBy: [
            {
                name: "Positions",
                value: "positions",
            },
            { name: "Employee Type", value: "employeeType" },
            { name: "Contacts (A-Z)", value: "lastName" },
            { name: "Contacts (Z-A)", value: "-lastName" },
        ],
    };

    //   /**
    //  * Sort By
    //  * @memberof score.controllers.EventCrewingController
    //  * @public
    //  * @type {Object[]}
    //  */
    positionCategories = [
        { id: "ATL", description: "ATL" },
        { id: "BTL", description: "BTL" },
    ];
    groupUpdateInvalid: any;
    showActivation = false;
    showButtonPanel = false;
    isNoMsg = false;
    contactList = [];
    //SCOR-10828
    fileType = "";
    originalTimesheet: any;
    groupUpdate = {
        status: false,
        selectAll: false,
        count: 0,
        submitted: false,
        options: [
            {
                name: "Time",
                value: "time",
            },
            {
                name: "Field Notes",
                value: "productionManagerComment",
            },
        ],
        updatedTime: "",
        updatedStartTime: "",
        updatedPayrollComment: "",
        selected: {},
    };
    headerTimesheetsDates: any[] = [];
    pageSize = [10, 25, 50, 100, 250];
    totalRecords = 0;
    eventTimesheetQuery: any;
    previousPayrollQuery: any;
    canSubmitTimesheet: any;
    eventData: any;
    crewWorkEndDate: any;
    crewWorkStartDate: any;
    activeTabData: any;
    previousDepartments = 0;
    previousPositionType = "";
    previousPositionCategory = null;
    previousSortBy = null;
    filterOrSortChangedPropName = "";
    sourcePositionTypeList: any[] = [];
    sourceDepartmentList = [
        { id: "Production", description: "Production" },
        { id: "Engineering", description: "Engineering" },
    ];

    addItem(event) {
        this.hasNewTabSelected = false;
        this.cd.detectChanges();
        this.hasNewTabSelected = true;
        this.activateTab(event);
    }
    check(item, item2?, item3?) {}

    getPayrollQuery() {
        this.eventTimesheetQuery = this._payrollService.getPayrollQuery();

        this.previousPayrollQuery = 1;
        this.eventTimesheetQuery.eventId = this.eventData.id;
        this.eventTimesheetQuery.timeCardDate = this.activeTimesheetDate;
        this.eventTimesheetQuery.positionType = "";
        this.eventTimesheetQuery.pageSize = 10000;
        // this.updateTimesheetDate()
    }

    submissionValidation(item) {
        if (!item.timeIn && !item.timeOut && !item.lunchIn && !item.lunchOut) {
            if (
                item.productionManagerComment &&
                item.productionManagerComment.length > 0
            ) {
                return true;
            }
        }

        if (this.calculateDays(item.timeIn, item.timeOut) === 0) {
            if (this.calculateDays(item.timeOut, item.lunchOut) > 0) {
                item.lunchOut = moment(item.lunchOut).add(-1, "days").toDate();
            }
            if (this.calculateDays(item.timeOut, item.lunchIn) > 0) {
                item.lunchIn = moment(item.lunchIn).add(-1, "days").toDate();
            }
        }
        return this.setItemFieldValidity(item);
    }

    submissionRateValidation(item) {
        if (
            item.eventPosition.employeeType.description != "NY" &&
            item.eventPosition.employeeType.description != "Staff" &&
            item.eventPosition.employeeType.description != "Stamford" &&
            item.eventPosition.employeeType.description != "Vendor"
        ) {
            if (item.rate != null) return false;
            else {
                return true;
            }
        } else {
            return false;
        }

        return true;
    }

    setItemFieldValidity(item) {
        const isValidInTime = this.timeInValidation(item);
        const isValidEndTime = this.timeOutValidation(item);
        let isValidLunchInTime = true;
        let isValidLunchOutTime = true;
        if (isValidInTime && isValidEndTime) {
            isValidLunchInTime = this.lunchInValidation(item);
            isValidLunchOutTime = this.lunchOutValidation(item);
        }
        if (!isValidInTime)
            this.eventTimesheetForm?.controls["timeIn_" + item.mpId].setErrors({
                required: false,
            });
        else
            this.eventTimesheetForm?.controls["timeIn_" + item.mpId].setErrors(
                null
            );

        if (!isValidEndTime)
            this.eventTimesheetForm?.controls["timeOut_" + item.mpId].setErrors(
                { required: false }
            );
        else
            this.eventTimesheetForm?.controls["timeOut_" + item.mpId].setErrors(
                null
            );

        if (!isValidLunchInTime)
            this.eventTimesheetForm?.controls["lunchIn_" + item.mpId].setErrors(
                { required: false }
            );
        else
            this.eventTimesheetForm?.controls["lunchIn_" + item.mpId].setErrors(
                null
            );

        if (!isValidLunchOutTime)
            this.eventTimesheetForm?.controls[
                "lunchOut_" + item.mpId
            ].setErrors({ required: false });
        else
            this.eventTimesheetForm?.controls[
                "lunchOut_" + item.mpId
            ].setErrors(null);

        if (this.eventTimesheetForm)
            item.invalidTime = !(
                isValidInTime &&
                isValidEndTime &&
                isValidLunchInTime &&
                isValidLunchOutTime
            );

        return (
            isValidInTime &&
            isValidEndTime &&
            isValidLunchInTime &&
            isValidLunchOutTime
        );
    }

    restartSearch() {
        // this.showButtonPanel = false;
        this.eventTimesheetQuery.currentPage = 1;
        this.previousPayrollQuery = _.cloneDeep(
            this.eventTimesheetQuery.currentPage
        );
        this.updateTimesheetDate();
    }

    onPositionTypeSelected($event) {
        if ($event?.id) {
            this.eventTimesheetQuery.positionType = $event;
        } else {
            this.eventTimesheetQuery.positionType = null;
        }
        this.restartSearch();
    }

    errorStatusChecking(status) {
        const stat = status.toLowerCase();
        if (status) {
            if (stat.indexOf("error||") > -1) {
                return "error";
            } else if (stat.indexOf("validation failed") > -1) {
                return "validation-failed";
            }
        }
        return status;
    }

    getValidationAttributes() {
        return {
            validation: {
                isIntimeValid: true,
                isOuttimeValid: true,
                isLunchInTimeValid: true,
                isLunchOutTimeValid: true,
            },
        };
    }

    updateTimesheetDate() {
        this.isTabPanelEnabled = false;
        this.showActivation = false;
        this.resetGroupUpdate();
        if (this.eventTimesheet && this.eventTimesheet.timesheets) {
            this.eventTimesheet.timesheets.items = null;
        }
        this.eventTimesheetQuery.positionType =
            this.eventTimesheetQuery?.positionType?.id <= 0
                ? null
                : this.eventTimesheetQuery.positionType;
        const query = _.cloneDeep(this.eventTimesheetQuery);
        query.sortBy = query.sortBy || "SortOrder";
        //workaround for null value
        const hasPositionCategoryFilter = this.eventTimesheetQuery
            .positionCategory
            ? true
            : false;
        const hasDepartmentFilter = this.eventTimesheetQuery.departments
            ? true
            : false;
        const hasPositionTypeFilter = this.eventTimesheetQuery.positionType
            ? true
            : false;

        this.cd.detach();
        this.subs.sink = this._payrollService
            .getEventTimesheet(query)
            .subscribe({
                next: (result) => {
                    this.batchStatus = "";
                    result.reference.payrollProviderStatus = true;
                    const updateValidation = this.getValidationAttributes();
                    _.forEach(
                        result.reference.timesheets.items,
                        (value, key) => {
                            this.batchStatus = value.batchStatus;

                            _.assign(value, updateValidation);
                            if (value.payrollProvider === "") {
                                result.reference.payrollProviderStatus = false;
                            }
                            this.contactList = result.reference.contactList;
                            value.id = key;
                            value.status = this.errorStatusChecking(
                                value.status
                            );
                            this.calculateMeal(value);
                        }
                    );

                    this.totalRecords =
                        result.reference.timesheets.totalRecords;
                    this.eventTimesheet = result.reference;

                    if (
                        (this.eventTimesheetQuery.departments === 0 ||
                            this.eventTimesheetQuery.departments === "" ||
                            this.eventTimesheetQuery.departments ===
                                undefined) &&
                        (this.eventTimesheetQuery.positionType === "" ||
                            this.eventTimesheetQuery.positionType === null) &&
                        (this.eventTimesheetQuery.positionCategory === null ||
                            this.eventTimesheetQuery.positionCategory === "" ||
                            this.eventTimesheetQuery.positionCategory === 0 ||
                            this.eventTimesheetQuery.positionCategory ===
                                undefined) &&
                        (this.eventTimesheetQuery.sortBy === undefined ||
                            this.eventTimesheetQuery.sortBy === 0 ||
                            this.eventTimesheetQuery.sortBy === null)
                    ) {
                        this.sourcePositionTypeList =
                            this.eventTimesheet.positionTypes;
                    }

                    this.originalTimesheet = _.cloneDeep(this.eventTimesheet);
                    this.isNoMsg = false;
                    this.filterApplied = false;

                    if (
                        this.eventTimesheet.timesheets.items.length === 0 &&
                        !hasPositionCategoryFilter &&
                        !hasDepartmentFilter &&
                        !hasPositionTypeFilter
                    ) {
                        this.showActivation = true;
                    } else {
                        this.showActivation = false;
                        this.isNoMsg = true;
                        if (
                            hasPositionCategoryFilter ||
                            hasDepartmentFilter ||
                            hasPositionTypeFilter
                        ) {
                            this.filterApplied = true;
                        }
                    }

                    const timeout =
                        (this.totalRecords > 0
                            ? this.totalRecords / 40 + 2
                            : 1) * 1000;
                    setTimeout(() => {
                        console.log("adding**");
                        //this.cd.reattach();
                        //this.cd.detectChanges();
                        this.setChangeDetectionInterval();
                    }, timeout);

                    //first load validation
                    // timeout logic--> total records * half second * 10 (as constant... trying out)
                    if (!this.showActivation)
                        setTimeout(
                            () => {
                                _.each(
                                    this.eventTimesheet.timesheets.items,
                                    (item) => {
                                        this.setItemFieldValidity(item);
                                    }
                                );
                            },
                            this.totalRecords * 0.5 * 10
                        );
                },
                error: (data) => {
                    if (data && data.error.length > 0) {
                        this.log.showError(data.error);
                        this.showActivation = false;
                    } else {
                        this.log.showError(
                            "Unable to load timesheets at this time. Please try again later."
                        );
                    }
                    this.isTabPanelEnabled = true;
                },
                complete: () => {
                    this.showButtonPanel = true;
                    this.isTabPanelEnabled = true;
                },
            });
    }

    onItemChanged($event, item) {
        if (item.timeOut && item.timeIn) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.timeOut,
            });
            item.timeOut = dates.date2;
        }
        if (item.timeOut && item.timeIn && item.lunchIn) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.lunchIn,
            });
            item.lunchIn = dates.date2;
        }
        if (item.timeOut && item.timeIn && item.lunchOut) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.lunchOut,
            });
            item.lunchOut = dates.date2;
        }

        this.calculateDuration(item.timeIn, item.timeOut, item);
        this.calculateMeal(item);
        setTimeout(() => this.setItemFieldValidity(item), 100);
    }

    //onItemChanged(id) {
    //  //let matches = _.filter(this.eventTimesheet.timesheets.items, (i: any) => { return i.mpId == id; });
    //  //if (matches) {
    //  //  let item = matches[0];
    //  //  this.calculateDuration(item.timeIn, item.timeOut, item);
    //  //  this.calculateMeal(item);
    //  //}
    //}
    checkValidation(timeValidation) {
        return timeValidation;
    }
    onTimeSelected(event, item, index?, formControlName?) {
        console.log("called");
        if (formControlName) {
            item[formControlName] = event;
        }

        //switch (formControlName) {
        //  case "timeIn":
        //  case "timeOut":
        //    item['isIntimeValid'] = this.timeInValidation(item);
        //    item['isOuttimeValid'] = this.timeOutValidation(item);
        //    this.lunchInValidation(item);
        //    this.lunchOutValidation(item);
        //    item['isLunchInTimeValid'] = true;
        //    item['isLunchOutTimeValid'] = true;
        //    break;

        //  case "lunchIn":
        //  case "lunchOut":
        //    item['isLunchInTimeValid'] = this.lunchInValidation(item);
        //    item['isLunchOutTimeValid'] = this.lunchOutValidation(item);
        //    item['isIntimeValid'] = this.timeInValidation(item);
        //    item['isOuttimeValid'] = this.timeOutValidation(item);
        //    break;

        //  case "groupUpdate":
        //    item['isIntimeValid'] = this.timeInValidation(item);
        //    item['isOuttimeValid'] = this.timeOutValidation(item);
        //    item['isLunchInTimeValid'] = this.lunchInValidation(item);
        //    item['isLunchOutTimeValid'] = this.lunchOutValidation(item);
        //    break;
        //}

        //this.onItemChanged1(item);

        //switch (formControlName) {
        //  case "timeIn":
        //    item['isIntimeValid'] = this.timeInValidation(item);
        //    item['isOuttimeValid'] = this.timeOutValidation(item);
        //    break;
        //  case "timeOut":
        //    item['isOuttimeValid'] = this.timeOutValidation(item);
        //    item['isIntimeValid'] = this.timeInValidation(item);
        //    break;
        //  case "lunchIn":
        //    item['isLunchInTimeValid'] = this.lunchInValidation(item);
        //    item['isLunchOutTimeValid'] = this.lunchOutValidation(item);
        //    break;
        //  case "lunchOut":
        //    item['isLunchOutTimeValid'] = this.lunchOutValidation(item);
        //    item['isLunchInTimeValid'] = this.lunchInValidation(item);
        //    break;

        //  default:
        //}
    }
    validateAllFields(item) {
        if (item.timeOut && item.timeIn && item.lunchIn && item.lunchOut) {
            item["isIntimeValid"] = this.timeInValidation(item);
            item["isOuttimeValid"] = this.timeOutValidation(item);
            item["isLunchInTimeValid"] = this.lunchInValidation(item);
            item["isLunchOutTimeValid"] = this.lunchOutValidation(item);
        }
    }
    timeInValidation(item) {
        if (item.timeOut && !item.timeIn) {
            this.isInvlidateTimeProvided = true;
            return false;
        }

        if (!item.timeIn) {
            return true;
        }

        if (item.timeOut && item.timeIn) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.timeOut,
            });
            item.timeOut = dates.date2;
        }
        //if ((item.timeOut ? item.timeIn < item.timeOut : true)
        //  && (item.lunchIn ? item.timeIn < item.lunchIn : true)
        //  && (item.lunchOut ? item.timeIn < item.lunchOut : true))

        if (
            (item.timeOut
                ? moment(item.timeIn).isBefore(item.timeOut)
                : true) &&
            (item.lunchIn
                ? moment(item.timeIn).isBefore(item.lunchIn)
                : true) &&
            (item.lunchOut ? moment(item.timeIn).isBefore(item.lunchOut) : true)
        ) {
            return true;
        }
        return false;
    }

    timeOutValidation(item) {
        if (!item.timeOut && !item.timeIn) {
            return true;
        }
        if (item.timeOut && item.timeIn) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.timeOut,
            });
            item.timeOut = dates.date2;
        }

        //if ((item.timeIn ? item.timeOut > item.timeIn : true)
        //  && (item.lunchIn ? item.timeOut > item.lunchIn : true)
        //  && (item.lunchOut ? item.timeOut > item.lunchOut : true))

        if (
            (item.timeIn ? moment(item.timeOut).isAfter(item.timeIn) : true) &&
            (item.lunchIn
                ? moment(item.timeOut).isAfter(item.lunchIn)
                : true) &&
            (item.lunchOut ? moment(item.timeOut).isAfter(item.lunchOut) : true)
        ) {
            return true;
        }
        return false;
    }

    lunchInValidation(item) {
        if (!item.timeIn && !item.timeOut && !item.lunchIn) {
            return true;
        }

        if (item.timeOut && item.timeIn && item.lunchIn) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.lunchIn,
            });
            item.lunchIn = dates.date2;
        }
        //if ((item.timeIn ? item.lunchIn > item.timeIn : true)
        //  && (item.lunchOut ? item.lunchIn < item.lunchOut : true)
        //  && (item.timeOut ? item.lunchIn < item.timeOut : true))

        if (
            (item.timeIn ? moment(item.lunchIn).isAfter(item.timeIn) : true) &&
            (item.lunchOut
                ? moment(item.lunchIn).isBefore(item.lunchOut)
                : true) &&
            (item.timeOut ? moment(item.lunchIn).isBefore(item.timeOut) : true)
        ) {
            return true;
        }
        return false;
    }

    lunchOutValidation(item) {
        if (!item.lunchIn && !item.lunchOut) {
            this.isInvlidateLunchTimeProvided = false;
            return true;
        }

        if (item.lunchOut && !item.lunchIn) {
            this.isInvlidateLunchTimeProvided = true;
            return false;
        }

        if (item.timeOut && item.timeIn && item.lunchOut) {
            const dates = this.incrementDate({
                date: item.timeIn,
                date2: item.lunchOut,
            });
            item.lunchOut = dates.date2;
        }
        //if ((item.timeIn ? item.lunchOut > item.timeIn : true)
        //  && (item.lunchIn ? item.lunchOut > item.lunchIn : true)
        //  && (item.timeOut ? item.lunchOut < item.timeOut : true))

        if (
            (item.timeIn ? moment(item.lunchOut).isAfter(item.timeIn) : true) &&
            (item.lunchIn
                ? moment(item.lunchOut).isAfter(item.lunchIn)
                : true) &&
            (item.timeOut ? moment(item.lunchOut).isBefore(item.timeOut) : true)
        ) {
            this.isInvlidateLunchTimeProvided = false;
            return true;
        }
        return false;
    }

    incrementDate(dates) {
        let timeDifference;
        //date = moment(date).local();
        //date2 = moment(date2).local();

        if (
            dates.date &&
            dates.date2 &&
            moment(this.getDateString(dates.date)).diff(
                this.getDateString(dates.date2),
                "days"
            ) === 0
        ) {
            timeDifference =
                this.getMinFromTime(dates.date2) -
                this.getMinFromTime(dates.date);

            if (timeDifference < 0) {
                dates.date2 = moment(dates.date2).add(1, "days").format();
            }
        }
        return dates;
    }
    getDateString(date) {
        return moment(date).format("YYYY-MM-DD");
    }
    getMinFromTime(time) {
        const date = new Date(time);
        const minutes = date.getMinutes(),
            hours = date.getHours(),
            minutesInHour = 60;

        return hours * minutesInHour + minutes;
    }
    calculateMinutes(time, timeout) {
        let hours;

        const minutes = new Date(time).getMinutes(); // changed

        hours = new Date(time).getHours();
        const minutesInHour = 60;

        return hours * minutesInHour + minutes;
    }
    setUpdatedEndDate(startDate, endDate, currentDate) {
        let timeDifference;
        if (startDate && endDate) {
            timeDifference =
                this.calculateMinutes(endDate, "") -
                this.calculateMinutes(startDate, "");
            if (
                timeDifference < 0 &&
                moment(endDate).diff(moment(currentDate), "days") === 0
            ) {
                endDate = moment(endDate).add(1, "days").toDate();
            }
        }

        return endDate;
    }

    isSameDateAs(outDate, inDate) {
        return (
            moment(outDate).year() === moment(inDate).year() &&
            outDate.getMonth() === inDate.getMonth() &&
            outDate.getDate() === inDate.getDate()
        );
    }
    calculateMinutesDiff(startDate, endDate) {
        return moment(endDate).diff(moment(startDate), "minutes");
    }
    calculateDuration(startDate, endDate, item) {
        //var lunchDifference = moment(item.lunchOut).diff(moment(item.lunchIn), "minutes");
        // if (item.timeOut && !item.timeIn) {
        //    this.eventTimesheetForm["activityTimeForm_" + item.id].timeIn.$setValidity("timeConflict", false);
        // }
        // else {
        //     this.eventTimesheetForm["activityTimeForm_" + item.id].timeIn.$setValidity("timeConflict", true);
        // }
        // if (item.lunchOut != null && item.lunchIn != null) {
        //     if ((lunchDifference < 0 || !(this.isSameDateAs(item.lunchOut, item.lunchIn))) && item.lunchOut !== "") {
        //         this.eventTimesheetForm["activityTimeForm_" + item.id].lunchIn.$setValidity("timeConflict", false);
        //         this.eventTimesheetForm["activityTimeForm_" + item.id].lunchOut.$setValidity("timeConflict", false);
        //         item.lunchOut = null;
        //     } else {
        //         this.eventTimesheetForm["activityTimeForm_" + item.id].lunchIn.$setValidity("timeConflict", true);
        //         this.eventTimesheetForm["activityTimeForm_" + item.id].lunchOut.$setValidity("timeConflict", true);
        //     }

        // } else if ((item.lunchOut == null && item.lunchIn != null) || (item.lunchIn == null && item.lunchOut != null)) {
        //     this.eventTimesheetForm["activityTimeForm_" + item.id].lunchIn.$setValidity("timeConflict", false);
        //     this.eventTimesheetForm["activityTimeForm_" + item.id].lunchOut.$setValidity("timeConflict", false);
        // } else {
        //     this.eventTimesheetForm["activityTimeForm_" + item.id].lunchIn.$setValidity("timeConflict", true);
        //     this.eventTimesheetForm["activityTimeForm_" + item.id].lunchOut.$setValidity("timeConflict", true);
        // }
        if (!startDate || !endDate) {
            return "";
        }
        const dateDiff = moment(endDate).diff(moment(startDate), "minutes");
        const hours = Math.floor(dateDiff / 60);
        const minutes = dateDiff % 60;
        let duration = "";
        if (hours === 0) {
            duration = minutes + " min";
        } else if (hours > 0 && minutes > 0) {
            duration = hours + "h " + minutes + " min";
        } else if (hours > 0 && minutes === 0) {
            duration = hours + " h";
        }

        if (item) {
            item.duration = dateDiff / 60;
        }
        return duration;
    }

    calculateMeal(item) {
        this.updateDate(item);
        const x = item.lunchIn;
        const lunchDuration = this.calculateMinutesDiff(
            item.lunchIn,
            item.lunchOut
        );

        const startAndMealStartdurationDiff = this.calculateMinutesDiff(
            item.timeIn,
            item.lunchIn
        );

        let hasError = false;
        let hasWarning = false;
        item["isLunchDurationMorethanaHr"] = false;
        let title = "";
        if (lunchDuration <= 0) {
            setTimeout(() => (item.lunchOut = null), 50);
        }
        if (lunchDuration > 60) {
            hasWarning = true;
            item.isLunchDurationMorethanaHr = true;
            title = "Meal time is greater than 1 hour.";
        }
        if (lunchDuration > 0 && lunchDuration < 60) {
            hasWarning = true;
            title = "Meal time is less than 1 hour.";
        }
        if (
            item.timeIn &&
            item.lunchIn &&
            (startAndMealStartdurationDiff < 120 ||
                startAndMealStartdurationDiff > 300)
        ) {
            hasWarning = true;
            const mealTimeInvalidRangeMsg =
                "Warning—Meal Start is outside of contractual agreement and will trigger additional payment!";
            title = title + "\n" + mealTimeInvalidRangeMsg;
        }
        if (item.timeIn && item.timeOut && item.lunchOut && item.lunchIn) {
            if (!this.submissionValidation(item)) {
                hasError = true;
                const mealTimeOutRangeMsg =
                    "You must enter MEAL START and/or MEAL END within the START and END times for this contact.";
                title = title + "\n" + mealTimeOutRangeMsg;
            }
        }
        if (!item.timeIn && !item.timeOut && !item.lunchOut && !item.lunchIn) {
            this.submissionValidation(item);
        }
        if (hasError || item.isLunchDurationMorethanaHr) {
            item.isTimeAlertHigh = true;
        } else {
            item.isTimeAlertHigh = false;
        }
        if (title != "") {
            item.mealTitle = title;
            return true;
        }
        return false;
    }

    //unchOutValidation(item) {
    //  if ((!item.lunchIn) && !item.lunchOut) {
    //    return true;
    //  }

    //  if (item.lunchOut && !item.lunchIn) {
    //    this.isInvlidateLunchTimeProvided = true;
    //    return false;
    //  }
    //  if ((item.timeIn ? item.lunchOut > item.timeIn : true) && (item.lunchIn ? item.lunchOut > item.lunchIn : true) && (item.timeOut ? item.lunchOut < item.timeOut : true)) {
    //    return true;
    //  }
    //  return false;
    //}

    updateDate(item) {
        if (item.timeIn) {
            if (item.timeOut) this.validateAndUpdateDate("timeOut", item);
            if (item.lunchIn) this.validateAndUpdateDate("lunchIn", item);
            if (item.lunchOut) this.validateAndUpdateDate("lunchOut", item);
        }
    }

    validateAndUpdateDate(selectedItem, item) {
        const dateDiff = moment(item[selectedItem]).diff(
            moment(item.timeIn),
            "minutes"
        );
        const hours = Math.floor(dateDiff / 60);
        const minutes = dateDiff % 60;
        if (hours >= 24) {
            item[selectedItem] = moment(item[selectedItem])
                .add(-1, "days")
                .toDate();
        }
    }

    openExportModal(eventTimesheetQuery, contactIdList, fileType) {
        const initialState: any = {
            backdrop: "static",

            eventTimesheetQuery: this.eventTimesheetQuery,
            contactList: this.contactList,
            reportType: fileType,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard:false
        };
        this.bsReportExportModalRef = this._modalService.show(
            EventTimesheetReportExportComponent,
            modalOptions
        );
    }

    selectAll(evt) {
        const unSubmittedTimesheets = _.filter(
            this.eventTimesheet.timesheets.items,
            function (element) {
                return element.isSubmitted !== "Y";
            }
        );

        this.groupUpdate.selectAll = evt.target.checked;
        this.groupUpdate.status = evt.target.checked;
        if (evt.target.checked === true) {
            //SCOR-10921
            // $scope.groupUpdate.count = $scope.eventTimesheet.timesheets.items.length; //SCOR-10921
            this.groupUpdate.count = unSubmittedTimesheets.length;
        } else {
            this.resetGroupUpdate();
        }
        _.forEach(this.eventTimesheet.timesheets.items, (value) => {
            //SCOR-10921, Added condition to not to check submitted records
            if (value.isSubmitted !== "Y") {
                value.selected = evt.target.checked;
            }
        });
        this.canSubmitTimesheet = true;
    }
    groupUpdateSelect(id) {
        const item = _.find(this.eventTimesheet.timesheets.items, (o) => {
            return o.mpId == id;
        });
        if (item) {
            item.selected = !item.selected;

            const selected = _.filter(
                this.eventTimesheet.timesheets.items,
                (i) => {
                    return i.selected == true;
                }
            );
            if (selected && selected.length > 0) {
                this.groupUpdate.status = true;
                if (
                    selected.length ===
                    this.eventTimesheet.timesheets.items.length
                ) {
                    this.groupUpdate.selectAll = true;
                } else {
                    this.groupUpdate.selectAll = false;
                }
            } else {
                this.groupUpdate.status = false;
            }
        }
    }

    resetGroupUpdate() {
        this.groupUpdate.status = false;
        this.groupUpdate.submitted = false;
        this.groupUpdate.selectAll = false;
        this.groupUpdate.selected = {};
        this.groupUpdate.updatedTime = "";
        this.groupUpdate.count = 0;
        if (
            this.eventTimesheet &&
            this.eventTimesheet.timesheets &&
            this.eventTimesheet &&
            this.eventTimesheet.timesheets.items
        ) {
            _.forEach(this.eventTimesheet.timesheets.items, (value) => {
                value.selected = false;
            });
        }
    }
    openActivateTimesheetModal() {
        this.showActivation = false;

        const initialState: any = {
            modalTitle: "Activate Timesheet",
            modalMessage:
                "<p>You are about to activate the timesheet for this event day. Once you have activated this timesheet, any updates made to this daily agenda will not update the Timesheet for this date.</p><p>Would you like to continue?</p>",
            confirmText: "Yes, Continue",
            cancelText: "No, Cancel",
            hideConfirm: false,
            eventTimesheetQuery: this.eventTimesheetQuery,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsActivateTSModalRef = this._modalService.show(
            ModalActivateTimesheetComponent,
            modalOptions
        );
        this.bsActivateTSModalRef.content.onAffirmation.subscribe((result) => {
            this.updateTimesheetDate();
            this.getTimesheetDate();
        });
        this.bsActivateTSModalRef.content.onRejection.subscribe((result) => {
            this.updateTimesheetDate();
        });
    }

    openSubmitEventTimesheetModal(category) {
        let isValidBatch = true;
        let isValidRateBatch = true;
        this.isTimesheetValidatedForSubmission = true;
        let contactName = "";
        // before submitting, validating whether the timesheets are adhering to validations
        _.forEach(this.eventTimesheet.timesheets.items, (value) => {
            if (
                value.eventPosition.position.category === category ||
                category === "All"
            ) {
                if (!this.submissionValidation(value)) {
                    isValidBatch = false;
                    this.isTimesheetSubmittable = false;
                } else {
                    value.invalidTime = false;
                }
                if (this.submissionRateValidation(value)) {
                    isValidRateBatch = false;
                    contactName = contactName + value.contact.fullName + ", ";
                }
            }
        });
        if (this.isInvlidateLunchTimeProvided || !isValidBatch) {
            this.log.showError(
                'Unable to submit the timesheets. Please review the row(s) in error displaying the <i class="fa fa-exclamation-triangle triangle-icon"></i> icon.'
            );
            this.isInvlidateLunchTimeProvided = false;
            isValidBatch = true;
            return false;
        }

        if (!isValidRateBatch) {
            this.log.showError(
                "Employee Type or Rate is missing for " +
                    contactName +
                    "please update on the Crew Page."
            );
            isValidRateBatch = true;
            return false;
        }
        this.isTimesheetValidatedForSubmission = false;

        const initialState: any = {
            eventTimesheet: this.eventTimesheet,
            category: category,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false
        };

        this.bsSubmitTSModalRef = this._modalService.show(
            ModalSubmitEventTimesheetComponent,
            modalOptions
        );
        this.bsSubmitTSModalRef.content.onSync.subscribe((result) => {
            this.log.trace("Timesheet submitted successfully");
            //no need for updatetimesheet. gettimesheetdate will rebuild tabs and existing tab will be set.
            // It will retrigger the updateTimesheetDate();
            //this.updateTimesheetDate();
            this.getTimesheetDate();
        });
        // modalInstance.result.then(function () {
        //     log.trace("Timesheet submitted successfully");
        //     $scope.eventTimesheetForm.$setPristine();
        //     $scope.updateTimesheetDate();
        //     getTimesheetDates();
        // }, function () {
        //     log.trace("Timesheet submitted unsuccessfully");
        // });
        return;
    }
    onTimeGroupUpdated(event, i, updateGroupTime) {
        this.groupUpdate[updateGroupTime] = event;
    }
    groupUpdateBtn(update) {
        let date;
        this.eventTimesheetForm.form.markAsDirty();
        this.groupUpdate.submitted = true;
        if (
            this.eventTimesheet.timesheets &&
            this.eventTimesheet.timesheets.items &&
            update.selected.name !== ""
        ) {
            if (
                update.selected.name === "Field Notes" &&
                update.updatedPayrollComment !== ""
            ) {
                _.forEach(
                    this.eventTimesheet.timesheets.items,
                    function (value) {
                        if (value.selected) {
                            value.productionManagerComment =
                                update.updatedPayrollComment;
                        }
                    }
                );
                this.groupUpdate.submitted = false;
                this.groupUpdate.selected = {};
                //$scope.groupUpdate.updatedTime = "";
                update.updatedStartTime = "";
                update.updatedEndTime = "";
                update.updatedMealStartTime = "";
                update.updatedMealEndTime = "";
                this.groupUpdate.updatedPayrollComment = "";
                this.groupUpdateInvalid = false;
            } else if (
                update.selected.name === "Field Notes" &&
                update.updatedPayrollComment === ""
            ) {
                this.groupUpdateInvalid = true;
            }

            // TODO: Refactor
            if (
                update.selected.name !== "Field Notes" &&
                update.selected.name !== ""
            ) {
                if (
                    (update.updatedStartTime &&
                        update.updatedStartTime !== "") ||
                    (update.updatedEndTime && update.updatedEndTime !== "") ||
                    (update.updatedMealStartTime &&
                        update.updatedMealStartTime !== "") ||
                    (update.updatedMealEndTime &&
                        update.updatedMealEndTime !== "")
                ) {
                    _.forEach(
                        this.eventTimesheet.timesheets.items,
                        (value: any) => {
                            /*As the below operation is for bulk update of clock in/out and meal in/out times excluding if rate card is flat day - SCOR-10858*/
                            if (
                                value.selected &&
                                value.billCodeUnitCodeTypeCode !== "Dy"
                            ) {
                                if (
                                    update.updatedStartTime &&
                                    update.updatedStartTime !== ""
                                ) {
                                    value["timeIn"] = moment(
                                        update.updatedStartTime
                                    ).format();
                                }
                                if (
                                    update.updatedEndTime &&
                                    update.updatedEndTime !== ""
                                ) {
                                    date = null;
                                    date = this.setUpdatedEndDate(
                                        value.timeIn,
                                        update.updatedEndTime,
                                        this.activeTimesheetDate
                                    );
                                    if (date) {
                                        value["timeOut"] = moment(
                                            _.cloneDeep(date)
                                        ).format();
                                    } else {
                                        value["timeOut"] = moment(
                                            update.updatedEndTime
                                        ).format();
                                    }
                                }

                                if (
                                    update.updatedMealStartTime &&
                                    update.updatedMealStartTime !== ""
                                ) {
                                    date = null;
                                    date = this.setUpdatedEndDate(
                                        value.timeIn,
                                        update.updatedMealStartTime,
                                        this.activeTimesheetDate
                                    );
                                    if (date) {
                                        value["lunchIn"] = moment(
                                            _.cloneDeep(date)
                                        ).format();
                                    } else {
                                        value["lunchIn"] = moment(
                                            update.updatedMealStartTime
                                        ).format();
                                    }
                                }

                                if (
                                    update.updatedMealEndTime &&
                                    update.updatedMealEndTime !== ""
                                ) {
                                    date = null;
                                    date = this.setUpdatedEndDate(
                                        value.timeIn,
                                        update.updatedMealEndTime,
                                        this.activeTimesheetDate
                                    );
                                    if (date) {
                                        value["lunchOut"] = moment(
                                            _.cloneDeep(date)
                                        ).format();
                                    } else {
                                        value["lunchOut"] = moment(
                                            update.updatedMealEndTime
                                        ).format();
                                    }
                                }
                                this.onItemChanged(null, value);
                            }
                        }
                    );

                    this.groupUpdate.submitted = false;
                    this.groupUpdate.selected = {};
                    //$scope.groupUpdate.updatedTime = "";
                    update.updatedStartTime = "";
                    update.updatedEndTime = "";
                    update.updatedMealStartTime = "";
                    update.updatedMealEndTime = "";
                    this.groupUpdate.updatedPayrollComment = "";
                    this.groupUpdateInvalid = false;
                } else {
                    this.groupUpdateInvalid = true;
                }
            }
        }
        // this.eventTimesheetForm.$setDirty();
    }
    saveTimesheet(eventTimesheet) {
        let i, item;
        this.isTimesheetValidatedForSubmission = false;
        this.isInvlidateLunchTimeProvided = false;
        this.isInvlidateTimeProvided = false;
        const selectedTimesheets: any[] = [];
        const savetimesheet: any = _.cloneDeep(this.eventTimesheet);
        for (i = 0; i < this.eventTimesheet.timesheets.items.length; i += 1) {
            item = this.eventTimesheet.timesheets.items[i];

            if (item.selected) {
                if (item.lunchOut && !item.lunchIn) {
                    this.isInvlidateLunchTimeProvided = true;
                }

                if (item.timeOut && !item.timeIn) {
                    this.isInvlidateTimeProvided = true;
                }

                item.updatedBy = this.authorName;
                item.updatedDate = new Date();

                selectedTimesheets.push(item);
            }
        }

        if (selectedTimesheets.length > 0) {
            savetimesheet.timesheets.items = selectedTimesheets;
        } else {
            this.log.showError("No Timesheet is selected to save");
            return false;
        }

        // This condition has been placed to avoid the submission of invlid lunch out dates
        if (this.isInvlidateTimeProvided) {
            this.log.showError(
                "Invalid In time entered.Unable to save timesheets"
            );
            this.isInvlidateTimeProvided = false;
            return false;
        }

        // This condition has been placed to avoid the submission of invlid lunch out dates
        if (this.isInvlidateLunchTimeProvided) {
            this.log.showError(
                "Invalid Meal time entered.Unable to save timesheets"
            );
            this.isInvlidateLunchTimeProvided = false;
            return false;
        }

        // usSpinnerService.spin("spinner");
        this.subs.sink = this._payrollService
            .saveEventTimesheet(savetimesheet)
            .subscribe({
                next: (result) => {
                    // log.trace("saveTimesheet - got results: " + JSON.stringify(result));
                    this.originalTimesheet = _.cloneDeep(this.eventTimesheet);
                    // this.eventTimesheetForm.$setPristine();
                    this.restartSearch();
                },
                error: (msg: any) => {
                    this.log.error(
                        "saveTimesheet - got an error: " + JSON.stringify(msg)
                    );
                    this.log.showError(
                        "Unable to save timesheets at this time. Please try again later."
                    );
                    // usSpinnerService.stop("spinner");
                },
            });

        return;
    }

    cancelTimesheet() {
        this.resetGroupUpdate();
        this.eventTimesheet = _.cloneDeep(this.originalTimesheet);
    }
    activateTab(item?) {
        // $emit("hideAlert");
        if (this.activeTimesheetDate !== item.date) {
            this.activeTimesheetDate = item.date;
            if (!this.eventTimesheetQuery) this.getPayrollQuery();
            if (this.eventTimesheetQuery) {
                this.eventTimesheetQuery.departments = 0;
                this.eventTimesheetQuery.positionType = "";
                this.eventTimesheetQuery.positionCategory = null;
                this.eventTimesheetQuery.sortBy = null;
                this.eventTimesheetQuery.currentPage = 1;
                this.eventTimesheetQuery.pageSize = 10000;
                this.showButtonPanel = false;
                this.eventTimesheetQuery.timeCardDate = item.date;
            }
        }
        this.updateTimesheetDate();
        // this.activeTabData.unsubscribe();
    }
    activeTab() {
        return this.timesheetDates.filter((eventTimesheet) => {
            return eventTimesheet.active;
        })[0];
    }
    calculateDays(timeIn, timeOut) {
        if (!timeIn || !timeOut) {
            return 0;
        }
        return new Date(timeOut).getDate() - new Date(timeIn).getDate();
    }

    getTimesheetDate() {
        this.log.trace("Getting event timesheet dates for tabs");
        // usSpinnerService.spin("spinner");
        const tempHeaderTimesheetDates: any[] = [];
        this.subs.sink = this._payrollService
            .getEventTimesheetDates(
                this.eventId,
                this.crewWorkStartDate,
                this.crewWorkEndDate
            )
            .subscribe({
                next: (result) => {
                    this.timesheetDates = result.reference;

                    for (
                        let i = 0;
                        i < this.timesheetDates.length &&
                        i <= RemotesConstants.eventConstants.scheduleLength;
                        i++
                    ) {
                        this.timesheetDates[i].date = moment(
                            this.timesheetDates[i].date
                        ).format("YYYY-MM-DD");
                        this.timesheetDates[i].active = false;

                        if (
                            moment(this.timesheetDates[i].date).format(
                                "YYYY-MM-DD"
                            ) === this.activeTimesheetDate.toString()
                        ) {
                            this.timesheetDates[i].active = true;
                        }

                        tempHeaderTimesheetDates.push(this.timesheetDates[i]);
                    }

                    if (this.activeTimesheetDate == "") {
                        this.timesheetDates[0].active = true;
                        this.activeTimesheetDate = this.timesheetDates[0].date;
                    }

                    this.headerTimesheetsDates = tempHeaderTimesheetDates;
                    this.getPayrollQuery();
                    setTimeout(() => {
                        this.cd.detectChanges();
                        this.setChangeDetectionInterval();
                    }, 1000);
                },
                error: (err) => {
                    this.log.error(
                        "getTimesheetDates - got an error: " +
                            JSON.stringify(err)
                    );
                    this.log.showError(
                        "Unable to get timesheet dates for the tabs at this time. Please try again later."
                    );
                },
            });
    }
    workActivtiy(item) {
        let itemBolean;
        const data = item.filter((item) => item.isPlanned == true);
        if (data > 3) {
            itemBolean = true;
        } else if (data < 3) {
            itemBolean = false;
        }
        return itemBolean;
    }
    checkDirtyForm(method, params, propName) {
        this.filterOrSortChangedPropName = propName;
        if (propName == "departments") {
            this.previousDepartments = params.oldValue;
        }
        if (propName == "positionType") {
            this.previousPositionType = params.oldValue;
        }
        if (propName == "positionCategory") {
            this.previousPositionCategory = params.oldValue;
        }
        if (propName == "sortBy") {
            this.previousSortBy = params.oldValue;
        }

        if (this.eventTimesheetForm.dirty) {
            this.unsavedChangesModal(() => {
                method.apply(this, params);
            }, this.eventTimesheetForm.invalid);
        } else {
            this.previousPayrollQuery = _.cloneDeep(
                this.eventTimesheetQuery.currentPage
            );
            method.apply(this, params);
        }
    }

    /*
     * Generic  to show the modal. All parameters are customizable per need
     */
    showValidationModal(
        title,
        message,
        confirmText,
        cancelText,
        saveText,
        disableSaveFn
    ) {
        this.log.trace("showValidationErrorModal");

        //if (confirmText == null)
        //  confirmText = "Continue"

        const initialState: any = {
            self: this,
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText,
            saveText: saveText,
            disableSave:
                this.isInvlidateLunchTimeProvided ||
                this.isInvlidateTimeProvided ||
                disableSaveFn,
        };
        const modalOptions = {
            animated: true,
            ignoreBackdropClick: true,
            initialState: initialState,
            keyboard: false
        };

        this.bsDirtyCheckModalRef = this._modalService.show(
            NbcModalDirtyCheckComponent,
            modalOptions
        );
        this.bsDirtyCheckModalRef.content.onAffirmation.subscribe(() => {
            this.resetFitlerAndSortByValue();
            this.previousPayrollQuery = _.cloneDeep(
                this.eventTimesheetQuery.currentPage
            );
            this.saveTimesheet(this.eventTimesheet);
            this.eventTimesheetForm.resetForm();
        });
        this.bsDirtyCheckModalRef.content.onRejection.subscribe(() => {
            this.reassignPreviousFitlerAndSortByValue(false);
            this.resetFitlerAndSortByValue();
            //this.eventTimesheetForm.form.markAsPristine();
            //this.cancelTimesheet();
        });
        this.bsDirtyCheckModalRef.content.onDisregard.subscribe(() => {
            this.resetFitlerAndSortByValue();
            this.reassignPreviousFitlerAndSortByValue(true);
            this.eventTimesheetForm.resetForm();
            this.previousPayrollQuery = _.cloneDeep(
                this.eventTimesheetQuery.currentPage
            );
            this.restartSearch();
        });
    }

    unsavedChangesModal(callback, disableSave) {
        let isTcSelected = false;
        _.forEach(this.eventTimesheet.timesheets.items, (value) => {
            if (value.selected) {
                isTcSelected = true;
            }
        });

        this.showValidationModal(null, null, null, null, null, !isTcSelected);
    }

    ///SCOR-21465 - Reassign/Clear the filter value based on the conditions.
    reassignPreviousFitlerAndSortByValue(isDiscard) {
        if (isDiscard) {
            this.eventTimesheetQuery.departments = 0;
            this.eventTimesheetQuery.positionType = "";
            this.eventTimesheetQuery.positionCategory = null;
            this.eventTimesheetQuery.sortBy = null;
        } else {
            if (this.filterOrSortChangedPropName == "departments") {
                this.eventTimesheetQuery.departments = this.previousDepartments;
            }
            if (this.filterOrSortChangedPropName == "positionType") {
                this.eventTimesheetQuery.positionType =
                    this.previousPositionType;
            }
            if (this.filterOrSortChangedPropName == "positionCategory") {
                this.eventTimesheetQuery.positionCategory =
                    this.previousPositionCategory;
            }
            if (this.filterOrSortChangedPropName == "sortBy") {
                this.eventTimesheetQuery.sortBy = this.previousSortBy;
            }
        }
    }

    ///SCOR-21465 - Clear the filter value from respective variables.
    resetFitlerAndSortByValue() {
        this.previousDepartments = 0;
        this.previousPositionType = "";
        this.previousPositionCategory = null;
        this.previousSortBy = null;
    }

    setChangeDetectionInterval() {
        if (this.changeDetectionInterval)
            clearInterval(this.changeDetectionInterval);
        this.changeDetectionInterval = setInterval(() => {
            this.cd.detectChanges();
        }, 200);
    }

    ngOnInit(): void {
        this.canSubmitTimesheet = true;
        this.showButtonPanel = true;
        this._sharedDataService.setCurrentPage("Timesheet");
        this._sharedDataService
            .getCurrentEventId()
            .subscribe((eventNumber) => (this.eventId = eventNumber));

        this._sharedDataService.getEventInfo().subscribe((eventData) => {
            if (!_.isEmpty(eventData)) {
                this.eventData = eventData;
                this.crewWorkEndDate = eventData.crewWorkEndDate;
                this.crewWorkStartDate = eventData.crewWorkStartDate;
                this.getTimesheetDate();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
            }
        }
    }

    public ngAfterViewInit(): void {
        this.forms.changes.subscribe((comps: QueryList<NgForm>) => {
            this.eventTimesheetForm = comps.first;
        });
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
