import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { MinimumWageService } from "src/app/Shared/Services/minimum-wage.service";
import { SubSink } from "subsink";
import { NgForm } from "@angular/forms";
import { Subject } from "rxjs";
import { forEach, result } from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import * as moment from "moment";
import { DatePipe } from "@angular/common";

@Component({
    selector: "modal-minimum-wage",
    templateUrl: "./modal-minimum-wage.component.html",
})
export class ModalMinimumWageComponent implements OnInit, OnDestroy {
    constructor(
        private _minimumWageService: MinimumWageService,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService,
        private datePipe: DatePipe
    ) {}

    private subs = new SubSink();
    public onSync!: Subject<any>;
    modalTitle: string | undefined;
    showAlertMessage = false;
    isAdd = false;
    wage: any | undefined;
    allWages: any;
    statesList: any;
    stateList: any;
    endDate: any;
    startDate: any;
    submitted = false;
    dateranges: any[] = [];
    cityDateranges: any[] = [];
    clonedWages: any;

    objCheckHolder: any;
    exitLoop = false;

    closeModal() {
        this.bsModalRef.hide();
    }

    upsertWage() {
        const isValid = this.validate();
        this.submitted = true;

        this.objCheckHolder = {
            isWageExists: false,
            errorMessage: "",
        };

        if (isValid) {
            this.objCheckHolder = this.isWageExists_sub(this.wage);
            if (this.objCheckHolder.isWageExists) {
                console.log(this.objCheckHolder.isWageExists);
                this.log.showModalError(this.objCheckHolder.errorMessage);
                this.showAlertMessage = true;

                //reset value for wage exists check
                this.objCheckHolder.isWageExists = false;
                this.exitLoop = false;
            } else {
                this.wage.startDate = moment(this.wage.startDate).isValid()
                    ? moment(this.wage.startDate).format()
                    : "";
                this.wage.endDate = moment(this.wage.endDate).isValid()
                    ? moment(this.wage.endDate).format()
                    : "";

                this.subs.sink = this._minimumWageService
                    .upsertWage(this.wage)
                    .subscribe(
                        (result) => {
                            this.log.showSuccess("Wage added successfully");
                            this.onSync.next("Wage added");
                            this.bsModalRef.hide();
                        },
                        (error) => {
                            console.log(error);
                            this.log.showModalError(
                                "The wage failed to be added!"
                            );
                            this.showAlertMessage = true;
                        },
                        () => {}
                    );
            }
        }
    }

    isWageExists_sub(wage) {
        const userEnteredStartDate = moment(wage.startDate).isValid()
            ? new Date(wage.startDate).toDateString()
            : "";
        const userEnteredEndDate = moment(wage.endDate).isValid()
            ? new Date(wage.endDate).toDateString()
            : "";

        let matchingWage;
        let activeWage;
        const userAction = wage.id > 0 ? "edit" : "add";

        //CHECK 1 : Duplicate check for City/state
        if (wage.city != "") {
            matchingWage = _.filter(this.allWages, (matchingWages) => {
                return (
                    matchingWages.state == wage.state &&
                    matchingWages.city == wage.city
                );
            });
            activeWage = _.filter(matchingWage, (city) => {
                return (
                    city.state == wage.state &&
                    city.city == wage.city &&
                    !moment(city.endDate).isValid()
                );
            });
            this.objCheckHolder = this.isWageExists_Duplicate(
                matchingWage,
                "city",
                userEnteredStartDate,
                userEnteredEndDate,
                wage,
                userAction
            );
        } else {
            matchingWage = _.filter(this.allWages, (matchingWages) => {
                return (
                    matchingWages.state == wage.state &&
                    (matchingWages.city == "" || matchingWages.city == null)
                );
            });
            activeWage = _.filter(matchingWage, (state) => {
                return (
                    state.state == wage.state &&
                    (state.city == "" || state.city == null) &&
                    !moment(state.endDate).isValid()
                );
            });
            this.objCheckHolder = this.isWageExists_Duplicate(
                matchingWage,
                "state",
                userEnteredStartDate,
                userEnteredEndDate,
                wage,
                userAction
            );
        }

        //CHECK 2 : User shouldnt be allowed to enter a new active row (without end date if one exists for the city/state)
        if (!this.objCheckHolder.isWageExists) {
            if (wage.city != "") {
                this.objCheckHolder = this.isWageExists_ActiveRow(
                    matchingWage,
                    "city",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            } else {
                this.objCheckHolder = this.isWageExists_ActiveRow(
                    matchingWage,
                    "state",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            }
        }
        //CHECK 3 : User should not be entering overlapping dates

        //CHECK 3 : overlap checks to see if dates fall between existing date ranges
        if (!this.objCheckHolder.isWageExists) {
            if (wage.city != "") {
                this.objCheckHolder = this.isWageExists_Overlap(
                    matchingWage,
                    "city",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            } else {
                this.objCheckHolder = this.isWageExists_Overlap(
                    matchingWage,
                    "state",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            }
        }

        //CHECK 4 : Prevent users from saving future dates
        if (!this.objCheckHolder.isWageExists) {
            if (wage.city != "") {
                this.objCheckHolder = this.isWageExists_Future(
                    matchingWage,
                    "city",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            } else {
                this.objCheckHolder = this.isWageExists_Future(
                    matchingWage,
                    "state",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            }
        }

        //CHECK 5 : Prevent user from editing a row and changing the date to a past date while there are future date
        // Eg: if there is a range for May 1 and May 31 and no other active rows , then dont allow user to add a new row with no end date for Apr 1
        if (!this.objCheckHolder.isWageExists) {
            if (wage.city != "") {
                this.objCheckHolder = this.isWageExists_Past(
                    matchingWage,
                    "city",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            } else {
                this.objCheckHolder = this.isWageExists_Past(
                    matchingWage,
                    "state",
                    userEnteredStartDate,
                    userEnteredEndDate,
                    wage,
                    userAction,
                    activeWage
                );
            }
        }

        return this.objCheckHolder;
    }

    isWageExists_Duplicate(
        wage,
        checkOption,
        userEnteredStartDate,
        userEnteredEndDate,
        userEnteredWage,
        userAction
    ) {
        wage.forEach((wageItem) => {
            //var stateStartDate = new Date(new Date(wageItem.startDate).toDateString());
            //var stateEndDate = wageItem.endDate ? new Date(new Date(wageItem.endDate).toDateString()) : "";

            //var stateStartDate = moment(wageItem.startDate).format();
            //var stateEndDate = wageItem.endDate ? moment(wageItem.endDate).format() : "";

            const stateStartDate = moment(wageItem.startDate).isValid()
                ? new Date(wageItem.startDate).toDateString()
                : "";
            const stateEndDate = moment(wageItem.endDate).isValid()
                ? new Date(wageItem.endDate).toDateString()
                : "";

            //check for city..for now intentionally left 2 different checks in the interest of time
            if (checkOption == "city") {
                if (userEnteredEndDate == "") {
                    if (
                        wageItem.state == userEnteredWage.state &&
                        wageItem.city == userEnteredWage.city &&
                        stateStartDate == userEnteredStartDate
                    ) {
                        //doing an additional check to ensure that user is allowed to update the wage amount only if all the other values (state, city, dates are same)
                        if (
                            !userEnteredWage.wageId ||
                            (userEnteredWage.wageId &&
                                wageItem.wage == userEnteredWage.wage)
                        ) {
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A wage already exists.";
                            return this.objCheckHolder;
                        }
                    }
                } else {
                    if (
                        wageItem.state == userEnteredWage.state &&
                        wageItem.city == userEnteredWage.city &&
                        stateStartDate == userEnteredStartDate &&
                        stateEndDate == userEnteredEndDate
                    ) {
                        if (
                            !userEnteredWage.wageId ||
                            (userEnteredWage.wageId &&
                                wageItem.wage == userEnteredWage.wage)
                        ) {
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A wage already exists.";
                            return this.objCheckHolder;
                        }
                    }
                }
            }
            //check for state
            if (checkOption == "state") {
                if (userEnteredEndDate == "") {
                    if (
                        wageItem.state == userEnteredWage.state &&
                        stateStartDate == userEnteredStartDate
                    ) {
                        if (
                            !userEnteredWage.wageId ||
                            (userEnteredWage.wageId &&
                                wageItem.wage == userEnteredWage.wage)
                        ) {
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A wage already exists.";
                            return this.objCheckHolder;
                        }
                    }
                } else {
                    if (
                        wageItem.state == userEnteredWage.state &&
                        stateStartDate == userEnteredStartDate &&
                        stateEndDate == userEnteredEndDate
                    ) {
                        if (
                            !userEnteredWage.wageId ||
                            (userEnteredWage.wageId &&
                                wageItem.wage == userEnteredWage.wage)
                        ) {
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A wage already exists.";
                            return this.objCheckHolder;
                        }
                    }
                }
            }
        });
        return this.objCheckHolder;
    }

    isWageExists_ActiveRow(
        wage,
        checkOption,
        userEnteredStartDate,
        userEnteredEndDate,
        userEnteredWage,
        userAction,
        existingActiveWage
    ) {
        //if an active wage row exists, dont allow user to enter another one
        if (existingActiveWage && existingActiveWage.length > 0) {
            if (userEnteredEndDate == "") {
                existingActiveWage.forEach((activeWage) => {
                    if (checkOption == "city") {
                        /* if ((activeWage.wage == userEnteredWage.wage) && (new Date(activeWage.startDate).toString() == new Date(userEnteredWage.startDate).toDateString())) {*/
                        if (!userEnteredWage.wageId) {
                            //a new combo entry
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A current wage exists for this City and State.";
                            return this.objCheckHolder;
                        }
                        ////check if user is trying to edit an existing combo
                        //if ((userEnteredWage) && (new Date(activeWage.startDate).toString() == new Date(userEnteredWage.startDate).toDateString())) {
                        //  this.objCheckHolder.isWageExists = true;
                        //  this.objCheckHolder.errorMessage = "An active wage exists for this State and City.";
                        //  return this.objCheckHolder;
                        //}
                    }

                    if (checkOption == "state") {
                        if (!userEnteredWage.wageId) {
                            this.objCheckHolder.isWageExists = true;
                            this.objCheckHolder.errorMessage =
                                "A current wage exists for this State.";
                            return this.objCheckHolder;
                        }
                    }
                });
            }
        }
        return this.objCheckHolder;
    }

    isWageExists_Overlap(
        wage,
        checkOption,
        userEnteredStartDate,
        userEnteredEndDate,
        userEnteredWage,
        userAction,
        existingActiveWage
    ) {
        if (checkOption == "city") {
            wage.forEach((wageItem) => {
                const stateStartDate = moment(wageItem.startDate).isValid()
                    ? new Date(new Date(wageItem.startDate).toDateString())
                    : "";
                const stateEndDate = moment(wageItem.endDate).isValid()
                    ? new Date(new Date(wageItem.endDate).toDateString())
                    : "";
                userEnteredStartDate = moment(userEnteredStartDate).isValid()
                    ? new Date(new Date(userEnteredStartDate).toDateString())
                    : "";
                userEnteredEndDate = moment(userEnteredEndDate).isValid()
                    ? new Date(new Date(userEnteredEndDate).toDateString())
                    : "";

                //get date range between user entered start and end date
                if (userEnteredEndDate == "") {
                    if (
                        userEnteredStartDate >= stateStartDate &&
                        userEnteredStartDate <= stateEndDate &&
                        userEnteredWage.wageId &&
                        wageItem.wageId != userEnteredWage.wageId
                    ) {
                        this.objCheckHolder.isWageExists = true;
                        this.objCheckHolder.errorMessage =
                            "The entered dates overlap with an existing date combination for this City and State.";
                        return this.objCheckHolder;
                    }
                } else {
                    this.dateranges = [];
                    this.dateranges = this.getDatesInRange(
                        userEnteredStartDate,
                        userEnteredEndDate
                    );
                    this.dateranges.forEach((innerDate) => {
                        let innerDateValue;
                        innerDateValue = innerDate;
                        if (!this.objCheckHolder.isWageExists) {
                            if (
                                innerDateValue >= stateStartDate &&
                                stateEndDate != "" &&
                                innerDateValue <= stateEndDate &&
                                wageItem.wageId != userEnteredWage.wageId
                            ) {
                                this.objCheckHolder.isWageExists = true;
                                this.objCheckHolder.errorMessage =
                                    "The entered dates overlap with an existing date combination for this City and State.";
                            } else if (
                                stateEndDate == "" &&
                                userEnteredWage.wageId != wageItem.wageId
                            ) {
                                //Eg. If there is active row without end date Jun 1 and user enters date range May 31 to Jun 1 this check will prevent user from saving data
                                if (
                                    innerDate >= stateStartDate &&
                                    innerDate <= stateStartDate
                                ) {
                                    this.objCheckHolder.isWageExists = true;
                                    this.objCheckHolder.errorMessage =
                                        "The entered dates overlap with an existing date combination for this City and State.";
                                    return this.objCheckHolder;
                                }
                            }
                        }
                    });
                }
            });
        }

        if (checkOption == "state") {
            wage.forEach((wageItem) => {
                const stateStartDate = moment(wageItem.startDate).isValid()
                    ? new Date(new Date(wageItem.startDate).toDateString())
                    : "";
                const stateEndDate = moment(wageItem.endDate).isValid()
                    ? new Date(new Date(wageItem.endDate).toDateString())
                    : "";
                userEnteredStartDate = moment(userEnteredStartDate).isValid()
                    ? new Date(new Date(userEnteredStartDate).toDateString())
                    : "";
                userEnteredEndDate = moment(userEnteredEndDate).isValid()
                    ? new Date(new Date(userEnteredEndDate).toDateString())
                    : "";

                //get date range between user entered start and end date
                if (userEnteredEndDate == "") {
                    if (
                        userEnteredStartDate >= stateStartDate &&
                        userEnteredStartDate <= stateEndDate &&
                        wageItem.wageId != userEnteredWage.wageId
                    ) {
                        this.objCheckHolder.isWageExists = true;
                        this.objCheckHolder.errorMessage =
                            "The entered dates overlap with an existing date combination for this State.";
                        return this.objCheckHolder;
                    }
                } else {
                    this.dateranges = [];
                    this.dateranges = this.getDatesInRange(
                        userEnteredStartDate,
                        userEnteredEndDate
                    );
                    this.dateranges.forEach((innerDate) => {
                        let innerDateValue;
                        innerDateValue = innerDate;
                        if (!this.objCheckHolder.isWageExists) {
                            if (
                                innerDateValue >= stateStartDate &&
                                stateEndDate != "" &&
                                innerDateValue <= stateEndDate &&
                                wageItem.wageId != userEnteredWage.wageId
                            ) {
                                this.objCheckHolder.isWageExists = true;
                                this.objCheckHolder.errorMessage =
                                    "The entered dates overlap with an existing date combination for this State.";
                            } else if (
                                stateEndDate == "" &&
                                userEnteredWage.wageId != wageItem.wageId
                            ) {
                                //Eg. If there is active row without end date Jun 1 and user enters date range May 31 to Jun 1 this check will prevent user from saving data
                                if (
                                    innerDateValue >= stateStartDate &&
                                    innerDateValue <= stateStartDate
                                ) {
                                    this.objCheckHolder.isWageExists = true;
                                    this.objCheckHolder.errorMessage =
                                        "The entered dates overlap with an existing date combination for this State.";
                                    return this.objCheckHolder;
                                }
                            }
                        }
                    });
                }
            });
        }

        return this.objCheckHolder;
    }

    isWageExists_Future(
        wage,
        checkOption,
        userEnteredStartDate,
        userEnteredEndDate,
        userEnteredWage,
        userAction,
        existingActiveWage
    ) {
        //if active wage exists, just compare the user entered dates against active wage dates and dont allow user to enter future dates

        if (existingActiveWage.length > 0) {
            const activeWageStDate = new Date(
                new Date(existingActiveWage[0].startDate).toDateString()
            );
            const activeWageEndDate = moment(
                existingActiveWage[0].endDate
            ).isValid()
                ? new Date(
                      new Date(existingActiveWage[0].endDate).toDateString()
                  )
                : "";
            userEnteredStartDate = moment(userEnteredStartDate).isValid()
                ? new Date(new Date(userEnteredStartDate).toDateString())
                : "";
            userEnteredEndDate = moment(userEnteredEndDate).isValid()
                ? new Date(new Date(userEnteredEndDate).toDateString())
                : "";

            if (checkOption == "city") {
                /*    if (!userEnteredWage.wageId) {*/
                if (
                    (userEnteredStartDate >= activeWageStDate ||
                        (activeWageEndDate != "" &&
                            userEnteredStartDate <= activeWageEndDate)) &&
                    userEnteredWage.wageId != existingActiveWage[0].wageId
                ) {
                    this.objCheckHolder.isWageExists = true;
                    this.objCheckHolder.errorMessage =
                        "A current wage exists for this City and State. A future dated wage cannot be entered.";
                    return this.objCheckHolder;
                }
                // }
            }

            if (checkOption == "state") {
                /*      if (!userEnteredWage.wageId) {*/
                if (
                    (userEnteredStartDate >= activeWageStDate ||
                        (activeWageEndDate != "" &&
                            userEnteredStartDate <= activeWageEndDate)) &&
                    userEnteredWage.wageId != existingActiveWage[0].wageId
                ) {
                    this.objCheckHolder.isWageExists = true;
                    this.objCheckHolder.errorMessage =
                        "A current wage exists for this State. A future dated wage cannot be entered.";
                    return this.objCheckHolder;
                }
                //}
            }
        }
        return this.objCheckHolder;
    }

    isWageExists_Past(
        wage,
        checkOption,
        userEnteredStartDate,
        userEnteredEndDate,
        userEnteredWage,
        userAction,
        existingActiveWage
    ) {
        //only for cases where user is trying to edit an existing row and force it to a past date row with no end date
        userEnteredStartDate = moment(userEnteredStartDate).isValid()
            ? new Date(new Date(userEnteredStartDate).toDateString())
            : "";
        userEnteredEndDate = moment(userEnteredEndDate).isValid()
            ? new Date(new Date(userEnteredEndDate).toDateString())
            : "";

        if (userEnteredEndDate == "") {
            if (checkOption == "city") {
                if (existingActiveWage.length == 0) {
                    wage.forEach((wageItem) => {
                        const cityStartDate = moment(
                            wageItem.startDate
                        ).isValid()
                            ? new Date(
                                  new Date(wageItem.startDate).toDateString()
                              )
                            : "";
                        const cityEndDate = moment(wageItem.endDate).isValid()
                            ? new Date(
                                  new Date(wageItem.endDate).toDateString()
                              )
                            : "";

                        if (cityEndDate != "") {
                            this.dateranges = [];
                            this.dateranges = this.getDatesInRange(
                                cityStartDate,
                                cityEndDate
                            );
                            this.dateranges.forEach((dtRange) => {
                                if (!this.objCheckHolder.isWageExists) {
                                    if (userEnteredStartDate < dtRange) {
                                        this.objCheckHolder.isWageExists = true;
                                        this.objCheckHolder.errorMessage =
                                            "A current wage cannot be added for a past date when a valid future dated wage exists.";
                                        return this.objCheckHolder;
                                    }
                                }
                            });
                        }
                    });
                }
            }
            if (checkOption == "state") {
                if ((existingActiveWage.length = 0)) {
                    wage.forEach((wageItem) => {
                        const cityStartDate = moment(
                            wageItem.startDate
                        ).isValid()
                            ? new Date(wageItem.startDate).toDateString()
                            : "";
                        const cityEndDate = moment(wageItem.endDate).isValid()
                            ? new Date(wageItem.endDate).toDateString()
                            : "";

                        if (cityEndDate != "") {
                            this.dateranges = [];
                            this.dateranges = this.getDatesInRange(
                                cityStartDate,
                                cityEndDate
                            );
                            this.dateranges.forEach((dtRange) => {
                                if (!this.objCheckHolder.isWageExists) {
                                    if (userEnteredStartDate < dtRange) {
                                        this.objCheckHolder.isWageExists = true;
                                        this.objCheckHolder.errorMessage =
                                            "A current wage cannot be added for a past date when a valid future dated wage exists.";
                                        return this.objCheckHolder;
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }
        return this.objCheckHolder;
    }

    getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());

        while (date <= endDate) {
            this.dateranges.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return this.dateranges;
    }

    validate() {
        let isValidInfo = true;
        if (_.isEmpty(this.wage.state) || this.wage.state == null) {
            isValidInfo = false;
            this.wage.invalidState = true;
        } else {
            this.wage.invalidState = false;
        }
        if (this.wage.wage == "" || this.wage.wage == null) {
            isValidInfo = false;
            this.wage.invalidWage = true;
        } else {
            this.wage.invalidWage = false;
        }
        if (this.wage.startDate == "" || this.wage.startDate == null) {
            isValidInfo = false;
            this.wage.invalidStartDate = true;
        } else {
            this.wage.invalidStartDate = false;
        }
        const stDate = this.datePipe.transform(
            this.wage.startDate,
            "MMM d, yyyy"
        );
        const endDate = this.datePipe.transform(
            this.wage.endDate,
            "MMM d, yyyy"
        );
        if (stDate && endDate && new Date(endDate) < new Date(stDate)) {
            isValidInfo = false;
            this.wage.invalidEndDate = true;
        } else {
            this.wage.invalidEndDate = false;
        }
        return isValidInfo;
    }

    customRateValidation(event) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if (
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 37 ||
            event.keyCode == 39 ||
            event.keyCode == 46 ||
            event.keyCode == 190 ||
            event.keyCode == 110
        ) {
            //accepting valid characters
        } else {
            event.preventDefault();
        }

        const val = this.wage.wage;

        if (
            val &&
            val.toString().split(".").length > 1 &&
            (event.keyCode == 190 || event.keyCode == 110)
        ) {
            event.preventDefault();
        } else {
            if (
                val &&
                val.length >= 3 &&
                event.which !== 8 &&
                !(val.indexOf(".") > -1)
            ) {
                if (event.keyCode == 190 || event.keyCode == 110) {
                } else {
                    this.wage.wage = val.substr(0, 3) + "." + val.substr(4);
                }
            }
        }
    }

    customRateChanged(e) {
        if (Number(this.wage.wage) == 0) {
            this.wage.wage = "";
        } else {
            this.wage.wage = Number(this.wage.wage).toFixed(3).toString();
        }
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.isAdd = _.cloneDeep(this.isAdd);
        this.statesList = this.stateList[0];
        this.allWages = this.allWages;
        this.clonedWages = _.cloneDeep(this.allWages);
        this.wage = this.wage;
        this.wage.startDate = moment(this.wage.startDate).isValid()
            ? new Date(moment(this.wage.startDate).format())
            : "";
        this.wage.endDate = moment(this.wage.endDate).isValid()
            ? new Date(moment(this.wage.endDate).format())
            : "";

        //this.wage.startDate = this.wage.startDate ? new Date(this.wage.startDate) : null;
        //this.wage.endDate = this.wage.endDate ? new Date(this.wage.endDate) : null;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
