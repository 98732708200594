import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import * as moment from "moment";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import { tierPositionRateCard } from "src/app/Shared/Services/tierPositionRateCard.service";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { tierSetupWizardController } from "src/app/Shared/Services/tierSetupWizardController.service";
import { ModalUpsertTierRateCardComponent } from "./modal-upsert-tier-rate-card/modal-upsert-tier-rate-card.component";
@Component({
    selector: "tierPositionWizardTemplateComponent",
    templateUrl: "./tierPositionWizardTemplate.component.html",
    styleUrls: ["./tierPositionWizardTemplate.css"],
    encapsulation: ViewEncapsulation.None,
})
export class tierPositionWizardTemplateComponent
    implements OnInit, OnDestroy, OnChanges
{
    participatingNetworks: any;

    constructor(
        private log: NbcLoggerService,
        public _modalService: BsModalService,
        private _networkGroupService: networkGroupService,
        private _tierPositionRateCard: tierPositionRateCard,
        private _tierSetupWizardController: tierSetupWizardController
    ) {}

    bsModalRef?: BsModalRef;
    componentDestroyed$: Subject<boolean> = new Subject();
    @Input() networkConfig: any;
    @Input() isTabVisible = false;
    /*
     * This is deep clone of the network config. Developer needs to make sure this is in Sync with DB whenever the database values are brough back to the screen
     */
    configLocalStore: any = [];

    isExpandAll = true;
    invalidRateToSubmit = false;
    showAlertMessage = false;
    isCurrentRateDisabled = false;
    testdate = moment("2022-01-12T00:00:00-05:00").format("MMM D, YYYY");
    validation = {
        publishEnabled: false,
    };

    getNetworkConfig(networkGroupId) {
        this._networkGroupService
            .get(networkGroupId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.networkConfig = result.reference;
                    this.InitializeDerivedValues();
                },
                error: () => {
                    this.log.error(
                        "Unable to save rate. Please try again later."
                    );
                },
            });
    }

    //expand all functionality
    expandAll() {
        if (!this.networkConfig) {
            return;
        }

        if (this.isExpandAll) {
            this.isExpandAll = false;
        } else {
            this.isExpandAll = true;
        }

        _.forEach(this.networkConfig.tierPositions, (ratecard, index) => {
            if (ratecard) {
                if (this.isExpandAll) {
                    ratecard.isAccordionOpen = false;
                } else {
                    ratecard.isAccordionOpen = true;
                }
            }
        });
    }
    toggleButton() {}

    ExpandAccordion(tierPosition, accordionStatus: any) {
        const accordions = _.filter(
            this.networkConfig.tierPositions,
            function (role) {
                return role.accordionStatus === "isAccordionOpen";
            }
        );

        if (!accordions) {
            this.isExpandAll = !accordionStatus;
        }
        if (tierPosition)
            tierPosition.isAccordionOpen = !tierPosition.isAccordionOpen;
    }

    InitializeDerivedValues() {
        this.modifyDataset();

        //
        this.isExpandAll = true;

        this.ExpandAccordion(null, this.isExpandAll);

        //deep clone
        this.configLocalStore = _.cloneDeep(this.networkConfig);

        this.validation.publishEnabled = false;

        // validate published button based on publish status

        this.validatePublishEnabled();

        // format the dates to "MM/dd/yyyy" format
        this.formatPublishDates();

        // if we have any publish record (it is incorrect logic)
        this.isCurrentRateDisabled =
            this.networkConfig.networkGroupPublish &&
            //  this.networkConfig.networkGroupPublish.status !== "P" &&
            _.isEmpty(this.networkConfig.networkGroupPublish.asOfDate)
                ? true
                : false;

        //get participating networks
        let arrParticipatingNetworks: any;

        arrParticipatingNetworks = _.map(
            this.networkConfig.networks,
            "description"
        );
        this.participatingNetworks = _.forEach(
            arrParticipatingNetworks,
            (n, key) => {
                if (!_.isEmpty(this.participatingNetworks)) {
                    this.participatingNetworks =
                        this.participatingNetworks + "," + n;
                } else {
                    this.participatingNetworks = n;
                }
            }
        );
        this.participatingNetworks = this.participatingNetworks.join(", ");
    }

    modifyDataset() {
        if (this.networkConfig) {
            this.networkConfig.networkGroupPublish.effectiveDate = this
                .networkConfig.networkGroupPublish?.effectiveDate
                ? moment(
                      this.networkConfig.networkGroupPublish.effectiveDate
                  ).format("MMM D, YYYY")
                : "";
            this.formatPublishDates();

            _.each(this.networkConfig.tierPositions, (tierPosition: any) => {
                if (tierPosition) {
                    _.each(tierPosition.positionTiers, (positionTier: any) => {
                        if (!positionTier.currentRates) {
                            positionTier.currentRates = {
                                posTierId: null,
                                minRate: null,
                                percentile25: null,
                                percentile50: null,
                                percentile75: null,
                                maxRate: null,
                                thresholdRate: null,
                                isCurrentRate: false,
                            };
                        }

                        if (!positionTier.futureRates) {
                            positionTier.futureRates = {
                                posTierId: -1,
                                minRate: null,
                                percentile25: null,
                                percentile50: null,
                                percentile75: null,
                                maxRate: null,
                                thresholdRate: null,
                                isCurrentRate: false,
                                isInvalidRate: false,
                            };
                        }
                    });
                }
            });
        }
    }

    /*
     * method helps to format the dates in the requested format to represent on the screen. Method will get be called from multiple places.
     */
    formatPublishDates() {
        this.networkConfig.networkGroupPublish.publishedDate =
            this.networkConfig.networkGroupPublish &&
            this.networkConfig.networkGroupPublish.publishedDate
                ? moment(
                      this.networkConfig.networkGroupPublish.publishedDate
                  ).format("MM/DD/yyyy")
                : null;

        this.networkConfig.networkGroupPublish.asOfDate =
            this.networkConfig.networkGroupPublish &&
            this.networkConfig.networkGroupPublish.asOfDate
                ? moment(
                      this.networkConfig.networkGroupPublish.asOfDate
                  ).format("MM/DD/yyyy")
                : null;
    }

    // #region Add/Upsert Tier Rate Card

    /*
     * Adding new rate card. If the as of date is present
     */
    AddTierRateCard(rateCard) {
        if (
            !_.isEmpty(this.networkConfig.networkGroupPublish.asOfDate) &&
            this.networkConfig.networkGroupPublish.status === "P" &&
            !rateCard
        ) {
            const msg =
                "When Effective Date is changed or deleted or Future Rate has been edited or a New Tiered Rate Card has been added after ‘Future Rates’ are published, you must re-Publish Rate Card with the new Effective Date.";
            //need to check added 2 null at last
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.showAddTierRateCardModal.bind(this),
                rateCard,
                "Proceed",
                "Cancel",
                null,
                null
            );
        } else {
            this.showAddTierRateCardModal(rateCard);
        }
    }
    showAddTierRateCardModal(rateCard) {
        const clonedRateCard = _.clone(rateCard);
        const initialState: any = {
            self: this,
            networkGroupId: this.networkConfig.id,
            rateCard: clonedRateCard,
            existingRateCards: this.networkConfig.tierPositions,
        };
        const modalOptions = {
            animated: true,
          initialState: initialState,
          ignoreBackdropClick: true,
          keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalUpsertTierRateCardComponent,
            modalOptions
        );

        this.bsModalRef.content.onSync.subscribe((result: any) => {
            if (result && result.statusCode === 1) {
                //Get network configurations
                this.getNetworkConfig(this.networkConfig.id);
            }
        });
    }

    //#endregion

    //#region Effective Date

    onEffectiveDateChanged() {
        //user may click the effective date but may not take any action
        if (!this.isDateChanged()) {
            return;
        }
        //needtoverify  Cancel ARGS Was not present at last so passed null
        if (
            this.networkConfig.networkGroupPublish &&
            this.networkConfig.networkGroupPublish.status === "P" &&
            this.validateEffectiveDate()
        ) {
            const msg =
                "When Effective Date is changed or deleted or Future Rate has been edited or a New Tiered Rate Card has been added after ‘Future Rates’ are published, you must re-Publish Rate Card with the new Effective Date.";
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.effectiveDateChangeWorkflow.bind(this),
                null,
                "Proceed",
                "Cancel",
                this.resetCurrentEffectiveDate.bind(this),
                null
            );
        }
        if (!this.validateEffectiveDate() && this.validateFutureRates()) {
            const msg =
                "'Effective Date' cannot be empty or before current date, please update before Publishing Rates.";
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.resetCurrentEffectiveDate.bind(this),
                null,
                "OK",
                "",
                this.resetCurrentEffectiveDate.bind(this),
                null
            );
        } else {
            this.validatePublishEnabled();
        }
    }

    resetCurrentEffectiveDate() {
        if (
            !this.configLocalStore ||
            !this.configLocalStore.networkGroupPublish
        )
            return;

        this.networkConfig.networkGroupPublish.effectiveDate =
            this.configLocalStore.networkGroupPublish.effectiveDate;
    }

    getMomentDate() {
        return moment(this.networkConfig.networkGroupPublish.effectiveDate);
    }

    /*
     Effective days cannot be past days
     */
    validateEffectiveDate() {
        //TODO
        const hasUserDeleted = _.isEmpty(
            this.networkConfig.networkGroupPublish.effectiveDate?.toString()
        );
        const effectiveDate = moment(
            this.networkConfig.networkGroupPublish.effectiveDate
        );
        const today = new Date();
        const res = effectiveDate.diff(today, "days");
        if (res < 0 || hasUserDeleted) {
            return false;
        }
        return true;
    }

    isDateChanged() {
        //if the date newly provided or removed
        if (
            (!this.networkConfig.networkGroupPublish?.effectiveDate &&
                this.configLocalStore.networkGroupPublish?.effectiveDate) ||
            (this.networkConfig.networkGroupPublish?.effectiveDate &&
                !this.configLocalStore.networkGroupPublish?.effectiveDate)
        ) {
            return true;
        }
        //if storage + model has the date OR the model has date and storage do not have!
        else if (
            this.networkConfig.networkGroupPublish?.effectiveDate &&
            this.configLocalStore.networkGroupPublish?.effectiveDate
        ) {
            return moment(
                this.networkConfig.networkGroupPublish?.effectiveDate
            ).format("DDMMYYYY") !=
                moment(
                    this.configLocalStore.networkGroupPublish?.effectiveDate
                ).format("DDMMYYYY")
                ? true
                : false;
        }
        return false;
    }

    //#endregion

    //#region Publish Rates

    onPublishRateCard() {
        const effectiveDate = moment(
            this.networkConfig.networkGroupPublish.effectiveDate
        );
        const today = moment(new Date()).startOf("day");
        const res = effectiveDate.diff(today, "days");
        //needtoverify added null at
        if (res < 0) {
            const msg =
                " 'Effective Date' cannot be before current date, please update before Publishing Rates.";
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.resetCurrentEffectiveDate.bind(this),
                null,
                "OK",
                "",
                this.resetCurrentEffectiveDate.bind(this),
                null
            );
        } else if (res === 0) {
            const msg = "Do you wish to publish Tiered Rate Cards now?";
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.PublishRateCard.bind(this),
                "P",
                "Proceed",
                "Cancel",
                this.resetCurrentEffectiveDate.bind(this),
                null
            );
        } else if (res > 0) {
            const effectiveDate =
                this.networkConfig.networkGroupPublish &&
                this.networkConfig.networkGroupPublish.effectiveDate
                    ? moment(
                          this.networkConfig.networkGroupPublish.effectiveDate
                      ).format("MM/DD/yyyy")
                    : null;
            const msg =
                "Do you wish to publish Tiered Rate Cards on " + effectiveDate;
            this.showValidationErrorModal(
                "Tier Rate Card",
                msg,
                true,
                this.PublishRateCard.bind(this),
                "P",
                "Proceed",
                "Cancel",
                this.resetCurrentEffectiveDate.bind(this),
                null
            );
        } else {
            this.PublishRateCard("P");
        }
    }

    PublishRateCard(status) {
        this.showAlertMessage = false;

        if (!this.validateFutureRates()) {
            this.showAlertMessage = true;
            this.log.showError(
                "Rates need to be entered for each Future Rate for all Tiers and Range"
            );

            this.isExpandAll = true;
            this.expandAll();
            return;
        }

        if (_.isEmpty(this.networkConfig.tierPositions)) {
            this.showAlertMessage = true;
            this.log.showError("Rates card is not available to publish");
            this.isExpandAll = true;
            this.expandAll();
            return;
        }

        this.networkConfig.networkGroupPublish.status = status ? status : "D";

        this._networkGroupService
            .publishNetworkGroup(
                this.networkConfig.id,
                this.networkConfig.networkGroupPublish
            )
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    if (result.reference) {
                        this.log.showSuccess(
                            "Success! Tier RateCard has been published"
                        );

                        //refresh the screen
                        this.getNetworkConfig(this.networkConfig.id);
                    } else {
                        this.log.showError(
                            "Unable to publish tier ratecards. Please try again later."
                        );
                    }
                },
                (err) => {
                    this.log.error(
                        "Unable to publish tier ratecards. Please try again later. "
                    );
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    /*
     *  This method updates effective date along with the status to "D".
     * Updating Effective date will update all the possible future data for the earlier effective date
     * to the newly provided date/ to the blank
     */
    manageEffectiveDate() {
        this.networkConfig.networkGroupPublish.status = "D";

        this._networkGroupService
            .manageEffectiveDate(
                this.networkConfig.id,
                this.networkConfig.networkGroupPublish
            )
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.getPublishInfo(this.networkConfig.id);
                },
                (err) => {
                    this.log.error(
                        "Unable to update effective date. Please try again later."
                    );
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    effectiveDateChangeWorkflow() {
        this.enablePublishRateCard();
        if (this.networkConfig.networkGroupPublish.status === "P") {
            this.networkConfig.networkGroupPublish.status === "D";
        }

        this.manageEffectiveDate();
    }

    enablePublishRateCard() {
        this.validation.publishEnabled = true;
    }

    validateDateInput(event) {
        if (event) {
            if (event?.shiftKey === true) {
                event.preventDefault();
            }

            if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 8 ||
                event.keyCode == 9 ||
                event.keyCode == 46 ||
                event.keyCode == 190 ||
                event.keyCode == 110
            ) {
                //accepting valid characters
            }
        }
        setTimeout(() => this.onEffectiveDateChanged(), 200);
    }

    //validate publish criteria
    validatePublishEnabled() {
        if (this.networkConfig) {
            this.validation.publishEnabled =
                this.networkConfig.networkGroupPublish &&
                this.networkConfig.networkGroupPublish.effectiveDate &&
                this.networkConfig.networkGroupPublish.status !== "P"
                    ? true
                    : false;
        }
    }

    //#endregion

    // #region Rate change

    /* EVENT Handler
     * method is called when rate is changed in the text box.
     * Applicable for both current as well as future
     *
     */
    rateChanged(tierPositionId, positionTier, isCurrentRate, prop) {
        //System should not allow to make current rates blank, if user makes it blank and leave the field, cell should retain old rate
        if (
            isCurrentRate &&
            positionTier.currentRates &&
            (parseFloat(positionTier.currentRates[prop]) <= 0 ||
                !positionTier.currentRates[prop])
        ) {
            this.retriveOldValues(
                tierPositionId,
                positionTier,
                isCurrentRate,
                prop
            );
            return;
        }

        if (
            this.hasValueChanged(
                tierPositionId,
                positionTier,
                isCurrentRate,
                prop
            )
        ) {
            //for future rates only after publish
            if (
                this.networkConfig.networkGroupPublish &&
                this.networkConfig.networkGroupPublish.status === "P" &&
                !isCurrentRate
            ) {
                const msg =
                    "When Effective Date is changed or deleted or Future Rate has been edited or a New Tiered Rate Card has been added after ‘Future Rates’ are published, you must re-Publish Rate Card with the new Effective Date.";
                this.showValidationErrorModal(
                    "Tier Rate Card",
                    msg,
                    true,
                    this.updateRate.bind(this),
                    [
                        tierPositionId,
                        positionTier,
                        isCurrentRate,
                        this.networkConfig.id,
                        prop,
                    ],
                    "Proceed",
                    "Cancel",
                    this.retriveOldValues.bind(this),
                    [tierPositionId, positionTier, isCurrentRate, prop]
                );
            } else {
                this.updateRate([
                    tierPositionId,
                    positionTier,
                    isCurrentRate,
                    this.networkConfig.id,
                    prop,
                ]);
            }
        }
    }

    validateFutureRates() {
        let isValid = true;
        if (this.networkConfig) {
            _.each(this.networkConfig.tierPositions, (tierPosition: any) => {
                if (tierPosition && isValid) {
                    _.each(tierPosition.positionTiers, (positionTier: any) => {
                        if (!positionTier.futureRates) {
                            isValid = false;
                        } else {
                            if (
                                !positionTier.futureRates.minRate ||
                                !positionTier.futureRates.percentile25 ||
                                !positionTier.futureRates.percentile50 ||
                                !positionTier.futureRates.percentile75 ||
                                !positionTier.futureRates.maxRate
                            )
                                isValid = false;
                        }
                    });
                }
            });
            return isValid;
        }
        return true;
    }

    /*
     * persisting values
     */
    updateRate(args: any) {
        const tierPositionId = args[0];
        const positionTier = args[1];
        const isCurrentRate = args[2];
        const networkGroupId = args[3];
        const prop = args[4];
        //[0] tierPositionId,
        //[1]   positionTier,
        //[2]   isCurrentRate,
        //[3]   networkGroupId,
        //[4]   prop
        this._tierPositionRateCard
            .upsertRate(
                tierPositionId,
                positionTier,
                isCurrentRate,
                networkGroupId
            )
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.updateCurrentModel(
                        tierPositionId,
                        positionTier,
                        isCurrentRate,
                        prop
                    );
                },
                (err) => {
                    this.log.error(
                        "Unable to save rate. Please try again later. "
                    );
                },
                () => {
                    // we need to enable the publish button only if Future rate is changed. Current rates need not to be published. Future rates follows workflow.
                    if (
                        !isCurrentRate &&
                        this.networkConfig.networkGroupPublish &&
                        this.networkConfig.networkGroupPublish.status == "P"
                    ) {
                        this.getPublishInfo(networkGroupId); //sync up local store with angular model
                        this.InitializeDerivedValues();
                    }
                    this.syncLocalStorage(
                        tierPositionId,
                        positionTier,
                        isCurrentRate,
                        prop
                    ); //sync up local store with angular model
                }
            );
    }

    /*
     * Method is responsible to fetch and update the local config store with latet publish info.
     */
    getPublishInfo(networkGroupId) {
        this._networkGroupService
            .getPublishInfo(networkGroupId)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.networkConfig.networkGroupPublish = result.reference;
                    this.InitializeDerivedValues();
                },
                (err) => {
                    this.log.error(
                        "Unable to get publish information. Please try again later."
                    );
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    /*
     * If we need to sync the local storage with latest user updates, use this method.
     */
    syncLocalStorage(tierPositionId, newpositionTier, isCurrentRate, prop) {
        const oldpositionTier = this.oldTierPositionFromLocalStorage(
            tierPositionId,
            newpositionTier
        );

        if (isCurrentRate) {
            oldpositionTier.currentRates[prop] =
                newpositionTier.currentRates[prop];
        } else {
            oldpositionTier.futureRates[prop] =
                newpositionTier.futureRates[prop];
        }
    }

    /*
     * Use this method to update current model as soon as user does an action on the rates grid
     */

    updateCurrentModel(tierPositionId, newpositionTier, isCurrentRate, prop) {
        const tierPosition = _.find(
            this.networkConfig.tierPositions,
            function (tp) {
                return tp.id === tierPositionId;
            }
        );
        if (!_.isEmpty(tierPosition)) {
            //update last update time to current time. This will not be persisted in Db.
            // This code will provide instant effect when user clicks out of the cell after rate update
            tierPosition.lastUpdateDate = moment().format(
                "YYYY-MM-DDTHH:mm:ss"
            );

            //SCOR-13791 after validation, the model attribute is converted text. If it is number, it will be good for validations.
            if (isCurrentRate) {
                newpositionTier.currentRates[prop] = parseFloat(
                    newpositionTier.currentRates[prop]
                );
            } else {
                newpositionTier.futureRates[prop] = newpositionTier.futureRates[
                    prop
                ]
                    ? parseFloat(newpositionTier.futureRates[prop])
                    : "";
            }
        }
    }

    /*
     * Method responsible to find the existing object from the local storage
     */
    oldTierPositionFromLocalStorage(tierPositionId, newpositionTier) {
        if (!this.configLocalStore || !this.configLocalStore.tierPositions)
            return null;

        const tierPosition = _.find(
            this.configLocalStore.tierPositions,
            function (tp) {
                return tp.id === tierPositionId;
            }
        );

        if (!tierPosition) {
            return;
        }

        const oldpositionTier = _.find(
            tierPosition.positionTiers,
            function (pt) {
                return pt.id === newpositionTier.id;
            }
        );

        return oldpositionTier;
    }

    /*
     * Method uses local storage varialble to retrieve the old value. We need to keep the configLocalStore updated whenever the values are brought from the server
     */
    retriveOldValues(tierPositionId, newpositionTier, isCurrentRate, prop) {
        const oldpositionTier = this.oldTierPositionFromLocalStorage(
            tierPositionId,
            newpositionTier
        );

        if (isCurrentRate) {
            newpositionTier.currentRates[prop] =
                oldpositionTier.currentRates[prop];
        } else {
            newpositionTier.futureRates[prop] =
                oldpositionTier.futureRates[prop];
        }
    }

    //checking if the value in textbox has changed
    // it will be used while updating in db
    hasValueChanged(tierPositionId, newpositionTier, isCurrentRate, prop) {
        if (!this.configLocalStore || !this.configLocalStore.tierPositions)
            return false;

        const tierPosition = _.find(
            this.configLocalStore.tierPositions,
            function (tp) {
                return tp.id === tierPositionId;
            }
        );

        if (!tierPosition) {
            return false;
        }

        const oldpositionTier = _.find(
            tierPosition.positionTiers,
            function (pt) {
                return pt.id === newpositionTier.id;
            }
        );

        if (isCurrentRate) {
            // existing current rate may be empty.
            if (
                _.isEmpty(oldpositionTier.currentRates) ||
                oldpositionTier.currentRates[prop] == null
            ) {
                return _.isEmpty(newpositionTier.currentRates) ||
                    newpositionTier.currentRates[prop] == null
                    ? false
                    : true;
            } else {
                return newpositionTier.currentRates[prop] ==
                    oldpositionTier.currentRates[prop]
                    ? false
                    : true;
            }
        } else {
            //existing future rate may be empty
            if (
                _.isEmpty(oldpositionTier.futureRates) ||
                oldpositionTier.futureRates[prop] == null
            ) {
                return _.isEmpty(newpositionTier.futureRates) ||
                    newpositionTier.futureRates[prop] == null
                    ? false
                    : true;
            } else {
                return newpositionTier.futureRates[prop] ==
                    oldpositionTier.futureRates[prop]
                    ? false
                    : true;
            }
        }
    }

    /*
     * Ensure that the values are in ---.--- format.
     * No characters or special chars
     */
    validateRate(event, val, prop) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }

        if (val && val[prop] && val[prop].length >= 1) {
            if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 8 ||
                event.keyCode == 9 ||
                // event.keyCode == 37 || event.keyCode == 39 ||
                event.keyCode == 46 ||
                event.keyCode == 190 ||
                event.keyCode == 110
            ) {
                //accepting valid characters
            } else {
                event.preventDefault();
            }
        } else {
            if (
                (event.keyCode > 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 8 ||
                event.keyCode == 9 ||
                // event.keyCode == 37 || event.keyCode == 39 ||
                event.keyCode == 46 ||
                event.keyCode == 190 ||
                event.keyCode == 110
            ) {
                //accepting valid characters
            } else {
                event.preventDefault();
            }
        }
        //if ($(this).val().indexOf('.') !== -1 && event.keyCode == 190) { //SCOR-11961 not accepting (.) dot from num pad
        if (
            val &&
            val[prop] &&
            val[prop].toString().split(".").length > 1 &&
            (event.keyCode == 190 || event.keyCode == 110)
        ) {
            event.preventDefault();
        } else {
            if (
                val &&
                val[prop] &&
                val[prop].toString().length >= 3 &&
                event.which !== 8 &&
                !(val[prop].toString().indexOf(".") > -1)
            ) {
                if (event.keyCode == 190 || event.keyCode == 110) {
                    //do nothing
                } else {
                    val[prop] =
                        val[prop].toString().substr(0, 3) +
                        "." +
                        val[prop].toString().substr(4);
                }
            }
        }
    }

    // #endregion

    // #region Validations

    /*
     * Generic function to show the modal. All parameters are customizable per need
     */
    showValidationErrorModal(
        title,
        message,
        canUpdate,
        callback,
        args,
        confirmText,
        cancelText,
        cancelCallback,
        cancelargs
    ) {
        this.log.trace("showValidationErrorModal");

        const initialState: any = {
            self: this,
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText || "Continue",
            cancelText: !canUpdate ? "Close" : cancelText || undefined,
            hideConfirm: !canUpdate,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe(() => {
            callback(args);
            // this.findPrimaryBroadcastingDate();
        });
        this.bsModalRef.content.onRejection.subscribe(() => {
            if (cancelCallback) {
                if (cancelargs) cancelCallback(...cancelargs);
                else cancelCallback();
            }
        });
    }

    closeAlert() {}

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "networkConfig") {
                this.networkConfig = changes["networkConfig"]["currentValue"];
                this.ngOnInit();
            }
        }
    }

    ngOnInit(): void {
        if (this.networkConfig) {
            this.InitializeDerivedValues();
        }
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
