import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    ElementRef,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { PaginationComponent } from "ngx-bootstrap/pagination";
import { debounceTime, switchMap } from "rxjs/operators";
import { Observable, Subject, of } from "rxjs";

@Component({
    selector: "nbc-pagination",
    templateUrl: "./pagination.component.html",
})
export class NbcPaginationComponent implements AfterViewInit, OnChanges {
    constructor(private elementRef: ElementRef) {}

    @ViewChild(PaginationComponent) pagination: PaginationComponent;

    @Input() totalItems: any | undefined;
    @Input() itemsPerPage: any | undefined;
    @Input() onPageChanged: any | undefined;
    @Input() currentPage: any | undefined;
    @Input() maxSize = 7;

    @Output() currentPageChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageChanged = new EventEmitter<any>();

    private pageChangedSubject = new Subject<any>();

    rotate = false;

    pageChangedClicked($event) {
        this.pageChangedSubject.next($event);
    }

    //suraj: required this code snippet. The ngx control will send multiple page change events.
    // this will create page change on the grid multiple times causing redundant calls.
    // below code avoid this
    emitPageChangeEvent() {
        this.pageChangedSubject
            .pipe(
                debounceTime(300), // wait for 300ms after the last event
                switchMap(($event: any) => {
                    this.currentPageChange.emit($event.page);
                    this.pageChanged.emit($event);
                    return of([]);
                })
            )
            .subscribe((results: any) => {
                // no action required
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        const itemsPerPageChanged = changes["itemsPerPage"];
        if (
            itemsPerPageChanged &&
            !itemsPerPageChanged.firstChange &&
            itemsPerPageChanged.currentValue !=
                itemsPerPageChanged.previousValue
        ) {
            this.pagination.selectPage(1);
        }
    }

    ngAfterViewInit() {
        const filteredNodes = this.elementRef.nativeElement.querySelectorAll(
            ".pagination-prev,.pagination-next"
        );
        _.each(filteredNodes, (ele) => {
            ele.setAttribute("ng-if", "directionLinks");
        });

        this.emitPageChangeEvent();
    }
}
