import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseUrlHelperService } from "./baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";

@Injectable({ providedIn: "root" })
export class TimeZoneHelperService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService
    ) {}

    getTimezoneOffset(): string {
        const offset = new Date().getTimezoneOffset();
        const offsetInHr =
            (offset < 0 ? "+" : "-") + // Note the reversed sign!
            parseInt(Math.abs(offset / 60).toString(), 10) +
            ":" +
            Math.abs(offset % 60).toString();
        return offsetInHr;
    }

    getTimezone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    getAllTimeZones() {
        return this.http.get(this._apiroot.baseUrl + "timezones");
    }

    searchTimeZone(timeZone: any): any {
        const timeZones: any = {
            "Afghanistan Standard Time": { abbr: "AFT", offset: 4.5 },
            "Alaskan Standard Time": { abbr: "AKST", offset: -9 },
            "Alaskan Daylight Time": { abbr: "AKDT", offset: -8 },
            "Arab Standard Time": { abbr: "AST", offset: 3 },
            "Arabian Standard Time": { abbr: "AST", offset: 4 },
            "Arabic Standard Time": { abbr: "AST", offset: 3 },
            "Arabic Daylight Time": { abbr: "ADT", offset: 4 },
            "Armenia Summer Time": { abbr: "AMST", offset: 5 },
            "Armenia Time": { abbr: "AMT", offset: 4 },
            "Argentina Standard Time": { abbr: "AST", offset: -3 },
            "Argentina Daylight Time": { abbr: "ADT", offset: -2 },
            "ASEAN Common Time": { abbr: "ACT", offset: 8 },
            "Atlantic Standard Time": { abbr: "AST", offset: -4 },
            "Atlantic Daylight Time": { abbr: "ADT", offset: -3 },
            "AUS Central Standard Time": { abbr: "ACST", offset: 9.5 },
            "AUS Eastern Standard Time": { abbr: "AEST", offset: 10 },
            "AUS Eastern Daylight Time": { abbr: "AEDT", offset: 11 },
            "Australian Central Daylight Time": { abbr: "ACDT", offset: 10.5 },
            "Australian Central Standard Time": { abbr: "ACST", offset: 9.5 },
            "Australian Eastern Daylight Time": { abbr: "AEDT", offset: 11 },
            "Australian Eastern Standard Time": { abbr: "AEST", offset: 10 },
            "Australian Western Daylight Time": { abbr: "AWDT", offset: 9 },
            "Australian Western Standard Time": { abbr: "AWST", offset: 8 },
            "Azerbaijan Standard Time": { abbr: "AZT", offset: 4 },
            "Azerbaijan Daylight Time": { abbr: "AZST", offset: 5 },
            "Azores Standard Time": { abbr: "AZOT", offset: -1 },
            "Azores Daylight Time": { abbr: "AZOST", offset: 0 },
            "Bahia Standard Time": { abbr: "BST", offset: -3 },
            "Bahia Daylight Time": { abbr: "BDT", offset: -2 },
            "Bangladesh Standard Time": { abbr: "BST", offset: 6 },
            "Bangladesh Daylight Time": { abbr: "BDT", offset: 7 },
            "Belarus Standard Time": { abbr: "BST", offset: 3 },
            "Belarus Daylight Time": { abbr: "BDT", offset: 4 },
            "Brasilia Time": { abbr: "BRT", offset: -3 },
            "Brunei Time": { abbr: "BDT", offset: 8 },
            "British Indian Ocean Time": { abbr: "BIOT", offset: 6 },
            "Baker Island Time": { abbr: "BIT", offset: -12 },
            "Bolivia Time": { abbr: "BOT", offset: -4 },
            "British Summer Time": { abbr: "BST", offset: 1 },
            "Bhutan Time": { abbr: "BTT", offset: 6 },
            "Canada Central Standard Time": { abbr: "CCST", offset: -6 },
            "Cabo Verde Standard Time": { abbr: "CVT", offset: -1 },
            "Cape Verde Standard Time": { abbr: "CVT", offset: -1 },
            "Cape Verde Time": { abbr: "CVT", offset: -1 },
            "Caucasus Standard Time": { abbr: "CST", offset: 4 },
            "Caucasus Daylight Time": { abbr: "CDT", offset: 5 },
            "Cen. Australia Standard Time": { abbr: "CAST", offset: 9.5 },
            "Cen. Australia Daylight Time": { abbr: "CADT", offset: 10.5 },
            "Central America Standard Time": { abbr: "CAST", offset: -6 },
            //"Central Africa Time": { abbr: "CAT", offset: 2 },
            //"Central Daylight Time": { abbr: "CDT", offset: -5 },
            //"Central Indonesia Time": { abbr: "CIT", offset: 8 },
            //"Central Western Standard Time": { abbr: "CWST", offset: 8.75 },
            "Central Asia Standard Time": { abbr: "CAST", offset: 6 },
            "Central Brazilian Standard Time": { abbr: "CBST", offset: -4 },
            "Central Brazilian Daylight Time": { abbr: "CBDT", offset: -3 },
            "Central Europe Standard Time": { abbr: "CEST", offset: 1 },
            "Central Europe Daylight Time": { abbr: "CEDT", offset: 2 },
            "Central European Time": { abbr: "CET", offset: 1 },
            "Central European Standard Time": { abbr: "CEST", offset: 1 },
            "Central European Daylight Time": { abbr: "CEDT", offset: 2 },
            "Central Pacific Standard Time": { abbr: "CPST", offset: 11 },
            "Central Standard Time": { abbr: "CST", offset: -6 },
            "Central Daylight Time": { abbr: "CDT", offset: -5 },
            "Central Standard Time (Mexico)": { abbr: "CST", offset: -6 },
            "Central Daylight Time (Mexico)": { abbr: "CDT", offset: -5 },
            "China Standard Time": { abbr: "CST", offset: 8 },
            //"Chamorro Standard Time": { abbr: "ChST", offset: 10 },
            //"Chatham Daylight Time": { abbr: "CHADT", offset: 13.75 },
            //"Chatham Standard Time": { abbr: "CHAST", offset: 12.75 },
            //"Choibalsan": { abbr: "CHOT", offset: -8 },
            //"Chile Summer Time": { abbr: "CLST", offset: -3 },
            //"Chile Standard Time": { abbr: "CLT", offset: -4 },
            //"China Time": { abbr: "CT", offset: 8 },
            //"Christmas Island Time": { abbr: "CXT", offset: 7 },
            //"Chuuk Time": { abbr: "CHUT", offset: 10 },
            //"Clipperton Island Standard Time": { abbr: "CIST", offset: -8 },
            //"Cocos Islands Time": { abbr: "CCT", offset: 6.5 },
            //"Colombia Summer Time": { abbr: "COST", offset: -4 },
            //"Colombia Time": { abbr: "COT", offset: -5 },
            //"Cook Island Time": { abbr: "CKT", offset: -10 },
            "Dateline Standard Time": { abbr: "DST", offset: -12 },
            "E. Africa Standard Time": { abbr: "EAST", offset: 3 },
            "E. Australia Standard Time": { abbr: "EAST", offset: 10 },
            "E. Europe Standard Time": { abbr: "EEST", offset: 2 },
            "E. Europe Daylight Time": { abbr: "EEDT", offset: 3 },
            "E. South America Standard Time": { abbr: "ESAST", offset: -3 },
            "E. South America Daylight Time": { abbr: "ESADT", offset: -2 },
            "Eastern Standard Time": { abbr: "EST", offset: -5 },
            "Eastern Daylight Time": { abbr: "EDT", offset: -4 },
            "Egypt Standard Time": { abbr: "EST", offset: 2 },
            "Egypt Daylight Time": { abbr: "EDT", offset: 3 },
            "Ekaterinburg Standard Time": { abbr: "YEKT", offset: 5 },
            "Russia TZ 4 Daylight Time": { abbr: "YEKT", offset: 6 },
            "Fiji Standard Time": { abbr: "FJT", offset: 12 },
            "Fiji Daylight Time": { abbr: "FJT", offset: 13 },
            "FLE Standard Time": { abbr: "EET", offset: 2 },
            "FLE Daylight Time": { abbr: "EET", offset: 3 },
            "Georgian Standard Time": { abbr: "GET", offset: 4 },
            "GMT Standard Time": { abbr: "GMT", offset: 0 },
            "GMT Daylight Time": { abbr: "GMT", offset: 1 },
            "Greenland Standard Time": { abbr: "GNST", offset: -3 },
            "Greenland Daylight Time": { abbr: "GNDT", offset: -2 },
            "Greenwich Standard Time": { abbr: "GST", offset: 0 },
            "GTB Standard Time": { abbr: "GTBST", offset: 2 },
            "GTB Daylight Time": { abbr: "GTBDT", offset: 3 },
            "Hawaiian Standard Time": { abbr: "HAST", offset: -10 },
            "India Standard Time": { abbr: "IST", offset: 5.5 },
            "Iran Standard Time": { abbr: "IRST", offset: 3.5 },
            "Iran Daylight Time": { abbr: "IRDT", offset: 4.5 },
            "Israel Standard Time": { abbr: "ISST", offset: 2 },
            "Jerusalem Daylight Time": { abbr: "ISDT", offset: 3 },
            "Jordan Standard Time": { abbr: "JST", offset: 2 },
            "Jordan Daylight Time": { abbr: "JDT", offset: 3 },
            "Kaliningrad Standard Time": { abbr: "EEST", offset: 2 },
            "Russia TZ 1 Daylight Time": { abbr: "EEDT", offset: 3 },
            "Kamchatka Standard Time": { abbr: "PETT", offset: 12 },
            "Kamchatka Daylight Time": { abbr: "PETT", offset: 13 },
            "Korea Standard Time": { abbr: "KST", offset: 9 },
            "Libya Standard Time": { abbr: "LST", offset: 2 },
            "Libya Daylight Time": { abbr: "LDT", offset: 3 },
            "Line Islands Standard Time": { abbr: "LIST", offset: 14 },
            "Magadan Standard Time": { abbr: "MAGT", offset: 10 },
            "Magadan Daylight Time": { abbr: "MAGT", offset: 11 },
            "Mauritius Standard Time": { abbr: "MUT", offset: 4 },
            "Mauritius Daylight Time": { abbr: "MUT", offset: 5 },
            "Mid-Atlantic Standard Time": { abbr: "MAT", offset: -2 },
            "Mid-Atlantic Daylight Time": { abbr: "MAT", offset: -1 },
            "Middle East Standard Time": { abbr: "MEST", offset: 2 },
            "Middle East Daylight Time": { abbr: "MEDT", offset: 3 },
            "Montevideo Standard Time": { abbr: "MVST", offset: -3 },
            "Montevideo Daylight Time": { abbr: "MVDT", offset: -2 },
            "Morocco Standard Time": { abbr: "MOST", offset: 0 },
            "Morocco Daylight Time": { abbr: "MODT", offset: 1 },
            "Mountain Standard Time": { abbr: "MST", offset: -7 },
            "Mountain Daylight Time": { abbr: "MDT", offset: -6 },
            "Mountain Standard Time (Mexico)": { abbr: "MST", offset: -7 },
            "Mountain Daylight Time (Mexico)": { abbr: "MDT", offset: -6 },
            "Myanmar Standard Time": { abbr: "MYST", offset: 6.5 },
            "N. Central Asia Standard Time": { abbr: "NCAST", offset: 6 },
            "N. Central Asia Daylight Time": { abbr: "NCADT", offset: 7 },
            "Namibia Standard Time": { abbr: "NMST", offset: 1 },
            "Namibia Daylight Time": { abbr: "NMDT", offset: 2 },
            "Nepal Standard Time": { abbr: "NPT", offset: 5.75 },
            "New Zealand Standard Time": { abbr: "NZST", offset: 12 },
            "New Zealand Daylight Time": { abbr: "NZDT", offset: 13 },
            "Newfoundland Standard Time": { abbr: "NST", offset: -3.5 },
            "Newfoundland Daylight Time": { abbr: "NDT", offset: -2.5 },
            "North Asia East Standard Time": { abbr: "IRKT", offset: 8 },
            "North Asia East Daylight Time": { abbr: "IRKT", offset: 9 },
            "North Asia Standard Time": { abbr: "KRAT", offset: 7 },
            "North Asia Daylight Time": { abbr: "KRAT", offset: 8 },
            "Pacific SA Standard Time": { abbr: "PSAST", offset: -4 },
            "Pacific SA Daylight Time": { abbr: "PSADT", offset: -3 },
            "Pacific Standard Time": { abbr: "PST", offset: -8 },
            "Pacific Daylight Time": { abbr: "PDT", offset: -7 },
            "Pacific Standard Time (Mexico)": { abbr: "PST", offset: -8 },
            "Pacific Daylight Time (Mexico)": { abbr: "PDT", offset: -7 },
            "Pakistan Standard Time": { abbr: "PKT", offset: 5 },
            "Pakistan Daylight Time": { abbr: "PKT", offset: 6 },
            "Paraguay Standard Time": { abbr: "PRST", offset: -4 },
            "Paraguay Daylight Time": { abbr: "PRDT", offset: -3 },
            "Romance Standard Time": { abbr: "RST", offset: 1 },
            "Romance Daylight Time": { abbr: "RDT", offset: 2 },
            "Russia Time Zone 10": { abbr: "RT1ST", offset: 11 },
            "Russia Time Zone 11": { abbr: "RT1ST", offset: 12 },
            "Russia Time Zone 3": { abbr: "RT3ST", offset: 4 },
            "Russian Standard Time": { abbr: "RST", offset: 3 },
            "Russian Daylight Time": { abbr: "RDT", offset: 4 },
            "SA Eastern Standard Time": { abbr: "SAEST", offset: -3 },
            "SA Pacific Standard Time": { abbr: "SAPST", offset: -5 },
            "SA Western Standard Time": { abbr: "SAWST", offset: -4 },
            "Samoa Standard Time": { abbr: "SMST", offset: 13 },
            "Samoa Daylight Time": { abbr: "SMDT", offset: 14 },
            "SE Asia Standard Time": { abbr: "THA", offset: 7 },
            "Singapore Standard Time": { abbr: "SNST", offset: 8 },
            "South Africa Standard Time": { abbr: "SAST", offset: 2 },
            "Sri Lanka Standard Time": { abbr: "SLT", offset: 5.5 },
            "Syria Standard Time": { abbr: "SST", offset: 2 },
            "Syria Daylight Time": { abbr: "SDT", offset: 3 },
            "Taipei Standard Time": { abbr: "TIST", offset: 8 },
            "Tasmania Standard Time": { abbr: "TAST", offset: 10 },
            "Tasmania Daylight Time": { abbr: "TADT", offset: 11 },
            "Tokyo Standard Time": { abbr: "TST", offset: 9 },
            "Tonga Standard Time": { abbr: "PHOT", offset: 13 },
            "Turkey Standard Time": { abbr: "EET", offset: 2 },
            "Turkey Daylight Time": { abbr: "EET", offset: 3 },
            "Ulaanbaatar Standard Time": { abbr: "UST", offset: 8 },
            "US Eastern Standard Time": { abbr: "UEST", offset: -5 },
            "US Eastern Daylight Time": { abbr: "UEDT", offset: -4 },
            "US Mountain Standard Time": { abbr: "UMST", offset: -7 },
            UTC: { abbr: "CUT", offset: 0 },
            "UTC+12": { abbr: "U", offset: 12 },
            "UTC-02": { abbr: "U", offset: -2 },
            "UTC-11": { abbr: "U", offset: -11 },
            "Venezuela Standard Time": { abbr: "VST", offset: -4.5 },
            "Vladivostok Standard Time": { abbr: "VLAT", offset: 10 },
            "Vladivostok Daylight Time": { abbr: "VLAT", offset: 11 },
            "W. Australia Standard Time": { abbr: "AWST", offset: 8 },
            "W. Australia Daylight Time": { abbr: "AWDT", offset: 9 },
            "W. Central Africa Standard Time": { abbr: "ECT", offset: 1 },
            "W. Europe Standard Time": { abbr: "WET", offset: 1 },
            "W. Europe Daylight Time": { abbr: "WET", offset: 2 },
            "West Asia Standard Time": { abbr: "WAST", offset: 5 },
            "West Pacific Standard Time": { abbr: "WPST", offset: 10 },
            "Yakutsk Standard Time": { abbr: "YAKT", offset: 9 },
            "Yakutsk Daylight Time": { abbr: "YAKT", offset: 10 },
        };
        return timeZones[timeZone];
    }
}
