<header id="col-main-hdr"><h1>Create Season or Event</h1></header>
<div class="col-main">
  <nav class="breadcrumb">
    <ul>

      <li home></li>
      <li>&gt;</li>
      <li>Create a Season or Event</li>
    </ul>
  </nav>
  <div class="page-container">
    <div class="container-fluid">

      <nbc-alert></nbc-alert>

      <div class="season-item" season-item *ngFor="let eventType of eventTypes">
        <img src="/assets/image/{{eventType.type}}/{{eventType.imageId || 0}}"
             (mouseenter)="onMouseEnter(eventType.imageId)" />
        <div #seasonlogo id="{{eventType.imageId}}"
             class="season-overlay"
             (mouseleave)="onMouseLeave(eventType.imageId)">

          <a class="btn btn-default btn-default-grad"
             title="Create Season"
             (click)="CreateSeason(eventType.id)">Create Season</a>

          <a class="nbc-router-link btn btn-default btn-default-grad"
             routerLink="/Events/createEvent/{{eventType.id}}">
            Create Event
          </a>
          <!-- <a  class="btn btn-default btn-default-grad" title="Create Event">Create Event</a> -->
          <div class="season-overlay-bg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
