<div class="modal-header">
  <h4 class="modal-title">POSITION REQUEST</h4>
  <button type="button" class="btn-close"
          aria-hidden="true" (click)="modalCancel()">
  </button>
</div>
<div class="modal-body">
  Please review and make any necessary updates before submitting your request
  <br />

  <span style="color:red"
        *ngIf="eventDetails && (!positionDetails || !positionDetails.payRate)">
    Pay Rate should be available to request the position for Onboarding
  </span>
  <form #frmPositionRequest="ngForm">
    <nbc-modal-alert></nbc-modal-alert>
    <div class="row">
      <div class="col-md-12">
        <div class="form-box">
          <div class="row" *ngIf="eventDetails">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>Hiring Manager</b>
                  <div class="form-group">
                    <input type="text"
                           [disabled]="true"
                           [(ngModel)]="hiringManger"
                           name="hiringManager"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b># of Positions</b>
                  <div class="form-group">
                    <input type="text"
                           name="noOfPosition"
                           [(ngModel)]="NoOfPositions"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <b>Event Name</b>
              <div class="form-group"
                   *ngIf=" eventDetails && eventDetails.eventName">
                <input type="text"
                       name="eventName"
                       [(ngModel)]="eventDetails.eventName"
                       class="form-control req-modal"
                       placeholder=""
                       maxlength="100"
                       [disabled]="true" />
              </div>
            </div>
            <div class="col-md-12">
              <b>Event Venue</b>
              <div class="form-group"
                   *ngIf=" eventDetails && eventDetails.venue && eventDetails.venue.name">
                <input type="text"
                       name="venueName"
                       readonly
                       class="form-control req-modal"
                       [(ngModel)]="eventDetails.venue.name"
                       placeholder=""
                       maxlength="100"
                       [disabled]="true" />
              </div>
            </div>

            <div class="col-md-12"
                 *ngIf="eventDetails">
              <div class="row">

                <div class="col-md-4">
                  <b>Event Broadcast Date(s)</b>
                </div>

              </div>
              <div class="form-group form-group-wrapper form-inline"
                   *ngFor=" let broadcastDate of eventDetails.broadcastDates ; let i = index"
                   [hidden]="broadcastDate.isAirDate === false">

                <div class="form-group">
                  <div class="input-group input-group-set d-flex">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                    <input type="datetime"
                           name="airDate_"i
                           class="form-control form-control-md req-modal"
                           authenticate-required="true"
                           bsDatepicker
                           [bsValue]="eventDetails.broadcastDates[i].airDate"
                           #drp="bsDatepicker"
                           [minDate]="date"
                           [maxDate]="date"
                           readonly
                           date-time
                           placeholder="Air Date"
                           [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}"
                           [disabled]="true">

                  </div>
                </div>

                <div class="form-group">
                  <span class="form-control-txt">
                    at
                  </span>
                </div>

                <div class="form-group">
                  <div class="input-group input-group-time" style="max-width:80px;">
                    <div name="startTime_{{i}}"
                         style="overflow: hidden; "
                         nbc-time
                         [nbcDisabled]="true"
                         nbcClass="form-control form-control-sm  req-modal"
                         [model]="eventDetails.broadcastDates[i].startTime">
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <span>-</span>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-time" style="max-width:80px;">
                    <div name="endTime_{{i}}"
                         style="overflow: hidden;"
                         nbc-time
                         [nbcDisabled]="true"
                         nbcClass="form-control form-control-sm  req-modal"
                         [model]="eventDetails.broadcastDates[i].endTime">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-group">
                    <label class="form-control-txt"><span>Local Time on</span></label>
                  </div>
                  <div class="form-group">
                    <input type="text"
                           name="network_{{i}}"
                           value="{{eventDetails.broadcastDates[i].network.description}}"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100"
                           disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>Production Manager</b>
                  <div class="form-group">
                    <div [hidden]="countryCode != 'US'">
                      <div>
                        <nbc-select *ngIf="productionManagers?.length>0" name="productionManager"
                                    [source]="productionManagers"
                                    [(model)]="eventDetails.onsiteProductionManager"
                                    [nbcRequired]="true"
                                    [customErrorClass]="(!eventDetails?.onsiteProductionManager?.id && submitted) ? 'nbc-control-error':''"
                                    display="description"
                                    value="id"
                                    placeHolderDisplay="--Select--"
                                    placeHolderValue="null">
                        </nbc-select>
                        <span class="control-label control-label-error"
                              *ngIf="!eventDetails.onsiteProductionManager?.id && isRequiredValidation && submitted">
                          Production Manager is required.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>                           
                <div class="col-md-4">
                  <b>Reports To</b>
                  <div [hidden]="countryCode != 'US'">
                    <div [ngClass]="{'has-error': !eventDetails?.reportsToManager  && submitted && isRequiredValidation}">
                      <nbc-select *ngIf="reportsToManager?.length>0"
                                  name="reportsToManager"
                                  [source]="reportsToManager"
                                  [(model)]="eventDetails.reportsToManager"
                                  [nbcRequired]="true"
                                  [customErrorClass]="(!eventDetails?.reportsToManager?.id && submitted) ? 'nbc-control-error':''"
                                  display="description"
                                  value="id"
                                  placeHolderDisplay="--Select--"
                                  placeHolderValue="null">
                      </nbc-select>
                      <span class="control-label control-label-error"
                            *ngIf="!eventDetails?.reportsToManager?.id && isRequiredValidation && submitted">
                        Reports To is required.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12"> 
              <div class="row">
                <div class="col-md-4">
                  <b>Tiered Rate Card</b>
                  <div class="form-group">
                    <input type="text"
                           name="tieredPosition"
                           value="{{tieredPosition}}"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Tiered Event</b>
                  <div>
                    <div class="btn-group btn-group-sm">
                      <label class="btn btn-white btn-yes"
                             [(ngModel)]="isTieredEventBtn"
                             btnRadio="true"
                             tabindex="0"
                             style="margin:0 !important;"
                             ngDefaultControl
                             name="isTieredEvent"
                             role="button"
                             [disabled]="true">Yes</label>
                      <label class="btn btn-white"
                             [(ngModel)]="isTieredEventBtn"
                             btnRadio="false"
                             tabindex="0"
                             role="button"
                             style="margin:0 !important;"
                             ngDefaultControl
                             name="isTieredEvent"
                             [disabled]="true">No</label>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row"
                   *ngIf="positionDetails">
                <div class="col-md-4">
                  <b>Position Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="posName"
                           value="{{positionDetails.description}}"
                           class="form-control req-modal"
                           placeholder=""
                           readonly
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Pay Rate</b>
                  <div class="input-group">
                    <div class="input-group-text">$</div>
                    <input type="text"
                           name="payRate"
                           class="form-control req-modal"
                           value="{{positionDetails.payRate}}"
                           placeholder=""
                           readonly
                           maxlength="250"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4"
                     *ngIf="rateAboveScale">
                  <b>Rate is Above Scale</b>
                  <div>
                    <div class="btn-group btn-group-sm">
                      <label class="btn btn-white btn-yes"
                             [(ngModel)]="rateAboveScale"
                             btnRadio="true"
                             tabindex="0"
                             style="margin:0 !important;"
                             ngDefaultControl
                             name="rateAboveScale1"
                             role="button"
                             [disabled]="true">Yes</label>
                      <label class="btn btn-white"
                             [(ngModel)]="rateAboveScale"
                             btnRadio="false"
                             tabindex="0"
                             role="button"
                             style="margin:0 !important;"
                             ngDefaultControl
                             name="rateAboveScale2"
                             [disabled]="true">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12"
                 *ngIf="isRateAboveScale">
              <div class="form-box">
                <h4>Tiered Rate Comments <span class="label-note">(Optional)</span></h4>
                <textarea class="form-control req-modal"
                          value="{{positionDetails.tieredRateComment}}"
                          textarea-maxlength="250"
                          [disabled]="true"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-12">
        <div class="row"
             style="margin-left:1px">
          <div class="col-md-4">
            <b>Position Start Date</b>

            <div class="form-group"
                 [ngClass]="{'has-error': submitted && isRequiredValidation && isPositionStartDateInvalid}">
              <div class="form-group">
                <div class="input-group input-group-set"
                     date-time-append>
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  <input type="datetime"
                         name="positionStartDate"
                         class="form-control req-modal"
                         required
                         bsDatepicker
                         [(bsValue)]="positionStartDate"
                         #drp="bsDatepicker"
                         (click)="onDateChangeStarte()"
                         (bsValueChange)="onPositionStartDateChange()"
                         (minDate)="date"
                         (maxDate)="date"
                         readonly
                         [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}"
                         placeholder="Position Start Date">
                </div>
                <span class="control-label control-label-error"
                      *ngIf="isRequiredValidation && isPositionStartDateInvalid && submitted ">
                  Position Start Date
                  should be between Crew Work Schedule date.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <b>Company Code</b>         
            <div class="form-group">
              <div [ngClass]="{'has-error': !companyCode?.id && submitted && isRequiredValidation}">
                <nbc-select *ngIf="companyCodeDDl?.options?.length>0"
                            name="companyCodeDDl"
                            [source]="companyCodeDDl.options"
                            [(model)]="companyCode"
                            [nbcRequired]="true"
                            [customErrorClass]="(!companyCode?.id && submitted) ? 'nbc-control-error':''"
                            display="description"
                            value="id"
                            placeHolderDisplay="Select a Company Code"
                            placeHolderValue="null">
                </nbc-select>
                <span class="control-label control-label-error"
                      *ngIf="!companyCode?.id && isRequiredValidation && submitted">
                  Company Code is required.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row"
             style="margin-left:1px">
          <div class="col-md-4">            
            <b>Position Type</b>
            <div class="form-group">
              <div [ngClass]="{'has-error': !postionType?.id && submitted}">
                <nbc-select *ngIf="positionTypeDDl?.options?.length>0"
                            name="positionTypeDDl"
                            [source]="positionTypeDDl.options"
                            [(model)]="postionType"
                            [nbcRequired]="true"
                            [customErrorClass]="(!postionType?.id && submitted) ? 'nbc-control-error':''"
                            display="description"
                            value="id"
                            placeHolderDisplay="Select a Position Type"
                            placeHolderValue="null">
                </nbc-select>

                <span class="control-label control-label-error"
                      *ngIf="!postionType?.id && isRequiredValidation && submitted">Position Type is required.</span>

              </div>
            </div>
          </div> 
          <div class="col-md-4">
            <b>FLSA Status</b>
            <div class="form-group">
              <div [ngClass]="{'has-error': !flsaStatus?.id && submitted && isRequiredValidation}">
                <nbc-select *ngIf="flsaStatusDDl?.options?.length>0"
                            name="flsaDDL"
                            [source]="flsaStatusDDl.options"
                            [(model)]="flsaStatus"
                            [nbcRequired]="true"
                            [nbcDisabled]="disableFlsaStatus"
                            [customErrorClass]="(!flsaStatus?.id && submitted) ? 'nbc-control-error':''"
                            display="description"
                            value="id"
                            placeHolderDisplay="Select FLSA"
                            placeHolderValue="null">
                </nbc-select>
                <span class="control-label control-label-error"
                      *ngIf="!flsaStatus?.id && isRequiredValidation && submitted">FLSA Status is required.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12"
           *ngFor=" let contact of contactDetails">
        <div class="form-box">
          <h3>Employee Details</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <b>Email</b>
                  <div class="form-group">
                    <input type="text"
                           name="email"
                           value="{{contact.email}}"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>First Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="firstName"
                           value="{{contact.firstName}}"
                           class="form-control req-modal"
                           [ngModelOptions]="{standalone: true}"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Middle Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="contactMiddleName"
                           value="{{contact.contactMiddleName}}"
                           class="form-control req-modal"
                           placeholder=""
                           [ngModelOptions]="{standalone: true}"
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Last Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="lastName"
                           value="{{contact.lastName}}"
                           class="form-control req-modal"
                           [ngModelOptions]="{standalone: true}"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>State of Legal Residence</b>

                  <div class="form-group" *ngIf="states?.length>0">
                    <div [hidden]="countryCode != 'US'">
                      <div [ngClass]="{'has-error': !contact.state && submitted && isRequiredValidation}">
                        <select name="stateOfLegalResidence"
                                class="form-select"
                                [(ngModel)]="contact.state"
                                required>
                          <option [ngValue]="null">--Select--</option>
                          <option *ngFor="let state of states" [ngValue]="state.code">{{state.name}}</option>
                        </select>
                        <span *ngIf="!contact.state && submitted && isRequiredValidation"
                              class="control-label control-label-error">
                          State is required.
                        </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  After submitting this form the included Contacts will be notified and directed to the Registration Portal to begin
  the onboarding process.
</div>
<div class="modal-footer text-left col-md-12 d-flex justify-content-between align-items-center">
  <div *ngIf="positionDetails" class="col-md-6">
  <button type="submit" class="btn btn-primary"
       [disabled]="!disableSubmitPositionRequestButtonOnClick && positionDetails.payRate ? false : true"
        (click)="isRequiredValidation = true;submitted=true;savePositionRequest(frmPositionRequest);">
   
        Submit Request
  </button>
  <button type="button" class="btn btn-default" (click)="cancel()">
        Cancel
  </button>
  </div>  
  <div class="col-md-5" *ngIf="positionRequestsLength > 1">
     {{currentRequest+1}} OUT OF {{positionRequestsLength}}
  </div>
  </div>
