import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }

  //SCOR-9546
  getDepartmentDetails(eventId:any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewSchedule/lookUp/" + eventId);
  };

  exportCrewWorkScheduleReport(eventId: any, airStatusReport: any, userName: any, reportType: any): Observable<any> {

    this.log.trace("Get the Header called with: " + eventId + "and airStatusReport" + airStatusReport);
    const createdDateTime = new Date();
    const userId = window.preload.user.id;

    userName = window.preload.user.firstName + " " + window.preload.user.lastName;
    let outPutData:any;
    if (reportType === "Excel") {
      outPutData = this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/CrewWorkScheduleReport", { eventId: eventId, inputInfo: airStatusReport, createdBy: userName, SSO: userId, createdDate: createdDateTime.toDateString(), createdTime: createdDateTime.toTimeString(), hours: createdDateTime.getHours(), minutes: createdDateTime.getMinutes(), timeZone: createdDateTime.getTimezoneOffset() }, { responseType: "arraybuffer", headers: { "Content-type": "application/json", "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } });
    }

    if (reportType === "PDF") {
      outPutData = this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/CrewWorkScheduleReport_PDF", { eventId: eventId, inputInfo: airStatusReport, createdBy: userName, SSO: userId, createdDate: createdDateTime.toDateString(), createdTime: createdDateTime.toTimeString(), hours: createdDateTime.getHours(), minutes: createdDateTime.getMinutes(), timeZone: createdDateTime.getTimezoneOffset() }, { responseType: "arraybuffer", headers: { "Content-type": "application/json", "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } });
    }
    return outPutData;
  };

  getRemotesCrewSSOStatusLookupDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewSSOSTatus/lookUp/" + eventId);
  };

  exportSSOStatusReport(eventId: any, airStatusReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName;
    return this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/SSOStatusReport", { eventId: eventId, inputInfo: airStatusReport, createdBy: userName, createdDate: createdDateTime.toDateString(), createdTime: createdDateTime.toTimeString(), hours: createdDateTime.getHours(), minutes: createdDateTime.getMinutes(), timeZone: createdDateTime.getTimezoneOffset() }, { responseType: "arraybuffer", headers: { "Content-type": "application/json", "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } });
  };

  getRemotesCrewChangeLookupDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewChange/lookUp/" + eventId);
  };

  exportCrewChangeReport(eventId: any, airStatusReport: any, userName: any): Observable<any> {

    this.log.trace("Get the Header called with: " + eventId + "and airStatusReport" + airStatusReport);
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName;

    return this.http.post(this._apiroot.baseUrl + "events/" + eventId + "/CrewChangeReport",
      {
        eventId: eventId,
        inputInfo: airStatusReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: { "Content-type": "application/json", "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
      });
  };

  exportEmergencyContact(eventNo: any) {
    const eventId = eventNo;
    const createdDateTime = new Date();
    const userName = window.preload.user.firstName + " " + window.preload.user.lastName;
    const department = "Remotes";
    return this.http
      .post(this._apiroot.baseUrl + "reports/Remotes/Crew/EmergencyContact/export/" + eventId,
        {
          eventId: eventId,
          createdBy: userName,
          createdDate: createdDateTime.toDateString(),
          department: department,
          hours: createdDateTime.getHours(),
          minutes: createdDateTime.getMinutes(),
          timeZone: createdDateTime.getTimezoneOffset()
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-type": "application/json",
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        });
  };

  getPayrollDashboardTimesheets(query: any): Observable<any> {
    this.log.trace("get payroll dashboard timesheets summary and items: ");
    return this.http.post(this._apiroot.baseUrl + "payroll/timesheets/submitted", query);
  }

  exportContactInformationReport(eventId: any, contactinformationreport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/ContactInformationReport/",
      {
        eventId: eventId,
        inputInfo: contactinformationreport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset(),
        createdSSO: window.preload.user.id
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  };
  getContactInformationDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/ContactInformation/lookUp/" + eventId);
  };
  exportDefaultPayRateReport(eventId: any, payRateReport: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/PayRate/" + eventId,
      {
        eventId: eventId,
        inputInfo: payRateReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      }).pipe(
        catchError((err) => {
          return throwError(err);
        })
      );


  }
  exportSSOOnlyReport(eventId?: any): Observable<any> {
    return this.http.post(this._apiroot.baseUrl + "reports/SSOOnly/",
      {},
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "text/plain"
        }
      });
  }

  exportFlaggedbyHRReport(eventId?: any): Observable<any> {
    const createdDateTime = new Date();
    const userName = window.preload.user.firstName + " " + window.preload.user.lastName;
    return this.http.post(this._apiroot.baseUrl + "reports/FlaggedbyHRReport/",
      {
        eventId: eventId,
        inputInfo: '',
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  }

  exportDefaultProductionMemoReport(eventId: any, remoteProductionMemoReport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/RemoteProductionMemo/" + eventId,
      {
        eventId: eventId,
        inputInfo: remoteProductionMemoReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });


  }
  exportDefaultRemoteStaffingSummaryReport(eventId: any, remoteStaffingSummaryReport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/RemoteStaffingSummary/" + eventId,
      {
        eventId: eventId,
        season: remoteStaffingSummaryReport.seasons,
        events: remoteStaffingSummaryReport.events,
        inputInfo: remoteStaffingSummaryReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });

  }

  exportDefaultflagPayRateReport(eventId: any, flagPayRateReport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/FlagPayRate/" + eventId,
      {
        eventId: eventId,
        inputInfo: flagPayRateReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });

  }
  exportOnboardingReport(eventId: any, onboardingReport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    const department = "";

    return this.http.post(this._apiroot.baseUrl + "reports/Onboarding/" + eventId,
      {
        eventId: eventId,
        inputInfo: onboardingReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        department: department, createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  }

  exportDefaultSAPDiscrepancyReport(eventId: any, sapDiscrepancyreport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/SAPDiscrepancyReport/",
      {
        eventId: eventId,
        inputInfo: sapDiscrepancyreport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      }, {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });

  }
  getsapDiscrepancyDetails(eventId?: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/SAPDiscrepancy/lookUp/" + eventId);
  };
  exportsapDiscrepancyReport(eventId: any, sapDiscrepancyreport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/SAPDiscrepancyReport/",
      {
        eventId: eventId,
        inputInfo: sapDiscrepancyreport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });
  };
  getCrewRateDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewRate/lookUp/" + eventId);
  };

  getCrewRateCategory(positionFunctionId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewRateCategory/" + positionFunctionId);
  };
  autoCompleteByOnlyEvent(term: any, eventName: any): Observable<any> {
    // log.trace("searching: " + term);
    let data = { eventName: eventName }
    return this.http.get<any>(
      this._apiroot.baseUrl + "reports/getOnlyEvent/" + encodeURIComponent(term))
      .pipe(
        map((data: any) => data && data.events || []),
        catchError((err) => {
          return []
        })
      );
  };
  getCrewRateTierNetwork(networkGroupId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/CrewRateTierNetwork/" + networkGroupId);
  };
  exportCrewRateReport(eventId: any, crewRateReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/CrewRate/" + eventId,
      {
        eventId: eventId,
        inputInfo: crewRateReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset(),
        createdSSO: window.preload.user.id
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });
  };
  exportflagPayRateReport(eventId: any, flagPayRateReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/FlagPayRate/" + eventId,
      {
        eventId: eventId,
        inputInfo: flagPayRateReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  };
  getflagPayRateDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/PayRate/lookUp/" + eventId);

  };

  exportRemoteStaffingSummaryReport(eventId: any, remoteStaffingSummaryReport: any, userName: any): Observable<any> {

    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/RemoteStaffingSummary/" + eventId,
      {
        eventId: eventId,
        season: remoteStaffingSummaryReport.seasons,
        events: remoteStaffingSummaryReport.events,
        inputInfo: remoteStaffingSummaryReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  };
  // SCOR-11419
  autoComplete(term: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/getSeason/" + (term || ""))
      .pipe(
        map((data: any) => data && data.seasons || []),
        catchError((err) => {
          return []
        })
      );;
  };
  autoCompleteBySeason(term: any, season: any) {
    // log.trace("searching: " + term);
    return this.http.post(this._apiroot.baseUrl + "reports/eventBySeason/" + (term || ""), { season: season })
      .pipe(
        map((data: any) => data && data.reference || []),
        catchError((err) => {
          return []
        })
      );;
  };
  getremoteProductionMemoDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/PayRate/lookUp/" + eventId);

  };
  exportProductionMemoReport(eventId: any, remoteProductionMemoReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/RemoteProductionMemo/" + eventId,
      {
        eventId: eventId,
        inputInfo: remoteProductionMemoReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });
  };

  getPayRateDetails(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/PayRate/lookUp/" + eventId);

  };
  exportPayRateReport(eventId: any, payRateReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/PayRate/" + eventId,
      {
        eventId: eventId,
        inputInfo: payRateReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      }).pipe(
        catchError((err) => {
          return throwError(err);
        })
      );;
  };

  getOnboardingHiringManagers(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/OnboardingHiringManagers/lookUp");
  };

  getOnboardingProductionManagers(eventId: any): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/OnboardingProductionManagers/lookUp");
  };

  exportRequestStatusReport(requestStatusReport: any, userName: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/OnboardingReport/",
      {
        inputInfo: requestStatusReport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    });
  };

  exportRemotesOnboardingRecords(selectedIds: any): Observable<any> {
    const eventId = 30;
     const createdDateTime = new Date();
    const userName = window.preload.user.firstName + " " + window.preload.user.lastName;
    const department = "Remotes";

    return this.http.post(this._apiroot.baseUrl + "reports/Remotes/Onboarding/export",
      {
        eventId: eventId,
        inputInfo: selectedIds,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        department: department,
        hours: createdDateTime.getHours().toString(),
        minutes: createdDateTime.getMinutes().toString(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
      responseType: "arraybuffer",
      headers: {
        "Content-type": "application/json",
        "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    });

  }

  geteventLocationDetails(): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "reports/EventLocation/lookUp");
  };
  exporteventLocationReport(eventLocationreport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/EventLocationReport",
      {
        inputInfo: eventLocationreport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  };

  exportpayrollTimecardsReport(payrollTimecardsreport?: any, userName?: any): Observable<any> {
    const createdDateTime = new Date();
    userName = window.preload.user.firstName + " " + window.preload.user.lastName + " (" + window.preload.user.id + ")";
    return this.http.post(this._apiroot.baseUrl + "reports/payrollTimecardsReport",
      {
        inputInfo: payrollTimecardsreport,
        createdBy: userName,
        createdDate: createdDateTime.toDateString(),
        createdTime: createdDateTime.toTimeString(),
        hours: createdDateTime.getHours(),
        minutes: createdDateTime.getMinutes(),
        timeZone: createdDateTime.getTimezoneOffset()
      },
      {
        responseType: "arraybuffer",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      });
  };

}
