import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable, BehaviorSubject, Subject } from "rxjs";
//import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable()
export class SharedDataService {
    private isEventSection = new Subject<any>();
    private eventInfo = new BehaviorSubject<any>([]);
    private eventSummary = new BehaviorSubject<any>([]);
    private eventAirBroadcastDates = new BehaviorSubject<any>([]);
    private currentPage = new Subject<any>();
    private eventId = new BehaviorSubject<any>([]);
    private seasonId = new BehaviorSubject<any>([]);
    private seasonData = new BehaviorSubject<any>([]);
    private CssModuleName = new Subject<any>();
    public positionRequest: Subject<boolean> = new Subject();

    private userTokens = new BehaviorSubject<any>([]);
    private alert = new BehaviorSubject<any>([]);
    private modalAlert = new BehaviorSubject<any>([]);
    public crewContactSearchFlyout: Subject<boolean> = new Subject<any>();
    public eventTimeSheetdate$ = new Subject<any>();
    public eventdata$ = new Subject<any>();

    constructor(private log: NGXLogger) {}

    //*************************** START:  EVENT INFO ***********************************************************************/
    setEvenSection(isEventSection: any) {
        this.isEventSection.next(isEventSection);
    }
    setRequestPosition(position: any) {
        this.positionRequest.next(position);
    }
    getRequestPosition() {
        return this.positionRequest.asObservable();
    }
    getEventSection(): Observable<any> {
        return this.isEventSection.asObservable();
    }

    setEventInfo(event: any) {
        // this.eventInfo = new BehaviorSubject<any>([]);
        this.eventInfo.next(event);
    }
    getEventInfo(): Observable<any> {
        return this.eventInfo.asObservable();
    }
    setEventSubscribeData(data: any) {
        this.eventdata$.next(data);
    }
    getEventSubscribeData() {
        return this.eventdata$.asObservable();
    }
    setEventTimeSheetdate(item: any) {
        this.eventTimeSheetdate$.next(item);
    }
    getEventTimeSheetdate() {
        return this.eventTimeSheetdate$.asObservable();
    }
    setEventSummary(eventSummary: any) {
        this.log.trace("SharedDataService - Event summary received");
        this.eventSummary.next(eventSummary);
    }
    getEventSummary(): Observable<any> {
        return this.eventSummary.asObservable();
    }

    setEventAirBroadcastDates(dates: any) {
        this.log.trace("SharedDataService - event Air BroadcastDates received");
        this.eventAirBroadcastDates.next(dates);
    }
    getEventAirBroadcastDates(): Observable<any> {
        return this.eventAirBroadcastDates.asObservable();
    }

    setCurrentPage(pageName: any) {
        this.currentPage.next(pageName);
    }

    getCurrentPage(): Observable<any> {
        return this.currentPage.asObservable();
    }

    setCurrentEventId(eventId: any) {
        this.log.trace(
            "SharedDataService - Current eseasonIdvent number received"
        );
        this.eventId.next(eventId);
    }
    getCurrentEventId(): Observable<any> {
        return this.eventId.asObservable();
    }

    setCurrentSeasonId(seasonId: any) {
        this.log.trace("SharedDataService - Current seasonId number received");
        this.seasonId.next(seasonId);
    }
    getCurrentSeasonId(): Observable<any> {
        return this.seasonId.asObservable();
    }

    setSeasonData(seasonData: any) {
        this.log.trace("SharedDataService - Event summary received");
        this.seasonData.next(seasonData);
    }
    getSeasonData(): Observable<any> {
        return this.seasonData.asObservable();
    }

    setCss(moduleName: any) {
        this.log.trace("SharedDataService - CssModuleName " + moduleName);
        this.CssModuleName.next(moduleName);
    }
    getCss(): Observable<any> {
        return this.CssModuleName.asObservable();
    }

    //*************************** START:  ACCESS TOKENS ***********************************************************************/
    //Set users tokens for UI validation
    setUserTokens(usertokens: any) {
        this.log.trace("updated tokens received");
        this.userTokens = usertokens;
    }

    // get all the latest user tokens for UI validation
    getUserTokens(): Observable<any> {
        return this.userTokens.asObservable();
    }

    //*************************  START : ALERT *****************************************************************/
    showAlert(alert: any) {
        this.log.trace("alert received" + alert);
        // this.alert = new BehaviorSubject<any>([]);
        this.alert.next(alert);
    }

    getAlertMessage(): Observable<any> {
        return this.alert.asObservable();
    }

    removeAlert() {
        let existingalerts = this.alert.getValue();
        existingalerts = new BehaviorSubject<any>([]);
        this.alert.next(existingalerts);
    }

    clearAlert(alert: any) {
        this.log.trace("alert clear" + alert);
        // this.alert = new BehaviorSubject<any>([]);
        this.alert.next(alert);
    }

    showModalAlert(alert: any) {
        this.log.trace("alert received" + alert);
        this.modalAlert.next(alert);
    }

    getModalAlertMessage(): Observable<any> {
        return this.modalAlert.asObservable();
    }

    removeModalAlert() {
        let existingalerts = this.modalAlert.getValue();
        existingalerts = new BehaviorSubject<any>([]);
        this.modalAlert.next(existingalerts);
    }
    getCrewContactSearchFlyout(data: any) {
        this.crewContactSearchFlyout.next(data);
    }
    setCrewContactSearchFlyout() {
        return this.crewContactSearchFlyout.asObservable();
    }
}
