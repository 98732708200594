import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SharedDataService } from './sharedData.service';



@Component({
  selector: 'side-nav',
  templateUrl: './sideNav.component.html'
})

export class SideNavComponent implements OnInit {
  public href: string = "";
  uiversion = "";

  hrefResources: string[] = [];
  isEventThemeApplicable = false;
  isCollapsed = true;
  sideNavCollapsed = false;

  constructor(private router: Router,
    private _sharedDataService: SharedDataService) {
  }   
  


  ngOnInit() {
    this._sharedDataService.getEventSection()
      .subscribe(isEvent => {
      
        this.isEventThemeApplicable = isEvent
      }); 
    this.uiversion = window?.preload?.uisettings?.applicationVersion;
  }

  toggleSideNav() {    
    this.sideNavCollapsed = !this.sideNavCollapsed;
  }
}
