<!--<event-header></event-header>-->
<div *ngIf="hasLoaded">
 <nbc-alert></nbc-alert>
  <div class="page-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">
          <div class="box-dash">
            <div class="box-dash-team-logos no-logos">
              <span *ngIf="(model.homeTeam && model.awayTeam)">
                <img width="60" height="6" src="/assets/image/team/{{model?.awayTeam?.imageId}}" />
                &#64;
                <img width="60" height="60" src="/assets/image/team/{{model?.homeTeam?.imageId}}" />
              </span>
              <div *ngIf="!model.homeTeam || !model.awayTeam" class="event-name">{{ model.eventName }}</div>
              <div class="event-name" *ngIf="model.isTaped">
                <span>(TAPED)</span>
              </div>
            </div>
            <div class="box-dash-inner-alt">
              <div class="row" *ngFor="let broadcastDate of model.broadcastAirDates">
                <div class="col-sm-3 col-md-12 col-lg-4">

                  <span><strong>{{broadcastDate.airDate | date:'EEE, MMM d'}}</strong></span>
                </div>

                <div class="col-sm-6 col-md-8 col-lg-5">
                  <span>{{broadcastDate.startTime | date:'h:mma'}}</span> - <span>{{broadcastDate.endTime | date:'h:mma'}} {{eventTimezone}}</span>
                </div>
                <div class="col-sm-3 col-md-4 col-lg-3">
                  <i class="icon-tv-network"></i>{{broadcastDate.network.description}}
                </div>
              </div>
            </div>
            <div class="box-dash-inner-dflt">
              <div class="event-time-out-banner">
                <div *ngIf="!!model.broadcastDates" style="float:left">
                  <span>
                    <nbc-event-countdown-label [nbcStartDate]="model.crewWorkStartDate"
                                               [nbcEndDate]="model.crewWorkEndDate"
                                               nbcLabelAppendText="to event">
                    </nbc-event-countdown-label>
                  </span>
                </div>
                <div *ngIf="model.eventLocation" class="event-location-banner">
                  <span>
                    {{model.eventLocation}}
                  </span>
                </div>
              </div>
              <div class="box-dash-event-dtls">
                <div class="row">
                  <div class="col-md-2 col-xs-2">
                    <span class="box-dash-event-dtl-icon">
                      <i class="fa fa-map-marker"></i>
                    </span>
                  </div>
                  <div class="col-md-8 col-xs-8 event-venue-details">
                    <h4 class="box-dash-event-dtl-ttl cursor-pointer"
                        [popover]="popTemplate" [outsideClick]="true" tabindex="-1" style="-ms-word-break: break-all; word-break: break-all;">
                      {{model && model.venue? model.venue.name : ""}}
                    </h4>
                    <p [hidden]="!model.venue">
                      {{model?.venue?.city}}
                      <span *ngIf="model?.venue?.city && model?.venue?.state">, </span> {{ model?.venue?.state }}
                    </p>
                  </div>
                  <div class="col-md-2">
                    <span class="label label-default label-tier" *ngIf="model.level > 0">L{{model.level}}</span>
                  </div>
                </div>
                <div class="row" authenticate-user="event.wbsecode.retrieve">
                  <div class="col-md-2 col-xs-2">
                    <span class="box-dash-event-dtl-icon">
                      <i class="fa fa-barcode"></i>
                    </span>
                  </div>
                  <div class="col-md-10 col-xs-10">
                    <span class="box-dash-event-dtl-loc">{{ model.budgetCode }}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-xs-2">
                    <span class="box-dash-event-dtl-icon">
                      <i class="fa fa-trophy"></i>
                    </span>
                  </div>
                  <div class="col-md-10 col-xs-10">
                    <span class="box-dash-event-dtl-loc">EVENT ID {{ model.id }}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 col-xs-2">
                    <span class="box-dash-event-dtl-icon">
                      <i class="fa fa-user"></i>
                    </span>
                  </div>
                  <div class="col-md-10 col-xs-10">
                    <div class="box-dash-event-dtl-list">
                      <div class="row">
                        <div class="col-md-5">
                          <label>Director</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="eventSummary.director && eventSummary.director?.id !== 0">
                            {{eventSummary.director?.firstName}}
                          </span>
                          <!--<a href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{eventSummary.director.id}}" })" target="_blank" title="{{eventSummary.director.firstName}}">{{eventSummary.director.firstName}}</a>-->
                          <a *ngIf="eventSummary.director?.id !== 0"
                             routerLink="/Contacts/Detail/{{eventSummary.director?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{eventSummary.director?.firstName}}">
                            {{ eventSummary.director?.firstName }}
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <label>
                            Producer
                          </label>
                        </div>
                        <div class="col-md-7">
                          <a [hidden]="eventSummary.producer?.id !== 0">{{eventSummary.producer?.firstName}}</a>
                          <!--<a [hidden]="eventSummary.producer.id === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{eventSummary.producer.id}}" })" target="_blank" title="{{eventSummary.producer.firstName}}">{{eventSummary.producer.firstName}}</a>-->
                          <a *ngIf="eventSummary.producer?.id !== 0"
                             routerLink="/Contacts/Detail/{{eventSummary.producer?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{  eventSummary.producer?.firstName }}">
                            {{  eventSummary.producer?.firstName }}
                          </a>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <label>Homebase PM</label>
                        </div>
                        <div class="col-md-7">
                          <a [hidden]="event.homeBaseManager?.id !== 0">{{ event.homeBaseManager ? event.homeBaseManager?.description : '' }}</a>
                          <a *ngIf="event.homeBaseManager?.id !== 0"
                             routerLink="/Contacts/Detail/{{event.homeBaseManager?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.homeBaseManager ? event.homeBaseManager?.description : '' }}">
                            {{ event.homeBaseManager ? event.homeBaseManager?.description : '' }}
                          </a>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <label>Manpower</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="event.manPowerManager?.id !== 0">
                            {{ event.manPowerManager ? event.manPowerManager?.description : '' }}
                          </span>
                          <!--<a [hidden]="event.manPowerManager === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{ event.manPowerManager ? event.manPowerManager.id : '' }}" })" target="_blank" title="{{ event.manPowerManager ? event.manPowerManager.description : '' }}">{{ event.manPowerManager ? event.manPowerManager.description : '' }}</a>-->
                          <a *ngIf="event.manPowerManager?.id !== 0"
                             routerLink="/Contacts/Detail/{{event.manPowerManager?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.manPowerManager ? event.manPowerManager?.description : '' }}">
                            {{ event.manPowerManager ? event.manPowerManager?.description : '' }}
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <label>Tech Mgr</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="event.technicalManager?.id !== 0">{{ event.technicalManager ? event.technicalManager?.description : '' }}</span>
                          <!--<a [hidden]="event.technicalManager === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{ event.technicalManager ? event.technicalManager.id : '' }}" })" target="_blank" title="{{ event.technicalManager ? event.technicalManager.description : '' }}">{{ event.technicalManager ? event.technicalManager.description : '' }}</a>-->
                          <a *ngIf="event.technicalManager?.id !== 0"
                             routerLink="/Contacts/Detail/{{event.technicalManager?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.technicalManager ? event.technicalManager?.description : '' }}">
                            {{ event.technicalManager ? event.technicalManager?.description : '' }}
                          </a>
                        </div>
                      </div>
                      <div class="row" [hidden]="!event.technicalManager2">
                        <div class="col-md-5">
                          <label>Tech Mgr</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="event.technicalManager2?.id !== 0">{{ event.technicalManager2 ? event.technicalManager2.description : '' }}</span>
                          <!--<a [hidden]="event.technicalManager2 === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{ event.technicalManager2 ? event.technicalManager2.id : '' }}" })" target="_blank" title="{{ event.technicalManager2 ? event.technicalManager2.description : '' }}">{{ event.technicalManager2 ? event.technicalManager2.description : '' }}</a>-->
                          <a *ngIf="event.technicalManager2 !== 0"
                             routerLink="/Contacts/Detail/{{event.technicalManager2?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.technicalManager2 ? event.technicalManager2?.description : '' }}">
                            {{ event.technicalManager2 ? event.technicalManager2?.description : '' }}
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <label>Onsite Prod Mgr</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="event.onsiteProductionManager !== 0">{{ event.onsiteProductionManager ? event.onsiteProductionManager.description : '' }}</span>
                          <!--<a [hidden]="event.onsiteProductionManager === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{ event.onsiteProductionManager ? event.onsiteProductionManager.id : '' }}" })" target="_blank" title="{{ event.onsiteProductionManager ? event.onsiteProductionManager.description : '' }}">{{ event.onsiteProductionManager ? event.onsiteProductionManager?.description : '' }}</a>-->
                          <a *ngIf="event.onsiteProductionManager !== 0"
                             routerLink="/Contacts/Detail/{{event.onsiteProductionManager?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.onsiteProductionManager ? event.onsiteProductionManager.description : '' }}">
                            {{ event.onsiteProductionManager ? event.onsiteProductionManager.description : '' }}
                          </a>

                        </div>
                      </div>
                      <div class="row" [hidden]="!event.onsiteProductionManager2">
                        <div class="col-md-5">
                          <label>Onsite Prod Mgr</label>
                        </div>
                        <div class="col-md-7">
                          <span [hidden]="event.onsiteProductionManager2 !== 0">{{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.description : '' }}</span>
                          <!--<a [hidden]="event.onsiteProductionManager2 === 0" href="@Url.AngularAction(" ContactDetail", "Contacts" , new { area="Contacts" , id="{{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.id : '' }}" })" target="_blank" title="{{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.description : '' }}">{{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.description : '' }}</a>-->
                          <a *ngIf="event.onsiteProductionManager2 !== 0"
                             routerLink="/Contacts/Detail/{{event.onsiteProductionManager2?.id}}"
                             target="_blank"
                             class="btn-link"
                             title="{{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.description : '' }}">
                            {{ event.onsiteProductionManager2 ? event.onsiteProductionManager2?.description : '' }}
                          </a>
                        </div>
                      </div>
                      <div class="row" [hidden]="!event.facility">
                        <div class="col-md-5">
                          <label>Facility</label>
                        </div>
                        <div class="col-md-7">
                          <span>{{ event.facility ? event.facility : '' }}</span>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 notification-summary-button"
                       authenticate-user="reports.productionmemo.export">
                    <form ngNoForm method="post" [action]="productionMemoActionUrl"
                          target="_parent">
                      <button class="btn btn-primary notification-summary-button-spacing" type="submit">
                        View Production Memo
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-dash box-dash-event-summary" authenticate-user="financial.budgetforecast.retrieve">
            <div class="box-dash-hdr-bar">
              <h2 class="box-dash-hdr"  [ngClass]="event.financials.completedStatus">Financials</h2>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="row">
                  <div class="col-md-12">
                    <label class="ms-3 mt-3 fw-bold">Average Airfare</label>
                    <div class="row finance-fares">
                          <div class="col-md-6" >Talent</div>
                          <div class="col-md-6">${{event.financials.talentAirFare}}</div>                       
                    </div>
                    <div class="row finance-fares">
                          <div class="col-md-6">Production</div>
                          <div class="col-md-6">${{event.financials.productionAirFare}}</div>
                       
                    </div>
                    <div class="row finance-fares">
                          <div class="col-md-6">Engineers</div>
                          <div class="col-md-6">${{event.financials.engineersAirFare}}</div>
                        
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:20px">
                  <div class="col-md-12">
                    <label class="ms-3 mt-3 fw-bold">Average Hotel Nightly Cost</label>
                    <div class="row finance-fares">                     
                          <div class="col-md-6">Production</div>
                          <div class="col-md-6">${{event.financials.productionHotelFare}}</div>      
                    </div>
                    <div class="row finance-fares">
                          <div class="col-md-6">Engineers</div>
                          <div class="col-md-6">${{event.financials.engineersHotelFare}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="mt-3 fw-bold">Hours Budgeted / Day</label>
                <div class="row  finance-fares" style="margin:0px;" *ngFor="let budgetHours of model.financials.budgetedHours | orderBy:'broadCastDate'">
                 
                      <div class="col-md-7">{{budgetHours.broadCastDate  | date:"EEE MM/dd/yyyy"}}</div>
                      <div class="col-md-3">{{budgetHours.hours}}</div>
                </div>

              </div>
            </div>

            <div class="box-dash-hdr-bar">
              <h2 class="box-dash-hdr">Minimum Wage  : {{event.minimumWage == 0 ? "Not Available" :  "$" + event.minimumWage}}</h2>
            </div>
          </div>


          <div class="box-dash event-notes">
            <div class="box-dash-hdr-bar">
              <button *ngIf="!edittingNotes"
                      class="btn btn-white float-end"
                       (click)="editNotes(true)"
                      authenticate-user="event.update"
                      authenticate-command="hide">
                Edit <i class="fa fa-pencil"></i>
              </button>
              <h2 class="box-dash-hdr">Notes</h2>
            </div>
            <div class="box-dash-inner-dflt">
              <div class="form-group">
                <textarea id="event_summary_notes"
                          class="form-control"
                          [(ngModel)]="model.note"
                          [disabled]="!edittingNotes"
                          maxlength="250"
                          appInputRestriction="restrictChars"></textarea>
              </div>
              <div *ngIf="edittingNotes">
                <button class="btn btn-primary" type="button" (click)="saveNotes()">Save</button>
                <button class="btn btn-default" type="button" (click)="editNotes(false)">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="box-dash">
            <div class="box-dash-inner-dflt">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="box-dash-hdr">Positions</h2>
                  <div class="row">
                    <div class="col-3">
                      <div class="box-dash-position-dtl">
                        <span class="box-dash-position-cnt">{{eventSummary.confirmedPositions}}</span>
                        <span class="box-dash-position-type">Confirmed</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="box-dash-position-dtl">
                        <span class="box-dash-position-cnt">{{eventSummary.pendingPositions}}</span>
                        <span class="box-dash-position-type">Assigned</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="box-dash-position-dtl">
                        <span class="box-dash-position-cnt">{{eventSummary.totalPositions - eventSummary.confirmedPositions - eventSummary.pendingPositions}}</span>
                        <span class="box-dash-position-type">Open</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="box-dash-position-dtl">
                        <span class="box-dash-position-cnt">{{eventSummary.totalPositions}}</span>
                        <span class="box-dash-position-type">Total</span>
                      </div>
                    </div>
                  </div>

                  <div class="progress">
                    <div class="progress-bar progress-bar-success" [style.width]="((eventSummary.confirmedPositions / eventSummary.totalPositions) * 100) + '%'"></div>
                    <div class="progress-bar progress-bar-warning" [style.width]="((eventSummary.pendingPositions / eventSummary.totalPositions) * 100) + '%'"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="box-dash-alert">
                    <span class="label label-warning"><i class="fa fa-warning"></i></span>
                    <span class="box-dash-alert-cnt">{{eventSummary.schedulingConflicts}}</span>
                    assigned contacts have conflicts
                  </div>
                  <div class="box-dash-alert">
                    <span class="label label-success"><i class="fa fa-user"></i></span>
                    <span class="box-dash-alert-cnt">{{eventSummary.onBoardingRequested}}</span>
                    pending onboarding request
                  </div>
                  <div class="box-dash-alert">
                    <span class="label label-danger"><i class="fa fa-user"></i></span>
                    <span class="box-dash-alert-cnt">{{eventSummary.onBoardedInPast30Days}}</span>
                    new contacts onboarded
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-dash  box-dash-map-md">
            <div class="box-dash-inner-dflt text-end">
              <h2 class="box-dash-hdr float-start">Crew</h2>
              <ul class="box-dash-crew-list">
                <li *ngFor="let locationcounts of eventSummary.locationPositionCounts"><strong>{{locationcounts.count}} {{locationcounts.name}}</strong></li>
              </ul>
            </div>
          </div>
          <div class="box-dash  box-dash-map-md">
            <div class="box-dash-inner-dflt text-end">
              <h2 class="box-dash-hdr float-start">
                Crew Changes
              </h2>
              <button class="btn btn-primary" (click)="getEventCrewChangeSummary(5)">
                Last 5 Days
              </button>
              <button class="btn btn-primary" (click)="getEventCrewChangeSummary(7)">
                Last 7 Days
              </button>
              <button class="btn btn-primary" (click)="getEventCrewChangeSummary(10)">
                Last 10 Days
              </button>
            </div>
              <div class="box-dash-inner-dflt text-start"  *ngIf="crewChangeList">
                <table class="table" *ngIf="crewChangeList?.length > 0">
                  <thead>
                    <tr>
                    <th style="color:#606060;">Position</th>
                    <th style="color:#606060;">Contact Name</th>
                    <th style="color:#606060;">Changed Element</th>
                    <th style="color:#606060;">From Value</th>
                    <th style="color:#606060;">To Value</th>
                    <th style="color:#606060;">Changed By</th>
                      </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let crewChange of crewChangeList">
                        <td style="color:#606060;">{{crewChange.positionName}}</td>
                        <td style="color:#606060;">{{crewChange.contactName}}</td>
                        <td style="color:#606060;">{{crewChange.changedElement}}</td>
                        <td style="color:#606060;">{{crewChange.fromValue}}</td>
                        <td style="color:#606060;">{{crewChange.toValue}}</td>
                        <td style="color:#606060;">{{crewChange.changedBy}}</td>
                      </tr>
                    </tbody>                  
                </table>
                <div  *ngIf="crewChangeList?.length == 0">
                  <strong>No Changes for this period</strong>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
<ng-template #popTemplate>
  <div *ngIf="venueDetails" style="word-wrap:break-word;">
    {{(venueDetails.name) + (venueAddressToolTipText)}}
    <br>{{(venueDetails.city || '') + venueState + ' ' + (venueDetails.zip || '')}}
    <br />V:{{venueDetails.phone?.phoneNumber || ''}}
    <br />{{venueDetails.associateName|| ''}}
    <br />O:{{venueDetails.associateOfficePhone|| ' '}} M:{{venueDetails.associateMobilePhone|| ' '}}
    <br />{{venueDetails.associateEmail|| ' '}}
    <br /><a class="btn-link" target="_blank" href="http://{{venueDetails.website || ''}}">{{venueDetails.website}}</a>
    <br />{{venueDetails.noteField|| ' '}}   
  </div>
</ng-template>
