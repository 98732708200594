import { Component, OnInit, Input, OnChanges } from "@angular/core";

@Component({
    selector: "pagination-label",
    templateUrl: "./pagination-label.component.html",
})
export class NbcPaginationLabelComponent implements OnChanges {
    @Input() pageSize: any | undefined;
    @Input() currentPage: any | undefined;
    @Input() totalRecords: any | undefined;

    endCount: any | undefined;
    startCount: any | undefined;

    createPaginationLabel() {
        if (this.totalRecords == 0) {
            this.startCount = 0;
        } else {
            this.startCount =
                parseInt(this.currentPage, 10) * parseInt(this.pageSize, 10) -
                (parseInt(this.pageSize, 10) - 1);
        }
        this.endCount = parseInt(this.currentPage) * parseInt(this.pageSize);
        if (this.endCount > this.totalRecords) {
            this.endCount = this.totalRecords;
        }
    }

    ngOnChanges() {
        this.createPaginationLabel();
    }
}
