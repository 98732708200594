<!--TODO: moving clone event to event folder. Need to remove the duplicated one.-->
<div class="modal-header">
  <h3 class="modal-title">Clone Event</h3>
  <button type="button" class="btn-close" (click)="cancel()">
  <span aria-hidden="true"></span>
  <span class="sr-only">Close</span>
  </button>
</div>
  <form #cloneForm="ngForm" name="cloneForm" novalidate>
    <div class="modal-body">
      <nbc-modal-alert></nbc-modal-alert>
      <div class="form-group" *ngIf="hasInactiveMasterData" style="color:red;">NOTE: Following Job Titles are inactive and will not copy: {{inActiveJobtitles}} </div>
      <div class="form-group">
        <label for="eventName">Event Name</label>
        <input type="text"
               #eventName="ngModel"
               appInputRestriction="restrictChars"
               class="form-control"
               id="eventName"
               name="eventName"
               [(ngModel)]="clone.eventName"
               maxlength="100"
               required />
        <span class="control-label control-label-error" *ngIf="eventName?.invalid && submitted">Event name required.</span>
      </div>


      <div class="form-group" [ngClass]="{'has-error': submitted && firstAirDate.errors?.required }">
        <label for="airDate">Event Date/Time</label>
        <div class="form-group-wrapper form-inline">
          <div class="form-group">
            <div class=" input-group input-group-set d-flex" date-time-append>
              <span class="input-group-text"><i class="fa fa-calendar"></i></span>
              <input type="text"
                     placeholder="Date"
                     class="form-control"
                     bsDatepicker
                     #firstAirDate="ngModel"
                     name="airDate"
                     [(ngModel)]="clone.broadcastDate.airDate"
                     (bsValueChange)="onBroadcastDateChange($event)"
                     [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY'}"
                     required
                     readonly>

            </div>
            <span class="control-label control-label-error" *ngIf="submitted && (clone.broadcastDate.airDate===undefined || clone.broadcastDate.airDate==='')">Please select an Air Date.</span>
          </div>

          <div class="form-group d-block">
            <span *ngIf="clone.broadcastDate.airDate < todaysDate">*You are about to clone an event to a past date. Please review before clicking "Clone Event". </span>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="isDataLoaded">
        <label for="eventInformation">Select the information you would like to clone.</label>
        <ul class="p-revert">
          <li class="checkbox">
            <label><input type="checkbox" [(ngModel)]="clone.wbse" name="wbse" (change)="setWBSE()" />WBSE</label>
            <div class="form-group" *ngIf="!clone.wbse" [ngClass]="{'has-error': submitted && budgetCode?.errors?.required }">
              <div class="input-group">
                <input type="text"
                       name="budgetCode"
                       class="form-control"
                       [required]="!clone.wbse"
                       (click)="openWBSESearchModal()"
                       placeholder="Budget / Show Code"
                       readonly="readonly"
                       [(ngModel)]="clone.budgetCode"
                       #budgetCode="ngModel"
                       maxlength="250"
                       authenticate-command="disabled">
                <span class="input-group-btn">
                  <button class="btn btn-white" type="button" (click)="openWBSESearchModal()" authenticate-user="event.wbsecode.create" authenticate-command="disabled"><i class="fa fa-search"></i></button>
                </span>
              </div>
              <span class="control-label control-label-error" *ngIf="submitted && budgetCode?.invalid">Please select a Budget Code.</span>
            </div>
          </li>




          <li class="checkbox">
            <label>
              <input type="checkbox"
                     [(ngModel)]="clone.eventPositions"
                     name="eventPositions"
                     [required]="clone.crewMemberSchedule" /> Event Positions <br />
            </label>
            <ul>
              <li *ngIf="clone.eventPositions" class="checkbox">
                <label>
                  <input type="checkbox" [(ngModel)]="clone.contacts" name="contacts" (click)="activateRates()" /> Contacts
                </label>

                <span *ngIf="toggleButton"></span>
              </li>
              <li *ngIf="clone.eventPositions" class="checkbox">
                <label>
                  <input type="checkbox"
                         [disabled]="!RateCardClone.isActive"
                         [(ngModel)]="clone.rateCardAndRates"
                         name="rateCardAndRates"
                         (checked)="(RateCardClone.checked && clone.contacts)" /> Rate Cards and Rates
                </label>

              </li>
            </ul>
            <span *ngIf="toggle"></span>
          </li>
          <li class="checkbox"><label><input type="checkbox" name="crewMemberSchedule" [(ngModel)]="clone.crewMemberSchedule" /> Crew Member Schedule</label></li>

          <li class="checkbox">
            <label><input type="checkbox" *ngIf="hasEventLocation" [(ngModel)]="clone.eventlocationcheck" name="eventlocationcheck" (change)="setEventLocation()" />Event Location</label>
            <div class="form-group" *ngIf="!clone.eventlocationcheck || !hasEventLocation" [ngClass]="{'has-error': submitted && !eventlocationValid }">
              <div class="input-group">                
                <nbc-dropdown [source]="EventLocations"
                              name="eventlocation"
                              [(model)]="clone.eventLocation"
                              display="desc"
                              [nbcRequired]="!clone.eventlocationcheck"
                              value="id"
                              nbcSize="w-100"
                              placeholder="Select Event Location">
                </nbc-dropdown>
              </div>
              <span class="control-label control-label-error" *ngIf="submitted && !eventlocationValid">Please select a Event Location.</span>
            </div>
          </li>
          <!-- <li class="checkbox">
    <label>
      <input type="checkbox" [(ngModel)]="clone.dailyAgenda" name="dailyAgenda" (change)="setCloneEventInfo()" /> Daily Agenda <br />
    </label>
    <ul>
      <li *ngIf="clone.dailyAgenda" class="checkbox">
        <label>
          <input type="checkbox" [(ngModel)]="clone.dailyAgendaActivities" name="dailyAgendaActivities" /> Event Activity
        </label>
      </li>
    </ul>
    <span *ngIf="toggle"> </span>
  </li> -->


        </ul>


      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="!isDataLoaded" (click)="ok(cloneForm.valid)">Clone Event</button>
        <button class="btn btn-default" (click)="cancel()">Cancel</button>
      </div>
    </div>
    </form>
