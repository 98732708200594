import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "venue-impact-list-a",
    templateUrl: "./modal-venue-impact-list.html",
})
export class ModalVenueImpactList implements OnInit, OnDestroy {
    componentDestroyed$: Subject<boolean> = new Subject();
    constructor(
        private _venueService: VenueService,
        private _remoteQueryService: RemotesQueryService,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {
        this.query = _remoteQueryService.getVenueChangeImpactQuery();
    }
    public imapctResponse!: Subject<any>;

    impactedEvents: any;
    message = "";
    modalTitle = "";
    query: any;

    search() {
        this._venueService
            .getImpactedEvents(this.query)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.impactedEvents = result;
                },
                error: (err) => {
                    this.log.showModalError(
                        "unable to find change impact. Please try later."
                    );
                    this.log.error(err);
                },
            });
    }

    proceed() {
        this.imapctResponse.next(true);
        this.bsModalRef.hide();
    }

    closeModal() {
        this.imapctResponse.next(false);
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.imapctResponse = new Subject();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
