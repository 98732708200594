<div id="body" [ngClass]="{'event-theme':isEventThemeApplicable}">

  <div class="col-menu" [ngClass]="{'col-menu-collapsed' : sideNavCollapsed}">
    <nav>
     
        <a href="javascript:void(0)" class="nav-collapse" (click)="toggleSideNav()">

          <span class="fa-stack fa-lg">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-chevron-left fa-stack-1x"></i>
            <i class="fa fa-chevron-right fa-stack-1x"></i>
          </span>

        </a>
    
        <ul>
          <li routerLinkActive="active">
            <a routerLink="/dashboard2" title="Dashboard"><i class="fa fa-tachometer"></i>Dashboard</a>

          </li>

          <li routerLinkActive="active" authenticate-user="event.retrieve">
            <a routerLink="/Events" title="Events"><i class="fa fa-trophy"></i>Events</a>

          </li>
          <li routerLinkActive="active" authenticate-user="contact.retrieve">
            <a routerLink="/Contacts" title="Contacts"><i class="fa fa-male"></i>Contacts</a>
          </li>

          <li routerLinkActive="active" authenticate-user="payrolltimesheet.retrieve">
            <a routerLink="/Payroll" title="Payroll"><i class="fa fa-dollar"></i>Payroll</a>
          </li>
          <li routerLinkActive="active" authenticate-user="position.retrieve">
            <a routerLink="/templates" title="Templates"><i class="fa fa-th"></i>Templates</a>
          </li>
          <li routerLinkActive="active" authenticate-user="onboarding.retrieve">
            <a routerLink="/Onboarding" title="Onboarding"><i class="fa fa-user"></i>Onboarding</a>

          </li>

          <li routerLinkActive="active">
            <a routerLink="/Reports" title="Reports"><i class="fa fa-file-text-o"></i>Reports</a>
          </li>
          <li routerLinkActive="active" authenticate-user="remotesadmin.retrieve">
            <a routerLink="/admin" title="Admin"><i class="fa fa-gear"></i>Admin</a>

          </li>
          <!-- 12-July-2024 : As per the ticket SCOR-25251 hiding Tier RateCard link -->
          <!--<li routerLinkActive="active" authenticate-user="TierRateCard.retrieve">
            <a routerLink="/tierratecard" title="Tier RateCard"><i class="fa fa-tasks"></i>Tier RateCard</a>
          </li>-->

        </ul>
    </nav>
    <!--New Code-->
    <!--<nav class="main-menu">
      <ul>
        <li routerLinkActive="active">
          <a routerLink="/dashboard2" title="Dashboard"><i class="fa fa-tachometer"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="event.retrieve">
          <a routerLink="/Events" title="Events"><i class="fa fa-trophy"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="contact.retrieve">
          <a routerLink="/Contacts" title="Contacts"><i class="fa fa-male"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="payrolltimesheet.retrieve">
          <a routerLink="/Payroll" title="Payroll"><i class="fa fa-dollar"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="position.retrieve">
          <a routerLink="/templates" title="Templates"><i class="fa fa-th"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="onboarding.retrieve">
          <a routerLink="/Onboarding" title="Onboarding"><i class="fa fa-user"></i></a>

        </li>

        <li routerLinkActive="active">
          <a routerLink="/Reports" title="Reports"><i class="fa fa-file-text-o"></i></a>
        </li>
        <li routerLinkActive="active" authenticate-user="remotesadmin.retrieve">
          <a routerLink="/admin" title="Admin"><i class="fa fa-gear"></i></a>

        </li>
        <li routerLinkActive="active" authenticate-user="TierRateCard.retrieve">
          <a routerLink="/tierratecard" title="Tier RateCard"><i class="fa fa-tasks"></i></a>
        </li>
      </ul>
      <ul class="logout">
        <li>
        
            <i></i>
            <span class="nav-text">
              v{{uiversion}}
            </span>
          
        </li>
      </ul>
    </nav>-->
    <div style="position:  fixed;bottom: 10px;color: #a7a9ac;margin-left: 50px;">v{{uiversion}}</div>
  </div>
 
  <div class="col-main" style="padding-bottom:0px;">
    <router-outlet></router-outlet>

  </div>
</div>

<!--<style type="text/css">

    .main-menu:hover, nav.main-menu.expanded {
    width: 100px;
    overflow: visible;
  }

  .main-menu {
    background: #212121;
    border-right: 1px solid #e5e5e5;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 60px;
    overflow: hidden;
   -webkit-transition: width .05s linear;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1,1);
    /*z-index: 1000;*/
  }

    .main-menu > ul {
      margin: 7px 0;
    }

    .main-menu li {
      position: relative;
      display: block;
      width: 200px;     /* --change*/
    }

      .main-menu li > a {
        position: relative;
        display: table;
        border-collapse: collapse;
        border-spacing: 0;
        color: #999;
        font-family: arial;
        font-size: 24px;
        text-decoration: none;
        -webkit-transform: translateZ(0) scale(1,1);
        -webkit-transition: all .1s linear;
        transition: all .1s linear;
      }

    .main-menu .nav-icon {
      position: relative;
      display: table-cell;
      width: 60px;
      height: 36px;
      text-align: center;
      vertical-align: middle;
      font-size: 18px;
    }

    .main-menu .nav-text {
      position: relative;
      display: table-cell;
      vertical-align: middle;
      width: 190px;
      font-family: 'Titillium Web', sans-serif;
    }

    .main-menu > ul.logout {
      position: absolute;
      left: 0;
      bottom: 0;
    }

  .no-touch .scrollable.hover {
    overflow-y: hidden;
  }

    .no-touch .scrollable.hover:hover {
      overflow-y: auto;
      overflow: visible;
    }

  a:hover, a:focus {
    text-decoration: none;
  }

  nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

    nav ul, nav li {
      outline: 0;
      margin: 0;
      padding: 0;
    }

    .main-menu li:hover > a, nav.main-menu li.active > a, .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus, .no-touch .dashboard-page nav.dashboard-menu ul li:hover a, .dashboard-page nav.dashboard-menu ul li.active a {
      color: #fff;
      background-color: #5fa2db;
    }

  .area {
    float: left;
    background: #e2e2e2;
    width: 100%;
    height: 100%;
  }

  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
  }
  #body .col-main {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 30px;
    position: relative;
    background: #e6e6e6;
    z-index: 1;
    -ms-overflow-x: hidden;
    overflow-x: hidden;
  }
</style>-->
