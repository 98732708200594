import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from '../sharedData.service';


@Injectable({ providedIn: 'root' })
export class NbcLoggerService {
  private log: NGXLogger;
  constructor(
    private alertService: SharedDataService,
    private externalLogger: NGXLogger) {
    this.log = externalLogger;
  }
  public trace(trace:any) {
    this.log.trace(trace);
  }

  public debug(debugMsg: any) {
    this.log.debug(debugMsg);
  } 

  public error(errorMsg: any) {
    this.log.error(errorMsg);    
  }

  public showError(uiMsg:any) {
    this.alertService.showAlert({ type: "danger", msg: uiMsg });
  }

  public showSuccess(uiMsg: any) {
    this.alertService.showAlert({ type: "success", msg: uiMsg });
  }

  public showInfo(uiMsg: any) {
    this.alertService.showAlert({ type: "info", msg: uiMsg });
  }

  public showWarning(uiMsg: any) {
    this.alertService.showAlert({ type: "warning", msg: uiMsg });
  }

  public showModalError(uiMsg: any) {
    this.alertService.showModalAlert({ type: "danger", msg: uiMsg });
  }

  public showModalSuccess(uiMsg: any) {
    this.alertService.showModalAlert({ type: "success", msg: uiMsg });
  }
  public showModalInfo(uiMsg: any) {
    this.alertService.showModalAlert({ type: "info", msg: uiMsg });
  }

  public closeAlert() {
    this.alertService.removeAlert();
  }

  public clearAlert() {
    this.alertService.clearAlert({ type: "clear"});
  }

  public closeModalError() {

  }
}
