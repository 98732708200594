<div class="hr-container">
  <div class="onboarding-filter-div" authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
    <div class="row d-flex">
      <div class="col-6 p-0">
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="positionStatus"
                        [source]="positionStatusList"
                        value="value"
                        display="name"
                        [(model)]="selectedRequestStatus"
                        placeHolderDisplay="Request Status"
                        placeHolderValue="0"
                        (change)="changeRequestStatus()">
            </nbc-select>
          </span>
        </div>
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="positionType"
                        [(model)]="selectedPositionType"
                        [source]="positionTypeList"
                        placeHolderDisplay="Position Type"
                        placeHolderValue="0"
                        display="name"
                        value="value"
                        (change)="changePositionType()">
            </nbc-select>
          </span>
        </div>
        <div class="form-group onboarding">
          <span authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
            <nbc-select name="employeeStatus"
                        [(model)]="selectedEmployeeStatus"
                        [source]="employeeStatusList"
                        placeHolderDisplay="Employee Status"
                        placeHolderValue="0"
                        display="name"
                        value="value"
                        (change)="changeEmployeeStatus()">
            </nbc-select>
          </span>
        </div>
      </div>
    </div>
    <div class="row"> 
      <span class="col-md-2" style="line-height:1px;padding:6px;width:200px;">
        <nbc-select name="searchCriteriaSelection"
                    [(model)]="selectSearchCriteria"
                    [source]="searchCriteria"
                    placeHolderDisplay="Select a Search Criteria"
                    placeHolderValue="0"
                    display="optionDescription"
                    value="optionValue"
                    (nbcValueChange)="changeSearchCriteria()">
        </nbc-select> &nbsp;

      </span>
      <span class="col-md-5" style="padding:6px;line-height:21px;">
        <input class="onboarding-text-box"
               name="contactSearchCriteria"
               [disabled]="searchTextboxEnable"
               placeholder="Enter the Name"
               [(ngModel)]="contactSearchTerm"
               [typeahead]="contactObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="fullName"
               [typeaheadOptionsLimit]="25"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeaheadWaitMs]="500"
               (typeaheadOnSelect)="onContactSearchTermSelect($event)"
               [isAnimated]="true"
               authenticate-user="HR.Filter.Update"
               authenticate-command="hide"
               *ngIf="selectSearchCriteria?.optionValue == '1' || selectSearchCriteria?.optionValue == '3' || selectSearchCriteria?.optionValue == '7'"/>

        <input class="onboarding-text-box"
               name="positionSearchCriteria"
               [disabled]="searchTextboxEnable"
               placeholder="Enter the Position"
               [(ngModel)]="positionSearchTerm"
               [typeahead]="positionObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="description"
               [typeaheadOptionsLimit]="25"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeaheadWaitMs]="500"
               (typeaheadOnSelect)="onPositionSearchTermSelect($event)"
               [isAnimated]="true"
               authenticate-user="HR.Filter.Update"
               authenticate-command="hide"
               *ngIf="selectSearchCriteria?.optionValue == '4'" />

        <input class="onboarding-text-box"
               name="textSearchCriteria"
               [disabled]="searchTextboxEnable"
               placeholder="Enter the Search Term"
               [(ngModel)]="queryContainer.query.searchTerm.description"
               [isAnimated]="true"
               authenticate-user="HR.Filter.Update"
               authenticate-command="hide"
               *ngIf="!selectSearchCriteria || selectSearchCriteria?.optionValue=='0' || selectSearchCriteria?.optionValue == '2' || selectSearchCriteria?.optionValue == '5' || selectSearchCriteria?.optionValue == '6'" />

        <button class="onboarding-go-button"
                [disabled]="goButtonEnable"
                (click)="search()"
                authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
          Go
        </button>

        <button class="onboarding-reset-button"
                (click)="restartSearch()"
                authenticate-user="HR.Filter.Update"
                authenticate-command="hide">
          Reset
        </button>
      </span>
    </div>
  </div>
  <div class="page-content pad-10">
    <p *ngIf="gridData.items.length === 0">No Results</p>
    <div>
      <div *ngIf="gridData.items.length > 0" class="row view-options">
        <div class="col-md-4">
          <div class="form-inline form-sort-options">
            <div class="form-group">
              <nbc-page-size [source]="pageSizeList" [model]="queryContainer.query.pageSize" (nbcValueChange)="searchPage($event)"></nbc-page-size>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group text-end float-end d-flex">
            <label class="pagination-results">
              <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                currentPage="{{queryContainer.query.currentPage}}"
                                totalRecords="{{queryContainer.query.totalRecords}}"></pagination-label>
            </label>
              <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                              itemsPerPage="{{queryContainer.query.pageSize}}"
                              [(currentPage)]="queryContainer.query.currentPage"
                              (pageChanged)="search();"></nbc-pagination>
            
          </div>
        </div>
      </div>
      <history-grid></history-grid>
    </div>
    
  </div>
</div>

