<div class="div-1">
  <header id="col-main-hdr"><h1>Dashboard</h1></header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
    </ul>
  </nav>
  <nbc-alert></nbc-alert>

  <!--<upcoming-events></upcoming-events>-->
  <rem-myevents></rem-myevents>
</div>

<style>
 /* .background {
    background-image: url('/angularclient/src/assets/Content/images/NFL_Field_Background.jpg');
  }
*/
  html, body {
    margin: 0;
    height: 100%;
  }

  .div-1 {
    /* background-color: #FBD603;*/
    /*background-image:;*/
    /*background-image: url('file:///C:\Manjiri\SCORE\Remote\Nbc.Score.Remotes.Web\angularclient\src\assets\Content\images\NFL_Field_Background.jpg');*/
    /*background-image: url("/Content/app/images/tree-736885_1280.jpg");*/
    background-image: url("/Content/app/images/NFL_Field_Background.jpg");
    /*background-image: url(/Content/app/images/NFLField.png);*/
    background-size: cover;
    background-repeat: no-repeat;
    /* width: 1150px;
    height: 100vh;*/
    width: 100%;
    height: 100%;
    /*border: 5px solid red;
    box-sizing: border-box;*/
  }
</style>
