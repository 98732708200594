<div class="box-dash border-dash tranparent" authenticate-user="dashboard.myevents.retrieve">

  <div class="box-dash-hdr-bar backgrounddash headerdiv">
    <div class="float-end btn-fullscreen hidden-sm hidden-xs maximizesign">
      <a title="Fullscreen" (click)="fullScreenmode()">
        <!--<img style="width:20px" src="/Content/app/images/windowmaximize.png" *ngIf="fullscreenMode" />
  <img style="width:20px" src="/Content/app/images/windowrestore.png" *ngIf="!fullscreenMode" />-->
        <i style="color: white; font-size: 22px;  margin-top: 5px; margin-left: 10px;" [ngClass]="!fullscreenMode ? 'fa-window-restore' : 'fa-window-maximize'" class="fa-regular"></i>
      </a>
    </div>
    <h2 class="box-dash-hdr fontcolourdash"><i class="fa fa-flag" aria-hidden="true" style="margin-left:-17px"></i> My Events</h2>

  </div>
  <div class="box-dash-inner-dflt">
    <!--<div class="table-responsive " [ngClass]="status ? 'fullscreen' : ''">-->
    <div class="table-responsive">

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <button type="button" class="btn" [ngClass]="isShowMyUpcoming ? 'btn-dashboardSelected' : 'btn-dashboard'" (click)="showMyUpcomingOrFavourite('upcoming')">My UpComing</button>
          <button type="button" class="btn" [ngClass]="isShowFavourite ? 'btn-dashboardSelected' : 'btn-dashboard'" (click)="showMyUpcomingOrFavourite('favourite')">Favorites</button>
        </div>
      </div>

      <div class="row" *ngIf="isShowMyUpcoming">
        <!--My UpComing-->
        <div class="box-dash-inner-dflt">
          <div class="table-responsive">
            <!--<table class="table-dash table-valign-top" *ngIf="upcomingEvents">
      <tbody>-->
            <!--<tr *ngFor="let event of model.myFavorites | orderBy:['startDate','eventType'] ">-->
            <!--<tr *ngFor="let event of upcomingEvents | orderBy:['startDate','eventTypeName'] ">
            <td class="text-center">
              <img width="45" height="45" src="/assets/image/eventType/{{event.eventTypeImageId}}">
            </td>
            <td class="table-dash-col-full">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-8 col-md-7 col-lg-8">
                    <div class="row">
                      <div class="col-md-12">
                        <h5 (click)="goto(event.id)" class="box-dash-hdr-sub txt-uppercase">
                          <a routerLink="/Events/Event/{{event.eventId}}">{{event.eventName}}</a>
                        </h5>
                      </div>
                    </div>
                    <div class="status-dtl-block status-planning">
                      {{event.startDate|date:"EEE MMM d, yyyy"}}<span> - {{event.endDate|date:"EEE MMM d, yyyy"}}</span>
                      <br />
                      <span class="progress-lbl-sm"><strong>{{event.totalPositions}}</strong> Positions</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>-->
            <div class="row" *ngIf="upcomingEvents">
              <div class="event-tile col-lg-3 col-md-4 col-sm-6 col-xs-12"
                   *ngFor="let event of upcomingEvents | orderBy:['startDate','eventTypeName'] ">
                <div class="event-tile-main">
                  <div class="container-fluid">
                    <div class="row dashboardevents">
                      <div class="col-sm-2 col-mg-2 col-lg-2">
                        <img width="45" height="45" src="/assets/image/eventType/{{event.eventTypeImageId}}">
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <div class="row">
                          <div class="col-md-12">
                            <h5 (click)="goto(event.id)" class="btn-link box-dash-hdr-sub txt-uppercase">
                              <a routerLink="/Events/Event/{{event.eventId}}">{{event.eventName}}</a>
                            </h5>
                          </div>
                        </div>
                        <div class="status-dtl-block status-planning">
                          {{event.startDate|date:"EEE MMM d, yyyy"}}<span> - {{event.endDate|date:"EEE MMM d, yyyy"}}</span>
                          <br />
                          <span class="progress-lbl-sm"><strong>{{event.totalPositions}}</strong> Positions</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p *ngIf="upcomingEvents && upcomingEvents.length == 0 && myEventsComplete">No Results</p>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isShowFavourite">
        <!--My Favorites-->
        <div class="box-dash-inner-dflt">
          <div class="table-responsive">
            <!--<table class="table-dash table-valign-top" *ngIf="favoriteEvents">
    <tbody>
      <tr *ngFor="let event of favoriteEvents | orderBy:['startDate','eventTypeName'] ">
        <td class="text-center">
          <img width="45" height="45" src="/assets/image/eventType/{{event.eventTypeImageId}}">
        </td>
        <td class="table-dash-col-full">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-8 col-md-7 col-lg-8">
                <div class="row">
                  <div class="col-md-12">
                    <h5 (click)="goto(event.id)" class="box-dash-hdr-sub txt-uppercase">
                      <a routerLink="/Events/Event/{{event.eventId}}">{{event.eventName}}</a>
                    </h5>
                  </div>
                </div>
                <div class="status-dtl-block status-planning">
                  {{event.startDate|date:"EEE MMM d, yyyy"}}<span> - {{event.endDate|date:"EEE MMM d, yyyy"}}</span>
                  <br />
                  <span class="progress-lbl-sm"><strong>{{event.totalPositions}}</strong> Positions</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>-->
            <div class="row" *ngIf="favoriteEvents">
              <div class="event-tile col-lg-3 col-md-4 col-sm-6 col-xs-12"
                   *ngFor="let event of favoriteEvents | orderBy:['startDate','eventTypeName'] ">
                <div class="event-tile-main">
                  <div class="container-fluid">
                    <div class="row dashboardevents">
                      <div class="col-sm-2 col-mg-2 col-lg-2">
                        <img width="45" height="45" src="/assets/image/eventType/{{event.eventTypeImageId}}">
                      </div>
                      <div class="col-sm-10 col-md-10 col-lg-10">
                        <div class="row">
                          <div class="col-md-12">
                            <h5 (click)="goto(event.id)" class="btn-link box-dash-hdr-sub txt-uppercase">
                              <a routerLink="/Events/Event/{{event.eventId}}">{{event.eventName}}</a>
                            </h5>
                          </div>
                        </div>
                        <div class="status-dtl-block status-planning">
                          {{event.startDate|date:"EEE MMM d, yyyy"}}<span> - {{event.endDate|date:"EEE MMM d, yyyy"}}</span>
                          <br />
                          <span class="progress-lbl-sm"><strong>{{event.totalPositions}}</strong> Positions</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p *ngIf="favoriteEvents && favoriteEvents.length == 0 && myEventsComplete">No Results</p>
          </div>
      </div>

    </div>
  </div>
</div>
