import { NgModule } from "@angular/core"; 
import { EventBusService } from './services/event-bus.service';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    EventBusService
  ],
})
export class CoreModule { }
