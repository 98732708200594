import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-flag-pay-rate-report",
    templateUrl: "./flag-pay-rate-report.component.html",
})
export class FlagPayRateReportComponent implements OnInit {
    constructor(
        private _reportService: ReportService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {
        this.bsInlineRangeValue = [];
    }
    reportName;
    payRateReport: any;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    flagPayRateReport: any;
    showAlertMessage: any;
    isExport = true;
    showEventsRowAll = true;
    showEventsEditRow = false;
    showEventsTagRow = false;
    eventId = 13;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    eventsList: any[] = [];
    selectedEvent: any[] = [];
    flagPayRateReportInfo: any;
    bsInlineRangeValue: Date[];
    alerts: any;

    cancel() {
        this.bsModalRef.hide();
    }
    onToggleFilterDatepicker($event) {
        if ($event) {
            this.flagPayRateReportInfo.dateRangeStartDate = $event[0];
            this.flagPayRateReportInfo.dateRangeEndDate = $event[1];

            this.bsInlineRangeValue[($event[0], $event[1])];
        }
    }
    openDateRangeDatepicker(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isExport = false;
    }
    editRow(value) {
        switch (value) {
            case "EventsRow":
                {
                    this.showEventsRowAll = false;
                    this.showEventsEditRow = true;
                    this.showEventsTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }
    onItemSelect(event) {
        this.selectedEvent = event;
    }
    confirmSelection(value) {
        if (value === "DateRangeRow") {
            this.showDateRangeRowAll = false;
            this.showSection(value);
        }

        if (value === "EventsRow") {
            this.showEventsEditRow = false;
            this.showSection(value);
        }
    }
    showSection(value) {
        if (value === "DateRangeRow") {
            if (
                this.flagPayRateReportInfo.dateRangeStartDate !== null &&
                this.flagPayRateReportInfo.dateRangeEndDate !== null
            ) {
                this.showDateRangeRowAll = false;
                this.showDateRangeEditRow = false;
                this.showDateRangeTagRow = true;
                this.isExport = false;
            } else {
                this.showDateRangeRowAll = true;
                this.showDateRangeEditRow = false;
                this.showDateRangeTagRow = false;
                this.isExport = true;
            }
        }

        if (value === "EventsRow") {
            if (
                !_.isUndefined(this.selectedEvent) &&
                this.selectedEvent.length > 0
            ) {
                this.showEventsRowAll = false;
                this.showEventsEditRow = false;
                this.showEventsTagRow = true;
            } else {
                this.showEventsRowAll = true;
                this.showEventsTagRow = false;
                this.showEventsEditRow = false;
            }
        }
    }

    stateChanged(checked, value, row) {
        switch (row) {
            case "EventsRow":
                {
                    if (checked) {
                        this.eventsList.push(value);
                        this.flagPayRateReportInfo.events.push(value);
                        this.isExport = false;
                    }
                    if (!checked) {
                        this.eventsList.splice(
                            this.eventsList.indexOf(value),
                            1
                        );
                        this.flagPayRateReportInfo.events.splice(
                            this.flagPayRateReportInfo.events.indexOf(value),
                            1
                        );
                        // document.getElementById("FlatPayrateButton").disabled = true;
                        this.isExport = true;
                    }
                    break;
                }
                break;
        }
    }

    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.flagPayRateReportInfo.dateRangeStartDate = null;
            this.flagPayRateReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            //document.getElementById("FlatPayrateButton").disabled = true;
            this.isExport = true;
        }
    }

    removeTag(item, row, index) {
        switch (row) {
            case "EventsRow":
                {
                    this.eventsList.splice(this.eventsList.indexOf(item), 1);
                    this.selectedEvent.splice(
                        this.selectedEvent.indexOf(item),
                        1
                    );
                    item.value = !item.value;
                    this.showSection("EventsRow");
                    this.isExport = true;
                    break;
                }
                break;
        }
    }

    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    getflagPayRateDetails() {
        this._reportService.getflagPayRateDetails(this.eventId).subscribe({
            next: (data) => {
                if (data == null) {
                    this.showAlertMessage = true;
                    this.log.showModalError(
                        "There are no data points to export for this report at the moment."
                    );
                } else {
                    this.flagPayRateReport = data;
                }
            },
            error: (data) => {
                this.showAlertMessage = true;
                this.log.showModalError("Error Occurred");
            },
        });
    }

    exportflagPayRateReport() {
        const tempflagPayRateReportInfo: any = {};
        tempflagPayRateReportInfo.dateRangeStartDate = this
            .flagPayRateReportInfo.dateRangeStartDate
            ? moment(this.flagPayRateReportInfo.dateRangeStartDate).format(
                  "MM/DD/yyyy"
              )
            : "";
        tempflagPayRateReportInfo.dateRangeEndDate = this.flagPayRateReportInfo
            .dateRangeEndDate
            ? moment(this.flagPayRateReportInfo.dateRangeEndDate).format(
                  "MM/DD/yyyy"
              )
            : "";

        tempflagPayRateReportInfo.events = this.selectedEvent;
        this.isDownload = true;
        this._reportService
            .exportflagPayRateReport(
                this.eventId,
                tempflagPayRateReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        const fileName = "SCORE-Flag Pay-Rate_";
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.showAlertMessage = false;

                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    ngOnInit(): void {
        this.getflagPayRateDetails();
        this.flagPayRateReportInfo = {};
        this.flagPayRateReportInfo.dateRangeStartDate = null;
        this.flagPayRateReportInfo.dateRangeEndDate = null;
        this.flagPayRateReportInfo.events = [];
    }
}
