<nbc-alert></nbc-alert>
<div>
    <div class="content-filters">
        <div class="row">
            <div class="col-lg-1 col-md-2 col-sm-2 position-relative">
                <div class="form-group">
                    <input type="text"
                           class="form-control"
                           numbersOnly
                           [(ngModel)]="seasonQuery.startYear" />
                </div>
                <div class="form-control-compare">to</div>
            </div>
            <div class="col-lg-1 col-md-2 col-sm-2">
                <div class="form-group">
                    <input type="text"
                           class="form-control"
                           numbersOnly
                           [(ngModel)]="seasonQuery.endYear" />
                </div>
            </div>
          <!--<div class="col-lg-1 col-md-2 col-sm-2">
            <div class="input-group form-group">
              <input type="text" class="form-control" numbersOnly [(ngModel)]="seasonQuery.startYear" />
              <span class="form-control-compare">to</span>
            </div>
          </div>
          <div class="col-lg-1 col-md-2 col-sm-2">
            <div class="input-group form-group">
              <input type="text" class="form-control" numbersOnly [(ngModel)]="seasonQuery.endYear" />
            </div>
          </div>-->
            <div class="col-lg-3 col-md-4 col-sm-4">
                <div class="form-group">
                    <input type="text"
                           class="form-control"
                           placeholder="Season Name"
                           appInputRestriction="restrictChars"
                           [(ngModel)]="seasonQuery.seasonName"
                           maxlength="50" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="form-group">
                    <button class="btn btn-secondary"
                            (click)="seasonSearch()">Search</button>
                    <button class="btn btn-default"
                            (click)="resetSeasonSearch()">Reset</button>
                       <a (click)="showFilter = !showFilter"
                       class="float-end lnk-show-hide">
                      {{ showFilter ? 'Hide' : 'Show' }} Filters <i class="fa"
                                                                    [ngClass]="{'fa-sort-asc': showFilter, 'fa-sort-desc': !showFilter}"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="row"
             *ngIf="showFilter">
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="form-group">

                    <nbc-dropdown *ngIf="eventTypes?.length>0"
                                  [source]="eventTypes  | orderBy: 'name'"
                                  [(model)]="seasonQuery.eventType"
                                  display="name"
                                  value="id"
                                  nbcSize="w-100"
                                  placeholder="All Event Types"
                                  (nbcOnOptionSelect)="restartSearch()"></nbc-dropdown>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="form-group">

                </div>
            </div>
        </div>
    </div>
    <div class="main-pane-content">
        <div class="row view-options">
                <div class="col-md-5">
                    <div class="form-inline form-sort-options">
                        <div class="form-group">
                            <label class="form-group-label">Sort:</label>

                            <nbc-dropdown [source]="sortList"
                                          [(model)]="seasonQuery.sortBy"
                                          display="name"
                                          value="predicate"
                                          nbcSize="w-100"
                                          (nbcOnOptionSelect)="restartSearch()"></nbc-dropdown>

                        </div>
                        <div class="form-group">
                            <label class="form-group-label">View:</label>
                            <div class="btn-group">
                                <button class="btn btn-white"
                                        [(ngModel)]="viewFormat"
                                        btnRadio="'grid'"
                                        (click)="showGridView()"><i class="fa fa-th-large"></i></button>
                                <button class="btn btn-white"
                                        [(ngModel)]="viewFormat"
                                        btnRadio="'list'"
                                        (click)="showListView()"><i class="fa fa-bars"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                   <div class="form-group view-options text-end">
                     <div class="mb-3 pagination-container d-flex">
                         <label class="pagination-results pagination-result-pos ms-auto">
                           <pagination-label pageSize="{{model?.pageSize}}"
                                             currentPage="{{model?.currentPage}}}"
                                             totalRecords="{{model?.totalRecords}}">
                           </pagination-label>
                         </label>
                         <nbc-pagination totalItems="{{model?.totalRecords}}"
                                         itemsPerPage="{{model?.pageSize}}"
                                         [(currentPage)]="seasonQuery.currentPage"
                                         (pageChanged)="seasonSearch()">
                         </nbc-pagination>
                       </div>
                   </div>
                </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row no-results"
                     *ngIf="model?.totalRecords==0">There are no results matching the search criteria.</div>

                <div class="view-grid"
                     *ngIf="showGrid">
                    <div class="row">
                        <div class="season-tile col-md-3 col-sm-6 col-xs-12"
                             *ngFor="let season of model?.items">
                            <div class="season-tile-img">
                                <img src="/assets/image/eventType/{{season.eventType.imageId}}"
                                     width="60"
                                     height="60" />
                            </div>
                            <div class="season-tile-dtls">
                                <span class="season-tile-date">
                              {{season.startDate | date }} - {{season.endDate | date}}
                              <span class="col-md-offset-2">
                                  <label class="tier-position"
                                               *ngIf="season?.eventType?.tier > 0">T{{season.eventType.tier}}</label></span></span>
                                <span class="season-tile-title">
                                <a class="btn-link" routerLink="/Events/Season/{{season.id}}">{{season.name}}</a>
                                </span>
                                <span class="season-tile-count">{{season.totalEvents}} Events</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="view-list"
                     *ngIf="!showGrid">
                    <div class="row">
                        <div class="season-tile col-xs-12"
                             *ngFor="let season of model.items">
                            <div class="season-tile-img">
                                <img src="/assets/image/eventType/{{season.eventType.imageId}}"
                                     width="40"
                                     height="40" />
                            </div>
                            <div class="season-tile-dtls">
                                <div class="row">
                                    <span class="season-tile-title col-md-3 col-sm-4">
                                  <a class="btn-link" routerLink="/Events/Season/{{season.id}}">{{season.name}}</a></span>
                                    <span class="season-tile-date col-md-7 col-sm-6">{{season.startDate|date }} - {{
                                        season.endDate |date }}<span class="col-md-offset-2"><label
                                                   class="tier-position"
                                                   *ngIf="season?.eventType?.tier > 0">T{{season.eventType.tier}}</label></span></span>
                                    <span class="season-tile-count col-sm-2">{{season.totalEvents}} Events</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
