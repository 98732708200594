<input [(ngModel)]="searchTerm"
       name="{{nbcName}}"
       [required]="nbcRequired"
       [typeahead]="source$"
       [typeaheadAsync]="true"
       typeaheadOptionField="{{optionDisplay}}"
       [typeaheadOptionsLimit]="OptionsLimit"
       [typeaheadMinLength]="1"
       placeholder="{{placeholder}}"
       class="form-control"
       [isAnimated]="true"
       [typeaheadWaitMs]="200"
       appInputRestriction="restrictChars"
       (typeaheadOnSelect)="onItemSelected($event)"
       (ngModelChange)="onSearchTermUpdate()"
       (blur)="onBlur()">
