import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { positionTemplateService } from "src/app/Shared/Services/positiontemplateservice";
import * as _ from "lodash";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import * as moment from "moment";
import { eventTierService } from "src/app/Shared/Services/eventtier.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NbcBudgetCode } from "src/app/Shared/components/nbc-budgetcode/nbc-budget.component";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { SubSink } from "subsink";

@Component({
    selector: "app-clone-event-modal",
    templateUrl: "./clone-event-modal.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class CloneEventModalComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _positionTemplateService: positionTemplateService,
        private _EventTypeService: EventTypeService,
        private _networkGroupService: networkGroupService,
        private _eventTierService: eventTierService,
        private _EventService: EventService,
        public _modalService: BsModalService,
        public bsModalRef: BsModalRef
    ) {}

    modalWarningRef?: BsModalRef;
    modalBudgetCodeRef?: BsModalRef;

    public onSync!: Subject<any>;
    bsValue = moment().toDate();
    events: any;
    isDataLoaded = true;
    firstAirDate: any;
    defaultDate: Date = new Date();
    private subs = new SubSink();

    clone: any = {
        eventName: "",
        eventId: "",
        eventInformation: true,
        eventPositions: false,
        contacts: false,
        defaultRates: false,
        negotiatedRates: false,
        crewMemberSchedule: false,
        dailyAgenda: false,
        broadcastDate: { airDate: "" },
        rateCardAndRates: false,
        eventLocation: "",
        eventlocationcheck: false,
    };

    EventLocations: any[] = [
        { id: "Features", desc: "Features" },
        { id: "Hybrid", desc: "Hybrid" },
        { id: "Onsite", desc: "Onsite" },
        { id: "Stamford", desc: "Stamford" },
    ];

    hasEventLocation = false;
    eventlocationValid = true;
    hasInactiveMasterData = false;
    inActiveJobtitles = "";
    onBroadcastDateChange(datechange) {
        this.clone.broadcastDate.airDate = datechange;
        return true;
    }

    modalInstance: any;
    allTierNetworkSetups = [] as any;
    networkValidationMessage =
        "This Event Cannot be Cloned because all of the Broadcast Networks are not in the same Network Group.";

    RateCardClone = {
        isActive: true,
        checked: false,
    };
    tierConfig: any = null;
    todaysDate = new Date();
    submitted = false;
    cancel() {
        this.log.trace("canceled modal");

        this.bsModalRef.hide();
    }

    setWBSE() {
        this.log.trace("Updated WBSE");
        if (this.clone.wbse) {
            this.clone.budgetCode = "";
        }
    }

    setEventLocation() {
        this.log.trace("Updated Event Location");
        if (this.clone.eventlocationcheck) {
            this.clone.eventLocation = "";
        }
    }

    ok(isValid) {
        this.submitted = true;
        this.eventlocationValid = true;
        if (
            !this.clone.eventlocationcheck &&
            (this.clone.eventLocation == null ||
                this.clone.eventLocation == undefined ||
                this.clone.eventLocation == "" ||
                this.clone.eventLocation == 0)
        ) {
            this.eventlocationValid = false;
            isValid = false;
        }
        const errorMessage =
            this.clone.crewMemberSchedule &&
            !this.clone.eventPositions &&
            !this.eventlocationValid
                ? "Crew member schedule cannot be cloned unless Event positions is checked."
                : "Form is invalid.";

        if (!isValid) {
            this.log.trace("ok(false)");
            this.log.showModalError(errorMessage);
            return;
        } else {
            this.log.trace("ok(true)");
            this.onSync.next(this.clone);
            this.bsModalRef.hide();
        }
    }

    setCloneEventInfo() {
        if (this.clone.dailyAgenda) {
            this.clone.eventInformation = true;
        }
    }

    checkInactiveMasterData() {
        this.subs.sink = this._EventService
            .checkInactiveMaster(this.events.eventId)
            .subscribe({
                next: (result) => {
                    // this.log.trace("checkInactiveMaster got data" + JSON.stringify(result));
                    this.hasInactiveMasterData = result.reference.length > 0;
                    if (this.hasInactiveMasterData) {
                        const inActivePositions = result.reference.filter(
                            (jobtitle, i, arr) =>
                                arr.findIndex(
                                    (t) =>
                                        t.eventPosition.position.id ===
                                        jobtitle.eventPosition.position.id
                                ) === i
                        );

                        this.inActiveJobtitles = inActivePositions
                            .map(
                                (obj) => obj.eventPosition.position.description
                            )
                            .join(", ");
                    }
                },
                error: (err) => {
                    this.log.showModalError(
                        "checkInactiveMaster got an error: " +
                            JSON.stringify(err)
                    );
                },
            });
    }

    getAllTierNetworkSetup() {
        this.subs.sink = this._networkGroupService.allsetups().subscribe({
            next: (result) => {
                // this.log.trace("getAllNetworks got data" + JSON.stringify(result));
                this.allTierNetworkSetups = result.reference;
                this.validateNetworkGroupCompatibility();
            },
            error: (err) => {
                this.log.error(err);
                this.log.showModalError("getAllNetworks got an error");
            },
        });
    }

    validateNetworkGroupCompatibility() {
        if (_.isEmpty(this.allTierNetworkSetups)) {
            const msg = "Error occured. Not able to proceed.Please try again";
            // showValidationErrorModal("Tier Rate Card", msg, true, closeModal, null, "Proceed")
            //check
            this.showValidationModal("Tier Rate Card", msg, "Proceed", "");
            return false;
        } else {
            const networkGroupIds: any[] = [];

            for (let i = 0; i < this.events.broadcastingNetworks.length; i++) {
                for (
                    let group = 0;
                    group < this.allTierNetworkSetups.length;
                    group++
                ) {
                    const network = _.find(
                        this.allTierNetworkSetups[group].networks,
                        (network) => {
                            return (
                                network.description ===
                                this.events.broadcastingNetworks[i]
                            );
                        }
                    );
                    if (network) {
                        networkGroupIds.push(
                            this.allTierNetworkSetups[group].id
                        );
                    }
                }
            }

            const uniqueGroupIds: any[] = _.uniq(networkGroupIds);
            if (uniqueGroupIds == null || uniqueGroupIds.length > 1) {
                const msg = this.networkValidationMessage;
                this.isDataLoaded = true;
                this.showValidationModal("Tier Rate Card", msg, "Proceed", "");
            } else {
                this.tierConfig = _.find(
                    this.allTierNetworkSetups,
                    {
                        id: uniqueGroupIds[0]
                    }
                );
                this.getNetworkSetup(
                    this.tierConfig.networks[0].id,
                    this.events.eventTypeId
                );
            }
        }
        return true;
    }

    getNetworkSetup(networkId, eventTypeId) {
        this.subs.sink = this._eventTierService
            .getEventTierSetup(networkId, eventTypeId)
            .subscribe(
                (result) => {
                    this.tierConfig = result.reference;
                    this.validateSourceEventCompatibility();
                    this.TierRateBusinessRules();
                },
                (err) => {
                    this.log.error("got an error: ");
                },
                () => {
                    this.isDataLoaded = true;
                }
            );
    }

    validateSourceEventCompatibility() {
        let tierConfigNetworks: any[] = [];
        if (this.tierConfig && this.tierConfig.tierGroupNetworks) {
            tierConfigNetworks = _.map(
                this.tierConfig.tierGroupNetworks,
                "description"
            );
        }

        const uniqueWithSourceEvent = _.difference(
            this.events.broadcastingNetworks,
            tierConfigNetworks
        );

        const uniqueWithTierConfig = _.difference(
            tierConfigNetworks,
            this.events.broadcastingNetworks
        );

        if (
            _.isNumber(this.events.sourceTier) &&
            this.events.sourceTier > 0 &&
            this.tierConfig &&
            _.isNumber(this.tierConfig.tierId) &&
            this.tierConfig.tierId > 0
        ) {
            if (uniqueWithSourceEvent && uniqueWithSourceEvent.length > 0) {
                const msg = this.networkValidationMessage;
                this.showValidationModal("Tier Rate Card", msg, "Proceed", "");
            }
        }

        if (
            !this.events.sourceTier &&
            this.tierConfig &&
            _.isNumber(this.tierConfig.tierId) &&
            this.tierConfig.tierId > 0
        ) {
            if (uniqueWithSourceEvent && uniqueWithSourceEvent.length > 0) {
                const msg = this.networkValidationMessage;
                this.showValidationModal("Tier Rate Card", msg, "Proceed", "");
            }
        }
    }

    TierRateBusinessRules() {
        if (
            _.isNumber(this.events.sourceTier) &&
            this.events.sourceTier > 0 &&
            this.tierConfig &&
            _.isNumber(this.tierConfig.tierId) &&
            this.tierConfig.tierId > 0
        ) {
            if (this.events.sourceTier === this.tierConfig.tierId) {
                this.RateCardClone.isActive = true;
                this.RateCardClone.checked = false;
            }

            if (this.events.sourceTier !== this.tierConfig.tierId) {
                this.RateCardClone.isActive = false;
                this.RateCardClone.checked = false;
            }
        } else if (
            !this.events.sourceTier &&
            this.tierConfig &&
            (!this.tierConfig.tierId || this.tierConfig.tierId == 0)
        ) {
            this.RateCardClone.isActive = true;
            this.RateCardClone.checked = true;
            //  $scope.clone.rateCardAndRates = true;
        } else if (
            !this.events.sourceTier &&
            this.tierConfig &&
            _.isNumber(this.tierConfig.tierId) &&
            this.tierConfig.tierId > 0
        ) {
            this.RateCardClone.isActive = false;
            this.RateCardClone.checked = false;
        }
    }

    openWBSESearchModal() {
        this.log.trace("Opening modal");

        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            id:2,
            animated: true,
            backdrop: true,
            keyboard: false,
            class: "child-modal2",
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.modalBudgetCodeRef = this._modalService.show(
            NbcBudgetCode,
            modalOptions
        );
        setTimeout(() => {
          document.querySelectorAll(
            ".child-modal2"
          )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
        }, 200);
        this.modalBudgetCodeRef.content.onSync.subscribe((result) => {
            this.clone.budgetCode = result;
        });
    }

    showValidationModal(title, message, confirmText, cancelText) {
        this.log.trace("showValidationErrorModal");

        if (confirmText == null) confirmText = "Continue";

        const initialState: any = {
            id:2,
            self: this,
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText || "Continue",
            cancelText: cancelText,
            hideConfirm: false,
        };
        const modalOptions = {
            backdrop: true,
            keyboard: false,
            class: "child-modal2",
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.modalWarningRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        setTimeout(() => {
          document.querySelectorAll(
            ".child-modal2"
          )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
        }, 200);
        this.modalWarningRef.content.onAffirmation.subscribe((result) => {
            this.cancel();
        });
    }
    activateRates() {
        if (
            !this.events.sourceTier &&
            this.tierConfig &&
            (!this.tierConfig.tierId || this.tierConfig.tierId == 0)
        ) {
            if (this.clone.eventPositions && this.clone.contacts) {
                this.clone.rateCardAndRates = true;
            } else {
                this.clone.rateCardAndRates = false;
            }
        }
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.events = this.events;
        this.tierConfig = this.tierConfig;
        this.clone.eventId = this.events.eventId;
        this.clone.eventName = this.events.eventName + " Clone 1";
        this.hasEventLocation =
            this.events.eventLocation != "" &&
            this.events.eventLocation != null;
        this.checkInactiveMasterData();
        this.getAllTierNetworkSetup();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
