<div class="form-inline">
  <div class="form-group form-group-wrapper">
    <input type="text"
           appInputRestriction="restrictChars"
           name="eventName"
           class="form-control"
           placeholder="Event Name"
           [(ngModel)]="dataModel.eventName"
           maxlength="100" />
  </div>
  <div class="form-group form-group-wrapper">
    <input name="venue"
           [(ngModel)]="venueSearchTerm"
           appInputRestriction="restrictChars"
           [typeahead]="searchVenue$"
           typeaheadOptionField="name"
           [typeaheadMinLength]="2"
           [typeaheadOptionsLimit]="50"
           placeholder="Select Venue"
           class="form-control"
           [isAnimated]="true"
           [typeaheadWaitMs]="200"
           (typeaheadOnSelect)="onVenueSelect($event)">
  </div>

</div>
