import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";

@Component({
    selector: "nbc-typeahead",
    templateUrl: "nbc-typeahead.component.html",
})
export class NbcTypeaheadComponent implements OnChanges {
    constructor() {}

    @Input() searchTerm!: string;
    @Input() model!: any;
    @Input() source$: Observable<any[]> = new Observable();
    @Input() Service$: Observable<any[]> = new Observable();
    @Input() optionDisplay!: string;
    @Input() placeholder!: any;
    @Input() nbcName!: string;
    @Input() nbcRequired = false;
    @Input() OptionsLimit = 10;
    @Input() nbcAuthenticateUser!: string;
    @Input() nbcAuthenticateCommand!: string;
    @Input() nbcMandatorySelect = false;

    @Output() searchTermChange = new EventEmitter<any>();
    @Output() modelChange = new EventEmitter<any>();
    @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();

    onItemSelected($event: any) {
        this.model = $event.item;
        this.modelChange.emit($event.item);
        this.onItemSelect.emit($event.item);
    }

    onSearchTermUpdate() {
        this.searchTermChange.emit(this.searchTerm);
    }

    onBlur() {
        if (!this.model && this.nbcMandatorySelect) {
            this.searchTerm = "";
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                this.model = current;
            }
            if (propName === "searchTerm") {
                const current = changes["searchTerm"]["currentValue"];
                const prev = changes["searchTerm"]["previousValue"];
                if (current === "") {
                    this.modelChange.emit(null);
                    this.onItemSelect.emit(null);
                }
            }
        }
    }
}
