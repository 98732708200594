<div id="header-profile">
  <div style="padding-bottom: 3px;">
    <span href="#" id="header-user">
      <i class="fa fa-user"></i>
      <span class="user-name">
        {{user.firstName}} {{user.lastName}}
      </span>
    </span> |
    <a data-toggle="modal" style="cursor: pointer;" (click)="openRemotesHelpModel('olympic'); openHelpModel()" class="btn-link">Help</a>|
    <a (click)="logout()" class="btn-link">Log Out</a>
  </div>
  <div>
    <span style="font-size:11px;padding-left:25px;">
      Last Login - {{user.lastLogin}}
    </span>
  </div>
</div>
