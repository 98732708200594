<div class="contact-search-flyout-wrapper showIt" [ngClass]="isFullScreenMode ? 'pt-5':''"
     *ngIf="flyout">
  <div class="contact-search-flyout">
    <div class="contact-form-wrapper">
      <form role="form"
            #contactSearchForm="ngForm"
            name="contactSearchForm"
            novalidate
            class="pad-5">
        <button type="button"
                class="btn btn-icon btn-people"
                (click)="toggleFlyout()"> </button>
        <div class="control-label control-label-error"
             style="color:#f75b5b"
             *ngIf="!isValidSubmission">Please fill in at least one search criterion.</div>
        <label class="sr-only">Name</label>
        <div class="row">
          <div class="form-group col-sm-6">
            <input type="text"
                   class="form-control input-sm"
                   [ngModelOptions]="{standalone: true}"
                   id="contactFirstName"
                   placeholder="First Name"
                   [(ngModel)]="contactQuery.firstName" />
          </div>
          <div class="form-group col-sm-6">
            <input type="text"
                   class="form-control input-sm"
                   [ngModelOptions]="{standalone: true}"
                   id="contactLastName"
                   placeholder="Last Name"
                   [(ngModel)]="contactQuery.lastName" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <input type="text"
                   class="form-control input-sm"
                   id="sso"
                   placeholder="SSO"
                   [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="contactQuery.sso" />
          </div>
          <div class="form-group col-sm-6">
            <input type="text"
                   class="form-control input-sm"
                   id="email"
                   placeholder="Email"
                   [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="contactQuery.email" />
          </div>
        </div>
        <div class="form-group">
          <nbc-tags [(searchTerm)]="positionSearchTerm"
                    [searchTermObservable$]="positionObservable$"
                    [tagDisplay]="'description'"
                    [placeholder]="'Position(s)'"
                    [tagId]="contactQuery.positions"
                    [tagName]="contactQuery.positions"
                    [tags]="contactQuery.positions"
                    (tagChange)="setPositionEventTerm($event)"
                    (nbcOnOptionSelect)="onPositionSelected($event)"></nbc-tags>



        </div>
        <div class="form-group">
          <div>
            <nbc-tags [(searchTerm)]="crewPositionSearchTerm"
                      [searchTermObservable$]="crewedPositionObservable$"
                      [tagDisplay]="'description'"
                      [tagId]="contactQuery.crewedPositions"
                      [tagName]="contactQuery.crewedPositions"
                      [tags]="contactQuery.crewedPositions"
                      [placeholder]="'Crewed Position(s)'"
                      (tagChange)="setCrewPositionEventTerm($event)"
                      (nbcOnOptionSelect)="onCrewedPositionSelected($event)"></nbc-tags>
          </div>
        </div>
        <div class="form-group">
          <nbc-typeahead [(searchTerm)]="venueSearchTerm"
                         [(model)]="contactQuery.venue"
                         placeholder="Search Venue"
                         optionDisplay="displayText"
                         [source$]="searchVenueObservable$"
                         [nbcMandatorySelect]="false"
                         (onItemSelect)="contactQuery.searchVenueId=contactQuery.venue?.id||null"
                         OptionsLimit="50">
          </nbc-typeahead>

        </div>
        <div class="row">
          <div class="clearfix col-sm-6">
            <div class="form-group">
              <label class="control-label">Resides Within:</label>
              <select class="dropdown-toggle form-select input-sm"
                      [(ngModel)]="contactQuery.radius"
                      [ngModelOptions]="{standalone: true}">
                <option [ngValue]=""></option>
                <option *ngFor="let radius of radii"
                        [ngValue]="radius">
                  {{radius.description}}
                </option>
              </select>
            </div>
          </div>
          <div class="clearfix col-sm-6">
            <div class="form-group">
              <label class="control-label">of Location:</label>
              <input class="form-control input-sm"
                     type="text"
                     [(ngModel)]="contactQuery.searchLocation"
                     [ngModelOptions]="{standalone: true}"
                     placeholder="(City & State) or Zip" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="clearfix col-sm-6">
            <div class="form-group">
              <label class="control-label">Union Designation:</label>
              <select class="dropdown-toggle form-select input-sm"
                      [(ngModel)]="contactQuery.union"
                      [ngModelOptions]="{standalone: true}">
                <option [ngValue]=""></option>
                <option *ngFor="let union of unions"
                        [ngValue]="union"
                        (click)="data(union)">
                  {{union.description}}
                </option>
              </select>
            </div>
          </div>
          <div class="clearfix col-sm-6">
            <div class="form-group">
              <label class="control-label">Employee Type:</label>
              <select class="dropdown-toggle form-select input-sm"
                      id="empType"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="contactQuery.employeeType">
                <option [ngValue]=""></option>
                <option *ngFor="let employeeType of employeeTypes"
                        [ngValue]="employeeType">
                  {{employeeType.description }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Have Worked On</label>
            <select class="dropdown-toggle form-select input-sm"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="contactQuery.eventType">
              <option value=""></option>
              <option *ngFor="let eventType of eventTypes"
                      [ngValue]="eventType">
                {{eventType.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <label>Have Worked With</label>
            <label class="sr-only">Director or Producer</label>

            <nbc-typeahead [(searchTerm)]="contactSearchTerm"
                           [(model)]="workedWith"
                           placeholder="Director or Producer"
                           optionDisplay="description"
                           [source$]="contacts$"
                           OptionsLimit="20"
                           (onItemSelect)="setWorkedWith($event)">

            </nbc-typeahead>
          </div>
        </div>

        <div class="form-group">
          <button type="submit"
                  class="btn btn-secondary btn-sm"
                  (click)="searchContacts(contactSearchForm)">
            Search
          </button>
          <button type="reset"
                  class="btn btn-default btn-sm"
                  (click)="reset()">
            Reset
          </button>
          <label>(Max 50 Results Returned)</label>
          <div class="error"
               *ngIf="flyoutIsEmpty">
            Need at least one parameter!
          </div>
        </div>
      </form>
    </div>
    <div class="contact-results-wrapper">
      <div class="contact-card-wrapper pad-5"
           *ngIf="hasSearchResults">
        <div class="container-fluid">
          <div class="row" cdkDropList [cdkDropListData]="contacts.items">
            <div class="col-sm-6"
                 *ngFor="let contact of contacts.items">
              <div class="contact-card">
                <div class="contact-avatar">
                  <img cdkDrag [cdkDragData]="contact"
                       src="/assets/image/contact/{{contact.imageId}}" style="width:30px;height:30px;" />
                </div>
                <div class="contact-name">
                  <span>
                    <a routerLink="/Contacts/Detail/{{contact.id}}"
                       title="{{contact.firstName}} {{contact.middleName}} {{contact.lastName}}"
                       target="_blank"
                       class="btn-link"
                       authenticate-user="Contact_Profile_Header.Retrieve, contact_profile_profile_info.retrieve"
                       authenticate-command="disableanchor">
                      {{contact.firstName}}
                      {{contact.middleName}} {{contact.lastName}}
                    </a>
                  </span>
                </div>
                <div class="row">
                  <div class="col-md-10">
                    <div class="location country-{{contact.country|lowercase}}"
                         *ngIf="contact.city || contact.state">
                      <span class="flag-icon flag-icon-{{ contact.country | lowercase }}"></span>
                      {{contact.city}}<span *ngIf="contact.city && contact.state">,</span>
                      {{contact.province}}
                    </div>
                    <div class="position">
                      <span *ngfor="let skill of contact.skills">
                        {{skill.description}}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="pull-right" *ngIf="contact.flaggedByHR">
                      <span class='fa-stack fa-1x'>
                        <i class='fa fa-circle fa-stack-2x'
                           style='color:red;'></i><i class='fa fa-warning fa-stack-1x'
                                                     style='color:yellow;'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
