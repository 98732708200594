<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button"
  class="btn-close"
  aria-hidden="true"
  (click)="closeModal()">
</button>
</div>

<div class="modal-body">
  <!--*ngIf="eventDetails && (!positionDetails || !positionDetails.payRate)">
  Pay Rate should be available to request the position for Onboarding-->
  <!--<span style="color:red"
        *ngIf="!isFormDirty && !submitted">
    There are no changes saved.
  </span>

  <span style="color:green"
        *ngIf="submitted">
    The Position Request has been successfully updated.
  </span>-->
  <nbc-alert></nbc-alert>

  <br />
  <form #frmPositionRequest="ngForm">
    <nbc-modal-alert></nbc-modal-alert>
    <div class="row">
      <div class="col-md-12">
        <div class="form-box">
          <div class="row">
            <div class="col-md-4">
              <b>Production Manager</b>
              <input type="text"
                     name="productionManager"
                     value="{{productionManager}}"
                     class="form-control req-modal"
                     placeholder=""
                     maxlength="100"
                     [disabled]="true" />
            </div>
            <div class="col-md-4">
              <b>Reports To</b>
              <div class="form-group">
                <input type="text"
                       name="reportsTo"
                       value="{{reportsTo}}"
                       class="form-control req-modal"
                       placeholder=""
                       maxlength="100"
                       [disabled]="true" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row"
             style="margin-left:1px">
          <div class="col-md-4">
            <b>Position Start Date</b>

            <div class="form-group"
                 [ngClass]="{'has-error': submitted && isRequiredValidation && isPositionStartDateInvalid}">
              <div class="form-group">
                <div class="input-group input-group-set"
                     date-time-append>
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  <input type="datetime"
                         name="positionStartDate"
                         class="form-control req-modal"
                         required
                         bsDatepicker
                         [(bsValue)]="positionStartDate"
                         #drp="bsDatepicker"
                         (minDate)="date"
                         (maxDate)="date"
                         readonly
                         [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}">
                </div>
                <!--<span class="control-label control-label-error"
        *ngIf="isRequiredValidation && isPositionStartDateInvalid && submitted ">
    Position Start Date
    should be between Crew Work Schedule date.
  </span>-->
                <span class="control-label control-label-error"
                      *ngIf="isPositionStartDateInvalid">
                  Position Start Date
                  should be between Crew Work Schedule date.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <b>Company Code</b>
            <div class="form-group">
              <div [ngClass]="{'has-error': !companyCode && submitted && isRequiredValidation}">
                <nbc-select *ngIf="companyCodeDDl?.options?.length>0"
                            name="companyCodeDDl"
                            [source]="companyCodeDDl.options"
                            [(model)]="companyCode"
                            [nbcRequired]="true"
                            [customErrorClass]="(!companyCode && submitted) ? 'nbc-control-error':''"
                            display="description"
                            value="id">
                </nbc-select>
                <span class="control-label control-label-error"
                      *ngIf="!companyCode && isRequiredValidation && submitted">
                  Company Code is required.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row"
             style="margin-left:1px">
          <div class="col-md-4">
            <b>Position Type</b>
            <div class="form-group">
              <div [ngClass]="{'has-error': !positionType && submitted}">
                <nbc-select name="positionTypeDDl"
                            [source]="positionTypeDDl.options"
                            [(model)]="positionType"
                            [nbcRequired]="true"
                            [customErrorClass]="(!positionType && submitted) ? 'nbc-control-error':''"
                            display="description"
                            value="id">
                </nbc-select>

                <span class="control-label control-label-error"
                      *ngIf="!positionType && isRequiredValidation && submitted">Position Type is required.</span>

              </div>
            </div>
          </div>
          <div class="col-md-4">
            <b>FLSA Status</b>
            <div class="form-group">

              <input type="text"
                     name="email"
                     value="{{flsa}}"
                     class="form-control req-modal"
                     placeholder=""
                     maxlength="100"
                     [disabled]="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12"
           *ngFor=" let contact of contacts">
        <div class="form-box">
          <h3>Employee Details</h3>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <b>Email</b>
                  <div class="form-group">
                    <input type="text"
                           name="email"
                           value="{{contact.email}}"
                           class="form-control req-modal"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>First Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="firstName"
                           value="{{contact.firstName}}"
                           class="form-control req-modal"
                           [ngModelOptions]="{standalone: true}"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Middle Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="contactMiddleName"
                           value="{{contact.contactMiddleName}}"
                           class="form-control req-modal"
                           placeholder=""
                           [ngModelOptions]="{standalone: true}"
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <b>Last Name</b>
                  <div class="form-group">
                    <input type="text"
                           name="lastName"
                           value="{{contact.lastName}}"
                           class="form-control req-modal"
                           [ngModelOptions]="{standalone: true}"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <b>State of Legal Residence</b>

                  <div class="form-group">
                    <input type="text"
                           name="lastName"
                           value="{{contact.state}}"
                           class="form-control req-modal"
                           [ngModelOptions]="{standalone: true}"
                           placeholder=""
                           maxlength="100"
                           [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer text-left col-md-12">
  <div class="col-md-6">
    <button type="submit"
            class="btn btn-primary"
            [disabled]="!disableSubmitPositionRequestButtonOnClick ? false : true"
            (click)="isRequiredValidation = true;submitted=true;savePositionRequest(frmPositionRequest);">
      Update Request
    </button>
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
