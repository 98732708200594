import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PermissionService } from "src/app/Shared/Services/permission.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import * as _ from "lodash";
import { ContactSharedDataService } from "../../contact-shared.service";
import { subscribeOn, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "olympics-permission",
    templateUrl: "./olympics-permissions.component.html",
})
export class OlympicsPermissionComponent implements OnInit, OnDestroy {
    constructor(
        private _contactSharedData: ContactSharedDataService,
        private _permissionService: PermissionService,
        private log: NbcLoggerService
    ) {}
    componentDestroyed$: Subject<boolean> = new Subject();
    dropdownList!: any[];
    selectedItems!: any[];
    dropdownSettings!: IDropdownSettings;
    roles: any | undefined;
    selectedrole: any | null;
    roleCombo: any[] = [];
    roleCombos: any[] = [];
    user: any | undefined;
    contact: any | undefined;
    permissions: any | undefined;
    resultsFound = false;

    getRoles() {
        this.log.trace("getWorkSchedule");

        this._permissionService
            .getRoles()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("getRoleSetup got results: " + result);
                    if (result && result.reference) {
                        this.roles = result.reference;
                    }
                },
                error: (err) => {
                    this.log.error("got an error: " + err?.error?.message);
                    this.log.showError(
                        "Unable to load the permission at this time. Please try again later."
                    );
                },
            });
    }

    getRoleCombos() {
        this.roleCombos = [];
        this._permissionService
            .getRoleCombos(this.selectedrole.roleNo)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    // this.log.trace("getRoleSetup got results: " + result);
                    this.roleCombo = [];
                    if (result && result.reference) {
                        this.roleCombos = result.reference;
                    }
                    this.checkandmapRoleCombos();
                },
                error: (err) => {
                    this.log.error("got an error: " + err?.error?.message);
                    this.log.showError(
                        "Unable to load the events at this time. Please try again later."
                    );
                },
            });
    }

    checkandmapRoleCombos() {
        if (this.selectedrole) {
            const userDefaults = _.filter(this.user.userDefaultRows, (role) => {
                return role.division.code === "OLY";
            });
            const matchrole = _.filter(this.user.userRoleRows, (role) => {
                return (
                    role.userDefaultNo === userDefaults[0].userDefaultRowNo &&
                    role.roleNo.roleNo === this.selectedrole.roleNo
                );
            });
            if (matchrole && matchrole.length > 0) {
                const rolecombos = matchrole[0].roleCombos.split(",");
                _.each(rolecombos, (rolecombo) => {
                    if (rolecombo !== "") {
                        const rolecom = _.filter(this.roleCombos, (role) => {
                            return role.roleComboNo == rolecombo;
                        });
                        if (rolecom) {
                            if (_.isUndefined(this.roleCombo))
                                this.roleCombo = [];
                            this.roleCombo.push(rolecom[0]);
                        }
                    }
                });
            }
        }
    }

    addRoles() {
        this.log.trace("getWorkSchedule");

        const userDefaults = _.filter(
            this.user.userDefaultRows,
            function (role) {
                return role.division.code === "OLY";
            }
        );
        const rolecombosnos = this.roleCombo
            .map(function (e) {
                return e.roleComboNo;
            })
            .join(",");
        if (this.contact.sso != "") {
            if (this.user.listId === 0) {
                this.user.userId = this.contact.sso.toString();
                this.user.firstName = this.contact.firstName;
                this.user.lastName = this.contact.lastName;
                this.user.listId = this.contact.id;
                const match = _.find(
                    this.user.userDefaultRows,
                    function (role) {
                        return role.division.code === "OLY";
                    }
                );
                if (match) {
                    match.checkBox1 = "Y";
                }
            }

            let userrole = 0;

            const matchrole = _.filter(this.user.userRoleRows, (role) => {
                return (
                    role.userDefaultNo === userDefaults[0].userDefaultRowNo &&
                    role.roleNo.roleNo === this.selectedrole.roleNo
                );
            });
            if (matchrole && matchrole.length > 0) {
                userrole = matchrole[0].userRoleNo;
            }

            const role = {
                roleCombos: rolecombosnos,
                roleNo: this.selectedrole,
                userDefaultNo: userDefaults[0].userDefaultRowNo,
                userRoleNo: userrole,
            };

            this.user.userRoleRows.push(role);
            this._permissionService
                .SaveREMOLYUserPermissions(this.user)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result) => {
                        // this.log.trace("getRoleSetup got results: " + result);
                    },
                    error: (err) => {
                        this.log.error("got an error: " + err?.error?.message);
                        this.log.showError(
                            "Unable to Save role at this time. Please try again later."
                        );
                    },
                    complete: () => this.getUserPermissions(),
                });
        }
    }

    getUserPermissions() {
        this.log.trace("getWorkSchedule");
        if (this.contact.sso != "") {
            this._permissionService
                .getRemOlyUserPermission(this.contact.id)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe({
                    next: (result) => {
                        //this.log.trace("getRoleSetup got results: " + result);
                        if (result) {
                            this.user = result["reference"];
                            const userDefaults = _.filter(
                                this.user.userDefaultRows,
                                function (role) {
                                    return role.division.code === "OLY";
                                }
                            );
                            this.permissions = _.filter(
                                this.user.userRoleRows,
                                function (role) {
                                    return (
                                        role.userDefaultNo ===
                                        userDefaults[0].userDefaultRowNo
                                    );
                                }
                            );
                            this.resultsFound = true;
                        } else {
                            this.resultsFound = false;
                        }
                    },
                    error: (err) => {
                        this.log.error("got an error: " + err?.error?.message);
                        this.log.showError(
                            "Unable to load the events at this time. Please try again later."
                        );
                    },
                });
        }
    }
    checkRole() {
        return this.selectedrole === null || this.selectedrole === undefined;
    }

    onItemSelect(item: any) {
        this.roleCombo = item;
    }
    onSelectAll(items: any) {
        // this.selectedValues.emit(event);
    }
    ngOnInit() {
        this._contactSharedData.getContact().subscribe((contact) => {
            this.contact = contact;
            this.getUserPermissions();
        });

        this.getRoles();
        this.selectedrole = null;

        //this.roles = [
        //  { roleNo: 1, roleDesc: 'Mumbai' },
        //  { roleNo: 2, roleDesc: 'Bangaluru' },
        //  { roleNo: 3, roleDesc: 'Pune' },
        //  { roleNo: 4, roleDesc: 'Navsari' },
        //  { roleNo: 5, roleDesc: 'New Delhi' },
        //];
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
