
<div class="page-container">
  <div class="container-fluid pad-10">

    <!--SCOR-21168-->
    <!--Header-->
    <div class="row" id='CreateEvent'>
      <div class="col-md-7">
        <div class="float-start">
          <a authenticate-user="event.create"
             class="btn btn-primary btn-block" style="background-color:green"
             routerLink="../Events/CreateSeasonOrEvent">+ Create Season or Event</a>
        </div>
      </div>
    </div>
    <!--Upper section-->
    <div class="row" id='toprow' style="margin-top: 20px;">
      <div class="col-md-12 col-sm-12 col-lg-12" id='positionrequest'>
        <app-position-request-onboarding></app-position-request-onboarding>
      </div>
    </div>
    <div class="row" id='toprow' style="margin-top: 20px;">
      <div class="col-md-12 col-sm-12 col-lg-12" id='eventdashboard'>
        <app-my-event-dashboard></app-my-event-dashboard>
      </div>
    </div>

    <div class="row" id='bottomrow' style="margin-top: 20px;">
      <div class="col-md-6 col-sm-12 col-lg-6" id='eventrecentlyviewed'>
        <app-dashboard-event-recentlyviewed></app-dashboard-event-recentlyviewed>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-6" id='contactrecentlyviewed'>
        <app-dashboard-contact-recentlyviewed></app-dashboard-contact-recentlyviewed>
      </div>
    </div>

    <div class="row" id='timecardrow' style="margin-top: 20px;">
      <div class="col-md-6 col-sm-12 col-lg-6" id='pendingtimecards'>
        <app-dashboard-pendingtimecards></app-dashboard-pendingtimecards>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-6" id='validationfailedtimecards'>
        <app-dashboard-validationfailedtimecards></app-dashboard-validationfailedtimecards>
      </div>
    </div>
  </div>
  </div>
