import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { ContactService } from "src/app/Shared/Services/contact.service";

@Component({
    selector: "nbc-contact-auto",
    templateUrl: "./contact-typeahead.component.html",
})
export class ContactTypeaheadComponent implements OnInit {
    constructor(
        private _eventBus: EventBusService,
        private _contactService: ContactService
    ) {}

    @Input() listItemId: string | undefined;
    @Input() eventHandler: string | undefined;
    @Input() model: string | undefined;
    @Input() placeholder: string | undefined;
    @Input() hasFocus = false;
    @Output() hasFocusChange: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    componentDestroyed$: Subject<boolean> = new Subject();
    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm: string | undefined;
    hasContactSelected = false;

    onBlur() {
        if (!this.hasContactSelected) {
            this.model = "";
            this.contactSearchTerm = "";
        }
    }

    onFocus() {
        this.hasFocus = true;
        this.hasFocusChange.emit(this.hasFocus);
    }

    onContactSelected($event) {
        const contact = $event.item;
        const contactName =
            contact.firstName + " " + contact.middleName
                ? contact.middleName
                : "" + " " + contact.lastName;
        this.model = contactName;
        this.modelChange.emit(contactName);
        //this.BackupItems.push(contact);
        this.contactSearchTerm = contactName;
        this.hasContactSelected = true;
        const data = {
            id: this.listItemId,
            contact: contact,
        };
        this._eventBus.emit(
            new EmitEvent(
                "ContactTypeahead:contactSelected",
                "onContactSelect",
                data
            )
        );

        this.hasFocus = false;
        this.hasFocusChange.emit(this.hasFocus);
    }

    contactTypeahed() {
        this.hasContactSelected = false;
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._contactService.searchByName(token);
            })
        );
    }

    ngOnInit() {
        if (this.model) this.contactSearchTerm = this.model;

        this.contactTypeahed();
    }
}
