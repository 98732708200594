import { Pipe, PipeTransform } from "@angular/core";
import { pipe } from "rxjs";
import { retry } from "rxjs/operators";

@Pipe({
    name: "positionFilter",
})
export class PositionFilterPipe implements PipeTransform {
    transform(position: any[], searchTerm: string): any[] {
        if (!position || !searchTerm) {
            return position;
        }

        return position.filter(
            (position) =>
                position.eventName
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1 ||
                position.contactName
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1 ||
                position.onboardingStatus
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1
        );
    }
}
