import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable } from 'rxjs';
/*import { ISelect } from 'src/app/Event/dashboard/eventlist.component'*/;

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) {
  }


  search(eventTypeId) {
  this.log.trace("retrieving active position templates");
  return this.http.get(this._apiroot.baseUrl + "positiontemplate/" + eventTypeId + "/active");
  };

  getTemplates  (name, active) {
    this.log.trace("retrieving position templates with optional name and active status");
    return this.http.get(this._apiroot.baseUrl + "positionTemplate?name=" + (name || "") + "&active=" + (active || ""));
  };

  getTemplateById  (positionTemplateId) {
    this.log.trace("retrieving position template by id " + positionTemplateId);
    return this.http.get(this._apiroot.baseUrl + "positionTemplate/" + positionTemplateId);
  };

  getTemplateByEventTypeId  (eventTypeId) {
    this.log.trace("retrieving position position template by event type id " + eventTypeId);
    return this.http.get(this._apiroot.baseUrl + "positionTemplate/" + eventTypeId + "/active");
  };

  updateTemplate  (positionTemplateObj) {
    this.log.trace("adding or updating a position template " + JSON.stringify(positionTemplateObj));
    return this.http.post(this._apiroot.baseUrl + "positionTemplate", positionTemplateObj);
  };

  deletePosition  (positionTemplateId, positionItemId) {
    this.log.trace("delete position " + positionItemId);
    return this.http['delete'](this._apiroot.baseUrl + "positionTemplate/" + positionTemplateId + "/positions/" + positionItemId);
  };

  updatePositions  (positionTemplateId, positionTemplateItems) {
    this.log.trace("adding or updating positions for a position template " + JSON.stringify(positionTemplateItems));
    return this.http.post(this._apiroot.baseUrl + "positionTemplate/" + positionTemplateId + "/positions", positionTemplateItems);
  };

  clonePosition  (sourceId, overrides) {
    this.log.trace("cloning position " + sourceId + " with the following overrides: " + JSON.stringify(overrides));
    return this.http.post(this._apiroot.baseUrl + "positionTemplate/" + sourceId + "/clone", overrides);
  };

}
