import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
    providedIn: "root",
})
export class NetworkService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    //@LiquidCache('getNetworks')
    getNetworks(): Observable<any> {
        this.log.debug("fetching networks");
        return this.http.get(this._apiroot.baseUrl + "networks", {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    //@LiquidCache('getAllNetworks')
    getAllNetworks(): Observable<any> {
        this.log.trace("get a distinct list of networks");
        return this.http
            .get(this._apiroot.baseUrl + "admin/network/allnetworks")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    upsert(network: any): Observable<any> {
        this.log.trace("update network");
        return this.http
            .post(
                this._apiroot.baseUrl + "admin/network/upsertnetwork",
                network
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
