<form>
  <div>
    <div class="content-filters">
      <div class="row">
        <div class="col-lg-3 col-md-4">
          <div class="mb-3">
            <div class="input-group input-group-set dropdown"
                 dropdown>
              <span class="input-group-text"
                    dropdown-toggle><i class="fa fa-calendar"></i></span>
              <input type="text"
                     placeholder="Daterangepicker"
                     readonly
                     class="form-control"
                     bsDaterangepicker
                     (bsValueChange)="onToggleFilterDatepicker($event)"
                     [bsValue]="bsInlineRangeValue"
                     [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}" />
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="mb-3">
            <div class="d-flex">
                  <div class="dropdown input-group-text border-0 p-0" dropdown>
                    <button id="dropdownVenue"dropdownToggle
                            type="button"class="bg-transparent form-control">
                      <span [hidden]="!showVenue">Venue</span>
                      <span [hidden]="showVenue">Location</span>
                      <i class="fa fa-sort" style="padding-left:5px;"></i>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdownVenue">
                      <li [ngClass]="{'active':showVenue}">
                        <a role="menuitem" class="dropdown-item" tabindex="-1" (click)="hideLocation()">Venue</a>
                      </li>
                      <li [ngClass]="{'active':!showVenue}">
                        <a role="menuitem" class="dropdown-item" tabindex="-1" (click)="showLocation()">Location</a>
                      </li>
                    </ul>
                  </div>
                  <div class="animate-switch-container w-100" [ngSwitch]="showVenue">
                    <div class="animate-switch" *ngSwitchCase="true">
                      <input [(ngModel)]="venueSearchTerm"
                             appInputRestriction="restrictChars"
                             class="form-control"
                             name="venue"
                             placeholder="Select Venue"
                             [typeahead]="venueSearchObservable$"
                             [typeaheadAsync]="true"
                             typeaheadOptionField="displayText"
                             [typeaheadOptionsLimit]="10"
                             [isAnimated]="true"
                             [typeaheadWaitMs]="200"
                             (typeaheadOnSelect)="onVenueSelected($event)">
                    </div>
                    <div class="animate-switch" *ngSwitchCase="false">
                      <input appInputRestriction="restrictChars"
                             [disabled]="!defaultEventResultsHaveLoaded"
                             type="text"
                             name="txtCityName"
                             class="form-control"
                             placeholder="City Name"
                             [(ngModel)]="eventQuery.city"
                             maxlength="100" />
                    </div>
                </div>
               </div>
              </div>
            </div>
        <div class="col-lg-3 col-md-4 col-sm-6 w">
          <div class="mb-3">
            <div class="d-flex">
              <div class="dropdown input-group-text border-0 p-0" dropdown>
                <button type="button"
                        class="bg-transparent form-control"
                        id="dropdownTeam"
                        dropdownToggle>
                  <span [hidden]="!showTeam">Team</span>
                  <span [hidden]="showTeam">Event</span>
                  <i class="fa fa-sort" style="padding-left:5px;"></i>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="dropdownTeam">
                  <li role="presentation"[ngClass]="{'active':showTeam}">
                    <a role="menuitem" class="dropdown-item" tabindex="-1" (click)="hideEvent()">Team</a>
                  </li>
                  <li role="presentation" [ngClass]="{'active':!showTeam}">
                    <a role="menuitem"  class="dropdown-item" tabindex="-1" (click)="showEvent()">Event</a>
                  </li>
                </ul>
              </div>
              <div class="animate-switch-container w-100" [ngSwitch]="showTeam">
                <div class="animate-switch" *ngSwitchCase="true">
                  <ng-template #customItemTemplate
                               let-model="item"
                               let-index="index">
                    <span>
                      <img src="/assets/image/team/{{model.imageId}}"
                           width="16" />
                      <span style="padding-left:10px;">{{model.name}}</span>
                    </span>
                  </ng-template>

                  <input name="teamAutoComplete"
                         [(ngModel)]="teamNameSearchTerm"
                         [typeahead]="teamNameObservable$"
                         typeaheadOptionField="name"
                         [typeaheadAsync]="true"
                         [typeaheadMinLength]="2"
                         [typeaheadOptionsLimit]="10"
                         [typeaheadItemTemplate]="customItemTemplate"
                         placeholder="Team Name"
                         class="form-control"
                         [disabled]="!defaultEventResultsHaveLoaded"
                         [isAnimated]="true"
                         [typeaheadWaitMs]="200"
                         (typeaheadOnSelect)="onTeamSelected($event)">
                </div>
                <div class="animate-switch"
                     *ngSwitchCase="false">
                  <input type="text"
                         name="txtEventName"
                         appInputRestriction="restrictChars"
                         [disabled]="!defaultEventResultsHaveLoaded"
                         class="form-control"
                         placeholder="Event Name"
                         [(ngModel)]="eventQuery.eventName"
                         maxlength="100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <div class="mb-3">
            <button type="submit"
                    class="btn btn-secondary"
                    [disabled]="!defaultEventResultsHaveLoaded"
                    (click)="eventSearch()">
              Search
            </button>
            <button type="button"
                    name="Reset"
                    class="btn btn-default"
                    (click)="resetEventSearch()">
              Reset
            </button>
            <a (click)="showFilter = !showFilter"
               class="float-end lnk-show-hide">
              {{ showFilter ? 'Hide' : 'Show' }} Filters <i class="fa fa-sort-asc"
                 [ngClass]="{'fa-sort-asc': showFilter, 'fa-sort-desc': !showFilter}"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- Filters -->
      <div class="row"
           [hidden]="!showFilter">
        <div class="col-lg-2 col-md-4 col-sm-6"
             *ngIf="!season">

          <div class="mb-3">
            <nbc-dropdown *ngIf="eventTypes?.length>0" [source]="eventTypes | orderBy: 'name' "
                          name="ddlEventTypeId"
                          display="name"
                          value="id"
                          [model]="eventQuery.eventTypeId"
                          nbcSize="w-100"
                          (nbcOnOptionSelect)="onEventTypeSelected($event)"
                          placeholder="All Event Types">
            </nbc-dropdown>
          </div>

        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <div class="mb-3">
            <div class="input-group input-group-set"
                 *ngIf="networks && networks.length>0 "
                 dropdown>
              <!-- <span class="input-group-addon dropdown"><i class="fa fa-filter"></i></span> -->

              <nbc-dropdown *ngIf="networks?.length>0" [source]="networks"
                            [(model)]="eventQuery.networkId"
                            name='ddlNetworkId'
                            display="description"
                            value="id"
                            nbcSize="w-100"
                            (nbcOnOptionSelect)="eventSearch()"
                            placeholder="All Networks">
              </nbc-dropdown>

            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <div class="mb-3 d-flex">
            <label class="form-group-label"
                   style="padding-right:5px;">Level</label>
            <span class="d-flex">
              <button type="button"
                      name="isLevel1"
                      class="btn btn-white"
                      style="margin:0 !important;"
                      [(ngModel)]="eventQuery.isLevel1"
                      btnCheckbox
                      (click)="eventSearch()"
                      tabindex="0"
                      role="button"
                      ngDefaultControl>
                1
              </button>
              <button type="button"
                      name="isLevel2"
                      class="btn btn-white"
                      style="margin:0 !important;"
                      [(ngModel)]="eventQuery.isLevel2"
                      btnCheckbox
                      tabindex="0"
                      (click)="eventSearch()"
                      role="button"
                      ngDefaultControl>
                2
              </button>
              <button type="button"
                      name="isLevel3"
                      class="btn btn-white"
                      style="margin:0 !important;"
                      [(ngModel)]="eventQuery.isLevel3"
                      btnCheckbox
                      (click)="eventSearch()"
                      tabindex="0"
                      role="button"
                      ngDefaultControl>
                3
              </button>
            </span>
          </div>
        </div>
        <div class="col-lg-1 col-md-4 col-sm-6 p-0"
             *ngIf="!season">
          <div class="mb-3">
            <nbc-dropdown *ngIf="tiers?.length>0" [source]="tiers"
                          name="tier"
                          [(model)]="eventQuery.tier"
                          display="name"
                          value="id"
                          nbcSize="w-100"
                          (nbcOnOptionSelect)="eventSearch()"
                          placeholder="All Tiers">
            </nbc-dropdown>
          </div>
        </div>
        <div class="col-lg-2 col-md-4  col-sm-6">
          <div class="form-group">
            <div class="input-group input-group-set"
                 dropdown>
              <!-- <span class="input-group-addon dropdown"><i class="fa fa-filter"></i></span> -->
              <select (change)="filterByBroadcastType()"
                      [(ngModel)]="eventQuery.broadcastType"
                      name="ddlBroadcastType">
                <option value=""
                        selected>
                  All Broadcast Types
                </option>
                <option value="Live">Live</option>
                <option value="Taped">Taped</option>
                <option value="Outside Package">Outside Package</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <div class="mb-3">
            <div class="d-flex input-group-set">
              <label class="input-group-addon">Status</label>
              <div class="dropdown w-100"
                   dropdown [insideClick]="true"
                   *ngIf="eventStatusList?.length>0">
                <button class="text-start form-control"
                        dropdownToggle type="button"
                        id="dropdownStatus" aria-controls="dropdown-Status">
                  {{ eventTypeFilter.Status }}
                </button>
                <ul id="dropdown-Status" *dropdownMenu
                    class="dropdown-menu" role="menu"
                    aria-labelledby="dropdownStatus">
                  <li role="menuitem" *ngFor="let status of eventStatusList;let $index =index;">
                    <label class="checkbox">
                      <input type="checkbox" name="status"
                             [(checked)]="status.checked"
                             (change)="onSelect(eventTypeFilter,$index)" />
                      {{status.description}}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex ps-4">
          <div class="col-lg-2 col-md-4 col-sm-6 ps-2">
            <div class="mb-3">
              <div class="input-group input-group-set"
                   dropdown>
                <!-- <span class="input-group-addon dropdown"><i class="fa fa-filter"></i></span> -->

                <nbc-dropdown *ngIf="timeSheetStatuses?.length>0" [source]="timeSheetStatuses"
                              name="timesheetStatus"
                              [(model)]="eventQuery.timesheetStatus"
                              display="name"
                              value="id"
                              nbcSize="w-100"
                              (nbcOnOptionSelect)="eventSearch()"
                              placeholder="All Timesheets">
                </nbc-dropdown>


              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-7 ps-2">
              <div class="mb-3">
                <div *ngIf="managers?.length>0" class="d-flex input-group-set">
                  <span class="input-group-text dropdown">
                    <span style="margin-right: 5px;">Manager</span>
                    </span>
                   <div class="w-100 me-3">
                     <nbc-dropdown [source]="managers| orderBy: 'description' "
                                   name="ddlManager"
                                   [(model)]="eventQuery.managerId"
                                   display="description"
                                   value="id"
                                   nbcSize="w-100"
                                   (nbcOnOptionSelect)="eventSearch()"
                                   placeholder="Any">
                     </nbc-dropdown>
                   </div>
                 </div>
               </div>
          </div>
          <div class="col-lg-1 col-md-4 col-sm-6 ps-2">
            <div class="mb-3">
              <div class="checkbox float-end ">
                <label>
                  <input type="checkbox"
                         name="MyEvent"
                         [(ngModel)]="eventQuery.isMyEvent"
                         (change)="eventSearch()"> My events only
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-pane-content">
        <div *ngIf="model.totalRecords > 0" class="row view-options">
          <div class="col-md-5">
            <div class="form-inline form-sort-options d-flex">
              <div class="form-group">
                <label class="form-group-label">Sort:</label>
                <nbc-dropdown [source]="sortList"
                              name="ddlSortBy"
                              [(model)]="eventQuery.sortBy"
                              display="name"
                              value="predicate"
                              nbcSize="w-100"
                              (nbcOnOptionSelect)="eventSearch()"></nbc-dropdown>
              </div>
              <div class="mb-3">
                <label class="form-group-label">View:</label>
                <div class="btn-group">
                  <button type="button"
                          class="btn btn-white"
                          name="ViewShowGrid"
                          ngDefaultControl
                          [(ngModel)]="viewFormat"
                          btnRadio="'grid'"
                          (click)="showGrid = true">
                    <i class="fa fa-th-large"></i>
                  </button>
                  <button type="button"
                          class="btn btn-white"
                          [(ngModel)]="viewFormat"
                          name="ViewShowGridFalse"
                          ngDefaultControl
                          btnRadio="'list'"
                          (click)="showGrid = false">
                    <i class="fa fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="mb-3 pagination-container d-flex">
              <label class="pagination-results pagination-result-pos ms-auto">
                <pagination-label pageSize="{{model.pageSize}}"
                                  currentPage="{{model.currentPage}}}"
                                  totalRecords="{{model.totalRecords}}">
                </pagination-label>
              </label>
              <nbc-pagination totalItems="{{model.totalRecords}}"
                              itemsPerPage="{{model.pageSize}}"
                              [(currentPage)]="eventQuery.currentPage"
                              (pageChanged)="eventSearch()">
              </nbc-pagination>
            </div>

          </div>
        </div>
      <div class="row">

        <div class="col-md-12"
             *ngIf="model?.totalRecords===0">
          <p>There are no results matching the search criteria.</p>
        </div>
        <div class="col-md-12"
             [hidden]="hideResults">
          <!-- GridView -->
                    <div class="view-grid"
                         *ngIf="showGrid && model.items"
                         style="min-height:250px;">
                      <div class="row">
                        <div class="event-tile col-lg-3 col-md-6 col-sm-6 col-xs-12"
                             *ngFor="let event of model.items">
                          <div class="event-tile-main">
                            <div class="event-tile-dtls event-tile-status-planning">
                              <span class="event-tile-date"
                                    *ngIf="!(event.startDate === '') ">
                                {{event.startDate| date:"EEE. MMM. d"}} - {{event.endDate|date:"EEE. MMM. d, yyyy"}}
                                <span class="offset-md-3">
                                  <label class="tier-position"
                                         *ngIf="event?.tier > 0">T{{event.tier}}</label>
                                </span>
                              </span>
                              <span class="event-tile-date"
                                    *ngIf="event.startDate === '' ">
                                {{event.endDate|date:"EEE. MMM. d, yyyy"}}
                                <span class="offset-md-3">
                                  <label class="tier-position"
                                         [hidden]="event.tier < 0">T{{event.tier}}</label>
                                </span>
                              </span>

                              <span class="event-tile-title">
                                <a class="btn-link" routerLink="/Events/Event/{{event.id}}"
                                   routerLinkActive="activebutton">
                                  <truncate-text [truncateString]="event.name"
                                                 [truncateLimit]="56"></truncate-text>
                                </a>
                              </span>
                              <span class="event-tile-location"
                                    *ngIf="event.venue.name || 'No Venue'">
                                <truncate-text [truncateString]="event.venue.name"
                                               [truncateLimit]="25"></truncate-text>
                              </span>
                              <a (click)="saveFavorite(event)"
                                 class="favoriteEvent hdr-action-icon float-end"
                                 [(ngModel)]="event.isFavorite"
                                 ngDefaultControl
                                 name="IsFavouriteGrid{{event.venue.name}}"
                                 authenticate-user="favoriteevent.update,favoriteevent.retrieve">
                                <i class="fa"
                                   [ngClass]="{'fa-star': event.isFavorite, 'fa-star-o': !event.isFavorite}"></i>
                              </a>
                            </div>
                            <div class="event-tile-sub-dtls">
                              <a routerLink="/Events/Season/{{event.seasonId}}"
                                 title="{{event.seasonName}}"
                                 class="event-tile-season"
                                 *ngIf="event.seasonId > 0">
                                <span class="badge text-bg-secondary">S</span>
                              </a>
                              <span class="event-tile-network">
                                <i class="event-tile-network-icon"></i>
                                <span *ngFor="let network of event.networks| unique ; let last = last">
                                  {{network}}<span *ngIf="!last">, </span>
                                </span>
                              </span>

                            </div>
                            <div class="event-tile-positions">
                              <nbc-filled-positions-bar [totalPositions]="event.totalPositions"
                                                        [pendingPositions]="event.pendingPositions"
                                                        [confirmedPositions]="event.confirmedPositions"
                                                        [openPositions]="event.totalPositions-(event.pendingPositions + event.confirmedPositions)">

                              </nbc-filled-positions-bar>
                              <label>{{event.totalPositions}} Positions</label>
                            </div>
                          </div>
                          <div class="event-tile-aside">
                            <nbc-event-countdown-label nbcLabelPrependText="In "
                                                       [nbcStartDate]="event.startDate"
                                                       [nbcEndDate]="event.endDate"></nbc-event-countdown-label>
                            <img src="/assets/image/team/{{event?.awayTeam?.imageId}}"
                                 width="40"
                                 height="40"
                                 class="event-tile-game-logo"
                                 [hidden]="!event?.awayTeam?.imageId" />
                            <img src="/assets/image/team/{{event?.homeTeam?.imageId}}"
                                 width="40"
                                 height="40"
                                 class="event-tile-game-logo"
                                 [hidden]="!event?.homeTeam?.imageId" />
                            <img src="/assets/image/eventType/{{event?.eventType?.imageId}}"
                                 width="40"
                                 height="40"
                                 class="event-tile-game-logo"
                                 [hidden]="event?.homeTeam?.imageId || event?.awayTeam?.imageId || !event?.eventType?.imageId" />

                            <img class="event-tile-submissions"
                                 *ngIf="(event.eventTcSubmitStatus == 'Fully Submitted' && event.totalPositions > 0)"
                                 src="/Content/app/images/green_check.png"
                                 width="28"
                                 height="28"
                                 title="{{event.eventTcSubmitStatus}}" />
                            <img class="event-tile-submissions"
                                 *ngIf="event.eventTcSubmitStatus === 'Not Submitted'"
                                 src="/Content/app/images/red_check.png"
                                 width="28"
                                 height="28"
                                 title="{{event.eventTcSubmitStatus}}" />
                            <img class="event-tile-submissions"
                                 *ngIf="event.eventTcSubmitStatus === 'Partially Submitted'"
                                 src="/Content/app/images/orange_check.png"
                                 width="28"
                                 height="28"
                                 title="{{event.eventTcSubmitStatus}}" />
                            <div *ngIf="event.eventLocation" class="event-location-banner">
                              <span>
                                {{event.eventLocation}}
                              </span>
                            </div>
                            <!--hidden because of tier rate card new data is not available on this document-->
                            <a [hidden]="true"
                               authenticate-user="event.create"
                               class="event-tile-copy"
                               (click)="openCloneEventModal(event)">
                              <i [hidden]="true"
                                 class="fa fa-copy"></i>
                            </a>
                            <!-- <a authenticate-user="event.create" class="event-tile-copy" (click)="openCloneEventModal(event)"><i class="fa fa-copy"></i></a>-->
                          </div>
                        </div>
                      </div>
                    </div>



                    <modal-event-listview [showGrid]="showGrid"
                                          [model]="model"> </modal-event-listview>
                    <!-- ListView -->



                </div>
      </div>
    </div>
  </div>
</form>
