import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-contact-information-report",
    templateUrl: "./contact-information-report.component.html",
})
export class ContactInformationReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}

    getCriteriaData;
    reportName;
    events: any[] = [];
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    addEventTerm: any;
    suggestions$?: Observable<any[]> | undefined;
    contactInformationReportInfo;
    contactInformationReport;
    ShowEventsTypeRowAll = true;
    isExport = true;
    ShowEventsTypeEditRow = false;
    eventId = 13;
    ShowEventsTypeTagRow = false;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    eventsTypeList: any[] = [];
    selectedEvent: any[] = [];
    loadObserver: any;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    showAlertMessage: any;
    alerts: any;
    openDateRangeDatepicker(event) {
        this.log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
    }
    onToggleFilterDatepicker($event) {
        this.contactInformationReportInfo.dateRangeStartDate = $event[0];
        this.contactInformationReportInfo.dateRangeEndDate = $event[1];
    }

    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    editRow(value) {
        switch (value) {
            case "EventsRow":
                {
                    this.ShowEventsTypeRowAll = false;
                    this.ShowEventsTypeEditRow = true;
                    this.ShowEventsTypeTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }

    clear() {
        this.events = [];
        this.addEventTerm = "";
    }
    confirmSelection(value) {
        if (value === "EventsRow") {
            this.ShowEventsTypeEditRow = false;
            this.showSection(value);
        }
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
            if (
                !this.contactInformationReportInfo.dateRangeStartDate &&
                !this.contactInformationReportInfo.dateRangeEndDate
            ) {
                this.isExport = true;
            } else {
                this.isExport = false;
            }
        }
    }
    showSection(value) {
        switch (value) {
            case "EventsRow":
                {
                    if (
                        !_.isUndefined(this.selectedEvent) &&
                        this.selectedEvent.length > 0
                    ) {
                        this.ShowEventsTypeRowAll = false;
                        this.ShowEventsTypeEditRow = false;
                        this.ShowEventsTypeTagRow = true;
                    } else {
                        this.ShowEventsTypeRowAll = true;
                        this.ShowEventsTypeTagRow = false;
                        this.ShowEventsTypeEditRow = false;
                    }
                    break;
                }
                break;
            case "DateRangeRow":
                {
                    if (
                        this.contactInformationReportInfo.dateRangeStartDate !==
                            null &&
                        this.contactInformationReportInfo.dateRangeEndDate !==
                            null &&
                        this.contactInformationReportInfo.dateRangeStartDate !==
                            undefined &&
                        this.contactInformationReportInfo.dateRangeEndDate !==
                            undefined
                    ) {
                        this.showDateRangeRowAll = false;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = true;
                        // document.getElementById("conBtnReportExport").disabled = false;
                    } else {
                        this.showDateRangeRowAll = true;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = false;
                    }
                    break;
                }
                break;
        }
    }
    stateChanged(checked, value, row) {
        if (row === "EventsRow") {
            if (checked) {
                this.eventsTypeList.push(value);
                this.contactInformationReportInfo.events.push(value);
            }
            if (!checked) {
                this.eventsTypeList.splice(
                    this.eventsTypeList.indexOf(value),
                    1
                );
                this.contactInformationReportInfo.events.splice(
                    this.contactInformationReportInfo.events.indexOf(value),
                    1
                );
            }
        }
    }
    setSearchEventTerm($event) {
        this.addEventTerm = $event;
    }
    loadEvents() {
        this.showAlertMessage = false;
        this.log.trace("loadSeasons");
        this.suggestions$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.addEventTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._reportService.autoCompleteByOnlyEvent(
                    query,
                    this.userName
                );
            })
        );
    }
    removeTagEvent(name, event) {
        this.events.splice(this.events.indexOf(event), 1);
    }
    removeTag(item, row, index) {
        if (row === "EventsRow") {
            this.eventsTypeList.splice(this.eventsTypeList.indexOf(item), 1);
            this.selectedEvent.splice(this.selectedEvent.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("EventsRow");
        }
    }

    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.contactInformationReportInfo.dateRangeStartDate = null;
            this.contactInformationReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (
                this.contactInformationReportInfo.dateRangeStartDate === null &&
                this.contactInformationReportInfo.dateRangeEndDate === null
            ) {
                this.isExport = true;
            }
        }
    }

    getContactInformationReportDetails() {
        this._reportService
            .getContactInformationDetails(this.eventId)
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.contactInformationReport = data;
                        this.getCriteriaData = data;
                    }
                },
                error: (data) => {
                    this.log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    exportContactInformationReport() {
        // usSpinnerService.spin("genericSpinner");

        const tempContactInformationReportInfo: any = {};

        tempContactInformationReportInfo.eventType = this.selectedEvent;
        tempContactInformationReportInfo.events = this.events;
        tempContactInformationReportInfo.dateRangeStartDate = moment(
            this.contactInformationReportInfo.dateRangeStartDate
        ).format("MM/DD/yyyy");
        tempContactInformationReportInfo.dateRangeEndDate = moment(
            this.contactInformationReportInfo.dateRangeEndDate
        ).format("MM/DD/yyyy");

        this.isDownload = true;
        this._reportService
            .exportContactInformationReport(
                this.eventId,
                tempContactInformationReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.contactInformationReport = this.getCriteriaData;
                        const fileName = "SCORE-ContactInformation_";
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.showAlertMessage = false;

                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    this.log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });
    }

    onItemSelect(event) {
        this.selectedEvent = event;
    }
    onEventSelected(event) {
        //  if (this.events.indexOf(event) == -1) {
        //   this.events.push(event);
        //   // this.selectedEvent = this.events;

        // }
        this.events = event;
        this.addEventTerm = "";
    }
    ngOnInit(): void {
        this.loadEvents();
        this.getContactInformationReportDetails();
        this.contactInformationReportInfo = {};

        this.contactInformationReportInfo.events = null;
        //event type
        this.contactInformationReportInfo.eventsType = [];
        this.contactInformationReportInfo.dateRangeStartDate = null;
        this.contactInformationReportInfo.dateRangeEndDate = null;
    }
}
