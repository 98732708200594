import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { SubSink } from "subsink";
import { OnboardingService } from "src/app/Shared/Services/onboarding.service";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { NgForm } from "@angular/forms";
import { debug } from "console";

@Component({
    selector: "sendremidner-onb",
    templateUrl: "./sendreminder.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/hr/hr.css",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class SendReminderComponent implements OnInit, OnDestroy {
    hrOnboardingItems: any;
    text: any;
    comments: any = "";
    result: any;
    showAlertMessage = false;

    public onSync!: Subject<any>;

    sendreminder = {
        onboardingReminderList: [],
        comments: "",
    };

    constructor(
        public bsModalRef: BsModalRef,
        private _onboardingService: OnboardingService,
        private log: NbcLoggerService
    ) {
        // this.getPositionRequestInfo(this.crewRegNo, this.contactId, this.eventId, this.hrOnboardingItem);
    }

    /*  public onSync!: Subject<any>;*/
    /*  showAlertMessage: boolean = false;*/
    modalTitle = "";
    isPositionStartDateInvalid = false;
    //isAdd: boolean = false;
    private subs = new SubSink();
    //submitted: boolean = false;

    //Closing Modal
    closeModal() {
        /*    console.log(this.bsModalRef);*/
        this.bsModalRef.hide();
    }

    sendReminder() {
        this.log.trace("save updated Position Request");
        this.sendreminder.comments = this.comments;
        this.sendreminder.onboardingReminderList = this.hrOnboardingItems;
        this.subs.sink = this._onboardingService
            .sendReminder(this.sendreminder)
            .subscribe(
                (result) => {
                    // this.submitted = true;
                    //  this.onSync.next(result);
                    this.log.showSuccess(
                        "The Reminder has been successfully sent"
                    );
                    this.onSync.next(
                        "The Reminder has been successfully sent."
                    );
                    this.bsModalRef.hide();
                    //this.bsModalRef.hide();
                },
                (err) => {
                    console.log(err.error);
                    this.log.trace(
                        "The Reminder has been not sent " + err.error
                    );
                    this.log.showModalError("The Reminder has been not sent ");
                    this.showAlertMessage = true;
                    //          this.log.showModalError('Cannot have duplicate Network Names!');
                    ///*          this.showAlertMessage = true;*/
                    //this.submitted = false;
                }
            );
    }

    cancel() {
        this.log.trace("cancel edit position request modal");
        this.bsModalRef.hide();
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.hrOnboardingItems = this.hrOnboardingItems;
        if (this.hrOnboardingItems.length > 1) {
            this.text = this.hrOnboardingItems.length + " Contacts";
        } else {
            this.text = this.hrOnboardingItems[0].contactName;
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
