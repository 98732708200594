import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { AccordionComponent } from "./accordion/accordion.component";
import { ReportsComponent } from "./reports.component";

const routes: Routes = [
    {
        path: "",
        component: ReportsComponent,
        children: [
            {
                path: "",
                component: AccordionComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReportsRoutingModule {
    static forChild() {
        return {
            ngModule: ReportsRoutingModule,
            providers: [],
        };
    }
}
