import {
    Component,
    OnInit,
    ElementRef,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateSeasonComponent } from "../season/components/create-season/create-season.component";
import { Router } from "@angular/router";
import { SharedDataService } from "../../Shared/sharedData.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-CreateSeasonOrEvent",
    templateUrl: "./create-season-event.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/events/events.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class CreateSeasonOrEvent implements OnInit {
    constructor(
        private _EventTypeService: EventTypeService,
        private _modalService: BsModalService,
        private log: NbcLoggerService,
        private _sharedService: SharedDataService,
        public router: Router
    ) {}

    @ViewChildren("seasonlogo") seasonlogo!: QueryList<
        ElementRef<HTMLLIElement>
    >;

    eventTypes = [];
    mouseOver = false;
    bsModalRef?: BsModalRef;
    private subs = new SubSink();

    displaySetting = "none !important";
    getEventTypes() {
        this.subs.sink = this._EventTypeService.getEventTypes({}).subscribe(
            (result) => {
                // this.log.trace("got results: " + JSON.stringify(result));
                this.eventTypes = result.reference;
            },
            (err) => {
                this.log.showError("got an error: ");
            },
            () => {}
        );
    }

    CreateSeason(id: any) {
        const initialState: any = {
            hideConfirm: false,
            eventTypeId: id,
            season: {},
        };

      const modalOptions = {
            id:1,
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };
        this.bsModalRef = this._modalService.show(
            CreateSeasonComponent,
            modalOptions
        );
        this.bsModalRef.content.onSeasonSync.subscribe((seasonId: any) => {
            this.router.navigateByUrl("Events/Season/" + seasonId);
        });
    }

    onMouseEnter(id: any) {
        this.seasonlogo.forEach((element, index) => {
            if (element.nativeElement.id == id) {
                element.nativeElement.style.display = "block";
            }
        });
    }
    onMouseLeave(id: any) {
        this.seasonlogo.forEach((element, index) => {
            if (element.nativeElement.id == id) {
                element.nativeElement.style.removeProperty("display");
            }
        });
    }

    ngOnInit(): void {
        this._sharedService.setCss("event");
        this.getEventTypes();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
