import { Component, Input, OnInit } from "@angular/core";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-filled-positions-bar",
    templateUrl: "./NbcFilledPositionsBar.component.html",
})
export class NbcFilledPositionsBarComponent implements OnInit {
    originalString: string | undefined;
    constructor(private log: NbcLoggerService) {
        this.confirmedPercentage = 0;
        this.pendingPercentage = 0;
        this.openPositions = 0;
    }

    confirmedPercentage: number | undefined;
    pendingPercentage: number | undefined;
    OpenPositions: number | 0 | undefined;

    @Input() totalPositions!: number | 0;
    @Input() pendingPositions!: number | 0;
    @Input() confirmedPositions!: number | 0;
    @Input() openPositions!: number | 0;

    filledPositionsBar() {
        this.log.debug(
            "calculating nbcFilledPositionsBar :" + this.openPositions
        );

        this.confirmedPercentage =
            (this.confirmedPositions / this.totalPositions) * 100;
        this.pendingPercentage =
            (this.pendingPositions / this.totalPositions) * 100;
    }
    ngOnInit() {
        this.log.debug("starting the progressbar");
        this.filledPositionsBar();
    }
}
