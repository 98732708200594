import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { catchError } from "rxjs/operators";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
    providedIn: "root",
})
export class BudgetService {
    constructor(
        private log: NGXLogger,
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService
    ) {}

    /*
     * Generic method to return the collection of the tier network setup
     */

    //@LiquidCache("getCompanyCodes")
    getCompanyCodes(): Observable<any> {
        this.log.trace("Calling get company codes");
        return this.http
            .get(this._apiroot.baseUrl + "budget/companyCodes")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    search(searchObj: any): Observable<any> {
        return this.http
            .post<any>(this._apiroot.baseUrl + "budget/search/", searchObj)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
