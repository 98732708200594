import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PermissionUtility } from './permission-utility.service';
import * as _ from 'lodash';

 

@Injectable({ providedIn: "root" })
export class AuthGuardService implements CanActivate {
  private  permissionUtility: any;
  user: any | undefined;
  constructor(
    private router: Router
  ) {
    this.permissionUtility = PermissionUtility.PermissionUtilities();
    this.user = window.preload.user;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: any = {};
    if (currentUser) {
      let routeTokens: any[] = _.has(route.data, "tokens") ? route.data.tokens:[];
      let isAuthorized = this.permissionUtility.hasAccess(this.user, this.user.allFeatures, routeTokens,null,null,null);
      // check if route is restricted by role
      if (!isAuthorized) {
        // role not authorised so redirect to home page
        this.router.navigate(["/unauthorized"]);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
