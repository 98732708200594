import { Component, OnDestroy, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { TimeZoneHelperService } from "src/app/Shared/helper/timezonehelper.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { Subject } from "rxjs";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { BaseUrlHelperService } from "../../Shared/helper/baseUrlHelper.service";
import { EditEventComponent } from "../manageEvent/editevent/edit-event.component";
import { CloneEventModalComponent } from "../clone-event/clone-event-modal.component";
import { SubSink } from "subsink";
import { CloneEventInfoModalComponent } from "../clone-event/clone-event-info-modal.component";
import * as moment from "moment";

@Component({
    selector: "event-header",
    templateUrl: "./eventheader.component.html",
})
export class EventHeaderComponent implements OnInit, OnDestroy {
    constructor(
        private _timeZoneService: TimeZoneHelperService,
        private _globalService: GlobalService,
        private _eventService: EventService,
        private _seasonService: SeasonService,
        private datePipe: DatePipe,
        private router: Router,
        private _sharedData: SharedDataService,
        private _activatedRoute: ActivatedRoute,
        private eventBus: EventBusService,
        private _apiroot: BaseUrlHelperService,
        private _modalService: BsModalService,
        private log: NbcLoggerService
    ) {}
    private subs = new SubSink();
    eventId!: number | 0; // = preload.eventId;
    season: any;
    networks = {};
    isCollapsed = false;
    // = preload.eventId;
    event: any = {};
    bsModalRef?: BsModalRef;
    eventSummary: any | undefined;
    pageName: string | undefined;
    hasTeamNames = false;
    eventTimeZone = "";
    filteredBroadcastDates: any = [];
    arrEventBroadcastToolTipText: any = [];
    eventBroadcastToolTipText = "";
    seasonEventsList: any = [];
    isCloneCallInProgress = false;
    _clone_timeToWaitForFallbackSearch = 2.5 * 60 * 1000; //2.5 mins
    isReadytoRedirect = false;
    // Methods
    eventBroadcastToolTip() {
        this.arrEventBroadcastToolTipText = [];
        this.log.trace("EventHeader -> Populate event broadcast tooltip ");
        const timezone = this._timeZoneService.searchTimeZone(
            this.event.venue.timeZoneId
        ).abbr;

        this.event.broadcastAirDates.forEach((item: any) => {
            const airDate =
                this.transformDate(item.airDate, "EEE, MMM d, yyyy, ") +
                this.datePipe.transform(item.startTime, "h:mma - ") +
                this.datePipe.transform(item.endTime, "h:mma ") +
                timezone;

            this.arrEventBroadcastToolTipText.push(airDate);
        });

        this.eventBroadcastToolTipText =
            this.arrEventBroadcastToolTipText.join("<br/>");
        return true;
    }
    timeSheet() {
        this.router.navigate(["/TimeSheet"]);
    }
    transformDate(date: Date, format: string): any {
        return this.datePipe.transform(date, format);
    }

    getFilteredBroadcastDates() {
        this.filteredBroadcastDates = [];
        for (let i = 0; i < this.event.broadcastDates.length; i++) {
            if (this.event.broadcastDates[i].isAirDate === true) {
                this.filteredBroadcastDates.push(this.event.broadcastDates[i]);
            }
        }

        //sorting the broadcast dates to display the dates first and last day of the event in event summary SCOR-12210
        if (
            this.filteredBroadcastDates &&
            this.filteredBroadcastDates.length > 0
        ) {
          this.filteredBroadcastDates.sort(function compare(a: any, b: any) {
                const x = new Date(a.airDate);
                const y = new Date(b.airDate);
                return x > y ? 1 : -1;
            });
        }

        this._sharedData.setEventAirBroadcastDates(this.filteredBroadcastDates);
        this.eventBus.emit(
            new EmitEvent(
                "EventHeader:GetFilteredBroadcastDates",
                "setAirBroadcastingDates",
                this.filteredBroadcastDates
            )
        );
    }
    setSubscribedata() {
        this._sharedData.setEventSubscribeData(this.event);
    }
    getEvent() {
        this.log.trace("EventHeader -> getEvent called");

        const ref = this;
        this.subs.sink = this._eventService.getEvent(this.eventId).subscribe(
            (data) => {
                this.log.trace("EventHeader -> getEvent: data received");
                this.event = data.reference;
                if (this.event) {
                    this.log.trace(
                        "EventHeader -> EventHeader - Event Details received: " +
                            this.event.id
                    );
                    this._sharedData.setEventInfo(this.event);
                    this.hasTeamNames =
                        !!this.event.homeTeam && !!this.event.awayTeam;
                }
                if (this.event && this.event.venue) {
                    this.eventTimeZone = this._timeZoneService.searchTimeZone(
                        this.event.venue.timeZoneId
                    ).abbr;
                }

                this.getSeasonData();
                this.getSeasonEventsList();
            },
            (err) => this.log.error(err),
            () => {
                this.log.trace("EventHeader -> removing spinner");
                //usSpinnerService.stop("searchSpinner");
                this.getFilteredBroadcastDates();
            }
        );
    }
    getSeasonData() {
        this.log.trace("EventHeader -> getSeasonData:fetching data");
        if (
            this.event &&
            _.has(this.event, "seasonId") &&
            this.event.seasonId !== 0
        ) {
            this.subs.sink = this._seasonService
                .getSeasonData(this.event.seasonId)
                .subscribe(
                    (data) => {
                        this.log.trace(
                            "EventHeader -> successfully retrieved the season summary"
                        );
                        this.season = data.reference;
                        this._sharedData.setSeasonData(this.season);
                    },
                    (err) => {
                        this.log.error(err);
                        this.log.showError(
                            "Unable to get Season Information at this time. Please try again later."
                        );
                    }
                );
        }
    }
    getSeasonEventsList() {
        this.log.trace("EventHeader -> getSeasonEventsList called");
        if (
            this.event &&
            !_.isUndefined(this.event) &&
            this.event.seasonId > 0
        ) {
            this.subs.sink = this._eventService
                .search({
                    seasonId: this.event.seasonId,
                    currentPage: 1,
                    pageSize: 10000,
                })
                .subscribe({
                    next: (result) => {
                        this.log.trace(
                            "EventHeader -> successfully retrieved the list of events for the season"
                        );
                        this.seasonEventsList = result.items || [];
                    },
                    error: (err) => {
                        this.log.error("got an error: " + JSON.stringify(err));
                        this.log.showError(
                            "Unable to get Season Events at this time. Please try again later."
                        );
                    },
                });
        }
    }
    openEditEventModal() {
        this.log.trace("EventHeader -> Opening modal");

        const initialState: any = {
            self: this,
            eventData: this.event,
            networks: this.networks,
            seasons: this.season == undefined ? [] : [this.season],
            eventSummary: this.eventSummary,
            isSeasonSetup: false,
        };
      const modalOptions = {
            id:1,
            animated: true,
            backdrop: true,
            keyboard: false,
            initialState: initialState,
            ignoreBackdropClick: true,
            class: "modal-lg",
        };

        this.bsModalRef = this._modalService.show(
            EditEventComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result: any) => {
            this.event = result.event;
            this.season = result.selectedSeason;
            // Commenting below lines to avoid firefox error. Also it is irrelevent to load this data before page reload.
            //this.getSeasonEventsList();
            //this.getFilteredBroadcastDates();
            window.location.reload();
        });
    }

    getEventNetworks() {
        this.subs.sink = this._globalService.getEventNetworks().subscribe(
            (result) => {
                // this.log.trace("got results: " + JSON.stringify(result));
                this.networks = result.reference;
            },
            (err) => {
                this.log.showError(" got an error: ");
            },
            () => {}
        );
    }

    openCloneEventModal(event: any) {
        this.log.trace("EventHeader -> Opening modal modalClone.html");
        const broadcastingNetworks = _.without(
            _.map(this.event.broadcastDates, "network.description"),
            undefined
        );
        const initialState: any = {
            self: this,
            events: {
                eventId: this.event.id,
                eventName: this.event.eventName,
                sourceTier: this.event.tier,
                broadcastingNetworks: broadcastingNetworks,
                eventTypeId: this.event.eventTypeId,
                eventLocation: this.event.eventLocation,
            },
            tierConfig: this.event.eventTierSetup,
        };
      const modalOptions = {
            id:1,
            animated: true,
            backdrop: true,
            keyboard: false,
            initialState: initialState,
            ignoreBackdropClick: true,
        };
        this.bsModalRef = this._modalService.show(
            CloneEventModalComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((clone: any) => {
            if (clone) {
                this.isCloneCallInProgress = true;
                setTimeout(() => {
                    this.searchEventExplicitely(clone);
                }, this._clone_timeToWaitForFallbackSearch);
                // this.log.trace("clone event modal dismissed with: " + clone.eventName);

                clone.broadcastDate.airDate = moment(clone.broadcastDate.airDate).startOf("day").format("YYYY-MM-DDT00:00:00");
                this.subs.sink = this._eventService
                    .cloneEvent(clone)
                    .subscribe({
                        next: (response: any) => {
                            this.isCloneCallInProgress = false;
                            window.location.href =
                                this._apiroot.basePath +
                                "Events/Event/" +
                                response.reference.id;
                        },
                        error: (err) => {
                            this.log.error(
                                "clone event modal got an error cloning the event: " +
                                    JSON.stringify(err)
                            );
                            this.log.showError(
                                "Unable to clone the event at this time. Please try again later."
                            );
                        },
                    });
            } else {
                this.log.trace(
                    "EventHeader -> clone event modal dismissed without value"
                );
            }
        });
    }

    // This method is specific to a scanario where clone takes more than 3.8 mins which is app service timeout
    searchEventExplicitely(settings: any) {
        if (this.isCloneCallInProgress) {
            const eventQuery = {
                pageSize: 10,
                currentPage: 1,
                eventName: settings.eventName,
            };

            this._eventService.search(eventQuery).subscribe({
                next: (result) => {
                    if (result.items.length != 0) {
                        this.openCloneEventStatus(result);
                    }
                },
            });
        }
    }
    // This method is to open the modal to show the counter to a scanario where clone takes more than 3.8 mins which is app service timeout
  openCloneEventStatus(event: any) {
        this.isCloneCallInProgress = false;
        const arr = _.sortBy(event.items, ["id"]);
        const lastItem = _.last(arr);

        const initialStateForClone: any = {
            self: this,
            events: {
                eventId: lastItem.id,
                eventName: lastItem.name,
            },
        };

        const modalOptionsforClone = {
            animated: true,
            backdrop: true,
            keyboard: false,
            initialState: initialStateForClone,
            ignoreBackdropClick: false,
        };

        this.bsModalRef = this._modalService.show(
            CloneEventInfoModalComponent,
            modalOptionsforClone
        );

        this.bsModalRef.content.onSync.subscribe((res: any) => {
            this.isReadytoRedirect = res.isOkToRedirect;
            if (this.isReadytoRedirect)
                window.location.href =
                    this._apiroot.basePath + "Events/Event/" + lastItem.id;
        });
    }

    saveFavorite(event: any) {
        const favoriteStatus = event.isFavorite;
        const favoriteQuery = {
            eventId: event.id,
            eventName: event.eventName,
            key: event.isFavoriteKey || 0,
            isFavorite: !favoriteStatus,
        };

        if (!favoriteStatus) {
            this.MarkFavourite(favoriteQuery, favoriteStatus);
        } else {
            this.MarkUnFavourite(favoriteQuery, favoriteStatus);
        }
    }

    MarkFavourite(favoriteQuery: any, favoriteStatus: any) {
        this.subs.sink = this._eventService.favorite(favoriteQuery).subscribe({
            next: (result) => {
                // this.log.trace("saveFavorite got results: " + JSON.stringify(result));
                this.event.isFavorite = !favoriteStatus;
                this.event.isFavoriteKey = result.reference;
            },
            error: (err) => {
                this.log.showError(" got an error: ");
            },
        });
    }

    MarkUnFavourite(favoriteQuery: any, favoriteStatus: any) {
        this.subs.sink = this._eventService
            .unfavorite(favoriteQuery)
            .subscribe({
                next: (result) => {
                    // this.log.trace("saveFavorite got results: " + JSON.stringify(result));
                    this.event.isFavorite = !favoriteStatus;
                    this.event.isFavoriteKey = result.reference;
                },
                error: (err) => {
                    this.log.showError(" got an error: ");
                },
            });
    }

    getEventSummary() {
        this.log.trace("EventHeader -> getEventSummary called");
        const ref = this;
        this.subs.sink = this._eventService
            .getEventSummary(this.eventId)
            .subscribe(
                (data) => {
                    this.log.trace(
                        "EventHeader -> getEventSummary: data received"
                    );
                    this.eventSummary = data.reference;
                    this.log.trace(
                        "EventHeader -> sharing event summary from event header"
                    );
                    this._sharedData.setEventSummary(this.eventSummary);
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to load event at this time. Please try again later."
                    );
                },
                () => {
                    this.log.trace("EventHeader -> removing spinner");
                }
            );
    }

    // Event Listeners
    //$scope.$on("onUpdateEvent", function (e, event) {
    //  $scope.event = event;
    //});

    //$scope.$on("onUpdateSeasonEvents", function (e, seasonEvents) {
    //  $scope.getSeasonEventsList = seasonEvents;
    //});

    //$scope.$on("onUpdateSeason", function (e, season) {
    //  $scope.season = season;
    //});

    //$scope.$on("onUpdatePageName", function (e, pageName) {
    //  $scope.pageName = pageName;
    //});

    //init

    //ngAfterViewChecked() {
    //  this.log.trace("EventHeader -> ngAfterViewChecked");
    //  this.finishedLoading.emit(true);
    //}

    ngOnInit() {
        this._activatedRoute.params.subscribe(
            (params) => (this.eventId = params["id"])
        );

        this._sharedData.setEvenSection(true);
        this._sharedData.setCss("event");
        this._sharedData.setCurrentEventId(this.eventId);

        this._sharedData.getCurrentPage().subscribe((page) => {
            this.pageName = page;
        });
        this.getEvent();
        this.getEventSummary();
        this.getEventNetworks();
    }
    ngOnDestroy() {
        this._sharedData.setEvenSection(false);
        this._sharedData.setEventInfo({});
        this._sharedData.setEventSummary({});
        this.subs.unsubscribe();
    }
}
