<div>
  <div>
    <div class="page-container event-crew"
         authenticate-user="eventtimesheet.retrieve">
      <div class="container-fluid">
        <div class="row"
             id="crew-hdr-fixed">
          <div class="col-md-12">
            <nbc-alert></nbc-alert>
            <div class="header-bar clearfix">
              <p class="heading-title float-start">Timesheets</p>
              <div class="float-end"
                   *ngIf=" eventTimesheet?.timesheets?.items?.length > 0"
                   authenticate-user="reports.timesheet.export">
                <!--SCOR-10828-->
                <div class="btn-group dropdown"
                     dropdown>
                  <button type="button"
                          id="button-Export" dropdownToggle
                          aria-controls="dropdown-Export"
                          class="btn btn-secondary">
                    Export... <span class="fa fa-caret-down"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu"
                      id="button-Export"
                      role="menu">
                    <li role="menuitem" class="dropdown-item">
                      <a (click)="openExportModal(eventTimesheetQuery, contactIdList,'Excel')">
                        Export to Excel
                      </a>
                    </li>
                    <li role="menuitem" class="dropdown-item">
                      <a (click)="openExportModal(eventTimesheetQuery, contactIdList, 'pdf')">
                        Export to PDF
                      </a>
                    </li>
                    <li role="menuitem" class="dropdown-item">
                      <a (click)="openExportModal(eventTimesheetQuery, 'contactIdList', 'ExcelSignIn')">
                        Export Sign-In
                      </a>
                    </li>
                    <li role="menuitem" class="dropdown-item">
                      <a (click)="openExportModal(eventTimesheetQuery, 'contactIdList','ExcelPost')">
                        Export Post
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="float-end btn-fullscreen hidden-sm hidden-xs">
                  <span maximize #maximize="maximize"></span>
                </div>
              </div>
            </div>
            <app-nbc-tab-display *ngIf="headerTimesheetsDates?.length>0"
                                 [tabSource]="headerTimesheetsDates"
                                 [tabDefault]="activeTimesheetDate"
                                 [(isTabPanelEnabled)]="isTabPanelEnabled"
                                 (newItemEventData)="addItem($event)"></app-nbc-tab-display>
            <div class="content-filters d-inline-block w-100">
              <div class="row"
                   *ngIf="showActivation">
                <div class="col-md-12 text-center">
                  <div class="form-group">
                    <button class="btn btn-primary"
                            authenticate-user="eventtimesheet.create"
                            (click)="openActivateTimesheetModal()">
                      Activate Timesheet
                    </button>
                  </div>
                </div>
              </div>
              <div class="row"
                   *ngIf="!showActivation && isNoMsg">
                <div class="col-md-12"
                     *ngIf="showButtonPanel">
                  <div class="float-start">
                    <div class="form-group">
                      <div class="timesheet-status"
                           *ngIf="eventTimesheet?.timesheets?.items?.length>0"
                           [ngClass]="{'status-draft' : (batchStatus | lowercase) !== 'submitted', 'status-submitted' : (batchStatus | lowercase) === 'submitted'  }">
                        <i class="fa fa-circle"></i>
                        <span *ngIf="(batchStatus | lowercase) === 'not submitted'">
                          Not
                        </span>
                        <span *ngIf="(batchStatus | lowercase) === 'partially submitted'">
                          Partially
                        </span>Submitted
                      </div>
                    </div>
                  </div>
                  <div class="float-end"
                       authenticate-user="eventtimesheet.update">
                    <div class="form-group" *ngIf="(batchStatus | lowercase) !== 'submitted'">                     
                        <button type="button"
                                [disabled]="!groupUpdate.status"
                                class="btn btn-secondary"                              
                                (click)="saveTimesheet(eventTimesheet);">
                          <span data-toggle="tooltip"
                                tooltip-trigger="mouseenter"
                                tooltip-placement="top"
                                tooltip="{{groupUpdate.status ? null : 'Select Position(s) to Save'}}">Save</span>
                        </button>                      
                      <button type="button"
                              class="btn btn-default"
                              (click)="checkDirtyForm(cancelTimesheet)">
                        Cancel
                      </button>
                      <button type="submit"
                              class="btn btn-primary"
                              (click)="openSubmitEventTimesheetModal('BTL');"
                              [disabled]="!eventTimesheet.isTimesheetSubmittable || !eventTimesheet.isNotBTLTimesheetSubmittable">
                        Submit BTL
                      </button>
                      <button type="submit"
                              class="btn btn-primary"
                              (click)="openSubmitEventTimesheetModal('ATL');"
                              [disabled]="!eventTimesheet.isTimesheetSubmittable || !eventTimesheet.isNotATLTimesheetSubmittable">
                        Submit ATL
                      </button>
                      <button type="submit"
                              class="btn btn-primary"
                              (click)="openSubmitEventTimesheetModal('All');"
                              [disabled]="!eventTimesheet.isTimesheetSubmittable || !eventTimesheet.isNotAllTimesheetSubmittable">
                        Submit ALL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row"
                   *ngIf="!showActivation && isNoMsg">
                <div class="col-sm-12">
                  <div class="form-inline form-sort-options">
                    <div class="form-group">
                      <label class="form-group-label"><i class="fa fa-sort"></i> Sort By</label>
                      <nbc-dropdown [(model)]="eventTimesheetQuery.sortBy"
                                    [source]="filterOptions.sortBy"
                                    value="value"
                                    placeholder="Sort By"
                                    display="name"
                                    nbcSize="w-100"
                                    (nbcOnOptionSelect)="checkDirtyForm(restartSearch,$event,'sortBy');">
                      </nbc-dropdown>
                    </div>
                    <div class="form-group" *ngIf="eventTimesheet?.timesheets?.items">
                      <label class="form-group-label"><i class="fa fa-filter"></i> Filter</label>
                      <nbc-dropdown [(model)]="eventTimesheetQuery.departments"
                                    [source]="sourceDepartmentList"
                                    display="description"
                                    value="id"
                                    nbcSize="w-100"
                                    placeholder="All Departments"
                                    [nbcDisableEmptyList]="false"
                                    (nbcOnOptionSelect)="checkDirtyForm(restartSearch,$event,'departments');">
                      </nbc-dropdown>
                    </div>
                    <div class="form-group" *ngIf="eventTimesheet?.timesheets?.items">
                      <label class="form-group-label"></label>
                      <nbc-dropdown [(model)]="eventTimesheetQuery.positionType"
                                    [source]="sourcePositionTypeList"
                                    display="description"
                                    nbcSize="w-100"
                                    placeholder="All Position Types"
                                    [nbcDisableEmptyList]="false"
                                    (nbcOnOptionSelect)="checkDirtyForm(restartSearch,$event,'positionType');">
                      </nbc-dropdown>
                    </div>
                    <div class="form-group" *ngIf="eventTimesheet?.timesheets?.items">
                      <label class="form-group-label"></label>
                      <nbc-dropdown [(model)]="eventTimesheetQuery.positionCategory"
                                    [source]="positionCategories"
                                    display="description"
                                    value="description"
                                    nbcSize="w-100"
                                    placeholder="ATL/BTL"
                                    [nbcDisableEmptyList]="false"
                                    (nbcOnOptionSelect)="checkDirtyForm(restartSearch,$event,'positionCategory');">
                      </nbc-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-header"
                 *ngIf="groupUpdate.status"
                 authenticate-user="eventtimesheet.update">
              <div class="form-inline">
                <div class="form-group">
                  <label>Group Update: </label>
                </div>
                <div class="form-group"
                     [ngClass]="{'has-error' : groupUpdate.submitted && (groupUpdate.selected === '' || groupUpdate.selected.name === 'Select')}">
                  <nbc-select name="groupUpdate"
                              [nbcRequired]="true"
                              [(model)]="groupUpdate.selected"
                              [source]="groupUpdate.options"
                              display="name"
                              value="value"
                              placeHolderDisplay="Select"
                              placeHolderValue="-1">
                  </nbc-select>
                </div>
                <div class="form-group"
                     *ngIf="groupUpdate?.selected?.name === 'Time'">
                  <form name="eventTsGroupForm" #eventTsGroupForm="ngForm" novalidate>
                    <div style="padding:4px;">
                      <div class="row timesheet-list" style="width:400px">
                        <div class="col-md-2" style="min-width: 93px;">
                          <div nbc-time
                               name="timeIn"
                               style="height:35px;padding:0px;"
                               [isStartTime]="true"
                               class="form-control form-control-sm"
                               tabindex="0"
                               placeholder="Start"
                               [DefaultDate]="activeTimesheetDate"
                               [(model)]="groupUpdate.updatedStartTime">
                          </div>
                        </div>
                        <div class="col-md-2" style="min-width: 93px;">
                          <div nbc-time
                               style="overflow: hidden; height: 35px; padding: 0px;"
                               class="form-control form-control-sm"
                               placeholder="End"
                               name="updatedEndTime"
                               [isStartTime]="false"
                               [DefaultDate]="activeTimesheetDate"
                               [(model)]="groupUpdate.updatedEndTime"></div>
                        </div>
                        <div class="col-md-2" style="min-width: 93px;">
                          <div style="overflow: hidden; height: 35px; padding: 0px;"
                               class="form-control form-control-sm"
                               nbc-time
                               placeholder="Meal Start"
                               name="updatedMealStartTime"
                               [isStartTime]="true"
                               [DefaultDate]="activeTimesheetDate"
                               [(model)]="groupUpdate.updatedMealStartTime">
                          </div>
                        </div>
                        <div class="col-md-2" style="min-width: 93px;">
                          <div style="overflow: hidden; height: 35px; padding: 0px;"
                               nbc-time
                               class="form-control form-control-sm"
                               name="updatedMealEndTime"
                               placeholder="Meal End"
                               [isStartTime]="false"
                               [DefaultDate]="activeTimesheetDate"
                               [(model)]="groupUpdate.updatedMealEndTime">

                          </div>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="form-group"
                     style="padding-right:10px;"
                     [ngClass]="{'has-error' : groupUpdate.submitted && groupUpdate.updatedPayrollComment === ''}">
                  <textarea [(ngModel)]="groupUpdate.updatedPayrollComment"
                            maxlength="255"
                            *ngIf="groupUpdate.selected && groupUpdate.selected.name !== 'Select' && groupUpdate.selected.name === 'Field Notes'"
                            rows="2"
                            style="height:51px;overflow-x:hidden;text-align:left;white-space:normal;resize:none;padding-top:0;"
                            placeholder="Leave a comment"
                            class="form-control"
                            appInputRestriction="restrictChars"></textarea>
                </div>
                <div class="form-group">
                  <button type="button"
                          class="btn btn-primary"
                          (click)="groupUpdateBtn(groupUpdate);">
                    Update
                  </button>
                  <button type="button"
                          class="btn btn-default"
                          (click)="resetGroupUpdate();">
                    Cancel
                  </button>
                </div>
                <div class="form-group has-error"
                     *ngIf="groupUpdate.submitted && (groupUpdate.selected === '' || groupUpdate.selected.name === 'Select')">
                  <span class="control-label">Please select the field to edit.</span>
                </div>
                <div class="form-group has-error"
                     *ngIf="groupUpdate.submitted && ((groupUpdate.updatedStartTime === '' && groupUpdate.updatedEndTime === '' && groupUpdate.updatedMealStartTime === '' && groupUpdate.updatedMealEndTime === '') || groupUpdate.updatedPayrollComment === '')">
                  <span *ngIf="groupUpdate.selected.name ==='Field Notes'"
                        class="control-label">Please enter some Field Notes.</span>
                  <span *ngIf="groupUpdate.selected.name ==='Time'"
                        class="control-label">
                    Please enter time to update.
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasNewTabSelected" class="page-content grid-content pad-0 timesheet-list">
          <div *ngIf="!showActivation && eventTimesheet?.timesheets?.items?.length === 0 && filterApplied">
            No Results
          </div>
          <div class="row"
               *ngIf=" eventTimesheet?.timesheets?.items?.length > 0">
            <div class="view-options row-pagination-event-timesheet">

              <div class="col-md-12">
                <div class="form-group text-end">
                  <label class="pagination-results">
                    <div>{{totalRecords}} Results</div>
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div id="event-timesheet-grid">
            <div class="hdr-grid-event-timesheet hdr-grid hdr-grid-ttl-stacked"
                 *ngIf=" eventTimesheet?.timesheets?.items?.length > 0">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="hdr-grid-ttl">
                        <div class="hdr-grid-ttl-txt">
                          <input type="checkbox"
                                 ignore-dirty
                                 name="selectAll"
                                 [(ngModel)]="groupUpdate.selectAll"
                                 authenticate-user="eventtimesheet.update"
                                 authenticate-command="disabled"
                                 (click)="selectAll($event);"
                                 authenticate-disabled="{{(eventTimesheet?.timesheets?.items?.length === 0) || (batchStatus | lowercase) === 'submitted'}}" />
                          Contact
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div class="hdr-grid-ttl-txt">
                          Position
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          EMP Type
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-6">
                  <div class="row">
                    <div class="col-lg-2 col-md-3 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          {{eventTimesheet.timecardDate|date:"EEE"}}<br />
                          {{eventTimesheet.timecardDate|date:"MMM d"}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          Dur
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          Start
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          End
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          Meal Start
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl text-center">
                        <div class="hdr-grid-ttl-txt">
                          Meal End
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 hidden-md hidden-sm hidden-xs">
                      <div class="hdr-grid-ttl">
                        <div class="hdr-grid-ttl-txt">
                          Field Notes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form name="eventTimesheetForm" #eventTimesheetForm="ngForm"
                  novalidate
                  *ngIf="eventTimesheet?.timesheets?.items">
              <div>
                <div>
                  <div class="row grid-row"
                       [ngClass]="{'error-row' : item.invalidTime}"
                       *ngFor="let item of eventTimesheet.timesheets.items ; let i =index"
                       [attr.data-index]="i">
                    <div class="col-lg-4 col-md-6 grid-group-title"
                         [ngClass]="{'error-row' : item.invalidTime}">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="grid-timesheet-itm grid-timesheet-itm-checkbox {{ item.status | lowercase }}">
                            <input type="checkbox"
                                   ignore-dirty
                                   name="selected"
                                   [(ngModel)]="item.selected"
                                   [ngModelOptions]="{standalone: true}"
                                   authenticate-user="eventtimesheet.update"
                                   authenticate-command="hide"
                                   (click)="groupUpdateSelect(item.mpId)"
                                   [disabled]="item.isSubmitted === 'Y'" />
                          </div>
                          <div class="grid-timesheet-itm grid-timesheet-itm-contact"
                               [ngClass]="{'mtk-contact-flag' : item.mobTkContactFlag,'mtk-contact-save-flag' : item.mobTkContactSaveFlag}" >
                            <div class="grid-timesheet-itm-ttl">
                              <a routerLink="/Contacts/Detail/{{item.contact.id}}"
                                class="btn-link"
                                 title="{{item.contact.firstName}} {{item.contact.lastName}}, SSO:{{item.contact.sso}}"
                                 target="_blank">{{item.contact.firstName}}&nbsp;{{item.contact.lastName}}</a>
                              <button type="button"
                                      class="float-end btn btn-no-style"
                                      *ngIf="item?.mobTkContactComment"
                                      data-toggle="tooltip"
                                      tooltip-trigger="mouseenter"
                                      tooltip-placement="top"
                                      tooltip="{{item.mobTkContactComment}}">
                                <i class="fa fa-comment cursor-pointer"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 col-xs-9">
                          <div class="grid-timesheet-itm">
                            <!-- <truncate-text truncate-string="item.eventPosition.jobTitleDetail ? item.eventPosition.jobTitleDetail : item.eventPosition.position.description"
                            truncate-limit="17"></truncate-text> -->
                            <span *ngIf="item.eventPosition.jobTitleDetail"
                                  tooltip-placement="top"
                                  tooltip="{{item.eventPosition.jobTitleDetail}}">
                              {{item.eventPosition.jobTitleDetail}}
                            </span>
                            <span  *ngIf="!item.eventPosition.jobTitleDetail"
                                  tooltip-placement="top"
                                  tooltip="{{item.eventPosition.position.description}}">
                            {{item.eventPosition.position.description}}</span>

                          </div>
                        </div>
                        <div class="col-sm-2 col-xs-3">
                          <div class="grid-timesheet-itm text-center">
                            <!-- <truncate-text truncate-string="item.eventPosition.employeeType.description"
                            truncate-limit="3"> -->
                            <!-- </truncate-text> -->
                            <span *ngIf="item.eventPosition.employeeType.description"
                                  tooltip-placement="top"
                                  tooltip="{{item.eventPosition.employeeType.description}}">
                              {{item.eventPosition.employeeType.description | slice:0:3}}...
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                      <div class="row">
                        <div class="col-lg-2 col-sm-3 col-xs-9 grid-group-profile"
                             [ngClass]="{'error-row' : item.invalidTime}">
                          <div class="grid-timesheet-itm grid-timesheet-itm-date">
                            <div class="col-xs-12"
                                 *ngIf="item &&item.eventPosition && item.eventPosition.workScheduleDates && item.eventPosition.workScheduleDates.length > 0 && item.eventPosition.workScheduleDates[0].workActivities">
                              <span [ngClass]="{'activity-1':((item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length === 1), 'activity-2':((item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length === 2), 'activity-3':((item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length === 3),'activity-4':((item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length === 4)}"
                                    class="activity activity-{{workActivity.code|lowercase}}"
                                    *ngFor=" let workActivity of item.eventPosition.workScheduleDates[0].workActivities">
                                <span *ngIf="(item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length >= 3">{{workActivity.code[0]}}</span>
                                <span *ngIf="(item.eventPosition.workScheduleDates[0].workActivities|filterBy:['isPlanned']:true).length < 3">{{workActivity.code}}</span>
                              </span>
                            </div>
                            <div class="col-xs-3"
                                 [hidden]="true">
                              <div class="popover-btn-wrapper">
                                <span class="activity activity-duration">
                                  {{item.eventPosition.workScheduleDates[0].plannedDuration || 0}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-1 col-xs-3">
                          <div class="grid-timesheet-itm text-center">
                            <span class="fa-stack fa-1x flat-day"
                                  *ngIf="item.billCodeUnitCodeTypeCode === 'Dy'"
                                  data-toggle="tooltip"
                                  tooltip-trigger="mouseenter"
                                  tooltip-placement="top"
                                  tooltip="Rate Code: FLAT DAY">
                              <i class="fa fa-circle fa-stack-2x"></i>
                              <strong class="fa-stack-1x fa-inverse">D</strong>
                            </span>
                            <span *ngIf="item.billCodeUnitCodeTypeCode !== 'Dy'">
                              {{calculateDuration(item.timeIn, item.timeOut, item)}}
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-1 col-sm-2 col-xs-3">
                          <div class="grid-timesheet-itm text-center"> 
                            <div class="cursor-pointer form-group-time form-group-time-height ">
                              <div nbc-time
                                   class="form-group-time-height"
                                   [id]="item.mpId"
                                   name="timeIn_{{item.mpId}}"
                                  
                                   [isStartTime]="true"
                                   current-date="activeTimesheetDate"
                                   tabindex="0"
                                   placeholder="Start"
                                   [DefaultDate]="eventTimesheetQuery.timeCardDate"
                                   [(model)]="item.timeIn"
                                   (nbcBlur)="onItemChanged($event,item)"
                                   (modelChange)="onTimeSelected($event,item,i,'timeIn')"
                                   [nbcDisabled]="item.isSubmitted === 'Y' || (!currentUser.allFeatures['eventtimesheet.update'] && !currentUser.isSuperUser) || item.billCodeUnitCodeTypeCode === 'Dy'"
                                   [nbcRequired]="(item.timeOut || item.lunchIn || item.lunchOut) && true">
                              </div>

                            </div>

                          </div>
                        </div>
                        <div class="col-lg-1 col-sm-2 col-xs-3">
                          <div class="grid-timesheet-itm text-center"> 
                            <div class="cursor-pointer form-group-time form-group-time-height ">
                              <div nbc-time
                                   class="form-group-time-height"
                                   [id]="item.mpId"
                                   
                                   name="timeOut_{{item.mpId}}"
                                   [isStartTime]="false"
                                   current-date="activeTimesheetDate"
                                   tabindex="0"
                                   placeholder="End"
                                   [DefaultDate]="eventTimesheetQuery.timeCardDate"
                                   (nbcBlur)="onItemChanged($event,item)"
                                   [(model)]="item.timeOut"
                                   (modelChange)="onTimeSelected($event,item,i,'timeOut')"
                                   [nbcDisabled]="item.isSubmitted === 'Y' || (!currentUser.allFeatures['eventtimesheet.update'] && !currentUser.isSuperUser) || item.billCodeUnitCodeTypeCode === 'Dy'"
                                   [nbcRequired]="(item.timeIn || item.lunchIn || item.lunchOut) && true">
                              </div>
                            </div>
                          </div>


                        </div>
                        <div class="col-lg-1 col-sm-2 col-xs-3">
                          <div class="grid-timesheet-itm text-center">
                            <div date-time-append
                                 class="cursor-pointer form-group-time form-group-time-height "> 
                              <div nbc-time
                                   class="form-group-time-height"
                                   [id]="item.mpId"
                                   
                                   name="lunchIn_{{item.mpId}}"
                                   [isStartTime]="false"
                                   current-date="activeTimesheetDate"
                                   tabindex="0"
                                   placeholder="Start"
                                   [(model)]="item.lunchIn"
                                   (nbcBlur)="onItemChanged($event,item)"
                                   [DefaultDate]="eventTimesheetQuery.timeCardDate"
                                   (modelChange)="onTimeSelected($event,item,i,'lunchIn')"
                                   [nbcDisabled]="item.isSubmitted === 'Y' || (!currentUser.allFeatures['eventtimesheet.update'] && !currentUser.isSuperUser) || item.billCodeUnitCodeTypeCode === 'Dy'"
                                   [nbcRequired]="(item.timeIn || item.timeOut || item.lunchOut) && true">
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="col-lg-1 col-sm-2 col-xs-3">
                          <div class="grid-timesheet-itm text-center">
                            <div class="cursor-pointer form-group-time form-group-time-height "> 
                              <div nbc-time
                                   class="form-group-time-height"
                                   [id]="item.mpId"
                                    
                                   name="lunchOut_{{item.mpId}}"
                                   [isStartTime]="false"
                                   current-date="activeTimesheetDate"
                                   tabindex="0"
                                   placeholder="End"
                                   [(model)]="item.lunchOut"
                                   (nbcBlur)="onItemChanged($event,item)"
                                   [DefaultDate]="eventTimesheetQuery.timeCardDate"
                                   (modelChange)="onTimeSelected($event,item,i,'lunchOut')"
                                   [nbcDisabled]="item.isSubmitted === 'Y' || (!currentUser.allFeatures['eventtimesheet.update'] && !currentUser.isSuperUser) || item.billCodeUnitCodeTypeCode === 'Dy'"
                                   [nbcRequired]="(item.timeIn || item.timeOut || item.lunchIn) && true">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-10 col-xs-9">
                          <div class="grid-timesheet-itm grid-timesheet-itm-comments">
                            <textarea [(ngModel)]="item.productionManagerComment"
                                      name="tsComments_{{item.mpId}}"
                                      maxlength="255"
                                      rows="2"
                                      style="height:51px;overflow-x:hidden;text-align:left;white-space:normal;resize:none;padding-top:0;"
                                      placeholder="Leave a comment"
                                      class="form-control"
                                      appInputRestriction="restrictChars"
                                      authenticate-user="eventtimesheet.update"
                                      authenticate-command="disabled"
                                      [disabled]="(item.isSubmitted === 'Y')"></textarea>
                          </div>
                        </div>
                        <div class="col-lg-1 col-sm-2 col-xs-3 text-center">
                          <div class="grid-timesheet-itm grid-timesheet-itm-alerts">
                            <span class="time-alert-high"
                                  *ngIf="item && item.timeIn && item.timeOut && calculateMinutesDiff(item.timeIn, item.timeOut) > 900 "
                                  data-toggle="tooltip"
                                  tooltip-trigger="mouseenter"
                                  tooltip-placement="top"
                                  tooltip="Duration is greater than 15 hours">
                              <i class="fa fa-clock-o"></i>
                              <i class="fa fa-arrow-up"></i>
                            </span>
                            <span class="time-alert-low"
                                  *ngIf="item && item.timeIn && item.timeOut &&  calculateMinutesDiff(item.timeIn, item.timeOut) < 480 "
                                  data-toggle="tooltip"
                                  tooltip-trigger="mouseenter"
                                  tooltip-placement="top"
                                  tooltip="Duration is less than 8 hours">
                              <i class="fa fa-clock-o"></i>
                              <i class="fa fa-arrow-down"></i>
                            </span>
                            <span
                                  *ngIf="calculateMeal(item) && item.lunchIn && item.lunchOut"
                                  data-toggle="tooltip"
                                  tooltip-trigger="mouseenter"
                                  tooltip-placement="top"
                                  tooltip="{{item.mealTitle}}">
                              <span  [ngClass]="item.isTimeAlertHigh ? 'time-alert-high' : 'time-alert-low'">
                                <i class="fa fa-cutlery"></i>
                                <i class="fa fa-arrow-up"
                                   *ngIf="item.isLunchDurationMorethanaHr"></i>
                                <i class="fa fa-arrow-down"
                                   *ngIf="!item.isLunchDurationMorethanaHr"></i>
                              </span>
                            </span>

                            <span class="time-alert-low"
                                  *ngIf="calculateDays(item.timeIn, item.timeOut) > 0"
                                  data-toggle="tooltip"
                                  tooltip-trigger="mouseenter"
                                  tooltip-placement="top"
                                  tooltip="Duration goes into the following day">
                              <i class="fa fa-moon-o"></i>
                              <i class="fa fa-arrow-down"></i>
                            </span>
                            <span class="time-alert-high"
                                  *ngIf="item.invalidTime">
                              <i class="fa fa-exclamation-triangle"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
