import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import * as _ from "lodash";

@Component({
    selector: "modal-contact-type-change",
    templateUrl: "./modal-contact-type-change.html",
})
export class ModalContactTypeChangeComponent implements OnInit {
    constructor(public modalRef: BsModalRef) {}

    public onSync!: Subject<any>;

    hrStatusCompletedNonVendor: any | undefined;
    contactCopy: any = {};
    contact: any = {};

    HRStatus: any | undefined;

    closeModal() {
        this.modalRef.hide();
        this.contact.isNonVendor = !this.contact.isNonVendor;
    }

    confirm() {
        this.contactCopy = _.cloneDeep(this.contact);
        this.contact.isNonVendor = this.contact.isNonVendor;
        if (!this.contact.isNonVendor) {
            this.contact.perNer = "";
            this.contact.sso = "";
            this.contact.IsUsCitizen = false;
            this.contact.employmentStatus = "not required";
            this.contact.employeeGroup = "";
            this.contact.employeeSubGroup = "";
            this.contact.businessSegment = "";
            this.contact.unionAffiliation = "";
            this.contact.union = "";
            this.contact.payrollArea = "";
            this.contact.businessSegment = "";
            this.contact.employeeTypeHr = "";
            //For vendor to display 4th section
            this.HRStatus = 4;
        } else {
            this.contact.IsUsCitizen = true;
            this.contact.employmentStatus = "Processing";
            //For non-vendor to display 1st section
            this.HRStatus = 1;
        }

        this.onSync.next(this.contact);
        this.modalRef.hide();
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.hrStatusCompletedNonVendor = this.HRStatus;
        // this.contact = this.contact;
    }
}
