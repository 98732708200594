
<div class="box-dash border-dash tranparent">
  <div class="box-dash-hdr-bar backgrounddash headerdiv">    
    <h2 class="box-dash-hdr fontcolourdash"><i class="fa fa-user" aria-hidden="true" style="margin-left:-17px"></i>Timecards Validation Failed</h2>
  </div>
  <div class="box-dash-inner-dflt">
    <!--<div class="table-responsive">-->
    <div class="row">
      <div class="col-md-12">
        <!--Grid Header-->
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                Contact Name
              </th>
              <th>Event Name</th>
              <th>
                <a href="javascript:void(0)" (click)="sortByColumn('dateWorked');">
                  Date Worked
                  <span class="th-sort">
                    <span *ngIf="queryContainer.query.sortByColumn === 'dateWorked'">
                      <i *ngIf="queryContainer.query.reverseSort"
                         class="fa fa-caret-up"></i>
                      <i *ngIf="!queryContainer.query.reverseSort"
                         class="fa fa-caret-down"></i>
                    </span>
                  </span>
                </a>

              </th>
              <th>Error</th>             
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of allvalidationfailedtimecards">
              <td><a href="/Remotes/Contacts/Detail/{{item.contactId}}/profileinfo" target="_blank">{{item.contactName}}</a></td>
              <td><a href="/Remotes/Payroll/upcomingevents/setup/{{item.eventNo}}" target="_blank">{{item.eventName}}</a></td>
              <td style="width: 110px;"><a href="Remotes/Payroll/timesheet/{{item.eventNo}}/{{item.timecardDate}}/" target="_blank">{{item.timecardDate}}</a></td>
              <td>{{item.validationFailureMessage}}</td>
            </tr>
          </tbody>
        </table>

        <!--<p>Event Recently Viewed!</p>-->
      </div>
      <!--<div class="col-sm-6">
      </div>-->
    </div>
  </div>
</div>
