import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from "lodash";
import { extend } from "lodash";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { NbcBaseComponent } from "src/app/Core/component/Base.Component";
import { PermissionUtility } from "src/app/Core/services/permission-utility.service";

@Component({
    selector: "airport-code",
    templateUrl: "./airport-code.component.html",
})
export class AirportCodeComponent extends NbcBaseComponent implements OnInit {
    constructor(
        private globalService: GlobalService,
        private _eventBus: EventBusService,
        private log: NbcLoggerService
    ) {
        super();
    }

    @Input() model: any | undefined;
    @Input() name: string | undefined;
    @Input() listId: string | undefined;
    @Input() nbcClass: string | undefined;
    @Input() isRequired = false;
    @Input() journeyLeg: string | undefined;
    @Input() nbcAuthenticateUser: any | undefined;
    @Input() nbcAuthenticateCommand: any | undefined;
    @Input() isBulkUpdate = false;

    @Output() modelChange = new EventEmitter<any>();

    airportCodeObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<any> = new Subject();

    searchTerm = "";
    isAuthorized = false;

    getAirportCodes() {
        this.log.trace("get airports");
        this.airportCodeObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.searchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this.globalService.getAirportCodes(token);
            })
        );
    }

    onValueSelected($event) {
        const airport = $event.item;
        this.modelChange.emit(airport.description);

        const data = {
            listId: this.listId,
            airpotcode: airport.description,
            journeyLeg: this.journeyLeg,
        };
        if (!this.isBulkUpdate)
            this._eventBus.emit(
                new EmitEvent(
                    "airport-code:onValueSelected",
                    "onSelectAirportCode",
                    data
                )
            );
    }
    ngOnInit() {
        this.searchTerm = this.model;
        this.getAirportCodes();
        this.isAuthorized = super.authorize(this.nbcAuthenticateUser);
    }
}
