import {
    Component,
    OnInit,
    Directive,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
//import { Placeholder } from "@angular/compiler/src/i18n/i18n_ast";

@Component({
    selector: "nbc-multi-select",
    templateUrl: "./nbc-multi-select-dropdown.component.html",
})
export class NbcMultiSelectDropdown implements OnInit, OnChanges {
    constructor() {}

    @Input() source!: any[];
    @Input() selectedValues: any | undefined;
    @Input() display: any | undefined;
    @Input() value: any | undefined;
    @Input() placeholder: any | undefined;
    //@Output() userSelection = new EventEmitter<any>();
    @Output() selectedItemsChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nbcOnOptionSelect = new EventEmitter<any>();

    dropdownList!: any[];
    selectedItems!: any[];
    dropdownSettings!: IDropdownSettings;

    onItemSelect(item: any) {
        //this.selectedItemsChange.emit(this.selectedItems);
        this.nbcOnOptionSelect.emit(this.selectedItems);
    }
    onSelectAll(items: any) {
        //  this.selectedItemsChange.emit(this.selectedItems);
        this.nbcOnOptionSelect.emit(this.selectedItems);
    }

    initialiseData() {
        this.dropdownSettings = {
            singleSelection: false,
            idField: "",
            textField: "",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true,
            enableCheckAll: false,
        };
    }

    onsourceChange(val: any) {}
    ngOnInit() {
        this.initialiseData();

        this.dropdownSettings.idField = this.value;
        this.dropdownSettings.textField = this.display;
        this.dropdownList = this.source;
        this.selectedItems = this.selectedValues;
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "selectedValues") {
                const current = changes["selectedValues"]["currentValue"];
                const prev = changes["selectedValues"]["previousValue"];
                if (
                    (current === 0 || current === "" || current === null) &&
                    prev !== 0 &&
                    prev !== "" &&
                    prev !== null
                ) {
                    this.selectedItems = [];
                } else {
                    this.selectedItems = current;
                }
            }
        }
    }
}
