<div class="box-dash">
  <div class="box-dash-hdr-bar">
    <h2 class="box-dash-hdr">Recently Viewed</h2>
  </div>
  <div class="box-dash-inner-dflt">
    <div class="table-responsive">
      <table class="table-dash">
        <tbody>
          <tr *ngFor="let item of recentlyViewed">
            <td>
              <img width="45"
                   height="45"
                   src="/assets/image/{{item.referenceType}}/{{item.imageId}}">
            </td>
            <td class="table-dash-col-full">
              <h5 *ngIf="item.referenceType === 'event'"
                  class="box-dash-hdr-sub">
                <a routerLink="/Events/Event/{{item.referenceId}}">
                  {{item.name}}
                </a>
              </h5>
              <h5 *ngIf="item.referenceType === 'contact'"
                  class="box-dash-hdr-sub">
                <a class="btn-link" routerLink="/Contacts/Detail/{{item.referenceId}}" target="_blank">
                  {{item.name}}
                </a>
              </h5>
              <h5 *ngIf="item.referenceType === 'season'"
                  class="box-dash-hdr-sub">
                <a routerLink="/Events/Season/{{item.referenceId}}">
                  {{item.name}}
                </a>
              </h5>
              <span class="txt-uppercase">{{item.description}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p [hidden]="recentlyViewed?.length != 0 && recentlyViewedComplete">No Results</p>
  </div>
</div>
