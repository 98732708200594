import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable } from "rxjs";
/*import { ISelect } from 'src/app/Event/dashboard/eventlist.component'*/ @Injectable()
export class DashboardService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    getUpcomingEvents(filters: any): Observable<any> {
        this.log.trace("getting dashboard with filters " + filters);
        return this.http.post(
            this._apiroot.baseUrl + "dashboard/upcomingEvents",
            filters
        );
    }

    getDashboard(): Observable<any> {
        this.log.trace("getting dashboard");
        return this.http.get(this._apiroot.baseUrl + "dashboard");
    }

    getRecentlyViewed(): Observable<any> {
        this.log.trace("getting dashboard recently viewed data");
        return this.http.get(this._apiroot.baseUrl + "recentlyviewed");
    }

    getPositionRequestSummary() {
        this.log.trace("getting dashboard position request data");
        return this.http.get(
            this._apiroot.baseUrl + "dashboard/positionRequestSummary"
        );
    }

    getI9DocumentVerify() {
        this.log.trace("getting dashboard I9 document Verify data");
        return this.http.get(
            this._apiroot.baseUrl + "dashboard/i9documentverify"
        );
    }

    getRecentlyViewedData(type: any): Observable<any> {
        this.log.trace("getting dashboard recently viewed data");
        return this.http.get(
            this._apiroot.baseUrl + "dashboard/recentlyviewed/" + type
        );
    }

    getEventListData(type: any): Observable<any> {
        this.log.trace("getting dashboard Event List data");
        return this.http.get(
            this._apiroot.baseUrl + "dashboard/eventlist/" + type
        );
    }

  //GetPendingTimecards(): Observable<any> {
  //  this.log.trace("getting dashboard Pending Timecards");
  //  return this.http.get(this._apiroot.baseUrl + "dashboard/pendingtimecards");
  //};

  GetPendingTimecards(TimesheetValidationFailedFilterQuery): Observable<any> {
    this.log.trace("getting dashboard Validation Failed Timecards");
    return this.http.post(this._apiroot.baseUrl + "dashboard/pendingtimecards", TimesheetValidationFailedFilterQuery);
  };


  GetValidationFailedTimecards(): Observable<any> {
    this.log.trace("getting dashboard Validation Failed Timecards");
    return this.http.get(this._apiroot.baseUrl + "dashboard/validationfailedtimecards");
  };

  GetTSValidationFailedTimecards(TimesheetValidationFailedFilterQuery): Observable<any> {
    this.log.trace("getting dashboard Validation Failed Timecards");
    return this.http.post(this._apiroot.baseUrl + "dashboard/Timesheetvalidationfailedtimecards", TimesheetValidationFailedFilterQuery);
  };



  

}
