import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ContactService } from "src/app/Shared/Services/contact.service";

@Component({
    selector: "modal-contact-sap-result",
    templateUrl: "./modal-contact-comments.html",
})
export class ModalContactCommentComponent implements OnInit, OnDestroy {
    constructor(
        public _modalRef: BsModalRef,
        private _contactService: ContactService,
        private log: NbcLoggerService
    ) {}
    componentDestroyed$: Subject<boolean> = new Subject();
    //subject to transmit the results
    public onSync!: Subject<any>;
    contact: any = {};
    comments = "";

    cancel() {
        this._modalRef.hide();
    }

    saveComment() {
        this.log.trace("save contact comment");

        this.contact.contactComment = this.comments;

        this._contactService
            .upsertContact(this.contact)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    this.onSync.next(result);
                    this._modalRef.hide();
                },
                error: (e) => {
                    this.log.error("got an error: " + JSON.stringify(e));
                    //if (status === 400) {
                    //  $scope.$emit("showAlert", "danger", data.message, "modalAlert");
                    this.log.showModalError(
                        "Unable to perform the save at this time. Please try again later."
                    );
                },
            });
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.comments = this.contact.contactComment;
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
