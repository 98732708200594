<div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>

</div>
<div class="modal-body">
    <span>Timekeeper cannot be the payroll provider to some of your selected contacts because they do not have SSO numbers. These contacts will be assigned to TBTK instead. Do you want to continue?</span>
</div>
<div class="modal-footer text-right">
    <button class="btn btn-default" (click)="closeModal();">No, Cancel</button>
    <button class="btn btn-primary" (click)="confirm();">Yes, Continue</button>
</div>
