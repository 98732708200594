import { Component, Input } from "@angular/core";
import * as _ from "lodash";
import { PermissionUtility } from "../services/permission-utility.service";

@Component({
    selector: "base",
    template: "",
})
export class NbcBaseComponent {
    //method to validate if the user has access to the functionality. Based on the result, component can take the
    //further step to disable, hide etc.

    @Input("authenticate-required")
    authenticateRequired: any;

    @Input("authenticate-user")
    authenticateUser: any;

    @Input("authenticate-department")
    authenticateDepartment: any;

    @Input("authenticate-contact-id")
    authenticateContactId: any;

    @Input("authenticate-command")
    authenticateCommand: any;

    @Input("authenticate-disabled")
    authenticateDisabled: any | undefined;

    authorize(
        tokens = null,
        departmentId = null,
        flockCompareId = null,
        mustAuthDept = null
    ) {
        tokens = this.authenticateUser || tokens;
        departmentId = this.authenticateDepartment || departmentId;
        flockCompareId = this.authenticateContactId || flockCompareId;
        mustAuthDept = this.authenticateRequired || mustAuthDept;

        let hasAccess = true;
        if (_.isEmpty(tokens)) return hasAccess;
        const access = PermissionUtility.PermissionUtilities();
        const user = window.preload.user;
        hasAccess = access.hasAccess(
            user,
            user.allFeatures,
            tokens.split(","),
            departmentId,
            flockCompareId,
            mustAuthDept
        );

        if (
            //looking for user defined specific value and not null,undefined etc.
            this.authenticateDisabled === true ||
            this.authenticateDisabled === false
        )
            return hasAccess || this.authenticateDisabled;
        else return hasAccess;
    }
}
