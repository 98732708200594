<div class="Row" *ngIf="isTabVisible===true">
  <div class="col-md-12 wizard-layout">
    <nbc-alert></nbc-alert>
    <div class="Row">
      <div class="col-md-12">
        <span class=" pull-left">
          <span class="wizard-networkGroup-details"> {{networkConfig.name}}</span>
        </span>
        <span class="pull-right">
          <span class="wizard-networkGroup-details">{{networkConfig.numberOfTiers}} Tiers</span>
        </span>
      </div>
    </div>
    <div class="Row">
      <div class="col-md-12">
        <span class="wizard-networkgroup-header">{{participatingNetworks}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12  wizard-position-actionpanel">
        <div class="col-md-1">
          <input type="button"
                 value="Collapse All"
                 class="left btn btn-primary wizard-expandAll"
                 *ngIf="!isExpandAll"
                 (click)="expandAll();" />
          <input type="button"
                 value="Expand All"
                 class="left btn btn-primary wizard-expandAll"
                 *ngIf="isExpandAll"
                 (click)="expandAll();" />
        </div>
        <div class="col-md-8">
          <div>
            <div class="col-md-3">
              <span class="pull-right"
                    style="line-height:35px;">Effective Date: </span>
            </div>
            <div class="col-md-3 pull-left">
              <div class="row" *ngIf="networkConfig.networkGroupPublish">
                <input type="text"
                       id="date_effectiveDate"
                       class="form-control"
                       bsDatepicker
                       name="airDate"
                       autocomplete="off"
                       [(ngModel)]="networkConfig.networkGroupPublish.effectiveDate"
                       (ngModelChange)="validateDateInput($event)"
                       [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY'}">
              </div>
              <div class="row">
                <span *ngIf="networkConfig?.networkGroupPublish?.asOfDate"
                      style="display:block;padding-top:10px;">
                  As Of Date:
                  {{networkConfig.networkGroupPublish.asOfDate}}
                </span>
              </div>
            </div>
            <div class="col-md-5 pull-right">
              <div class="row">
                <!-- //TODO -->
                <input type="button"
                       value="Publish Rates"
                       class="left btn btn-primary"
                       [disabled]="!validation?.publishEnabled"
                       (click)="onPublishRateCard()" />

              </div>
              <div class="row">
                <span *ngIf="(networkConfig?.networkGroupPublish?.publishedDate && !validation?.publishEnabled)"
                      style="display:block;padding-top:13px;">
                  Published:
                  {{networkConfig?.networkGroupPublish?.publishedDate}}
                </span>
              </div>

            </div>
          </div>

        </div>
        <div class="col-md-3">
          <input type="button"
                 value="+ Tiered Rate Card"
                 class="pull-right btn btn-primary"
                 (click)="AddTierRateCard('');" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <accordion close-others=" oneatatime">
          <accordion-group [(isOpen)]="tierPosition.isAccordionOpen"
                           id="accordion_{{index+((currentPage-1)*20)+1}}"
                           *ngFor="let tierPosition of networkConfig.tierPositions;let index = index">
            <!-- <accordion-heading> -->
            <div accordion-heading
                 class="row"
                 (click)="toggleButton();">
              <div class="col-md-4">
                <i class="fa"></i>{{tierPosition.desc}}
              </div>
              <div class="col-md-2">
                <i class="fa"></i>{{tierPosition.positionCategory}}
              </div>
              <div class="col-md-4">
                <i class="fa"></i>{{tierPosition.position.description}}
              </div>
              <div class="col-md-2">
                <i class="fa"></i>{{tierPosition.lastUpdateDate | date : 'MM/dd/yyyy'}}
                <i class="pull-right fa"
                   [ngClass]="{'fa-caret-up': tierPosition.isAccordionOpen, 'fa-caret-down': !tierPosition.isAccordionOpen}"></i>
                <span class="pull-right"
                      style="width:30px;text-align:center;height:19px;">
                  <i class="fa fa-pencil"
                     (click)="AddTierRateCard(tierPosition);"></i>
                </span>
              </div>
            </div>

            <!-- </accordion-heading> -->
            <div>
              <table class="table table-bordered table-hover table-responsive wizard-rate">
                <thead>
                  <tr>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      Tier
                    </th>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      Low
                    </th>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      25%
                    </th>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      50%
                    </th>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      75%
                    </th>
                    <th class="wizard-rate-ranges-header"
                        colspan="2">
                      High
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2"></td>
                    <td class="wizard-rate-ranges-header">
                      Current
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Future
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Current
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Future
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Current
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Future
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Current
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Future
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Current
                    </td>
                    <td class="wizard-rate-ranges-header">
                      Future
                    </td>
                  </tr>

                  <tr *ngFor="let positionTier of tierPosition.positionTiers">
                    <td colspan="2">
                      <span>{{positionTier?.id}}</span>
                    </td>
                    <td>
                      <input type="text"
                             [ngClass]="{'invalid-current-Rate':!positionTier?.currentRates?.minRate}"
                             class="custom-rate-field wizard-rate-range-input"
                             [ngStyle]="{'border': !positionTier.currentRates.minRate && !isCurrentRateDisabled ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,true,'minRate')"
                             (keydown)="validateRate($event,positionTier.currentRates,'minRate')"
                             [(ngModel)]="positionTier.currentRates.minRate"
                             [disabled]="isCurrentRateDisabled"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user=""
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input future-rate"
                             [ngClass]="{'invalid-Future-Rate':!positionTier?.futureRates?.minRate}"
                             [ngStyle]="{'border': !positionTier.futureRates.minRate ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,false,'minRate')"
                             (keydown)="validateRate($event,positionTier.futureRates,'minRate')"
                             (blur)="validateRate($event,positionTier.futureRates,'minRate')"
                             [(ngModel)]="positionTier.futureRates.minRate"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user="TierRateCard.Update"
                             authenticate-command="disabled" />

                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input"
                             [ngClass]="{'invalid-current-Rate':!positionTier.currentRates.percentile25}"
                             [ngStyle]="{'border': !positionTier.currentRates.percentile25 && !isCurrentRateDisabled ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,true,'percentile25')"
                             (keydown)="validateRate($event,positionTier.currentRates,'percentile25')"
                             [(ngModel)]="positionTier.currentRates.percentile25"
                             [disabled]="isCurrentRateDisabled"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user=""
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input future-rate"
                             [ngClass]="{'invalid-Future-Rate':!positionTier.futureRates.percentile25}"
                             [ngStyle]="{'border': !positionTier.futureRates.percentile25 ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,false,'percentile25')"
                             (keydown)="validateRate($event,positionTier.futureRates,'percentile25')"
                             [(ngModel)]="positionTier.futureRates.percentile25"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user="TierRateCard.Update"
                             authenticate-command="disabled" />


                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input"
                             [ngClass]="{'invalid-current-Rate':!positionTier.currentRates.percentile50}"
                             [ngStyle]="{'border': !positionTier.currentRates.percentile50 && !isCurrentRateDisabled ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,true,'percentile50')"
                             (keydown)="validateRate($event,positionTier.currentRates,'percentile50')"
                             [(ngModel)]="positionTier.currentRates.percentile50"
                             [disabled]="isCurrentRateDisabled"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user=""
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input future-rate"
                             [ngClass]="{'invalid-Future-Rate':!positionTier.futureRates.percentile50}"
                             [ngStyle]="{'border': !positionTier.futureRates.percentile50 ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,false,'percentile50')"
                             (keydown)="validateRate($event,positionTier.futureRates,'percentile50')"
                             [(ngModel)]="positionTier.futureRates.percentile50"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user="TierRateCard.Update"
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input"
                             [ngClass]="{'invalid-current-Rate':!positionTier.currentRates.percentile75}"
                             [ngStyle]="{'border': !positionTier.currentRates.percentile75  && !isCurrentRateDisabled? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,true,'percentile75')"
                             (keydown)="validateRate($event,positionTier.currentRates,'percentile75')"
                             [(ngModel)]="positionTier.currentRates.percentile75"
                             [disabled]="isCurrentRateDisabled"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user=""
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input future-rate"
                             [ngClass]="{'invalid-Future-Rate':!positionTier.futureRates.percentile75}"
                             [ngStyle]="{'border': !positionTier.futureRates.percentile75 ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,false,'percentile75')"
                             (keydown)="validateRate($event,positionTier.futureRates,'percentile75')"
                             [(ngModel)]="positionTier.futureRates.percentile75"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user="TierRateCard.Update"
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input"
                             [ngClass]="{'invalid-current-Rate':!positionTier.currentRates.maxRate}"
                             [ngStyle]="{'border': !positionTier.currentRates.maxRate && !isCurrentRateDisabled ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,true,'maxRate')"
                             (keydown)="validateRate($event,positionTier.currentRates,'maxRate')"
                             [(ngModel)]="positionTier.currentRates.maxRate"
                             [disabled]="isCurrentRateDisabled"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user=""
                             authenticate-command="disabled" />
                    </td>
                    <td>
                      <input type="text"
                             class="custom-rate-field wizard-rate-range-input future-rate"
                             [ngClass]="{'invalid-Future-Rate':!positionTier.futureRates.maxRate}"
                             [ngStyle]="{'border': !positionTier.futureRates.maxRate ? '1px solid red' :''}"
                             (blur)="rateChanged(tierPosition.id,positionTier,false,'maxRate')"
                             (keydown)="validateRate($event,positionTier.futureRates,'maxRate')"
                             [(ngModel)]="positionTier.futureRates.maxRate"
                             placeholder="---.---"
                             maxlength="7"
                             authenticate-user="TierRateCard.Update"
                             authenticate-command="disabled" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </accordion-group>
        </accordion>
      </div>
      <div *ngIf="networkConfig.tierPositions==0">
        <span style="color:red;"> No Record Found</span>
      </div>
    </div>
  </div>
</div>
