import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[nbcRightClickOpen]",
})
export class NbcRightClickOpenDirective {
    @Input() nbcRightClickOpen: string;

    constructor(private el: ElementRef) {}

    @HostListener("contextmenu", ["$event"])
    onContextMenu(event: Event) {
        this.el.nativeElement.parentElement.setAttribute(
            "href",
            this.nbcRightClickOpen
        );
        // Ensure it opens in a new tab if clicked normally
        this.el.nativeElement.parentElement.setAttribute("target", "_blank");
        // Optionally, you might want to style the cursor to indicate it's clickable
        this.el.nativeElement.parentElement.style.cursor = "pointer";
    }
}
