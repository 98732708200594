import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { OnboardingComponent } from "./onboarding.component";
import { OnboardingHistoryComponent } from "./history/onboarding-history.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { OnboardingRequestsComponent } from "./requests/onboarding-requests.component";
import { ContactService } from "../Shared/Services/contact.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NbcSharedModule } from "../Shared/shared.module";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ModalModule } from "ngx-bootstrap/modal";
//import { NgPipesModule } from 'ngx-pipes';
//import { ChartsModule } from 'ng2-charts';
import { OnboardingRouterModule } from "./onboarding-routing.module";
import { OnboardingService } from "../Shared/Services/Onboarding/onboarding.service";
import { RequestsGridComponent } from "./requests/requests-grid.component";
import { HistoryGridComponent } from "./history/history-grid.component";
import { OnboardingColorsService } from "../Shared/Services/Onboarding/onboarding-colors.service";
import { EditPositionRequestComponent } from "./requests/editPositionRequest.component";
import { SendReminderComponent } from "./requests/sendreminder.component";
//import { NbcSelectComponent } from 'src/app/Shared/directives/nbcSelect/nbc-select.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TabsModule,
        NbcSharedModule,
        //NgPipesModule,
        TypeaheadModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        /*ChartsModule*/
        OnboardingRouterModule.forChild(),
    ],
    exports: [],
    declarations: [
        OnboardingComponent,
        OnboardingRequestsComponent,
        OnboardingHistoryComponent,
        RequestsGridComponent,
        HistoryGridComponent,
        EditPositionRequestComponent,
        SendReminderComponent,
    ],
    providers: [ContactService, OnboardingService, OnboardingColorsService],
})
export class OnboardingModule {}
