import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { Observable, throwError } from 'rxjs';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { catchError } from 'rxjs/operators';
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }



  searchDepartments(term: string): Observable<any> {
    this.log.trace("searchDepartments: " + term);
    return this.http.get(this._apiroot.baseUrl + "departments/searchByName?term=" + term).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }


  //LiquidCache('getDepartments')
  getDepartments(): Observable<any> {
    this.log.trace("getDepartments: ");
    return this.http.get(this._apiroot.baseUrl + "departments").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  }
}
