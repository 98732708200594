
<header id="col-main-hdr">
  <h1><i class="fa fa-user"></i> Onboarding</h1>
</header>
<nav class="breadcrumb">
  <ul>
    <li home></li>
    <li>/</li>
    <li>Onboarding</li>
  </ul>
</nav>

<header class="onboarding-header">
  <div class="container-fluid">
    <div class="head">
      <div class="row">
        <div class="col-md-12">
          <div class="topnav">
            <p>Position Request Status</p>
          </div>
        </div>
      </div>
      <div class="content-filters">
        <div class="row onboarding">
          <div class="col-sm-9">
            <ul class="status">
              <li><div class="hdr-count" style="margin-left:40%"><span style="display: block; font-size: 1.3em;">{{itemCount1}}</span></div><em> Position Requests Submitted</em><span></span></li>
              <li class="first"><div class="hdr-count"><span style="display: block; font-size: 1.3em;">{{itemCount2}}</span></div><em> Registrations Submitted</em><span></span></li>
              <li class="current"><div class="hdr-count"><span style="display: block; font-size: 1.3em;">{{itemCount3}}</span></div><em> Registrations Completed</em><span></span></li>
              <li class="second"><div class="hdr-count"><span style="display: block; font-size: 1.3em;">{{itemCount4}}</span></div><em> Registrations Approved</em><span></span></li>
              <li class="currentone"><div class="hdr-count"><span style="display: block; font-size: 1.3em;">{{itemCount5}}</span></div><em> Onboardings In Progress</em><span></span></li>
              <li class="third"><div class="hdr-count"><span style="display: block; font-size: 1.3em;">{{itemCount6}}</span></div><em> Requests Completed <br />in the last 30 days</em></li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul class="reject"><li><div class="hdr-count rej"><span style="display: block; font-size: 1.3em;">{{itemCount7}}</span></div><em> Registrations <br />Rejected</em></li></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>


<div class="page-container">
  <nbc-alert></nbc-alert>
  <div class="container-fluid">
    <tabset class="content-tabs contact-tabs-lg" #tabset (contextmenu)="associateUrl()">
      <tab *ngFor="let t of tabs"
           [heading]="t.heading"
           (selectTab)="go(t.route)"
           [active]="t.active">
      </tab>
    </tabset>
    <router-outlet></router-outlet>
  </div>
</div>
