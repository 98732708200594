import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PayrollLayout } from "./payroll-layout.component";
import { PayrollComponent } from "./payroll.component";
import { SubmittedTimesheetsComponent } from "./submittedTimesheets/submittedTimesheets.component";
import { PayrollTimesheet } from "./timesheet/payroll-timesheet.component";
import { UpcomingEventSetup } from "./upcomingEvents/setup/upcoming-event-setup";
import { PayrollUpcomingEventsComponent } from "./upcomingEvents/upcomingEvents.component";

const routes: Routes = [
    //{ path: 'Payroll/upcomingevents/setup/:id', component: UpcomingEventSetup },
    //{ path: 'Payroll/timesheet/:id/:timecarddate', component: PayrollTimesheet },
    {
        path: "",
        component: PayrollLayout,
        children: [
            {
                path: "",
                component: PayrollComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "submitted",
                        pathMatch: "full",
                    },
                    {
                        path: "submitted",
                        component: SubmittedTimesheetsComponent,
                    },
                    {
                        path: "upcomingEvents",
                        component: PayrollUpcomingEventsComponent,
                    },
                ],
            },
            {
                path: "timesheet/:id/:timecarddate",
                component: PayrollTimesheet,
            },
            {
                path: "upcomingevents/setup/:id",
                component: UpcomingEventSetup,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PayrollRouterModule {
    static forChild() {
        return {
            ngModule: PayrollRouterModule,
            providers: [],
        };
    }
}
