import { Component, OnDestroy, OnInit } from "@angular/core";
import { error } from "console";
import * as _ from "lodash";
import { result } from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { MinimumWageService } from "src/app/Shared/Services/minimum-wage.service";
import { SubSink } from "subsink";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalMinimumWageComponent } from "./modal-minimum-wage.component";
import * as moment from "moment";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-minimum-wage",
    templateUrl: "./minimum-wage.component.html",
})
export class MinimumWageComponent implements OnInit, OnDestroy {
    constructor(
        private _minimumWageService: MinimumWageService,
        public _modalService: BsModalService,
        private log: NbcLoggerService
    ) {}

    private subs = new SubSink();
    totalRecords: any;
    bsModalRef?: BsModalRef;
    ShowModal = false;
    wage: any;
    allWages: any;
    stateList: any;
    resultsFound = false;
    queryContainer: any = { query: {} };
    showAlertMessage = false;
    onText = "Y";
    offText = "N";
    result: any;
    StateObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<boolean> = new Subject();
    searchQuery = "";
    EventSource: string[] = [];
    pageSizeList = [10, 25, 50, 100];
    clonedWages: any;
    begin: any;
    end: any;

    getAllWages() {
        this.subs.sink = this._minimumWageService.getAllWages().subscribe(
            (result) => {
                const pageSize = parseInt(this.queryContainer.query.pageSize);
                this.totalRecords = result.reference.recordCount;
                this.stateList = result.reference.states;
                this.queryContainer.query.totalRecords = result.reference.recordCount;
                this.begin =
                    (this.queryContainer.query.currentPage - 1) * pageSize;
                this.end = this.begin + pageSize;
                this.allWages = result.reference.minimumWageItems.slice(
                    this.begin,
                    this.end
                );
                this.clonedWages = result.reference.minimumWageItems;

                if (this.totalRecords > 0) {
                    this.resultsFound = true;
                } else {
                    this.resultsFound = false;
                }
            },
            (error) => {
                this.log.error(error);
                this.showAlertMessage = true;
            },
            () => {}
        );
    }

    openUpserMinimumWageModal(wage) {
        const wageObj = {
            state: "",
            city: "",
            startDate: "",
            endDate: "",
            wage: "",
            active: "Y",
        };

        const wageEdit = wage;

        const initialState: any = {
            self: this,
            wage: wage == null ? wageObj : _.cloneDeep(wageEdit),
            modalTitle: wage ? "Edit Wage" : "Add New Wage",
            isAdd: wage ? false : true,
            stateList: this.stateList,
            allWages: this.allWages,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalMinimumWageComponent,
            modalOptions
        );

        this.bsModalRef.content.onSync.subscribe((result) => {
            this.reset();
            this.getAllWages();
        });
    }

    getWageListingQuery(currentPage, pageSize, totalRecords) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 100,
            totalRecords: totalRecords || 100,
        };
    }

    updateStatus(wage) {
        if (!wage.active) wage.active = "N";
        this.subs.sink = this._minimumWageService.upsertWage(wage).subscribe(
            (result) => {
                this.result = result.reference;
                this.showAlertMessage = true;
                this.log.showSuccess("Wage status updated successfully");
                this.reset();
            },
            (err) => {
                this.log.error("Wage status update - got an error: ");
                this.showAlertMessage = true;
            },
            () => {
                this.log.trace("Wage status update - removing spinner");
            }
        );
    }

    searchTypeHead(val: string) {
        this.allWages = _.filter(this.clonedWages, (matchingwages) => {
            return matchingwages.state
                .toLowerCase()
                .includes(val.toLowerCase());
        });

        this.queryContainer.query.totalRecords = this.allWages.length;
        this.begin =
            (this.queryContainer.query.currentPage - 1) *
            this.queryContainer.query.pageSize;
        this.end = this.begin + this.queryContainer.query.pageSize;

        this.allWages = this.allWages.slice(this.begin, this.end);

        if (this.allWages.length > 0) {
            this.resultsFound = true;
        } else {
            this.resultsFound = false;
        }
    }

    typeaheadOnSelect(term: any): void {
        term = term.target.value.trim();
        this.EventSource = [];
        let states = [];
        let iteratedState = [];
        states = this.stateList[0];

        for (let i = 0; i < states.length; i++) {
            iteratedState = states[i];
            console.log(iteratedState);
            if (iteratedState["name"].toLowerCase().includes(term)) {
                this.EventSource[i] = iteratedState["name"];
            }
        }
        console.log(EventSource);
    }

    searchPage(pagesize) {
        this.queryContainer.query.pageSize = pagesize;
        this.search();
    }

    search() {
        if (this.searchQuery == "") {
            this.getAllWages();
        } else {
            this.searchTypeHead(this.searchQuery);
        }
    }

    reset() {
        this.queryContainer.query.currentPage = 1;
        this.queryContainer.query = this.getWageListingQuery(null, null, null);
        this.getAllWages();
        this.searchQuery = "";
    }

    ngOnInit() {
        this.queryContainer.query = this.getWageListingQuery(null, null, null);
        this.getAllWages();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
