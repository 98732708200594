<!--TODO: moving clone event to event folder. Need to remove the duplicated one.-->
<div class="modal-header">
  <h3 class="modal-title">Clone Event</h3></div>
  <button type="button" class="btn-close" (click)="closeMsg()"><span aria-hidden="true"></span><span class="sr-only">Close</span></button>
  <form novalidate>
    <div class="modal-body">
      <div class="form-group">
        <label style="font-size:medium">All positions will not be available right-away, event cloning is in progress...{{statusCounter}}</label>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" style="width:75px;" (click)="closeMsg()">OK</button>
      </div>
    </div>
    </form>
