<div class="tab-content ng-scope">
  <div class="container-fluid">
    <span us-spinner
          spinner-key="searchSpinner"></span>
    <div class="row">
      <div class="col-md-12 msg">
        <div>

        </div>
      </div>
    </div>
    <div class="row"
         authenticate-user="admin.remotesnetworkgroup.create">
      <div class="col-md-12 mainheader">
        <div class="header-bar clearfix">
          <p class="heading-title pull-left"></p>
          <button type="button"
                  class="btn btn-primary pull-right"
                  (click)="openUpsertModal(null)"
                  authenticate-user="admin.remotesnetworkgroup.create">
            + ADD NETWORK GROUP
          </button>
        </div>
      </div>
    </div>
    <form role="form"
          name="NetworkGroupSetupForm"
          novalidate>
      <div class="tab-page-content">
        <div class="row"
             *ngIf="resultsFound">
          <div class="col-md-8">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="dark-header">
                  <tr>

                    <th style="cursor:default;"
                        class="col-md-1 default-header">NETWORK GROUP</th>
                    <th style=" cursor:default;"
                        class="col-md-1 default-header">TIERED?</th>
                    <th style=" cursor:default;"
                        class="col-md-1 default-header"># OF TIERS</th>
                    <th style=" cursor:default;"
                        class="col-md-1 default-header">NETWORK LIST</th>
                    <th style=" cursor:default;"
                        class="col-md-1 default-header"></th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor=" let networkGroup of setups">
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{networkGroup.name}}
                    </td>

                    <td class="col-md-2"
                        disabled>
                      <bSwitch name="{{networkGroup.name}}"
                               [(ngModel)]="networkGroup.isNetworkGroupTiered"
                               [switch-on-color]="'success'"
                               [disabled]="true"
                               [switch-off-color]="'danger'"
                               [switch-handle-width]="30"
                               [switch-size]="'mini'"
                               (click)="updateStatus(jobInfo)">
                      </bSwitch>

                    </td>
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{networkGroup.numberOfTiers}}
                    </td>
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{networkGroup.flatNetworkList}}
                    </td>
                    <td class="col-md-1 text-end">
                      <span class="edit-row">
                        <a href="javascript:void(0)"
                           class="btn btn-primary"
                           (click)="openUpsertModal(networkGroup)"
                           authenticate-user="admin.remotesnetwork.edit"
                           authenticate-command="disabled">
                          <i class="fa-solid fa-pencil fa-lg"></i>
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p *ngIf="!resultsFound">No Results.</p>
      </div>
    </form>
  </div>
</div>

