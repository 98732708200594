import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, Subject, throwError } from "rxjs";
import { IEventQuery } from "./remotesquery.service";
import { catchError, map } from "rxjs/operators";
import * as _ from "lodash";
//import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
    providedIn: "root",
})
export class EventService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    //@LiquidCache('getEventStatusList')
    getEventStatusList(): Observable<any> {
        this.log.debug("get a list of event statuses");
        return this.http.get(this._apiroot.baseUrl + "events/status", {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }
    getEventTimesheetDateBoundaries(eventId: any) {
        this.log.trace(
            "get event timesheet date boundaries by event: " + eventId
        );
        return this.http.get(
            this._apiroot.baseUrl +
                "event/" +
                eventId +
                "/EventTimesheetDateBoundaries",
            {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                }),
            }
        );
    }

    getEventManagerList(eventManagers: any): Observable<any> {
        this.log.debug("get a list of event managers");
        return this.http.get(this._apiroot.baseUrl + "eventManager", {
            params: { profileTypeIds: eventManagers },
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    search(eventQuery: any): Observable<any> {
        this.log.debug(
            "calling search for object" + JSON.stringify(eventQuery)
        );
        return this.http
            .post(this._apiroot.baseUrl + "events/search/", eventQuery)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getEvent(eventId: number): Observable<any> {
        this.log.trace("get event");
        const relativeUrl = "events/" + eventId;
        return this.http.get(this._apiroot.baseUrl + relativeUrl, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    getEventInfoDetails(eventId: number): Observable<any> {
        this.log.trace("get event");
        const relativeUrl = "events/" + eventId + "/Info";
        return this.http.get(this._apiroot.baseUrl + relativeUrl, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    getEventSummary(eventId: number): Observable<any> {
        this.log.trace("get event summary");
        const relativeUrl = "events/" + eventId + "/summary";
        return this.http.get(this._apiroot.baseUrl + relativeUrl, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    createEvent(event: any): Observable<any> {
        this.log.debug("creating an event");
        return this.http.post(this._apiroot.baseUrl + "events", event).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    updateEvent(event: any): Observable<any> {
        this.log.trace("updating existing event");
        return this.http
            .put(this._apiroot.baseUrl + "events/" + event.id, event)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    save(event: any) {
        if (!_.isUndefined(event.id)) {
            return this.updateEvent(event);
        }
        return this.createEvent(event);
    }

    getEventDashboardSummary(eventId: number): Observable<any> {
        this.log.trace("get event dashboard summary");
        const relativeUrl = "events/" + eventId + "/dashboardsummary";
        return this.http.get(this._apiroot.baseUrl + relativeUrl, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    getRecentActivities(eventId: number): Observable<any> {
        this.log.trace("get recent activities");
        const relativeUrl = "events/" + eventId + "/recentActivity";
        return this.http.get(this._apiroot.baseUrl + relativeUrl, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        });
    }

    autocompleteUsingSeason(seasonId: any, name: any): Observable<any> {
        this.log.trace("filtering events");

        return this.http
            .get<any>(
                this._apiroot.baseUrl +
                    "events/" +
                    String(seasonId) +
                    "/search?name=" +
                    encodeURIComponent(name)
            )
            .pipe(map((data: any) => (data && data.reference) || []));
    }

    getEventInfo(eventId: any): Observable<any> {
        this.log.trace("get event");
        return this.http.get(this._apiroot.baseUrl + "events/" + eventId).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    favorite(favoriteQuery: any): Observable<any> {
        this.log.debug("get event");
        return this.http
            .post(this._apiroot.baseUrl + "events/favorite", favoriteQuery)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    unfavorite(favoriteQuery: any): Observable<any> {
        return this.http
            .delete(
                this._apiroot.baseUrl +
                    "events/" +
                    favoriteQuery.eventId +
                    "/unfavorite/" +
                    favoriteQuery.key,
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                    }),
                }
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getTieredRateCardForPostion(
        networkGroupId: any,
        tierId: any,
        useOldRates: any,
        eventId: any
    ): Observable<any> {
        this.log.trace("get tiered data card details data: " + tierId);
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "tierratecards/getTierPositions/" +
                    networkGroupId +
                    "/" +
                    tierId +
                    "/" +
                    useOldRates +
                    "/" +
                    eventId
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    bulkUpdateEventPositions(eventId: any, positions: any): Observable<any> {
        this.log.trace("bulk update event positions");
        return this.http
            .post(
                this._apiroot.baseUrl + "events/" + eventId + "/positions",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventPositionEmployeeType(
        eventId: any,
        positions: any
    ): Observable<any> {
        this.log.trace("saving employee types on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/employeeType",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventPositionTravel(eventId: any, positions: any): Observable<any> {
        this.log.trace("saving employee types on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/travel",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventPositionRateCode(eventId: any, positions: any): Observable<any> {
        this.log.trace("saving employee types on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/rateCode",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    savePositionApprovedDate(eventId: any, positions: any): Observable<any> {
        this.log.trace("saving position approved date on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/positionApprovedDate",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventPositionWorkSchedule(
        eventId: any,
        positions: any
    ): Observable<any> {
        this.log.trace("saving work schedule on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/workSchedule",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    updateEventPositionContact(eventId: any, positions: any): Observable<any> {
        this.log.trace("saving work schedule on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/resource/contact",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    deleteBackupContact(eventId: any, position: any): Observable<any> {
        this.log.trace("saving work schedule on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/crewBackupContact/delete",
                position
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    deleteEventPosition(eventId: any, position: any): Observable<any> {
        this.log.trace("delete event position");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/delete",
                position
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    addUpdatePositions(
        eventId: any,
        position: any,
        contact: any,
        contactType: any
    ): Observable<any> {
        this.log.trace("add or update event positions");
        position[contactType.toLowerCase() + "Contact"] = contact;
        return this.updateEventPositionContact(eventId, [position]);
    }

    addEventPosition(eventId: any, position: any): Observable<any> {
        this.log.trace("add event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/create",
                position
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    checkInactiveMaster(eventId: any): Observable<any> {
        this.log.trace("check inactive master data in event: " + eventId);
        return this.http.get(
            this._apiroot.baseUrl + "events/" + eventId + "/checkinactivemaster"
        );
    }

    copyEventPositions(
        sourceEventId: any,
        targetEventId: any,
        copyContact: any,
        copyCrewWorkSchedule: any
    ): Observable<any> {
        this.log.trace("copyEventPositions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/copyPositionFrom/" +
                    sourceEventId +
                    "/to/" +
                    targetEventId +
                    "/withContact/" +
                    copyContact +
                    "/" +
                    copyCrewWorkSchedule,
                null
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveNote(eventId: any, note: any): Observable<any> {
        this.log.trace("saving note for eventId " + eventId);
        return this.http
            .put(this._apiroot.baseUrl + "events/" + eventId + "/note", {
                eventId: eventId,
                note: note,
            })
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    saveEventPositionComment(eventId: any, positions: any): Observable<any> {
        this.log.trace("saving travel on event positions");
        return this.http
            .post(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/positions/comment",
                positions
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    cloneEvent(cloneEventSettings: any): Observable<any> {
        this.log.trace("saving travel on event positions");
        return this.http
            .post(this._apiroot.baseUrl + "events/clone/", cloneEventSettings)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    searchBySeasonId(seasonId: any): Observable<any> {
        this.log.trace("searchBySeasonId" + JSON.stringify(seasonId));
        return this.http
            .get(this._apiroot.baseUrl + "seasons/" + seasonId + "/events")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    removeEventFromSeason(event: any): Observable<any> {
        this.log.trace(
            "removing an event from a season." + JSON.stringify(event)
        );
        return this.http
            .delete(this._apiroot.baseUrl + "events/" + event + "/season")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getTimesheetReport(
        eventId: any,
        date: any,
        contactIds: any,
        reportType: any
    ): Observable<any> {
        this.log.trace("getTimesheetReport for type " + reportType);

        return this.http.post(
            "/EventExport/" +
                eventId +
                "/timesheets/" +
                date +
                "/" +
                reportType,
            contactIds,
            {
                responseType: "arraybuffer",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
            }
        );
    }

    getEventCrewChange(eventId: any, noOfDays: any): Observable<any> {
        this.log.trace("get event");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "events/" +
                    eventId +
                    "/crewChange/" +
                    noOfDays
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
