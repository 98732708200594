import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HelpComponent } from "../help.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";

const routes: Routes = [
    {
        path: "",
        component: HelpComponent,
    },
    {
        path: "ContactUs",
        component: ContactUsComponent,
    },
    {
        path: "Thanks",
        component: ThankYouComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpRouterModule {
    static forChild() {
        return {
            ngModule: HelpRouterModule,
            providers: [],
        };
    }
}
