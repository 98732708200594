<div class="container">
  <div class="content top-margin">


    <div class="container">
      <div class="row">

        <!-- Left side menu -->
        <!-- end left side menu -->
        <div class="col-md-1"></div>
        <div class="col-md-10">

          <!-- Form Panel -->
          <div class="panel contact-help">
            <div class="panel-heading">
              <h6 class="panel-title">YOUR REQUEST HAS BEEN SUBMITTED SUCCESSFULLY</h6>
            </div>
            <div class="panel-body">
              One of our NBCU Help Desk Technicians will follow up with you shortly. If this is urgent
              please call our support line at 1-855-NBCU-TECH.

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
