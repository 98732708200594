import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-remote-staffing-summary-report",
    templateUrl: "./remote-staffing-summary-report.component.html",
})
export class RemoteStaffingSummaryReportComponent implements OnInit {
    constructor(
        private _reportService: ReportService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}
    data: any;
    reportName;
    // = modelValue;
    showAlertMessage;
    eventTerm: any;
    seasonTerm: any;
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    loadObserver$: Observable<any[]> | undefined;
    seasonObserver$: Observable<any[]> | undefined;
    seasons: any[] = [];
    remoteStaffingSummaryReportInfo;
    remoteStaffingSummaryReport;
    alerts;
    eventId = 13;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    userName = this.user.firstName + " " + this.user.lastName;
    events: any[] = [];
    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }

    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }

    loadSeasons() {
        this.showAlertMessage = false;
        this.seasonObserver$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.seasonTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._reportService.autoComplete(query);
            })
        );
    }

    loadEvents() {
        this.showAlertMessage = false;
        this.loadObserver$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.eventTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._reportService.autoCompleteByOnlyEvent(
                    query,
                    this.userName
                );
            })
        );
    }
    removeTag(name, event) {
        this.events.splice(this.events.indexOf(event), 1);
    }
    removeTagSeason(name, item) {
        this.seasons.splice(this.seasons.indexOf(event), 1);
    }
    isOneFieldEntered() {
        if (this.events.length === 0) {
            return false;
        }
        return true;
    }
    clearEvent() {
        this.events = [];
        this.eventTerm = "";
    }
    clearSeason() {
        this.seasons = [];
        this.seasonTerm = "";
    }
    exportRemoteStaffingSummaryReport() {
        const tempStaffingSummaryReportInfo: any = {};

        tempStaffingSummaryReportInfo.seasons = this.seasons;
        tempStaffingSummaryReportInfo.events = this.events;
        // tempStaffingSummaryReportInfo.SSO = this.currentUser.id;

        //if ($scope.isOneFieldEntered()) {
        // usSpinnerService.spin("genericSpinner");
        this.showAlertMessage = false;
        this.isDownload = true;

        this._reportService
            .exportRemoteStaffingSummaryReport(
                this.eventId,
                tempStaffingSummaryReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        // $scope.staffingReport = data;
                        // this.remoteStaffingSummaryReport = this.getCriteriaData;
                        //reportService.exportStaffingReport($scope.eventId, $scope.temppayRateReportInfo,$scope.userName).success(function (result) {
                        const fileName = "Remotes-Staffing-Summary _";
                        this.showAlertMessage = false;
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                        //var blob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        //var fileURL = URL.createObjectURL(blob);
                        // $scope.isSaved = data.reference;

                        //window.open(fileURL);
                    }
                },
                error: (data) => {
                    // log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                    this.log.showModalError("Error Occurred");
                },
            });

        // .finally(function () {
        //     usSpinnerService.stop("genericSpinner");
        //     log.trace("removing spinner");
        //     usSpinnerService.stop("genericSpinner");
        // });

        //}
        //else {
        //    if (!$scope.isOneFieldEntered()) {
        //        $scope.showAlertMessage = true;
        //        $scope.alerts = [{ type: "danger", msg: "Please fill in at least one event in the search criterion." }];
        //    }
        //}
    }

    onSeasonSelected($event) {
        if (this.seasons.indexOf($event.item) == -1) {
            this.seasons.push($event.item);
        }

        this.seasonTerm = "";
    }
    onEventSelected($event) {
        if (this.events.indexOf($event.item) == -1) {
            this.events.push($event.item);
        }
        this.eventTerm = "";
    }
    changeTypeaheadLoading($event) {}
    setSearchTerm($event) {
        this.eventTerm = $event;
    }
    setSearchSeasonTerm($event) {
        this.seasonTerm = $event;
    }
    emitEvent($event) {
        this.events = $event;
    }
    emitSeason($event) {
        this.seasons = $event;

        this.log.trace(this.seasons);
        if (_.isEmpty(this.seasons)) {
            this.seasons = [];
        }
        this.seasonTerm = "";
    }
    ngOnInit(): void {
        this.loadEvents();
        this.loadSeasons();
        this.remoteStaffingSummaryReportInfo = {};

        //$scope.payRateReportInfo.events = null;
        this.remoteStaffingSummaryReportInfo.events = [];
        this.remoteStaffingSummaryReportInfo.seasons = [];
    }
}
