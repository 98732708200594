import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

 
@Injectable({ providedIn: 'root' })
export class BaseUrlHelperService{
  public baseUrl: string;
  public basePath: string;
  constructor(private http: HttpClient) {
    this.baseUrl = window.location.origin + '/api/';
    this.basePath = window.location.origin + '/Remotes/';
  }
}
