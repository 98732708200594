import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private log: NGXLogger, private http: HttpClient, private _apiroot: BaseUrlHelperService) { }
  loadContactusData(userId, eventId): Observable<any> {
    this.log.trace("Calling load contact us data");

    return this.http.get(this._apiroot.baseUrl + "help/contactus/" + userId + "/" + eventId + "/GetHelpemail").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };

  sendContactusData(postData): Observable<any> {
    return this.http.post(this._apiroot.baseUrl + "help/Helpemail", postData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;   
  };

  postFile(data,FileTimeStamp){
    return this.http.post(this._apiroot.baseUrl + FileTimeStamp + "help/UploadAttachment", data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };
}
