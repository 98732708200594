<div>
    <div class="modal-header">
        <h3 class="modal-title">{{reportName}}</h3>
      <button type="button"
                class="btn-close"
                (click)="cancel()"
                aria-hidden="true"> </button>
    </div> 
    <div *ngIf="showAlertMessage">
        <div class="pt-3">
            <nbc-modal-alert></nbc-modal-alert>
        </div>
      </div>
    <div class="modal-body">
      <div class="grid-table">
        <!--Date Range-->
        <div class="grid-content-row" *ngIf="showDateRangeRowAll" [ngStyle]="{'pointer-events':events.length>=1?'none':'auto'}">
          <div class="grid-col report-name">
            <span *ngIf="(((crewRateReportInfo.dateRangeStartDate == null || crewRateReportInfo.dateRangeStartDate == '') && !(events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate != null && crewRateReportInfo.dateRangeStartDate != '' && !(events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate != null && crewRateReportInfo.dateRangeStartDate != '' && (events?.length > 0)))" style="color:red;">*</span>Date Range
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('DateRangeRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showDateRangeEditRow">
          <div class="grid-col report-name">
            <span style="color:red;">*</span>Date Range
          </div>
          <div class="grid-col input-info">
            <div class="input-group input-group-set max-input"
                 dropdown
                 is-open="dateRangeFilter"
                 on-toggle="onToggleDateRangeDatepicker()"
                 required>
              <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
              <input type="text"
                     name="arrivalDate"
                     placeholder="Select Date Range"
                     class="form-control"
                     bsDaterangepicker
                     required
                     readonly
                     [(ngModel)]="bsInlineRangeValue"
                     #drp="bsDaterangepicker"
                     (bsValueChange)="onToggleFilterDatepicker($event)"
                     [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('DateRangeRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showDateRangeTagRow">
          <div class="grid-col report-name">
            <span *ngIf="(((crewRateReportInfo.dateRangeStartDate == null || crewRateReportInfo.dateRangeStartDate == '') && !(events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate != null && crewRateReportInfo.dateRangeStartDate != '' && !(events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate != null && crewRateReportInfo.dateRangeStartDate != '' && (events?.length > 0)))" style="color:red;">*</span>Date Range
          </div>
          <div class="grid-col">
            <div class="col-md-4">

              <span class="filter-tag">
                {{
crewRateReportInfo.dateRangeStartDate |
                            date:'MM/dd/yyyy'
                }}-{{crewRateReportInfo.dateRangeEndDate | date:'MM/dd/yyyy'}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeDateTag('DateRangeRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('DateRangeRow')"></i>
            </a>
          </div>
        </div>
        <!--SSO-->

        <div class="grid-content-row"
             *ngIf="!showSSOsRow">
          <div class="grid-col report-name">
            SSO
          </div>
          <div class="grid-col"
               *ngIf="!showSSOsRowAll">
            {{crewRateReport.SSO}}
          </div>
          <div class="grid-col"
               *ngIf="showSSOsRowAll">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('SSOsRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showSSOsRow">
          <div class="grid-col report-name">
            SSO
          </div>
          <div class="grid-col input info">
            <div class="row">
              <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                <div class="form-group">
                  <input class="form-control"
                         type="text"
                         maxlength="9"
                         [(ngModel)]="crewRateReport.SSO"
                         appInputRestriction="numberOnly" />
                </div>
              </div>

            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('SSOsRow')"></i>
            </a>
          </div>
        </div>
        <!--Event Type-->

        <div class="grid-content-row"
             *ngIf="showEventTypesRowAll">
          <div class="grid-col report-name">
            Event Type
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('EventTypesRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showEventTypesEditRow">
          <div class="grid-col report-name">
            Event Type
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="crewRateReport.eventType"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedEventType"
                                      [placeholder]="'Select Event Type'"
                                      (nbcOnOptionSelect)="onItemSelectEventType($event)">
                    </nbc-multi-select>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('EventTypesRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showEventTypesTagRow">
          <div class="grid-col report-name">
            Event Type
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let eventType of selectedEventType">
              <span class="filter-tag">
                {{eventType.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(eventType,'EventTypesRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('EventTypesRow')"></i>
            </a>
          </div>
        </div>

        <!--Position Name-->

        <div class="grid-content-row"
             *ngIf="showPositionNameRowAll">
          <div class="grid-col report-name">
            Position Name
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('PositionFunctionRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row highlight"
             *ngIf="showPositionNameEditRow">
          <div class="grid-col report-name">
            Position Name
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="crewRateReport.positionFunction"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedPositionFunction"
                                      [placeholder]="'Select Position Name'"
                                      (nbcOnOptionSelect)="onItemSelectPositionName($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('PositionFunctionRow')"></i>
            </a>
          </div>
        </div>
        <div class="grid-content-row"
             *ngIf="showPositionNameTagRow">
          <div class="grid-col report-name">
            Position Name
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let position of selectedPositionFunction">
              <span class="filter-tag">
                {{position.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(position,'PositionFunctionRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('PositionFunctionRow')"></i>
            </a>
          </div>
        </div>

        <!--Category-->

        <div class="grid-content-row"
             *ngIf="showCategoryRowAll">
          <div class="grid-col report-name">
            Category
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('CrewsRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row highlight"
             *ngIf="showCategoryEditRow">
          <div class="grid-col report-name">
            Category
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="categoryList"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedCategory"
                                      [placeholder]="'Select Category'"
                                      (nbcOnOptionSelect)="onItemSelectCategory($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('CrewsRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row"
             *ngIf="showCategoryTagRow">
          <div class="grid-col report-name">
            Category
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let category of selectedCategory">
              <span class="filter-tag">
                {{category.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(category,'CrewsRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('CrewsRow')"></i>
            </a>
          </div>
        </div>



        <!--Employee Type-->

        <div class="grid-content-row"
             *ngIf="showEmployeeTypeRowAll">
          <div class="grid-col report-name">
            Employee Type
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('EmployeeTypeRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row highlight"
             *ngIf="showEmployeeTypeEditRow">
          <div class="grid-col report-name">
            Employee Type
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="crewRateReport.employeeType"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedEmployeeType"
                                      [placeholder]="'Select Employee Type'"
                                      (nbcOnOptionSelect)="onItemSelectEmployeeType($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('EmployeeTypeRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row"
             *ngIf="showEmployeeTypeTagRow">
          <div class="grid-col report-name">
            Employee Type
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let employee of selectedEmployeeType">
              <span class="filter-tag">
                {{employee.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(employee,'EmployeeTypeRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('EmployeeTypeRow')"></i>
            </a>
          </div>
        </div>

        <!--Manpower Contact-->

        <div class="grid-content-row"
             *ngIf="showManpowerRowAll">
          <div class="grid-col report-name">
            Manpower Contact
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('ManpowerRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row highlight"
             *ngIf="showManpowerEditRow">
          <div class="grid-col report-name">
            Manpower Contact
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="crewRateReport.manPower"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedManPowerList"
                                      [placeholder]="'Select Manpower Contact'"
                                      (nbcOnOptionSelect)="onItemSelectManpower($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('ManpowerRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row"
             *ngIf="showManpowerTagRow">
          <div class="grid-col report-name">
            Manpower Contact
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let manpower of selectedManPowerList">
              <span class="filter-tag">
                {{manpower.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(manpower,'ManpowerRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('ManpowerRow')"></i>
            </a>
          </div>
        </div>


        <!--Homebase PM-->
        <div class="grid-content-row"
             *ngIf="showhomebasePMRowAll">
          <div class="grid-col report-name">
            Homebase PM
          </div>
          <div class="grid-col">
            All
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('ManagersRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row highlight"
             *ngIf="showhomebasePMEditRow">
          <div class="grid-col report-name">
            Homebase PM
          </div>
          <div class="grid-col">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <nbc-multi-select [source]="crewRateReport.homebasePM"
                                      value="id"
                                      display="name"
                                      [(selectedValues)]="selectedHomebasePM"
                                      [placeholder]="'Select Homebase PM'"
                                      (nbcOnOptionSelect)="onItemSelectHomebasePM($event)">
                    </nbc-multi-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-check"
                 (click)="confirmSelection('ManagersRow')"></i>
            </a>
          </div>
        </div>

        <div class="grid-content-row"
             *ngIf="showhomebasePMTagRow">
          <div class="grid-col report-name">
            Homebase PM
          </div>
          <div class="grid-col">
            <div class="col-md-6"
                 *ngFor="let homebasepm of selectedHomebasePM">
              <span class="filter-tag">
                {{homebasepm.name}} <a>
                  <i class="fa fa-times-circle"
                     (click)="removeTag(homebasepm,'ManagersRow')"></i>
                </a>
              </span>
            </div>
          </div>
          <div class="grid-col report-edit">
            <a>
              <i class="fa fa-pencil"
                 (click)="editRow('ManagersRow')"></i>
            </a>
          </div>
        </div>

        <!--Event Name-->

        <div class="grid-content-row" [ngStyle]="{'pointer-events':crewRateReportInfo?.dateRangeStartDate?'none':'auto'}">
          <div class="grid-col report-name">
            <span *ngIf="(((crewRateReportInfo.dateRangeStartDate == null || crewRateReportInfo.dateRangeStartDate == '') && !(events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate == null || crewRateReportInfo.dateRangeStartDate == '' && (events?.length > 0)) || 
              (crewRateReportInfo.dateRangeStartDate != null && crewRateReportInfo.dateRangeStartDate != '' && (events?.length > 0)))" style="color:red;">*</span>
            Event Name 
          </div>
          <div class="grid-col">
            <nbc-tags [(searchTerm)]="addEventTerm"
                      [searchTermObservable$]="suggestions$"
                      [tagDisplay]="'name'"
                      [tagId]="'name'"
                      [tagName]="'name'"
                      (tagChange)="setSearchEventTerm($event)"
                      placeholder="Add Event"
                      [tags]="events"
                      (tagOnRemove)="onRemoveEventTag($event)"
                      (nbcOnOptionSelect)="onEventSelected($event)"></nbc-tags>
          </div>
          <div class="grid-col report-edit">
            <button class="btn btn-default"
                    (click)="clear()">
              Clear
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                (click)="exportCrewRateReport()"
                value="disable"
                [disabled]="isExport">Export</button>
    </div>
</div>
<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
