
<div class="box-dash border-dash tranparent">
  <div class="box-dash-hdr-bar backgrounddash headerdiv">
    <div class="float-end btn-fullscreen hidden-sm hidden-xs maximizesign">
      <a title="Fullscreen" (click)="fullScreenmode()">
        <i style="color: white; font-size: 22px; margin-top: 5px; margin-left: 10px;" [ngClass]="!fullscreenMode ? 'fa-window-restore' : 'fa-window-maximize'" class="fa-regular"></i>

      </a>
    </div>
    <h2 class="box-dash-hdr fontcolourdash"><i class="fa fa-stop-circle-o" aria-hidden="true" style="margin-left:-17px"></i> Events: Recently Viewed</h2>
  </div>
  <div class="box-dash-inner-dflt">
    <!--<div class="table-responsive">-->
    <div class="row">
      <div class="col-md-12">
        <!--Grid Header-->
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
               Name
              </th>
              <th>Location</th>
              <th>Date Viewed</th>
              <!--<th>
                <a (click)="sortByColumn('dateviewed');" class="text-nowrap" style="color:black">
                  Date Viewed
                  <span class="th-sort">
                    <span *ngIf="sort.orderByField === 'dateviewed'">
                      <i class="fa" [ngClass]="{'fa-caret-up': !sort.reverseSort, 'fa-caret-down': sort.reverseSort}"></i>
                    </span>
                  </span>
                </a>
              </th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of recentlyViewed">
              <td><a class="btn-link" href="/Events/Event/{{item.id}}/Summary" target="_blank">{{item.name}}</a></td>
              <td>{{item.additionalData}}</td>
              <td>{{item.dateViewed}}</td>
            </tr>
          </tbody>
        </table>

        <!--<p>Event Recently Viewed!</p>-->
      </div>
      <!--<div class="col-sm-6">
      </div>-->
    </div>
  </div>
</div>
