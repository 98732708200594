
<div class="tab-content ng-scope">
  <div authenticate-user="Admin.EventType.Retrieve"
       class="page-container"
       style="padding-left:0;padding-right:0;">
    <div class="container-fluid">

      <div class="row"
           style="padding-top:10px;">
        <div class="col-md-12 d-flex">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="control-label"
                     style="display:inline;">Event Type Name</label>

              <input type="text"
                     class="form-control"
                     style="width:50%;display:inline;margin-left:5%;"
                     [(ngModel)]="searchQuery"
                     id="name"
                     name="name"
                     maxlength="36"
                     [typeahead]="EventSource"
                     [typeaheadMinLength]="1"
                     [typeaheadWaitMs]=200
                     (keyup)="typeaheadOnSelect($event)"
                     autocomplete="off"
                     placeholder=''>


            </div>
          </div>
          <div class="col-sm-3">
            <div class="filter-actions">
              <button class="btn btn-secondary"
                      (click)="searchTypeHead('textSearch');"
                      tabindex="8">
                Search
              </button>
              <button class="btn btn-default"
                      (click)="reset();"
                      tabindex="9">
                Reset
              </button>
            </div>
          </div>
          <div class="col-sm-6">
            <button type="button"
                    class="btn btn-primary pull-right"
                    (click)="openUpsertModal()"
                    authenticate-user="admin.eventtype.create">
              + ADD NEW Event Type
            </button>
          </div>

        </div>
      </div>
      <div class="tab-page-content">
        <div style="padding-top:10px;"
             class="row view-options"
             *ngIf="resultsFound">
          <div class="col-md-4">
            <div class="form-inline form-sort-options">
              <div class="form-group eventType">
                <nbc-page-size [source]='pageSizeList'
                [model]="queryContainer.query.pageSize"
                (nbcValueChange)="searchPage($event)"></nbc-page-size>

              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group venuestat d-flex">
              <label class="pagination-results ms-auto">
                <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                  currentPage="{{queryContainer.query.currentPage}}}"
                                  totalRecords="{{queryContainer.query.totalRecords}}"></pagination-label>


              </label>


              <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                              itemsPerPage="{{queryContainer.query.pageSize}}"
                              [(currentPage)]="queryContainer.query.currentPage"
                              (pageChanged)="search()"></nbc-pagination>

            </div>
          </div>
        </div>
        <div class="row"
             *ngIf="resultsFound">
          <div class="col-md-12">
            <div class=" table-responsive">

              <table class="table table-striped">
                <thead class="dark-header">
                  <tr>
                    <th style="cursor:default;"
                        class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('name');$event.preventDefault();">
                        Event Type Name
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="(queryContainer.query.sortByColumn != 'name' && queryContainer.query.sortByColumn != '-name') "></i>
                          <span *ngIf="(queryContainer.query.sortByColumn === 'name' || queryContainer.query.sortByColumn === '-name' )">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>
                    <th style="cursor:default;"
                        class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('id');$event.preventDefault();">
                        Event Type ID
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="(queryContainer.query.sortByColumn !== 'id' && queryContainer.query.sortByColumn !== '-id')"></i>
                          <span *ngIf="(queryContainer.query.sortByColumn === 'id' || queryContainer.query.sortByColumn === '-id')">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>

                      </a>
                    </th>

                    <th style="cursor:default;text-align:center"
                        class="col-md-3 default-header">GRAPHIC</th>
                    <th style=" cursor:default;"
                        class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('hasTeam');$event.preventDefault();">
                        Team Sport ?
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="(queryContainer.query.sortByColumn !== 'hasTeam' )"></i>
                          <span *ngIf="(queryContainer.query.sortByColumn === 'hasTeam') ">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>
                    <th style=" cursor:default;"
                        class="col-md-2">
                      <a href="javascript:void(0)"
                         (click)="sortByColumn('hasFlex');$event.preventDefault();">
                        Flex ?
                        <span class="th-sort">
                          <i class="fa fa-sort"
                             *ngIf="(queryContainer.query.sortByColumn !== 'hasFlex' )"></i>
                          <span *ngIf="(queryContainer.query.sortByColumn === 'hasFlex' )">
                            <i *ngIf="!queryContainer.query.reverseSort"
                               class="fa fa-caret-up"></i>
                            <i *ngIf="queryContainer.query.reverseSort"
                               class="fa fa-caret-down"></i>
                          </span>
                        </span>
                      </a>
                    </th>
                    <th style=" cursor:default;"
                        class="col-md-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let eventType of eventTypes">
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{eventType.name}}
                    </td>
                    <td class="col-md-2"
                        style="-ms-word-break: break-all; word-break: break-all;">
                      {{eventType.id}}
                    </td>
                    <td class="col-md-3 event-type float-none"
                        style="-ms-word-break: break-all; word-break: break-all;">

                      <img src="/assets/image/{{eventType.type}}/{{eventType.imageId || 0}}?q={{imageNoCacheQuery}}" />
                    <td class="col-md-2">
                      <input type="checkbox"
                             disabled="disabled"
                             [(ngModel)]="eventType.hasTeam" />

                    </td>
                    <td class="col-md-2">
                      <input type="checkbox"
                             disabled="disabled"
                             [(ngModel)]="eventType.hasFlex" />

                    </td>

                    <td class="col-md-1 text-end">
                      <span class="edit-row">
                        <a href="javascript:void(0)"
                           class="btn btn-primary"
                           (click)="openUpsertModal(eventType)"
                           authenticate-user="admin.eventtype.edit"
                           authenticate-command="disabled"><i class="fa-solid fa-pencil fa-lg"></i></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <p *ngIf="!resultsFound">No Results.</p>
    </div>
  </div>
</div>
