<pagination #pagination
            [totalItems]="totalItems"
            [ngModel]="currentPage"
            [itemsPerPage]="itemsPerPage"
            [maxSize]="maxSize"
            (pageChanged)="pageChangedClicked($event)"
            class="pagination"
            [boundaryLinks]="true"
            [directionLinks]="true"
            [rotate]="rotate"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate"
            [customPreviousTemplate]="prevTemplate"
            [customNextTemplate]="nextTemplate"></pagination>
 


<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
  <!--<span ng-if="boundaryLinks"
        style="font-family: 'fontawesome'"
        class="pagination-first ">&#xf04a;</span>-->
  <span ng-if="boundaryLinks"
        style="font-family: 'fontawesome'"
        class="pagination-first ">
    <i class="fa fa-step-backward"></i>
  </span>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
  <span ng-if="boundaryLinks"
        style="font-family: 'fontawesome'"
        class="pagination-last">
    <i class="fa fa-step-forward"></i>
  </span>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <span ng-if="directionLinks"
        style="font-family: 'fontawesome'"
        class="pagination-prev">
    <i class="fa fa-caret-left"></i>
  </span>
</ng-template>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <span ng-if="directionLinks"
        style="font-family: 'fontawesome'"
        class="pagination-next">
    <i class="fa fa-caret-right"></i>
  </span>
</ng-template> 
