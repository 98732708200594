<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="!activityEditMode && !deleteModalActive">Add Activity</span>
    <span *ngIf="activityEditMode && !deleteModalActive">Edit Activity</span>
    <span *ngIf="deleteModalActive">Delete Activity</span>
  </h4><button type="button" class="btn-close" (click)="closeModal()"> </button>
</div>
<div class="modal-body">
  <span us-spinner spinner-key="saveSpinner"></span>
  <div [hidden]="deleteModalActive">

    <form addActivityForm="ngForm" novalidate>
      <div class="form-horizontal">
        <div class="form-group" [ngClass]="">
          <label for="activityTitle" class="col-md-3 control-label">Title</label>
          <div class="col-md-9">
            <input type="text"
                   name="activityTitle"
                   appInputRestriction="restrictChars"
                   class="form-control"
                   [(ngModel)]="model.title"
                   #fieldTitle="ngModel"
                   required
                   maxlength="30"
                   authenticate-required="true"
                   authenticate-user="contact.workschedule.update,contactworkschedule.addactivity.update"
                   authenticate-command="disabled" />
            <span *ngIf="fieldTitle.invalid && submitted" class="control-label control-label-error">Please enter a title.</span>
          </div>
        </div>
        <div class="form-group" [ngClass]="">
          <label for="activityLocation" class="col-md-3 control-label">Location</label>
          <div class="col-md-9">
            <input type="text"
                   maxlength="50"
                   name="activityLocation"
                   appInputRestriction="restrictChars"
                   class="form-control"
                   [(ngModel)]="model.location"
                   #fieldLocation="ngModel"
                   required
                   authenticate-required="true"
                   authenticate-user="contact.workschedule.update,ContactWorkSchedule.AddActivity.Update"
                   authenticate-command="disabled">
            <span *ngIf="fieldLocation.invalid && submitted" class="control-label control-label-error">Please enter a location.</span>
          </div>
        </div>
        <div class="form-group" ng-class="">
          <label class="col-md-3 control-label">Date/Time</label>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-2 text-right">
                <label class="control-label">From:</label>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <div class="input-group input-group-set dropdown" dropdown>
                    <span class="input-group-addon dropdown-toggle" dropdown-toggle>
                      <i class="fa fa-calendar"></i>
                    </span>
                    <input type="text"
                           name="activityStartDate"
                           class="form-control dropdown-toggle control-label"
                           style="border-right-color:#ffc800;"
                           dropdown-toggle
                           authenticate-required="true"
                           readonly="readonly"
                           [(ngModel)]="model.startDate"
                           authenticate-user="contact.workschedule.update,ContactWorkSchedule.AddActivity.Update"
                           authenticate-command="disabled"
                           bsDatepicker
                           [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'MMM d, YYYY h:mm A', showWeekNumbers: false }" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 text-right">
                <label class="control-label">To:</label>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <div class="input-group input-group-set dropdown" dropdown>
                    <span class="input-group-addon dropdown-toggle"
                          dropdown-toggle>
                      <i class="fa fa-calendar"></i>
                    </span>
                    <input type="text"
                           name="activityEndDate"
                           class="form-control dropdown-toggle control-label"
                           style="border-right-color:#ffc800;"
                           dropdown-toggle authenticate-required="true"
                           readonly="readonly"
                           [(ngModel)]="model.endDate"
                           authenticate-user="contact.workschedule.update,ContactWorkSchedule.Update"
                           authenticate-command="disabled"
                           bsDatepicker
                           [bsConfig]="{ adaptivePosition: true }" />
                  </div>
                  <span *ngIf="model.endDate < model.startDate && submitted"
                        class="control-label control-label-error">Date Issues</span>
                  <span *ngIf="model.startDate > model.endDate && !submitted"
                        class="control-label control-label-error">*Please select a proper date.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="activityNotes" class="col-md-3 control-label">Notes</label>
          <div class="col-md-9">
            <textarea name="activityNotes"
                      appInputRestriction="restrictChars"
                      class="form-control"
                      [(ngModel)]="model.notes"
                      maxlength="100"
                      authenticate-user="contact.workschedule.update,ContactWorkSchedule.Update"
                      authenticate-command="disabled"></textarea>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-3">
            <button type="submit"
                    class="btn btn-link"
                    (click)=" deleteModalActive = true;"
                    *ngIf="activityEditMode"
                    [disabled]="!activityEditMode"
                    authenticate-user="contact.workschedule.delete,ContactWorkSchedule.Delete"
                    authenticate-command="disabled">
              Delete Activity
            </button>
          </div>
          <div class="col-md-9">
            <button type="submit" class="btn btn-primary" ng-click="addActivity(addActivityForm.$valid)"
                    authenticate-user="contact.workschedule.update,ContactWorkSchedule.Update,ContactWorkSchedule.EventName.Update" authenticate-command="disabled">
              <span ng-show="!activityEditMode">Add</span><span ng-show="activityEditMode">Update</span>
            </button>
            <button type="button" class="btn btn-default" ng-click="closeModal()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="deleteModalActive">
    <form deleteActivityForm="ngForm" novalidate>
      <p>Are you sure you want to delete this activity?</p>
      <div class="text-right" *ngIf="deleteModalActive">
        <button type="submit" class="btn btn-primary" (click)="confirmDeleteActivity()">Confirm</button>
        <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
      </div>
    </form>
  </div>
</div>

