import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";
import { SharedDataService } from "../../../Shared/sharedData.service";

@Component({
    selector: "season-dashboard",
    templateUrl: "./dashboard.component.html",
})
export class SeasonDashboardComponent implements OnInit, OnDestroy {
    constructor(
        private log: NbcLoggerService,
        private _seasonServices: SeasonService,
        private _venueService: VenueService,
        private _sharedData: SharedDataService
    ) {}
    seasonId = window.preload.seasonId || 0;
    eventQuery: any = {};
    season: any = {};
    pageName = "Season Overview";
    seasonSummary: any = {};
    mapLocations: any[] = [];
    startDate = new Date();
    eventId: any;
    title: any;
    map = {
        center: { latitude: 40.1451, longitude: -99.668 },
        zoom: 4,
        options: { scrollwheel: false },
        bounds: {},
    };
    markers: any[] = [];
    private subs = new SubSink();
    eventCount = {
        total: 0,
        executed: 0,
        planning: 0,
    };
    getSeasonSummaryData() {
        // log.trace("getEventSummary called");
        // usSpinnerService.spin("searchSpinner");
        this.subs.sink = this._seasonServices
            .getSeasonSummaryData(this.seasonId)
            .subscribe({
                next: (result) => {
                    this.log.trace("successfully retrieved the season summary");
                    this.seasonSummary = result.reference;
                    _.forEach(this.seasonSummary.events, (item) => {
                        if (item.statusCode.description === "Executing") {
                            this.eventCount.executed += 1;
                        } else if (item.statusCode.description === "Planning") {
                            this.eventCount.planning += 1;
                        }
                    });

                    this.eventCount.total = this.seasonSummary.events
                        ? this.seasonSummary.events.length
                        : 0;
                },
                error: (data) => {
                    this.log.error("got an error: " + JSON.stringify(data));
                },
            });
    }
    generateMarkers(
        eventId,
        id,
        longitude,
        latitude,
        title,
        startDate,
        markerIcon
    ) {
        this.log.trace("generateMarkers called");
        const idKey = "id",
            ret = {
                eventId: eventId,
                longitude: longitude,
                latitude: latitude,
                title: title,
                startDate: startDate,
                show: false,
                icon: markerIcon || "",
            };

        if (title) {
            ret.show = !ret.show;
        }

        ret[idKey] = id;
        return ret;
    }

    populateMap(data, gmap) {
        const geoCoder = new gmap.Geocoder();
        let address: any[] = [],
            addressStr,
            venue = {};
        _.forEach(data, (item, index) => {
            if (
                item.longitude &&
                item.latitude &&
                item.longitude !== -1 &&
                item.latitude !== -1
            ) {
                this.markers.push(
                    this.generateMarkers(
                        item.eventId,
                        item.id,
                        item.longitude,
                        item.latitude,
                        item.name,
                        item.startDate,
                        item.icon
                    )
                );
            } else if (item.address || (item.city && item.state)) {
                if (item.address) {
                    address.push(item.address);
                }
                if (item.city) {
                    address.push(item.city);
                }

                if (item.state) {
                    address.push(item.state);
                }

                if (item.zip) {
                    address.push(item.zip);
                }
                addressStr = address.join(" ");
                geoCoder.geocode({ address: addressStr }, (results, status) => {
                    if (status === gmap.GeocoderStatus.OK) {
                        if (results[0].geometry.location) {
                            this.markers.push(
                                this.generateMarkers(
                                    item.eventId,
                                    item.id,
                                    results[0].geometry.location.lng(),
                                    results[0].geometry.location.lat(),
                                    item.name,
                                    item.startDate,
                                    item.icon
                                )
                            );

                            if (item.id) {
                                venue = {
                                    eventId: item.id,
                                    id: item.id,
                                    address: item.address,
                                    city: item.city,
                                    state: item.state,
                                    zip: item.zip,
                                    longitude:
                                        results[0].geometry.location.lng(),
                                    latitude:
                                        results[0].geometry.location.lat(),
                                    icon: "/content/app/images/marker-venue.png",
                                };
                                this.saveVenue(venue);
                            }
                        }
                    }
                });
            }
        });
    }

    addMarker(location) {
        this.mapLocations.push(location);
    }
    open(season) {
        // var modalInstance = $modal.open({
        //     scope: $scope,
        //     templateUrl: "/scripts/app/areas/events/views/season/createSeasonModal.html",
        //     controller: "ModalCreateSeason",
        //     keyboard: false,
        //     backdrop: "static",
        //     resolve: {
        //         season: function () {
        //             return season;
        //         }
        //     }
        // });
        // modalInstance.result.then(function (data) {
        //     log.trace("createSeasonModal.html Modal dismissed with: " + data);
        //     if (!!data && !! data.reference) {
        //         $scope.season = data.reference;
        //     }
        // }, function () {
        //     log.trace("createSeasonMOdal.html modal dismissed without value");
        // });
    }

    openCloneEventModal(event) {
        // log.trace("Opening modal modalClone.html");
        // var broadcastingNetworks = _.without(_.pluck(event.broadcastDates, "network.description"), undefined);
        // console.log(broadcastingNetworks);
        // var modalInstanceEvent = $modal.open({
        //     templateUrl: "/scripts/app/areas/events/views/event/modalClone.html",
        //     controller: "ModalCloneController",
        //     keyboard: false,
        //     backdrop: "static",
        //     resolve: {
        //         event: function () {
        //             return {
        //                 eventId: event.id,
        //                 eventName: event.name,
        //                 sourceTier: event.tier,
        //                 broadcastingNetworks: broadcastingNetworks,
        //                 eventTypeId: event.eventType.id
        //             };
        //         },
        //         tierConfig: function () {
        //             return event.eventTierSetup;
        //         }
        //     }
        // });
        // modalInstanceEvent.result.then(function (clone) {
        //     log.trace("clone event modal dismissed with: " + clone);
        //     usSpinnerService.spin("searchSpinner");
        //     eventServices.cloneEvent(clone).success(function (data) {
        //         log.trace("clone event modal successfully saved data: " + JSON.stringify(data));
        //         $window.location.href = preload.siteRoot + "events/event/summary/" + data.reference.id;
        //     }).error(function (data) {
        //         log.error("clone event modal got an error cloning the event: " + JSON.stringify(data));
        //         $scope.$emit("showAlert", "danger", "Unable to clone the event at this time. Please try again later.");
        //     }).finally(function () {
        //         usSpinnerService.stop("searchSpinner");
        //     });
        // }, function () {
        //     log.trace("clone event modal dismissed without value");
        // });
    }
    saveVenue(venue) {
        this.log.trace("saveVenue called");
        // usSpinnerService.spin("searchSpinner");
        this.subs.sink = this._venueService.saveVenue(venue).subscribe({
            next: () => {
                this.log.trace("successfully retrieved the season summary");
            },
            error: (data) => {
                this.log.error("got an error: " + JSON.stringify(data));
            },
        });
    }
    setEventQuery() {
        this.log.trace("setEventQuery()");
        if (this.eventQuery) {
            this.eventQuery.seasonId = this.seasonId;
            // this.$broadcast("onUpdateEventQuery", $scope.eventQuery);
            // this.$broadcast("onLoadDefaultEventQuery", $scope.eventQuery);
        }
    }

    // $scope.$watch(function () { return $scope.mapLocations; }, function (data) {
    //   GoogleMapApi.then(function (gmap) {
    //       $scope.markers = [];
    //       $scope.populateMap(data, gmap);
    //   });
    // }, true);

    // Event Listeners
    // $scope.$on("onSeasonLoaded", function (e, season) {
    //   log.trace("onSeasonLoaded");
    //   $scope.season = season;
    // });

    // $scope.$on("onLoadEventQuery", function (e, data) {
    //   log.trace("onLoadEventQuery : " + JSON.stringify(data));
    //   $scope.eventQuery = data;
    //   $scope.setEventQuery();
    //   $scope.$broadcast("onTriggerEventSearch");
    // });

    // $scope.$on("onUpdateEventSearchResults", function (e, data) {
    //   if (angular.isDefined(data)) {
    //       angular.forEach(data.items, function (item) {
    //           if (item.venue) {
    //               $scope.addMarker({
    //                   eventId: item.id,
    //                   id: item.venue.id,
    //                   name: item.name,
    //                   address: item.venue.address,
    //                   city: item.venue.city,
    //                   state: item.venue.state,
    //                   zip: item.venue.zip,
    //                   startDate: item.startDate,
    //                   longitude: item.venue.longitude,
    //                   latitude: item.venue.latitude,
    //                   icon: "/content/app/images/marker-venue.png"
    //               });
    //           }
    //       });
    //   }
    // });
    ngOnInit(): void {
        this._sharedData.getCurrentSeasonId().subscribe((seasonId) => {
            this.seasonId = seasonId;
        });
        this.getSeasonSummaryData();
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
