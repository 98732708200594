<div class="main-pane-content" *ngIf="contact && countries">
  <div class="row">
    <div class="col-md-12">
      <form #profileInfoUpdate="ngForm">
        <div class=" form-box">
          <a authenticate-user="ContactHRMaster.SSO.Update,contact.update,name.update,dob.update,ssn.update,contactpositionskill.update,union.update,contactlocation.update,contactinformation.update,contacthrmaster.employmentstatus,contacthrmaster.employeegroup,contacthrmaster.employeesubgroup,contacthrmaster.unionaffiliation,contacthrmaster.payrollarea,contacthrmaster.businesssegment, contactlocation.airport.update"
             class="form-box-edit"
             (click)="setEditMode();"
             *ngIf="!editMode"><i class="fa fa-pencil"></i></a>
          <div class="row">
            <div class="col-md-4"
                 authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve,ContactLocation.Airport.Retrieve">
              <div authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve,ContactLocation.Airport.Retrieve">
                <h4>Location</h4>
                <!-- READONLY VIEW -->
                <div class="detail-itms" *ngIf="!editMode">
                  <div authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">
                    <span class="detail-itm">
                      <span class="detail-txt">{{contact.city}}</span>
                    </span>
                    <span class="detail-itm">
                      <span class="detail-txt">{{contact.province}} {{contact.postalCode}} </span>
                    </span>
                    <span class="detail-itm">
                      <span class="detail-txt">{{getDisplayCountry(contact.country)}}</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- EDIT VIEW -->
              <div class="container">
                <div class="form-horizontal" *ngIf="editMode">
                  <div class="form-group d-flex">
                    <label class="col-md-4 control-label">Country</label>
                    <div class="col-md-8">
                      <nbc-select [source]="countries"
                                  [model]="selectedCountry"
                                  display="name"
                                  value="isoCode"
                                  (modelChange)="setStates($event, true)"
                                  placeHolderDisplay="Select Country"
                                  placeHolderValue="null"
                                  authenticate-user="contact.hrmaster.update,ContactLocation.Update"
                                  authenticate-command="nbcselectdisabled">
                      </nbc-select>
                    </div>

                  </div>
                  <div class="form-group d-flex">
                    <label class="col-md-4 control-label">City</label>
                    <div class="col-md-8">
                      <input type="text"
                             appInputRestriction="restrictChars"
                             name="city"
                             maxlength="40"
                             class="form-control"
                             [(ngModel)]="contact.city"
                             authenticate-user="contact.update,contact.hrmaster.update,ContactLocation.Update"
                             authenticate-command="disabled">
                    </div>
                  </div>
                  <div class="form-group d-flex" *ngIf="(contact.country=='US' || states?.length != 0)">
                      <label class="col-md-4 control-label">State</label>
                      <div class="col-md-8">
                        <select name="province"
                                class="dropdown-toggle form-select"
                                [(ngModel)]="contact.province"
                                [value]="contact.province"
                                style="height:35px;"
                                authenticate-user="contact.update,contact.hrmaster.update,ContactLocation.Update"
                                authenticate-command="disabled">
                          <option selected [ngValue]="null">--Select--</option>
                          <option *ngFor="let state of states" [value]="state.code">{{state.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group d-flex" *ngIf="contact.country!='US'">
                      <label class="col-md-4 control-label">Province</label>
                      <div class="col-md-8">
                        <input name="province"
                               type="text"
                               appInputRestriction="restrictChars"
                               class="form-control"
                               [(ngModel)]="contact.province"
                               authenticate-user="contact.update,contact.hrmaster.update,ContactLocation.Update"
                               authenticate-command="disabled">
                      </div>
                    </div>
                    <div class="form-group d-flex">
                      <label class="col-md-4 control-label">Postal Code</label>
                      <div class="col-md-8">
                        <input name="postalCode"
                               type="text"
                               appInputRestriction="restrictChars"
                               class="form-control"
                               [(ngModel)]="contact.postalCode"
                               maxlength="10"
                               authenticate-user="contact.update,contact.hrmaster.update,ContactLocation.Update"
                               authenticate-command="disabled" />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-4">
              <h4>Contact Info</h4>
              <!-- READONLY VIEW -->
              <div class="detail-itms" *ngIf="!editMode" authenticate-user="contact.retrieve,ContactInformation.Retrieve">

                <span class="detail-itm" *ngIf="contact.email" authenticate-user="contact.retrieve,ContactInformation.Email.Retrieve">
                  <span class="detail-lbl">Email:</span>
                  <span class="detail-txt">{{contact.email}}</span>
                </span>
                <span class="detail-itm" style="font-size:12px;font-style:italic;" *ngIf="contact.emailLastUpdated && contact.emailLastUpdated.updatedBy != ''" authenticate-user="contact.retrieve,ContactInformation.Email.Retrieve">
                  <span class="detail-txt">Email Last Updated By: </span>
                  <span class="detail-txt">{{contact.emailLastUpdated.updatedBy}} {{contact.emailLastUpdated.updatedDate | date:"MM-dd-yyyy"}}</span>
                </span>
                <span class="detail-itm" *ngIf="contact?.phone1.phoneNumber">
                  <span class="detail-lbl">Primary:</span>
                  <span class="detail-txt" disabled *ngIf="contact?.phone1"><strong>{{contact.phone1?.phoneNumber}}</strong></span>
                </span>
                <span class="detail-itm" *ngIf="contact.phone2.phoneNumber">
                  <span class="detail-lbl">Alternate:</span>
                  <span class="detail-txt" disabled *ngIf="contact?.phone2">{{contact.phone2?.phoneNumber}}</span>
                </span>
                <span class="detail-itm">
                  <span class="detail-lbl" authenticate-user="contact.pernersso.retrieve,ContactHRMaster.PERNER.Retrieve">Per Ner:</span>
                  <span authenticate-user="contact.pernersso.retrieve,ContactHRMaster.PERNER.Retrieve" class="detail-txt">{{ contact.perNer}}</span>
                </span>
              </div>
              <!-- EDIT VIEW -->
              <div class="container">
                <div class="form-horizontal" *ngIf="editMode">

                  <div class="form-group d-flex" [ngClass]="{'has-error': (emailEle.invalid && profileInfoUpdate.submitted) }">
                    <label class="col-md-4 control-label">Email</label>
                    <div class="col-md-7">
                      <input type="email"
                             class="form-control"
                             maxlength="50"
                             [(ngModel)]="contact.email"
                             [pattern]="emailRegex"
                             #emailEle="ngModel"
                             required
                             name="email"
                             authenticate-user="contact.update,contact.hrmaster.update,ContactInformation.Email.Update"
                             authenticate-command="disabled">
                      <span class="control-label control-label-error"
                            *ngIf="(( emailEle.invalid && profileInfoUpdate.submitted) || (profileInfoUpdate.form.controls['email']?.errors?.pattern))">Please enter a valid Email.</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-4"></div>
                    <div class="col-md-7">
                      <span class="detail-itm"
                            style="font-size:12px;font-style:italic;"
                            *ngIf="contact.emailLastUpdated && contact.emailLastUpdated.updatedBy != ''"
                            authenticate-user="contact.retrieve,ContactInformation.Email.Retrieve">
                        <span class="detail-txt">Email Last Updated By: </span>
                        <span class="detail-txt">{{contact.emailLastUpdated.updatedBy}} {{contact.emailLastUpdated.updatedDate | date:"MM-dd-yyyy"}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="form-group d-flex" #phone1Group="ngModelGroup" ngModelGroup="name">
                    <div class="col-md-4">Primary</div>
                    <!--<nbc-phone-number name="viewPhone1" [nbcRequired]="true" [(isValid)]="isPhoneValid1" [isSubmitted]="submitted"
                                      [(model)]="contact.phone1.phoneNumber" [ngClass]="{'has-error': !isPhoneValid1}">
                    </nbc-phone-number>-->
                    <nbc-phone-number name="viewPhone1" [nbcRequired]="true" [(isValid)]="isPrimaryPhoneValid" [isSubmitted]="submitted"
                                      [(model)]="contact.phone1.phoneNumber" [ngClass]="{'has-error': !isPrimaryPhoneValid}">
                    </nbc-phone-number>
                  </div>

                  <div class="form-group d-flex">
                    <div class="col-md-4">
                      Alternate
                    </div>

                    <!--<nbc-phone-number name="viewPhone2" [(isValid)]="isSecondaryPhoneValid" isSubmitted="profileInfoUpdate.submitted"
                                      [(model)]="contact.phone2.phoneNumber">
                    </nbc-phone-number>-->
                    <nbc-phone-number name="viewPhone2" [nbcRequired]="false" [(isValid)]="isSecondaryPhoneValid" [isSubmitted]="submitted"
                                      [(model)]="contact.phone2.phoneNumber" [ngClass]="{'has-error': !isSecondaryPhoneValid}"></nbc-phone-number>

                  </div>
                  <!--[ngClass]="{'has-error': hrVerifiedUpdate.perNer?.errors?.required && contact.pernersso.update}-->
                  <div class="form-group d-flex">
                    <label class="col-md-4 control-label">Per Ner</label>
                    <div class="col-md-8">
                      <input type="text"
                             appInputRestriction="restrictChars"
                             name="perNer"
                             class="form-control"
                             [(ngModel)]="contact.perNer"
                             authenticate-user="contact.pernersso.update,ContactHRMaster.PERNER.Update"
                             authenticate-command="disabled">
                      <!--<span class="control-label control-label-error"
                      [hidden]="!hrVerifiedUpdate?.perNer?.error.required && !contact?.pernersso?.update">Per Ner is required.</span>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" authenticate-user="contact.hrmaster.retrieve,DOB.Retrieve,SSN.Retrieve">
              <h4>Personal Info</h4>
              <!-- READONLY VIEW -->
              <div class="detail-itms" *ngIf="!editMode">
                <span class="detail-itm">
                  <span class="detail-lbl">Contact Type:</span>
                  <span class="detail-txt" *ngIf="!contact.isNonVendor">Vendor &nbsp;</span>
                  <span class="detail-txt" *ngIf="contact.isNonVendor">Non-Vendor &nbsp;</span>
                </span>
                <span class="detail-itm" *ngIf="contact.dobMonth" authenticate-user="contact.hrmaster.retrieve,DOB.Retrieve">
                  <span class="detail-lbl">DOB:</span>
                  <span class="detail-txt" *ngIf="!showDobRestrictedField">{{ getMonthName(contact.dobMonth) || contact.dobMonth }} {{ contact.dobDay }} &nbsp;</span>
                  <span class="detail-txt" *ngIf="showDobRestrictedField">XXXXXX  XX</span>
                  <span class="profile-restricted-firstFour" *ngIf="showDobRestrictedField">
                    <i class="glyphicon glyphicon-eye-open"></i>
                  </span>
                </span>
                <span class="detail-itm" *ngIf="contact.ssnLastFour" authenticate-user="contact.hrmaster.retrieve,SSN.Retrieve">
                  <span class="detail-lbl">SSN:</span>
                  <span class="detail-txt" *ngIf="!showSsnRestrictedField">XXX-XX-{{contact.ssnLastFour}}</span>
                  <span class="detail-txt" *ngIf="showSsnRestrictedField">XXX-XX-XXX</span>
                  <span class="profile-restricted-lastFour" *ngIf="showSsnRestrictedField">
                    <i class="glyphicon glyphicon-eye-open"></i>
                  </span>
                </span>
                <span class="detail-itm" authenticate-user="hrcontactflag.retrieve">
                  <span class="detail-lbl">HR Contact Flag:</span>
                  <div>

                    <button class="btn btn-success btn-approve btn-sm approve-el" *ngIf="!contact.flaggedByHR" [disabled]="true" data-is-approved="true">
                      <i class="fa fa-thumbs-up"></i>
                    </button>
                    <button class="btn btn btn-danger btn-reject btn-sm reject-el" *ngIf="contact.flaggedByHR" [disabled]="true" data-is-approved="false">
                      <i class="fa fa-thumbs-down"></i>
                    </button>
                  </div>
                </span>
                <span class="detail-itm">
                  <span class="detail-lbl">SSO:</span>
                  <span authenticate-user="contact.pernersso.retrieve,ContactHRMaster.SSO.Retrieve" class="detail-txt">{{ contact.sso }}</span>
                </span>
              </div>
              <!-- EDIT VIEW -->
              <div class="container">
                <div class="form-horizontal" *ngIf="editMode">
                  <div class="form-group d-inline-flex">
                    <div class="form-group d-flex" style="margin-left:auto;margin-bottom:9px">
                      <p class="form-control-static">Is the contact a Vendor/Non-Vendor?</p>
                    </div>
                    <div class="form-group d-flex" style="margin-left:auto;margin-bottom:15px">
                      <label class="checkbox-inline"
                             container="body"
                             placement="top"
                             containerClass="tooltip"
                             [tooltip]="nonVendorTemplate">
                        <input type="radio"
                               name="isNonVendor"
                               class="inline"
                               [(ngModel)]="contact.isNonVendor"
                               (change)="confrimVendorChangeModal()"
                               authenticate-user="contact.update,contact.contacttype.update"
                               authenticate-command="disabled"
                               [value]="true"
                               required> Non-Vendor
                      </label>
                      <label class="checkbox-inline"
                             container="body"
                             placement="top"
                             containerClass="tooltip"
                             tooltip="{{vendorTemplate}}">
                        <input type="radio"
                               name="isNonVendor"
                               class="inline"
                               [(ngModel)]="contact.isNonVendor"
                               (change)="confrimVendorChangeModal()"
                               authenticate-user="contact.update,contact.contacttype.update"
                               authenticate-command="disabled"
                               style="margin-left:-15px"
                               [value]="false"
                               required> Vendor
                      </label>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top:-13px;margin-bottom:5px;color:#D08A57"
                       *ngIf="contact.isNonVendor">
                    <span class="control-label">Enter either DOB and SSN or SSO</span>
                  </div>
                  <div class="form-group d-flex"
                       [ngClass]="{'has-error': (fieldDobMonth?.invalid || fielddobDay?.invalid) && profileInfoUpdate.submitted}">
                    <label class="col-md-2 control-label">DOB</label>
                    <div class="col-md-10" *ngIf="showDobRestrictedField">
                      <div class="form-inline">
                        <span class="detail-txt" *ngIf="showDobRestrictedField">XXXXXX  XX</span>
                        <span class="profile-restricted-firstFour" *ngIf="showDobRestrictedField">
                          <i class="glyphicon glyphicon-eye-open"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-10" *ngIf="!showDobRestrictedField">
                      <div class="form-inline d-flex">

                        <select name="dobMonth"
                                class="form-select-md form-select w-25 me-1"
                                [(ngModel)]="contact.dobMonth"
                                style="height:35px;"
                                [required]="dobMonthRequired"
                                #fieldDobMonth="ngModel"
                                (change)="clearDobDay(); validateDob();"
                                authenticate-user="contact.update,contact.hrmaster.update,DOB.Update"
                                authenticate-command="disabled"
                                [ngClass]="{'border-red': contact.dobMonth <= 0 && (contact.sso === '' || contact.sso === null) && profileInfoUpdate.submitted}">
                          <option [ngValue]="-1">--Select--</option>
                          <option *ngFor="let month of months" [ngValue]="month.id">{{month.name}}</option>
                        </select>

                        <select name="dobDay"
                                class="form-select-md form-select w-25"
                                [(ngModel)]="contact.dobDay"
                                style="height:35px;"
                                #fielddobDay="ngModel"
                                (change)="validateDob();"
                                [required]="dobDayRequired"
                                [ngClass]="{'border-red': contact.dobDay <= 0 && (contact.sso === '' || contact.sso === null) && profileInfoUpdate.submitted}"
                                authenticate-user="contact.update,contact.hrmaster.update,DOB.Update"
                                authenticate-command="disabled">
                          <option [ngValue]="-1">--Select--</option>
                          <option *ngFor="let value of getDaysFromMonth(contact.dobMonth)" [ngValue]="value">{{value}}</option>
                        </select>
                      </div>
                      <span class="control-label control-label-error"
                            *ngIf="((fieldDobMonth.invalid || fielddobDay.invalid ||contact.dobMonth <= 0 ||contact.dobDay <= 0 ) && profileInfoUpdate.submitted && contact.isNonVendor)">Enter a valid birth month and birth date.</span>
                    </div>
                  </div>
                  <div class="form-group d-flex" style="" [ngClass]="{'has-error':ssnRequired && profileInfoUpdate.submitted}">
                    <label class="col-md-2 control-label">SSN</label>
                    <div class="col-md-10" *ngIf="showSsnRestrictedField">
                      <div class="form-inline">
                        <span class="detail-txt" *ngIf="showSsnRestrictedField">XXX-XX-XXX</span>
                        <span class="profile-restricted-lastFour" *ngIf="showSsnRestrictedField">
                          <i class="glyphicon glyphicon-eye-open"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-10" *ngIf="!showSsnRestrictedField">
                      <div class="form-inline">
                        XXX-XX- <input authenticate-user="contact.update,contact.hrmaster.update,SSN.Update"
                                       authenticate-command="disabled"
                                       name="ssn"
                                       #fieldSSN="ngModel"
                                       type="text"
                                       class="form-control form-control-sm"
                                       [(ngModel)]="contact.ssnLastFour"
                                       maxlength="4"
                                       [pattern]="lastFourRegex"
                                       [required]="(contact.isNonVendor && ssnRequired)"
                                       (change)="checkVendorValidation()"
                                       [authenticate-required]="contact.isNonVendor && ssnRequired"
                                       [ngClass]="{'ng-valid-not-required': !(contact.isNonVendor && ssnRequired)}" />
                        <span class="profile-restricted-lastFour" *ngIf="showSsnRestrictedField">
                          <i class="glyphicon glyphicon-eye-open"></i>
                        </span>
                      </div>
                      <span class="control-label control-label-error" *ngIf="(fieldSSN.invalid && profileInfoUpdate.submitted)">Please enter a valid SSN.</span>

                    </div>
                  </div>

                  <div align="right">

                  </div>
                  <div *ngIf="checkHRStatus() !==3" class="form-group d-flex" style="" [ngClass]="{'has-error':fieldsso.invalid && profileInfoUpdate.submitted}">
                    <label class="col-md-2 control-label">SSO</label>
                    <div class="col-md-10">
                      <div class="form-inline">
                        <input authenticate-user="contact.update,contact.hrmaster.update,SSO.Update,ContactHRMaster.SSO.Update"
                               authenticate-command="disabled"
                               name="sso"
                               type="text"
                               appInputRestriction="numberOnly"
                               #fieldsso="ngModel"
                               class="form-control form-control-sm"
                               [(ngModel)]="contact.sso"
                               maxlength="9"
                               [pattern]="ssoRegex"
                               (change)="checkVendorValidation()"
                               [required]="(contact.isNonVendor && ssoRequired)"
                               [disabled]="checkHRStatus() ==3 ?'disabled':null "
                               [authenticate-required]="contact.isNonVendor && ssoRequired"
                               [ngClass]="{'ng-valid-not-required': !(contact.isNonVendor && ssoRequired)}" style="width:200px" />
                        <!--ng-disabled="checkHRStatus() ===3 && isNotHRSU" as per SCOR-11894 no user can edit SSO if HR verified in this section-->
                      </div>
                      <span class="control-label control-label-error"
                            *ngIf="((fieldsso.invalid && profileInfoUpdate.submitted))">Please enter a valid SSO.</span>
                    </div>
                  </div>
                  <div *ngIf="checkHRStatus() ==3"
                       class="form-group d-flex">
                    <label class="col-md-2 control-label">SSO</label>
                    <div class="col-md-10">
                      <span authenticate-user="contact.pernersso.retrieve,ContactHRMaster.SSO.Retrieve"
                            class="detail-txt"
                            style="margin-top: 7px;position: absolute;">
                        {{ contact.sso }}
                      </span>

                    </div>
                  </div>
                  <div class="form-group d-flex" [ngClass]="{'has-error':profileInfoUpdate?.ssn?.invalid && submitted}"
                       authenticate-user="hrcontactflag.update,hrcontactcomment.update">
                    <label class="col-md-1 control-label">HR Contact Flag</label>
                    <label class="col-md-1 control-label"></label>
                    <div class="col-md-11">
                      <button class="btn btn-success btn-approve btn-sm approve-el"
                              [disabled]="!contact.flaggedByHR"
                              (click)="flagContact(false)"
                              authenticate-user="hrcontactflag.update"
                              data-is-approved="true">
                        <i class="fa fa-thumbs-up"></i>
                      </button>
                      <button class="btn btn btn-danger btn-reject btn-sm reject-el"
                              [disabled]="contact.flaggedByHR"
                              (click)="flagContact(true)"
                              authenticate-user="hrcontactflag.update"
                              data-is-approved="false">
                        <i class="fa fa-thumbs-down"></i>
                      </button>
                      <span style="font-size:2em"
                            (click)="commentContact()"
                            authenticate-user="hrcontactcomment.update"
                            authenticate-command="disabled"
                            data-is-approved="false">
                        <i class="fa fa-comment-o"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!editMode">
            <div class="col-md-12">
              <ng-container>
                <button authenticate-user="contact.emergencycontact.retrieve"
                        authenticate-command="hide"
                        type="button"
                        class="btn btn-primary"
                        id="btnEmergencyContact"
                        (click)="emergencyContact()">
                  View Emergency Contact
                </button>
              </ng-container>
            </div>
          </div>
          <!-- EDIT VIEW BUTTONS -->
          <div class="row" *ngIf="editMode">
            <div class="col-md-12">
              <button authenticate-user="contact.update,contact.rate.update,contact.hrmaster.update,ContactLocation.Update,ContactInformation.Update,DOB.Update,SSN.Update, contactlocation.airport.update,contact.nabet.update"
                      type="submit"
                      class="btn btn-primary"
                      id="btnCreateContact"
                      (click)="disableSave || saveProfileInfo(profileInfoUpdate,profileInfoUpdate.valid)">
                Save
              </button>
              <ng-container>
                <button authenticate-user="contact.realtimesync.update"
                        authenticate-command="disabled"
                        authenticate-required="contact.isNonVendor"
                        type="button"
                        *ngIf="!(checkHRStatus() ===3 && isNotHRSU && !isSuperUser)"
                        class="btn btn-primary"
                        id="btnVerifyContact"
                        (click)="verifyContact()">
                  Verify Contact
                </button>
              </ng-container>
              <button authenticate-user="contact.update,contact.rate.update,contact.hrmaster.update,ContactLocation.Update,ContactInformation.Update,DOB.Update,SSN.Update, contactlocation.airport.update,contact.nabet.update"
                      type="button"
                      class="btn btn-default"
                      (click)="setReadMode();resetSubSection()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- removed NGIF because it create a child scope here-->
    <div class="col-md-12" authenticate-user="contact.hrmaster.retrieve,onboarding.retrieve,ContactHRMaster.Retrieve">
      <form #hrVerifiedUpdate="ngForm">

        <div class="form-box">
          <div class="row" *ngIf="HRStatus === 1">
            <div class="col-md-12">
              <h4 class="hdr-danger">This Contact Has No HR Master</h4>
              <p>SAP Master required, but contact's profile has not completed the sync with SAP</p>
            </div>
          </div>
          <div class="row" *ngIf="HRStatus === 2">
            <div class="col-md-12">
              <h4 class="hdr-danger">This Contact is Not HR Verified</h4>
            </div>
          </div>

          <div class="row" *ngIf="HRStatus === 3">

            <span authenticate-user="contact.hrmaster.update,ContactHRMaster.Update">
              <a class="form-box-edit" (click)="setEditModeHRVerifiedSection()" *ngIf="!editModeHRVerifiedSection"><i class="fa fa-pencil"></i></a>
            </span>
            <div class="">
              <div class="row"><h4 class="hdr-success col-md-8">HR Verified</h4> <span class="col-md-4 hr-master-indication">* Indicates this is not their direct manager</span> </div>

              <div class="row">
                <div class="col-md-4">
                  <!-- READ ONLY VIEW -->
                  <div class="detail-itms" *ngIf="!editModeHRVerifiedSection">
                    <span class="detail-itm">
                      <span class="detail-lbl">Per Ner:</span>
                      <span authenticate-user="contact.pernersso.retrieve,ContactHRMaster.PERNER.Retrieve" class="detail-txt">{{ contact.perNer}}</span>
                    </span>
                    <span class="detail-itm">
                      <span class="detail-lbl">SSO:</span>
                      <span authenticate-user="contact.pernersso.retrieve,ContactHRMaster.SSO.Retrieve" class="detail-txt">{{ contact.sso }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="contact.hrmaster.update,ContactHRMaster.EmploymentStatus.Retrieve">
                      <span class="detail-lbl">Employment Status:</span>
                      <span class="detail-txt">{{ contact.employmentStatus }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.JOBTITLE.Retrieve">
                      <span class="detail-lbl">HR Manager:</span>
                      <span class="detail-txt">{{ contact.hrManager }}<span *ngIf="!contact?.isDirectHRManager" [ngClass]="{'mandatory':contact.isDirectHRManager}">*</span></span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.JOBTITLE.Retrieve">
                      <span class="detail-lbl">SAP Job Title:</span>
                      <span class="detail-txt">{{ contact.sapJobTitle }}</span>
                    </span>
                  </div>
                  <!-- EDIT VIEW -->
                  <div class="container">
                    <div authenticate-user="contact.hrmaster.update,ContactHRMaster.Update" class="form-horizontal" *ngIf="editModeHRVerifiedSection">
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrPerNer.invalid && contact.pernersso.update}">
                        <label class="col-md-4 control-label">Per Ner</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="perNer"
                                 #fieldHrPerNer="ngModel"
                                 class="form-control"
                                 [(ngModel)]="contact.perNer"
                                 authenticate-user="contact.pernersso.update,ContactHRMaster.PERNER.Update"
                                 authenticate-command="disabled">
                          <span class="control-label control-label-error" *ngIf="fieldHrPerNer.invalid && contact.pernersso.update">Per Ner is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">SSO</label>
                        <div class="col-md-8" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.SSO.Retrieve">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="SSO"
                                 class="form-control"
                                 [(ngModel)]="contact.sso"
                                 maxlength="9"
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.SSO.Update"
                                 authenticate-command="disabled"
                                 style="width:200px">
                        </div>
                      </div>
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrEmpStatus.invalid }">
                        <label class="col-md-4 control-label">Employment Status</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="employmentStatus"
                                 #fieldHrEmpStatus="ngModel"
                                 class="form-control"
                                 [(ngModel)]="contact.employmentStatus"
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.EmploymentStatus.Update"
                                 authenticate-command="disabled"
                                 required
                                 authenticate-required="true">
                          <span class="control-label control-label-error" *ngIf="fieldHrEmpStatus.invalid">Employment Status is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">HR Manager</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.hrManager }}</span><span *ngIf="!contact?.isDirectHRManager" [ngClass]="{'mandatory':contact.isDirectHRManager}">*</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">SAP Job Title</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.sapJobTitle }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <!-- READ ONLY VIEW -->
                  <div class="detail-itms"
                       *ngIf="!editModeHRVerifiedSection">
                    <span class="detail-itm" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.UnionAffiliation.Retrieve">
                      <span class="detail-lbl">Union Affiliation:</span>
                      <span class="detail-txt">{{ contact.unionAffiliation }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.EmployeeGroup.Retrieve">
                      <span class="detail-lbl">Employee Group:</span>
                      <span class="detail-txt">{{ contact.employeeGroup }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.EmployeeSubGroup.Retrieve">
                      <span class="detail-lbl">Employee Sub-Group:</span>
                      <span class="detail-txt">{{ contact.employeeSubGroup }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.WORKLOCATION.Retrieve">
                      <span class="detail-lbl">1-over-1 Parent Supervisor:</span>
                      <span class="detail-txt">{{ contact.superManager }}<span *ngIf="!contact?.isDirectParentSupervisor" [ngClass]="{'mandatory':contact.isDirectParentSupervisor}">*</span></span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.WORKLOCATION.Retrieve">
                      <span class="detail-lbl">Work Location:</span>
                      <span class="detail-txt">{{ contact.workLocation }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.CompanyCode.Retrieve">
                      <span class="detail-lbl">Company Code:</span>
                      <span class="detail-txt">{{ contact.companyCode.fieldValue }}</span>
                    </span>
                  </div>
                  <!-- EDIT VIEW -->
                  <div class="container">
                    <div authenticate-user="contact.hrmaster.update,ContactHRMaster.Update"
                         class="form-horizontal"
                         *ngIf="editModeHRVerifiedSection">
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrUnion.invalid }">
                        <label class="col-md-4 control-label">Union Affiliation</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="unionAffiliation"
                                 #fieldHrUnion="ngModel"                                 
                                 class="form-control"
                                 [(ngModel)]="contact.unionAffiliation"
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.UnionAffiliation.Update"
                                 authenticate-command="disabled"
                                 required
                                 authenticate-required="true">
                          <span class="control-label control-label-error"
                                *ngIf="fieldHrUnion.invalid">Union Affiliation is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrEmpGroup.invalid }">
                        <label class="col-md-4 control-label">Employee Group</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="employeeGroup"
                                 class="form-control"
                                 [(ngModel)]="contact.employeeGroup"
                                 #fieldHrEmpGroup="ngModel"
                                 required
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.EmployeeGroup.Update"
                                 authenticate-command="disabled"
                                 authenticate-required="true">
                          <span class="control-label control-label-error"
                                *ngIf="fieldHrEmpGroup.invalid">Employee Group is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrEmpSubGroup.invalid }">
                        <label class="col-md-4 control-label">Employee Sub-Group</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="employeeSubGroup"
                                 #fieldHrEmpSubGroup="ngModel"
                                 class="form-control"
                                 [(ngModel)]="contact.employeeSubGroup"
                                 required
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.EmployeeSubGroup.Update"
                                 authenticate-command="disabled"
                                 authenticate-required="true">
                          <span class="control-label control-label-error" *ngIf="fieldHrEmpSubGroup.invalid">Employee Sub Group is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">1-over-1 Parent Supervisor</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.superManager }}</span><span *ngIf="!contact?.isDirectParentSupervisor" [ngClass]="{'mandatory':contact.isDirectParentSupervisor}">*</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">Work Location</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.workLocation }}</span>
                        </div>
                      </div>
                      <div class="form-group d-flex" authenticate-user="ContactHRMaster.CompanyCode.Retrieve">
                        <label class="col-md-4 control-label">Company Code</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.companyCode.fieldValue }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <!-- READ ONLY VIEW -->
                  <div class="detail-itms" *ngIf="!editModeHRVerifiedSection">
                    <span class="detail-itm" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.PayrollArea.Retrieve">
                      <span class="detail-lbl">Payroll Area:</span>
                      <span class="detail-txt">{{ contact.payrollArea }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="contact.hrmaster.retrieve,ContactHRMaster.BusinessSegment.Retrieve">
                      <span class="detail-lbl">Business segment:</span>
                      <span class="detail-txt">{{ contact.businessSegment }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.BusinessSegment.Retrieve">
                      <span class="detail-lbl">Sub Business Segment:</span>
                      <span class="detail-txt">{{ contact.subBusSegment }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="ContactHRMaster.BusinessSegment.Retrieve">
                      <span class="detail-lbl">Direct Manager:</span>
                      <span class="detail-txt">{{ contact.directManager }}<span *ngIf="!contact?.isDirectManager" [ngClass]="{'mandatory':contact.isDirectManager}">*</span></span>
                    </span>
                    <span class="detail-itm" authenticate-user="hrcontactflag.retrieve">
                      <span class="detail-lbl">Career Type:</span>
                      <span class="detail-txt">{{ contact.careerType }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="hrcontactflag.retrieve">
                      <span class="detail-lbl">FLSA:</span>
                      <span class="detail-txt">{{ contact.flsa }}</span>
                    </span>
                    <span class="detail-itm" authenticate-user="hrcontactflag.retrieve">
                      <span class="detail-lbl">Bonus Eligibility:</span>
                      <span class="detail-txt">{{ contact.bonusEligibility ? "Y":"N" }}</span>
                    </span>
                  </div>
                  <!-- EDIT VIEW -->
                  <div class="container">
                    <div authenticate-user="contact.hrmaster.update, ContactHRMaster.PayrollArea.Update, ContactHRMaster.BusinessSegment.Update"
                         class="form-horizontal"
                         *ngIf="editModeHRVerifiedSection">
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrPayrollArea.invalid }">
                          <label class="col-md-4 control-label">Payroll Area</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="payrollArea"
                                 #fieldHrPayrollArea="ngModel"
                                 class="form-control"
                                 [(ngModel)]="contact.payrollArea"                                 
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.PayrollArea.Update"
                                 authenticate-command="disabled"
                                 required
                                 authenticate-required="true">
                          <span class="control-label control-label-error" *ngIf="fieldHrPayrollArea.invalid">Payroll Area is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex" [ngClass]="{'has-error': fieldHrBusSeg.invalid }">
                        <label class="col-md-4 control-label">Business Segment</label>
                        <div class="col-md-8">
                          <input type="text"
                                 appInputRestriction="restrictChars"
                                 name="businessSegment"
                                 #fieldHrBusSeg="ngModel"
                                 class="form-control"                                
                                 [(ngModel)]="contact.businessSegment"
                                 authenticate-user="contact.hrmaster.update,ContactHRMaster.BusinessSegment.Update"
                                 authenticate-command="disabled"
                                  required
                                 authenticate-required="true">
                          <span class="control-label control-label-error" *ngIf="fieldHrBusSeg.invalid">Business segment is required.</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label"> Sub Business Segment</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.subBusSegment }}</span>
                        </div>
                      </div>
                      <div class="form-group d-flex">
                        <label class="col-md-4 control-label">Direct Manager</label>
                        <div class="col-md-8">
                          <span class="form-control-lbl">{{ contact.directManager }}</span><span *ngIf="!contact?.isDirectManager" [ngClass]="{'mandatory':contact.isDirectManager}">*</span>
                        </div>
                      </div>

                        <div authenticate-user="hrcontactflag.retrieve" class="form-group d-flex justify-content-between">
                          <div class="col-md-3">
                            <label class="control-label">Career Type</label>
                            </div>
                            <div class="col-md-8">
                              <span class="form-control-lbl">{{ contact.careerType }}</span>
                            </div>
                          </div>
                        <div authenticate-user="hrcontactflag.retrieve" class="form-group d-flex justify-content-between">
                          <div class="col-md-3">
                            <label class="control-label">FLSA</label>
                            </div>
                            <div class="col-md-8">
                              <span class="form-control-lbl">{{ contact.flsa }}</span>
                            </div>
                          </div>
                        <div authenticate-user="hrcontactflag.retrieve" class="form-group d-flex justify-content-between">
                          <div class="col-md-3">
                            <label class="control-label">Bonus Eligibility</label>
                            </div>
                            <div class="col-md-8">
                              <span class="form-control-lbl">{{ contact.bonusEligibility ? "Y":"N" }}</span>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row" *ngIf="HRStatus == 4">
            <div class="col-md-12">
              <h4 class="hdr-danger">This Contact Has No HR Master</h4>
              <p>SAP Master not required</p>
            </div>
          </div>
          <!-- EDIT VIEW BUTTONS -->
          <div class="row" *ngIf="editModeHRVerifiedSection">
            <div class="col-md-12" authenticate-user="contact.hrmaster.update,COntactHRMaster.Update">
              <button type="submit" class="btn btn-primary" (click)="saveHRVerifiedSection(hrVerifiedUpdate)">Save</button>
              <button type="button" class="btn btn-default" (click)="setReadModeHRVerifiedSection()">Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
