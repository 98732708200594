import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { getDate } from "ngx-bootstrap/chronos/utils/date-getters";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-modal-submit-event-timesheet",
    templateUrl: "./modal-submit-event-timesheet.component.html",
})
export class ModalSubmitEventTimesheetComponent implements OnInit {
    constructor(
        private _payrollService: PayrollService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    public onSync!: Subject<any>;
    private subs = new SubSink();

    modal = {
        error: false,
    };
    category: any;
    eventTimesheet: any;
    timesheetToSubmit: any;
    isSubmitButtonEnabled = true;

    calculateMinutes(startDate, endDate) {
        const minutes = moment(endDate).diff(moment(startDate), "minutes");
        return minutes;
    }

    //SCOR-10921, filtering selected timesheets.
    //var selectedTimesheets = $.grep(timesheetToSubmit.timesheets.items, function (element) { return element.selected; });

    submitPayrollBtn() {
        // usSpinnerService.spin("modalSpinner");
        this.isSubmitButtonEnabled = false;
        let i;
        // Suraj Patil --_ sending category to determine the user action. This property will be used to filter and send the records to TIBCO. SCOR-12327
        this.timesheetToSubmit.EventTimesheetSubmittedCategory = this.category;

        //SCOR-10921, filtering selected timesheets.
        //var selectedTimesheetsForSubmission = $.grep(timesheetToSubmit.timesheets.items, function (element) { return element.selected; });

        for (
            i = 0;
            i < this.timesheetToSubmit.timesheets.items.length;
            i += 1
        ) {
            //SCOR-10921, Change the isSubmitted to 'Y' only for selected records
            if (
                this.timesheetToSubmit.timesheets.items[i].eventPosition
                    .position.category === this.category ||
                this.category === "All"
            ) {
                this.timesheetToSubmit.timesheets.items[i].isSubmitted = "Y";
            }
        }
        //this.timesheetToSubmit = this.timesheetToSubmit;
        this.subs.sink = this._payrollService
            .saveEventTimesheet(this.timesheetToSubmit)
            .subscribe({
                next: (data) => {
                    // this.log.trace("got results: " + JSON.stringify(data));
                    this.onSync.next(undefined);
                    this.bsModalRef.hide();
                },
                error: (data) => {
                    // this.log.error("got an error: " + JSON.stringify(data));
                    if (data.message.length > 0) {
                        this.log.showError(data.error || data.message);
                    } else {
                        this.log.showError(
                            "Unable to submit timesheets at this time. Please try again later."
                        );
                    }
                    this.isSubmitButtonEnabled = true;
                },
                complete: () => {
                    this.isSubmitButtonEnabled = true;
                },
            });
    }

    closeModal() {
        this.log.trace("modal closed");
        this.isSubmitButtonEnabled = true;
        this.bsModalRef.hide();
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.timesheetToSubmit = cloneDeep(this.eventTimesheet);
        _.forEach(this.timesheetToSubmit.timesheets.items, (value) => {
            //SCOR-10921, the validation should be done when all timesheets are not selected or all ATL or BTL timesheets are selected.
            if (
                value.eventPosition.position.category === this.category ||
                this.category === "All"
            ) {
                const lunchDuration = this.calculateMinutes(
                    value.lunchIn,
                    value.lunchOut
                );
                const positionDuration = this.calculateMinutes(
                    value.timeIn,
                    value.timeOut
                );
                if (lunchDuration > 60 || lunchDuration < 60) {
                    this.modal["error"] = true;
                }
                if (positionDuration > 900 || positionDuration < 480) {
                    this.modal["error"] = true;
                }
            }
        });
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
