
<div>
  <header id="col-main-hdr">
    <h1><i class="fa fa-th"></i> Templates</h1>
  </header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li><a routerLink="../../templates" title="Event Position Templates">Templates</a></li>
      <li>/</li>
      <li>{{breadcrumb.templateName}}</li>
    </ul>
  </nav>
  <div class="page-container">
    <div class="container-fluid">
      <nbc-alert></nbc-alert>
      <div class="row">
        <div class="col-md-12">
          <div class="header-bar clearfix">
            <div *ngIf="!editMode && template">
              <p class="heading-title pull-left">
                {{template.name}}
                <label class="label label-default" style="margin:2px;" *ngIf="template.level > 0">L{{template.level}}</label>
                <label class="label label-success" style="margin:2px;"  *ngIf="template.isActive">Active</label>
                <label class="label label-danger" style="margin:2px;"  *ngIf="!template.isActive">Inactive</label>
                <button type="button" class="btn btn-icon-clear" (click)="setEditMode()" authenticate-user="position.update"><i class="fa fa-pencil"></i></button>
              </p>
            </div>
            <form *ngIf="editMode" class="form-inline pull-left" #updateTemplateForm="ngForm">
              <div *ngIf="eventTypes"
                   class="form-group"
                   [ngClass]="{'has-error': template.eventType.id ==0 && submitted}">
                <nbc-dropdown [source]="eventTypes | orderBy :['name']"
                              [(model)]="template.eventType.id"
                              display="name"
                              value="id"
                              name="templateEventType"
                              nbcSize="w-100"
                              placeholder="Event Type">
                </nbc-dropdown>
              </div>
              <div class="form-group">
                <span style="color:red"
                      *ngIf="template.eventType.id ==0 && submitted"
                      class=" control-label control-label-error form-group">Please select an Event Type.</span>
                <input type="text"
                         appInputRestriction="restrictChars"
                       [(ngModel)]="template.name"
                       class="form-control"
                       value="{{template.name}}"
                       #templateName="ngModel"
                       name="templateName"
                       required
                       maxlength="100" />
                <span *ngIf="templateName.invalid && submitted" class="control-label control-label-error" style="color:red">Please enter a template name.</span>
              </div>
              <div class="form-group">
                <label>Level</label>
                <div class="btn-group">
                  <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;"   [(ngModel)]="template.level" [btnRadio]="1" tabindex="0"  role="button" uncheckable>1</button>
                  <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;"   [(ngModel)]="template.level" [btnRadio]="2" tabindex="0"  role="button" uncheckable>2</button>
                  <button type="button" name="templateLevel" class="btn btn-white" style="margin:0 !important;"   [(ngModel)]="template.level" [btnRadio]="3" tabindex="0"  role="button" uncheckable>3</button>
                </div>
              </div>
              <div class="form-group">
                <div class="btn-group">
                  <button type="button"
                          class="btn btn-white"
                          [ngClass]="{'btn-success':template.isActive, 'btn-white':!template.isActive}"
                          [(ngModel)]="template.isActive"
                          name="templateActive"
                          (click)="template.isActive = true;"
                          btn-radio="true">
                    Active
                  </button>
                  <button type="button"
                          class="btn btn-white"
                          [ngClass]="{'btn-white':template.isActive, 'btn-danger':!template.isActive}"
                          [(ngModel)]="template.isActive"
                          name="templateinActive"
                          (click)="template.isActive = false;"
                          btn-radio="false">
                    Inactive
                  </button>
                </div> 
              </div>
              <button type="button" class="btn btn-white m-0" [disabled]="isCloneInProgress  " (click)="saveTemplate(updateTemplateForm.valid)"><i class="fa fa-save" style="color:orange"></i></button>
              <button type="button" class="btn btn-white" (click)="setReadMode()"><i class="fa fa-ban"></i></button>
            

            </form>
            <button type="button"
                    class="btn btn-primary pull-right"
                    (click)="openPositionModal()"
                    authenticate-user="position.create"
                    [disabled]="!reorderButtonInactive">+ Attach a Position</button>
          </div>
          <div class="content-filters" *ngIf="template?.positions?.length>0">
            <div class="row">
              <div class="col-sm-6">
                <form class="form-inline form-sort-options" (submit)="searchTemplates()">
                  <div class="form-group">
                    <input class="form-control"
                           restrict-characters="restrict-characters"
                           type="text"
                           name="searchText"
                           [(ngModel)]="filter.searchText"
                           maxlength="100"
                           appInputRestriction="restrictChars" />
                    <button type="submit" class="btn btn-secondary" (click)="searchPositions()">Search</button>
                    <button type="button" class="btn btn-default" (click)="clearSearch()">Clear</button>
                  </div>
                </form>
              </div>
              <div class="col-sm-6">
                <div class="form-group pull-right">
                  <button type="button" class="btn btn-default" (click)="savePositionsOrder()" authenticate-user="position.update" *ngIf="!reorderButtonInactive">Save Order</button>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="(template?.positions?.length<=0)">
                  <em>Click the Attach a Position button to create your first position for this template</em>
                </div>
                <div *ngIf="template.positions?.length>0 && showGrid" class="table-responsive">
                  <table class="table table-striped table-sortable">
                    <thead>
                      <tr>
                        <th>
                          <a href="#" (click)="sortByColumn('sortOrder');$event.preventDefault();">
                            List Order
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'sortOrder'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </a>
                        </th>
                        <th>
                          <a href="#" (click)="sortByColumn('position.description');$event.preventDefault();">
                            Position
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'position.description'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </a>
                        </th>
                        <th>
                          <a href="#" (click)="sortByColumn('position.category');$event.preventDefault();">
                            Category
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'position.category'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </a>
                        </th>
                        <th>
                          <a href="#" (click)="sortByColumn('position.department');$event.preventDefault();">
                            Department
                            <span class="th-sort">
                              <span *ngIf="sort.orderByField == 'position.department'">
                                <i *ngIf="!sort.reverseSort" class="fa fa-caret-up"></i>
                                <i *ngIf="sort.reverseSort" class="fa fa-caret-down"></i>
                              </span>
                            </span>
                          </a>
                        </th>
                        <th>
                          Skill Sets
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody dragula="position" [dragulaModel]="template.positions">
                      <tr  *ngFor="let positionItem of template.positions | filterBy:['position.description']:filter.searchText  | orderBy: sort.orderFieldname"
                          [ngClass]="{'unsortable': (sort.orderByField !== 'sortOrder') || (sort.orderByField === 'sortOrder' && sort.reverseSort) }">
                        <td>
                          <input type="number"
                                 [(ngModel)]="positionItem.sortOrder"
                                 name="positionSortOrder"
                                 (blur)="sortOrderNumEntered(positionItem)"
                                 class="form-control form-control-xs input-sm input-number text-center"
                                 authenticate-user="position.update"
                                 authenticate-command="disabled"
                                 [authenticate-disabled]="sortProcessing" />
                        </td>
                        <td><span class="text-uppercase">{{positionItem.position.description}}</span></td>
                        <td><span class="text-uppercase">{{positionItem.position.category}}</span></td>
                        <td>{{positionItem.position.department}}</td>
                        <td>
                          <span *ngFor="let skill of positionItem.skills; let $last=last">
                            {{skill.description}}<span *ngIf=" ! $last ">,</span>
                          </span>
                        </td>
                        <td class="text-end table-action-icons">
                          <span authenticate-user="position.update"
                                class="btn btn-icon-clear position-template-handle ui-sortable-handle"
                                *ngIf="!(sort.orderByField !== 'sortOrder') || (sort.orderByField === 'sortOrder' && sort.reverseSort)"><i class="fa fa-arrows dragable"></i></span>
                          <a authenticate-user="position.update"
                             title="To Enable Drag and Drop Sort by List Order"
                             class="btn btn-icon-clear disabled"
                             *ngIf="(sort.orderByField !== 'sortOrder') || (sort.orderByField === 'sortOrder' && sort.reverseSort)">
                          <i class="fa fa-arrows dragable"></i>
                          </a>
                          <button authenticate-user="position.update"
                                  type="button"
                                  class="btn btn-icon-clear"
                                  (click)="openPositionModal(positionItem)"><i class="fa fa-pencil"></i></button>
                          <button authenticate-user="position.delete"
                                  type="button"
                                  class="btn btn-icon-clear"
                                  (click)="openDeletePositionModal(positionItem)"><i class="fa fa-times"></i></button>
                        </td>
                      </tr>
                      <tr *ngIf="!template.positions">
                        <td colspan="6">No results</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
