import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class eventManagerService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    // this.eventManager = function (eventManagers) {
    //     log.trace("getting eventManagerIds: " + JSON.stringify(eventManagers));
    //     return $http({
    //         url: apiRoot + "eventManager/",
    //         params: { profileTypeIds: eventManagers },
    //         method: "GET"
    //     });
    // };

    eventManager(eventManagers: any): Observable<any> {
        this.log.trace("tierratecard: get event tier setup");
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "eventManager" +
                    "/?profileTypeIds=" +
                    eventManagers
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
