import { Component, Input, OnInit } from "@angular/core";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-budget-bar",
    templateUrl: "./nbc-budget-bar.component.html",
})
export class NbcBudgetBarComponent implements OnInit {
    constructor(private log: NbcLoggerService) {}

    totalBudget = 0;
    usedBudget = 0;
    usedBudgetPercentage: any;
    differencePercentage: any;

    @Input() budgetTotal;
    @Input() budgetUsed;

    filledBudgetBar() {
        this.totalBudget = this.budgetTotal;
        this.usedBudget = this.budgetUsed;
        this.usedBudgetPercentage = 0;
        this.differencePercentage = 0;

        if (this.totalBudget == 0 && this.usedBudget == 0) {
            this.usedBudgetPercentage = 0;
            this.differencePercentage = 0;
        } else if (this.totalBudget == 0 && this.usedBudget != 0) {
            this.usedBudgetPercentage = 0;
            this.differencePercentage = 0;
        } else {
            this.usedBudgetPercentage =
                (this.usedBudget / this.totalBudget) * 100;
            this.differencePercentage =
                ((this.totalBudget - this.usedBudget) / this.totalBudget) * 100;
        }
        this.differencePercentage = Math.round(this.differencePercentage);
    }
    ngOnInit() {
        this.log.debug("starting the progressbar");
        this.filledBudgetBar();
    }
}
