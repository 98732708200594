import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { Observable, throwError } from "rxjs";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ContactService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    searchByName(term: string): Observable<any> {
        // this.log.trace("searching a contacts name " + JSON.stringify(term));
        return this.http
            .get(this._apiroot.baseUrl + "contacts/searchbyname/?term=" + term)
            .pipe(map((data: any) => (data && data.reference) || []));
    }

    workedWith(term: any): Observable<any> {
        // this.log.trace("retrieving directors and producers" + JSON.stringify(term));
        return this.http
            .get(this._apiroot.baseUrl + "contacts/workedWith/" + term)
            .pipe(
                map((data: any) => (data && data.reference) || []),
                catchError((err) => {
                    return [];
                })
            );
    }

    getContact(id: any): Observable<any> {
        // this.log.trace("searching a contacts by id " + JSON.stringify(id));
        return this.http.get(this._apiroot.baseUrl + "contacts/" + id).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    search(searchObj: any): Observable<any> {
        //this.log.trace("calling contact search for object" + JSON.stringify(searchObj));
        return this.http
            .post<any>(this._apiroot.baseUrl + "contacts/search/", searchObj)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    decrypt(field: any, contact: any): Observable<any> {
        // this.log.trace("calling contact decrypt for object" + JSON.stringify(contact));
        return this.http.post<any>(
            this._apiroot.baseUrl + "contacts/decrypt/" + field,
            contact
        );
    }

    getWorkHistory(query: any): Observable<any> {
        this.log.trace(
            "get work history for contact" + JSON.stringify(query.contactId)
        );
        // this is a post bc we are paginating these results and need to send the search query (including currentPage)
        return this.http.post<any>(
            this._apiroot.baseUrl + "contacts/workHistory/",
            query
        );
    }

    getWorkSchedule(
        contactId: any,
        startDate: any,
        endDate: any
    ): Observable<any> {
        this.log.trace("get work schedule for " + JSON.stringify(contactId));
        return this.http.get(
            this._apiroot.baseUrl +
                "contacts/" +
                contactId +
                "/workSchedule/?startDate=" +
                startDate +
                "&endDate=" +
                endDate
        );
    }

    getMatchingRecordsFromSAPIDM(contact: any): Observable<any> {
        this.log.trace(
            "retrieving matching data from SAP/IDM " + JSON.stringify(contact)
        );
        return this.http
            .post<any>(
                this._apiroot.baseUrl + "contacts/searchSAPIDMRecords",
                contact
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    syncContact(contact: any): Observable<any> {
        //  this.log.trace("sync Contact " + JSON.stringify(contact));
        return this.http
            .post<any>(this._apiroot.baseUrl + "contacts/sync", contact)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getEmergencyContact(contact: any): Observable<any> {
        //  this.log.trace("emergency contact " + JSON.stringify(contact));
        return this.http
            .post<any>(
                this._apiroot.baseUrl + "contacts/emergencycontact",
                contact
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    updateCustomField(contactId: any, customField: any): Observable<any> {
        this.log.trace("update custom fields" + JSON.stringify(customField));
        return this.http.post<any>(
            this._apiroot.baseUrl + "contacts/updatecustom/" + contactId,
            customField
        );
    }

    upsertContact(contact: any): Observable<any> {
        //  this.log.trace("upsert contact " + JSON.stringify(contact));
        return this.http
            .post<any>(this._apiroot.baseUrl + "contacts/", contact)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    contactEmailAddressMatchSearch(searchObj: any): Observable<any> {
        this.log.trace(
            "calling search for matching email" + JSON.stringify(searchObj)
        );
        return this.http.post<any>(
            this._apiroot.baseUrl + "contacts/searchByEmail",
            searchObj
        );
    }
    getDefaultContactQuery() {
        this.log.trace("get the default object for contact query");

        const defaultContactQuery = {
            currentPage: 1,
            pageSize: 10,
            // isActive: new triStateAnswerFactory.TriStateAnswer("Active", "Inactive"),
            id: null,
            firstName: "",
            middleName: "",
            lastName: "",
            positions: [],
            skills: [],
            eventType: {
                id: 0,
                name: "",
            },
            seasonId: 0,
            season: {
                id: 0,
                name: "",
            },
            eventId: 0,
            event: {
                id: 0,
                description: "",
            },
            venue: null,
            email: "",
            phone: "",
            sso: "",
            perNer: "",
            union: {
                id: 0,
                description: "",
            },
            distance: "1",
            location: "1",
            countryCode: "",
            city: "",
            state: "",
            dob: "",
            lastFourSsn: "",
            workedWith: 0,
            sortBy: "LastName",
            workedAtVenue: false,
            crewedPositions: [],
            searchVenueId: 0,
            employeeType: {
                id: 0,
                description: "",
            },
            searchLocation: "",
            searchLocations: [],
            radius: {
                id: 0,
                description: "",
            },
        };

        return defaultContactQuery;
    }
    verifyContact(contact: any): Observable<any> {
        // this.log.trace("Verifying Contact " + JSON.stringify(contact));
        return this.http
            .post<any>(this._apiroot.baseUrl + "contacts/sync", contact)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    getWorkScheduleAll(query: any): Observable<any> {
        // this.log.trace("get work schedule for " + JSON.stringify(query.contactId));
        return this.http
            .post<any>(this._apiroot.baseUrl + "contacts/workSchedule/", query)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
