<form #savePosition="ngForm">
  <div class="modal-header d-block">
    <div class="row">
      <div class="col-sm-8 d-flex">
        <input [(ngModel)]="positionSearchTerm"
               appInputRestriction="restrictChars"
               name="positionName"
               class="form-control form-control-ttl float-start me-3 "
               [typeahead]="positionObservable$"
               [typeaheadAsync]="true"
               typeaheadOptionField="description"
               [typeaheadMinLength]="1"
               [typeaheadOptionsLimit]="10"
               placeholder="Position Name"
               [isAnimated]="true"
               [typeaheadWaitMs]="200"
               required
               #positionName="ngModel"
               [disabled]="disablePosition"
               (typeaheadOnSelect)="onPositionSelect($event)">
      <span class="modal-category-dept d-flex">
        {{model.eventPosition.category}}<br />{{model.eventPosition.department}}
      </span>
      </div>
      <button type="button" class="btn-close me-3" aria-hidden="true" (click)="cancel()"></button>
    </div>
  </div>
  <div class="modal-body">
    <nbc-modal-alert></nbc-modal-alert>
    <div class="row">
      <div class="col-sm-8">
        <div class="form-group">
          <label for="jobTitleDetail" class="control-label">Job Title Detail</label>
          <input class="form-control"
                 name="jobTitleDetail"
                 [(ngModel)]="model.eventPosition.jobTitleDetail"
                 maxlength="250"
                 appInputRestriction="restrictChars" />
        </div>
        <div class="form-group">
          <label for="skills" class="control-label">Skill Set</label>
          <div *ngIf="skillData?.length>0">
            <nbc-tags [(searchTerm)]="skillSearchTerm"
                      [sourceData]="skillData"
                      [asyncLoad]="false"
                      [tagDisplay]="'description'"
                      [tagId]="model.eventPosition.skills"
                      [tagName]="model.eventPosition.skills"
                      [tags]="model.eventPosition.skills"
                      [placeholder]="'Add Skill'"
                      (tagChange)="setSkillEventTerm($event)"
                      [nbcMinLength]="0"
                      (nbcOnOptionSelect)="onSkillSelected($event)"></nbc-tags>
          </div>

          <div *ngIf="skillData?.length==0">
            <input tagsinput type="text" class="form-control" placeholder="Add Skill" disabled />
          </div>
        </div>
        <div class="form-group">
          <label for="notes" class="control-label">Notes</label>
          <input class="form-control"
                 name="notes"
                 [(ngModel)]="model.eventPosition.notes"
                 maxlength="250"
                 appInputRestriction="restrictChars" />
        </div>
        <div class="row">
          <div>
            <div class="form-group col-md-6" *ngIf="!model.id">
              <label class="control-label">Number of Positions</label>
              <input class="form-control form-control-xs"
                     [(ngModel)]="numPositions"
                     name="numberOfPosition"
                     type="text"
                     maxlength="2"
                     appInputRestriction="numberOnly" />
            </div>
          </div>
          <!--<div>
              <div class="form-group col-md-6" *ngIf="!model.id">
                  <label class="control-label">Sort Order</label>
                  <input class="form-control form-control-xs" [(ngModel)]="model.sortOrder" type="text" maxlength="3" numbers-only />
              </div>
          </div>-->
        </div>
      </div>
      <div class="col-sm-4" *ngIf="!applyTierRateRules()">
        <label class="control-label">Employee Type</label>
        <div class="form-group">
          <nbc-select name="employeeType"
                      [source]="employeeTypes"
                      value="id"
                      display="description"
                      [(model)]="model.eventPosition.employeeType"
                      [nbcAddBlank]="true"
                      (change)="getRateCards(model.eventPosition.employeeType.id)">
          </nbc-select>
        </div>
        <label class="control-label">Rate Card</label>
        <div class="form-group"> 
          <div *ngIf="rateCodes && rateCodes.length >0 && model.eventPosition && !disableRateCode">
            <nbc-select *ngIf="!disableRateCode"
                        name="rateCodes"
                        [source]="rateCodes"
                        value="id"
                        display="name"
                        [(model)]="model.eventPosition.rateCode"
                        [nbcDisabled]="disableRateCode"
                        [nbcAddBlank]="true"
                        (change)="checkRateCard();customRate=''">
            </nbc-select>
          </div>
          <div *ngIf="!rateCodes || rateCodes.length==0 && disableRateCode">
            <span class="disabled" *ngIf="disableRateCode">N/A</span>
          </div>
          <div class="vendor" *ngIf="!!model.eventPosition.rateCards && model.eventPosition.rateCards.length">
            <select class="form-control" disabled></select>
          </div>
        </div>
        <div *ngIf="model.eventPosition?.rateCode?.id === '00'">
          <label class="control-label">Custom Rate</label>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-text">$</div>
              <input type="text"                                          
                     nbc-decimal
                     decimals="3"
                     [(ngModel)]="customRate"
                     class="form-control custom-flat-rate-field"
                     maxlength="7"
                     name="customRateHourly"
                     (blur)="customRateChanged()" />
            </div>
          </div>
        </div>
        <div *ngIf="model.eventPosition.rateCode?.id === 'FLAT DAY'">
          <label class="control-label">Custom Rate</label>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-text">$</div>
              <input type="text"
                     [(ngModel)]="customRate"
                     class="form-control custom-flat-rate-field"
                     maxlength="8"
                     name="flatRateInput"
                     nbc-decimal
                     decimals="3"
                     (blur)="customRateChanged()">
            </div>
          </div>
        </div>
      </div>
      <!-- STARTS: TIER RATE CARD-->

      <div class="col-sm-4" *ngIf="applyTierRateRules()">
        <label class="control-label">Employee Type</label>
        <div class="form-group">
          <nbc-select name="TierrateEmployeeTypes"
                      [source]="employeeTypes"
                      value="id"
                      display="description"
                      [(model)]="model.eventPosition.employeeType"
                      [nbcAddBlank]="true"
                      (change)="tierEmployeeTypeChanged()">
          </nbc-select>
        </div>
        <label class="control-label">Tiered Rate Card</label>
        <div class="form-group">
          <div>
            <span *ngIf="tierRateCards?.length>0 && model.eventPosition.employeeType && (model.eventPosition?.employeeType?.description?.toLowerCase() !== 'vendor')">
              <nbc-select name="TierrateRateCode"
                          [source]="tierRateCards"
                          value="billing_code"
                          display="description"
                          [(model)]="model.eventPosition.rateCode"
                          [nbcDisabled]="disableRateCode"
                          [nbcAddBlank]="true"
                          (change)="associateToPosition();customRate=''">
              </nbc-select>
            </span>
            <span class="disabled"
                  *ngIf="!model.eventPosition.employeeType || model.eventPosition?.employeeType?.description?.toLowerCase() === 'vendor'">
              N/A
            </span>
          </div>
          <div class="vendor" *ngIf="!!model.eventPosition.rateCards && model.eventPosition.rateCards.length==0">
            <select class="form-control" disabled></select>
          </div>
        </div>
        <div *ngIf="model.eventPosition.employeeType && model.eventPosition.rateCode && model.eventPosition.employeeType?.description != 'vendor'">
          <label class="control-label">Custom Rate</label>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-text">$</div>
              <input type="text"
                     name="tierCustomRate"
                     restrict-characters
                     [(ngModel)]="customRate"
                     class="form-control"
                     maxlength="10"
                     (click)="openEditTierRatecardModal()"
                     numbers-only readonly="readonly" />
            </div>
          </div>
        </div>
      </div>


      <!-- ENDS: TIER RATE CARD-->

    </div>
    <div class="form-group">
      <div class="float-end" *ngIf="model.eventPosition.workScheduleDates?.length > 5">
        <button class="btn btn-xs btn-icon"
                (click)="showPrevDates()"
                [disabled]="crewStartDisplay <= 0">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button class="btn btn-xs btn-icon"
                (click)="showNextDates()"
                [disabled]="(crewEndDisplay + 1) >= model.eventPosition.workScheduleDates?.length">
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
      <label class="control-label">Work Schedule</label>
      <button aria-hidden="true" class="d-none"></button>
      <div class="grid-content">
        <table class="table table-condensed table-bordered table-workschedule">
          <thead>
            <tr>

              <th *ngFor="let workScheduleDate of model.eventPosition.workScheduleDates;let $index=index;"
                  class="text-center"
                  [hidden]="!($index >= crewStartDisplay && $index <= crewEndDisplay)">

                {{workScheduleDate.displayDay}}<br />
                {{workScheduleDate.displayDate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngFor="let workScheduleDate of model.eventPosition.workScheduleDates;let $index=index;"
                  class="text-center"
                  [hidden]="!($index >= crewStartDisplay && $index <= crewEndDisplay)"
                  (click)="setWorkSchedulePopover(workScheduleDate,model.id,$index)">
                <div class="ns-popover-btn-wrapper"
                     style="min-height: 50px;"
                     [popover]="popTemplate" placement="bottom" [outsideClick]="true" [delay]="100">
                  <span class="activity activity-{{workActivity.code|lowercase}}"
                        *ngFor="let workActivity of workScheduleDate?.workActivities |filterBy:['isPlanned']:true"
                        [ngClass]="{'activity-1':((workScheduleDate.workActivities|filterBy:['isPlanned']:true).length === 1),
                        'activity-2':((workScheduleDate.workActivities|filterBy:['isPlanned']:true).length === 2),
                        'activity-3':((workScheduleDate.workActivities|filterBy:['isPlanned']:true).length === 3),
                        'activity-4':((workScheduleDate.workActivities|filterBy:['isPlanned']:true).length === 4)}">
                    <span *ngIf="(workScheduleDate.workActivities|filterBy:['isPlanned']:true).length >= 3">
                      {{workActivity.code[0]}}
                    </span>
                    <span *ngIf="(workScheduleDate.workActivities|filterBy:['isPlanned']:true).length < 3">
                      {{workActivity.code}}
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <div class="modal-footer text-center">
    <button type="button" class="btn btn-primary" [disabled]="isSaveDisabled" (click)="savePositionData(savePosition.valid)">Save</button>
    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
  </div>
</form>

<ng-template #popTemplate>
  <div work-activity *ngIf="workSchedulePopover?.showPopover"
       [(toggle)]="workSchedulePopover.showPopover"
       maxAllowedActivity="4"
       id="{{workSchedulePopover.id}}"
       day="{{workSchedulePopover.index}}"
       (workActivitiesChange)="saveWorkScheduleDay($event)"
       parentId="{{workSchedulePopover.parentId}}"
       [workActivities]="workSchedulePopover.workActivities"></div>
</ng-template>
