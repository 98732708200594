<div class="modal-header">
  <h4 class="modal-title">Timesheet Submission</h4>
  <button type="button" class="btn-close"
          aria-hidden="true"(click)="closeModal()">
  </button>
</div>

<div class="modal-body modal-agenda-body">
  <nbc-alert></nbc-alert>
  <div *ngIf="modal.error">
    <p>You have potential conflicts with your time entries. Do you want to ignore the conflicts and submit them as is?</p>
  </div>
  <div *ngIf="!modal.error">
    <div *ngIf="category === 'BTL'">
      <p>You're about to submit all BTL timesheets to payroll. You will no longer be able to update the time entries for these contacts after submission.</p>
      <p>Do you want to continue?</p>
    </div>
    <div *ngIf="category === 'ATL'">
      <p>You're about to submit all ATL timesheets to payroll. You will no longer be able to update the time entries for these contacts after submission.</p>
      <p>Do you want to continue?</p>
    </div>
    <div *ngIf="category === 'All'">
      <p>You're about to submit the timesheet  to payroll. You will no longer be able to update the time entries after submission.</p>
      <p>Do you want to continue?</p>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-default" (click)="closeModal()">Cancel</button>
  <span *ngIf="modal.error">
    <button class="btn btn-primary" (click)="modal.error = false">Continue</button>
  </span>
  <span *ngIf="!modal.error">
    <button class="btn btn-primary" (click)="submitPayrollBtn()" [disabled]="!isSubmitButtonEnabled" >Submit to Payroll</button>
  </span>
</div>
