<div>
  <form>
    <div class="modal-header">
      <h4 class="modal-title">Timesheet Export</h4>
      <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
    </div>
    <div class="modal-body modal-agenda-body">
      <nbc-modal-alert></nbc-modal-alert>
      <div>
        <p>{{reportHeader}}</p>
      </div>
      <div *ngIf="contactList">
        <span class="checkbox" *ngFor="let item of contactList">
          <label>
            <input type="checkbox"
                   name="contactList"
                   [(ngModel)]="item.isSelected"
                   [ngModelOptions]="{standalone: true}"
                   (change)="addContactToList(item)"> {{item.description}}
          </label>
        </span>
        <input type="hidden" name="contactIds" [ngModel]="contactIds" />

      </div>

    </div>
    <div class="modal-footer text-right">
      <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary" type="submit" (click)="Export()" [disabled]="contactIds.length <= 0">Export</button>
    </div>
  </form>
</div>
<app-nbc-file-download></app-nbc-file-download>
