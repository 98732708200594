import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedDataService } from "../Shared/sharedData.service";
import { TabsetComponent } from "ngx-bootstrap/tabs";

@Component({
    selector: "payroll",
    templateUrl: "./payroll.component.html",
    //styleUrls: [
    //  "../../assets/Content/app/areas/events/events.css",
    //  "../../assets/Content/app/areas/payroll/payroll.css"],
    //encapsulation: ViewEncapsulation.None
})
export class PayrollComponent implements OnInit {
    constructor(
        private _sharedData: SharedDataService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}
    _: any;

    @ViewChild("tabset") tabset: TabsetComponent | undefined;
    buttonText!: string | "+ Create an Event";
    tabs = [
        {
            heading: "SUBMITTED TIMESHEETS",
            route: "submitted",
            active: true,
        },
        {
            heading: "UPCOMING EVENTS",
            route: "upcomingEvents",
            active: false,
        },
    ];

    go(route: string) {
        _.forEach(this.tabs, function (tab, index) {
            tab.active = false;
            if (tab.route === route) {
                tab.active = true;
            }
        });
        this.router.navigate([route], { relativeTo: this.activatedRoute });
    }

    activateSelectedTab() {
        const activatedRoute =
            this.activatedRoute.snapshot?.firstChild?.routeConfig?.path;
        if (activatedRoute) {
            _.forEach(this.tabs, (rt) => {
                rt.active = false;
                if (activatedRoute?.toLowerCase() === rt.route.toLowerCase()) {
                    rt.active = true;
                }
            });
        }
    }

    associateUrl() {
        const elem = document.querySelector(".tab-container ul");
        if (elem) {
            for (let i = 0; i < elem.children.length; i++) {
                elem.children[i]
                    ?.querySelector("a")
                    ?.setAttribute("href", this.getLocationRoute(i));
            }
        }
    }

    getLocationRoute(index) {
        const arr = window.location.href.split("/");
        arr.pop();
        arr.push(this.tabs[index].route);
        return arr.join("/");
    }

    ngOnInit() {
        this._sharedData.setCss("payroll");
        this.activateSelectedTab();
    }
}
