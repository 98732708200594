import { Component, OnInit } from '@angular/core';
import { NbcLoggerService } from '../Shared/logger/nbc-logger.service';
import { EmitEvent, EventBusService } from '../Core/services/event-bus.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { tierConfigChangeImpactService } from '../Shared/Services/tier-config-change-Impact.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RemotesQueryService } from '../Shared/Services/remotesquery.service';
import { NbcModalConfirmation } from '../Shared/components/confirmation/nbc-modal-confirmation.component';
import { modalImpactedEventsList } from '../TierRatecard/impact-modal/modal-Impacted-Events.component';
import { OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'network-group-impact-analysis',
  template: '<div></div>'
})
export class NetworkGroupImpactAnalysisComponent implements OnInit, OnDestroy {
  constructor(
    private _tierConfigChangeImpactService: tierConfigChangeImpactService,
    private _remotesQueryService: RemotesQueryService,
    private eventBus: EventBusService,
    private log: NbcLoggerService,
    public _modalService: BsModalService
  ) {
    this.query = _remotesQueryService.getTierConfigChangeQuery();
  }

  private subs = new SubSink();
  bsModalRef?: BsModalRef;
  query: any;
  baseCopyOfConfig: any;
  changeTracker: any;
  currentChangedNetwork: any;
  eventChangeParams: any;

  message: string = '';
  impactedEvents: any | undefined;
  componentDestroyed$: Subject<boolean> = new Subject();

  resetVariables() {
    this.query = this._remotesQueryService.getTierConfigChangeQuery();
  }

  openImpactedEventListModal(callingChange) {
    this.log.trace('Opening Imapcted Event modal');

    const initialState: any = {
      self: this,
      queryData: this.query,
      message: this.message,
      modalTitle: 'Tier Rate Card'
    };
    let modalOptions = {
      animated: true,
      initialState: initialState
    };
    this.bsModalRef = this._modalService.show(
      modalImpactedEventsList,
      modalOptions
    );
    this.bsModalRef.content.onSync.subscribe(result => {
      if (result) {
        switch (callingChange) {
          case 'network':
            this.networkGroupProceedClicked(this.currentChangedNetwork);
            break;
          case 'eventType':
            this.eventTypeProceedClicked();
            break;
        }
      } else {
        switch (callingChange) {
          case 'network':
            this.cancelNetworkGroupAction(this.currentChangedNetwork);
            break;
          case 'eventType':
            this.eventTypeChangeCancelled();
            break;
        }
      }
    });
  }

  getFlatEventTypeList(networkGroup) {
    var eventTypes: any = [];
    if (!_.isUndefined(networkGroup) && networkGroup.eventTiers) {
      for (var i = 0; i < networkGroup.eventTiers.length; i++) {
        var eventTier = networkGroup.eventTiers[i];
        eventTypes.push(_.map(eventTier.eventTypes, 'id'));
      }
    }
    return eventTypes.join(',');
  }

  getMessage(isTieredGroup, isNewAdd) {
    if (isTieredGroup) {
      if (isNewAdd) {
        this.message =
          'Moving Network to Tiered Network Group will impact all future events listed below: ';
      } else {
        this.message =
          'Moving Network to Non-Tiered Network Group will impact all future events listed below:';
      }
    } else {
      if (isNewAdd) {
        this.message =
          'Moving Network to Non-Tiered Network Group will impact all future events listed below:';
      } else {
        this.message =
          'Moving Network to Tiered Network Group will impact all future events listed below: ';
      }
    }
  }

  onTierChangedImpact(data) {
    this.onTierCountChangedImpact(data.networkGroup, data.baseCopyOfConfig);
    this.log.trace(data);
  }

  //#region "TIER COUNT CHANGE"
  onTierCountChangedImpact(networkGroup, pristineObj) {
    //if the network group had never tier enabled, other possibilities of impact will not arise. So, we are using
    //pristin object. We will ignore recent change of tier enabled as tiers wont exists earlier in notmal business scenario. & it is not allowed to deactivate the tier
    if (!_.isUndefined(pristineObj) && !pristineObj.isTierEnabled) {
      return;
    }
    var flattenEventTypes = this.getFlatEventTypeList(pristineObj);
    var hasEventTypes = _.isEmpty(flattenEventTypes) ? false : true;
    var hasPublished =
      !_.isUndefined(networkGroup) &&
        !_.isEmpty(networkGroup.networkGroupPublish.asOfDate)
        ? true
        : false;

    var hasTierCountIncreased =
      !_.isUndefined(networkGroup) && !_.isUndefined(pristineObj)
        ? networkGroup.numberOfTiers > pristineObj.numberOfTiers
        : false;

    if (
      !_.isUndefined(networkGroup) &&
      !_.isUndefined(pristineObj) &&
      networkGroup.numberOfTiers === pristineObj.numberOfTiers
    ) {
      //TODO
      //  this.$emit("onTierCountChangeConfirmed", "nochange");
    } else if (hasTierCountIncreased) {
      this.WhenNumberOfTierCountIncreased(hasEventTypes, hasPublished);
    } else {
      this.WhenNumberOfTierCountDecreased(
        hasPublished,
        networkGroup,
        pristineObj
      );
    }
  }

  WhenNumberOfTierCountIncreased(hasEventTypes, hasPublished) {
    /*for count increased scenario, we have 3 acceptance critieria. 
     * 1.# of Tiers count change (Increased)-Before publish
     * 2.After publish but effective date is not current
     * 3.After publish and effective date is current (Ex: Existing 2, select 4)
     */
    /*above conditions have mostly same result 1. Adding row 2.Unpublishing the tier*/
    //TODO
    // this.$emit("onTierCountChangeConfirmed", "increased");
  }

  WhenNumberOfTierCountDecreased(hasPublished, networkGroup, pristineObj) {
    var hasEventTypesInRemovedTiers = false;

    // if the tier to be removed has any event type associated
    if (!_.isUndefined(pristineObj) && !_.isEmpty(pristineObj.eventTiers)) {
      for (var i = 0; i < pristineObj.eventTiers.length; i++) {
        if (i < networkGroup.numberOfTiers) continue;

        var eventTypes = _.map(pristineObj.eventTiers[i].eventTypes, 'id');
        if (!_.isEmpty(eventTypes)) {
          hasEventTypesInRemovedTiers = true;
        }
      }
    }

    // if associated
    if (hasEventTypesInRemovedTiers) {
      var msg =
        'There is at least one Event Type associated to this Tier so it cannot be removed.';
      const initialState: any = {
        self: this,
        modalTitle: 'Tier Rate Card',
        modalMessage: msg,
        confirmText: 'OK',
        cancelText: '',
        hideConfirm: false
      };
      let modalOptions = {
        animated: true,
        initialState: initialState
      };

      this.bsModalRef = this._modalService.show(
        NbcModalConfirmation,
        modalOptions
      );
      this.bsModalRef.content.onAffirmation.subscribe(result => {
        this.eventBus.emit(
          new EmitEvent(
            'modal-network-group.component',
            'onTieredCountRevert',
            pristineObj
          )
        );
      });
      return;
    } else {
      this.eventBus.emit(
        new EmitEvent(
          'modal-network-group.component',
          'onTierCountChangeConfirmed',
          { action: 'decreased' }
        )
      );
    }
  }

  ParticipatingNetworkChanged(networkGroupConfig, network) {
    if (!_.isUndefined(network)) {
      this.query.networkId = network.id;
      this.query.eventTypeId = this.getFlatEventTypeList(networkGroupConfig);
      this.getMessage(
        !_.isUndefined(networkGroupConfig)
          ? networkGroupConfig.isTierEnabled
          : false,
        network.isSelected
      );
      this.getImpactedFutureEvents(false, 'network');
    }
  }

  onEventTypeAssociatedToGroup(arg: any) {
    this.eventChangeParams = arg;
    this.getFlatNetworkList(arg.networkGroup);
    //get selected element
    const draggedElement = arg.item;

    if (draggedElement) {
      this.query.eventTypeId = draggedElement.id;
      this.query.networkId = this.getFlatNetworkList(arg.networkGroup);

      const message =
        'This Tier Change will impact all future events listed below:';
      this.getImpactedFutureEvents(message, 'eventType');
    }
  }

  getFlatNetworkList(networkGroup: any) {
    let networks: any[] = [];

    networks.push(_.map(networkGroup.networks, 'id'));

    return networks.join(',');
  }

  //  getImpactedFutureEvents  (proceedCallback, args, CancelCallback, cancelArgs,showNoRecordMessage) {
  getImpactedFutureEvents(showNoRecordMessage, callingChange?) {
    this._tierConfigChangeImpactService
      .getImpactedFutureEvents(this.query)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        result => {
          this.impactedEvents = result;
          if (
            (this.impactedEvents && this.impactedEvents['totalRecords'] > 0) ||
            showNoRecordMessage
          ) {
            this.openImpactedEventListModal(callingChange);
          } else {
            switch (callingChange) {
              case 'network':
                this.networkGroupProceedClicked(this.currentChangedNetwork);
                break;

              case 'eventType':
                this.networkGroupProceedClicked(this.currentChangedNetwork);
                break;
            }
          }
        },
        err => {
          this.log.showError('unable to find change impact. Please try later.');
        }
      );
  }

  cancelNetworkGroupAction(changedNetwork) {
    this.resetVariables();
    this.eventBus.emit(
      new EmitEvent(
        'tier-ratecard-impact-analysis',
        'onNetworkStateChangeCancelled',
        { changedNetwork: changedNetwork }
      )
    );
  }

  networkGroupProceedClicked(network) {
    this.eventBus.emit(
      new EmitEvent(
        'tier-ratecard-impact-analysis',
        'onNetworkStateChangeConfirmed',
        { network: network }
      )
    );
  }

  eventTypeProceedClicked() {
    this.eventBus.emit(
      new EmitEvent(
        'tier-ratecard-impact-analysis',
        'onEventTypeProceedClicked',
        this.eventChangeParams
      )
    );
  }
  eventTypeChangeCancelled() {
    this.eventBus.emit(
      new EmitEvent(
        'tier-ratecard-impact-analysis',
        'onEventTypeChangeCancelled',
        this.eventChangeParams
      )
    );
  }

  onNetworkStateChangedImpact(data) {
    this.currentChangedNetwork = data.network;
    this.ParticipatingNetworkChanged(data.networkGroup, data.network);
  }

  ngOnInit() {
    this.subs.sink = this.eventBus.on('TierRatecardNetworkChanged', data => {
      this.onNetworkStateChangedImpact(data);
    });

    this.subs.sink = this.eventBus.on('TierChangedImpact', data => {
      this.onTierChangedImpact(data);
    });

    this.subs.sink = this.eventBus.on('onEventTypeAssociatedToGroup', params => {
      this.onEventTypeAssociatedToGroup(params);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
