
import { Injectable } from "@angular/core";
import { Subject, Subscription, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class EventBusService {
  constructor() { }

  private subject = new Subject<any>();

  on(event: string, action: any): Subscription { 
    return this.subject
      .pipe(
        filter((e: EmitEvent) => {
          return e.method === event;
        }),
        map((event: EmitEvent) => {
          return event.value;
        })
      )
      .subscribe(action);
  }

  emit(event: EmitEvent) { 
    this.subject.next(event);
  }

}

export class EmitEvent {
  constructor(
    public caller: any,
    public method: any,
    public value: any) { }
}



