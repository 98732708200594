<div>
    <div class="modal-header">
        <h3 class="modal-title"
            *ngIf="!model.id">Create New Season</h3>
        <h3 class="modal-title"
            *ngIf="model.id">Edit Season</h3>
        <button type="button" class="btn-close"
                aria-hidden="true" (click)="cancel()">
        </button>
    </div>
    <form #createSeasonForm="ngForm">
      <div class="modal-body">

        <div class="form-group"
             [ngClass]="{'has-error': seasonName.invalid && createSeasonForm.submitted}">
          <label for="seasonName">Season Name</label>
          <input type="text"
                 appInputRestriction=restrictChars
                 class="form-control"
                 #seasonName="ngModel"
                 id="seasonName"
                 name="seasonName"
                 required
                 [(ngModel)]="model.name"
                 authenticate-required="true"
                 maxlength="50"
                 authenticate-user="event.update"
                 authenticate-command="disabled"
                 (blur)="changeHandler(createSeasonForm)" />
          <span class="control-label control-label-error"
                *ngIf="createSeasonForm.submitted && seasonName.errors?.required">Season name is required.</span>
        </div>
        <div class="form-group"
             [ngClass]="{'has-error': dateRange.invalid && createSeasonForm.submitted}">
          <label for="dateRange">Date Range</label>
          <div class="input-group input-group-set max-input"
               dropdown
               is-open="dateRangeFilter">
            <span class="input-group-text"
                  dropdown-toggle><i class="fa fa-calendar"></i></span>
            <input type="text"
                   placeholder="Select Date Range"
                   class="form-control"
                   bsDaterangepicker
                   required
                   readonly
                   name="dateRange"
                   #dateRange="ngModel"
                   [(ngModel)]="model.dateRange"
                   #drp="bsDaterangepicker"
                   (bsValueChange)="onToggleFilterDatepicker($event, createSeasonForm)"
                   (onHidden)="changeHandler(createSeasonForm)"
                   [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">
            <div class="dropdown-menu"
                 (click)="openDateRangeDatepicker($event)">
              <div date-range
                   start="onboardingReportInfo.dateRangeStartDate"
                   end="onboardingReportInfo.dateRangeEndDate"></div>
            </div>
          </div>
          <span class="control-label control-label-error"
                *ngIf="createSeasonForm.submitted && dateRange.errors?.required">Date range is required.</span>
        </div>

        <div class="form-group"
             [ngClass]="{'has-error': createSeasonForm.submitted && (model.budgetCode==undefined || model.budgetCode=='')}">
          <label for="budgetCode">Show/Budget Code</label>
          <div class="input-group">
            <input type="text"
                   appInputRestriction=restrictChars
                   class="form-control cursor-pointer"
                   id="budgetCode"
                   required
                   name="budgetCode"
                   #budgetCode="ngModel"
                   disabled="disabled"
                   [(ngModel)]="model.budgetCode"
                   maxlength="250"
                   authenticate-user="event.wbsecode.update"
                   authenticate-command="disabled">
            <input type="hidden" name="hdBudgetCode" [(ngModel)]="model.budgetCode" required />
            <span class="input-group-btn">
              <button class="btn btn-white"
                      type="button"
                      (click)="showBudgetContent(createSeasonForm)"
                      authenticate-user="event.wbsecode.update"
                      authenticate-command="disabled">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <span class="control-label control-label-error"
                *ngIf="createSeasonForm.submitted && (model.budgetCode==undefined || model.budgetCode=='')">
            Budget
            code is required.
          </span>
        </div>


      </div>
      <div class="modal-footer">
        <button type="submit"
                (click)="createSeason(createSeasonForm)"
                class="btn btn-primary"
                [disabled]="disableSave"
                id="btnUpdateSeason">
          <span *ngIf="!season.id">Create Season</span>
          <span *ngIf="season.id">Edit Season</span>
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">
          Cancel
        </button>
      </div>
      <!--<nbc-form-controls [ngForm]="createSeasonForm"></nbc-form-controls>-->
    </form>
</div>
