import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { PayrollService } from "src/app/Shared/Services/payroll.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { Subject, Observable, Subscriber } from "rxjs";
import { takeUntil, switchMap } from "rxjs/operators";
import * as moment from "moment";
import { ModalSubmittedTimesheetExport } from "./components/export/modal-submitted-timesheets-exports.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as _ from "lodash";

@Component({
    selector: "submitted-timecard",
    templateUrl: "./submittedTimesheets.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/payroll/payroll.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class SubmittedTimesheetsComponent implements OnInit, OnDestroy {
    constructor(
        private _networkService: NetworkService,
        private _eventTypeService: EventTypeService,
        private _payrollService: PayrollService,
        private _contactService: ContactService,
        private _modalService: BsModalService,
        private _remotesQueryService: RemotesQueryService,
        private ngxService: NgxUiLoaderService,
        private log: NbcLoggerService
    ) {}
    bsModalRef?: BsModalRef;
    componentDestroyed$: Subject<boolean> = new Subject();
    payrollTimesheetSubmittedQuery: any = {};
    eventTypes: any[] | undefined;
    networks: any[] | undefined;
    dashboardHeader: any | undefined;
    payrollTimesheets: any | undefined;
    bsInlineRangeValue!: any[];
    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm = "";

    filterOptions: any = {
        sortBy: [
            { name: "Most Recent", value: "recent" },
            { name: "Oldest", value: "-recent" },
            { name: "Event Name (A-Z)", value: "eventName" },
            { name: "Event Name (Z-A)", value: "-eventName" },
        ],
    };
    statusColors: any = ["orange", "green", "purple", "black", "yellow"];
    setDefaultQuery() {
        this.payrollTimesheetSubmittedQuery =
            this._remotesQueryService.getPayrollTimesheetSubmittedQuery();
        this.payrollTimesheetSubmittedQuery.sortBy = "recent";
        this.bsInlineRangeValue = [
            this.payrollTimesheetSubmittedQuery.startDate,
            this.payrollTimesheetSubmittedQuery.endDate,
        ];
    }

    getEventTypes() {
        this.log.debug("SubmittedTimesheets -> getEventTypes:fetching data");
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this.eventTypes = data.reference;
                    this.eventTypes?.sort((a, b) =>
                        a.name.localeCompare(b.name)
                    );
                    this.log.trace(
                        "SubmittedTimesheets -> event types received"
                    );
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to load event types at this time. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    getNetworks() {
        this.log.debug("SubmittedTimesheets -> getNetworks:fetching data");
        this._networkService
            .getNetworks()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this.networks = data.reference;
                    this.networks?.sort((a, b) =>
                        a.description.localeCompare(b.description)
                    );
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to load networks at this time. Please try again later."
                    );
                    this.log.error(err);
                },
            });
    }

    getPayrollDashboardTimesheets(resetpage) {
        this.log.trace("SubmittedTimesheets -> getPayrollDashboardTimesheets");
        this.ngxService.startBackground();

        this.payrollTimesheetSubmittedQuery.startDate = moment(
            this.payrollTimesheetSubmittedQuery.startDate
        ).format();
        this.payrollTimesheetSubmittedQuery.endDate = moment(
            this.payrollTimesheetSubmittedQuery.endDate
        ).format();
        if (!_.isEmpty(this.payrollTimesheetSubmittedQuery.event))
            this.payrollTimesheetSubmittedQuery.event = _.trim(
                this.payrollTimesheetSubmittedQuery.event
            );

        if (resetpage) {
            if (this.payrollTimesheets) {
                this.payrollTimesheets.currentPage = 1;
            }
            this.payrollTimesheetSubmittedQuery.currentPage = 1;
        }

        this._payrollService
            .getPayrollDashboardTimesheets(this.payrollTimesheetSubmittedQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (data) => {
                    this.dashboardHeader =
                        data.reference.payrollDashboardHeader;
                    this.payrollTimesheets = data.reference.payrollTimesheets;
                    this.log.debug(
                        "SubmittedTimesheets -> getPayrollDashboardTimesheets received"
                    );
                    this.ngxService.stopBackground();
                },
                error: (err) => {
                    this.log.showError(
                        "Unable to retrieve payroll dashboard submitted info at this time. Please try again later."
                    );
                    this.log.error(err);
                    this.ngxService.stopBackground();
                },
            });
    }

    resetSearch() {
        this.setDefaultQuery();
        this.contactSearchTerm = "";
        this.getPayrollDashboardTimesheets(true);
    }

    openSubmittedTimesheetsExportModal() {
        const initialState: any = {
            self: this,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false
        };
        this.bsModalRef = this._modalService.show(
            ModalSubmittedTimesheetExport,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            if (result) {
                this.exportTimesheet(
                    result.formAction,
                    result.providers,
                    result.startDate
                );
            }
        });
    }

    exportTimesheet(apiURL, providers, startDate) {
        this._payrollService
            .exportTimesheet(apiURL, providers)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    if (result === 404) {
                        this.log.showError(
                            "There is no data to export to Excel for this date: " +
                                startDate +
                                " and these providers: " +
                                providers.join(", ") +
                                "."
                        );
                    } else {
                        let fileName = "PayrollReport-";
                        if (providers && providers.length > 0) {
                            fileName +=
                                "providers-" + providers.join("-") + "-";
                        } else {
                            fileName = "all-providers";
                        }

                        this.openInExcel(result, fileName);
                    }
                },
                (err) => {
                    if (err.status === 404)
                        this.log.showWarning(
                            "There is no data to export to Excel for this date: " +
                                startDate +
                                " and these providers: " +
                                providers.join(", ") +
                                "."
                        );
                    else
                        this.log.showError(
                            "Unable to export Payroll timesheets."
                        );
                },
                () => {}
            );

        //payrollService.exportTimesheet(apiURL, providers).success(function (data, status, headers) {
        //  if (status === 204) {
        //    $scope.$emit("showAlert", "warning", "There is no data to export to Excel for this date: " + startDate + " and these providers: " + providers.join(", ") + ".");
        //  }
        //  else {
        //    var fileName = "PayrollReport-";
        //    if (providers && providers.length > 0) {
        //      fileName += "providers-" + providers.join("-") + "-";
        //    }
        //    else {
        //      fileName = "all-providers";
        //    }

        //    $scope.openInExcel(data, fileName);
        //  }

        //}).error(function (data) {
        //  log.error("got an error: " + JSON.stringify(data));
        //  $scope.$emit("showAlert", "danger", "Unable to export Payroll timesheets.");
        //}).finally(function () {
        //  log.trace("removing spinner");
        //  usSpinnerService.stop("generalSpinner");
        //});
    }
    openInExcel(data, fileName) {
        //var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        //var fileURL = URL.createObjectURL(blob);
        //fileName = fileName + ".xlsx";
        //// FOR IE
        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  window.navigator.msSaveOrOpenBlob(blob, fileName);
        //}
        //else {
        //  var a = document.createElement("a"); a.href = fileURL;
        //  a.target = "_blank";
        //  a.download = fileName + ".xlsx";
        //  document.body.appendChild(a);
        //  a.click();
        //}

        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(blob);
        const d = moment(new Date()).format("MM-dd-yyyy");
        const a = document.createElement("a");
        a.href = fileURL;
        a.target = "_blank";
        a.download = fileName + d + ".xlsx";
        document.body.appendChild(a);
        a.click();
    }
    contactTypeahead() {
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._contactService.searchByName(
                    encodeURIComponent(token)
                );
            })
        );
    }

    onContactSelect($contact) {
        this.log.trace("onContactSelect()");
        this.payrollTimesheetSubmittedQuery.contactId = $contact.item.id;
        this.payrollTimesheetSubmittedQuery.contact = $contact.item;
    }

    onToggleFilterDatepicker($event) {
        this.log.trace("Upcoming Events --> DateRange selected");
        this.payrollTimesheetSubmittedQuery.startDate = $event[0];
        this.payrollTimesheetSubmittedQuery.endDate = $event[1];

        // this.getPayrollDashboardTimesheets();
    }

    onEventSelect(item: any) {
        this.payrollTimesheetSubmittedQuery.eventTypeIds = item;
    }

    onNetworkSelect(item: any) {
        this.payrollTimesheetSubmittedQuery.networkIds = item;
    }

    changeTypeaheadLoading($event) {}

    ngOnInit(): void {
        this.getNetworks();
        this.getEventTypes();
        this.setDefaultQuery();
        this.bsInlineRangeValue = [
            this.payrollTimesheetSubmittedQuery.startDate,
            this.payrollTimesheetSubmittedQuery.endDate,
        ];

        this.contactTypeahead();
        this.getPayrollDashboardTimesheets(true);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
