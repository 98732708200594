<div class="add-contact-modal">
  <span us-spinner spinner-key="modalSpinner"></span>
  <div class="modal-header" style="height:60px">
    <h4 class="modal-title ">Confirm Contact Type Change</h4><button type="button" class="btn-close" (click)="closeModal()"> </button>
  </div>
  <div *ngIf="!(hrStatusCompletedNonVendor === 3)" style="margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">Are you sure you want to make this change?</div>
  <div *ngIf="hrStatusCompletedNonVendor === 3" style="margin-top: 15px;margin-bottom: 15px;margin-left: 15px;">This contact is HR Verifed and may be crewed to a Remote or Olympic position. Are you sure you want to make this change?</div>
  <div class="modal-footer text-right">
    <div class="row">
      <div class="col-sm-12">
        <button type="button"
                class="btn btn-default"
                style="background-color:orange"
                (click)="confirm()">
          Yes
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="closeModal()">
          No
        </button>
      </div>
    </div>
  </div>

</div>
