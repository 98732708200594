<div>
  <div class="modal-header">
    <h3 class="modal-title">{{modalTitle}}</h3>
    <button type="button"
            class="btn-close"
            (click)="closeModal()">
    </button>
  </div>

  <nbc-modal-alert></nbc-modal-alert>

  <form role="form"
        name="upsertNetworkForm"
        #form="ngForm"
        novalidate>

    <div class="modal-body">
      <div class="container">
        <div class="col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <label for="description"
                     class="control-label">State</label>

              <select name="state" required
                      class="form-select"
                      [(ngModel)]="wage.state">
                <option value="0">--Select--</option>
                <option *ngFor="let state of statesList"
                        [ngValue]="state.name">
                  {{state.name}}
                </option>
              </select>
              <span class=" control-label control-label-error"
                *ngIf="wage.invalidState && submitted">State is required.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <label for="description"
                     class="control-label">City</label>

              <div>
                <input type="text"
                       class="form-control"
                       placeholder=""
                       name="city"
                       [(ngModel)]="wage.city"
                       maxlength="40">
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <label for="description"
                     class="control-label">Minimum Wage</label>

              <div>
                <div class="input-group">
                  <span class="input-group-text"><i class="fa fa-dollar"></i></span>
                  <!--<input #description=ngModel
         type="number"
         class="form-control"
         placeholder=""
         id="wage"
         name="wage"
         [(ngModel)]="wage.wage"
         pattern="/^(\d+)?(?:\.\d{1,3})?$/"
         maxlength="10" required>-->

                  <input type="text" required
                         class="form-control pull-left"
                         placeholder="---.---"
                         maxlength="7"
                         name="wage"
                         style="font-size:13px;"
                         [(ngModel)]="wage.wage"
                         (keydown)="customRateValidation($event)"
                         (blur)="customRateChanged($event)" />
                </div>
                <span class=" control-label control-label-error"
                      *ngIf="wage.invalidWage && submitted">Minimum wage is required.</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <label for="description"
                     class="control-label">Start Date</label>
              <div>
                <input type="datetime"
                       name="startDate"
                       class="form-control req-modal"
                       required
                       bsDatepicker
                       [(bsValue)]="wage.startDate"
                       #drp="bsDatepicker"
                       (minDate)="wage.startDate"
                       (maxDate)="wage.startDate"
                       readonly
                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}">

                <span class=" control-label control-label-error"
                      *ngIf="wage.invalidStartDate && submitted">Start Date is required.</span>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="col-md-12">
          <div class="col-md-8">
            <div class="form-group">
              <label for="description"
                     class="control-label">End Date</label>

              <div>
                <input type="datetime"
                       name="endDate"
                       class="form-control req-modal"
                       bsDatepicker
                       [(bsValue)]="wage.endDate"
                       #drp="bsDatepicker"
                       (minDate)="wage.endDate"
                       (maxDate)="wage.endDate"
                       readonly
                       [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}">

                <span class=" control-label control-label-error"
                      *ngIf="wage.invalidEndDate && submitted">End Date cannot be lesser than Start Date.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer">
      <button type="button"
              class="btn btn-default"
              (click)="closeModal()">
        Cancel
      </button>
      <button type="button"
              *ngIf="isAdd"
              class="btn btn-primary"
              id="btnSaveWage"
              (click)="disableSave || upsertWage()">
        Save
      </button>
      <button type="button"
              *ngIf="!isAdd"
              class="btn btn-primary"
              id="btnUpdateWage"
              (click)="disableSave || upsertWage()">
        Update
      </button>
    </div>
  </form>
</div>
