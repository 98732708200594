import * as _ from "lodash";

 
export class crewItemConflictFactory {
  private static crewItems:any[] = [];
  static checkCrewItemConflict(item:any) {
    var found;
    if (item) {
      found = _.find(crewItemConflictFactory.crewItems, { id: item.id });

      //if the conflict has been previously checked and it was notconflicted earlier.
      if (typeof found !== "undefined" && found.eventPosition.isConflictExists) {
        return true;
      }
    }
    return false;
  }
  static addCrewItemConflictChecked(item:any) {
    var found;

    if (item) {
      found = _.find(crewItemConflictFactory.crewItems, { id: item.id });

      if (typeof found === "undefined") {
        crewItemConflictFactory.crewItems.push(item);
      }
      else {
        var index = _.findIndex(crewItemConflictFactory.crewItems, { id: item.id });

        // Replace item at index using native splice
        crewItemConflictFactory.crewItems.splice(index, 1, found);

      }
    }
  }
  static removeCrewItemConflictChecked(item:any) {
    var found;

    if (item) {
      //found = _.find(crewItems, { id: item.id });
      found = _.findIndex(crewItemConflictFactory.crewItems, { id: item.id });

      if (found > -1) {
        crewItemConflictFactory.crewItems.splice(found, 1);
      }
    }
  }

}
