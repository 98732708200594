<input authenticate-user="event.crew.update"
       authenticate-command="disabled"
       [(ngModel)]="contactSearchTerm"
       appInputRestriction="restrictChars"
       name="contactSearch"
       [typeahead]="contactObservable$"
       [typeaheadAsync]="true"
       [typeaheadOptionsLimit]="20"
       [typeaheadItemTemplate]="customItemTemplate"
       [typeaheadMinLength]="3"
       placeholder="{{placeholder}}"
       class="form-control"
       [isAnimated]="true"
       [typeaheadWaitMs]="200"
       (blur)="onBlur()"
       (focus)="onFocus()"
       container="body"
       (typeaheadOnSelect)="onContactSelected($event)">



<ng-template #customItemTemplate let-model="item" let-index="index">
  <!--<h5>This is: {{model | json}} Index: {{ index }}</h5>-->

  <strong>
    {{model.firstName ? model.firstName :model.code }}  {{model.contactMiddleName? model.contactMiddleName :""}} {{model.lastName? model.lastName :""}}{{model.contactNameNote? ", " + model.contactNameNote:""}}
  </strong>

  <font size="2px" *ngIf="model.flaggedByHR">
    <div class="fa-stack fa-1x">
      <i class="fa fa-circle fa-stack-2x" style="color: red;"></i>
      <i class="fa fa-warning fa-stack-1x" style="color: yellow;"></i>
    </div>
  </font>
  <br />
  <font size="2px">
    <small>
      <strong>Email:</strong> {{model.email ? model.email :'' }}
      <strong>SSO:</strong> {{model.sso ? model.sso :'' }}
      <strong>City:</strong>  {{model.city ? model.city:'' }}
      <strong>State:</strong> {{model.state ? model.state:'' }}
    </small>
  </font>

</ng-template>
