import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    NgxUiLoaderConfig,
    SPINNER,
    POSITION,
    PB_DIRECTION,
} from "ngx-ui-loader";

//const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//  bgsColor: "red",
//  fgsColor: "red",
//  bgsPosition: POSITION.centerCenter,
//  bgsSize: 40,
//  bgsType: SPINNER.rectangleBounce, // background spinner type
//  fgsType: SPINNER.rectangleBounce, // foreground spinner type
//  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
//  pbThickness: 7, // progress bar thickness
//  pbColor: "red",
//};

@NgModule({
    imports: [
        // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderRouterModule,
        NgxUiLoaderHttpModule.forRoot({
            showForeground: false,
            exclude: ["/api/ping"],
        }),
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class GlobalLoaderModule {}
