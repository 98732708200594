import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { Observable, of } from "rxjs";
import { NbcBaseComponent } from "../../../../Core/component/Base.Component";

@Component({
    selector: "payroll-indicators",
    templateUrl: "./indicators.component.html",
})
export class PayrollIndicatorsComponent
    extends NbcBaseComponent
    implements OnInit, OnChanges
{
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    @Input() searchTerm!: any | undefined;
    @Input() searchTermObservable$: Observable<any[]> = new Observable();
    @Input() searchList!: any[];
    @Input() indicatorList!: any[];
    @Input() tagDisplay!: any;
    @Input() tagEventlist!: any;
    @Input() placeholder!: any;
    @Input() tagId!: any;
    @Input() tags!: any;
    @Input() tagName!: any;
    @Input() nbcAuthenticateUser!: any;

    @Output() nbcOnOptionSelect = new EventEmitter<any>();
    @Output() tagChange: EventEmitter<any> = new EventEmitter<any>();

    name: string | undefined;
    isAuthorized = true;
    eventTerm: any;
    tagSource: any[] = [];

    onEventSelected($event, input) {
        if (this.tags.indexOf($event.item) == -1) {
            this.tags.push($event.item);
        }
        this.updateSearchList();
        this.searchTerm = "";
        this.nbcOnOptionSelect.emit(this.tags);
        input.value = "";
    }
    removeTag(name, event) {
        this.searchList.push(event);
        this.tags.splice(this.tags.indexOf(event), 1);
        //this.updateSearchList()
        this.nbcOnOptionSelect.emit(this.tags);
    }
    //EmitModelData() {
    //  this.tagChange.emit(this.searchTerm);
    //  this.onDemandChangeDetection();
    //}

    updateSearchList() {
        if (this.tags) {
            this.tags.forEach((tag) => {
                this.searchList.forEach((item, index) => {
                    if (item.id === tag.id) {
                        this.searchList.splice(index, 1);
                    }
                });
            });
        }
        this.searchTermObservable$ = of(this.searchList);
    }

    onDemandChangeDetection() {
        setTimeout(() => this.cd.detectChanges(), 2000);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "indicatorList") {
                const current = changes["indicatorList"]["currentValue"];
                const prev = changes["indicatorList"]["previousValue"];
                this.searchList = _.cloneDeep(current);
                this.searchTermObservable$ = of(this.searchList);
            }
            if (propName === "tags") {
                const current = changes["tags"]["currentValue"];
                const prev = changes["tags"]["previousValue"];
                this.tags = current;
                this.updateSearchList();
            }
        }
    }
    ngOnInit(): void {
        this.updateSearchList();
        this.searchList = _.cloneDeep(this.indicatorList);
        this.searchTermObservable$ = of(this.searchList);
        this.searchTermObservable$.subscribe((val) => {});

        this.isAuthorized = this.authorize("payrolltimesheet.update");
        this.name =
            "nbcselect_" +
            Date.now().toString(36) +
            Math.random().toString(36).substr(2);
    }
}
