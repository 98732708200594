import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ContactListComponent } from "./search/contactlist.component";
import { ContactSearchComponent } from "./search/contactsearch.component";
import { ContactComponent } from "./contact.component";
import { PositionService } from "../Shared/Services/position.service";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AccordionModule } from "ngx-bootstrap/accordion";

import { EmployeeTypeService } from "../Shared/Services/employeetype.service";
import { AddressService } from "../Shared/Services/address.service";
import { NgPipesModule, OrderByPipe } from "ngx-pipes";
import { RouterModule } from "@angular/router";
import { ContactDetailsComponent } from "./details/contact-details.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ContactProfileComponent } from "./details/profile/contact-profile.component";
import { ContactWorkHistoryComponent } from "./details/workhistory/contact-work-history.component";
import { ContactWorkScheduleComponent } from "./details/workschedule/contact-work-schedule.component";
import { ContactWorkScheduleAllComponent } from "./details/workscheduleall/contact-work-schedule-all.component";
import { DateService } from "../Shared/helper/date.service";
import { ContactSharedDataService } from "./details/contact-shared.service";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ModalModule, BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { NbcSharedModule } from "../Shared/shared.module";
import { SkillsService } from "../Shared/Services/skills.service";
import { ModalContactSapComponent } from "./component/sap/modal-contact-sap-result";
import { ModalContactIdmComponent } from "./component/idm/modal-contact-idm-result";
import { ModalContactTypeChangeComponent } from "./component/contactType/modal-contact-type-change";
import { ModalContactCommentComponent } from "./component/contactComments/modal-contact-comments";
import { ModalAddNewContactComponent } from "./component/addContact/modal-add-new-contact";
import { ModalWorkScheduleAddActivity } from "./details/workschedule/activity/modal-work-schedule-add-activity";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TalentPermissionComponent } from "./details/permissions/talent/talent-permissions.component";
import { RemotesPermissionComponent } from "./details/permissions/remotes/remotes-permissions.component";
import { OlympicsPermissionComponent } from "./details/permissions/olympics/olympics-permissions.component";
import { PermissionService } from "../Shared/Services/permission.service";
import { ContactRouterModule } from "./contact-routing.module";
import { ContactLayoutComponent } from "./contact-layout.component";
import { NbcPhoneNumberComponent } from "../Shared/components/nbc-phone-number/nbc-phone-number.component";
import { ModalEmergencyContactComponent } from "./component/emergencyContacts/modal-emergency-contact.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";

//FullCalendarModule.registerPlugins([interactionPlugin, dayGridPlugin]); //TODO: Revist

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NbcSharedModule,
        TypeaheadModule.forRoot(),
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgPipesModule,
        FullCalendarModule,
    ContactRouterModule.forChild(),
    TooltipModule.forRoot(),
    ],
    exports: [],
    declarations: [
        ContactComponent,
        ContactListComponent,
        ContactSearchComponent,

        ContactDetailsComponent,
        ContactProfileComponent,
        ContactWorkHistoryComponent,
        ContactWorkScheduleComponent,
        ContactWorkScheduleAllComponent,
        ModalContactSapComponent,
        ModalContactIdmComponent,
        ModalContactTypeChangeComponent,
        ModalContactCommentComponent,
        ModalEmergencyContactComponent,
        ModalAddNewContactComponent,
        ModalWorkScheduleAddActivity,
        TalentPermissionComponent,
        RemotesPermissionComponent,
        OlympicsPermissionComponent,
        ContactLayoutComponent,
        NbcPhoneNumberComponent,
    ],
    providers: [
        PositionService,
        EmployeeTypeService,
        AddressService,
        DateService,
        ContactSharedDataService,
        OrderByPipe,
        NbcLoggerService,
        SkillsService,
        PermissionService,
    ],
})
export class ContactModule {}
