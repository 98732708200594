import { NgModule } from "@angular/core";
import { TemplateListComponent } from './list/template-list.component';
import { TemplateService } from '../Shared/Services/template.service';
import { EventTypeService } from '../Shared/Services/eventType.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbcSharedModule } from '../Shared/shared.module';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgPipesModule, OrderByPipe, FilterByPipe } from 'ngx-pipes';
 import { ModalAddTemplateComponent } from './components/addtemplate/modal-add-template.component';
import { TemplateDetailListComponent } from "./detail-list/template-detail-list.component";
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DragulaModule } from "ng2-dragula";
import { TemplateRouterModule } from "./templates-routing.module";
import { ModalPositionComponent } from './components/attachposition/modal-position.component';


@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    NbcSharedModule,
    CommonModule,
    ModalModule.forRoot(),
    NgPipesModule,
    ButtonsModule.forRoot(),
    TypeaheadModule.forRoot(),
    DragulaModule.forRoot(),
    TemplateRouterModule.forChild()
  ],
  exports: [],
  declarations: [
    TemplateListComponent,
    TemplateDetailListComponent,
    ModalAddTemplateComponent,
    ModalPositionComponent
  ],
  providers: [
    TemplateService,
    EventTypeService,
    OrderByPipe,
    FilterByPipe
  ],
})
export class TemplatesModule { }
