import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import { SubSink } from "subsink";
import { ModalNetworkGroupComponent } from "./modal-network-group.component";

@Component({
    selector: "app-network-group",
    templateUrl: "./network-group.component.html",
})
export class NetworkGroupComponent implements OnInit, OnDestroy {
    constructor(
        private _networkGroupService: networkGroupService,
        public _modalService: BsModalService,
        private log: NbcLoggerService
    ) {}

    private subs = new SubSink();
    bsModalRef?: BsModalRef;
    ShowModal = false;
    setups: any;
    groupAssociatedNetworks: any = [];
    resultsFound = false;
    showAlertMessage = false;

    Setups = [];
    isAdd = false;
    modalTitle = "";
    networkGroup: any;
    isNetworkGroupInUse: any;
    disableSave: boolean | undefined;
    networks: any;

    formValidity: any = {
        groupName: false,
        isNetworkGroupTiered: false,
        networkPanel: false,
        numberOfTiers: false,
    };
    changeTracker = {
        networks: [],
    };

    search() {
        this.subs.sink = this._networkGroupService.allsetups().subscribe(
            (result) => {
                // this.log.trace("getAllNetworks got data" + JSON.stringify(result));
                this.setups = result.reference;
                this.groupAssociatedNetworks = [];
                this.getGroupAssociatedNetworks();
                this.resultsFound = true;
            },
            (err) => {
                this.log.error(
                    "getAllNetworks got an error: " + JSON.stringify(err)
                );
            },
            () => {
                this.log.trace("removing spinner");
            }
        );
    }

    cancel() {
        this.ShowModal = false;
    }

    openUpsertModal(networkGroup) {
        this.showAlertMessage = false;
        this.isAdd = networkGroup ? false : true;
        this.modalTitle = networkGroup
            ? "Edit Network Group"
            : "Add New Network Group";
        this.networkGroup = networkGroup;
        this.isNetworkGroupInUse = networkGroup
            ? networkGroup.isNetworkGroupTiered && networkGroup.id > 0
            : false;
        this.disableSave = false;

        var allNetworkGroupSetup = allNetworkGroupSetup;

        const baseCopyOfConfig = _.cloneDeep(this.networkGroup);

        const networkGroupObj = { numberOfTiers: "0" };
        if (!this.networkGroup) {
            this.networkGroup = networkGroupObj;
        }

        const initialState: any = {
            self: this,
            networkGroupToEdit: this.networkGroup,
            groupAssociatedNetworks: this.groupAssociatedNetworks,
            allNetworkGroupSetup: this.setups,
            isAdd: this.isAdd,
            modalTitle: this.modalTitle,
            showAlertMessage: false,
            disableSave: false,
            isNetworkGroupInUse: this.isNetworkGroupInUse,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalNetworkGroupComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.log.showSuccess(result);
            this.search();
        });
    }

    /*
     * This function is used to find unique networks those are already associated to some network
     */
    getGroupAssociatedNetworks = () => {
        _.forEach(this.setups, (obj) => {
            obj.flatNetworkList = _.sortBy(
                _.map(obj.networks, "description")
            ).join(",");
            const temp = _.map(obj.networks, "id");
            this.groupAssociatedNetworks.push({
                groupId: obj.id,
                neworks: temp,
            });
        });
    };

    ngOnInit(): void {
        this.search();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
