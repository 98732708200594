import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalService } from "ngx-bootstrap/modal";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";

@Component({
    selector: "work-schedule-add-activity",
    templateUrl: "./modal-work-schedule-add-activity.html",
})
export class ModalWorkScheduleAddActivity implements OnInit {
    constructor(
        private _modalService: BsModalService,
        private log: NbcLoggerService
    ) {}

    deleteModalActive = false;
    activityEditMode = false;
    model: any = {};
    activityItem: any | undefined;
    contact: any = {};
    submitted = false;
    test = "this is test";

    // Public Methods
    openDatepicker($event) {
        //dateRangePickerFactory($event);
    }

    addActivity(isValid) {
        this.log.trace("addActivity called");
        this.submitted = true;
        if (
            isValid &&
            this.model.startDate !== null &&
            this.model.endDate !== null &&
            this.model.endDate > this.model.startDate
        ) {
            //contactService.addActivity(this.contact.id, this.model).success(function (activity) {
            //  $modalInstance.close(activity.reference);
            //}).error(function (data) {
            //  log.error("got an error: " + JSON.stringify(data));
            //  this.$emit("showAlert", "danger", "Unable to add the activity at this time. Please try again later.");
            //}).finally(function () {
            // this. log.trace("removing spinner");
            //});
        }
    }
    confirmDeleteActivity() {
        // add delete request here
        //contactService.deleteActivity(this.model.id).success(function () {
        // this. log.trace("deleteActivity");
        //  $modalInstance.close(null);
        //}).error(function (data) {
        //  log.error("got an error: " + JSON.stringify(data));
        //  this.$emit("showAlert", "danger", "Unable to delete the activity at this time. Please try again later.");
        //}).finally(function () {
        // this. log.trace("removing spinner");
        //});
    }

    //TODO: fix
    //this.$watch("model.startDate + model.endDate", function () {
    //  if (this.addActivity) {
    //    this.addActivity.activityDates = {};
    //    this.addActivity.activityDates.$error = {};
    //    this.addActivity.activityDates.$error.required = !(!!this.model.startDate && !!this.model.endDate);
    //  }
    //}, false);

    closeModal() {
        this.log.trace("cancelled modal");
        if (this.deleteModalActive) {
            this.deleteModalActive = false;
        } else {
            //$modalInstance.dismiss("cancel");
        }
    }

    ngOnInit() {
        if (this.activityItem) {
            this.model = _.cloneDeep(this.activityItem);
            this.activityEditMode = true;
        } else {
            this.model = {
                title: "",
                location: "",
                startDate: undefined,
                endDate: undefined,
                notes: "",
                contactWorkHistory: {
                    contactId: this.contact.id,
                },
            };
            this.activityEditMode = false;
        }
    }
}
