import { Component, OnInit } from "@angular/core";
import { NbcLoggerService } from "../../logger/nbc-logger.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { AnySrvRecord } from "dns";
import { BudgetService } from "../../Services/budget.service";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: "nbc-budgetcode",
    templateUrl: "nbc-budget.component.html",
})
export class NbcBudgetCode implements OnInit {
    constructor(
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef,
        private _BudgetService: BudgetService
    ) {}

    public onSync!: Subject<any>;

    componentDestroyed$: Subject<boolean> = new Subject();
    wbsecodes = [];
    chargeCodeType = "N";
    chargeCodeTypes: any = [];
    companyCode: any;
    budgetQuery: any;

    showResultsContents = false;

    getCompanyCodes() {
        this._BudgetService
            .getCompanyCodes()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (result) => {
                    this.log.trace("got results: ");
                    this.companyCode = result.reference;
                },
                (err) => {
                    this.log.showError("got an error: ");
                },
                () => {}
            );
    }

    getChargeCodes() {
        this.chargeCodeTypes = [
            {
                id: 0,
                name: 0,
                description: "N",
            },
            {
                id: 0,
                name: 0,
                description: "K",
            },
            {
                id: 0,
                name: 0,
                description: "M",
            },
            {
                id: 0,
                name: 0,
                description: "R",
            },
            {
                id: 0,
                name: 0,
                description: "T",
            },
            {
                id: 0,
                name: 0,
                description: "W",
            },
        ];
    }

    getBudgetQuery() {
        return {
            pageSize: 10,
            currentPage: 1,
            code: "",
            description: "",
            coCode: "",
            //startAirDate: "",
            //endAirDate: ""
        };
    }

    isEmptyOrSpaces(str: any) {
        return str === null || str.match(/^ *$/) !== null;
    }

    // Methods
    isOneFieldEntered() {
        if (
            this.isEmptyOrSpaces(this.budgetQuery.code) &&
            this.isEmptyOrSpaces(this.budgetQuery.description) &&
            (this.budgetQuery.coCode === "" || this.budgetQuery.coCode === 0)
        ) {
            return false;
        } else if (
            (this.budgetQuery.coCode === "Select Company Code" ||
                this.budgetQuery.coCode === 0) &&
            this.isEmptyOrSpaces(this.budgetQuery.description) &&
            this.isEmptyOrSpaces(this.budgetQuery.code)
        ) {
            return false;
        }
        return true;
    }

    budgetSearch(budgetForm: any) {
        if (budgetForm == undefined) return;

        this.log.trace("search()");
        budgetForm.submitted = true;
        if (this.isOneFieldEntered()) {
            if (
                this.budgetQuery.coCode === "Select Company Code" ||
                this.budgetQuery.coCode === 0
            ) {
                this.budgetQuery.coCode = "";
            }

            const query = _.cloneDeep(this.budgetQuery);
            this._BudgetService
                .search(query)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                    (result) => {
                        //  this. log.trace("got results: " + JSON.stringify(result));
                        this.wbsecodes = result;
                        this.budgetQuery.currentPage = result.currentPage;
                        this.showResultsContents = true;
                    },
                    (err) => {
                        this.log.showError("got an error: ");
                    },
                    () => {}
                );
        }
    }

    resetBudgetSearch(budgetForm: any) {
        this.log.trace("resetBudgetSearch()");
        budgetForm.submitted = false;
        this.budgetQuery = _.cloneDeep(this.getBudgetQuery());
        this.wbsecodes = [];
        this.showResultsContents = false;
        this.chargeCodeType = "N";
    }
    selectWbseCode(wbsecode: any) {
        this.onSync.next(
            wbsecode.coCode + "." + this.chargeCodeType + "." + wbsecode.code
        );
        this.bsModalRef.hide();
    }

    // Modal Confirm Action
    confirm() {
        this.log.trace("Modal Confirmation Confirmed");
        this.onSync.next(true);
        this.bsModalRef.hide();
    }

    cancel() {
        this.log.trace("Modal Confirmation Cancelled");
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.getCompanyCodes();
        this.getChargeCodes();
        this.budgetQuery = this.getBudgetQuery();
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
