import { Component, OnInit } from "@angular/core";
import { SharedDataService } from "../../sharedData.service";
import { Subject } from "rxjs";
import { AlertComponent } from "ngx-bootstrap/alert";
import * as _ from "lodash";
import { NbcLoggerService } from "../../logger/nbc-logger.service";

@Component({
    selector: "nbc-alert",
    templateUrl: "./nbc-alert.component.html",
})
export class NbcAlerts implements OnInit {
    constructor(
        private _sharedData: SharedDataService,
        private log: NbcLoggerService
    ) {}

    alerts: any[] = [];
    dismissible = true;

    onClosed(dismissedAlert: AlertComponent): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
    }

    unsetAlerts(newMsg: any) {
        this._sharedData.removeAlert();
    }

    ngOnInit() {
        this._sharedData.getAlertMessage().subscribe((msg) => {
            if (
                _.isUndefined(this.alerts) ||
                (msg.type && msg.type == "clear")
            ) {
                this.alerts = [];
            }
            if (msg.type && msg.type != "clear") {
                const newMsg = {
                    type: msg.type,
                    msg: msg.msg,
                    displaytype: "",
                };
                switch (newMsg.type) {
                    case "danger":
                        newMsg.displaytype = "Error";
                        break;
                    case "success":
                        newMsg.displaytype = "Success";
                        break;
                    case "info":
                        newMsg.displaytype = "Heads up";
                        break;
                }

                this.alerts.push(newMsg);
                setTimeout(() => this.unsetAlerts(newMsg), 2000);
            }
        });
    }
}
