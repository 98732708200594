
<header id="header-main">
  <a href="{{scoreUrl}}" id="header-brand" aria-label="NBC Sports Event Pro">
    <span class="logo-nbc-sports"></span>
    <!-- <span class="logo-eventpro"></span> -->
  </a>
  <div id="header-search">
    <input type="text" placeholder="Search..." />
    <button>
      <i class="fa fa-search"></i>
    </button>
  </div>
  <rem-dashboard-identity></rem-dashboard-identity>

</header>
<global-announcement></global-announcement>
<side-nav></side-nav>
<nbc-loader></nbc-loader>
<nbc-idle></nbc-idle>

