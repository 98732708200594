import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: "app-delete-event-modal",
    templateUrl: "./delete-event-modal.component.html",
})
export class DeleteEventModalComponent implements OnInit {
    constructor(private bsModalRef: BsModalRef) {}
    public onSync!: Subject<any>;
    ngOnInit() {
        this.onSync = new Subject();
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    deleteEvent() {
        this.onSync.next("Yes");
        this.bsModalRef.hide();
    }
}
