import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService
  ) { }

  public getOnboardingPositionRequests(eventId: any, positionListItem: any): Observable<any> {
     this.log.trace("get onboarding Details");
     return this.http.post(this._apiroot.baseUrl +  "remotes/onboarding/event/" + eventId + "/OnboardingTemplate", positionListItem).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  public saveOnboardingPositionRequests(eventId: any, positionRequest: any): Observable<any> {
  
    this.log.trace("save onboarding Details");
    return this.http.post(this._apiroot.baseUrl + "remotes/onboarding/event/" + eventId + "/saveOnboarding", positionRequest).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  public updateOnboardingPositionRequest(eventId: any, positionRequest: any): Observable<any> {
    this.log.trace("update onboarding Details");
    return this.http.post(this._apiroot.baseUrl + "remotes/onboarding/event/" + eventId + "/updatePositionRequest", positionRequest).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  public getPositionRequestInfo(crewRegistrationNo: any, contactId: any, eventId: any, positionRequestInfo: any): Observable<any> {
    this.log.trace("get onboarding single request Details");
    //console.log("get onboarding single request Details");
    return this.http.post(this._apiroot.baseUrl + "remotes/onboarding/event/getPositionRequestInfo/" + crewRegistrationNo + "/" + contactId + "/" + eventId, positionRequestInfo).pipe(
      catchError((err) => {
        console.log("NOT NESTED Method");
        console.log(err);
        return throwError(err);
      })
    );
  };

  public sendReminder(onboardingReminder: any): Observable<any> {
    var endpoint = this._apiroot.baseUrl + "remotes/HrOnboarding/RequestTab/SendReminder";
    return this.http.post(endpoint, onboardingReminder).pipe(
      catchError((err) => {
        console.log("NOT NESTED Method");
        console.log(err);
        return throwError(err);
      })
    );
  };
}
