 
<div class="nbc-tag-container">
   <div class="tag-container">
    <div *ngFor="let tag of tags"
         class="tag"
         [ngClass]="!isAuthorized?'disabled':''">
      <span *ngIf="tag?.name"
            class="tag-text small">{{tag.name}}</span>
      <span *ngIf="tag?.description"
            class="tag-text small">{{tag.description}}</span>
      <i *ngIf="isAuthorized"
         [ngClass]="isAuthorized?'tag-remove fa fa-remove remove-button':''"
         (click)="removeTag('positions',tag);"></i>
    </div>
    <span *ngIf="asyncLoad">
      <input name={{tagName}}
             id={{tagId}}
             [typeahead]="searchTermObservable$"
             [(ngModel)]="searchTerm"
             typeaheadOptionField={{tagDisplay}}
             (ngModelChange)="EmitModelData()"
             [typeaheadOptionsLimit]="100"
             [typeaheadMinLength]="nbcMinLength"
             placeholder={{placeholder}}
             class="form-control"
             [isAnimated]="true"
             [typeaheadWaitMs]="200"
             [disabled]="!isAuthorized"
             appInputRestriction="restrictChars"
             (typeaheadOnSelect)="onEventSelected($event)">
    </span>
    <span *ngIf="!asyncLoad">
      <input name={{tagName}}
             id={{tagId}}
             [typeahead]="sourceData"
             [(ngModel)]="searchTerm"
             typeaheadOptionField={{tagDisplay}}
             (ngModelChange)="EmitModelData()"
             [typeaheadOptionsLimit]="100"
             [typeaheadMinLength]="nbcMinLength"
             placeholder={{placeholder}}
             class="form-control"
             [isAnimated]="true"
             [typeaheadWaitMs]="200"
             [disabled]="!isAuthorized"
             appInputRestriction="restrictChars"
             (typeaheadOnSelect)="onEventSelected($event)">
    </span>
  </div>
</div>

