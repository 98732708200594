import { OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DragulaService } from "ng2-dragula";
import { Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TeamService } from "../../Services/team.service";

@Component({
    selector: "nbc-teams",
    templateUrl: "nbc-teams.component.html",
})
export class NbcTeamsComponent implements OnInit, OnChanges {
    constructor(private _teamService: TeamService) {}

    @Input() eventTypeId!: any;
    @Input() nbcName!: string;
    @Input() model!: any;
    @Input() placeholder!: string;

    @Output() searchTermChange = new EventEmitter<any>();
    @Output() modelChange = new EventEmitter<any>();
    @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();

    searchTerm!: string;
    searchTeam$: Observable<any[]> | undefined;

    onItemSelected($event: any) {
        this.model = $event.item;
        this.modelChange.emit($event.item);
        this.onItemSelect.emit($event.item);
    }

    onSearchTermUpdate() {
        this.searchTermChange.emit(this.searchTerm);
    }

    onBlur() {
        if (!this.model) {
            this.searchTerm = "";
        }
    }

    searchTeam() {
        this.searchTeam$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.searchTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._teamService.search(
                    this.eventTypeId,
                    encodeURIComponent(query)
                );
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];

                this.model = current;
                if (this.model) this.searchTerm = this.model.name;
            }
        }
        if (!this.model) {
            this.searchTerm = "";
        }
    }

    ngOnInit() {
        this.searchTeam();
        //this.initializeDragDrop();
    }
}

class Teams {
    constructor(public team: string) {}
}
