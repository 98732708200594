import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";
import { ModalVenueComponent } from "./modal-venue.component";

@Component({
    selector: "app-venue",
    templateUrl: "./venue.component.html",
})
export class VenueComponent implements OnInit, OnDestroy {
    constructor(
        private _venueSetupService: VenueService,
        private log: NbcLoggerService,
        private _modalService: BsModalService
    ) {}

    private subs = new SubSink();
    componentDestroyed$: Subject<boolean> = new Subject();
    countries: any;
    ShowModal = false;
    eventId: any;
    values: any = {};
    pageSizeList = [10, 25, 50, 100];
    bsModalRef?: BsModalRef;

    isAdd = false;
    states: any;
    timeZones = [];
    modelValue: any;
    venue: any = {};
    submitted = false;
    result: any;

    isChanged: any;
    isValid: any;

    VenueName: string[] = [];
    recordCount: number | undefined;
    alerts: { type: string; msg: any }[] | undefined;
    showAlertMessage = false;
    maxNum: any;
    queryContainer: any = { query: {} };
    typeAheadVenueQuery: any = { query: {} };
    typeAheadCityQuery: any = { query: {} };
    venueSetupDetails: any;
    resultsFound = false;

    getVenueDetails(venueList) {
        this.subs.sink = this._venueSetupService
            .getVenueDetails(venueList.id)
            .subscribe(
                (result) => {
                    const venue = result.reference;
                    this.openVenueModal(false, venue);
                },
                (err) => {
                    this.log.showError("Unable to load Venue details.");
                },
                () => {
                    this.log.trace("removing spinner");
                }
            );
    }

    openVenueModal(isAdd, venue) {
        //Maxnum exists in open
        this.values = {
            isAdd: isAdd,
            venueDetail: venue,
            maxNum: this.maxNum,
            eventId: this.eventId,
            countries: this.countries,
        };

        const initialState: any = {
            self: this,
            values: this.values,
        };

        const modalOptions = {
            animated: true,
            initialState: initialState,
            ignoreBackdropClick: true,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            ModalVenueComponent,
            modalOptions
        );
        this.bsModalRef.content.onSync.subscribe((result) => {
            this.getAllVenues();
        });
    }

    getCountries() {
        this.subs.sink = this._venueSetupService.getCountries().subscribe(
            (result) => {
                this.countries = result.reference;
            },
            (err) => {
                this.log.error(
                    "getCountries got an error: " + JSON.stringify(err)
                );
                this.log.showError("Unable to load Countries at this time.");
            }
        );
    }

    openAddVenueModal(isAdd, venue) {
        this.isAdd = isAdd;
        if (!isAdd) {
            this.getVenueDetails(venue);
        } else {
            this.openVenueModal(isAdd, venue);
        }
    }

    initializeData(result) {
        this.maxNum = result.reference.length;
        this.queryContainer.query.totalRecords = result.reference.totalRecords;
        this.venueSetupDetails = result.reference.items;
        if (result.reference.totalRecords > 0) {
            this.resultsFound = true;
        } else {
            this.resultsFound = false;
        }
    }

    getVenuetypeAheadQuery(term: any) {
        this.typeAheadVenueQuery = {
            query: this.getVenueSetupQuery(null, null, null),
        };

        this.typeAheadVenueQuery.query.currentPage = 1;
        this.typeAheadVenueQuery.query.pageSize = 20;
        this.typeAheadVenueQuery.query.venue = term;
        return this.typeAheadVenueQuery.query;
    }

    venuesearchTerm = "";
    venueObservable$: Observable<any[]> | undefined;

    onVenueSelected($event) {
        this.queryContainer.query.venue = $event.item.name;
    }
    VenueTypeahead() {
        this.venueObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.venuesearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                const term = token.trim();
                return this._venueSetupService.autocomplete(term);
            })
        );
    }
    citysearchTerm = "";
    cityObservable$: Observable<any[]> | undefined;
    onCitySelected($event) {
        this.queryContainer.query.city = $event.item.city;
    }

    getCitytypeAheadQuery(term: any) {
        this.typeAheadCityQuery = {
            query: this.getVenueSetupQuery(null, null, null),
        };
        this.typeAheadCityQuery.query.currentPage = 1;
        this.typeAheadCityQuery.query.pageSize = 20;
        this.typeAheadCityQuery.query.city = term;
        return this.typeAheadCityQuery.query;
    }

    getCityTypeAhead() {
        this.cityObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.citysearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                const term = token.trim();
                return this._venueSetupService.getAllVenuesCity(
                    this.getCitytypeAheadQuery(term)
                );
            })
        );
    }

    getAllVenues() {
        this.log.trace("getAllPropertyZone");
        //usSpinnerService.spin("searchSpinner");
        this.recordCount = 0;
        if (this.queryContainer.query.venue != this.venuesearchTerm) {
            this.queryContainer.query.venue = this.venuesearchTerm;
        }
        if (this.queryContainer.query.city != this.citysearchTerm) {
            this.queryContainer.query.city = this.citysearchTerm;
        }
        this.subs.sink = this._venueSetupService
            .getAllVenues(this.queryContainer.query)
            .subscribe(
                (result) => {
                    // this.log.trace("getAllPropertyZone got details" + JSON.stringify(result));
                    this.initializeData(result);
                },
                (err) => {
                    this.showAlertMessage = true;
                    this.alerts = [{ type: "danger", msg: err.message }];
                    this.log.trace("get got an error: " + JSON.stringify(err));
                },
                () => {
                    this.log.trace("removing spinner");
                    //usSpinnerService.stop("searchSpinner");
                }
            );
    }

    sortByColumn(column) {
        this.log.trace("Sort By Column");
        if (this.queryContainer.query.sortByColumn === column) {
            this.queryContainer.query.reverseSort =
                !this.queryContainer.query.reverseSort;
        } else {
            this.queryContainer.query.sortByColumn = column;
            this.queryContainer.query.reverseSort = false;
        }
        this.search();
    }

    searchPage(pagesize) {
        this.queryContainer.query.pageSize = pagesize;
        this.search();
    }

    search() {
        this.getAllVenues();
    }

    getVenueSetupQuery(currentPage, pageSize, totalRecords) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 100,
            sortByColumn: "name",
            reverseSort: false,
            venue: "",
            city: "",
            exactTerm: false,
        };
    }

    customSearch() {
        this.queryContainer.query.pageSize = 25;
        this.queryContainer.query.currentPage = 1;
        this.getAllVenues();
    }

    clearSearch() {
        this.venuesearchTerm = "";
        this.citysearchTerm = "";
        this.queryContainer.query.pageSize = 25;
        this.queryContainer.query.currentPage = 1;
        this.queryContainer.query.sortByColumn = "name";
        this.queryContainer.query.reverseSort = false;
        this.queryContainer.query.venue = "";
        this.queryContainer.query.city = "";
        this.queryContainer.query.exactTerm = false;
        this.getAllVenues();
    }

    ngOnInit(): void {
        this.queryContainer.query = this.getVenueSetupQuery(null, null, null);
        this.getAllVenues();
        this.getCountries();
        this.VenueTypeahead();
        this.getCityTypeAhead();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
