import * as _ from "lodash";

 

export class CssMapService{

  private static cssMap: any[] = [
    {
    "module": "dashboard",
      "css": ["Content/app_bs5/css/controllers.css","Content/app_bs5/areas/dashboard/dashboard.css"]
    },
    {
      "module": "event",
      "css": ["Content/app_bs5/areas/events/events.css"]
    },
    {
      "module": "eventsdashboard",
      "css": ["Content/app_bs5/areas/events/events.css","Content/app_bs5/css/dragula-dnd.css"]
    },
    {
      "module": "contactsearch",
      "css": ["Content/app_bs5/areas/events/events.css", "Content/app_bs5/areas/contacts/contacts.css"]
    },
    {
      "module": "contact",
      "css": ["Content/app_bs5/areas/events/events.css","Content/app_bs5/areas/contacts/contacts.css"]
    },
    {
      "module": "admin",
      "css": ["Content/app_bs5/areas/admin/venue.css", "Content/app_bs5/areas/admin/admin.min.css","Content/app_bs5/css/bootstrap-switch.css"]
    },
    {
      "module": "report",
      "css": ["Content/app_bs5/css/controllers.css", "Content/app_bs5/css/RemotesReport.css"]
    },
    {
      "module": "onboarding",
      "css": ["Content/app_bs5/areas/hr/hr.css","Content/app_bs5/areas/hr/onboarding.css"]
    },
    {
      "module": "payroll",
      "css": ["Content/app_bs5/areas/events/events.css","Content/app_bs5/areas/payroll/payroll.min.css"]
    },
    {
      "module": "template",
      "css": ["Content/app_bs5/areas/admin/admin.min.css", "Content/app_bs5/css/dragula-dnd.css"]
    },
    {
      "module": "tierratecard",
      "css": ["Content/app_bs5/areas/admin/admin.min.css", "Content/app_bs5/css/dragula-dnd.css"]
    },
    {
      module: "contactUs",
      css: [
        "Content/app_bs5/areas/contacts/contacts.css",
        "Content/app_bs5/css/bootstrap-switch.css",
        "Content/app_bs5/areas/help/contactUs.css",        
      ],
    },
  ]
  static get(module: string): any {
    return _.find(this.cssMap, (o) => { return o.module === module });
  }
}
