<div>
  <div class="modal-header">
    <h3 class="modal-title">{{reportName}}</h3>
    <button type="button"
            class="btn-close"
            (click)="cancel()"
            aria-hidden="true">
    </button>
  </div>
  <div *ngIf="showAlertMessage">
    <div class="pt-3">
        <nbc-modal-alert></nbc-modal-alert>
    </div>
  </div>
    <!--<span us-spinner spinner-key="genericSpinner" spinner-start-active="true"></span>-->
    <div class="modal-body">
        <div class="grid-table">
            <div class="grid-content-row"
                 *ngIf="showEventsRowAll">
                <div class="grid-col report-name">
                    Events
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showEventsEditRow">
                <div class="grid-col report-name">
                    Events
                </div>
                <div class="grid-col">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <div [ngClass]="{'has-error': submitted || requireRateCard}">
                                    <nbc-multi-select [source]="payRateReport.events"
                                                      value="id"
                                                      display="name"
                                                      [(selectedValues)]="selectedEvent"
                                                      [placeholder]="'Select'"
                                                      (nbcOnOptionSelect)="onItemSelect($event)"></nbc-multi-select>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('EventsRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showEventsTagRow">
              <div class="grid-col report-name">
                Events
              </div>
              <div class="grid-col">
                <div class="row">
                  <div class="col-md-6"
                       *ngFor="let events of selectedEvent">
                    <span class="filter-tag">{{events.name}} <a><i class="fa fa-times-circle"
                           (click)="removeTag(events,'EventsRow')"></i></a></span>
                  </div>
                </div>
              </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('EventsRow')"></i></a>
                </div>
            </div>

            <div class="grid-content-row"
                 *ngIf="showDateRangeRowAll">
                <div class="grid-col report-name">
                    Date Range
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showDateRangeEditRow">
                <div class="grid-col report-name">
                    Date Range
                </div>
                <div class="grid-col input-info">
                  <div class="input-group input-group-set max-input"
                       dropdown
                       is-open="dateRangeFilter"
                       on-toggle="onToggleDateRangeDatepicker()">
                    <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
                    <input type="text"
                           name="arrivalDate"
                           class="form-control dropdown-toggle"
                           bsDaterangepicker
                           readonly
                           [(ngModel)]="bsInlineRangeValue"
                           #drp="bsDaterangepicker"
                           (bsValueChange)="onToggleFilterDatepicker($event)"
                           placeholder="Select Date Range"
                           [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

                  </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DateRangeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showDateRangeTagRow">
                <div class="grid-col report-name">
                    Date Range
                </div>
                <div class="grid-col">
                    <div class="col-md-6">
                        <span class="filter-tag">{{payRateReportInfo.dateRangeStartDate |
                            date:'MM/dd/yyyy'}}-{{payRateReportInfo.dateRangeEndDate | date:'MM/dd/yyyy'}} <a><i
                                   class="fa fa-times-circle"
                                   (click)="removeDateTag('DateRangeRow')"></i></a></span>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                (click)="exportPayRateReport()">Export</button>

    </div>
</div>

<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
