import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import * as _ from "lodash";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ContactService } from "src/app/Shared/Services/contact.service";
import { EmployeeTypeService } from "src/app/Shared/Services/employeetype.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { PositionService } from "src/app/Shared/Services/position.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { SubSink } from "subsink";

@Component({
    selector: "app-crew-contact-search-flyout",
    templateUrl: "./crew-contact-search-flyout.component.html",
})
export class CrewContactSearchFlyoutComponent implements OnInit {
    constructor(
        private _EventTypeService: EventTypeService,
        private _contactService: ContactService,
        private _venueService: VenueService,
        private _employeeTypeService: EmployeeTypeService,
        private _positionService: PositionService,
        private log: NbcLoggerService,
        private _sharedData: SharedDataService
    ) {}

    @Input() isFullScreenMode = false;
    // Public Vars
    contactQuery: any;
    contacts: any;
    flyoutIsEmpty: any;
    contactQueryValidateAlerts: any;
    hasSearchResults: any;
    employeeTypes: any;
    eventPosition = [];
    userSelects = [{}];
    show = false;
    venueSearchTerm = "";
    searchVenueData = [];
    searchVenueObservable$: Observable<any[]> | undefined;
    componentDestroyed$: Subject<any> = new Subject();

    positionSearchTerm = "";
    positionObservable$: Observable<any[]> | undefined;

    crewPositionSearchTerm: any | undefined;
    crewedPositionObservable$: Observable<any[]> | undefined;

    contactSearchTerm: any | undefined;
    contacts$: Observable<any[]> | undefined;

    private subs = new SubSink();
    wordkedWithList = [];
    eventTypes: any;
    employeeTypenew: any;
    flyout = true;
    // TODO: need an endpoint
    workedWith: any;
    isValidSubmission = true;
    unions = [
        {
            id: "1",
            description: "Union",
        },
        {
            id: "2",
            description: "Non Union",
        },
        {
            id: "3",
            description: "Any",
        },
    ];

    radii = [
        {
            id: "1",
            description: "1 mile",
        },
        {
            id: "5",
            description: "5 miles",
        },
        {
            id: "10",
            description: "10 miles",
        },
        {
            id: "25",
            description: "25 miles",
        },
        {
            id: "50",
            description: "50 miles",
        },
        {
            id: "100",
            description: "100 miles",
        },
    ];
    // Public Methods
    positiondata: any;
    closeAlert(index) {
        this.contactQueryValidateAlerts.splice(index, 1);
    }

    isOneFieldEntered() {
        this.isValidSubmission = !(
            this.contactQuery.firstName === "" &&
            this.contactQuery.lastName === "" &&
            this.contactQuery.sso === "" &&
            this.contactQuery.email === "" &&
            this.contactQuery.positions.length === 0 &&
            this.contactQuery.crewedPositions.length === 0 &&
            (!this.contactQuery.venue || this.contactQuery.venue?.id === 0) &&
            (this.contactQuery.searchLocation === "" ||
                !this.contactQuery.radius ||
                this.contactQuery.radius.id === 0) &&
            (!this.contactQuery.employeeType ||
                this.contactQuery.employeeType.id === 0) &&
            (!this.contactQuery.union || this.contactQuery.union.id === 0) &&
            (this.contactQuery.eventType.id === 0 ||
                !this.contactQuery.eventType ||
                this.contactQuery.eventType.id === "") &&
            this.contactQuery.workedWith === 0
        );
    }

    getEventTypes() {
        this.subs.sink = this._EventTypeService
            .getEventTypes({})
            .subscribe((result) => {
                // usSpinnerService.spin("genericSpinner");
                // this.log.trace("got results: " + JSON.stringify(result));
                this.eventTypes = result.reference;
                this.eventTypes.sort(function (a, b) {
                    const nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase();
                    if (nameA < nameB)
                        //sort string ascending
                        return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
            });
        // .error(function (data) {
        //     this.log.error("got an error: " + JSON.stringify(data));
        //     $scope.$emit("showAlert", "danger", "Unable to load the event types at this time. Please try again later.");
        // }).finally(function () {
        //     this.log.trace("removing spinner");
        //     usSpinnerService.stop("genericSpinner");
        // });
    }

    initializeContactQueryObjectAndProperties() {
        this.contactQuery = this._contactService.getDefaultContactQuery();
        this.contactQuery.pageSize = 10000000;
    }

    reset() {
        this.log.trace("cancel");
        this.contactQueryValidateAlerts = [];
        this.isValidSubmission = true;
        this.contactQuery = this._contactService.getDefaultContactQuery();
        this.contactQuery.pageSize = 10000000;
        this.contacts = {};
    }

    searchContacts(contactSearchForm) {
        this.log.trace("searchContacts");
        this.contactQueryValidateAlerts = [];
        this.isOneFieldEntered();
        if (this.isValidSubmission) {
            //TODO: Needs to be resolved in the backend
            //Search by Venue should be disabled when worked at Venue is False

            //self.contactQuery.venue = self.contactQuery.workedAtVenue ? self.eventVenue : "";
            this.contactQuery.limit = 50;
            this.subs.sink = this._contactService
                .search(this.contactQuery)
                .subscribe((result) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.contacts = result;
                    this.hasSearchResults = true;
                });
        } else {
            this.contactQueryValidateAlerts.push({
                type: "danger",
                msg: "Please enter a search criterion",
            });
        }
        //usSpinnerService.stop("genericSpinner");
    }
    searchByName(term) {
        // return this._contactService.getDirectorsAndProducers(encodeURIComponent(term), this.contactQuery.eventType.id).subscribe( (res) => {
        //     this.log.trace("response: " + JSON.stringify(res));
        //     return res.data.reference;
        // });
    }
    setWorkedWith() {
        if (this.workedWith && !_.isEmpty(this.workedWith)) {
            this.contactQuery.workedWith = this.workedWith.id;
        } else {
            this.contactQuery.workedWith = 0;
        }
    }

    workedWithAutoComplete(term) {
        return (this.subs.sink = this._contactService
            .workedWith(term.target.value)
            .subscribe((res: any) => {
                // log.trace("response: " + JSON.stringify(res));

                this.wordkedWithList = res.reference;
            }));
    }

    contactAutocomplete() {
        this.contacts$ = new Observable((observer: Subscriber<string>) => {
            // Runs on every search
            observer.next(this.contactSearchTerm);
        }).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._contactService.workedWith(token);
            })
        );
    }

    toggleFlyout() {
        this.log.trace("Clicked human icon for the flyout");
        //$scope.$emit("SET_FLYOUT", "clicked");
        this.flyout = !this.flyout;
    }

    searchVenue() {
        this.log.trace("get venue");
        this.searchVenueObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.venueSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._venueService.search(token);
            })
        );
    }

    onVenueSelect($item) {
        this.log.trace("onVenueSelect()");
        this.contactQuery.searchVenueId = $item.id;
    }

    getEmployeeTypes() {
        // usSpinnerService.spin("generalSpinner");
        this.subs.sink = this._employeeTypeService
            .getAllEmployeeTypes()
            .subscribe((result) => {
                // this.log.trace("getEmployeeTypes got EmployeeTypes" + JSON.stringify(result));
                this.employeeTypes = result.reference;
                this.employeeTypes.sort(function (a, b) {
                    const nameA = a.description.toLowerCase(),
                        nameB = b.description.toLowerCase();
                    if (nameA < nameB)
                        //sort string ascending
                        return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
            });
    }

    /**
     * Load Event Positions
     * @param {Object} query query
     * @public
     * @memberof score.controllers.modalCrewPositionController
     * @returns {Promise}
     */
    //loadEventPositions(query) {
    //  this.log.trace("loadEventPositions");

    //  return this.subs.sink = this._positionService.searchPositions(query)
    //    .subscribe((res) => {
    //    // this.log.trace("response: " + JSON.stringify(res));
    //    this.eventPosition = res.reference.slice(0, 20);
    //    this.show = true;

    //  });
    //};

    onPositionSelected(position) {
        this.log.trace(this.contactQuery.positions);
        if (_.isEmpty(this.contactQuery.positions)) {
            this.contactQuery.positions = [];
        }

        this.contactQuery.positions = position;
        this.positionSearchTerm = "";
    }

    setPositionEventTerm($event) {
        this.positionSearchTerm = $event;
    }

    positionTypeahead() {
        this.positionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.positionSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._positionService.searchPositions(token);
            })
        );
    }

    setCrewPositionEventTerm($event) {
        this.crewPositionSearchTerm = $event;
    }

    onCrewedPositionSelected(position) {
        this.log.trace(this.contactQuery.crewedPositions);
        if (_.isEmpty(this.contactQuery.crewedPositions)) {
            this.contactQuery.crewedPositions = [];
        }

        this.contactQuery.crewedPositions = position;
        this.crewPositionSearchTerm = "";
    }

    crewedpositionTypeahead() {
        this.crewedPositionObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.crewPositionSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._positionService.searchPositions(token);
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    //ngOnChanges(): void {
    //    this.flyout = true;
    //}

    selectSuggestion(s) {
        if (this.userSelects) {
            this.userSelects = this.userSelects.filter(
                (item: any) => item.id !== s.id
            );
        }

        this.userSelects.push(s);
        // this.assignToNgModel();
    }
    suggest() {
        this.show = true;
    }

    isSelected(s: any) {
        return this.userSelects.findIndex((item: any) => item.id === s.id) > -1
            ? true
            : false;
    }
    ngOnInit(): void {
        this._sharedData.setCrewContactSearchFlyout().subscribe((res) => {
            if (res) {
                this.flyout = true;
            }
        });
        this.getEventTypes();
        this.getEmployeeTypes();
        this.initializeContactQueryObjectAndProperties();
        this.searchVenue();
        this.positionTypeahead();
        this.crewedpositionTypeahead();
        this.contactAutocomplete();
    }
}
