<script scr="~/Content/app/RemotesReport.css?v=@AssemblyHelper.versionHash"
        rel="stylesheet"></script>

<div>
    <!--<h3 class="test"> Helloo..!!</h3>-->
    <div class="modal-header">
        <h3 class="modal-title">Crew SSO Status Report</h3>
        <button type="button" class="btn-close"
                (click)="cancel()"aria-hidden="true">
        </button>
        <div *ngIf="showAlertMessage">
            <alert *ngFor="let alert of alerts"
                   type="{{alert.type}}"
                   close="closeAlert()">{{alert.msg}}</alert>

        </div>
    </div>
    <div class="modal-body">
        <div class="grid-table">

            <!--Department-->
            <div class="grid-content-row"
                 *ngIf="!showDepartmentRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col"
                     *ngIf="!showDepartmentRowAll">
                    {{SSOStatusReportInputInfo.department.name}}
                </div>
                <div class="grid-col"
                     *ngIf="showDepartmentRowAll">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DepartmentRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showDepartmentRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col input-info">
                    <select class="form-select max-input"
                            [(ngModel)]="SSOStatusReportInputInfo.department">
                        <option [ngValue]="null">Select Department</option>
                        <option [ngValue]=" department"
                                *ngFor="let department of SSOStatusReport ">{{department.name}}</option>

                    </select>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DepartmentRow')"></i></a>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button id="crewSSOStatusBtnReportExport"
                type="button"
                class="btn btn-primary"
                (click)="exportReport()"
                value="disable">Export</button>
    </div>
</div>

<app-nbc-file-download></app-nbc-file-download>
