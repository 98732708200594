import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[appInputRestriction]'
})
export class InputRestrictionDirective {
  inputElement: ElementRef;

  @Input('appInputRestriction') appInputRestriction: string | undefined;
  restrictRegex = '/[!\$%\^\*()+=\|\]\}\{\[;<>`~?]/';

  constructor(el: ElementRef) {
    this.inputElement = el;
  }

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  @HostListener('keypress', ['$event']) onKeyPress(event:any) {
    if (this.appInputRestriction === 'numberOnly') {
      this.integerOnly(event);
    } else if (this.appInputRestriction === 'noSpecialChars') {
      this.noSpecialChars(event);
    } else if (this.appInputRestriction === 'restrictChars') {
      this.restrictChars(event);
    }
  };

  @HostListener('paste', ['$event']) onPaste(event: any) {
    if (this.appInputRestriction === 'numberOnly') {
      this.integerOnlyPaste(event);
    } else if (this.appInputRestriction === 'noSpecialChars') {
      this.noSpecialCharsPaste(event);
    } else if (this.appInputRestriction === 'restrictChars') {
      this.restrictCharsPaste(event);
    }
  }

  integerOnly(event: any) {
    const e = <KeyboardEvent>event;

    //Suraj Patil --> now MDN is not supporting keycode and it is depricated.
    //if (e.key === 'Tab' || e.key === 'TAB') {
    //  return;
    //}
    //if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
    //  // Allow: Ctrl+A
    //  (e.keyCode === 65 && e.ctrlKey === true) ||
    //  // Allow: Ctrl+C
    //  (e.keyCode === 67 && e.ctrlKey === true) ||
    //  // Allow: Ctrl+V
    //  (e.keyCode === 86 && e.ctrlKey === true) ||
    //  // Allow: Ctrl+X
    //  (e.keyCode === 88 && e.ctrlKey === true)) {
    //  // let it happen, don't do anything
    //  return;
    //}
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      return;  // let it happen, don't do anything
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }

  integerOnlyPaste(event: any) {
    const e = <KeyboardEvent>event;
    var regEx = new RegExp("[^0-9]");
    var text = event.clipboardData.getData('text/plain');
    if (regEx.test(text)) {
      e.preventDefault();
    }    
  }

  noSpecialChars(event: any) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      return;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.restrictRegex);
    if (regEx.test(ch)) {
      return;
    }
    e.preventDefault();
  }

  noSpecialCharsPaste(event: any) {
    var regEx = new RegExp("[^a-zA-Z0-9 ]");
    var text = event.clipboardData.getData('text/plain');
    if (regEx.test(text)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  restrictChars(event: any) {
    const e = <KeyboardEvent>event;
    //if (e.key === 'Tab' || e.key === 'TAB') {
    //  return;
    //}
    //let k;
   
    const ch = String.fromCharCode(e.keyCode);
    if (this.restrictRegex.indexOf(ch) === -1 || ch==="/") {
      return;
    }
    e.preventDefault();
  }

  restrictCharsPaste(event: any) {
    const e = <KeyboardEvent>event;
    //if (e.key === 'Tab' || e.key === 'TAB') {
    //  return;
    //}
    //let k;
    var splchar = false;
    var text = event.clipboardData.getData('text/plain');
    for (var i = 0; i < text.length; i++) {
      const ch = text.charAt(i);
      if (this.restrictRegex.indexOf(ch) === -1 || ch === "/") {
        splchar = true;
      }
    }
    if (!splchar) {
      e.preventDefault();
    }
  }
}
