import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscriber } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcBudgetCode } from "src/app/Shared/components/nbc-budgetcode/nbc-budget.component";
import { TimeZoneHelperService } from "src/app/Shared/helper/timezonehelper.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { BudgetService } from "src/app/Shared/Services/budget.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { eventManagerService } from "src/app/Shared/Services/eventmanager.service";
import { eventTierService } from "src/app/Shared/Services/eventtier.service";
import { EventTypeService } from "src/app/Shared/Services/eventType.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { networkGroupService } from "src/app/Shared/Services/network-group-service";
import { positionTemplateService } from "src/app/Shared/Services/positiontemplateservice";
import { SeasonService } from "src/app/Shared/Services/season.service";
import { TeamService } from "src/app/Shared/Services/team.service";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { SubSink } from "subsink";

@Component({
    selector: "edit-event",
    templateUrl: "./edit-event.component.html",
})
export class EditEventComponent implements OnInit, OnDestroy {
    originalBroadcastDates: any;

    constructor(
        public _bsModalRef: BsModalRef,
        public _modalService: BsModalService,
        private _EventTypeService: EventTypeService,
        private _GlobalService: GlobalService,
        private _eventManagerService: eventManagerService,
        private _BudgetService: BudgetService,
        private _timeZoneService: TimeZoneHelperService,
        private _positionTemplateService: positionTemplateService,
        private _SeasonService: SeasonService,
        private _EventService: EventService,
        private _VenueService: VenueService,
        private _TeamService: TeamService,
        private _eventTierService: eventTierService,
        private _networkGroupService: networkGroupService,
        @Inject(DOCUMENT) private document: Document,
        private log: NbcLoggerService
    ) {}
    sortedBroadcastDates: any[] = [];
    defaultDate: Date = new Date();
    VenueNameObservable$: Observable<any[]> | undefined;
    AwayTeamObservable$: Observable<any[]> | undefined;
    HomeTeamObservable$: Observable<any[]> | undefined;
    eventName: any;
    eventData: any;
    eventPositionTemplates: any;
    venueSearchQueryTypeahead = "";
    AwaySearchQueryTypeahead = "";
    HomeSearchQueryTypeahead = "";
    showResultsContents = false;
    difference = 0;
    disableSave = false;
    public onSync!: Subject<any>;
    chargeCodeType: any;
    chargeCodeTypes: any;
    budgetQuery: any;
    currentTierStatus: any;
    viewNotes = false;
    companyCode: any;
    componentDestroyed$: Subject<boolean> = new Subject();
    private subs = new SubSink();
    bsInlineRangeValue!: any[];
    bsModalRef?: BsModalRef;
    calendar_broadcast_minDate: Date = new Date();
    calendar_broadcast_maxDate!: Date;
    _clonedBroadcastDates: any[] = [];
    model: any;
    networks: any;
    seasons: any[] = [];
    eventStatusList = [];
    budgetModalActive = false;
    wbsecodes = {};
    defaultModalTitle = "Edit Event Information";
    modalTitle = this.defaultModalTitle;
    submitted = false;
    hideTeamFields = false;
    isBroadcastDateSet = false;
    submitDetectBroadcastDateSet = false;
    budgetCodeError = false;
    eventManagers = {
        all: { id: 11, values: [] },
        homeBase: { id: 12, values: [] },
        technical: { id: 13, values: [] },
        manPower: { id: 14, values: [] },
        onSite: { id: 15, values: [] },
    };
    eventType: any = {};
    isEventLocationValid = true;
    eventSummary: any;
    selectedSeason: any = {};
    broadcastTimesInvalid = false;
    networkIdInvalid = false;
    eventTimeZone = "";
    isSeasonSetup: any;
    disableCrewWorkScheduleDatepicker = true;
    eventTimesheetDateBoundaries = {
        earliestActivatedDate: null,
        latestActivatedDate: null,
    };
    isInitialLoad = true;
    blankLocation = false;
    loadDates = false;

    tierNetworks: any = {};
    tierConfig: any = null;
    nonTieredNetworks: any = [];
    crewWorkScheduleValidation: any = {
        required: false,
        submittedTimesheetRange: false,
    };

  EventLocations: any[] = [
    { id: "Features", desc: "Features" },
    { id: "Hybrid", desc: "Hybrid" },
    { id: "Onsite", desc: "Onsite" },
    { id: "Stamford", desc: "Stamford" },
  ];

    TimekeepingReminders: any[] = [
        { id: "10:00 AM", desc: "10:00 AM" },
        { id: "12:00 PM", desc: "12:00 PM" },
        { id: "02:00 PM", desc: "02:00 PM" },
        { id: "04:00 PM", desc: "04:00 PM" },
        { id: "06:00 PM", desc: "06:00 PM" },
        { id: "08:00 PM", desc: "08:00 PM" },
        { id: "10:00 PM", desc: "10:00 PM" },
    ];

    INVALID_EXEC_STATUS_TOUPDATE = ["CLOSED", "INPROGRESS"];

    PRIMARY_DATE_INDEX: any = 0;
    broadCastdateBeingChanged = false;

    minAirDate = "";
    allTierNetworkSetups = [];
    allNonTieredNetworks = [];

    closeModal() {
        this._bsModalRef.hide();
    }

    addLocation() {
        let activeRows = 0;
        for (const loc of this.model.additionalLocations) {
            if (loc.isDeleted == undefined || loc.isDeleted != "Y") {
                activeRows++;
            }
        }
        if (this.model.additionalLocations !== undefined) {
            const tempId = this.model.additionalLocations
                ? this.model.additionalLocations.length + 1
                : 1;
            this.model.additionalLocations.push({
                locationId: 0,
                locationName: "",
                isDeleted: "N",
                tempId: tempId,
            });
            this.model.additionalLocations = _.cloneDeep(
                this.model.additionalLocations
            );
        }
    }

    getEventLocationChange() {
        if (
            this.model.eventLocation === undefined ||
            this.model.eventLocation === null ||
            this.model.eventLocation === "" ||
            this.model.eventLocation === 0
        ) {
            this.isEventLocationValid = false;
        } else {
            this.isEventLocationValid = true;
        }
    }

    isNotDeleted(loc: any) {
        return loc.isDeleted === "N";
    }

    removeLocation(loc: any, obj: any) {
        const item = _.find(this.model.additionalLocations, (o: any) => {
            return o.locationId == obj.locationId;
        });
        //let item = this.model.additionalLocations[loc];
        if (item && item.locationId != 0) {
            const index = _.findIndex(
                this.model.additionalLocations,
                (o: any) => {
                    return o.locationId == obj.locationId;
                }
            );
            const tempLocs = _.cloneDeep(this.model.additionalLocations);
            tempLocs[index].isDeleted = "Y";
            tempLocs[index].locationName = "XX DELETED XX";
            this.model.additionalLocations = tempLocs;
        } else {
            const tempLoc = _.cloneDeep(this.model.additionalLocations);
            const newItem = _.findIndex(
                this.model.additionalLocations,
                (o: any) => {
                    return o.tempId == obj.tempId;
                }
            );
            tempLoc.splice(newItem, 1);
            this.model.additionalLocations = tempLoc;

            // this.model.additionalLocations.splice(this.model.additionalLocations.findIndex(function (l) {
            //     return l.$$hashKey === loc.$$hashKey;
            // }), 1);
        }
    }

    getEventNetworks() {
        this.subs.sink = this._GlobalService.getEventNetworks().subscribe({
            next: (result) => {
                this.log.trace("got results: ");
                this.networks = result.reference;
            },
            error: (err) => {
                this.log.showModalError(" got an error: ");
            },
        });
    }

    getNonTieredNetworks() {
        this.subs.sink = this._networkGroupService
            .getNonTieredNetworks()
            .subscribe({
                next: (result) => {
                    this.log.trace("got results: ");
                    this.nonTieredNetworks = result.reference;
                },
                error: (err) => {
                    this.log.showModalError(
                        " Unable to load event networks at this time. Please try again later."
                    );
                },
            });
    }

    setNetworkId(date: any) {
        date.networkId = date.network.id;
    }

    checkEventNetworks() {
        if (this.networks.length === 0) {
            this.getEventNetworks();
        }
    }

    getNetwork(id: any) {
        return _.find(this.networks, function (n) {
            return n.id === id;
        });
    }

    getAllNonTieredNetworksFromGroups() {
        let nonTierEnabledGroups: any;
        nonTierEnabledGroups = _.filter(this.allTierNetworkSetups, {
            isNetworkGroupTiered: false,
        });
        this.allNonTieredNetworks = [];

        if (!_.isEmpty(nonTierEnabledGroups)) {
            for (let i = 0; i < nonTierEnabledGroups.length; i++) {
                this.allNonTieredNetworks = this.allNonTieredNetworks.concat(
                    nonTierEnabledGroups[i].networks
                );
            }

            // we need to add free networks to the networks from above list
            this.allNonTieredNetworks = this.allNonTieredNetworks.concat(
                this.nonTieredNetworks
            );
            this.allNonTieredNetworks = _.filter(
                this.allNonTieredNetworks,
                "id"
            );
        }
    }

    getAllTierNetworkSetup() {
        this.subs.sink = this._networkGroupService.allsetups().subscribe({
            next: (result) => {
                this.log.trace("getAllNetworks got data");
                this.allTierNetworkSetups = result.reference;
            },
            error: (err) => {
                this.log.showModalError("getAllNetworks got an error:");
            },
            complete: () => {
                this.getAllNonTieredNetworksFromGroups();
            },
        });
    }

    getNetworkGroupSetupByNetworkGroupId() {
        if (
            this.eventData &&
            this.eventData.networkGroupId &&
            this.eventData.networkGroupId > 0
        ) {
            this.subs.sink = this._eventTierService
                .getEventTierSetupByNetworkGroup(
                    this.eventData.networkGroupId,
                    this.model.eventTypeId
                )
                .subscribe({
                    next: (result) => {
                        // this.log.trace("got a result: " + JSON.stringify(result));

                        this.validateTierConfigResponse(
                            result.reference,
                            null,
                            null
                        );
                    },
                    error: (err) => {
                        this.log.showModalError(
                            "Unable to load the tier setup. Please try again later."
                        );
                    },
                });
        }
    }

    /*
     * method gets tier setup info for the event type and network id
     */
    getNetworkGroupSetup(
        $event: any,
        networkId: any,
        index: any,
        oldNetworkId: any
    ) {
        oldNetworkId = oldNetworkId || $event?.oldValue;
        let changedDateindex = 0;
        changedDateindex = parseInt(index) - 1;

        if (!_.has(this.model.broadcastDates[changedDateindex], "network")) {
            this.model.broadcastDates[changedDateindex].network =
                this.getNetwork(networkId);
        }

        if (
            networkId > 0 &&
            this.PRIMARY_DATE_INDEX != null &&
            this.PRIMARY_DATE_INDEX > -1 &&
            changedDateindex !== this.PRIMARY_DATE_INDEX
        ) {
            if (this.tierConfig) {
                //with groups
                if (
                    this.tierConfig.networkGroupId != null &&
                    this.tierConfig.networkGroupId > 0
                ) {
                    //tiere enabled on Groups
                    if (this.tierConfig.isNetworkGroupTiered) {
                        if (
                            _.findIndex(
                                this.tierConfig.tierGroupNetworks,
                                {
                                    id: networkId
                                }
                            ) < 0
                        ) {
                            var arrParticipatingNetworks: any;
                            arrParticipatingNetworks = _.map(
                                this.tierConfig.tierGroupNetworks,
                                "description"
                            );
                            arrParticipatingNetworks =
                                arrParticipatingNetworks.join("; ");
                            const msg =
                                "You must select networks from current Network Group: " +
                                arrParticipatingNetworks;
                            this.showValidationModal(
                                "Tiered Rate Card",
                                msg,
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.cancelTransactionWorkflow.bind(this),
                                [changedDateindex, oldNetworkId]
                            );
                            return;
                        }
                    }

                    //flag is disabled i.e. group is available but not tiered yet
                    if (!this.tierConfig.isNetworkGroupTiered) {
                        const combinedNonTieredNetworks =
                            this.nonTieredNetworks.concat(
                                this.tierConfig.tierGroupNetworks
                            );
                        if (
                            _.findIndex(
                                combinedNonTieredNetworks,
                                {
                                    id: networkId
                                }
                            ) < 0
                        ) {
                            const arrNonTieredNetworks = _.map(
                                this.nonTieredNetworks,
                                "description"
                            ).concat(
                                _.map(
                                    this.tierConfig.tierGroupNetworks,
                                    "description"
                                )
                            );

                            arrParticipatingNetworks =
                                arrNonTieredNetworks.join("; "); //+ getJoinedNonTieredFloatingNetworks();
                            const msg =
                                "You must select networks from current Network Group: " +
                                arrParticipatingNetworks;
                            this.showValidationModal(
                                "Tiered Rate Card",
                                msg,
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.cancelTransactionWorkflow.bind(this),
                                [changedDateindex, oldNetworkId]
                            );
                            return;
                        }
                    }
                } else {
                    //if the primary is from free list i.e. not part of any groups
                    if (
                        _.findIndex(
                            this.allNonTieredNetworks,
                            {
                                id: networkId
                            }
                        ) < 0
                    ) {
                        var arrParticipatingNetworks: any;
                        arrParticipatingNetworks = _.map(
                            this.allNonTieredNetworks,
                            "description"
                        );
                        arrParticipatingNetworks =
                            arrParticipatingNetworks.join("; ");
                        const msg =
                            "You must select networks from current Network Group: " +
                            arrParticipatingNetworks;
                        this.showValidationModal(
                            "Tiered Rate Card",
                            msg,
                            false,
                            null,
                            null,
                            null,
                            "OK",
                            this.cancelTransactionWorkflow.bind(this),
                            [changedDateindex, oldNetworkId]
                        );
                        return;
                    }
                }
            }

            //everything is ok for secondary networks.So, we have to recalculate primary date index. This may be because of network "NBC"
            if (this.model.broadcastDates[changedDateindex]) {
                this.model.broadcastDates[changedDateindex].networkId =
                    networkId;
                this.model.broadcastDates[changedDateindex].network =
                    this.getNetwork(networkId);
                this.findPrimaryBroadcastingDate();
            }
            return;
        }

        if (!networkId || networkId <= 0) {
            if (index - 1 == this.PRIMARY_DATE_INDEX) {
                this.tierConfig = null;
            }
            this.model.broadcastDates[changedDateindex].network = null;
            this.findPrimaryBroadcastingDate();
            return;
        }

        //Step 1: get the tier information for the selected network

        // usSpinnerService.spin("saveSpinner");
        this.getEventTierSetup(networkId, changedDateindex, oldNetworkId, null);
    }

    SetModelValueTapDate($event: any) {
        this.model.tapeDate = $event;
        this.getMinAirDate();
    }
    HomeTeamTypeahead() {
        this.HomeTeamObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.HomeSearchQueryTypeahead);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._TeamService.search(
                    this.eventType.id || 0,
                    encodeURIComponent(token)
                );
            })
        );
    }

    AwayTeamTypeahead() {
        this.AwayTeamObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.AwaySearchQueryTypeahead);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._TeamService.search(
                    this.eventType.id || 0,
                    encodeURIComponent(token)
                );
            })
        );
    }

    VenueNameTypeahead() {
        this.VenueNameObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.venueSearchQueryTypeahead);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._VenueService.autocomplete(token);
            })
        );
    }

    getEventType() {
        this.subs.sink = this._EventTypeService
            .getEventTypesById(this.model.eventTypeId)
            .subscribe(
                (result) => {
                    this.log.trace("got a result: ");
                    this.eventType = result.reference;
                },
                (err) => {
                    this.log.error("got an error: ");
                },
                () => {}
            );
    }

    getEventTierSetup(
        networkId: any,
        changedDateindex: any,
        oldNetworkId: any,
        reassociatedNetworkGroupId: any
    ) {
        this.subs.sink = this._eventTierService
            .getEventTierSetup(networkId, this.model.eventTypeId)
            .subscribe({
                next: (result) => {
                    if (reassociatedNetworkGroupId) {
                        if (!_.isEmpty(this.eventData)) {
                            this.model.eventTierSetup.networkGroupId =
                                result.reference.networkGroupId;
                        }
                    }
                    this.validateTierConfigResponse(
                        result.reference,
                        changedDateindex,
                        oldNetworkId
                    );
                },
                error: (err) => {
                    this.log.showModalError(
                        "Unable to load the tier setup. Please try again later."
                    );
                },
            });
    }

    resetNetworkSelection(index: any) {
        //resetting broadcasting network
        this.model.broadcastDates[index - 1].networkId = "";
    }

    onDateChangeStarted() {
        this.broadCastdateBeingChanged = true;
    }

    onCalenderhidden(str: any) {
        this.broadCastdateBeingChanged = false;
    }
    getNetworks(index: any) {
        return this.networks;
    }

    getSeasons() {
        this.subs.sink = this._SeasonService
            .searchByEventType(this.model.eventTypeId, "")
            .subscribe({
                next: (result) => {
                    this.seasons = result.reference;
                },
                error: (err) => {
                    this.log.showModalError(
                        " Unable to load seasons at this time. Please try again later. "
                    );
                },
            });
    }

    getPositionTemplates() {
        this.subs.sink = this._positionTemplateService
            .getTemplateByEventTypeId(this.model.eventTypeId)
            .subscribe({
                next: (result) => {
                    this.log.trace("got results: ");
                    this.eventPositionTemplates = result.reference;
                    if (result.reference.length == 0) {
                        this.model.eventPositionTemplate = "";
                    }
                },
                error: (err) => {
                    this.log.showModalError(
                        " Unable to load templates at this time. Please try again later "
                    );
                },
            });
    }

    checkSeasons() {
        if (this.seasons.length === 0) {
            this.getSeasons();
        }
    }

    checkEventType() {
        if (
            !_.isUndefined(this.model.eventType) &&
            this.model.eventType !== null
        ) {
            if (this.model.eventType.hasTeam !== true) {
                this.hideTeamFields = true;
            }
        }
    }

    checkForSeason() {
        if (this.model.seasonId && this.model.seasonId > 0) {
            this.model.partOfSeason = true;
        }
    }

    setSeasonId() {
        if (!this.model.partOfSeason) {
            this.model.seasonId = 0;
        }
    }

    removeBroadcastDate(index: any) {
        //TODO
        let isAirDates;
        this.model.broadcastDates.splice(index, 1);
        isAirDates = _.filter(this.model.broadcastDates, { isAirDate: true });
        if (this.model.broadcastDates.length === 0 || isAirDates.length === 0) {
            this.addBroadcastDate();
        }
    }
    detectBroadcastDateSet() {
        if (this.model.broadcastDates.length === 0) {
            this.isBroadcastDateSet = false;
        } else {
            this.isBroadcastDateSet = true;
        }
    }
    setMinMaxBroadcastDate() {
        this.submitDetectBroadcastDateSet = true;
        this.detectBroadcastDateSet();
        const dateRange = this.getSortedBroadcastDates();

        this.model.minBroadcastDate = moment(
            dateRange[this.PRIMARY_DATE_INDEX].airDate
        )
            .add(24, "hours")
            .format()
            .toString();
        this.model.maxBroadcastDate = moment(
            dateRange[dateRange.length - 1].airDate
        )
            .subtract(24, "hours")
            .format()
            .toString();
    }

    getSortedBroadcastDates() {
        const broadcastDateCopy = _.clone(this.model.broadcastDates);

        this.sortedBroadcastDates = broadcastDateCopy.sort(function (
            left: any,
            right: any
        ) {
            return moment.utc(left.airDate).diff(moment.utc(right.airDate));
        });
        return this.sortedBroadcastDates;
    }

    getBroadcastDate() {
        return {
            airDate: "",
            startTime: "",
            endTime: "",
            networkId: "",
            isAirDate: true,
        };
    }

    filterBroadcastDates(item: any) {
        if (item.isAirDate === true) {
            return true;
        }
        return;
    }

    addBroadcastDate() {
        if (!this.model.broadcastDates) {
            this.model.broadcastDates = [];
        }
        this.model.broadcastDates.push(this.getBroadcastDate());
        this._clonedBroadcastDates.push(this.getBroadcastDate());
    }

    broadcastDateFactory(
        index: any,
        broadcastItem1: any,
        crewStartDate: any,
        crewEndDate: any
    ) {
        let updateCrewWorkRange = false;
        const broadcastItem = this.model.broadcastDates[index];
        if (broadcastItem && broadcastItem.airDate !== "") {
            if (broadcastItem.airDate) {
                if (broadcastItem.startTime) {
                    const startTimeHours = moment(
                            broadcastItem.startTime
                        ).hours(),
                        startTimeMinutes = moment(
                            broadcastItem.startTime
                        ).minutes(),
                        startTimeSeconds = moment(
                            broadcastItem.startTime
                        ).seconds();

                    broadcastItem.startTime = moment(broadcastItem.airDate)
                        .hours(startTimeHours)
                        .minutes(startTimeMinutes)
                        .seconds(startTimeSeconds)
                        .toDate();
                    broadcastItem.startTime = moment(broadcastItem.startTime)
                        .format()
                        .toString();
                    //this._clonedBroadcastDates[index] = broadcastItem;
                } else {
                    broadcastItem.startTime = moment(broadcastItem.airDate)
                        .add(7, "hours")
                        .toDate();
                    broadcastItem.startTime = moment(broadcastItem.startTime)
                        .format()
                        .toString();
                }
                if (broadcastItem.endTime) {
                    const endTimeHours = moment(broadcastItem.endTime).hours(),
                        endTimeMinutes = moment(
                            broadcastItem.endTime
                        ).minutes(),
                        endTimeSeconds = moment(
                            broadcastItem.endTime
                        ).seconds();

                    broadcastItem.endTime = moment(broadcastItem.airDate)
                        .hours(endTimeHours)
                        .minutes(endTimeMinutes)
                        .seconds(endTimeSeconds)
                        .toDate();
                    broadcastItem.endTime = moment(broadcastItem.endTime)
                        .format()
                        .toString();
                } else {
                    broadcastItem.endTime = moment(broadcastItem.airDate)
                        .add(15, "hours")
                        .toDate();
                    broadcastItem.endTime = moment(broadcastItem.endTime)
                        .format()
                        .toString();
                }

                broadcastItem.airDate = moment(broadcastItem.startTime)
                    .startOf("day")
                    .format();
                this.model.broadcastDates.splice(index, 1, broadcastItem);
                this._clonedBroadcastDates.splice(index, 1, broadcastItem);
            }
            const sortedBroadcastDates = this.getSortedBroadcastDates();
            const totalLength = sortedBroadcastDates.length;
            if (crewStartDate) {
                // if (broadcastItem.airDate < crewStartDate) {
                if (
                    moment(crewStartDate).isSameOrAfter(
                        moment(sortedBroadcastDates[0].airDate),
                        "day"
                    )
                ) {
                    updateCrewWorkRange = true;
                    crewStartDate = moment(sortedBroadcastDates[0].airDate);
                }
            } else {
                updateCrewWorkRange = false;
            }
            if (crewEndDate) {
                const x = new Date(
                    sortedBroadcastDates[totalLength - 1].airDate
                );
                if (
                    !moment(crewEndDate).isSameOrAfter(
                        new Date(sortedBroadcastDates[totalLength - 1].airDate),
                        "day"
                    )
                ) {
                    updateCrewWorkRange = true;
                    crewEndDate = moment(
                        sortedBroadcastDates[totalLength - 1].airDate
                    );
                }
            } else {
                updateCrewWorkRange = false;
            }

            if (updateCrewWorkRange) {
                this.disableCrewWorkScheduleDatepicker = false;
                this.bsInlineRangeValue = [
                    new Date(crewStartDate),
                    new Date(crewEndDate),
                ];
            }
        }
    }

    // this.onBroadcastDateChange = broadcastDateFactory;  //TODO

    showBudgetContent() {
        this.budgetModalActive = true;
        this.modalTitle = "Search and Select Budget/Show Code";
        this.showResultsContents = false;

        const initialState: any = {
            self: this,
        };
      const modalOptions = {
            id:2,
            animated: true,
            backdrop: true,
            keyboard: false,
            class: "child-modal2",
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(NbcBudgetCode, modalOptions);
        setTimeout(() => {
          document.querySelectorAll(
            ".child-modal2"
          )[0].parentElement.style.background = "rgba(0, 0, 0, 0.5)";
        }, 200);
        this.bsModalRef.content.onSync.subscribe((result: any) => {
            this.model.budgetCode = result;
        });

        //this.$emit("hideAlert");
    }

    checkBroadcastRange(broadcast: any) {
        //providing some time for data model sync
        setTimeout(() => {
            if (broadcast.endTime < broadcast.startTime) {
                this.broadcastTimesInvalid = true;
            } else {
                this.broadcastTimesInvalid = false;
            }
        }, 300);
    }

    checkBroadcastTimes() {
        for (let i = 0; i < this.model.broadcastDates.length; i++) {
            if (
                this.model.broadcastDates[i].startTime >
                this.model.broadcastDates[i].endTime
            ) {
                this.broadcastTimesInvalid = true;
            }
        }
    }

    checkNetworks() {
        for (let i = 0; i < this.model.broadcastDates.length; i++) {
            if (
                this.model.broadcastDates[i].networkId === "" ||
                this.model.broadcastDates[i].networkId === 0
            ) {
                this.networkIdInvalid = true;
                return;
            }
        }
        this.networkIdInvalid = false;
        return;
    }

    updateEvent(eventForm: any) {
        //TODO

        if (
            this.model.eventLocation == null ||
            this.model.eventLocation == undefined ||
            this.model.eventLocation == "" ||
            this.model.eventLocation == 0
        ) {
            this.isEventLocationValid = false;
        }

        this.submitted = true;
        //Validate that the venue is selected for event creations.
        if (
            eventForm.controls.venue.value == "" ||
            eventForm.controls.venue.value == undefined
        ) {
            eventForm.controls.venue.setErrors({ required: false });
            return false;
        }

        if (!this.isEventLocationValid) {
            return false;
        }

        if (!this.model.crewWorkStartDate || !this.model.crewWorkEndDate) {
            this.crewWorkScheduleValidation.required = true;
            return;
        }

        this.log.trace("updateEvent");
        this.checkBroadcastTimes();
        this.checkNetworks();
        eventForm.isValid = this.checkTapeDate(this.model.tapeDate);
        //var convertTapeDate= new Date(moment.utc(this.model.tapeDate).format("MM/DD/YYYY"));
        //this.model.tapeDate = convertTapeDate;

        //Validate that the crew work schedule doesn"t exceed 30 days
        this.validateDateRange();
        if (this.difference > 39) {
            return;
        }

        //Validate if "part of season" is selected
        this.submitted = true;
        if (
            this.model.partOfSeason &&
            (!_.isNumber(_.parseInt(this.model.seasonId.toString())) ||
                this.model.seasonId == 0)
        ) {
            return false;
        }
        //Validate that home team and away team are not the same... and a team sport.
        if (
            this.model.eventType.hasTeam &&
            this.model.homeTeam !== null &&
            this.model.awayTeam !== null &&
            this.model.homeTeam !== "" &&
            this.model.awayTeam !== "" &&
            this.model.awayTeam.id === this.model.homeTeam.id
        ) {
            return;
        }

        //Validate that home team and away team are not empty if team sport
        if (
            !this.model.homeTeam &&
            !this.model.awayTeam &&
            this.model.eventType.hasTeam
        ) {
            return;
        }
        //Validate that the Broadcast Start Times are not greater than the end times and valid netword Id
        if (
            this.broadcastTimesInvalid === true ||
            this.networkIdInvalid === true
        ) {
            return;
        }
        if (
            this.model.budgetCode === undefined ||
            this.model.budgetCode === null
        ) {
            return false;
        }
        // associating an event tier
        this.model.tier = this.tierConfig ? this.tierConfig.tierId : 0;

        this.model.onsiteProductionManager =
            this.model.onsiteProductionManager === 0
                ? null
                : this.model.onsiteProductionManager;
        this.model.onsiteProductionManager2 =
            this.model.onsiteProductionManager2 === 0
                ? null
                : this.model.onsiteProductionManager2;

        if (
            this.eventTimesheetDateBoundaries.earliestActivatedDate &&
            this.eventTimesheetDateBoundaries.latestActivatedDate
        ) {
            if (
                moment(
                    this.eventTimesheetDateBoundaries.earliestActivatedDate
                ).isBefore(this.model.crewWorkStartDate, "day") ||
                moment(
                    this.eventTimesheetDateBoundaries.latestActivatedDate
                ).isAfter(this.model.crewWorkEndDate, "day")
            ) {
                this.crewWorkScheduleValidation.submittedTimesheetRange = true;
                //eventForm.crewWorkSchedule.errors.submittedTimesheetRange = true;
                eventForm.controls.crewWorkSchedule.setErrors({
                    submittedTimesheetRange: true,
                });
                return;
            } else {
                this.crewWorkScheduleValidation.submittedTimesheetRange = false;
                eventForm.controls.crewWorkSchedule.setErrors(null);
                // eventForm.crewWorkSchedule.errors.submittedTimesheetRange = false;
            }
        } else {
            this.crewWorkScheduleValidation.submittedTimesheetRange = false;
            eventForm.controls.crewWorkSchedule.setErrors(null);
            // eventForm.crewWorkSchedule.errors.submittedTimesheetRange = false;
        }

        this.blankLocation = false;
        for (const loc of this.model.additionalLocations) {
            if (!loc.locationName || loc.locationName === "") {
                this.blankLocation = true;
                return false;
            } else {
                if (
                    loc.eventId !== 0 &&
                    (loc.locationName.toLowerCase() === "onsite" ||
                        loc.locationName.toLowerCase() === "@home" ||
                        loc.locationName.toLowerCase() === "stamford")
                ) {
                    this.blankLocation = true;
                    return false;
                }
            }
        }

        if (eventForm.isValid) {
            const eventToSave = _.cloneDeep(this.model);
            eventToSave.tapeDate = new Date(
                moment.utc(this.model.tapeDate).format("MM/DD/YYYY")
            );
            _.each(eventToSave.broadcastDates, (value, key) => {
                let hasAirDate = false;
                _.forEach(this.originalBroadcastDates, (valueOld, keyOld) => {
                    if (moment(valueOld.airDate).isSame(value.airDate, "day")) {
                        valueOld.isAirDate = true;
                        valueOld.network = value.network;
                        valueOld.networkId = value.networkId;
                        valueOld.startTime = value.startTime;
                        valueOld.endTime = value.endTime;
                        hasAirDate = true;
                        return;
                    }
                });
                if (!hasAirDate) {
                    value.id = "";
                    this.originalBroadcastDates.push(value);
                }
            });

            eventToSave.broadcastAirDates = this.model.broadcastDates;
            eventToSave.broadcastDates = this.originalBroadcastDates;

            this.disableSavefn();
            this.subs.sink = this._EventService.save(eventToSave).subscribe({
                next: (result) => {
                    if (this.model.partOfSeason) {
                        if (
                            !_.isEmpty(result) &&
                            !result.reference.sportsEventCallStatus
                        ) {
                            const data: any = {
                                event: result.reference,
                                selectedSeason: this.selectedSeason,
                            };
                            this.showValidationModal(
                                "Sports Event Website",
                                "Unable to published the event in Sports Event Website.",
                                false,
                                null,
                                null,
                                null,
                                "OK",
                                this.sportsEventWebsiteErrorConfirmation.bind(
                                    this
                                ),
                                [data]
                            );
                        } else {
                            const data: any = {
                                event: result.reference,
                                selectedSeason: this.selectedSeason,
                            };
                            this.onSync.next(data);
                            this.closeModal();
                        }
                    } else {
                        if (
                            !_.isEmpty(result) &&
                            !result.reference.sportsEventCallStatus
                        ) {
                            this.log.showModalError(
                                "Unable to published the event in Sports Event Website."
                            );
                        }
                        const data: any = {
                            event: result.reference,
                            selectedSeason: null,
                        };
                        this.onSync.next(data);
                        this.closeModal();
                    }
                },
                error: (msg) => {
                    this.log.error("got an error: " + JSON.stringify(msg));
                    this.log.showModalError(
                        "Unable to update the event at this time. Please try again later."
                    );
                },
                complete: () => {
                    this.enableSavefn();
                },
            });
        } else {
            this.log.showModalError("Form is invalid");
        }
        return;
    }

    sportsEventWebsiteErrorConfirmation(data: any) {
        this.onSync.next(data);
        this.closeModal();
    }

    checkTapeDate(tapeDate: any) {
        if (tapeDate > this.minAirDate) {
            return false;
        } else {
            return true;
        }
    }

    enableSavefn() {
        //  $("#btnUpdateEvent").removeClass("btn btn-default").addClass("btn btn-primary");
        this.disableSave = false;
    }
    disableSavefn() {
        this.disableSave = true;
        //$("#btnUpdateEvent").removeClass("btn btn-primary").addClass("btn btn-default");
    }

    getSelectedSeason() {
        if (!(this.model && this.model.seasonId && this.model.seasonId > 0)) {
            return;
        }

        this.subs.sink = this._SeasonService
            .getSeasonData(this.model.seasonId)
            .subscribe({
                next: (result) => {
                    this.log.trace("Successfully selected a season");
                    this.selectedSeason = result.reference;
                    this.checkOutOfSeasonRangeBroadcastDates();
                },
                error: (err) => {
                    this.log.showModalError("Unable to select a season. ");
                },
            });
    }

    setEventTitle() {
        if (this.model.awayTeam && this.model.homeTeam) {
            const awayTeam = this.model.awayTeam.name,
                homeTeam = this.model.homeTeam.name;
            this.model.eventName = awayTeam + " @ " + homeTeam;
        }
    }
    setEventVenue() {
        this.model.venue = this.model.homeTeam.venue;
        if (this.model.homeTeam.venue) {
            this.eventTimeZone = this._timeZoneService.searchTimeZone(
                this.model.homeTeam.venue.timeZoneId
            ).abbr;
        }
    }
    onAwayTeamSelect(item: any) {
        this.model.awayTeam = item.item;
        this.model.awayTeam.imageId = null;
        //this.model.awayTeamId = item.item.id;
        setTimeout(() => {
            this.model.awayTeam.imageId = item.item.imageId;
        }, 100);

        this.setEventTitle();
    }
    onHomeTeamSelect(item: any) {
        this.model.homeTeam = item.item;
        this.model.homeTeam.imageId = null;
        //this.model.homeTeamId = item.item.id;
        setTimeout(() => {
            this.model.homeTeam.imageId = item.item.imageId;
        }, 100);

        if (item.item.venue) {
            this.model.venue = item.item.venue;
            this.venueSearchQueryTypeahead = item.item.venue.displayText;
        }
        this.setEventTitle();
        this.setEventVenue();
    }

    onVenueSelect(item: any) {
        this.model.venueId = item.id;
        this.model.venue = item.item;
        // this.eventTimeZone = timeZoneService.getTimeZones(item.timeZoneId).abbr;  //TODO
    }

    cancel() {
        this.log.trace("cancelled modal");
        if (this.budgetModalActive) {
            this.budgetModalActive = false;
            this.modalTitle = this.defaultModalTitle;
        } else {
            //$modalInstance.dismiss("cancel");
        }
    }

    // Set the value of manager ids
    setOnsiteProductionManagerId() {
        this.model.onsiteProductionManagerId = this.model
            .onsiteProductionManager
            ? parseInt(this.model.onsiteProductionManager.id, 10)
            : null;
    }

    setTechnicalManagerId() {
        if (this.model.technicalManager)
            this.model.technicalManagerId = parseInt(
                this.model.technicalManager.id,
                10
            );
        else this.model.technicalManagerId = null;
    }

    setOnsiteProductionManagerId2() {
        this.model.onsiteProductionManagerId2 = this.model
            .onsiteProductionManager2
            ? parseInt(this.model.onsiteProductionManager2.id, 10)
            : null;
    }

    setTechnicalManagerId2() {
        this.model.technicalManagerId2 = this.model.technicalManager2
            ? parseInt(this.model.technicalManager2.id, 10)
            : null;
    }

    setManPowerManagerId() {
        this.model.manPowerManagerId = this.model.manPowerManager
            ? parseInt(this.model.manPowerManager.id, 10)
            : null;
    }

    setHomeBaseManagerId() {
        this.model.homeBaseManagerId = this.model.homeBaseManager
            ? parseInt(this.model.homeBaseManager.id, 10)
            : null;
    }

    cntr = 0;
    openDatepicker($event: any) {
        if (this.cntr == 0 && $event.length == 2) {
            this.getSortedBroadcastDates();
            const minBroadcastDate = moment(
                _.first(this.sortedBroadcastDates).airDate
            );
            const startDate = moment($event[0]).format("MM/DD/YYYY");
            const endDate = moment($event[1]).format("MM/DD/YYYY");

            const validminDate = minBroadcastDate.isBetween(
                startDate,
                endDate,
                "days",
                "[]"
            );
            //let validmaxDate = maxBroadcastDate.isBetween(startDate, endDate, "days", '[]');
            this.calendar_broadcast_minDate = new Date();
            if (validminDate) {
                this.log.trace("Create Events --> DateRange selected");
                this.model.crewWorkStartDate = moment(startDate)
                    .format()
                    .toString();
                this.model.crewWorkEndDate = moment(endDate)
                    .format()
                    .toString();
                this.crewWorkScheduleValidation.required = false;
            } else {
                this.bsInlineRangeValue = [];
                this.model.crewWorkStartDate = null;
                this.model.crewWorkEndDate = null;
                this.cntr = +1;
            }
        } else {
            this.cntr = 0;
        }

        this.addBroadcastDateforBudget();
    }

    addBroadcastDateforBudget() {
        this.model.crewWorkStartDate;
        this.model.crewWorkEndDate;
        const currentDate = new Date(this.model.crewWorkStartDate);
        const endDate = new Date(this.model.crewWorkEndDate);
        const budgethours: any = [];

        while (currentDate <= endDate) {
            const budgethour = this.model.financials.budgetedHours.find(
                (obj) =>
                    moment(obj.broadCastDate).format("MM/DD/YYYY") ==
                    moment(currentDate).format("MM/DD/YYYY")
            );
            if (!budgethour) {
                const newDate = {
                    broadCastDate: _.cloneDeep(
                        moment(currentDate).format("YYYY-MM-DDT00:00:00")
                    ),
                    hours: null,
                };
                budgethours.push(newDate);
            } else {
                budgethours.push(_.cloneDeep(budgethour));
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        this.model.financials.budgetedHours = _.cloneDeep(budgethours);
    }

    checkOutOfSeasonRangeBroadcastDates() {
        if (this.selectedSeason) {
            _.forEach(this.model.broadcastDates, (value, index) => {
                this.checkOutOfSeasonRange(index);
            });
        }
    }

    SetModelValueAirDate(index: any, $event: any) {
        if ($event == "") {
            $event = undefined;
        }

        const defaultStartTime = "07:00 AM";
        const defaultEndTime = "03:00 PM";

        if (!_.isUndefined($event)) {
            this.model.broadcastDates[index].airDate = moment(
                moment($event).format("MM/DD/YYYY")
            )
                .format()
                .toString();
            this._clonedBroadcastDates[index].airDate =
                this.model.broadcastDates[index].airDate;
            //let date = moment($event).format('MM/DD/YYYY');
            //if (!this.model.broadcastDates[index].startTime) {
            //  this.model.broadcastDates[index].startTime = moment(date + ' ' + defaultStartTime);
            //}
            //if (!this.model.broadcastDates[index].endTime) {
            //  this.model.broadcastDates[index].endTime = $event = moment(date + ' ' + defaultEndTime);
            //}
            //this.defaultDate = $event
        }

        const dates = this.getSortedBroadcastDates();

        //if (!_.isEmpty(dates)) {
        //  this.sortedBroadcastDates = _.sortBy(this.model.broadcastDates, (dateObj) => {
        //    return new Date(dateObj.airDate);
        //  });
        //}
    }

    checkOutOfSeasonRange($index: any) {
        setTimeout(() => {
            if (
                this.model.broadcastDates &&
                this.model.broadcastDates[$index].airDate &&
                this.model.partOfSeason === true &&
                !!this.model.seasonId &&
                this.selectedSeason.startDate &&
                this.selectedSeason.endDate &&
                (this.selectedSeason.startDate.valueOf() >
                    this.model.broadcastDates[$index].airDate.valueOf() ||
                    this.selectedSeason.endDate.valueOf() <
                        this.model.broadcastDates[$index].airDate.valueOf())
            ) {
                this.model.broadcastDates[$index].outOfSeasonRange = true;
            } else {
                this.model.broadcastDates[$index].outOfSeasonRange = false;
            }
        }, 300);
    }

    // this.$watch("model.crewWorkStartDate + model.crewWorkEndDate", function () {
    //     if (this.updateEvent) {
    //         this.updateEvent.crewWorkSchedule = {};
    //         this.updateEvent.crewWorkSchedule.$error = {};
    //         this.updateEvent.crewWorkSchedule.$error.required = !(!!this.model.crewWorkStartDate && !!this.model.crewWorkEndDate);
    //     }
    // }, false);

    // budget modal stuff
    getEventStatusList() {
        this.subs.sink = this._EventService.getEventStatusList().subscribe({
            next: (result) => {
                this.eventStatusList = result.reference;
                this.eventStatusList.sort((a: any, b: any) =>
                    a.description.localeCompare(b.description)
                );
            },
            error: (err) => {
                this.log.showModalError(
                    " Unable to perform the search at this time. Please try again later. "
                );
            },
        });
    }
    getCompanyCodes() {
        this.subs.sink = this._BudgetService.getCompanyCodes().subscribe({
            next: (result) => {
                this.companyCode = result.reference;
            },
            error: (err) => {
                this.log.showModalError(
                    "Unable to fetch company codes at this time. Please try again "
                );
            },
        });
    }

    getChargeCodes() {
        this.chargeCodeType = "N";
        this.chargeCodeTypes = [
            {
                id: 0,
                name: 0,
                description: "N",
            },
            {
                id: 0,
                name: 0,
                description: "K",
            },
            {
                id: 0,
                name: 0,
                description: "M",
            },
            {
                id: 0,
                name: 0,
                description: "R",
            },
            {
                id: 0,
                name: 0,
                description: "T",
            },
            {
                id: 0,
                name: 0,
                description: "W",
            },
        ];
    }

    getBudgetQuery() {
        return {
            pageSize: 10,
            currentPage: 1,
            code: "",
            description: "",
            coCode: "",
        };
    }

    // this.$root.currentUser.userRoles.forEach(function (role) {
    //     if (role.description === "Payroll - SU") {
    //         this.viewNotes = false;
    //     }
    // });

    budgetSearch() {
        //TODO
        // log.trace("search");
        // this.budgetForm.submitted = true;
        // if (this.isOneFieldEntered()) {
        //     if (this.budgetQuery.coCode === "Select Company Code") {
        //         this.budgetQuery.coCode = "";
        //     }
        //     usSpinnerService.spin("modalSpinner");
        //     budgetService.search(this.budgetQuery).success(function (result) {
        //         log.trace("got results: " + JSON.stringify(result));
        //         this.wbsecodes = result;
        //         this.budgetQuery.currentPage = result.currentPage;
        //         this.budgetCodeError = false;
        //         this.showResultsContents = true;
        //     }).error(function (data) {
        //         log.error("got an error: " + JSON.stringify(data));
        //         this.$emit("showAlert", "danger", "Unable to perform the search at this time. Please try again later.", "modalAlert");
        //     }).finally(function () {
        //         log.trace("removing spinner");
        //         usSpinnerService.stop("modalSpinner");
        //     });
        // }
    }
    resetBudgetSearch() {
        this.log.trace("resetBudgetSearch()");
        this.budgetQuery = _.cloneDeep(this.getBudgetQuery());
        this.wbsecodes = {};
        this.showResultsContents = false;
        this.chargeCodeType = "N";
    }

    selectWbseCode(wbsecode: any, chargeCodeType: any) {
        this.model.budgetCode =
            wbsecode.coCode + "." + chargeCodeType + "." + wbsecode.code;
        this.cancel();
    }

    getEventManagers() {
        _.forEach(this.eventManagers, (managers) => {
            this.subs.sink = this._eventManagerService
                .eventManager([managers.id])
                .subscribe(
                    (result) => {
                        // this.log.trace("got results: " + JSON.stringify(result));
                        managers.values = result.reference;
                    },
                    (err) => {
                        this.log.error("got an error: ");
                    },
                    () => {}
                );
        });
    }

    isOneFieldEntered() {
        if (
            this.budgetQuery.code === "" &&
            this.budgetQuery.description === "" &&
            (this.budgetQuery.coCode === "" ||
                this.budgetQuery.coCode === "Select Company Code")
        ) {
            return false;
        } else {
            return true;
        }
    }

    // this.$watch("selectedSeason.name", function () {
    //     this.seasonName = this.selectedSeason.name;
    // });

    validateDateRange() {
        const startCrewDate = this.model.crewWorkStartDate;
        const endCrewDate = this.model.crewWorkEndDate;

        this.difference = moment(endCrewDate).diff(startCrewDate, "days");
    }

    /**************************** START: TIERED RATECARD CODE ***************************************/

    validateTierConfigResponse(
        newTierConfig: any,
        changedDateindex: any,
        oldNetworkId: any
    ) {
        this.isInitialLoad = false;

        const isOpenedFromSeasonSetup =
            _.has(this.model, "openedFromSeasonSetupPage") &&
            this.model.openedFromSeasonSetupPage &&
            !_.has(this.model, "id");

        if (isOpenedFromSeasonSetup) {
            //This is duplicated code from createEventContoller.js
            this.isInitialLoad = false;
            if (!this.tierConfig) {
                this.tierConfig = newTierConfig;
                this.tierNetworks = this.tierConfig.tierGroupNetworks;
                //this.model.tier = this.tierConfig.tierId;  <-- from create page
                this.currentTierStatus = this.setEvenTierStatus(newTierConfig); // _.cloneDeep(newTierConfig.tierId);
                this.model.networkGroupId = this.tierConfig.networkGroupId;
                if (changedDateindex) {
                    this.model.broadcastDates[changedDateindex].network =
                        this.getNetwork(
                            this.model.broadcastDates[changedDateindex]
                                .networkId
                        );
                }
            } else {
                // all cases will begin if we have the data from earlier transaction

                if (this.tierConfig && newTierConfig) {
                    this.isInitialLoad = false;
                    //if new and old tier are same
                    if (
                        this.tierConfig.networkGroupId !==
                            newTierConfig.networkGroupId &&
                        this.tierConfig.tierId === 0 &&
                        newTierConfig.tierId === 0
                    ) {
                        this.tierConfig = newTierConfig;
                        this.tierNetworks = newTierConfig.tierGroupNetworks;
                        this.model.networkGroupId =
                            this.tierConfig.networkGroupId;
                        this.model.eventTierSetup.tierId =
                            this.model.eventTierSetup.tierId;
                    }

                    //case 1: Broadcast Network changes it to a different network which is designated to a different Tierd Grouping,  System must display message,
                    if (
                        this.tierConfig.isTierEnabled &&
                        newTierConfig.isTierEnabled &&
                        this.tierConfig.networkGroupId !=
                            newTierConfig.networkGroupId
                    ) {
                        const msg =
                            "You are changing Event to another Tiered Grouping, do  you wish to proceed?";
                        this.showValidationModal(
                            "Tiered Rate Card",
                            msg,
                            true,
                            this.tierConfigChangeWorkflow.bind(this),
                            [newTierConfig],
                            "Proceed",
                            "Cancel",
                            this.cancelTransactionWorkflow.bind(this),
                            [changedDateindex, oldNetworkId]
                        );

                        return;
                    }

                    // case 2: Network changes to a different network which is designated to a Non-Tiered Event,  System must display message,
                    //"You are changing from a Tiered to a Non-Tiered Event, do  you wish to proceed?"
                    else if (
                        this.tierConfig.isTierEnabled &&
                        !newTierConfig.isTierEnabled
                    ) {
                        const msg =
                            "You are changing from a Tiered to a Non-Tiered Event, do  you wish to proceed?";
                        this.showValidationModal(
                            "Tiered Rate Card",
                            msg,
                            true,
                            this.tierConfigChangeWorkflow.bind(this),
                            [newTierConfig],
                            "Proceed",
                            "Cancel",
                            this.cancelTransactionWorkflow.bind(this),
                            [changedDateindex, oldNetworkId]
                        );

                        return;
                    }

                    // case 3:  Broadcast Network, and changes to a different network which is designated to Tierd Grouping,  System must display message,
                    //"You are changing from a Non-Tiered to a Tiered Event, do  you wish to proceed?"
                    else if (
                        !this.tierConfig.isTierEnabled &&
                        newTierConfig.isTierEnabled
                    ) {
                        const msg =
                            "You are changing from a Non-Tiered to a Tiered Event, do  you wish to proceed?";
                        this.showValidationModal(
                            "Tiered Rate Card",
                            msg,
                            true,
                            this.tierConfigChangeWorkflow.bind(this),
                            [newTierConfig],
                            "Proceed",
                            "Cancel",
                            this.cancelTransactionWorkflow.bind(this),
                            [changedDateindex, oldNetworkId]
                        );
                        return;
                    }
                    if (
                        !this.tierConfig.isTierEnabled &&
                        !newTierConfig.isTierEnabled &&
                        this.tierConfig.networkGroupId !=
                            newTierConfig.networkGroupId
                    ) {
                        this.tierConfigChangeWorkflow(newTierConfig);
                        return;
                    } else if (
                        (!this.tierConfig.isTierEnabled &&
                            !newTierConfig.isTierEnabled) ||
                        (this.tierConfig.isTierEnabled &&
                            newTierConfig.isTierEnabled)
                    ) {
                        this.tierConfigChangeWorkflowForNonTier(newTierConfig);
                        return;
                    }
                }
            }
        } else {
            //first load
            if (!this.tierConfig) {
                this.isInitialLoad = false;
                this.tierConfig = newTierConfig;
                this.tierNetworks = this.tierConfig.tierGroupNetworks;
                this.model.networkGroupId = this.tierConfig.networkGroupId;
                this.currentTierStatus = this.setEvenTierStatus(newTierConfig); // _.cloneDeep(newTierConfig.tierId);
                if (changedDateindex) {
                    this.model.broadcastDates[changedDateindex].network =
                        this.getNetwork(
                            this.model.broadcastDates[changedDateindex]
                                .networkId
                        );
                }
            }
            //when earlier configuration exists and new is loaded
            else if (
                this.model.eventTierSetup &&
                newTierConfig &&
                oldNetworkId !== null
            ) {
                this.isInitialLoad = false;
                //Don’t allow to Change. closed and inProgress Show Message, "A Broadcast Network Cannot be changed to a Different Network Group after an event has Closed", with "OK" button.
                // tiered to tiered
                // non-tier to tier
                // tier to non-tier
                //conditions are not required to write as the group change is not allowed
                if (
                    this.model.eventTierSetup.networkGroupId !=
                        newTierConfig.networkGroupId &&
                    _.includes(
                        this.INVALID_EXEC_STATUS_TOUPDATE,
                        this.eventData.eventTierSetup.eventExecutionStatus.toUpperCase()
                    )
                ) {
                    const msg = this.getMessageWRTExecutionStatus(
                        this.eventData.eventTierSetup.eventExecutionStatus
                    );
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        false,
                        null,
                        null,
                        null,
                        "OK",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );
                    return;
                } else if (
                    this.model.eventTierSetup.isTierEnabled &&
                    newTierConfig.isTierEnabled &&
                    this.model.eventTierSetup.networkGroupId !=
                        newTierConfig.networkGroupId
                ) {
                    const msg =
                        "You are changing current Event to another Tiered Rate Card Group, All approved BTL Positions will be unconfirmed and all existing Tiered Rate Cards and Rates will be cleared, do you wish to continue?";
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        [newTierConfig],
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );
                    return;
                } else if (
                    !this.model.eventTierSetup.isTierEnabled &&
                    newTierConfig.isTierEnabled
                ) {
                    const msg =
                        "You are changing current Event to a Tiered Rate Card Group, All approved BTL Positions will be unconfirmed and all existing Rate Cards and Rates will be cleared, do you wish to continue?";
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        [newTierConfig],
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );
                    return;
                } else if (
                    this.model.eventTierSetup.isTierEnabled &&
                    !newTierConfig.isTierEnabled
                ) {
                    const msg =
                        "You are changing current Event to a Non-Tiered Event, All approved BTL Positions will be unconfirmed and all existing Tiered Rate Cards and Rates will be cleared, do you wish to continue?";
                    this.showValidationModal(
                        "Tiered Rate Card",
                        msg,
                        true,
                        this.tierConfigChangeWorkflow.bind(this),
                        [newTierConfig],
                        "Proceed",
                        "Cancel",
                        this.cancelTransactionWorkflow.bind(this),
                        [changedDateindex, oldNetworkId]
                    );
                    return;
                } else if (
                    !this.model.eventTierSetup.isTierEnabled &&
                    !newTierConfig.isTierEnabled &&
                    this.model.eventTierSetup.networkGroupId !=
                        newTierConfig.networkGroupId
                ) {
                    this.tierConfigChangeWorkflow(newTierConfig);
                    return;
                } else if (
                    this.model.eventTierSetup.networkGroupId ===
                        newTierConfig.networkGroupId &&
                    newTierConfig.networkGroupId !==
                        this.tierConfig.networkGroupId
                ) {
                    this.tierConfigChangeWorkflow(newTierConfig);
                    return;
                } else if (
                    (!this.model.eventTierSetup.isTierEnabled &&
                        !newTierConfig.isTierEnabled) ||
                    (this.model.eventTierSetup.isTierEnabled &&
                        newTierConfig.isTierEnabled)
                ) {
                    this.tierConfigChangeWorkflowForNonTier(newTierConfig);
                    return;
                }
            }
        }
    }

    getMessageWRTExecutionStatus(executionStatus: any) {
        let msg = "";

        switch (executionStatus.toUpperCase()) {
            case "CLOSED":
                msg =
                    "A Broadcast Network Cannot be changed to a Different Network Group after an event has Closed";
                break;

            case "INPROGRESS":
                msg =
                    "A Broadcast Network Cannot be changed to a Different Network Group for events in progress";
                break;
        }
        return msg;
    }

    tierConfigChangeWorkflow(newTierConfig: any) {
        //clearing secondary broadcasting networks
        for (let i = 0; i < this.model.broadcastDates.length; i++) {
            //maintain primary network
            if (i === this.PRIMARY_DATE_INDEX) {
                this.model.broadcastDates[i].network = this.getNetwork(
                    this.model.broadcastDates[i].networkId
                );
                continue;
            }

            this.model.broadcastDates[i].networkId = "";
            this.model.broadcastDates[i].network = null;
        }

        this.currentTierStatus = this.setEvenTierStatus(newTierConfig); // _.cloneDeep(newTierConfig.tierId);

        //update local store with latest config
        this.tierConfig = newTierConfig;
        this.tierNetworks = newTierConfig.networks;
        this.model.networkGroupId = this.tierConfig.networkGroupId;

        this.trackConfigChange();
        this.findPrimaryBroadcastingDate();
    }

    tierConfigChangeWorkflowForNonTier(newTierConfig: any) {
        //update local store with latest config
        for (let i = 0; i < this.model.broadcastDates.length; i++) {
            //must maintain first date
            if (i === this.PRIMARY_DATE_INDEX) {
                this.model.broadcastDates[i].network = this.getNetwork(
                    this.model.broadcastDates[i].networkId
                );
                continue;
            }
        }
        this.currentTierStatus = this.setEvenTierStatus(newTierConfig); // _.cloneDeep(newTierConfig.tierId);
        this.tierConfig = newTierConfig;
        this.tierNetworks = newTierConfig.tierGroupNetworks;
        this.model.tier = this.tierConfig.tierId;
        this.model.networkGroupId = this.tierConfig.networkGroupId;
        this.findPrimaryBroadcastingDate();
    }

    trackConfigChange() {
        if (
            !_.isEmpty(this.eventData.broadcastDates) &&
            !_.isEmpty(this.model.broadcastDates) &&
            this.eventData.broadcastDates[this.PRIMARY_DATE_INDEX] &&
            this.model.broadcastDates[this.PRIMARY_DATE_INDEX]
        ) {
            //the tier setup depends on the first broadcasting network in the event schedule.
            //This method will help to find user confirmation
            //if the user uses same network, we will not clear the information.
            if (
                this.eventData.broadcastDates[this.PRIMARY_DATE_INDEX]
                    .networkId !==
                this.model.broadcastDates[this.PRIMARY_DATE_INDEX].networkId
            ) {
                this.model.changeTracker.networks = [];
                const changedNetwork = _.find(
                    this.networks,
                    {
                        id: this.model.broadcastDates[this.PRIMARY_DATE_INDEX].networkId
                    }
                );
                this.model.changeTracker.networks.push(changedNetwork);
            } else {
                this.model.changeTracker = {
                    networks: [],
                    eventTiers: [],
                };
            }
        }
    }

    cancelTransactionWorkflow(changedDateindex: any, oldNetworkId: any) {
        //reset the network value
        if (this.model.broadcastDates[changedDateindex]) {
            this.model.broadcastDates[changedDateindex].networkId =
                oldNetworkId;
            this.model.broadcastDates[changedDateindex].network =
                this.getNetwork(oldNetworkId);
            this.findPrimaryBroadcastingDate();
        }
    }

    /*
     * Generic function to show the modal. All parameters are customizable per need
     */
    showValidationModal(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any
    ) {
        //if (confirmText == null)
        //  confirmText = "Continue"

        const initialState: any = {
            self: this,
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText,
            hideConfirm: !canUpdate,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe(() => {
            callback(...args);
            this.findPrimaryBroadcastingDate();
        });
        this.bsModalRef.content.onRejection.subscribe(() => {
            cancelCallback(...cancelargs);
        });

        // log.trace("showValidationErrorModal");
        // var modalInstance;

        // modalInstance = $modal.open({
        //     templateUrl: "/scripts/app/views/shared/modalConfirmation.html",
        //     controller: "modalConfirmationController",
        //     keyboard: false,
        //     backdrop: "static",
        //     resolve: {
        //         modalTitle: function () {
        //             return title;
        //         },
        //         modalMessage: function () {
        //             return message;
        //         },
        //         confirmText: function () {
        //             return confirmText || "Continue";
        //         },
        //         cancelText: function () {
        //             return !canUpdate ? (cancelText ? cancelText : "Close") : (cancelText || undefined);
        //         },
        //         hideConfirm: function () {
        //             return !canUpdate;
        //         }
        //     }
        // });

        // modalInstance.result.then(function () {
        //     log.trace("showValidationErrorModal - Modal dismissed");

        //     // if we have a callback, fire it
        //     if (typeof callback === "function") {
        //         callback.apply(null, args);
        //     }
        // }, function () {
        //     if (typeof cancelCallback === "function") {
        //         cancelCallback.apply(null, cancelargs);
        //     }
        // });
    }

    closeAlert() {}

    /**************************** END: TIERED RATECARD CODE *****************************************/

    getEventTimesheetDateBoundaries() {
        //TODO
        // log.trace("getEventTimesheetDateBoundaries called");
        if (!this.eventData.id) {
            this.disableCrewWorkScheduleDatepicker = false;
            return true;
        }
        // return;
        // usSpinnerService.spin("modalSpinner");
        this.subs.sink = this._EventService
            .getEventTimesheetDateBoundaries(this.eventData.id)
            .subscribe({
                next: (result: any) => {
                    // this.log.trace("got results: " + JSON.stringify(result));
                    this.disableCrewWorkScheduleDatepicker = false;
                    this.eventTimesheetDateBoundaries = result.reference;
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                },
            });
        return;
    }

    clearBroadcastType() {
        if (!this.model.isSportsEventWebsitePublished) {
            this.model.broadcastType = null;
            this.model.tapeDate = null;
        }
    }

    getMinAirDate() {
        for (let i = 0; i < this.model.broadcastDates.length; i++) {
            if (i == 0) {
                this.minAirDate = this.model.broadcastDates[0].airDate;
            } else if (this.model.broadcastDates.length > 1) {
                if (this.minAirDate > this.model.broadcastDates[i].airDate) {
                    this.minAirDate = this.model.broadcastDates[i].airDate;
                }
            } else {
                this.minAirDate = this.model.broadcastDates[i].airDate;
            }
        }
    }
    clearSportsEventWebsitePublished() {
        const selectedItem = this.model.statusCode.description;
        if (selectedItem === "Cancelled" || selectedItem === "Inactive") {
            if (this.model.isSportsEventWebsitePublished) {
                this.model.isSportsEventWebsitePublished = false;
                this.model.broadcastType = null;
                this.model.tapeDate = null;
            }
        }
    }

    /*
     * Met
     */
    findPrimaryBroadcastingDate() {
        this.PRIMARY_DATE_INDEX = null;
        this.broadCastdateBeingChanged = false;
        const foundNbcNetwork = false;
        const dates = _.cloneDeep(this.model.broadcastDates);
        let sortedDates: any = "";
        if (!_.isEmpty(dates)) {
            sortedDates = _.sortBy(
                this.model.broadcastDates,
                function (dateObj) {
                    return new Date(dateObj.airDate);
                }
            );
        }

        //"NBC" will have most precedence.
        // this.PRIMARY_DATE_INDEX = _.findIndex(this.model.broadcastDates, "network.description", "NBC");

        const firstNBCBroadcastingIndex = _.findIndex(
            sortedDates,
            (airdate: any) => {
                if (airdate.network) {
                    return airdate.network.description === "NBC";
                }
                return false;
            }
        );

        if (firstNBCBroadcastingIndex >= 0) {
            this.PRIMARY_DATE_INDEX = _.findIndex(
                this.model.broadcastDates,
                {
                    "airDate": sortedDates[firstNBCBroadcastingIndex].airDate
                }
            );
        }
        if (
            (!this.PRIMARY_DATE_INDEX || this.PRIMARY_DATE_INDEX < 0) &&
            !foundNbcNetwork
        ) {
            let isPrimaryFound = false;
            let i = 0;
            while (!isPrimaryFound && i < sortedDates.length) {
                if (
                    _.has(sortedDates[i], "network") &&
                    !_.isEmpty(sortedDates[i].network)
                ) {
                    //need to find the corrosponding date from broadcast array

                    if (_.has(sortedDates[i], "airDate")) {
                        this.PRIMARY_DATE_INDEX = _.findIndex(
                            this.model.broadcastDates,
                            {
                                "airDate": sortedDates[i]["airDate"]
                            }
                        );
                    } else {
                        this.PRIMARY_DATE_INDEX = _.findIndex(
                            this.model.broadcastDates,
                            {
                                'network.description':  sortedDates[i].network.description
                            }
                        );
                    }

                    // this.PRIMARY_DATE_INDEX = i;
                    isPrimaryFound = true;
                }
                i = i + 1;
            }
        }

        if (
            this.PRIMARY_DATE_INDEX == null &&
            !_.isEmpty(this.model.broadcastDates) &&
            this.model.broadcastDates.length > 0
        ) {
            this.PRIMARY_DATE_INDEX = _.findIndex(
                this.model.broadcastDates,
                {
                    airDate: sortedDates[0]["airDate"]
                }
            );
        }
        //this.PRIMARY_DATE_INDEX = this.PRIMARY_DATE_INDEX + 1;
    }

    setEvenTierStatus(newTierConfig: any) {
        if (
            _.includes(
                this.INVALID_EXEC_STATUS_TOUPDATE,
                this.eventData.eventTierSetup.eventExecutionStatus.toUpperCase()
            )
        ) {
            return this.eventData.tier;
        } else {
            return _.cloneDeep(newTierConfig.tierId);
        }
    }

    init() {
        if (_.isEmpty(this.model.statusCode)) {
            this.model.statusCode = {
                id: 1,
                description: "Planning",
            };
        }

        if (!this.model.flex && !this.eventName && this.isSeasonSetup) {
            this.model.flex = "N";
        }

        if (!this.model.level && !this.eventName && this.isSeasonSetup) {
            this.model.level = 1;
        }

        if (
            !this.model.broadcastDates ||
            this.model.broadcastDates.length === 0
        ) {
            this.addBroadcastDate();
        } else {
            this.getNetworkGroupSetupByNetworkGroupId();
        }

        if (this.model.id && this.model.id > 0) {
            this.isInitialLoad = false;
        }

        if (this.model.tapeDate !== null) {
            this.model.tapeDate = new Date(
                moment.utc(this.model.tapeDate).year(),
                moment.utc(this.model.tapeDate).month(),
                moment.utc(this.model.tapeDate).date()
            );
        }
        this.getEventManagers();
        this.getEventStatusList();
        this.getMinAirDate();
        this.clearBroadcastType();
        this.checkEventNetworks();
        this.checkSeasons();
        this.getPositionTemplates();
        this.getCompanyCodes();
        this.getSelectedSeason();
        this.getChargeCodes();
        this.getEventTimesheetDateBoundaries();
        this.getNonTieredNetworks();
        this.getAllTierNetworkSetup();
        this.findPrimaryBroadcastingDate();

        setTimeout(() => {
            this.loadDates = true;
        }, 2000);

        if (
            _.has(this.model, "openedFromSeasonSetupPage") &&
            this.model.openedFromSeasonSetupPage &&
            this.eventData.id > 0
        ) {
            setTimeout(() => {
                if (this.PRIMARY_DATE_INDEX > -1) {
                    const networkId =
                        this.model.broadcastDates[this.PRIMARY_DATE_INDEX]
                            .networkId;
                    this.getNetworkGroupSetup(
                        null,
                        networkId,
                        this.PRIMARY_DATE_INDEX + 1,
                        null
                    );
                }
            }, 1000);
        }

        if (
            !this.eventData.networkGroupId ||
            this.eventData.networkGroupId === 0
        ) {
            const reassociatedNetworkGroupId = true;

            this.tierConfig = null;
            this.tierNetworks = null;
            this.model.networkGroupId = null;
            if (this.PRIMARY_DATE_INDEX > -1) {
                const networkId =
                    this.model.broadcastDates[this.PRIMARY_DATE_INDEX]
                        .networkId;

                if (networkId || networkId > 0) {
                    this.getEventTierSetup(
                        networkId,
                        null,
                        null,
                        reassociatedNetworkGroupId
                    );
                }
            }
        }
    }

    ngOnInit() {
        this.onSync = new Subject();
        setTimeout(() => {
            this.originalBroadcastDates =
                _.cloneDeep(this.eventData.broadcastDates) || [];
            const originalBroadcastAirDates =
                _.cloneDeep(this.eventData.broadcastAirDates) || [];

            this.eventData.broadcastDates = this.eventData.broadcastDates || [
                this.getBroadcastDate(),
            ];
            this.model = this.eventData;
            this.model = _.cloneDeep(this.eventData);
            const x = _.cloneDeep(this.eventData);
            if (!this.eventData.id) {
                this.disableCrewWorkScheduleDatepicker = false;
            }

            this._clonedBroadcastDates =
                _.cloneDeep(this.eventData.broadcastAirDates) || [];
            let originalAirDates: any = [];
            _.forEach(this.originalBroadcastDates, function (value, key) {
                value.isAirDate = false;
                value.network = "";
                value.networkId = null;
            });
            _.forEach(originalBroadcastAirDates, function (value, key) {
                originalAirDates.push(_.cloneDeep(value));
            });

            if (!_.isEmpty(originalAirDates)) {
                this.sortedBroadcastDates = _.sortBy(
                    originalAirDates,
                    function (dateObj) {
                        return new Date(dateObj.airDate);
                    }
                );

                originalAirDates = this.sortedBroadcastDates;
            }

            this.venueSearchQueryTypeahead = this.model.venue.displayText;
            this.HomeSearchQueryTypeahead = this.model?.homeTeam?.name;
            this.AwaySearchQueryTypeahead = this.model?.awayTeam?.name;

            if (this.model.crewWorkStartDate && this.model.crewWorkEndDate) {
                this.bsInlineRangeValue = [
                    new Date(this.model.crewWorkStartDate),
                    new Date(this.model.crewWorkEndDate),
                ];
                // this.model.statusCode= this.model.statusCode.id;
            }

            if (
                this.model.openedFromSeasonSetupPage &&
                !this.model.eventTierSetup
            ) {
                this.model.eventTierSetup = {
                    networkGroupId: null,
                    eventTierSetup: "None",
                };
            }

            this.model.broadcastDates = originalAirDates;
            _.each(this.model.broadcastDates, (value: any, key) => {
                if (!_.isEmpty(value.airDate)) {
                    this.model.broadcastDates[key].airDate = new Date(
                        value.airDate
                    );
                }
            });

            this.checkEventType();
            this.checkForSeason();
            this.getSeasons();
            this.viewNotes = true;
            this.budgetQuery = this.getBudgetQuery();
            this.VenueNameTypeahead();
            this.AwayTeamTypeahead();
            this.HomeTeamTypeahead();
            this.init();
        }, 200);
        // this fix bug ui of datapicker when scrool page or change size of window and datapicker is load, dont touch
        this.document
            ?.querySelector("modal-container")
            ?.addEventListener("scroll", function () {});
        window.addEventListener("resize", () => {});
    }
    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
