<header id="col-main-hdr">
    <h1><i class="fa fa-money"></i> Reports</h1>
</header>
<nav class="breadcrumb">
    <ul>
      <li home></li>
        <li>/</li>
        <li>Reports</li>
    </ul>
</nav>
<div class="page-container">
    <div class="container-fluid">
        <!-- <tabset class="content-tabs contact-tabs-lg">
        <tab *ngFor="let t of tabs"
             [heading]="t.heading"
             (selectTab)="go(t.route)"
             [active]="t.active">
        </tab>
      </tabset> -->
        <router-outlet></router-outlet>
    </div>

</div>
