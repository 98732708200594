<div class="page-container-tbl">
  <div class="container-fluid-tbl">
    <div class="row-tbl">
      <div class="col-md-4 box-dash ">
        <div class="box-dash-hdr-bar">
          <h2 class="box-dash-hdr">Upcoming Events</h2>
          <div class="pull-right btn-fullscreen hidden-sm hidden-xs" maximize #maximize="maximize"><span (click)="maximize.maximize()"></span></div>
        </div>
        <div class="box-dash-inner-dflt main-dsh-google-maps-adj">

          <div class="form-group">
            <div class="input-group input-group-set" dropdown>
              <span class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </span>
              <span>
                <input type="text"
                       placeholder="Daterangepicker"
                       class="form-control"
                       bsDaterangepicker
                       readonly
                       [bsValue]="bsInlineRangeValue"
                       #drp="bsDaterangepicker"
                       (bsValueChange)="onToggleFilterDatepicker($event)"
                       [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">
              </span>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group-set" dropdown>
              <span class="input-group-addon dropdownToggle">
                <i class="fa fa-filter"></i>
              </span>
              <select class="dropdown nbc-dropdown"
                      style="width:100%"
                      [(ngModel)]="upcomingEventsQuery.eventTypeId"
                      (ngModelChange)="filterResults();">
                <option [value]="undefined">All Event Types</option>
                <option *ngFor="let eventType of eventTypes | orderBy :['name']"
                        [value]="eventType.id">
                  {{eventType.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table-dash table-valign-mid" *ngIf="upcomingEvents?.eventTypeSummaries?.length>0 ">
              <tbody>
                <!--<tr *ngFor="let event of upcomingEvents.eventTypeSummaries | slice:0:4" class="txt-theme-{{ event.type }}">-->
                <tr *ngFor="let event of upcomingEvents.eventTypeSummaries | slice:0:4">
                  <!--<td><img width="45" height="45" src="assets/image/eventType/{{event.imageId || 0}}"></td>-->
                  <td><img width="45" height="45" src="assets/image/eventType/0"></td>
                  <!--<td><span class="box-dash-count">{{ event.count }}</span></td>-->
                  <td><span class="box-dash-count"></span></td>
                  <td class="table-dash-col-full">
                    <!--<span class="box-dash-hdr-sub txt-uppercase">{{ event.name | uppercase }}</span>-->
                    <span class="box-dash-hdr-sub txt-uppercase"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p *ngIf="upcomingEvents?.eventTypeSummaries?.length==0 && eventTypeSearchComplete">
            No Results
          </p>

          <a authenticate-user="event.create"
             class="btn btn-primary btn-block"
             routerLink="../Events/CreateSeasonOrEvent">+ Create Season or Event</a>

        </div>
      </div>
      <div class="col-md-8 box-dash col-tbl-middle google-maps-container clearfix">
        <img src="Content/images/Map_USA.jpg" class="map-container" />
      </div>
    </div>

  </div>

</div>

