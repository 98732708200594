//import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { map, tap } from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Observer, of, Subscriber } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-event-location-report",
    templateUrl: "./event-location-report.component.html",
})
export class EventLocationReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}
    //  @Input() reportName = modelValue;
    reportName: any;
    eventsData: any[] = [];
    suggestions$?: Observable<any[]> | undefined;
    autoCompleteByOnlyEvent: any;
    autoCompleteByOnlyEventTerm: any;
    ShowEventsTypeRowAll = true;
    isExport = true;
    ShowEventsTypeEditRow = false;
    //$scope.ShowEventsTypeSelectedRow = false;
    ShowEventsTypeTagRow = false;
    eventLocationReport: any;
    getCriteriaData: any;
    // eventId = window.preload.eventId;
    eventId = 13;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    // userName = $scope.user.firstName + " " + $scope.user.lastName;
    userName;
    eventsTypeList: any[] = [];
    // $scope.rateCard = [];
    selectedEvent: any[] = [];
    events: any[] = [];
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    showAlertMessage: any;
    eventLocationReportInfo: any;
    alerts: any;

    openDateRangeDatepicker(event) {
        this.log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
    }

    onItemSelect($event) {
        this.selectedEvent = $event;
    }

    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    onToggleFilterDatepicker(event) {
        this.eventLocationReportInfo.dateRangeStartDate = event[0];
        this.eventLocationReportInfo.dateRangeEndDate = event[1];
    }
    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    editRow(value) {
        switch (value) {
            case "EventsRow":
                {
                    this.ShowEventsTypeRowAll = false;
                    this.ShowEventsTypeEditRow = true;
                    this.ShowEventsTypeTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }
    confirmSelection(value, item?) {
        if (value === "EventsRow") {
            this.ShowEventsTypeEditRow = false;
            this.showSection(value);
        }
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
        }

        if (
            this.eventLocationReportInfo.dateRangeStartDate &&
            this.eventLocationReportInfo.dateRangeEndDate
        ) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }
    }

    showSection(value) {
        switch (value) {
            case "EventsRow":
                {
                    if (
                        !_.isUndefined(this.selectedEvent) &&
                        this.selectedEvent.length > 0
                    ) {
                        this.ShowEventsTypeRowAll = false;
                        this.ShowEventsTypeEditRow = false;
                        this.ShowEventsTypeTagRow = true;
                    } else {
                        this.ShowEventsTypeRowAll = true;
                        this.ShowEventsTypeTagRow = false;
                        this.ShowEventsTypeEditRow = false;
                    }
                    break;
                }
                break;
            case "DateRangeRow":
                {
                    if (
                        this.eventLocationReportInfo.dateRangeStartDate !==
                            null &&
                        this.eventLocationReportInfo.dateRangeEndDate !==
                            null &&
                        this.eventLocationReportInfo.dateRangeStartDate !==
                            undefined &&
                        this.eventLocationReportInfo.dateRangeEndDate !==
                            undefined
                    ) {
                        this.showDateRangeRowAll = false;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = true;
                    } else {
                        this.showDateRangeRowAll = true;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = false;
                    }
                    break;
                }
                break;
        }
    }

    setSearchEventTerm($event) {
        this.autoCompleteByOnlyEventTerm = $event;
    }
    stateChanged(checked, value, row) {
        if (row === "EventsRow") {
            if (checked) {
                this.eventsTypeList.push(value);
                this.eventLocationReportInfo.events.push(value);
                //document.getElementById("PayrateButton").disabled = false;
                const element = document.getElementById(
                    "PayrateButton"
                ) as HTMLElement;
                element.setAttribute("disabled", "true");
            }
            if (!checked) {
                this.eventsTypeList.splice(
                    this.eventsTypeList.indexOf(value),
                    1
                );
                this.eventLocationReportInfo.events.splice(
                    this.eventLocationReportInfo.events.indexOf(value),
                    1
                );
                //if ($scope.eventsTypeList.length === 0) {
                //    document.getElementById("PayrateButton").disabled = true;
                //}
            }
        }
    }

    removeTag(item, row, index) {
        if (row === "EventsRow") {
            this.eventsTypeList.splice(this.eventsTypeList.indexOf(item), 1);
            this.selectedEvent.splice(this.selectedEvent.indexOf(item), 1);
            item.value = !item.value;
            this.showSection("EventsRow");
        }
    }
    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.eventLocationReportInfo.dateRangeStartDate = null;
            this.eventLocationReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (
                !this.eventLocationReportInfo.dateRangeStartDate &&
                !this.eventLocationReportInfo.dateRangeEndDate
            ) {
                this.isExport = true;
            } else {
                this.isExport = false;
            }
        }
    }

    geteventLocationReportDetails() {
        this._reportService.geteventLocationDetails().subscribe({
            next: (data) => {
                if (data == null) {
                    this.showAlertMessage = true;
                    this.log.showModalError(
                        "There are no data points to export for this report at the moment."
                    );
                } else {
                    this.eventLocationReport = data;
                    this.getCriteriaData = data;
                }
            },
            error: (data) => {
                this.log.error("get got an error: " + JSON.stringify(data));
                this.showAlertMessage = true;
            },
        });
    }

    exportEventLocationReport(data?) {
        const tempEventLocationReportInfo: any = {};

        tempEventLocationReportInfo.eventType = this.selectedEvent;
        tempEventLocationReportInfo.dateRangeStartDate = moment(
            this.eventLocationReportInfo.dateRangeStartDate
        ).format("MM/DD/yyyy");
        tempEventLocationReportInfo.dateRangeEndDate = moment(
            this.eventLocationReportInfo.dateRangeEndDate
        ).format("MM/DD/yyyy");

        this.isDownload = true;

        this._reportService
            .exporteventLocationReport(
                tempEventLocationReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.eventLocationReport = this.getCriteriaData;
                        const fileName = "SCORE-EventLocation_";
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.showAlertMessage = false;
                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    this.log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                },
            });
    }
    openInExcel(data, fileName) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(blob);
        const d = moment(new Date()).format("MM-dd-yyyy");
        const a = document.createElement("a");
        a.href = fileURL;
        a.target = "_blank";
        a.download = fileName + d + ".xlsx";
        document.body.appendChild(a);
        a.click();
        // }
    }
    ngOnInit(): void {
        this.eventLocationReportInfo = {};
        this.geteventLocationReportDetails();
        //event type
        this.eventLocationReportInfo.eventsType = [];
        this.eventLocationReportInfo.dateRangeStartDate = null;
        this.eventLocationReportInfo.dateRangeEndDate = null;
    }
}
