import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';

@Injectable({
  providedIn: 'root'
})
export class tierPositionRateCard {

  constructor(  private log: NGXLogger,private http: HttpClient,  private _apiroot: BaseUrlHelperService,) { }



  upsertRate (tierPositionId, positionTier, isCurrentRate, networkGroupId): Observable<any>  {
  return this.http.post(this._apiroot.baseUrl+ "tierratecard/wizard/tierPositionRateCard/" + networkGroupId + "/"+ tierPositionId + "/upsert/" + isCurrentRate, positionTier).pipe(
    catchError((err) => {
      return throwError(err);
    })
  );;
};


upsertTierPosition (networkGroupId, tierPosition): Observable<any>  {
  return this.http.post(this._apiroot.baseUrl+ "tierratecard/wizard/tierPositionRateCard/" + networkGroupId + "/UpsertTierPosition", tierPosition).pipe(
    catchError((err) => {
      return throwError(err);
    })
  );;
};






 
}
