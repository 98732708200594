import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpRouterModule } from "./help-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NbcSharedModule } from "src/app/Shared/shared.module";
import { HelpComponent } from "../help.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";

@NgModule({
    declarations: [HelpComponent, ContactUsComponent, ThankYouComponent],
    imports: [
        CommonModule,
        FormsModule,
        NbcSharedModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        HelpRouterModule.forChild(),
    ],
    exports: [HelpComponent],
})
export class HelpModule {}
