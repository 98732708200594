import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { IdentityComponent } from "./Dashboard/identity.component";
import { AnnouncementComponent } from "./Announcement/announcement.component";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgPipesModule } from "ngx-pipes";
import { SideNavComponent } from "./Shared/sideNav.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CookieService } from "ngx-cookie-service";
import { TimeZoneHelperService } from "./Shared/helper/timezonehelper.service";
import { CustomHttpInterceptorService } from "./Shared/helper/customHttpInterceptor.service";

import { SharedDataService } from "./Shared/sharedData.service";
import { TemplatesModule } from "./Templates/templates.module";
import { CoreModule } from "./Core/core.module";
import { GlobalLoaderModule } from "./external/loader/global-loader.module";
import { identityFactory } from "./Core/services/identity.service";
//import { SandboxModule } from "./sandbox/sandbox.module";
import { NgChartsModule } from "ng2-charts";
import { AuthGuardService } from "./Core/services/auth-guard.service";
import { NbcUnauthorized } from "./Shared/components/unauthorized/nbc-unauthorized.component";

import { TierRateCardModule } from "./TierRatecard/tierratecard.module";
import { DashboardModule } from "./Dashboard/dashboard.module";
import { CustomPreloadingStrategyService } from "./Shared/helper/custom-preloading-strategy.service";
import { NbcIdleComponent } from "./Core/component/nbc-idle/nbc-idle.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { NbcSharedModule } from "./Shared/shared.module";
//import {
//    LiquidCacheStorageTypes,
//    NgxLiquidCacheModule,
//} from "ngx-liquid-cache";
import { UiSettingsFactory } from "./Core/services/uisettings.service";
import { RemotesCookieFactory } from "./Core/services/remotes-cookie-provider.service";
import { EventModule } from "./Events/event.module";
import { ContactModule } from "./Contact/contact.module";
import { PayrollModule } from "./Payroll/payroll.module";
import { OnboardingModule } from "./Onboarding/onboarding.module";
import { AdminModule } from "./admin/admin.module";
import { ReportsModule } from "./reports/reports.module";
import { HelpModule } from "./Misc/help/help.module";

/*import { PositionFilterPipe } from '../app/Dashboard/position-request-onboarding/position-filter.pipe';*/


@NgModule({
    declarations: [
        AppComponent,
        IdentityComponent,
        AnnouncementComponent,
        SideNavComponent,
        NbcUnauthorized,
        NbcIdleComponent,
        /*PositionFilterPipe*/
    ],
    imports: [
        CoreModule,
        NbcSharedModule,
        GlobalLoaderModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        TemplatesModule,
        //DashboardModule,
        //SandboxModule,
        HttpClientModule,
        DashboardModule,
        EventModule,
        ContactModule,
        PayrollModule,
        OnboardingModule,
        AdminModule,
        ReportsModule,
        //NgxLiquidCacheModule.forRoot({
        //    duration: 600,
        //    localStoragePrefix: "nbc-rem-",
        //    shareBetweenTabs: true,
        //    storageType: LiquidCacheStorageTypes.localStorage,
        //}),

        TierRateCardModule,
        RouterModule.forRoot(
            [
                { path: "unauthorized", component: NbcUnauthorized },
                {
                    path: "Payroll",
                    loadChildren: () =>
                        import("./Payroll/payroll.module").then(
                            (m) => m.PayrollModule
                        ),
                },
                {
                    path: "Events",
                    data: { preload: true },
                    loadChildren: () =>
                        import("./Events/event.module").then(
                            (m) => m.EventModule
                        ),
                },
                {
                    path: "Contacts",
                    data: { preload: true },
                    loadChildren: () =>
                        import("./Contact/contact.module").then(
                            (m) => m.ContactModule
                        ),
                },
                {
                    path: "Onboarding",
                    loadChildren: () =>
                        import("./Onboarding/onboarding.module").then(
                            (m) => m.OnboardingModule
                        ),
                },
                {
                    path: "admin",
                    loadChildren: () =>
                        import("./admin/admin.module").then(
                            (m) => m.AdminModule
                        ),
                },
                {
                    path: "Reports",
                    loadChildren: () =>
                        import("./reports/reports.module").then(
                            (m) => m.ReportsModule
                        ),
                },
                {
                    path: "Help",
                    loadChildren: () => {
                        return HelpModule;
                    },
                },
                { path: "**", component: NbcUnauthorized },
            ],
            {
                preloadingStrategy: CustomPreloadingStrategyService,
                onSameUrlNavigation: "reload",
            }
        ),
        BrowserAnimationsModule,
        NgPipesModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule,
        NgChartsModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        CookieService,
        TimeZoneHelperService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: RemotesCookieFactory,
            multi: true,
            deps: [CookieService, TimeZoneHelperService, HttpClient],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: identityFactory,
            multi: true,
            deps: [HttpClient],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: UiSettingsFactory,
            multi: true,
            deps: [HttpClient],
        },
        SharedDataService,
        AuthGuardService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
