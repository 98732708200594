import { Component, OnInit, OnDestroy } from "@angular/core";
import * as moment from "moment";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { GlobalService } from "src/app/Shared/Services/global.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "modal-submitted-tc-export",
    templateUrl: "./modal-submitted-timesheets-exports.component.html",
})
export class ModalSubmittedTimesheetExport implements OnInit, OnDestroy {
    constructor(
        private _globalService: GlobalService,
        public bsModalRef: BsModalRef,
        private log: NbcLoggerService
    ) {}

    /**
     * formActionbase
     * @private
     */
    formActionBase = "payroll/payroll/getpayrollreport/";

    formAction = "";

    componentDestroyed$: Subject<boolean> = new Subject();
    onSync: Subject<any> = new Subject();
    /**
     * Public var section
     * @public
     */

    /**
     * startDate set to today
     * @public
     */
    startDate = new Date();

    endDate = "";

    /**
     * Array of payrollProviders to send
     * @public
     */
    providers: any[] = [];

    /**
     * Array of payrollProviders to get from Server
     * @public
     */
    payrollProvidersList: any[] = [];

    /**
     * Set endDate based on startDate
     * @public
     */
    setEndDate() {
        this.endDate = moment(moment(this.startDate).add(6, "d")).format(
            "MMM DD YYYY"
        );
    }

    /**
     * Set formAction
     * @public
     */
    setFormAction() {
        this.formAction =
            this.formActionBase + moment(this.startDate).format("YYYY-MM-DD");
    }

    dateChange(value: Date) {
        this.startDate = value;
    }

    /**
     * getPayrollProviderList
     * @public
     */

    getPayrollProviderList() {
        this.log.trace("getPayrollProviders()");
        this._globalService
            .getPayrollProviders()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: (result) => {
                    const completeProviderList = result;
                    completeProviderList.forEach((item: any) => {
                        if (
                            item.description !== "TK" &&
                            item.description !== "TBD"
                        ) {
                            this.payrollProvidersList.push(item);
                        }
                    });
                    this.resetList();
                },
                error: (err) => {
                    this.log.showModalError(
                        "Unable to load payroll providers at this time. Please try again later."
                    );
                    this.log.error("got an error: " + JSON.stringify(err));
                },
            });
    }

    /**
     * Add Provider to list
     * @member {Object} - payrollProvider
     * @public
     */
    addProviderToList(item) {
        let index;

        if (item.isSelected) {
            this.providers.push(item.description);
        } else {
            index = this.providers.indexOf(item.description);
            if (index > -1) {
                this.providers.splice(index, 1);
            }
        }
    }

    /**
     * Resets list to checked
     * @public
     */
    resetList() {
        this.payrollProvidersList.forEach((item) => {
            item.isSelected = true;
            this.addProviderToList(item);
        });
    }

    /**
     * Close Modal
     * @public
     */
    cancel() {
        this.log.trace("modal closed");
        this.bsModalRef.hide();
    }

    export() {
        const obj = {
            formAction: this.formAction,
            providers: this.providers,
            startDate: moment(this.startDate).format("YYYY-MM-DD"),
        };
        this.onSync.next(obj);
        this.cancel();
        //sourceData.exportTimesheet(self.formAction, self.providers, moment(self.startDate).format("YYYY-MM-DD"));
    }

    ngOnInit() {
        this.onSync = new Subject<any>();
        this.getPayrollProviderList();
        this.setFormAction();
        this.setEndDate();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
