
<div>
  <div class="modal-header">
    <h4 class="modal-title">Edit Event Information</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <nbc-modal-alert></nbc-modal-alert>
    <form name="eventUpdate"
          #eventUpdate="ngForm"
          novalidate>

      <div class="row" *ngIf="model">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="form-box">
                <div class="row">
                  <div class="col-md-6">
                    <h4>Event Info</h4>
                  </div>
                </div>
                <div class="row" *ngIf="model">
                  <div class="col-md-8 col-sm-8"
                       authenticate-user="event.update">
                    <div class="form-inline text-left form-group">
                      <div class="form-group">
                        <label style="padding:0px" class="checkbox-inline">
                          <input type="radio"
                                 name="isLive"
                                 class="inline"
                                 [(ngModel)]="model.isTaped"
                                 [value]="false" required /> Live Broadcast
                        </label>
                      </div>
                      <div class="form-group">
                        <label class="checkbox-inline">
                          <input type="radio"
                                 name="isTaped"
                                 class="inline"
                                 [(ngModel)]="model.isTaped"
                                 [value]="true" required /> Taped
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="model">
                  <div class="col-md-12"
                       authenticate-user="event.update">
                    <div class="form-inline text-left form-group">
                      <div class="form-group">
                        <label style="cursor:pointer;">
                          Mobile Timekeeping: <input class="checkbox" style="cursor:pointer;"
                                                     type="checkbox"
                                                     name="mobileTimekeeping"
                                                     [checked]="model.isMobileTimekeepingChecked"
                                                     [(ngModel)]="model.isMobileTimekeepingChecked">
                        </label>
                        </div>
                      <div *ngIf="model.isMobileTimekeepingChecked" style="padding-left:15px;"
                           class="form-group">
                        <nbc-dropdown *ngIf="model.isMobileTimekeepingChecked"
                                      [source]="TimekeepingReminders"
                                      name="ddlTimekeepingReminders"
                                      [(model)]="model.timekeepingReminder"
                                      display="desc"
                                      value="id"
                                      nbcSize="w-100"
                                      placeholder="Select Timekeeping Reminder">
                        </nbc-dropdown>
                      </div>
                        </div>
                      </div>

                </div>
                <div class="row">
                  <div class="col-md-4"
                       authenticate-user="event.update">
                    <div class="form-inline text-left form-group">
                      <div class="form-group">
                        <label>
                          Status:
                        </label>
                      </div>
                      <div *ngIf="eventStatusList?.length>0"
                           class="form-group"
                           [ngClass]="{'has-error': model?.statusCode?.id === 0 && submitted}">
                        <nbc-select [source]="eventStatusList"
                                    name="ddleventStatusList"
                                    [(model)]="model.statusCode"
                                    display="description"
                                    value="id"
                                    placeHolderDisplay="Select Status"
                                    placeHolderValue="">
                        </nbc-select>

                        <span *ngIf="(!(!!model.statusCode) ||model.statusCode.id === 0) && submitted"
                              class="control-label control-label-error">
                          Please Select a Status
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row"
                     [hidden]="hideTeamFields"
                     authenticate-user="event.update">
                  <div class="col-md-6 col-sm-6 position-relative">
                    <div class="form-group form-group-wrapper"
                         [ngClass]="{'has-error': submitted && model.eventType.hasTeam && ((!!model.homeTeam || !!model.awayTeam) || (model?.homeTeam?.id === model?.awayTeam?.id)) }">

                      <div class="row">

                        <div [ngClass]="{'col-md-2': model?.awayTeam?.imageId}"
                             class="col-md-2"
                             *ngIf="model?.awayTeam?.imageId">
                          <img src="/assets/image/team/{{model?.awayTeam?.imageId || 0}}" width="40" height="40" class="event-tile-game-logo" />
                        </div>
                        <ng-template #customItemTemplate
                                     let-model="item"
                                     let-index="index">

                          <span>
                            <img src="/assets/image/team/{{model.imageId}}"
                                 width="16" />
                            <span>{{model.name}}</span>
                          </span>

                        </ng-template>
                        <div class="col-md-10"
                             [ngClass]="{'col-md-10':model?.awayTeam?.imageId, 'col-md-12': !model?.awayTeam?.imageId }">

                          <input [(ngModel)]="AwaySearchQueryTypeahead"
                                 #awayTeam="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="awayTeam"
                                 placeholder="Away Team"
                                 required
                                 class="form-control"
                                 autocomplete="off"
                                 [isAnimated]="true"
                                 [typeaheadWaitMs]="200"
                                 [typeahead]="AwayTeamObservable$"
                                 [typeaheadItemTemplate]="customItemTemplate"
                                 [typeaheadAsync]="true"
                                 typeaheadOptionField="name"
                                 [typeaheadOptionsLimit]="50"
                                 (typeaheadOnSelect)="onAwayTeamSelect($event)">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <span *ngIf="submitted && model.eventType.hasTeam && !!model.homeTeam && !!model.homeTeam && model.homeTeam.id === model.awayTeam.id"
                                class="control-label control-label-error">
                            Home Team and Away Team cannot be the same.
                          </span>
                          <span *ngIf="submitted && model.eventType.hasTeam && !model.awayTeam"
                                class="control-label control-label-error">
                            Please select away team.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-control-compare form-control-at">&#64;</div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group form-group-wrapper"
                         [ngClass]="{'has-error': submitted && model.eventType.hasTeam && ((!!model.homeTeam || !!model.awayTeam) || (model.homeTeam.id === model.awayTeam.id)) }">
                      <div class="row">

                        <div [ngClass]="{'col-md-2': model?.homeTeam?.imageId}"
                             class="col-md-2"
                             *ngIf="model?.homeTeam?.imageId">
                          <img src="/assets/image/team/{{model?.homeTeam?.imageId ||0}}" width="40" height="40" class="event-tile-game-logo" />
                        </div>
                        <div class="col-md-10"
                             [ngClass]="{'col-md-10':model?.homeTeam?.imageId, 'col-md-12': !model?.homeTeam?.imageId }">

                          <input [(ngModel)]="HomeSearchQueryTypeahead"
                                 #homeTeam="ngModel"
                                 appInputRestriction="restrictChars"
                                 name="homeTeam"
                                 autocomplete="off"
                                 placeholder="Home Team"
                                 required
                                 [typeaheadItemTemplate]="customItemTemplate"
                                 class="form-control"
                                 [isAnimated]="true"
                                 [typeaheadWaitMs]="200"
                                 [typeahead]=" HomeTeamObservable$"
                                 [typeaheadAsync]="true"
                                 typeaheadOptionField="name"
                                 [typeaheadOptionsLimit]="50"
                                 (typeaheadOnSelect)="onHomeTeamSelect($event)">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <span *ngIf="submitted && model.eventType.hasTeam && !model.homeTeam"
                                class="control-label control-label-error">
                            Please select home team.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group"
                         [ngClass]="{'has-error':eventName.invalid && submitted}"
                         authenticate-user="event.update">
                      <input type="text"
                             #eventName="ngModel"
                             appInputRestriction="restrictChars"
                             name="eventName"
                             class="form-control"
                             authenticate-required="true"
                             placeholder="Event Name"
                             [(ngModel)]="model.eventName"
                             authenticate-user="event.update"
                             authenticate-command="disabled"
                             maxlength="100"
                             required />
                      <span *ngIf="eventName.invalid && submitted"
                            class="control-label control-label-error">
                        Please enter an Event Name.
                      </span>
                    </div>
                    <div class="form-group"
                         [ngClass]="{'has-error':budgetCode.invalid && submitted}"
                         authenticate-user="event.wbsecode.retrieve">
                      <div class="input-group">


                        <input type="text"
                               name="budgetCode"
                               #budgetCode="ngModel"
                               class="form-control"
                               authenticate-required="true"
                               readonly="readonly"
                               required
                               [(ngModel)]="model.budgetCode"
                               maxlength="250"
                               authenticate-user="event.wbsecode.create"
                               authenticate-command="disabled">

                        <span class="input-group-btn">
                          <button class="btn btn-white"
                                  type="button"
                                  (click)="showBudgetContent()"
                                  authenticate-user="event.wbsecode.update"
                                  authenticate-command="disabled">
                            <i class=" fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                      <span *ngIf="budgetCode.invalid && submitted"
                            class="control-label control-label-error">
                        Please select a Budget Code.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6"
                       *ngIf="isSeasonSetup">
                    <div class="form-group"
                         authenticate-user="event.update">
                      <label>This event is part of {{selectedSeason.name}}</label>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6"
                       *ngIf="!isSeasonSetup">
                    <div class="form-group">
                      <label>
                        <input name="chkPartOfSeason"
                               type="checkbox"
                               authenticate-user="event.update"
                               authenticate-command="disabled"
                               [(ngModel)]="model.partOfSeason"
                               (change)="setSeasonId();checkOutOfSeasonRangeBroadcastDates();" />
                        This event is part of a season.
                      </label>
                    </div>
                    <div *ngIf="model?.partOfSeason && seasons"
                         class="form-group"
                         [ngClass]="{'has-error' : submitted && (!model.seasonId || model.seasonId == 'Select Season' || model.seasonId == '' || model.seasonId == 0 )}">
                      <nbc-dropdown name="ddlseasonId"
                                    [source]="seasons"
                                    [(model)]="model.seasonId"
                                    display="name"
                                    value="id"
                                    nbcSize="w-100"
                                    placeholder="Select Season"
                                    (nbcOnOptionSelect)="getSelectedSeason();"
                                    [nbcRequired]="model.partOfSeason"
                                    nbcAuthenticateUser="event.update"
                                    nbcAuthenticateCommand="disabled">
                      </nbc-dropdown>
                      <span class="control-label control-label-error"
                            *ngIf="submitted && (!model.seasonId || model.seasonId === 'Select Season' || model.seasonId === '' || model.seasonId === 0 )">
                        Please select a season.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                      <label>
                        Facility
                      </label>
                      <input type="text"
                             #facility="ngModel"
                             appInputRestriction="restrictChars"
                             name="facility"
                             class="form-control"
                             placeholder="Facility"
                             [(ngModel)]="model.facility"
                             maxlength="150" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group"
                         [ngClass]="{'has-error':!isEventLocationValid && submitted}">
                      <label>
                        Event Location
                      </label>
                      <nbc-dropdown [source]="EventLocations"
                                    name="eventLocation"
                                    [(model)]="model.eventLocation"
                                    (nbcOnOptionSelect)="getEventLocationChange();"
                                    display="desc"
                                    [nbcRequired]="true"
                                    value="id"
                                    nbcSize="w-100"
                                    placeholder="Select Event Location">
                      </nbc-dropdown>
                      <span *ngIf="!isEventLocationValid && submitted"
                            class="control-label control-label-error">Please select a Event Location.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-box"
                   authenticate-user="event.update">
                <h4>Venue</h4>
                <div *ngIf="!model.hasActivatedTimesheet">
                  <div class="form-group"
                       [ngClass]="{'has-error':venue?.invalid && submitted}">
                    <input [(ngModel)]="venueSearchQueryTypeahead"
                           #venue="ngModel"
                           required
                           appInputRestriction="restrictChars"
                           name="venue"
                           [disabled]="model.hasActivatedTimesheet"
                           autocomplete="off"
                           placeholder="Select Venue"
                           class="form-control"
                           [isAnimated]="true"
                           [typeaheadWaitMs]="200"
                           [typeahead]=" VenueNameObservable$"
                           [typeaheadAsync]="true"
                           typeaheadOptionField="displayText"
                           [typeaheadOptionsLimit]="50"
                           (typeaheadOnSelect)="onVenueSelect($event)"
                           authenticate-required="true"
                           authenticate-user="event.update"
                           authenticate-command="disabled"
                           authenticate-disabled="model.hasActivatedTimesheet" />
                    <span class="control-label control-label-error" *ngIf="venue?.invalid && submitted">Please enter a venue.</span>

                  </div>
                </div>
                <div *ngIf="model.hasActivatedTimesheet">
                  <input [(ngModel)]="venueSearchQueryTypeahead"
                         name="venue"
                         required
                         class="form-control"
                         appInputRestriction="restrictChars"
                         disabled>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-box"
                   authenticate-user="event.update">
                <h4>Crew Location</h4>
                <div class="form-group"
                     *ngFor="let loc of model.additionalLocations | filterBy:['isDeleted']:'N';let locationindex=index">
                  <!-- <div class="form-group" *ngFor="let loc in model.additionalLocations | filter:isNotDeleted">|  TODO -->
                  <div class="row">
                    <span class="col-md-6">
                      <input type="text"
                             appInputRestriction="restrictChars"
                             name="locations{{locationindex}}"
                             class="form-control"
                             [(ngModel)]="loc.locationName"
                             [disabled]="loc.eventId == 0"
                             maxlength="25"
                             autocomplete="off"
                             authenticate-required="true" />
                    </span>
                <span class="col-md-2" style="line-height:30px;">
                <a (click)="removeLocation(locationindex,loc)" *ngIf="loc.eventId != 0"
                   style="vertical-align:middle;"><i class="fa fa-times-circle"></i></a>
                    </span>
                  </div>
                </div>
                <div>
                  <span *ngIf="blankLocation && submitted"
                        class="control-label control-label-error">
                    Locations must not be blank or any of the following locations Onsite, @Home, Stamford.
                  </span>
                  <a (click)="addLocation()"
                     class="btn-link cursor-pointer"><i class="fa fa-plus-square"></i> Add another location</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-6"
                 *ngIf="model?.eventType">
              <div class="form-box"
                   authenticate-user="event.update">
                <h4>Flex</h4>
                <div class="btn-group">

                  <label class="btn btn-white"
                         ngDefaultControl
                         name="flexY"
                         [(ngModel)]="model.flex"
                         btnRadio="Y"
                         authenticate-user="event.update"
                         authenticate-command="disabledlabel"
                         uncheckable>Flex</label>
                  <label class="btn btn-white"
                         ngDefaultControl
                         authenticate-user="event.update"
                         authenticate-command="disabledlabel"
                         name="flexN"
                         [(ngModel)]="model.flex"
                         btnRadio="N"
                         uncheckable>No Flex</label>

                </div>
              </div>
            </div>
            <div [ngClass]="model?.eventType?.hasFlex ? 'col-sm-6 col-md-6' : 'col-md-12 col-sm-6'">
              <div class="form-box"
                   authenticate-user="event.update">
                <h4>Level</h4>
                <div class="btn-group">


                  <button type="button"
                          name="templateLevel"
                          class="btn btn-white"
                          style="margin:0 !important;"
                          [(ngModel)]="model.level"
                          authenticate-user="event.update"
                          authenticate-command="disabled"
                          [btnRadio]="1"
                          tabindex="0"
                          role="button"
                          uncheckable>
                    1
                  </button>
                  <button type="button"
                          name="templateLevel"
                          class="btn btn-white"
                          style="margin:0 !important;"
                          [(ngModel)]="model.level"
                          authenticate-user="event.update"
                          authenticate-command="disabled"
                          [btnRadio]="2"
                          tabindex="0"
                          role="button"
                          uncheckable>
                    2
                  </button>
                  <button type="button"
                          name="templateLevel"
                          class="btn btn-white"
                          style="margin:0 !important;"
                          [(ngModel)]="model.level"
                          authenticate-user="event.update"
                          authenticate-command="disabled"
                          [btnRadio]="3"
                          tabindex="0"
                          role="button"
                          uncheckable>
                    3
                  </button>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-box"
                   id="event-broadcast-date"
                   authenticate-user="event.update">
                <div class="row">
                  <div class="col-md-12 d-flex">
                    <div class="col-md-9 ps-3">
                      <b>Event Date / Time / Network</b>
                    </div>
                    <div class="col-md-3 pe-3">
                      <b class="float-end"
                         [hidden]="isInitialLoad">
                        <span *ngIf="currentTierStatus">Tier {{currentTierStatus}}</span>
                        <span *ngIf="!currentTierStatus">Non-Tiered</span>
                      </b>
                    </div>
                  </div>
                </div>
                <form #broadcastDateForm="ngForm"
                      name="broadcastDateForm" *ngIf="loadDates">
                  <div class="form-group form-group-wrapper form-inline"
                       [ngClass]="{'has-error': (model.broadcastDates[index].airDate === '' || ((model.broadcastDates[index].networkId ==='' || model.broadcastDates[index].networkId === 0) && model.broadcastDates[index].isAirDate === true ) || model.broadcastDates[index].outOfSeasonRange || broadcastTimesInvalid || model.broadcastDates[index].startTime > model.broadcastDates[index].endTime) && submitted}"
                       *ngFor="let broadcastDate of model.broadcastDates;let index = index; let first = first;"
                       [hidden]="broadcastDate.isAirDate === false">

                    <div class="form-group">
                      <div class="input-group input-group-set d-flex">
                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        <input type="text"
                               style="width:120px;"
                               placeholder="Date"
                               class="form-control"
                               name="airDate{{index}}"
                               readonly
                               bsDatepicker
                               [bsValue]="model.broadcastDates[index].airDate"
                               #drp="bsDatepicker"
                               (bsValueChange)="SetModelValueAirDate(index,$event);broadcastDateFactory(index,model.broadcastDates[index], model.crewWorkStartDate, model.crewWorkEndDate);getMinAirDate();checkOutOfSeasonRange(index);checkBroadcastTimes();findPrimaryBroadcastingDate();"
                               (click)="onDateChangeStarted();"
                               required
                               [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM D, YYYY'}"
                               (onHidden)="onCalenderhidden('onHidden')">


                      </div>
                    </div>

                    <div class="form-group">
                      <span class="form-control-txt">at</span>
                    </div>

                    <div class="form-group">
                      <div class="input-group input-group-time" date-time-append>
                        <nbc-date-time id="starttime{{index}}"
                                       name="starttime{{index}}"
                                       type="hours"
                                       format="24hr"
                                       placeholder="Start Time"
                                       [defaultDate]="defaultDate"
                                       (modelChange)="checkOutOfSeasonRange(index); checkBroadcastRange(model.broadcastDates[index]);"
                                       [(model)]="_clonedBroadcastDates[index].startTime"
                                       [nbcRequired]="true"
                                       [nbcDatesNavigationEnabled]="false">

                        </nbc-date-time>


                      </div>
                    </div>

                    <div class="form-group">
                      <span>-</span>
                    </div>

                    <div class="form-group">
                      <div class="input-group input-group-time" date-time-append>

                        <nbc-date-time id="endtime{{index}}"
                                       name="endtime{{index}}"
                                       type="hours"
                                       format="24hr"
                                       placeholder="End Time"
                                       (modelChange)="checkOutOfSeasonRange(index);checkBroadcastRange(model.broadcastDates[index]);"
                                       defaultDate="{{defaultDate}}"
                                       [(model)]="_clonedBroadcastDates[index].endTime"
                                       [nbcRequired]="true"
                                       [nbcDatesNavigationEnabled]="false">
                        </nbc-date-time>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-control-txt"><span>Local Time {{ eventTimeZone ? '(' + eventTimeZone + ')' : '' }} on</span></label>
                      </div>
                      <div class="form-group" *ngIf="getNetworks(index+1)?.length>0">
                        <nbc-dropdown [source]="getNetworks(index+1)"
                                      (nbcOnOptionSelect)="getNetworkGroupSetup($event,model.broadcastDates[index].networkId,index+1,oldValue);findPrimaryBroadcastingDate();"
                                      name="networkId{{index}}"
                                      [(model)]="model.broadcastDates[index].networkId"
                                      display="description"
                                      value="id"
                                      nbcSize="w-100"
                                      name=network{{index}}
                                      placeholder="Network"
                                      [nbcRequired]="true">
                        </nbc-dropdown>

                      </div>

                    </div>
                    <div class="form-group float-end"
                         *ngIf="index!==(PRIMARY_DATE_INDEX) && !broadCastdateBeingChanged"
                         authenticate-user="event.update">
                      <div class="btn btn-icon-clear" (click)="removeBroadcastDate(index);findPrimaryBroadcastingDate();">
                        <i class="fa fa-times-circle"></i>
                      </div>
                    </div>
                    <span *ngIf="(broadcastDateForm?.airDate?.invalid || model.broadcastDates[index].networkId === '' || model.broadcastDates[index].networkId === 0) && submitted" class="control-label control-label-error">Please select an Date and network.</span>
                    <span class="control-label control-label-error" *ngIf="model.broadcastDates[index].outOfSeasonRange === true">The selected date is outside the season date range. </span>
                    <span class="control-label control-label-error" *ngIf="model.broadcastDates[index].startTime > model.broadcastDates[index].endTime && submitted "> The selected broadcast start time cannot be later than the selected end time.</span>
                  </div>


                </form>



                <div>
                  <a (click)="addBroadcastDate()"
                     class="cursor-pointer btn-link"
                     authenticate-user="event.update">
                    <i class="fa fa-plus-square"></i> Add another date
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-box"
                   authenticate-user="event.update">
                <h4>Crew Work Schedule</h4>
                <!-- TODO -->

                <div class="form-group" [ngClass]="{'has-error':(eventUpdate?.controls?.crewWorkSchedule?.errors?.required || eventUpdate?.controls?.crewWorkSchedule?.errors?.submittedTimesheetRange) && submitted || (difference > 39)}">
                  <div class="input-group input-group-set dropdown" dropdown (click)="setMinMaxBroadcastDate()">
                    <span class="input-group-text" dropdown-toggle><i class="fa fa-calendar"></i></span>
                    <input type="text"
                           [disabled]="disableCrewWorkScheduleDatepicker"
                           name="crewWorkSchedule"
                           placeholder="Select Date Range"
                           class="form-control"
                           readonly
                           required
                           bsDaterangepicker
                           [ngModel]="bsInlineRangeValue"
                           (bsValueChange)="openDatepicker($event);"
                           [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">

                  </div>
                  <span *ngIf="difference > 39"
                        class="control-label control-label-error">
                    Crew Work Schedule cannot exceed 40 days.
                  </span>
                  <span *ngIf="eventUpdate?.controls?.crewWorkSchedule?.errors?.required && submitted"
                        class="control-label control-label-error">
                    Please select a Crew Schedule date range.
                  </span>
                  <span *ngIf="eventUpdate?.controls?.crewWorkSchedule?.errors?.submittedTimesheetRange && submitted"
                        class="control-label control-label-error">
                    The start date must be on or before {{eventTimesheetDateBoundaries.earliestActivatedDate | date : 'MMM d, yyyy'}} and the end date must be on or after {{eventTimesheetDateBoundaries.latestActivatedDate | date : 'MMM d, yyyy'}}.
                  </span>
                </div>


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-box"
                   id="event-managers"
                   authenticate-user="event.update">
                <h4>Event Managers</h4>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Homebase PM</label>
                      <div class="input-group" *ngIf="eventManagers?.homeBase?.values.length>0">
                        <nbc-select [source]="eventManagers.homeBase.values"
                                    [(model)]="model.homeBaseManager"
                                    name="homebasemanagerid"
                                    display="description"
                                    value="id"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setHomeBaseManagerId()"
                                    placeHolderDisplay="Select HB PM"
                                    placeHolderValue="-1"></nbc-select>
                      </div>


                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Manpower</label>
                      <div class="input-group" *ngIf="eventManagers?.manPower?.values.length>0">
                        <nbc-select [source]="eventManagers.manPower.values"
                                    [(model)]="model.manPowerManager"
                                    display="description"
                                    value="id"
                                    name="setManPowerManagerId"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setManPowerManagerId()"
                                    placeHolderDisplay="Select Man Power"
                                    placeHolderValue="-1"></nbc-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Technical Manager</label>
                      <div class="input-group" *ngIf="eventManagers?.technical?.values.length>0">
                        <nbc-select [source]="eventManagers.technical.values"
                                    [(model)]="model.technicalManager"
                                    display="description"
                                    value="id"
                                    name="setTechnicalManagerId"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setTechnicalManagerId()"
                                    placeHolderDisplay="Select TM"
                                    placeHolderValue="-1"></nbc-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Onsite Production Manager</label>
                      <div class="input-group" *ngIf="eventManagers?.onSite?.values.length>0">
                        <nbc-select [source]="eventManagers.onSite.values"
                                    [(model)]="model.onsiteProductionManager"
                                    display="description"
                                    value="id"
                                    name="setOnsiteProductionManagerId"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setOnsiteProductionManagerId()"
                                    placeHolderDisplay="Select OPM"
                                    placeHolderValue="-1"></nbc-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Tech Manager</label>
                      <div class="input-group" *ngIf="eventManagers?.technical?.values.length>0">
                        <nbc-select [source]="eventManagers.technical.values"
                                    [(model)]="model.technicalManager2"
                                    display="description"
                                    value="id"
                                    name="setTechnicalManagerId2"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setTechnicalManagerId2()"
                                    placeHolderDisplay="Select TM"
                                    placeHolderValue="-1"></nbc-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                      <label>Onsite Prod Manager</label>
                      <div class="input-group" *ngIf="eventManagers?.onSite?.values.length>0">
                        <nbc-select [source]="eventManagers.onSite.values"
                                    [(model)]="model.onsiteProductionManager2"
                                    display="description"
                                    value="id"
                                    name="setOnsiteProductionManagerId2"
                                    authenticate-user="event.update"
                                    authenticate-command="nbcselectdisabled"
                                    (change)="setOnsiteProductionManagerId2()"
                                    placeHolderDisplay="Select OPM"
                                    placeHolderValue="-1"></nbc-select>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row"
               authenticate-user="financial.budgetforecast.retrieve">
            <div class="col-md-12">
              <div class="form-box"
                   authenticate-user="financial.budgetforecast.update">
                <h4>Financials <span class="label-note">(Optional)</span></h4>
                <div class="row">
                  <div class="col-sm-6 col-xs-12">
                    <div class="row">
                      <div class="col-md-12">
                        <label>Average Airfare</label>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">Talent $</div>
                                <input type="text"
                                       name="talentAirFare"
                                       class="form-control"
                                       placeholder="Talent Air Fare"
                                       [(ngModel)]="model.financials.talentAirFare"
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       nbc-decimal
                                        decimals="3"
                                       maxlength="12"
                                       />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">Production $</div>
                                <input type="text"
                                       name="productionAirFare"
                                       class="form-control"
                                       placeholder="Production Air Fare"
                                       [(ngModel)]="model.financials.productionAirFare"
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       nbc-decimal
                                       decimals="3"
                                       maxlength="12"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">Engineers $</div>
                                <input type="text"
                                       name="engineersAirFare"
                                       class="form-control"
                                       placeholder="Engineers Air Fare"
                                       [(ngModel)]="model.financials.engineersAirFare"                                      
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       nbc-decimal
                                       decimals="3"
                                       maxlength="12" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Average Hotel Nightly Cost</label>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">Production $</div>
                                <input type="text"
                                       name="productionHotelFare"
                                       class="form-control"
                                       placeholder="Production Hotel Fare"
                                       [(ngModel)]="model.financials.productionHotelFare"
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       nbc-decimal
                                       decimals="3"
                                       maxlength="12" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="input-group">
                                <div class="input-group-text">Engineers $</div>
                                <input type="text"
                                       name="engineersHotelFare"
                                       class="form-control"
                                       placeholder="Engineers Hotel Fare"
                                       [(ngModel)]="model.financials.engineersHotelFare"
                                       authenticate-user="financial.budgetforecast.update"
                                       authenticate-command="disabled"
                                       nbc-decimal
                                       decimals="3"
                                       maxlength="12" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Hours Budgeted / Day</label>
                    <div class="row" *ngFor="let budgetHours of model.financials.budgetedHours | orderBy:'broadCastDate' ;let index = index;">
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-text">{{budgetHours.broadCastDate  | date:"EEE MM/dd/yyyy"}}</div>
                            <input type="text"
                                   appInputRestriction="numberOnly"
                                   name="budgetedhours-{{index}}"
                                   class="form-control"
                                   placeholder="Budgeted Hours"
                                   [(ngModel)]="budgetHours.hours"
                                   maxlength="2"
                                   authenticate-user="financial.budgetforecast.update"
                                   authenticate-command="disabled"
                                   numbers-only />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="row" *ngIf="!model.totalPositions > 0">  TODO -->
            <div class="col-md-12">
              <div *ngIf="eventPositionTemplates?.length>0"
                   class="form-box"
                   id="event-position-template"
                   authenticate-user="event.update">
                <h4>Event Position Template <span class="label-note">(Optional)</span></h4>

                <nbc-select [source]="eventPositionTemplates"
                            [(model)]="model.eventPositionTemplate"
                            display="description"
                            value="id"
                            name="ddlTemplates"
                            placeHolderValue="-1"
                            authenticate-user="event.update"
                            authenticate-command="nbcselectdisabled"
                            placeHolderDisplay="Select Event Position Template"></nbc-select>
              </div>
            </div>
          </div>
          <!--SCOR-4106-->
          <div *ngIf="viewNotes">
            <div class="row">
              <div class="col-md-12">
                <div class="form-box"
                     id="notes"
                     authenticate-user="event.update">
                  <h4>Notes <span class="label-note">(Optional)</span></h4>
                  <textarea class="form-control"
                            name="notes"
                            [(ngModel)]="model.note"
                            textarea-maxlength="250"
                            authenticate-user="event.update"
                            authenticate-command="disabled"
                            appInputRestriction="restrictChars"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="text-end">
            <button type="button"
                    class="btn btn-default"
                    (click)="closeModal()">
              Cancel
            </button>
            <button type="button"
                    class="btn btn-primary"
                    id="btnUpdateEvent"
                    (click)="updateEvent(eventUpdate)">
              Save
            </button>
            <!-- [disabled]="disableCrewWorkScheduleDatepicker" -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
