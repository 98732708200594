import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { noop, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';

@Injectable({
  providedIn: 'root'
})
export class JobTitleService {

  constructor(private log: NGXLogger, private http: HttpClient, private _apiroot: BaseUrlHelperService,) { }

  getAllJobTitleDetails(jobCodeFilterQuery): Observable<any> {
    this.log.debug("calling search for object" + JSON.stringify(jobCodeFilterQuery));
    return this.http.post(this._apiroot.baseUrl + "jobTitle/getAllJobTitleDetails/", jobCodeFilterQuery).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };


  getJobTitle(searchterm): Observable<any> {
    this.log.trace("searching for :" + searchterm);
    // using github public api to get users by name
    return this.http.get<any>(
      this._apiroot.baseUrl + "jobTitle/getJobTitle/" + searchterm)
      .pipe(
        map((data: any) => data && data.reference || []),
        catchError((err) => {
          return []
        })
      );
  }



  //   getJobTitle(term: string): Observable<any> {
  //     this.log.trace("searching a job title " + JSON.stringify(term));
  //     return this.http.get(this._apiroot.baseUrl + "jobTitle/getJobTitle/?term=" + term).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );;
  // };



  getgroupTypes(): Observable<any> {
    this.log.trace("searching a group type ");
    return this.http.get(this._apiroot.baseUrl + "jobTitle/getgroupTypes").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };

  GetNewGroupCodeForJobTitle(): Observable<any> {
    this.log.trace("retrieving all olympic job title details");
    return this.http.get(this._apiroot.baseUrl + "jobTitle/newJobTitleGroupCode").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };


  getJobTitleDetails(groupId): Observable<any> {
    this.log.trace("retrieving a job title details" + JSON.stringify(groupId));
    return this.http.get(this._apiroot.baseUrl + "jobTitle/" + groupId + "/getJobTitleDetails").pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };



  saveJobTitleDetails(jobInfo): Observable<any> {
    this.log.trace("saving new jobcode " + JSON.stringify(jobInfo));
    return this.http.post(this._apiroot.baseUrl + "jobTitle/saveJobTitleDetails", jobInfo).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );;
  };


}
