<input [(ngModel)]="searchTerm"
       name="{{nbcName}}"
       [typeahead]="searchTeam$"
       [typeaheadAsync]="true"
       typeaheadOptionField="name"
       [typeaheadOptionsLimit]="10"
       placeholder="{{placeholder}}"
       class="form-control"
       [isAnimated]="true"
       [typeaheadWaitMs]="200"
       [typeaheadItemTemplate]="customItemTemplate"
       (typeaheadOnSelect)="onItemSelected($event)"
       (ngModelChange)="onSearchTermUpdate()"
       (blur)="onBlur()">


<ng-template #customItemTemplate let-model="item"
             let-index="index">
  <span>
    <img src="/assets/image/team/{{model.imageId}}"
         width="16" /><span style="padding-left:10px;">{{model.name}}</span>
  </span>
</ng-template>
