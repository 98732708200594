<div>
    <div class="event-tile-budget-progress">
        <div class="progress">
            <div class="progress-bar progress-bar-info" [ngStyle]="{width: usedBudgetPercentage + '%'}"></div>
        </div>
        <label class="event-tile-budget-lbl"><strong>{{totalBudget| currency}}</strong>Budget</label>
        <label class="event-tile-forecast-lbl"><strong>{{usedBudget| currency}}</strong>Forecast</label>
    </div>
    <div class="event-tile-budget-change">
        <i class="fa fa-caret-down"></i> {{differencePercentage}}%
    </div>
</div>