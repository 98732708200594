import { ChangeDetectorRef } from "@angular/core";
import {
    Component,
    OnInit,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    OnChanges,
    ViewEncapsulation,
    ChangeDetectionStrategy,
} from "@angular/core";
import * as _ from "lodash";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { NbcBaseComponent } from "../../../../Core/component/Base.Component";

@Component({
    selector: "[work-activity]",
    templateUrl: "./webui-activity-popover.component.html",
})
export class webuiActivityPopoverComponent
    extends NbcBaseComponent
    implements OnInit
{
    constructor(
        private log: NbcLoggerService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }
    @Input() id!: string;
    @Input() parentId!: number;
    @Input() day!: string;
    @Input() name!: string;
    @Input() workActivities!: any[];
    @Input() plannedActivites!: any[];
    @Input() toggle!: boolean;
    @Input() maxAllowedActivity!: number;
    @Input() showSaveCancelBtn!: boolean;
    @Input() nbcAuthenticateUser!: any;

    @Output() toggleChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() workActivitiesChange = new EventEmitter<any>();

    isAuthorized = true;
    left = 0;
    top = 0;
    loaded = false;
    selectedActivities = 0;
    clonedActivities: any[] = [];

    ///method can monitor any click on the document and help to close the popover
    onDocumentClick(event) {
        if (this.loaded) {
            this.closePopover();
        }
    }

    closePopover() {
        // document.getElementById("webuiPopover_" + this.id)?.remove();
        this.cloneActivity();
        document.getElementsByTagName("body")[0].click();
        // this.toggle = false;
        //this.toggleChange.emit(false);
    }

    /**
     * method provides element position on the screen
     *  * this helps to retain absolute positioning of the popover
     * @param event
     */
    getPosition() {
        const offsetLeft = 0;
        const offsetTop = 0;
        const parent = document.getElementById(this.id.toString());
        const cord = this.getCoords(parent);
        this.top = cord.top;
        this.left = cord.left;
    }

    getCoords(elem) {
        const box = elem.getBoundingClientRect();
        return {
            top: box.top - 220,
            left: box.left - 200,
        };
    }

    cancel(event) {
        //event.stopPropagation();

        this.closePopover();
        // return false;
    }

    saveUpsertPositionActivity() {
        if (!this.showSaveCancelBtn)
            setTimeout(() => this.saveActivities(false), 100);
    }

    saveActivities(close = true) {
        _.each(this.clonedActivities, (item) => {
            item.isChecked = item.isPlanned;
        });

        const output = {
            workActivities: this.clonedActivities,
            id: this.id,
            parentId: this.parentId,
            day: this.day,
        };
        this.workActivitiesChange.emit(output);
        if (close) this.closePopover();
    }

    stopEvent(event) {
        event.stopPropagation();
        //return false;
    }

    onActivityClick(workActivity) {
        if (this.isAuthorized) {
            //workActivity.isPlanned = !workActivity.isPlanned;
            setTimeout(() => {
                const activities = this.getSelectedActivities();
                this.selectedActivities = _.isEmpty(activities)
                    ? 0
                    : activities.length;
                this.cd.detectChanges();
            }, 100);
        }
    }

    cloneActivity() {
        this.clonedActivities = _.cloneDeep(this.workActivities);
        const activities = this.getSelectedActivities();
        this.selectedActivities = _.isEmpty(activities) ? 0 : activities.length;
    }

    getSelectedActivities() {
        return _.filter(this.clonedActivities, (o) => {
            return o.isPlanned == true;
        });
    }

    ngOnInit() {
        this.cloneActivity();
        this.maxAllowedActivity = _.parseInt(
            this.maxAllowedActivity.toString()
        );
        this.isAuthorized = this.authorize(this.nbcAuthenticateUser);
        setTimeout(() => {
            this.loaded = true;
        }, 200);
    }
}
