<div class="main-pane-content" style="height:400px;">
  <div class="container-fluid">
    <span us-spinner spinner-key="searchSpinner"></span>
    <div class="row">
      <div class="col-md-12 msg">
        <div>
        </div>
      </div>
    </div>
    <div class="row" authenticate-user="">
      <div class="col-md-12 mainheader">
        <div class="header-bar clearfix">          
          <div class="row">
            <div class="col-md-3">
              <select name="businessunit"
                      class="form-select"
                      (change)="getDepartment()"
                      [(ngModel)]="selectedbusinessunit"
                      style="height:35px;">
                <option [value]="null">--Select--</option>
                <option *ngFor="let option of businessunits" [value]="option.buId">{{option.buName}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <select name="department"
                      class="form-select"
                      (change)="getRoles()"
                      [(ngModel)]="selecteddepartment"
                      style="height:35px;">
                <option [value]="null">--Select--</option>
                <option *ngFor="let option of departments" [value]="option.deptId">{{option.deptName}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <select name="role"
                      class="form-select"
                      [(ngModel)]="selectedrole"
                      style="height:35px;">
                <option [value]="null">--Select--</option>
                <option *ngFor="let option of roles" [value]="option.roleId">{{option.roleName}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <button type="button" [disabled]="checkRole()" authenticate-user="contact.permissiontalent.create" class="btn btn-primary pull-right ms-auto"
                      (click)="addRoles()">
                + ADD ROLE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form role="form" 
          #permissionTalentForm="ngForm" authenticate-user="contact.permissiontalent.retrieve" novalidate>
      <div class="tab-page-content">
        <div class="row" ng-if="resultsFound">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="dark-header">
                  <tr>
                    <th style="cursor:default;" class="col-md-1 default-header">Business Unit</th>
                    <th style=" cursor:default;" class="col-md-1 default-header">Department</th>
                    <th style=" cursor:default;" class="col-md-1 default-header">Roles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let permission of permissions">
                    <td class="col-md-4" style="-ms-word-break: break-all; word-break: break-all;">{{permission.buName}}</td>
                    <td class="col-md-4" style="-ms-word-break: break-all; word-break: break-all;">{{permission.deptName}}</td>
                    <td class="col-md-4" style="-ms-word-break: break-all; word-break: break-all;">{{permission.roleDesc}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p *ngIf="!resultsFound">No Permission.</p>
      </div>
    </form>
  </div>

</div>
