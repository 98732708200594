import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { SubSink } from "subsink";

@Component({
    selector: "modal-network",
    templateUrl: "./modal-network.html",
})
export class ModalNetworkComponent implements OnInit, OnDestroy {
    constructor(
        private _networkService: NetworkService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}

    public onSync!: Subject<any>;
    showAlertMessage = false;
    network: any | undefined;
    allNetworks: any;
    Networks: any;
    modalTitle: string | undefined;
    isAdd = false;
    private subs = new SubSink();
    submitted = false;

    //Closing Modal
    closeModal() {
        this.bsModalRef.hide();
    }

    validate() {
        let msg;

        if (_.isEmpty(_.trim(this.network.description))) {
            msg = "Network Name is required";
        } else {
            const existingNetwork = _.findIndex(this.allNetworks, (o: any) => {
                if (!o.description || !this.network) return false;

                return (
                    o.description.toLowerCase() ===
                    _.trim(this.network.description.toLowerCase())
                );
            });

            if (existingNetwork > -1 && this.isAdd) {
                msg = "Cannot have duplicate Network Names!";
            }
            let j = 0;
            let duplicateIndicator = 0;
            for (j; j < this.allNetworks.length; j++) {
                if (
                    this.allNetworks[j].description.toLowerCase() ==
                    this.network.description.toLowerCase()
                ) {
                    duplicateIndicator++;
                }
            }
            if (duplicateIndicator > 1) {
                msg = "Cannot have duplicate Network Names!";
            }
            if (duplicateIndicator == 1 && this.isAdd) {
                msg = "Cannot have duplicate Network Names!";
            }
        }
        if (msg) {
            this.log.showModalError(msg);
            this.showAlertMessage = true;
            return false;
        }

        return true;
    }

    upsertNetwork() {
        this.submitted = true;
        if (!this.validate()) {
            return;
        }

        this.subs.sink = this._networkService.upsert(this.network).subscribe(
            (result) => {
                this.log.showSuccess("Network added");
                this.onSync.next("Network added");
                this.bsModalRef.hide();
            },
            (err) => {
                if (err.error.includes("already exists")) {
                    this.log.showModalError(
                        "Cannot have duplicate Network Names!"
                    );
                    this.showAlertMessage = true;
                }
            },
            () => {}
        );
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.allNetworks = this.allNetworks;
        this.network = this.network;
        this.isAdd = _.cloneDeep(this.isAdd);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
