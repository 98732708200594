import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SharedDataService } from "../Shared/sharedData.service";

@Component({
    selector: "rem-dashboard",
    templateUrl: "./dashboard.component.html",
    //styleUrls: [
    //  "../../assets/Content/app/controllers.css"],
    //encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    constructor(private _sharedService: SharedDataService) {}

    ngOnInit() {
        this._sharedService.setCss("dashboard");
    }
}
