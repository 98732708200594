<div class="view-list"
     *ngIf="!showGrid">
  <div class="row"
       *ngFor="let event of model.items">
    <div class="event-tile col-md-12">
      <div class="event-tile-aside">
        <nbc-event-countdown-label nbcLabelPrependText="In "
                                   [nbcStartDate]="event.startDate"
                                   [nbcEndDate]="event.endDate"></nbc-event-countdown-label>
        <img src="/assets/image/team/{{event?.awayTeam?.imageId}}" width="40" height="40" class="event-tile-game-logo" [hidden]="!event?.awayTeam?.imageId" />
        <span [hidden]="!event?.homeTeam?.imageId || !event?.awayTeam?.imageId">@</span>
        <img src="/assets/image/team/{{event?.homeTeam?.imageId}}" width="40" height="40" class="event-tile-game-logo" [hidden]="!event?.homeTeam?.imageId" />
        <img src="/assets/image/eventType/{{event?.eventType?.imageId}}" width="40" height="40" class="event-tile-game-logo" [hidden]="event?.homeTeam?.imageId || event?.awayTeam?.imageId || !event?.eventType?.imageId" />
        <div *ngIf="event.eventLocation" class="event-location-banner">
          <span>
            {{event.eventLocation}}
          </span>
        </div>
      </div>
      <div class="event-tile-main">
        <div class="event-tile-dtls event-tile-status-archived col-lg-3 col-md-6 col-sm-6">
          <a (click)="saveFavorite(event)"
             ngDefaultControl
             class="favoriteEvent hdr-action-icon pull-right"
             name="IsFavouriteEvent{{event.name}}"
             [(ngModel)]="event.isFavorite"
             authenticate-user="favoriteevent.update,favoriteevent.retrieve">
            <i class="fa"
               [ngClass]="{'fa-star': event.isFavorite, 'fa-star-o': !event.isFavorite}"></i>
          </a>
          <span class="event-tile-date"
                [hidden]="event.startDate === '' ">
            {{event.startDate|date:"EEE. MMM. d"}} - {{event.endDate|date:"EEE. MMM. d, yyyy"}}
            <span class="col-md-offset-2">
              <label class="tier-position"
                     *ngIf="event.tier && event.tier > 0">T{{event.tier}}</label>
            </span>
          </span>
          <span class="event-tile-date"
                *ngIf="event.startDate === '' ">
            {{event.endDate|date:"EEE.MMM. d, yyyy"}}
            <span class="col-md-offset-2">
              <label class="tier-position"
                     *ngIf="event.tier && event.tier > 0">T{{event.tier}}
              </label>
            </span>
          </span>

          <span class="event-tile-title">
            <a class="btn-link" routerLink="/Events/Event/{{event.id}}" routerLinkActive="activebutton">
              {{event.name}}
            </a>
          </span>
          <span class="event-tile-location">{{event.venue.name || 'No Venue'}}</span>
          <a routerLink="/Events/Season/{{event.seasonId}}"
             title="{{event.seasonName}}"
             class="event-tile-season pull-right"
             *ngIf="event.seasonId > 0">
            <span class="label label-default">S</span>
          </a>
        </div>
        <div class="event-tile-sub-dtls col-lg-3 col-md-6 col-sm-6">
          <span class="event-tile-network">
            <i class="event-tile-network-icon"></i>
            <span *ngFor="let network of event.networks | unique ">
              {{network}}<span [hidden]="$last">, </span>
            </span>

          </span>

        </div>
        <div class="event-tile-positions col-lg-3 col-md-6 col-sm-6">

          <nbc-filled-positions-bar totalPositions="{{event.totalPositions}}"
                                    pendingPositions="{{event.pendingPositions}}"
                                    confirmedPositions="{{event.confirmedPositions}}">

          </nbc-filled-positions-bar>


          <label>{{event.totalPositions}} Positions</label>

          <img class="event-tile-submissions"
               *ngIf="event.eventTcSubmitStatus == 'Fully Submitted' && event.totalPositions > 0"
               src="/Content/app/images/green_check.png"
               width="28"
               height="28"
               title="{{event.eventTcSubmitStatus}}" />
          <img class="event-tile-submissions"
               *ngIf="event.eventTcSubmitStatus == 'Not Submitted'"
               src="/Content/app/images/red_check.png"
               width="28"
               height="28"
               title="{{event.eventTcSubmitStatus}}" />
          <img class="event-tile-submissions"
               *ngIf="event.eventTcSubmitStatus == 'Partially Submitted'"
               src="/Content/app/images/orange_check.png"
               width="28"
               height="28"
               title="{{event.eventTcSubmitStatus}}" />
        </div>
        <div class="event-tile-budget col-lg-3 col-md-6 col-sm-6"
             authenticate-user="financial.budgetforecast.retrieve">         
        </div>

        <!--hidden because of tier rate card new data is not available on this document-->
        <a [hidden]="true"
           authenticate-user="event.create"
           class="event-tile-copy"
           (click)="openCloneEventModal(event)">
          <i [hidden]="true"
             class="fa fa-copy"></i>
        </a>
      </div>
    </div>
  </div>
</div>

