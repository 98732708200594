<div class="page-container">
  <div class="container-fluid">
    <div>
      <div>
        <div class="page-container">
          <div class="container-fluid">

            <div class="page-content">
              <accordion [isAnimated]="true"
                         close-others="toggleAtATime">
                <accordion-group *ngFor=" let report of reports" (click)="report.isAccordionOpen=!report.isAccordionOpen">
                  <span accordion-heading>
                    <i class="fa fa-caret-right"
                       [ngClass]="report.isAccordionOpen ?'fa-caret-down':''"></i> {{report.title}}
                    <span class="report-count">({{report.count}})</span>
                  </span>
                  <div class="row report-panel">
                    <div class="top-no-border"></div>
                    <div class="row ms-0 me-0 ps-0 pe-0" *ngIf="report?.needPermission == true">
                      <div class="col-md-6"
                           *ngFor="let item of report.items"
                           authenticate-user="{{item.key}}"
                           authenticate-command="hide">
                        <div class="report-item">
                          {{item.name}}
                          <span class="report-btn"
                                style="margin-left: 10px">
                            <!-- @*<a ng-class="item.isExport? 'btn btn-secondary' : 'btn btn-secondary'" ng-hide="item.isExport=='false'" ng-click=" Export(item.name);">Export</a>*@ -->
                            <a class="btn btn-primary"
                               [ngClass]="{hasHidden: item.isNotCustomize=='true'}"
                               (click)="customizeReportModal(item.name)">
                              Export ...
                            </a>

                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="report?.needPermission == false">
                      <div class="col-md-6"
                           *ngFor="let item of report.items">
                        <div class="report-item">
                          {{item.name}}
                          <span class="report-btn"
                                style="margin-left: 10px">
                            <!-- @*<a [ngClass]="item.isExport? 'btn btn-secondary' : 'btn btn-secondary'" ng-hide="item.isExport=='false'" ng-click=" Export(item.name);">Export</a>*@ -->
                            <a class="btn btn-primary"
                               [ngClass]="{hasHidden: item.isNotCustomize=='true'}"
                               (click)="customizeReportModal(item.name)">
                              Export ...
                            </a>

                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </accordion-group>
              </accordion>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-nbc-file-download *ngIf="isDownload"></app-nbc-file-download>
