<div>
  <div class="modal-header">
    <h3 class="modal-title">{{modalTitle}}</h3>
    <button type="button"
            class="btn-close"
            (click)="closeModal()">
    </button>
  </div>

  <nbc-modal-alert></nbc-modal-alert>
  <form role="form"
        name="upsertNetworkForm"
        #form="ngForm"
        novalidate>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="container">
            <div class="col-md-8">
              <div class="form-group">
                <label for="description"
                       class="control-label">Network</label>

                <div [ngClass]="{'has-error': description.invalid && submitted}">
                  <input #description=ngModel
                         type="text"
                         class="form-control"
                         placeholder=""
                         id="description"
                         name="description"
                         [(ngModel)]="network.description"
                         maxlength="40"
                         required>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-default"
              (click)="closeModal()">
        Cancel
      </button>
      <button type="button"
              *ngIf="isAdd"
              class="btn btn-primary"
              id="btnSaveJobCode"
              (click)="disableSave || upsertNetwork()">
        Save
      </button>
      <button type="button"
              *ngIf="!isAdd"
              class="btn btn-primary"
              id="btnSaveJobCode"
              (click)="disableSave || upsertNetwork()">
        Update
      </button>
    </div>
  </form>
</div>
