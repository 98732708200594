import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { PositionService } from "../Shared/Services/position.service";
import { mergeMap } from "rxjs/operators";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { SharedDataService } from "../Shared/sharedData.service";

@Component({
    selector: "",
    templateUrl: "./contact.component.html",
    //styleUrls:  [
    //  "../../assets/Content/app/areas/contacts/contacts.css"],
    //encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
    constructor(
        private _positionService: PositionService,
        private _dataService: SharedDataService,
        private log: NbcLoggerService
    ) {}

    search: string | undefined;
    asyncSelected: string | undefined;
    asyncSelected2: any | undefined;
    positionObserver: Observable<any[]> | undefined;
    typeaheadLoading: boolean | undefined;

    positionTypeahead() {
        this.positionObserver = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.asyncSelected);
            }
        ).pipe(
            mergeMap((token: string) => this.getPositionsAsObservable(token))
        );
    }

    getPositionsAsObservable(searchterm: string): Observable<any[]> {
        return this._positionService.autocomplete(searchterm);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
        this.log.trace(e);
    }

    typeaheadOnSelect($event) {
        // selected object is $event.item
        this.asyncSelected2 = $event.item;
    }
    ngOnInit() {
        this.positionTypeahead();
        this._dataService.setCss("contactsearch");
    }
}
