import { OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NbcFileDownloadComponent } from 'src/app/Shared/components/nbc-file-download/nbc-file-download.component';
import { NbcLoggerService } from 'src/app/Shared/logger/nbc-logger.service';
import { ReportService } from 'src/app/Shared/Services/report.service';
import { SharedDataService } from 'src/app/Shared/sharedData.service';
import { SubSink } from 'subsink';
import { Observable, Subject, Subscriber, Observer } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-crew-rates-report",
    templateUrl: "./crew-rates-report.component.html",
})
export class CrewRatesReportComponent implements OnInit,OnDestroy {

  constructor(private _reportService: ReportService,
    private log: NbcLoggerService,
    private _sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef) { }
   
  reportName;
  showDateRangeRowAll = true;
  showDateRangeEditRow = false;
  showDateRangeTagRow = false;
  showPositionNameTagRow: any
  showEventsRow;
  showEmployeeTypeTagRow: any
  eventId = 13;
  showEventsRowAll = true;
  showEventsEditRow = false;
  showEventsSelectedRow = false;
  isExport = true;
  isDownload = true;
  //showTiersRowAll = true;
  //showTiersEditRow = false;
  //showTiersSelectedRow = false;

  //showNetworksRowAll = true;
  //showNetworksEditRow = false;
  //showNetworksSelectedRow = false;

  //showTierRateCardsRowAll = true;
  //showTierRateCardsEditRow = false;
  //showTierRateCardsSelectedRow = false;
  getCriteriaData: any;
  showEventTypesTagRow: any;
  getEventsTypes:any[] = []
  showSSOsRowAll = true;
  showSSOsEditRow = false;
  showSSOsSelectedRow = false;

    showManpowerRowAll = true;
    showManpowerEditRow = false;
    showManpowerSelectedRow = false;

    showEventTypesRowAll = true;
    showEventTypesEditRow = false;
    showEventTypesSelectedRow = false;

    showhomebasePMRowAll = true;
    showhomebasePMEditRow = false;
    showhomebasePMSelectedRow = false;

    showCategoryRowAll = true;
    showCategoryEditRow = false;
    showCategorySelectedRow = false;

    showPositionNameRowAll = true;
    showPositionNameEditRow = false;
    showPositionNameSelectedRow = false;

    showEmployeeTypeEditRow = false;
    showEmployeeTypeRowAll = true;
    showEmployeeTypeSelectedRow = false;

  //showTieredRowAll = true;
  //showTieredEditRow = false;
  //showTieredTagRow = false;
  showManpowerTagRow: any;
  @ViewChild(NbcFileDownloadComponent) child;
  user = window.preload.user;
  userName = this.user.firstName + " " + this.user.lastName;
  eventsList: any[] = [];
  selectedEvent: any[] = [];
  positionFunctionList: any[] = [];
  selectedPositionFunction: any[] = [];
  selectedManager:any[] = [];
  employeeTypeList: any[] = [];
  selectedEmployeeType: any[] = [];
  //tiersList: any = [];
  //selectedTier: any = [];
  eventTypesList: any[] = [];
  selectedEventType: any[] = [];
  //categoryList: any[] = [];
  selectedCategory: any[] = [];
  //tierRateCardsList: any[] = [];
  //selectedTierRateCards: any[] = [];
  manPowerList = [];
  selectedManPowerList: any[] = [];
  homebasePMList: any[] = [];
  selectedHomebasePM: any[] = [];
  //networkGroupList: any[] = [];
  //selectedNetworkGroup: any[] = [];
  showAlertMessage: any;
  alerts: any;
  //events;
  events: any[] = [];
  //showTiersRow: any;
  showCategoryTagRow: any;
  //showNetworksRow: any;
  ///showTierRateCardsRow: any;
  //showTieredRow: any;
  showSSOsTagRow: any;
  showhomebasePMTagRow;
  showSSOsRow = false;
  showEventTypesRow: any;
  showhomebasePMRow: any;
  showCategoryRow: any;
  showEmployeeTypeRow: any;
  showPositionNameRow: any;
  showManpowerRow: any;
  crewRateReportInfo: any
  showTravelOutDateEditRow: any;
  showEventsTagRow: any;
  //showTiersTagRow: any;
  //showNetworksTagRow: any;
  //showTierRateCardsTagRow: any;
  selectedsso: any;
  ///tieredList: any;
  title:any
  crewRateReport: any = undefined
  categoryList: any[] = [
    { name: "ATL", id: 1 },
    { name: "BTL", id: 2 }
  ];

  addEventTerm: any;
  suggestions$?: Observable<any[]> | undefined;

  private subs = new SubSink();

    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }

  onRemoveEventTag(item) {
    if (this.crewRateReportInfo?.dateRangeStartDate && this.crewRateReportInfo?.dateRangeEndDate || (this.events.length != 0)) {
      this.isExport = false;
    } else {
      this.isExport = true;
    }
  }

 
  setSearchEventTerm($event) {
    this.addEventTerm = $event;
  }

  onEventSelected(event) {
    this.events = event
    this.addEventTerm = "";
    if (this.crewRateReportInfo?.dateRangeStartDate && this.crewRateReportInfo?.dateRangeEndDate || (this.events.length != 0)) {
      this.isExport = false;
    } else {
      this.isExport = true;
    }
  }

  clear() {
    this.events = [];
    this.addEventTerm = ''
    if (this.crewRateReportInfo?.dateRangeStartDate && this.crewRateReportInfo?.dateRangeEndDate || (this.events.length != 0)) {
      this.isExport = false;
    } else {
      this.isExport = true;
    }
  }

  closeAlert() {
    this.alerts = [];
    this.showAlertMessage = false;
  };
  openDateRangeDatepicker(event) {
    // this.log.trace("openDatepicker called");
    event.preventDefault();
    event.stopPropagation();
    //document.getElementById("PayrateButton").disabled = false;
  };

    editRow(value) {
        {
            if (value == "EventsRow") {
                this.showEventsRow = true;
            }

            if (value == "DateRangeRow") {
                this.showDateRangeRowAll = false;
                this.showDateRangeEditRow = true;
                this.showDateRangeTagRow = false;
            }


      if (value == "SSOsRow") {
        this.showSSOsRow = true;
      }


      if (value == "EventTypesRow") {
        //this.showEventTypesRow = true;
        this.showEventTypesRowAll = false;
        this.showEventTypesEditRow = true;
        this.showEventTypesTagRow = false;
      }


      if (value == "ManagersRow") {
        //this.showhomebasePMRow = true;
        this.showhomebasePMRowAll = false;
        this.showhomebasePMEditRow = true;
        this.showhomebasePMTagRow = false;

      }


      if (value == "CrewsRow") {
        //this.showCategoryRow = true;
        this.showCategoryRowAll = false;
        this.showCategoryEditRow = true;
        this.showCategoryTagRow = false;
      }


      if (value == "EmployeeTypeRow") {
        //this.showEmployeeTypeRow = true;
        this.showEmployeeTypeRowAll = false;
        this.showEmployeeTypeEditRow = true;
        this.showEmployeeTypeTagRow = false;

      }

      if (value == "PositionFunctionRow") {
        //this.showPositionNameRow = true;
        this.showPositionNameRowAll = false;
        this.showPositionNameEditRow = true;
        this.showPositionNameTagRow = false;
      }

      if (value == "ManpowerRow") {
        //this.showManpowerRow = true;
        this.showManpowerRowAll = false;
        this.showManpowerEditRow = true;
        this.showManpowerTagRow = false;
      }


      //if (value == "TieredRow") {
      //  this.showTieredRow = true;
      //}

    }
  };


  confirmSelection(value) {

    if (value == "DateRangeRow") {
      this.showDateRangeEditRow = false;
      this.showSection(value);
    }
    if (value === "TravelOutDateRow") {
      this.showTravelOutDateEditRow = false;
      this.showSection(value);
    }

    if (value === "EventsRow") {
      this.showEventsRow = false;
      //this.checkEventsValue();
    }

    if (value === "SSOsRow") {
      this.showSSOsRow = false;
      this.checkSSOValue();
    }

    if (value === "EventTypesRow") {
      this.showEventTypesRow = false;
      //this.checkEventTypeValue();
      this.showSection(value);
    }

    if (value === "ManagersRow") {
      this.showhomebasePMRow = false;
      this.showSection(value);
      //this.checkHomeBasePMValue();
    }

    if (value === "CrewsRow") {
      this.showCategoryRow = false;
      this.showSection(value);
      //this.checkCrewValue();
    }
    if (value === "EmployeeTypeRow") {
      this.showEmployeeTypeRow = false;
      //this.checkEmployeeTypeValue();
      this.showSection(value);
    }
    if (value === "PositionFunctionRow") {
      this.showPositionNameRow = false;
      //this.checkPositionNameValue();
      this.showSection(value);
    }

    if (value === "ManpowerRow") {
      this.showManpowerRow = false;
      //this.checkManPowerValue();
      this.showSection(value);
    }
     
    if (this.crewRateReportInfo.dateRangeStartDate && this.crewRateReportInfo.dateRangeEndDate || (this.events.length != 0)) {
     this.isExport = false;
    }else {
      this.isExport = true;
    }
  };

  showSection(value) {
    switch (value) {
      case "DateRangeRow":
        {
          if (this.crewRateReportInfo.dateRangeStartDate !== null && this.crewRateReportInfo.dateRangeEndDate !== null) {
            this.showDateRangeRowAll = false;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = true;
            // document.getElementById("crewRateBtnReportExport").disabled = false;

          }
          else {
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (this.crewRateReportInfo.dateRangeStartDate === null && this.crewRateReportInfo.dateRangeEndDate === null) {
              // document.getElementById("crewRateBtnReportExport").disabled = true;
            }

          }
          break;
        }
        break;
      case "EmployeeTypeRow":
        {
          if (!_.isUndefined(this.selectedEmployeeType) && this.selectedEmployeeType.length > 0) {
            this.showEmployeeTypeRowAll = false;
            this.showEmployeeTypeEditRow = false;
            this.showEmployeeTypeTagRow = true;

          }
          else {
            this.showEmployeeTypeRowAll = true;
            this.showEmployeeTypeEditRow = false;
            this.showEmployeeTypeTagRow = false;
          }
          break;

          //this.checkEmployeeTypeValue();
        }
        break;

      case "PositionFunctionRow":
        {

          if (!_.isUndefined(this.selectedPositionFunction) && this.selectedPositionFunction.length > 0) {
            this.showPositionNameRowAll = false;
            this.showPositionNameEditRow = false;
            this.showPositionNameTagRow = true;
          }
          else {
            this.showPositionNameRowAll = true;
            this.showPositionNameEditRow = false;
            this.showPositionNameTagRow = false;
          }
          break;
        }
        break;

      case "EventsRow":
        {

          if (!_.isUndefined(this.eventsList) && this.eventsList.length > 0) {
            this.showEventsRowAll = false;
            this.showEventsEditRow = false;
            this.showEventsTagRow = true;
          }
          else {
            this.showEventsRowAll = true;
            this.showEventsEditRow = false;
            this.showEventsTagRow = false;
          }
          break;
        }
        break;

            case "SSOsRow":
                {
                    if (
                        !_.isUndefined(this.selectedsso) &&
                        this.selectedsso.length > 0
                    ) {
                        this.showSSOsRowAll = false;
                        this.showSSOsEditRow = false;
                        this.showSSOsTagRow = true;
                    } else {
                        this.showSSOsRowAll = true;
                        this.showSSOsTagRow = false;
                        this.showSSOsEditRow = false;
                    }
                    break;
                }
                break;

      case "EventTypesRow":
        {

          if (!_.isUndefined(this.selectedEventType) && this.selectedEventType.length > 0) {
            this.showEventTypesRowAll = false;
            this.showEventTypesEditRow = false;
            this.showEventTypesTagRow = true;

          }
          else {
            this.showEventTypesRowAll = true;
            this.showEventTypesTagRow = false;
            this.showEventTypesEditRow = false;

          }
          break;

        }
        break;

            case "ManagersRow":
                {
                    // $scope.checkHomeBasePMValue();


          if (!_.isUndefined(this.selectedHomebasePM) && this.selectedHomebasePM.length > 0) {
            this.showhomebasePMRowAll = false;
            this.showhomebasePMEditRow = false;
            this.showhomebasePMTagRow = true;

          }
          else {
            this.showhomebasePMRowAll = true;
            this.showhomebasePMTagRow = false;
            this.showhomebasePMEditRow = false;

          }
          break;

        }
        break;

      case "CrewsRow":
        {
          if (!_.isUndefined(this.selectedCategory) && this.selectedCategory.length > 0) {
            this.showCategoryRowAll = false;
            this.showCategoryEditRow = false;
            this.showCategoryTagRow = true;

          }
          else {
            this.showCategoryRowAll = true;
            this.showCategoryTagRow = false;
            this.showCategoryEditRow = false;

          }
          break;

        }
        break;

      case "ManpowerRow":
        {

          if (!_.isUndefined(this.selectedManPowerList) && this.selectedManPowerList.length > 0) {
            this.showManpowerRowAll = false;
            this.showManpowerEditRow = false;
            this.showManpowerTagRow = true;

          }
          else {
            this.showManpowerRowAll = true;
            this.showManpowerTagRow = false;
            this.showManpowerEditRow = false;

          }
          break;

        }
        break;

    }
  };

  checkSSOValue() {
    if (this.crewRateReport?.SSO != null) {
      this.showSSOsRowAll = false;
    }
    else {
      this.showSSOsRowAll = true;
    }
  };


  removeDateTag(value) {
    if (value == "DateRangeRow") {
      this.crewRateReportInfo.dateRangeStartDate = null;
      this.crewRateReportInfo.dateRangeEndDate = null;
      this.showDateRangeRowAll = true;
      this.showDateRangeEditRow = false;
      this.showDateRangeTagRow = false;
      if (this.crewRateReportInfo.dateRangeStartDate && this.crewRateReportInfo.dateRangeEndDate || (this.events.length != 0)) {
        this.isExport = false;
       }else {
         this.isExport = true;
       }
    }
  };

  removeSSOTag(item, row, index) {
    if (row === "SSOsRow") {
      this.showSection("SSOsRow");

    }
  }


  exportCrewRateReport() {

    var tempcrewRateReportInfo: any = {};

    tempcrewRateReportInfo.dateRangeStartDate = this.crewRateReportInfo.dateRangeStartDate === null ? null: moment(this.crewRateReportInfo.dateRangeStartDate).format("MM/DD/yyyy");
    tempcrewRateReportInfo.dateRangeEndDate = this.crewRateReportInfo.dateRangeEndDate === null ? null : moment(this.crewRateReportInfo.dateRangeEndDate).format("MM/DD/yyyy");

    tempcrewRateReportInfo.events = this.events != null ? this.events : [];

    tempcrewRateReportInfo.employeeType = this.selectedEmployeeType != null ? this.selectedEmployeeType : [];

    tempcrewRateReportInfo.positionFunction = this.selectedPositionFunction != null ? this.selectedPositionFunction : [];

    tempcrewRateReportInfo.manPower = this.selectedManPowerList != null ? this.selectedManPowerList : [];

    tempcrewRateReportInfo.homebasePM = this.selectedHomebasePM != null ? this.selectedHomebasePM : [];

    tempcrewRateReportInfo.eventType = this.selectedEventType != null ? this.selectedEventType : [];

    tempcrewRateReportInfo.category = this.selectedCategory != null ? this.selectedCategory : [];

    tempcrewRateReportInfo.sso = this.crewRateReport.SSO;
     
    tempcrewRateReportInfo.eventType.forEach(item => {
      item.value = false;  
    });

    tempcrewRateReportInfo.employeeType.forEach(item => {
      item.value = false;
    })

    tempcrewRateReportInfo.positionFunction.forEach(item => {
      item.value = false;
    })

    tempcrewRateReportInfo.manPower.forEach(item => {
      item.value = false;
    })

    tempcrewRateReportInfo.homebasePM.forEach(item => {
      item.value = false;
    })

    tempcrewRateReportInfo.category.forEach(item => {
      item.value = false;
    })
     
    this.isDownload = true;
    this.subs.sink = this._reportService.exportCrewRateReport(this.eventId, tempcrewRateReportInfo, this.userName).subscribe({
      next: (data) => {
        if (data==null) {
          this.showAlertMessage = true;
          this.log.showModalError("There are no data points to export for this report at the moment.");
        }
        else {
          this.crewRateReport = this.getCriteriaData;
          var fileName = "SCORE-Crew-Summary_";
          var d = moment(new Date()).format("MM-DD-yyyy");
          this.showAlertMessage = false;
          
            this.child.fileName = fileName + d;
            this.child.fileType = 'excel';
            this.child.data = data;
            this.child.download();
        }

      }, error: (data) => {
        this.showAlertMessage = true;
        this.log.showModalError("Error Occurred");
      }
    })
  };


  onItemSelectEventType($event) {
    this.selectedEventType = $event;
  }

  onItemSelectPositionName($event) {
    this.selectedPositionFunction = $event;
  }

  onItemSelectCategory($event) {
    this.selectedCategory = $event;
  }

  onItemSelectEmployeeType($event) {
    this.selectedEmployeeType = $event;
  }

  onItemSelectManpower($event) {
    this.selectedManPowerList = $event;
  }

  onItemSelectHomebasePM($event) {
    this.selectedHomebasePM = $event;
  }
  

  removeTag(item, row, index) {
    if (row === "EventTypesRow") {
      this.crewRateReport.eventType.splice(this.crewRateReport.eventType.indexOf(item), 1);
      this.selectedEventType.splice(this.selectedEventType.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("EventTypesRow");
    }

    if (row === "PositionFunctionRow") {
      this.positionFunctionList.splice(this.positionFunctionList.indexOf(item), 1);
      this.selectedPositionFunction.splice(this.selectedPositionFunction.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("PositionFunctionRow");
    }

    if (row === "CrewsRow") {
      //this.crewRateReport.category.splice(this.crewRateReport.category.indexOf(item), 1);
      this.selectedCategory.splice(this.selectedCategory.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("CrewsRow");
    }

    if (row === "EmployeeTypeRow") {
      this.employeeTypeList.splice(this.employeeTypeList.indexOf(item), 1);
      this.selectedEmployeeType.splice(this.selectedEmployeeType.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("EmployeeTypeRow");
    }

    if (row === "ManpowerRow") {
      this.crewRateReport.manPower.splice(this.crewRateReport.manPower.indexOf(item), 1);
      this.selectedManPowerList.splice(this.selectedManPowerList.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("ManpowerRow");
    }

    if (row === "ManagersRow") {
      this.crewRateReport.homebasePM.splice(this.crewRateReport.homebasePM.indexOf(item), 1);
      this.selectedHomebasePM.splice(this.selectedHomebasePM.indexOf(item), 1);
      item.value = !item.value;
      this.showSection("ManagersRow");
    }
  }

  onToggleFilterDatepicker($event) {
    this.crewRateReportInfo.dateRangeStartDate = $event[0];
    this.crewRateReportInfo.dateRangeEndDate = $event[1];
  }
  getCrewRateDetails() {
   this.subs.sink=this._reportService.getCrewRateDetails(this.eventId).subscribe(
      {
        next: (data) => {
          if (data === null) {
            this.showAlertMessage = true;
             this.log.showModalError("There are no data points to export for this report at the moment.") ;
          }
          else {

            this.crewRateReport = data;
            this.getCriteriaData = data;
          }

        }, error: (data) => {
          this.showAlertMessage = true;
         this.log.showModalError("Error Occurred");
        }
      })
  };

  loadEvents() {
    this.showAlertMessage = false;
    this.log.trace("loadSeasons");
    this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.addEventTerm);
    }).pipe(
      switchMap((query) => {
        return this._reportService.autoCompleteByOnlyEvent(query, this.userName)
      })
    );
  };

  ngOnInit(): void {
    this.loadEvents();
    this.getCrewRateDetails();
    this.crewRateReportInfo = {};
    this.crewRateReportInfo.dateRangeStartDate = null;
    this.crewRateReportInfo.dateRangeEndDate = null;
    this.crewRateReportInfo.events = null;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  };

}
