import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { CoreModule } from "../Core/core.module";
import { tierConfigChangeImpactService } from "../Shared/Services/tier-config-change-Impact.service";
import { NbcSharedModule } from "../Shared/shared.module";
import { modalImpactedEventsList } from "../TierRatecard/impact-modal/modal-Impacted-Events.component";
import { AdminRouterModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { EventTypeComponent } from "./event-type/event-type.component";
import { ModalEventTypeComponent } from "./event-type/modal-event-type.component";
import { JobTitleComponent } from "./job-title/job-title.component";
import { ModalJobTitleComponent } from "./job-title/modal-job-title.component";
import { NetworkGroupImpactAnalysisComponent } from "./network-group-impact-analysis.component";
import { ModalNetworkGroupComponent } from "./network-group/modal-network-group.component";
import { NetworkGroupComponent } from "./network-group/network-group.component";
import { ModalNetworkComponent } from "./network/modal-network";
import { NetworkComponent } from "./network/network.component";
import { ModalVenueImpactList } from "./venue/component/modal-venue-impact-list";
import { ModalVenueComponent } from "./venue/modal-venue.component";
import { VenueComponent } from "./venue/venue.component";
import { MinimumWageComponent } from "./minimum-wage/minimum-wage.component";
import { ModalMinimumWageComponent } from "./minimum-wage/modal-minimum-wage.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NbcSharedModule,
        RouterModule,
        TabsModule.forRoot(),
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        JwBootstrapSwitchNg2Module,
        ReactiveFormsModule,
        HttpClientModule,
        CoreModule,
        AdminRouterModule.forChild(),
        BsDatepickerModule.forRoot(),
    ],
    exports: [],
    declarations: [
        AdminComponent,
        JobTitleComponent,
        VenueComponent,
        EventTypeComponent,
        NetworkComponent,
        NetworkGroupComponent,
        ModalJobTitleComponent,
        ModalNetworkComponent,
        ModalVenueComponent,
        ModalEventTypeComponent,
        ModalNetworkGroupComponent,
        NetworkGroupImpactAnalysisComponent,
        modalImpactedEventsList,
        ModalVenueImpactList,
        MinimumWageComponent,
        ModalMinimumWageComponent,
    ],
    providers: [tierConfigChangeImpactService],
})
export class AdminModule {}
