import { DOCUMENT } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
} from "@angular/core";
import { SubSink } from "subsink";
import { EventBusService } from "src/app/Core/services/event-bus.service";
@Component({
    selector: "[payroll-fullscreen]",
    template:
        '<a class="btn btn-default" title="Fullscreen" (click)="toggle()"><i class="fa" [ngClass]="fullscreenMode ? \'fa-compress\' : \'fa-expand\'"></i></a>',
})
export class PayrollFullScreenComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cd: ChangeDetectorRef,
        private eventBus: EventBusService
    ) {}
    private subs = new SubSink();
    settings: any = {
        frozenColHeaders: [
            "hb",
            "day",
            "duration",
            "times",
            "comments",
            "scheduled-hrs",
            "contract-code",
            "contract-rule",
            "indicator",
            "pay-transactions",
            "dayCode",
            "wageSchedule",
            "flatday",
            "job-code",
            "rate",
            "costing",
            "gl-account",
            "payrollerComments",
        ],
        OFFSET_WIDTH: 2,
        nonscrollableHeadersWidth: 0,
        frozenHeader: undefined,
        frozenColHeader: undefined,
        nonscrollableHeaders: undefined,
        gridTableContainer: undefined,
        evtFrozenHeader: undefined,
        evtFrozenColHeaders: undefined,
        evtIndicators: undefined,
        form: undefined,
        formSensor: undefined,
        pagination: undefined,
    };
    fullscreenMode = false;

    @Output() onFullScreen = new EventEmitter<any>();

    hasClass(el, selector) {
        const className = " " + selector + " ";
        if (
            (" " + el.className + " ")
                .replace(/[\n\t]/g, " ")
                .indexOf(className) > -1
        ) {
            return true;
        }
        return false;
    }
    isHidden(el) {
        const style = window.getComputedStyle(el);
        return style.display === "none";
    }

    getElement(query) {
        return this.document.querySelector(query);
    }

    findChild(parent, query) {
        return parent.querySelectorAll(query)[0];
    }

    toggle() {
        if (this.hasClass(document.querySelector("body"), "fullscreen")) {
            this.fullscreenMode = true;
            this.document.querySelector("body")?.classList.remove("fullscreen");
            this.settings?.unfrozenPane?.removeAttribute("style");
        } else {
            this.fullscreenMode = false;
            this.onFullScreen.emit(true);
            setTimeout(() => this.PayrollFullScreen(), 200);
        }
    }

    PayrollFullScreen() {
        this.initialize();
    }

    initialize() {
        this.document.body.className += " fullscreen";
        this.setupHeaders();
        this.calculate();
    }
    setupHeaders() {
        this.settings.paginationWrapper = this.getElement(
            ".view-options-wrapper"
        );
        this.settings.pagination = this.getElement(".view-options");
        this.settings.frozenHeader = this.getElement(".frozen-header");
        this.settings.frozenColHeader = this.getElement(".frozen-col-header");
        this.settings.frozenHeader.append(this.settings.pagination);
        this.settings.frozenHeader.append(this.settings.frozenColHeader);
        this.settings.nonscrollableHeaders = this.getElement(".hdr-frozen");
        this.settings.scrollableHeaders = this.getElement(".hdr-scrollable");
        this.settings.gridTableContainer = this.getElement(
            ".grid-table-container"
        );
        this.settings.form = document.querySelector(
            'form[name="payrollTimesheetForm"]'
        );
    }
    calculate() {
        if (!this.isHidden(this.settings.form)) {
            setTimeout(() => {
                this.calculateScrollLocation();
                this.calculateScrollableHeaders();
                this.bindEvents();
                this.calculateFrozenHeader();
            }, 100);
        }
    }

    calculateScrollLocation() {
        this.settings.nonscrollableHeadersWidth =
            this.getElement(".hdr-nonscrollable").offsetWidth;
        const scrollLoc = this.settings.gridTableContainer.scrollLeft;
        this.settings.scrollableHeaders.style.cssText =
            "margin-left:" +
            this.settings.nonscrollableHeadersWidth +
            "px; left:" +
            -scrollLoc +
            "px";
    }
    calculateScrollableHeaders() {
        const prefix = ".grid-col-";
        const scrollable = this.getElement(".hdr-scrollable");
        let totalWidth = 0,
            i,
            columnName;

        const row = this.document.querySelector(".grid-row");
        for (i = 0; i < this.settings.frozenColHeaders.length; i += 1) {
            if (row) {
                columnName = this.settings.frozenColHeaders[i];
                const width =
                    i === 0
                        ? this.findChild(row, prefix + columnName).offsetWidth +
                          this.settings.OFFSET_WIDTH
                        : this.findChild(row, prefix + columnName).offsetWidth;
                const child = this.findChild(scrollable, prefix + columnName);
                if (child)
                    child.style.cssText =
                        "width:" + width + "px;left:" + totalWidth + "px";

                totalWidth = totalWidth + width;
            }
        }
    }

    bindEvents() {
        document
            .querySelector(".frozen-header")
            ?.addEventListener("scroll", (e) => {
                this.calculateFrozenHeader();
                this.calculateScrollableHeaders();
            });
        document
            .querySelector(".grid-table")
            ?.addEventListener("scroll", (e) => {
                this.calculateScrollableHeaders();
            });
        document
            .querySelector(".grid-col.grid-col-indicator")
            ?.addEventListener("scroll", (e) => {
                this.calculateScrollableHeaders();
            });
        this.bindScroll();
    }

    bindScroll() {
        this.settings.gridTableContainer.addEventListener("scroll", () => {
            this.calculateScrollLocation();
        });
    }
    calculateFrozenHeader() {
        this.settings.frozenHeader = this.getElement(".frozen-header");
        this.settings.unfrozenPane = this.getElement(".unfrozen-pane");
        const height = this.settings.frozenHeader.offsetHeight;
        if (!this.fullscreenMode) {
            const columnHeight =
                this.settings.nonscrollableHeaders?.offsetHeight;
            this.settings.unfrozenPane.style.cssText =
                "margin-top:" + (height + columnHeight) + "px";
        }
    }

    ngOnInit() {
        this.subs.sink = this.eventBus.on("fullScreenGroupUpdate", () => {
            this.calculateFrozenHeader();
        });
    }
}
