<form method="post">
  <div class="modal-header">
    <h4 class="modal-title">Export Event Timesheets</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
  </div>
  <div class="modal-body modal-agenda-body">
    <div nbc-alerts alerts-key="modalAlert"></div>
    <div class="row">
      <div class="col-lg-3">
        Begins On
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="form-group">
          <div class="input-group input-group-set" date-time-append>
            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
            <input type="text"
                   placeholder="Datepicker"
                   class="form-control form-control-md"
                   bsDatepicker
                   readonly
                   [bsValue]="startDate"
                   (bsValueChange)="dateChange($event);setFormAction();setEndDate();"
                   [bsConfig]="{ isAnimated: true,dateInputFormat: 'MMM DD YYYY' }">
           
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        Ends On
      </div>
      <div class="col-lg-5">
        <p>{{endDate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        Payroll Providers
      </div>
      <div class="col-lg-9 ">
        <label class="checkbox-inline payroll-provider-modal-checkbox" *ngFor="let item of payrollProvidersList">
          <input type="checkbox"
                 name="{{item.description}}"
                 [(ngModel)]="item.isSelected"
                 (change)="addProviderToList(item)"> {{item.description}}
        </label>
      </div>
    </div>
    <input type="hidden"
           name="payrollProviders[]"
           *ngFor="let item of providers"
           value="{{item}}" />
  </div>
  <div class="modal-footer text-right">
    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="export()" [disabled]="providers.length === 0">Export</button>
  </div>
</form>
