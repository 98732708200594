<div>
  <header id="col-main-hdr"><h1>Contacts</h1></header>
  <nav class="breadcrumb">
    <ul>
      <li home></li>
      <li>/</li>
      <li><a routerLink="../../" title="Contacts">Contacts</a></li>
      <li>/</li>
      <li>{{ contact?.firstName }} {{ contact?.middleName }} {{ contact?.lastName}}</li>
    </ul>
  </nav>
  <nbc-alert></nbc-alert>
  <div *ngIf="contact" class="page-container">
    <div class="container-fluid">

      <!-- DISPLAY VIEW -->
      <div authenticate-user="contact.retrieve,contact_profile_header.retrieve" class="page-content" style="min-height:270px" [hidden]="editMode">
        <a authenticate-user="contact.update,name.update,dob.update,ssn.update,contactpositionskill.update,union.update,contactlocation.update,contactinformation.update,contacthrmaster.employmentstatus,contacthrmaster.employeegroup,contacthrmaster.employeesubgroup,contacthrmaster.unionaffiliation,contacthrmaster.payrollarea,contacthrmaster.businesssegment, contactlocation.airport.update,contact.nabet.update" class="page-content-edit"
           (click)="setEditMode()" [hidden]="noEditMode"><i class="fa-solid fa-pencil"></i></a>
        <div class="row">
          <div class="col-md-3">
            <div class="profile-img">
              <img authenticate-user="contact.retrieve,ID.Retrieve" src="/assets/image/contact/{{contact?.imageId}}" />
            </div>
          </div>
          <div class="col-md-9">
            <h2 authenticate-user="contact.retrieve,Name.Retrieve">
              {{contact.firstName}} {{contact.middleName}} {{contact.lastName}} {{contact.contactNameNote}} <span class="label label-default-lte" authenticate-user="contact.retrieve,ID.Retrieve"> #{{contact.id}}</span>
            </h2>
            <div class="profile-tags" authenticate-user="contact.retrieve,ContactPositionSkills.Retrieve">
              <span class="label label-default-lte"
                    *ngFor="let position of contact.positions">{{position.description}}</span>
            </div>
            <div class="profile-skills" authenticate-user="contact.retrieve,ContactPositionSkills.Retrieve">
              <em>Skill Set:</em>
              <span *ngFor="let skill of contact.skills; let $index=index;">
                <span [hidden]="$index==contact.skills.length-1">{{skill.description}},</span>
                <span [hidden]="$index!==contact.skills.length-1">{{skill.description}}</span>
              </span>
            </div>
            <div class="profile-labels">
              <span authenticate-user="contact.retrieve,Union.Retrieve">{{contact.union}}</span>
              <span [hidden]="!contact.isNabet"
                    authenticate-user="contact.nabet.retrieve" authenticate-command="hide">NABET</span>
              <span authenticate-user="contact.retrieve,EmployeeType.Retrieve">{{contact.employeeType?.description}}</span>
              <span authenticate-user="contact.rate.retrieve,EmployeeType.RateCode.Retrieve" *ngIf="contact.rateCode.name">{{contact.rateCode.name}}</span>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-3">

          </div>
          <div class="col-md-9">
            <div style="float:left; display: flex; padding: 4px 2px 0 15px;">
              <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                <i class="fa fa-address-card"
                   style="color:{{contact.contactVerified.color}};font-size:20px"
                   authenticate-user="contact.contactverified.retrieve" authenticate-command="hide"
                   title="{{contact.contactVerified.tooltip}}">
                </i>
              </span>
              <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                <i class="fa fa-envelope"
                   style="color:{{contact.covidCommunicationSent.color}};font-size:20px"
                   authenticate-user="contact.communicationsent.retrieve" authenticate-command="hide"
                   title="{{contact.covidCommunicationSent.tooltip}}">

                </i>
              </span>
              <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                <i class="fa fa-check-circle"
                   style="color:{{contact.covidReplyRecd.color}};font-size:20px"
                   authenticate-user="contact.acknowledgement.retrieve" authenticate-command="hide"
                   title="{{contact.covidReplyRecd.tooltip}}">

                </i>
              </span>
            </div>
            <span *ngIf="contact.flaggedByHR" class="profile-comment"><strong>This contact cannot be crewed to an event. Please contact HR if further information is required.</strong></span>
          </div>
        </div>
        <!--Hidding the Add to wishlist Button as it is not available in QA-->
        <div class="page-content-action-btm d-none">
          <button class="btn btn-danger">Add to Wishlist</button>
        </div>
      </div>

      <!-- EDIT VIEW -->
      <form #contactUpdate="ngForm" novalidate *ngIf="editMode">
        <div class="page-content" style="min-height: 270px;" [hidden]="!editMode">
          <div class="row">
            <div class="col-md-2">
              <div class="profile-img">
                <img src="/assets/image/contact/{{contact.imageId}}" />
              </div>
              <div class="profile-status">
                <div class="input-group input-group-set" date-time-append *ngIf="1==2">
                  <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                  <input type="datetime"
                         class="form-control form-control-md"
                         date-time
                         [ngModel]="contact.inactiveDate"
                         name="contact.inactiveDate"
                         min-view="date" max-view="date"
                         format="mediumDate" readonly="readonly"
                         placeholder="Inactive Date" ng-required="!contact.isActive">
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" [ngClass]="{'has-error':contactUpdate.firstName?.invalid && submitted}">
                    <label class="form-control-label">First Name</label>
                    <input authenticate-user="contact.update,Name.Update"
                           authenticate-command="disabled"
                           type="text"
                           appInputRestriction="restrictChars"
                           name="firstName" maxlength="20"
                           #name="ngModel"
                           required
                           [class.is-invalid]="name.invalid && name.touched"
                           class="form-control input-lg"
                           [(ngModel)]="contact.firstName" authenticate-required="true" />

                    <div [hidden]="(name.valid || name.pristine) && !contactUpdate.submitted"
                         class="text-danger">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-control-label">Middle Name</label>
                    <input authenticate-user="contact.update,Name.Update"
                           authenticate-command="disabled" type="text"
                           appInputRestriction="restrictChars"
                           class="form-control input-lg" maxlength="20" name="middleName"
                           [(ngModel)]="contact.middleName" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group" [ngClass]="{'has-error':contactUpdate.lastName?.invalid && submitted}">
                    <label class="form-control-label">Last Name</label>
                    <input authenticate-user="contact.update,Name.Update"
                           authenticate-command="disabled"
                           type="text"
                           #lastname="ngModel"
                           appInputRestriction="restrictChars"
                           class="form-control input-lg"
                           maxlength="20" name="lastName"
                           [(ngModel)]="contact.lastName"
                           required
                           authenticate-required="true" />
                    <div [hidden]="(lastname.valid || lastname.pristine) && !contactUpdate.submitted"
                         class="text-danger">Please enter a Last Name.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">Event Position</label> 
                    <nbc-tags [(searchTerm)]="positionSearchTerm"
                              [searchTermObservable$]="positionObservable$"
                              [tagDisplay]="'description'"
                              [tagId]="contact.positions"
                              [tagName]="contact.positions"
                              [tags]="contact.positions"
                              [placeholder]="'Add Position'"
                              (tagChange)="setPositionEventTerm($event)"
                              (nbcOnOptionSelect)="onPositionSelected($event)"
                              nbcAuthenticateUser="contact.update,ContactPositionSkills.Update"
                              nbcAuthenticateCommand="tagsdisabled"></nbc-tags>
                  </div>
                  <div class="form-group">
                    <label class="form-control-label">Skill Set</label>
                    <div>
                      <nbc-tags [searchTerm]="skillSearchTerm"
                                [sourceData]="skillData"
                                [asyncLoad]="false"
                                [tagDisplay]="'description'"
                                [tagId]="contact.skills"
                                [tagName]="contact.skills"
                                [tags]="contact.skills"
                                [placeholder]="'Add Skill'"
                                (tagChange)="setSkillEventTerm($event)"
                                [nbcMinLength]="1"
                                nbcAuthenticateUser="contact.update,ContactPositionSkills.Update"
                                nbcAuthenticateCommand="tagsdisabled"
                                (nbcOnOptionSelect)="onSkillSelected($event)"></nbc-tags>
                    </div>
                  </div> 
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <span *ngIf="contact.flaggedByHR" class="profile-comment"><strong><br />This contact cannot be crewed to an event. Please contact HR if further information is required.</strong></span>
                </div>
              </div>

              <div class="row">
                <div style="float:left; display: flex; padding: 4px 2px 0 15px;"
                     authenticate-user="contact.contactverified.update,contact.communicationsent.update,contact.acknowledgement.update,contact.contactverified.retrieve,contact.communicationsent.retrieve,contact.acknowledgement.retrieve"
                     authenticate-command="hide">
                  <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                    <i class="fa fa-address-card cursor-pointer contact-verified"
                       style="color:{{contact.contactVerified.color}};font-size:20px"
                       authenticate-user="contact.contactverified.update" authenticate-command="disabled"
                       title="{{contact.contactVerified.tooltip}}"
                       (click)="contactVerified();">
                    </i>
                  </span>
                  <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                    <i class="fa fa-envelope cursor-pointer covid-communication-sent"
                       style="color:{{contact.covidCommunicationSent.color}};font-size:20px"
                       authenticate-user="contact.communicationsent.update" authenticate-command="disabled"
                       title="{{contact.covidCommunicationSent.tooltip}}"
                       (click)="covidCommunicationSent();">

                    </i>
                  </span>
                  <span class="fa-stack" style="text-align: center;border: 1px solid #ddd;">
                    <i class="fa fa-check-circle cursor-pointer covid-reply-recd"
                       style="color:{{contact.covidReplyRecd.color}};font-size:20px"
                       authenticate-user="contact.acknowledgement.update" authenticate-command="disabled"
                       title="{{contact.covidReplyRecd.tooltip}}"
                       (click)="covidReplyRecd();">
                    </i>
                  </span>
                </div>
              </div>
              <div class="row" authenticate-user="contact.nabet.update" authenticate-command="hide">
                <div style="float:left;padding: 20px 2px 0 15px;">
                  <label class="cbcontainer">
                    <span style="padding:7px;font-size:14px;">NABET</span>
                    <input type="checkbox" name="contact.isNabet"
                           [(ngModel)]="contact.isNabet" />

                    <span class="checkmark"></span>
                  </label>
                </div>

              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-group">
                  <label class="form-control-label">Contact Name Note</label>
                  <input authenticate-user="contact.update,Name.Update"
                         authenticate-command="disabled" type="text"
                         class="form-control input-md" maxlength="20" name="contactNameNote"
                         [(ngModel)]="contact.contactNameNote"
                         appInputRestriction="restrictChars" />
                </div>
                <label class="form-control-label">Employee Type</label>
                <nbc-select [model]="contact.employeeType"
                            name="contact.employeeType"
                            [source]="employeeTypes"
                            (modelChange)="onEmployeeTypeChange($event)"
                            authenticate-user="contact.rate.update,EmployeeType.Update"
                            authenticate-command="nbcselectdisabled"
                            [attachOldValue]="false"
                            display="description"
                            value="description"
                            placeHolderDisplay="Select One"
                            placeHolderValue="null">
                </nbc-select>
              </div>
              <div class="form-group" authenticate-user="contact.rate.update,EmployeeType.RateCode.Update">
                <label class="form-control-label">Rate Card</label>
                <nbc-dropdown *ngIf="contact?.employeeType?.description?.toLowerCase() !== 'vendor' && rateCardList?.length>0"
                              [source]="rateCardList"
                              [(model)]="contact.rateCode"
                              display="id"
                              nbcSize="w-100"
                              (nbcOnOptionSelect)="checkRateCode()"
                              placeholder="Select One"
                              nbcAuthenticateUser="contact.rate.update,EmployeeType.RateCode.Update"
                              nbcAuthenticateCommand="nbcdisabled"
                              nbcDisabled="(contact.employeeType === null || contact.employeeType === '' || rateCardList.length === 0 || contact.employeeType.description?.toLowerCase() === 'vendor' || !(currentUser.allFeatures['contact.rate.update'] || currentUser.isSuperUser))">
                </nbc-dropdown>
                <label *ngIf="contact?.employeeType?.description?.toLowerCase() === 'vendor'" class="form-control-label">: N/A</label>
              </div>
              <div class="form-group" [ngClass]="{'has-error':contactUpdate.union?.invalid && submitted}">
                <label class="form-control-label">Union Designation</label>
                <select name="contact.union"
                        class="dropdown-toggle form-control"
                        [(ngModel)]="contact.union"
                        authenticate-user="contact.update,Union.Update"
                        authenticate-command="disabled"
                        #union="ngModel"
                        style="height:35px;">
                  <option [ngValue]="undefined">Select One</option>
                  <option *ngFor="let unionOption of unionOptions" [ngValue]="unionOption.name">{{unionOption.name}}</option>
                </select>
                <span [hidden]="(union.valid || union.pristine)  && !contactUpdate.submitted"
                      class="text-danger">
                  Please enter a Union.
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>&nbsp;</div>
      <div class="content-tabs">
        <tabset class="contact-tabs-lg">
          <tab *ngFor="let t of tabs | filterBy:['hasAccess']:true"
               [active]="t.active"
               (selectTab)="go(t.route)">
            <ng-template tabHeading>              
              <span nbcRightClickOpen="Remotes/Contacts/Detail/{{contactId}}/profileinfo">{{t.heading}}</span>
            </ng-template>
          </tab>
        </tabset>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>




</div>
