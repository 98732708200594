<!--<pre class="card card-block card-header">Model: {{ asyncSelected2?.id}}</pre>

<input [(ngModel)]="asyncSelected"
       [typeahead]="positionObserver"
       [typeaheadAsync]="true"
       typeaheadOptionField="description"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       placeholder="Locations loaded via observable"
       class="form-control"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"

        [isAnimated]="true">-->

<contact-search></contact-search>

