import { Component, OnInit, OnDestroy } from "@angular/core";
import * as moment from "moment";
import { EventTypeService } from "../Shared/Services/eventType.service";
import { NbcLoggerService } from "../Shared/logger/nbc-logger.service";
import { DashboardService } from "../Shared/Services/dashboard.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "upcoming-events",
    templateUrl: "./upcomingevents.components.html",
})
export class UpcomingEventsComponent implements OnInit, OnDestroy {
    bsInlineRangeValue = [moment().toDate(), moment().toDate()];
    //maxDate = moment().toDate();
    eventTypes: any;
    eventTypeSearchComplete = false;
    toggleMenu = document.querySelector("button");

    constructor(
        private _eventTypeService: EventTypeService,
        private _dashboardService: DashboardService,
        private log: NbcLoggerService
    ) {
        //   this.maxDate.setDate(this.maxDate.getDate() +90);
        // this.bsInlineRangeValue = [moment().toDate(), this.maxDate];
    }
    componentDestroyed$: Subject<boolean> = new Subject();

    upcomingEvents: any = {
        upcomingEvents: [],
        eventTypeSummaries: [],
    };
    //  console.log(this.upcomingEvents);
    id!: any | {};
    upcomingEventsQuery: any = {
        startDate: moment().toDate(),
        endDate: moment().add(3, "M").toDate(),
        eventTypeId: undefined,
    };

    onToggle($event: any) {
        const navElm = document.querySelector(".c-nav");
        // this.navElm.classList.toggle('is-active');
    }

    onToggleFilterDatepicker($event: any) {
        this.log.trace("Upcoming Events --> DateRange selected");
        this.upcomingEventsQuery.startDate = $event[0];
        this.upcomingEventsQuery.endDate = $event[1];

        this.getUpcomingEvents();
    }

    getEventTypes() {
        this.log.trace("UpcomingEvents -> getEventTypes:fetching data");
        this._eventTypeService
            .getEventTypes({})
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.eventTypes = data.reference;
                    this.log.trace("UpcomingEvents -> event types received");
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the event types at this time. Please try again later."
                        );
                },
                () => {}
            );
    }

    getUpcomingEvents() {
        this.log.trace("getUpcomingEvents called");
        // usSpinnerService.spin("searchSpinner");

        const dashboardQuery = {
            startDate: this.upcomingEventsQuery.startDate,
            endDate: this.upcomingEventsQuery.endDate,
            eventTypeId: this.upcomingEventsQuery.eventTypeId,
        };

        this.log.trace("UpcomingEvents -> getEventTypes:fetching data");
        this._dashboardService
            .getUpcomingEvents(dashboardQuery)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.upcomingEvents.upcomingEvents = data.upcomingEvents;
                    this.upcomingEvents.eventTypeSummaries =
                        data.eventTypeSummary;
                    this.log.trace("UpcomingEvents -> event types received");
                },
                (err) => {
                    this.log.error(err),
                        this.log.showError(
                            "Unable to load the event types at this time. Please try again later."
                        );
                },
                () => {}
            );
    }

    filterResults() {
        if (this.upcomingEventsQuery.eventTypeId === "undefined") {
            this.upcomingEventsQuery.eventTypeId = undefined;
        }
        this.getUpcomingEvents();
    }

    ngOnInit() {
        this.getEventTypes();
        this.bsInlineRangeValue = [
            this.upcomingEventsQuery.startDate,
            this.upcomingEventsQuery.endDate,
        ];
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
