import { ChangeContext } from "@angular-slider/ngx-slider";
import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";

@Component({
    selector: "modal-tier-rate",
    templateUrl: "./modal-tier-ratecard.component.html",
})
export class ModalTierRatecardComponent implements OnInit {
    constructor(
        private _modalService: BsModalService,
        private bsTierRates: BsModalRef,
        private log: NbcLoggerService
    ) {}

    bsConfModalRef!: BsModalRef;

    onSync!: Subject<any>;
    positionListItem!: any;
    position!: any;
    selectedTierrateCard!: any;
    tierrateCard!: any;
    sourceModule!: any;
    sourceModuleName!: any;
    tierRateCardComments!: any;

    tierRates: any = null;
    MAX_AMOUNT_ALLOWED = 250;
    minimumWage: any;

    //slider
    options: any = {};
    sliderValue!: number;
    configureSlider() {
        let rates: any[] = [];
        this.sliderValue =
            this.positionListItem?.customRate || this.tierRates.minRate;
        rates = [
            { value: this.tierRates.minRate, legend: "Low" },
            { value: this.tierRates.percentile25, legend: "25th" },
            { value: this.tierRates.percentile50, legend: "50th" },
            { value: this.tierRates.percentile75, legend: "75th" },
            { value: this.tierRates.maxRate, legend: "High" },
        ];
        this.options = {
            floor: this.tierRates.minRate,
            ceil: this.tierRates.maxRate,
            showTicks: true,
            stepsArray: rates,
            precision: 3,
            step: 0.25,
            tooltip: "show",
            //ticksArray: [this.tierRates.minRate, this.tierRates.percentile25, this.tierRates.percentile50, this.tierRates.percentile75, this.tierRates.maxRate],
            showTicksValues: true,
            showSelectionBar: true,
            getSelectionBarColor: (value: number): string => {
                value = this.positionListItem.customRate || this.sliderValue;
                if (value <= this.tierRates.percentile25) {
                    return "green";
                }
                if (
                    value > this.tierRates.percentile25 &&
                    value <= this.tierRates.percentile50
                ) {
                    return "gray";
                }
                if (
                    value > this.tierRates.percentile50 &&
                    value <= this.tierRates.percentile75
                ) {
                    return "yellow";
                }
                if (
                    value > this.tierRates.percentile75 &&
                    value <= this.tierRates.maxRate
                ) {
                    return "orange";
                }

                if (value > this.tierRates.maxRate) {
                    return "red";
                }
                return "gray";
            },
        };
    }

    closeModal() {
        this.log.trace("closeModal");
        this.bsTierRates.hide();
    }

    /*
     * user updated value using text box
     */
    customRateChanged(e:any) {
        if (Number(this.positionListItem.customRate) == 0) {
            this.positionListItem.customRate = "";
        } else {
            this.positionListItem.customRate = Number(
                this.positionListItem.customRate
            )
                .toFixed(3)
                .toString();
        }
        this.sliderValue = this.positionListItem.customRate;
        //$("#slider1").bootstrapSlider('setValue', this.positionListItem.customRate, false, true);
        //e.preventDefault();
        this.customRateUserConfirmationWorkFlow(this.sliderValue);
        //SliderChangedByValue(e);
    }

    customRateChangedBySlider(changeContext: ChangeContext) {
        this.positionListItem.customRate = null;
        this.positionListItem.customRate = changeContext.value;
    }

    /*
     * For the rates > $250
     */
    customRateUserConfirmationWorkFlow(newValue: any) {
        if (!newValue) return;

        if (newValue < this.minimumWage) {
            this.showConfirmMsg(
                "Tier Rate Card",
                "The entered rate is less than the minimum wage.",
                false,
                null,
                null,
                null,
                "Close",
                null,
                []
            );
        } else if (newValue > this.MAX_AMOUNT_ALLOWED) {
            this.showConfirmMsg(
                "Tier Rate Card",
                "Hourly Rates over $250, must be entered by Payroll Team, Please enter $250 and notify payroll of what rate should be.",
                false,
                null,
                null,
                null,
                "Close",
                this.clearExeededCustomRate,
                []
            );
        }
    }

    clearExeededCustomRate() {
        this.positionListItem.customRate = null;
        // $("#slider1").bootstrapSlider('setValue', this.positionListItem.customRate, false, true);
    }

    //character validation and formatting
    customRateValidation(event: any) {
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if (
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 37 ||
            event.keyCode == 39 ||
            event.keyCode == 46 ||
            event.keyCode == 190 ||
            event.keyCode == 110
        ) {
            //accepting valid characters
        } else {
            event.preventDefault();
        }

        const val = this.positionListItem.customRate;

        if (
            val &&
            val.toString().split(".").length > 1 &&
            (event.keyCode == 190 || event.keyCode == 110)
        ) {
            event.preventDefault();
        } else {
            if (
                val &&
                val.length >= 3 &&
                event.which !== 8 &&
                !(val.indexOf(".") > -1)
            ) {
                if (event.keyCode == 190 || event.keyCode == 110) {
                } else {
                    this.positionListItem.customRate =
                        val.substr(0, 3) + "." + val.substr(4);
                }
            }
        }
    }

    /*
     * Saving the custom rate selected by the user
     */
    onSaveRate() {
        const newValue = this.positionListItem.customRate
            ? this.positionListItem.customRate
            : this.positionListItem.customRate;
        const closeCallback = this.sourceModule
            ? this.closeModal
            : function () {};
        console.log(this.minimumWage);

        if (newValue > this.MAX_AMOUNT_ALLOWED || newValue < this.minimumWage) {
            this.customRateUserConfirmationWorkFlow(newValue);
        }
        //If Value entered is a above the "High" amount
        else if (newValue > this.tierRates.maxRate) {
            this.showConfirmMsg(
                "Tier Rate Card",
                "Entered Value is above High Rate for selected Tired Rate Card, Please get an approval for this rate.",
                true,
                this.saveRate,
                null,
                "Proceed",
                "Cancel",
                closeCallback,
                []
            );
        }

        //If Value entered is a below the "Low" amount
        else if (newValue < this.tierRates.minRate) {
            this.showConfirmMsg(
                "Tier Rate Card",
                "Entered Value is below Low Rate for selected Tired Rate Card.",
                true,
                this.saveRate,
                null,
                "Proceed",
                "Cancel",
                closeCallback,
                []
            );
        } else {
            //normal rate within range
            this.saveRate();
        }
    }

    //rate save function
    saveRate() {
        this.positionListItem.posTierId = this.tierRates.posTierId;
        this.positionListItem.eventPosition.posTierId =
            this.tierRates.posTierId;
        this.onSync.next(this.positionListItem);
        //$modalInstance.close(this.positionListItem);
        this.closeModal();
    }

    showConfirmMsg(
        title: any,
        message: any,
        canUpdate: any,
        callback: any,
        args: any,
        confirmText: any,
        cancelText: any,
        cancelCallback: any,
        cancelargs: any
    ) {
        this.log.trace("showValidationErrorModal");
        let modalInstance;

        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: confirmText,
            cancelText: cancelText,
            hideConfirm: !confirmText ? true : false,
        };
        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsConfModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsConfModalRef.content.onAffirmation.subscribe((result: any) => {
            this.log.trace("confirmation");
            callback.apply(this, args);
        });
        this.bsConfModalRef.content.onRejection.subscribe((result: any) => {
            this.log.trace("onRejection");
            cancelCallback.apply(this, cancelargs);
        });
    }

    ngOnInit(): void {
        this.onSync = new Subject();
        this.positionListItem = _.cloneDeep(this.position);
        this.selectedTierrateCard = this.tierrateCard;
        this.sourceModule = this.sourceModuleName;
        // this.sourceModule = this.position.sourceModule;
        this.tierRateCardComments = this.position.tierRateCardComments;

        this.tierRates =
            this.selectedTierrateCard.positionTiers[0].currentRates;
        this.minimumWage = this.minimumWage;
        this.configureSlider();
    }
}
