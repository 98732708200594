<div class="modal-header">
  <h4 class="modal-title">Search and Select Budget/Show Code</h4>
  <button type="button" class="btn-close" aria-hidden="true" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <form #budgetForm="ngForm" name="budgetForm" novalidate>
    <nbc-modal-alert></nbc-modal-alert>

    <div class="form-horizontal"
         [ngClass]="{'has-error': !isOneFieldEntered() && budgetForm.submitted }">
      <div class="mb-3 d-flex">
        <label class="col-md-3 control-label">Company Code</label>
        <div class="col-md-9" *ngIf ="companyCode?.length > 0">
          <nbc-dropdown [source]="companyCode"
                        name="companyCode"
                        [(model)]="budgetQuery.coCode"
                        display="description"
                        value="description"
                        nbcSize="w-100"
                        placeholder="Select Company Code">
          </nbc-dropdown>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <label class="col-md-3 control-label"
               [ngClass]="{'has-error': !isOneFieldEntered() &&  budgetForm.submitted }">
          Budget/Show Code
        </label>
        <div class="col-md-9">
          <input type="text"
                 appInputRestriction="restrictChars"
                 name="budgetShowCode"
                 class="form-control"
                 [(ngModel)]="budgetQuery.code"
                 maxlength="250" />
        </div>
      </div>
      <div class="mb-3 d-flex">
        <label class="col-md-3 control-label">Charge Code Type</label>
        <div class="col-md-9">
          <nbc-dropdown [source]="chargeCodeTypes"
                        name="chargeCodeTypes"
                        [(model)]="chargeCodeType"
                        display="description"
                        value="description"
                        nbcSize="w-100"
                        >
          </nbc-dropdown>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <label class="col-md-3 control-label">Description</label>
        <div class="col-md-9">
          <input type="text"
                 appInputRestriction="restrictChars"
                 class="form-control"
                 name="description"
                 [(ngModel)]="budgetQuery.description"
                 maxlength="250" />
          <div *ngIf="!isOneFieldEntered() && budgetForm.submitted"
               class="form-group">
            <span class="control-label control-label-error">
              Please enter at least one search criterion.
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <div class="col-md-9 offset-md-3">
          <button class="btn btn-primary"
                  (click)="budgetQuery.currentPage=1;budgetSearch(budgetForm);">
            Search
          </button> 
          <input type="button"
                 class="btn btn-default"
                 value=" Reset"
                 (click)="chargeCodeType='N';resetBudgetSearch(budgetForm)"/>
        </div>
      </div>

    </div>
    <div class="clearfix" *ngIf="showResultsContents === true">
      <div>
        <pagination-label pageSize="{{wbsecodes.pageSize}}"
                          currentPage="{{wbsecodes.currentPage}}"
                          totalRecords="{{wbsecodes.totalRecords}}">
        </pagination-label>
      </div>

      <nbc-pagination *ngIf="showResultsContents === true"
                      totalItems="{{wbsecodes.totalRecords}}"
                      style="float: right;"
                      itemsPerPage="{{wbsecodes.pageSize}}"
                      [(currentPage)]="budgetQuery.currentPage"
                      (pageChanged)="budgetSearch(budgetForm)">
      </nbc-pagination>
    </div>
    <div *ngIf="showResultsContents === true"
         class="table-responsive clearfix" >
      <table class="table table-hover table-condensed">
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wbsecode of wbsecodes.items"
              (click)="selectWbseCode(wbsecode, chargeCodeType)">
            <td>{{wbsecode.code}}</td>
            <td>{{wbsecode.description}}</td>
            <td>{{wbsecode.coCode}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
