import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SubSink } from "subsink";
import { NbcFileDownloadComponent } from "../../../../Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "../../../../Shared/logger/nbc-logger.service";
import { EventService } from "../../../../Shared/Services/event.service";

@Component({
  selector: "event-export",
  templateUrl: "./event-timesheet-report-export.component.html"
})
export class EventTimesheetReportExportComponent implements OnInit {

  constructor(private log: NbcLoggerService,
    private eventService: EventService,
    public bsModalRef: BsModalRef) { }

  @ViewChild(NbcFileDownloadComponent) child;
  contactIds: any[] = [];
  eventTimesheetQuery: any | undefined;
  fileType: any | undefined
  reportHeader: any;
  contactList: any;
  pdfDate: any;
  formAction: any;
  fileName= "";
  reportType = "";
  private subs = new SubSink();

  setContactIds() {
    if (this.contactList && this.contactList.length > 0) {
      for (let i = 0; i < this.contactList.length; i += 1) {
        this.contactList[i].isSelected = true;
        this.contactIds.push(this.contactList[i].id);
      }
    }
  };

  addContactToList(item) {
    let index;

    if (item.isSelected) {
      this.contactIds.push(item.id);
    } else {
      index = this.contactIds.indexOf(item.id);
      if (index > -1) {
        this.contactIds.splice(index, 1);
      }
    }
  };


  cancel() {
    this.log.trace("modal closed");
    this.bsModalRef.hide()
  };

  Export() {
    this.subs.sink = this.eventService.getTimesheetReport(this.eventTimesheetQuery.eventId, this.pdfDate, this.contactIds, this.reportType)
      .subscribe({
        next: (data) => { 

          this.child.fileName = this.fileName;
          this.child.fileType = this.fileType;
          this.child.data = data;
          this.child.download();
          this.cancel()
        }
      });
  }


  ngOnInit(): void {

    this.reportType = this.reportType;
    this.contactList = _.cloneDeep(this.contactList);
    this.eventTimesheetQuery = this.eventTimesheetQuery;
    const time = moment().format("MM_DD_YYYY_h_mm_ss A");
    if (this.reportType.toLowerCase() === "pdf") {
      this.fileType = "pdf";
      this.fileName = "Event-" + this.eventTimesheetQuery.eventId + "-TimesheetExport_" + time;
      this.reportHeader = "Select Contacts for PDF Export";
    }
    else if (this.reportType.toLowerCase() === "excelsignin") {
      this.fileType = "excel";
      this.fileName = "Event-" + this.eventTimesheetQuery.eventId + "-TimesheetExportSignIn_" + time;
      this.reportHeader = "Select Contacts for Sign-In Report";
    }
    else if (this.reportType.toLowerCase() === "excelpost") {
      this.fileType = "excel";
      this.fileName = "Event-" + this.eventTimesheetQuery.eventId + "-TimesheetExportPost_" + time;
      this.reportHeader = "Select Contacts for Post Report ";
    }
    else {
      this.fileType = "excel";
      this.fileName = "Event-" + this.eventTimesheetQuery.eventId + "-TimesheetExport_" + time;
      this.reportHeader = "Select Contacts for Excel Report";
    }

    this.pdfDate = moment(this.eventTimesheetQuery.timeCardDate).format("M-DD-YYYY"); 
    this.setContactIds()

  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
