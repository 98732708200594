<div>
  <span us-spinner spinner-key="loadImpactedEventSpinner"></span>
  <div class="modal-header ng-scope">
    <h4 class="modal-title ng-binding">{{modalTitle}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
  </div>
  <div style="padding:10px;">
    {{message}}
  </div>
  <div class="modal-body">
    <div nbc-alerts alerts-key="modalAlert"></div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <div class="form-group view-options text-right" *ngIf="impactedEvents?.items?.length > 0">
              <label class="pagination-results">
                <pagination-label pageSize="{{impactedEvents.pageSize}}"
                                  currentPage="{{impactedEvents.currentPage}}"
                                  totalRecords="{{impactedEvents.totalRecords}}"></pagination-label>
              </label>
              
              <nbc-pagination totalItems="{{impactedEvents.totalRecords}}"
                              itemsPerPage="{{impactedEvents.pageSize}}"
                              [(currentPage)]="queryData.currentPage"
                              (pageChanged)="getImpactedFutureEvents($event)"></nbc-pagination> 
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Event ID</th>
                  <th>Event Type</th>
                  <th>Event</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of impactedEvents?.items">
                  <td>{{event.id}}</td>
                  <td>{{event.eventType.name}}</td>
                  <td>
                    <a class="nbc-router-link btn-link" routerLink="Events/Event/{{event.id}}/Summary" target="_blank">
                      {{event.eventName}}
                    </a> 
                  </td>

                </tr>
                <tr *ngIf="impactedEvents?.items?.length == 0">
                  <td colspan="4">none</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-right">
    <div class="row">
      <div class="col-sm-12">
        <div>
          <button class="btn btn-default" (click)="closeModal()">Cancel</button>
          <button class="btn btn-primary pull-right" (click)="proceed()">Proceed</button>
        </div>
      </div>
    </div>
  </div>
</div>

