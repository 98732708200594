import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { OnboardingService } from "../../Shared/Services/Onboarding/onboarding.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { Subject, Observable, Subscriber } from "rxjs";
import { takeUntil, switchMap, mergeMap } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
    EmitEvent,
    EventBusService,
} from "../../Core/services/event-bus.service";
import { OnboardingColorsService } from "../../Shared/Services/Onboarding/onboarding-colors.service";
import { ContactService } from "../../Shared/Services/contact.service";
import { PositionService } from "../../Shared/Services/position.service";
import { cloneDeep } from "lodash";
import { NbcModalConfirmation } from "../../Shared/components/confirmation/nbc-modal-confirmation.component";
import { SharedDataService } from "../../Shared/sharedData.service";
import { ReportService } from "../../Shared/Services/report.service";
import { NbcFileDownloadComponent } from "../../Shared/components/nbc-file-download/nbc-file-download.component";
import { EditPositionRequestComponent } from "./editPositionRequest.component";
import { SubSink } from "subsink";
import { SendReminderComponent } from "./sendreminder.component";
import * as _ from "lodash";
import { NgxUiLoaderService, SPINNER } from "ngx-ui-loader";

@Component({
    selector: "onboarding-requests",
    templateUrl: "./onboarding-requests.component.html",
    //styleUrls: [
    //  "../../../assets/Content/app/areas/hr/hr.css",
    //  "../../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.Emulated
})
export class OnboardingRequestsComponent implements OnInit, OnDestroy {
    constructor(
        private _onboardingService: OnboardingService,
        private _modalService: BsModalService,
        private _remotesQueryService: RemotesQueryService,
        private _eventBusService: EventBusService,
        private _onboardingColorsService: OnboardingColorsService,
        private _contactService: ContactService,
        private _positionService: PositionService,
        private _sharedService: SharedDataService,
        private _reportService: ReportService,
        private log: NbcLoggerService,
        private ngxService: NgxUiLoaderService
    ) {}

    componentDestroyed$: Subject<boolean> = new Subject();
    @ViewChild(NbcFileDownloadComponent) child;

    private subs = new SubSink();
    spinnerType = SPINNER.rectangleBounce;
    isFirstTimehRequestPageLoad = false;

    positionStatusList: any[] = [
        { name: "Position Requests Submitted", value: 1 },
        { name: "Registrations Submitted", value: 2 },
        { name: "Registrations Complete", value: 3 },
        { name: "Registrations Rejected", value: 4 },
        { name: "Registrations Approved", value: 5 },
        { name: "Onboardings In-Progress", value: 6 },
    ];

    positionTypeList: any = [
        { name: "PEP Casual", value: "PEP Casual" },
        { name: "Daily Hire NABET", value: "Daily Hire NABET" },
        { name: "Daily Hire DGA", value: "Daily Hire DGA" },
        { name: "PEP IATSE", value: "PEP IATSE" },
    ];

    employeeStatusList: any = [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
        { name: "Processing", value: "Processing" },
        { name: "Withdrawn", value: "Withdrawn" },
    ];

    exportStatusList: any = [
        { name: "Submitted", value: "Y" },
        { name: "Not Submitted", value: "N" },
    ];

    searchCriteria: any = [
        { optionValue: "1", optionDescription: "Contact Name" },
        { optionValue: "2", optionDescription: "SSO" },
        { optionValue: "3", optionDescription: "Hiring Manager" },
        { optionValue: "7", optionDescription: "Production Manager" },
        { optionValue: "4", optionDescription: "Position Name" },
        { optionValue: "5", optionDescription: "Event Name" },
        { optionValue: "6", optionDescription: "Event Id" },
    ];

    pageSizeList: any[] = [10, 25, 50, 100];

    exportEnable: any = false;
    reminderEnable: any = false;
    goButtonEnable: any = true;
    searchTextboxEnable: any = true;
    colors: any | undefined;
    selectedIds: any[] | undefined;
    positionRequestInfo: any;

    contactObservable$: Observable<any[]> | undefined;
    contactSearchTerm = "";
    hasContactSelected = false;

    positionObservable$: Observable<any[]> | undefined;
    positionSearchTerm = "";
    hasPositionSelected = false;

    selectedRequestStatus: any = "";
    selectedPositionType: any = "";
    selectedEmployeeStatus: any = "";
    selectedExportStatus: any = "";
    selectSearchCriteria: any = {
        optionValue: "0",
        optionDescription: "",
    };

    gridData = {
        items: [],
        query: this._remotesQueryService.getHrOnboardingRequestTabQuery(),
    };

    bsModalRef?: BsModalRef;

    queryContainer: any | undefined;

    //global variables for windows
    childwindows = [];
    newWindow = null;

    contactTypeahead() {
        this.hasContactSelected = false;
        this.contactObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.contactSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                this.queryContainer.query.searchTerm.description = token; //contact name fuzzy search
                return this._contactService.searchByName(
                    encodeURIComponent(token)
                );
            })
        );
    }

    onContactSearchTermSelect($searchTerm): void {
        this.queryContainer.query.searchTerm.description =
            $searchTerm.item.fullName;
        this.queryContainer.query.searchTerm.name = $searchTerm.id;
    }

    positionTypeahead() {
        this.positionObservable$ = new Observable((observer) => {
            // Runs on every search
            observer.next(this.positionSearchTerm);
        }).pipe(mergeMap((token) => this.getPositionsAsObservable(token)));
    }

    getPositionsAsObservable(searchterm) {
        return this._positionService.autocomplete(searchterm);
    }

    onPositionSearchTermSelect($searchTerm): void {
        this.queryContainer.query.searchTerm.description =
            $searchTerm.item.description;
        this.queryContainer.query.searchTerm.name = $searchTerm.id;
    }

    searchPage(pageSize) {
        this.queryContainer.query.pageSize = pageSize;
        this.search();
    }

    search(): void {
        //if (this.queryContainer.query.searchTerm.description != " ") {
        this.log.trace("OnboardingRequest -> Search() called");
        const onboardingQuery = cloneDeep(this.queryContainer);
        this._onboardingService
            .search(onboardingQuery.query)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.log.trace(
                        "OnboardingRequest -> Search(): Data received"
                    );
                    this.exportEnable = true;
                    this.reminderEnable = false;
                    this.queryContainer.query.totalRecords =
                        data.reference.totalRecords;
                    this.gridData.items = data.reference.items;
                    this.gridData.query = this.queryContainer.query;
                    //making sure angular change detection is complete
                    setTimeout(() => {
                        this._eventBusService.emit(
                            new EmitEvent(
                                "onboard-request:search",
                                "buildOnboardingRequestGrid",
                                this.gridData
                            )
                        );
                    }, 300);
                },
                (err) => {
                    this.log.error(err);
                    this.stopLoaderAfterSearchResponseInRequestTab();
                },
                () => {
                    this.log.trace("OnboardingRequest -> removing spinner");
                    this.stopLoaderAfterSearchResponseInRequestTab();
                }
            );
        //}
    }



  stopLoaderAfterSearchResponseInRequestTab(): void {
    if (this.isFirstTimehRequestPageLoad) {
      this.ngxService.stopBackground();
      this.isFirstTimehRequestPageLoad = false;
    }
  };

    changeRequestStatus(): void {
        if (this.selectedRequestStatus?.value) {
            this.queryContainer.query.requestStatusId =
                this.selectedRequestStatus.value;
        } else {
            this.queryContainer.query.requestStatusId = null;
        }
        this.queryContainer.query.currentPage = 1;
        this.search();
    }

    changePositionType(): void {
        if (this.selectedPositionType?.value) {
            this.queryContainer.query.positionType =
                this.selectedPositionType.value;
        } else {
            this.queryContainer.query.positionType = null;
        }
        this.queryContainer.query.currentPage = 1;
        this.search();
    }

    changeEmployeeStatus(): void {
        if (this.selectedEmployeeStatus?.value) {
            this.queryContainer.query.employeeStatus =
                this.selectedEmployeeStatus.value;
        } else {
            this.queryContainer.query.employeeStatus = null;
        }
        this.queryContainer.query.currentPage = 1;
        this.search();
    }

    changeExportStatus(): void {
        if (this.selectedExportStatus?.value) {
            this.queryContainer.query.exportStatus =
                this.selectedExportStatus.value;
        } else {
            this.queryContainer.query.exportStatus = null;
        }
        this.queryContainer.query.currentPage = 1;
        this.search();
    }

    changeSearchCriteria(): void {
        if (
            this.selectSearchCriteria.optionValue &&
            this.selectSearchCriteria.optionValue != 0
        ) {
            this.queryContainer.query.searchTerm.id =
                this.selectSearchCriteria.optionValue;
            this.enableSearchButton();
        } else {
            this.queryContainer.query.searchTerm.description = "";
            this.disableSearchButton();
        }
    }

    export(): void {
        this.selectedIds.forEach((item:any)=>{
            if(typeof(item?.i9Verify) == "object")
            {
                item.i9Verify = item?.i9Verify?.name;
            }
        });
        this._reportService
            .exportRemotesOnboardingRecords(this.selectedIds)
            .subscribe({
                next: (data) => {
                    this.child.fileName = "SCORE-Remotes HR Onboarding Export_";
                    this.child.fileType = "excel";
                    this.child.data = data;
                    this.child.download();
                    this.update(this.selectedIds, "Export Records", "Y");
                    setTimeout(() => this.search(), 200);
                },
            });
    }

    disableSearchButton(): void {
        this.goButtonEnable = true;
        this.searchTextboxEnable = true;
        this.queryContainer.query.searchTerm.description = "";
    }

    enableSearchButton(): void {
        this.goButtonEnable = false;
        this.searchTextboxEnable = false;
        this.queryContainer.query.searchTerm.description = "";
    }

    sortColumn(column) {
        if (this.queryContainer.query.sortByColumn === column) {
            this.queryContainer.query.reverseSort =
                !this.queryContainer.query.reverseSort;
        } else {
            this.queryContainer.query.sortByColumn = column;
            this.queryContainer.query.reverseSort = false;
        }
        this.search();
    }

    openCancelRequestModal(registrationObject) {
        this.log.trace("Opening modal");
        const initialState: any = {
            self: this,
            modalTitle: "Warning",
            modalMessage:
                "You are about to cancel this request which will remove this request from the Registration Portal.",
            confirmText: "Confirm",
            cancelText: "Cancel",
            hideConfirm: false,
        };
        const modalOptions = {
            animated: true,
            initialState: initialState,
            keyboard: false,
        };
        this.bsModalRef = this._modalService.show(
            NbcModalConfirmation,
            modalOptions
        );
        this.bsModalRef.content.onAffirmation.subscribe((result) => {
            this.cancelRegistration(registrationObject);
        });
    }

    cancel(registrationObject): void {
        this.openCancelRequestModal(registrationObject);
    }

  cancelRegistration(registrationObject) {
    this.log.trace("OnboardingRequest -> cancel registration");
    const regData = _.cloneDeep(registrationObject);
    regData.i9Verify = regData?.i9Verify?.name;

    this._onboardingService.cancel(regData).pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.log.trace("OnboardingRequest -> cancel getting updated HrCounts");
          this._eventBusService.emit(new EmitEvent("getHrCounts", "getHrCounts", null));
          this.search();
        },
        err => {
          this.log.error("OnboardingRequest -> Cancel error:" + err);
        },
        () => {
          this.log.trace("OnboardingRequest -> Cancel removing spinner");
        }
      );
  };

  update(registrationObject, columnName, value): void {
    
    this.log.trace("OnboardingRequest -> Update called");
    const regData = _.cloneDeep(registrationObject);
    var updateColumn = columnName;
    switch (columnName) {
      // SCOR-21167 -- comment to remove Document Coloumn
      //case "Documents":
      //  columnName = "rem_docs";
      //  break;
      case "I-9 Form Filled":
        columnName = "i9_filed";
        break;
      case "SAP Position":
        columnName = "sap_pos_no";
        regData[0].i9Verify = regData[0]?.i9Verify?.name;
        break;
      case "TAF Submitted":
        columnName = "finance_approved";
        regData[0].i9Verify = regData[0]?.i9Verify?.name;
        if (value == true) {
          value = "Y";
        } else {
          value = "N";
        }
        break;
      case "Export Records":
        columnName = "exp_pos_req_sent";
        regData[0].i9Verify = regData[0]?.i9Verify?.name;
        break;
      case "I9Verify":
        columnName = "i9_verify";
        regData[0].i9Verify = value;
        break;

    }

    this._onboardingService.update(regData, columnName, value == 'N/A' ? 'NA' : value).pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          data => {
            this.log.trace("OnboardingRequest -> Update getting updated HrCounts");
            this._eventBusService.emit(new EmitEvent("getHrCounts", "getHrCounts", null));
            this.search();
          },
          err => {
            this.log.error("OnboardingRequest -> Update error:" + err);
          },
          () => {
            this.log.trace("OnboardingRequest -> Update removing spinner");
          }
        );
  };

    enableExportButton(selectedIds): void {
        this.selectedIds = selectedIds;
        this.exportEnable = false;
        //$scope.$apply();
    }

    disableExportButton(): void {
        this.selectedIds = undefined;
        this.exportEnable = true;
        //this.$apply();
    }

    bulkExport(checkboxesSelected, selectedIds): void {
        if (checkboxesSelected) {
            this.selectedIds = selectedIds;
            this.exportEnable = false;
            //this.$apply();
        } else {
            this.selectedIds = undefined;
            this.exportEnable = true;
            //this.$apply();
        }
    }

    restartSearch(): void {
        this.queryContainer = {
            query: this._remotesQueryService.getHrOnboardingRequestTabQuery(),
        };
        this.selectedRequestStatus = "";
        this.search();
        this.goButtonEnable = true;
        this.searchTextboxEnable = true;
        this.contactSearchTerm = "";
        this.positionSearchTerm = "";
        this.selectSearchCriteria = "";
        this.selectedPositionType = "";
        this.selectedEmployeeStatus = "";
        this.selectedExportStatus = "";
    }

  updateDetails(registrationObjects: any) {
    this.update(registrationObjects.items, registrationObjects.columnName, registrationObjects.value);
  };

  getI9VerifyMasterData() {
    this._onboardingService.getI9verifyMasterData()
      .subscribe(
        data => {
          this._eventBusService.emit(new EmitEvent("i9verifyMasterData", "i9verifyMasterData", data.reference));
        },
        err => {
          this.log.error(err);
        },
        () => {
          this.log.trace("Onboarding MasterData -> removing spinner");
        });
  }


    openHrOnboardingPositionModal(hrOnboardingItem) {
        const positionRequestNo = hrOnboardingItem.requestNumber;
        const crewRegNo = hrOnboardingItem.crewRegistrationNo;
        const drupalUrl = window.preload.uisettings.drupalModal;

        const url =
            drupalUrl +
            "%26field_position_request_number%3D" +
            positionRequestNo +
            "%26field_status_crew_reg_number%3D" +
            crewRegNo;
        const settings =
            "top=125,left=280,width=800,height=775,location=0,titlebar=0";

        const newWindow = window.open(url, crewRegNo, settings);

        for (let i = 0; i < this.childwindows.length; i++) {
            if (
                this.childwindows[i] &&
                this.childwindows[i] == newWindow &&
                this.childwindows[i].opened
            ) {
                this.childwindows.splice(i, 1);
                this.childwindows[i].close();
            }
        }
        this.childwindows[this.childwindows.length] = newWindow;
        if (newWindow) {
            newWindow.focus();
        }
        return false;
    }

    changeTypeaheadLoading($event) {}

    openEditPositionRequestModal(hrOnboardingItem) {
        //console.log(
        //    "successfully captured emitted event from request-grid.componet.ts"
        //);
        //console.log(hrOnboardingItem);

        const initialState: any = {
            self: this,
            hrOnboardingItem: hrOnboardingItem,
            crewRegNo: hrOnboardingItem.crewRegistrationNo,
            contactId: hrOnboardingItem.contactId,
            eventId: hrOnboardingItem.eventId,
            modalTitle: hrOnboardingItem ? "Edit Position Request" : "",
            isAdd: false,
        };
        const modalOptions = {
            animated: true,
            ignoreBackdropClick: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            EditPositionRequestComponent,
            modalOptions
        );

        this.bsModalRef.content.onSync.subscribe((result) => {
            this.search();
        });
    }

    SendReminder(hrOnboardingItem) {
        console.log(
            "successfully captured emitted event from request-grid.componet.ts"
        );
        console.log(hrOnboardingItem);

        const initialState: any = {
            self: this,
            hrOnboardingItems: hrOnboardingItem,
        };
        const modalOptions = {
            animated: true,
            ignoreBackdropClick: true,
            initialState: initialState,
            keyboard: false,
        };

        this.bsModalRef = this._modalService.show(
            SendReminderComponent,
            modalOptions
        );

        this.bsModalRef.content.onSync.subscribe((result) => {
            this.search();
        });
    }

  SendReminderBulk() {
    var selecteditems = _.filter(this.selectedIds, (o: any) => { return o.requestStatus === '6' || o.requestStatus === '5' ; });
    if (this.selectedIds && this.selectedIds.length > 0) {
      if (selecteditems.length > 0) {
        this.log.showError("One or more positions do not meet the criteria to send the reminder");
      }
      else {
        this.SendReminder(this.selectedIds);
      }
    }
  }
  

  initSubs(): void {
    this.subs.sink = this._eventBusService.on("updateDetails", (dataSource) => this.updateDetails(dataSource));
    this.subs.sink = this._eventBusService.on("updateI9ReviewDetailsFromHistroy", (dataSource) => this.updateDetails(dataSource));
    this.subs.sink = this._eventBusService.on("changeSortColumn", (dataSource) => this.sortColumn(dataSource));
    this.subs.sink = this._eventBusService.on("cancelReg", (DataSource) => this.cancel(DataSource));
    this.subs.sink = this._eventBusService.on("openHrOnboardingPositionModal", (hrOnboardingItem) => this.openHrOnboardingPositionModal(hrOnboardingItem));
    this.subs.sink = this._eventBusService.on("openEditPosReqModal", (hrOnboardingItem) => this.openEditPositionRequestModal(hrOnboardingItem));
    this.subs.sink = this._eventBusService.on("sendReminderModal", (hrOnboardingItem) => this.SendReminder(hrOnboardingItem));
    this.subs.sink = this._eventBusService.on("setSelectedIds", (selectedIds) => {
      this.selectedIds = selectedIds;
      if (this.selectedIds && this.selectedIds?.length > 0) {
        this.reminderEnable = true;
      }
      else {
        this.reminderEnable = false;
      }
    });
  };

  ngOnInit(): void {
    this.isFirstTimehRequestPageLoad = true;
    this.ngxService.startBackground();
    this.getI9VerifyMasterData();
    this.initSubs();
    this.queryContainer = {
      query: this._remotesQueryService.getHrOnboardingRequestTabQuery()
    };
    this._sharedService.getRequestPosition().subscribe((res) => {
      this.exportEnable = res
    })
    this.colors = this._onboardingColorsService.getColors();
    this.contactTypeahead();
    this.positionTypeahead();
    this.search();
  }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
