import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../Core/services/auth-guard.service";
import { ContactLayoutComponent } from "./contact-layout.component";
import { ContactComponent } from "./contact.component";
import { ContactDetailsComponent } from "./details/contact-details.component";
import { OlympicsPermissionComponent } from "./details/permissions/olympics/olympics-permissions.component";
import { RemotesPermissionComponent } from "./details/permissions/remotes/remotes-permissions.component";
import { TalentPermissionComponent } from "./details/permissions/talent/talent-permissions.component";
import { ContactProfileComponent } from "./details/profile/contact-profile.component";
import { ContactWorkHistoryComponent } from "./details/workhistory/contact-work-history.component";
import { ContactWorkScheduleComponent } from "./details/workschedule/contact-work-schedule.component";
import { ContactWorkScheduleAllComponent } from "./details/workscheduleall/contact-work-schedule-all.component";

const routes: Routes = [
    {
        path: "",
        component: ContactLayoutComponent,
        canActivate: [AuthGuardService],
        data: {
            tokens: ["contact.retrieve"],
        },
        children: [
            {
                path: "",
                component: ContactComponent,
            },
            {
                path: "Detail/:id",
                component: ContactDetailsComponent,
                canActivate: [AuthGuardService],
                data: {
                    tokens: ["contact.retrieve", "Name.Retrieve"],
                },
                children: [
                    {
                        path: "",
                        redirectTo: "profileinfo",
                        pathMatch: "full",
                    },
                    {
                        path: "profileinfo",
                        component: ContactProfileComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.retrieve",
                                "contact.hrmaster.retrieve",
                                "contact_profile_profile_info.retrieve",
                            ],
                        },
                    },
                    {
                        path: "workhistory",
                        component: ContactWorkHistoryComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.workhistory.retrieve",
                                "contact_profile_work_history.retrieve",
                            ],
                        },
                    },
                    {
                        path: "workschedule",
                        component: ContactWorkScheduleComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.workschedule.retrieve",
                                "contact.workschedule.create",
                                "contact_profile_work_schedule.retrieve",
                            ],
                        },
                    },
                    {
                        path: "workscheduleall",
                        component: ContactWorkScheduleAllComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.workschedule.retrieve",
                                "contact.workschedule.create",
                                "contact_profile_work_schedule.retrieve",
                            ],
                        },
                    },
                    {
                        path: "permissionTalent",
                        component: TalentPermissionComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.permissiontalent.retrieve",
                                "contact.permissiontalent.create",
                                "contact_profile_permission_talent.retrieve",
                            ],
                        },
                    },
                    {
                        path: "permissionRemotes",
                        component: RemotesPermissionComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.permissionremotes.retrieve",
                                "contact.permissionremotes.create",
                                "contact_profile_permission_remotes.retrieve",
                            ],
                        },
                    },
                    {
                        path: "permissionOlympics",
                        component: OlympicsPermissionComponent,
                        canActivate: [AuthGuardService],
                        data: {
                            tokens: [
                                "contact.permissionolympics.retrieve",
                                "contact.permissionolympics.create",
                                "contact_profile_permission_olympics.retrieve",
                            ],
                        },
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContactRouterModule {
    static forChild() {
        return {
            ngModule: ContactRouterModule,
            providers: [],
        };
    }
}
