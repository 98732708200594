import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { Observable, Observer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { VenueService } from "../../../../../Shared/Services/venue.service";

@Component({
    selector: "nbc-noteam-setup",
    templateUrl: "./nbc-no-team-setup.component.html",
})
export class NbcNoTeamSetupComponent implements OnInit, OnChanges {
    constructor(private _venueService: VenueService) {}

    @Input() dataModel!: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    venueSearchTerm = "";
    searchVenue$?: Observable<any[]> | undefined;

    searchVenue() {
        this.searchVenue$ = new Observable(
            (observer: Observer<string | undefined>) => {
                observer.next(this.venueSearchTerm);
            }
        ).pipe(
            switchMap((query) => {
                return this._venueService.search(encodeURIComponent(query));
            })
        );
    }

    onVenueSelect($event) {
        this.dataModel["venue"] = $event.item;
        this.modelChange.emit(this.dataModel);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];

                this.dataModel = current;
            }
        }
    }

    ngOnInit() {
        this.searchVenue();
    }
}
