<div class="modal-header">
  <h3 *ngIf="modelValue.isAdd" class="modal-title">
    Add New Job Title
  </h3>
  <h3 *ngIf="!modelValue.isAdd" class="modal-title">
    Edit Job Title
  </h3>
  <button type="button" class="btn-close" (click)="closeModal()"> </button>
</div>
<nbc-modal-alert *ngIf="showAlertMessage"></nbc-modal-alert>


<form role="form"
      #detailsForm="ngForm"
      name="addJobCodeForm"
      novalidate>
  <span us-spinner
        spinner-key="modalSpinner"
        spinner-start-active="true"></span>
</form>

<div class="modal-body">
  <div class="row m-0">
    <div class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label for="groupCode"
                 class="control-label">Job Title Code</label><br />
          <span>{{modelValue?.jobTitleDetails?.groupCode}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label for="groupName"
                 class="control-label">Job Title</label>

          <div [ngClass]="{'has-error': groupName?.invalid && submitted}">

            <input [disabled]="(modelValue.isAdd && !modelValue.isGroupCodeAvailable) || (!modelValue.isAdd && modelValue.jobTitleDetails?.isJobTitleAlreadyUsed) "
                   type="text"
                   class="form-control"
                   placeholder=""
                   id="groupName"
                   (change)="JobTitleOnChange()"
                   name="groupName"
                   [(ngModel)]="modelValue?.jobTitleDetails.groupName"
                   maxlength="60"
                   required
                   appInputRestriction="restrictChars"
                   #groupName="ngModel"
                   required>
            <span class=" control-label control-label-error"
                  *ngIf="groupName?.invalid && submitted ">Job title is required.</span>
            <span class=" control-label control-label-error"
                  *ngIf="!groupName?.invalid && submitted && isJobtileExists">Job title already exists</span>


          </div>
        </div>
      </div>
    </div>


    <div class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label for="departmentName"
                 class="control-label">Department</label>

          <!-- <div [ngClass]="{'has-error': !modelValue.isValidDept && submitted}"> -->
          <select #id="ngModel" required
                  class="form-select"
                  [(ngModel)]="modelValue.jobTitleDetails.department.id"
                  [disabled]="(modelValue.isAdd && !modelValue.isGroupCodeAvailable) || (!modelValue.isAdd && modelValue.jobTitleDetails?.isJobTitleAlreadyUsed)">
            <option [value]="0">--Select Department--</option>
            <option *ngFor="let dept of departments"
                    [value]="dept.value">
              {{dept.label}}
            </option>
          </select>
          <span class=" control-label control-label-error"
                *ngIf="!modelValue.isValidDept && submitted">Department is required.</span>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label for="groupType"
                 class="control-label">Group Type</label>
          <select id="groupTypeNo" required
                  name="groupTypeNo"
                  #groupType="ngModel"
                  class="form-select"
                  [(ngModel)]="modelValue.jobTitleDetails.groupTypeNo"
                  [disabled]="(modelValue.isAdd && !modelValue.isGroupCodeAvailable) || (!modelValue.isAdd && modelValue.jobTitleDetails?.isJobTitleAlreadyUsed)">
            <option [value]="0">--Select Group Type--</option>
            <option *ngFor="let grtype of groupTypes"
                    [ngValue]="grtype.id">
              {{grtype.description}}
            </option>
          </select>

          <span class=" control-label control-label-error"
                *ngIf="!modelValue.isValidGroupType && submitted">Group Type is required.</span>
          <!-- </div> -->
        </div>

        <span class="control-label">
          Note: If the desirable Group Type is not available in the drop down,
          please email
        </span><a href="mailto:SCOREITSupport@nbcuni.com" class="btn-link">SCOREITSupport@nbcuni.com</a>

      </div>
    </div>
    <br />
    <br />
    <div class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label for="flsaStatus"
                 class="control-label">FLSA Status</label>
          <select id="flsastatus" required
                  name="flsaStatus"
                  #groupType="ngModel"
                  class="form-select"
                  [(ngModel)]="modelValue.jobTitleDetails.flsaStatus"
                  [disabled]="(modelValue.isAdd && !modelValue.isGroupCodeAvailable) || (!modelValue.isAdd && modelValue.jobTitleDetails?.isJobTitleAlreadyUsed)">
            <option [value]="null">--Select FLSA Status--</option>
            <option *ngFor="let flsa of flsaStatuses"
                    [ngValue]="flsa.value">
              {{flsa.label}}
            </option>
          </select>

          <span class=" control-label control-label-error"
                *ngIf="!modelValue.isFlsaStatusValid && submitted">FLSA Status is required.</span>
          <!-- </div> -->
        </div>

      </div>
    </div>

    <div *ngIf="!modelValue.isAdd"
         class="col-md-12">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">STATUS</label>
          <div style="height: 35px;">
            <bSwitch name="s{{modelValue?.jobTitleDetails?.groupCode}}"
                     [switch-on-text]="onText"
                     [switch-off-text]="offText"
                     [(ngModel)]="modelValue.jobTitleDetails.active"
                     [switch-on-color]="'success'"
                     [switch-off-color]="'danger'"
                     [switch-handle-width]="30"
                     [switch-size]="'mini'">
            </bSwitch>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button type="button"
          class="btn btn-default"
          (click)="closeModal()">
    Cancel
  </button>
  <button type="button"
          *ngIf="modelValue.isAdd"
          class="btn btn-primary"
          id="btnSaveJobCode"
          (click)="disableSave || addJobCode()">
    Save
  </button>
  <button type="button"
          *ngIf="!modelValue.isAdd"
          class="btn btn-primary"
          id="btnSaveJobCode"
          (click)="disableSave || addJobCode()">
    Update
  </button>
</div>
