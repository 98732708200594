import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";
import { NbcLoggerService } from "../logger/nbc-logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SeasonService {
    constructor(
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService,
        private log: NbcLoggerService
    ) {}

    getSeasonData(seasonId: number): Observable<any> {
        this.log.trace("get season data for: " + seasonId);
        return this.http
            .get(this._apiroot.baseUrl + "seasons/" + seasonId)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    searchByEventType(eventTypeId: any, term: any): Observable<any> {
        this.log.trace(
            "calling searchByEventType" + JSON.stringify(eventTypeId)
        );
        return this.http
            .get(
                this._apiroot.baseUrl +
                    "seasons/" +
                    eventTypeId +
                    "/search?term=" +
                    term
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    searchByName(term: any): Observable<any> {
        this.log.trace("calling searchByName" + JSON.stringify(term));
        return this.http
            .get(this._apiroot.baseUrl + "seasons/search/?term=" + term)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    autocomplete(searchterm: any): Observable<any> {
        this.log.trace("calling searchByName" + JSON.stringify(searchterm));
        // using github public api to get users by name
        return this.http
            .get<any>(
                this._apiroot.baseUrl + "seasons/search/?term=" + searchterm
            )
            .pipe(map((data: any) => (data && data.reference) || []));
    }

    search(searchObj: any) {
        this.log.trace("calling search for object" + JSON.stringify(searchObj));
        return this.http.post(
            this._apiroot.baseUrl + "seasons/search/",
            searchObj
        );
    }

    addCreateSeasonData(data: any) {
        this.log.trace("Adding season: " + JSON.stringify(data));
        return this.http.post(this._apiroot.baseUrl + "seasons", data);
    }

    getSeasonSummaryData(seasonId: any): Observable<any> {
        this.log.trace(
            "Getting season summary data: " + JSON.stringify(seasonId)
        );
        return this.http
            .get(this._apiroot.baseUrl + "seasons/" + seasonId + "/dashboard")
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }
}
