import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../../logger/nbc-logger.service';
import { Observable, throwError } from 'rxjs';
import { IEventQuery } from './../remotesquery.service';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';


@Injectable()
export class OnboardingService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }

  getRemotesHrCount(): Observable<any> {
    this.log.trace("get hr Remotes count");
    return this.http.get(this._apiroot.baseUrl + "remotes/HrOnboarding/headerTemplate", {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  };


  search(hrOnboardingQuery): Observable<any> {
    return this.http.post(this._apiroot.baseUrl + "remotes/HrOnboarding/RequestTab/search", hrOnboardingQuery, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  };


  historySearch(hrOnboardingQuery): Observable<any> {
    return this.http.post(this._apiroot.baseUrl + "remotes/HrOnboarding/HistoryTab/search", hrOnboardingQuery, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  };

  cancel(registrationObject): Observable<any> {
    return this.http.post(this._apiroot.baseUrl + "remotes/HrOnboarding/RequestTab/cancel", registrationObject);
  };

  getI9verifyMasterData(): Observable<any> {
    return this.http.get(this._apiroot.baseUrl + "remotes/onboarding/event/getI9VerifyMasterData");
  };

  update(registrationObject, columnName, value): Observable<any> {
    var endpoint = this._apiroot.baseUrl + "remotes/HrOnboarding/RequestTab/update/" + columnName + "/" + value;
    return this.http.post(endpoint, registrationObject, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  };
};

