import { Component } from "@angular/core";

@Component({
  selector: "nbc-unauthorized",
  templateUrl: "./nbc-unauthorized.component.html"
})

export class NbcUnauthorized {
  constructor() { }

} 
