import { Injectable } from "@angular/core";
import { NbcLoggerService } from "../logger/nbc-logger.service";
declare global {
    interface Window {
        preload: any;
    }
}
window.preload = window.preload || {};

@Injectable({
    providedIn: "root",
})
export class RemotesQueryService {
    constructor(private log: NbcLoggerService) {}

    TriStateAnswer(
        afDescription: any,
        neDescription: any,
        affirmativeBool: boolean,
        negativeBool: boolean
    ) {
        const affirmative = affirmativeBool || false;
        const negative = negativeBool || false;
        const aDescription = afDescription || "";
        const nDescription = neDescription || "";
    }

    getEventQuery(): any {
        this.log.debug("get event query shell object");
        const dnow = new Date();

        const query = <IEventQuery>{};
        (query.pageSize = 10),
            (query.currentPage = 1),
            (query.crewStartDate = new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate()
            )),
            (query.crewEndDate = new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate() + 90
            )),
            (query.venue = null),
            (query.team = null),
            (query.eventName = ""),
            (query.eventTypeId = ""),
            (query.networkId = 0),
            (query.isLevel1 = false),
            (query.isLevel2 = false),
            (query.isLevel3 = false),
            (query.isPlanning = true),
            (query.isExecuting = true),
            (query.isClosing = true),
            (query.isClosed = false),
            (query.isArchived = false),
            (query.isCancelled = false),
            (query.isInactive = false),
            (query.isMyEvent = false),
            (query.managerId = 0),
            (query.city = ""),
            (query.state = ""),
            (query.sortBy = "StartDate"),
            (query.userRoles = window.preload.user.userRoleNames),
            (query.tier = ""),
            (query.timesheetStatus = ""),
            (query.broadcastType = "");

        return query;
    }

    getCrewItemQuery(): any {
        return {
            eventId: 0,
            department: null,
            employeeTypeId: null,
            pageSize: 100,
            currentPage: 1,
            sortBy: "",
            status: null,
            allPositions: true,
            positionRequest: "",
            locationNo: null,
            crewPositionNo: null,
        };
    }

    getContactQuery(): any {
        const isactive = this.TriStateAnswer(
            "Active",
            "Inactive",
            false,
            false
        );
        return {
            currentPage: 1,
            pageSize: 10,
            isActive: isactive,
            id: null,
            firstName: "",
            middleName: "",
            lastName: "",
            positions: [],
            skills: [],
            eventType: {
                id: 0,
                name: "",
            },
            seasonId: 0,
            season: {
                id: 0,
                name: "",
            },
            eventId: 0,
            event: {
                id: 0,
                description: "",
            },
            venue: null,
            email: "",
            phone: "",
            sso: "",
            perNer: "",
            union: {
                id: 0,
                description: "",
            },
            distance: "1",
            location: "1",
            countryCode: "",
            city: "",
            state: "",
            dob: "",
            lastFourSsn: "",
            workedWith: 0,
            sortBy: "LastName",
            workedAtVenue: false,
            crewedPositions: [],
            searchVenueId: 0,
            employeeType: {
                id: 0,
                description: "",
            },
            searchLocation: "",
            searchLocations: [],
            radius: {
                id: 0,
                description: "",
            },
        };
    }

    getPayrollTimesheetSubmittedQuery(): any {
        const dnow = new Date();
        return {
            pageSize: 10,
            currentPage: 1,
            eventId: "",
            eventTypeIds: [],
            networkIds: [],
            contactId: "0",
            startDate: new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate() - 90
            ),
            endDate: new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate()
            ),
            sortBy: "recent",
            status: "All",
            userRoles: window.preload.user.userRoleNames,
        };
    }

    getPayrollUpcomingTimesheetQuery(): any {
        const dnow = new Date();
        return {
            endDate: new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate() + 7
            ),
            startDate: new Date(
                dnow.getFullYear(),
                dnow.getMonth(),
                dnow.getDate() - 7
            ),
            sortBy: "recent",
            eventId: "",
            eventTypeId: "-1",
            networkId: "-1",
            contactId: "0",
            pageSize: 10,
            currentPage: 1,
            userRoles: window.preload.user.userRoleNames,
        };
    }
    getTierConfigChangeQuery(): any {
        return {
            pageSize: 10,
            currentPage: 1,
            venueId: 0,
            networkId: 0,
            eventTypeId: 0,
        };
    }

    getVenueChangeImpactQuery() {
        return {
            pageSize: 10,
            currentPage: 1,
            venueId: 0,
        };
    }

    getVenueSetupQuery(currentPage: any, pageSize: any, totalRecords: any) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 100,
            sortByColumn: "name",
            reverseSort: false,
            venue: "",
            city: "",
            exactTerm: false,
        };
    }

    getPayrollQuery(): any {
        return {
            eventId: 0,
            timeCardDate: new Date(),
            pageSize: 25,
            sortBy: "SortOrder",
            currentPage: 1,
            departments: "",
            positionType: null,
            employeeType: [],
            contactType: 0,
            allContacts: {
                id: 0,
                description: "",
            },
            isTravel: true,
            isNonTravel: true,
            positionCategory: "",
        };
    }

    getCrewItemStatuses() {
        this.log.trace("getting crew Statuses");
        return [
            { id: "O", description: "Open" },
            { id: "R", description: "Assigned" },
            { id: "C", description: "Confirmed" },
        ];
    }

    getHrOnboardingRequestTabQuery(
        currentPage = 1,
        pageSize = 25,
        totalRecords = 0
    ) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 0,
            reverseSort: false,
            searchTerm: { id: null, description: null, name: null },
            sortByColumn: null,
            requestStatusId: null,
            positionType: null,
            employeeStatus: null,
            myRequestChecked: false,
            exportStatus: null,
        };
    }

    getHrOnboardingHistoryTabQuery(
        currentPage = 1,
        pageSize = 25,
        totalRecords = 0
    ) {
        return {
            currentPage: currentPage || 1,
            pageSize: pageSize || 25,
            totalRecords: totalRecords || 0,
            reverseSort: false,
            searchTerm: { id: null, description: null, name: null },
            sortByColumn: null,
            requestStatusId: null,
            positionType: null,
            employeeStatus: null,
            myRequestChecked: false,
        };
    }
}

export interface IEventQuery {
    pageSize: number;
    currentPage: number;
    crewStartDate: Date;
    crewEndDate: Date;
    venue: any | null;
    team: any | null;
    eventName: string;
    eventTypeId: string;
    networkId: 0;
    isLevel1: boolean;
    isLevel2: boolean;
    isLevel3: boolean;
    isPlanning: boolean;
    isExecuting: boolean;
    isClosing: boolean;
    isClosed: boolean;
    isArchived: boolean;
    isCancelled: boolean;
    isInactive: boolean;
    isMyEvent: boolean;
    managerId: any;
    city: string;
    state: string;
    sortBy: string;
    userRoles: any[];
    tier: any;
    timesheetStatus: string;
    broadcastType: string;
    seasonId: any;
}
