import * as _ from "lodash";

export class PermissionUtility {
    public static PermissionUtilities(): any {
        return {
            checkTokens: PermissionUtility.checkTokens,
            hasAccess: PermissionUtility.hasAccess,
            hasAccessToFlock: PermissionUtility.hasAccessToFlock,
            hasAccessToDepartment: PermissionUtility.hasAccessToDepartment,
            isOwnFlock: PermissionUtility.isOwnFlock,
            isOwnDepartment: PermissionUtility.isOwnDepartment,
            getValidAndRemovedItems: PermissionUtility.getValidAndRemovedItems,
        };
    }

    /**
     * Check Tokens
     * @memberof PermissionsUtilities
     * @param {currentUser} user
     * @param {String[]} tokens
     * @param {allFeatures} featuresClone
     * @returns {Boolean}
     */
    private static checkTokens(user: any, tokens: any, featuresClone: any) {
        if (!user && !tokens && !featuresClone) {
            return {
                hasToken: false,
                isForceToken: false,
                isHiringManagerRestrictedToken: false,
                isShepherdRestrictedToken: false,
            };
        }

        let index = 0,
            token,
            tokensLength = tokens ? tokens.length : 0,
            hasToken = false,
            departmentRestrictedRoleIndex,
            shepherdRestrictedRoleIndex,
            isForceToken = false,
            isHiringManagerRestrictedToken = false,
            isShepherdRestrictedToken = false;

        const departmentRestrictedRole = _.filter(user.userRoles, {
            restriction: "DEPARTMENT",
        });
        const shepherdRestrictedRole = _.find(user.userRoles, {
            restriction: "SHEPHERD",
        });

        for (index; index < tokensLength; index++) {
            token = tokens[index].trim().toLowerCase();

            if (user.allFeatures[token]) {
                hasToken = true;

                if (!_.isEmpty(departmentRestrictedRole)) {
                    const roleNames = _.map(
                        departmentRestrictedRole,
                        "description"
                    );
                    const rolesWithToken = featuresClone[token];
                    const departmentRestrictedRoleMatchFound = _.intersection(
                        roleNames,
                        rolesWithToken
                    );
                    if (!_.isEmpty(departmentRestrictedRoleMatchFound)) {
                        for (
                            let i = 0;
                            i < departmentRestrictedRole.length;
                            i++
                        ) {
                            departmentRestrictedRoleIndex = featuresClone[
                                token
                            ].indexOf(roleNames[i]);
                            if (departmentRestrictedRoleIndex !== -1) {
                                featuresClone[token].splice(
                                    departmentRestrictedRoleIndex,
                                    1
                                );
                            }
                        }
                        isHiringManagerRestrictedToken = true;
                    }
                }

                if (shepherdRestrictedRole) {
                    shepherdRestrictedRoleIndex = featuresClone[token].indexOf(
                        shepherdRestrictedRole.description
                    );
                    if (shepherdRestrictedRoleIndex !== -1) {
                        featuresClone[token].splice(
                            shepherdRestrictedRoleIndex,
                            1
                        );
                        isShepherdRestrictedToken = true;
                    }
                }

                if (featuresClone[token].length !== 0) {
                    isForceToken = featuresClone[token].length !== 0;
                    break;
                }
            } else {
                if (!user.isSuperUser) {
                    //log.warn("User SSO:" + user.id + " does not access for the token: " + token + ".");
                }
            }
        }

        return {
            hasToken: hasToken,
            isForceToken: isForceToken,
            isHiringManagerRestrictedToken: isHiringManagerRestrictedToken,
            isShepherdRestrictedToken: isShepherdRestrictedToken,
        };
    }

    /**
     * Is Own Flock
     * @memberof PermissionsUtilities
     * @param {Int} flockId
     * @param {Int} flockCompareId
     * @returns {Boolean}
     */
    private static isOwnFlock(flockId: any, flockCompareId: any) {
        return flockId === flockCompareId;
    }

    /**
     * Is Own Department
     * @memberof PermissionsUtilities
     * @param {Int[]} departmentIds
     * @param {Int} deptCompareId
     * @returns {Boolean}
     */
    private static isOwnDepartment(departmentIds: any, deptCompareId: any) {
        return departmentIds.indexOf(deptCompareId) > -1;
    }

    /**
     * Has Access to Flock
     * @memberof PermissionsUtilities
     * @param {Boolean} isShepherd
     * @param {Boolean} isInFlock
     * @param {Boolean} isForce
     * @returns {Boolean}
     */
    private static hasAccessToFlock(
        isShepherd: any,
        isInFlock: any,
        isForce: any,
        isShepherdToken: any
    ) {
        return isShepherd && (isInFlock || isForce) && isShepherdToken;
    }

    /**
     * Has Access to Department
     * @memberof PermissionsUtilities
     * @param {Boolean} isHiringManager
     * @param {Boolean} isInDept
     * @param {Boolean} isForce
     * @returns {Boolean}
     */
    private static hasAccessToDepartment(
        isHiringManager: any,
        isInDept: any,
        isForce: any,
        isHMToken: any
    ) {
        return isHiringManager && (isInDept || isForce) && isHMToken;
    }

    /**
     * Is Hiring Manager with No Restrictions
     * @memberof PermissionsUtilities
     * @param {Boolean} isHiringManager
     * @param {Int} deptId
     * @returns {Boolean}
     */
    private static isHiringManagerWithNoRestrictions(
        user: any,
        deptId: any,
        isHMToken: any
    ) {
        return user.isHiringManager && !deptId && isHMToken;
    }

    /**
     * Is Shepherd with No Restrictions
     * @memberof PermissionsUtilities
     * @param {Boolean} isShepherd
     * @param {Int} contactId
     * @returns {Boolean}
     */
    private static isShepherdWithNoRestrictions(
        user: any,
        contactId: any,
        isShepherdToken: any
    ) {
        return user.isShepherd && !contactId && isShepherdToken;
    }

    /**
     * Has Normal Access
     * @memberof PermissionsUtilities
     * @param {Boolean} isForceToken
     * @returns {Boolean}
     */
    private static hasNormalAccess(isForceToken: any) {
        return isForceToken;
    }

    /**
     * Has Access
     * @memberof PermissionsUtilities
     * @param {currentUser} user
     * @param {allFeaturesClone} featureClone
     * @param {String[]} tokens
     * @param {Int} deptCompareId
     * @param {Int} flockCompareId
     * @returns {Boolean}
     */
    private static hasAccess(
        user: any,
        featureClone: any,
        tokens: any,
        deptCompareId: any,
        flockCompareId: any,
        mustAuthDept: any
    ) {
        const isFlock = PermissionUtility.isOwnFlock(
                user.contactId,
                flockCompareId
            ),
            isDepartment = PermissionUtility.isOwnDepartment(
                user.departmentIds,
                deptCompareId
            ),
            userPermissions = PermissionUtility.checkTokens(
                user,
                tokens,
                featureClone
            );
        let deptValidated = true;

        if (user.isSuperUser) {
            return true;
        }
        let isHRSU = false;
        if (mustAuthDept) {
            user.userRoles.forEach(function (role: any) {
                if (
                    role.description &&
                    (role.description.toUpperCase() === "OLYMPIC_HR_SU" ||
                        role.description.toUpperCase() === "HR_SU")
                ) {
                    isHRSU = true;
                }
            });

            if (isHRSU && !isDepartment) {
                deptValidated = false;
            }
        }

        if (
            (PermissionUtility.hasNormalAccess(userPermissions.isForceToken) &&
                deptValidated) ||
            PermissionUtility.hasAccessToDepartment(
                user.isHiringManager,
                isDepartment,
                userPermissions.isForceToken,
                userPermissions.isHiringManagerRestrictedToken
            ) ||
            PermissionUtility.isHiringManagerWithNoRestrictions(
                user,
                deptCompareId,
                userPermissions.isHiringManagerRestrictedToken
            ) ||
            PermissionUtility.isShepherdWithNoRestrictions(
                user,
                flockCompareId,
                userPermissions.isShepherdRestrictedToken
            ) ||
            PermissionUtility.hasAccessToFlock(
                user.isShepherd,
                isFlock,
                userPermissions.isForceToken,
                userPermissions.isShepherdRestrictedToken
            )
        ) {
            return userPermissions.hasToken;
        }

        return false;
    }

    /**
     * Validate Selected Items in Array
     * @memberof PermissionsUtilities
     * @param {Items} items
     * @param {currentUser} user
     * @param {elementTokens} accessTokensForProperties
     * @returns {Object}
     */
    private static getValidAndRemovedItems(
        items: any,
        user: any,
        accessTokensForProperties: any,
        deptPath: any,
        contactPath: any
    ) {
        let deptId: any, contactId: any;

        return items.reduce(
            (acc: any, currentItem: any) => {
                deptId = _.get(currentItem, deptPath) || null;
                contactId = _.get(currentItem, contactPath) || null;

                _.every(
                    accessTokensForProperties,
                    (accessTokensForProperty) => {
                        return PermissionUtility.hasAccess(
                            user,
                            _.cloneDeep(user.allFeatures),
                            accessTokensForProperty,
                            deptId,
                            contactId,
                            null
                        );
                    }
                );
                const hasAccessToUpdateAllProperties =
                    accessTokensForProperties.every(
                        (accessTokensForProperty: any) => {}
                    );

                if (hasAccessToUpdateAllProperties) {
                    acc.validItems.push(currentItem);
                } else {
                    acc.removedItems.push(currentItem);
                }

                return acc;
            },
            { validItems: [], removedItems: [] }
        );
    }
}
