import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
    AfterViewInit,
    ViewChildren,
    QueryList,
} from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DropResult } from "ngx-smooth-dnd";
import { Subscription, fromEvent } from "rxjs";
import {
    EmitEvent,
    EventBusService,
} from "src/app/Core/services/event-bus.service";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { SharedDataService } from "src/app/Shared/sharedData.service";
import { SubSink } from "subsink";
import { NbcModalConfirmation } from "src/app/Shared/components/confirmation/nbc-modal-confirmation.component";
import { forEach } from "lodash";
import { PopoverDirective } from "ngx-bootstrap/popover";

@Component({
    selector: "crew-grid",
    templateUrl: "./crew-grid.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewGridComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private _sharedData: SharedDataService,
        private _eventBus: EventBusService,
        private _subModalService: BsModalService,
        private log: NbcLoggerService,
        public cd: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        this.maxDatePositionRequestedDate = new Date();
        // this.minDatePositionRequestedDate.setDate(this.minDatePositionRequestedDate.getDate() - 1);
    }

    @ViewChild("scrollableDiv") scrollableDiv: ElementRef | undefined;
    @ViewChild("dragDropContainer") dragDropContainer: ElementRef | undefined;

    @ViewChild("topScroll") topScroll!: ElementRef<HTMLDivElement>;
    @ViewChild("topchild") topChild!: ElementRef<HTMLDivElement>;
    @ViewChild("scrollableDiv") mainScroll!: ElementRef<HTMLDivElement>;

    @ViewChild(PopoverDirective) activePopover!: PopoverDirective;

    private sub = new SubSink();
    private resizeSubscription!: Subscription;
    changeDetectionInterval: any;
    bsEditTierRate?: BsModalRef;
    bsModalRef?: BsModalRef;
    additionalLocations!: any[];
    isFullScreenMode = false;
    gridConfigData: any[] | undefined;
    load = false;
    dataLoaded = false;
    range = "range-0-7";
    moment: any = moment;
    disableRightWorkSchedulePagging = false;
    gridHeaderConfig: any;
    eventData: any;
    modifiedListItems: any[] = [];
    allEmployeeTypes: any[] = [];
    listItemsQuery: any = {};
    allTieredPositions: any[] = [];
    listItems: any;
    maxDatePositionRequestedDate: Date;
    //holding DnD vallues from flyout
    list2: any[] = [];
    isTieredEvent = false;
    canPositionRequest = false;
    workSchedulePopover!: any;
    positionFlag: any = {
        noHrFlag: false,
        posConflict: false,
    };
    isGridAvailable = false;
    subs = new Subscription();
    paging: any = {
        startRange: 0,
        endRange: 6,
        currentPage: 1,
        lastPage: 1,
    };

    minimumWage: any;
    hasMinimumWageCheck = false;
    cachedPositionListItems: any; //SCOR-21415 : min wage implementation : to refresh the grid row  or grid to previous data
    displayBulkUpdate = false;

    marginLeft = 765;
    fixedmarginLeft = 765;
    prevScrollPos = window.pageXOffset;
    isPopoverHidden = false;

    getListItemById(id: any) {
        return _.find(this.modifiedListItems, (item) => item.id == id);
    }

    getRateCards(eventPosition: any) {
        let rateCards: any[] = [];
        if (
            eventPosition.employeeType &&
            eventPosition.employeeType.id > 0 &&
            eventPosition.employeeType.description.toLowerCase() !== "vendor"
        ) {
            if (this.isTieredEvent && eventPosition.category === "BTL") {
                const filterd = _.find(
                    this.allTieredPositions,
                    (e) => e.id === eventPosition.employeeType.id
                );
                rateCards = filterd ? filterd.rateCards : [];
            } else {
                const filterd = _.find(
                    this.allEmployeeTypes,
                    (e) => e.id === eventPosition.employeeType.id
                );
                rateCards = filterd ? filterd.rateCards : [];
            }
        }
        return rateCards;
    }

    onEditPositionRequestModal(id: any) {
        const positionListItem = this.getListItemById(id);
        //if (this.isTieredEvent && positionListItem.eventPosition.category === "BTL") {
        //  if (positionListItem.eventPosition.rateCode) {
        //    let rateCard = _.find(this.allTieredPositions, "billing_code", positionListItem.eventPosition.rateCode.id);
        //      positionListItem.eventPosition.rateCode.description = rateCard?.desc;
        //  }
        //}
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:EditPosition",
                "openEditPositionRequestModal",
                positionListItem
            )
        );
    }

    openHrOnboardingModal(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:openHrOnboardingModal",
                "openHrOnboardingModal",
                this.getListItemById(id)
            )
        );
    }

    onEditPosition(event: any, id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onEditPosition",
                "openPositionModal",
                this.getListItemById(id)
            )
        );
    }

    onDeletePosition(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onDeletePosition",
                "openDeletePositionModal",
                this.getListItemById(id)
            )
        );
    }

    onDeleteContact(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onDeleteContact",
                "openRemoveCrewModal",
                this.getListItemById(id)
            )
        );
    }

    onConfirmContact(id: any) {
        const listitem = this.getListItemById(id);
        //ignore minimum wage check for flat rates
        //get rate value associated with Ratecard : MinWage Story --additional check to avoid user from confirming position from any scenario if min wage criteria is not met

        const title = "Minimum Wage",
            message = "The entered rate is less than the minimum wage.";

        let selectedRateCode = "";
        if (listitem.eventPosition) {
            if (
                listitem.eventPosition.rateCode &&
                listitem.eventPosition.rateCode != null
            ) {
                selectedRateCode = listitem.eventPosition.rateCode.id;
            }
        }

        let rateValue;
        if (selectedRateCode != "") {
            const rateCard = _.filter(
                listitem.eventPosition.employeeType.rateCards,
                (rateCard) => {
                    return rateCard.id == selectedRateCode;
                }
            );
            if (rateCard.length > 0) {
                rateValue = rateCard[0].billingRate;
            }
        } else {
            rateValue = null;
        }

        if (
            listitem.eventPosition.rateCode &&
            listitem.eventPosition.rateCode != null &&
            listitem.eventPosition.rateCode.id != "FLAT DAY" &&
            listitem.customRate &&
            listitem.customRate < this.minimumWage
        ) {
            this.openMinimumWarningModal(title, message);
        } else if (
            listitem.eventPosition.rateCode &&
            listitem.eventPosition.rateCode != null &&
            listitem.eventPosition.rateCode.id != "FLAT DAY" &&
            listitem.eventPosition.configuredRate &&
            listitem.eventPosition.configuredRate < this.minimumWage
        ) {
            this.openMinimumWarningModal(title, message);
        } else if (
            listitem.eventPosition.rateCode &&
            listitem.eventPosition.rateCode != null &&
            listitem.eventPosition.rateCode.id != "FLAT DAY" &&
            rateValue != null &&
            rateValue < this.minimumWage
        ) {
            this.openMinimumWarningModal(title, message);
        } else {
            this._eventBus.emit(
                new EmitEvent(
                    "CrewGrid:confirmContact",
                    "confirmContact",
                    this.getListItemById(id)
                )
            );
        }
    }

    onUnconfirmContact(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onUnconfirmContact",
                "openUnconfirmModal",
                this.getListItemById(id)
            )
        );
    }

    openConflictModal(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:openConflictModal",
                "openConflictModal",
                this.getListItemById(id)
            )
        );
    }

    /**
     * On Contact Verified
     * @listens click
     * @fires CovidCommunicationSent
     * @private
     */
    onCovidCommunicationSent(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onCovidCommunicationSent",
                "covidCommunicationSent",
                this.getListItemById(id)
            )
        );
    }

    /**
     * On Contact Verified
     * @listens click
     * @fires ContactVerified
     * @private
     */
    onContactVerified(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onContactVerified",
                "contactVerified",
                this.getListItemById(id)
            )
        );
    }

    /**
     * On Contact Verified
     * @listens click
     * @fires CovidReplyRecd
     * @private
     */
    onCovidReplyRecd(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onCovidReplyRecd",
                "covidReplyRecd",
                this.getListItemById(id)
            )
        );
    }

    /**
     * On I9 Docs Verified
     * @listens click
     * @fires i9DocsVerified
     * @private
     */
    onI9DocsVerified(id: any) {
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid:onI9DocsVerified",
                "i9DocsVerified",
                this.getListItemById(id)
            )
        );
    }

    onBackupItemChanged(data: any) {
        //                        scope.$emit("assignBackupContact", listItem, contactObj, contactType, null, "New");
        const listItem = this.getListItemById(data.id);

        const backupItem = {
            action: data.action,
            listItem: listItem,
            backup: data.contact,
            contactType: "secondary",
            serviceMethodName: null,
            backupContactAction: data.action,
        };
        if (data.action == "New")
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:onBackupItemChanged",
                    "assignBackupContact",
                    backupItem
                )
            );

        if (data.action == "Delete")
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:onBackupItemChanged",
                    "removeBackupContact",
                    backupItem
                )
            );
        _.forEach(this.modifiedListItems, (item) => {
            if (item.id == data.id) {
                item.crewPositionBackupCount--;
            }
        });
        // this.listItem?.crewPositionBackupCount--;
        //scope.$emit("removeBackupContact", listItem, {}, $(this).data("contactType"), null, "Delete", posIds[1]);
    }

    onAssignPrimaryBackupContact(data: any) {
        const listItem = this.getListItemById(data.id);
        const backupItem = {
            action: data.action,
            listItem: listItem,
            backup: data.contact,
            contactType: "alternate",
            serviceMethodName: "updateEventPositionContact",
            backupContactAction: "Update",
        };

        if (data.action === "Update") {
            //                scope.$emit("assignBackupContact", posListItem, {}, "alternate", "updateEventPositionContact", "Update", posIds[1]);

            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:onBackupItemChanged",
                    "assignBackuptoPrimary",
                    backupItem
                )
            );
        }
    }

    onTierrateTextboxClick(id: any) {
        this.log.trace("openEditTierRatecardModal");
        const listItem = this.getListItemById(id);
        const data = {
            listItem: listItem,
            allTieredPositions: this.allTieredPositions,
            munimumWage: this.minimumWage,
        };
        this._eventBus.emit(
            new EmitEvent(
                "crew-page:onTierrateTextboxClick",
                "openEditTierRatecardModal",
                data
            )
        );
    }

    selectEmployeeType(id: any) {
        const listItem = this.getListItemById(id);

        if (this.isTieredEvent && listItem.eventPosition.category === "BTL") {
            listItem.customRate = undefined;
            listItem.customRateExeedsBy = undefined;
        }
        listItem.asyncDataSave.employeeTypeState = "inprogress";
        //                scope.$emit("saveEventPositionEmployeeType", listItem);
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:selectEmployeeType",
                "saveEventPositionEmployeeType",
                listItem
            )
        );
    }

    selectAdditionalLocation(id: any) {
        const listItem = this.getListItemById(id);

        if (
            listItem.eventPosition?.status?.id.toLowerCase() == "c" &&
            listItem.additionalLocation == null
        ) {
            this.log.showError(
                "Location cannot be cleared from a confirmed position."
            );
            listItem.additionalLocation = listItem["additionalLocationCopy"];
            return;
        }

        listItem.asyncDataSave.additionalLocationState = "inprogress";
        //                scope.$emit("saveEventPositionEmployeeType", listItem);
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:selectAdditionLocation",
                "saveEventPositionAdditionalLocation",
                listItem
            )
        );

        if (listItem.additionalLocation) {
            listItem["additionalLocationClass"] = "";
            if (listItem.additionalLocation.locationName) {
                if (listItem.additionalLocation.locationName === "@Home")
                    listItem["additionalLocationClass"] = "home";
                else if (
                    listItem.additionalLocation.locationName === "Stamford"
                )
                    listItem["additionalLocationClass"] = "stamford";
                else if (listItem.additionalLocation.locationName === "Onsite")
                    listItem["additionalLocationClass"] = "";
                else if (listItem.additionalLocation.locationName !== "") {
                    listItem["additionalLocationClass"] = "others";
                } else {
                    listItem["additionalLocationClass"] = "";
                }
            } else {
                listItem["additionalLocationClass"] = "";
            }
            listItem["additionalLocationCopy"] = listItem.additionalLocation;
        } else {
            listItem["additionalLocationClass"] = "";
        }
    }

    onContactSelect(data: any) {
        const listItem = this.getListItemById(data.id);
        const params = {
            listItem: listItem,
            contact: data.contact,
            contactType: "primary",
        };
        if (data.contact.flaggedByHR)
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:onContactSelect",
                    "invalidContactAlert",
                    null
                )
            );
        else
            this._eventBus.emit(
                new EmitEvent(
                    "cerw-grid:onContactSelect",
                    "assignContact",
                    params
                )
            );
    }

    assignContactDnD(event: CdkDragDrop<string[]>, itemId: any) {
        const data = {
            id: itemId,
            contact: event.item.data,
        };
        this.onContactSelect(data);
    }

    /**
     * Select Rate Code
     * @private
     * @listens change
     * @fires saveEventPositionRateCode
     */
    selectRateCode(model: any, id: any) {
        let preventRateSave = false;
        const listItem = this.getListItemById(id);
        this.hasMinimumWageCheck = false;
        if (model) {
            if (model.id == "FLAT DAY" || model.id == "00") {
                this.hasMinimumWageCheck = false;
                //SCOR-21587
                if (listItem.customRate && listItem.customRate > 0) {
                    listItem.customRate = null;
                }
            } else {
                this.hasMinimumWageCheck = true;
            }
        }

        const rateCode = {
            name: "",
            id: "",
            billing_code: "",
        };
        if (this.isTieredEvent && listItem.eventPosition.category === "BTL") {
            if (model) {
                rateCode.id = model?.billing_code;
                rateCode.name = model?.desc;
                rateCode.billing_code = model?.billing_code;
                listItem.eventPosition.rateCode = rateCode;
            } else listItem.eventPosition.rateCode = null;
        } else {
            //non-tiered events
            //console.log("2.6");
            //console.log(this.cachedPositionListItems[1].eventPosition.rateCode)
            listItem.eventPosition.rateCode = model;
            //console.log("2.7");
            //console.log(this.cachedPositionListItems[1].eventPosition.rateCode)
        }
        if (listItem.eventPosition.rateCode) {
            if (listItem.eventPosition.rateCode) {
                listItem.eventPosition.configuredRate =
                    listItem.eventPosition.rateCode?.billingRate;
            }

            if (
                this.isTieredEvent &&
                listItem.eventPosition.category === "BTL" &&
                listItem.eventPosition?.rateCode
            ) {
                listItem.customRate = null;
                listItem.customRateExeedsBy = null;
                const rateCard = _.find(this.allTieredPositions, {
                    billing_code: listItem.eventPosition.rateCode.id,
                });
                if (!_.isEmpty(rateCard)) {
                    //XXX Add check for !null position and description !== ""
                    if (
                        rateCard.position &&
                        rateCard.position.description !== ""
                    ) {
                        listItem.eventPosition.posTierId =
                            rateCard.positionTiers[0].currentRates.posTierId;
                        listItem.posTierId =
                            rateCard.positionTiers[0].currentRates.posTierId;
                        listItem.eventPosition.jobTitleDetail =
                            listItem.eventPosition.position.description;
                        listItem.eventPosition.position = rateCard.position;
                        listItem.eventPosition.department =
                            rateCard.position.department;
                        listItem.eventPosition.category =
                            rateCard.position.category;
                    } else {
                        listItem.eventPosition.jobTitleDetail = "";
                    }

                    //rateCode.id = rateCard.billing_code;
                    //rateCode.name = rateCard.desc;
                    //if (listItem.eventPosition.existingRateCard.startsWith("TR_")) {
                    //    listItem.eventPosition.jobTitleDetail = "";
                    //}
                }
                //listItem.eventPosition.rateCode = rateCode;

                listItem.eventPosition.existingRateCard =
                    listItem.eventPosition.rateCode.id;
            }
        } else {
            listItem.eventPosition.rateCode = null;
            listItem.customRate = null;
            listItem.customRateExeedsBy = null;
            listItem.eventPosition.posTierId = null;
            listItem.posTierId = null;
            if (listItem.eventPosition.existingRateCard.startsWith("TR_")) {
                listItem.eventPosition.jobTitleDetail = "";
            }
        }

        if (this.hasMinimumWageCheck) {
            const wage = this.minimumWage;
            if (model.billingRate < this.minimumWage) {
                //var index = _.findIndex(this.modifiedListItems, { id: listItem.id });
                //this.modifiedListItems[index].eventPosition.rateCode = this.cachedPositionListItems[index].eventPosition.rateCode;
                //this.modifiedListItems[index].customRate = this.cachedPositionListItems[index].customRate;

                //setTimeout(() => {
                //  this.updateGridRow(this.modifiedListItems[index]);
                //  this.cd.detectChanges();
                //}, 2000);

                preventRateSave = true;

                const title = "Minimum Wage",
                    message = "The entered rate is less than the minimum wage.";
                this.openMinimumWarningModal(title, message);
            }
        }
        if (!preventRateSave) {
            listItem.asyncDataSave.rateCodeState = "inprogress";
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:selectRateCode",
                    "saveEventPositionRateCode",
                    listItem
                )
            );
        }
    }

    openMinimumWarningModal(title: any, message: any) {
        this.log.trace("openMinimumWarningModal");

        title = title || "Minimum Wage";
        message = message || "The entered rate is less than the minimum wage.";

        const initialState: any = {
            modalTitle: title,
            modalMessage: message,
            confirmText: null,
            cancelText: "Close",
            hideConfirm: true,
        };

        const modalOptions = {
            animated: true,
            backdrop: true,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: initialState,
        };

        this.bsModalRef = this._subModalService.show(
            NbcModalConfirmation,
            modalOptions
        );
    }

    selectTravelType(id: any) {
        const listItem = this.getListItemById(id);
        const data = {
            listItem: listItem,
            travelType: listItem.eventPosition.outBoundLocalTravel,
            isInBound: true,
        };
        this._eventBus.emit(
            new EmitEvent("crew-grid:selectTravelType", "setTravelType", data)
        );

        // scope.$emit("setTravelType", getListItemById(this), $(this).val(), true);
    }

    selectInBoundTravelType(id: any) {
        const listItem = this.getListItemById(id);
        const data = {
            listItem: listItem,
            travelType: listItem.eventPosition.inBoundLocalTravel,
            isInBound: true,
        };
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:selectInBoundTravelType",
                "setTravelType",
                data
            )
        );
    }

    selectOutBoundTravelType(id: any) {
        const listItem = this.getListItemById(id);
        const data = {
            listItem: listItem,
            travelType: listItem.eventPosition.outBoundLocalTravel,
            isInBound: false,
        };
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:selectOutBoundTravelType",
                "setTravelType",
                data
            )
        );
    }

    onSelectAirportCode(selected: any) {
        const listItem = this.getListItemById(selected.listId);
        const leg = selected.journeyLeg;
        const code = selected.airpotcode;

        if (leg === "inBoundArr") {
            listItem.eventPosition.inBoundArrAirportCode = code;
            listItem.eventPosition.tempInBoundArrAirportCode = code;
        }
        if (leg === "inBoundDep") {
            listItem.eventPosition.inBoundDepAirportCode = code;
            listItem.eventPosition.tempInBoundDepAirportCode = code;
        }
        if (leg === "outBoundArr") {
            listItem.eventPosition.outBoundArrAirportCode = code;
            listItem.eventPosition.tempOutBoundArrAirportCode = code;
        }
        if (leg === "outBoundDep") {
            listItem.eventPosition.outBoundDepAirportCode = code;
            listItem.eventPosition.tempOutBoundDepAirportCode = code;
        }

        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:onSelectAirportCode",
                "saveEventPositionTravel",
                listItem
            )
        );
    }

    onTierRateChange(rateCode: any, id: any) {
        const listItem = this.getListItemById(id);
        //if (this.isTieredEvent && listItem?.eventPosition?.rateCode?.hasOwnProperty("id"))
        //  listItem.eventPosition.rateCode.id = listItem?.eventPosition?.rateCode.billing_code;
    }

    toggleInBoundTravelButton(id: any) {
        const listItem = this.getListItemById(id);
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:toggleInBoundTravelButton",
                "toggleInBoundTravelButton",
                listItem
            )
        );
    }

    toggleOutBoundTravelButton(id: any) {
        const listItem = this.getListItemById(id);
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:toggleOutBoundTravelButton",
                "toggleOutBoundTravelButton",
                listItem
            )
        );
    }

    selectAllRecords() {
        this.gridHeaderConfig.selectAllSelected =
            !this.gridHeaderConfig.selectAllSelected;
        const isCheckboxset = this.gridHeaderConfig.selectAllSelected;
        _.forEach(this.modifiedListItems, (item) => {
            item.selected = isCheckboxset;
        });

        if (this.gridHeaderConfig.selectAllSelected) {
            const data = {
                listItems: this.modifiedListItems,
                showBulkUpdate: true,
            };
            this._eventBus.emit(
                new EmitEvent(
                    "CrewGrid:selectAllRecords",
                    "selectAllRecords",
                    data
                )
            );
        } else {
            this._eventBus.emit(
                new EmitEvent(
                    "CrewGrid:selectPosition",
                    "closeBulkUpdateMenu",
                    null
                )
            );
        }
    }

    OnBulkUpdateClosed() {
        this.gridHeaderConfig.selectAllSelected = false;
        _.forEach(this.modifiedListItems, (item) => {
            item.selected = false;
        });
    }

    selectPosition() {
        const selectedItems: any[] = this.getSelectedListItems();

        if (selectedItems && selectedItems.length > 0) {
            //for (let item = 0; item < selectedItems.length; item++) {
            //  if (this.isTieredEvent && selectedItems[item].eventPosition.category === "BTL") {
            //    if (selectedItems[item].eventPosition.rateCode) {
            //      let rateCard = _.find(this.allTieredPositions, "billing_code", selectedItems[item].eventPosition.rateCode.id);
            //      selectedItems[item].eventPosition.rateCode.description = rateCard?.desc;
            //    }
            //  }
            //}

            if (selectedItems.length === this.modifiedListItems.length)
                this.gridHeaderConfig.selectAllSelected = true;
            else this.gridHeaderConfig.selectAllSelected = false;

            this._eventBus.emit(
                new EmitEvent(
                    "CrewGrid:selectPosition",
                    "openBulkUpdateMenu",
                    selectedItems
                )
            );
        } else {
            this.gridHeaderConfig.selectAllSelected = false;
            this._eventBus.emit(
                new EmitEvent(
                    "CrewGrid:selectPosition",
                    "closeBulkUpdateMenu",
                    null
                )
            );
        }
    }

    getSelectedListItems() {
        return _.filter(this.modifiedListItems, "selected");
    }

    resetCalenderCurrentDate(value: Date, id: any) {
        const listItem = this.getListItemById(id);
        const str = moment(value).format("MM/DD/YYYY");
        if (listItem.confirmDate != value) {
            listItem.confirmDate = str;
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:resetCalenderCurrentDate",
                    "savePositionApprovedDate",
                    listItem
                )
            );
        }
    }

    Arraymove(arr: any, fromIndex: any, toIndex: any) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    setSort(id: any, sortOrder: any) {
        const index = _.findIndex(this.modifiedListItems, { id: id });

        if (index > -1 && sortOrder !== undefined) {
            const originalSortOrder = this.modifiedListItems[index].sortOrder;
            const itemBeingMoved = _.cloneDeep(this.modifiedListItems[index]);
            itemBeingMoved.sortOrder = sortOrder;
            const cloned = _.cloneDeep(this.modifiedListItems);
            this.modifiedListItems = [];
            this.Arraymove(cloned, originalSortOrder - 1, sortOrder - 1);

            this.modifiedListItems = cloned;
            const data = {
                listItems: [itemBeingMoved],
                redrawGrid: null,
                refreshGrid: true,
            };
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:setSort",
                    "updateSortOrderForCrewItems",
                    data
                )
            );
        }
    }
    onBlurSort($event: any, id: any, orginalSortOrder: any) {
        const newSortOrder = parseInt($event.target.value, 10);
        if (orginalSortOrder !== newSortOrder) {
            this.setSort(id, newSortOrder);
        }
    }

    sendAirTravelNotification(id: any) {
        const listItem = this.getListItemById(id);
        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:sendAirTravelNotification",
                "sendAirTravelNotification",
                listItem
            )
        );
    }

    workActivitiesPaging(action: any) {
        if (action === "prev") {
            this.paging.currentPage = this.paging.currentPage - 1;
        } else {
            this.paging.currentPage = this.paging.currentPage + 1;
        }
    }

    setWorkSchedulePopover(schedule: any, listItemId: any, index: any) {
        this.workSchedulePopover = schedule;
        this.workSchedulePopover.showPopover = true;
        const ind = {
            index: index,
            parentId: listItemId,
        };
        _.assign(this.workSchedulePopover, ind);
        schedule.showPopover = true;
    }

    modifyHeader() {
        const crewWorkStartDate = this.moment(this.eventData.crewWorkStartDate),
            crewWorkEndDate = this.moment(this.eventData.crewWorkEndDate),
            tempDate = crewWorkStartDate;
        let scheduleDates: any,
            index = 0,
            diff = crewWorkEndDate.diff(crewWorkStartDate, "days");

        if (diff > RemotesConstants.eventConstants.scheduleLength) {
            diff = RemotesConstants.eventConstants.scheduleLength;
        }
        scheduleDates = [];
        for (index; index <= diff; index++) {
            scheduleDates.push({
                date: tempDate.format(),
            });
            tempDate.add(1, "days");
        }

        this.gridHeaderConfig = {
            scheduleDates: scheduleDates,
            visibleWorkScheduleDateRange:
                RemotesConstants.eventConstants.visibleWorkScheduleDateRange,
            disableRightWorkSchedulePagging:
                this.disableRightWorkSchedulePagging,
            paging: this.paging,
            selectAllSelected: false,
        };
        this.associateScheduleColumnClass(scheduleDates);
        this.log.trace(
            "****** gridHeaderConfig " +
                this.gridHeaderConfig.visibleWorkScheduleDateRange
        );
    }

    buildGridBody(disableGridSort: any) {
        if (disableGridSort) {
            _.sortBy(this.modifiedListItems, [
                function (o) {
                    return o.sortOrder;
                },
            ]);
            //disable sort
        } else {
            //enable
        }
    }

    bulkUpdateGridRows(listItems: any) {
        //    this.destroyGrid(this.eventData)
        //setTimeout(() => {

        //  this.buildGrid(this.gridConfigData);
        //}, 100);
        if (listItems) {
            for (let i = 0; i < listItems.length; i++) {
                const existingItem = this.getListItemById(listItems[i].id);
                // let modified = this.modifyData([existingItem])[0];
                listItems[i].selected = existingItem.selected;
                setTimeout(() => this.updateGridRow(listItems[i]), 1000);
            }
        }
        //this.updateSortOrderForCrewItemsSuccessful(true);
        //this.setCheckState(listItems);
    }

    setCheckState(selectedPositionListItems: any) {
        for (let i = 0; i < selectedPositionListItems.length; i++) {
            const id = selectedPositionListItems[i].id;
            const item = this.getListItemById(id);
            item.selected = true;

            if (
                this.modifiedListItems.length ===
                selectedPositionListItems.length
            ) {
                this.gridHeaderConfig.selectAllSelected = true;
            }
        }
    }

    updateSortOrderForCrewItemsSuccessful(redrawGrid: any) {
        if (redrawGrid) {
            this.buildGrid(false);
        } else {
            _.sortBy(this.modifiedListItems, [
                function (o) {
                    return o.sortOrder;
                },
            ]);
        }
    }
    /**
     *  function to count the number of decimals provided by the user
     * @param value
     * @returns
     */
    countDecimals(value: any) {
        if (Math.floor(value) !== value && String(value).indexOf(".") > -1) {
            if (String(value).indexOf(".") === 0) {
                return value.toString().split(".")[0].length || 0;
            } else {
                return value.toString().split(".")[1].length || 0;
            }
        }

        return 0;
    }

    fixDecimals(customRate: any) {
        const maxLength = 8;
        const maxDecimals = 3;
        let formattedCustomRate = customRate;
        const hasDecimal = customRate.toString().indexOf(".");
        //dont have decimal
        if (hasDecimal <= -1) {
            let availableLength = 8 - (customRate.toString().length + 1);
            if (availableLength > 0) {
                availableLength = availableLength > 3 ? 3 : availableLength;
                formattedCustomRate =
                    parseFloat(customRate).toFixed(availableLength);
            }
        } else {
            //has decimals
            const decVal = this.countDecimals(customRate);
            const nonDecVal = customRate.toString().length - decVal;
            const availableLength = 8 - customRate.toString().length;
            const canbeFixed =
                availableLength - (maxDecimals - decVal) > -1 ? true : false;

            if (canbeFixed) {
                formattedCustomRate = parseFloat(customRate).toFixed(3);
            }
        }
        return formattedCustomRate;
    }

    validateCustomRate(event: any, id: any) {
        const listItem = this.getListItemById(id);
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if (
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 37 ||
            event.keyCode == 39 ||
            event.keyCode == 46 ||
            event.keyCode == 190 ||
            event.keyCode == 110
        ) {
            //accepting valid characters
        } else {
            event.preventDefault();
        }

        //if ($(this).val().indexOf('.') !== -1 && event.keyCode == 190) { //SCOR-11961 not accepting (.) dot from num pad
        if (
            (listItem.customRate && listItem.customRate.split(".").length) >
                1 &&
            (event.keyCode == 190 || event.keyCode == 110)
        ) {
            event.preventDefault();
        } else {
            if (
                listItem.customRate &&
                listItem.customRate.length >= 3 &&
                event.which !== 8 &&
                !(listItem.customRate.indexOf(".") > -1)
            ) {
                if (event.keyCode == 190 || event.keyCode == 110) {
                } else {
                    let strCustom = listItem.customRate.toString();
                    strCustom =
                        strCustom.substr(0, 3) + "." + strCustom.substr(4);
                    listItem.customRate = strCustom;
                }
            }
        }

        //scor-21415
    }

    validateCustomFlatRate(event: any, id: any) {
        const listItem = this.getListItemById(id);
        if (event.shiftKey == true) {
            event.preventDefault();
        }
        if (
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            event.keyCode == 37 ||
            event.keyCode == 39 ||
            event.keyCode == 46 ||
            event.keyCode == 190 ||
            event.keyCode == 110
        ) {
            //accepting valid characters
            const decimals = this.countDecimals(listItem.customRate);
            if (decimals <= 3) {
                if (listItem.customRate) {
                    const newVal = listItem.customRate
                        ? listItem.customRate.toString()
                        : "";
                    const final =
                        newVal.indexOf(".") >= 0
                            ? newVal.substr(0, newVal.indexOf(".")) +
                              newVal.substr(newVal.indexOf("."), 4)
                            : newVal;

                    listItem.customRate = final;
                }
            } else {
                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }

        //if ($(this).val().indexOf('.') !== -1 && event.keyCode == 190) { //SCOR-11961 not accepting (.) dot from num pad
        if (
            (listItem.customRate &&
                listItem.customRate.toString().split(".").length) > 1 &&
            (event.keyCode == 190 || event.keyCode == 110)
        ) {
            event.preventDefault();
        }
    }

    setCustomFlatRate(id: any) {
        const listItem = this.getListItemById(id);
        const validRatecode = /^[0-9.]+$/;

        if (validRatecode.test(listItem.customRate)) {
            _.merge(listItem, { customRate: listItem.customRate });
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:setCustomFlatRate",
                    "saveEventPositionRateCode",
                    listItem
                )
            );
        } else {
            this._eventBus.emit(
                new EmitEvent(
                    "crew-grid:setCustomFlatRate",
                    "saveEventPositionRateCode",
                    listItem
                )
            );
        }
    }

    setCustomRate(id: any) {
        let preventRateSave = false;
        const listItem = this.getListItemById(id);
        const validRatecode = /^[0-9.]+$/;
        if (!listItem) return;

        if (
            listItem.customRate != "" &&
            listItem.customRate < this.minimumWage
        ) {
            preventRateSave = true;
            this.displayBulkUpdate = true;
            //var index = _.findIndex(this.modifiedListItems, { id: listItem.id });
            //this.modifiedListItems[index].eventPosition.rateCode = this.cachedPositionListItems[index].eventPosition.rateCode;
            //this.modifiedListItems[index].customRate = this.cachedPositionListItems[index].customRate;

            //setTimeout(() => {
            //  this.updateGridRow(this.modifiedListItems[index]);
            //  this.cd.detectChanges();
            //}, 2000);

            const title = "Minimum Wage",
                message = "The entered rate is less than the minimum wage.";
            this.openMinimumWarningModal(title, message);

            //this.log.error("Entered custom rate is less than the minimum wage for this location : " + JSON.stringify(listItem));
            //this.log.showError("Entered custom rate is less than the minimum wage for this location.");
        }

        if (!preventRateSave) {
            if (listItem.customRate <= 250 && listItem) {
                if (validRatecode.test(listItem.customRate)) {
                    _.merge(listItem, { customRate: listItem.customRate });
                    listItem.asyncDataSave.rateCodeState = "inprogress";
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-grid:setCustomFlatRate",
                            "saveEventPositionRateCode",
                            listItem
                        )
                    );
                } else {
                    if (!listItem.hasOwnProperty(listItem.customRate)) {
                        _.merge(listItem, { customRate: "" });
                    }
                    listItem.asyncDataSave.rateCodeState = "inprogress";
                    this._eventBus.emit(
                        new EmitEvent(
                            "crew-grid:setCustomFlatRate",
                            "saveEventPositionRateCode",
                            listItem
                        )
                    );
                }
            } else {
                this._eventBus.emit(
                    new EmitEvent(
                        "crew-grid:setCustomFlatRate",
                        "showCustomRateValidationMsg",
                        listItem
                    )
                );
            }
        }
    }

    setCustomRateFocus(listItem: any) {
        const input = "rate" + listItem.id.toString();
        const ele = document.getElementById(input);
        document.getElementById(input)?.focus();
    }

    leaveComment(id: any) {
        const listItem = this.getListItemById(id);
        ////patch
        //if (this.isTieredEvent && listItem?.eventPosition?.rateCode?.hasOwnProperty("id"))
        //  listItem.eventPosition.rateCode.id = listItem?.eventPosition?.rateCode?.billing_code;

        this._eventBus.emit(
            new EmitEvent("crew-grid:leaveComment", "leaveComment", listItem)
        );
    }

    modifyData(listItems: any) {
        if (!listItems) return;

        for (let index = 0; index < listItems.length; index++) {
            const data = listItems[index];
            if (data.customRate) {
                if (
                    data.eventPosition.rateCode &&
                    data.eventPosition.rateCode.id?.toString().toLowerCase() ===
                        "flat day"
                )
                    data.customRate = this.fixDecimals(data.customRate);
                else data.customRate = parseFloat(data.customRate).toFixed(3);
            }
            if (data.primaryContact && data.primaryContact.contactVerified) {
                if (data.primaryContact.contactVerified.updatedBy != "") {
                    data.primaryContact.contactVerified.modifiedDate = moment
                        .utc(data.primaryContact.contactVerified.updatedDate)
                        .format("MM/DD/YYYY");
                }
                data.primaryContact.contactVerified.color = data.primaryContact
                    .contactVerified.fieldBool
                    ? "Green"
                    : "Red";
            }
            data["hasContactFocus"] = false;
            if (
                data.primaryContact &&
                data.primaryContact.covidCommunicationSent
            ) {
                if (
                    data.primaryContact.covidCommunicationSent.updatedBy != ""
                ) {
                    data.primaryContact.covidCommunicationSent.modifiedDate =
                        moment
                            .utc(
                                data.primaryContact.covidCommunicationSent
                                    .updatedDate
                            )
                            .format("MM/DD/YYYY");
                }
                data.primaryContact.covidCommunicationSent.color = data
                    .primaryContact.covidCommunicationSent.fieldBool
                    ? "Green"
                    : "Red";
            }

            if (data.primaryContact && data.primaryContact.i9DocsVerified) {
                if (data.primaryContact.i9DocsVerified.updatedBy != "") {
                    data.primaryContact.i9DocsVerified.modifiedDate = moment
                        .utc(data.primaryContact.i9DocsVerified.updatedDate)
                        .format("MM/DD/YYYY");
                }
                data.primaryContact.i9DocsVerified.color = data.primaryContact
                    .i9DocsVerified.fieldBool
                    ? "Green"
                    : "Red";
            }

            if (data.primaryContact && data.primaryContact.covidReplyRecd) {
                if (data.primaryContact.covidReplyRecd.updatedBy != "") {
                    data.primaryContact.covidReplyRecd.modifiedDate = moment
                        .utc(data.primaryContact.covidReplyRecd.updatedDate)
                        .format("MM/DD/YYYY");
                }
                data.primaryContact.covidReplyRecd.color = data.primaryContact
                    .covidReplyRecd.fieldBool
                    ? "Green"
                    : "Red";
            }

            data.confirmDate = data.confirmDate
                ? moment(data.confirmDate).local().toDate()
                : null;

            // associate record selection boolean property
            if (!_.has(listItems[index], "selected"))
                _.assign(listItems[index], { selected: false });
            //associating billing_code property to ratecode object for all event position rate codes. Only when position is BTL and event is tiered, it will be used.
            // It is a hack to manag billing_code property and ratecode.id property in a dropdown
            const rateCodeId =
                listItems[index].eventPosition &&
                listItems[index].eventPosition.rateCode
                    ? listItems[index].eventPosition.rateCode.id
                    : null;
            if (listItems[index].eventPosition) {
                _.assign(listItems[index].eventPosition.rateCode, {
                    billing_code: rateCodeId,
                });
                _.assign(listItems[index].eventPosition.rateCode, {
                    name: rateCodeId,
                });

                //attaching UI specific property for child component to show
                _.each(data.eventPosition.workScheduleDates, (schedule) => {
                    _.assign(schedule, { showPopover: false });
                });

                //associate ratecodes to employeetype
                if (
                    listItems[index].eventPosition.employeeType &&
                    listItems[index].eventPosition.employeeType.id > 0
                )
                    _.assign(listItems[index].eventPosition.employeeType, {
                        rateCards: this.getRateCards(data.eventPosition),
                    });

                //work schedule classes
                this.associateScheduleColumnClass(
                    listItems[index].eventPosition.workScheduleDates
                );
            }

            if (!_.has(listItems[index], "showBackupContacts"))
                _.assign(listItems[index], { showBackupContacts: false });

            if (!_.has(listItems[index], "isVisible"))
                _.assign(listItems[index], { isVisible: true });
            // data.confirmDate = moment.utc(data.confirmDate).format("MM/DD/YYYY");
            data.isTieredEvent = this.isTieredEvent;
            data.canPositionRequest = this.canPositionRequest;
            data.isVisible = true;
            if (data.primaryContact) {
                const middelName = _.has(data.primaryContact, "middleName")
                    ? data.primaryContact.middleName
                    : _.has(data.primaryContact, "contactMiddleName")
                      ? data.primaryContact.contactMiddleName
                      : "";
                const phone =
                    data.primaryContact.phone1 &&
                    data.primaryContact.phone1.phoneNumber
                        ? data.primaryContact.phone1.phoneNumber
                        : "";
                const sso =
                    data.primaryContact.sso && data.primaryContact.sso > 0
                        ? data.primaryContact.sso
                        : "";
                const city = data.primaryContact.city
                    ? data.primaryContact.city
                    : "";
                const state = data.primaryContact.state
                    ? data.primaryContact.state
                    : "";
                const notes = data.primaryContact.contactNameNote
                    ? data.primaryContact.contactNameNote.replace(
                          /"/g,
                          "&quot;"
                      )
                    : "";
                const contactAddressTitle =
                    "<div>" +
                    data.primaryContact.firstName +
                    " " +
                    middelName +
                    " " +
                    data.primaryContact.lastName +
                    "," +
                    " " +
                    notes +
                    "<div> email: " +
                    data.primaryContact.email +
                    "</div>" +
                    "<div> Phone: " +
                    phone +
                    " sso: " +
                    sso +
                    "</div>" +
                    "<div> city: " +
                    city +
                    " state: " +
                    state +
                    "</div></div>";

                _.assign(data, { contactAddressTitle: contactAddressTitle });
            }

            _.assign(data, {
                asyncDataSave: {
                    employeeTypeState: "",
                    rateCodeState: "",
                    customRateState: "",
                    additionalLocationState: "",
                },
            });
            data.uniqueTrackById = this.getUniqueTrackByid(index);

            this.attachValidationAttributes(listItems[index]);

            //console.log(listItems[index].rules);

            //the rate value is available in different objects for various scenarios. Using this new variable to store the rate value to determine cell highlight logic
            data.billingRateForMinWageHighlight = null;

            if (
                data.eventPosition.employeeType &&
                data.eventPosition.employeeType.rateCode &&
                data.eventPosition.rateCode.id?.toString().toLowerCase() !==
                    "flat day"
            ) {
                //case 1:if (data.customRate > 0)
                data.billingRateForMinWageHighlight =
                    data.customRate > 0 ? data.customRate : null;
                //case 2:else if (data.eventPosition.rateCode.billingRate > 0) {
                data.billingRateForMinWageHighlight =
                    data.eventPosition.rateCode.billingRate > 0
                        ? data.eventPosition.rateCode.billingRate
                        : null;
            } else if (
                data.eventPosition.rateCode &&
                data.eventPosition.rateCode.id?.toString().toLowerCase() !==
                    "flat day" &&
                data.eventPosition.rateCode.billingRate > 0
            ) {
                data.billingRateForMinWageHighlight =
                    data.eventPosition.rateCode.billingRate;
            } else if (
                data.eventPosition.rateCode != null &&
                data.eventPosition.employeeType &&
                data.eventPosition.rateCode.id?.toString().toLowerCase() !==
                    "flat day"
            ) {
                data.eventPosition.employeeType?.rateCards?.forEach(function (
                    rate: any
                ) {
                    if (
                        data.eventPosition.rateCode.id?.toString() ==
                            rate.id?.toString() &&
                        rate.billingRate > 0
                    ) {
                        data.billingRateForMinWageHighlight = rate.billingRate;
                        return;
                    }
                });
            } else {
                data.billingRateForMinWageHighlight = null;
            }

            if (data.additionalLocation) {
                data["additionalLocationClass"] = "";
                if (data.additionalLocation.locationName) {
                    if (data.additionalLocation.locationName === "@Home")
                        data["additionalLocationClass"] = "home";
                    else if (
                        data.additionalLocation.locationName === "Stamford"
                    )
                        data["additionalLocationClass"] = "stamford";
                    else if (data.additionalLocation.locationName === "Onsite")
                        data["additionalLocationClass"] = "";
                    else if (data.additionalLocation.locationName !== "") {
                        data["additionalLocationClass"] = "others";
                    } else {
                        data["additionalLocationClass"] = "";
                    }
                } else {
                    data["additionalLocationClass"] = "";
                }
                data["additionalLocationCopy"] = data.additionalLocation;
            } else {
                data["additionalLocationClass"] = "";
            }
        }
        return listItems;
    }

    getUniqueTrackByid(increment?: any) {
        let stamp = new Date().getTime();
        if (increment) stamp = stamp + increment;

        return stamp;
    }

    associateScheduleColumnClass(collection: any) {
        for (let index = 0; index < collection.length; index++) {
            const obj = collection[index];
            // let className = "hidden";
            let className = "";
            let page = 0;
            if (index < 7) {
                className = " range-0-7";
                page = 1;
            } else if (index > 6 && index <= 13) {
                className = " range-7-14";
                page = 2;
            } else if (index > 13 && index <= 20) {
                className = " range-14-21";
                page = 3;
            } else if (index > 20 && index <= 27) {
                className = " range-21-28";
                page = 4;
            } else if (index > 27 && index <= 34) {
                className = " range-28-35";
                page = 5;
            } else if (index > 34 && index <= 41) {
                className = " range-35-42";
                page = 6;
            } else if (index > 41 && index <= 48) {
                className = " range-42-49";
                page = 7;
            }

            this.paging.lastPage = page;
            const scheduleClass = {
                className: className,
                page: page,
            };
            _.assign(obj, scheduleClass);
        }
    }

    attachValidationAttributes(listItem: any) {
        const rules: any = {
            showOnboardingStatus: false,
            canPositionRequest: false,
            showHrClass: false,
            isConflictExists: false,
            positionStatusConfirmed: false,
            requestPositionOnboarding: false,
            isTierPosition: false,
            isCustomRateApplicable: false,
        };
        // if (listItem.primaryContact && listItem.primaryContact.onboardingStatus && listItem.primaryContact.onboardingStatus==="1" && listItem.eventPosition.status.description === 'Confirmed') {
        if (
            listItem.primaryContact &&
            listItem.primaryContact?.onboardingStatus &&
            listItem.eventPosition?.status?.description === "Confirmed"
        ) {
            rules.showOnboardingStatus = true;
        }
        if (
            listItem.primaryContact &&
            !listItem.primaryContact.isHrVerified &&
            listItem.primaryContact.onboardingStatus != "1" &&
            listItem.primaryContact.onboardingStatus != "4" &&
            listItem.primaryContact.onboardingStatus != "" &&
            listItem.eventPosition.employeeType &&
            listItem.eventPosition.employeeType?.description !== "BUR" &&
            listItem.eventPosition.employeeType?.description !== "CHI" &&
            listItem.eventPosition.employeeType?.description !== "NY" &&
            listItem.eventPosition.employeeType?.description !== "Stamford" &&
            listItem.eventPosition.employeeType?.description !== "Staff" &&
            listItem.eventPosition.employeeType?.description !== "Vendor"
        ) {
            rules.canPositionRequest = true;
        }
        if (
            listItem.primaryContact &&
            listItem.primaryContact.canShowHrIcon &&
            listItem.eventPosition.status.description === "Confirmed"
        ) {
            rules.showNoHrClass = true;
        }
        if (
            listItem.primaryContact &&
            listItem.eventPosition.isConflictExists
        ) {
            rules.isConflictExists = true;
        }

        if (listItem.eventPosition) {
            if (listItem.eventPosition.status.description === "Confirmed") {
                rules.positionStatusConfirmed = true;
            }

            if (
                listItem.primaryContact &&
                listItem.primaryContact.isNonVendor &&
                !listItem.primaryContact.isHrVerified &&
                listItem.eventPosition.status.description.toLowerCase() ===
                    "confirmed" &&
                listItem.eventPosition.position.groupType.description !==
                    "Talent" &&
                listItem.canPositionRequest
            ) {
                rules.requestPositionOnboarding = true;
            }

            if (
                listItem.eventPosition.rateCode &&
                (listItem.eventPosition.rateCode?.id === "00" ||
                    listItem.eventPosition.rateCode?.id === "FLAT DAY")
            ) {
                rules.isCustomRateApplicable = true;
            }
            if (
                this.isTieredEvent &&
                listItem.eventPosition.category === "BTL"
            ) {
                rules.isTierPosition = true;
            }
        }

        const validation = {
            rules: rules,
        };
        _.assign(listItem, validation);
    }

    buildGrid(gridData: any) {
        if (!_.isEmpty(gridData)) {
            this.gridConfigData = gridData;
            this.eventData = gridData.eventData;
            this.minimumWage = this.eventData.minimumWage;
            this.isTieredEvent =
                !_.isEmpty(this.eventData) &&
                this.eventData.tier &&
                this.eventData.tier > 0
                    ? true
                    : false;
            this.canPositionRequest =
                !_.isEmpty(this.eventData) && this.eventData.canPositionRequest
                    ? true
                    : false;
            this.modifyHeader();
            this.allEmployeeTypes = gridData.employeeTypes;
            this.listItemsQuery = gridData.crewItemQuery;
            this.allTieredPositions = gridData.tieredPositions;
            this.additionalLocations = gridData.additionalLocations;
            this.modifiedListItems = this.modifyData(
                gridData.positionListItems
            );
            if (gridData.selectedPositionsBulkUpdate.length > 0) {
                this.setCheckState(gridData.selectedPositionsBulkUpdate);
            }
            this.cachedPositionListItems = _.cloneDeep(this.modifiedListItems);
            // this.destroyGrid(this.eventData)
            this.buildGridBody(gridData.disableGridSort);
            this._eventBus.emit(
                new EmitEvent(
                    "crewGrid:BuildGrid",
                    "workScheduleDaysInitialized",
                    this.gridHeaderConfig
                )
            );

            const timeout =
                gridData?.positionListItems?.length > 0
                    ? gridData?.positionListItems.length / 70 + 1
                    : 1;
            setTimeout(() => {
                this.cd.detectChanges();
                this.setChangeDetectionInterval();
                this.updateHeight();
                this.onScrollHide("grid-col-scrollable", this.prevScrollPos);
            }, timeout);
        }
    }

    updateGridRow(listItem: any) {
        const modifiedItem = this.modifyData([listItem]);
        const index = _.findIndex(this.modifiedListItems, { id: listItem.id });
        if (index > -1) {
            this.modifiedListItems.splice(index, 1, modifiedItem[0]);
            //this.cachedPositionListItems.splice(index, 1, modifiedItem[0]);
        } else {
            this.modifiedListItems.push(modifiedItem[0]);
            //this.cachedPositionListItems.push(modifiedItem[0]);
        }
        //this.reRenderRowByItem(modifiedItem);
        setTimeout(() => {
            this.onScrollHide("grid-col-scrollable", this.prevScrollPos);
        }, 100);
    }

    reportAsyncStatus(data: any) {
        const index = _.findIndex(this.modifiedListItems, {
            id: data.listItem.id,
        });

        switch (data.operation.toLowerCase()) {
            case "employeetype":
                this.modifiedListItems[index].asyncDataSave.employeeTypeState =
                    data.listItem.asyncDataSave.employeeTypeState;
                break;
            case "ratecode":
                this.modifiedListItems[index].asyncDataSave.rateCodeState =
                    data.listItem.asyncDataSave.rateCodeState;
                break;
            case "additionallocation":
                this.modifiedListItems[
                    index
                ].asyncDataSave.additionalLocationState =
                    data.listItem.asyncDataSave.additionalLocationState;
                break;
        }
    }

    getRelatedCrewPositions(listitems: any) {
        let crewPositions: any = [];
        if (!listitems || listitems.length == 0) return;
        if (listitems.length === this.modifiedListItems.length) {
            crewPositions = _.map(this.modifiedListItems, "id");
        } else {
            _.each(listitems, (selectedItem) => {
                _.each(this.modifiedListItems, (listItem) => {
                    if (
                        listItem.primaryContact?.id ===
                        selectedItem.primaryContact?.id
                    )
                        crewPositions.push(listItem.id);
                });
            });
        }

        this._eventBus.emit(
            new EmitEvent(
                "crew-grid:getRelatedCrewPositions",
                "getCrewPositionsByIds",
                _.uniq(crewPositions)
            )
        );
    }

    destroyGrid(data: any) {
        //this.isGridAvailable = false;
        if (data) {
            setTimeout(() => {
                this.buildGrid(data);
                //this.isGridAvailable = true;
            }, 100);
        }
    }

    reRenderRowByItem(listItem: any) {
        if (!listItem) return;
        listItem.isVisible = false;
        setTimeout(() => {
            listItem.isVisible = true;
        }, 500);
    }

    reRenderRowById(listId: any) {
        if (!listId) return;
        const listItem = this.getListItemById(listId);
        listItem.isVisible = false;
        setTimeout(() => {
            listItem.isVisible = true;
        }, 500);
    }

    saveWorkScheduleDay(output: any) {
        let plannedWorkActivities: any = [];

        const listItem = _.find(this.modifiedListItems, {
            id: parseInt(output.parentId, 10),
        });
        const clonedListItem = _.cloneDeep(listItem);
        plannedWorkActivities = _.filter(output.workActivities, (o) => {
            return o.isPlanned == true;
        });

        listItem.eventPosition.workScheduleDates[output.day].workActivities =
            output.workActivities;
        listItem.eventPosition.workScheduleDates[
            output.day
        ].plannedWorkActivities = plannedWorkActivities;
        listItem.eventPosition.workScheduleDates[
            output.day
        ].plannedActivityCount = plannedWorkActivities.length;

        //TODO:AngularUpgrade: Save Activities
        // scope.$emit("saveWorkScheduleDay", listItem, output.day, initialListItem);
        const data = {
            listItem: listItem,
            day: output.day,
            initialListItem: clonedListItem,
        };
        this._eventBus.emit(
            new EmitEvent(
                "CrewGrid-SaveWorkSchedule",
                "saveWorkScheduleDay",
                data
            )
        );
    }

    InitialiseEventHandlers() {
        //Suraj Patil --> intentionally calling destroy grid as it will call internally to build the new grid structure.
        this.sub.sink = this._eventBus.on("buildGrid", (data: any) => {
            this.destroyGrid(data);
        });

        this.sub.sink = this._eventBus.on("updateGridRow", (data: any) =>
            this.updateGridRow(data)
        );

        this.sub.sink = this._eventBus.on("onBackupItemChanged", (data: any) =>
            this.onBackupItemChanged(data)
        );

        this.sub.sink = this._eventBus.on(
            "onAssignPrimaryBackupContact",
            (data: any) => this.onAssignPrimaryBackupContact(data)
        );

        this.sub.sink = this._eventBus.on("onSelectAirportCode", (data: any) =>
            this.onSelectAirportCode(data)
        );

        this.sub.sink = this._eventBus.on(
            "updateSortOrderForCrewItemsSuccessful",
            (redraw: any) => this.updateSortOrderForCrewItemsSuccessful(redraw)
        );

        this.sub.sink = this._eventBus.on(
            "setCheckState",
            (selectedPositionListItems: any) =>
                this.setCheckState(selectedPositionListItems)
        );

        this.sub.sink = this._eventBus.on("destroyGrid", (data: any) =>
            this.destroyGrid(data)
        );

        this.sub.sink = this._eventBus.on(
            "bulkUpdateGridRows",
            (listItems: any) => this.bulkUpdateGridRows(listItems)
        );

        this.sub.sink = this._eventBus.on("OnBulkUpdateClosed", (param: any) =>
            this.OnBulkUpdateClosed()
        );

        this.sub.sink = this._eventBus.on("onContactSelect", (data: any) =>
            this.onContactSelect(data)
        );

        this.sub.sink = this._eventBus.on(
            "setCustomRateFocus",
            (listItem: any) => this.setCustomRateFocus(listItem)
        );

        this.sub.sink = this._eventBus.on("reportAsyncStatus", (data: any) =>
            this.reportAsyncStatus(data)
        );

        this.sub.sink = this._eventBus.on(
            "getRelatedCrewPositions",
            (data: any) => this.getRelatedCrewPositions(data)
        );

        this.sub.sink = this._eventBus.on("fullScreenMode", (data: any) => {
            this.fullScreenMode(data);
        });
    }

    fullScreenMode(val: any) {
        this.isFullScreenMode = !this.isFullScreenMode;
        if (!this.isFullScreenMode) {
            setTimeout(() => {
                this.setTopScrollWidth();
            }, 2000);
        }
        //else {
        //    setTimeout(() => {
        //        if(this.isFullScreenMode)
        //            {
        //                const gridTableElement = document.querySelectorAll(
        //                    ".grid-table-container"
        //                );
        //                let tableContainerRow = gridTableElement[1] as HTMLElement;
        //                tableContainerRow.style.cssText += "margin-top: 0px !important;";
        //            }
        //    }, 50);
        //}
    }

    onRowDrop(dropResult: DropResult) {
        const removedIndex = dropResult.removedIndex || 0;
        const addedIndex = dropResult.addedIndex || 0;
        const item = this.modifiedListItems[removedIndex];
        this.setSort(item.id, addedIndex + 1);
    }

    onScroll($event: any) {
        const currentScrollPos = this.scrollableDiv?.nativeElement.scrollLeft;

        // Only horizontal scroll
        if (this.prevScrollPos != currentScrollPos) {
            this.marginLeft = 765 - currentScrollPos;
        }

        this.prevScrollPos = currentScrollPos;
        this.onScrollHide("grid-col-scrollable", currentScrollPos);

        this.syncTopScroll($event);
        this.popoverOnGridScroll();
    }

    popoverOnGridScroll() {
        const elements = document.querySelectorAll(".hdr-frozen");
        let frozenHeaderWidth = 0;

        elements.forEach((element) => {
            frozenHeaderWidth += element.getBoundingClientRect().width;
        });

        if (this.isFullScreenMode) {
            frozenHeaderWidth = frozenHeaderWidth / 2 - 159;
        }

        document.querySelectorAll(".popover").forEach((popover) => {
            const popoverRect = popover.getBoundingClientRect();
            if (popoverRect.left - 20 < frozenHeaderWidth) {
                (popover as HTMLElement).style.visibility = "hidden";
            } else {
                (popover as HTMLElement).style.visibility = "visible";
            }
        });
    }

    onScrollHide(parentClassName: any, currentScrollPos: any) {
        const rowElement = Array.from(
            this.scrollableDiv?.nativeElement.getElementsByClassName(
                parentClassName
            )
        );

        rowElement.forEach((parent: any, index) => {
            const children: any = Array.from(
                parent.getElementsByClassName("child")
            );

            // Reset all children to visible
            children.forEach((child: any) =>
                child.classList.remove("grid_visibility_none")
            );

            for (let i = 1; i < currentScrollPos / 100; i++) {
                children[i - 1].classList.add("grid_visibility_none");
            }
        });
    }

    updateHeight() {
        const childElement = this.scrollableDiv?.nativeElement.querySelector(
            ".smooth-dnd-container"
        );
        const headerChild =
            this.scrollableDiv?.nativeElement.querySelector(".hdr-grid-row");

        const divheaderHeight = headerChild?.offsetHeight;
        const div1Height = childElement?.offsetHeight;
        let gridHeight = div1Height + divheaderHeight + 40;
        if (this.isFullScreenMode) {
            gridHeight = gridHeight + 60;
        }
        this.renderer.setStyle(
            this.scrollableDiv?.nativeElement,
            "min-height",
            `${gridHeight}px`
        );
        this.setTopScrollWidth();
    }

    setChangeDetectionInterval() {
        if (this.changeDetectionInterval)
            clearInterval(this.changeDetectionInterval);
        this.changeDetectionInterval = setInterval(() => {
            this.cd.detectChanges();
        }, 2000);
    }

    public trackItem(index: number, item: any) {
        return item.uniqueTrackById;
    }

    async ngOnInit() {
        this.destroyGrid(null);
        this.isGridAvailable = true;
        this.InitialiseEventHandlers();
        this._sharedData.setCss("eventsdashboard");
        this.hasMinimumWageCheck = false;

        //handling the browser resize event
        this.resizeSubscription = fromEvent(window, "resize").subscribe(() => {
            this.setTopScrollWidth();
        });
    }

    ngAfterViewInit() {
        // Sync initial scroll positions if needed
        this.syncScroll();
    }

    onScrollbar(target: HTMLDivElement) {
        if (target === this.topScroll.nativeElement) {
            this.mainScroll.nativeElement.scrollLeft =
                this.topScroll.nativeElement.scrollLeft;
        } else if (target === this.mainScroll.nativeElement) {
            this.topScroll.nativeElement.scrollLeft =
                this.mainScroll.nativeElement.scrollLeft;
        }
    }

    syncMainScroll() {
        // Sync main scroll position when top scroll is moved
        if (this.topScroll && this.mainScroll) {
            this.mainScroll.nativeElement.scrollLeft =
                this.topScroll.nativeElement.scrollLeft;
        }
    }

    syncTopScroll(event) {
        // Sync top scroll position when main scroll is moved
        if (this.topScroll && this.mainScroll) {
            this.topScroll.nativeElement.scrollLeft =
                this.mainScroll.nativeElement.scrollLeft;
        }
    }

    setTopScrollWidth() {
        const headerChild =
            this.scrollableDiv?.nativeElement.querySelector(".hdr-grid-row");
        if (this.topChild) {
            this.renderer?.setStyle(
                this.topChild?.nativeElement,
                "width",
                `${headerChild?.offsetWidth}px`
            );
        }

        this.syncScroll();
    }

    private syncScroll() {
        if (this.topScroll && this.mainScroll) {
            this.topScroll.nativeElement.scrollLeft =
                this.mainScroll.nativeElement.scrollLeft;
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.sub.unsubscribe();
        this.resizeSubscription?.unsubscribe();
    }
}
