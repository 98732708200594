import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { EventTypeService } from "../../Shared/Services/eventType.service";
import { NetworkService } from "src/app/Shared/Services/network.service";
import { RemotesQueryService } from "src/app/Shared/Services/remotesquery.service";
import { EventService } from "src/app/Shared/Services/event.service";
import { RemotesConstants } from "src/app/Shared/helper/constants.service";
import { Observable, Subject, Subscriber } from "rxjs";
import { VenueService } from "src/app/Shared/Services/venue.service";
import { UniquePipe } from "ngx-pipes";
import { Router, ActivatedRoute } from "@angular/router";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { switchMap, takeUntil } from "rxjs/operators";
import { TeamService } from "../../Shared/Services/team.service";
import { SubSink } from "subsink";
import * as moment from "moment";

@Component({
    selector: "event-list",
    templateUrl: "./eventlist.component.html",
    styles: [
        `
            .show {
                display: table-cell !important;
            }
        `,
    ],
    providers: [UniquePipe],
})
export class EventListComponent implements OnInit, OnDestroy {
    constructor(
        private _eventTypeService: EventTypeService,
        private _networkService: NetworkService,
        private _remotesQueryService: RemotesQueryService,
        private _EventService: EventService,
        private _venueService: VenueService,
        private _teamService: TeamService,
        private router: Router,
        private route: ActivatedRoute,
        private log: NbcLoggerService
    ) {
        this.bsInlineRangeValue = [
            this.defaultCrewStartDate,
            this.defaultCrewEndDate,
        ];
    }

    VenueName: string[] = [];
    TeamName: string[] = [];
    venueSearchObservable$: Observable<any[]> | undefined;
    venueSearchTerm = "";

    defaultCrewStartDate: Date = new Date();
    defaultCrewEndDate: Date = new Date(
        new Date().setDate(new Date().getDate() + 90)
    );
    componentDestroyed$: Subject<boolean> = new Subject();
    teamNameObservable$: Observable<any[]> | undefined;
    teamNameSearchTerm = "";
    bsInlineRangeValue: Date[];
    venueSuggestions$: Observable<any> | undefined;
    selectedEventTypeId: any = "";
    season: any | false;
    eventQuery: any = {};
    defaultEventQuery: any = {};
    defaultEventResultsHaveLoaded: any = true;
    private subs = new SubSink();
    model: any = {
        broadcastDates: [
            {
                airDate: "",
                startTime: "",
                endTime: "",
            },
        ],
        broadcastType: "",
        timesheetStatus: "",
    };
    venue: any = "";
    eventTypes: any = [];
    networks: any = [];
    managers: any = [];
    showFilter: any = true;

    sortList = [
        { name: "Earliest", predicate: "StartDate" },
        { name: "Latest", predicate: "-StartDate" },
        { name: "Event Name A-Z", predicate: "Name" },
        { name: "Event Name Z-A", predicate: "-Name" },
        { name: "Level", predicate: "Level" },
    ];

    showGrid = true;
    viewFormat = "'grid'";
    showVenue = true;
    showTeam = true;
    hideResults = false;
    eventTypeFilter = {
        Status: "3 Selected",
        Count: 3,
        Max: 7,
    };

    tiers: any = [
        { name: "Tier 1", id: 1 },
        { name: "Tier 2", id: 2 },
        { name: "Tier 3", id: 3 },
        { name: "Tier 4", id: 4 },
    ];

    timeSheetStatuses: any = [
        { name: "Not Submitted", id: "Not Submitted" },
        { name: "Partially Submitted", id: "Partially Submitted" },
        { name: "Fully Submitted", id: "Fully Submitted" },
    ];

    eventStatusList: any = [];

    showLocation() {
        this.showVenue = false;
        this.venue = "";
        this.eventQuery.venue = undefined;
    }
    hideLocation() {
        this.showVenue = true;
        this.eventQuery.city = "";
    }

    setDefaultEventQuery() {
        this.teamNameSearchTerm = "";
        this.eventQuery = this._remotesQueryService.getEventQuery();
    }

    hideEvent() {
        this.showTeam = true;
        this.eventQuery.eventName = "";
    }

    showEvent() {
        this.showTeam = false;
        this.eventQuery.teamName = "";
    }

    restartSearch() {
        this.eventQuery = this._remotesQueryService.getEventQuery();
        this.bsInlineRangeValue = [
            this.defaultCrewStartDate,
            this.defaultCrewEndDate,
        ];
        this.eventQuery.currentPage = 1;
        //NEED managerId==0
        //if(this.eventQuery.managerId === 0) {
        //  this.eventQuery.managerId = "";
        //}
        if (this.eventQuery.tier === 0) {
            this.eventQuery.tier = "";
        }
        if (this.eventQuery.timesheetStatus === 0) {
            this.eventQuery.timesheetStatus = "";
        }
        //NEED networkid==0
        //if (this.eventQuery.networkId === 0) {
        //  this.eventQuery.networkId = "";
        //}
        this.checkTeam();
        this.eventSearch();
    }

    filterByBroadcastType() {
        this.eventSearch();
    }

    saveFavorite(event: any) {
        const favoriteStatus = event.isFavorite,
            favoriteQuery = {
                eventId: event.id,
                eventName: event.name,
                key: event.isFavoriteKey,
                isFavorite: !favoriteStatus,
            },
            index = this.model.items.indexOf(event);
        let eventFavoritePromise: any;
        if (!favoriteStatus) {
            this.subs.sink = this._EventService
                .favorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        ("Unable to favorite/unfavorite event at this time. Please try again later.");
                    },
                    () => {
                        this.log.debug("favorite successfully added");
                    }
                );
        } else {
            this.subs.sink = this._EventService
                .unfavorite(favoriteQuery)
                .subscribe(
                    (data) => {
                        eventFavoritePromise = data;
                        this.model.items[index].isFavorite = !favoriteStatus;
                        // this.model.items[index].isFavoriteKey = data.reference;
                    },
                    (err) => {
                        this.log.error(err);
                        this.log.showError(
                            "Unable to favorite/unfavorite event at this time. Please try again later."
                        );
                    },

                    () => {
                        this.log.debug("unfavorite successfully added");
                    }
                );
        }
    }

    oneventTypeFilterSelect(eventType: any) {
        this.eventQuery.eventType = eventType;
    }

    eventSearch() {
        this.log.debug("event search started");
        if (!!this.eventQuery.crewStartDate && !!this.eventQuery.crewEndDate) {
            this.eventQuery.eventTypeId =
                this.eventQuery.eventTypeId == 0
                    ? ""
                    : this.eventQuery.eventTypeId;
            this.eventQuery.tier =
                this.eventQuery.tier == 0 ? "" : this.eventQuery.tier;
            this.eventQuery.timesheetStatus =
                this.eventQuery.timesheetStatus == 0
                    ? ""
                    : this.eventQuery.timesheetStatus;

            //This is required as http interceptor removes empty paramters.
            const clonedQuery = _.cloneDeep(this.eventQuery);
            clonedQuery.crewStartDate = moment(
                this.eventQuery.crewStartDate
            ).format();
            clonedQuery.crewEndDate = moment(
                this.eventQuery.crewEndDate
            ).format();

            this.subs.sink = this._EventService.search(clonedQuery).subscribe(
                (data) => {
                    this.model = data;
                    this.model.broadcastType = this.eventQuery.broadcastType;
                    this.model.timesheetStatus =
                        this.eventQuery.timesheetStatus;
                    this.eventQuery.currentPage = this.model.currentPage;
                },
                (err) => {
                    this.log.error(err);
                    this.log.showError(
                        "Unable to perform the search at this time. Please try again later."
                    );
                },
                () => {
                    this.log.debug("event search complete");
                    this.defaultEventResultsHaveLoaded = true;
                    //TODO:AngularUpgrade -  existing functionality
                    // this.$emit("onUpdateEventSearchResults", this.model);
                }
            );
        }
    }
    onSelect(eventTypeFilter: any, $index: any) {
        eventTypeFilter.Count = 0;
        const item = this.eventStatusList[$index];
        item.checked = !item.checked;
        this.eventStatusList.splice($index, 1, item);

        this.eventStatusList.forEach((val:any) => {
            if (val.checked === true) {
                eventTypeFilter.Count += 1;
            }
            this.eventQuery["is" + val.description] = val.checked || false;
        });
        if (eventTypeFilter.Count > 0) {
            eventTypeFilter.Status = eventTypeFilter.Count + " Selected";
            if (eventTypeFilter.Count === this.eventStatusList.length) {
                if (eventTypeFilter.Count === this.eventStatusList.length) {
                    eventTypeFilter.Status = "All Selected";
                }
            }
        } else {
            eventTypeFilter.Status = "None Selected";
        }

        //making sure that angular change detection is complete
        setTimeout(() => this.eventSearch(), 200);
    }

    checkTeam() {
        if (this.eventQuery.team === undefined) {
            this.hideResults = true;
        } else {
            this.hideResults = false;
        }
    }
    resetEventSearch() {
        this.venueSearchTerm = "";
        this.teamNameSearchTerm = "";
        this.setDefaultEventQuery();
        this.showVenue = true;
        this.eventTypeFilter.Status = "3 Selected";
        this.eventStatusList.forEach(function (key: any) {
            if (
                key.description.toLowerCase() === "planning" ||
                key.description.toLowerCase() === "executing" ||
                key.description.toLowerCase() === "closing"
            ) {
                key.checked = true;
            } else {
                key.checked = false;
            }
        });
        this.hideEvent();
        this.restartSearch();
    }

    getEventTypes() {
        //const ref = this;
        this.log.debug("getEventTypes:fetching data");
        this.subs.sink = this._eventTypeService.getEventTypes({}).subscribe(
            (data) => {
                this.eventTypes = data.reference;
                // console.log(this.eventTypes);
            },
            (err) => this.log.error(err),
            () => {
                this.log.debug("event types received");
            }
        );
    }

    getNetworks() {
        this.log.debug("getNetworks:fetching data");
        this.subs.sink = this._networkService.getNetworks().subscribe(
            (data) => {
                this.networks = data.reference.sort((a: any, b: any) =>
                    a.description.localeCompare(b.description)
                );
            },
            (err) => this.log.error(err),
            () => {
                this.log.debug("networks received");
            }
        );
    }

    getEventStatusList() {
        this.log.debug("getEventStatusList:fetching data");
        this.subs.sink = this._EventService.getEventStatusList().subscribe(
            (data) => {
                this.eventStatusList = data.reference;
                _.forEach(this.eventStatusList, (item: any) => {
                    if (
                        item.description.toLowerCase() === "planning" ||
                        item.description.toLowerCase() === "executing" ||
                        item.description.toLowerCase() === "closing"
                    ) {
                        item.checked = true;
                    }
                });
            },
            (err) => this.log.error(err),
            () => {
                this.log.debug("event status received");
            }
        );
    }

    getEventManagers() {
        this.log.debug("getEventManagers:fetching data");
        this.subs.sink = this._EventService
            .getEventManagerList([
                RemotesConstants.eventManagers.homeBase.id,
                RemotesConstants.eventManagers.technical.id,
                RemotesConstants.eventManagers.manPower.id,
                RemotesConstants.eventManagers.onSite.id,
            ])
            .subscribe(
                (data) => {
                    this.managers = data.reference;
                },
                (err) => this.log.error(err),
                () => {
                    this.log.debug("event status received");
                }
            );
    }

    // searchVenue() {
    //   this.log.debug("searchVenue:fetching data");
    //   this.venueSuggestions$ = undefined; ///this._venueService.search(this.eventQuery.venue);

    // }

    searchVenue() {
        this.log.trace("get airports");
        this.venueSearchObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                observer.next(this.venueSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._venueService.search(token);
            })
        );
    }

    onVenueSelected($event: any) {
        this.eventQuery.venue = $event.item;
    }
    onEventTypeChange($event: any) {
        this.eventQuery.eventTypeId = $event.id;
        this.eventSearch();
    }

    searchTeam(term: any) {
        this.log.trace("searchTeam ");

        this.subs.sink = this._venueService
            .fullSearch(encodeURIComponent(term.target.value.trim()))
            .subscribe(
                (result: any) => {
                    const resultRef = result.reference.map(
                        (result: any) => result.name
                    );
                    this.TeamName = [];
                    const data = Array.prototype.push.apply(
                        this.TeamName,
                        resultRef
                    );
                },
                (err) => {
                    this.log.error(err);
                },
                () => {
                    this.log.trace("addJobCode - removing spinner");
                }
            );
    }

    teamTypeahed() {
        this.teamNameObservable$ = new Observable(
            (observer: Subscriber<string>) => {
                // Runs on every search
                observer.next(this.teamNameSearchTerm);
            }
        ).pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((token: string) => {
                return this._teamService.autocompleteSearch(token);
            })
        );
    }

    onTeamSelected($event: any) {
        this.eventQuery.team = $event.item;
    }

    goto(eventId: number) {
        this.log.debug("goto" + eventId);
        this.router.navigate(["Events/Event", eventId]);
    }

    checkValue() {}

    onEventTypeSelected($event: any) {
        this.eventQuery.eventTypeId = $event.id;
        this.eventSearch();
    }

    onToggleFilterDatepicker($event: any) {
        this.log.trace("Upcoming Events --> DateRange selected");
        this.eventQuery.crewStartDate = $event[0];
        this.eventQuery.crewEndDate = $event[1];
        //this.eventSearch();
    }

    openCloneEventModal(event: any) {}

    ngOnInit() {
        this.eventQuery = this._remotesQueryService.getEventQuery();
        this.teamTypeahed();
        this.getEventTypes();
        this.getNetworks();
        this.getEventStatusList();
        this.getEventManagers();
        this.searchVenue();
        this.eventSearch();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}

export interface ISelect {
    id: number;
    name: string;
}
