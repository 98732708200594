import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges, } from '@angular/core';

@Directive({
  selector: 'input[setFocus]',
})
export class NbcFocusDirective implements AfterViewInit, OnChanges {

  @Input('setFocus')
  private focused: boolean = false;

  constructor(public element: ElementRef<HTMLElement>) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.focused) {
      setTimeout(() => {
        this.element.nativeElement.setAttribute("style", "border:1px solid red;");
        this.element.nativeElement.focus();
      }, 0);
    }
    else {
      this.element.nativeElement.removeAttribute("style");

    }
  }

  ngAfterViewInit(): void {
    // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    if (this.focused) {
      setTimeout(() => this.element.nativeElement.focus(), 0);
    }
  }
}
