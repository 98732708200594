//import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { map, tap } from "lodash";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, Observer, of, Subscriber } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { NbcFileDownloadComponent } from "src/app/Shared/components/nbc-file-download/nbc-file-download.component";
import { NbcLoggerService } from "src/app/Shared/logger/nbc-logger.service";
import { ReportService } from "src/app/Shared/Services/report.service";

@Component({
    selector: "app-payroll-timecards-report",
    templateUrl: "./payroll-timecards-report.component.html",
})
export class PayrollTimecardsReportComponent implements OnInit {
    constructor(
        private log: NbcLoggerService,
        private _reportService: ReportService,
        public bsModalRef: BsModalRef
    ) {}
    //  @Input() reportName = modelValue;
    reportName: any;
    eventsData: any[] = [];
    suggestions$?: Observable<any[]> | undefined;
    ShowTimecardTypeRowAll = true;
    isExport = true;
    ShowTimecardTypeEditRow = false;
    //$scope.ShowTimecardTypeSelectedRow = false;
    ShowTimecardTypeTagRow = false;
    payrollTimecardsReport: any;
    getCriteriaData: any;
    // eventId = window.preload.eventId;
    eventId = 13;
    user = window.preload.user;
    eventselectionsettings = {
        externalIdProp: "",
        smartButtonMaxItems: 1,
        scrollable: true,
        displayProp: "name",
        enableSearch: false,
    };
    // userName = $scope.user.firstName + " " + $scope.user.lastName;
    userName;
    eventsTypeList: any[] = [];
    // $scope.rateCard = [];
    selectedTimecardType: any[] = [];
    events: any[] = [];
    @ViewChild(NbcFileDownloadComponent) child;
    isDownload = false;
    showDateRangeRowAll = true;
    showDateRangeEditRow = false;
    showDateRangeTagRow = false;
    showAlertMessage: any;
    payrollTimecardsReportInfo: any;
    timecardTypes: any[] = [
        { id: 1, name: "Pending Approval" },
        { id: 2, name: "Validation Failed" },
    ];
    alerts: any;

    openDateRangeDatepicker(event) {
        this.log.trace("openDatepicker called");
        event.preventDefault();
        event.stopPropagation();
    }

    onItemSelect($event) {
        this.selectedTimecardType = $event;
    }

    cancel() {
        // $modalInstance.close();
        this.bsModalRef.hide();
    }
    onToggleFilterDatepicker(event) {
        this.payrollTimecardsReportInfo.dateRangeStartDate = event[0];
        this.payrollTimecardsReportInfo.dateRangeEndDate = event[1];
    }
    closeAlert() {
        this.alerts = [];
        this.showAlertMessage = false;
    }
    editRow(value) {
        switch (value) {
            case "TimecardTypesRow":
                {
                    this.ShowTimecardTypeRowAll = false;
                    this.ShowTimecardTypeEditRow = true;
                    this.ShowTimecardTypeTagRow = false;
                }
                break;
            case "DateRangeRow":
                {
                    this.showDateRangeRowAll = false;
                    this.showDateRangeEditRow = true;
                    this.showDateRangeTagRow = false;
                }
                break;
        }
    }
    confirmSelection(value, item?) {
        if (value === "TimecardTypesRow") {
            this.ShowTimecardTypeEditRow = false;
            this.showSection(value);
        }
        if (value === "DateRangeRow") {
            this.showDateRangeEditRow = false;
            this.showSection(value);
        }

        if (
            this.payrollTimecardsReportInfo.dateRangeStartDate &&
            this.payrollTimecardsReportInfo.dateRangeEndDate
        ) {
            this.isExport = false;
        } else {
            this.isExport = true;
        }
    }

    showSection(value) {
        switch (value) {
            case "TimecardTypesRow":
                {
                    if (
                        !_.isUndefined(this.selectedTimecardType) &&
                        this.selectedTimecardType.length > 0
                    ) {
                        this.ShowTimecardTypeRowAll = false;
                        this.ShowTimecardTypeEditRow = false;
                        this.ShowTimecardTypeTagRow = true;
                    } else {
                        this.ShowTimecardTypeRowAll = true;
                        this.ShowTimecardTypeTagRow = false;
                        this.ShowTimecardTypeEditRow = false;
                    }
                    break;
                }
                break;
            case "DateRangeRow":
                {
                    if (
                        this.payrollTimecardsReportInfo.dateRangeStartDate !==
                            null &&
                        this.payrollTimecardsReportInfo.dateRangeEndDate !==
                            null &&
                        this.payrollTimecardsReportInfo.dateRangeStartDate !==
                            undefined &&
                        this.payrollTimecardsReportInfo.dateRangeEndDate !==
                            undefined
                    ) {
                        this.showDateRangeRowAll = false;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = true;
                    } else {
                        this.showDateRangeRowAll = true;
                        this.showDateRangeEditRow = false;
                        this.showDateRangeTagRow = false;
                    }
                    break;
                }
                break;
        }
    }

    stateChanged(checked, value, row) {
        if (row === "TimecardTypesRow") {
            if (checked) {
                this.eventsTypeList.push(value);
                this.payrollTimecardsReportInfo.events.push(value);
            }
            if (!checked) {
                this.eventsTypeList.splice(
                    this.eventsTypeList.indexOf(value),
                    1
                );
                this.payrollTimecardsReportInfo.events.splice(
                    this.payrollTimecardsReportInfo.events.indexOf(value),
                    1
                );
            }
        }
    }

    removeTag(item, row, index) {
        if (row === "TimecardTypesRow") {
            this.eventsTypeList.splice(this.eventsTypeList.indexOf(item), 1);
            this.selectedTimecardType.splice(
                this.selectedTimecardType.indexOf(item),
                1
            );
            item.value = !item.value;
            this.showSection("TimecardTypesRow");
        }
    }
    removeDateTag(value) {
        if (value === "DateRangeRow") {
            this.payrollTimecardsReportInfo.dateRangeStartDate = null;
            this.payrollTimecardsReportInfo.dateRangeEndDate = null;
            this.showDateRangeRowAll = true;
            this.showDateRangeEditRow = false;
            this.showDateRangeTagRow = false;
            if (
                !this.payrollTimecardsReportInfo.dateRangeStartDate &&
                !this.payrollTimecardsReportInfo.dateRangeEndDate
            ) {
                this.isExport = true;
            } else {
                this.isExport = false;
            }
        }
    }

    exportPayrollTimecardsReport(data?) {
        const temppayrollTimecardsReportInfo: any = {};

        temppayrollTimecardsReportInfo.timecardTypes =
            this.selectedTimecardType;
        temppayrollTimecardsReportInfo.dateRangeStartDate = moment(
            this.payrollTimecardsReportInfo.dateRangeStartDate
        ).format("MM/DD/yyyy");
        temppayrollTimecardsReportInfo.dateRangeEndDate = moment(
            this.payrollTimecardsReportInfo.dateRangeEndDate
        ).format("MM/DD/yyyy");

        this.isDownload = true;

        this._reportService
            .exportpayrollTimecardsReport(
                temppayrollTimecardsReportInfo,
                this.userName
            )
            .subscribe({
                next: (data) => {
                    if (data == null) {
                        this.showAlertMessage = true;
                        this.log.showModalError(
                            "There are no data points to export for this report at the moment."
                        );
                    } else {
                        this.payrollTimecardsReport = this.getCriteriaData;
                        const fileName = "SCORE-payrollTimecards_";
                        const d = moment(new Date()).format("MM-DD-yyyy");
                        this.showAlertMessage = false;
                        this.child.fileName = fileName + d;
                        this.child.fileType = "excel";
                        this.child.data = data;
                        this.child.download();
                    }
                },
                error: (data) => {
                    this.log.error("get got an error: " + JSON.stringify(data));
                    this.showAlertMessage = true;
                },
            });
    }
    openInExcel(data, fileName) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(blob);
        const d = moment(new Date()).format("MM-dd-yyyy");
        const a = document.createElement("a");
        a.href = fileURL;
        a.target = "_blank";
        a.download = fileName + d + ".xlsx";
        document.body.appendChild(a);
        a.click();
        // }
    }
    ngOnInit(): void {
        this.payrollTimecardsReportInfo = {};
        this.payrollTimecardsReportInfo.timecardType = [];
        this.payrollTimecardsReportInfo.dateRangeStartDate = null;
        this.payrollTimecardsReportInfo.dateRangeEndDate = null;
    }
}
