
<div *ngIf="dropdownList">
  <ng-multiselect-dropdown [placeholder]="placeholder"
                           [settings]="dropdownSettings"
                           [data]="dropdownList"
                           [(ngModel)]="selectedItems"
                           (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemSelect($event)"
                            (onDeSelectAll)="onSelectAll($event)"
                           (onSelectAll)="onSelectAll($event)">
  </ng-multiselect-dropdown>
</div>
