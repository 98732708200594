import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
//import { OnboardingService } from 
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { OnboardingService } from '../Shared/Services/Onboarding/onboarding.service';
import { takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from '../Shared/sharedData.service';
import { Subject } from 'rxjs';
import { EmitEvent, EventBusService } from '../Core/services/event-bus.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
    selector: "onboarding",
    templateUrl: "./onboarding.component.html",
    //styleUrls: [
    //  "../../assets/Content/app/areas/events/events.css",
    //  "../../assets/Content/app/areas/hr/hr.css",
    //  "../../assets/Content/app/areas/hr/onboarding.css"],
    //encapsulation: ViewEncapsulation.None
})
export class OnboardingComponent implements OnInit, OnDestroy {
    constructor(
        private activatedRoute: ActivatedRoute,
        private _onboardingService: OnboardingService,
        private router: Router,
        private _sharedData: SharedDataService,
        private _eventBusService: EventBusService,
        private log: NGXLogger
    ) {}
    _: any;

    @ViewChild("tabset") tabset: TabsetComponent | undefined;

    componentDestroyed$: Subject<boolean> = new Subject();

    private subs = new SubSink();

    hrCounts: any | undefined;

    itemCount1 = 0;
    itemCount2 = 0;
    itemCount3 = 0;
    itemCount4 = 0;
    itemCount5 = 0;
    itemCount6 = 0;
    itemCount7 = 0;

    buttonText!: string | "+ Create an Event";
    tabs = [
        {
            heading: "REQUESTS",
            route: "requests",
            active: true,
        },
        {
            heading: "HISTORY",
            route: "history",
            active: false,
        },
    ];

    go(route: string) {
        _.forEach(this.tabs, function (tab, index) {
            tab.active = false;
            if (tab.route === route) {
                tab.active = true;
            }
        });
        this.router.navigate([route], { relativeTo: this.activatedRoute });
    }

    activateSelectedTab() {
        const activatedRoute =
            this.activatedRoute.snapshot?.firstChild?.routeConfig?.path;
        if (activatedRoute) {
            _.forEach(this.tabs, (rt) => {
                rt.active = false;
                if (activatedRoute?.toLowerCase() === rt.route.toLowerCase()) {
                    rt.active = true;
                }
            });
        }
    }

  getHrCounts() {
    this.log.trace("OnboardingHeader -> getHrCounts called");
    let ref = this;
    this._onboardingService.getRemotesHrCount().pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.log.trace("OnboardingHeader -> getHrCounts: data received");
          this.itemCount1 = data.positionRequestsSubmitted;
          this.itemCount2 = data.registrationsSubmitted;
          this.itemCount3 = data.registrationsComplete;
          this.itemCount4 = data.registrationsApproved;
          this.itemCount5 = data.onboardingsInprogress;
          this.itemCount6 = data.requestsComplete;
          this.itemCount7 = data.registrationsRejected;

          //this.log.trace("OnboardingHeader -> sharing status count data from hrCounts");
          //this._sharedData.setEventSummary(this.hrCounts)
        },
        err => {
          this.log.error(err);
          //$scope.$emit("showAlert", "danger", "Unable to load event at this time. Please try again later.");
        },
        () => {
          this.log.trace("OnboardingHeader -> removing spinner");
        });
  };

  updateI9ReviewDetails(registrationObjects: any) {
    this.update(registrationObjects.items, registrationObjects.columnName, registrationObjects.value);
  }

  update(registrationObject, columnName, value): void {

    this.log.trace("OnboardingHistory -> Update called");
    const regData = _.cloneDeep(registrationObject);
    var updateColumn = columnName;
    switch (columnName) {
      case "I9Verify":
        columnName = "i9_verify";
        regData[0].i9Verify = value;
        break;
    }
    this._onboardingService.update(regData, columnName, value == 'N/A' ? 'NA' : value).pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.log.trace("OnboardingHistory -> Update getting updated HrCounts");
        },
        err => {
          this.log.error("OnboardingHistory -> Update error:" + err);
        },
        () => {
          this.log.trace("OnboardingHistory -> Update removing spinner");
        }
      );

  };


  associateUrl() {
    let elem = document.querySelector(".tab-container ul");
    if (elem) {
      for (let i = 0; i < elem.children.length; i++) {
        elem.children[i]?.querySelector("a")?.setAttribute("href", this.getLocationRoute(i));
      }
    }
  }

    getLocationRoute(index) {
        const arr = window.location.href.split("/");
        arr.pop();
        arr.push(this.tabs[index].route);
        return arr.join("/");
    }

  initSubs(): void {
    this.subs.sink = this._eventBusService.on("getHrCounts", () => this.getHrCounts());
    this.subs.sink = this._eventBusService.on("updateI9Review", (dataSource) => this.updateI9ReviewDetails(dataSource));
  }

  ngOnInit() {
    this._sharedData.setCss("onboarding");
    this.getHrCounts();
    this.activateSelectedTab();
    this.initSubs();
   
  }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.subs.unsubscribe();
    }
}
