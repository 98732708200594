
<div *ngIf="alerts && alerts.length>0">
  <div *ngFor="let alert of alerts" style="width:96%;margin:auto;">
    <alert [type]="alert.type"
           [dismissible]="dismissible"
           (onClosed)="onClosed(alert)">
        {{ alert.msg }}
    </alert>
  </div>
</div>
