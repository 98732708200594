import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class HttpMonitorService {
    private isLoading$$ = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading$$.asObservable();

    private canShowLoader$$ = new BehaviorSubject<boolean>(false);
    canShowLoader$ = this.canShowLoader$$.asObservable();

    private loadTime$$ = new BehaviorSubject<any[]>(null);
    loadTime$ = this.loadTime$$.asObservable();

    setLoading(isLoading: boolean) {
        this.isLoading$$.next(isLoading);
    }

    setShowLoader(show: boolean) {
        this.canShowLoader$$.next(show);
    }

    /* code for Timing service to be used to show the loading time */
    private timings: any[] = [];

    logTime(url: string, time: number): void {
        const timeInSeconds = time / 1000;
        this.timings.push({ url: url, time: timeInSeconds });
        this.loadTime$$.next(this.timings);
    }

  getTimings(): any[] {
    setTimeout(() => this.timings.length = 0, 1000);
        return this.timings;
    }

    clearTimings(): void {
        this.timings = [];
    }
}
