<input type="datetime"
       id="{{id}}"
       name="{{name}}"
       class="calcontainer form-control form-control-sm {{name}}"
       style="text-align:center;margin:1px;"
       placeholder="{{placeholder}}"
       readonly
       [disabled]="nbcDisabled"
       [required]="nbcRequired"
       value="{{displayModel}}"
       (click)="show($event)"
       [ngClass]="!isValidTime?'ng-invalid':''"/>
<div *ngIf="!nbcDisabled">
  <div *ngIf="view=='hours' && showme"  name="{{name}}"  class="{{name}}"
       style="position: absolute; background-color: #FFF; width: 300px; z-index: 8889; box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); white-space: initial !important;">
    <div>
      <table>
        <thead>
          <tr class="header">
            <th *ngIf="nbcDatesNavigationEnabled"
                class="{{name}}"
                (click)="prev()"
                style="width: 20px; text-align: center; vertical-align: middle; cursor: pointer;">‹</th>
            <th colspan="5" class="header-text {{name}}" style="cursor: pointer;" [innerHtml]="getHeaderDate()"></th>
            <th *ngIf="nbcDatesNavigationEnabled"
                class="{{name}}"
                (click)="next()"
                style="width: 20px; text-align: center; vertical-align: middle; cursor: pointer;">›</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7">
              <span *ngFor="let hour of hours"
                    [ngClass]="{'now':isNow(hour),'active':isSameHour(hour)}"
                    class="btn {{name}}" 
                    style="padding:5px;font-size:13px !important; width:22%;" 
                    (click)="setDate(hour)" [innerHtml]="getHour(hour)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <button type="button" class="btn btn-xs pull-right {{name}}" (click)="cancel(date)">Clear</button>
    </div>
  </div>
  <div *ngIf="view=='minutes'"
                     name="{{name}}" 
       style="position: absolute; background-color: #FFF; width: 630px; z-index: 8890; box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); white-space: initial !important; "> 
    <div>
      <table>
        <thead>
          <tr class="header">
            <th *ngIf="nbcTimeNavigationEnabled"
                class="{{name}}"
                (click)="prev(1)"
                style="width: 20px; text-align: center; vertical-align: middle; cursor: pointer;">‹</th>
            <th colspan="5" class="header-text {{name}}" style="cursor: pointer;" (click)="setView('hours')"  [innerHtml]="getHeaderDate()"></th>
            <th *ngIf="nbcTimeNavigationEnabled"
                class="{{name}}"
                (click)="next(1)"
                style="width: 20px; text-align: center; vertical-align: middle; cursor: pointer;">›</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7">
              <span class="btn" *ngFor="let minute of minutes"
                    [ngClass]="{'now':isNow(minute),'active':isSameMinutes(minute)}"
                    (click)="setDate(minute)"
                    [innerHtml]="getTime(minute)">
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <button type="button" class="btn btn-xs pull-right {{name}}" (click)="cancel(date)">Clear</button>
    </div>
  </div>
</div>

