import { Component, OnInit } from "@angular/core";

@Component({
    selector: "season-list",
    templateUrl: "./seasonlist.component.html",
})
export class SeasonListComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
