import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SubSink } from "subsink";
import { NbcLoggerService } from "../../../../Shared/logger/nbc-logger.service";
import { EventService } from "../../../../Shared/Services/event.service";

@Component({
    selector: "modal-edit-pos-comment",
    templateUrl: "modal-Edit-Position-Comments.component.html",
})
export class ModalEditPositionCommentsComponent implements OnInit {
    constructor(
        private _eventServices: EventService,
        private log: NbcLoggerService,
        public bsModalRef: BsModalRef
    ) {}
    private subs = new SubSink();
    public onSync!: Subject<any>;

    eventId: any | undefined;
    comments: string | undefined;
    positionListItem: any | undefined;

    saveComment() {
        this.log.trace("save position comment");
        this.positionListItem.eventPosition.comments = this.comments;
        this.subs.sink = this._eventServices
            .saveEventPositionComment(this.eventId, [this.positionListItem])
            .subscribe({
                next: (result) => {
                    // this.log.trace("saveComment - got results: " + JSON.stringify(result));
                    this.onSync.next(
                        this.positionListItem.eventPosition.comments
                    );
                    this.bsModalRef.hide();
                },
                error: (err) => {
                    this.log.error("got an error: " + JSON.stringify(err));
                    this.log.showModalError(
                        "Unable to perform the search at this time. Please try again later."
                    );
                },
            });
    }

    cancel() {
        this.log.trace("cancelled modal");
        this.bsModalRef.hide();
    }
    ngOnInit() {
        this.onSync = new Subject();
        this.eventId = this.eventId;
        this.comments = this.comments;
        this.positionListItem = this.positionListItem;
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
