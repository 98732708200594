<div>
  <form novalidate #contactSearchForm="ngForm" (ngSubmit)="search()">
    <header id="col-main-hdr">
      <h1>Contacts</h1>
    </header>
    <nav class="breadcrumb">
      <ul>
        <li home>
        </li>
        <li>/</li>
        <li>Contacts</li>
      </ul>
    </nav>
    <div class="page-container">
      <div class="container-fluid">

        <div class="row clearfix">


          <div [ngClass]="hasSearchResults ? 'col-lg-3 col-md-4 col-sm-5 contact-sidebar' : 'col-md-12'">
            <div class="contact-search-wrapper">
              <div class="header-bar clearfix">
                <p class="heading-title pull-left text-dark fw-bold">Search Contacts</p>
                <button [hidden]="hasSearchResults"
                        authenticate-user="contact.create,Checkbox.Create, Create_Contact.Retrieve"
                        type="button"
                        class="btn btn-primary pull-right"
                        (click)="openAddContactModal({});">
                  + Add Contact
                </button>
              </div>
              <div class="form-body-container">
                <div class="row">
                  <div class="col-md-12">
                    <nbc-alert></nbc-alert>
                  </div>
                  <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5'">
                    <label class="control-label"
                           authenticate-user="contact.retrieve, name.retrieve">Name</label>
                    <div class="row"
                         authenticate-user="contact.retrieve, name.retrieve">
                      <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                        <div class="form-group">
                          <input type="text"
                                 autocomplete="off"
                                 name="contactQuery.firstName"
                                 appInputRestriction="restrictChars"
                                 class="form-control"
                                 placeholder="First Name"
                                 [(ngModel)]="contactQuery.firstName"
                                 maxlength="20" />
                        </div>
                      </div>
                      <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                        <div class="form-group">
                          <input type="text"
                                 autocomplete="off"
                                 name="contactQuery.lastName"
                                 appInputRestriction="restrictChars"
                                 class="form-control"
                                 placeholder="Last Name"
                                 [(ngModel)]="contactQuery.lastName"
                                 maxlength="20" />
                        </div>
                      </div>
                    </div>
                    <div class="row"
                         authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">
                      <div [ngClass]="hasSearchResults ? 'col-md-4' : 'col-md-3'">
                        <label class="control-label"
                               authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">
                          Resides Within
                        </label>
                        <nbc-select name="contactQuery.radius"
                                    defaultSelectClass="form-select group-update"
                                    [source]="radii"
                                    display="description"
                                    value="radius"
                                    placeHolderDisplay="Radius (miles)"
                                    placeHolderValue="null"
                                    ngDefaultControl
                                    (modelChange)="onRadiusSelected($event)">
                        </nbc-select>

                      </div>
                      <div [ngClass]="{'col-md-3': ((states?.length > 0) && !hasSearchResults), 'col-md-6': (!(states?.length >0)) }">
                        <label class="control-label"
                               authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">Location</label>
                        <div class="form-group"
                             authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">
                          <input type="text"
                                 autocomplete="off"
                                 name="contactQuery.searchLocation"
                                 [(ngModel)]="contactQuery.searchLocation"
                                 class="form-control"
                                 placeholder="(City, State) or City or Zip"
                                 maxlength="50" />
                        </div>
                      </div>
                      <div [ngClass]="{'col-md-12': (hasSearchResults), 'col-md-3': (!hasSearchResults)}">
                        <label class="control-label"
                               authenticate-user="contact.hrmaster.retrieve,ContactLocation.Retrieve">Country</label>
                        <div class="form-group">

                          <input [(ngModel)]="contactQuery.countryName"
                                 name="contactQuery.countryName"
                                 [typeahead]="countries"
                                 typeaheadOptionField="name"
                                 class="form-control"
                                 autocomplete="off"
                                 [isAnimated]="true"
                                 placeholder="Select Country"
                                 (typeaheadOnSelect)="onCountrySelected($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5 offset-lg-1'"
                       authenticate-user="contact.retrieve, contact_search_results.retrieve">
                    <div class="form-group">
                      <label class="control-label">Positions</label>

                      <!-- <div class="form-group">
                        <div class="nbc-tag-container">
                          <div class="tag-container">
                            <div *ngFor="let position of contactQuery.positions" class="tag">
                              <span class="tag-text">{{position.description}}</span>
                            <i class="tag-remove glyphicon glyphicon-remove" (click)="removeTag('positions',position);"></i>
                            </div>
                            <input [(ngModel)]="positionSearchTerm"
                                   appInputRestriction="restrictChars"
                                   name="contactQuery.positions"
                                   [typeahead]="positionObserver"
                                   [typeaheadAsync]="true"
                                   typeaheadOptionField="description"
                                   [typeaheadOptionsLimit]="10"
                                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                                   placeholder="Add Position"
                                   class="form-control"
                                   [isAnimated]="true"
                                   typeaheadWaitMs="200"
                                   (typeaheadOnSelect)="onPositionSelected($event)">
                          </div>
                        </div>
                      </div> -->
                      <nbc-tags [(searchTerm)]="positionSearchTerm"
                                [searchTermObservable$]="positionObserver$"
                                [tagDisplay]="'description'"
                                [placeholder]="'Add Position'"
                                [tagId]="contactQuery.positions"
                                [tagName]="contactQuery.positions"
                                (tagChange)="setPositionEventTerm($event)"
                                (nbcOnOptionSelect)="onPositionSelected($event)"></nbc-tags>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Skill Sets</label>

                      <!-- <div class="form-group">
                        <div class="nbc-tag-container">
                          <div class="tag-container">
                            <div *ngFor="let skill of contactQuery.skills" class="tag">
                              <span class="tag-text">{{skill.description}}</span>
                              <i class="tag-remove glyphicon glyphicon-remove" (click)="removeTag('skills',skill);"></i>
                            </div>
                            <input [(ngModel)]="skillSearchTerm"
                                   name="contactQuery.skills"
                                   appInputRestriction="restrictChars"
                                   [typeahead]="skillObservable"
                                   [typeaheadAsync]="true"
                                   typeaheadOptionField="description"
                                   [typeaheadOptionsLimit]="10"
                                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                                   placeholder="Add Skill"
                                   class="form-control"
                                   [isAnimated]="true"
                                   typeaheadWaitMs="200"
                                   (typeaheadOnSelect)="onSkillSelected($event)">
                          </div>
                        </div>
                      </div>  -->
                      <nbc-tags [(searchTerm)]="skillSearchTerm"
                                [searchTermObservable$]="skillObservable$"
                                [tagDisplay]="'description'"
                                [placeholder]="'Add Skill'"
                                [tagId]="contactQuery.skills"
                                [tagName]="contactQuery.skills"
                                (tagChange)="seSkillEventTerm($event)"
                                (nbcOnOptionSelect)="onSkillSelected($event)"></nbc-tags>
                    </div>
                  </div>
                </div>
              </div>
              <div class="expand-options">
                <div class="accordian-wrap" authenticate-user="contact.workhistory.retrieve,contact_profile_work_history.retrieve">
                  <accordion>
                    <accordion-group [(isOpen)]="accordionInfo.workHistory.isOpen">
                      <div accordion-heading [ngClass]="{'opened': accordionInfo.workHistory.isOpen}" (click)="isWorkHistoryOpenChange(); $event.stopPropagation()">
                        <span class="heading-three caps text-dark fw-bold">Work History</span>
                        <span class="accordian-text subtext">Search by past events</span>
                        <span class="fa-stack pull-right cursor-pointer" (click)="isWorkHistoryOpenChange(); $event.stopPropagation()">
                          <i class="fa fa-circle fa-stack-2x"></i>
                          <i class="fa fa-stack-1x" [ngClass]="{'fa-minus' : accordionInfo.workHistory.isOpen, 'fa-plus': !accordionInfo.workHistory.isOpen }"></i>
                        </span>
                      </div>
                      <div class="row">
                        <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5'">
                          <label class="control-label">Worked On:</label>
                          <div class="row">
                            <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-4'">
                              <div class="form-group">
                                <nbc-dropdown [source]="eventTypes" [(model)]="contactQuery.eventType.id"
                                              display="name" value="id"
                                              nbcSize="w-100"
                                              (nbcOnOptionSelect)="onEventTypeSelected"
                                              placeholder="Event Type">
                                </nbc-dropdown>

                              </div>
                            </div>
                            <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-4'">
                              <div class="form-group">
                                <input [(ngModel)]="seasonSearchTerm"
                                       name="contactQuery.season"
                                       appInputRestriction="restrictChars"
                                       [typeahead]="seasonObservable"
                                       [typeaheadAsync]="true"
                                       typeaheadOptionField="name"
                                       [typeaheadOptionsLimit]="10"
                                       (typeaheadLoading)="changeTypeaheadLoading($event)"
                                       placeholder="Season"
                                       class="form-control"
                                       [isAnimated]="true"
                                       [typeaheadWaitMs]="200"
                                       (typeaheadOnSelect)="onSeasonSelected($event)">
                                <!--<input type="text" restrict-characters="restrict-characters" class="form-control" placeholder="Season" [(ngModel)]="contactQuery.season" typeahead-on-select="onSeasonSelect($item, $model, $label)" typeahead-wait-ms="200" typeahead="season as season.name for season in searchSeason($viewValue)" maxlength="50" autocomplete="off" />-->
                              </div>
                            </div>
                            <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-4'" authenticate-user="contact.workhistory.retrieve,contact_profile_work_history.retrieve">
                              <div class="form-group">
                                <input [(ngModel)]="eventSearchTerm"
                                       name="contactQuery.event"
                                       appInputRestriction="restrictChars"
                                       [typeahead]="eventObservable"
                                       [typeaheadAsync]="true"
                                       typeaheadOptionField="description"
                                       [typeaheadOptionsLimit]="10"
                                       (typeaheadLoading)="changeTypeaheadLoading($event)"
                                       placeholder="Event Name"
                                       class="form-control"
                                       [isAnimated]="true"
                                       [typeaheadWaitMs]="200"
                                       (typeaheadOnSelect)="onEventSelected($event)">
                                <!--<input type="text" restrict-characters="restrict-characters" class="form-control input-md" placeholder="Event Name" [(ngModel)]="contactQuery.event" typeahead-on-select="onEventSelect($item, $model, $label)" typeahead-wait-ms="200" typeahead="event as event.description for event in searchEvent(contactQuery.seasonId, $viewValue)" autocomplete="off" />-->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5 offset-lg-1'">
                          <div class="form-group">
                            <label class="control-label">Worked At:</label>
                            <input [(ngModel)]="venueSearchTerm"
                                   name="contactQuery.venue"
                                   appInputRestriction="restrictChars"
                                   [typeahead]="venueObserver"
                                   [typeaheadAsync]="true"
                                   typeaheadOptionField="displayText"
                                   [typeaheadOptionsLimit]="10"
                                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                                   placeholder="Select Venue"
                                   class="form-control"
                                   [isAnimated]="true"
                                   [typeaheadWaitMs]="200"
                                   (typeaheadOnSelect)="onVenueSelected($event)">
                            <!--<input type="text" restrict-characters="restrict-characters" class="form-control" placeholder="Select Venue" maxlength="100" typeahead-wait-ms="200" [(ngModel)]="contactQuery.venue" typeahead="venue as venue.displayText for venue in searchVenue($viewValue)" maxlength="20" autocomplete="off" />-->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-4 col-lg-5'">
                          <label class="control-label">Employee Type:</label>
                          <div class="row">
                            <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-4'">
                              <div class="form-group">
                                <nbc-dropdown [source]="employeeTypes" [(model)]="contactQuery.employeeType.id"
                                              display="description" value="id"
                                              nbcSize="w-100"
                                              (nbcOnOptionSelect)="onEmployeeTypeSelected"
                                              placeholder="Employee Type"></nbc-dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-8 col-lg-5 offset-lg-1'">
                          <div class="form-group">
                            <label class="control-label">Crewed Positions</label>

                            <!-- <div class="form-group">
                              <div class="nbc-tag-container">
                                <div class="tag-container">
                                  <div *ngFor="let crewposition of contactQuery.crewedPositions" class="tag">
                                    <span class="tag-text">{{crewposition.description}}</span>
                                    <i class="tag-remove glyphicon glyphicon-remove" (click)="removeTag('crewedPositions',crewposition);"></i>
                                  </div>

                                  <input [(ngModel)]="crewPositionSearchTerm"
                                         name="contactQuery.crewedPositions"
                                         [typeahead]="crewedPositionObserver"
                                         [typeaheadAsync]="true"
                                         appInputRestriction="restrictChars"
                                         typeaheadOptionField="description"
                                         [typeaheadOptionsLimit]="10"
                                         (typeaheadLoading)="changeTypeaheadLoading($event)"
                                         placeholder="Add Crewed Position"
                                          [isAnimated]="true"
                                         typeaheadWaitMs="200"
                                         class="form-control"
                                         (typeaheadOnSelect)="onCrewedPositionSelected($event)">
                                </div>
                              </div>
                            </div> -->
                            <nbc-tags [(searchTerm)]="crewPositionSearchTerm"
                                      [searchTermObservable$]="crewedPositionObserver$"
                                      [tagDisplay]="'description'"
                                      [tagId]="contactQuery.crewedPositions"
                                      [tagName]="contactQuery.crewedPositions"
                                      [placeholder]="'Add Crewed Position'"
                                      (tagChange)="setCrewPositionEventTerm($event)"
                                      (nbcOnOptionSelect)="onCrewedPositionSelected($event)"></nbc-tags>
                          </div>
                        </div>
                      </div>
                    </accordion-group>
                  </accordion>
                </div>
                <div class="accordian-wrap">
                  <accordion>
                    <accordion-group [isOpen]="accordionInfo.personal.isOpen">
                      <div accordion-heading [ngClass]="{'opened':accordionInfo.personal.isOpen}" (click)="isWorkPersonalInfoOpenChange(); $event.stopPropagation()">
                        <span class="heading-three caps text-dark fw-bold">Personnel Info</span>
                        <span class="accordian-text subtext">Search by Email, Phone Number, HR info</span>
                        <span class="fa-stack pull-right cursor-pointer" (click)="isWorkPersonalInfoOpenChange(); $event.stopPropagation()">
                          <i class="fa fa-circle fa-stack-2x"></i>
                          <i class="fa fa-stack-1x" [ngClass]="{'fa-minus' : accordionInfo.personal.isOpen, 'fa-plus': !accordionInfo.personal.isOpen }"></i>
                        </span>
                      </div>
                      <div class="expanded-accordian-view">
                        <div class="row">
                          <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5'">

                            <div class="row">
                              <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'" authenticate-user="contact.retrieve, contactinformation.email.retrieve">
                                <div class="form-group">
                                  <label class="control-label">Email</label>
                                  <input class="form-control" type="text"
                                         appInputRestriction="restrictChars"
                                         maxlength="50"
                                         [(ngModel)]="contactQuery.email"
                                         [pattern]="emailRegex"
                                         #emailEle="ngModel"
                                         name="contactQuery.email">
                                  <span class="control-label control-label-error"
                                        *ngIf="(emailEle.invalid && contactSearchForm.submitted)"
                                        style="color:red;">Enter a valid email</span>
                                </div>
                              </div>
                              <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'" authenticate-user="contact.retrieve, contactinformation.retrieve">
                                <div class="form-group">
                                  <label class="control-label">Phone</label>
                                  <input class="form-control"
                                         type="text"
                                         maxlength="20"
                                         name="contactQuery.phone"
                                         [(ngModel)]="contactQuery.phone"
                                         phoneFormatOnly />
                                </div>
                              </div>
                            </div>
                            <div class="row" authenticate-user="contact.retrieve, union.retrieve">
                              <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                                <div class="form-group">
                                  <label class="control-label">Union Designation:</label>
                                  <nbc-dropdown [source]="unions"
                                                nbcSize="w-100"
                                                [(model)]="contactQuery.union"
                                                display="description">
                                  </nbc-dropdown>

                                  <!--<div nbc-dropdown [(ngModel)]="contactQuery.union" nbc-source="unions" nbc-display="description" nbc-add-blank="false"></div>-->
                                </div>
                              </div>

                            </div>
                          </div>
                          <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6 col-lg-5 offset-lg-1'">
                            <div class="form-group">
                              <label authenticate-user="contact.hrmaster.retrieve, contacthrmaster.retrieve">HR Identifications:</label>
                              <div class="row">
                                <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                                  <div class="form-group">
                                    <input authenticate-user="contact.hrmaster.retrieve, contacthrmaster.retrieve"
                                           class="form-control"
                                           type="text"
                                           autocomplete="off"
                                           appInputRestriction="numberOnly"
                                           placeholder="SSO #"
                                           maxlength="10"
                                           name="contactQuery.sso"
                                           [(ngModel)]="contactQuery.sso"
                                           numbersOnly />
                                  </div>
                                </div>
                                <div [ngClass]="hasSearchResults ? 'col-md-12' : 'col-md-6'">
                                  <div class="form-group">
                                    <input authenticate-user="contact.hrmaster.retrieve, contacthrmaster.retrieve"
                                           class="form-control"
                                           type="text"
                                           appInputRestriction="restrictChars"
                                           placeholder="PerNer #"
                                           maxlength="10"
                                           name="contactQuery.perNer"
                                           [(ngModel)]="contactQuery.perNer" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </accordion-group>
                  </accordion>
                </div>
                <div class="col-md-12 col-sm-12 footer-bar">
                  <p>(Max {{contactQuery.limit}} Results)</p>
                  <button type="submit" class="btn btn-primary" [disabled]="contactSearchForm.invalid">Search</button>
                  <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 col-sm-7" [hidden]="!hasSearchResults">
            <div id="search-results" class="search-results">
              <div class="filter-container view-options">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-inline form-sort-options">
                       <!-- SCOR-25582: Removed the Sort By feature -->
                      <!--<div class="form-group">
                        <span>Sort By:</span>
                        <nbc-dropdown [source]="sortList"
                                      [(model)]="contactQuery.sortBy"
                                      display="name" value="predicate"
                                      (nbcOnOptionSelect)="paginate1($event)"></nbc-dropdown>
                      </div>-->
                      <div class="form-group">
                        <nbc-page-size [source]="pageSizeList" [model]="contactQuery.pageSize" (nbcValueChange)="searchPage($event)"></nbc-page-size>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contacts">
                    <div class="form-group text-right d-grid justify-content-end">
                      <div class="d-flex justify-content-end">
                        <pagination-label pageSize="{{contacts.pageSize}}"
                                          currentPage="{{contacts.currentPage}}"
                                          totalRecords="{{contacts.totalRecords}}"></pagination-label>
                      </div>
                      <div class="d-flex justify-content-end">
                        <nbc-pagination totalItems="{{contacts.totalRecords}}"
                                        itemsPerPage="{{contactQuery.pageSize}}"
                                        [maxSize]="5"
                                        [(currentPage)]="contactQuery.currentPage"
                                        (pageChanged)="paginate($event)"></nbc-pagination>
                        &nbsp;
                        <button authenticate-user="contact.create,Checkbox.Create"
                                type="button"
                                class="btn btn-primary"
                                (click)="openAddContactModal('lg');">
                          Add Contact
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <contact-list [contacts]="contacts"></contact-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
