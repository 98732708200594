<div class="container">
  <div class="content top-margin">


    <div class="container">
      <div class="row">

        <!-- Left side menu -->
        <!-- end left side menu -->
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <alert *ngIf="showAlertMessage"
                 type="{{alert.type}}"
                 close="closeAlert()">{{alert.msg}}</alert>
          <!-- Form Panel -->
          <div class="panel contact-help">
            <form #formContactus="ngForm" role="form" name="formContactus" novalidate>
              <div class="panel-heading">
                <h3 class="panel-title">Contact Form</h3>
              </div>
              <div class="panel-body">
                <div class="form-horizontal">
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Full Name</label>
                    </div>
                    <div class="col-md-8">
                      <span [ngClass]="{'has-error': formContactus.form.controls['fullname']?.errors?.required && submitted}">
                        <input type="text"
                               class="form-control has-border"
                               maxlength="120"
                               placeholder="Full Name"
                               [(ngModel)]="model.fullName"
                               name="fullname"
                               #fullname="ngModel"
                               id="fullname"
                               required>
                      </span>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Contact Number</label>
                    </div>
                    <div class="col-md-4">
                      <span [ngClass]="{'has-error':contactNumber.invalid && submitted}">
                        <input type="text"
                               class="form-control has-border"
                               placeholder="Contact Number"
                               name="contactNumber"
                               #contactNumber="ngModel"
                               id="contactNumber"
                               [(ngModel)]="model.contactNo"
                               maxlength="30"
                               required
                               phoneFormatOnly>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <input type="text"
                             class="form-control has-border md"
                             placeholder="Contact Number Extension"
                             name="contactext"
                             #contactext="ngModel"
                             id="contactext"
                             [(ngModel)]="model.contactExt"
                             maxlength="10"
                             phoneFormatOnly>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Email address</label>
                    </div>
                    <div class="col-md-8">
                      <span [ngClass]="{'has-error':email.invalid && submitted}">
                        <input type="text"
                               class="form-control has-border"
                               placeholder="Email"
                               name="email"
                               #email="ngModel"
                               id="email"
                               [(ngModel)]="model.email"
                               maxlength="75"
                               [pattern]="contactEmailRegex"
                               required>
                      </span>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">SSO</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text"
                             class="form-control has-border"
                             name="sso"
                             [(ngModel)]="model.ssoid"
                             maxlength="9"
                             numbersOnly>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Subject</label>
                    </div>
                    <div class="col-md-8">
                      <span [ngClass]="{'has-error':subject.invalid && submitted}">
                        <input type="text"
                               class="form-control has-border"
                               placeholder="Subject"
                               maxlength="150"
                               [(ngModel)]="model.subject"
                               name="subject"
                               #subject="ngModel"
                               id="subject"
                               required>
                      </span>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Message</label>
                    </div>
                    <div class="col-md-8">
                      <span [ngClass]="{'has-error':message.invalid && submitted}">
                        <textarea class="form-control has-border scroll-textarea"
                                  name="message"
                                  #message="ngModel"
                                  id="message"
                                  maxlength="500"
                                  appInputRestriction="restrictChars"
                                  [(ngModel)]="model.message"
                                  placeholder="Your message"
                                  required></textarea>
                      </span>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-3 text-right">
                      <label class="control-label">Attachment </label>
                    </div>
                    <div class="col-md-4">
                      <!--<label for="file-upload" class="fileUpload btn btn-secondary btn-block">{{uploadText}}</label>-->
                      <button (click)="fileInput.click()" class="btn btn-secondary btn-upload">
                        {{ uploadText }}
                        <img *ngIf="spin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                      </button>
                      <input #fileInput type="file" (change)="onFileSelected($event)" accept=".doc,.docx,.jpg,.jpeg,.png,.pdf,.xls,.xlsx" style="display: none;" />
                    </div>

                    <br />
                    <br />
                    <br />
                    <div style="width:75%; " *ngFor="let uploaded_document of documentName;let index = index;">
                      <div *ngIf="uploaded_document" style=" width:25% ;margin:0 auto; ">
                        <div class="form-box-close">

                          <i class="fa fa-times-circle" style="position: absolute;"
                             (click)="deleteFileUpload(uploaded_document.file, index, model.filePath, model.FileTimeStamp)"></i>
                        </div>
                        <span class="form-box-filenames">
                          <!--<i class="fa fa-file"></i>-->
                          <!--<a *ngIf="document.photoApproved" style="text-decoration:none;cursor:default"> {{uploaded_documentPickName}}</a>-->
                          {{uploaded_document.file }}
                        </span>
                        <br />
                        <br />
                      </div>
                    </div>


                  </div>
                  <!-- Form Actions -->

                  <div class="row form-actions">
                    <div class="col-md-4 col-md-offset-3">
                      <button type="button"
                              class="btn btn-secondary"
                              (click)="submitContactUsDetails()">
                        Submit
                      </button>

                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>




        </div>




      </div>
    </div>

  </div>

</div>
