<div>
    <!--<h3 class="test"> Helloo..!!</h3>-->
    <div class="modal-header">
        <h3 class="modal-title">Crew Change Report</h3>
        <button type="button" class="btn-close"
                (click)="cancel()" aria-hidden="true">
        </button>
        <div *ngIf="showAlertMessage">
            <alert *ngFor="let alert of alerts"
                   type="{{alert.type}}"
                   close="closeAlert()">{{alert.msg}}</alert>

        </div>
    </div> 
    <div class="modal-body">
        <div class="grid-table">
            <!--Date/Time Range of Change-->
            <div class="grid-content-row"
                 *ngIf="showDateRangeRowAll">
                <!--<div class="grid-col report-name" style="font-family:Calibri">-->
                <div class="grid-col report-name">
                    <span style="color:red;">*</span> Date/Time Range of Change
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>

            <div class="grid-content-row highlight"
                 *ngIf="showDateRangeEditRow">
                <div class="grid-col report-name">
                    <span style="color:red;">*</span> Date/Time Range of Change
                </div>
                <div class="grid-col input-info">
                    <div class="input-group input-group-set max-input"
                         dropdown
                         is-open="rangeOfChangeDateFilter"
                         on-toggle="onToggleDateRangeDatepicker()">
                        <span class="input-group-text"
                              dropdown-toggle><i class="fa fa-calendar"></i></span>
                        <!-- <input name="arrivalDate" class="form-control dropdown-toggle" dropdown-toggle readonly="readonly" value="{{CrewChangeReportInputInfo.startDate?(CrewChangeReportInputInfo.startDate|date) + ' -':'Select Date Range'}} {{(CrewChangeReportInputInfo.endDate|date)||''}}" /> -->
                        <input type="text"
                               placeholder="Select Date Range"
                               class="form-control"
                               bsDaterangepicker
                               readonly
                               [bsValue]="bsInlineRangeValue"
                               #drp="bsDaterangepicker"
                               (bsValueChange)="onToggleFilterDatepicker($event)"
                               [bsConfig]="{ isAnimated: true,rangeInputFormat : 'MMM D, YYYY', dateInputFormat: 'MMM D, YYYY', showWeekNumbers: false}">


                        <div class="dropdown-menu"
                             (click)="openDateRangeDatepicker($event)">
                            <div date-range
                                 start="CrewChangeReportInputInfo.startDate"
                                 end="CrewChangeReportInputInfo.endDate"></div>
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DateRangeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showDateRangeTagRow">
                <div class="grid-col report-name">
                    Date/Time Range of Change
                </div>
                <div class="grid-col">
                    <div class="col-md-4">
                        <span class="filter-tag">{{CrewChangeReportInputInfo.startDate |
                            date:'MM/dd/yyyy'}}-{{CrewChangeReportInputInfo.endDate | date:'MM/dd/yyyy'}} <a><i
                                   class="fa fa-times-circle"
                                   (click)="removeDateTag('DateRangeRow')"></i></a></span>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DateRangeRow')"></i></a>
                </div>
            </div>

            <!--Department-->
            <div class="grid-content-row"
                 *ngIf="!showDepartmentRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col"
                     *ngIf="!showDepartmentRowAll">
                    {{CrewChangeReportInputInfo.department.name}}
                </div>
                <div class="grid-col"
                     *ngIf="showDepartmentRowAll">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('DepartmentRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showDepartmentRow">
                <div class="grid-col report-name">
                    Department
                </div>
                <div class="grid-col input-info">
                    <select class="form-select max-input"
                            [(ngModel)]="CrewChangeReportInputInfo.department">
                        <option [ngValue]="null">Select Department</option>
                        <option [ngValue]="department"
                                *ngFor="let department of CrewChangeReport.department">{{department.name}}</option>
                    </select>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('DepartmentRow')"></i></a>
                </div>
            </div>


            <!--Position Name-->
            <div class="grid-content-row"
                 *ngIf="!showPositionNameRow">
                <div class="grid-col report-name">
                    Job Title
                </div>
                <div class="grid-col"
                     *ngIf="!showPositionNameRowAll">
                    {{CrewChangeReportInputInfo.positionName.name}}
                </div>
                <div class="grid-col"
                     *ngIf="showPositionNameRowAll">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('PositionNameRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showPositionNameRow">
                <div class="grid-col report-name">
                    Job Title
                </div>
                <div class="grid-col input-info">
                    <select class="form-select max-input"
                            [(ngModel)]="CrewChangeReportInputInfo.positionName">
                        <option [ngValue]="null">Select Position Name</option>
                        <option [ngValue]="positionName"
                                *ngFor="let positionName of CrewChangeReport.positionName">{{positionName.name}}
                        </option>

                    </select>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('PositionNameRow')"></i></a>
                </div>
            </div>

            <!--Employee Type-->
            <div class="grid-content-row"
                 *ngIf="!showEmployeeTypeRow">
                <div class="grid-col report-name">
                    Employee Type
                </div>
                <div class="grid-col"
                     *ngIf="!showEmployeeTypeRowAll">
                    {{CrewChangeReportInputInfo.employeeType.name}}
                </div>
                <div class="grid-col"
                     *ngIf="showEmployeeTypeRowAll">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('EmployeeTypeRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showEmployeeTypeRow">
                <div class="grid-col report-name">
                    Employee Type
                </div>
                <div class="grid-col input-info">
                    <select class="form-select max-input"
                            [(ngModel)]="CrewChangeReportInputInfo.employeeType">
                        <option [ngValue]="null">Select Employee Type</option>
                        <option [ngValue]="employeeType"
                                *ngFor="let employeeType of CrewChangeReport.employeeType">{{employeeType.name}}
                        </option>

                    </select>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('EmployeeTypeRow')"></i></a>
                </div>
            </div>

            <!--Changed Data Element -->
            <div class="grid-content-row"
                 *ngIf="showChangeElementRowAll">
                <div class="grid-col report-name">
                    Changed Data Element
                </div>
                <div class="grid-col">
                    All
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('ChangeElementRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row highlight"
                 *ngIf="showChangeElementEditedRow">
                <div class="grid-col report-name">
                    Changed Data Element
                </div>
                <div class="grid-col">
                    <div class="row">
                        <div class="col-md-4"
                             *ngFor="let changeElement of CrewChangeReport.changeElement">
                            <input type="checkbox"
                                   [(ngModel)]="changeElement.value"
                                   [checked]="changeElement.value"
                                   (change)="stateChanged(changeElement.value,changeElement,'ChangeElementRow')">
                            {{changeElement.name}}
                        </div>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-check"
                           (click)="confirmSelection('ChangeElementRow')"></i></a>
                </div>
            </div>
            <div class="grid-content-row"
                 *ngIf="showChangeElementTagRow">
                <div class="grid-col report-name">
                    Changed Data Element
                </div>
                <div class="grid-col">
                    <div class="col-md-4"
                         *ngFor="let changeElement of CrewChangeReportInputInfo.changeElement">
                        <span class="filter-tag"
                              *ngIf="changeElement.value">{{changeElement.name}} <a><i class="fa fa-times-circle"
                                   (click)="removeTag(changeElement,'ChangeElementRow')"></i></a></span>
                    </div>
                </div>
                <div class="grid-col report-edit">
                    <a><i class="fa fa-pencil"
                           (click)="editRow('ChangeElementRow')"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="cancel()">Cancel</button>
        <button id="crewChangeBtnReportExport"
                type="button"
                class="btn btn-primary"
                (click)="exportReport()"
                value="disable"
                [disabled] = "isExport">Export</button>
    </div>
</div>

<app-nbc-file-download></app-nbc-file-download>
