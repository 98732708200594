import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { noop, Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { catchError, map, tap } from "rxjs/operators";
import { BaseUrlHelperService } from "../helper/baseUrlHelper.service";

@Injectable({
    providedIn: "root",
})
export class TeamService {
    constructor(
        private log: NGXLogger,
        private http: HttpClient,
        private _apiroot: BaseUrlHelperService
    ) {}

    /*
     * Generic method to return the collection of the tier network setup
     */

    search(eventTypeId: any, term: any): Observable<any> {
        this.log.trace("searching: " + eventTypeId + "for " + term);
        // using github public api to get users by name
        return this.http
            .get<any>(
                this._apiroot.baseUrl +
                    "teams/Search/" +
                    eventTypeId +
                    "?term=" +
                    term
            )
            .pipe(
                map((data: any) => (data && data.reference) || []),
                tap(
                    () => noop,

                    (err) => {
                        this.log.error(
                            (err && err.message) || "Something goes wrong"
                        );
                        // in case of http error
                        //this.errorMessage = err && err.message || 'Something goes wrong';
                    }
                )
            );
    }

    autocompleteSearch(searchterm: any): Observable<any> {
        this.log.trace("searching for :" + searchterm);
        // using github public api to get users by name
        return this.http
            .get<any>(
                this._apiroot.baseUrl +
                    "teams/Search?term=" +
                    encodeURIComponent(searchterm)
            )
            .pipe(
                map((data: any) => (data && data.reference) || []),
                catchError((err) => {
                    return [];
                })
            );
    }

    fullSearch(term: any) {
        this.log.trace("searching: for " + term);
        return this.http
            .get(this._apiroot.baseUrl + "teams/Search?term=" + term)
            .pipe(
                map((data: any) => (data && data.reference) || []),
                tap(
                    () => noop,

                    (err) => {
                        this.log.error(
                            (err && err.message) || "Something goes wrong"
                        );
                        // in case of http error
                        //this.errorMessage = err && err.message || 'Something goes wrong';
                    }
                )
            );
    }
}
