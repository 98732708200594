import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dynamicHtmlTooltip]'
})
export class NbcDynamicHtmlTooltipDirective {
  @Input('dynamicHtmlTooltip') tooltipContent = '';
  tooltipElement: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltipContent) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private createTooltip(): void {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'dynamic-tooltip');
    this.tooltipElement.innerHTML = this.tooltipContent;
    this.setPosition();
    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  private setPosition(): void {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const top = hostPos.top + window.scrollY + hostPos.height + 5;
    const left = hostPos.left + window.scrollX;
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'background-color', 'black');
    this.renderer.setStyle(this.tooltipElement, 'color', 'white');
    this.renderer.setStyle(this.tooltipElement, 'border-radius', `${5}px`);
    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'z-index', '9999');
    this.renderer.setStyle(this.tooltipElement, 'display', 'block');
  }

  private showTooltip(): void {
    const isTooltipExist = document.getElementsByClassName("dynamic-tooltip");
    if (isTooltipExist.length == 0)
      this.createTooltip();
  }

  private hideTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
    else {
      //INC4188887 - Tooltip did not hide after changing the Rate Code and then mouseover on the contact name
      const divsToRemove = document.getElementsByClassName("dynamic-tooltip");
      if (divsToRemove.length > 0) {
        const divToRemove = divsToRemove[0];
        // Remove the element
        divToRemove.parentNode?.removeChild(divToRemove);
      } else {
        console.log("Element not found");
      }
    }
  }
}
