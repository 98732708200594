import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TierRateCardComponent } from "./Component/tierratecard.component";

const routes: Routes = [
    {
        path: "tierratecard",
        component: TierRateCardComponent,
        children: [
            {
                path: "",
                redirectTo: "configure",
                pathMatch: "full",
            },
            {
                path: "configure",
                component: TierRateCardComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TierRateCardRouterModule {
    static forChild() {
        return {
            ngModule: TierRateCardRouterModule,
            providers: [],
        };
    }
}
