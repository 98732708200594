import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from "@angular/core";

@Directive({
    selector: "[nbc-decimal]",
})
export class NbcDecimalDirective {
    @Input("decimals") decimals = 0;
    @Input("negative") negative = 0;
    @Output() ngModelChange = new EventEmitter();

    private checkAllowNegative(value: string) {
        if (this.decimals <= 0) {
            return String(value).match(new RegExp(/^-?\d+$/));
        } else {
            const regExpString =
                "^-?\\s*((\\d+(\\.\\d{0," +
                this.decimals +
                "})?)|((\\d*(\\.\\d{1," +
                this.decimals +
                "}))))\\s*$";
            return String(value).match(new RegExp(regExpString));
        }
    }

    private check(value: string) {
        if (this.decimals <= 0) {
            return String(value).match(new RegExp(/^\d+$/));
        } else {
            const regExpString =
                "^\\s*((\\d+(\\.\\d{0," +
                this.decimals +
                "})?)|((\\d*(\\.\\d{1," +
                this.decimals +
                "}))))\\s*$";
            return String(value).match(new RegExp(regExpString));
        }
    }

    private run(oldValue: any) {
        setTimeout(() => {
            const currentValue: string = this.el.nativeElement.value;
            const allowNegative = this.negative > 0 ? true : false;

            if (allowNegative) {
                if (
                    !["", "-"].includes(currentValue) &&
                    !this.checkAllowNegative(currentValue)
                ) {
                    this.el.nativeElement.value = oldValue;
                    this.ngModelChange.emit(oldValue);
                }
            } else {
                if (currentValue !== "" && !this.check(currentValue)) {
                    this.el.nativeElement.value = oldValue;
                    this.ngModelChange.emit(oldValue);
                }
            }
        });
    }

    constructor(private el: ElementRef) {}

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.el.nativeElement.value);
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        this.run(this.el.nativeElement.value);
    }
}
