import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NbcLoggerService } from "../../logger/nbc-logger.service";
import { AddressService } from "../../Services/address.service";
import { PhoneNumberService } from "../../Services/phone-number.service";
import { formViewProvider } from "../../../Core/view-provider/form-view-provider";

@Component({
    selector: "nbc-phone-number",
    templateUrl: "nbc-phone-number.component.html",
    viewProviders: [formViewProvider],
})
export class NbcPhoneNumberComponent implements OnChanges {
    constructor(
        private log: NbcLoggerService,
        private _phonenumberService: PhoneNumberService,
        private _addressService: AddressService
    ) {}

    @Input() model!: any;
    @Input() placeholder!: any;
    @Input() nbcRequired = false;
    @Input() nbcAuthenticateUser!: string;
    @Input() nbcAuthenticateCommand!: string;
    @Input() isSubmitted = false;
    @Input() isValid = false;

    @Output() modelChange = new EventEmitter<any>();
    @Output() isValidChange = new EventEmitter<any>();

    public onSync!: Subject<any>;

    componentDestroyed$: Subject<boolean> = new Subject();

    phoneNumber = "";
    completePhoneNumber: any;
    dialCode = "";
    countryCode = "";
    country: any = {
        dialCode: "1",
        displayOrder: 1,
        id: 3,
        idType: null,
        isoCode: "US",
        name: "United States of America",
    };
    defaultCountryCode = "US";
    countries: any = [];
    isPhoneNumberValid = false;
    showCountry = false;

    onBlur() {
        this.SplitNumber(this.phoneNumber);
        if (this.completePhoneNumber)
            this.modelChange.emit(this.completePhoneNumber.number);
        else this.modelChange.emit(this.phoneNumber);
        this.CheckValidation();
    }

    SplitNumber(number) {
        if (number) {
            const result = this._phonenumberService.parsePhoneNumber(
                number,
                this.countryCode
            );
            if (result) {
                this.log.trace("got results: ");
                this.completePhoneNumber = result;
                this.country = {
                    isoCode: this.completePhoneNumber.country,
                    name: this.completePhoneNumber.name,
                };
                this.countryCode = this.completePhoneNumber.country;
                this.phoneNumber = this.completePhoneNumber.nationalNumber;
            }
        } else {
            //this.country = {
            //    isoCode: "",
            //};
            if (this.completePhoneNumber) this.completePhoneNumber.number = "";
        }
    }

    CheckValidation() {
        this.isPhoneNumberValid = this._phonenumberService.isValidNumber(
            this.phoneNumber,
            this.countryCode
        );

        if (this.nbcRequired === true) {
            this.isValid = this.isPhoneNumberValid;
        } else {
            if (this.phoneNumber !== "") {
                this.isValid = this.isPhoneNumberValid;
            } else {
                this.isValid = true;
            }
        }
        this.isValidChange.emit(this.isValid);
    }

    getCountries() {
        this.log.trace("ContactProfile -> get countries:fetching data");
        this._addressService
            .getCountries()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                (data) => {
                    this.countries = data.reference;
                    this.SplitNumber(this.model);
                    this.setDialCode();
                    this.CheckValidation();
                    this.showCountry = true;
                },
                (err) => this.log.error(err)
            );
    }

    setDialCode() {
        this.dialCode = "";
        this.countryCode =
            this.country?.isoCode === undefined ? "" : this.country?.isoCode;
        if (this.countryCode !== "") {
            this.dialCode = this.getCountryDialCode(this.countryCode);
            this.SplitNumber(this.phoneNumber);
            this.CheckValidation();
        }
    }

    getCountryDialCode(IsoCode) {
        let dialcode;
        if (this.countries) {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].isoCode === IsoCode) {
                    dialcode = this.countries[i].dialCode;
                }
            }
        }
        return dialcode;
    }

    ngOnInit() {
        this.onSync = new Subject();
        this.getCountries();
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "model") {
                const current = changes["model"]["currentValue"];
                const prev = changes["model"]["previousValue"];
                this.model = current;
                if (!this.completePhoneNumber) this.SplitNumber(this.model);
                this.CheckValidation();
            }
        }
    }
}
