import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportsComponent } from "./reports.component";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { NbcSharedModule } from "src/app/Shared/shared.module";
import { NgPipesModule } from "ngx-pipes";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ModalModule } from "ngx-bootstrap/modal";
import { ReportsRoutingModule } from "./reports-routing.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionComponent } from "./accordion/accordion.component";
import { PayRateReportComponent } from "./pay-rate-report/pay-rate-report.component";
import { RemoteProductionMemoReportComponent } from "./remote-production-memo-report/remote-production-memo-report.component";
import { RemoteStaffingSummaryReportComponent } from "./remote-staffing-summary-report/remote-staffing-summary-report.component";
import { FlagPayRateReportComponent } from "./flag-pay-rate-report/flag-pay-rate-report.component";
import { CrewRatesReportComponent } from "./crew-rates-report/crew-rates-report.component";
import { ContactInformationReportComponent } from "./contact-information-report/contact-information-report.component";
import { SapDiscrepancyReportComponent } from "./sap-discrepancy-report/sap-discrepancy-report.component";
import { OnboardingReportComponent } from "./onboarding-report/onboarding-report.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { EventLocationReportComponent } from "./event-location-report/event-location-report.component";
import { PayrollTimecardsReportComponent } from "./payroll-timecards-report/payroll-timecards-report.component";
//import { SelectDropDownModule } from "ngx-select-dropdown";

@NgModule({
    declarations: [
        ReportsComponent,
        AccordionComponent,
        PayRateReportComponent,
        RemoteProductionMemoReportComponent,
        RemoteStaffingSummaryReportComponent,
        FlagPayRateReportComponent,
        CrewRatesReportComponent,
        ContactInformationReportComponent,
        SapDiscrepancyReportComponent,
        OnboardingReportComponent,
        EventLocationReportComponent,
        PayrollTimecardsReportComponent,
    ],
    imports: [
        BsDatepickerModule.forRoot(),

        RouterModule,
        FormsModule,
        NbcSharedModule,
        NgPipesModule,
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        CommonModule,
        ReportsRoutingModule.forChild(),
        AccordionModule.forRoot(),
    ],
})
export class ReportsModule {}
