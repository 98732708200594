import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlHelperService } from '../helper/baseUrlHelper.service';
import { NbcLoggerService } from '../logger/nbc-logger.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {
  constructor(private http: HttpClient,
    private _apiroot: BaseUrlHelperService,
    private log: NbcLoggerService) { }  
   

  autocomplete(searchterm:any): Observable<any> {
    this.log.trace("calling skills" + JSON.stringify(searchterm));
    // using github public api to get users by name
    return this.http.get<any>(
      this._apiroot.baseUrl + "skills?term=" + (searchterm || ""))
      .pipe(
        map((data: any) => data && data.reference || []),
        catchError((err) => {
          return []
        })
      );
  }
  autoCompleteByPosition(positionId:any, term:any): Observable<any>{
    this.log.trace("searching: " + JSON.stringify(term) + "for position " + JSON.stringify(positionId));
    return this.http.get<any>(this._apiroot.baseUrl + "positions/" + positionId + "/skills?term=" + (term || "")).pipe(
      map((data: any) => data && data.reference || []),
      catchError((err) => {
        return []
      })
    );
};
}
