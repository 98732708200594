
<div class="tab-content ng-scope">
  <div authenticate-user="Admin.Network.Retrieve">
    <div class="container-fluid">

      <div class="row"
           authenticate-user="admin.remotesnetwork.create">
        <div class="col-md-12 mainheader">
          <div class="header-bar clearfix">
            <p class="heading-title pull-left"></p>
            <button type="button"
                    class="btn btn-primary pull-right"
                    (click)="openUpsertModal(null)"
                    authenticate-user="admin.remotesnetwork.create">
              + ADD NETWORK
            </button>
          </div>
        </div>
      </div>
      <form role="form"
            name="NetworkSetupForm"
            novalidate>
        <div class="tab-page-content">
          <div style="padding-top:10px;"
               class="row view-options"
               *ngIf="resultsFound">
            <div class="col-md-4">
              <div class="form-inline form-sort-options">
                <div class="form-group pagination-results">
                  <nbc-page-size [source]='pageSizeList'
                                 [model]="queryContainer.query.pageSize"
                                 (nbcValueChange)="searchPage($event)"></nbc-page-size>

                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group venuestat d-flex">
                <label class="pagination-results ms-auto">
                  <pagination-label pageSize="{{queryContainer.query.pageSize}}"
                                    currentPage="{{queryContainer.query.currentPage}}}"
                                    totalRecords="{{queryContainer.query.totalRecords}}">
                  </pagination-label>
                </label>
                <nbc-pagination totalItems="{{queryContainer.query.totalRecords}}"
                                itemsPerPage="{{queryContainer.query.pageSize}}"
                                [(currentPage)]="queryContainer.query.currentPage"
                                (pageChanged)="search()"></nbc-pagination>
              </div>
            </div>
          </div>
          <div class="row"
               *ngIf="resultsFound">
            <div class="col-md-8">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead class="dark-header">
                    <tr>
                      <th style="cursor:default;"
                          class="col-md-1 default-header">NETWORK NAME</th>

                      <th style=" cursor:default;"
                          class="col-md-2 default-header"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let network of networks">
                      <td class="col-md-2"
                          style="-ms-word-break: break-all; word-break: break-all;">
                        {{network.description}}
                      </td>


                      <td class="col-md-1 text-end">
                        <span class="edit-row">
                          <a href="javascript:void(0)"
                             class="btn btn-primary"
                             (click)="openUpsertModal(network)"
                             authenticate-user="admin.remotesnetwork.edit"
                             authenticate-command="disabled">
                            <i class="fa-solid fa-pencil fa-lg"></i>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p *ngIf="!resultsFound">No Results.</p>
        </div>
      </form>
    </div>
  </div>
</div>
